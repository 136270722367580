import React, { useCallback, useMemo, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import Typography from '@material-ui/core/Typography'
import MuiButton from '@material-ui/core/Button'
import { useGetData } from '../../../utils/default/ComplexFlowHook'
import { useCommonStyles, useDialogStyles } from '../../../utils/admin/StyleHook'
import useT from '../../../i18ns/admin/useT'
import CoreTable from '../../default/present/CoreTable'
import { createTableData } from '../../../utils/default/TableHelper'
import { BcMath, RoundingMode } from '@golden/bcmath'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { AdminEmperorFees } from '@golden/gdk-admin'
import { usePageFlow } from '../../../utils/default/PageFlowHook'
import LoadingAndErrorFrame from '../../default/frames/LoadingAndErrorFrame'

const Button = React.memo(MuiButton)

const AccountEmperorFeeDialog: React.FC<{ handleClose: () => void }> = (props) => {
  const commonClasses = useCommonStyles()
  const dialogClasses = useDialogStyles()
  const { t } = useT()
  const gdk = useGDK()
  const pageFlow = usePageFlow()

  const [res, setRes] = useState<AdminEmperorFees>({ deposit: [], withdraw: [] })
  useGetData({
    canLoadData: true,
    gdkFunc: () => gdk.emperor.getEmperorFees(),
    gdkFuncDependencies: [gdk],
    onBeforeFetch: pageFlow.setLoadingStart,
    onSuccess: (data) => {
      setRes(data)
      pageFlow.setContentShow()
    },
    onError: pageFlow.setGDKError
  })

  const createFeeTableData = useCallback((rows: Array<{ id: string, type: string, rate: string }>) => createTableData(
    {
      id: {
        label: '',
        value: 'id'
      },
      type: {
        label: t('common.way'),
        value: 'type',
        align: 'center'
      },
      rate: {
        label: t('common.feePercent'),
        value: 'rate',
        align: 'right'
      }
    },
    ['type', 'rate'],
    rows,
    'id'
  ), [t])
  const tables = useMemo(() => (['deposit', 'withdraw'] as const).map((type) => ({
    title: t(`common.${type}`),
    data: createFeeTableData(res[type].map((el) => ({
      id: el.method,
      type: el.name,
      rate: BcMath.base(el.fee_rate).round(2, RoundingMode.RoundDown).get() + '%'
    })))
  })), [createFeeTableData, res, t])
  const tableClasses = useMemo(() => ({
    head: commonClasses.greyTableHead,
    row: commonClasses.tableRow,
    cellHead: commonClasses.tableCellHead
  }), [commonClasses])

  return (
    <Dialog maxWidth="lg" open={true}>
      <Box padding={2} className={commonClasses.dialogPinkHeader}>
        {t('common.financeFee')}
      </Box>
      <DialogContent>
        <Box minWidth="700px">
          <LoadingAndErrorFrame { ...pageFlow.status }>
            <Grid container spacing={3}>
              { tables.map((el) => (<Grid item md={6}>
                <Box paddingY={1}>
                  <Typography variant="h5" className={dialogClasses.title}>{el.title}</Typography>
                </Box>
                <CoreTable
                  data={el.data}
                  total={el.data.rows.length}
                  hasHeadWithNoData
                  classes={tableClasses}
                />
              </Grid>)) }
            </Grid>
          </LoadingAndErrorFrame>
        </Box>
      </DialogContent>
      <Box display="flex" justifyContent="center">
        <Button onClick={props.handleClose} classes={{ root: dialogClasses.cancelButton }}>
          {t('common.close')}
        </Button>
      </Box>
    </Dialog>
  )
}

export default React.memo(AccountEmperorFeeDialog)

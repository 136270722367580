import React from 'react'
import Box from '@material-ui/core/Box'
import FinanceDepositWayToolbar from '../../../components/admin/finance/FinanceDepositWayToolbar'
import FinanceDepositWayTable from '../../../components/admin/finance/FinanceDepositWayTable'
import ScrollablePaper from '../../../components/default/present/ScrollablePaper'
import { SearchToRequestFunc } from '../../../utils/default/ComplexFlowHook'
import { acceptUndefined, pipe, guaranteeBetween } from '../../../utils/default/FormHelper'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { Branch, DepositWayQuery, PaginationReq } from '@golden/gdk-admin'
import { omitBy, isUndefined } from '@golden/utils'

export type RequestType = PaginationReq & DepositWayQuery
export const IS_PROXY_FOR_DEPOSIT = 'is_proxy_for_deposit'

export const searchToRequest: SearchToRequestFunc<RequestType> = (search) => {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const is_active = acceptUndefined(
    search.is_active,
    pipe(
      parseInt,
      (value) => guaranteeBetween(value, 0, 2)
    )
  )

  const converted = {
    page: acceptUndefined(
      search.page,
      pipe(
        parseInt,
        (value) => guaranteeBetween(value, 1, Number.MAX_SAFE_INTEGER)
      )
    ) || 1,
    is_active: is_active === undefined ? 1 : is_active,
    method: search.method,
    forwarder_slug: search.forwarder_slug,
    layer_id: search.layer_id,
    is_for_proxy_deposit: search.layer_id === IS_PROXY_FOR_DEPOSIT ? 1 : undefined
  }
  return omitBy(converted, isUndefined) as RequestType
}

const FinanceDepositWayPage: React.FC = () => {
  const gdk = useGDK()
  gdk.trunk.next([Branch.FORWARDERS])

  return (
    <Box paddingY={5}>
      <ScrollablePaper marginX={5}>
        <Box padding={4}>
          <FinanceDepositWayToolbar />
        </Box>
        <Box paddingTop={1} paddingBottom={5} paddingX={4}>
          <FinanceDepositWayTable />
        </Box>
      </ScrollablePaper>
    </Box>
  )
}

export default React.memo(FinanceDepositWayPage)

import { FrequencyType } from '@golden/gdk-admin'
import TKeyType from '../../i18ns/admin/TKeyType'

const frequencyName: { [key in FrequencyType]: TKeyType } = {
  [FrequencyType.DAY]: 'common.day',
  [FrequencyType.WEEK]: 'common.week',
  [FrequencyType.MONTH]: 'common.month'
}

export default frequencyName

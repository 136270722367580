import React from 'react'
import Box from '@material-ui/core/Box'
import SystemIpWhitelistForm from '../../../components/admin/system/SystemIpWhitelistForm'
import SystemIpWhitelistTable from '../../../components/admin/system/SystemIpWhitelistTable'
import ScrollablePaper from '../../../components/default/present/ScrollablePaper'

const SystemIpWhitelistPage: React.FC = () => {
  return (
    <Box paddingY={5}>
      <ScrollablePaper marginX={5}>
        <Box padding={4}>
          <SystemIpWhitelistForm />
        </Box>
        <Box paddingTop={1} paddingBottom={5} paddingX={4}>
          <SystemIpWhitelistTable />
        </Box>
      </ScrollablePaper>
    </Box>
  )
}

export default React.memo(SystemIpWhitelistPage)

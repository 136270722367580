import React from 'react'
import { Link } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import allRoute from '../route/route'
import useGDK from '../../../providers/admin/gdk/useGDK'
import useGlobalDialog from '../../../providers/admin/dialog/useGlobalDialog'
import { createGlobalDialogConfig } from '../../../utils/default/DialogHelper'
import { useDialogHandleClick, useRequestFromSearch } from '../../../utils/default/ComplexFlowHook'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import useT from '../../../i18ns/admin/useT'
import { searchToRequest } from '../../../views/admin/forestage/BannerNotificationPage'
import { useChecker } from '../../../utils/admin/AdminRouteHook'
import { BannerNotificationTargetType } from '@golden/gdk-admin'

interface PropTypes {
  id: number
  target: BannerNotificationTargetType
  reload: () => void
}

const BannerNotificationTableButtonsBase: React.FC<{
  id: number
  target: BannerNotificationTargetType
  handleDelete: () => void
}> = React.memo((props) => {
  const {
    id,
    target,
    handleDelete
  } = props
  const writable = useChecker()
  const request = useRequestFromSearch({ searchToRequest })
  const classes = useCommonStyles()
  const { t } = useT()

  return (
    <Grid container direction="column" spacing={1} justifyContent="center">
      { writable && (<Grid item>
        <Button
          component={Link}
          to={allRoute.forestageBannerNotificationCopy.encodePath({ search: { ...request }, param: { target, id } })}
          size="small"
          className={classes.blueGradualOutlineButton}
        >
          {t('common.copy')}
        </Button>
      </Grid>) }
      { writable && (<>
        <Grid item>
          <Button
            onClick={handleDelete}
            size="small"
            className={classes.pinkGradualOutlineButton}
          >
            {t('common.delete')}
          </Button>
        </Grid>
      </>) }
    </Grid>
  )
})

const BannerNotificationTableButtons: React.FC<PropTypes> = (props) => {
  const { t } = useT()
  const gdk = useGDK()
  const globalDialog = useGlobalDialog()
  const { id, target, reload } = props

  const handleDelete = useDialogHandleClick({
    dialogId: `deleteBannerNotification-${id}`,
    globalDialog,
    changeDialogConfig: createGlobalDialogConfig({
      showIcon: false,
      message: t('dialog.confirmDelete')
    }),
    successDialogConfig: createGlobalDialogConfig({
      showIcon: false,
      message: t('dialog.deleteFinish'),
      showCancel: false
    }),
    getFailDialogConfig: (error: string) => createGlobalDialogConfig({
      variant: 'error',
      showIcon: true,
      message: error,
      showCancel: false
    }),
    payload: id,
    gdkFunc: (payload) => gdk.notification.deleteBannerNotification(payload),
    gdkFuncDependencies: [gdk],
    onSuccess: () => reload(),
    onSuccessDependencies: [reload]
  })

  return (
    <BannerNotificationTableButtonsBase
      id={id}
      target={target}
      handleDelete={handleDelete}
    />
  )
}

export default React.memo(BannerNotificationTableButtons)

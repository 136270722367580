import React, { useCallback, useState } from 'react'
import { useDebouncedCallback } from 'use-debounce'
import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import EditAnnouncementForm, {
  formToRequest,
  initialForm,
  EditAnnouncementFormType
} from '../../../components/admin/forestage/EditAnnouncementForm'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import { useRedirectHandleBack, useRequestFromSearch } from '../../../utils/default/ComplexFlowHook'
import allRoute from '../../../components/admin/route/route'
import useT from '../../../i18ns/admin/useT'
import useGlobalDialog from '../../../providers/admin/dialog/useGlobalDialog'
import { createGlobalDialogConfig } from '../../../utils/default/DialogHelper'
import AnnouncementConfirmDialog from '../../../components/admin/forestage/AnnouncementConfirmDialog'
import { GDKFormError } from '@golden/gdk-admin'
import { useDialogFlow } from '../../../utils/default/DialogHook'
import { searchToRequest } from './AnnouncementPage'

const defaultForm = initialForm()

const AnnouncementCreatePage: React.FC = () => {
  const request = useRequestFromSearch({ searchToRequest })
  const commonClasses = useCommonStyles()
  const { t } = useT()
  const gdk = useGDK()
  const globalDialog = useGlobalDialog()
  const [state, setState] = useState<{ open: boolean, form: EditAnnouncementFormType }>({ open: false, form: defaultForm })
  const [handleBack, handleDebouncedBack] = useRedirectHandleBack({ path: allRoute.forestageAnnouncement.encodePath({ search: { ...request }, param: {} }) })

  const [handleDebouncedSubmit] = useDebouncedCallback(useCallback((form: EditAnnouncementFormType) => {
    setState({ open: true, form })
  }, []), 200)

  useDialogFlow(globalDialog, 'createAnnouncementSuccess', () => {
    handleBack()
    globalDialog.clearState()
  }, [handleBack])

  const handleSubmit = useCallback((form: EditAnnouncementFormType) => {
    handleDebouncedSubmit(form)
    return form
  }, [handleDebouncedSubmit])

  const handleCancel = useCallback(() => setState((last) => ({ ...last, open: false })), [])

  const [handleOK] = useDebouncedCallback(useCallback(() => {
    setState((state) => {
      gdk.announcement.createAnnouncement(formToRequest(state.form)).subscribe({
        next: () => {
          globalDialog.setConfig(createGlobalDialogConfig({
            showIcon: false,
            showCancel: false,
            message: t('dialog.createAnnouncementSuccess')
          }))
          globalDialog.setOpen({ id: 'createAnnouncementSuccess', value: true, isOK: false })
        },
        error: (error: GDKFormError) => {
          globalDialog.setConfig(createGlobalDialogConfig({
            showIcon: true,
            variant: 'error',
            showCancel: false,
            message: error.message
          }))
          globalDialog.setOpen({ id: 'createAnnouncementFail', value: true, isOK: false })
        }
      })
      return { ...state, open: false }
    })
  }, [gdk, globalDialog, t]), 200)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onOK = useCallback(handleOK, [])

  return (
    <React.Fragment>
      <Box paddingY={5} paddingX={4}>
        <Paper>
          <Box padding={4}>
            <Typography className={commonClasses.goldenWord} variant="h5">{t('page.createAnnouncement')}</Typography>
            <Box paddingY={4}>
              <EditAnnouncementForm
                defaultValue={defaultForm}
                onSubmit={handleSubmit}
                okText={t('common.confirmCreate')}
                onBack={handleDebouncedBack}
              />
            </Box>
          </Box>
        </Paper>
      </Box>
      <AnnouncementConfirmDialog
        title={t('dialog.confirmCreateAnnouncement')}
        open={state.open}
        form={state.form}
        onCancel={handleCancel}
        onOK={onOK}
      />
    </React.Fragment>
  )
}

export default React.memo(AnnouncementCreatePage)

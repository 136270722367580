import { AlertInfo } from '@golden/gdk-admin'
import TKeyType from '../../i18ns/admin/TKeyType'

const alertInfoName: { [key in AlertInfo]: TKeyType } = {
  [AlertInfo.DEPOSIT_WAY_FULL_CASH_REACHED]: 'adminPermission.warningLog.depostiWayFullCashReached',
  [AlertInfo.TRANSFER_STATUS_NOT_MATCH]: 'adminPermission.warningLog.transferStatusNotMatch',
  [AlertInfo.NO_TRANSFER_LOG_IN_THIRD_PARTY]: 'adminPermission.warningLog.noTransferLogInThirdParty',
  [AlertInfo.BET_REVENUE_3W]: 'adminPermission.warningLog.betRevenue3W',
  [AlertInfo.TRC_BIND]: 'adminPermission.warningLog.trcBind',
  [AlertInfo.REAL_NAME_DUPLICATE]: 'adminPermission.warningLog.realNameDuplicate',
  [AlertInfo.RC_WARNED_USER_GAME_LOGIN]: 'adminPermission.warningLog.rcWarnedUserGameLogin',
  [AlertInfo.WITHDRAW_REVENUE_3W]: 'adminPermission.warningLog.withdrawRevenue3W',
  [AlertInfo.WALLET_DUPLICATE]: 'adminPermission.warningLog.walletDuplicate'
}

export default alertInfoName

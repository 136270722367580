import { OrderStatusType } from '@golden/gdk-admin'
import TKeyType from '../../i18ns/admin/TKeyType'

const orderStatusName: { [key in OrderStatusType]: TKeyType } = {
  [OrderStatusType.UNPAID]: 'orderStatus.unpaid',
  [OrderStatusType.WIN]: 'orderStatus.win',
  [OrderStatusType.LOSE]: 'orderStatus.lose',
  [OrderStatusType.CANCELED]: 'orderStatus.canceled',
  [OrderStatusType.CANCELED_BY_SYSTEM]: 'orderStatus.canceledBySystem',
  [OrderStatusType.TIE]: 'orderStatus.tie'
}

export default orderStatusName

import React, { useState, useMemo } from 'react'
import { InAndOutPlatform } from '@golden/gdk-admin'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { searchToRequest } from './AdminReportInAndOutForm'
import { AdminReportInAndOutReport } from '../../../views/admin/adminReport/AdminReportInAndOutPage'
import { useRequestFromSearch, useGetDataByPayload } from '../../../utils/default/ComplexFlowHook'
import useT from '../../../i18ns/admin/useT'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import ScrollablePaper from '../../default/present/ScrollablePaper'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { usePageFlow } from '../../../utils/default/PageFlowHook'
import LoadingAndErrorFrame from '../../default/frames/LoadingAndErrorFrame'
import CoreTable from '../../default/present/CoreTable'
import { formatCount, formatMoney, createTableData } from '../../../utils/default/TableHelper'

interface RowType {
  id: number
  register: string
  bet: string
  betCash: string
  event: string
  deposits: string
  withdraws: string
}

const AdminReportInAndOutPlatform: React.FC = () => {
  const [res, setRes] = useState<InAndOutPlatform>({
    registered_user: 0,
    bet_people_count: 0,
    bet_cash: '0.0000',
    event: '0.0000',
    deposits: '0.0000',
    withdraws: '0.0000'
  })
  const gdk = useGDK()
  const pageFlow = usePageFlow()
  const request = useRequestFromSearch({ searchToRequest })
  const hide = request?.reports.length !== 0 && !request?.reports.includes(AdminReportInAndOutReport.PLATFORM)
  const { t } = useT()
  const classes = useCommonStyles()
  const tableClasses = useMemo(() => {
    return {
      head: classes.greyTableHead,
      cellHead: classes.tableCellHead,
      row: classes.tableRow
    }
  }, [classes])
  useGetDataByPayload({
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    payload: request!,
    gdkFunc: (payload) => gdk.history.getInAndOutPlatform(payload),
    gdkFuncDependencies: [gdk],
    onBeforeFetch: pageFlow.setLoadingStart,
    onSuccess: (res: InAndOutPlatform) => {
      setRes(res)
      pageFlow.setContentShow()
    },
    onError: pageFlow.setGDKError,
    canLoadData: request !== undefined && !hide
  })
  const rows = useMemo(() => {
    return [{
      id: 0,
      register: formatCount(res.registered_user),
      bet: formatCount(res.bet_people_count),
      betCash: formatMoney(res.bet_cash),
      event: formatMoney(res.event),
      deposits: formatMoney(res.deposits),
      withdraws: formatMoney(res.withdraws)
    }] as RowType[]
  }, [res])
  const data = useMemo(() => {
    return createTableData<RowType>(
      {
        id: {
          label: '',
          value: 'id'
        },
        register: {
          label: t('common.totalRegisterPlayer'),
          value: 'register',
          align: 'right'
        },
        bet: {
          label: t('common.totalBetPlayer'),
          value: 'bet',
          align: 'right'
        },
        betCash: {
          label: t('common.allBetCash'),
          value: 'betCash',
          align: 'right'
        },
        event: {
          label: t('common.systemActivity'),
          value: 'event',
          align: 'right'
        },
        deposits: {
          label: t('common.totalTransferIn'),
          value: 'deposits',
          align: 'right'
        },
        withdraws: {
          label: t('common.totalTransferOut'),
          value: 'withdraws',
          align: 'right'
        }
      },
      [
        'register',
        'bet',
        'betCash',
        'event',
        'deposits',
        'withdraws'
      ],
      rows,
      'id'
    )
  }, [t, rows])
  if (hide) return null
  return (
    <ScrollablePaper marginX={5}>
      <Box padding={4}>
        <Box
          paddingY={1.25}
          paddingX={2}
          marginBottom={3}
          className={classes.pinkTitleBar}
        >
          <Typography variant="h5">
            {t('common.platformStatistic')}
          </Typography>
        </Box>
        <Box>
          <LoadingAndErrorFrame { ...pageFlow.status }>
            <CoreTable
              classes={tableClasses}
              data={data}
              total={1}
            />
          </LoadingAndErrorFrame>
        </Box>
        <Box paddingTop={2} whiteSpace="pre">
          <Typography color="error">
            {t('common.platformStatisticTip')}
          </Typography>
        </Box>
      </Box>
    </ScrollablePaper>
  )
}

export default React.memo(AdminReportInAndOutPlatform)

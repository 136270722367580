import { ActivityWalletCreateProcess, ActivityWalletCreateProcessStatusType, PermissionType } from '@golden/gdk-admin'
import { makeStyles } from '@material-ui/core/styles'
import { Link } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Tooltip from '@material-ui/core/Tooltip'
import ErrorIcon from '@material-ui/icons/Error'
import Paper from '@material-ui/core/Paper'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useCommonStyles } from '../../../../utils/admin/StyleHook'
import useT from '../../../../i18ns/admin/useT'
import { useChecker } from '../../../../utils/admin/AdminRouteHook'
import React, { useMemo, useState, useEffect } from 'react'
import { useDialogHandleClick, useGetData, useReload } from '../../../../utils/default/ComplexFlowHook'
import useGDK from '../../../../providers/admin/gdk/useGDK'
import DateTime from '../../../default/present/DateTime'
import activityWalletQueuesStatusName from '../../../../constants/admin/activityWalletQueuesStatusName'
import activityWalletQueuesStatusColor from '../../../../constants/admin/activityWalletQueuesStatusColor'
import Button from '@material-ui/core/Button'
import { createTableData } from '../../../../utils/default/TableHelper'
import CoreTable from '../../../default/present/CoreTable'
import useGlobalDialog from '../../../../providers/admin/dialog/useGlobalDialog'
import { createGlobalDialogConfig } from '../../../../utils/default/DialogHelper'
import { Path } from '../../../../components/admin/route/route'
import { timer } from 'rxjs'

export interface RowType {
  id: number
  order: number | '-'
  name: string
  count: number
  operator: string
  uploadAt: React.ReactElement
  status: React.ReactElement
  operation: React.ReactElement | '-'
}

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.error.main,
    marginLeft: 2
  }
}))

const DeleteButton: React.FC<{ id: number, reload: () => void }> = (props) => {
  const { id, reload } = props
  const { t } = useT()
  const commonClasses = useCommonStyles()
  const gdk = useGDK()
  const globalDialog = useGlobalDialog()

  const handleDelete = useDialogHandleClick({
    dialogId: `deleteActivityWalletQueue-${id}`,
    globalDialog,
    changeDialogConfig: createGlobalDialogConfig({
      showIcon: false,
      message: t('dialog.confirmDelete')
    }),
    successDialogConfig: createGlobalDialogConfig({
      showIcon: false,
      message: t('dialog.deleteFinish'),
      showCancel: false
    }),
    getFailDialogConfig: (error: string) => createGlobalDialogConfig({
      variant: 'error',
      showIcon: true,
      message: error,
      showCancel: false
    }),
    payload: id,
    gdkFunc: (payload) => gdk.activity.deleteActivityWalletCreateProcess(payload),
    gdkFuncDependencies: [gdk],
    onSuccess: () => { reload() },
    onSuccessDependencies: [reload]
  })
  return (
    <Button
      size="small"
      className={commonClasses.blueGradualOutlineButton}
      onClick={handleDelete}
    >
      {t('common.delete')}
    </Button>
  )
}

const ActivityWalletProcessTable: React.FC = () => {
  const { t } = useT()
  const classes = useStyles()
  const gdk = useGDK()
  const writable = useChecker([PermissionType.ACTIVITY_WALLET])
  const commonClasses = useCommonStyles()
  const [loading, setLoading] = useState(false)
  const [list, setList] = useState<Array<ActivityWalletCreateProcess<Date>>>([])
  const [failedList, setFailedList] = useState<Array<ActivityWalletCreateProcess<Date>>>([])
  const { reload, reloadFlag } = useReload()

  const tableClasses = useMemo(() => ({
    head: commonClasses.pinkTableHead,
    row: commonClasses.tableRow,
    cellHead: commonClasses.tableCellHead,
    cellBody: commonClasses.pre
  }), [commonClasses])

  useGetData({
    gdkFunc: () => gdk.activity.getActivityWalletCreateProcess(),
    gdkFuncDependencies: [reloadFlag],
    onBeforeFetch: () => setLoading(true),
    onSuccess: (res: { list: Array<ActivityWalletCreateProcess<Date>>, failed_list: Array<ActivityWalletCreateProcess<Date>> }) => {
      setList(res.list)
      setFailedList(res.failed_list)
      setLoading(false)
    },
    onError: () => {
      setLoading(false)
    }
  })

  const rows: RowType[] = useMemo(() => [
    ...failedList.slice().reverse().map((item) => ({ ...item, order: '-' })),
    ...list.slice().reverse().map((item, index) => ({ ...item, order: index + 1 }))
  ].map((item) => {
    return {
      id: item.id,
      order: item.order,
      name: item.name,
      count: item.count,
      operator: item.operator,
      uploadAt: (<DateTime time={item.upload_at} />),
      status: (
        <Box display="flex" flexDirection="row" alignItems="center" justifyContent="center">
          <span className={commonClasses.chipText} style={{ backgroundColor: activityWalletQueuesStatusColor[item.status] }}>
            { t(activityWalletQueuesStatusName[item.status]) }
          </span>
          { item.failed_log && (
            <Tooltip title={(
              <div className={commonClasses.pre}>{item.failed_log}</div>
            )}>
              <ErrorIcon className={classes.icon} />
            </Tooltip>
          ) }
        </Box>
      ),
      operation: item.status === ActivityWalletCreateProcessStatusType.FAILED
        ? (<DeleteButton id={item.id} reload={reload} />)
        : '-'
    } as RowType
  }), [commonClasses.chipText, failedList, list, reload, t, classes, commonClasses.pre])

  const data = useMemo(() => {
    return createTableData<RowType>(
      {
        id: {
          label: '',
          value: 'id'
        },
        order: {
          label: t('common.queueOrder'),
          value: 'order',
          align: 'center'
        },
        name: {
          label: t('common.filename'),
          value: 'name',
          align: 'center'
        },
        count: {
          label: t('common.amount'),
          value: 'count',
          align: 'center'
        },
        operator: {
          label: t('common.updateBy'),
          value: 'operator',
          align: 'center'
        },
        uploadAt: {
          label: t('common.updateAt'),
          value: 'uploadAt',
          align: 'center'
        },
        status: {
          label: t('common.process'),
          value: 'status',
          align: 'center'
        },
        operation: {
          label: t('common.operation'),
          value: 'operation',
          align: 'center'
        }
      },
      [
        'order',
        'name',
        'count',
        'operator',
        'uploadAt',
        'status',
        ...(writable ? ['operation'] as const : [])
      ],
      rows,
      'id'
    )
  }, [rows, t, writable])

  useEffect(() => {
    const subscription = timer(0, 10000).subscribe(() => reload())
    return () => subscription.unsubscribe()
  }, [reload])

  return (
    <React.Fragment>
      <Grid container alignItems="center" spacing={2}>
        <Grid item>
          <Box display="flex" flexDirection="row" alignItems="center">
              {`${t('common.currentQueueWithColon')}${list.length}`}
              { list.length > 0 && (<span style={{ marginLeft: 20 }}>{`${t('common.failedWithColon')}${failedList.length}`}</span>)}
            </Box>
        </Grid>
        <Grid>
        {
          loading && (
          <Box display="flex" alignItems="center" justifyContent="center">
            <CircularProgress size={16} />
          </Box>
          )
        }
        </Grid>
        {
          writable && (
            <Grid item style={{ marginLeft: 'auto' }}>
              <Button
                component={Link}
                to={Path.ACTIVITY_WALLET_CREATE}
                className={commonClasses.purpleGradualButton}
                classes={{ disabled: commonClasses.disabledButton }}
              >
                {t('page.activityWalletCreate')}
              </Button>
            </Grid>
          )
        }
      </Grid>
      {
        (list.length > 0 || failedList.length > 0) && (
          <Paper>
            <Box padding={4} marginTop={2}>
              <CoreTable
                classes={tableClasses}
                data={data}
                total={list.length}
                showNoData={false}
              />
            </Box>
          </Paper>
        )
      }
    </React.Fragment>
  )
}

export default React.memo(ActivityWalletProcessTable)

import React, { useState, useMemo, createContext, Dispatch, SetStateAction } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { FetchTask, FetchTaskStatusType, PlatformType } from '@golden/gdk-admin'
import { Link, useLocation } from 'react-router-dom'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import ScrollablePaper from '../../../components/default/present/ScrollablePaper'
import LoadingAndErrorFrame from '../../../components/default/frames/LoadingAndErrorFrame'
import CoreTable from '../../../components/default/present/CoreTable'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import { usePageFlow } from '../../../utils/default/PageFlowHook'
import useGDK from '../../../providers/admin/gdk/useGDK'
import useT from '../../../i18ns/admin/useT'
import { useGetData, useReload } from '../../../utils/default/ComplexFlowHook'
import { useChecker } from '../../../utils/admin/AdminRouteHook'
import DateTime from '../../../components/default/present/DateTime'
import fetchTaskStatusName from '../../../constants/admin/fetchTaskStatusName'
import FetchTaskDialog, { initialForm, FormType, Payload } from '../../../components/admin/playerReport/FetchTaskDialog'
import { createDefaultFormState } from '../../../utils/default/FormHook'
import StateProvider from '../../../providers/default/StateProvider'
import FetchTaskButton from '../../../components/admin/playerReport/FetchTaskButton'
import { createTableData } from '../../../utils/default/TableHelper'
import allRoute from '../../../components/admin/route/route'
import { parsePath } from '../../../utils/default/RouteHelper'
import OnOffText from '../../../components/default/present/OnOffText'
import Tip from '../../../components/admin/Tip'
import FetchBtiButton from '../../../components/admin/playerReport/FetchBtiButton'
import useGDKStore from '../../../providers/admin/gdk/useGDKStore'
import { mergeArray } from '@golden/utils'

const useStyles = makeStyles(() => ({
  break: {
    wordBreak: 'break-all'
  }
}))

interface RowType {
  id: number
  platform: string
  is_active: React.ReactElement
  last_updated_at: React.ReactElement
  range: React.ReactElement
  count: number
  status: string
  operator: string
  function: React.ReactElement
  error: React.ReactElement
}

const defaultForm = initialForm()
const initialContext = createContext(createDefaultFormState(defaultForm))

const defaultPayload = {
  context: initialContext,
  defaultValue: defaultForm,
  open: false,
  platform: PlatformType.VR,
  onSubmit: (form: FormType) => form,
  onCancel: () => {}
}

export const DialogPayloadContext = createContext<[
  Payload,
  Dispatch<SetStateAction<Payload>>
]>([
  defaultPayload,
  () => {}
])

const PlayerReportFetchTaskPage: React.FC = () => {
  const classes = useStyles()
  const commonClasses = useCommonStyles()
  const { t } = useT()
  const platforms = useGDKStore.platform.platforms()
  const gdk = useGDK()
  const location = useLocation()
  const pageFlow = usePageFlow(true)
  const [list, setList] = useState<FetchTask[]>([])
  const { reloadFlag, reload } = useReload()
  const writable = useChecker()
  const search = useMemo(() => {
    return parsePath(location.search, location.pathname).search
  }, [location.search, location.pathname])

  useGetData({
    gdkFunc: () => gdk.platform.getFetchTasks(),
    gdkFuncDependencies: [gdk, reloadFlag],
    onBeforeFetch: pageFlow.setLoadingStart,
    onSuccess: (res: FetchTask[]) => {
      setList(res)
      pageFlow.setContentShow()
    },
    onError: (error) => {
      pageFlow.setError(error.message)
    }
  })
  const rows: RowType[] = useMemo(() => {
    return mergeArray(platforms, list, (platform, item) => platform.id === item.platform_id)
      .map(([platform, entry]) => {
        const startAt = entry.start_at ? (<DateTime time={entry.start_at} />) : (<p></p>)
        const symbol = entry.start_at && entry.end_at ? (<p>~</p>) : (<p></p>)
        const endAt = entry.end_at ? (<DateTime time={entry.end_at} />) : (<p></p>)
        return {
          id: platform.id,
          platform: platform.instance.walletName,
          is_active: (
            <OnOffText
              value={entry.is_active}
              yesText={t('common.open')}
              noText={t('common.close')}
            />
          ),
          last_updated_at: entry.updated_at ? (<DateTime time={entry.updated_at} />) : (<p></p>),
          range: [PlatformType.SABA, PlatformType.GF].includes(platform.id)
            ? (<p>{t('common.sabaTimeTip')}</p>)
            : (
            <Grid container direction="row" justifyContent="center" spacing={2}>
              <Grid item>
                {startAt}
              </Grid>
              <Grid item>
                {symbol}
              </Grid>
              <Grid item>
                {endAt}
              </Grid>
            </Grid>
              ),
          count: entry.count,
          status: entry.status ? t(fetchTaskStatusName[entry.status]) : '',
          operator: entry.updated_at ? entry.updated_by : '',
          function: entry.platform_id !== PlatformType.BTI
            ? (
            <FetchTaskButton
              disabled={!entry.is_active || entry.status === FetchTaskStatusType.PROCESSING}
              platform={entry.platform_id}
              reload={reload}
            />
              )
            : (
            <Grid container direction="row" spacing={2} justifyContent="center">
              <Grid item>
                <FetchTaskButton
                  disabled={!entry.is_active || entry.status === FetchTaskStatusType.PROCESSING}
                  platform={entry.platform_id}
                  reload={reload}
                />
              </Grid>
              <Grid item>
                <FetchBtiButton />
              </Grid>
            </Grid>
              ),
          error: (
            <p className={classes.break}>
              {entry.error}
            </p>
          )
        } as RowType
      })
  }, [list, platforms, t, reload, classes.break])
  const data = useMemo(() => {
    return createTableData<RowType>(
      {
        id: {
          label: '',
          value: 'id'
        },
        platform: {
          label: t('common.gameStore'),
          value: 'platform',
          align: 'center'
        },
        is_active: {
          label: t('common.gameStoreStatus'),
          value: 'is_active',
          align: 'center'
        },
        last_updated_at: {
          label: t('common.updateAt'),
          value: 'last_updated_at',
          align: 'center'
        },
        range: {
          label: (
            <div className={commonClasses.pre}>
              {t('common.lastUpdatedRange')}
            </div>
          ),
          value: 'range',
          align: 'center'
        },
        count: {
          label: (
            <div className={commonClasses.pre}>
              {t('common.updatedCount')}
            </div>
          ),
          value: 'count',
          align: 'center'
        },
        status: {
          label: t('common.status'),
          value: 'status',
          align: 'center'
        },
        operator: {
          label: t('common.updateBy'),
          value: 'operator',
          align: 'center'
        },
        function: {
          label: t('common.operation'),
          value: 'function',
          align: 'center'
        },
        error: {
          label: t('common.errorInformation'),
          value: 'error',
          align: 'left',
          width: 100
        }
      },
      writable
        ? [
            'platform',
            'is_active',
            'range',
            'count',
            'status',
            'operator',
            'last_updated_at',
            'function',
            'error'
          ]
        : [
            'platform',
            'is_active',
            'range',
            'count',
            'status',
            'operator',
            'last_updated_at',
            'error'
          ],
      rows,
      'id'
    )
  }, [rows, t, writable, commonClasses.pre])
  const tableClasses = useMemo(() => {
    return {
      head: commonClasses.pinkTableHead,
      cellHead: commonClasses.tableCellHead,
      row: commonClasses.tableRow
    }
  }, [commonClasses.pinkTableHead, commonClasses.tableCellHead, commonClasses.tableRow])
  return (
    <StateProvider
      defaultValue={defaultPayload}
      context={DialogPayloadContext}
    >
      <Box paddingY={5}>
        <Box paddingBottom={2} paddingLeft={4}>
          <Button
            component={Link}
            to={allRoute.playerReportBet.encodePath({ search, param: {} })}
            className={commonClasses.greyButton}
          >
            {t('common.back')}
          </Button>
        </Box>
        <ScrollablePaper marginX={4}>
          <Box padding={4}>
            <Tip text={t('common.warningFetchTaskTip')} />
            <Box paddingY={2}>
              <LoadingAndErrorFrame { ...pageFlow.status }>
                <CoreTable
                  classes={tableClasses}
                  data={data}
                  total={list.length}
                />
              </LoadingAndErrorFrame>
            </Box>
          </Box>
        </ScrollablePaper>
      </Box>
      <FetchTaskDialog />
    </StateProvider>
  )
}

export default PlayerReportFetchTaskPage

import React, { useMemo } from 'react'
import { getTime } from 'date-fns'
import { omitBy, isUndefined } from '@golden/utils'
import { ActivityType, ActivityWeeklyBonusDrawStatusType } from '@golden/gdk-campaign'
import Grid from '@material-ui/core/Grid'
import MuiTextField, { TextFieldProps } from '@material-ui/core/TextField'
import FormField from '../../default/form/FormField'
import DropDown, { PropTypes as DropDownProps } from '../../default/form/DropDown'
import { FormState, ValueGetter } from '../../../utils/default/FormHook'
import { ActivityFormType, ActivityRequest } from './ActivityRecordDetailForm'
import useT from '../../../i18ns/admin/useT'
import { getValueFromChangeEvent, convertEmptyToUndefined } from '../../../utils/default/FormHelper'
import { SearchToRequestFunc } from '../../../utils/default/ComplexFlowHook'
import { weeklyBonusDrawStatusName } from '../../../constants/default/activityStatusName'

export interface ActivityWeeklyBonusDrawFormType {
  account: string
  agent_account: string
  weeklyBonusDraw: ActivityWeeklyBonusDrawStatusType | '--'
}

export const initialForm = (): ActivityWeeklyBonusDrawFormType => ({
  account: '',
  agent_account: '',
  weeklyBonusDraw: '--'
})

export const getValueFromEvent: ValueGetter<ActivityWeeklyBonusDrawFormType> = {
  account: getValueFromChangeEvent,
  agent_account: getValueFromChangeEvent,
  weeklyBonusDraw: getValueFromChangeEvent
}

export const formToRequest = (form: ActivityFormType): ActivityRequest => {
  const converted = {
    activity: ActivityType.WEEKLY_BONUS_DRAW,
    account: convertEmptyToUndefined(form.account),
    agent_account: convertEmptyToUndefined(form.agent_account),
    status: form.weeklyBonusDraw === '--' ? undefined : form.weeklyBonusDraw,
    type: form.type,
    start_at: form.time.start === null ? undefined : getTime(form.time.start),
    end_at: form.time.end === null ? undefined : getTime(form.time.end),
    page: 1
  } as ActivityRequest
  return omitBy(converted, isUndefined) as ActivityRequest
}

export const searchToRequest: SearchToRequestFunc<{
  account: string | undefined
  agent_account: string | undefined
  status: ActivityWeeklyBonusDrawStatusType | '--'
}> = (search) => {
  const converted = {
    account: search.account ? search.account : (search.account ? search.account : undefined),
    agent_account: search.agent_account,
    weeklyBonusDraw: search.status ? search.status : '--'
  }
  return omitBy(converted, isUndefined) as any
}

interface PropTypes {
  context: React.Context<FormState<ActivityFormType>>
}

const TextField = React.memo(MuiTextField)

const ActivityRecordWeeklyBonusDrawForm: React.FC<PropTypes> = (props) => {
  const { context } = props
  const { t } = useT()

  const stateOptions = useMemo(() => {
    return [{ name: t('common.all'), value: '--' }]
      .concat(
        Object.keys(weeklyBonusDrawStatusName)
          .map((key) => ({ name: t(weeklyBonusDrawStatusName[key as ActivityWeeklyBonusDrawStatusType]), value: key })) as any
      )
  }, [t])

  return (
    <React.Fragment>
      <Grid item xs={12} md={6} lg={3}>
        <FormField<ActivityFormType, TextFieldProps>
          context={context}
          component={TextField}
          name="account"
          label={t('common.playerAccount')}
          placeholder={t('placeholder.inputPlayerAccount')}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} md={6} lg={3}>
        <FormField<ActivityFormType, TextFieldProps>
          context={context}
          component={TextField}
          name="agent_account"
          label={t('common.agentAccount')}
          placeholder={t('placeholder.inputAgentAccount')}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} md={6} lg={3}>
        <FormField<ActivityFormType, DropDownProps>
          context={context}
          component={DropDown}
          name="weeklyBonusDraw"
          label={t('common.status')}
          options={stateOptions}
          fullWidth
        />
      </Grid>
    </React.Fragment>
  )
}

export default React.memo(ActivityRecordWeeklyBonusDrawForm)

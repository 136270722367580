import React, { useState, useMemo } from 'react'
import { IpMonitoringList } from '@golden/gdk-admin'
import Box from '@material-ui/core/Box'
import PlayerIpMonitoringNestedTable from './PlayerIpMonitoringNestedTable'
import LoadingAndErrorFrame from '../../default/frames/LoadingAndErrorFrame'
import ScrollablePaper from '../../default/present/ScrollablePaper'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import useT from '../../../i18ns/admin/useT'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { searchToRequest } from './PlayerIpMonitoringForm'
import { usePageFlow } from '../../../utils/default/PageFlowHook'
import { useRequestFromSearch, useGetDataByPayload } from '../../../utils/default/ComplexFlowHook'
import allRoutes from '../route/route'

import { Link } from 'react-router-dom'
import DateTime from '../../default/present/DateTime'
import { startOfDay, subDays, getTime, endOfDay } from 'date-fns'
import VpnChip from '../VpnChip'
import useGDKStore from '../../../providers/admin/gdk/useGDKStore'
import { mergeArray } from '@golden/utils'

const PlayerIpMonitoringTable: React.FC = () => {
  const classes = useCommonStyles()
  const { t } = useT()
  const gdk = useGDK()
  const [list, setList] = useState<Array<IpMonitoringList<Date>>>([])
  const pageFlow = usePageFlow()
  const request = useRequestFromSearch({ searchToRequest })
  const categories = useGDKStore.platform.categories()
  useGetDataByPayload({
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    payload: request!,
    gdkFunc: (payload) => gdk.history.getIpMonitoringHistory(payload),
    gdkFuncDependencies: [gdk],
    onBeforeFetch: pageFlow.setLoadingStart,
    onSuccess: (res: Array<IpMonitoringList<Date>>) => {
      setList(res)
      pageFlow.setContentShow()
    },
    onError: pageFlow.setGDKError,
    canLoadData: request !== undefined
  })
  const tableClasses = useMemo(() => ({
    head: classes.pinkTableHead,
    row: classes.noBordertableRow,
    cellHead: classes.tableCellHead
  }), [classes])

  const dataForTable = useMemo(() => (
    list?.map((monitoringList) => ({
      ip: (<Box display="flex" flexDirection="column" alignItems="center">
        {monitoringList.is_vpn && (<VpnChip />)}
        <Link
          to={allRoutes.playerIp.encodePath({ search: { start_at: getTime(startOfDay(subDays(new Date(), 90))), end_at: getTime(endOfDay(new Date())), ip: monitoringList.ip, page: 1 }, param: {} })}
          target="_blank"
        >
          {monitoringList.ip}
        </Link>
      </Box>),
      is_register_ip: monitoringList.is_register_ip,
      histories: monitoringList.histories.map((history) => ({
        ...history,
        player_account: (
          <Link
            to={allRoutes.playerDetail.encodePath({ search: {}, param: { id: history.user_id } })}
            target="_blank"
          >
            {history.player_account}
          </Link>
        ),
        last_login_at: <DateTime time={history.last_login_at}/>,
        is_selected: (history.player_account === request?.account),
        player_real_name: history.player_real_name || '-',
        game_category_names: mergeArray(categories, history.game_categories ?? [], (category, id) => category.instance.id === id).map(([el]) => el.instance.name)
      }))
    }))
  ), [categories, list, request?.account])

  if (request === undefined) return null

  return (
    <ScrollablePaper marginX={5}>
      <Box padding={4}>
        <LoadingAndErrorFrame { ...pageFlow.status }>
          <PlayerIpMonitoringNestedTable
            classes={tableClasses}
            data={dataForTable}
            reqAccount={request.account}
            columnNames={
              {
                groupTag: t('common.ipAddress'),
                rowTags: [t('common.playerAccount'), t('common.gameCategoryType'), t('common.belongStaffName'), t('common.realName'), t('common.lastLoginAt')]
              }
            }
          />
        </LoadingAndErrorFrame>
      </Box>
    </ScrollablePaper>
  )
}

export default React.memo(PlayerIpMonitoringTable)

import React, { useMemo } from 'react'
import { EditAccountEmperorFormType } from './EditAccountEmperorForm'
import { FormState, useForm, ValueGetter, FormValidation, DisableFieldGetter } from '../../../utils/default/FormHook'
import { FormPropType, getValueFromChangeEvent } from '../../../utils/default/FormHelper'
import useT from '../../../i18ns/admin/useT'
import { createValidateNotEmpty, createValidatePassword, createValidateRange } from '../../../utils/default/Validator'

interface PropTypes extends FormPropType<EditAccountEmperorFormType> {
  context: React.Context<FormState<EditAccountEmperorFormType>>
  nickname?: string
  disableAccount?: boolean
  requirePassword?: boolean
  editSecurityPassword?: boolean
}

const getValueFromEvent: ValueGetter<EditAccountEmperorFormType> = {
  account: getValueFromChangeEvent,
  nickname: getValueFromChangeEvent,
  password: getValueFromChangeEvent,
  passwordConfirmation: getValueFromChangeEvent,
  securityPassword: getValueFromChangeEvent,
  securityPasswordConfirmation: getValueFromChangeEvent,
  isLoginable: getValueFromChangeEvent,
  percentage: getValueFromChangeEvent,
  memo: getValueFromChangeEvent
}

const EmperorFormStateProvider: React.FC<PropTypes> = (props) => {
  const {
    defaultValue,
    onSubmit,
    context: Context,
    disableAccount,
    requirePassword,
    children
  } = props

  const { t } = useT()

  const formValidation = useMemo(() => {
    return {
      account: [
        {
          func: createValidateNotEmpty<EditAccountEmperorFormType>('account', t),
          when: ['change', 'beforeClickSubmit']
        }
      ],
      nickname: [
        {
          func: createValidateNotEmpty<EditAccountEmperorFormType>('nickname', t),
          when: ['change', 'beforeClickSubmit']
        }
      ],
      password: [
        {
          func: createValidateNotEmpty<EditAccountEmperorFormType>('password', t),
          when: requirePassword ? ['change', 'beforeClickSubmit'] : []
        },
        {
          func: createValidatePassword<EditAccountEmperorFormType>('password', t, 'error.passwordFormatError'),
          when: ['change', 'beforeClickSubmit']
        },
        {
          func: (value, form) => {
            if (
              value !== '' &&
              form.passwordConfirmation !== '' &&
              value !== form.passwordConfirmation
            ) {
              return { isPass: false, stop: true, newError: { password: null, passwordConfirmation: t('error.mustEqualPassword') } }
            }
            return { isPass: true, stop: false, newError: { password: null, passwordConfirmation: null } }
          },
          when: ['change', 'beforeClickSubmit']
        }
      ],
      passwordConfirmation: [
        {
          func: createValidateNotEmpty<EditAccountEmperorFormType>('passwordConfirmation', t),
          when: requirePassword ? ['change', 'beforeClickSubmit'] : []
        },
        {
          func: createValidatePassword<EditAccountEmperorFormType>('passwordConfirmation', t, 'error.passwordFormatError'),
          when: ['change', 'beforeClickSubmit']
        },
        {
          func: (value, form) => {
            if (value !== '' && value !== form.password) {
              return { isPass: false, stop: true, newError: { passwordConfirmation: t('error.mustEqualPassword') } }
            }
            return { isPass: true, stop: false, newError: { passwordConfirmation: null } }
          },
          when: ['change', 'beforeClickSubmit']
        }
      ],
      securityPassword: [
        {
          func: createValidatePassword<EditAccountEmperorFormType>('securityPassword', t, 'error.passwordFormatError'),
          when: ['change', 'beforeClickSubmit']
        },
        {
          func: (value, form) => {
            if (
              value !== '' &&
              form.passwordConfirmation !== '' &&
              value !== form.passwordConfirmation
            ) {
              return { isPass: false, stop: true, newError: { password: null, passwordConfirmation: t('error.mustEqualPassword') } }
            }
            return { isPass: true, stop: false, newError: { password: null, passwordConfirmation: null } }
          },
          when: ['change', 'beforeClickSubmit']
        }
      ],
      securityPasswordConfirmation: [
        {
          func: createValidatePassword<EditAccountEmperorFormType>('securityPasswordConfirmation', t, 'error.passwordFormatError'),
          when: ['change', 'beforeClickSubmit']
        },
        {
          func: (value, form) => {
            if (value !== '' && value !== form.securityPassword) {
              return { isPass: false, stop: true, newError: { securityPasswordConfirmation: t('error.mustEqualPassword') } }
            }
            return { isPass: true, stop: false, newError: { securityPasswordConfirmation: null } }
          },
          when: ['change', 'beforeClickSubmit']
        }
      ],
      isLoginable: [],
      percentage: [
        {
          func: createValidateNotEmpty<EditAccountEmperorFormType>('percentage', t),
          when: ['change', 'beforeClickSubmit']
        },
        {
          func: createValidateRange<EditAccountEmperorFormType>(
            'percentage',
            (value) => Number(value as string),
            'lessEqual',
            100,
            t('error.exceedUpperLimit')
          ),
          when: ['change', 'beforeClickSubmit']
        }
      ],
      memo: []
    } as FormValidation<EditAccountEmperorFormType>
  }, [t, requirePassword])

  const disableField = useMemo(() => {
    return {
      account: () => disableAccount
    } as DisableFieldGetter<EditAccountEmperorFormType>
  }, [disableAccount])

  const formState = useForm<EditAccountEmperorFormType>({
    defaultValue,
    onSubmit,
    getValueFromEvent,
    formValidation,
    disableField
  })

  const { handleSubmit } = formState

  return (
    <Context.Provider value={formState}>
      <form onSubmit={handleSubmit}>
        {children}
      </form>
    </Context.Provider>
  )
}

export default EmperorFormStateProvider

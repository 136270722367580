import React, { useState } from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { Authenticator } from '@golden/gdk-admin'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import LoadingAndErrorFrame from '../../../components/default/frames/LoadingAndErrorFrame'
import useGlobalDialog from '../../../providers/admin/dialog/useGlobalDialog'
import useT from '../../../i18ns/admin/useT'
import { createGlobalDialogConfig } from '../../../utils/default/DialogHelper'
import { usePageFlow } from '../../../utils/default/PageFlowHook'
import { useGetData, useGDKFuncHandleSubmit } from '../../../utils/default/ComplexFlowHook'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import GoogleAuthenticatorForm, { FormType as GoogleAuthenticatorFormType } from '../../../components/admin/GoogleAuthenticatorForm'
import googleIcon from '../../../assets/admin/images/google-app-icon-small.png'
import { useChecker } from '../../../utils/admin/AdminRouteHook'

const useStyles = makeStyles((theme) => ({
  installExplain: {
    cursor: 'pointer',
    borderRadius: theme.shape.borderRadius,
    border: '1px solid #01c0c8',
    backgroundColor: theme.palette.grey[200],
    color: '#01c0c8',
    padding: theme.spacing(1),
    textDecoration: 'none'
  },
  text: {
    overflowWrap: 'anywhere',
    textAlign: 'center',
    wordBreak: 'break-all'
  },
  container: {
    [theme.breakpoints.up('xl')]: {
      borderRight: `2px solid ${theme.palette.grey[300]}`
    }
  }
}))

const SystemGooglePage: React.FC = () => {
  const theme = useTheme()
  const globalDialog = useGlobalDialog()
  const commonClasses = useCommonStyles()
  const classes = useStyles()
  const gdk = useGDK()
  const writable = useChecker()
  const [status, setStatus] = useState<{ result: boolean } | null>(null)
  const [qrcode, setQrcode] = useState<Authenticator>()
  const { t } = useT()
  const pageFlow = usePageFlow()

  useGetData({
    gdkFunc: () => gdk.parameter.getAuthenticatorStatus(),
    gdkFuncDependencies: [gdk],
    onBeforeFetch: pageFlow.setLoadingStart,
    onSuccess: (res) => {
      setStatus(res)
      pageFlow.setContentShow()
    },
    onError: pageFlow.setGDKError,
    canLoadData: status === null
  })

  useGetData({
    gdkFunc: () => gdk.parameter.issueAuthenticator(),
    gdkFuncDependencies: [gdk],
    onSuccess: setQrcode,
    canLoadData: status !== null && !status.result
  })

  const { handleSubmit: handleValidateSubmit } = useGDKFuncHandleSubmit({
    formToRequest: (form: GoogleAuthenticatorFormType) => form.join(''),
    gdkFunc: (payload) => gdk.parameter.validateAuthenticator(payload),
    gdkFuncDependencies: [gdk],
    onSuccess: () => {
      setStatus(null)
      globalDialog.setConfig(createGlobalDialogConfig({
        showIcon: false,
        showCancel: false,
        message: t('dialog.googleBindSuccess')
      }))
      globalDialog.setOpen({ id: 'validateAuthSuccess', value: true, isOK: false })
    },
    onError: (error) => {
      globalDialog.setConfig(createGlobalDialogConfig({
        variant: 'error',
        showCancel: false,
        message: error.message
      }))
      globalDialog.setOpen({ id: 'validateAuthFail', value: true, isOK: false })
    }
  })

  const { handleSubmit: handleDeleteSubmit } = useGDKFuncHandleSubmit({
    formToRequest: (form: GoogleAuthenticatorFormType) => form.join(''),
    gdkFunc: (payload) => gdk.parameter.deleteAuthenticator(payload),
    gdkFuncDependencies: [gdk],
    onSuccess: () => {
      setStatus(null)
      globalDialog.setConfig(createGlobalDialogConfig({
        showIcon: false,
        showCancel: false,
        message: t('dialog.deleteGoogleAuthenticatorSuccess')
      }))
      globalDialog.setOpen({ id: 'deleteAuthSuccess', value: true, isOK: false })
    },
    onError: (error) => {
      globalDialog.setConfig(createGlobalDialogConfig({
        variant: 'error',
        showCancel: false,
        message: error.message
      }))
      globalDialog.setOpen({ id: 'deleteAuthFail', value: true, isOK: false })
    }
  })

  return (
    <Box padding={5}>
      <LoadingAndErrorFrame { ...pageFlow.status }>
        <Box paddingTop={3}>
          <Paper>
            <Box padding={4}>
              <Grid container direction="column" spacing={4}>
                <Grid item>
                  <Box
                    display="flex"
                    flexDirection="row"
                    paddingY={1.25}
                    paddingX={2}
                    className={commonClasses.pinkTitleBar}
                  >
                    <Typography variant="h5">
                      {t('page.systemGoogle')}
                    </Typography>
                    <Box
                      marginX={2}
                      paddingX={2}
                      paddingY={0.5}
                      borderRadius={theme.shape.borderRadius}
                      color={theme.palette.common.white}
                      bgcolor={status?.result ? '#1fc485' : '#c10000'}
                    >
                      {status?.result ? t('common.bound') : t('common.notBind')}
                    </Box>
                  </Box>
                </Grid>
                <Grid item>
                  <Grid container direction="row" wrap="wrap">
                    <Grid item lg={12} xl={4}>
                      <Box
                        marginX={4}
                        paddingY={1}
                        paddingX={4}
                        className={classes.container}
                      >
                        <Grid container direction="column" alignItems="center">
                          <Grid item>
                            <img
                              src={
                                status?.result
                                  ? googleIcon
                                  : gdk.qrcode.generateURL(qrcode?.url ?? '', 200)
                              }
                              alt=""
                              width="200"
                              height="200"
                            />
                          </Grid>
                          <Grid item>
                            <Typography variant="h6" className={commonClasses.bold}>
                              {status?.result ? t('common.deleteGoogleAuthenticator') : t('common.systemKey')}
                            </Typography>
                          </Grid>
                          <Grid item>
                            {status?.result
                              ? ''
                              : (
                              <Typography variant="body1" className={classes.text}>
                                {qrcode?.key ?? ''}
                              </Typography>
                                )}
                          </Grid>
                          <Grid item>
                            <Box paddingTop={1}>
                              <Typography variant="h6" className={commonClasses.bold}>
                                {t('common.inputGoogleCode')}
                              </Typography>
                            </Box>
                          </Grid>
                          <GoogleAuthenticatorForm onSubmit={status?.result ? handleDeleteSubmit : handleValidateSubmit} disabled={!writable} />
                        </Grid>
                      </Box>
                    </Grid>
                    <Grid item lg={12} xl={6}>
                      <Box paddingY={1}>
                        <Grid container direction="column" spacing={2}>
                          <Grid item>
                            <Typography variant="h6" className={commonClasses.bold}>
                              {t('common.googleAuthenticatorExplain')}
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Box paddingTop={2}>
                              {t('common.googleAuthenticatorExplainPlain')}
                            </Box>
                          </Grid>
                          <Grid item>
                            <Grid container direction="row">
                              <a
                                href="/"
                                target="_blank"
                                className={classes.installExplain}
                              >
                                {t('common.installGoogleAuthenticatorExplain')}
                              </a>
                            </Grid>
                          </Grid>
                          <Grid item>
                            <Box paddingTop={6}>
                              <Typography variant="h6" className={commonClasses.bold}>
                                {t('common.googleAuthenticatorSoftwareDownload')}
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid item>
                            <Grid container direction="row" spacing={4}>
                              <Grid item>
                                <Box
                                  width={100}
                                  display="flex"
                                  flexDirection="column"
                                  alignItems="center"
                                >
                                  <img src={gdk.qrcode.generateURL(t('qrcode.android'), 300)} width={100} alt="Android" />
                                  <Typography variant="body1" className={commonClasses.bold}>
                                    {t('common.android')}
                                  </Typography>
                                </Box>
                              </Grid>
                              <Grid item>
                                <Box
                                  width={100}
                                  display="flex"
                                  flexDirection="column"
                                  alignItems="center"
                                >
                                  <img src={gdk.qrcode.generateURL(t('qrcode.googlePlay'), 300)} width={100} alt="Android" />
                                  <Typography variant="body1" className={commonClasses.bold}>
                                    {t('common.googlePlay')}
                                  </Typography>
                                </Box>
                              </Grid>
                              <Grid item>
                                <Box
                                  width={100}
                                  display="flex"
                                  flexDirection="column"
                                  alignItems="center"
                                >
                                  <img src={gdk.qrcode.generateURL(t('qrcode.appleStore'), 300)} width={100} alt="Android" />
                                  <Typography variant="body1" className={commonClasses.bold}>
                                    {t('common.ios')}
                                  </Typography>
                                </Box>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Box>
      </LoadingAndErrorFrame>
    </Box>
  )
}

export default React.memo(SystemGooglePage)

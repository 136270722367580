import React, { useCallback, useState } from 'react'
import { ChangeEmperorReq } from '@golden/gdk-admin'
import { omitBy, isUndefined } from '@golden/utils'
import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import EditAccountEmperorForm, {
  EditAccountEmperorFormType,
  initialForm
} from '../../../components/admin/account/EditAccountEmperorForm'
import LoadingAndErrorFrame from '../../../components/default/frames/LoadingAndErrorFrame'
import { useRedirectHandleBack, useDialogHandleSubmit, useGetDataByParams } from '../../../utils/default/ComplexFlowHook'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { usePageFlow } from '../../../utils/default/PageFlowHook'
import useGlobalDialog from '../../../providers/admin/dialog/useGlobalDialog'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import { createGlobalDialogConfig } from '../../../utils/default/DialogHelper'
import { Path } from '../../../components/admin/route/route'
import useT from '../../../i18ns/admin/useT'
import { convertEmptyToUndefined } from '../../../utils/default/FormHelper'

export const formToRequest = (form: EditAccountEmperorFormType): ChangeEmperorReq => {
  const converted = {
    nickname: form.nickname,
    password: convertEmptyToUndefined(form.password),
    password_confirmation: convertEmptyToUndefined(form.passwordConfirmation),
    security_password: convertEmptyToUndefined(form.securityPassword),
    security_password_confirmation: convertEmptyToUndefined(form.securityPasswordConfirmation),
    is_loginable: form.isLoginable === 1,
    percentage: Number(form.percentage),
    memo: form.memo
  } as ChangeEmperorReq
  return omitBy(converted, isUndefined) as ChangeEmperorReq
}

const AccountEmperorUpdatePage: React.FC = () => {
  const classes = useCommonStyles()
  const { t } = useT()
  const gdk = useGDK()
  const pageFlow = usePageFlow()
  const [defaultForm, setDefaultForm] = useState(initialForm())
  const [id, setId] = useState(0)
  const [isSetSecurityPassword, setIsSetSecurityPassword] = useState(false)

  useGetDataByParams({
    path: Path.ACCOUNT_EMPEROR_UPDATE,
    gdkFunc: (param: { id: string }) => gdk.emperor.getEmperor(Number(param.id)),
    gdkFuncDependencies: [gdk],
    onBeforeFetch: pageFlow.setLoadingStart,
    onSuccess: (res) => {
      setId(res.id)
      setDefaultForm(initialForm({
        account: res.account,
        nickname: res.nickname,
        isLoginable: res.is_loginable ? 1 : 2,
        percentage: res.percentage.toString(),
        memo: res.memo
      }))
      setIsSetSecurityPassword(res.is_security_password_set_up)
      pageFlow.setContentShow()
    },
    onError: pageFlow.setGDKError,
    canLoadData: id === 0
  })

  const globalDialog = useGlobalDialog()
  const [handleBack, handleDebouncedBack] = useRedirectHandleBack({ path: Path.ACCOUNT_EMPEROR })
  const { handleSubmit } = useDialogHandleSubmit({
    formToRequest,
    gdkFunc: (payload) => gdk.emperor.updateEmperor(payload, id),
    gdkFuncDependencies: [gdk, id],
    dialogId: 'updateEmperor',
    globalDialog,
    getChangeDialogConfig: useCallback((form: EditAccountEmperorFormType) => createGlobalDialogConfig({
      showIcon: false,
      message: (
        <React.Fragment>
          {t('dialog.confirmUpdateEmperorAccount')}
          <span className={classes.purpleWord}>{form.account}</span>
        </React.Fragment>
      )
    }), [t, classes.purpleWord]),
    getSuccessDialogConfig: useCallback(() => createGlobalDialogConfig({
      showIcon: false,
      message: t('dialog.updateEmperorAccountSuccess'),
      showCancel: false
    }), [t]),
    getFailDialogConfig: useCallback((error) => createGlobalDialogConfig({
      showIcon: true,
      variant: 'error',
      message: error,
      showCancel: false
    }), []),
    afterSuccessDialog: handleBack
  })
  return (
    <Box padding={5}>
      <Paper>
        <Box padding={4}>
          <Box
            paddingY={1.25}
            paddingX={2}
            className={classes.pinkTitleBar}
          >
            <Typography variant="h5">
              {t('common.updateEmperorAccount')}
            </Typography>
          </Box>
          <Box paddingY={2}>
            <LoadingAndErrorFrame { ...pageFlow.status } >
              <EditAccountEmperorForm
                disableAccount
                editSecurityPassword
                isSetSecurityPassword={isSetSecurityPassword}
                okText={t('common.confirmUpdate')}
                passwordText={t('common.updateLoginPassword')}
                onBack={handleDebouncedBack}
                onSubmit={handleSubmit}
                defaultValue={defaultForm}
                nickname={defaultForm.nickname}
                isUpdate
              />
            </LoadingAndErrorFrame>
          </Box>
        </Box>
      </Paper>
    </Box>
  )
}

export default React.memo(AccountEmperorUpdatePage)

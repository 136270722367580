import React, { useState, useContext, useMemo, useEffect } from 'react'
import { ActivityBetConditionType, ActivityDepositConditionType, ActivityDurationType, ActivityGameDurationType, ActivityLayoutReq, ActivityModuleType, ActivityLayoutBetConditionSportGameSetting, GameType, GameCategoryType } from '@golden/gdk-admin'
import { InitialFormFunc } from '../../../utils/default/ComplexFlowHook'
import useT from '../../../i18ns/admin/useT'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import MuiRadio, { RadioProps } from '@material-ui/core/Radio'
import MuiTextField, { TextFieldProps } from '@material-ui/core/TextField'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import AddIcon from '@material-ui/icons/Add'
import { makeStyles } from '@material-ui/core/styles'
import { getUnixTime } from 'date-fns'
import { ActivityManagementEditLayoutFormType, ActivityManagementFormContext } from './ActivityManagementEditLayoutForm'
import OnOffCheckbox, { PropTypes as CheckboxProps } from '../../../components/default/form/OnOffCheckbox'
import ActivityManagementTabs from './ActivityManagementTabs'
import { getCashInputProps, getValueFromCheckboxEvent, getValueFromValue } from '../../../utils/default/FormHelper'
import { ValueGetter } from '../../../utils/default/FormHook'
import FormField from '../../default/form/FormField'
import { activityDurationName, activityGameDurationName } from '../../../constants/admin/activityDurationName'
import DropDown, { PropTypes as DropDownProps } from '../../../components/default/form/DropDown'
import ActivityManagementEditLayoutSingleSettingForm, {
  initialForm as initialSingleForm,
  getValueFromEvent as singleGetValueFromEvent,
  SingleSettingFormType
} from './ActivityManagementEditLayoutSingleSettingForm'
import ActivityManagementEditLayoutGameSettingForm, {
  initialForm as initialGameForm,
  getValueFromEvent as gameGetValueFromEvent,
  GameSettingFormType
} from './ActivityManagementEditLayoutGameSettingForm'
import NumberInput from '../../../components/default/form/NumberInput'
import activityBetConditionTypeName from '../../../constants/admin/activityBetConditionTypeName'
import { useChecker } from '../../../utils/admin/AdminRouteHook'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import { isUndefined, omitBy, range } from '@golden/utils'
import { createTableData } from '../../../utils/default/TableHelper'
import CoreTable from '../../../components/default/present/CoreTable'
import activityDepositConditionTypeName from '../../../constants/admin/activityDepositConditionTypeName'
import GameIdInput, { PropTypes as GameIdProps } from '../GameIdInput'
import DateTimePicker, { PropTypes as TimePickerProps } from '../../default/form/DateTimePicker'

interface SportGamesRowType {
  id: number | null
  delete: React.ReactElement
  gameId: React.ReactElement
  tournamentId: React.ReactElement
  matchId: React.ReactElement
}

export type EditLayoutSettingFormType = {
  hasSetting: boolean
  moduleType: ActivityModuleType
}
& SingleSettingFormType
& GameSettingFormType

interface ErrorType {
  game_id: string
  tournament_id: string
  match_id: string
}

const useStyles = makeStyles((theme) => ({
  error: {
    color: theme.palette.error.main
  }
}))

export const initialForm: InitialFormFunc<EditLayoutSettingFormType> = (form) => ({
  hasSetting: false,
  moduleType: ActivityModuleType.SINGLE,
  ...initialSingleForm(form),
  ...initialGameForm(form),
  ...form
})

export const formToRequest = (form: EditLayoutSettingFormType): Partial<ActivityLayoutReq<number>> | undefined => {
  if (!form.hasSetting) return undefined
  const converted = {
    setting: form.moduleType === ActivityModuleType.SINGLE
      ? {
          type: form.moduleType,
          deposit_condition: form.singleHasDepositCondition
            ? {
                duration: form.singleDepositDuration,
                type: form.singleDepositType,
                threshold: Number(form.singleDepositThreshold || 0),
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                start_at: form.singleDepositDuration === ActivityDurationType.CUSTOM ? getUnixTime(form.singleDepositCustomStartAt!) : undefined,
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                end_at: form.singleDepositDuration === ActivityDurationType.CUSTOM ? getUnixTime(form.singleDepositCustomEndAt!) : undefined
              }
            : null,
          bet_condition: form.singleHasBetCondition
            ? {
                duration: form.singleBetDuration,
                type: form.singleBetType,
                threshold: Number(form.singleBetThreshold || 0),
                game_id: form.singleBetGameType === 'gameId' && form.singleBetGameIds.length > 0 ? form.singleBetGameIds : undefined,
                sport_games: form.singleBetGameType === 'sportGames' && form.singleBetSportGames.length > 0
                  ? form.singleBetSportGames.map((item) => ({
                    game_id: item.game_id,
                    tournament_id: item.tournament_id?.replace(/\s/g, ''),
                    match_id: item.match_id ? item.match_id.replace(/\s/g, '') : null
                  }))
                  : undefined,
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                start_at: form.singleBetDuration === ActivityDurationType.CUSTOM ? getUnixTime(form.singleBetCustomStartAt!) : undefined,
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                end_at: form.singleBetDuration === ActivityDurationType.CUSTOM ? getUnixTime(form.singleBetCustomEndAt!) : undefined
              }
            : null,
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          start_at: getUnixTime(form.singleStartAt!),
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          end_at: getUnixTime(form.singleEndAt!),
          frequency: form.singleFrequency === '-' ? undefined : form.singleFrequency
        }
      : {
          type: form.moduleType,
          deposit_condition: form.gameHasDepositCondition
            ? {
                duration: form.gameDepositDuration,
                type: form.gameDepositType,
                threshold: Number(form.gameDepositThreshold || 0)
              }
            : null,
          bet_condition: form.gameHasBetCondition
            ? {
                type: form.gameBetType,
                threshold: Number(form.gameBetThreshold || 0),
                game_id: form.gameBetGameType === 'gameId' && form.gameBetGameIds.length > 0 ? form.gameBetGameIds : undefined,
                sport_games: form.gameBetGameType === 'sportGames' && form.gameBetSportGames.length > 0
                  ? form.gameBetSportGames.map((item) => ({
                    game_id: item.game_id,
                    tournament_id: item.tournament_id?.replace(/\s/g, ''),
                    match_id: item.match_id ? item.match_id.replace(/\s/g, '') : null
                  }))
                  : undefined
              }
            : null,
          games: form.gameGames.map((game) => ({
            id: game.id,
            league: game.league,
            game: game.game,
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            game_start_at: getUnixTime(game.game_start_at!),
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            bet_start_at: form.gameHasBetCondition ? getUnixTime(game.bet_start_at!) : undefined,
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            bet_end_at: form.gameHasBetCondition ? getUnixTime(game.bet_end_at!) : undefined,
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            start_at: getUnixTime(game.start_at!),
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            end_at: getUnixTime(game.end_at!)
          }))
        }
  }
  return omitBy(converted, isUndefined)
}

export const getValueFromEvent: ValueGetter<EditLayoutSettingFormType> = {
  moduleType: getValueFromValue,
  hasSetting: getValueFromCheckboxEvent,
  ...singleGetValueFromEvent,
  ...gameGetValueFromEvent
}

const cashInputProps = {
  ...getCashInputProps(),
  decimalScale: 2
}

const TextField = React.memo(MuiTextField)
const Radio = React.memo(MuiRadio)

const SportGameTextField: React.FC<{
  value: string | null
  name: keyof ActivityLayoutBetConditionSportGameSetting
  index: number
  placeholder: string
  error: ErrorType[]
  setError: React.Dispatch<React.SetStateAction<ErrorType[]>>
  required: boolean
  disabled: boolean
  isSingle: boolean
}> = React.memo((props) => {
  const { value, name, index, placeholder, error, setError, disabled, required, isSingle } = props
  const { t } = useT()
  const { value: form, dispatch } = useContext(ActivityManagementFormContext)

  useEffect(() => {
    if ((isSingle && form.singleHasBetCondition && form.singleBetGameType === 'sportGames') || (!isSingle && form.gameHasBetCondition && form.gameBetGameType === 'sportGames')) {
      setError((error) => {
        return error.map((e, i) => i === index
          ? { ...e, [name]: required && !value?.trim() ? t('error.mustNotEmpty') : '' }
          : e
        )
      })
    } else {
      setError((error) => {
        return error.map((e, i) => i === index
          ? { ...e, [name]: '' }
          : e
        )
      })
    }
  }, [setError, t, isSingle, form.singleBetGameType, form.singleHasBetCondition, form.gameBetGameType, form.gameHasBetCondition, index, name, required, value])

  return (
    <TextField
      value={value}
      fullWidth
      placeholder={placeholder}
      onChange={(event) => {
        const value = event.target.value
        dispatch({
          type: 'change',
          label: (isSingle ? 'singleBetSportGames' : 'gameBetSportGames'),
          value: (isSingle
            ? form.singleBetSportGames.map((game, i) => index === i ? ({ ...game, [name]: value }) : game)
            : form.gameBetSportGames.map((game, i) => index === i ? ({ ...game, [name]: value }) : game)
          )
        })
        setError(error.map((e, i) => i === index
          ? { ...e, [name]: required && !value?.trim() ? t('error.mustNotEmpty') : '' }
          : e
        ))
      }}
      error={!!error[index][name as keyof ErrorType]}
      helperText={error[index][name as keyof ErrorType]}
      inputProps={{ maxLength: 50 }}
      disabled={disabled}
    />
  )
})

const SportGameDropDown: React.FC<{
  value: GameType | null
  index: number
  error: ErrorType[]
  setError: React.Dispatch<React.SetStateAction<ErrorType[]>>
  disabled: boolean
  isSingle: boolean
}> = React.memo((props) => {
  const { value, index, error, setError, disabled, isSingle } = props
  const { t } = useT()
  const { value: form, dispatch } = useContext(ActivityManagementFormContext)

  useEffect(() => {
    if ((isSingle && form.singleHasBetCondition && form.singleBetGameType === 'sportGames') || (!isSingle && form.gameHasBetCondition && form.gameBetGameType === 'sportGames')) {
      setError((error) => {
        return error.map((e, i) => i === index
          ? { ...e, game_id: !value ? t('error.mustNotEmpty') : '' }
          : e
        )
      })
    } else {
      setError((error) => {
        return error.map((e, i) => i === index
          ? { ...e, game_id: '' }
          : e
        )
      })
    }
  }, [setError, t, isSingle, form.singleBetGameType, form.singleHasBetCondition, form.gameBetGameType, form.gameHasBetCondition, index, value])

  return (
    <GameIdInput
      value={value ?? undefined}
      hideLabel
      fullWidth
      placeholder={t('placeholder.pleaseChoose', { item: t('common.gamePlatform') })}
      category={GameCategoryType.SPORT_LOTTERY}
      onChange={(value) => {
        dispatch({
          type: 'change',
          label: (isSingle ? 'singleBetSportGames' : 'gameBetSportGames'),
          value: (isSingle
            ? form.singleBetSportGames.map((game, i) => index === i ? ({ ...game, game_id: value as GameType }) : game)
            : form.gameBetSportGames.map((game, i) => index === i ? ({ ...game, game_id: value as GameType }) : game)
          )
        })
        setError(error.map((e, i) => i === index
          ? { ...e, game_id: !value ? t('error.mustNotEmpty') : '' }
          : e
        ))
      }}
      error={!!error[index]?.game_id}
      helperText={error[index]?.game_id}
      disabled={disabled}
      active
      noAll
    />
  )
})

const SportGameDeleteButton: React.FC<{
  index: number
  error: ErrorType[]
  setError: (value: ErrorType[]) => void
  isSingle: boolean
}> = React.memo((props) => {
  const { index, error, setError, isSingle } = props
  const { value: form, dispatch } = useContext(ActivityManagementFormContext)
  return (
    <IconButton
      size="small"
      onClick={() => {
        dispatch({
          type: 'change',
          label: (isSingle ? 'singleBetSportGames' : 'gameBetSportGames'),
          value: (isSingle
            ? form.singleBetSportGames.filter((_, i) => i !== index)
            : form.gameBetSportGames.filter((_, i) => i !== index)
          )
        })
        setError(error.filter((_, i) => i !== index))
      }}
    >
      <CloseIcon fontSize="small" />
    </IconButton>
  )
})

const SportGameAddButton: React.FC<{
  error: ErrorType[]
  setError: React.Dispatch<React.SetStateAction<ErrorType[]>>
  isSingle: boolean
}> = React.memo((props) => {
  const { error, setError, isSingle } = props
  const { value: form, dispatch } = useContext(ActivityManagementFormContext)
  return (
    <IconButton
      onClick={() => {
        const defaultGame = { game_id: '', tournament_id: '', match_id: '' }
        dispatch({
          type: 'change',
          label: (isSingle ? 'singleBetSportGames' : 'gameBetSportGames'),
          value: [...(isSingle ? form.singleBetSportGames : form.gameBetSportGames), defaultGame]
        })
        setError(error.concat({ game_id: '', tournament_id: '', match_id: '' }))
      }}
    >
      <AddIcon fontSize="small" />
    </IconButton>
  )
})

const SportGamesTable: React.FC = React.memo(() => {
  const { t } = useT()
  const { value: form } = useContext(ActivityManagementFormContext)
  const writable = useChecker()
  const commonClasses = useCommonStyles()
  const tableClasses = useMemo(() => ({
    head: commonClasses.pinkTableHead,
    row: commonClasses.tableRow,
    cellHead: commonClasses.tableCellHead,
    cellBody: commonClasses.pre
  }), [commonClasses])

  const isSingle = useMemo(() => form.moduleType === ActivityModuleType.SINGLE, [form.moduleType])
  const defaultErrorLength = useMemo(() => (isSingle ? form.singleBetSportGames.length : form.gameBetSportGames.length), [form.gameBetSportGames.length, form.singleBetSportGames.length, isSingle])
  const [error, setError] = useState<ErrorType[]>(range(0, defaultErrorLength).map(() => ({ game_id: '', tournament_id: '', match_id: '' })))
  const disabled = useMemo(() => (isSingle && (!form.singleHasBetCondition || form.singleBetGameType !== 'sportGames')) || (!isSingle && (!form.gameHasBetCondition || form.gameBetGameType !== 'sportGames')), [form.singleHasBetCondition, form.gameHasBetCondition, form.singleBetGameType, form.gameBetGameType, isSingle])

  const rows: SportGamesRowType[] = useMemo(() => {
    return (isSingle ? form.singleBetSportGames : form.gameBetSportGames).map((item, index) => {
      return {
        id: index,
        delete: (
          <SportGameDeleteButton
            index={index}
            error={error}
            setError={setError}
            isSingle={isSingle}
          />
        ),
        gameId: (
          <SportGameDropDown
            index={index}
            value={item.game_id}
            error={error}
            setError={setError}
            disabled={!writable || disabled}
            isSingle={isSingle}
          />
        ),
        tournamentId: (
          <SportGameTextField
            name="tournament_id"
            required
            index={index}
            value={item.tournament_id}
            placeholder={t('placeholder.pleaseInput', { item: t('common.tournamentId') })}
            error={error}
            setError={setError}
            disabled={!writable || disabled}
            isSingle={isSingle}
          />
        ),
        matchId: (
          <SportGameTextField
            name="match_id"
            index={index}
            value={item.match_id ?? null}
            required={false}
            placeholder={t('placeholder.pleaseInput', { item: t('common.matchId') })}
            error={error}
            setError={setError}
            disabled={!writable || disabled}
            isSingle={isSingle}
          />
        )
      }
    })
  }, [error, isSingle, form.singleBetGameType, form.singleBetSportGames, form.singleHasBetCondition, form.gameBetGameType, form.gameBetSportGames, form.gameHasBetCondition, t, writable])
  const data = useMemo(() => {
    const order: Array<keyof SportGamesRowType> = [
      'gameId',
      'tournamentId',
      'matchId'
    ]
    return createTableData<SportGamesRowType>(
      {
        id: {
          label: '',
          value: 'id'
        },
        delete: {
          label: '',
          value: 'delete',
          align: 'center',
          width: 10
        },
        gameId: {
          label: `${t('common.gamePlatform')}*`,
          value: 'gameId',
          align: 'center',
          width: 200
        },
        tournamentId: {
          label: `${t('common.tournamentId')}*`,
          value: 'tournamentId',
          align: 'center',
          width: 200
        },
        matchId: {
          label: `${t('common.matchId')}`,
          value: 'matchId',
          align: 'center',
          width: 200
        }
      },
      writable && ((!isSingle && form.gameBetSportGames.length > 1 && form.gameBetGameType === 'sportGames' && form.gameHasBetCondition) || (isSingle && form.singleBetSportGames.length > 1 && form.singleBetGameType === 'sportGames' && form.singleHasBetCondition)) ? ['delete', ...order] : order,
      rows,
      'index'
    )
  }, [t, form.singleBetSportGames.length, form.singleBetGameType, form.singleHasBetCondition, form.gameBetSportGames.length, form.gameBetGameType, form.gameHasBetCondition, writable, rows])

  return (<>
    <CoreTable
      classes={tableClasses}
      data={data}
      total={rows.length}
    />
    { writable && ((isSingle && form.singleBetGameType === 'sportGames' && form.singleHasBetCondition) || (!isSingle && form.gameBetGameType === 'sportGames' && form.gameHasBetCondition)) && (<SportGameAddButton
      error={error}
      setError={setError}
      isSingle={isSingle}
    />) }
  </>)
})

const SingleDurationCustomDateInput: React.FC<{
  mode: 'deposit' | 'bet'
}> = React.memo((props) => {
  const { mode } = props
  const { t } = useT()
  const writable = useChecker()
  return (
    <Grid item container direction="row" spacing={2} md={6} xl={5}>
      <Grid item xs={12} md={6}>
        <FormField<ActivityManagementEditLayoutFormType, TimePickerProps>
          component={DateTimePicker}
          context={ActivityManagementFormContext}
          name={mode === 'deposit' ? 'singleDepositCustomStartAt' : 'singleBetCustomStartAt'}
          placeholder={t('placeholder.inputBeginAt')}
          required
          disabled={!writable}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <FormField<ActivityManagementEditLayoutFormType, TimePickerProps>
          component={DateTimePicker}
          context={ActivityManagementFormContext}
          name={mode === 'deposit' ? 'singleDepositCustomEndAt' : 'singleBetCustomEndAt'}
          placeholder={t('placeholder.inputEndAt')}
          required
          disabled={!writable}
        />
      </Grid>
    </Grid>
  )
})

export const DepositConditionInput: React.FC = React.memo(() => {
  const { t } = useT()
  const { value: form, error } = useContext(ActivityManagementFormContext)
  const writable = useChecker()

  const isSingle = useMemo(() => form.moduleType === ActivityModuleType.SINGLE, [form.moduleType])

  const durationOptions = useMemo(() => {
    const options = isSingle
      ? Object.keys(activityDurationName).map((type) => ({ value: type, name: t(activityDurationName[type as ActivityDurationType]) }))
      : Object.keys(activityGameDurationName).map((type) => ({ value: type, name: t(activityGameDurationName[type as ActivityGameDurationType]) }))
    return [{ value: '-', name: t('common.pleaseChoose') }, ...options]
  }, [isSingle, t])

  const typeOptions = useMemo(() => Object.keys(activityDepositConditionTypeName).map((type) => ({ value: type, name: t(activityDepositConditionTypeName[type as ActivityDepositConditionType]) })), [t])

  const value = useMemo(() => isSingle ? form.singleHasDepositCondition : form.gameHasDepositCondition, [isSingle, form.singleHasDepositCondition, form.gameHasDepositCondition])

  const errorDepositThreshold = useMemo(() => {
    if (!form.hasSetting) return ''
    if (isSingle) {
      if (!form.singleHasDepositCondition) return ''
      if (!form.singleDepositThreshold) return t('error.mustNotEmpty')
      if (Number(form.singleDepositThreshold) === 0) return t('error.mustMoreThanZero')
      return ''
    }
    if (!form.gameHasDepositCondition) return ''
    if (!form.gameDepositThreshold) return t('error.mustNotEmpty')
    if (Number(form.gameDepositThreshold) === 0) return t('error.mustMoreThanZero')
    return ''
  }, [form.hasSetting, form.gameHasDepositCondition, form.gameDepositThreshold, form.singleHasDepositCondition, form.singleDepositThreshold, isSingle, t])

  const depositTypeHelperText = useMemo(() => {
    if (!form.hasSetting) return ''
    if (isSingle) {
      if (!form.singleHasDepositCondition) return ''
      return form.singleDepositType === ActivityDepositConditionType.SINGLE ? t('common.singleDepositTip') : t('common.accumulatedDepositTip')
    } else {
      if (!form.gameHasDepositCondition) return ''
      return form.gameDepositType === ActivityDepositConditionType.SINGLE ? t('common.singleDepositTip') : t('common.accumulatedDepositTip')
    }
  }, [form.hasSetting, form.singleHasDepositCondition, form.singleDepositType, form.gameHasDepositCondition, form.gameDepositType, isSingle, t])
  const disabled = useMemo(() => !writable || (form.isUpdate && isSingle), [form.isUpdate, isSingle, writable])

  return (
    <Grid item container alignItems="center" spacing={1}>
      <Grid item container alignItems="center">
        <Grid item container alignItems="center" md={3}>
          <FormField<ActivityManagementEditLayoutFormType, CheckboxProps>
            context={ActivityManagementFormContext}
            component={OnOffCheckbox}
            label={t('activityConditionType.deposit')}
            disabled={disabled}
            name={isSingle ? 'singleHasDepositCondition' : 'gameHasDepositCondition'}
          />
          <Box flex={1}>
            <FormField<ActivityManagementEditLayoutFormType, DropDownProps>
              hideLabel
              fullWidth
              context={ActivityManagementFormContext}
              component={DropDown}
              name={isSingle ? 'singleDepositDuration' : 'gameDepositDuration'}
              options={durationOptions}
              disabled={!value || disabled}
              error={isSingle ? !!error.singleDepositDuration : !!error.gameDepositDuration}
              helperText={(isSingle ? error.singleDepositDuration : error.gameDepositDuration) ?? ''}
            />
          </Box>
        </Grid>
        {(isSingle && form.singleDepositDuration === ActivityDurationType.CUSTOM) && (<SingleDurationCustomDateInput mode='deposit' />)}
        <Grid item container alignItems="center" md={9} xl={4}>
          <Box flex={1} marginLeft={2}>
            <FormField<ActivityManagementEditLayoutFormType, DropDownProps>
              hideLabel
              fullWidth
              context={ActivityManagementFormContext}
              component={DropDown}
              name={isSingle ? 'singleDepositType' : 'gameDepositType'}
              options={typeOptions}
              disabled={!writable || !value || form.isUpdate}
              helperText={depositTypeHelperText}
            />
          </Box>
          <Box paddingX={2} style={{ color: !value ? 'rgba(0, 0, 0, 0.38)' : '' }}>
            { `${t('common.moreThanAndEqualTo')}` }
          </Box>
          <Box flex={2}>
            <FormField<ActivityManagementEditLayoutFormType, TextFieldProps>
              fullWidth
              context={ActivityManagementFormContext}
              component={NumberInput}
              name={isSingle ? 'singleDepositThreshold' : 'gameDepositThreshold'}
              placeholder={t('common.cash')}
              disabled={!writable || !value}
              inputProps={cashInputProps}
              error={!!errorDepositThreshold}
              helperText={errorDepositThreshold}
            />
          </Box>
        </Grid>
      </Grid>
    </Grid>
  )
})

export const BetConditionInput: React.FC = React.memo(() => {
  const { t } = useT()
  const { value: form, error } = useContext(ActivityManagementFormContext)
  const writable = useChecker()

  const isSingle = useMemo(() => form.moduleType === ActivityModuleType.SINGLE, [form.moduleType])

  const durationOptions = useMemo(() => {
    const options = Object.keys(activityDurationName).map((type) => ({ value: type, name: t(activityDurationName[type as ActivityDurationType]) }))
    return [{ value: '-', name: t('common.pleaseChoose') }, ...options]
  }, [t])
  const typeOptions = useMemo(() => Object.keys(activityBetConditionTypeName).map((type) => ({ value: type, name: t(activityBetConditionTypeName[type as ActivityBetConditionType]) })), [t])

  const value = useMemo(() => isSingle ? form.singleHasBetCondition : form.gameHasBetCondition, [isSingle, form.singleHasBetCondition, form.gameHasBetCondition])
  const disabled = useMemo(() => !writable || (form.isUpdate && isSingle), [form.isUpdate, isSingle, writable])
  const styles = useStyles()

  return (
    <Grid item container alignItems="center">
      <Grid item container alignItems="center">
        <Grid item container alignItems="center" md={3}>
          <FormField<ActivityManagementEditLayoutFormType, CheckboxProps>
            context={ActivityManagementFormContext}
            component={OnOffCheckbox}
            label={t('activityConditionType.bet')}
            disabled={disabled}
            name={isSingle ? 'singleHasBetCondition' : 'gameHasBetCondition'}
          />
          <Box flex={1}>
            { isSingle
              ? (<FormField<ActivityManagementEditLayoutFormType, DropDownProps>
                hideLabel
                fullWidth
                context={ActivityManagementFormContext}
                component={DropDown}
                name="singleBetDuration"
                options={durationOptions}
                disabled={!value || disabled}
                error={!!error.singleBetDuration}
                helperText={error.singleBetDuration ?? ''}
              />)
              : t('common.duringBetTime')
            }
          </Box>
        </Grid>
        {(isSingle && form.singleBetDuration === ActivityDurationType.CUSTOM) && (<SingleDurationCustomDateInput mode='bet' />)}
        <Grid item container alignItems="center" md={9} xl={4}>
          <Box flex={1} marginLeft={2}>
            <FormField<ActivityManagementEditLayoutFormType, DropDownProps>
              hideLabel
              fullWidth
              context={ActivityManagementFormContext}
              component={DropDown}
              name={isSingle ? 'singleBetType' : 'gameBetType'}
              options={typeOptions}
              disabled={!writable || !value}
            />
          </Box>
          <Box paddingX={2} style={{ color: !value ? 'rgba(0, 0, 0, 0.38)' : '' }}>
            { t('common.moreThanAndEqualTo') }
          </Box>
          <Box flex={2} flexShrink={0}>
            <FormField<ActivityManagementEditLayoutFormType, TextFieldProps>
              fullWidth
              context={ActivityManagementFormContext}
              component={NumberInput}
              name={isSingle ? 'singleBetThreshold' : 'gameBetThreshold'}
              placeholder={t('common.cash')}
              disabled={!writable || !value}
              inputProps={cashInputProps}
            />
          </Box>
        </Grid>
        <Grid item container alignItems="center" xs={12}>
          <Box>
            <FormField<ActivityManagementEditLayoutFormType, RadioProps>
              component={Radio}
              className={((isSingle && form.singleBetGameType === null) || (!isSingle && form.gameBetGameType === null)) ? styles.error : ''}
              name={isSingle ? 'singleBetGameType' : 'gameBetGameType'}
              disabled={!writable || !value}
              checked={(isSingle && form.singleBetGameType === 'gameId') || (!isSingle && form.gameBetGameType === 'gameId')}
              context={ActivityManagementFormContext}
              value={'gameId'}
            />
          </Box>
          <Box flex={1} marginLeft={1}>
            <FormField<ActivityManagementEditLayoutFormType, GameIdProps>
              context={ActivityManagementFormContext}
              component={GameIdInput}
              name={isSingle ? 'singleBetGameIds' : 'gameBetGameIds'}
              fullWidth
              disabled={!writable || !value || (isSingle && form.singleBetGameType !== 'gameId') || (!isSingle && form.gameBetGameType !== 'gameId')}
              multiple
              active
            />
          </Box>
        </Grid>
        <Grid item container xs={12}>
          <Box>
            <FormField<ActivityManagementEditLayoutFormType, RadioProps>
              component={Radio}
              className={((isSingle && form.singleBetGameType === null) || (!isSingle && form.gameBetGameType === null)) ? styles.error : ''}
              name={isSingle ? 'singleBetGameType' : 'gameBetGameType'}
              disabled={!writable || !value}
              checked={(isSingle && form.singleBetGameType === 'sportGames') || (!isSingle && form.gameBetGameType === 'sportGames')}
              context={ActivityManagementFormContext}
              value={'sportGames'}
            />
          </Box>
          <Box flex={1} marginLeft={1}>
            <SportGamesTable />
          </Box>
        </Grid>
      </Grid>
    </Grid>
  )
})

const ActivityManagementEditLayoutSettingForm: React.FC = () => {
  const { value: form, dispatch } = useContext(ActivityManagementFormContext)
  const { t } = useT()

  const tabs = useMemo(() => [
    { value: ActivityModuleType.SINGLE, label: t('common.singleActivity') },
    { value: ActivityModuleType.GAME, label: t('common.game2') }
  ].filter((tab) => !form.isUpdate || tab.value === form.moduleType), [form.isUpdate, form.moduleType, t])

  return (
    <>
      <FormField<ActivityManagementEditLayoutFormType, CheckboxProps>
        context={ActivityManagementFormContext}
        component={OnOffCheckbox}
        name="hasSetting"
        label={t('common.applyModule')}
        disabled={form.isUpdate}
      />
      { form.hasSetting && (<Box>
        <ActivityManagementTabs
          value={form.moduleType}
          tabs={tabs}
          onChange={(value) => dispatch({ type: 'change', label: 'moduleType', value })}
        >
          <Box padding={2} style={{ backgroundColor: '#fff' }}>
            { form.moduleType === ActivityModuleType.SINGLE && <ActivityManagementEditLayoutSingleSettingForm /> }
            { form.moduleType === ActivityModuleType.GAME && <ActivityManagementEditLayoutGameSettingForm /> }
          </Box>
        </ActivityManagementTabs>
      </Box>) }
    </>
  )
}

export default React.memo(ActivityManagementEditLayoutSettingForm)

import React, { createContext, useMemo, useContext, useCallback, useState } from 'react'
import clsx from 'clsx'
import { useDebouncedCallback } from 'use-debounce/lib'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import MuiButton from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import MuiTextField, { TextFieldProps } from '@material-ui/core/TextField'
import { useCommonStyles, useDialogStyles } from '../../../utils/admin/StyleHook'
import useT from '../../../i18ns/admin/useT'
import { InitialFormFunc, useRedirectHandleBack, useGetDataByPayload } from '../../../utils/default/ComplexFlowHook'
import { ValueGetter, createDefaultFormState, FormValidation } from '../../../utils/default/FormHook'
import { getValueFromChangeEvent, getCashInputProps } from '../../../utils/default/FormHelper'
import FormStateProvider from '../../../components/default/form/FormStateProvider'
import RequiredText from '../../../components/default/form/RequiredText'
import FormField from '../../../components/default/form/FormField'
import allRoute, { Path } from '../../../components/admin/route/route'
import { createGlobalDialogConfig, OpenPayload, GloablDialogConfig } from '../../../utils/default/DialogHelper'
import { PaginationRes, RankEffectiveBetRecord, RankEffectiveBetSummary } from '@golden/gdk-admin'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { parsePath } from '../../../utils/default/RouteHelper'
import { usePageFlow } from '../../../utils/default/PageFlowHook'
import LoadingAndErrorFrame from '../../../components/default/frames/LoadingAndErrorFrame'
import { createValidateNotEmpty, createValidateCash } from '../../../utils/default/Validator'
import { formatMoney } from '../../../utils/default/TableHelper'
import CoreDialog from '../../../components/default/dialogs/CoreDialog'
import InputLabel from '@material-ui/core/InputLabel'
import NumberInput from '../../../components/default/form/NumberInput'
import FormHelperText from '@material-ui/core/FormHelperText'

export interface FormType {
  adjustment: string
  note: string
}

export const initialForm: InitialFormFunc<FormType> = (defaultForm) => ({
  adjustment: '',
  note: '',
  ...defaultForm
})

const defaultForm = initialForm()

const getValueFromEvent: ValueGetter<FormType> = {
  adjustment: getValueFromChangeEvent,
  note: getValueFromChangeEvent
}

const memoFormHelperTextProps = { error: true }

const cashInputProps = {
  ...getCashInputProps(),
  decimalScale: 4,
  maxLength: undefined
}

const FormContext = createContext(createDefaultFormState(initialForm()))

const TextField = React.memo(MuiTextField)

const Button = React.memo(MuiButton)

const AdjustmentInput: React.FC = React.memo(() => {
  const { t } = useT()
  const { value, handleChange, handleOther, error } = useContext(FormContext)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onChange = useCallback(handleChange('adjustment'), [])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onBlur = useCallback(handleOther('blur', 'adjustment'), [])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onFocus = useCallback(handleOther('focus', 'adjustment'), [])
  return (
    <Box position="static">
      <Box>
        <InputLabel shrink required>{t('common.adjustRankEffectiveBet')}</InputLabel>
      </Box>
      <Box display="flex" alignItems="flex-end">
        <Box display="flex" paddingX={1}>{t('common.deduct')}</Box>
        <Box display="flex" flexGrow={1}>
          <NumberInput
            fullWidth
            required
            inputProps={cashInputProps}
            value={value.adjustment}
            onChange={onChange}
            onBlur={onBlur}
            onFocus={onFocus}
            error={error.adjustment !== null}
          />
        </Box>
      </Box>
      <FormHelperText error={error.adjustment !== null}>{error.adjustment ?? ''}</FormHelperText>
    </Box>
  )
})

const SubmitButton: React.FC<{
  userId: number
  account: string
  onBack: () => void
}> = React.memo((props) => {
  const { userId, account, onBack } = props
  const gdk = useGDK()
  const commonClasses = useCommonStyles()
  const dialogClasses = useDialogStyles()
  const [loading, setLoading] = useState<boolean>(false)
  const [open, setOpen] = useState<OpenPayload>({ id: '', value: false, isOK: false })
  const [config, setConfig] = useState<GloablDialogConfig>(createGlobalDialogConfig())
  const { t } = useT()
  const { value, error, disableSubmit } = useContext(FormContext)
  const [handleDebouncedSubmit] = useDebouncedCallback(useCallback(() => {
    setConfig(createGlobalDialogConfig({
      showIcon: false,
      notUseTypo: true,
      message: (
        <React.Fragment>
          <Typography className={dialogClasses.text} align="center">{t('dialog.confirmUpdateRankEffectiveBet')}</Typography>
          <Typography className={clsx(commonClasses.purpleWord, dialogClasses.text)} align="center">
            [{account}] [{t('common.deduct')}] [{value.adjustment}]
          </Typography>
        </React.Fragment>
      )
    }))
    setOpen({ id: 'send', value: true, isOK: false })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, dialogClasses, value]), 200)
  const [handleDebouncedOK] = useDebouncedCallback(useCallback(() => {
    if (open.id === 'send') {
      setOpen({ id: 'send', value: false, isOK: true })
      gdk.player.deductRankEffectiveBet(userId, { adjustment: Number(value.adjustment), note: value.note }).subscribe({
        next: () => {
          setLoading(false)
          setConfig(createGlobalDialogConfig({
            showIcon: false,
            showOK: false,
            notUseTypo: true,
            message: (
              <Box whiteSpace="pre">
                <Typography className={dialogClasses.text} align="center">
                  {t('dialog.updateRankEffectiveBetSuccess')}
                </Typography>
              </Box>
            ),
            cancelText: t('common.confirm')
          }))
          setOpen({ id: 'success', value: true, isOK: false })
        },
        error: (error) => {
          setLoading(false)
          setConfig(createGlobalDialogConfig({
            showIcon: true,
            variant: 'error',
            message: error.message,
            showCancel: false
          }))
          setOpen({ id: 'error', value: true, isOK: false })
        }
      })
    }
    if (open.id === 'success') {
      setLoading(false)
    }
    if (open.id === 'error') {
      setLoading(false)
      setOpen({ id: 'error', value: false, isOK: true })
    }
  }, [dialogClasses, gdk, open.id, t, value, userId]), 200)
  const handleOKWithLoading = () => {
    setLoading(true)
    handleDebouncedOK()
  }
  const [handleDebouncedCancel] = useDebouncedCallback(useCallback(() => {
    if (open.id === 'send') setOpen({ id: 'send', value: false, isOK: false })
    if (open.id === 'success') onBack()
  }, [open, onBack]), 200)
  return (
    <React.Fragment>
      <Button
        className={commonClasses.purpleGradualButton}
        disabled={disableSubmit || Object.values(error).some((item) => item !== null)}
        onClick={handleDebouncedSubmit}
      >
        {t('common.confirmUpdate')}
      </Button>
      <CoreDialog
        notUseTypo={config.notUseTypo}
        showOK={config.showOK}
        okText={config.okText}
        showCancel={config.showCancel}
        cancelText={config.cancelText}
        showIcon={config.showIcon}
        open={open.value}
        variant={config.variant}
        message={config.message}
        loading={loading}
        onOK={handleOKWithLoading}
        onCancel={handleDebouncedCancel}
        okButtonClasses={{
          root: dialogClasses.okButton
        }}
        cancelButtonClasses={{
          root: open.id === 'success' ? dialogClasses.okButton : dialogClasses.cancelButton
        }}
      />
    </React.Fragment>
  )
})

export const formToRequest = (form: FormType) => ({
  ...form
})

const PlayerRankEffectiveBetUpdatePage: React.FC = () => {
  const commonClasses = useCommonStyles()
  const gdk = useGDK()
  const { t } = useT()
  const [data, setData] = useState({
    userId: 0,
    account: '',
    adjustable: '0.0000',
    adjustment: 0,
    message: '',
    note: ''
  })
  const { param, search } = parsePath(window.location.search, window.location.pathname, Path.PLAYER_RANK_EFFECTIVE_BET_UPDATE)
  const account = useMemo(() => {
    return param.account as string
  }, [param])
  const pageFlow = usePageFlow()
  const payload = useMemo(() => ({
    account,
    types: [],
    page: 1
  }), [account])
  useGetDataByPayload({
    payload,
    gdkFunc: (payload) => gdk.player.getRankEffectiveBetRecords(payload),
    gdkFuncDependencies: [gdk],
    onBeforeFetch: pageFlow.setLoadingStart,
    onSuccess: (res: PaginationRes<RankEffectiveBetRecord[]> & RankEffectiveBetSummary) => {
      setData({
        ...data,
        userId: res.user_id,
        account: res.account,
        adjustable: Number(res.required_recover_amount) > 0
          ? res.reached_recover_amount
          : res.rank_effective_bet,
        message: Number(res.required_recover_amount) > 0
          ? t('common.reachedRankEffectiveBet')
          : t('common.accumulatedRankEffectiveBet')
      })
      pageFlow.setContentShow()
    },
    onError: pageFlow.setGDKError
  })

  const validation = useMemo(() => {
    return {
      adjustment: [
        {
          func: createValidateNotEmpty('adjustment', t),
          when: ['change', 'beforeClickSubmit']
        },
        {
          func: createValidateCash('adjustment', t),
          when: ['change', 'beforeClickSubmit']
        }
      ],
      note: [
        {
          func: createValidateNotEmpty('note', t),
          when: ['change', 'beforeClickSubmit']
        }
      ]
    } as FormValidation<FormType>
  }, [t])
  const [handleBack, handleDebouncedBack] = useRedirectHandleBack({
    path: allRoute.playerRankEffectiveBet.encodePath({
      search,
      param: {}
    })
  })
  const memoInputProps = useMemo(() => ({
    classes: { input: commonClasses.memoField },
    inputProps: {
      placeholder: `${t('placeholder.inputMemo')}*`
    }
  }), [commonClasses.memoField, t])
  return (
    <FormStateProvider
      context={FormContext}
      defaultValue={defaultForm}
      onSubmit={(form) => form}
      getValueFromEvent={getValueFromEvent}
      validation={validation}
    >
      <Box padding={5}>
        <Paper>
          <Box padding={4}>
            <Box
              paddingY={1.25}
              paddingX={2}
              className={commonClasses.pinkTitleBar}
            >
              <Typography variant="h5">
                {t('page.rankEffectiveBetUpdate')}
              </Typography>
            </Box>
            <Box paddingY={2}>
              <LoadingAndErrorFrame { ...pageFlow.status } >
                <Grid container direction="column" spacing={3}>
                  <Grid item>
                    <Grid container direction="row" spacing={2}>
                      <Grid item xs={12} md={6} lg={3}>
                        <TextField
                          disabled
                          fullWidth
                          label={t('common.playerAccount')}
                          value={data.account}
                        />
                      </Grid>
                      <Grid item xs={12} md={6} lg={3}>
                        <TextField
                          disabled
                          fullWidth
                          label={t('common.adjustableRankEffectiveBet')}
                          value={formatMoney(data.adjustable)}
                          helperText={data.message}
                        />
                      </Grid>
                      <Grid item xs={12} md={6} lg={3}>
                        <AdjustmentInput />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <FormField<FormType, TextFieldProps>
                      context={FormContext}
                      component={TextField}
                      name="note"
                      multiline
                      fullWidth
                      type="text"
                      margin="normal"
                      variant="outlined"
                      InputProps={memoInputProps}
                      FormHelperTextProps={memoFormHelperTextProps}
                    />
                  </Grid>
                  <Grid item>
                    <RequiredText />
                  </Grid>
                </Grid>
              </LoadingAndErrorFrame>
            </Box>
          </Box>
        </Paper>
        <Box marginTop={2}>
          <Grid container direction="row" justify="flex-end" spacing={2}>
            <Grid item>
              <Button
                className={commonClasses.greyButton}
                onClick={handleDebouncedBack}
              >
                {t('common.cancel')}
              </Button>
            </Grid>
            <Grid item>
              <SubmitButton
                userId={data.userId}
                account={data.account}
                onBack={handleBack}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </FormStateProvider>
  )
}

export default React.memo(PlayerRankEffectiveBetUpdatePage)

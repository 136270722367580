import { PlayerStatusType } from '@golden/gdk-admin'
import TKeyType from '../../i18ns/admin/TKeyType'

const playerStatusName: { [key in PlayerStatusType]: TKeyType } = {
  [PlayerStatusType.NORMAL]: 'userStatus.hasBindCard',
  [PlayerStatusType.SUSPENDED]: 'userStatus.suspended',
  [PlayerStatusType.NO_CARD]: 'userStatus.noBindCard'
}

export default playerStatusName

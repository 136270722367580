import React, { createContext, useCallback, useContext, useMemo, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import MuiButton from '@material-ui/core/Button'
import MuiTextField, { TextFieldProps } from '@material-ui/core/TextField'
import DropDown, { PropTypes as DropDownProps } from '../../default/form/DropDown'
import NumberInput from '../../default/form/NumberInput'
import EmperorFormStateProvider from './EmperorFormStateProvider'
import FormField from '../../default/form/FormField'
import FormSubmitButton from '../../default/form/FormSubmitButton'
import RequiredText from '../../default/form/RequiredText'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import { InitialFormFunc, useGetData } from '../../../utils/default/ComplexFlowHook'
import { createDefaultFormState } from '../../../utils/default/FormHook'
import { FormPropType } from '../../../utils/default/FormHelper'
import useT from '../../../i18ns/admin/useT'

export interface EditAccountEmperorFormType {
  account: string
  nickname: string
  password: string
  passwordConfirmation: string
  securityPassword: string
  securityPasswordConfirmation: string
  isLoginable: number
  percentage: string
  memo: string
}

export const initialForm: InitialFormFunc<EditAccountEmperorFormType> = (defaultForm) => ({
  account: '',
  nickname: '',
  password: '',
  passwordConfirmation: '',
  securityPassword: '',
  securityPasswordConfirmation: '',
  isLoginable: 1,
  percentage: '',
  memo: '',
  ...defaultForm
})

interface PropTypes extends FormPropType<EditAccountEmperorFormType> {
  nickname?: string
  disableAccount?: boolean
  requirePassword?: boolean
  editSecurityPassword?: boolean
  isSetSecurityPassword?: boolean
  okText: string
  passwordText: string
  isUpdate?: true
  onBack: () => void
}

const nicknameInputProps = { maxLength: 10 }

const passwordInputProps = { maxLength: 255 }

const memoFormHelperTextProps = { error: true }

const FormContext = createContext(createDefaultFormState(initialForm()))

const TextField = React.memo(MuiTextField)

const Button = React.memo(MuiButton)

const AccountDropDown: React.FC<{ disableAccount: boolean | undefined }> = React.memo((props) => {
  const { disableAccount } = props
  const { t } = useT()
  const gdk = useGDK()
  const { value, handleChange, handleOther, error, disabled } = useContext(FormContext)
  const [validInputs, setValidInputs] = useState({ accounts: [] as string[] })
  useGetData({
    gdkFunc: () => gdk.emperor.getSuggestAccount(),
    gdkFuncDependencies: [gdk],
    onSuccess: (res) => setValidInputs(res)
  })
  const accountOptions = useMemo(() => {
    if (disableAccount) {
      return [{ name: value.account, value: value.account }]
    } else if (validInputs !== null) {
      return validInputs.accounts.map((item) => ({ name: item, value: item }))
    }
    return []
  }, [validInputs, disableAccount, value.account])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onChange = useCallback(handleChange('account'), [])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onBlur = useCallback(handleOther('blur', 'account'), [])
  return (
    <DropDown
      fullWidth
      required
      label={t('common.account')}
      options={accountOptions}
      value={value.account}
      onChange={onChange}
      onBlur={onBlur}
      helperText={error.account ?? ''}
      error={error.account !== null}
      disabled={disabled.account}
    />
  )
})

const percentageInputProps = {
  decimalScale: 0,
  fixedDecimalScale: true,
  allowNegative: false,
  suffix: '%'
}
const PercentageInput: React.FC<{ isUpdate: boolean }> = React.memo((props) => {
  const { t } = useT()
  const { value, handleChange, handleOther, error, disabled } = useContext(FormContext)
  const percentageHelperText = useMemo(() => {
    const tip = props.isUpdate ? t('common.activateImmediatelyThisMonth') : t('helperText.percentage', { percentage: 100 })
    return `${tip}${error.percentage ? ' ' : ''}${error.percentage ?? ''}`
  }, [error.percentage, props.isUpdate, t])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onChange = useCallback(handleChange('percentage'), [])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onBlur = useCallback(handleOther('blur', 'percentage'), [])
  return (
    <NumberInput
      fullWidth
      required
      label={t('common.splitPercentage')}
      inputProps={percentageInputProps}
      value={value.percentage}
      onChange={onChange}
      onBlur={onBlur}
      error={error.percentage !== null}
      disabled={disabled.percentage}
      helperText={percentageHelperText}
    />
  )
})

const EditAccountEmperorForm: React.FC<PropTypes> = (props) => {
  const {
    defaultValue,
    onSubmit,
    onBack,
    okText,
    nickname,
    passwordText,
    disableAccount,
    requirePassword,
    editSecurityPassword,
    isSetSecurityPassword,
    isUpdate = false
  } = props
  const classes = useCommonStyles()
  const { t } = useT()

  const memoInputProps = useMemo(() => ({
    classes: { input: classes.memoField },
    inputProps: {
      placeholder: t('placeholder.inputMemo')
    }
  }), [classes.memoField, t])

  const isLoginableOptions = useMemo(() => {
    return [
      { name: t('common.yes'), value: 1 },
      { name: t('common.no'), value: 2 }
    ]
  }, [t])

  return (
    <EmperorFormStateProvider
      context={FormContext}
      defaultValue={defaultValue}
      onSubmit={onSubmit}
      nickname={nickname}
      disableAccount={disableAccount}
      requirePassword={requirePassword}
      editSecurityPassword={editSecurityPassword}
    >
      <Grid container direction="column" spacing={3}>
        <Grid item>
          <Grid container direction="row" spacing={2}>
            <Grid item xs={12} md={3}>
              <AccountDropDown disableAccount={disableAccount} />
            </Grid>
            <Grid item xs={12} md={3}>
              <FormField<EditAccountEmperorFormType, TextFieldProps>
                context={FormContext}
                component={TextField}
                name="nickname"
                fullWidth
                label={t('common.nickname')}
                placeholder={t('placeholder.inputNickname')}
                inputProps={nicknameInputProps}
                required
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <FormField<EditAccountEmperorFormType, DropDownProps>
                context={FormContext}
                component={DropDown}
                name="isLoginable"
                fullWidth
                required
                label={t('common.isLoginable')}
                options={isLoginableOptions}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container direction="row" spacing={2}>
            <Grid item xs={12} md={3}>
              <FormField<EditAccountEmperorFormType, TextFieldProps>
                context={FormContext}
                component={TextField}
                name="password"
                fullWidth
                required={requirePassword}
                type="password"
                label={passwordText}
                inputProps={passwordInputProps}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <FormField<EditAccountEmperorFormType, TextFieldProps>
                context={FormContext}
                component={TextField}
                name="passwordConfirmation"
                fullWidth
                required={requirePassword}
                type="password"
                label={t('common.confirmLoginPassword')}
                inputProps={passwordInputProps}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <PercentageInput isUpdate={isUpdate} />
            </Grid>
          </Grid>
        </Grid>
        {
          editSecurityPassword && (
            <Grid item>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={12} md={3}>
                  <FormField<EditAccountEmperorFormType, TextFieldProps>
                    context={FormContext}
                    component={TextField}
                    name="securityPassword"
                    fullWidth
                    required={requirePassword}
                    type="password"
                    label={t('common.updateSecurityPassword')}
                    inputProps={passwordInputProps}
                    helperText={!isSetSecurityPassword ? t('common.notSetSecurityPassword') : ''}
                    FormHelperTextProps={{ error: true }}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <FormField<EditAccountEmperorFormType, TextFieldProps>
                    context={FormContext}
                    component={TextField}
                    name="securityPasswordConfirmation"
                    fullWidth
                    required={requirePassword}
                    type="password"
                    label={t('common.confirmSecurityPassword')}
                    inputProps={passwordInputProps}
                  />
                </Grid>
              </Grid>
            </Grid>
          )
        }
        <Grid item>
          <FormField<EditAccountEmperorFormType, TextFieldProps>
            context={FormContext}
            component={TextField}
            name="memo"
            multiline
            fullWidth
            type="text"
            margin="normal"
            variant="outlined"
            InputProps={memoInputProps}
            FormHelperTextProps={memoFormHelperTextProps}
          />
        </Grid>
        <Grid item>
          <RequiredText />
        </Grid>
        <Grid item>
          <Grid container direction="row" justifyContent="flex-end" spacing={2}>
            <Grid item>
              <Button
                className={classes.greyButton}
                onClick={onBack}
              >
                {t('common.cancel')}
              </Button>
            </Grid>
            <Grid item>
              <FormSubmitButton
                component={Button}
                context={FormContext}
                className={classes.purpleGradualButton}
                type="submit"
              >
                {okText}
              </FormSubmitButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </EmperorFormStateProvider>
  )
}

export default React.memo(EditAccountEmperorForm)

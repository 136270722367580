import React, { MouseEvent } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import { formatCount, formatMoney } from '../../../utils/default/TableHelper'

const useStyles = makeStyles((theme) => ({
  text: (props: { points: number, showLink: boolean | undefined }) => ({
    color: props.points < 0 ? theme.palette.error.main : '#55B559',
    textDecoration: props.showLink ? 'underline' : 'none',
    cursor: props.showLink ? 'pointer' : 'auto'
  })
}))

interface PropTypes {
  points: number | string
  isCount?: boolean
  showLink?: boolean
  showSign?: boolean
  onClick?: (event: MouseEvent<HTMLElement>) => void
  classes?: Partial<Record<'text', string>>
}

const PointsCell: React.FC<PropTypes> = (props) => {
  const { points, isCount, showLink, showSign, onClick, classes: classesProps } = props
  const classes = useStyles({ points: Number(points), showLink })
  return (
    <Box component="span" className={classesProps?.text ?? classes.text} onClick={onClick}>
      {showSign ? (Number(points) >= 0 ? '+' : '') : ''}
      {isCount ? formatCount(Number(points)) : formatMoney(points)}
    </Box>
  )
}

export default React.memo(PointsCell)

import React, { useState, useMemo, createContext, Dispatch, SetStateAction } from 'react'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import useT from '../../../i18ns/admin/useT'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { createDefaultPaginationData, formatCount, formatMoney, createTableData } from '../../../utils/default/TableHelper'
import { PaginationRes, ActivityDailySignIn, ActivitySumDailySignIn, NormalActivityQuery, PaginationReq, ActivityType } from '@golden/gdk-admin'
import { usePageFlow } from '../../../utils/default/PageFlowHook'
import { searchToRequest } from './ActivityRecordDetailForm'
import { useRequestFromSearch, useGetDataByPayload, usePaginationClickAndChangeUrl } from '../../../utils/default/ComplexFlowHook'
import DateTime from '../../default/present/DateTime'
import ScrollablePaper from '../../default/present/ScrollablePaper'
import useGDKStore from '../../../providers/admin/gdk/useGDKStore'
import CoreTable from '../../default/present/CoreTable'
import allRoute, { Path } from '../route/route'
import LoadingAndErrorFrame from '../../default/frames/LoadingAndErrorFrame'
import MemoTextWithTitleAndContext from '../../default/memo/MemoTextWithTitleAndContext'
import MemoPopoverWithContext from '../../default/memo/MemoPopoverWithContext'
import { MemoPopoverPayload, createDefaultMemoPopoverPayload } from '../../default/memo/MemoPopover'
import { dailySignInStateName } from '../../../constants/default/activityStatusName'
import { dailySignInStateColor } from '../../../constants/default/activityStatusColor'
import { useLocation } from 'react-router-dom'

interface SumRowType1 {
  id: number
  count: string
  success: string
  sent: string
  expired: string
  canceled: string
}

interface SumRowType2 {
  id: number
  bonus: string
  effectiveCash: string
}

interface RowType {
  id: number
  order: string
  apply: React.ReactElement
  account: string
  staffAccount: string
  count: string
  signInAt: string
  effectiveCash: string
  content: string
  state: React.ReactElement
  bonus: string
  updatedAt: React.ReactElement
  memo: React.ReactElement
}

const MemoContext = createContext<[MemoPopoverPayload, Dispatch<SetStateAction<MemoPopoverPayload>>]>([
  createDefaultMemoPopoverPayload(),
  () => {}
])

const ActivityRecordDailySignInTable: React.FC = () => {
  const classes = useCommonStyles()
  const { t } = useT()
  const gdk = useGDK()
  const activities = useGDKStore.activity.activities()
  const location = useLocation()
  const [list, setList] = useState<PaginationRes<ActivityDailySignIn[]> & ActivitySumDailySignIn>({
    ...createDefaultPaginationData([]),
    applicant_count: 0,
    applied: 0,
    sent: 0,
    expired: 0,
    canceled_count: 0,
    bonus: '0.0000',
    effective_cash: '0.0000'
  })
  const pageFlow = usePageFlow()
  const request = useRequestFromSearch({ searchToRequest })
  useGetDataByPayload({
    payload: request as NormalActivityQuery & PaginationReq,
    gdkFunc: (payload) => gdk.activity.getActivityRecord7(payload),
    gdkFuncDependencies: [gdk],
    onBeforeFetch: pageFlow.setLoadingStart,
    onSuccess: (res: PaginationRes<ActivityDailySignIn[]> & ActivitySumDailySignIn) => {
      setList(res)
      pageFlow.setContentShow()
    },
    onError: pageFlow.setGDKError,
    canLoadData: request !== undefined
  })
  const tableClasses = useMemo(() => ({
    head: classes.greyTableHead,
    row: classes.tableRow,
    cellHead: classes.tableCellHead,
    cellBody: classes.nowrap
  }), [classes])
  const sumRow1 = useMemo(() => {
    return [{
      id: 1,
      count: formatCount(list.applicant_count),
      success: formatCount(list.applied),
      sent: formatCount(list.sent),
      expired: formatCount(list.expired),
      canceled: formatCount(list.canceled_count)
    }] as SumRowType1[]
  }, [list])
  const sumData1 = useMemo(() => {
    return createTableData<SumRowType1>(
      {
        id: {
          label: '',
          value: 'id'
        },
        count: {
          label: t('common.activityTotalCount'),
          value: 'count',
          align: 'center'
        },
        success: {
          label: t('common.applySuccess'),
          value: 'success',
          align: 'center'
        },
        sent: {
          label: t('common.sentBonus'),
          value: 'sent',
          align: 'center'
        },
        expired: {
          label: t('common.expired'),
          value: 'expired',
          align: 'center'
        },
        canceled: {
          label: t('activityStatus.canceled'),
          value: 'canceled',
          align: 'center'
        }
      },
      [
        'count',
        'success',
        'sent',
        'expired',
        'canceled'
      ],
      sumRow1,
      'id'
    )
  }, [t, sumRow1])
  const sumRow2 = useMemo(() => {
    return [{
      id: 1,
      bonus: formatMoney(list.bonus),
      effectiveCash: formatMoney(list.effective_cash)
    }] as SumRowType2[]
  }, [list])
  const sumData2 = useMemo(() => {
    return createTableData<SumRowType2>(
      {
        id: {
          label: '',
          value: 'id'
        },
        bonus: {
          label: t('common.totalActivityBonus'),
          value: 'bonus',
          align: 'center'
        },
        effectiveCash: {
          label: t('common.weekEffectiveCash'),
          value: 'effectiveCash',
          align: 'center'
        }
      },
      [
        'bonus',
        'effectiveCash'
      ],
      sumRow2,
      'id'
    )
  }, [t, sumRow2])
  const rows = useMemo(() => {
    return list.data.map((item, index) => {
      return {
        id: index,
        order: item.serial_number,
        apply: (<DateTime time={item.applied_at} />),
        account: item.account,
        staffAccount: item.staff_account,
        count: formatCount(item.check_count),
        signInAt: item.sign_in_at.join(', '),
        effectiveCash: formatMoney(item.effective_cash),
        content: item.activity_content,
        state: (<p className={classes.chipText} style={{ backgroundColor: dailySignInStateColor[item.state] }}>{t(dailySignInStateName[item.state])}</p>),
        bonus: formatMoney(item.bonus),
        updatedAt: item.updated_at ? (<DateTime time={item.updated_at} />) : (<p></p>),
        memo: item.memo !== '' && (<MemoTextWithTitleAndContext context={MemoContext} title={item.memo} memo={item.memo} />)
      } as RowType
    })
  }, [list.data, t, classes])
  const data = useMemo(() => {
    return createTableData<RowType>(
      {
        id: {
          label: '',
          value: 'id'
        },
        order: {
          label: t('common.activitySerial'),
          value: 'order',
          align: 'center'
        },
        apply: {
          label: t('common.applyTime'),
          value: 'apply',
          align: 'center'
        },
        account: {
          label: t('common.playerAccount'),
          value: 'account',
          align: 'center'
        },
        staffAccount: {
          label: t('common.staffAccount'),
          value: 'staffAccount',
          align: 'center'
        },
        count: {
          label: t('common.checkCount'),
          value: 'count',
          align: 'center'
        },
        signInAt: {
          label: t('common.signInAt'),
          value: 'signInAt',
          align: 'center'
        },
        effectiveCash: {
          label: t('common.weekEffectiveCash'),
          value: 'effectiveCash',
          align: 'right'
        },
        content: {
          label: t('common.activityContent'),
          value: 'content',
          align: 'left'
        },
        state: {
          label: t('common.status'),
          value: 'state',
          align: 'center'
        },
        bonus: {
          label: t('common.activityBonus'),
          value: 'bonus',
          align: 'right'
        },
        updatedAt: {
          label: t('common.updatedAt'),
          value: 'updatedAt',
          align: 'center'
        },
        memo: {
          label: t('common.memo'),
          value: 'memo',
          align: 'center'
        }
      },
      [
        'order',
        'apply',
        'account',
        'staffAccount',
        'count',
        'signInAt',
        'effectiveCash',
        'content',
        'state',
        'bonus',
        'updatedAt',
        'memo'
      ],
      rows,
      'id'
    )
  }, [t, rows])
  const handlePagination = usePaginationClickAndChangeUrl({
    request,
    encodePath: (location.pathname === Path.ACTIVITY_RECORD_DETAIL) ? allRoute.activityRecordDetail.encodePath : allRoute.activityRecordSub.encodePath
  })
  return (
    <MemoPopoverWithContext memoPopoverContext={MemoContext}>
      <Box paddingBottom={3}>
        <ScrollablePaper marginX={5}>
          <Box padding={5}>
            <LoadingAndErrorFrame { ...pageFlow.status }>
              <Box
                paddingY={1.25}
                paddingX={2}
                className={classes.pinkTitleBar}
              >
                <Typography variant="h5">
                  {activities.find((item) => item.type === ActivityType.DAILY_SIGN_IN_BONUS)?.name ?? ''}
                </Typography>
              </Box>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={12} md={6}>
                  <Box paddingY={2}>
                    <Typography variant="h5">
                      {t('common.countStatisticResult')}
                    </Typography>
                  </Box>
                  <CoreTable
                    data={sumData1}
                    total={1}
                    classes={tableClasses}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box paddingY={2}>
                    <Typography variant="h5">
                      {t('common.bonusStatisticResult')}
                    </Typography>
                  </Box>
                  <CoreTable
                    data={sumData2}
                    total={1}
                    classes={tableClasses}
                  />
                </Grid>
              </Grid>
            </LoadingAndErrorFrame>
          </Box>
        </ScrollablePaper>
      </Box>
      <ScrollablePaper marginX={5}>
        <Box padding={5}>
          <LoadingAndErrorFrame { ...pageFlow.status }>
            <CoreTable
              data={data}
              total={list.total}
              classes={tableClasses}
              showPagination
              page={request?.page ?? 1}
              onChangePage={handlePagination}
            />
          </LoadingAndErrorFrame>
        </Box>
      </ScrollablePaper>
    </MemoPopoverWithContext>
  )
}

export default React.memo(ActivityRecordDailySignInTable)

import React, { useState, useMemo, createContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import MuiButton from '@material-ui/core/Button'
import MuiTextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import ErrorIcon from '@material-ui/icons/Error'
import useT from '../../../i18ns/admin/useT'
import { useCommonStyles, useDialogStyles } from '../../../utils/admin/StyleHook'
import { FeedbackFrequency, FeedbackForm, Feedback, PlayerRankType, GameType, PlatformType } from '@golden/gdk-admin'
import { ValueGetter, FormState, createDefaultFormState, FormValidation, ChangedFormGetter, DisableFieldGetter } from '../../../utils/default/FormHook'
import { getValueFromChangeEvent, getValueFromValue } from '../../../utils/default/FormHelper'
import { InitialFormFunc, useGetDataByParams, useDialogHandleSubmit, useRedirectHandleBack, useGetDataByPayload } from '../../../utils/default/ComplexFlowHook'
import { useLocation } from 'react-router'
import { parsePath } from '../../../utils/default/RouteHelper'
import { Path } from '../../../components/admin/route/route'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { usePageFlow } from '../../../utils/default/PageFlowHook'
import { createTableData, formatMoney } from '../../../utils/default/TableHelper'
import useGlobalDialog from '../../../providers/admin/dialog/useGlobalDialog'
import { createGlobalDialogConfig } from '../../../utils/default/DialogHelper'
import LoadingAndErrorFrame from '../../../components/default/frames/LoadingAndErrorFrame'
import FormStateProvider from '../../../components/default/form/FormStateProvider'
import FormField from '../../../components/default/form/FormField'
import DropDown, { PropTypes as DropDownProps } from '../../../components/default/form/DropDown'
import feedbackFrequencyName from '../../../constants/admin/feedbackFrequencyName'
import { createCorrectResult, createErrorResult } from '../../../utils/default/Validator'
import FormSubmitButton from '../../../components/default/form/FormSubmitButton'
import FeedbackRankInput, { PropTypes as FeedbackRankInputProps } from '../../../components/admin/externalGame/FeedbackRankInput'
import FeedbackChannelInput, { PropTypes as FeedbackChannelInputProps } from '../../../components/admin/externalGame/FeedbackChannelInput'
import RequiredText from '../../../components/default/form/RequiredText'
import playerRankName from '../../../constants/default/playerRankName'
import CoreTable from '../../../components/default/present/CoreTable'
import ScrollablePaper from '../../../components/default/present/ScrollablePaper'
import useGDKStore from '../../../providers/admin/gdk/useGDKStore'
import { getGameName } from '../../../utils/default/PlatformHelper'

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.error.main,
    marginRight: theme.spacing(0.5),
    fontSize: 18
  }
}))

interface FormType {
  period: FeedbackFrequency
  ranks: Array<{ rank: PlayerRankType, feedback: string }>
  channels: Array<{ channel_id: number, ranks: Array<{ rank: PlayerRankType, feedback: string }> }>
}

interface ConfirmRankRowType {
  id: string
  game: string
  period: string
  [key: string]: string
}

interface ConfirmChannelRowType {
  id: string
  channel: string
  [key: string]: string
}

const initialForm: InitialFormFunc<FormType> = (defaultForm) => ({
  period: FeedbackFrequency.DAY,
  ranks: [],
  channels: [],
  ...defaultForm
})

const getValueFromEvent: ValueGetter<FormType> = {
  period: getValueFromChangeEvent,
  ranks: getValueFromValue,
  channels: getValueFromValue
}

const getChangedForm: ChangedFormGetter<FormType> = {
  period: (value, form) => ({
    ...form,
    period: value,
    ranks: value === FeedbackFrequency.NO_FEEDBACK ? Object.keys(playerRankName).map((key) => ({ rank: key as PlayerRankType, feedback: '0' })) : form.ranks,
    channels: value === FeedbackFrequency.NO_FEEDBACK ? form.channels.map((item) => ({ ...item, ranks: [] })) : form.channels
  })
}

const getDisabled: DisableFieldGetter<FormType> = {
  ranks: (_, form) => form.period === FeedbackFrequency.NO_FEEDBACK,
  channels: (_, form) => form.period === FeedbackFrequency.NO_FEEDBACK
}

const formToRequest = (form: FormType): FeedbackForm => ({
  period: form.period,
  ranks: form.ranks,
  channels: form.channels
    .map((item) => {
      return { ...item, ranks: item.ranks.filter((rank) => rank.feedback && rank.feedback !== '') }
    })
    .filter((item) => item.ranks.length !== 0)
})

const FormContext = createContext<FormState<FormType>>(createDefaultFormState(initialForm()))
const Button = React.memo(MuiButton)
const TextField = React.memo(MuiTextField)

const stage = import.meta.env.VITE_APP_STAGE

const ExternalGameFeedbackUpdatePage: React.FC = () => {
  const { t } = useT()
  const gdk = useGDK()
  const pageFlow = usePageFlow()
  const channelPageFlow = usePageFlow()
  const classes = useStyles()
  const commonClasses = useCommonStyles()
  const dialogClasses = useDialogStyles()
  const location = useLocation()
  const globalDialog = useGlobalDialog()
  const games = useGDKStore.platform.games()
  const [platformId, setPlatformId] = useState<PlatformType | 0>(0)
  const [gameId, setGameId] = useState<GameType | 0>(0)
  const [defaultForm, setDefaultForm] = useState<FormType>(initialForm())
  const [channels, setChannels] = useState<Array<{ id: number, name: string }>>([])
  const payload = useMemo(() => ({ platformId, gameId }), [platformId, gameId])
  useGetDataByPayload({
    payload,
    gdkFunc: (payload) => gdk.platform.getChannelList(payload.platformId, payload.gameId, true),
    gdkFuncDependencies: [gdk],
    onBeforeFetch: channelPageFlow.setLoadingStart,
    onSuccess: (res: Array<{ id: number, name: string }>) => {
      channelPageFlow.setContentShow()
      setChannels(res)
    },
    onError: channelPageFlow.setGDKError,
    canLoadData: platformId !== 0 && gameId !== 0
  })
  const options = useMemo(() => {
    return Object.keys(feedbackFrequencyName)
      .map((key) => Number(key) as FeedbackFrequency)
      .filter((key) => {
        if (stage === 'prod' && key === FeedbackFrequency.RIGHT_NOW) {
          return false
        }
        return true
      })
      .map((key) => ({ name: t(feedbackFrequencyName[key]), value: key }))
  }, [t])
  const id = useMemo(() => {
    return parsePath(location.search, location.pathname, Path.EXTERNAL_GAME_FEEDBACK_UPDATE).param.id
  }, [location.pathname, location.search])
  const validation = useMemo(() => {
    return {
      period: [],
      ranks: [
        {
          func: (value) => {
            const ranks = value as Array<{ rank: PlayerRankType, feedback: string }>
            if (ranks.some((item) => !item.feedback || item.feedback === '')) {
              return createErrorResult('ranks', '')
            }
            return createCorrectResult('ranks')
          },
          when: ['beforeClickSubmit']
        }
      ],
      channels: []
    } as FormValidation<FormType>
  }, [])
  const tableClasses = useMemo(() => {
    return {
      head: commonClasses.blackTableHead,
      row: commonClasses.tableRow,
      cellHead: commonClasses.tableCellHead
    }
  }, [commonClasses])
  useGetDataByParams({
    path: Path.EXTERNAL_GAME_FEEDBACK_UPDATE,
    gdkFunc: (param: { id: string }) => gdk.platform.getFeedback(Number(param.id)),
    gdkFuncDependencies: [gdk],
    onBeforeFetch: pageFlow.setLoadingStart,
    onSuccess: (res: Feedback) => {
      pageFlow.setContentShow()
      setPlatformId(res.platform_id)
      setGameId(res.game_id)
      setDefaultForm(initialForm({
        period: res.period ?? FeedbackFrequency.DAY,
        ranks: res.ranks.map((item) => ({ rank: item.rank, feedback: item.feedback })),
        channels: res.channels.map((item) => ({ channel_id: item.channel_id, ranks: item.ranks.map((rank) => ({ rank: rank.rank, feedback: rank.feedback })) }))
      }))
    },
    onError: pageFlow.setGDKError
  })
  const [handleBack, handleDebouncedBack] = useRedirectHandleBack({
    path: Path.EXTERNAL_GAME_FEEDBACK
  })
  const { handleSubmit } = useDialogHandleSubmit({
    formToRequest,
    gdkFunc: (data) => gdk.platform.updateFeedback(id, data),
    gdkFuncDependencies: [gdk, id],
    globalDialog,
    dialogId: 'updateFeedback',
    getChangeDialogConfig: (form) => {
      const rankColumnObject = Object.keys(playerRankName)
        .map((key) => key as PlayerRankType)
        .map((key) => {
          return {
            [key.toString()]: {
              label: t(playerRankName[key]),
              value: key.toString(),
              align: 'center'
            }
          }
        })
        .reduce((item, obj) => Object.assign({}, item, obj), {})
      const rankDataObject = Object.keys(playerRankName)
        .map((key) => key as PlayerRankType)
        .map((key) => {
          const target = form.ranks.find((item) => item.rank === key)
          if (!target) return { [key.toString()]: t('common.notAdapt') }
          return {
            [key.toString()]: `${formatMoney(target.feedback)}%`
          }
        })
        .reduce((item, obj) => Object.assign({}, obj, item), {})
      const rankRow = [{
        id: '0',
        game: gameId === 0 ? '' : getGameName(gameId, games),
        period: t(feedbackFrequencyName[form.period]),
        ...rankDataObject
      }] as ConfirmRankRowType[]
      const rankData = createTableData<ConfirmRankRowType>(
        {
          id: {
            label: '',
            value: 'id'
          },
          game: {
            label: t('common.gamePlatform'),
            value: 'game',
            align: 'center'
          },
          period: {
            label: t('common.feedbackFrequency'),
            value: 'period',
            align: 'center'
          },
          ...rankColumnObject
        },
        [
          'game',
          'period',
          ...Object.keys(rankColumnObject)
        ],
        rankRow,
        'id'
      )
      const channelRow = form.channels
        .map((item) => {
          return { ...item, ranks: item.ranks.filter((rank) => rank.feedback && rank.feedback !== '') }
        })
        .filter((item) => item.ranks.length !== 0)
        .map((item, index) => {
          const channelName = channels.find((channel) => channel.id === item.channel_id)?.name ?? ''
          const rankDataObject = Object.keys(playerRankName)
            .map((key) => key as PlayerRankType)
            .map((key) => {
              const target = item.ranks.find((item) => item.rank === key)
              if (!target) return { [key.toString()]: t('common.notAdapt') }
              return {
                [key.toString()]: target.feedback === '' ? t('common.notAdapt') : `${target.feedback}%`
              }
            })
            .reduce((item, obj) => Object.assign({}, obj, item), {})
          return {
            id: `${index}`,
            channel: channelName,
            ...rankDataObject
          } as ConfirmChannelRowType
        })
      const channelData = createTableData<ConfirmChannelRowType>(
        {
          id: {
            label: '',
            value: 'id'
          },
          channel: {
            label: t('common.game'),
            value: 'channel',
            align: 'center'
          },
          ...rankColumnObject
        },
        [
          'channel',
          ...Object.keys(rankColumnObject)
        ],
        channelRow,
        'id'
      )
      return createGlobalDialogConfig({
        showIcon: false,
        notUseTypo: true,
        message: (
          <React.Fragment>
            <Typography className={dialogClasses.text}>{t('dialog.confirmUpdateFeedback')}</Typography>
            <Box width="100%">
              <CoreTable
                dense
                data={rankData}
                classes={tableClasses}
                total={1}
              />
            </Box>
            {channelRow.length !== 0 && (
              <Box paddingTop={2} width="100%">
                <CoreTable
                  dense
                  data={channelData}
                  classes={tableClasses}
                  total={channelRow.length}
                />
              </Box>
            )}
          </React.Fragment>
        )
      })
    },
    getSuccessDialogConfig: () => createGlobalDialogConfig({
      showIcon: false,
      showCancel: false,
      message: t('dialog.updateFeedbackSuccess')
    }),
    getFailDialogConfig: (error) => createGlobalDialogConfig({
      showIcon: true,
      showCancel: false,
      variant: 'error',
      message: error
    }),
    afterSuccessDialog: handleBack
  })
  return (
    <Box paddingY={6}>
      <FormStateProvider
        context={FormContext}
        defaultValue={defaultForm}
        onSubmit={handleSubmit}
        getValueFromEvent={getValueFromEvent}
        getChangedForm={getChangedForm}
        disableField={getDisabled}
        validation={validation}
      >
        <LoadingAndErrorFrame { ...pageFlow.status }>
          <ScrollablePaper marginX={6}>
            <Box padding={5}>
              <Box
                paddingY={1.25}
                paddingX={2}
                marginBottom={2}
                className={commonClasses.pinkTitleBar}
              >
                <Typography variant="h5">
                  {t('page.updateFeedbackSetting')}
                </Typography>
              </Box>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={12} md={6} lg={3}>
                  <TextField
                    disabled
                    label={t('common.gameStoreName')}
                    value={gameId === 0 ? '' : getGameName(gameId, games)}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <FormField<FormType, DropDownProps>
                    component={DropDown}
                    context={FormContext}
                    name="period"
                    label={t('common.feedbackFrequency')}
                    options={options}
                    fullWidth
                    required
                  />
                </Grid>
              </Grid>
              <Box paddingTop={2}>
                <FormField<FormType, FeedbackRankInputProps>
                  component={FeedbackRankInput}
                  context={FormContext}
                  name="ranks"
                />
              </Box>
              <Box paddingTop={2}>
                <Box display="flex" flexDirection="row" alignItems="center">
                  <ErrorIcon className={classes.icon} />
                  <Typography color="error">
                    {t('common.maxFeedbackTip')}
                  </Typography>
                </Box>
              </Box>
              <Box paddingTop={1}>
                <RequiredText />
              </Box>
            </Box>
          </ScrollablePaper>
          <Box paddingTop={2}>
            <ScrollablePaper marginX={6}>
              <Box padding={5}>
                <Box display="flex" flexDirection="row" alignItems="center">
                  <ErrorIcon className={classes.icon} />
                  <Typography color="error">
                    {t('common.feedbackTip3')}
                  </Typography>
                </Box>
                <Box display="flex" flexDirection="row" alignItems="center">
                  <ErrorIcon className={classes.icon} />
                  <Typography color="error">
                    {t('common.feedbackTip4')}
                  </Typography>
                </Box>
                <Box paddingTop={2}>
                  <FormField<FormType, FeedbackChannelInputProps>
                    component={FeedbackChannelInput}
                    context={FormContext}
                    name="channels"
                    channels={channels}
                    pageFlow={channelPageFlow}
                  />
                </Box>
              </Box>
            </ScrollablePaper>
          </Box>
          <Box display="flex" paddingTop={2} paddingX={6} justifyContent="flex-end">
            <Box marginRight={2}>
              <Button onClick={handleDebouncedBack} className={commonClasses.greyButton}>{t('common.back')}</Button>
            </Box>
            <Box>
              <FormSubmitButton
                context={FormContext}
                component={Button}
                type="submit"
                className={commonClasses.purpleGradualButton}
              >
                {t('common.confirmUpdate')}
              </FormSubmitButton>
            </Box>
          </Box>
        </LoadingAndErrorFrame>
      </FormStateProvider>
    </Box>
  )
}

export default React.memo(ExternalGameFeedbackUpdatePage)

import React from 'react'
import { Link } from 'react-router-dom'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import InternalWithdrawHistoryForm from '../../../components/admin/finance/InternalWithdrawHistoryForm'
import InternalWithdrawHistoryTable from '../../../components/admin/finance/InternalWithdrawHistoryTable'
import useT from '../../../i18ns/admin/useT'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import { Path } from '../../../components/admin/route/route'

const FinanceInternalWithdrawHistoryPage: React.FC = () => {
  const { t } = useT()
  const classes = useCommonStyles()
  return (
    <Box paddingY={5}>
      <Box paddingX={5}>
        <InternalWithdrawHistoryForm />
      </Box>
      <Box paddingTop={3}>
        <InternalWithdrawHistoryTable />
      </Box>
      <Box paddingX={5} paddingTop={2} display="flex" justifyContent="flex-end">
        <Button
          className={classes.greyButton}
          component={Link}
          to={Path.FINANCE_INTERNAL_WITHDRAW}
        >
          {t('common.back')}
        </Button>
      </Box>
    </Box>
  )
}

export default React.memo(FinanceInternalWithdrawHistoryPage)

import React, { Dispatch, SetStateAction, createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import Paper from '@material-ui/core/Paper'
import Box from '@material-ui/core/Box'
import Grid, { GridSize } from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { TextFieldProps } from '@material-ui/core/TextField'
import Divider from '@material-ui/core/Divider'
import { makeStyles } from '@material-ui/core'
import FormField from '../../../components/default/form/FormField'
import DropDown, { PropTypes as DropDownProps } from '../../../components/default/form/DropDown'
import GameCategoryInput from '../../../components/admin/GameCategoryInput'
import GameIdInput from '../../../components/admin/GameIdInput'
import NumberInput from '../../../components/default/form/NumberInput'
import FormSubmitButton from '../../../components/default/form/FormSubmitButton'
import CoreTable from '../../../components/default/present/CoreTable'
import DateInputBase, { DateInputValue } from '../../../components/default/form/DateInput'
import FormStateProvider from '../../../components/default/form/FormStateProvider'
import LoadingAndErrorFrame from '../../../components/default/frames/LoadingAndErrorFrame'
import { endOfDay, addDays } from 'date-fns'
import { createTableData, formatMoney } from '../../../utils/default/TableHelper'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import useGDK from '../../../providers/admin/gdk/useGDK'
import useT from '../../../i18ns/admin/useT'
import FileInputButton, { PropTypes as FileInputButtonPropTypes } from '../../../components/admin/FileInputButton'
import { getValueFromChangeEvent, getValueFromValue, getTimeFromDateInputValue, getCodeInputProps } from '../../../utils/default/FormHelper'
import { PageFlowType, usePageFlow } from '../../../utils/default/PageFlowHook'
import { useGetDataByPayload, useGetData, useDialogHandleSubmit, useReload, useRedirectHandleBack, InitialFormFunc } from '../../../utils/default/ComplexFlowHook'
import { createDefaultFormState, FormValidation, ValueGetter } from '../../../utils/default/FormHook'
import useGlobalDialog from '../../../providers/admin/dialog/useGlobalDialog'
import { createGlobalDialogConfig } from '../../../utils/default/DialogHelper'
import { ActivityWalletLimitType, GameCategoryType, GameType, ActivityWalletCsvRes } from '@golden/gdk-admin'
import { createValidateNotEmpty, createValidateDropDownNotEmpty, createCorrectResult, createErrorResult } from '../../../utils/default/Validator'
import { createDefaultMemoPopoverPayload, MemoPopoverPayload } from '../../../components/default/memo/MemoPopover'
import MemoPopoverWithContext from '../../../components/default/memo/MemoPopoverWithContext'
import ActivityWalletAddActivityTitleDialog from '../../../components/admin/activity/wallet/ActivityWalletAddActivityTitleDialog'
import { Path } from '../../../components/admin/route/route'
import MemoTextWithTitleAndContext from '../../../components/default/memo/MemoTextWithTitleAndContext'
import Tip from '../../../components/admin/Tip'
import { BcMath } from '@golden/bcmath'

type FormType = {
  file: File | null
  activityWalletTitleId: number | null
  multiple: string
  expired_at: DateInputValue
  code: string } & ({ limit_type: ActivityWalletLimitType.GAME_CATEGORY, limit_ids: GameCategoryType[] } | { limit_type: ActivityWalletLimitType.GAME, limit_ids: GameType[] })
interface RowType {
  id: number
  account: string
  cash: string
  content: string | React.ReactElement
  bindingDeposits: React.ReactElement
  barrierSum: string
  check: string | React.ReactElement
}

const useStyles = makeStyles((theme) => ({
  divider: {
    backgroundColor: '#eee',
    margin: '32px 0'
  },
  error: {
    color: theme.palette.error.main
  }
}))

const defaultValue: FormType = {
  file: null,
  activityWalletTitleId: null,
  multiple: '3',
  expired_at: {
    start: null,
    end: null
  },
  limit_type: ActivityWalletLimitType.GAME_CATEGORY as ActivityWalletLimitType,
  limit_ids: [],
  code: ''
}

const getValueFromEvent: ValueGetter<FormType> = {
  file: getValueFromValue,
  activityWalletTitleId: getValueFromChangeEvent,
  multiple: getValueFromChangeEvent,
  expired_at: getTimeFromDateInputValue,
  limit_type: getValueFromChangeEvent,
  limit_ids: getValueFromChangeEvent,
  code: getValueFromChangeEvent
}

export const FormContext = createContext(createDefaultFormState(defaultValue))

const multipleInputProps = {
  allowNegative: false,
  decimalScale: 0,
  maxLength: undefined
}

const codeInputProps = getCodeInputProps()

const MemoContext = createContext<[MemoPopoverPayload, Dispatch<SetStateAction<MemoPopoverPayload>>]>([
  createDefaultMemoPopoverPayload(),
  () => { }
])

const LimitTypeDropDown: React.FC = React.memo(() => {
  const { value, handleChange, dispatch } = useContext(FormContext)
  const { t } = useT()
  const limitTypeOptions = useMemo(() => (
    [
      {
        name: t('common.gameCategoryType'),
        value: ActivityWalletLimitType.GAME_CATEGORY
      },
      {
        name: t('common.gamePlatform'),
        value: ActivityWalletLimitType.GAME
      }
    ]
  ), [t])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onChange = useCallback(handleChange('limit_type'), [])

  return (
    <DropDown
      value={value.limit_type}
      options={limitTypeOptions}
      fullWidth
      required
      label={t('common.activityGamePlatform')}
      onChange={(e) => {
        onChange(e)
        dispatch({ type: 'change', label: 'limit_ids', value: [] })
      }}
    />
  )
})

const LimitIdsDropDown: React.FC = React.memo(() => {
  const { value, dispatch } = useContext(FormContext)

  return value.limit_type === ActivityWalletLimitType.GAME_CATEGORY
    ? (
    <GameCategoryInput
      value={value.limit_ids}
      fullWidth
      multiple
      noAll
      filterNoStopSaleGame
      required
      filterNoCategories={[GameCategoryType.LOTTERY]}
      onChange={(value) => {
        dispatch({ type: 'change', label: 'limit_ids', value })
      }}
    />
      )
    : (
    <GameIdInput
      value={value.limit_ids}
      fullWidth
      multiple
      noAll
      active
      required
      filterNoCategories={[GameCategoryType.LOTTERY]}
      onChange={(value) => {
        dispatch({ type: 'change', label: 'limit_ids', value })
      }}
    />
      )
})

const DateInput: React.FC = React.memo(() => {
  const classes = useCommonStyles()
  const { t } = useT()
  const { value, handleChange } = useContext(FormContext)
  const grid = useMemo(() => ({
    xs: 6 as GridSize,
    md: 6 as GridSize,
    lg: 6 as GridSize
  }), [])
  const option = useMemo(() => ({
    label: t('common.receivedExpiredTime'),
    grid
  }), [t, grid])

  const setOneDay = () => {
    return { start: null, end: endOfDay(addDays(new Date(), 1)) }
  }
  const setSevenDay = () => {
    return { start: null, end: endOfDay(addDays(new Date(), 7)) }
  }

  const setThirtyDay = () => {
    return { start: null, end: endOfDay(addDays(new Date(), 30)) }
  }

  const tools = useMemo(() => {
    return [
      {
        label: `1 ${t('common.day2')}`,
        change: setOneDay
      },
      {
        label: `7 ${t('common.day2')}`,
        change: setSevenDay
      },
      {
        label: `30 ${t('common.day2')}`,
        change: setThirtyDay
      }
    ]
  }, [t])

  const dateClasses = useMemo(() => ({
    button: classes.pinkGradualButton
  }), [classes.pinkGradualButton])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onChange = useCallback(handleChange('expired_at'), [])
  return (
    <DateInputBase
      value={value.expired_at}
      onChange={onChange}
      end={option}
      tools={tools}
      toolsGrid={grid}
      classes={dateClasses}
      required
      denseToolbar
    />
  )
})

const Step1: React.FC<{ res: ActivityWalletCsvRes | null, setRes: (value: ActivityWalletCsvRes | null) => void, pageFlow: PageFlowType }> = React.memo(({ res, setRes, pageFlow }) => {
  const { t } = useT()
  const { value: form, dispatch } = useContext(FormContext)
  const commonClasses = useCommonStyles()
  const classes = useStyles()
  const gdk = useGDK()
  const globalDialog = useGlobalDialog()
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const payload = useMemo(() => ({ file: form.file! }), [form.file])

  useEffect(() => {
    const value = {
      ...defaultValue,
      file: form.file
    }
    dispatch({
      type: 'set',
      value
    })
  }, [dispatch, form.file])

  useGetDataByPayload({
    payload,
    gdkFunc: (payload) => gdk.activity.postActivityWalletUserInfoCsv(payload),
    gdkFuncDependencies: [gdk],
    canLoadData: !!form.file,
    onBeforeFetch: () => {
      setRes(null)
      pageFlow.setLoadingStart()
    },
    onSuccess: (res: ActivityWalletCsvRes) => {
      setRes(res)
      pageFlow.setContentShow()
      globalDialog.setConfig(createGlobalDialogConfig({
        showIcon: true,
        variant: 'success',
        showCancel: false,
        message: t('dialog.createActivityWalletCsv', { account: res.account_duplicated, cash: res.warning_amount })
      }))
      globalDialog.setOpen({
        id: 'validateActivityWalletCsvSuccess',
        value: true,
        isOK: false
      })
    },
    onError: (error) => {
      setRes(null)
      globalDialog.setConfig(createGlobalDialogConfig({
        showIcon: true,
        variant: 'error',
        showCancel: false,
        message: error.message
      }))
      globalDialog.setOpen({
        id: 'validateActivityWalletCsvFailed',
        value: true,
        isOK: false
      })
      dispatch({ type: 'change', label: 'file', value: null })
      pageFlow.setContentShow()
    }
  })

  const tableClasses = useMemo(() => ({
    head: commonClasses.greyTableHead,
    row: commonClasses.tableRow,
    cellHead: commonClasses.tableCellHead,
    cellBody: commonClasses.nowrap
  }), [commonClasses])

  const rows = useMemo(() => {
    return res?.data.map((item, index): RowType => {
      return {
        id: index,
        account: item.account,
        cash: formatMoney(item.amount),
        content: (<MemoTextWithTitleAndContext context={MemoContext} title={item.content ?? '-'} memo={item.content ?? '-'} />),
        bindingDeposits: (<span className={commonClasses.pre}>{item.binding_deposits.join('\n')}</span>),
        barrierSum: form.multiple ? formatMoney(BcMath.base(item.amount).mul(form.multiple).get()) : '',
        check: (
          <>
          { item.is_account_duplicated && (<p className={classes.error}>{ t('error.accountDuplicated') }</p>) }
          { item.is_warning_amount && (<p className={classes.error}>{ t('error.cashTooHigh') }</p>) }
          { item.is_debit_card_unbound && (<p className={classes.error}>{ t('error.notAllowedToParticipateActivity') }</p>) }
          </>
        )
      }
    }) ?? []
  }, [t, res?.data, classes, form.multiple, commonClasses])
  const data = useMemo(() => {
    return createTableData<RowType>(
      {
        id: {
          label: '',
          value: 'id',
          width: 240
        },
        account: {
          label: t('common.playerAccount'),
          value: 'account',
          align: 'center',
          width: 240
        },
        cash: {
          label: t('common.cash'),
          value: 'cash',
          align: 'center'
        },
        content: {
          label: t('common.activityContent'),
          value: 'content',
          align: 'center'
        },
        bindingDeposits: {
          label: t('common.exchangedDepositOrderNo'),
          value: 'bindingDeposits',
          align: 'center'
        },
        barrierSum: {
          label: t('common.addEffectiveCash'),
          value: 'barrierSum',
          align: 'center'
        },
        check: {
          label: t('common.check'),
          value: 'check',
          align: 'center'
        }
      },
      [
        'account',
        'cash',
        'content',
        'bindingDeposits',
        'barrierSum',
        'check'
      ],
      rows,
      'id'
    )
  }, [rows, t])

  return (
    <>
      <Grid item xs={12}>
        <FormField<FormType, FileInputButtonPropTypes>
          context={FormContext}
          component={FileInputButton}
          name="file"
          loading={false}
        />
      </Grid>
      <Grid item xs={12}>
        <LoadingAndErrorFrame { ...pageFlow.status }>
          <MemoPopoverWithContext memoPopoverContext={MemoContext}>
            {
                res && (
                  <CoreTable
                    data={data}
                    total={0}
                    classes={tableClasses}
                  />
                )
              }
          </MemoPopoverWithContext>
        </LoadingAndErrorFrame>
      </Grid>
    </>
  )
})

const Step2: React.FC<{
  pageFlow: PageFlowType
  options: Array<{ value: number, name: string }>
  setOptions: React.Dispatch<React.SetStateAction<Array<{
    value: number
    name: string
  }>>> }> = React.memo(({ pageFlow, options, setOptions }) => {
  const { t } = useT()
  const gdk = useGDK()
  const commonClasses = useCommonStyles()
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const { reloadFlag, reload } = useReload()
  useGetData({
    canLoadData: pageFlow.status.showContent,
    gdkFunc: () => gdk.activity.getActivityWalletActivityTitle(),
    gdkFuncDependencies: [gdk, reloadFlag],
    onSuccess: (data) => {
      setOptions(data.map(item => ({ name: item.title, value: item.id })))
    }
  })
  const [, handleDebouncedBack] = useRedirectHandleBack({ path: Path.ACTIVITY_WALLET })

  return (
      <Grid item>
        <LoadingAndErrorFrame { ...pageFlow.status }>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={12} md={3}>
                  <FormField<FormType, DropDownProps>
                    context={FormContext}
                    component={DropDown}
                    name="activityWalletTitleId"
                    options={options}
                    label={t('common.activityName')}
                    placeholder={t('placeholder.pleaseChoose', { item: '' })}
                    fullWidth
                    required
                  />
                  <Box paddingTop={1} display="flex" justifyContent="flex-end">
                    <span className={commonClasses.anchor} onClick={() => setOpen(true)}>
                      {t('common.addActivityName')}
                    </span>
                    <ActivityWalletAddActivityTitleDialog open={open} close={() => setOpen(false)} reload={reload} />
                  </Box>
                </Grid>
                <Grid item xs={12} md={3}>
                  <FormField<FormType, TextFieldProps>
                    context={FormContext}
                    component={NumberInput}
                    name="multiple"
                    label={`${t('common.deltaEffectiveCash')}${t('common.multiple')}`}
                    inputProps={multipleInputProps}
                    placeholder={''}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <LimitTypeDropDown />
                </Grid>
                <Grid item xs={12} md={3}>
                  <LimitIdsDropDown />
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={12} md={6}>
                  <DateInput />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormField<FormType, TextFieldProps>
                    context={FormContext}
                    component={NumberInput}
                    name="code"
                    fullWidth
                    required
                    inputProps={codeInputProps}
                    label={t('common.googleCode')}
                    placeholder={t('placeholder.inputGoogleCode2')}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Divider className={classes.divider} />
          <Grid container justifyContent="space-between">
            <Grid item>
              <Tip text={t('common.createActivityWalletTip')} />
            </Grid>
            <Grid item>
            <Grid container justifyContent="flex-end" spacing={2}>
              <Grid item>
                <Button
                  className={commonClasses.greyButton}
                  onClick={handleDebouncedBack}
                >
                  {t('common.back')}
                </Button>
              </Grid>
              <Grid item>
                <FormSubmitButton
                  component={Button}
                  context={FormContext}
                  className={commonClasses.purpleGradualButton}
                  type="submit"
                >
                  {t('common.confirm')}
                </FormSubmitButton>
              </Grid>
            </Grid>
            </Grid>
          </Grid>
        </LoadingAndErrorFrame>
      </Grid>
  )
})

const ActivityWalletCreatePage: React.FC = () => {
  const gdk = useGDK()
  const classes = useCommonStyles()
  const { t } = useT()
  const pageFlow = usePageFlow(false, false)
  const csvTemplate = useMemo(() => {
    const titles = [t('common.playerAccount'), t('common.cash'), `${t('common.activityContent')}(${t('common.stringMaximum', { amount: 255 })})`, t('common.exchangedDepositOrderNo')]
    return `data:text/csv;charset=utf-8,%EF%BB%BF${encodeURI(titles.join(','))}`
  }, [t])

  const validation: FormValidation<FormType> = useMemo(() => {
    return {
      file: [],
      activityWalletTitleId: [
        {
          func: createValidateNotEmpty('activityWalletTitleId', t),
          when: ['change', 'beforeClickSubmit']
        }
      ],
      multiple: [
        {
          func: (value: unknown) => {
            if (Number(value) === 0) return createErrorResult('multiple', t('error.mustNotZero'))
            if (!Number.isInteger(Number(value))) return createErrorResult('multiple', t('error.mustBeInt'))
            return createCorrectResult('multiple')
          },
          when: ['change', 'beforeClickSubmit']
        }
      ],
      expired_at: [
        {
          func: (value: unknown) => {
            if (!(value as DateInputValue).end) return createErrorResult('expired_at', t('error.mustNotEmpty'))
            return createCorrectResult('expired_at')
          },
          when: ['change', 'beforeClickSubmit']
        }
      ],
      limit_type: [
        {
          func: createValidateDropDownNotEmpty('limit_type', t),
          when: ['change', 'beforeClickSubmit']
        }
      ],
      limit_ids: [
        {
          func: createValidateDropDownNotEmpty('limit_ids', t),
          when: ['change', 'beforeClickSubmit']
        }
      ],
      code: [
        {
          func: createValidateNotEmpty('code', t),
          when: ['change', 'beforeClickSubmit']
        }
      ]
    }
  }, [t])

  const globalDialog = useGlobalDialog()

  const formToRequest = (form: FormType) => {
    return ({
      file: form.file as File,
      id: form.activityWalletTitleId,
      multiple: Number(form.multiple),
      expired_at: form.expired_at.end,
      limit_type: form.limit_type,
      limit_ids: form.limit_ids,
      code: form.code
    }) as {
      file: File
      id: number
      multiple: number
      expired_at: Date
      code: string
    } & ({ limit_type: ActivityWalletLimitType.GAME_CATEGORY, limit_ids: GameCategoryType[] } | { limit_type: ActivityWalletLimitType.GAME, limit_ids: GameType[] })
  }

  const [handleBack] = useRedirectHandleBack({
    path: Path.ACTIVITY_WALLET
  })
  const { handleSubmit } = useDialogHandleSubmit({
    formToRequest,
    globalDialog,
    dialogId: 'createActivityWallet',
    gdkFunc: (payload) => gdk.activity.postActivityWalletCreateCsv(payload),
    gdkFuncDependencies: [gdk],
    getChangeDialogConfig: (form) => {
      const count = res?.data.length ?? 0
      const title = options.find((item) => item.value === form.activityWalletTitleId)?.name ?? ''
      const cash = res?.data.reduce((accu, curr) => BcMath.base(accu).add(Number(curr.amount)).get(), '0.0000')
      return createGlobalDialogConfig({
        showIcon: false,
        message: t('dialog.confirmCreateActivityWallet', { count, title, cash })
      })
    },
    getSuccessDialogConfig: () => createGlobalDialogConfig({
      showIcon: false,
      showCancel: false,
      message: t('dialog.sendSuccess3')
    }),
    getFailDialogConfig: (_, error) => createGlobalDialogConfig({
      showIcon: true,
      variant: 'error',
      showCancel: false,
      message: error.message
    }),
    afterSuccessDialog: handleBack
  })

  const [res, setRes] = useState<ActivityWalletCsvRes | null>(null)
  const [options, setOptions] = useState<Array<{ value: number, name: string }>>([])

  return (
    <Box paddingY={5}>
      <Box paddingX={5}>
        <Paper>
          <FormStateProvider<FormType>
            context={FormContext}
            defaultValue={defaultValue}
            onSubmit={handleSubmit}
            getValueFromEvent={getValueFromEvent}
            validation={validation}
          >
            <Box padding={4}>
              <Grid container direction="column" spacing={6}>
                <Grid item>
                  <Box
                    paddingY={1.25}
                    paddingX={2}
                    className={classes.pinkTitleBar}
                  >
                    <Grid container justify="space-between" alignItems="center">
                      <Grid>
                        <Typography variant="h5">
                          {t('page.activityWalletCreate')}
                        </Typography>
                      </Grid>
                      <Grid>
                        <a
                          href={csvTemplate}
                          download={`${t('common.template')}.csv`}
                          className={classes.anchor}
                        >
                          {t('common.downloadCsvTemplate')}
                        </a>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
                <Step1 res={res} setRes={setRes} pageFlow={pageFlow} />
                { res && (<Step2 pageFlow={pageFlow} options={options} setOptions={setOptions} />)}
              </Grid>
            </Box>
          </FormStateProvider>
        </Paper>
      </Box>
    </Box>
  )
}

export default React.memo(ActivityWalletCreatePage)

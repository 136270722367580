import React, { useState, useCallback, useRef, useEffect } from 'react'
import MuiTextField from '@material-ui/core/TextField'
import useT from '../../../i18ns/admin/useT'
import { useInternValue } from '../../../utils/default/FormHook'
import useGlobalDialog from '../../../providers/admin/dialog/useGlobalDialog'
import { createGlobalDialogConfig } from '../../../utils/default/DialogHelper'
import PlayerInputButton from './PlayerInputButton'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { useChecker } from '../../../utils/admin/AdminRouteHook'
import { PermissionType } from '@golden/gdk-admin'

interface PropTypes {
  id: number
  nickname: string
  reload: () => void
}

const TextField = React.memo(MuiTextField)

const PlayerNicknameInput: React.FC<PropTypes> = (props) => {
  const { id, nickname, reload } = props
  const gdk = useGDK()
  const globalDialog = useGlobalDialog()
  const [value, setValue] = useInternValue<string>(nickname, nickname)
  const [disabled, setDisabled] = useState<boolean>(true)
  const writable = useChecker([PermissionType.PLAYER_MANAGEMENT_INFO_NICKNAME])
  const { t } = useT()
  const ref = useRef<HTMLInputElement | null>(null)
  const handleUpdate = useCallback(() => setDisabled(false), [])
  const handleSave = useCallback(() => {
    gdk.player.updatePlayerNickname(id, value).subscribe({
      next: () => {
        setDisabled(true)
        reload()
      },
      error: (error) => {
        globalDialog.setConfig(createGlobalDialogConfig({
          showIcon: true,
          showCancel: false,
          variant: 'error',
          message: error.message
        }))
        globalDialog.setOpen({
          id: 'setNicknameFail',
          value: true,
          isOK: false
        })
      }
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gdk, id, value])
  useEffect(() => {
    if (!disabled) ref.current?.focus()
  }, [disabled])
  return (
    <TextField
      label={t('common.playerNickname')}
      InputProps={{
        inputRef: ref,
        endAdornment: writable && (
          <PlayerInputButton
            title={disabled ? t('common.update') : t('common.save')}
            onClick={disabled ? handleUpdate : handleSave}
          />
        )
      }}
      FormHelperTextProps={{ error: !disabled }}
      value={value}
      onChange={useCallback((event) => {
        setValue(event.target.value)
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [])}
      disabled={disabled}
      fullWidth
    />
  )
}

export default React.memo(PlayerNicknameInput)

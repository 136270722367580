import React from 'react'
import { AgentType, PlayerRankType, GuardType } from '@golden/gdk-admin'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import OnOffCheckbox from '../../default/form/OnOffCheckbox'
import { useInternValue } from '../../../utils/default/FormHook'
import agentTypeName from '../../../constants/default/agentTypeName'
import playerRankName from '../../../constants/default/playerRankName'
import useT from '../../../i18ns/admin/useT'

export interface PropTypes {
  label: string
  required?: boolean
  defaultValue?: Array<{ role: GuardType, value: AgentType | PlayerRankType }>
  value?: Array<{ role: GuardType, value: AgentType | PlayerRankType }>
  onChange?: (value: Array<{ role: GuardType, value: AgentType | PlayerRankType }>) => void
  disabled?: boolean
}

const getAgents = (): AgentType[] => {
  return Object.keys(agentTypeName)
    .map((key) => Number(key))
    .filter((key) => key >= AgentType.EMPEROR && key <= AgentType.STAFF)
}

const getPlayers = (): PlayerRankType[] => {
  const result = Object.keys(playerRankName) as PlayerRankType[]
  result.reverse()
  return result
}

const isChecked = (
  permissions: Array<{ role: GuardType, value: AgentType | PlayerRankType }>,
  role: GuardType,
  value: AgentType | PlayerRankType
): boolean => {
  return permissions.find((item) => item.role === role && item.value === value) !== undefined
}

const checkOne = (
  permissions: Array<{ role: GuardType, value: AgentType | PlayerRankType }>,
  role: GuardType,
  value: AgentType | PlayerRankType
): Array<{ role: GuardType, value: AgentType | PlayerRankType }> => {
  return isChecked(permissions, role, value)
    ? permissions.filter((item) => !(item.role === role && item.value === value))
    : permissions.concat({ role, value })
}

const isManagersChecked = (permissions: Array<{ role: GuardType, value: AgentType | PlayerRankType }>): boolean => {
  const filteredPermissions = permissions.filter((item) => item.role === GuardType.AGENT)
  return getAgents()
    .every((key) => filteredPermissions.find((item) => item.value === key) !== undefined)
}

const checkManagers = (permissions: Array<{ role: GuardType, value: AgentType | PlayerRankType }>):
Array<{ role: GuardType, value: AgentType | PlayerRankType }> => {
  const agents = getAgents()
  return isManagersChecked(permissions)
    ? permissions.filter((item) => !(item.role === GuardType.AGENT && agents.includes(item.value as AgentType)))
    : permissions.concat(agents.map((key) => ({ role: GuardType.AGENT, value: key })))
}

const isPlayersChecked = (permissions: Array<{ role: GuardType, value: AgentType | PlayerRankType }>): boolean => {
  const filteredPermissions = permissions.filter((item) => item.role === GuardType.USER)
  return getPlayers()
    .every((key) => filteredPermissions.find((item) => item.value === key) !== undefined)
}

const checkPlayers = (permissions: Array<{ role: GuardType, value: AgentType | PlayerRankType }>):
Array<{ role: GuardType, value: AgentType | PlayerRankType }> => {
  const players = getPlayers()
  return isPlayersChecked(permissions)
    ? permissions.filter((item) => !(item.role === GuardType.USER && players.includes(item.value as PlayerRankType)))
    : permissions.concat(players.map((key) => ({ role: GuardType.USER, value: key })))
}

const isAllChecked = (permissions: Array<{ role: GuardType, value: AgentType | PlayerRankType }>): boolean => {
  return isManagersChecked(permissions) &&
    isPlayersChecked(permissions)
}

const checkAll = (permissions: Array<{ role: GuardType, value: AgentType | PlayerRankType }>):
Array<{ role: GuardType, value: AgentType | PlayerRankType }> => {
  const agents = getAgents().map((key) => ({ role: GuardType.AGENT, value: key }))
  const players = getPlayers().map((key) => ({ role: GuardType.USER, value: key }))
  return isAllChecked(permissions)
    ? []
    : agents.concat(players as any)
}

const AnnouncementPermissionCheckboxGroup: React.FC<PropTypes> = (props) => {
  const { label, defaultValue, value, onChange, required, disabled } = props
  const { t } = useT()
  const [internValue, setInternValue] = useInternValue(
    defaultValue ?? value ?? [] as Array<{ role: GuardType, value: AgentType | PlayerRankType }>,
    value
  )
  return (
    <Grid container direction="column">
      <Typography>{label}{required && '*'}</Typography>
      <Grid item container direction="row">
        <Grid item xs={12} md={6} lg={2}>
          <OnOffCheckbox
            label={t('common.allPlateform')}
            value={isAllChecked(internValue)}
            onChange={() => {
              setInternValue((value) => {
                const newValue = checkAll(value)
                if (onChange) onChange(newValue)
                return newValue
              })
            }}
            disabled={disabled}
          />
        </Grid>
      </Grid>
      <Grid item container direction="row">
        <Grid item xs={12} md={2} lg={2}>
          <OnOffCheckbox
            label={t('common.allManager')}
            value={isManagersChecked(internValue)}
            onChange={() => {
              setInternValue((value) => {
                const newValue = checkManagers(value)
                if (onChange) onChange(newValue)
                return newValue
              })
            }}
            disabled={disabled}
          />
        </Grid>
        {
          getAgents()
            .map((key) => (
              <Grid key={key} item xs={12} md={2} lg={2}>
                <OnOffCheckbox
                  label={t(agentTypeName[key])}
                  value={isChecked(internValue, GuardType.AGENT, key)}
                  onChange={() => {
                    setInternValue((value) => {
                      const newValue = checkOne(value, GuardType.AGENT, key)
                      if (onChange) onChange(newValue)
                      return newValue
                    })
                  }}
                  disabled={disabled}
                />
              </Grid>
            ))
        }
      </Grid>
      <Grid item container direction="row">
        <Grid item xs={12} md={2} lg={2}>
          <OnOffCheckbox
            label={t('common.allPlayer')}
            value={isPlayersChecked(internValue)}
            onChange={() => {
              setInternValue((value) => {
                const newValue = checkPlayers(value)
                if (onChange) onChange(newValue)
                return newValue
              })
            }}
            disabled={disabled}
          />
        </Grid>
        {
          getPlayers()
            .map((key) => (
              <Grid key={key} item xs={12} md={2} lg={2}>
                <OnOffCheckbox
                  label={t(playerRankName[key])}
                  value={isChecked(internValue, GuardType.USER, key)}
                  onChange={() => {
                    setInternValue((value) => {
                      const newValue = checkOne(value, GuardType.USER, key)
                      if (onChange) onChange(newValue)
                      return newValue
                    })
                  }}
                  disabled={disabled}
                />
              </Grid>
            ))
        }
      </Grid>
    </Grid>
  )
}

export default React.memo(AnnouncementPermissionCheckboxGroup)

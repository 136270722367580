import React, { useCallback } from 'react'
import { AnnouncementStatusType, GDKError } from '@golden/gdk-admin'
import useT from '../../../i18ns/admin/useT'
import { useDebouncedCallback } from 'use-debounce/lib'
import { isAfter, isBefore } from 'date-fns'
import useGlobalDialog from '../../../providers/admin/dialog/useGlobalDialog'
import { createGlobalDialogConfig } from '../../../utils/default/DialogHelper'
import { useDialogFlow } from '../../../utils/default/DialogHook'
import { useNavigate } from 'react-router'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { useDialogHandleClick } from '../../../utils/default/ComplexFlowHook'
import allRoute from '../route/route'
import Switch from '../Switch'
import { useChecker } from '../../../utils/admin/AdminRouteHook'

interface PropTypes {
  id: number
  status: AnnouncementStatusType
  endAt: Date
  reload: () => void
}

const AnnouncementTableStatus: React.FC<PropTypes> = (props) => {
  const { id, status, reload, endAt } = props
  const globalDialog = useGlobalDialog()
  const gdk = useGDK()
  const navigate = useNavigate()
  const { t } = useT()
  const writable = useChecker()

  const [handleStart] = useDebouncedCallback(useCallback(() => {
    if (status === AnnouncementStatusType.NOT_START || isAfter(endAt, new Date())) {
      globalDialog.setConfig(createGlobalDialogConfig({
        showIcon: false,
        message: t('dialog.toggleOnAnnouncementQuestion')
      }))
      globalDialog.setOpen({ id: `startAnnouncement-${id}`, value: true, isOK: false })
    } else {
      globalDialog.setConfig(createGlobalDialogConfig({
        showIcon: false,
        message: t('dialog.anoouncementOutdateTip')
      }))
      globalDialog.setOpen({ id: `updateAnnouncement-${id}`, value: true, isOK: false })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t]), 50)

  useDialogFlow(globalDialog, `startAnnouncement-${id}`, () => {
    const subscription = gdk.announcement.toggleStatus(id, true)
      .subscribe({
        next: () => {
          globalDialog.setConfig(createGlobalDialogConfig({
            showIcon: false,
            message: t('dialog.toggleOnAnnouncementFinish'),
            showCancel: false
          }))
          globalDialog.setOpen({ id: `startAnnouncementSuccess-${id}`, value: true, isOK: false })
          reload()
        },
        error: (error: GDKError) => {
          globalDialog.setConfig(createGlobalDialogConfig({
            variant: 'error',
            showIcon: true,
            message: error.message,
            showCancel: false
          }))
          globalDialog.setOpen({ id: `startAnnouncementFail-${id}`, value: true, isOK: false })
        }
      })
    return () => {
      if ([`startAnnouncementSuccess-${id}`, `startAnnouncementFail-${id}`].includes(globalDialog.open.id)) {
        globalDialog.clearState()
        subscription.unsubscribe()
      }
    }
  }, [globalDialog.open, t])

  useDialogFlow(globalDialog, `updateAnnouncement-${id}`, () => {
    globalDialog.setOpen({ id: '', value: false, isOK: false })
    navigate(allRoute.forestageAnnouncementUpdate.encodePath({ search: {}, param: { id } }))
    return () => globalDialog.clearState()
  }, [globalDialog.open, navigate])

  const handleStop = useDialogHandleClick({
    dialogId: `stopAnnouncement-${id}`,
    globalDialog,
    changeDialogConfig: createGlobalDialogConfig({
      showIcon: false,
      message: t('dialog.toggleOffAnnouncementQuestion')
    }),
    successDialogConfig: createGlobalDialogConfig({
      showIcon: false,
      message: t('dialog.toggleOffAnnouncementFinish'),
      showCancel: false
    }),
    getFailDialogConfig: (error: string) => createGlobalDialogConfig({
      variant: 'error',
      showIcon: true,
      message: error,
      showCancel: false
    }),
    payload: id,
    gdkFunc: (payload) => gdk.announcement.toggleStatus(payload, false),
    gdkFuncDependencies: [gdk],
    onSuccess: () => reload(),
    onSuccessDependencies: [reload]
  })
  return (<>
    <Switch
      value={status === AnnouncementStatusType.START}
      onChange={(value) => {
        value ? handleStart() : handleStop()
        return false
      }}
      disabled={!writable}
    />
    { isBefore(endAt, new Date()) && (<div>{t('announcementStatus.outDate')}</div>) }
  </>)
}

export default React.memo(AnnouncementTableStatus)

import React, { useState, useMemo, useCallback, createContext, Dispatch, SetStateAction, useEffect } from 'react'
import {
  PaginationRes,
  ManualDeposit,
  ADMIN_API,
  GDKError,
  PermissionType,
  getTargetURL,
  NowTime,
  ManualDepositType
} from '@golden/gdk-admin'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import { searchToRequest } from './ManualDepositForm'
import CoreTable from '../../default/present/CoreTable'
import LoadingAndErrorFrame from '../../default/frames/LoadingAndErrorFrame'
import ScrollablePaper from '../../default/present/ScrollablePaper'
import { useRequestFromSearch, useGetDataByPayload, usePaginationClickAndChangeUrl, usePerPageChange, useReload } from '../../../utils/default/ComplexFlowHook'
import { usePageFlow } from '../../../utils/default/PageFlowHook'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { createDefaultPaginationData, createTableData, formatMoney, formatCount } from '../../../utils/default/TableHelper'
import useT from '../../../i18ns/admin/useT'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import allRoute from '../route/route'
import DateTime from '../../default/present/DateTime'
import MemoPopoverWithContext from '../../default/memo/MemoPopoverWithContext'
import { createDefaultMemoPopoverPayload, MemoPopoverPayload } from '../../default/memo/MemoPopover'
import useGDKStore from '../../../providers/admin/gdk/useGDKStore'
import MemoTextWithTitleAndContext from '../../default/memo/MemoTextWithTitleAndContext'
import PointsCell from '../../default/present/PointsCell'
import manualDepositName from '../../../constants/admin/manualDepositName'
import { useDebouncedCallback } from 'use-debounce/lib'
import useGlobalDialog from '../../../providers/admin/dialog/useGlobalDialog'
import { createGlobalDialogConfig } from '../../../utils/default/DialogHelper'
import { getServerUrl } from '../../../utils/default/StageHelper'
import { useChecker } from '../../../utils/admin/AdminRouteHook'
import { timer } from 'rxjs'
import ManualDepoistUpdateBindingDepositsDialog from './ManualDepoistUpdateBindingDepositsDialog'

const useStyles = makeStyles((theme) => ({
  noMargin: {
    margin: '0px auto'
  },
  lockText: {
    color: theme.palette.common.black
  }
}))

interface SumRowType {
  id: number
  count: string
  cash: string
}

interface RowType {
  id: number
  account: string
  staffAccount: string
  orderNumber: React.ReactElement
  cash: React.ReactElement
  balance: string
  type: string
  memo: React.ReactElement
  createdAt: React.ReactElement
  updatedAt: React.ReactElement
  updatedBy: string
  batchId: React.ReactElement
  isExcludingSpecifiedGames: string
  exchangedActivity: string
  multiple: number
  bindingDeposits: React.ReactElement | string
}

const MemoContext = createContext<[MemoPopoverPayload, Dispatch<SetStateAction<MemoPopoverPayload>>]>([
  createDefaultMemoPopoverPayload(),
  () => {}
])

const SerialNumberButton: React.FC<{ serialNumber: string, active: boolean, reload: () => void }> = (props) => {
  // MEMO: 因規格變更，修改用彈窗目前沒有場景會顯示，未來可能再次開放．詳見 https://app.clickup.com/t/3x5ezrw
  const { serialNumber } = props
  /*
  const { t } = useT()
  const commonClasses = useCommonStyles()
  const [open, setOpen] = useState(false)
  const writable = useChecker()
  const close = useCallback(() => {
    setOpen(false)
  }, [setOpen])
  */
  return <>{serialNumber}</>
  /*
  if (!writable || !active) return <>{serialNumber}</>
  return (
    <React.Fragment>
      <span
        className={commonClasses.anchor}
        onClick={() => setOpen(true)}
      >
        {serialNumber}
      </span>
      <ManualDepoistUpdateDialog serialNumber={serialNumber} open={open} close={close} title={t('common.updateSinglePlayer')} reload={reload} />
    </React.Fragment>
  )
  */
}

const BatchButton: React.FC<{ batchId: string, reload: () => void, active: boolean }> = (props) => {
  // MEMO: 因規格變更，修改用彈窗目前沒有場景會顯示，未來可能再次開放．詳見 https://app.clickup.com/t/3x5ezrw
  const { batchId } = props
  /*
  const { t } = useT()
  const commonClasses = useCommonStyles()
  const writable = useChecker()
  const [open, setOpen] = useState(false)
  const close = useCallback(() => {
    setOpen(false)
  }, [setOpen])
  */
  return <>{batchId}</>
  /*
  if (!writable || !active) return <>{batchId}</>
  return (
    <React.Fragment>
      <span
        className={(batchId !== '--' ? commonClasses.anchor : '')}
        onClick={() => {
          if (batchId === '--') return
          return setOpen(true)
        }}
      >
        {batchId}
      </span>
      <ManualDepoistUpdateDialog batchId={batchId} open={open} close={close} title={t('common.updateBatch')} reload={reload} />
    </React.Fragment>
  )
  */
}

const BindingDepositButton: React.FC<{ list: string[], reload: () => void, id: number, memo: string, account: string, cash: string }> = ({ id, list, reload, memo, account, cash }) => {
  const { t } = useT()
  const commonClasses = useCommonStyles()
  const [open, setOpen] = useState(false)
  const close = useCallback(() => {
    setOpen(false)
  }, [setOpen])

  const text = useMemo(() => {
    if (list.length === 0) return '--'
    if (list.length > 1) return `${list.length} ${t('common.amountShort')}`
    return list[0]
  }, [t, list])

  return (
    <React.Fragment>
      <span
        className={commonClasses.anchor}
        onClick={useCallback(() => setOpen(true), [])}
      >
        {text}
      </span>
      <ManualDepoistUpdateBindingDepositsDialog open={open} close={close} reload={reload} id={id} bindingDeposits={list} memo={memo} account={account} cash={cash} />
    </React.Fragment>
  )
}

const ManualDepositTable: React.FC = () => {
  const classes = useStyles()
  const commonClasses = useCommonStyles()
  const { t } = useT()
  const gdk = useGDK()
  const me = useGDKStore.admin.me()
  const [list, setList] = useState<PaginationRes<Array<ManualDeposit<Date>>> & { cash_sum: string }>({
    ...createDefaultPaginationData([]),
    cash_sum: '0.0000'
  })
  const writableExport = useChecker([PermissionType.MANUAL_DEPOSIT_EXPORT])
  const { reload, reloadFlag } = useReload()
  const pageFlow = usePageFlow()
  const globalDialog = useGlobalDialog()
  const request = useRequestFromSearch({ searchToRequest })
  const [time, setTime] = useState(NowTime.get())

  useEffect(() => {
    const subscription = timer(0, 1000).subscribe(() => setTime(NowTime.get()))
    return () => subscription.unsubscribe()
  }, [])

  useGetDataByPayload({
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    payload: request!,
    gdkFunc: (payload) => gdk.manual.getDepositList(payload),
    gdkFuncDependencies: [gdk, reloadFlag],
    onBeforeFetch: pageFlow.setLoadingStart,
    onSuccess: (res: PaginationRes<Array<ManualDeposit<Date>>> & { cash_sum: string }) => {
      setList(res)
      pageFlow.setContentShow()
    },
    onError: pageFlow.setGDKError,
    canLoadData: request !== undefined
  })
  const tableClasses = useMemo(() => ({
    head: commonClasses.pinkTableHead,
    row: commonClasses.tableRow,
    cellHead: commonClasses.tableCellHead,
    cellBody: commonClasses.pre
  }), [commonClasses])
  const sumRows: SumRowType[] = useMemo(() => {
    return [{
      id: 1,
      count: formatCount(list.total),
      cash: formatMoney(list.cash_sum)
    }] as SumRowType[]
  }, [list.cash_sum, list.total])
  const rows: RowType[] = useMemo(() => {
    return list.data.map((item, index) => {
      return {
        id: index,
        account: item.account,
        staffAccount: item.staff_account,
        orderNumber: (
          <SerialNumberButton serialNumber={item.order_number} active={false} reload={reload} />
        ),
        cash: (<PointsCell points={item.cash} />),
        balance: formatMoney(item.balance),
        type: t(manualDepositName[item.method]),
        memo: (<MemoTextWithTitleAndContext context={MemoContext} title={item.memo} memo={item.memo} />),
        createdAt: (<DateTime time={item.created_at} />),
        updatedAt: (<DateTime time={item.updated_at} />),
        updatedBy: item.updated_by,
        batchId: (<BatchButton batchId={item.batch_id} reload={reload} active={false} />),
        isExcludingSpecifiedGames: item.is_excluding_specified_games ? 'v' : 'x',
        exchangedActivity: item.activity_name ? item.activity_name : '-',
        multiple: item.multiple,
        bindingDeposits: item.method === ManualDepositType.EVENT ? ((<BindingDepositButton id={item.id} list={item.binding_deposits} reload={reload} memo={item.memo} account={item.account} cash={item.cash} />)) : '--'
      } as RowType
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [classes.lockText, list.data, t, me, time])
  const sumData = useMemo(() => {
    return createTableData<SumRowType>(
      {
        id: {
          label: '',
          value: 'id'
        },
        count: {
          label: t('common.totalCount'),
          value: 'count',
          align: 'center'
        },
        cash: {
          label: t('common.totalCash'),
          value: 'cash',
          align: 'center'
        }
      },
      [
        'count',
        'cash'
      ],
      sumRows,
      'id'
    )
  }, [sumRows, t])
  const data = useMemo(() => {
    return createTableData<RowType>(
      {
        id: {
          label: '',
          value: 'id'
        },
        account: {
          label: t('common.playerAccount'),
          value: 'account',
          align: 'center'
        },
        staffAccount: {
          label: t('common.staffAccount'),
          value: 'staffAccount',
          align: 'center'
        },
        orderNumber: {
          label: t('common.serialNumber'),
          value: 'orderNumber',
          align: 'center'
        },
        cash: {
          label: t('common.depositCash'),
          value: 'cash',
          align: 'right',
          width: 120
        },
        multiple: {
          label: `${t('common.deltaEffectiveCash')}${t('common.multiple')}`,
          value: 'multiple',
          align: 'center'
        },
        balance: {
          label: t('common.mainAccount'),
          value: 'balance',
          align: 'center'
        },
        type: {
          label: t('common.depositObject'),
          value: 'type',
          align: 'center'
        },
        memo: {
          label: t('common.playerMemo'),
          value: 'memo',
          align: 'center'
        },
        createdAt: {
          label: t('common.createdAt'),
          value: 'createdAt',
          align: 'center'
        },
        updatedAt: {
          label: t('common.updateAt'),
          value: 'updatedAt',
          align: 'center'
        },
        updatedBy: {
          label: t('common.updateBy'),
          value: 'updatedBy',
          align: 'center'
        },
        batchId: {
          label: t('common.batchNumber'),
          value: 'batchId',
          align: 'center'
        },
        isExcludingSpecifiedGames: {
          label: `${t('common.exclude')}${t('excludeType.specifiedGames')}`,
          value: 'isExcludingSpecifiedGames',
          align: 'center'
        },
        exchangedActivity: {
          label: t('common.exchangedActivity'),
          value: 'exchangedActivity',
          align: 'center'
        },
        bindingDeposits: {
          label: t('common.exchangedDepositOrderNo'),
          value: 'bindingDeposits',
          align: 'left'
        }
      },
      [
        'account',
        'staffAccount',
        'orderNumber',
        'cash',
        'multiple',
        'balance',
        'type',
        'isExcludingSpecifiedGames',
        'memo',
        'createdAt',
        'updatedAt',
        'updatedBy',
        'batchId',
        'exchangedActivity',
        'bindingDeposits'
      ],
      rows,
      'id'
    )
  }, [rows, t])
  const handlePagination = usePaginationClickAndChangeUrl({
    request,
    encodePath: allRoute.manualDeposit.encodePath
  })
  const handlePerPageChange = usePerPageChange({
    request,
    encodePath: allRoute.manualDeposit.encodePath,
    gdk,
    pageKey: ADMIN_API.GET_MANUAL_DEPOSIT_LIST.url
  })

  const [handleDebouncedExport] = useDebouncedCallback(() => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    gdk.manual.exportManualDeposit(request!)
      .subscribe({
        next: (res) => {
          const link = document.createElement('a')
          link.href = getTargetURL(getServerUrl('api'), res.url.replace(/\/api/i, ''))
          link.click()
          link.remove()
        },
        error: (err: GDKError) => {
          globalDialog.setConfig(createGlobalDialogConfig({
            variant: 'error',
            message: err.message,
            showCancel: false
          }))
          globalDialog.setOpen({ id: 'exportManualDepositFailed', value: true, isOK: false })
        }
      })
  }, 200)

  if (request === undefined) return null
  return (
    <MemoPopoverWithContext memoPopoverContext={MemoContext}>
      <Box paddingBottom={3}>
        <ScrollablePaper marginX={5}>
          <Box padding={4}>
            <LoadingAndErrorFrame { ...pageFlow.status }>
              <CoreTable
                classes={tableClasses}
                data={sumData}
                total={1}
              />
            </LoadingAndErrorFrame>
          </Box>
        </ScrollablePaper>
      </Box>
      <ScrollablePaper marginX={5}>
        <Box padding={4}>
          <LoadingAndErrorFrame { ...pageFlow.status }>
            {!!list.total && writableExport && (
              <Grid container justifyContent="flex-end">
                <Box marginBottom={2}>
                  <Button
                    onClick={handleDebouncedExport}
                    className={commonClasses.purpleGradualButton}
                  >
                    {t('common.export')}
                  </Button>
                </Box>
              </Grid>
            )}
            <CoreTable
              classes={tableClasses}
              data={data}
              total={list.total}
              showPagination
              page={request.page}
              perPage={list.per_page}
              onChangePage={handlePagination}
              onChangeRowsPerPage={handlePerPageChange}
            />
          </LoadingAndErrorFrame>
        </Box>
      </ScrollablePaper>
    </MemoPopoverWithContext>
  )
}

export default React.memo(ManualDepositTable)

import React, { useMemo, createContext, useCallback, useState, useEffect } from 'react'
import { FetchBtiStateType, GDKError } from '@golden/gdk-admin'
import { Subscription, timer } from 'rxjs'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import TextField, { TextFieldProps } from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Typography from '@material-ui/core/Typography'
import FormStateProvider from '../../default/form/FormStateProvider'
import FormField from '../../default/form/FormField'
import FormSubmitButton from '../../default/form/FormSubmitButton'
import { ValueGetter, FormValidation, createDefaultFormState } from '../../../utils/default/FormHook'
import { InitialFormFunc } from '../../../utils/default/ComplexFlowHook'
import { getValueFromChangeEvent } from '../../../utils/default/FormHelper'
import useT from '../../../i18ns/admin/useT'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import useGDK from '../../../providers/admin/gdk/useGDK'

export interface FormType {
  serialNumbers: string
}

export const initialForm: InitialFormFunc<FormType> = (defaultForm) => ({
  serialNumbers: '',
  ...defaultForm
})

const defaultForm = initialForm()
const FormContext = createContext(createDefaultFormState(defaultForm))

const getValueFromEvent: ValueGetter<FormType> = {
  serialNumbers: getValueFromChangeEvent
}

export interface PropTypes {
  open: boolean
  onClose: () => void
}

const FetchBtiDialog: React.FC<PropTypes> = (props) => {
  const classes = useCommonStyles()
  const [loading, setLoading] = useState<boolean>(false)
  const [orders, setOrders] = useState<string[]>([])
  const [results, setResults] = useState<Array<{
    serialNumber: string
    state: FetchBtiStateType | 'error' | 'pending'
    subscription: Subscription | null
    error: string | null
  }>>([])
  const { open, onClose } = props
  const { t } = useT()
  const gdk = useGDK()
  const validation = useMemo(() => {
    return {
      serialNumbers: []
    } as FormValidation<FormType>
  }, [])
  const hasUnsettled = useMemo(() => {
    return results.some((result) => result.state === FetchBtiStateType.UNSETTLED)
  }, [results])
  const hasSettled = useMemo(() => {
    return results.some((result) => result.state === FetchBtiStateType.SETTLED)
  }, [results])
  const hasNotFound = useMemo(() => {
    return results.some((result) => result.state === FetchBtiStateType.NOT_FOUND)
  }, [results])
  const hasError = useMemo(() => {
    return results.some((result) => result.state === 'error')
  }, [results])
  useEffect(() => {
    const newOrders = orders.slice().reverse()
    const timerSubscription = timer(1000, 1000).subscribe(() => {
      if (newOrders.length !== 0) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const order = newOrders.pop()!
        const handleSuccess = (response: { result: FetchBtiStateType }) => {
          if (response.result === FetchBtiStateType.IGNORED) {
            newOrders.push(order)
          } else {
            setResults((results) => {
              return results
                .filter((result) => result.serialNumber !== order)
                .concat({
                  serialNumber: order,
                  state: response.result,
                  subscription: null,
                  error: null
                })
            })
          }
        }
        const handleError = (error: GDKError) => {
          setResults((results) => {
            return results
              .filter((result) => result.serialNumber !== order)
              .concat({
                serialNumber: order,
                state: 'error',
                subscription: null,
                error: error.message
              })
          })
        }
        const subscription = gdk.platform.getFetchState(order).subscribe({ next: handleSuccess, error: handleError })
        setResults((results) => {
          return results
            .filter((result) => result.serialNumber !== order)
            .concat({
              serialNumber: order,
              state: 'pending',
              subscription,
              error: null
            })
        })
      } else {
        setLoading(false)
      }
    })
    return () => timerSubscription.unsubscribe()
  }, [orders, gdk])
  const handleSubmit = useCallback((form: FormType) => {
    setResults((results) => {
      results.forEach((result) => result.subscription?.unsubscribe())
      return []
    })
    setOrders(
      form.serialNumbers
        .split('\n')
        .map((order) => order.trim())
        .filter((order) => order !== '')
    )
    setLoading(true)
    return form
  }, [])
  const handleCancel = useCallback(() => {
    setResults((results) => {
      results.forEach((result) => result.subscription?.unsubscribe())
      return []
    })
    setOrders([])
    onClose()
  }, [onClose])
  return (
    <Dialog open={open} fullWidth maxWidth="sm">
      <Box padding={2} className={classes.dialogPinkHeader}>
        {t('common.purchaseId')}
      </Box>
      <FormStateProvider
        context={FormContext}
        defaultValue={defaultForm}
        onSubmit={handleSubmit}
        getValueFromEvent={getValueFromEvent}
        validation={validation}
      >
        <DialogContent>
          <Box padding={3}>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <FormField<FormType, TextFieldProps>
                  context={FormContext}
                  component={TextField}
                  name="serialNumbers"
                  variant="outlined"
                  placeholder={t('common.useEnterSplit')}
                  multiline
                  fullWidth
                  required
                  rows={3}
                  rowsMax={10}
                  disabled={loading}
                />
              </Grid>
              {hasUnsettled && (
                <Grid item>
                  <Typography>
                    {t('common.unsettleList')}
                  </Typography>
                  {results.filter((result) => result.state === FetchBtiStateType.UNSETTLED).map((result) => (
                    <Typography>
                      {result.serialNumber}
                    </Typography>
                  ))}
                </Grid>
              )}
              {hasSettled && (
                <Grid item>
                  <Typography>
                    {t('common.settleList')}
                  </Typography>
                  {results.filter((result) => result.state === FetchBtiStateType.SETTLED).map((result) => (
                    <Typography>
                      {result.serialNumber}
                    </Typography>
                  ))}
                </Grid>
              )}
              {hasNotFound && (
                <Grid item>
                  <Typography>
                    {t('common.notFoundList')}
                  </Typography>
                  {results.filter((result) => result.state === FetchBtiStateType.NOT_FOUND).map((result) => (
                    <Typography>
                      {result.serialNumber}
                    </Typography>
                  ))}
                </Grid>
              )}
              {hasError && (
                <Grid item>
                  <Typography>
                    {t('common.otherWithColon')}
                  </Typography>
                  {results.filter((result) => result.state === 'error').map((result) => (
                    <Typography>
                      {result.serialNumber} - {result.error}
                    </Typography>
                  ))}
                </Grid>
              )}
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Box padding={3} width="100%">
            <Grid container direction="row" justify="center" spacing={2}>
              <Grid item>
                <Button
                  className={classes.greyButton}
                  onClick={handleCancel}
                >
                  {t('common.cancel')}
                </Button>
              </Grid>
              <Grid item>
                <FormSubmitButton
                  context={FormContext}
                  component={Button}
                  className={classes.purpleGradualButton}
                  type="submit"
                  disabled={loading}
                >
                  {loading ? (<CircularProgress size={24} />) : t('common.updateSerialNumber')}
                </FormSubmitButton>
              </Grid>
            </Grid>
          </Box>
        </DialogActions>
      </FormStateProvider>
    </Dialog>
  )
}

export default React.memo(FetchBtiDialog)

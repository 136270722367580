import React from 'react'
import { Link } from 'react-router-dom'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import RoleManagementTable from '../../../components/admin/manager/RoleManagementTable'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import { Path } from '../../../components/admin/route/route'
import useT from '../../../i18ns/admin/useT'
import { useChecker } from '../../../utils/admin/AdminRouteHook'
import ScrollablePaper from '../../../components/default/present/ScrollablePaper'

const RoleManagementPage: React.FC = () => {
  const classes = useCommonStyles()
  const { t } = useT()
  const writable = useChecker()
  return (
    <Box paddingY={6}>
      <ScrollablePaper marginX={6}>
        <Box padding={6}>
          {writable && (
            <Box paddingBottom={2} display="flex" justifyContent="flex-end">
              <Button
                component={Link}
                to={Path.ROLE_MANAGEMENT_CREATE}
                className={classes.purpleGradualButton}
                classes={{ disabled: classes.disabledButton }}
              >
                <Box paddingX={2.5}>
                  {t('page.createRole')}
                </Box>
              </Button>
            </Box>
          )}
          <RoleManagementTable />
        </Box>
      </ScrollablePaper>
    </Box>
  )
}

export default React.memo(RoleManagementPage)

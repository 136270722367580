import React, { useState, useMemo, useCallback, useEffect, createContext, Dispatch, SetStateAction } from 'react'
import { Link } from 'react-router-dom'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import ScrollablePaper from '../../../components/default/present/ScrollablePaper'
import { FeedbackFrequency, Feedback, Branch, PlayerRankType, FeedbackChannel, GameStatus } from '@golden/gdk-admin'
import useT from '../../../i18ns/admin/useT'
import feedbackFrequencyName from '../../../constants/admin/feedbackFrequencyName'
import DropDown from '../../../components/default/form/DropDown'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import { formatMoney, createTableData, ColumnCollection } from '../../../utils/default/TableHelper'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { useGetDataByPayload } from '../../../utils/default/ComplexFlowHook'
import { usePageFlow } from '../../../utils/default/PageFlowHook'
import DateTime from '../../../components/default/present/DateTime'
import allRoute from '../../../components/admin/route/route'
import { useChecker } from '../../../utils/admin/AdminRouteHook'
import CoreTable from '../../../components/default/present/CoreTable'
import LoadingAndErrorFrame from '../../../components/default/frames/LoadingAndErrorFrame'
import useGDKStore from '../../../providers/admin/gdk/useGDKStore'
import playerRankName from '../../../constants/default/playerRankName'
import FeedbackDetailPanel from '../../../components/admin/externalGame/FeedbackDetailPanel'
import StateProvider from '../../../providers/default/StateProvider'
import { makeStyles } from '@material-ui/core'
import { enumValues, mergeArray } from '@golden/utils'

const useStyles = makeStyles(() => ({
  rowSelect: {
    backgroundColor: '#f4effe'
  }
}))

interface RowType extends Record<PlayerRankType, string> {
  id: number
  game: string
  frequency: string
  count: React.ReactElement
  operatedAt: React.ReactElement
  operator: string
  function: React.ReactElement
  detail: FeedbackChannel[]
}

const StateContext = createContext<[
  number[],
  Dispatch<SetStateAction<number[]>>
]>([
  [],
  () => {}
])

const stage = import.meta.env.VITE_APP_STAGE

const ExternalGameFeedbackPage: React.FC = () => {
  const gdk = useGDK()
  const { t } = useT()
  const aliveGames = useGDKStore.platform.aliveGames()
  const commonClasses = useCommonStyles()
  const classes = useStyles()
  const pageFlow = usePageFlow()
  const writable = useChecker()
  const [payload, setPayload] = useState<{ period?: FeedbackFrequency }>({})
  const [res, setRes] = useState<Feedback[]>([])
  useEffect(() => {
    gdk.trunk.trigger([Branch.PLATFORMS])
  }, [gdk])
  const options = useMemo(() => {
    return [{ name: t('common.allFrequency'), value: '--' }].concat(
      Object.keys(feedbackFrequencyName)
        .map((key) => Number(key) as FeedbackFrequency)
        .filter((key) => {
          if (stage === 'prod' && key === FeedbackFrequency.RIGHT_NOW) {
            return false
          }
          return true
        })
        .map((key) => ({ name: t(feedbackFrequencyName[key]), value: key })) as any
    )
  }, [t])
  const tableClasses = useMemo(() => ({
    head: commonClasses.pinkTableHead,
    row: commonClasses.tableRow,
    cellHead: commonClasses.tableCellHead,
    cellBody: commonClasses.pre,
    rowSelect: classes.rowSelect
  }), [commonClasses, classes.rowSelect])
  useGetDataByPayload({
    payload,
    gdkFunc: (payload) => gdk.platform.getFeedbackList(payload),
    gdkFuncDependencies: [gdk],
    onBeforeFetch: pageFlow.setLoadingStart,
    onSuccess: (res: Feedback[]) => {
      setRes(res)
      pageFlow.setContentShow()
    },
    onError: pageFlow.setGDKError
  })
  const rows = useMemo(() => mergeArray(aliveGames, res, (game, item) => game.id === item.game_id).map(([game, item]) => {
    const rankDataObject = Object.keys(playerRankName)
      .map((key) => key as PlayerRankType)
      .map((key) => {
        const feedback = item.ranks.find((rank) => rank.rank === key)
        if (!feedback) return { [key]: t('common.notAdapt') }
        return {
          [key.toString()]: `${formatMoney(feedback.feedback)}%`
        }
      })
      .reduce((item, obj) => Object.assign({}, obj, item), {})
    return {
      id: item.id,
      game: game.nameWithStatus,
      frequency: item.period ? t(feedbackFrequencyName[item.period]) : t('common.noSetting'),
      ...rankDataObject,
      count: item.channels.length === 0
        ? (<p>-</p>)
        : (
        <StateContext.Consumer>
          {([, setSelects]) => (
            <span
              className={commonClasses.purpleAnchor}
              onClick={() => {
                setSelects((selects) => {
                  if (selects.includes(item.id)) {
                    return selects.filter((id) => id !== item.id)
                  }
                  return selects.concat(item.id)
                })
              }}
            >
              {item.channels.length}
            </span>
          )}
        </StateContext.Consumer>
          ),
      operatedAt: (<DateTime time={item.updated_at} />),
      operator: item.updated_by,
      function: (
        <Button
          component={Link}
          to={allRoute.externalGameFeedbackUpdate.encodePath({ search: {}, param: { id: item.id } })}
          className={commonClasses.blueGradualOutlineButton}
          classes={{ disabled: commonClasses.blueGradualOutlineDisabledButton }}
          disabled={(game.status ?? GameStatus.STOP_SALE) === GameStatus.STOP_SALE}
        >
          {t('common.update')}
        </Button>
      ),
      detail: item.channels
    }
  }) as RowType[], [res, aliveGames, t, commonClasses.blueGradualOutlineButton, commonClasses.blueGradualOutlineDisabledButton, commonClasses.purpleAnchor])
  const data = useMemo(() => {
    const rankColumnObject = Object.keys(playerRankName)
      .map((key) => key as PlayerRankType)
      .map((key) => ({
        [key]: {
          label: t(playerRankName[key]),
          value: key,
          align: 'center'
        }
      }))
      .reduce((item, obj) => Object.assign({}, obj, item), {})
    return createTableData<RowType>(
      {
        id: {
          label: '',
          value: 'id'
        },
        game: {
          label: t('common.gamePlatform'),
          value: 'game',
          align: 'center'
        },
        frequency: {
          label: t('common.feedbackFrequency'),
          value: 'frequency',
          align: 'center'
        },
        ...rankColumnObject,
        count: {
          label: t('common.gameFeedbackContent'),
          value: 'count',
          align: 'center'
        },
        operatedAt: {
          label: t('common.updateAt'),
          value: 'operatedAt',
          align: 'center'
        },
        operator: {
          label: t('common.updateBy'),
          value: 'operator',
          align: 'center'
        },
        function: {
          label: t('common.function'),
          value: 'function',
          align: 'center'
        },
        detail: {
          label: '',
          value: 'detail'
        }
      } as ColumnCollection<RowType>,
      [
        'game',
        'frequency',
        ...enumValues(PlayerRankType),
        'count',
        'operatedAt',
        'operator',
        ...(writable ? ['function'] as const : [])
      ],
      rows,
      'id'
    )
  }, [rows, t, writable])
  return (
    <Box paddingY={6}>
      <ScrollablePaper marginX={6}>
        <Box padding={5}>
          <Box paddingBottom={2}>
            <Grid container justify="flex-end">
              <Grid item xs={12} md={6} lg={3}>
                <DropDown
                  label=""
                  className={commonClasses.outlineDropDown}
                  variant="outlined"
                  options={options}
                  value={payload.period ?? '--'}
                  onChange={useCallback((event) => {
                    if (event.target.value !== '--') {
                      setPayload((payload) => ({ ...payload, period: event.target.value }))
                    }
                  }, [])}
                  fullWidth
                />
              </Grid>
            </Grid>
          </Box>
          <LoadingAndErrorFrame { ...pageFlow.status }>
            <StateProvider
              context={StateContext}
              defaultValue={[]}
            >
              <StateContext.Consumer>
                {([selects]) => (
                  <CoreTable
                    data={data}
                    total={res.length}
                    classes={tableClasses}
                    isSelected
                    selected={selects}
                    collapse={FeedbackDetailPanel}
                  />
                )}
              </StateContext.Consumer>
            </StateProvider>
          </LoadingAndErrorFrame>
        </Box>
      </ScrollablePaper>
    </Box>
  )
}

export default React.memo(ExternalGameFeedbackPage)

import React, { createContext, useMemo, useContext, useCallback } from 'react'
import clsx from 'clsx'
import QrScanner from 'qr-scanner'
import { makeStyles } from '@material-ui/core/styles'
import { useDropzone } from 'react-dropzone'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import MuiButton from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import MuiTextField, { TextFieldProps } from '@material-ui/core/TextField'
import FormField from '../../default/form/FormField'
import FormSubmitButton from '../../default/form/FormSubmitButton'
import { FormPropType, getValueFromChangeEvent, getValueFromCheckboxEvent, getValueFromValue, getCashInputProps, getCodeInputProps } from '../../../utils/default/FormHelper'
import { InitialFormFunc } from '../../../utils/default/ComplexFlowHook'
import { createDefaultFormState, ValueGetter, FormValidation } from '../../../utils/default/FormHook'
import useT from '../../../i18ns/admin/useT'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import { DepositMethodType, ClientType } from '@golden/gdk-admin'
import RequiredText from '../../default/form/RequiredText'
import FormStateProvider from '../../default/form/FormStateProvider'
import { createValidateNotEmpty, createValidateCash, createValidateMin, createValidateMax, createErrorResult, createCorrectResult, createValidateRange } from '../../../utils/default/Validator'
import depositTypeName from '../../../constants/default/depositTypeName'
import NumberInput from '../../default/form/NumberInput'
import OnOffCheckbox from '../../default/form/OnOffCheckbox'
import { createGlobalDialogConfig } from '../../../utils/default/DialogHelper'
import useGlobalDialog from '../../../providers/admin/dialog/useGlobalDialog'
import useGDK from '../../../providers/admin/gdk/useGDK'
import clientTypeName from '../../../constants/admin/clientTypeName'
import PlayerLayerInput, { PropTypes as PlayerLayerInputProps, PlayerLayerValue } from '../PlayerLayerInput'
import CheckboxGroup, { PropTypes as CheckboxGroupProps } from '../../default/form/CheckboxGroup'
import ClearIcon from '@material-ui/icons/Clear'

const useStyles = makeStyles(() => ({
  icon: {
    fontSize: 48
  },
  input: {
    display: 'none'
  },
  dragSection: {
    cursor: 'pointer'
  },
  label: {
    fontWeight: 600
  },
  imageContainer: {
    position: 'relative'
  },
  clear: {
    position: 'absolute',
    top: '0',
    right: '0',
    cursor: 'pointer',
    color: '#fff',
    padding: '1px',
    background: '#fb9678'
  }
}))

export interface EditFinancePiggyWechatFormType {
  name: string
  account: string
  min: string
  max: string
  receiver: string
  memo: string
  isActive: boolean
  clients: ClientType[]
  layer: PlayerLayerValue
  code: string
  qr: string
}

export const initialForm: InitialFormFunc<EditFinancePiggyWechatFormType> = (defaultForm) => ({
  name: '',
  account: '',
  min: '',
  max: '',
  receiver: '',
  memo: '',
  isActive: false,
  clients: [],
  layer: {
    layers: [],
    isForProxyDeposit: false
  },
  code: '',
  qr: '',
  ...defaultForm
})

const getValueFromEvent: ValueGetter<EditFinancePiggyWechatFormType> = {
  name: getValueFromChangeEvent,
  account: getValueFromChangeEvent,
  min: getValueFromChangeEvent,
  max: getValueFromChangeEvent,
  receiver: getValueFromChangeEvent,
  memo: getValueFromChangeEvent,
  isActive: getValueFromCheckboxEvent,
  code: getValueFromChangeEvent,
  clients: getValueFromValue,
  layer: getValueFromValue,
  qr: getValueFromValue
}

interface PropTypes extends FormPropType<EditFinancePiggyWechatFormType> {
  disableName?: boolean
  disableAccount?: boolean
  okText: string
  onBack: () => void
}

const memoFormHelperTextProps = { error: true }

const cashInputProps = getCashInputProps()

const codeInputProps = getCodeInputProps()

const FormContext = createContext(createDefaultFormState(initialForm()))

const TextField = React.memo(MuiTextField)

const Button = React.memo(MuiButton)

const FileUploader: React.FC = React.memo(() => {
  const classes = useStyles()
  const gdk = useGDK()
  const commonClasses = useCommonStyles()
  const globalDialog = useGlobalDialog()
  const { value, dispatch } = useContext(FormContext)
  const { t } = useT()
  const handleFileUpload = useCallback((file: File | undefined) => {
    if (file) {
      QrScanner.scanImage(file)
        .then((result) => {
          dispatch({ type: 'change', value: result, label: 'qr' })
        })
        .catch((error) => {
          console.error(error)
          globalDialog.setConfig(createGlobalDialogConfig({
            showIcon: true,
            variant: 'error',
            showCancel: false,
            message: t('common.qrcodeInvalidate')
          }))
          globalDialog.setOpen({
            id: 'qrCodeError',
            isOK: false,
            value: true
          })
        })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const handleDrop = useCallback((files) => {
    const file = files[0]
    handleFileUpload(file)
  }, [handleFileUpload])
  const handleUpload = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    handleFileUpload(file)
  }, [handleFileUpload])
  const { getRootProps, getInputProps } = useDropzone({ onDrop: handleDrop })
  return (
    <div className={clsx(commonClasses.grayFrameContainer, classes.dragSection)} {...getRootProps()}>
      <input
        type="file"
        accept=".png,.jpg,.jpeg"
        className={classes.input}
        onChange={handleUpload}
        {...getInputProps()}
      />
      {value.qr && value.qr !== ''
        ? (
        <div className={classes.imageContainer}>
          <ClearIcon className={classes.clear} onClick={(event) => {
            dispatch({ type: 'change', value: '', label: 'qr' })
            event.stopPropagation()
          }}/>
          <img src={gdk.qrcode.generateURL(value.qr, 200)} alt="" width="150" height="150" />
        </div>
          )
        : (
        <Grid container direction="column" justify="center" alignItems="center">
          <Grid item>
            <CloudUploadIcon className={classes.icon} />
          </Grid>
          <Grid item>
            <p>{t('common.dropOrClick')} <span className={commonClasses.anchor}>{t('common.chooseFile')}</span> {t('common.uploadQRCode')}</p>
          </Grid>
        </Grid>
          )}
    </div>
  )
})

const EditFinancePiggyWechatForm: React.FC<PropTypes> = (props) => {
  const {
    defaultValue,
    onSubmit,
    onBack,
    okText,
    disableAccount,
    disableName
  } = props
  const classes = useStyles()
  const commonClasses = useCommonStyles()
  const { t } = useT()

  const validation = useMemo(() => {
    return {
      name: [
        {
          func: createValidateNotEmpty('name', t),
          when: ['blur', 'beforeClickSubmit']
        }
      ],
      account: [
        {
          func: createValidateNotEmpty('account', t),
          when: ['change', 'beforeClickSubmit']
        }
      ],
      min: [
        {
          func: createValidateNotEmpty('min', t),
          when: ['change', 'beforeClickSubmit']
        },
        {
          func: createValidateCash('min', t),
          when: ['change', 'beforeClickSubmit']
        },
        {
          func: createValidateMin('min', 'max', t),
          when: ['change', 'beforeClickSubmit']
        }
      ],
      max: [
        {
          func: createValidateNotEmpty('max', t),
          when: ['change', 'beforeClickSubmit']
        },
        {
          func: createValidateCash('max', t),
          when: ['change', 'beforeClickSubmit']
        },
        {
          func: createValidateMax('min', 'max', t),
          when: ['change', 'beforeClickSubmit']
        }
      ],
      receiver: [],
      memo: [],
      isActive: [],
      code: [
        {
          func: createValidateNotEmpty('code', t),
          when: ['change', 'beforeClickSubmit']
        }
      ],
      qr: [],
      clients: [
        {
          func: createValidateRange('clients', (value) => (value as any[]).length, 'moreThan', 0, t('error.mustNotEmpty')),
          when: ['beforeClickSubmit']
        }
      ],
      layer: [
        {
          func: (value) => {
            const layer = (value as PlayerLayerValue)
            if (layer.layers.length === 0 && !layer.isForProxyDeposit) return createErrorResult('layer', t('error.mustNotEmpty'))
            return createCorrectResult('layer')
          },
          when: ['beforeClickSubmit']
        }
      ],
      isForProxyDeposit: []
    } as FormValidation<EditFinancePiggyWechatFormType>
  }, [t])

  const clientOptions = useMemo(() => {
    return Object.keys(clientTypeName)
      .map((key) => Number(key) as ClientType)
      .map((key) => ({ name: t(clientTypeName[key]), value: key }))
  }, [t])

  const memoInputProps = useMemo(() => ({
    classes: { input: commonClasses.memoField },
    inputProps: {
      placeholder: t('placeholder.inputMemo')
    }
  }), [commonClasses.memoField, t])

  return (
    <FormStateProvider
      context={FormContext}
      defaultValue={defaultValue}
      onSubmit={onSubmit}
      getValueFromEvent={getValueFromEvent}
      validation={validation}
    >
      <Grid container direction="column" spacing={3}>
        <Grid item>
          <Grid container direction="row" spacing={2}>
            <Grid item xs={12} md={3}>
              <FormField<EditFinancePiggyWechatFormType, TextFieldProps>
                context={FormContext}
                component={TextField}
                name="name"
                fullWidth
                required
                label={t('common.wechatName')}
                disabled={disableName}
                inputProps={{ maxLength: 10 }}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                label={t('common.depositWay')}
                value={t(depositTypeName[DepositMethodType.SCAN_WECHAT])}
                disabled
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <FormField<EditFinancePiggyWechatFormType, TextFieldProps>
                context={FormContext}
                component={TextField}
                name="account"
                fullWidth
                required
                label={t('common.wechatAccount')}
                placeholder={t('placeholder.inputWechatAccount')}
                disabled={disableAccount}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container direction="row" spacing={2}>
            <Grid item xs={12} md={3}>
              <FormField<EditFinancePiggyWechatFormType, TextFieldProps>
                context={FormContext}
                component={NumberInput}
                name="min"
                fullWidth
                required
                label={t('common.minDepositMoney')}
                inputProps={cashInputProps}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <FormField<EditFinancePiggyWechatFormType, TextFieldProps>
                context={FormContext}
                component={NumberInput}
                name="max"
                fullWidth
                required
                label={t('common.maxDepositMoney')}
                inputProps={cashInputProps}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <FormField<EditFinancePiggyWechatFormType, TextFieldProps>
                context={FormContext}
                component={TextField}
                name="receiver"
                fullWidth
                label={t('common.playerSideReceiver')}
                placeholder={t('placeholder.inputPlayerSideReceiver')}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Typography className={classes.label}>{t('common.memoForPlayer')}</Typography>
          <FormField<EditFinancePiggyWechatFormType, TextFieldProps>
            context={FormContext}
            component={TextField}
            name="memo"
            multiline
            fullWidth
            type="text"
            margin="normal"
            variant="outlined"
            InputProps={memoInputProps}
            FormHelperTextProps={memoFormHelperTextProps}
          />
        </Grid>
        <Grid item>
          <FormField<EditFinancePiggyWechatFormType, CheckboxGroupProps>
            context={FormContext}
            component={CheckboxGroup}
            name="clients"
            label={t('common.platformVersion')}
            options={clientOptions}
            required
          />
        </Grid>
        <Grid item>
          <FormField<EditFinancePiggyWechatFormType, PlayerLayerInputProps>
            context={FormContext}
            component={PlayerLayerInput}
            name="layer"
            label={t('common.playerLayer')}
            required
            proxyDepositDisabled={false}
            isShowProxyDeposit={false}
          />
        </Grid>
        <Grid item>
          <Typography className={commonClasses.bold}>{t('common.status')}</Typography>
          <FormField
            component={OnOffCheckbox}
            context={FormContext}
            name="isActive"
            label={t('common.isStartPiggyWechat')}
          />
        </Grid>
        <Grid item>
          <Box
            paddingY={1.25}
            paddingX={2}
            className={commonClasses.pinkTitleBar}
          >
            <Typography variant="h5">
              {t('common.personWechatAccount')}
            </Typography>
          </Box>
        </Grid>
        <Grid item>
          <Grid container direction="row" spacing={2}>
            <Grid item xs={12} md={3}>
              <FormField<EditFinancePiggyWechatFormType, TextFieldProps>
                context={FormContext}
                component={NumberInput}
                name="code"
                fullWidth
                required
                inputProps={codeInputProps}
                label={t('common.googleCode')}
                placeholder={t('placeholder.inputGoogleCode2')}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <FileUploader />
        </Grid>
        <Grid item>
          <Box whiteSpace="pre">
            <Typography color="error">{t('common.fileSizeTip')}</Typography>
          </Box>
        </Grid>
        <Grid item>
          <RequiredText />
        </Grid>
        <Grid item>
          <Grid container direction="row" justify="flex-end" spacing={2}>
            <Grid item>
              <Button
                className={commonClasses.greyButton}
                onClick={onBack}
              >
                {t('common.cancel')}
              </Button>
            </Grid>
            <Grid item>
              <FormSubmitButton
                component={Button}
                context={FormContext}
                className={commonClasses.purpleGradualButton}
                type="submit"
              >
                {okText}
              </FormSubmitButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </FormStateProvider>
  )
}

export default React.memo(EditFinancePiggyWechatForm)

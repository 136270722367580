import { PlayerJumpShipReasonType } from '@golden/gdk-admin'
import TKeyType from '../../i18ns/admin/TKeyType'

const playerJumpShipReasonName: { [key in PlayerJumpShipReasonType]: TKeyType } = {
  [PlayerJumpShipReasonType.PLAYER_TO_PLATFORM]: 'playerJumpShipReason.playerToPlatform',
  [PlayerJumpShipReasonType.LONG_TIME_NO_LOGIN]: 'playerJumpShipReason.longTimeNoLogin',
  [PlayerJumpShipReasonType.STUPID_PLAYER]: 'playerJumpShipReason.stupidPlayer',
  [PlayerJumpShipReasonType.ABANDON_PLAYER]: 'playerJumpShipReason.abandonPlayer',
  [PlayerJumpShipReasonType.CUSTOM]: 'common.custom'
}

export default playerJumpShipReasonName

import React, { useState, useMemo, createContext, Dispatch, SetStateAction } from 'react'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import useT from '../../../i18ns/admin/useT'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { createDefaultPaginationData, formatCount, formatMoney, createTableData } from '../../../utils/default/TableHelper'
import { PaginationRes, PaginationReq, ActivityType, ActivityNewYear2024, ActivityNewYear2024Query, ActivityNewYear2024Sum, ActivityNewYear2024StatusType } from '@golden/gdk-admin'
import { usePageFlow } from '../../../utils/default/PageFlowHook'
import { searchToRequest } from './ActivityRecordDetailForm'
import { useRequestFromSearch, useGetDataByPayload, usePaginationClickAndChangeUrl } from '../../../utils/default/ComplexFlowHook'
import DateTime from '../../default/present/DateTime'
import ScrollablePaper from '../../default/present/ScrollablePaper'
import useGDKStore from '../../../providers/admin/gdk/useGDKStore'
import CoreTable from '../../default/present/CoreTable'
import allRoute, { Path } from '../route/route'
import LoadingAndErrorFrame from '../../default/frames/LoadingAndErrorFrame'
import MemoPopoverWithContext from '../../default/memo/MemoPopoverWithContext'
import { MemoPopoverPayload, createDefaultMemoPopoverPayload } from '../../default/memo/MemoPopover'
import { useLocation } from 'react-router-dom'
import { newYear2024StatusName } from '../../../constants/default/activityStatusName'

interface SumRowType2 {
  id: number
  totalBonus: string
  bonus3888: string
  bonus1888: string
  bonus888: string
  bonus388: string
  bonus188: string
  bonus88: string
  bonus38: string
  bonus18: string
  bonus0: string
}

interface RowType {
  id: number
  order: string
  sentTime: React.ReactElement
  account: string
  staffAccount: string
  content: string
  status: React.ReactElement | string
  bonus: string
  receivedAt: React.ReactElement | string
  updatedBy: string
  memo: string
}

const MemoContext = createContext<[MemoPopoverPayload, Dispatch<SetStateAction<MemoPopoverPayload>>]>([
  createDefaultMemoPopoverPayload(),
  () => {}
])

const ActivityRecordNewYear2024Table: React.FC = () => {
  const classes = useCommonStyles()
  const { t } = useT()
  const gdk = useGDK()
  const activities = useGDKStore.activity.activities()
  const location = useLocation()
  const [list, setList] = useState<PaginationRes<ActivityNewYear2024[]> & ActivityNewYear2024Sum>({
    ...createDefaultPaginationData([]),
    bonus_3888_count: 0,
    bonus_1888_count: 0,
    bonus_888_count: 0,
    bonus_388_count: 0,
    bonus_188_count: 0,
    bonus_88_count: 0,
    bonus_38_count: 0,
    bonus_18_count: 0,
    bonus_0_count: 0,
    total_bonus: '0,0000'
  })
  const pageFlow = usePageFlow()
  const request = useRequestFromSearch({ searchToRequest })
  useGetDataByPayload({
    payload: request as ActivityNewYear2024Query & PaginationReq,
    gdkFunc: (payload) => gdk.activity.getActivityRecordNewYear2024(payload),
    gdkFuncDependencies: [gdk],
    onBeforeFetch: pageFlow.setLoadingStart,
    onSuccess: (res: PaginationRes<ActivityNewYear2024[]> & ActivityNewYear2024Sum) => {
      setList(res)
      pageFlow.setContentShow()
    },
    onError: pageFlow.setGDKError,
    canLoadData: request !== undefined
  })
  const tableClasses = useMemo(() => ({
    head: classes.greyTableHead,
    row: classes.tableRow,
    cellHead: classes.tableCellHead,
    cellBody: classes.nowrap
  }), [classes])

  const sumRow = useMemo(() => {
    return [{
      id: 1,
      bonus3888: formatCount(list.bonus_3888_count),
      bonus1888: formatCount(list.bonus_1888_count),
      bonus888: formatCount(list.bonus_888_count),
      bonus388: formatCount(list.bonus_388_count),
      bonus188: formatCount(list.bonus_188_count),
      bonus88: formatCount(list.bonus_88_count),
      bonus38: formatCount(list.bonus_38_count),
      bonus18: formatCount(list.bonus_18_count),
      bonus0: formatCount(list.bonus_0_count),
      totalBonus: formatMoney(list.total_bonus)
    }] as SumRowType2[]
  }, [list])
  const sumData = useMemo(() => {
    return createTableData<SumRowType2>(
      {
        id: {
          label: '',
          value: 'id'
        },
        bonus3888: {
          label: '3888',
          value: 'bonus3888',
          align: 'center'
        },
        bonus1888: {
          label: '1888',
          value: 'bonus1888',
          align: 'center'
        },
        bonus888: {
          label: '888',
          value: 'bonus888',
          align: 'center'
        },
        bonus388: {
          label: '388',
          value: 'bonus388',
          align: 'center'
        },
        bonus188: {
          label: '188',
          value: 'bonus188',
          align: 'center'
        },
        bonus88: {
          label: '88',
          value: 'bonus88',
          align: 'center'
        },
        bonus38: {
          label: '38',
          value: 'bonus38',
          align: 'center'
        },
        bonus18: {
          label: '18',
          value: 'bonus18',
          align: 'center'
        },
        bonus0: {
          label: '0',
          value: 'bonus0',
          align: 'center'
        },
        totalBonus: {
          label: t('common.totalActivityBonus'),
          value: 'totalBonus',
          align: 'center'
        }
      },
      [
        'bonus3888',
        'bonus1888',
        'bonus888',
        'bonus388',
        'bonus188',
        'bonus88',
        'bonus38',
        'bonus18',
        'bonus0',
        'totalBonus'
      ],
      sumRow,
      'id'
    )
  }, [t, sumRow])
  const rows = useMemo(() => {
    return list.data.map((item, index) => {
      return {
        id: index,
        order: item.serial_number,
        sentTime: (<DateTime time={item.applied_at} />),
        account: item.account,
        staffAccount: item.staff_account,
        content: item.activity_content,
        status: t(newYear2024StatusName[item.status]),
        bonus: (item.status === ActivityNewYear2024StatusType.LOST) ? '0.0000' : formatMoney(item.bonus),
        receivedAt: item.received_at ? (<DateTime time={item.received_at} />) : ('--'),
        updatedBy: item.updated_by,
        memo: item.memo
      } as RowType
    })
  }, [list.data, t])
  const data = useMemo(() => {
    return createTableData<RowType>(
      {
        id: {
          label: '',
          value: 'id'
        },
        order: {
          label: t('common.activitySerial'),
          value: 'order',
          align: 'center'
        },
        sentTime: {
          label: t('common.applyTime'),
          value: 'sentTime',
          align: 'center'
        },
        account: {
          label: t('common.playerAccount'),
          value: 'account',
          align: 'center'
        },
        staffAccount: {
          label: t('common.staffAccount'),
          value: 'staffAccount',
          align: 'center'
        },
        content: {
          label: t('common.activityContent'),
          value: 'content',
          align: 'center'
        },
        status: {
          label: t('common.status'),
          value: 'status',
          align: 'center'
        },
        bonus: {
          label: t('common.activityBonus'),
          value: 'bonus',
          align: 'right'
        },
        receivedAt: {
          label: t('common.updatedAt'),
          value: 'receivedAt',
          align: 'center'
        },
        updatedBy: {
          label: t('common.updateBy'),
          value: 'updatedBy',
          align: 'center'
        },
        memo: {
          label: t('common.memo'),
          value: 'memo',
          align: 'center'
        }
      },
      [
        'order',
        'sentTime',
        'account',
        'staffAccount',
        'content',
        'status',
        'bonus',
        'receivedAt',
        'updatedBy',
        'memo'
      ],
      rows,
      'id'
    )
  }, [t, rows])
  const handlePagination = usePaginationClickAndChangeUrl({
    request,
    encodePath: (location.pathname === Path.ACTIVITY_RECORD_DETAIL) ? allRoute.activityRecordDetail.encodePath : allRoute.activityRecordSub.encodePath
  })
  return (
    <MemoPopoverWithContext memoPopoverContext={MemoContext}>
      <Box paddingBottom={3}>
        <ScrollablePaper marginX={5}>
          <Box padding={5}>
            <LoadingAndErrorFrame { ...pageFlow.status }>
              <Box
                paddingY={1.25}
                paddingX={2}
                className={classes.pinkTitleBar}
              >
                <Typography variant="h5">
                  {activities.find((item) => item.type === ActivityType.NEW_YEAR_2024)?.name ?? ''}
                </Typography>
              </Box>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={12}>
                  <Box paddingY={2}>
                    <Typography variant="h5">
                      {t('common.bonusStatisticResult')}
                    </Typography>
                  </Box>
                  <CoreTable
                    data={sumData}
                    total={1}
                    classes={tableClasses}
                  />
                </Grid>
              </Grid>
            </LoadingAndErrorFrame>
          </Box>
        </ScrollablePaper>
      </Box>
      <ScrollablePaper marginX={5}>
        <Box padding={5}>
          <LoadingAndErrorFrame { ...pageFlow.status }>
            <CoreTable
              data={data}
              total={list.total}
              classes={tableClasses}
              showPagination
              page={request?.page ?? 1}
              onChangePage={handlePagination}
            />
          </LoadingAndErrorFrame>
        </Box>
      </ScrollablePaper>
    </MemoPopoverWithContext>
  )
}

export default React.memo(ActivityRecordNewYear2024Table)

import React, { useMemo } from 'react'
import useT from '../../i18ns/admin/useT'
import DropDown, { PropTypes as DropDownProps } from '../default/form/DropDown'
import MultipleSelector, { PropTypes as MultipleSelectorProps } from '../default/form/MultipleSelector'
import { GameCategoryType, GameStatus } from '@golden/gdk-admin'
import { useInternValue } from '../../utils/default/FormHook'
import useGDKStore from '../../providers/admin/gdk/useGDKStore'

type SingleValueType = GameCategoryType | 'all'
type MultipleValueType = GameCategoryType[]
type ValueType = SingleValueType | MultipleValueType
type OmitType = 'value' | 'defaultValue' | 'onChange' | 'options'

export type PropTypes = Omit<DropDownProps, OmitType> &
Omit<MultipleSelectorProps, OmitType> & {
  value?: ValueType
  defaultValue?: ValueType
  filterNoCategories?: GameCategoryType[]
  filterNoStopSaleGame?: true
  onChange?: (value: ValueType) => void
  multiple?: boolean
  noAll?: boolean
  required?: boolean
  fullWidth?: boolean
}

const GameCategoryInput: React.FC<PropTypes> = React.memo((props) => {
  const { value = 'all', defaultValue, onChange, multiple = false, label, noAll = false, required = false, fullWidth = false, filterNoCategories, filterNoStopSaleGame } = props
  const { t } = useT()
  const [internValue, setInternValue] = useInternValue<PropTypes['value']>(defaultValue ?? value, value)
  const categories = useGDKStore.platform.categories()
  const options = useMemo(() => [
    ...(noAll ? [] : [{ name: t('common.all'), value: 'all' }]),
    ...categories
      .filter(item => !filterNoCategories?.includes(item.instance.id))
      .filter(item => filterNoStopSaleGame ? item.games.some(game => game.status !== GameStatus.STOP_SALE && game.status !== GameStatus.REMOVED) : true)
      .map((category) => ({ value: category.instance.id, name: category.instance.name }))
  ], [categories, noAll, t, filterNoCategories, filterNoStopSaleGame])
  if (multiple) {
    return (
      <MultipleSelector
        defaultValue={defaultValue as Array<string | number>}
        value={internValue as Array<string | number>}
        label={label ?? t('common.gameType')}
        options={options}
        fullWidth={fullWidth}
        required={required}
        onChange={(value) => {
          setInternValue(value as MultipleValueType)
          if (onChange) onChange(value as MultipleValueType)
        }}
      />
    )
  }
  return (
    <DropDown
      value={internValue}
      onChange={(event) => {
        const value = event.target.value as SingleValueType
        setInternValue(value)
        if (onChange) onChange(value)
      }}
      label={label ?? t('common.gameType')}
      options={options}
      fullWidth={fullWidth}
      required={required}
    />
  )
})

export default React.memo(GameCategoryInput)

import React, { useState, useMemo, createContext, Dispatch, SetStateAction } from 'react'
import {
  PaginationRes,
  ManualWithdraw,
  ADMIN_API,
  GDKError,
  PermissionType,
  getTargetURL
} from '@golden/gdk-admin'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import { searchToRequest } from './ManualWithdrawForm'
import CoreTable from '../../default/present/CoreTable'
import LoadingAndErrorFrame from '../../default/frames/LoadingAndErrorFrame'
import ScrollablePaper from '../../default/present/ScrollablePaper'
import { useRequestFromSearch, useGetDataByPayload, usePaginationClickAndChangeUrl, usePerPageChange } from '../../../utils/default/ComplexFlowHook'
import { usePageFlow } from '../../../utils/default/PageFlowHook'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { createDefaultPaginationData, createTableData, formatMoney, formatCount } from '../../../utils/default/TableHelper'
import useT from '../../../i18ns/admin/useT'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import allRoute from '../route/route'
import DateTime from '../../default/present/DateTime'
import MemoPopoverWithContext from '../../default/memo/MemoPopoverWithContext'
import { createDefaultMemoPopoverPayload, MemoPopoverPayload } from '../../default/memo/MemoPopover'
import useGDKStore from '../../../providers/admin/gdk/useGDKStore'
import MemoTextWithTitleAndContext from '../../default/memo/MemoTextWithTitleAndContext'
import PointsCell from '../../default/present/PointsCell'
import manualWithdrawName from '../../../constants/default/manualWithdrawName'
import { useDebouncedCallback } from 'use-debounce/lib'
import useGlobalDialog from '../../../providers/admin/dialog/useGlobalDialog'
import { createGlobalDialogConfig } from '../../../utils/default/DialogHelper'
import { getServerUrl } from '../../../utils/default/StageHelper'
import { useChecker } from '../../../utils/admin/AdminRouteHook'

const useStyles = makeStyles((theme) => ({
  noMargin: {
    margin: '0px auto'
  },
  lockText: {
    color: theme.palette.common.black
  }
}))

interface SumRowType {
  id: number
  count: string
  cash: string
}

interface RowType {
  id: number
  account: string
  staffAccount: string
  orderNumber: string
  cash: React.ReactElement
  balance: string
  method: string
  memo: React.ReactElement
  updatedAt: React.ReactElement
  updatedBy: string
  batchNumber: string
}

const MemoContext = createContext<[MemoPopoverPayload, Dispatch<SetStateAction<MemoPopoverPayload>>]>([
  createDefaultMemoPopoverPayload(),
  () => {}
])

const ManualWithdrawTable: React.FC = () => {
  const classes = useStyles()
  const commonClasses = useCommonStyles()
  const { t } = useT()
  const gdk = useGDK()
  const me = useGDKStore.admin.me()
  const [list, setList] = useState<PaginationRes<Array<ManualWithdraw<Date>>> & { cash_sum: string }>({
    ...createDefaultPaginationData([]),
    cash_sum: '0.0000'
  })
  const pageFlow = usePageFlow()
  const globalDialog = useGlobalDialog()
  const writableExport = useChecker([PermissionType.MANUAL_WITHDRAW_EXPORT])
  const request = useRequestFromSearch({ searchToRequest })
  useGetDataByPayload({
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    payload: request!,
    gdkFunc: (payload) => gdk.manual.getWithdrawList(payload),
    gdkFuncDependencies: [gdk],
    onBeforeFetch: pageFlow.setLoadingStart,
    onSuccess: (res: PaginationRes<Array<ManualWithdraw<Date>>> & { cash_sum: string }) => {
      setList(res)
      pageFlow.setContentShow()
    },
    onError: pageFlow.setGDKError,
    canLoadData: request !== undefined
  })
  const tableClasses = useMemo(() => ({
    head: commonClasses.pinkTableHead,
    row: commonClasses.tableRow,
    cellHead: commonClasses.tableCellHead,
    cellBody: commonClasses.pre
  }), [commonClasses])
  const sumRows: SumRowType[] = useMemo(() => {
    return [{
      id: 1,
      count: formatCount(list.total),
      cash: formatMoney(list.cash_sum)
    }] as SumRowType[]
  }, [list.cash_sum, list.total])
  const rows: RowType[] = useMemo(() => {
    return list.data.map((item, index) => {
      return {
        id: index,
        account: item.account,
        staffAccount: item.staff_account,
        orderNumber: item.order_number,
        cash: (<PointsCell points={item.cash} />),
        balance: formatMoney(item.balance),
        method: t(manualWithdrawName[item.method]),
        memo: (<MemoTextWithTitleAndContext context={MemoContext} title={item.memo} memo={item.memo} />),
        updatedAt: (<DateTime time={item.created_at} />),
        updatedBy: item.updated_by,
        batchNumber: item.batch_number
      } as RowType
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [classes.lockText, list.data, t, me])
  const sumData = useMemo(() => {
    return createTableData<SumRowType>(
      {
        id: {
          label: '',
          value: 'id'
        },
        count: {
          label: t('common.totalCount'),
          value: 'count',
          align: 'center'
        },
        cash: {
          label: t('common.totalCash'),
          value: 'cash',
          align: 'center'
        }
      },
      [
        'count',
        'cash'
      ],
      sumRows,
      'id'
    )
  }, [sumRows, t])
  const data = useMemo(() => {
    return createTableData<RowType>(
      {
        id: {
          label: '',
          value: 'id'
        },
        account: {
          label: t('common.playerAccount'),
          value: 'account',
          align: 'center'
        },
        staffAccount: {
          label: t('common.staffAccount'),
          value: 'staffAccount',
          align: 'center'
        },
        orderNumber: {
          label: t('common.serialNumber'),
          value: 'orderNumber',
          align: 'center'
        },
        cash: {
          label: t('common.withdrawCash'),
          value: 'cash',
          align: 'right'
        },
        balance: {
          label: t('common.mainAccount'),
          value: 'balance',
          align: 'center'
        },
        method: {
          label: t('common.withdrawObject'),
          value: 'method',
          align: 'center'
        },
        memo: {
          label: t('common.playerMemo'),
          value: 'memo',
          align: 'center'
        },
        updatedAt: {
          label: t('common.updateAt'),
          value: 'updatedAt',
          align: 'center'
        },
        updatedBy: {
          label: t('common.updateBy'),
          value: 'updatedBy',
          align: 'center'
        },
        batchNumber: {
          label: t('common.batchNumber'),
          value: 'batchNumber',
          align: 'center'
        }
      },
      [
        'account',
        'staffAccount',
        'orderNumber',
        'cash',
        'balance',
        'method',
        'memo',
        'updatedAt',
        'updatedBy',
        'batchNumber'
      ],
      rows,
      'id'
    )
  }, [rows, t])
  const handlePagination = usePaginationClickAndChangeUrl({
    request,
    encodePath: allRoute.manualWithdraw.encodePath
  })
  const handlePerPageChange = usePerPageChange({
    request,
    encodePath: allRoute.manualWithdraw.encodePath,
    gdk,
    pageKey: ADMIN_API.GET_MANUAL_WITHDRAW_LIST.url
  })

  const [handleDebouncedExport] = useDebouncedCallback(() => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    gdk.manual.exportManualWithdraw(request!)
      .subscribe({
        next: (res) => {
          const link = document.createElement('a')
          link.href = getTargetURL(getServerUrl('api'), res.url.replace(/\/api/i, ''))
          link.click()
          link.remove()
        },
        error: (err: GDKError) => {
          globalDialog.setConfig(createGlobalDialogConfig({
            variant: 'error',
            message: err.message,
            showCancel: false
          }))
          globalDialog.setOpen({ id: 'exportManualWithdrawFailed', value: true, isOK: false })
        }
      })
  }, 200)

  if (request === undefined) return null
  return (
    <MemoPopoverWithContext memoPopoverContext={MemoContext}>
      <Box paddingBottom={3}>
        <ScrollablePaper marginX={5}>
          <Box padding={4}>
            <LoadingAndErrorFrame { ...pageFlow.status }>
              <CoreTable
                classes={tableClasses}
                data={sumData}
                total={1}
              />
            </LoadingAndErrorFrame>
          </Box>
        </ScrollablePaper>
      </Box>
      <ScrollablePaper marginX={5}>
        <Box padding={4}>
          <LoadingAndErrorFrame { ...pageFlow.status }>
            {!!list.total && writableExport && (
              <Grid container justify="flex-end">
                <Box marginBottom={2}>
                  <Button
                    onClick={handleDebouncedExport}
                    className={commonClasses.purpleGradualButton}
                  >
                    {t('common.export')}
                  </Button>
                </Box>
              </Grid>
            )}
            <CoreTable
              classes={tableClasses}
              data={data}
              total={list.total}
              showPagination
              page={request.page}
              perPage={list.per_page}
              onChangePage={handlePagination}
              onChangeRowsPerPage={handlePerPageChange}
            />
          </LoadingAndErrorFrame>
        </Box>
      </ScrollablePaper>
    </MemoPopoverWithContext>
  )
}

export default React.memo(ManualWithdrawTable)

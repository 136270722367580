import React from 'react'
import Backdrop from '@material-ui/core/Backdrop'
import { makeStyles } from '@material-ui/core'
import AppImage from './AppImage'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.modal + 1
  },
  base: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  container: {
    width: '70%',
    height: '70%',
    display: 'flex',
    justifyContent: 'center'
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'contain'
  },
  close: {
    cursor: 'pointer',
    fontSize: 32,
    color: '#fff'
  }
}))

interface PropTypes {
  src: string
  webp?: string
  lqip?: string
  handleClose: () => void
}

const ImagePreview: React.FC<PropTypes> = (props) => {
  const { src, webp = '', lqip = '', handleClose } = props
  const classes = useStyles()

  return (
    <Backdrop
      open={true}
      className={classes.backdrop}
    >
      <div className={classes.base}>
        <div className={classes.container}>
          <AppImage
            src={src}
            webp={webp}
            lqip={lqip}
            imageClass={ classes.image}
          />
          <HighlightOffIcon
            className={classes.close}
            onClick={handleClose}
          />
        </div>
      </div>
    </Backdrop>
  )
}

export default React.memo(ImagePreview)

import React, { MouseEvent, useCallback } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Button from '../BlueButton'
import CustomThemeType from '../../../themes/default/CustomThemeType'
import useT from '../../../i18ns/admin/useT'

const useStyles = makeStyles((theme: CustomThemeType) => ({
  button: {
    wordBreak: 'keep-all'
  },
  text: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    width: theme.custom.shape.memoWidth,
    color: theme.custom.palette.blue.main,
    textDecoration: 'underline',
    cursor: 'pointer'
  }
}))

interface PropTypes {
  memo: string
  onClick?: (event: MouseEvent<HTMLElement>) => void
  onMouseEnter?: (event: MouseEvent<HTMLElement>) => void
  onMouseLeave?: (event: MouseEvent<HTMLElement>) => void
  disabled?: boolean
  onlyRead?: boolean
}

const MemoButton: React.FC<PropTypes> = (props) => {
  const { memo, onClick, onMouseEnter, onMouseLeave, disabled, onlyRead } = props
  const classes = useStyles()
  const { t } = useT()
  const handleClick = useCallback((event: MouseEvent<HTMLElement>) => {
    if (!disabled && !onlyRead && onClick !== undefined) {
      onClick(event)
    }
  }, [disabled, onlyRead, onClick])
  if ((!memo || memo.trim() === '') && !onlyRead) {
    return (
      <Button disabled={disabled} variant="outlined" onClick={handleClick} className={classes.button}>
        {t('common.memo')}
      </Button>
    )
  }
  return (
    <Box display="flex" justifyContent="center">
      <Typography
        className={classes.text}
        onClick={handleClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        {memo}
      </Typography>
    </Box>
  )
}

export default React.memo(MemoButton)

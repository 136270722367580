import { ActivityManagementStatus } from '@golden/gdk-admin'
import TKeyType from '../../i18ns/admin/TKeyType'

const activityManagementStatusName: { [key in ActivityManagementStatus]: TKeyType } = {
  [ActivityManagementStatus.EXPIRED]: 'announcementStatus.outDate',
  [ActivityManagementStatus.ACTIVE]: 'announcementStatus.start',
  [ActivityManagementStatus.DEACTIVATE]: 'announcementStatus.stop'
}

export default activityManagementStatusName

import React, { useState, useMemo, useEffect } from 'react'
import { OperationHistory, PaginationRes, SiteMessageHistory, SiteMessageType } from '@golden/gdk-admin'
import Box from '@material-ui/core/Box'
import { searchToRequest } from './JournalOperationForm'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import useT from '../../../i18ns/admin/useT'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { createDefaultPaginationData, createTableData } from '../../../utils/default/TableHelper'
import { usePageFlow } from '../../../utils/default/PageFlowHook'
import { useRequestFromSearch, useGetDataByPayload, usePaginationClickAndChangeUrl } from '../../../utils/default/ComplexFlowHook'
import DateTime from '../../default/present/DateTime'
import menuPermissionMap from '../../../constants/admin/menuPermissionMap'
import operationTypeName from '../../../constants/admin/operationTypeName'
import allRoute from '../route/route'
import LoadingAndErrorFrame from '../../default/frames/LoadingAndErrorFrame'
import ScrollablePaper from '../../default/present/ScrollablePaper'
import CoreTable from '../../default/present/CoreTable'
import { renderContent } from '@golden/tiptap-react'

interface RowType {
  id: number
  operateAt: React.ReactElement
  operator: string
  ip: string
  group: string
  route: string
  type: string
  description: string | SiteMessageHistory
}

const JournalOperationTable: React.FC = () => {
  const classes = useCommonStyles()
  const { t } = useT()
  const gdk = useGDK()
  const [list, setList] = useState<PaginationRes<OperationHistory[]>>(createDefaultPaginationData([]))
  const pageFlow = usePageFlow()
  const request = useRequestFromSearch({ searchToRequest })
  useGetDataByPayload({
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    payload: request!,
    gdkFunc: (payload) => gdk.history.getOperationHistory(payload),
    gdkFuncDependencies: [gdk],
    onBeforeFetch: pageFlow.setLoadingStart,
    onSuccess: (res: PaginationRes<OperationHistory[]>) => {
      setList(res)
      pageFlow.setContentShow()
    },
    onError: pageFlow.setGDKError,
    canLoadData: request !== undefined
  })
  const tableClasses = useMemo(() => ({
    head: classes.pinkTableHead,
    row: classes.tableRow,
    cellHead: classes.tableCellHead,
    cellBody: classes.pre
  }), [classes])

  const [rows, setRows] = useState<RowType[]>([])

  useEffect(() => {
    void Promise.all(list.data.map(async (item, index) => {
      const menuItem = menuPermissionMap.find((menuPermissionItem) => menuPermissionItem.permissions.includes(item.permission))

      let content: string = ''

      if (typeof item.description === 'string') {
        content = item.description
      } else {
        const _content = (await renderContent(item.description.content))
          .replace(/<p[^>]*>/g, '')
          .replace(/<\/p>/g, ' ')

        if (item.description.type === SiteMessageType.CREATE_MANUAL_NOTIFICATION) {
          content = `新增站内信 \n标题【${item.description.title}】\n内容【${_content}】`
        } else if (item.description.type === SiteMessageType.CREATE_MANUAL_NOTIFICATION_TEMPLATE) {
          content = `新增模板 \n标题【${item.description.title}】\n内容【${_content}】`
        } else if (item.description.type === SiteMessageType.EDIT_MANUAL_NOTIFICATION_TEMPLATE) {
          content = `编辑模板【${item.description.old_title ?? ''}】为【${item.description.title}】\n【${_content}】`
        }
      }

      return {
        id: index,
        operateAt: (
            <DateTime time={item.operate_at} />
        ),
        operator: item.account,
        ip: item.ip,
        group: menuItem ? t(menuItem.groupName) : '--',
        route: menuItem ? t(menuItem.routeName) : '--',
        type: t(operationTypeName[item.operation_type]),
        description: typeof item.description === 'string' ? item.description : <Box dangerouslySetInnerHTML={{ __html: content }} />
      } as RowType
    })).then(res => setRows(res))
  }, [list.data, t])

  const data = useMemo(() => {
    return createTableData<RowType>(
      {
        id: {
          label: '',
          value: 'id'
        },
        operateAt: {
          label: t('common.updateAt'),
          value: 'operateAt',
          align: 'center'
        },
        operator: {
          label: t('common.updateBy'),
          value: 'operator',
          align: 'center'
        },
        ip: {
          label: t('common.ipAddress'),
          value: 'ip',
          align: 'center'
        },
        group: {
          label: t('common.modifiedMainMenu'),
          value: 'group',
          align: 'center'
        },
        route: {
          label: t('common.modifiedSubMenu'),
          value: 'route',
          align: 'center'
        },
        type: {
          label: t('common.operationType'),
          value: 'type',
          align: 'center'
        },
        description: {
          label: t('common.description'),
          value: 'description',
          align: 'left'
        }
      },
      [
        'operateAt',
        'operator',
        'ip',
        'group',
        'route',
        'type',
        'description'
      ],
      rows,
      'id'
    )
  }, [rows, t])

  const handlePagination = usePaginationClickAndChangeUrl({
    request,
    encodePath: allRoute.journalOperation.encodePath
  })

  if (request === undefined) return null
  return (
    <ScrollablePaper marginX={5}>
      <Box padding={4}>
        <LoadingAndErrorFrame { ...pageFlow.status }>
          <CoreTable
            classes={tableClasses}
            data={data}
            total={list.total}
            showPagination
            page={request.page}
            onChangePage={handlePagination}
          />
        </LoadingAndErrorFrame>
      </Box>
    </ScrollablePaper>
  )
}

export default React.memo(JournalOperationTable)

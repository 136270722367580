import { BannerNotificationStatusType } from '@golden/gdk-admin'
import TKeyType from '../../i18ns/admin/TKeyType'

const bannerNotificationStatusName: { [key in BannerNotificationStatusType]: TKeyType } = {
  [BannerNotificationStatusType.ENABLE]: 'common.toggleOn',
  [BannerNotificationStatusType.DISABLE]: 'common.toggleOff',
  [BannerNotificationStatusType.EXPIRED]: 'common.expired'
}

export default bannerNotificationStatusName

import { NotificationType } from '@golden/gdk-admin'
import TKeyType from '../../i18ns/admin/TKeyType'

const notificationTypeName: { [key in NotificationType]: TKeyType } = {
  [NotificationType.REGISTER_SUCCESSFUL]: 'notificationType.registerSuccessful',
  [NotificationType.SEND_PHONE_VERIFY_CODE]: 'notificationType.sendPhoneVerifyCode',
  [NotificationType.SEND_EMAIL_VERIFY_LINK]: 'notificationType.sendEmailVerifyLink',
  [NotificationType.DEBIT_CARD_REVIEW_SUCCESSFUL]: 'notificationType.debitCardReviewSuccessful',
  [NotificationType.DEPOSIT_SUCCESSFUL]: 'notificationType.depositSuccessful',
  [NotificationType.WITHDRAW_REVIEW_SUCCESSFUL]: 'notificationType.withdrawReviewSuccessful',
  [NotificationType.WITHDRAW_REVIEW_FAIL]: 'notificationType.withdrawReviewFail',
  [NotificationType.DEBIT_CARD_REVIEW_FAIL]: 'notificationType.debitCardReviewFail',
  [NotificationType.PLAYER_RANK_UP]: 'notificationType.playerRankUp',
  [NotificationType.USER_RANK_UPGRADE]: 'notificationType.userRankUpgrade',
  [NotificationType.USER_RANK_DOWNGRADE]: 'notificationType.userRankDowngrade',
  [NotificationType.KEPT_EFFECTIVE_BET_NOT_ENOUGH]: 'notificationType.keptEffectiveBetNotEnough',
  [NotificationType.RECEIVE_UPGRADE_MONTHLY_BONUS]: 'notificationType.receiveUpgradeMonthlyBonus',
  [NotificationType.RECEIVE_UPGRADE_BONUS]: 'notificationType.receiveUpgradeBonus',
  [NotificationType.MODIFY_BANK_CARD_SUCCESS]: 'notificationType.modifyBankCardSuccess',
  [NotificationType.MODIFY_BANK_CARD_FAILURE]: 'notificationType.modifyBankCardFailure',
  [NotificationType.MANUAL_DEPOSIT_ACTIVITY]: 'notificationType.manualDepositActivity'
}

export default notificationTypeName

import React, { useState, useMemo, createContext, Dispatch, SetStateAction } from 'react'
import {
  PaginationRes,
  InternalWithdrawHistory
} from '@golden/gdk-admin'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import { searchToRequest } from './InternalWithdrawHistoryForm'
import CoreTable from '../../default/present/CoreTable'
import LoadingAndErrorFrame from '../../default/frames/LoadingAndErrorFrame'
import ScrollablePaper from '../../default/present/ScrollablePaper'
import { useRequestFromSearch, usePaginationClickAndChangeUrl, useGetDataByPayload } from '../../../utils/default/ComplexFlowHook'
import { usePageFlow } from '../../../utils/default/PageFlowHook'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { createDefaultPaginationData, createTableData, formatMoney, formatCount } from '../../../utils/default/TableHelper'
import useT from '../../../i18ns/admin/useT'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import allRoute from '../route/route'
import DateTime from '../../default/present/DateTime'
import MemoPopoverWithContext from '../../default/memo/MemoPopoverWithContext'
import { createDefaultMemoPopoverPayload, MemoPopoverPayload } from '../../default/memo/MemoPopover'
import useGDKStore from '../../../providers/admin/gdk/useGDKStore'
import MemoTextWithTitleAndContext from '../../default/memo/MemoTextWithTitleAndContext'
import internalWithdrawStatusColor from '../../../constants/admin/internalWithdrawStatusColor'
import internalWithdrawStatusName from '../../../constants/admin/internalWithdrawStatusName'

const useStyles = makeStyles((theme) => ({
  noMargin: {
    margin: '0px auto'
  },
  lockText: {
    color: theme.palette.common.black
  }
}))

interface SumRowType {
  id: number
  count: string
  cash: string
}

interface RowType {
  id: number
  time: React.ReactElement
  serial: string
  cash: string
  courier: string
  account: string
  bank: string
  name: string
  status: React.ReactElement
  memo: React.ReactElement
  updatedAt: React.ReactElement
  updatedBy: string
}

const MemoContext = createContext<[MemoPopoverPayload, Dispatch<SetStateAction<MemoPopoverPayload>>]>([
  createDefaultMemoPopoverPayload(),
  () => {}
])

const InternalWithdrawHistoryTable: React.FC = () => {
  const classes = useStyles()
  const commonClasses = useCommonStyles()
  const { t } = useT()
  const gdk = useGDK()
  const me = useGDKStore.admin.me()
  const [list, setList] = useState<PaginationRes<Array<InternalWithdrawHistory<Date>>> & { cash: string }>({
    ...createDefaultPaginationData([]),
    cash: '0.0000'
  })
  const pageFlow = usePageFlow()
  const request = useRequestFromSearch({ searchToRequest })
  useGetDataByPayload({
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    payload: request!,
    gdkFunc: (payload) => gdk.finance.getInternalWithdrawHistory({ ...payload }),
    gdkFuncDependencies: [gdk],
    onBeforeFetch: pageFlow.setLoadingStart,
    onSuccess: (res: PaginationRes<Array<InternalWithdrawHistory<Date>>> & { cash: string }) => {
      setList(res)
      pageFlow.setContentShow()
    },
    onError: pageFlow.setGDKError,
    canLoadData: request !== undefined
  })
  const tableClasses = useMemo(() => ({
    head: commonClasses.pinkTableHead,
    row: commonClasses.tableRow,
    cellHead: commonClasses.tableCellHead,
    cellBody: commonClasses.pre
  }), [commonClasses])
  const sumRows: SumRowType[] = useMemo(() => {
    return [{
      id: 1,
      count: formatCount(list.total),
      cash: formatMoney(list.cash)
    }] as SumRowType[]
  }, [list.cash, list.total])
  const rows: RowType[] = useMemo(() => {
    return list.data.map((item, index) => {
      return {
        id: index,
        time: (<DateTime time={item.created_at} />),
        serial: item.order_number,
        cash: formatMoney(item.cash),
        courier: item.forwarder_name,
        account: item.bank_account,
        bank: item.bank_name,
        name: item.real_name,
        status: (<span className={commonClasses.chipText} style={{ backgroundColor: internalWithdrawStatusColor[item.status] }}>{t(internalWithdrawStatusName[item.status])}</span>),
        memo: (<MemoTextWithTitleAndContext context={MemoContext} title={item.memo} memo={item.memo} />),
        updatedAt: (<DateTime time={item.updated_at} />),
        updatedBy: item.updated_by
      } as RowType
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [classes.lockText, list.data, t, me])
  const sumData = useMemo(() => {
    return createTableData<SumRowType>(
      {
        id: {
          label: '',
          value: 'id'
        },
        count: {
          label: t('common.totalCount'),
          value: 'count',
          align: 'center'
        },
        cash: {
          label: t('common.totalCash'),
          value: 'cash',
          align: 'center'
        }
      },
      [
        'count',
        'cash'
      ],
      sumRows,
      'id'
    )
  }, [sumRows, t])
  const data = useMemo(() => {
    return createTableData<RowType>(
      {
        id: {
          label: '',
          value: 'id'
        },
        time: {
          label: t('common.internalWithdrawTime'),
          value: 'time',
          align: 'center'
        },
        serial: {
          label: t('common.internalWithdrawSerial'),
          value: 'serial',
          align: 'center'
        },
        cash: {
          label: t('common.internalWithdrawCash'),
          value: 'cash',
          align: 'right'
        },
        courier: {
          label: t('common.withdrawWay'),
          value: 'courier',
          align: 'center'
        },
        account: {
          label: t('common.bankAccount'),
          value: 'account',
          align: 'center'
        },
        bank: {
          label: t('common.bankName'),
          value: 'bank',
          align: 'center'
        },
        name: {
          label: t('common.realName'),
          value: 'name',
          align: 'center'
        },
        status: {
          label: t('common.internalWithdrawStatus'),
          value: 'status',
          align: 'center'
        },
        memo: {
          label: t('common.memo'),
          value: 'memo',
          align: 'center'
        },
        updatedAt: {
          label: t('common.updateAt'),
          value: 'updatedAt',
          align: 'center'
        },
        updatedBy: {
          label: t('common.updateBy'),
          value: 'updatedBy',
          align: 'center'
        }
      },
      [
        'time',
        'serial',
        'cash',
        'courier',
        'account',
        'bank',
        'name',
        'status',
        'memo',
        'updatedAt',
        'updatedBy'
      ],
      rows,
      'id'
    )
  }, [rows, t])
  const handlePagination = usePaginationClickAndChangeUrl({
    request,
    encodePath: allRoute.financeInternalWithdrawHistory.encodePath
  })

  if (request === undefined) return null
  return (
    <MemoPopoverWithContext memoPopoverContext={MemoContext}>
      <Box paddingBottom={3}>
        <ScrollablePaper marginX={5}>
          <Box padding={4}>
            <LoadingAndErrorFrame { ...pageFlow.status }>
              <CoreTable
                classes={tableClasses}
                data={sumData}
                total={1}
              />
            </LoadingAndErrorFrame>
          </Box>
        </ScrollablePaper>
      </Box>
      <ScrollablePaper marginX={5}>
        <Box padding={4}>
          <LoadingAndErrorFrame { ...pageFlow.status }>
            <CoreTable
              classes={tableClasses}
              data={data}
              total={list.total}
              showPagination
              page={request.page}
              onChangePage={handlePagination}
            />
          </LoadingAndErrorFrame>
        </Box>
      </ScrollablePaper>
    </MemoPopoverWithContext>
  )
}

export default React.memo(InternalWithdrawHistoryTable)

import React, { useCallback, PropsWithChildren, useMemo } from 'react'
import { InitialFormFunc } from '../../../utils/default/ComplexFlowHook'
import { FormState, ValueGetter, useForm, FormValidation, ChangedFormGetter } from '../../../utils/default/FormHook'
import { getValueFromChangeEvent, getValueFromValue } from '../../../utils/default/FormHelper'
import useT from '../../../i18ns/admin/useT'
import { createValidateNotEmpty, createErrorResult, createCorrectResult, createValidateRange } from '../../../utils/default/Validator'

export interface EditBlacklistFormType {
  realName: string
  bankAccount: string
  bank: number | string
  province: number | string
  city: number | string
  memo: string
}

export const initialForm: InitialFormFunc<EditBlacklistFormType> = (defaultForm) => ({
  realName: '',
  bankAccount: '',
  bank: '--',
  province: '--',
  city: '--',
  memo: '',
  ...defaultForm
})

const getValueFromEvent: ValueGetter<EditBlacklistFormType> = {
  realName: getValueFromChangeEvent,
  bankAccount: getValueFromChangeEvent,
  bank: getValueFromChangeEvent,
  province: getValueFromChangeEvent,
  city: getValueFromChangeEvent,
  memo: getValueFromValue
}

const getChangedForm: ChangedFormGetter<EditBlacklistFormType> = {
  province: (value, form) => ({
    ...form,
    province: value,
    city: '--'
  })
}

export interface PropTypes {
  context: React.Context<FormState<EditBlacklistFormType>>
  defaultValue: EditBlacklistFormType
}

const BlacklistFormStateProvider: React.FC<PropsWithChildren<PropTypes>> = (props) => {
  const { context: Context, defaultValue, children } = props
  const { t } = useT()

  const onSubmit = useCallback((form) => form, [])

  const formValidation: FormValidation<EditBlacklistFormType> = useMemo(() => {
    return {
      realName: [],
      bankAccount: [
        {
          func: createValidateNotEmpty<EditBlacklistFormType>('bankAccount', t),
          when: ['change', 'beforeClickSubmit']
        },
        {
          func: createValidateRange<EditBlacklistFormType>(
            'bankAccount',
            (value) => (value as string).length,
            'moreEqual',
            16,
            t('error.invalidDebitCard')
          ),
          when: ['change', 'beforeClickSubmit']
        }
      ],
      bank: [
        {
          func: (value) => {
            if (value as string === '--') {
              return createErrorResult<EditBlacklistFormType>('bank', t('error.mustNotEmpty'))
            }
            return createCorrectResult<EditBlacklistFormType>('bank')
          },
          when: ['blur', 'beforeClickSubmit']
        }
      ],
      province: [],
      city: [],
      memo: [
        {
          func: createValidateNotEmpty<EditBlacklistFormType>('memo', t),
          when: ['change', 'beforeClickSubmit']
        }
      ]
    }
  }, [t])

  const formState = useForm<EditBlacklistFormType>({
    defaultValue,
    onSubmit,
    getValueFromEvent,
    formValidation,
    getChangedForm
  })

  return (
    <Context.Provider value={formState}>
      {children}
    </Context.Provider>
  )
}

export default React.memo(BlacklistFormStateProvider)

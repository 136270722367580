import React, { useState, useCallback } from 'react'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import AnnouncementPersonalTemplateTable from '../../../components/admin/forestage/AnnouncementPersonalTemplateTable'
import AnnouncementPersonalTemplateDialog from '../../../components/admin/forestage/AnnouncementPersonalTemplateDialog'
import { useReload, useRedirectHandleBack } from '../../../utils/default/ComplexFlowHook'
import useT from '../../../i18ns/admin/useT'
import allRoute from '../../../components/admin/route/route'
import { useCommonStyles } from '../../../utils/admin/StyleHook'

const CreateButton: React.FC<{ reload: () => void }> = (props) => {
  const { reload } = props
  const { t } = useT()
  const commonClasses = useCommonStyles()
  const [open, setOpen] = useState(false)
  const close = useCallback(() => {
    setOpen(false)
  }, [])

  return (
    <React.Fragment>
      <Button
        className={commonClasses.pinkGradualButton}
        onClick={useCallback(() => setOpen(true), [setOpen])}
      >
        {t('common.createTemplate')}
      </Button>
      <AnnouncementPersonalTemplateDialog title={t('common.createTemplate')} isEdit={false} open={open} close={close} reload={reload} />
    </React.Fragment>
  )
}

const AnnouncementPersonalTemplateManagementPage: React.FC = () => {
  const { reloadFlag, reload } = useReload()
  const { t } = useT()
  const commonClasses = useCommonStyles()
  const [handleDebouncedBack] = useRedirectHandleBack({ path: allRoute.forestagePersonal.encodePath({ search: { }, param: {} }) })

  return (
    <Box paddingY={6}>
      <Box marginX={6}>
        <Grid container justify='flex-end' spacing={2}>
          <Grid item>
            <Button
              className={commonClasses.greyButton}
              onClick={handleDebouncedBack}
            >
              {t('common.back')}
            </Button>
          </Grid>
          <Grid item>
            <CreateButton reload={reload} />
          </Grid>
        </Grid>
      </Box>
      <Box paddingTop={2}>
        <AnnouncementPersonalTemplateTable isEdit reload={reload} reloadFlag={reloadFlag} />
      </Box>
    </Box>
  )
}

export default React.memo(AnnouncementPersonalTemplateManagementPage)

import React, { useCallback } from 'react'
import Box from '@material-ui/core/Box'
import chat from '../../../assets/admin/images/chat-icon.svg'
import TopNavComponent from '../navigation/TopNavComponent'
import BattleFieldButton from '../../default/BattleFieldButton'
import allTopNav, { topNavOrder } from '../navigation/topNav'
import allLink from '../navigation/link'
import useT from '../../../i18ns/admin/useT'
import { defaultLanguage } from '../../../enums/default/LanguageType'
import { useDebouncedCallback } from 'use-debounce'
import { useChecker } from '../../../utils/admin/AdminRouteHook'
import { PermissionType } from '@golden/gdk-admin'

const AppBarContent: React.FC = () => {
  const { t, i18n } = useT()
  const lng = i18n.language || defaultLanguage
  const visibleCustomer = useChecker([PermissionType.CUSTOMER_SERVICE_READ])
  const [handleClick] = useDebouncedCallback(useCallback(() => {
    window.open(`${allLink.battleField.href}?lng=${lng}`)
  }, [lng]), 200)
  return (
    <Box
      display="flex"
      flexGrow={1}
      flexDirection="row"
      justifyContent="flex-end"
      alignItems="flex-end"
      paddingRight={0.5}
    >
      <Box
        display="flex"
        flexDirection="row"
      >
        {topNavOrder.map((topNav) => (
          <TopNavComponent key={topNav} topNav={allTopNav[topNav]} />
        ))}
        {visibleCustomer && (
          <Box paddingX={2}>
            <BattleFieldButton
              text={t('topNav.customerService')}
              icon={chat}
              onClick={handleClick}
            />
          </Box>
        )}
      </Box>
    </Box>
  )
}

export default React.memo(AppBarContent)

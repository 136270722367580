import React, { useMemo } from 'react'
import { getTime } from 'date-fns'
import { omitBy, isUndefined } from '@golden/utils'
import { ActivityType, ActivityNewYear2023StatusType } from '@golden/gdk-admin'
import Grid from '@material-ui/core/Grid'
import MuiTextField, { TextFieldProps } from '@material-ui/core/TextField'
import FormField from '../../default/form/FormField'
import DropDown, { PropTypes as DropDownProps } from '../../default/form/DropDown'
import { FormState, ValueGetter } from '../../../utils/default/FormHook'
import { ActivityFormType, ActivityRequest } from './ActivityRecordDetailForm'
import useT from '../../../i18ns/admin/useT'
import { getValueFromChangeEvent, convertEmptyToUndefined, acceptUndefined, pipe, guaranteeBeKey } from '../../../utils/default/FormHelper'
import { SearchToRequestFunc } from '../../../utils/default/ComplexFlowHook'
import { newYear2023StatusName } from '../../../constants/default/activityStatusName'

export interface ActivityNewYear2023FormType {
  account: string
  agent_account: string
  newYear2023Status: ActivityNewYear2023StatusType | '--'
}

export const initialForm = (): ActivityNewYear2023FormType => ({
  account: '',
  agent_account: '',
  newYear2023Status: '--'
})

export const getValueFromEvent: ValueGetter<ActivityNewYear2023FormType> = {
  account: getValueFromChangeEvent,
  agent_account: getValueFromChangeEvent,
  newYear2023Status: getValueFromChangeEvent
}

export const formToRequest = (form: ActivityFormType): ActivityRequest => {
  const converted = {
    activity: ActivityType.NEW_YEAR_2023,
    account: convertEmptyToUndefined(form.account),
    agent_account: convertEmptyToUndefined(form.agent_account),
    status: form.newYear2023Status === '--' ? undefined : form.newYear2023Status,
    type: form.type,
    start_at: form.time.start === null ? undefined : getTime(form.time.start),
    end_at: form.time.end === null ? undefined : getTime(form.time.end),
    page: 1
  } as ActivityRequest
  return omitBy(converted, isUndefined) as ActivityRequest
}

export const searchToRequest: SearchToRequestFunc<{
  account: string | undefined
  agent_account: string | undefined
  status: string | undefined
}> = (search) => {
  const converted = {
    account: search.account,
    agent_account: search.agent_account,
    status: acceptUndefined(search.status, pipe(
      (value) => guaranteeBeKey(value, Object.keys(newYear2023StatusName))
    ))
  }
  return omitBy(converted, isUndefined) as any
}

interface PropTypes {
  context: React.Context<FormState<ActivityFormType>>
}

const TextField = React.memo(MuiTextField)

const ActivityNewYear2023Form: React.FC<PropTypes> = (props) => {
  const { context } = props
  const { t } = useT()

  const stateOptions = useMemo(() => {
    return [{ name: t('common.all'), value: '--' }]
      .concat(
        Object.keys(newYear2023StatusName)
          .map((key) => ({ name: t(newYear2023StatusName[key as ActivityNewYear2023StatusType]), value: key })) as any
      )
  }, [t])

  return (
    <React.Fragment>
      <Grid item xs={12} md={6} lg={3}>
        <FormField<ActivityFormType, TextFieldProps>
          context={context}
          component={TextField}
          name="account"
          label={t('common.playerAccount')}
          placeholder={t('placeholder.inputPlayerAccount')}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} md={6} lg={3}>
        <FormField<ActivityFormType, TextFieldProps>
          context={context}
          component={TextField}
          name="agent_account"
          label={t('common.agentAccount')}
          placeholder={t('placeholder.inputAgentAccount')}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} md={6} lg={3}>
        <FormField<ActivityFormType, DropDownProps>
          context={context}
          component={DropDown}
          name="newYear2023Status"
          label={t('common.status')}
          options={stateOptions}
          fullWidth
        />
      </Grid>
    </React.Fragment>
  )
}

export default React.memo(ActivityNewYear2023Form)

import { RCStatusType } from '@golden/gdk-admin'
import TKeyType from '../../i18ns/admin/TKeyType'

const rcStatusName: { [key in RCStatusType]: TKeyType } = {
  [RCStatusType.IN_PROGRESS]: 'rcStatus.inProgress',
  [RCStatusType.REVIEWING]: 'rcStatus.reviewing',
  [RCStatusType.SUCCESSFUL]: 'rcStatus.successful',
  [RCStatusType.IRREGULAR]: 'rcStatus.irregular',
  [RCStatusType.CANCELED]: 'rcStatus.cancel'
}

export default rcStatusName

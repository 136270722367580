import React, { useMemo, createContext, useContext, useCallback, useState } from 'react'
import { PaginationReq, InternalWithdrawHistoryQuery } from '@golden/gdk-admin'
import { omitBy, isUndefined } from '@golden/utils'
import { startOfDay, endOfDay, subYears, startOfYear, getTime } from 'date-fns'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import MuiButton from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import MuiTextField, { TextFieldProps } from '@material-ui/core/TextField'
import FormStateProvider from '../../default/form/FormStateProvider'
import FormField from '../../default/form/FormField'
import FormSubmitButton from '../../default/form/FormSubmitButton'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import useT from '../../../i18ns/admin/useT'
import { pipe, guaranteeNotUndefined, parseInt, guaranteeBetween, getValueFromChangeEvent, convertEmptyToUndefined, getTimeFromDateInputValue } from '../../../utils/default/FormHelper'
import allRoutes from '../route/route'
import { useRequestFromSearch, SearchToRequestFunc, InitialFormFunc, useChangeUrlSubmit, useGetData } from '../../../utils/default/ComplexFlowHook'
import { FormValidation, createDefaultFormState, ValueGetter } from '../../../utils/default/FormHook'
import RequiredText from '../../default/form/RequiredText'
import { createShouldDisableDate, setToday, setYesterday, setThisMonth } from '../../../utils/default/TimeHelper'
import DateInputBase, { DateInputValue } from '../../default/form/DateInput'
import { usePageFlow } from '../../../utils/default/PageFlowHook'
import useGDK from '../../../providers/admin/gdk/useGDK'
import LoadingAndErrorFrame from '../../default/frames/LoadingAndErrorFrame'
import DropDown, { PropTypes as DropDownProps } from '../../default/form/DropDown'

export type InternalWithdrawHistoryRequest = PaginationReq & InternalWithdrawHistoryQuery

export interface InternalWithdrawHistoryFormType {
  time: DateInputValue
  updated_by: string
  forwarder_id: number
}

export const searchToRequest: SearchToRequestFunc<InternalWithdrawHistoryRequest> = (search) => {
  const fiveYearsAgo = getTime(startOfYear(subYears(new Date(), 5)))
  const endOfToday = getTime(endOfDay(new Date()))
  const converted = {
    ...search,
    page: pipe(
      guaranteeNotUndefined,
      parseInt,
      (value) => guaranteeBetween(value, 1, Number.MAX_SAFE_INTEGER)
    )(search.page),
    start_at: pipe(
      guaranteeNotUndefined,
      parseInt,
      (value) => guaranteeBetween(value, fiveYearsAgo, Number.MAX_SAFE_INTEGER)
    )(search.start_at),
    end_at: pipe(
      guaranteeNotUndefined,
      parseInt,
      (value) => guaranteeBetween(value, fiveYearsAgo, Number.MAX_SAFE_INTEGER)
    )(search.end_at),
    forwarder_id: pipe(
      guaranteeNotUndefined
    )(search.forwarder_id)
  } as InternalWithdrawHistoryRequest
  if (converted.end_at < converted.start_at) throw new Error('The end time can\'t exceed the start time')
  return omitBy(converted, isUndefined) as InternalWithdrawHistoryRequest
}

export const initialForm: InitialFormFunc<InternalWithdrawHistoryFormType> = (defaultForm) => ({
  time: {
    start: startOfDay(new Date()),
    end: endOfDay(new Date())
  },
  forwarder_id: 0,
  updated_by: '',
  ...defaultForm
})

const formToRequest = (form: InternalWithdrawHistoryFormType): InternalWithdrawHistoryRequest => {
  const converted = {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    start_at: getTime(form.time.start!),
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    end_at: getTime(form.time.end!),
    forwarder_id: form.forwarder_id,
    updated_by: convertEmptyToUndefined(form.updated_by),
    page: 1
  } as InternalWithdrawHistoryRequest
  return omitBy(converted, isUndefined) as InternalWithdrawHistoryRequest
}

const getValueFromEvent: ValueGetter<InternalWithdrawHistoryFormType> = {
  time: getTimeFromDateInputValue,
  forwarder_id: getValueFromChangeEvent,
  updated_by: getValueFromChangeEvent
}

const FormContext = createContext(createDefaultFormState(initialForm()))

const Button = React.memo(MuiButton)
const TextField = React.memo(MuiTextField)

const DateInput: React.FC = React.memo(() => {
  const classes = useCommonStyles()
  const { t } = useT()
  const { value, handleChange } = useContext(FormContext)
  const {
    shouldDisableStartDate,
    shouldDisableEndDate
  } = useMemo(() => {
    const fiveYearsAgo = startOfYear(subYears(new Date(), 5))
    return createShouldDisableDate(value.time.start, value.time.end, fiveYearsAgo)
  }, [value.time])
  const tools = useMemo(() => {
    return [
      {
        label: t('common.today'),
        change: setToday
      },
      {
        label: t('common.yesterday'),
        change: setYesterday
      },
      {
        label: t('common.thisMonth'),
        change: setThisMonth
      }
    ]
  }, [t])
  const startOption = useMemo(() => ({
    label: t('common.beginAt'),
    shouldDisableDate: shouldDisableStartDate
  }), [shouldDisableStartDate, t])

  const endOption = useMemo(() => ({
    label: t('common.endAt'),
    shouldDisableDate: shouldDisableEndDate
  }), [shouldDisableEndDate, t])

  const dateClasses = useMemo(() => ({
    button: classes.pinkGradualButton
  }), [classes.pinkGradualButton])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onChange = useCallback(handleChange('time'), [])
  return (
    <DateInputBase
      value={value.time}
      onChange={onChange}
      start={startOption}
      end={endOption}
      tools={tools}
      classes={dateClasses}
    />
  )
})

const InternalWithdrawHistoryForm: React.FC = () => {
  const classes = useCommonStyles()
  const { t } = useT()
  const pageFlow = usePageFlow()
  const gdk = useGDK()
  const [couriers, setCouriers] = useState<Array<{ id: number, name: string }>>([])

  const request = useRequestFromSearch({ searchToRequest })

  useGetData({
    gdkFunc: () => gdk.finance.getInternalWithdrawCourierList(),
    gdkFuncDependencies: [gdk],
    onBeforeFetch: pageFlow.setLoadingStart,
    onSuccess: (res: Array<{ id: number, name: string }>) => {
      setCouriers(res)
      pageFlow.setContentShow()
    },
    onError: pageFlow.setGDKError
  })

  const defaultForm = useMemo(() => {
    return initialForm({
      ...request,
      time: {
        start: request?.start_at ? new Date(request.start_at) : startOfDay(new Date()),
        end: request?.end_at ? new Date(request.end_at) : endOfDay(new Date())
      },
      forwarder_id: request?.forwarder_id
    })
  }, [request])

  const options = useMemo(() => {
    return couriers.map((item) => ({ name: item.name, value: item.id }))
  }, [couriers])

  const handleSubmit = useChangeUrlSubmit({
    toAddNowTimestamp: true,
    formToRequest,
    encodePath: allRoutes.financeInternalWithdrawHistory.encodePath
  })

  const validation = useMemo(() => ({
    time: [],
    forwarder_id: [],
    updated_by: []
  } as FormValidation<InternalWithdrawHistoryFormType>), [])

  return (
    <FormStateProvider
      context={FormContext}
      defaultValue={defaultForm}
      onSubmit={handleSubmit}
      validation={validation}
      getValueFromEvent={getValueFromEvent}
    >
      <Paper>
        <Box padding={4}>
          <LoadingAndErrorFrame { ...pageFlow.status }>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <Box
                  paddingY={1.25}
                  paddingX={2}
                  className={classes.pinkTitleBar}
                >
                  <Typography variant="h5">
                    {t('page.internalWithdrawHistory')}
                  </Typography>
                </Box>
              </Grid>
              <Grid item>
                <Grid container direction="row" spacing={2}>
                  <Grid item xs={12} md={3}>
                    <FormField<InternalWithdrawHistoryFormType, DropDownProps>
                      context={FormContext}
                      name="forwarder_id"
                      component={DropDown}
                      label={t('common.courierName')}
                      options={options}
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <DateInput />
              </Grid>
              <Grid item>
                <Grid container direction="row" spacing={2}>
                  <Grid item xs={12} md={3}>
                    <FormField<InternalWithdrawHistoryFormType, TextFieldProps>
                      context={FormContext}
                      component={TextField}
                      name="updated_by"
                      label={t('common.updateBy')}
                      placeholder={t('placeholder.inputUpdateBy')}
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <RequiredText />
              </Grid>
              <Grid item>
                <Grid container justify="flex-end">
                  <Grid item>
                    <FormSubmitButton
                      component={Button}
                      context={FormContext}
                      type="submit"
                      className={classes.purpleGradualButton}
                    >
                      {t('common.search')}
                    </FormSubmitButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </LoadingAndErrorFrame>
        </Box>
      </Paper>
    </FormStateProvider>
  )
}

export default React.memo(InternalWithdrawHistoryForm)

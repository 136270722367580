import React from 'react'
import { Link } from 'react-router-dom'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import AnnouncementPersonalTemplateTable from '../../../components/admin/forestage/AnnouncementPersonalTemplateTable'
import { useReload, useRedirectHandleBack } from '../../../utils/default/ComplexFlowHook'
import useT from '../../../i18ns/admin/useT'
import allRoute from '../../../components/admin/route/route'
import { useCommonStyles } from '../../../utils/admin/StyleHook'

const AnnouncementPersonalTemplatePage: React.FC = () => {
  const { reloadFlag, reload } = useReload()
  const { t } = useT()
  const commonClasses = useCommonStyles()
  const [handleDebouncedBack] = useRedirectHandleBack({ path: allRoute.forestagePersonal.encodePath({ search: { }, param: {} }) })

  return (
    <Box paddingY={6}>
      <Box marginX={6} display='flex' justifyContent='space-between'>
        <Typography>
          {`${t('common.selectTemplate')}${t('common.or')}`}
          <span> </span>
          <Typography
            className={commonClasses.anchor}
            component={Link}
            to={allRoute.forestagePersonalCreate.encodePath({ search: {}, param: {} })}
          >{t('common.createEmpteyContent')}</Typography>
        </Typography>
        <Button
          className={commonClasses.greyButton}
          onClick={handleDebouncedBack}
        >
          {t('common.back')}
        </Button>
      </Box>
      <Box paddingTop={2}>
        <AnnouncementPersonalTemplateTable isEdit={false} reload={reload} reloadFlag={reloadFlag} />
      </Box>
    </Box>
  )
}

export default React.memo(AnnouncementPersonalTemplatePage)

import { PlayerRankType } from '@golden/gdk-admin'
import TKeyType from '../../i18ns/admin/TKeyType'

const playerRankName: { [key in PlayerRankType]: TKeyType } = {
  [PlayerRankType.SILVER]: 'player.silver',
  [PlayerRankType.GOLD]: 'player.gold',
  [PlayerRankType.DIAMOND]: 'player.diamond',
  [PlayerRankType.CROWN]: 'player.crown',
  [PlayerRankType.GLORY]: 'player.glory',
  [PlayerRankType.KING]: 'player.king',
  [PlayerRankType.MASTER]: 'player.master'
}

export default playerRankName

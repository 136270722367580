import React, { createContext, useState, useMemo, useContext, useCallback, useEffect, ChangeEvent } from 'react'
import clsx from 'clsx'
import { useLocation } from 'react-router'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import TextField, { TextFieldProps } from '@material-ui/core/TextField'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import MuiButton from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import LoadingAndErrorFrame from '../../../components/default/frames/LoadingAndErrorFrame'
import { useCommonStyles, useDetailStyles, useDialogStyles } from '../../../utils/admin/StyleHook'
import useT from '../../../i18ns/admin/useT'
import useGlobalDialog from '../../../providers/admin/dialog/useGlobalDialog'
import { parsePath } from '../../../utils/default/RouteHelper'
import allRoute, { Path } from '../../../components/admin/route/route'
import { useRedirectHandleBack, useDialogHandleSubmit, useGetDataByParams, InitialFormFunc } from '../../../utils/default/ComplexFlowHook'
import { createGlobalDialogConfig } from '../../../utils/default/DialogHelper'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { usePageFlow } from '../../../utils/default/PageFlowHook'
import { AnnouncementPersonalForm, AnnouncementPersonalRes, NotificationWayType } from '@golden/gdk-admin'
import FormStateProvider from '../../../components/default/form/FormStateProvider'
import FormField from '../../../components/default/form/FormField'
import FormSubmitButton from '../../../components/default/form/FormSubmitButton'
import RequiredText from '../../../components/default/form/RequiredText'
import { getValueFromValue, getValueFromChangeEvent } from '../../../utils/default/FormHelper'
import { ValueGetter, createDefaultFormState, FormValidation } from '../../../utils/default/FormHook'
import { createValidateNotEmpty, createValidateEditorNotEmpty, createCorrectResult } from '../../../utils/default/Validator'
import { TextEditor } from '../../../components/default/form/textEditor/TextEditor'
import { defaultEditorState, EditorState, renderContent, parseContent } from '@golden/tiptap-react'
import CustomThemeType from '../../../themes/admin/CustomThemeType'
import { times } from '@golden/utils'

const useStyles = makeStyles((theme: CustomThemeType) => ({
  table: {
    minWidth: 400
  },
  content: {
    backgroundColor: theme.palette.grey[300],
    marginTop: theme.spacing(1),
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    border: 'solid 1px #e7e7e7',
    width: '100%',
    overflowY: 'auto'
  },
  button: {
    background: theme.palette.common.white,
    color: theme.custom.palette.buttonText.blue,
    border: `1px solid ${theme.custom.palette.buttonText.blue}`,
    '&:hover': {
      background: theme.custom.palette.button.blue,
      color: theme.palette.common.white
    },
    '&.active': {
      background: theme.custom.palette.button.blue,
      color: theme.palette.common.white
    }
  },
  textarea: {
    paddingLeft: '30px',
    resize: 'none'
  },
  list: {
    listStyle: 'none',
    lineHeight: '1.1876em',
    padding: '18.5px 15px',
    margin: 0,
    color: '#e7e7e7'
  },
  head: {
    width: 100
  }
}))

interface FormType {
  title: string
  content: EditorState
  way: NotificationWayType
  agent_account: string
  user_accounts: string
}

const formToRequest = (data: FormType): AnnouncementPersonalForm => {
  return {
    title: data.title,
    content: JSON.stringify(data.content.content),
    way: data.way,
    ...data.way === NotificationWayType.USERS_ACCOUNTS ? { user_accounts: data.user_accounts.split('\n').filter(item => item !== '') } : {},
    ...data.way === NotificationWayType.USERS_OF_AGENT ? { agent_account: data.agent_account } : {}
  }
}

const getValueFromEvent: ValueGetter<FormType> = {
  title: getValueFromChangeEvent,
  content: getValueFromValue,
  way: getValueFromValue,
  agent_account: getValueFromChangeEvent,
  user_accounts: getValueFromChangeEvent
}

const initialForm: InitialFormFunc<FormType> = (form) => ({
  title: '',
  content: defaultEditorState,
  way: NotificationWayType.USERS_OF_AGENT,
  agent_account: '',
  user_accounts: '',
  ...form
})

export const FormContext = createContext(createDefaultFormState(initialForm()))

const Button = React.memo(MuiButton)

const titleInputProps = {
  maxLength: 20
}

const Editor: React.FC = React.memo(() => {
  const { t } = useT()
  const { value, handleChange, error } = useContext(FormContext)
  return (
    <TextEditor
      value={value.content}
      onChange={handleChange('content')}
      controls={['textColor', 'clearFormat', 'link']}
      error={error.content !== null}
      helperText={error.content ?? ''}
      placeholder={`${t('placeholder.inputContent')}*`}
    />
  )
})

const AgentInput: React.FC = React.memo(() => {
  const { t } = useT()
  const gdk = useGDK()
  const [helperText, setHelperText] = useState('')
  const { value, handleChange, error, setError } = useContext(FormContext)
  const [dirty, setDirty] = useState<boolean>(false)

  const onChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setHelperText('')
    setDirty(true)
    handleChange('agent_account')(event)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useEffect(() => {
    if (value.agent_account === '' && dirty) {
      setError((error) => ({ ...error, agent_account: t('error.mustNotEmpty') }))
    } else if (value.agent_account !== '') {
      setError((error) => ({ ...error, agent_account: null }))
      const subsciption = gdk.notification.validateAgentAccount(value.agent_account).subscribe({
        next: (res) => {
          setHelperText(t('common.expectSendToPlayers', { count: res.count }))
        },
        error: () => {
          setError((error) => ({ ...error, agent_account: t('error.accountNotFound') }))
        }
      })
      return () => subsciption.unsubscribe()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value.agent_account, dirty, t, gdk])

  return (
    <TextField
      name="agent_account"
      label={t('common.agentAccount2')}
      placeholder={t('common.agentAccount2')}
      value={value.agent_account}
      onChange={onChange}
      error={error.agent_account !== null}
      helperText={error.agent_account ?? helperText}
      required
      fullWidth
    />
  )
})

const MAX_ENTER_USERS = 200
const UsersInput: React.FC = React.memo(() => {
  const { t } = useT()
  const classes = useStyles()
  const { value, handleChange, error, setError } = useContext(FormContext)
  const [dirty, setDirty] = useState<boolean>(false)

  const onChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setDirty(true)
    if (event.target.value.split('\n').length > MAX_ENTER_USERS) return
    handleChange('user_accounts')(event)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useEffect(() => {
    if (value.user_accounts === '' && dirty) {
      setError((error) => ({ ...error, user_accounts: t('error.mustNotEmpty') }))
    } else {
      setError((error) => ({ ...error, user_accounts: null }))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value.user_accounts, dirty, t])

  return (
    <Box position='relative'>
      <TextField
        name="user_accounts"
        value={value.user_accounts}
        onChange={onChange}
        error={error.user_accounts !== null}
        variant="outlined"
        placeholder={''}
        multiline
        fullWidth
        required
        rows={MAX_ENTER_USERS}
        rowsMax={MAX_ENTER_USERS}
        inputProps={{
          wrap: 'hard',
          className: classes.textarea
        }}
      />
      <Box position='absolute' top={0} left={0}>
        <ul className={classes.list}>
          {
            times(MAX_ENTER_USERS, (i) => (<li key={i}>{`${i + 1}.`}</li>))
          }
        </ul>
      </Box>
    </Box>
  )
})

const Inputs: React.FC = React.memo(() => {
  const { value } = useContext(FormContext)
  return (
    <React.Fragment>
      {
        value.way === NotificationWayType.USERS_OF_AGENT && (<AgentInput />)
      }
      {
        value.way === NotificationWayType.USERS_ACCOUNTS && (<UsersInput />)
      }
    </React.Fragment>
  )
})

const WayButtons: React.FC = React.memo(() => {
  const { t } = useT()
  const classes = useStyles()

  const { value, handleChange, setError, dispatch } = useContext(FormContext)
  const resetLastValue = () => {
    if (value.way === NotificationWayType.USERS_OF_AGENT) {
      dispatch({
        type: 'change',
        label: 'agent_account',
        value: ''
      })
      setError((error) => ({ ...error, agent_account: null }))
    }
    if (value.way === NotificationWayType.USERS_ACCOUNTS) {
      dispatch({
        type: 'change',
        label: 'user_accounts',
        value: ''
      })
      setError((error) => ({ ...error, user_accounts: null }))
    }
  }
  const onChange = useCallback((way: NotificationWayType) => {
    if (value.way === way) return
    resetLastValue()
    handleChange('way')(way)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  return (
    <ButtonGroup size="small" aria-label="small button group">
      <Button className={ clsx(value.way === NotificationWayType.USERS_OF_AGENT && 'active', classes.button) } onClick={() => onChange(NotificationWayType.USERS_OF_AGENT)}>{t('common.agentChain')}</Button>
      <Button className={ clsx(value.way === NotificationWayType.USERS_ACCOUNTS && 'active', classes.button) } onClick={() => onChange(NotificationWayType.USERS_ACCOUNTS)}>{t('common.selfInput')}</Button>
      <Button className={ clsx(value.way === NotificationWayType.ALL && 'active', classes.button) } onClick={() => onChange(NotificationWayType.ALL)}>{t('common.allPlayer2')}</Button>
    </ButtonGroup>
  )
})

const AnnouncementPersonalCreatePage: React.FC = () => {
  const classes = useStyles()
  const commonClasses = useCommonStyles()
  const dialogClasses = useDialogStyles()
  const detailClasses = useDetailStyles()
  const { t } = useT()
  const pageFlow = usePageFlow()
  const globalDialog = useGlobalDialog()
  const gdk = useGDK()
  const location = useLocation()

  const id = useMemo(() => {
    return parsePath(location.search, location.pathname, Path.FORESTAGE_PERSONAL_CREATE_BY_TEMPLATE).param.id
  }, [location.pathname, location.search])

  const [defaultForm, setDefaultForm] = useState(initialForm())

  const [handleDebouncedBack] = useRedirectHandleBack({ path: allRoute.forestagePersonalTemplate.encodePath({ search: { }, param: {} }) })
  const [handleBack] = useRedirectHandleBack({ path: allRoute.forestagePersonal.encodePath({ search: { }, param: {} }) })
  const [content, setContent] = useState('')
  const { handleSubmit } = useDialogHandleSubmit({
    dialogId: 'sendMessage',
    globalDialog,
    getChangeDialogConfig: useCallback((form: FormType) => {
      void renderContent(JSON.stringify(form.content.content)).then((res) => setContent(res))
      const playerList = (() => {
        if (form.way === NotificationWayType.USERS_OF_AGENT) return t('common.agentsPlayer', { agent: form.agent_account })
        if (form.way === NotificationWayType.USERS_ACCOUNTS) {
          const accounts = form.user_accounts.split('\n').filter(item => item.replace(/\s/g, '') !== '')
          if (accounts.length === 1) return accounts[0]

          return `${accounts[0]} ~ ${accounts[accounts.length - 1]}`
        }
        if (form.way === NotificationWayType.ALL) return t('common.allPlayer2')
        return ''
      })()

      return createGlobalDialogConfig({
        showIcon: false,
        notUseTypo: true,
        message: (
          <Box width={500} display="flex" flexDirection="column" alignItems="center">
            <Typography className={dialogClasses.text}>{t('dialog.confirmAgain')}</Typography>
            <table className={clsx(detailClasses.table, classes.table)}>
              <tbody>
                <tr>
                  <td className={clsx(detailClasses.greyHead, classes.head)}>
                    {t('common.title')}
                  </td>
                  <td className={detailClasses.cell}>
                    {form.title}
                  </td>
                </tr>
                <tr>
                  <td className={clsx(detailClasses.greyHead, classes.head)}>
                    {t('common.playerList')}
                  </td>
                  <td className={detailClasses.cell}>
                    { playerList }
                  </td>
                </tr>
              </tbody>
            </table>
            <Box className={classes.content} dangerouslySetInnerHTML={{ __html: content }} />
          </Box>
        )
      })
    }, [dialogClasses.text, t, detailClasses.table, detailClasses.greyHead, detailClasses.cell, classes.table, classes.head, classes.content, content]),
    getSuccessDialogConfig: useCallback((form: FormType, res: AnnouncementPersonalRes) => {
      let message: string | React.ReactElement = ''
      if (form.way === NotificationWayType.USERS_ACCOUNTS && res.successful_count === 1 && !res.duplicate_accounts && !res.successful_count) {
        message = t('dialog.sendSuccess2')
      } else {
        message = (
          <Box display="flex" flexDirection="column" alignItems="center">
            <Typography className={dialogClasses.text}>{t('dialog.sendSuccess2')}</Typography>
            <Box>
              {
                `${res.successful_count} ${t('common.successPeople')}`
              }
            </Box>
            {
              res.not_exist_accounts?.length > 0 && (
                <Box>
                  {
                    `${t('error.accountNotFound')}: ${res.not_exist_accounts.join('、')}`
                  }
                </Box>
              )
            }
            {
              res.duplicate_accounts?.length > 0 && (
                <Box>
                  {
                    `${t('error.accountDuplicated')}: ${res.duplicate_accounts.join('、')}`
                  }
                </Box>
              )
            }
          </Box>
        )
      }
      return createGlobalDialogConfig({
        showIcon: false,
        showCancel: false,
        message
      })
    }, [t, dialogClasses]),
    getFailDialogConfig: useCallback((error) => createGlobalDialogConfig({
      showIcon: true,
      variant: 'error',
      showCancel: false,
      message: error
    }), []),
    formToRequest,
    gdkFunc: (payload) => gdk.notification.sendPersonalNotification(payload),
    gdkFuncDependencies: [gdk],
    afterSuccessDialog: handleBack
  })
  const validation = useMemo(() => {
    return {
      title: [
        {
          func: createValidateNotEmpty('title', t),
          when: ['change', 'beforeClickSubmit']
        }
      ],
      content: [
        {
          func: createValidateEditorNotEmpty('content', t),
          when: ['change', 'beforeClickSubmit']
        }
      ],
      way: [],
      agent_account: [
        {
          func: (value, form, lastSubmitForm) => {
            if (form.way === NotificationWayType.USERS_OF_AGENT) return createValidateNotEmpty('agent_account', t)(value, form, lastSubmitForm, null)
            return createCorrectResult('agent_account')
          },
          when: ['change', 'beforeClickSubmit']
        }
      ],
      user_accounts: [
        {
          func: (value, form, lastSubmitForm) => {
            if (form.way === NotificationWayType.USERS_ACCOUNTS) return createValidateNotEmpty('user_accounts', t)(value, form, lastSubmitForm, null)
            return createCorrectResult('user_accounts')
          },
          when: ['change', 'beforeClickSubmit']
        }
      ]
    } as FormValidation<FormType>
  }, [t])

  useGetDataByParams({
    path: Path.FORESTAGE_PERSONAL_CREATE_BY_TEMPLATE,
    gdkFunc: (param: { id: string }) => gdk.notification.getPersonalNotificationTemplate(Number(param.id)),
    gdkFuncDependencies: [gdk],
    onBeforeFetch: pageFlow.setLoadingStart,
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    onSuccess: async (res) => {
      setDefaultForm(initialForm({
        ...res,
        content: {
          ...defaultEditorState,
          content: await parseContent(res.content)
        }
      }))
      pageFlow.setContentShow()
    },
    onError: pageFlow.setGDKError,
    canLoadData: id !== undefined
  })

  return (
    <Box paddingY={5} paddingX={4}>
      <LoadingAndErrorFrame {...pageFlow.status}>
        <Paper>
          <Box padding={4}>
            <Box
              paddingY={1.25}
              paddingX={2}
              marginBottom={2}
              className={commonClasses.pinkTitleBar}
            >
              <Typography variant="h5">
                {t('page.announcementPersonalCreate')}
              </Typography>
            </Box>
            <FormStateProvider<FormType>
              context={FormContext}
              defaultValue={defaultForm}
              onSubmit={handleSubmit}
              getValueFromEvent={getValueFromEvent}
              validation={validation}
            >
              <Grid container direction="column" spacing={3}>
                <Grid item container direction="row">
                  <Grid item xs={12}>
                    <FormField<FormType, TextFieldProps>
                      component={TextField}
                      context={FormContext}
                      name="title"
                      label={t('common.title')}
                      placeholder={t('placeholder.inputTitle')}
                      inputProps={titleInputProps}
                      required
                      fullWidth
                    />
                  </Grid>
                </Grid>
                <Grid item container direction="row">
                  <Grid item xs={12}>
                    <Editor />
                  </Grid>
                </Grid>
                <Grid item container direction="row">
                  <Grid item xs={12}>
                    <WayButtons />
                  </Grid>
                </Grid>
                <Grid item>
                  <Inputs />
                </Grid>
                <Grid item>
                  <RequiredText />
                </Grid>
                <Grid item container direction="row" justify="flex-end" spacing={2}>
                  <Grid item>
                    <Button
                      className={commonClasses.greyButton}
                      onClick={handleDebouncedBack}
                    >
                      {t('common.cancel')}
                    </Button>
                  </Grid>
                  <Grid item>
                    <FormSubmitButton
                      component={Button}
                      context={FormContext}
                      className={commonClasses.purpleGradualButton}
                      type="submit"
                    >
                      {t('common.send3')}
                    </FormSubmitButton>
                  </Grid>
                </Grid>
              </Grid>
            </FormStateProvider>
          </Box>
        </Paper>
      </LoadingAndErrorFrame>
    </Box>
  )
}

export default React.memo(AnnouncementPersonalCreatePage)

import { FetchTaskStatusType } from '@golden/gdk-admin'
import TKeyType from '../../i18ns/admin/TKeyType'

const fetchTaskStatusName: { [key in FetchTaskStatusType]: TKeyType } = {
  [FetchTaskStatusType.PROCESSING]: 'fetchTaskStatus.processing',
  [FetchTaskStatusType.FINISH]: 'fetchTaskStatus.finish',
  [FetchTaskStatusType.ERROR]: 'fetchTaskStatus.error'
}

export default fetchTaskStatusName

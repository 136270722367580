import React, { useMemo, createContext, Dispatch, SetStateAction, useEffect, useState, useRef } from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import { AdminPlayerInfo, PermissionType, PlayerRankType, PlayerStatusType, Branch } from '@golden/gdk-admin'
import { Link } from 'react-router-dom'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import { parsePath } from '../../../utils/default/RouteHelper'
import { Path } from '../../../components/admin/route/route'
import { useLocation } from 'react-router'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import useT from '../../../i18ns/admin/useT'
import StateProvider from '../../../providers/default/StateProvider'
import PlayerDetailInfoSection from '../../../components/admin/player/PlayerDetailInfoSection'
import PlayerDetailPermissionSection from '../../../components/admin/player/PlayerDetailPermissionSection'
import PlayerDetailCenterSection from '../../../components/admin/player/PlayerDetailCenterSection'
import PlayerDetailCardSection from '../../../components/admin/player/PlayerDetailCardSection'
import PlayerDetailCryptoWalletSection from '../../../components/admin/player/PlayerDetailCryptoWalletSection'
import PlayerDetailFinanceSection from '../../../components/admin/player/PlayerDetailFinanceSection'
import PlayerDetailWalletSection from '../../../components/admin/player/PlayerDetailWalletSection'
import { useReload } from '../../../utils/default/ComplexFlowHook'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { useChecker } from '../../../utils/admin/AdminRouteHook'
import useGDKStore from '../../../providers/admin/gdk/useGDKStore'
import CustomThemeType from '../../../themes/default/CustomThemeType'

const useStyles = makeStyles((theme: CustomThemeType) => ({
  button: {
    position: 'fixed',
    bottom: 60,
    backgroundColor: '#808080',
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      background: '#b2b2b2',
      opacity: 1,
      color: theme.palette.common.white
    }
  }
}))

const infoSection = 'info'
const permissionSection = 'permission'
const centerSection = 'center'
const cardSection = 'card'
const cryptoWalletSection = 'cryptoWallet'
const financeSection = 'finance'
const walletSection = 'wallet'

const defaultInfo: AdminPlayerInfo = {
  account: '',
  agent_chain: [],
  nickname: '',
  rank: PlayerRankType.SILVER,
  status: PlayerStatusType.NORMAL,
  staff: '',
  created_at: new Date(),
  last_login_at: new Date(),
  registered_ip: '',
  login_ip: '',
  phone: '',
  email: '',
  is_allow_modify: false,
  is_goubao_bound: false,
  bubble_id: null,
  battle_field_memo: '',
  risk_memo: '',
  finance_memo: '',
  pm_version_switch: null
}

export const InfoContext = createContext<[
  AdminPlayerInfo,
  Dispatch<SetStateAction<AdminPlayerInfo>>
]>([
  defaultInfo,
  () => {}
])

const scrollTop = () => {
  window.scrollTo({ behavior: 'smooth', top: 0 })
}

const PlayerDetailPage: React.FC = () => {
  const classes = useStyles()
  const commonClasses = useCommonStyles()
  const { t } = useT()
  const location = useLocation()
  const [sectionLoaded, setSectionLoaded] = useState<string>('')
  const id = useMemo(() => {
    return Number((parsePath(location.search, location.pathname, Path.PLAYER_DETAIL).param as { id: string }).id)
  }, [location])
  const { reload, reloadFlag } = useReload()
  const gdk = useGDK()
  const aliveGames = useGDKStore.platform.aliveGames()
  useEffect(() => {
    gdk.trunk.trigger([Branch.PLATFORMS])
  }, [gdk])
  const infoRef = useRef<HTMLDivElement>(null)
  const permissionRef = useRef<HTMLDivElement>(null)
  const centerRef = useRef<HTMLDivElement>(null)
  const cardRef = useRef<HTMLDivElement>(null)
  const cryptoWalletsRef = useRef<HTMLDivElement>(null)
  const financeRef = useRef<HTMLDivElement>(null)
  const walletRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    if (location.hash) {
      switch (location.hash) {
        case '#info':
          infoRef.current?.scrollIntoView({ block: 'center' })
          break
        case '#permission':
          permissionRef.current?.scrollIntoView({ block: 'center' })
          break
        case '#center':
          centerRef.current?.scrollIntoView({ block: 'center' })
          break
        case '#card':
          cardRef.current?.scrollIntoView({ block: 'center' })
          break
        case '#cryptoWallet':
          cryptoWalletsRef.current?.scrollIntoView({ block: 'center' })
          break
        case '#finance':
          financeRef.current?.scrollIntoView({ block: 'center' })
          break
        case '#wallet':
          walletRef.current?.scrollIntoView({ block: 'center' })
          break
        default:
      }
    }
  }, [location.hash, sectionLoaded])
  return (
    <StateProvider
      context={InfoContext}
      defaultValue={defaultInfo}
    >
      <Box paddingY={5}>
        <Box paddingX={5}>
          <Grid container direction="row" spacing={2} justifyContent="flex-end">
            {useChecker([PermissionType.PLAYER_MANAGEMENT_INFO_READ]) && (
              <Grid item>
                <Button component="a" href={`#${infoSection}`} className={commonClasses.greenGradualButton}>{t('common.playerInformation')}</Button>
              </Grid>
            )}
            {useChecker([PermissionType.PLAYER_MANAGEMENT_PERMISSION_READ]) && (
              <Grid item>
                <Button component="a" href={`#${permissionSection}`} className={commonClasses.greenGradualButton}>{t('common.playerPermissionSetting')}</Button>
              </Grid>
            )}
            {useChecker([PermissionType.PLAYER_MANAGEMENT_CENTER_READ]) && (
              <Grid item>
                <Button component="a" href={`#${centerSection}`} className={commonClasses.greenGradualButton}>{t('common.playerCenterSetting')}</Button>
              </Grid>
            )}
            {useChecker([PermissionType.PLAYER_MANAGEMENT_DEBIT_CARD_READ]) && (
              <Grid item>
                <Button component="a" href={`#${cardSection}`} className={commonClasses.greenGradualButton}>{t('common.debitCardData')}</Button>
              </Grid>
            )}
            {useChecker([PermissionType.PLAYER_MANAGEMENT_CRYPTO_WALLET_READ]) && (
              <Grid item>
                <Button component="a" href={`#${cryptoWalletSection}`} className={commonClasses.greenGradualButton}>{t('common.cryptoWalletData')}</Button>
              </Grid>
            )}
            {useChecker([PermissionType.PLAYER_MANAGEMENT_TRANSACTION_READ]) && (
              <Grid item>
                <Button component="a" href={`#${financeSection}`} className={commonClasses.greenGradualButton}>{t('common.depositWithdrawStatistic')}</Button>
              </Grid>
            )}
            {useChecker([PermissionType.PLAYER_MANAGEMENT_WALLET_READ]) && (
              <Grid item>
                <Button component="a" href={`#${walletSection}`} className={commonClasses.greenGradualButton}>{t('common.platformWalletStatistic')}</Button>
              </Grid>
            )}
            <Grid item>
              <Button component={Link} to={Path.PLAYER} className={commonClasses.greyButton}>{t('common.back')}</Button>
            </Grid>
          </Grid>
        </Box>
        {useChecker([PermissionType.PLAYER_MANAGEMENT_INFO_READ]) && (
          <Box paddingX={5} paddingTop={2}>
            <div ref={infoRef}/>
            <PlayerDetailInfoSection reloadFlag={reloadFlag} id={id} section={infoSection} loaded={setSectionLoaded} />
          </Box>
        )}
        {useChecker([PermissionType.PLAYER_MANAGEMENT_PERMISSION_READ]) && (
          <Box paddingX={5} paddingTop={2}>
            <div ref={permissionRef}/>
            <PlayerDetailPermissionSection reloadFlag={reloadFlag} reload={reload} id={id} section={permissionSection} loaded={setSectionLoaded} aliveGames={aliveGames} />
          </Box>
        )}
        {useChecker([PermissionType.PLAYER_MANAGEMENT_CENTER_READ]) && (
          <Box paddingX={5} paddingTop={2}>
            <div ref={centerRef}/>
            <PlayerDetailCenterSection id={id} section={centerSection} loaded={setSectionLoaded} />
          </Box>
        )}
        {useChecker([PermissionType.PLAYER_MANAGEMENT_DEBIT_CARD_READ]) && (
          <Box paddingTop={2}>
            <div ref={cardRef}/>
            <PlayerDetailCardSection reload={reload} id={id} section={cardSection} loaded={setSectionLoaded} />
          </Box>
        )}
        {useChecker([PermissionType.PLAYER_MANAGEMENT_CRYPTO_WALLET_READ]) && (
          <Box paddingTop={2}>
            <div ref={cryptoWalletsRef}/>
            <PlayerDetailCryptoWalletSection reload={reload} id={id} section={cryptoWalletSection} loaded={setSectionLoaded} />
          </Box>
        )}
        {useChecker([PermissionType.PLAYER_MANAGEMENT_TRANSACTION_READ]) && (
          <Box paddingX={5} paddingTop={2}>
            <div ref={financeRef}/>
            <PlayerDetailFinanceSection id={id} section={financeSection} loaded={setSectionLoaded} />
          </Box>
        )}
        {useChecker([PermissionType.PLAYER_MANAGEMENT_WALLET_READ]) && (
          <Box paddingTop={2}>
            <div ref={walletRef}/>
            <PlayerDetailWalletSection id={id} section={walletSection} loaded={setSectionLoaded} />
          </Box>
        )}
        <Box paddingX={5} display="flex" justifyContent="flex-end" paddingTop={2} height={60}>
          <Button onClick={scrollTop} className={clsx(commonClasses.greyButton, classes.button)}>
            <ArrowUpwardIcon />
            Top
          </Button>
        </Box>
      </Box>
    </StateProvider>
  )
}

export default React.memo(PlayerDetailPage)

import React, { createContext, useState, useEffect } from 'react'
import { Lang, AdminModule, createAdminModule, Branch, GDKError, ErrorCode } from '@golden/gdk-admin'
import { sendErrorToSentry } from '@golden/sentry-react'
import Language, { defaultLanguage } from '../../../enums/default/LanguageType'
import useT from '../../../i18ns/admin/useT'
import { getServerUrl } from '../../../utils/default/StageHelper'
import { combineLatestWith } from 'rxjs'

const gdkLocaleMap: { [key in Language]: Lang } = {
  [Language.ZH_CN]: Lang.ZH_CN
}

const apiUrl: string = getServerUrl('api')
const wsUrl: string = getServerUrl('ws')
const timeout = 30000

const defaultGDK = createAdminModule(apiUrl, wsUrl, timeout, gdkLocaleMap[defaultLanguage])

export const GDKContext = createContext<AdminModule>(defaultGDK)

const GdkProvider: React.FC = (props) => {
  const { children } = props
  const { i18n } = useT()
  const [hasGDKLogin, setHasGDKLogin] = useState<boolean>(true)
  const [gdk, setGDK] = useState<AdminModule>(createAdminModule(apiUrl, wsUrl, timeout, gdkLocaleMap[i18n.language as Language]))

  useEffect(() => {
    gdk.socket.connect()
    gdk.socket.handleReconnectError = (error: Error) => sendErrorToSentry({
      error,
      config: { tags: { websocket: 'v1' } }
    })
  }, [gdk])

  useEffect(() => {
    const subscription = gdk.auth.isLoggedIn.subscribe((res) => {
      if (!res && hasGDKLogin) {
        setHasGDKLogin(false)
      } else if (res && !hasGDKLogin) {
        gdk.socket.disconnect()

        setHasGDKLogin(true)
        setGDK(createAdminModule(apiUrl, wsUrl, timeout, gdkLocaleMap[i18n.language as Language]))
      }
    })
    return () => subscription.unsubscribe()
  }, [gdk, hasGDKLogin, i18n.language])

  useEffect(() => {
    const subscription = gdk.auth.isLoggedIn.pipe(
      combineLatestWith(gdk.auth.isWrong)
    ).subscribe({
      next: (res: [boolean, boolean]) => {
        const [isLogin, isWrong] = res
        if (isLogin && !isWrong) gdk.trunk.trigger([Branch.ME, Branch.ADMIN_ALERT_RECENT, Branch.SUPPORT_BANK, Branch.PLATFORMS, Branch.PUBLISHERS, Branch.PLAYER_LAYER])
      }
    })
    return () => subscription.unsubscribe()
  }, [gdk, hasGDKLogin, i18n.language])

  useEffect(() => {
    gdk.trunk.trigger([Branch.ADMIN_STATUS])
    const subscription = gdk.error.subscribe({
      next: (error: GDKError) => {
        if (error.code === ErrorCode.PLAYER_MAINTENANCE) {
          window.location.replace(`/maintenance.html?time=${(error.response as unknown as { comment: string }).comment}&title=${(error.response as unknown as { title: string }).title}`)
        }
        if (error.code === ErrorCode.AUTHENTICATOR_SECRET_KEY_NOT_DEFINE) {
          gdk.trunk.trigger([Branch.ME])
        }
        if (error.code === ErrorCode.IP_NOT_IN_WHITELIST) {
          gdk.trunk.trigger([Branch.ADMIN_STATUS])
        }
      }
    })
    return () => subscription.unsubscribe()
  }, [gdk])

  return (
    <GDKContext.Provider value={gdk}>
      {children}
    </GDKContext.Provider>
  )
}

export default GdkProvider

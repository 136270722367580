import React, { PropsWithChildren } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import AppBar from './AppBar'
import AppDrawer from './AppDrawer'

const useStyles = makeStyles((theme) => ({
  toolbar: {
    display: 'flex',
    padding: '0 8px',
    ...theme.mixins.toolbar
  },
  noScroll: {
    overflow: 'hidden'
  }
}))

interface PropTypes {
  drawerContentComponent?: any
  appBarContentComponent?: any
  text: string
}

const AppFrameBase: React.FC<PropsWithChildren<PropTypes>> = (props) => {
  const {
    drawerContentComponent: DrawerContentComponent = () => null,
    appBarContentComponent: AppBarContentComponent = () => null,
    text
  } = props
  const classes = useStyles()
  return (
    <React.Fragment>
      <AppBar text={text}>
        <AppBarContentComponent />
      </AppBar>
      <Box display="flex" className={classes.noScroll}>
        <AppDrawer>
          <DrawerContentComponent />
        </AppDrawer>
        <Box display="flex" flexDirection="column" flexGrow={1} overflow='hidden'>
          <div className={classes.toolbar} />
          {props.children}
        </Box>
      </Box>
    </React.Fragment>
  )
}

export default React.memo(AppFrameBase)

import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import ErrorIcon from '@material-ui/icons/Error'
import CustomThemeType from '../../themes/admin/CustomThemeType'
import { AccountWarnedType } from '@golden/gdk-admin'
import Tooltip from '@material-ui/core/Tooltip'
import useT from '../../i18ns/admin/useT'

const useStyles = makeStyles((theme: CustomThemeType) => ({
  fm_icon: {
    marginRight: theme.spacing(0.5),
    color: theme.palette.error.main,
    fontSize: theme.typography.h5.fontSize
  },
  rc_icon: {
    marginRight: theme.spacing(0.5),
    color: theme.custom.palette.buttonText.blue,
    fontSize: theme.typography.h5.fontSize
  }
}))

interface PropTypes {
  isWarned: boolean
  warnedType?: AccountWarnedType
}

const PlayerIsWarnedIcon: React.FC<PropTypes> = (props) => {
  const { isWarned, warnedType } = props
  const { t } = useT()
  const classes = useStyles()
  const toolTipName = `${(warnedType === AccountWarnedType.RISK_CONTROL) ? t('common.riskControl') : t('common.finance')} ${t('common.warning')}`
  if (!isWarned) return null
  return (
    <Tooltip title={toolTipName}>
      <ErrorIcon className={(warnedType === AccountWarnedType.RISK_CONTROL) ? classes.rc_icon : classes.fm_icon} />
    </Tooltip>
  )
}

export default React.memo(PlayerIsWarnedIcon)

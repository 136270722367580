import React, { createContext, Dispatch, SetStateAction, useCallback, useState } from 'react'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import StateProvider from '../../../providers/default/StateProvider'
import useT from '../../../i18ns/admin/useT'
import { useRedirectHandleBack, useDialogHandleSubmit } from '../../../utils/default/ComplexFlowHook'
import { Path } from '../../../components/admin/route/route'
import { useDialogStyles } from '../../../utils/admin/StyleHook'
import useGDK from '../../../providers/admin/gdk/useGDK'
import useGlobalDialog from '../../../providers/admin/dialog/useGlobalDialog'
import { createGlobalDialogConfig } from '../../../utils/default/DialogHelper'
import EmperorSearchForm from '../../../components/admin/EmperorSearchForm'
import AccountAgentWalletCreateForm, { formToRequest, FormType } from '../../../components/admin/account/AccountAgentWalletCreateForm'

const RequestContext = createContext<[
  string,
  Dispatch<SetStateAction<string>>
]>(['', () => {}])

const AccountAgentWalletCreateRechargePage: React.FC = () => {
  const dialogClasses = useDialogStyles()
  const [handleBack, handleDebouncedBack] = useRedirectHandleBack({
    path: Path.ACCOUNT_AGENT_WALLET
  })
  const { t } = useT()
  const gdk = useGDK()
  const [loading, setLoading] = useState<boolean>(false)
  const globalDialog = useGlobalDialog()
  const { handleSubmit } = useDialogHandleSubmit({
    dialogId: 'createRecharge',
    globalDialog,
    getChangeDialogConfig: useCallback((form: FormType) => {
      return createGlobalDialogConfig({
        showIcon: false,
        notUseTypo: true,
        message: (<Typography className={dialogClasses.text} align="center">{t('dialog.confirmCreateRecharge')}[{form.account}][{form.amount}]</Typography>)
      })
    }, [t, dialogClasses]),
    getSuccessDialogConfig: useCallback(() => createGlobalDialogConfig({
      showIcon: false,
      showCancel: false,
      message: t('dialog.createRechargeSuccess')
    }), [t]),
    onSuccess: () => {
      setLoading(false)
    },
    getFailDialogConfig: useCallback((error) => createGlobalDialogConfig({
      showIcon: true,
      variant: 'error',
      showCancel: false,
      message: error
    }), []),
    onError: () => {
      setLoading(false)
    },
    formToRequest,
    gdkFunc: (payload) => {
      setLoading(true)
      return gdk.agentWallet.recharge(payload)
    },
    gdkFuncDependencies: [gdk],
    afterSuccessDialog: handleBack
  })
  return (
    <StateProvider context={RequestContext} defaultValue={''}>
      <Box padding={5}>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <EmperorSearchForm context={RequestContext} />
          </Grid>
          <Grid item>
            <AccountAgentWalletCreateForm
              requireCode
              handleSubmit={handleSubmit}
              handleCancel={handleDebouncedBack}
              loading={loading}
              amountText={t('common.rechargeAmount')}
              requestContext={RequestContext}
            />
          </Grid>
        </Grid>
      </Box>
    </StateProvider>
  )
}

export default React.memo(AccountAgentWalletCreateRechargePage)

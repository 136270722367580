import React, { useState, useCallback } from 'react'
import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import EditSystemNotificationForm, { initialForm, formToRequest, FormType as EditSystemNotificationFormType } from '../../../components/admin/forestage/EditSystemNotificationForm'
import LoadingAndErrorFrame from '../../../components/default/frames/LoadingAndErrorFrame'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import useT from '../../../i18ns/admin/useT'
import useGlobalDialog from '../../../providers/admin/dialog/useGlobalDialog'
import allRoute, { Path } from '../../../components/admin/route/route'
import { useRedirectHandleBack, useDialogHandleSubmit, useGetDataByParams, useRequestFromSearch } from '../../../utils/default/ComplexFlowHook'
import { createGlobalDialogConfig } from '../../../utils/default/DialogHelper'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { usePageFlow } from '../../../utils/default/PageFlowHook'
import { searchToRequest } from './AnnouncementNotificationPage'
import { defaultEditorState, parseContent } from '@golden/tiptap-react'

const AnnouncementNotificationUpdatePage: React.FC = () => {
  const request = useRequestFromSearch({ searchToRequest })
  const classes = useCommonStyles()
  const { t } = useT()
  const pageFlow = usePageFlow()
  const [id, setId] = useState<number>(0)
  const [trigger, setTrigger] = useState<number>(0)
  const [defaultForm, setDefaultForm] = useState<EditSystemNotificationFormType>(initialForm())
  const globalDialog = useGlobalDialog()
  const gdk = useGDK()
  useGetDataByParams({
    path: Path.FORESTAGE_NOTIFICATION_UPDATE,
    gdkFunc: (param: { id: string }) => gdk.notification.getSystemtNotification(Number(param.id)),
    gdkFuncDependencies: [gdk],
    onBeforeFetch: pageFlow.setLoadingStart,
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    onSuccess: async (res) => {
      setDefaultForm(initialForm({
        title: res.title || '',
        content: {
          ...defaultEditorState,
          content: await parseContent(res.content)
        },
        channels: res.channels,
        isActive: res.is_active
      }))
      setId(res.id)
      setTrigger(res.trigger)
      pageFlow.setContentShow()
    },
    onError: pageFlow.setGDKError,
    canLoadData: id === 0
  })
  const [handleBack, handleDebouncedBack] = useRedirectHandleBack({ path: allRoute.forestageNotification.encodePath({ search: { ...request }, param: {} }) })
  const { handleSubmit } = useDialogHandleSubmit({
    dialogId: 'updateSystemNotification',
    globalDialog,
    getChangeDialogConfig: useCallback(() => createGlobalDialogConfig({
      showIcon: false,
      message: t('dialog.confirmUpdateSystemNotification')
    }), [t]),
    getSuccessDialogConfig: useCallback(() => createGlobalDialogConfig({
      showIcon: false,
      showCancel: false,
      message: t('dialog.updateSystemNotificationSuccess')
    }), [t]),
    getFailDialogConfig: useCallback((error) => createGlobalDialogConfig({
      showIcon: true,
      variant: 'error',
      showCancel: false,
      message: error
    }), []),
    formToRequest,
    gdkFunc: (payload) => gdk.notification.updateSystemNotification(id, payload),
    gdkFuncDependencies: [gdk, id],
    afterSuccessDialog: handleBack
  })
  return (
    <Box paddingY={5} paddingX={4}>
      <LoadingAndErrorFrame {...pageFlow.status}>
        <Paper>
          <Box padding={4}>
            <Box
              paddingY={1.25}
              paddingX={2}
              marginBottom={2}
              className={classes.pinkTitleBar}
            >
              <Typography variant="h5">
                {t('page.updateSystemNotification')}
              </Typography>
            </Box>
            <EditSystemNotificationForm
              notificationType={trigger}
              defaultValue={defaultForm}
              onSubmit={handleSubmit}
              okText={t('common.confirmUpdate')}
              onBack={handleDebouncedBack}
            />
          </Box>
        </Paper>
      </LoadingAndErrorFrame>
    </Box>
  )
}

export default React.memo(AnnouncementNotificationUpdatePage)

import React, { useState, useMemo, createContext, Dispatch, SetStateAction } from 'react'
import { EmperorInfo, PaginationRes } from '@golden/gdk-admin'
import { Link } from 'react-router-dom'
import PointsCell from '../../default/present/PointsCell'
import Button from '@material-ui/core/Button'
import MemoButtonWithContext from '../../default/memo/MemoButtonWithContext'
import MemoPopoverAndDialogWithContext from '../../default/memo/MemoPopoverAndDialogWithContext'
import LoadingAndErrorFrame from '../../default/frames/LoadingAndErrorFrame'
import CoreTable from '../../default/present/CoreTable'
import { searchToRequest, initialSort } from '../../../views/admin/account/AccountEmperorPage'
import { createDefaultMemoPopoverPayload, MemoPopoverPayload } from '../../default/memo/MemoPopover'
import { MemoDialogPayload, createDefaultMemoDialogPayload } from '../../default/memo/MemoDialog'
import { useRequestFromSearch, useGetDataByPayload, useSortClickAndChangeUrl, usePaginationClickAndChangeUrl, useReload } from '../../../utils/default/ComplexFlowHook'
import useGDK from '../../../providers/admin/gdk/useGDK'
import useGlobalDialog from '../../../providers/admin/dialog/useGlobalDialog'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import { usePageFlow } from '../../../utils/default/PageFlowHook'
import {
  createDefaultPaginationData,
  createTableData,
  formatCount
} from '../../../utils/default/TableHelper'
import allRoute from '../route/route'
import useT from '../../../i18ns/admin/useT'
import { useChecker } from '../../../utils/admin/AdminRouteHook'
import DateTime from '../../default/present/DateTime'

interface RowType {
  id: number
  account: string
  nickname: string
  created_at: React.ReactElement
  registeredUser: string
  depositedUser: string
  depositCount: string
  depositMoney: React.ReactElement
  percentage: string
  status: React.ReactElement
  agentCount: React.ReactElement
  memo: React.ReactElement
  operation: React.ReactElement
}

const MemoPopoverContext = createContext<[MemoPopoverPayload, Dispatch<SetStateAction<MemoPopoverPayload>>]>([
  createDefaultMemoPopoverPayload(),
  () => {}
])

const MemoDialogContext = createContext<[MemoDialogPayload, Dispatch<SetStateAction<MemoDialogPayload>>]>([
  createDefaultMemoDialogPayload(),
  () => {}
])

const AccountEmperorTable: React.FC = () => {
  const classes = useCommonStyles()
  const { t } = useT()
  const gdk = useGDK()
  const globalDialog = useGlobalDialog()
  const pageFlow = usePageFlow()
  const { reloadFlag, reload } = useReload()
  const [list, setList] = useState<PaginationRes<EmperorInfo[]>>(
    createDefaultPaginationData([])
  )
  const request = useRequestFromSearch({ searchToRequest })
  useGetDataByPayload({
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    payload: request!,
    gdkFunc: (payload) => gdk.emperor.getEmperorList(payload),
    gdkFuncDependencies: [gdk, reloadFlag],
    onBeforeFetch: pageFlow.setLoadingStart,
    onSuccess: (res: PaginationRes<EmperorInfo[]>) => {
      setList(res)
      pageFlow.setContentShow()
    },
    onError: pageFlow.setGDKError,
    canLoadData: request !== undefined
  })

  const writable = useChecker()
  const tableClasses = useMemo(() => {
    return {
      head: classes.pinkTableHead,
      cellHead: classes.tableCellHead,
      row: classes.tableRow
    }
  }, [classes])
  const sort = useMemo(() => {
    const initSort = initialSort()
    if (request === undefined) return { column: initSort.sort_by, order: initSort.order }
    return { column: request.sort_by, order: request.order }
  }, [request])
  const rows: RowType[] = useMemo(() => {
    return list.data.map((item) => {
      return {
        id: item.id,
        account: item.account,
        nickname: item.nickname,
        created_at: (<DateTime time={item.created_at} />),
        registeredUser: formatCount(item.registered_user),
        depositedUser: formatCount(item.deposited_user),
        depositCount: formatCount(item.deposit_count),
        depositMoney: (<PointsCell points={item.cash_deposit} />),
        percentage: `${Number(item.percentage)}%`,
        status: (<span style={{ color: (item.is_loginable ? 'green' : 'red') }}>{(item.is_loginable ? t('common.normal') : t('common.isNotLoginable'))}</span>),
        agentCount: (
          <Link to={allRoute.accountEmperorLayer.encodePath({ search: {}, param: { id: item.id } })}>
            {item.agent_count}
          </Link>
        ),
        memo: (
          <MemoButtonWithContext
            onlyRead={!writable}
            memoDialogContext={MemoDialogContext}
            memoPopoverContext={MemoPopoverContext}
            showIconInSuccess={false}
            memo={item.memo}
            account={item.account}
            nickname={item.nickname}
            id={item.id}
            type="agent"
          />
        ),
        operation: (
          <Button
            component={Link}
            to={allRoute.accountEmperorUpdate.encodePath({ search: {}, param: { id: item.id } })}
            size="small"
            className={classes.blueGradualOutlineButton}
            classes={{ disabled: classes.blueGradualOutlineDisabledButton }}
          >
            {t('common.update')}
          </Button>
        )
      } as RowType
    })
  }, [list.data, t, writable, classes.blueGradualOutlineButton, classes.blueGradualOutlineDisabledButton])
  const data = useMemo(() => {
    return createTableData<RowType>(
      {
        id: {
          label: '',
          value: 'id'
        },
        account: {
          label: t('common.account'),
          value: 'account',
          align: 'center',
          canSort: true,
          width: 90
        },
        nickname: {
          label: t('common.nickname'),
          value: 'nickname',
          align: 'center',
          width: 120
        },
        created_at: {
          label: t('common.createdAt'),
          value: 'created_at',
          align: 'center',
          canSort: true,
          width: 120
        },
        registeredUser: {
          label: t('common.registeredPlayerCount'),
          value: 'registeredUser',
          align: 'center',
          width: 120
        },
        depositedUser: {
          label: t('common.depositedPlayerCount'),
          value: 'depositedUser',
          align: 'center',
          width: 120
        },
        depositCount: {
          label: t('common.playerDepositCount'),
          value: 'depositCount',
          align: 'center',
          width: 140
        },
        depositMoney: {
          label: t('common.playerDepositMoney'),
          value: 'depositMoney',
          align: 'right',
          width: 140
        },
        percentage: {
          label: t('common.splitPercentage'),
          value: 'percentage',
          align: 'center',
          width: 140
        },
        status: {
          label: t('common.status'),
          value: 'status',
          align: 'center',
          width: 120
        },
        agentCount: {
          label: t('common.manageLayer'),
          value: 'agentCount',
          align: 'center',
          width: 120
        },
        memo: {
          label: t('common.memo'),
          value: 'memo',
          align: 'center',
          width: 120
        },
        operation: {
          label: t('common.operation'),
          value: 'operation',
          align: 'center'
        }
      },
      [
        'account',
        'nickname',
        'created_at',
        'registeredUser',
        'depositedUser',
        'depositCount',
        'depositMoney',
        'percentage',
        'status',
        'agentCount',
        'memo',
        ...(writable ? ['operation'] as const : [])
      ],
      rows,
      'id'
    )
  }, [rows, t, writable])

  const handleSort = useSortClickAndChangeUrl({
    request,
    encodePath: allRoute.accountEmperor.encodePath
  })

  const handlePagination = usePaginationClickAndChangeUrl({
    request,
    encodePath: allRoute.accountEmperor.encodePath
  })

  if (request === undefined) return null

  return (
    <LoadingAndErrorFrame { ...pageFlow.status }>
      <MemoPopoverAndDialogWithContext
        gdk={gdk}
        globalDialog={globalDialog}
        memoDialogContext={MemoDialogContext}
        memoPopoverContext={MemoPopoverContext}
        onOK={reload}
      >
        <CoreTable
          classes={tableClasses}
          data={data}
          total={list.total}
          showPagination
          page={request.page}
          sorted={sort}
          onSort={handleSort}
          onChangePage={handlePagination}
        />
      </MemoPopoverAndDialogWithContext>
    </LoadingAndErrorFrame>
  )
}

export default React.memo(AccountEmperorTable)

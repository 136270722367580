import { Dispatch, SetStateAction } from 'react'
import { Variant } from '../../components/default/dialogs/CoreDialog'

export interface GlobalDialogType {
  open: OpenPayload
  setOpen: Dispatch<SetStateAction<OpenPayload>>
  setConfig: Dispatch<SetStateAction<GloablDialogConfig>>
  clearState: () => void
}

export interface OpenPayload {
  id: string
  value: boolean
  isOK: boolean
  payload?: any
}

export interface GloablDialogConfig {
  variant: Variant
  message: string | React.ReactElement
  showIcon: boolean
  okText?: string
  showOK: boolean
  cancelText?: string
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false
  showCancel: boolean
  notUseTypo: boolean
}

export function createGlobalDialogConfig (config?: Partial<GloablDialogConfig>): GloablDialogConfig {
  return {
    variant: 'info',
    message: '',
    showIcon: true,
    showOK: true,
    showCancel: true,
    notUseTypo: false,
    ...config
  }
}

import { ClientPlatStrType } from '@golden/gdk-admin'
import TKeyType from '../../i18ns/admin/TKeyType'

const clientPlatformName: { [key in ClientPlatStrType]: TKeyType } = {
  [ClientPlatStrType.PC]: 'device.computer',
  [ClientPlatStrType.H5]: 'device.h5',
  [ClientPlatStrType.APP]: 'device.app'
}

export default clientPlatformName

import React, { useState, useMemo, Dispatch, SetStateAction, createContext } from 'react'
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import MuiTextField, { TextFieldProps } from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import DateTime from '../../../components/default/present/DateTime'
import ScrollablePaper from '../../../components/default/present/ScrollablePaper'
import LoadingAndErrorFrame from '../../../components/default/frames/LoadingAndErrorFrame'
import CoreTable from '../../../components/default/present/CoreTable'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import useT from '../../../i18ns/admin/useT'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { PaginationRes, PlayerJumpShipItem, PaginationReq, PlayerJumpShipReasonType, JumpShipStatusType, ADMIN_API } from '@golden/gdk-admin'
import { usePageFlow } from '../../../utils/default/PageFlowHook'
import { useRequestFromSearch, SearchToRequestFunc, useGetDataByPayload, usePaginationClickAndChangeUrl, useReload, usePerPageChange, useChangeUrlSubmit } from '../../../utils/default/ComplexFlowHook'
import { pipe, parseInt, guaranteeBetween, acceptUndefined, convertEmptyToUndefined, getValueFromChangeEvent } from '../../../utils/default/FormHelper'
import { createDefaultPaginationData, createTableData, formatDateTime } from '../../../utils/default/TableHelper'
import playerJumpShipReasonName from '../../../constants/admin/playerJumpShipReasonName'
import allRoute, { Path } from '../../../components/admin/route/route'
import jumpShipStatusColor from '../../../constants/admin/jumpShipStatusColor'
import jumpShipStatusName from '../../../constants/admin/jumpShipStatusName'
import { useChecker } from '../../../utils/admin/AdminRouteHook'
import playerJumpShipErrorTypeName from '../../../constants/admin/playerJumpShipErrorTypeName'
import JumpShipCancelButton from '../../../components/admin/player/JumpShipCancelButton'
import JumpShipCancelDialog, { Payload as DialogPayload } from '../../../components/admin/player/JumpShipCancelDialog'
import StateProvider from '../../../providers/default/StateProvider'
import FormStateProvider from '../../../components/default/form/FormStateProvider'
import FormField from '../../../components/default/form/FormField'
import FormSubmitButton from '../../../components/default/form/FormSubmitButton'
import { createDefaultFormState, FormValidation, ValueGetter } from '../../../utils/default/FormHook'
import MemoPopoverWithContext from '../../../components/default/memo/MemoPopoverWithContext'
import MemoTextWithContext from '../../../components/default/memo/MemoTextWithContext'
import { createDefaultMemoPopoverPayload, MemoPopoverPayload } from '../../../components/default/memo/MemoPopover'

const useStyles = makeStyles(() => ({
  textField: {
    paddingTop: 14,
    paddingBottom: 14
  },
  clip: {
    textOverflow: 'clip',
    width: '100%'
  }
}))

const MemoPopoverContext = createContext<[MemoPopoverPayload, Dispatch<SetStateAction<MemoPopoverPayload>>]>([
  createDefaultMemoPopoverPayload(),
  () => {}
])

const defaultPayload: DialogPayload = {
  item: null,
  open: false
}

export const DialogPayloadContext = createContext<[
  DialogPayload,
  Dispatch<SetStateAction<DialogPayload>>
]>([
  defaultPayload,
  () => {}
])

interface RowType {
  id: number
  applyTime: React.ReactElement
  player: string
  past: string
  now: string
  reason: React.ReactElement
  result: React.ReactElement
  memo: React.ReactElement
  processTime: React.ReactElement
  updateBy: string
  operate: React.ReactElement
}

interface FormType {
  account: string
}

const initialForm = (): FormType => ({
  account: ''
})
const defaultForm = initialForm()

const getValueFromEvent: ValueGetter<FormType> = {
  account: getValueFromChangeEvent
}

const searchToRequest: SearchToRequestFunc<{ account?: string } & PaginationReq> = (search) => {
  return {
    ...search,
    page: acceptUndefined(search.page, pipe(
      parseInt,
      (value) => guaranteeBetween(value, 1, Number.MAX_SAFE_INTEGER)
    )) || 1
  }
}

const formToRequest = (form: FormType) => {
  if (convertEmptyToUndefined(form.account)) {
    return { account: convertEmptyToUndefined(form.account) }
  }
  return {}
}

const TextField = React.memo(MuiTextField)
const FormContext = createContext(createDefaultFormState(defaultForm))

const PlayerJumpShipPage: React.FC = () => {
  const classes = useStyles()
  const commonClasses = useCommonStyles()
  const { t } = useT()
  const gdk = useGDK()
  const { reload, reloadFlag } = useReload()
  const [list, setList] = useState<PaginationRes<PlayerJumpShipItem[]>>(createDefaultPaginationData([]))
  const pageFlow = usePageFlow()
  const request = useRequestFromSearch({ searchToRequest })
  const writable = useChecker()
  const validation = useMemo(() => {
    return {
      account: []
    } as FormValidation<FormType>
  }, [])
  useGetDataByPayload({
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    payload: request!,
    gdkFunc: (payload) => gdk.jumpShip.getJumpShipList(payload),
    gdkFuncDependencies: [gdk, reloadFlag],
    onBeforeFetch: pageFlow.setLoadingStart,
    onSuccess: (res: PaginationRes<PlayerJumpShipItem[]>) => {
      setList(res)
      pageFlow.setContentShow()
    },
    onError: pageFlow.setGDKError,
    canLoadData: request !== undefined
  })
  const tableClasses = useMemo(() => ({
    head: commonClasses.pinkTableHead,
    row: commonClasses.tableRow,
    cellHead: commonClasses.tableCellHead
  }), [commonClasses])
  const rows: RowType[] = useMemo(() => {
    return list.data.map((item, index) => {
      const reason = item.reason !== PlayerJumpShipReasonType.CUSTOM ? t(playerJumpShipReasonName[item.reason]) : `${t('common.custom')}: ${item.description}`
      const reasonMemo = [item.not_check_rule, item.not_check_agent_chain]
        .map((item, index) => {
          if (!item) return null
          if (index === 0) return t('common.notCheckJumpShipRule')
          if (index === 1) return t('common.notCheckAgentChain')
          return null
        })
        .filter((item) => item !== null)
        .join('、')
      return {
        id: index,
        applyTime: (<DateTime time={item.operate_at} />),
        player: item.player_account,
        past: item.original_staff_account,
        now: item.new_staff_account,
        reason: item.not_check_rule || item.not_check_agent_chain
          ? (
          <MemoTextWithContext
            classes={{ text: classes.clip }}
            memoPopoverContext={MemoPopoverContext}
            memo={reasonMemo}
          >
            <span className={commonClasses.anchor}>{reason}</span>
          </MemoTextWithContext>
            )
          : (
          <span>{reason}</span>
            ),
        result: (<span className={commonClasses.chipText} style={{ backgroundColor: jumpShipStatusColor[item.status] }}>{t(jumpShipStatusName[item.status])}</span>),
        memo: (
          item.error ? (<Typography color="error">{t(playerJumpShipErrorTypeName[item.error])}</Typography>) : (<p></p>)
        ),
        processTime: item.processed_at ? (<DateTime time={item.processed_at} />) : (<p>--</p>),
        updateBy: item.operator_account,
        operate: item.status === JumpShipStatusType.PENDING
          ? writable ? (<JumpShipCancelButton item={item} />) : (<></>)
          : (item.status === JumpShipStatusType.CANCEL && item.canceled_at
              ? (
          <React.Fragment>
            <Typography color="error">{formatDateTime(item.canceled_at)}</Typography>
            <Typography color="error">{t('common.cancelJumpShip')}</Typography>
          </React.Fragment>
                )
              : (<p></p>))
      }
    })
  }, [list.data, t, classes.clip, commonClasses.anchor, commonClasses.chipText, writable])
  const data = useMemo(() => {
    return createTableData<RowType>(
      {
        id: {
          label: '',
          value: 'id'
        },
        applyTime: {
          label: t('common.applyAt'),
          value: 'applyTime',
          align: 'center'
        },
        player: {
          label: t('common.playerAccount'),
          value: 'player',
          align: 'center'
        },
        past: {
          label: t('common.originalStaffAccount'),
          value: 'past',
          align: 'center'
        },
        now: {
          label: t('common.newStaffAccount'),
          value: 'now',
          align: 'center'
        },
        reason: {
          label: t('common.jumpShipReason'),
          value: 'reason',
          align: 'center',
          width: 180
        },
        result: {
          label: t('common.operationResult'),
          value: 'result',
          align: 'center'
        },
        memo: {
          label: t('common.memo'),
          value: 'memo',
          align: 'center',
          width: 180
        },
        processTime: {
          label: t('common.systemOperationTime'),
          value: 'processTime',
          align: 'center'
        },
        updateBy: {
          label: t('common.updateBy'),
          value: 'updateBy',
          align: 'center'
        },
        operate: {
          label: t('common.operation'),
          value: 'operate',
          align: 'center',
          width: 120
        }
      },
      [
        'applyTime',
        'player',
        'past',
        'now',
        'reason',
        'result',
        'memo',
        'processTime',
        'updateBy',
        'operate'
      ],
      rows,
      'id'
    )
  }, [rows, t])
  const handlePagination = usePaginationClickAndChangeUrl({
    request,
    encodePath: allRoute.playerJumpShip.encodePath
  })
  const handlePerPageChange = usePerPageChange({
    request,
    encodePath: allRoute.playerJumpShip.encodePath,
    gdk,
    pageKey: ADMIN_API.GET_PLAYER_JUMP_SHIP_RECORD.url
  })
  const handleSearch = useChangeUrlSubmit({
    formToRequest,
    encodePath: allRoute.playerJumpShip.encodePath,
    toAddNowTimestamp: true
  })
  if (request === undefined) return null
  return (
    <StateProvider
      context={DialogPayloadContext}
      defaultValue={defaultPayload}
    >
      <Box paddingY={5}>
        <ScrollablePaper marginX={5}>
          <Box padding={4}>
            <FormStateProvider<FormType>
              context={FormContext}
              defaultValue={defaultForm}
              onSubmit={handleSearch}
              validation={validation}
              getValueFromEvent={getValueFromEvent}
            >
              <Box paddingBottom={2} display="flex" justifyContent="flex-end">
                <FormField<FormType, TextFieldProps>
                  context={FormContext}
                  component={TextField}
                  name="account"
                  variant="outlined"
                  InputProps={{ classes: { input: classes.textField } }}
                  placeholder={t('placeholder.inputPlayerAccount')}
                />
                <Box paddingLeft={2} display="flex">
                  <FormSubmitButton
                    component={Button}
                    context={FormContext}
                    type="submit"
                    className={commonClasses.purpleGradualButton}
                  >
                    {t('common.search')}
                  </FormSubmitButton>
                </Box>
                {writable && (
                  <Box paddingLeft={2} display="flex">
                    <Button
                      component={Link}
                      to={Path.PLAYER_JUMP_SHIP_CREATE}
                      className={commonClasses.purpleGradualButton}
                    >
                      {t('common.playerJumpShip')}
                    </Button>
                  </Box>
                )}
              </Box>
            </FormStateProvider>
            <MemoPopoverWithContext memoPopoverContext={MemoPopoverContext}>
              <LoadingAndErrorFrame { ...pageFlow.status } >
                <CoreTable
                  classes={tableClasses}
                  data={data}
                  total={list.total}
                  showPagination
                  page={request.page}
                  perPage={list.per_page}
                  onChangePage={handlePagination}
                  onChangeRowsPerPage={handlePerPageChange}
                />
              </LoadingAndErrorFrame>
            </MemoPopoverWithContext>
          </Box>
        </ScrollablePaper>
      </Box>
      <JumpShipCancelDialog reload={reload} />
    </StateProvider>
  )
}

export default React.memo(PlayerJumpShipPage)

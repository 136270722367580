import React, { useState, Dispatch, SetStateAction } from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { Authenticator, Branch } from '@golden/gdk-admin'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import GoogleAuthenticatorForm, { FormType as GoogleAuthenticatorFormType } from '../GoogleAuthenticatorForm'
import useGDK from '../../../providers/admin/gdk/useGDK'
import useGDKStore from '../../../providers/admin/gdk/useGDKStore'
import useT from '../../../i18ns/admin/useT'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import { useGetData, useGDKFuncHandleSubmit } from '../../../utils/default/ComplexFlowHook'
import { createGlobalDialogConfig } from '../../../utils/default/DialogHelper'
import useGlobalDialog from '../../../providers/admin/dialog/useGlobalDialog'

const useStyles = makeStyles((theme) => ({
  installExplain: {
    cursor: 'pointer',
    borderRadius: theme.shape.borderRadius,
    border: '1px solid #01c0c8',
    backgroundColor: theme.palette.grey[200],
    color: '#01c0c8',
    padding: theme.spacing(1),
    textDecoration: 'none'
  },
  text: {
    overflowWrap: 'anywhere',
    textAlign: 'center',
    wordBreak: 'break-all'
  },
  container: {
    [theme.breakpoints.up('xl')]: {
      borderRight: `2px solid ${theme.palette.grey[300]}`
    }
  }
}))

interface PropTypes {
  setShow: Dispatch<SetStateAction<boolean>>
}

const BaseDataGoogleAuthenticatorSection: React.FC<PropTypes> = (props) => {
  const { setShow } = props
  const theme = useTheme()
  const classes = useStyles()
  const commonClasses = useCommonStyles()
  const globalDialog = useGlobalDialog()
  const [qrcode, setQrcode] = useState<Authenticator>()
  const me = useGDKStore.admin.me()
  const gdk = useGDK()
  const { t } = useT()

  useGetData({
    gdkFunc: () => gdk.auth.issueAuthenticator(),
    gdkFuncDependencies: [gdk],
    onSuccess: setQrcode,
    canLoadData: me?.is_need_authenticator
  })

  const { handleSubmit } = useGDKFuncHandleSubmit({
    formToRequest: (form: GoogleAuthenticatorFormType) => form.join(''),
    gdkFunc: (payload) => gdk.auth.validateAuthenticator(payload),
    gdkFuncDependencies: [gdk],
    onSuccess: () => {
      setShow(true)
      gdk.trunk.trigger([Branch.ME])
    },
    onError: (error) => {
      globalDialog.setConfig(createGlobalDialogConfig({
        variant: 'error',
        showCancel: false,
        message: error.message
      }))
      globalDialog.setOpen({ id: 'authenticatorError', value: true, isOK: false })
    }
  })

  if (me === null) return null
  return (
    <Paper>
      <Box padding={4}>
        <Grid container direction="column" spacing={4}>
          <Grid item>
            <Box
              display="flex"
              flexDirection="row"
              paddingY={1.25}
              paddingX={2}
              className={commonClasses.pinkTitleBar}
            >
              <Typography variant="h5">
                {t('common.googleAuthenticator')}
              </Typography>
              <Box
                marginX={2}
                paddingX={2}
                paddingY={0.5}
                borderRadius={theme.shape.borderRadius}
                color={theme.palette.common.white}
                bgcolor={me.is_need_authenticator ? '#c10000' : '#1fc485'}
              >
                {me.is_need_authenticator ? t('common.notBind') : t('common.bound')}
              </Box>
            </Box>
          </Grid>
          <Grid item>
            <Grid container direction="row" wrap="wrap">
              {me.is_need_authenticator && (
                <Grid item lg={12} xl={4}>
                  <Box
                    marginX={4}
                    paddingY={1}
                    paddingX={4}
                    className={classes.container}
                  >
                    <Grid container direction="column" alignItems="center">
                      <Grid item>
                        <img src={gdk.qrcode.generateURL(qrcode?.url ?? '', 200)} alt="" width="200" height="200" />
                      </Grid>
                      <Grid item>
                        <Typography variant="h6" className={commonClasses.bold}>
                          {t('common.systemKey')}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="body1" className={classes.text}>
                          {qrcode?.key ?? ''}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Box paddingTop={1}>
                          <Typography variant="h6" className={commonClasses.bold}>
                            {t('common.inputGoogleCode')}
                          </Typography>
                        </Box>
                      </Grid>
                      <GoogleAuthenticatorForm onSubmit={handleSubmit} />
                    </Grid>
                  </Box>
                </Grid>
              )}
              <Grid item lg={12} xl={6}>
                <Box paddingY={1}>
                  <Grid container direction="column" spacing={2}>
                    <Grid item>
                      <Typography variant="h6" className={commonClasses.bold}>
                        {t('common.googleAuthenticatorExplain')}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Box paddingTop={2}>
                        {t('common.googleAuthenticatorExplainPlain')}
                      </Box>
                    </Grid>
                    <Grid item>
                      <Grid container direction="row">
                        <a
                          href="/"
                          target="_blank"
                          className={classes.installExplain}
                        >
                          {t('common.installGoogleAuthenticatorExplain')}
                        </a>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Box paddingTop={6}>
                        <Typography variant="h6" className={commonClasses.bold}>
                          {t('common.googleAuthenticatorSoftwareDownload')}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item>
                      <Grid container direction="row" spacing={4}>
                        <Grid item>
                          <Box
                            width={100}
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                          >
                            <img src={gdk.qrcode.generateURL(t('qrcode.android'), 300)} width={100} alt="Android" />
                            <Typography variant="body1" className={commonClasses.bold}>
                              {t('common.android')}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item>
                          <Box
                            width={100}
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                          >
                            <img src={gdk.qrcode.generateURL(t('qrcode.googlePlay'), 300)} width={100} alt="Android" />
                            <Typography variant="body1" className={commonClasses.bold}>
                              {t('common.googlePlay')}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item>
                          <Box
                            width={100}
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                          >
                            <img src={gdk.qrcode.generateURL(t('qrcode.appleStore'), 300)} width={100} alt="Android" />
                            <Typography variant="body1" className={commonClasses.bold}>
                              {t('common.ios')}
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  )
}

export default React.memo(BaseDataGoogleAuthenticatorSection)

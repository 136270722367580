import React, { createContext, useContext, useMemo, useCallback } from 'react'
import { omitBy, isUndefined } from '@golden/utils'
import { getTime, startOfYear, startOfDay, endOfDay, subYears } from 'date-fns'
import MuiButton from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { ActivitySearchTimeType, ActivityApplicationRequest } from '@golden/gdk-admin'
import { SearchToRequestFunc, InitialFormFunc, useRequestFromSearch, useChangeUrlSubmit } from '../../../../utils/default/ComplexFlowHook'
import { guaranteeNotUndefined, parseInt, guaranteeBetween, getTimeFromDateInputValue, getValueFromChangeEvent, pipe, convertEmptyToUndefined, guaranteeBeOneOf, getBankAccountInputProps } from '../../../../utils/default/FormHelper'
import activitySearchTimeName from '../../../../constants/admin/activitySearchTimeName'
import DateInputBase, { DateInputValue } from '../../../default/form/DateInput'
import { ValueGetter, createDefaultFormState, FormValidation } from '../../../../utils/default/FormHook'
import useT from '../../../../i18ns/admin/useT'
import DropDown from '../../../default/form/DropDown'
import { setToday, setYesterday, setThisMonth, setThisWeek, setLastMonth, setLastWeek, createShouldDisableDate } from '../../../../utils/default/TimeHelper'
import { useCommonStyles } from '../../../../utils/admin/StyleHook'
import FormStateProvider from '../../../default/form/FormStateProvider'
import allRoute from '../../route/route'
import RequiredText from '../../../default/form/RequiredText'
import FormSubmitButton from '../../../default/form/FormSubmitButton'
import FormField from '../../../../components/default/form/FormField'
import { TextField, TextFieldProps } from '@material-ui/core'
import NumberInput from '../../../../components/default/form/NumberInput'

interface ActivityApplicationFormType {
  type: ActivitySearchTimeType
  time: DateInputValue
  batch_id: string
  activity_title: string
  user_account: string
  agent_account: string
  deposit_serial_number: string
}

export const initialForm: InitialFormFunc<ActivityApplicationFormType> = (defaultForm) => ({
  type: ActivitySearchTimeType.APPLIED_TIME,
  time: {
    start: startOfDay(new Date()),
    end: endOfDay(new Date())
  },
  batch_id: '',
  activity_title: '',
  user_account: '',
  agent_account: '',
  deposit_serial_number: '',
  ...defaultForm
})

const formToRequest = (form: ActivityApplicationFormType): Partial<ActivityApplicationRequest> => {
  const converted = {
    type: form.type,
    start_at: form.time.start ? getTime(form.time.start) : startOfDay(new Date()),
    end_at: form.time.end ? getTime(form.time.end) : endOfDay(new Date()),
    batch_id: convertEmptyToUndefined(form.batch_id),
    activity_title: convertEmptyToUndefined(form.activity_title),
    user_account: convertEmptyToUndefined(form.user_account),
    agent_account: convertEmptyToUndefined(form.agent_account),
    deposit_serial_number: convertEmptyToUndefined(form.deposit_serial_number),
    page: 1
  }
  return omitBy(converted, isUndefined)
}

export const searchToRequest: SearchToRequestFunc<ActivityApplicationRequest> = (search) => {
  const fiveYearsAgo = getTime(startOfYear(subYears(new Date(), 5)))
  const endOfToday = getTime(endOfDay(new Date()))
  const converted = {
    ...search,
    page: pipe(
      guaranteeNotUndefined,
      parseInt,
      (value) => guaranteeBetween(value, 1, Number.MAX_SAFE_INTEGER)
    )(search.page),
    type: pipe(
      (value) => guaranteeNotUndefined(value as string),
      parseInt,
      (value) => guaranteeBeOneOf(value, Object.keys(activitySearchTimeName).map((key) => Number(key)))
    )(search.type),
    start_at: pipe(
      (value) => guaranteeNotUndefined(value as string),
      parseInt
    )(search.start_at),
    end_at: pipe(
      (value) => guaranteeNotUndefined(value as string),
      parseInt
    )(search.end_at)
  }
  if (converted.end_at < converted.start_at) throw new Error('The end time can\'t exceed the start time')
  return omitBy(converted, isUndefined) as ActivityApplicationRequest
}

const getValueFromEvent: ValueGetter<ActivityApplicationFormType> = {
  type: getValueFromChangeEvent,
  time: getTimeFromDateInputValue,
  activity_title: getValueFromChangeEvent,
  user_account: getValueFromChangeEvent,
  agent_account: getValueFromChangeEvent,
  batch_id: getValueFromChangeEvent,
  deposit_serial_number: getValueFromChangeEvent
}

const FormContext = createContext(createDefaultFormState(initialForm()))

const Button = React.memo(MuiButton)

const SearchDropDown: React.FC = React.memo(() => {
  const { t } = useT()
  const { value, handleChange, handleOther, error } = useContext(FormContext)
  const searchTimeOptions = useMemo(() => {
    return Object.keys(activitySearchTimeName)
      .map((key) => Number(key) as ActivitySearchTimeType)
      .map((key) => ({ name: t(activitySearchTimeName[key]), value: key }))
  }, [t])
  return (
    <Grid item xs={12} md={6} lg={3}>
      <DropDown
        value={value.type}
        onChange={handleChange('type')}
        onBlur={handleOther('blur', 'type')}
        options={searchTimeOptions}
        label={t('common.searchDate')}
        fullWidth
        required
        error={error.type !== null}
        helperText={error.type ?? ''}
      />
    </Grid>
  )
})

const DateInput: React.FC = React.memo(() => {
  const classes = useCommonStyles()
  const { t } = useT()
  const { value, handleChange } = useContext(FormContext)
  const {
    shouldDisableStartDate,
    shouldDisableEndDate
  } = useMemo(() => {
    const fiveYearsAgo = startOfYear(subYears(new Date(), 5))
    return createShouldDisableDate(value.time.start, value.time.end, fiveYearsAgo)
  }, [value.time])
  const tools = useMemo(() => {
    return [
      {
        label: t('common.today'),
        change: setToday
      },
      {
        label: t('common.yesterday'),
        change: setYesterday
      },
      {
        label: t('common.thisWeek'),
        change: setThisWeek
      },
      {
        label: t('common.lastWeek'),
        change: setLastWeek
      },
      {
        label: t('common.thisMonth'),
        change: setThisMonth
      },
      {
        label: t('common.lastMonth'),
        change: setLastMonth
      }
    ]
  }, [t])
  const startOption = useMemo(() => ({
    label: t('common.beginAt'),
    shouldDisableDate: shouldDisableStartDate
  }), [shouldDisableStartDate, t])

  const endOption = useMemo(() => ({
    label: t('common.endAt'),
    shouldDisableDate: shouldDisableEndDate
  }), [shouldDisableEndDate, t])

  const dateClasses = useMemo(() => ({
    button: classes.pinkGradualButton
  }), [classes.pinkGradualButton])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onChange = useCallback(handleChange('time'), [])

  return (
    <DateInputBase
      value={value.time}
      onChange={onChange}
      start={startOption}
      end={endOption}
      tools={tools}
      classes={dateClasses}
      FrontComponent={SearchDropDown}
    />
  )
})

const ActivityApplicationForm: React.FC = () => {
  const classes = useCommonStyles()
  const { t } = useT()
  const request = useRequestFromSearch({ searchToRequest })
  const defaultForm = useMemo(() => {
    if (request === undefined) {
      return initialForm()
    }
    return initialForm({
      time: {
        start: new Date(request.start_at),
        end: new Date(request.end_at)
      },
      ...request
    })
  }, [request])

  const handleSubmit = useChangeUrlSubmit({
    toAddNowTimestamp: true,
    formToRequest,
    encodePath: allRoute.activityApplication.encodePath
  })

  const validation = useMemo(() => ({
    time: [],
    type: [],
    activity_title: [],
    batch_id: [],
    user_account: [],
    agent_account: [],
    deposit_serial_number: []
  } as FormValidation<ActivityApplicationFormType>), [])

  return (
    <FormStateProvider
      context={FormContext}
      defaultValue={defaultForm}
      onSubmit={handleSubmit}
      validation={validation}
      getValueFromEvent={getValueFromEvent}
    >
      <Paper>
        <Box padding={4}>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <Box
                paddingY={1.25}
                paddingX={2}
                className={classes.pinkTitleBar}
              >
                <Typography variant="h5">
                  {t('page.activityApplication')}
                </Typography>
              </Box>
            </Grid>
            <Grid item>
              <DateInput />
            </Grid>
            <Grid item>
              <Grid container direction="row" wrap="wrap" spacing={2}>
                <Grid item xs={12} md={6} lg={3}>
                  <FormField<ActivityApplicationFormType, TextFieldProps>
                    context={FormContext}
                    component={NumberInput}
                    name="batch_id"
                    label={t('common.batchNumberId')}
                    placeholder={t('placeholder.pleaseInput', { item: t('common.batchNumberId') })}
                    fullWidth
                    inputProps={getBankAccountInputProps()}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <FormField<ActivityApplicationFormType, TextFieldProps>
                    context={FormContext}
                    component={TextField}
                    name="activity_title"
                    label={t('common.activityName')}
                    placeholder={t('placeholder.pleaseInput', { item: t('common.activityName') })}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <FormField<ActivityApplicationFormType, TextFieldProps>
                    context={FormContext}
                    component={TextField}
                    name="user_account"
                    label={t('common.playerAccount')}
                    placeholder={t('placeholder.inputPlayerAccount')}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <FormField<ActivityApplicationFormType, TextFieldProps>
                    context={FormContext}
                    component={TextField}
                    name="agent_account"
                    label={t('common.agentAccount')}
                    placeholder={t('placeholder.inputAgentAccount')}
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid container direction="row" wrap="wrap" spacing={2}>
                <Grid item xs={12} md={6} lg={3}>
                  <FormField<ActivityApplicationFormType, TextFieldProps>
                    context={FormContext}
                    component={TextField}
                    name="deposit_serial_number"
                    label={t('common.depositSerialNumber')}
                    placeholder={t('placeholder.depositSerialNumber')}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <RequiredText />
            </Grid>
            <Grid item>
              <Grid container justifyContent="flex-end">
                <Grid item>
                  <FormSubmitButton
                    component={Button}
                    context={FormContext}
                    type="submit"
                    className={classes.purpleGradualButton}
                  >
                    {t('common.search')}
                  </FormSubmitButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </FormStateProvider>
  )
}
export default React.memo(ActivityApplicationForm)

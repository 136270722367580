import React, { useState, useMemo, createContext, Dispatch, SetStateAction } from 'react'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import { useCommonStyles, useDialogStyles } from '../../../utils/admin/StyleHook'
import { makeStyles } from '@material-ui/core'
import CustomThemeType from '../../../themes/default/CustomThemeType'
import useT from '../../../i18ns/admin/useT'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { createDefaultPaginationData, formatCount, formatMoney, createTableData } from '../../../utils/default/TableHelper'
import { PaginationRes, ActivityUefaBuildingUp, ActivityUefaBuildingUpSum, ActivityUefaBuildingUpQuery, PaginationReq, ActivityType, ActivityUefaBuildingUpStateType } from '@golden/gdk-admin'
import { usePageFlow } from '../../../utils/default/PageFlowHook'
import { searchToRequest } from './ActivityRecordDetailForm'
import { useRequestFromSearch, useGetDataByPayload, usePaginationClickAndChangeUrl } from '../../../utils/default/ComplexFlowHook'
import DateTime from '../../default/present/DateTime'
import ScrollablePaper from '../../default/present/ScrollablePaper'
import useGDKStore from '../../../providers/admin/gdk/useGDKStore'
import CoreTable from '../../default/present/CoreTable'
import allRoute, { Path } from '../route/route'
import LoadingAndErrorFrame from '../../default/frames/LoadingAndErrorFrame'
import MemoPopoverWithContext from '../../default/memo/MemoPopoverWithContext'
import { MemoPopoverPayload, createDefaultMemoPopoverPayload } from '../../default/memo/MemoPopover'
import { uefaBuildingUpStatusName } from '../../../constants/default/activityStatusName'
import { uefaBuildingUpStateColor } from '../../../constants/default/activityStatusColor'
import { useLocation } from 'react-router-dom'

interface SumRowType1 {
  id: number
  totalCount: string
  appliedCount: string
  receivedCount: string
  expiredCount: string
  cancelledCount: string
}

interface SumRowType2 {
  id: number
  totalBonus: string
}

interface RowType {
  id: number
  order: string
  apply: React.ReactElement
  account: string
  staffAccount: string
  positiveRevenue: string | null
  content: string
  status: React.ReactElement
  bonus: string
  updatedAt: React.ReactElement
  memo: React.ReactElement
}

const MemoContext = createContext<[MemoPopoverPayload, Dispatch<SetStateAction<MemoPopoverPayload>>]>([
  createDefaultMemoPopoverPayload(),
  () => {}
])

const useStyles = makeStyles((theme: CustomThemeType) => ({
  title: {
    textAlign: 'center',
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(2),
    color: theme.custom.palette.text.dialog,
    fontSize: theme.custom.typography.dialog
  },
  memo: {
    color: theme.palette.error.main
  }
}))

const MemoButton: React.FC<{ account: string, detail: Array<{ date: string, cash: string, revenue: string, percentage: string }> }> = (props) => {
  const commonClasses = useCommonStyles()
  const [open, setOpen] = useState(false)
  const { account, detail } = props
  const { t } = useT()
  if (detail.length === 0) return null
  return (
    <>
      <span className={commonClasses.anchor} onClick={() => setOpen(true)}>
        {t('common.updateDays', { count: detail.length })}
      </span>
      <MemoDialog open={open} account={account} detail={detail} close={() => setOpen(false)} />
    </>
  )
}

const MemoDialog: React.FC<{ open: boolean, close: () => void, account: string, detail: Array<{ date: string, cash: string, revenue: string, percentage: string }> }> = (props) => {
  const { open, account, detail, close } = props
  const classes = useStyles()
  const dialogClasses = useDialogStyles()
  const commonClasses = useCommonStyles()
  const { t } = useT()

  const tableClasses = useMemo(() => ({
    head: commonClasses.greyTableHead,
    row: commonClasses.tableRow,
    cellHead: commonClasses.tableCellHead,
    cellBody: commonClasses.nowrap
  }), [commonClasses])

  const rows = useMemo(() => {
    return detail.map((item, index) => {
      return {
        id: index,
        ...item,
        percentage: Number(item.percentage) > 0
          ? (<span className={classes.memo}>{`${item.percentage}%`}</span>)
          : ''
      }
    })
  }, [detail, classes])

  const data = useMemo(() => {
    return createTableData(
      {
        id: {
          label: '',
          value: 'id'
        },
        date: {
          label: t('common.date'),
          value: 'date',
          align: 'center',
          width: 100
        },
        cash: {
          label: t('common.betCash'),
          value: 'cash',
          align: 'center',
          width: 100
        },
        revenue: {
          label: t('common.totalWinAndLose'),
          value: 'revenue',
          align: 'center'
        },
        percentage: {
          label: t('common.accumulatePower'),
          value: 'percentage',
          align: 'center'
        }
      },
      [
        'date',
        'cash',
        'revenue',
        'percentage'
      ],
      rows,
      'id'
    )
  }, [rows, t])

  return (
    <Dialog maxWidth="sm" open={open}>
      <DialogTitle className={classes.title}>{account}</DialogTitle>
      <DialogContent dividers>
        <CoreTable
          data={data}
          total={rows.length}
          classes={tableClasses}
        />
      </DialogContent>
      <DialogActions>
        <Grid container justify="center">
          <Button onClick={close} classes={{ root: dialogClasses.okButton }}>
            {t('common.confirm')}
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  )
}

const AdminReportActivityUefaBuildingUpTable: React.FC = () => {
  const classes = useCommonStyles()
  const { t } = useT()
  const gdk = useGDK()
  const activities = useGDKStore.activity.activities()
  const location = useLocation()
  const [list, setList] = useState<PaginationRes<ActivityUefaBuildingUp[]> & { summary: ActivityUefaBuildingUpSum }>({
    ...createDefaultPaginationData([]),
    summary: {
      total_count: 0,
      applied_count: 0,
      received_count: 0,
      expired_count: 0,
      cancelled_count: 0,
      total_bonus: '0.0000'
    }
  })
  const pageFlow = usePageFlow()
  const request = useRequestFromSearch({ searchToRequest })
  useGetDataByPayload({
    payload: request as ActivityUefaBuildingUpQuery & PaginationReq,
    gdkFunc: (payload) => gdk.activity.getActivityRecordUefaBuildingUp(payload),
    gdkFuncDependencies: [gdk],
    onBeforeFetch: pageFlow.setLoadingStart,
    onSuccess: (res: PaginationRes<ActivityUefaBuildingUp[]> & { summary: ActivityUefaBuildingUpSum }) => {
      setList(res)
      pageFlow.setContentShow()
    },
    onError: pageFlow.setGDKError,
    canLoadData: request !== undefined
  })
  const tableClasses = useMemo(() => ({
    head: classes.greyTableHead,
    row: classes.tableRow,
    cellHead: classes.tableCellHead,
    cellBody: classes.nowrap
  }), [classes])
  const sumRow1 = useMemo(() => {
    return [{
      id: 1,
      totalCount: formatCount(list.summary.total_count),
      appliedCount: formatCount(list.summary.applied_count),
      receivedCount: formatCount(list.summary.received_count),
      expiredCount: formatCount(list.summary.expired_count),
      cancelledCount: formatCount(list.summary.cancelled_count)
    }] as SumRowType1[]
  }, [list])
  const sumData1 = useMemo(() => {
    return createTableData<SumRowType1>(
      {
        id: {
          label: '',
          value: 'id'
        },
        totalCount: {
          label: t('common.activityTotalCount'),
          value: 'totalCount',
          align: 'center'
        },
        appliedCount: {
          label: t('activityStatus.success'),
          value: 'appliedCount',
          align: 'center'
        },
        receivedCount: {
          label: t('activityStatus.sent'),
          value: 'receivedCount',
          align: 'center'
        },
        expiredCount: {
          label: t('activityStatus.expired'),
          value: 'expiredCount',
          align: 'center'
        },
        cancelledCount: {
          label: t('activityStatus.canceled'),
          value: 'cancelledCount',
          align: 'center'
        }
      },
      [
        'totalCount',
        'appliedCount',
        'receivedCount',
        'expiredCount',
        'cancelledCount'
      ],
      sumRow1,
      'id'
    )
  }, [t, sumRow1])
  const sumRow2 = useMemo(() => {
    return [{
      id: 1,
      totalBonus: formatMoney(list.summary.total_bonus)
    }] as SumRowType2[]
  }, [list])
  const sumData2 = useMemo(() => {
    return createTableData<SumRowType2>(
      {
        id: {
          label: '',
          value: 'id'
        },
        totalBonus: {
          label: t('common.totalActivityBonus'),
          value: 'totalBonus',
          align: 'center'
        }
      },
      [
        'totalBonus'
      ],
      sumRow2,
      'id'
    )
  }, [t, sumRow2])
  const rows = useMemo(() => {
    return list.data.map((item, index) => {
      return {
        id: index,
        order: item.serial_number,
        apply: (<DateTime time={item.created_at} />),
        account: item.account,
        staffAccount: item.staff_account,
        positiveRevenue: item.positive_revenue ?? '-',
        content: item.activity_content,
        status: (<p className={classes.chipText} style={{ backgroundColor: uefaBuildingUpStateColor[item.state] }}>{t(uefaBuildingUpStatusName[item.state])}</p>),
        bonus: item.state === ActivityUefaBuildingUpStateType.RECEIVED ? formatMoney(item.bonus) : '-',
        updatedAt: item.updated_at ? (<DateTime time={item.updated_at} />) : (<p></p>),
        memo: (<MemoButton account={item.account} detail={item.detail} />)
      } as RowType
    })
  }, [list.data, t, classes])
  const data = useMemo(() => {
    return createTableData<RowType>(
      {
        id: {
          label: '',
          value: 'id'
        },
        order: {
          label: t('common.activitySerial'),
          value: 'order',
          align: 'center'
        },
        apply: {
          label: t('common.applyTime'),
          value: 'apply',
          align: 'center'
        },
        account: {
          label: t('common.playerAccount'),
          value: 'account',
          align: 'center'
        },
        staffAccount: {
          label: t('common.staffAccount'),
          value: 'staffAccount',
          align: 'center'
        },
        positiveRevenue: {
          label: t('common.positiveRevenue'),
          value: 'positiveRevenue',
          align: 'center'
        },
        content: {
          label: t('common.activityContent'),
          value: 'content',
          align: 'left'
        },
        status: {
          label: t('common.status'),
          value: 'status',
          align: 'center'
        },
        bonus: {
          label: t('common.activityBonus'),
          value: 'bonus',
          align: 'right'
        },
        updatedAt: {
          label: t('common.updatedAt'),
          value: 'updatedAt',
          align: 'center'
        },
        memo: {
          label: t('common.memo'),
          value: 'memo',
          align: 'center'
        }
      },
      [
        'order',
        'apply',
        'account',
        'staffAccount',
        'content',
        'positiveRevenue',
        'status',
        'bonus',
        'updatedAt',
        'memo'
      ],
      rows,
      'id'
    )
  }, [t, rows])
  const handlePagination = usePaginationClickAndChangeUrl({
    request,
    encodePath: (location.pathname === Path.ACTIVITY_RECORD_DETAIL) ? allRoute.activityRecordDetail.encodePath : allRoute.activityRecordSub.encodePath
  })
  return (
    <MemoPopoverWithContext memoPopoverContext={MemoContext}>
      <Box paddingBottom={3}>
        <ScrollablePaper marginX={5}>
          <Box padding={5}>
            <LoadingAndErrorFrame { ...pageFlow.status }>
              <Box
                paddingY={1.25}
                paddingX={2}
                className={classes.pinkTitleBar}
              >
                <Typography variant="h5">
                  {activities.find((item) => item.type === ActivityType.UEFA_BUILDING_UP)?.name ?? ''}
                </Typography>
              </Box>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={12} md={6}>
                  <Box paddingY={2}>
                    <Typography variant="h5">
                      {t('common.countStatisticResult')}
                    </Typography>
                  </Box>
                  <CoreTable
                    data={sumData1}
                    total={1}
                    classes={tableClasses}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box paddingY={2}>
                    <Typography variant="h5">
                      {t('common.bonusStatisticResult')}
                    </Typography>
                  </Box>
                  <CoreTable
                    data={sumData2}
                    total={1}
                    classes={tableClasses}
                  />
                </Grid>
              </Grid>
            </LoadingAndErrorFrame>
          </Box>
        </ScrollablePaper>
      </Box>
      <ScrollablePaper marginX={5}>
        <Box padding={5}>
          <LoadingAndErrorFrame { ...pageFlow.status }>
            <CoreTable
              data={data}
              total={list.total}
              classes={tableClasses}
              showPagination
              page={request?.page ?? 1}
              onChangePage={handlePagination}
            />
          </LoadingAndErrorFrame>
        </Box>
      </ScrollablePaper>
    </MemoPopoverWithContext>
  )
}

export default React.memo(AdminReportActivityUefaBuildingUpTable)

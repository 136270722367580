import React from 'react'
import Box from '@material-ui/core/Box'
import PlayerIpForm from '../../../components/admin/player/PlayerIpForm'
import PlayerIpTable from '../../../components/admin/player/PlayerIpTable'

const PlayerIpPage: React.FC = () => {
  return (
    <Box paddingY={5}>
      <Box paddingX={5}>
        <PlayerIpForm />
      </Box>
      <Box paddingTop={3}>
        <PlayerIpTable />
      </Box>
    </Box>
  )
}

export default React.memo(PlayerIpPage)

import React from 'react'
import { omitBy, isUndefined } from '@golden/utils'
import { Sort, PaginationReq, EmperorInfo } from '@golden/gdk-admin'
import Box from '@material-ui/core/Box'
import AccountEmperorForm from '../../../components/admin/account/AccountEmperorForm'
import AccountEmperorTable from '../../../components/admin/account/AccountEmperorTable'
import { Order } from '../../../components/default/present/CoreTable'
import { SearchToRequestFunc } from '../../../utils/default/ComplexFlowHook'
import {
  pipe,
  parseInt,
  acceptUndefined,
  guaranteeBeKey,
  guaranteeBeOrder,
  guaranteeBetween
} from '../../../utils/default/FormHelper'
import ScrollablePaper from '../../../components/default/present/ScrollablePaper'

export type AccountEmperorRequest = { account?: string } & Sort & PaginationReq

export const initialSort = () => ({ sort_by: 'account', order: 'asc' } as Sort)

export const searchToRequest: SearchToRequestFunc<AccountEmperorRequest> = (search) => {
  const converted = {
    sort_by: acceptUndefined(
      search.sort_by,
      (value) => guaranteeBeKey<EmperorInfo>(value as keyof EmperorInfo, ['account', 'created_at'])
    ) ?? 'account',
    order: (acceptUndefined(search.order, guaranteeBeOrder) ?? 'asc') as Order,
    page: acceptUndefined(
      search.page,
      pipe(
        parseInt,
        (value) => guaranteeBetween(value, 1, Number.MAX_SAFE_INTEGER)
      )
    ) || 1,
    account: search.account
  }
  return omitBy(converted, isUndefined) as AccountEmperorRequest
}

const AccountEmperorPage: React.FC = () => {
  return (
    <Box paddingY={5}>
      <ScrollablePaper marginX={5}>
        <Box padding={4}>
          <Box paddingBottom={3}>
            <AccountEmperorForm />
          </Box>
          <AccountEmperorTable />
        </Box>
      </ScrollablePaper>
    </Box>
  )
}

export default React.memo(AccountEmperorPage)

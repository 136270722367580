import React, { useState, useMemo } from 'react'
import { Link } from 'react-router-dom'
import { PaginationRes, PaginationReq, DepositStatisticDetailQuery, DepositStatisticDetail, DepositStatisticType, ForwarderType } from '@golden/gdk-admin'
import { getTime, startOfYear, subYears } from 'date-fns'
import { omitBy, isUndefined } from '@golden/utils'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import useT from '../../../i18ns/admin/useT'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { createDefaultPaginationData, createTableData, formatMoney, formatDateTime } from '../../../utils/default/TableHelper'
import { usePageFlow } from '../../../utils/default/PageFlowHook'
import { useRequestFromSearch, useGetDataByPayload, usePaginationClickAndChangeUrl, SearchToRequestFunc } from '../../../utils/default/ComplexFlowHook'
import allRoute from '../../../components/admin/route/route'
import LoadingAndErrorFrame from '../../../components/default/frames/LoadingAndErrorFrame'
import ScrollablePaper from '../../../components/default/present/ScrollablePaper'
import CoreTable from '../../../components/default/present/CoreTable'
import { useLocation } from 'react-router'
import { guaranteeNotUndefined, guaranteeBetween, pipe, parseInt, acceptUndefined } from '../../../utils/default/FormHelper'
import { parsePath } from '../../../utils/default/RouteHelper'
import depositStatisticTypeName from '../../../constants/admin/depositStatisticTypeName'
import DateTime from '../../../components/default/present/DateTime'
import depositTypeName from '../../../constants/default/depositTypeName'

type Request = DepositStatisticDetailQuery & { title: string } & PaginationReq

export const searchToRequest: SearchToRequestFunc<Request> = (search) => {
  const fiveYearsAgo = getTime(startOfYear(subYears(new Date(), 5)))
  const converted = {
    page: pipe(
      guaranteeNotUndefined,
      parseInt,
      (value) => guaranteeBetween(value, 1, Number.MAX_SAFE_INTEGER)
    )(search.page),
    start_at: pipe(
      guaranteeNotUndefined,
      parseInt,
      (value) => guaranteeBetween(value, fiveYearsAgo, Number.MAX_SAFE_INTEGER)
    )(search.start_at),
    end_at: pipe(
      guaranteeNotUndefined,
      parseInt,
      (value) => guaranteeBetween(value, fiveYearsAgo, Number.MAX_SAFE_INTEGER)
    )(search.end_at),
    type: acceptUndefined(search.type, parseInt),
    bank_code: acceptUndefined(search.bank_code, parseInt),
    slug: search.slug,
    category: search.category,
    title: search.title,
    forwarder_id: search.forwarder_id
  } as Request
  if (converted.end_at < converted.start_at) throw new Error('The end time can\'t exceed the start time')
  return omitBy(converted, isUndefined) as Request
}

interface RowType {
  id: number
  serial: string
  account: string
  cash: string
  fee: string
  name: string
  way: string
  time: React.ReactElement
  operator: string
}

const AdminReportDepositStatisticDetailPage: React.FC = () => {
  const classes = useCommonStyles()
  const { t } = useT()
  const gdk = useGDK()
  const location = useLocation()
  const [list, setList] = useState<PaginationRes<DepositStatisticDetail[]>>(createDefaultPaginationData([]))
  const pageFlow = usePageFlow()
  const request = useRequestFromSearch({ searchToRequest })
  const search = useMemo(() => {
    return parsePath(location.search, location.pathname).search
  }, [location.pathname, location.search])
  useGetDataByPayload({
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    payload: request!,
    gdkFunc: (payload) => gdk.history.getDepositStatisticDetail(payload),
    gdkFuncDependencies: [gdk],
    onBeforeFetch: pageFlow.setLoadingStart,
    onSuccess: (res: PaginationRes<DepositStatisticDetail[]>) => {
      setList(res)
      pageFlow.setContentShow()
    },
    onError: pageFlow.setGDKError,
    canLoadData: request !== undefined
  })
  const tableClasses = useMemo(() => ({
    head: classes.greyTableHead,
    row: classes.tableRow,
    cellHead: classes.tableCellHead,
    cellBody: classes.pre
  }), [classes])
  const rows: RowType[] = useMemo(() => {
    return list.data.map((item, index) => {
      const type = [ForwarderType.GOLDEN, ForwarderType.GOLDEN_ALIPAY, ForwarderType.GOLDEN_WECHAT].includes(item.slug) ? DepositStatisticType.GOLDEN : DepositStatisticType.THIRD_PARTY
      return {
        id: index,
        serial: item.order_number,
        account: item.account,
        cash: formatMoney(item.cash),
        fee: formatMoney(item.fee),
        type: t(depositStatisticTypeName[type]),
        name: item.deposit_account,
        way: t(depositTypeName[item.method]),
        time: (<DateTime time={item.updated_at} />),
        operator: item.updated_by
      } as RowType
    })
  }, [list.data, t])
  const data = useMemo(() => {
    return createTableData<RowType>(
      {
        id: {
          label: '',
          value: 'id'
        },
        serial: {
          label: t('common.orderNumber'),
          value: 'serial',
          align: 'center'
        },
        account: {
          label: t('common.account'),
          value: 'account',
          align: 'center'
        },
        cash: {
          label: t('common.cash'),
          value: 'cash',
          align: 'center'
        },
        fee: {
          label: t('common.systemFee'),
          value: 'fee',
          align: 'center'
        },
        name: {
          label: `${t('common.courierName')} / ${t('common.depositBank')}`,
          value: 'name',
          align: 'center'
        },
        way: {
          label: t('common.depositWay'),
          value: 'way',
          align: 'center'
        },
        time: {
          label: t('common.updateAt'),
          value: 'time',
          align: 'center'
        },
        operator: {
          label: t('common.updateBy'),
          value: 'operator',
          align: 'center'
        }
      },
      [
        'serial',
        'account',
        'cash',
        'fee',
        'name',
        'way',
        'time',
        'operator'
      ],
      rows,
      'id'
    )
  }, [rows, t])
  const handlePagination = usePaginationClickAndChangeUrl({
    request,
    encodePath: allRoute.adminReportDepositStatisticDetail.encodePath
  })
  const title: string = request?.title ?? ''
  const startAt: Date = request?.start_at ? new Date(request.start_at) : new Date()
  const endAt: Date = request?.end_at ? new Date(request.end_at) : new Date()
  return (
    <Box paddingY={5}>
      <ScrollablePaper marginX={5}>
        <Box padding={4}>
          <LoadingAndErrorFrame { ...pageFlow.status }>
            <Box
              paddingY={1.25}
              paddingX={2}
              marginBottom={3}
              className={classes.pinkTitleBar}
            >
              <Typography variant="h5">
                {`${t('common.detailWithColon')}${title} (${formatDateTime(startAt)} - ${formatDateTime(endAt)})`}
              </Typography>
            </Box>
            <CoreTable
              classes={tableClasses}
              data={data}
              total={list.total}
              showPagination
              page={request?.page ?? 1}
              onChangePage={handlePagination}
            />
          </LoadingAndErrorFrame>
        </Box>
      </ScrollablePaper>
      <Box paddingX={5} paddingTop={2} display="flex" justifyContent="flex-end">
        <Button
          component={Link}
          to={allRoute.adminReportDepositStatistic.encodePath({ param: {}, search: { ...search, page: 1 } })}
          className={classes.greyButton}
        >
          {t('common.back')}
        </Button>
      </Box>
    </Box>
  )
}

export default React.memo(AdminReportDepositStatisticDetailPage)

import { AdminTransferTransactionStatusType } from '@golden/gdk-admin'
import TKeyType from '../../i18ns/admin/TKeyType'

const transferTransactionStatusTypeName: { [key in AdminTransferTransactionStatusType]: TKeyType } = {
  [AdminTransferTransactionStatusType.IN_PROGRESS]: 'transferStatus.process',
  [AdminTransferTransactionStatusType.SUCCESSFUL]: 'transferStatus.success',
  [AdminTransferTransactionStatusType.FAIL]: 'transferStatus.failure',
  [AdminTransferTransactionStatusType.ROLLBACK]: 'transferStatus.rollback',
  [AdminTransferTransactionStatusType.ERROR]: 'transferStatus.error'
}

export default transferTransactionStatusTypeName

import React, { useState } from 'react'
import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import ActivityManagementEditSystemLayoutForm, {
  ActivityManagementEditSystemLayoutFormType,
  initialForm
} from '../../../components/admin/activity/ActivityManagementEditSystemLayoutForm'
import LoadingAndErrorFrame from '../../../components/default/frames/LoadingAndErrorFrame'
import {
  useRedirectHandleBack,
  useGetDataByParams
} from '../../../utils/default/ComplexFlowHook'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { usePageFlow } from '../../../utils/default/PageFlowHook'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import allRoute, { Path } from '../../../components/admin/route/route'
import useT from '../../../i18ns/admin/useT'
import { defaultEditorState, parseContent } from '@golden/tiptap-react'
import { ActivityType } from '@golden/gdk-admin'

const ActivityManagementLayoutSystemLookPage: React.FC = () => {
  const classes = useCommonStyles()
  const { t } = useT()
  const gdk = useGDK()
  const pageFlow = usePageFlow(true, false)
  const [defaultForm, setDefaultForm] = useState<ActivityManagementEditSystemLayoutFormType>(initialForm())

  useGetDataByParams({
    path: Path.ACTIVITY_MANAGEMENT_LAYOUT_SYSTEM_LOOK,
    gdkFunc: (param: { id: string }) => {
      const { id } = param
      // 系統活動 對應 type call api
      switch (id) {
        case ActivityType.NEW_MEMBER_BONUS:
          return gdk.activity.getActivityLayoutNewMemberBonus()
        case ActivityType.RECOMMEND_FRIEND:
          return gdk.activity.getActivityLayoutRecommendFriend()
        case ActivityType.RANK_BONUS:
          return gdk.activity.getActivityLayoutRankBonus()
        case ActivityType.DAILY_SIGN_IN_BONUS:
          return gdk.activity.getActivityLayoutDailySignInBonus()
        case ActivityType.HIGH_CASH_BACK:
          return gdk.activity.getActivityLayoutFeedbackBonus()
        case ActivityType.WEEKLY_BONUS_GOLD:
          return gdk.activity.getActivityLayoutWeeklyBonus()
        case ActivityType.NEW_YEAR_2023:
          return gdk.activity.getActivityLayoutNewYear2023()
        case ActivityType.DRAGON_BOAT_FESTIVAL_2023:
          return gdk.activity.getActivityLayoutDragonBoatFestival2023()
        case ActivityType.NEW_YEAR_2024:
          return gdk.activity.getActivityLayoutNewYear2024()
        case ActivityType.DEPOSIT_BONUS:
          return gdk.activity.getActivityLayoutDepositBonus()
        case ActivityType.UEFA_BUILDING_UP:
          return gdk.activity.getActivityLayoutUefaBuildingUp()
        case ActivityType.UEFA_STARS:
          return gdk.activity.getActivityLayoutUefaStars()
        case ActivityType.WEEKLY_BONUS_DRAW:
          return gdk.activity.getActivityLayoutWeeklyBonusDraw()
        case ActivityType.DAILY_BONUS_DRAW:
          return gdk.activity.getActivityLayoutDailyBonusDraw()
        default:
          throw Error('missed system activity api')
      }
    },
    gdkFuncDependencies: [gdk],
    onBeforeFetch: pageFlow.setLoadingStart,
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    onSuccess: async (entry) => {
      setDefaultForm({
        activity_id: entry.activity_id,
        type: entry.type,
        title: entry.title,
        startAt: entry.start_at,
        endAt: entry.end_at,
        isNoEndAt: entry.end_at === null,
        showStartAt: entry.show_start_at,
        showEndAt: entry.show_end_at,
        isNoShowEndAt: entry.show_end_at === null,
        content: {
          ...defaultEditorState,
          content: await parseContent(entry.content)
        },
        agentLongImage: entry.agent_long_image_url ? { url: entry.agent_long_image_url, id: entry.agent_long_image_id } : null,
        couponImage: null,
        desktopHomeImage: null,
        mobileHomeImage: null,
        isCarousel: entry.is_carousel,
        isGuest: entry.is_guest,
        isMy: entry.is_my,
        tagIds: entry.tags.map(item => item.id),
        tournaments: entry.tournaments
      })
      pageFlow.setContentShow()
    },
    onError: pageFlow.setGDKError
  })

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, handleDebouncedBack] = useRedirectHandleBack({ path: allRoute.activityManagement.encodePath({ search: {}, param: {} }) })

  return (
    <React.Fragment>
      <Box paddingY={5} paddingX={4}>
        <LoadingAndErrorFrame {...pageFlow.status}>
          <Paper>
            <Box padding={4}>
              <Typography className={classes.goldenWord} variant="h5">{t('page.lookActivity')}</Typography>
              <Box paddingY={4}>
                <ActivityManagementEditSystemLayoutForm
                  defaultValue={defaultForm}
                  onSubmit={(form) => form}
                  okText={''}
                  onBack={handleDebouncedBack}
                  isLook
                />
              </Box>
            </Box>
          </Paper>
        </LoadingAndErrorFrame>
      </Box>
    </React.Fragment>
  )
}

export default React.memo(ActivityManagementLayoutSystemLookPage)

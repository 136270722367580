import React, { useContext, useCallback, useState } from 'react'
import { Link } from 'react-router-dom'
import SearchIcon from '@material-ui/icons/Search'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import useT from '../../../i18ns/admin/useT'
import { Path } from '../route/route'
import { RequestContext } from '../../../views/admin/finance/FinanceInternalWithdrawPage'

const InternalWithdrawToolbar: React.FC = () => {
  const [, setRequest] = useContext(RequestContext)
  const [courier, setCourier] = useState<string>('')
  const classes = useCommonStyles()
  const { t } = useT()
  return (
    <Grid container direction="row" alignItems="flex-end" justify="flex-end" spacing={2}>
      <Grid item xs={6} md={3}>
        <Box display="flex" flexDirection="row" alignItems="flex-end">
          <Box display="flex" marginRight={1}>
            <SearchIcon />
          </Box>
          <Box display="flex" flexGrow={1}>
            <TextField
              value={courier}
              onChange={useCallback((event) => {
                setCourier(event.target.value)
              }, [])}
              placeholder={t('placeholder.inputSearchCourier')}
              fullWidth
            />
          </Box>
        </Box>
      </Grid>
      <Grid item>
        <Button
          onClick={useCallback(() => {
            setRequest((request) => ({ ...request, courier }))
          // eslint-disable-next-line react-hooks/exhaustive-deps
          }, [courier])}
          className={classes.pinkGradualButton}
        >
          <Box paddingY={0.5}>
            {t('common.search')}
          </Box>
        </Button>
      </Grid>
      <Grid item>
        <Button
          component={Link}
          to={Path.FINANCE_INTERNAL_WITHDRAW_ACCOUNT}
          className={classes.purpleGradualButton}
        >
          <Box paddingY={0.5}>
            {t('common.internalWithdrawAccountManager')}
          </Box>
        </Button>
      </Grid>
    </Grid>
  )
}

export default React.memo(InternalWithdrawToolbar)

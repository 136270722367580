import React, { useState, useMemo, useCallback, useEffect } from 'react'
import clsx from 'clsx'
import { ActivityApplicationStatusType, ActivityLockApplicationReq, ActivityLockApplicationRes, ActivityApplicationQuery, PaginationReq, getTargetURL } from '@golden/gdk-admin'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Box from '@material-ui/core/Box'
import { useCommonStyles, useDialogStyles } from '../../../../utils/admin/StyleHook'
import useT from '../../../../i18ns/admin/useT'
import useGDK from '../../../../providers/admin/gdk/useGDK'
import OnOffCheckbox from '../../../default/form/OnOffCheckbox'
import { useGDKFuncHandleClick, useRequestFromSearch } from '../../../../utils/default/ComplexFlowHook'
import useGlobalDialog from '../../../../providers/admin/dialog/useGlobalDialog'
import { createGlobalDialogConfig } from '../../../../utils/default/DialogHelper'
import DropDown from '../../../../components/default/form/DropDown'
import RadioGroup from '../../../../components/default/form/RadioGroup'
import MuiTextField from '@material-ui/core/TextField'
import { searchToRequest } from './ActivityApplicationForm'
import { getServerUrl } from '../../../../utils/default/StageHelper'
interface PropTypes {
  id?: number
  ids?: Array<{ id: number, batchId: string }>
  playerAccount?: string
  tab: ActivityApplicationStatusType
  reload: () => void
  isBatch?: boolean
  resetSelected: () => void
  exportPayload: (ActivityApplicationQuery & { ids?: number[] } & PaginationReq) | null
}

const TextField = React.memo(MuiTextField)

const typeMapText = {
  passed: 'common.passed',
  unpassed: 'common.unpassed',
  update: 'common.update',
  locked: 'common.locked'
} as const

const LockOperationButton: React.FC<PropTypes & {
  type: 'locked'
}> = React.memo((props) => {
  const { type, id = 0, ids = [], playerAccount = '', reload, isBatch = false, resetSelected, exportPayload } = props
  const commonClasses = useCommonStyles()
  const dialogClasses = useDialogStyles()
  const { t } = useT()
  const gdk = useGDK()
  const globalDialog = useGlobalDialog()

  const [open, setOpen] = useState(false)
  const [isExportWhileLocking, setIsExportWhileLocking] = useState(true)
  const typeMapButtonClass = {
    passed: commonClasses.greenGradualOutlineButton,
    unpassed: commonClasses.pinkGradualOutlineButton,
    update: commonClasses.greyOutlineButton,
    locked: commonClasses.yellowGradualOutlineButton
  }

  const request = useRequestFromSearch({ searchToRequest })
  const payload = useMemo(() => {
    return ({
      ids: isBatch ? ids.map((el) => el.id) : [id],
      start_at: request?.start_at ?? 0,
      end_at: request?.end_at ?? 0,
      does_export: isBatch ? isExportWhileLocking : false
    })
  }, [id, ids, isBatch, request, isExportWhileLocking])

  const { handleClick, loading } = useGDKFuncHandleClick({
    payload,
    gdkFunc: (payload: ActivityLockApplicationReq) => gdk.activity.lockActivityApplication(payload),
    gdkFuncDependencies: [gdk],
    onSuccessDependencies: [isExportWhileLocking, exportPayload],
    onSuccess: (res: ActivityLockApplicationRes) => {
      setOpen(false)
      reload()
      globalDialog.setConfig(createGlobalDialogConfig({
        message: (
          <>
            <Box marginBottom={4}>
              <Typography className={dialogClasses.title} align="center">{t('dialog.lockResult')}</Typography>
            </Box>
            {res.successful_count > 0 && <Typography className={clsx(dialogClasses.text)} align="center">
              {`${res.successful_count} ${t('common.successPeople')}`}
            </Typography>}
            {res.failed_count > 0 && <Typography className={clsx(dialogClasses.text)} align="center">
              {`${res.failed_count} ${t('common.failedPeople')}`}
            </Typography>}
            {res.failed_accounts.length > 0 && <Typography className={clsx(dialogClasses.text)} align="center">
              {`${t('dialog.lockFailedAccounts')}：${res.failed_accounts.join('、')}`}
            </Typography>}
          </>
        ),
        showIcon: false,
        showCancel: false
      }))
      globalDialog.setOpen({
        id: `updateSuccess-${payload.ids.join('-')}`,
        value: true,
        isOK: false
      })
      if (isBatch && isExportWhileLocking) {
        exportLockedAfterLockSuccess()
      } else {
        resetSelected()
      }
    },
    onError: (error) => {
      globalDialog.setConfig(createGlobalDialogConfig({
        variant: 'error',
        message: error.message,
        showIcon: false,
        showCancel: false
      }))
      globalDialog.setOpen({
        id: `updateFailed-${id}`,
        value: true,
        isOK: false
      })
    }
  })

  const exportLockedAfterLockSuccessPayload = useMemo(() => {
    if (!exportPayload) return null
    return ({
      ...exportPayload,
      status: ActivityApplicationStatusType.LOCKED
    })
  }, [exportPayload])

  const { handleClick: exportLockedAfterLockSuccess } = useGDKFuncHandleClick({
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    payload: exportLockedAfterLockSuccessPayload,
    gdkFunc: (payload) => gdk.activity.exportActivityApplicationList(payload),
    gdkFuncDependencies: [exportPayload],
    onSuccess: (res: { url: string }) => {
      const link = document.createElement('a')
      link.href = getTargetURL(getServerUrl('api'), res.url.replace(/\/api/i, ''))
      link.click()
      link.remove()
      if (!(isBatch && isExportWhileLocking)) {
        resetSelected()
      }
    },
    onError: (err) => {
      globalDialog.setConfig(createGlobalDialogConfig({
        variant: 'error',
        message: err.message,
        showCancel: false
      }))
      globalDialog.setOpen({ id: 'exportActivityApplicationsFailed', value: true, isOK: false })
    }
  })

  const dialogTitle = useMemo(() => isBatch
    ? t('dialog.confirmBatchUpdateActivityApplication', { action: t(typeMapText.locked), amount: ids.length })
    : t('dialog.confirmUpdateActivityApplication', { action: t(typeMapText.locked), playerAccount }), [ids.length, isBatch, playerAccount, t])

  const tip = useMemo(() => {
    const batchIds = [...new Set(ids.map((el) => el.batchId))]
    if (batchIds.length <= 1) return ''
    return `${t('common.activityApplicationBatchIdTip')}${batchIds.join('、')}`
  }, [ids, t])

  return (<>
    <Box margin={1}>
      <Button
        onClick={() => { setOpen(true) }}
        className={typeMapButtonClass.locked}
      >
        {isBatch ? t('common.batchSomething', { item: t(typeMapText[type]) }) : t(typeMapText[type])}
      </Button>
    </Box>
    <Dialog open={open} fullWidth maxWidth="sm">
      <DialogTitle className={commonClasses.dialogPinkHeader}>
        {dialogTitle}
      </DialogTitle>
      <DialogContent>
        <Grid container direction="column" spacing={1}>
          <Grid item>
            { !!tip && (<Typography color="error" align="center">{tip}</Typography>) }
          </Grid>
          { isBatch && (
            <Box>
              <OnOffCheckbox
                label={t('common.syncExportSheets')}
                value={isExportWhileLocking}
                onChange={(event) => { setIsExportWhileLocking(event.target.checked) }}
              />
            </Box>
          )}
        </Grid>
      </DialogContent>
      <Box padding={2}>
        <DialogActions>
          <Grid container spacing={1} justifyContent="center">
            <Grid item>
              <Button
                color="secondary"
                variant="outlined"
                onClick={() => { setOpen(false) }}
              >
                {t('common.cancel')}
              </Button>
            </Grid>
            <Grid item>
              <Button
                color="secondary"
                variant="contained"
                onClick={handleClick}
                disabled={loading}
              >
                {t('common.confirm')}
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Box>
    </Dialog>
  </>)
})

const PassOrUnpassOperationButton: React.FC<PropTypes & {
  type: 'passed' | 'unpassed' | 'update'
}> = React.memo((props) => {
  const { type, id = 0, ids = [], playerAccount = '', reload, isBatch = false, resetSelected } = props
  const commonClasses = useCommonStyles()
  const { t } = useT()
  const gdk = useGDK()
  const globalDialog = useGlobalDialog()

  const [open, setOpen] = useState(false)
  const [dropdownValue, setDropdownValue] = useState<string | -1 | -2>(t('common.notReachStandard'))
  const [inputValue, setInputValue] = useState('')
  const [radioValue, setRadioValue] = useState(ActivityApplicationStatusType.PASSED)

  const showDropDown = useMemo(() => (type === 'update' && radioValue === ActivityApplicationStatusType.REJECTED) || type === 'unpassed', [radioValue, type])
  const showInput = useMemo(() => dropdownValue === -1 || type === 'passed' || (type === 'update' && radioValue === ActivityApplicationStatusType.PASSED), [dropdownValue, radioValue, type])
  const showRadio = useMemo(() => type === 'update', [type])

  const typeMapButtonClass = {
    passed: commonClasses.greenGradualOutlineButton,
    unpassed: commonClasses.pinkGradualOutlineButton,
    update: commonClasses.greyOutlineButton,
    locked: commonClasses.yellowGradualOutlineButton
  }

  const statusOptions = useMemo(() => [
    { value: ActivityApplicationStatusType.PASSED, name: t('common.passed') },
    { value: ActivityApplicationStatusType.REJECTED, name: t('common.unpassed') }
  ], [t])

  const memoOptions = useMemo(() => [
    ...[t('common.notReachStandard'), t('common.illegalBet'), t('common.positiveProfit')].map((value) => ({ value, name: value })),
    { value: -1, name: t('common.selfInput') }
  ], [t])

  const handleClose = useCallback(() => {
    setOpen(false)
    setDropdownValue(t('common.notReachStandard'))
    setInputValue('')
    setRadioValue(ActivityApplicationStatusType.PASSED)
  }, [t])

  const payload = useMemo(() => {
    return ({
      ids: isBatch ? ids.map((el) => el.id) : [id],
      status: type === 'update'
        ? radioValue
        : type === 'passed'
          ? ActivityApplicationStatusType.PASSED
          : ActivityApplicationStatusType.REJECTED,
      memo: dropdownValue === -1 || type === 'passed' || (type === 'update' && radioValue === ActivityApplicationStatusType.PASSED)
        ? inputValue
        : dropdownValue === -2
          ? ''
          : dropdownValue
    })
  }, [dropdownValue, id, ids, inputValue, isBatch, radioValue, type])

  const { handleClick, loading } = useGDKFuncHandleClick({
    payload,
    gdkFunc: (payload) => gdk.activity.updateActivityApplication(payload),
    gdkFuncDependencies: [gdk],
    onSuccess: () => {
      globalDialog.setConfig(createGlobalDialogConfig({
        message: t('dialog.updateSuccess'),
        showIcon: false,
        showCancel: false
      }))
      globalDialog.setOpen({
        id: `updateSuccess-${payload.ids.join('-')}`,
        value: true,
        isOK: false
      })
      reload()
      resetSelected()
      handleClose()
    },
    onError: (error) => {
      globalDialog.setConfig(createGlobalDialogConfig({
        variant: 'error',
        message: error.message,
        showIcon: false,
        showCancel: false
      }))
      globalDialog.setOpen({
        id: `updateFailed-${id}`,
        value: true,
        isOK: false
      })
    }
  })

  const dialogTitle = useMemo(() => isBatch
    ? t('dialog.confirmBatchUpdateActivityApplication', { action: t(typeMapText[type]), amount: ids.length })
    : t('dialog.confirmUpdateActivityApplication', { action: t(typeMapText[type]), playerAccount }), [ids.length, isBatch, playerAccount, t, type])

  const tip = useMemo(() => {
    const batchIds = [...new Set(ids.map((el) => el.batchId))]
    if (batchIds.length <= 1) return ''
    return `${t('common.activityApplicationBatchIdTip')}${batchIds.join('、')}`
  }, [ids, t])

  return (<>
    <Box margin={1}>
      <Button
        onClick={() => setOpen(true)}
        className={typeMapButtonClass[type]}
      >
        {isBatch ? t('common.batchSomething', { item: t(typeMapText[type]) }) : t(typeMapText[type])}
      </Button>
    </Box>
    <Dialog open={open} fullWidth maxWidth="sm">
      <DialogTitle className={commonClasses.dialogPinkHeader}>
        {dialogTitle}
      </DialogTitle>
      <DialogContent>
        <Grid container direction="column" spacing={1}>
          <Grid item>
            { !!tip && (<Typography color="error" align="center">{tip}</Typography>) }
          </Grid>
          { showRadio && <Grid item>
            <RadioGroup
              label={t('userStatus.reviewing')}
              value={radioValue}
              options={statusOptions}
              onChange={(event) => {
                const value = event.target.value as ActivityApplicationStatusType
                setRadioValue(value)
                setInputValue('')
              }}
            />
          </Grid> }
          { showDropDown && <Grid item>
            <DropDown
              autoFocus
              fullWidth
              label={t('common.memo')}
              value={dropdownValue}
              options={memoOptions}
              onChange={(event) => {
                const value = event.target.value as string | -1
                setDropdownValue(value)
              }}
            />
          </Grid> }
          { showInput && <Grid item>
            <TextField
              autoFocus
              fullWidth
              type="text"
              margin="normal"
              variant="outlined"
              label={!showDropDown ? t('common.memo') : ''}
              placeholder={t('placeholder.pleaseInput', { item: t('common.memo') })}
              FormHelperTextProps={{ error: true }}
              defaultValue={inputValue}
              onChange={(event) => {
                const value = event.target.value
                setInputValue(value)
              }}
            />
          </Grid> }
        </Grid>
      </DialogContent>
      <Box padding={2}>
        <DialogActions>
          <Grid container spacing={1} justifyContent="center">
            <Grid item>
              <Button
                color="secondary"
                variant="outlined"
                onClick={handleClose}
              >
                {t('common.cancel')}
              </Button>
            </Grid>
            <Grid item>
              <Button
                color="secondary"
                variant="contained"
                onClick={handleClick}
                disabled={loading}
              >
                {t('common.confirm')}
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Box>
    </Dialog>
  </>)
})

const ActivityApplicationOperation: React.FC<PropTypes> = (props) => {
  const { tab } = props

  if (tab === ActivityApplicationStatusType.CREATED) {
    return (
      <LockOperationButton
        type="locked"
        {...props}
      />
    )
  }
  if (tab === ActivityApplicationStatusType.LOCKED) {
    return (<>
      <PassOrUnpassOperationButton
        type="passed"
        {...props}
      />
      <PassOrUnpassOperationButton
        type="unpassed"
        {...props}
      />
    </>)
  }
  return (
    <PassOrUnpassOperationButton
      type="update"
      {...props}
    />
  )
}

export default React.memo(ActivityApplicationOperation)

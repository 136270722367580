import React, { useState, useCallback } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useDebouncedCallback } from 'use-debounce'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import DragHandleIcon from '@material-ui/icons/DragHandle'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import useT from '../../../i18ns/admin/useT'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { GDKError } from '@golden/gdk-admin'
import CustomThemeType from '../../../themes/admin/CustomThemeType'
import useGlobalDialog from '../../../providers/admin/dialog/useGlobalDialog'
import { createGlobalDialogConfig } from '../../../utils/default/DialogHelper'
import { useGetData } from '../../../utils/default/ComplexFlowHook'
import { usePageFlow } from '../../../utils/default/PageFlowHook'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import type { DropResult } from 'react-beautiful-dnd'
import { cloneDeep } from '@golden/utils'

const useStyles = makeStyles((theme: CustomThemeType) => ({
  actions: {
    paddingBottom: theme.spacing(4)
  },
  item: {
    border: '1px solid #e9e9e9',
    '&:not(:first-child)': {
      marginTop: '-1px'
    }
  }
}))

const ActivityItem: React.FC<{ id: number, index: number, title: string }> = (props) => {
  const { id, index, title } = props
  const classes = useStyles()

  return (
    <Draggable draggableId={`activity-${id}`} index={index}>
      {
        (provided) => {
          const { draggableProps, dragHandleProps, innerRef } = provided
          return (
            <ListItem
              divider
              ref={innerRef}
              className={classes.item}
              {...draggableProps}
            >
              <ListItemIcon {...dragHandleProps}>
                <DragHandleIcon />
              </ListItemIcon>
              <ListItemText
                primary={title}
              />
            </ListItem>
          )
        }
      }
    </Draggable>
  )
}

const ActivityManagementSortDialog: React.FC<{ open: boolean, onClose: () => void, reload: () => void }> = (props) => {
  const { open, onClose, reload } = props
  const { t } = useT()
  const globalDialog = useGlobalDialog()
  const gdk = useGDK()
  const classes = useStyles()
  const commonClasses = useCommonStyles()
  const [priority, setPriority] = useState<Array<{ id: number, title: string, priority: number }>>([])
  const pageFlow = usePageFlow()

  useGetData({
    gdkFunc: () => gdk.activity.getActivityPriority(),
    gdkFuncDependencies: [gdk, open],
    onBeforeFetch: pageFlow.setLoadingStart,
    onSuccess: (res) => {
      setPriority(res)
      pageFlow.setContentShow()
    },
    onError: pageFlow.setGDKError,
    canLoadData: open
  })

  const [handleSubmitDebounced] = useDebouncedCallback(useCallback(() => {
    onClose()
    gdk.activity.updateActivityPriority(priority.map(({ id }, index) => ({ id, priority: index }))).subscribe({
      next: () => {
        setPriority([])
        reload()
      },
      error: (error: GDKError) => {
        setPriority([])
        reload()
        globalDialog.setConfig(createGlobalDialogConfig({
          variant: 'error',
          showIcon: true,
          showCancel: false,
          message: error.message
        }))
        globalDialog.setOpen({
          id: 'sortPriorityError',
          value: false,
          isOK: false
        })
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gdk, onClose, priority]), 200)
  const handleClose = useCallback(() => {
    onClose()
    setPriority([])
  }, [onClose])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSubmit = useCallback(handleSubmitDebounced, [])
  const handleSorting = (result: DropResult) => {
    const { source, destination } = result
    if (!destination) return

    const { droppableId: sourceId, index: sourceIndex } = source
    const { droppableId: targetId, index: targetIndex } = destination
    if (sourceId === targetId && sourceIndex === targetIndex) return

    const newPriority = cloneDeep(priority)

    const [target] = newPriority.splice(sourceIndex, 1)
    newPriority.splice(targetIndex, 0, target)

    setPriority(newPriority)
  }
  return (
    <Dialog open={open} fullWidth maxWidth="sm">
      <Box padding={2} className={commonClasses.dialogPinkHeader}>
        {t('common.activitySort')}
      </Box>
      <DialogContent>
        <Box padding={3}>
          <DragDropContext onDragEnd={handleSorting}>
            <Droppable droppableId='droppable-1'>
              {
                (provided) => {
                  const { droppableProps, innerRef, placeholder } = provided
                  return (
                    <List
                      disablePadding
                      ref={innerRef}
                      {...droppableProps}
                    >
                      {
                        priority.map((item, index) => (<ActivityItem key={item.id} {...item} index={index} />))
                      }
                      {placeholder}
                    </List>
                  )
                }
              }
            </Droppable>
          </DragDropContext>
        </Box>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Grid container direction="row" spacing={2} justify="center">
          <Grid item>
            <Button
              className={commonClasses.greyButton}
              onClick={handleClose}
            >
              {t('common.cancel')}
            </Button>
          </Grid>
          <Grid item>
            <Button
              className={commonClasses.purpleGradualButton}
              onClick={handleSubmit}
            >
              {t('common.saveSetting')}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  )
}

export default React.memo(ActivityManagementSortDialog)

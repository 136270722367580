import React, { useCallback, useMemo, useEffect } from 'react'
import { PiggyWechatForm, Branch, ClientType } from '@golden/gdk-admin'
import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import useT from '../../../i18ns/admin/useT'
import useGDK from '../../../providers/admin/gdk/useGDK'
import useGlobalDialog from '../../../providers/admin/dialog/useGlobalDialog'
import { useRedirectHandleBack, useDialogHandleSubmit } from '../../../utils/default/ComplexFlowHook'
import { Path } from '../../../components/admin/route/route'
import { createGlobalDialogConfig } from '../../../utils/default/DialogHelper'
import LoadingAndErrorFrame from '../../../components/default/frames/LoadingAndErrorFrame'
import { usePageFlow } from '../../../utils/default/PageFlowHook'
import EditFinancePiggyWechatForm, { EditFinancePiggyWechatFormType, initialForm } from '../../../components/admin/finance/EditFinancePiggyWechatForm'

const formToRequest = (form: EditFinancePiggyWechatFormType): PiggyWechatForm => ({
  name: form.name,
  account: form.account,
  cash_max: Number(form.max),
  cash_min: Number(form.min),
  real_name: form.receiver || null,
  memo: form.memo,
  is_active: form.isActive,
  clients: form.clients,
  layer_ids: form.layer.layers,
  code: form.code,
  qr_content: form.qr || null,
  is_for_proxy_deposit: form.layer.isForProxyDeposit as boolean
})

const FinancePiggyWechatCreatePage: React.FC = () => {
  const classes = useCommonStyles()
  const { t } = useT()
  const gdk = useGDK()
  const pageFlow = usePageFlow()
  const globalDialog = useGlobalDialog()
  const [handleBack, handleDebouncedBack] = useRedirectHandleBack({ path: Path.FINANCE_PIGGY_WECHAT })
  const { handleSubmit } = useDialogHandleSubmit({
    formToRequest,
    gdkFunc: (payload) => gdk.finance.createPiggyWechat(payload),
    gdkFuncDependencies: [gdk],
    dialogId: 'createPiggyWechat',
    globalDialog,
    getChangeDialogConfig: useCallback((form: EditFinancePiggyWechatFormType) => createGlobalDialogConfig({
      showIcon: false,
      message: (
        <React.Fragment>
          {t('dialog.confirmCreatePiggyWechat')}
          <span className={classes.purpleWord}>{form.account} {form.receiver}</span>
        </React.Fragment>
      )
    }), [t, classes.purpleWord]),
    getSuccessDialogConfig: useCallback(() => createGlobalDialogConfig({
      showIcon: false,
      showCancel: false,
      message: t('dialog.createPiggyWechatSuccess')
    }), [t]),
    getFailDialogConfig: useCallback((_, error) => createGlobalDialogConfig({
      showIcon: true,
      variant: 'error',
      showCancel: false,
      message: error.message
    }), []),
    afterSuccessDialog: handleBack
  })
  const defaultForm = useMemo(() => {
    return initialForm({
      name: t('common.personWechat'),
      memo: t('common.dontScanTwice'),
      clients: [ClientType.DESKTOP, ClientType.MOBILE]
    })
  }, [t])
  useEffect(() => {
    gdk.trunk.trigger([Branch.PLAYER_LAYER])
  }, [gdk])
  return (
    <Box padding={5}>
      <Paper>
        <Box padding={4}>
          <Box
            paddingY={1.25}
            paddingX={2}
            className={classes.pinkTitleBar}
          >
            <Typography variant="h5">
              {t('page.createPiggyWechat')}
            </Typography>
          </Box>
          <Box paddingY={2}>
            <LoadingAndErrorFrame { ...pageFlow.status }>
              <EditFinancePiggyWechatForm
                okText={t('common.confirmCreate')}
                onBack={handleDebouncedBack}
                onSubmit={handleSubmit}
                defaultValue={defaultForm}
              />
            </LoadingAndErrorFrame>
          </Box>
        </Box>
      </Paper>
    </Box>
  )
}

export default React.memo(FinancePiggyWechatCreatePage)

import { AdminWithdrawStatusType } from '@golden/gdk-admin'

const adminWithdrawStatusColor: { [key in AdminWithdrawStatusType]: string } = {
  [AdminWithdrawStatusType.IN_PROGRESS]: '#808080',
  [AdminWithdrawStatusType.REVIEWING]: '#808080',
  [AdminWithdrawStatusType.SUCCESSFUL]: '#72d476',
  [AdminWithdrawStatusType.THIRD_PARTY_WITHDRAWING]: '#808080',
  [AdminWithdrawStatusType.CANCEL]: '#ff5f5f',
  [AdminWithdrawStatusType.THIRD_PARTY_WITHDRAW_FAIL]: '#808080'
}

export default adminWithdrawStatusColor

import React from 'react'
import { Link } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import useGlobalDialog from '../../../providers/admin/dialog/useGlobalDialog'
import useT from '../../../i18ns/admin/useT'
import allRoute from '../route/route'
import { useDialogHandleClick } from '../../../utils/default/ComplexFlowHook'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { createGlobalDialogConfig } from '../../../utils/default/DialogHelper'

interface PropTypes {
  id: number
  account: string
  reload: () => void
}

const WithdrawalLimitButtons: React.FC<PropTypes> = (props) => {
  const { id, account, reload } = props
  const { t } = useT()
  const gdk = useGDK()
  const classes = useCommonStyles()
  const globalDialog = useGlobalDialog()
  const handleDelete = useDialogHandleClick({
    globalDialog,
    dialogId: `deleteWithdrawLimit-${id}`,
    payload: id,
    gdkFunc: (payload) => gdk.withdraw.deleteWithdrawLimit(payload),
    gdkFuncDependencies: [gdk],
    onSuccess: reload,
    changeDialogConfig: createGlobalDialogConfig({
      showIcon: false,
      message: (
        <React.Fragment>
          <Typography align="center">{t('dialog.confirmDeleteWithdrawLimit')}</Typography>
          <Typography align="center" className={classes.purpleWord}>[{account}]</Typography>
        </React.Fragment>
      )
    }),
    successDialogConfig: createGlobalDialogConfig({
      showIcon: false,
      showCancel: false,
      message: t('dialog.deleteWithdrawLimitSuccess')
    }),
    getFailDialogConfig: (error) => createGlobalDialogConfig({
      showIcon: true,
      variant: 'error',
      showCancel: false,
      message: error
    })
  })
  return (
    <Grid container direction="column" spacing={1}>
      <Grid item>
        <Button
          className={classes.blueGradualOutlineButton}
          component={Link}
          to={allRoute.withdrawalLimitUpdate.encodePath({ search: {}, param: { id } })}
        >
          {t('common.update')}
        </Button>
      </Grid>
      <Grid item>
        <Button
          className={classes.pinkGradualOutlineButton}
          onClick={handleDelete}
        >
          {t('common.delete')}
        </Button>
      </Grid>
    </Grid>
  )
}

export default WithdrawalLimitButtons

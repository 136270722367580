import React, { useCallback } from 'react'
import clsx from 'clsx'
import { startOfDay, endOfDay } from 'date-fns'
import { MuiThemeProvider, createTheme, useTheme, makeStyles } from '@material-ui/core/styles'
import Grid, { GridSize } from '@material-ui/core/Grid'
import Button from '../Button'
import { useInternValue, useCheckMDWidth } from '../../../utils/default/FormHook'
import { KeyboardDateTimePicker, KeyboardDatePicker } from '@golden/pickers'

export interface DateInputValue {
  start: Date | null
  end: Date | null
}

export interface PropTypes {
  value?: DateInputValue
  defaultValue?: DateInputValue
  error?: boolean
  startError?: boolean
  endError?: boolean
  helperText?: string | null
  startHelperText?: string | null
  endHelperText?: string | null
  onChange?: (value: DateInputValue) => void
  start?: {
    label: string
    disabled?: boolean
    shouldDisableDate?: (day: Date | null) => boolean
    grid?: {
      xs?: GridSize
      md?: GridSize
      lg?: GridSize
    }
  }
  end?: {
    label: string
    disabled?: boolean
    shouldDisableDate?: (day: Date | null) => boolean
    grid?: {
      xs?: GridSize
      md?: GridSize
      lg?: GridSize
    }
  }
  tools?: Array<{
    label: string
    disabled?: boolean
    change: (value: DateInputValue) => DateInputValue
  }>
  toolsGrid?: {
    xs?: GridSize
    md?: GridSize
    lg?: GridSize
  }
  classes?: Partial<Record<'button', string>>
  FrontComponent?: any
  BackComponent?: any
  denseToolbar?: boolean
  denseDatePicker?: boolean
  required?: boolean
  sm?: boolean
  onlyDate?: boolean
}

const useStyles = makeStyles(() => ({
  button: {
    whiteSpace: 'nowrap'
  },
  sm: {
    width: '50%'
  }
}))

const DateInput: React.FC<PropTypes> = (props) => {
  const { value, defaultValue, error, helperText, startError, startHelperText, endError, endHelperText, onChange, start, end, tools, toolsGrid, FrontComponent, BackComponent, denseToolbar, denseDatePicker, classes: propsClasses, required, sm, onlyDate } = props
  const [internValue, setInternValue] = useInternValue(
    defaultValue ?? value ?? { start: null, end: null },
    value
  )
  const classes = useStyles()
  const theme = useTheme()
  const matches = useCheckMDWidth()
  const pickerTheme = createTheme({
    palette: {
      primary: {
        main: theme.palette.primary.main,
        light: theme.palette.primary.light,
        dark: theme.palette.primary.main,
        contrastText: theme.palette.primary.contrastText
      }
    }
  })
  const handleStartTimeChange = useCallback((start) => {
    setInternValue((value) => {
      let newValue = { ...value, start }
      if (onlyDate) {
        newValue = { ...value, start: start ? startOfDay(start) : null }
      }
      if (onChange !== undefined) onChange(newValue)
      return newValue
    })
  }, [onChange, setInternValue, onlyDate])
  const handleEndTimeChange = useCallback((end) => {
    setInternValue((value) => {
      let newValue = { ...value, end }
      if (onlyDate) {
        newValue = { ...value, end: end ? endOfDay(end) : null }
      }
      if (onChange !== undefined) onChange(newValue)
      return newValue
    })
  }, [onChange, setInternValue, onlyDate])
  return (
    <MuiThemeProvider theme={pickerTheme}>
      <Grid
        container
        wrap="wrap"
        direction="row"
        alignItems="center"
        spacing={2}
      >
        {FrontComponent && (<FrontComponent />)}
        {
          start && (
            <Grid item xs={sm ? 12 : 12} md={sm ? 12 : 6} lg={sm ? 6 : (denseDatePicker ? 2 : 3)} {...start?.grid}>
              {onlyDate
                ? (
                <KeyboardDatePicker
                  fullWidth
                  autoOk
                  variant="inline"
                  format="yyyy-MM-dd"
                  mask="____-__-__"
                  margin="dense"
                  label={start.label}
                  value={internValue.start}
                  onChange={handleStartTimeChange}
                  error={(error ?? startError)}
                  helperText={(error ? helperText : startHelperText)}
                  disabled={start.disabled}
                  shouldDisableDate={start.shouldDisableDate}
                  required={required}
                  InputLabelProps={{
                    style: {
                      fontSize: 14
                    }
                  }}
                />
                  )
                : (
                <KeyboardDateTimePicker
                  fullWidth
                  autoOk
                  ampm={false}
                  variant="inline"
                  format="yyyy-MM-dd HH:mm:ss"
                  mask="____-__-__ __:__:__"
                  margin="dense"
                  label={start.label}
                  value={internValue.start}
                  onChange={handleStartTimeChange}
                  error={(error ?? startError)}
                  helperText={(error ? helperText : startHelperText)}
                  disabled={start.disabled}
                  shouldDisableDate={start.shouldDisableDate}
                  required={required}
                  InputLabelProps={{
                    style: {
                      fontSize: 14
                    }
                  }}
                />
                  )}
        </Grid>
          )
        }
        {
          end && (
            <Grid item xs={sm ? 12 : 12} md={sm ? 12 : 6} lg={sm ? 6 : (denseDatePicker ? 2 : 3)} {...end?.grid}>
              {onlyDate
                ? (
                  <KeyboardDatePicker
                    fullWidth
                    autoOk
                    variant="inline"
                    format="yyyy-MM-dd"
                    mask="____-__-__"
                    margin="dense"
                    label={end?.label}
                    value={internValue.end}
                    onChange={handleEndTimeChange}
                    error={(error ?? endError)}
                    helperText={(error ? helperText : endHelperText)}
                    disabled={end?.disabled}
                    shouldDisableDate={end?.shouldDisableDate}
                    required={required}
                    InputLabelProps={{
                      style: {
                        fontSize: 14
                      }
                    }}
                  />
                  )
                : (
                  <KeyboardDateTimePicker
                    fullWidth
                    autoOk
                    ampm={false}
                    variant="inline"
                    format="yyyy-MM-dd HH:mm:ss"
                    mask="____-__-__ __:__:__"
                    margin="dense"
                    label={end?.label}
                    value={internValue.end}
                    onChange={handleEndTimeChange}
                    error={(error ?? endError)}
                    helperText={(error ? helperText : endHelperText)}
                    disabled={end?.disabled}
                    shouldDisableDate={end?.shouldDisableDate}
                    required={required}
                    InputLabelProps={{
                      style: {
                        fontSize: 14
                      }
                    }}
                  />
                  )}
            </Grid>
          )
        }
        {BackComponent && (<BackComponent />)}
        {tools !== undefined && (
          <Grid item xs={12} md={12} lg={denseToolbar ? 3 : 6} {...toolsGrid}>
            <Grid container direction={matches ? 'row' : 'column'} spacing={2}>
              {tools.map((tool) => (
                <Grid item xs={12} md={6} lg={denseToolbar ? 4 : 2} key={tool.label}>
                  <Button
                    fullWidth
                    onClick={() => {
                      const newValue = tool.change(internValue)
                      if (onChange !== undefined) onChange(newValue)
                      setInternValue(newValue)
                    }}
                    disabled={tool.disabled}
                    variant="contained"
                    color="primary"
                    className={clsx(classes.button, propsClasses?.button)}
                  >
                    {tool.label}
                  </Button>
                </Grid>
              ))}
            </Grid>
          </Grid>
        )}
      </Grid>
    </MuiThemeProvider>
  )
}

export default React.memo(DateInput)

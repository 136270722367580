import React, { useMemo, createContext, useContext, useCallback } from 'react'
import {
  PaginationReq,
  TransactionType,
  AdminTransferTransactionStatusType,
  TransferHistoryQuery,
  TransferSearchTimeType,
  WalletType
} from '@golden/gdk-admin'
import { omitBy, isUndefined, enumValues } from '@golden/utils'
import { startOfDay, endOfDay, subYears, startOfYear, getTime } from 'date-fns'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import MuiButton from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import MuiTextField, { TextFieldProps } from '@material-ui/core/TextField'
import DateInputBase, { DateInputValue } from '../../../default/form/DateInput'
import DropDown, { PropTypes as DropDownProps } from '../../../default/form/DropDown'
import FormField from '../../../default/form/FormField'
import FormSubmitButton from '../../../default/form/FormSubmitButton'
import { useCommonStyles } from '../../../../utils/admin/StyleHook'
import useT from '../../../../i18ns/admin/useT'
import {
  getValueFromChangeEvent,
  getTimeFromDateInputValue,
  convertEmptyToUndefined,
  pipe,
  parseInt,
  guaranteeBetween,
  guaranteeNotUndefined,
  acceptUndefined,
  guaranteeBeOneOf,
  convertUndefinedToDefault
} from '../../../../utils/default/FormHelper'
import { InitialFormFunc, useChangeUrlSubmit, useRequestFromSearch, SearchToRequestFunc } from '../../../../utils/default/ComplexFlowHook'
import FormStateProvider from '../../../default/form/FormStateProvider'
import { ValueGetter, FormValidation, createDefaultFormState } from '../../../../utils/default/FormHook'
import {
  createShouldDisableDate,
  setToday,
  setYesterday,
  setThisMonth,
  setLastMonth
} from '../../../../utils/default/TimeHelper'
import allRoute from '../../route/route'
import transactionTypeName from '../../../../constants/admin/transactionTypeName'
import transferTransactionStatusTypeName from '../../../../constants/admin/transferTransactionStatusTypeName'
import useGDKStore from '../../../../providers/admin/gdk/useGDKStore'
import transferSearchTimeName from '../../../../constants/admin/transferSearchTimeName'
import PlayerReportTransferCheckButton from './PlayerReportTransferCheckButton'

export type PlayerReportTransferRequest = PaginationReq & TransferHistoryQuery

export interface PlayerReportTransferFormType {
  search_type: TransferSearchTimeType
  time: DateInputValue
  account: string
  wallet: WalletType
  platform: WalletType | '--'
  transfer_number: string
  transaction_type: TransactionType | '--'
  transaction_status: AdminTransferTransactionStatusType | '--'
}

export const initialForm: InitialFormFunc<PlayerReportTransferFormType> = (defaultForm) => ({
  search_type: TransferSearchTimeType.CREATED_AT,
  time: {
    start: startOfDay(new Date()),
    end: endOfDay(new Date())
  },
  account: '',
  wallet: WalletType.CENTER,
  platform: '--',
  transfer_number: '',
  transaction_type: '--',
  transaction_status: '--',
  ...defaultForm
})

const getValueFromEvent: ValueGetter<PlayerReportTransferFormType> = {
  search_type: getValueFromChangeEvent,
  time: getTimeFromDateInputValue,
  account: getValueFromChangeEvent,
  wallet: getValueFromChangeEvent,
  platform: getValueFromChangeEvent,
  transfer_number: getValueFromChangeEvent,
  transaction_type: getValueFromChangeEvent,
  transaction_status: getValueFromChangeEvent
}

const formToRequest = (form: PlayerReportTransferFormType): PlayerReportTransferRequest => {
  const converted = {
    search_type: form.search_type,
    start: form.time.start ? getTime(form.time.start) : undefined,
    end: form.time.end ? getTime(form.time.end) : undefined,
    account: convertEmptyToUndefined(form.account),
    golden_wallet: form.wallet,
    game_wallet: form.platform === '--' ? undefined : form.platform,
    transfer_number: convertEmptyToUndefined(form.transfer_number),
    transaction_status: form.transaction_status === '--' ? undefined : form.transaction_status,
    transaction_type: form.transaction_type === '--' ? undefined : form.transaction_type,
    page: 1
  } as PlayerReportTransferRequest
  const omited = omitBy(converted, isUndefined)
  return omited as PlayerReportTransferRequest
}

export const searchToRequest: SearchToRequestFunc<PlayerReportTransferRequest> = (search) => {
  const fiveYearsAgo = getTime(startOfYear(subYears(new Date(), 5)))
  const endOfToday = getTime(endOfDay(new Date()))
  const converted = {
    ...search,
    page: pipe(
      guaranteeNotUndefined,
      parseInt,
      (value) => guaranteeBetween(value, 1, Number.MAX_SAFE_INTEGER)
    )(search.page),
    search_type: pipe(
      (value) => convertUndefinedToDefault(value, TransferSearchTimeType.CREATED_AT),
      (value) => guaranteeBeOneOf(value, Object.keys(transferSearchTimeName))
    )(search.search_type),
    start: acceptUndefined(search.start, pipe(
      parseInt
    )),
    end: acceptUndefined(search.end, pipe(
      parseInt
    )),
    golden_wallet: pipe(
      guaranteeNotUndefined,
      (value) => guaranteeBeOneOf(Number(value), enumValues(WalletType)),
      parseInt
    )(search.golden_wallet),
    game_wallet: acceptUndefined(search.game_wallet, pipe(
      (value) => guaranteeBeOneOf(Number(value), enumValues(WalletType)),
      parseInt
    )),
    transaction_type: acceptUndefined(search.transaction_type, pipe(
      (value) => guaranteeBeOneOf(value, Object.keys(transactionTypeName))
    )),
    transaction_status: acceptUndefined(search.transaction_status, pipe(
      (value) => guaranteeBeOneOf(value, Object.keys(transferTransactionStatusTypeName)),
      parseInt
    ))
  } as PlayerReportTransferRequest
  if (converted.end < converted.start) throw new Error('The end time can\'t exceed the start time')
  return omitBy(converted, isUndefined) as PlayerReportTransferRequest
}

const FormContext = createContext(createDefaultFormState(initialForm()))

const TextField = React.memo(MuiTextField)

const Button = React.memo(MuiButton)

const SearchDropDown: React.FC = React.memo(() => {
  const { t } = useT()
  const { value, handleChange, handleOther, error } = useContext(FormContext)
  const searchTimeOptions = useMemo(() => {
    return Object.keys(transferSearchTimeName)
      .map((key) => ({ name: t(transferSearchTimeName[key as TransferSearchTimeType]), value: key }))
  }, [t])
  return (
    <Grid item xs={12} md={6} lg={3}>
      <DropDown
        value={value.search_type}
        onChange={handleChange('search_type')}
        onBlur={handleOther('blur', 'search_type')}
        options={searchTimeOptions}
        label={t('common.searchDate')}
        fullWidth
        required
        error={error.search_type !== null}
        helperText={error.search_type ?? ''}
      />
    </Grid>
  )
})

const DateInput: React.FC = React.memo(() => {
  const classes = useCommonStyles()
  const { t } = useT()
  const { value, handleChange } = useContext(FormContext)
  const {
    shouldDisableStartDate,
    shouldDisableEndDate
  } = useMemo(() => {
    const fiveYearsAgo = startOfYear(subYears(new Date(), 5))
    return createShouldDisableDate(value.time.start, value.time.end, fiveYearsAgo)
  }, [value.time])
  const tools = useMemo(() => {
    return [
      {
        label: t('common.today'),
        change: setToday
      },
      {
        label: t('common.yesterday'),
        change: setYesterday
      },
      {
        label: t('common.thisMonth'),
        change: setThisMonth
      },
      {
        label: t('common.lastMonth'),
        change: setLastMonth
      }
    ]
  }, [t])
  const startOption = useMemo(() => ({
    label: t('common.beginAt'),
    shouldDisableDate: shouldDisableStartDate
  }), [shouldDisableStartDate, t])

  const endOption = useMemo(() => ({
    label: t('common.endAt'),
    shouldDisableDate: shouldDisableEndDate
  }), [shouldDisableEndDate, t])

  const dateClasses = useMemo(() => ({
    button: classes.pinkGradualButton
  }), [classes.pinkGradualButton])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onChange = useCallback(handleChange('time'), [])
  return (
    <DateInputBase
      value={value.time}
      onChange={onChange}
      start={startOption}
      end={endOption}
      tools={tools}
      classes={dateClasses}
      FrontComponent={SearchDropDown}
    />
  )
})

const PlayerReportTransferForm: React.FC = () => {
  const classes = useCommonStyles()
  const { t } = useT()
  const platforms = useGDKStore.platform.alivePlatforms()

  const request = useRequestFromSearch({ searchToRequest })

  const defaultForm = useMemo(() => {
    if (request) {
      return initialForm({
        time: {
          start: request.start ? new Date(request.start) : null,
          end: request.end ? new Date(request.end) : null
        },
        wallet: request.golden_wallet,
        platform: request.game_wallet,
        ...request
      })
    }
    return initialForm()
  }, [request])

  const handleSubmit = useChangeUrlSubmit({
    toAddNowTimestamp: true,
    formToRequest,
    encodePath: allRoute.playerReportTransfer.encodePath
  })

  const validation = useMemo(() => ({
    search_type: [],
    time: [],
    account: [],
    wallet: [],
    platform: [],
    transfer_number: [],
    transaction_status: [],
    transaction_type: []
  } as FormValidation<PlayerReportTransferFormType>), [])

  const walletOptions = [
    { name: t('common.mainAccount'), value: WalletType.CENTER },
    { name: t('common.activityWallet'), value: WalletType.ACTIVITY }
  ]

  const platformOptions = [{ name: t('common.all'), value: '--' }].concat(
    platforms
      .filter((platform) => platform.wallet_id !== WalletType.CENTER)
      .map((item) => ({
        name: item.nameWithStatus,
        value: item.id
      })) as any
  )

  const transactionTypeOptions = [{ name: t('common.all'), value: '--' }].concat(
    Object.keys(transactionTypeName)
      .map((key) => ({
        name: t(transactionTypeName[key as TransactionType]),
        value: key
      })) as any
  )

  const transactionStatusOptions = [{ name: t('common.all'), value: '--' }].concat(
    Object.keys(transferTransactionStatusTypeName)
      .map((key) => Number(key) as AdminTransferTransactionStatusType)
      .map((key) => ({
        name: t(transferTransactionStatusTypeName[key]),
        value: key
      })) as any
  )

  return (
    <FormStateProvider
      context={FormContext}
      defaultValue={defaultForm}
      onSubmit={handleSubmit}
      validation={validation}
      getValueFromEvent={getValueFromEvent}
    >
      <Box display="flex" paddingBottom={2} justifyContent="flex-end">
        <PlayerReportTransferCheckButton />
      </Box>
      <Paper>
        <Box padding={4}>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <Box
                paddingY={1.25}
                paddingX={2}
                className={classes.pinkTitleBar}
              >
                <Typography variant="h5">
                  {t('common.transferHistory')}
                </Typography>
              </Box>
            </Grid>
            <Grid item>
              <DateInput />
            </Grid>
            <Grid item>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={12} md={3}>
                  <FormField<PlayerReportTransferFormType, DropDownProps>
                    context={FormContext}
                    component={DropDown}
                    name="transaction_type"
                    label={t('common.transactionType')}
                    fullWidth
                    options={transactionTypeOptions}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <FormField<PlayerReportTransferFormType, DropDownProps>
                    context={FormContext}
                    component={DropDown}
                    name="wallet"
                    label={t('common.platformWallet')}
                    options={walletOptions}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <FormField<PlayerReportTransferFormType, DropDownProps>
                    context={FormContext}
                    component={DropDown}
                    name="platform"
                    label={t('common.gameWallet')}
                    options={platformOptions}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <FormField<PlayerReportTransferFormType, DropDownProps>
                    context={FormContext}
                    component={DropDown}
                    name="transaction_status"
                    label={t('common.transactionStatus')}
                    fullWidth
                    options={transactionStatusOptions}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={12} md={3}>
                  <FormField<PlayerReportTransferFormType, TextFieldProps>
                    context={FormContext}
                    component={TextField}
                    name="account"
                    label={t('common.playerAccount')}
                    placeholder={t('placeholder.inputPlayerAccount')}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <FormField<PlayerReportTransferFormType, TextFieldProps>
                    context={FormContext}
                    component={TextField}
                    name="transfer_number"
                    label={t('common.transactionSerial')}
                    placeholder={t('placeholder.inputSerial')}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container justifyContent="flex-end">
                <Grid item>
                  <FormSubmitButton
                    component={Button}
                    context={FormContext}
                    type="submit"
                    className={classes.purpleGradualButton}
                  >
                    {t('common.search')}
                  </FormSubmitButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </FormStateProvider>
  )
}

export default React.memo(PlayerReportTransferForm)

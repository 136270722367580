import React from 'react'
import Box from '@material-ui/core/Box'
import PlayerRankEffectiveBetForm from '../../../components/admin/player/PlayerRankEffectiveBetForm'
import PlayerRankEffectiveBetTable from '../../../components/admin/player/PlayerRankEffectiveBetTable'

const PlayerRankEffectiveBetPage: React.FC = () => {
  return (
    <Box paddingY={5}>
      <Box paddingX={5}>
        <PlayerRankEffectiveBetForm />
      </Box>
      <Box paddingTop={3}>
        <PlayerRankEffectiveBetTable />
      </Box>
    </Box>
  )
}

export default React.memo(PlayerRankEffectiveBetPage)

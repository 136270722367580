import React from 'react'
import Box from '@material-ui/core/Box'
import ManualWithdrawForm from '../../../components/admin/manual/ManualWithdrawForm'
import ManualWithdrawTable from '../../../components/admin/manual/ManualWithdrawTable'

const ManualWithdrawPage: React.FC = () => {
  return (
    <Box paddingY={5}>
      <Box paddingX={5}>
        <ManualWithdrawForm />
      </Box>
      <Box paddingTop={3}>
        <ManualWithdrawTable />
      </Box>
    </Box>
  )
}

export default React.memo(ManualWithdrawPage)

import React from 'react'
import clsx from 'clsx'
import { AnnouncementStatusType } from '@golden/gdk-admin'
import { SvgIconProps } from '@material-ui/core/SvgIcon'
import Box from '@material-ui/core/Box'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'
import VisibilityIcon from '@material-ui/icons/Visibility'
import CancelIcon from '@material-ui/icons/Cancel'
import ScheduleIcon from '@material-ui/icons/Schedule'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import announcementStatusName from '../../../constants/admin/announcementStatusName'
import useT from '../../../i18ns/admin/useT'

interface PropTypes {
  status: AnnouncementStatusType
}

const announcementStatusIcon: { [key in AnnouncementStatusType]: React.ComponentType<SvgIconProps> } = {
  [AnnouncementStatusType.NOT_START]: VisibilityOffIcon,
  [AnnouncementStatusType.START]: VisibilityIcon,
  [AnnouncementStatusType.STOP]: CancelIcon,
  [AnnouncementStatusType.OUT_DATE]: ScheduleIcon
}

const NotificationTableStatus: React.FC<PropTypes> = (props) => {
  const { status } = props
  const classes = useCommonStyles()
  const { t } = useT()
  const AnnouncementIcon = announcementStatusIcon[status]
  return (
    <Box
      className={clsx({
        [classes.enableStatus]: status === AnnouncementStatusType.START,
        [classes.disableStatus]: status !== AnnouncementStatusType.START,
        [classes.nowrap]: true
      })}
      display="flex"
      flexDirection="row"
      alignItems="center"
      justifyContent="center"
    >
      <AnnouncementIcon className={classes.statusIcon} />
      {t(announcementStatusName[status])}
    </Box>
  )
}

export default React.memo(NotificationTableStatus)

import { ActivityFrequencyType } from '@golden/gdk-admin'
import TKeyType from '../../i18ns/admin/TKeyType'

const activityFrequencyName: { [key in ActivityFrequencyType]: TKeyType } = {
  [ActivityFrequencyType.ONE_TIME]: 'common.oneTime',
  [ActivityFrequencyType.EVERYDAY]: 'common.everyday',
  [ActivityFrequencyType.EVERY_WEEK]: 'common.everyWeek',
  [ActivityFrequencyType.EVERY_MONTH]: 'common.everyMonth'
}

export default activityFrequencyName

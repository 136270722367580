import React from 'react'
import { getTime, startOfDay, endOfDay } from 'date-fns'
import { InternalWithdraw } from '@golden/gdk-admin'
import { Link } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import allRoute from '../route/route'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import useT from '../../../i18ns/admin/useT'
import { useChecker } from '../../../utils/admin/AdminRouteHook'

interface PropTypes {
  item: InternalWithdraw
}

const InternalWithdrawButtons: React.FC<PropTypes> = (props) => {
  const { item } = props
  const { t } = useT()
  const classes = useCommonStyles()
  const writable = useChecker()

  if (!item.enable_internal_withdraw) return (<>-</>)
  return (
    <Grid container direction="column" spacing={1} justify="center">
      {writable && (
        <Grid item>
          <Button
            component={Link}
            to={allRoute.financeInternalWithdrawExecute.encodePath({ search: {}, param: { id: item.forwarder_id } })}
            size="small"
            classes={{ root: classes.blueGradualOutlineButton, disabled: classes.blueGradualOutlineDisabledButton }}
            disabled={!item.is_active || Number(item.balance) <= 0}
          >
            {t('common.internalWithdraw')}
          </Button>
        </Grid>
      )}
      <Grid item>
        <Button
          component={Link}
          to={allRoute.financeInternalWithdrawHistory.encodePath({ search: { forwarder_id: item.forwarder_id, start_at: getTime(startOfDay(new Date())), end_at: getTime(endOfDay(new Date())), page: 1 }, param: {} })}
          size="small"
          className={classes.blueGradualOutlineButton}
        >
          {t('common.record')}
        </Button>
      </Grid>
    </Grid>
  )
}

export default React.memo(InternalWithdrawButtons)

import React, { Dispatch, SetStateAction, PropsWithChildren } from 'react'
import { AdminModule } from '@golden/gdk-admin'
import StateProvider from '../../../providers/default/StateProvider'
import { MemoPopoverPayload } from './MemoPopover'
import MemoDialog, { MemoDialogPayload, createDefaultMemoDialogPayload } from './MemoDialog'
import { GlobalDialogType } from '../../../utils/default/DialogHelper'
import MemoPopoverWithContext from './MemoPopoverWithContext'

interface PropTypes {
  gdk: AdminModule
  globalDialog: GlobalDialogType
  memoPopoverContext: React.Context<[MemoPopoverPayload, Dispatch<SetStateAction<MemoPopoverPayload>>]>
  memoDialogContext: React.Context<[MemoDialogPayload, Dispatch<SetStateAction<MemoDialogPayload>>]>
  onOK: (memoDialogPayload: MemoDialogPayload) => void
}

const MemoPopoverAndDialogWithContext: React.FC<PropsWithChildren<PropTypes>> = (props) => {
  const {
    gdk,
    globalDialog,
    memoPopoverContext,
    memoDialogContext: MemoDialogContext,
    children,
    onOK
  } = props
  return (
    <StateProvider
      context={MemoDialogContext}
      defaultValue={createDefaultMemoDialogPayload()}
    >
      <MemoPopoverWithContext memoPopoverContext={memoPopoverContext}>
        {children}
      </MemoPopoverWithContext>
      <MemoDialogContext.Consumer>
        {([memoDialogPayload, setMemoDialogPayload]) => (
          <MemoDialog
            {...memoDialogPayload}
            gdk={gdk}
            globalDialog={globalDialog}
            onOK={(memo) => {
              onOK({ ...memoDialogPayload, open: false, memo })
              setMemoDialogPayload((memoDialogPayload) => {
                return { ...memoDialogPayload, open: false }
              })
            }}
            onCancel={() => setMemoDialogPayload((memoDialogPayload) => ({ ...memoDialogPayload, open: false }))}
          />
        )}
      </MemoDialogContext.Consumer>
    </StateProvider>
  )
}

export default React.memo(MemoPopoverAndDialogWithContext)

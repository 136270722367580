import { SmsReceiveStatusType } from '@golden/gdk-admin'
import TKeyType from '../../i18ns/admin/TKeyType'

const smsReceiveStatusName: { [key in SmsReceiveStatusType]: TKeyType } = {
  [SmsReceiveStatusType.SUCCESS]: 'smsReceiveStatus.success',
  [SmsReceiveStatusType.FAILURE]: 'smsReceiveStatus.failure',
  [SmsReceiveStatusType.WAITING]: 'smsReceiveStatus.waiting',
  [SmsReceiveStatusType.UNKNOWN]: 'smsRecevieStatus.unknown',
  [SmsReceiveStatusType.OTHER]: 'smsRecevieStatus.unsend'
}

export default smsReceiveStatusName

import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import GroupType from '../../default/navigation/GroupType'
import ListGroup from './ListGroup'
import PathGroup from './PathGroup'
import LinkGroup from './LinkGroup'
import allRoute from '../route/route'
import RouteKey from '../route/RouteKey'
import allLink, { LinkKey } from './link'
import GroupKey from './GroupKey'
import CustomThemeType from '../../../themes/admin/CustomThemeType'
import TKeyType from '../../../i18ns/admin/TKeyType'
import useT from '../../../i18ns/admin/useT'
import useGDKStore from '../../../providers/admin/gdk/useGDKStore'
import { getPass } from '../../../utils/admin/AdminRouteHelper'

const useStyles = makeStyles((theme: CustomThemeType) => ({
  text: {
    wordBreak: 'keep-all'
  },
  item: {
    marginTop: theme.spacing(1)
  },
  itemButton: {
    '&:hover': {
      backgroundColor: '#0099a0',
      color: theme.palette.common.white,
      '& svg': {
        color: theme.palette.common.white
      }
    },
    '& svg': {
      color: theme.custom.palette.text.navigation
    }
  },
  itemSelected: {
    backgroundColor: '#0099a0 !important',
    color: theme.palette.common.white,
    '& svg': {
      color: theme.palette.common.white
    }
  },
  subItem: {
    color: theme.palette.common.white,
    paddingLeft: theme.spacing(9.5),
    height: theme.spacing(5),
    marginTop: theme.spacing(1)
  },
  subItemButton: {
    '&:hover': {
      backgroundColor: '#0099a0'
    }
  },
  subItemSelected: {
    backgroundColor: '#0099a0 !important'
  }
}))

interface PropTypes {
  group: GroupType<RouteKey, GroupKey, LinkKey, TKeyType>
}

const GroupComponent: React.FC<PropTypes> = (props) => {
  const { group } = props
  const classes = useStyles()
  const { t } = useT()
  const me = useGDKStore.admin.me()

  if (Array.isArray(group.subItem)) {
    const filteredGroup = (group.subItem).filter((item) => {
      if (Object.values(RouteKey).includes((item as RouteKey))) {
        if (me === null) return false
        return getPass(allRoute[item as RouteKey].permissions, me)
      }
      if (Object.values(LinkKey).includes((item as LinkKey))) {
        if (me === null) return false
        return getPass(allLink[item as LinkKey].permissions, me)
      }
      return false
    })
    if (filteredGroup.length === 0) {
      return null
    }
    return (
      <ListGroup
        classes={classes}
        groupKey={group.key}
        icon={group.icon}
        title={group.key === GroupKey.MY_DATA && me !== null ? me.account : t(group.title)}
        subItems={filteredGroup}
      />
    )
  }

  if (Object.values(RouteKey).includes((group.subItem as RouteKey))) {
    if (me === null) return null
    if (!getPass(allRoute[group.subItem as RouteKey].permissions, me)) return null
    return (
      <PathGroup
        classes={classes}
        groupKey={group.key}
        icon={group.icon}
        title={t(group.title)}
        subItem={group.subItem as RouteKey}
      />
    )
  }

  if (Object.values(LinkKey).includes((group.subItem as LinkKey))) {
    if (me === null) return null
    if (!getPass(allLink[group.subItem as LinkKey].permissions, me)) return null
    return (
      <LinkGroup
        classes={classes}
        groupKey={group.key}
        icon={group.icon}
        title={t(group.title)}
        subItem={group.subItem as LinkKey}
      />
    )
  }
  return null
}

export default React.memo(GroupComponent)

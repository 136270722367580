import React, { Dispatch, SetStateAction, PropsWithChildren } from 'react'
import StateProvider from '../../../providers/default/StateProvider'
import MemoPopover, { MemoPopoverPayload, createDefaultMemoPopoverPayload } from './MemoPopover'

interface PropTypes {
  memoPopoverContext: React.Context<[MemoPopoverPayload, Dispatch<SetStateAction<MemoPopoverPayload>>]>
}

const MemoPopoverWithContext: React.FC<PropsWithChildren<PropTypes>> = (props) => {
  const {
    memoPopoverContext: MemoPopoverContext,
    children
  } = props
  return (
    <StateProvider
      context={MemoPopoverContext}
      defaultValue={createDefaultMemoPopoverPayload()}
    >
      {children}
      <MemoPopoverContext.Consumer>
        {([memoPopoverPayload, setMemoPopoverPayload]) => (
          <MemoPopover
            {...memoPopoverPayload}
            onClose={() => setMemoPopoverPayload((memoPopoverPayload) => ({ ...memoPopoverPayload, open: false }))}
          />
        )}
      </MemoPopoverContext.Consumer>
    </StateProvider>
  )
}

export default React.memo(MemoPopoverWithContext)

import { InternalWithdrawStatusType } from '@golden/gdk-admin'

const internalWithdrawStatusColor: { [key in InternalWithdrawStatusType]: string } = {
  [InternalWithdrawStatusType.SUCCESSFUL]: '#72d476',
  [InternalWithdrawStatusType.IN_PROGRESS]: '#b86fff',
  [InternalWithdrawStatusType.FAIL]: '#ff5f5f',
  [InternalWithdrawStatusType.REQUEST_FAIL]: '#ff5f5f'
}

export default internalWithdrawStatusColor

import React, { useEffect } from 'react'
import Box from '@material-ui/core/Box'
import WithdrawalRCSlipForm from '../../../components/admin/withdrawal/WithdrawalRCSlipForm'
import WithdrawalRCSlipTable from '../../../components/admin/withdrawal/WithdrawalRCSlipTable'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { Branch } from '@golden/gdk-admin'

const WithdrawalRCSlipPage: React.FC = () => {
  const gdk = useGDK()
  useEffect(() => {
    gdk.trunk.trigger([Branch.PUBLISHERS, Branch.PLAYER_LAYER])
  }, [gdk])
  return (
    <Box paddingY={5}>
      <Box paddingX={5}>
        <WithdrawalRCSlipForm />
      </Box>
      <Box paddingTop={3}>
        <WithdrawalRCSlipTable />
      </Box>
    </Box>
  )
}

export default React.memo(WithdrawalRCSlipPage)

import React from 'react'
import { withProfiler, ErrorBoundary } from '@golden/sentry-react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { MuiThemeProvider } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import allRoute, { routeOrder } from '../../components/admin/route/route'
import AppRoute from '../../components/admin/route/AppRoute'
import ErrorPage from '../../components/default/frames/ErrorPage'
import LoadingPage from '../../components/default/frames/LoadingPage'
import AppFrame from '../../components/admin/frames/AppFrame'
import DrawerContent from '../../components/admin/frames/AppDrawerContent'
import AppBarContent from '../../components/admin/frames/AppBarContent'
import PickersUtilsProvider from '../../providers/default/PickersUtilsProvider'
import GDKStoreProvider from '../../providers/admin/gdk/GDKStoreProvider'
import GDKProvider from '../../providers/admin/gdk/GDKProvider'
import DrawerProvider from '../../providers/default/drawer/DrawerProvider'
import SnackbarProvider from '../../providers/admin/snackbar/SnackbarProvider'
import GreetingDisplayStatusProvider from '../../providers/admin/greeting/GreetingDisplayStatusProvider'
import GlobalDialogProvider from '../../providers/admin/dialog/GlobalDialogProvider'
import theme from '../../themes/admin/theme'
import '../../i18ns/admin/i18n'
import FinanceAlertHandler from '../../providers/admin/FinanceAlertHandler'
import { usePageview } from '@golden/ga-react'
import { StageStatus } from '@golden/shared'

const routes = routeOrder.map((key) => allRoute[key])

const App: React.FC = () => {
  if (!(['local', 'alpha', 'beta'] as StageStatus[]).includes(import.meta.env.VITE_APP_STAGE)) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    usePageview(import.meta.env.VITE_APP_GA_ID)
  }
  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <BrowserRouter>
        <React.Suspense fallback={<LoadingPage />}>
          <ErrorBoundary errorPage={ErrorPage}>
            <PickersUtilsProvider>
              <GDKProvider>
                <GDKStoreProvider>
                  <FinanceAlertHandler />
                  <DrawerProvider>
                    <GlobalDialogProvider>
                      <GreetingDisplayStatusProvider>
                        <SnackbarProvider>
                          <AppFrame
                            drawerContentComponent={DrawerContent}
                            appBarContentComponent={AppBarContent}
                          >
                            <Routes>
                              {routes.map((route) => (
                                <Route
                                  key={route.key}
                                  path={route.path}
                                  element={<AppRoute />}
                                />
                              ))}
                            </Routes>
                          </AppFrame>
                        </SnackbarProvider>
                      </GreetingDisplayStatusProvider>
                    </GlobalDialogProvider>
                  </DrawerProvider>
                </GDKStoreProvider>
              </GDKProvider>
            </PickersUtilsProvider>
          </ErrorBoundary>
        </React.Suspense>
      </BrowserRouter>
    </MuiThemeProvider>
  )
}

export default withProfiler(App)

import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import { AgentWalletTransactionType } from '@golden/gdk-admin'
import agentWalletTransactionTypeName from '../../../constants/default/agentWalletTransactionTypeName'
import useT from '../../../i18ns/admin/useT'

const useStyles = makeStyles((theme) => ({
  text: (props: { type: AgentWalletTransactionType }) => {
    let color = ''
    if ([AgentWalletTransactionType.RECHARGE, AgentWalletTransactionType.DEPOSIT].includes(props.type)) color = '#55B559'
    if ([AgentWalletTransactionType.REVOKE].includes(props.type)) color = theme.palette.error.main
    return ({
      color
    })
  }
}))

interface PropTypes {
  type: AgentWalletTransactionType
}

const AgentTransactionText: React.FC<PropTypes> = (props) => {
  const { type } = props
  const classes = useStyles({ type })
  const { t } = useT()

  return (
    <Box component="span" className={classes.text}>
      {t(agentWalletTransactionTypeName[type])}
    </Box>
  )
}

export default React.memo(AgentTransactionText)

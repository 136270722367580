import React, { useMemo } from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { createTableData, formatMoney } from '../../../utils/default/TableHelper'
import CoreTable from '../../default/present/CoreTable'
import { useCommonStyles, useDialogStyles } from '../../../utils/admin/StyleHook'
import useT from '../../../i18ns/admin/useT'
import useGlobalDialog from '../../../providers/admin/dialog/useGlobalDialog'
import { createGlobalDialogConfig } from '../../../utils/default/DialogHelper'
import DateTime from '../../default/present/DateTime'
import { AdminWithdrawStatusType } from '@golden/gdk-admin'
import adminWithdrawStatusColor from '../../../constants/admin/adminWithdrawStatusColor'
import adminWithdrawStatusName from '../../../constants/admin/adminWithdrawStatusName'

const useStyles = makeStyles((theme) => ({
  success: {
    backgroundColor: '#72d476'
  },
  failure: {
    background: '#f44336'
  },
  serial: {
    margin: theme.spacing(1)
  },
  helperText: {
    color: '#aaa'
  }
}))

interface PropTypes {
  items: Array<{
    finished_at?: Date
    updated_by: string
    forwarder_name: string
    order_number: string
    cash: string
    cash_crypto?: string
    status: AdminWithdrawStatusType
    content: string
    exchange_rate?: string
  }>
  isCrypto: boolean
}

interface DialogRowType {
  id: number
  time: React.ReactElement
  content: string
}

interface RowType {
  id: number
  operator: string
  name: string
  serial: React.ReactElement
  cash: string | React.ReactElement
  exchange_rate: string
  status: React.ReactElement
}

const WithdrawalFMSlipCourierDetail: React.FC<PropTypes> = (props) => {
  const { items, isCrypto } = props
  const { t } = useT()
  const classes = useStyles()
  const commonClasses = useCommonStyles()
  const dialogClasses = useDialogStyles()
  const globalDialog = useGlobalDialog()
  const tableClasses = useMemo(() => ({
    head: commonClasses.greyTableHead,
    row: commonClasses.tableRow,
    cellHead: commonClasses.tableCellHead
  }), [commonClasses])
  const rows: RowType[] = useMemo(() => {
    return items.map((item, index) => ({
      id: index,
      operator: item.updated_by,
      name: item.forwarder_name,
      serial: (
        <Typography
          className={clsx(commonClasses.anchor, classes.serial)}
          onClick={() => {
            const row: DialogRowType[] = [{
              id: 1,
              time: item.finished_at ? (<DateTime time={item.finished_at} />) : (<p></p>),
              content: item.content
            }]
            const data = createTableData<DialogRowType>(
              {
                id: {
                  label: '',
                  value: 'id'
                },
                time: {
                  label: t('common.time'),
                  value: 'time',
                  align: 'center'
                },
                content: {
                  label: t('common.content'),
                  value: 'content',
                  align: 'left'
                }
              },
              [
                'time',
                'content'
              ],
              row,
              'id'
            )
            globalDialog.setConfig(createGlobalDialogConfig({
              showIcon: false,
              showCancel: false,
              notUseTypo: true,
              message: (
                <Box display="flex" flexDirection="column" alignItems="center">
                  <Typography className={dialogClasses.text}>{`${t('common.orderNumberWithColon')}${item.order_number}`}</Typography>
                  <CoreTable
                    dense
                    noScroll
                    data={data}
                    total={1}
                    classes={{
                      head: commonClasses.blackTableHead,
                      cellHead: commonClasses.tableCellHead,
                      row: commonClasses.borderTableRow
                    }}
                  />
                </Box>
              )
            }))
            globalDialog.setOpen({
              id: `openDetail-${index}`,
              value: true,
              isOK: false
            })
          }}
        >
          {item.order_number}
        </Typography>
      ),
      cash: (
        <React.Fragment>
          <div>{formatMoney(item.cash)}</div>
          {isCrypto && (<div className={classes.helperText}>{item.cash_crypto ? formatMoney(item.cash_crypto) : '-'} USDT</div>)}
        </React.Fragment>
      ),
      status: (
        <span className={commonClasses.chipText} style={{ backgroundColor: adminWithdrawStatusColor[item.status] }}>
          {t(adminWithdrawStatusName[item.status])}
        </span>
      ),
      exchange_rate: item.exchange_rate ? formatMoney(item.exchange_rate) : '-'
    }) as RowType)
  }, [classes.serial, commonClasses.anchor, commonClasses.blackTableHead, commonClasses.borderTableRow, commonClasses.chipText, commonClasses.tableCellHead, dialogClasses.text, globalDialog, items, t, classes.helperText, isCrypto])
  const data = useMemo(() => {
    return createTableData<RowType>(
      {
        id: {
          label: '',
          value: 'id'
        },
        operator: {
          label: t('common.updateBy'),
          value: 'operator',
          align: 'center'
        },
        name: {
          label: t('common.courierName'),
          value: 'name',
          align: 'center'
        },
        serial: {
          label: t('common.orderNumber'),
          value: 'serial',
          align: 'center'
        },
        cash: {
          label: t('common.cash'),
          value: 'cash',
          align: 'center'
        },
        exchange_rate: {
          label: t('common.exchangeRate'),
          value: 'exchange_rate',
          align: 'left'
        },
        status: {
          label: t('common.status'),
          value: 'status',
          align: 'center'
        }
      },
      isCrypto
        ? [
            'operator',
            'name',
            'serial',
            'cash',
            'exchange_rate',
            'status'
          ]
        : [
            'operator',
            'name',
            'serial',
            'cash',
            'status'
          ],
      rows,
      'id'
    )
  }, [rows, t, isCrypto])
  return (
    <CoreTable
      dense
      classes={tableClasses}
      data={data}
      total={items.length}
    />
  )
}

export default React.memo(WithdrawalFMSlipCourierDetail)

import React, { useCallback, useEffect } from 'react'
import { DepositWayForm, DepositMethodType, Branch, ClientType, DepositCategoryType } from '@golden/gdk-admin'
import Box from '@material-ui/core/Box'
import EditFinanceDepositWayForm, { EditFinanceDepositWayFormType, initialForm } from '../../../components/admin/finance/EditFinanceDepositWayForm'
import useT from '../../../i18ns/admin/useT'
import { useDialogHandleSubmit, useRedirectHandleBack, useRequestFromSearch } from '../../../utils/default/ComplexFlowHook'
import useGlobalDialog from '../../../providers/admin/dialog/useGlobalDialog'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import { createGlobalDialogConfig } from '../../../utils/default/DialogHelper'
import allRoute from '../../../components/admin/route/route'
import depositTypeName from '../../../constants/default/depositTypeName'
import { searchToRequest } from './FinanceDepositWayPage'
import useGDKStore from '../../../providers/admin/gdk/useGDKStore'

const formToRequest: (form: EditFinanceDepositWayFormType) => DepositWayForm = (form) => {
  return {
    forwarder_id: Number(form.courier),
    category: form.method[0] as DepositCategoryType,
    method: form.method[1] as DepositMethodType,
    cash_min: Number(form.min),
    cash_max: Number(form.max),
    cash_full: Number(form.full),
    fee_rate: Number(form.userFee),
    layer_ids: form.playerLayer.layers,
    agent_types: form.agentLayers,
    is_active: form.isActive,
    is_recommended: form.isRecommended,
    clients: form.clients,
    is_for_proxy_deposit: form.playerLayer.isForProxyDeposit
  }
}

const defaultForm = initialForm({
  clients: [ClientType.DESKTOP, ClientType.MOBILE]
})

const FinanceDepositWayCreatePage: React.FC = () => {
  const request = useRequestFromSearch({ searchToRequest })
  const classes = useCommonStyles()
  const globalDialog = useGlobalDialog()
  const gdk = useGDK()
  const { t } = useT()
  const forwarders = useGDKStore.finance.forwarders()

  useEffect(() => {
    gdk.trunk.trigger([Branch.PLAYER_LAYER, Branch.FORWARDERS])
  }, [gdk])

  const [handleBack, handleDebouncedBack] = useRedirectHandleBack({
    path: allRoute.financeDepositWay.encodePath({ search: { ...request }, param: {} })
  })

  const { handleSubmit } = useDialogHandleSubmit<EditFinanceDepositWayFormType, DepositWayForm, null>({
    dialogId: 'createWay',
    globalDialog,
    getChangeDialogConfig: useCallback((form) => createGlobalDialogConfig({
      showIcon: false,
      message: (
        <React.Fragment>
          {t('dialog.confirmCreateDeposit')}
          <span className={classes.purpleWord}>
            {`${forwarders[form.slug]?.name ?? ''} ${t(depositTypeName[form.method[1] as DepositMethodType])}`}
          </span>
        </React.Fragment>
      )
    }), [t, forwarders, classes.purpleWord]),
    getSuccessDialogConfig: useCallback(() => createGlobalDialogConfig({
      showIcon: false,
      showCancel: false,
      message: t('dialog.createDepositSuccess')
    }), [t]),
    getFailDialogConfig: useCallback((error) => createGlobalDialogConfig({
      showIcon: true,
      variant: 'error',
      showCancel: false,
      message: error
    }), []),
    formToRequest,
    gdkFunc: (payload) => gdk.finance.createDepositWay(payload),
    gdkFuncDependencies: [gdk],
    afterSuccessDialog: handleBack
  })

  return (
    <Box padding={5}>
      <EditFinanceDepositWayForm
        title={t('common.createDeposit')}
        okText={t('common.confirmCreate')}
        onBack={handleDebouncedBack}
        onSubmit={handleSubmit}
        defaultValue={defaultForm}
      />
    </Box>
  )
}

export default React.memo(FinanceDepositWayCreatePage)

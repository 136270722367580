import React, { useState, useRef, useMemo, useCallback, useEffect } from 'react'
import { PaginationRes, ActivityApplication, ActivityApplicationSum, ActivityApplicationStatusType, ActivityApplicationRequestPayload, ActivityApplicationExportRequestPayload, ADMIN_API, PermissionType, getTargetURL } from '@golden/gdk-admin'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import { searchToRequest } from './ActivityApplicationForm'
import { useCommonStyles } from '../../../../utils/admin/StyleHook'
import useT from '../../../../i18ns/admin/useT'
import useGDK from '../../../../providers/admin/gdk/useGDK'
import { createDefaultPaginationData, createTableData } from '../../../../utils/default/TableHelper'
import { usePageFlow } from '../../../../utils/default/PageFlowHook'
import { useRequestFromSearch, useGetDataByPayload, useReload, useGDKFuncHandleClick, usePerPageChange, usePaginationClickAndChangeUrl } from '../../../../utils/default/ComplexFlowHook'
import DateTime from '../../../default/present/DateTime'
import LoadingAndErrorFrame from '../../../default/frames/LoadingAndErrorFrame'
import ScrollablePaper from '../../../default/present/ScrollablePaper'
import CoreTable from '../../../default/present/CoreTable'
import { useChecker } from '../../../../utils/admin/AdminRouteHook'
import playerRankName from '../../../../constants/default/playerRankName'
import activityApplicationStatusColor from '../../../../constants/admin/activityApplicationStatusColor'
import activityApplicationStatusName from '../../../../constants/admin/activityApplicationStatusName'
import TableTabs from '../../TableTabs'
import ActivityApplicationOperation from './ActivityApplicationOperation'
import { getServerUrl } from '../../../../utils/default/StageHelper'
import useGlobalDialog from '../../../../providers/admin/dialog/useGlobalDialog'
import { createGlobalDialogConfig } from '../../../../utils/default/DialogHelper'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import allRoute from '../../route/route'
interface RowType {
  id: number
  selected: React.ReactElement
  batchId: string
  createdAt: React.ReactElement
  activityName: string
  playerAccount: string
  rank: string
  agentAccount: string
  depositCondition: React.ReactElement
  betCondition: React.ReactElement
  detail: React.ReactElement
  status: React.ReactElement
  updatedAt: React.ReactElement
  operator: string
  memo: string
  operation: React.ReactElement
}

const useStyles = makeStyles((theme) => ({
  noBorder: {
    border: 'none'
  },
  white: {
    color: theme.palette.common.white
  }
}))

const getSelectedInitialState = (): { [key in ActivityApplicationStatusType]: Array<{ id: number, batchId: string }> } => ({
  created: [],
  locked: [],
  passed: [],
  rejected: [],
  cancelled: []
})

const LockedCannotBeCancelledInfo: React.FC = () => {
  const { t } = useT()
  return (<Box paddingY={2}>
    <Typography>{t('common.lockedCannotBeCancelledInfo')}</Typography>
  </Box>)
}

const ActivityApplicationTable: React.FC = () => {
  const tableIsInitializedRef = useRef(false)
  const writableEdit = useChecker([PermissionType.ACTIVITY_APPLICATION])
  const writableExport = useChecker([PermissionType.ACTIVITY_APPLICATION_EXPORT])
  const commonClasses = useCommonStyles()
  const classes = useStyles()
  const { t } = useT()
  const gdk = useGDK()
  const [tab, setTab] = useState(ActivityApplicationStatusType.CREATED)
  const [list, setList] = useState<PaginationRes<Array<ActivityApplication<Date>>> & ActivityApplicationSum>({
    ...createDefaultPaginationData([]),
    total_count_by_status: { created: 0, locked: 0, rejected: 0, passed: 0, cancelled: 0 }
  })
  const [selected, setSelected] = useState(getSelectedInitialState())
  const resetSelected = useCallback(() => {
    setSelected(getSelectedInitialState())
  }, [])
  const pageFlow = usePageFlow()
  const { reload, reloadFlag } = useReload()
  const globalDialog = useGlobalDialog()

  const request = useRequestFromSearch({ searchToRequest })
  const payload: ActivityApplicationRequestPayload | null = useMemo(() => {
    if (request === undefined) return null
    return {
      ...request,
      status: tab
    }
  }, [request, tab])
  const exportPayload: ActivityApplicationExportRequestPayload | null = useMemo(() => {
    if (!payload) return null
    const ids = selected[tab].map((el) => el.id)
    return {
      ...payload,
      ...(ids.length > 0 ? { ids } : {})
    }
  }, [payload, selected, tab])

  useGetDataByPayload({
    payload,
    gdkFunc: (payload) => gdk.activity.getActivityApplicationList(payload),
    gdkFuncDependencies: [gdk, reloadFlag],
    onBeforeFetch: pageFlow.setLoadingStart,
    onSuccess: (res: PaginationRes<ActivityApplication[]> & ActivityApplicationSum) => {
      setList(res)
      pageFlow.setContentShow()
    },
    onError: pageFlow.setGDKError,
    canLoadData: !!payload
  })

  const tableClasses = useMemo(() => ({
    head: clsx(commonClasses.pinkTableHead, classes.noBorder),
    row: commonClasses.tableRow,
    cellHead: commonClasses.tableCellHead,
    cellBody: commonClasses.pre
  }), [classes.noBorder, commonClasses.pinkTableHead, commonClasses.pre, commonClasses.tableCellHead, commonClasses.tableRow])
  const tabIsCreated = useMemo(() => tab === ActivityApplicationStatusType.CREATED, [tab])
  const tabIsCancelled = useMemo(() => tab === ActivityApplicationStatusType.CANCELLED, [tab])
  const isDisplayOperations = useMemo(() => !!selected[tab].length && writableEdit && !tabIsCancelled, [selected, tab, writableEdit, tabIsCancelled])
  const isDisplayExportButton = useMemo(() => !!list.total && writableExport, [list.total, writableExport])

  const handleSelect = useCallback((id: number, batchId: string) => (_: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    const newSelected = checked ? [...selected[tab], { id, batchId }] : selected[tab].filter((el) => el.id !== id)
    setSelected({ ...selected, [tab]: newSelected })
  }, [selected, tab])
  const handleSelectAll = useCallback((_: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    const listIds = list.data.map((el) => ({ id: el.id, batchId: el.batch_id }))
    const selectedWithoutList = selected[tab].filter((el) => !listIds.map((item) => item.id).includes(el.id))
    const newSelected = checked ? [...selectedWithoutList, ...listIds] : selectedWithoutList
    setSelected({ ...selected, [tab]: newSelected })
  }, [list.data, selected, tab])

  useEffect(() => {
    if (!tableIsInitializedRef.current) {
      tableIsInitializedRef.current = true
      return
    }
    if (request) {
      resetSelected()
    }
  }, [request, resetSelected])

  const rows: RowType[] = useMemo(() => {
    return list.data.map((item) => {
      return {
        id: item.id,
        selected: (
          <Box paddingLeft={2}>
            <Checkbox
              checked={selected[tab].map((el) => el.id).includes(item.id)}
              onChange={handleSelect(item.id, item.batch_id)}
            />
          </Box>
        ),
        batchId: item.batch_id,
        createdAt: (<DateTime time={item.created_at} />),
        activityName: item.title,
        playerAccount: item.user_account,
        rank: t(playerRankName[item.user_rank]),
        agentAccount: item.agent_account,
        depositCondition: (<span className={commonClasses.pre}>{item.deposit_condition}</span>),
        betCondition: (<span className={commonClasses.pre}>{item.bet_condition}</span>),
        detail: (<span className={commonClasses.pre}>{item.detail}</span>),
        status: (
          <span className={commonClasses.chipText} style={{ backgroundColor: activityApplicationStatusColor[item.status] }}>
            { t(activityApplicationStatusName[item.status]) }
          </span>
        ),
        updatedAt: item.updated_at ? (<DateTime time={item.updated_at} />) : (<></>),
        operator: item.operator || '',
        memo: item.memo || '',
        operation: (<ActivityApplicationOperation
          id={item.id}
          playerAccount={item.user_account}
          tab={tab}
          exportPayload={exportPayload}
          reload={reload}
          resetSelected={resetSelected}
        />)
      } as RowType
    })
  }, [commonClasses.chipText, commonClasses.pre, list.data, selected, tab, exportPayload, t, handleSelect, resetSelected, reload])

  const position = useMemo(() => {
    const left: Array<keyof RowType> = [
      ...((writableEdit || writableExport) ? ['selected'] as const : []),
      ...['playerAccount', 'createdAt', 'activityName', 'batchId', 'rank', 'agentAccount', 'depositCondition', 'betCondition', 'detail', 'status'] as const
    ]
    const middle: Array<keyof RowType> = [ActivityApplicationStatusType.LOCKED, ActivityApplicationStatusType.CANCELLED].includes(tab) ? ['updatedAt', 'operator'] : ['updatedAt', 'operator', 'memo']
    const right: Array<keyof RowType> = writableEdit && !selected[tab].length && !tabIsCancelled ? ['operation'] : []
    return tabIsCreated ? [...left, ...right] : [...left, ...middle, ...right]
  }, [selected, tab, writableEdit, writableExport, tabIsCancelled, tabIsCreated])
  const data = useMemo(() => {
    return createTableData<RowType>(
      {
        id: {
          label: '',
          value: 'id'
        },
        selected: {
          label: (
            <Box paddingLeft={2}>
              <Checkbox
                color="default"
                classes={{
                  root: classes.white,
                  checked: classes.white
                }}
                checked={list.data.map((el) => el.id).every((el) => selected[tab].map((item) => item.id).includes(el))}
                onChange={handleSelectAll}
              />
            </Box>
          ),
          value: 'selected'
        },
        batchId: {
          label: t('common.batchNumberId'),
          value: 'batchId',
          align: 'center',
          width: 60
        },
        createdAt: {
          label: t('common.applyTime'),
          value: 'createdAt',
          align: 'center',
          width: 80
        },
        activityName: {
          label: t('common.activityName'),
          value: 'activityName',
          align: 'center'
        },
        playerAccount: {
          label: t('common.playerAccount'),
          value: 'playerAccount',
          align: 'center',
          width: 80
        },
        rank: {
          label: t('common.playerRank'),
          value: 'rank',
          align: 'center',
          width: 40
        },
        agentAccount: {
          label: t('common.belongStaffName'),
          value: 'agentAccount',
          align: 'center',
          width: 80
        },
        depositCondition: {
          label: t('activityConditionType.deposit'),
          value: 'depositCondition',
          align: 'center',
          width: 160
        },
        betCondition: {
          label: t('activityConditionType.bet'),
          value: 'betCondition',
          align: 'center'
        },
        detail: {
          label: t('common.gameDetail'),
          value: 'detail',
          align: 'center'
        },
        status: {
          label: t('common.status'),
          value: 'status',
          align: 'center',
          width: 40
        },
        updatedAt: {
          label: t('common.updatedAt'),
          value: 'updatedAt',
          align: 'center',
          width: 80
        },
        operator: {
          label: t('common.updateBy'),
          value: 'operator',
          align: 'center'
        },
        memo: {
          label: t('common.memo'),
          value: 'memo',
          align: 'center',
          width: 100
        },
        operation: {
          label: t('common.operation'),
          value: 'operation',
          align: 'center',
          width: 40
        }
      },
      position,
      rows,
      'id'
    )
  }, [classes.white, handleSelectAll, list.data, position, rows, selected, t, tab])

  const tabs = useMemo(() => Object.keys(activityApplicationStatusName).map((status) => {
    const count: number | string = list?.total_count_by_status?.[status as ActivityApplicationStatusType] ?? '-'
    return {
      label: `${t(activityApplicationStatusName[status as ActivityApplicationStatusType])} (${count})`,
      value: status
    }
  }), [list?.total_count_by_status, t])

  const { handleClick: handleExport, loading } = useGDKFuncHandleClick({
    payload: exportPayload,
    gdkFunc: (payload) => gdk.activity.exportActivityApplicationList(payload),
    onSuccess: (res: { url: string }) => {
      const link = document.createElement('a')
      link.href = getTargetURL(getServerUrl('api'), res.url.replace(/\/api/i, ''))
      link.click()
      link.remove()
    },
    onError: (err) => {
      globalDialog.setConfig(createGlobalDialogConfig({
        variant: 'error',
        message: err.message,
        showCancel: false
      }))
      globalDialog.setOpen({ id: 'exportActivityApplicationsFailed', value: true, isOK: false })
    }
  })
  const handlePagination = usePaginationClickAndChangeUrl({
    request,
    encodePath: allRoute.activityApplication.encodePath
  })
  const handlePerPageChange = usePerPageChange({
    request,
    encodePath: allRoute.activityApplication.encodePath,
    gdk,
    pageKey: ADMIN_API.GET_ACTIVITY_APPLICATIONS.url
  })

  if (request === undefined) return (<></>)
  return (
    <>
      <ScrollablePaper marginX={5}>
        <Box padding={2}>
          <Box display="flex">
            <TableTabs
              value={tab}
              tabs={tabs}
              onChange={(value) => {
                setTab(value as ActivityApplicationStatusType)
                resetSelected()
              }}
            />
          </Box>
          <Box paddingX={2} display="flex" justifyContent="space-between" alignItems="center">
            <Box paddingY={2}>{!!list.total && (writableEdit || writableExport) && t('common.nDataSelected', { amount: selected[tab].length })}</Box>
            <Box display="flex">
              { isDisplayOperations &&
                (<ActivityApplicationOperation
                  ids={selected[tab]}
                  tab={tab}
                  reload={reload}
                  isBatch
                  resetSelected={resetSelected}
                  exportPayload={exportPayload}
                />) }
              { isDisplayExportButton &&
                (<Box margin={1}>
                  <Button
                    onClick={() => {
                      handleExport()
                      resetSelected()
                    }}
                    className={commonClasses.purpleGradualOutlineButton}
                    disabled={loading}
                  >
                    {t('common.export')}
                  </Button>
                </Box>) }
            </Box>
          </Box>
          <LoadingAndErrorFrame { ...pageFlow.status }>
            <CoreTable
              tableFooterMemo={tabIsCreated ? LockedCannotBeCancelledInfo : undefined}
              classes={tableClasses}
              data={data}
              total={list.total || 0}
              showPagination
              page={payload?.page ?? 1}
              perPage={list?.per_page}
              onChangePage={handlePagination}
              onChangeRowsPerPage={handlePerPageChange}
            />
          </LoadingAndErrorFrame>
        </Box>
      </ScrollablePaper>
    </>
  )
}

export default React.memo(ActivityApplicationTable)

import React, { createContext, useCallback } from 'react'
import Box from '@material-ui/core/Box'
import EditWithdrawalLimitForm, {
  initialWithdrawalLimitForm,
  formToRequest
} from '../../../components/admin/withdrawal/EditWithdrawalLimitForm'
import { createDefaultFormState } from '../../../utils/default/FormHook'
import useT from '../../../i18ns/admin/useT'
import { useRedirectHandleBack, useDialogHandleSubmit } from '../../../utils/default/ComplexFlowHook'
import { Path } from '../../../components/admin/route/route'
import useGDK from '../../../providers/admin/gdk/useGDK'
import useGlobalDialog from '../../../providers/admin/dialog/useGlobalDialog'
import { createGlobalDialogConfig } from '../../../utils/default/DialogHelper'

const defaultForm = initialWithdrawalLimitForm()
const FormContext = createContext(createDefaultFormState(defaultForm))

const WithdrawalLimitCreatePage: React.FC = () => {
  const { t } = useT()
  const gdk = useGDK()
  const globalDialog = useGlobalDialog()
  const [handleBack, handleDebouncedBack] = useRedirectHandleBack({
    path: Path.WITHDRAWAL_LIMIT
  })
  const { handleSubmit } = useDialogHandleSubmit({
    globalDialog,
    dialogId: 'createWithdrawLimit',
    formToRequest,
    gdkFunc: (form) => gdk.withdraw.createWithdrawLimit(form),
    gdkFuncDependencies: [gdk],
    getChangeDialogConfig: useCallback(() => createGlobalDialogConfig({
      showIcon: false,
      message: t('dialog.confirmCreateWithdrawLimit')
    }), [t]),
    getSuccessDialogConfig: useCallback(() => createGlobalDialogConfig({
      showIcon: false,
      message: t('dialog.createWithdrawLimitSuccess'),
      showCancel: false
    }), [t]),
    getFailDialogConfig: useCallback((_, error) => createGlobalDialogConfig({
      showIcon: true,
      variant: 'error',
      message: error.message,
      showCancel: false
    }), []),
    afterSuccessDialog: handleBack
  })
  return (
    <Box padding={5}>
      <EditWithdrawalLimitForm
        context={FormContext}
        defaultValue={defaultForm}
        title={t('page.createWithdrawLimit')}
        okText={t('common.confirmCreate')}
        onCancel={handleDebouncedBack}
        onSubmit={handleSubmit}
      />
    </Box>
  )
}

export default React.memo(WithdrawalLimitCreatePage)

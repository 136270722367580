import React, { useCallback, useMemo } from 'react'
import { WithdrawalWay } from '@golden/gdk-admin'
import { useNavigate } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import allRoute from '../route/route'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import useT from '../../../i18ns/admin/useT'
import useGDK from '../../../providers/admin/gdk/useGDK'
import useGlobalDialog from '../../../providers/admin/dialog/useGlobalDialog'
import { useDialogHandleClick, useRequestFromSearch } from '../../../utils/default/ComplexFlowHook'
import { createGlobalDialogConfig } from '../../../utils/default/DialogHelper'
import { searchToRequest } from '../../../views/admin/finance/FinanceDepositWayPage'
import useGDKStore from '../../../providers/admin/gdk/useGDKStore'

const ButtonsBase: React.FC<{
  status: boolean
  handleStart: () => void
  handleStop: () => void
  handleDelete: () => void
  handleUpdate: () => void
}> = React.memo((props) => {
  const {
    status,
    handleStart,
    handleStop,
    handleDelete,
    handleUpdate
  } = props
  const classes = useCommonStyles()
  const { t } = useT()
  return (
    <Grid container direction="column" spacing={1} justify="center">
      {
        !status &&
          (
            <Grid item>
              <Button
                onClick={handleStart}
                size="small"
                className={classes.greenGradualOutlineButton}
              >
                {t('common.toggleOn')}
              </Button>
            </Grid>
          )
      }
      {
        status &&
          (
            <Grid item>
              <Button
                onClick={handleStop}
                size="small"
                className={classes.greyOutlineButton}
              >
                {t('common.toggleOff')}
              </Button>
            </Grid>
          )
      }
      <Grid item>
        <Button
          size="small"
          className={classes.blueGradualOutlineButton}
          onClick={handleUpdate}
        >
          {t('common.update')}
        </Button>
      </Grid>
      <Grid item>
        <Button
          onClick={handleDelete}
          size="small"
          className={classes.pinkGradualOutlineButton}
        >
          {t('common.delete')}
        </Button>
      </Grid>
    </Grid>
  )
})

interface PropTypes {
  item: WithdrawalWay
  reload: () => void
}

const FinanceWithdrawalWayButtons: React.FC<PropTypes> = (props) => {
  const { item, reload } = props
  const { t } = useT()
  const gdk = useGDK()
  const classes = useCommonStyles()
  const globalDialog = useGlobalDialog()
  const forwarders = useGDKStore.finance.forwarders()
  const navigate = useNavigate()
  const request = useRequestFromSearch({ searchToRequest })

  const startPayload = useMemo(() => ({
    forwarder_id: item.forwarder_id,
    cash_auto_min: Number(item.cash_auto_min).toString(),
    cash_auto_max: Number(item.cash_auto_max).toString(),
    cash_min: Number(item.cash_min).toString(),
    cash_max: Number(item.cash_max).toString(),
    layer_ids: item.layers.map((item) => item.id),
    is_active: true,
    is_auto_withdraw: item.is_auto_withdraw,
    method: item.method
  }), [item])
  const handleStart = useDialogHandleClick({
    dialogId: `startDepositWay-${item.id}`,
    globalDialog,
    changeDialogConfig: createGlobalDialogConfig({
      showIcon: false,
      message: (
        <React.Fragment>
          {t('dialog.confirmStartWithdrawalWay')}
          <span className={classes.purpleWord}>{item.forwarder_name}</span>
        </React.Fragment>
      )
    }),
    successDialogConfig: createGlobalDialogConfig({
      showIcon: false,
      message: t('dialog.startWithdrawalWaySuccess'),
      showCancel: false
    }),
    getFailDialogConfig: (error: string) => createGlobalDialogConfig({
      variant: 'error',
      showIcon: true,
      message: error,
      showCancel: false
    }),
    payload: startPayload,
    gdkFunc: (payload) => gdk.finance.updateWithdrawalWay(item.id, payload),
    gdkFuncDependencies: [gdk, item.id],
    onSuccess: () => reload(),
    onSuccessDependencies: [reload]
  })

  const stopPayload = useMemo(() => ({
    forwarder_id: item.forwarder_id,
    cash_auto_min: Number(item.cash_auto_min).toString(),
    cash_auto_max: Number(item.cash_auto_max).toString(),
    cash_min: Number(item.cash_min).toString(),
    cash_max: Number(item.cash_max).toString(),
    layer_ids: item.layers.map((item) => item.id),
    is_active: false,
    is_auto_withdraw: item.is_auto_withdraw,
    method: item.method
  }), [item])
  const handleStop = useDialogHandleClick({
    dialogId: `stopDepositWay-${item.id}`,
    globalDialog,
    changeDialogConfig: createGlobalDialogConfig({
      showIcon: false,
      message: (
        <React.Fragment>
          {t('dialog.confirmStopWithdrawalWay')}
          <span className={classes.purpleWord}>{item.forwarder_name}</span>
        </React.Fragment>
      )
    }),
    successDialogConfig: createGlobalDialogConfig({
      showIcon: false,
      message: t('dialog.stopWithdrawalWaySuccess'),
      showCancel: false
    }),
    getFailDialogConfig: (error: string) => createGlobalDialogConfig({
      variant: 'error',
      showIcon: true,
      message: error,
      showCancel: false
    }),
    payload: stopPayload,
    gdkFunc: (payload) => gdk.finance.updateWithdrawalWay(item.id, payload),
    gdkFuncDependencies: [gdk, item.id],
    onSuccess: () => reload(),
    onSuccessDependencies: [reload]
  })

  const handleDelete = useDialogHandleClick({
    dialogId: `deleteWithdrawWay-${item.id}`,
    globalDialog,
    changeDialogConfig: createGlobalDialogConfig({
      showIcon: false,
      message: `${t('dialog.confirmDeleteWithdrawWay')} [${item.forwarder_name}] [${item.cash_auto_min} ~ ${item.cash_auto_max}]`
    }),
    successDialogConfig: createGlobalDialogConfig({
      showIcon: false,
      message: t('dialog.deleteWithdrawWaySuccess'),
      showCancel: false
    }),
    getFailDialogConfig: (error: string) => createGlobalDialogConfig({
      variant: 'error',
      showIcon: true,
      message: error,
      showCancel: false
    }),
    payload: item.id,
    gdkFunc: (payload) => gdk.finance.deleteWithdrawWay(payload),
    gdkFuncDependencies: [gdk],
    onSuccess: () => reload(),
    onSuccessDependencies: [reload]
  })

  const handleUpdate = useCallback(() => {
    if (forwarders[item.slug]?.forwarders.some((forwarder) => {
      const result = forwarder.id === item.forwarder_id
      if (result) console.log(forwarder.id, item.id)
      return result
    })) {
      navigate(allRoute.financeWithdrawalWayUpdate.encodePath({ search: { ...request }, param: { id: item.id } }))
      return
    }
    globalDialog.setConfig(createGlobalDialogConfig({
      variant: 'error',
      showIcon: true,
      message: t('error.pleaseEnableForwarder'),
      showCancel: false
    }))
    globalDialog.setOpen({
      id: `updateWithdrawWay-${item.id}`,
      value: true,
      isOK: false
    })
  }, [forwarders, globalDialog, item.forwarder_id, item.id, item.slug, navigate, request, t])

  return (
    <ButtonsBase
      status={item.is_active}
      handleStart={handleStart}
      handleStop={handleStop}
      handleDelete={handleDelete}
      handleUpdate={handleUpdate}
    />
  )
}

export default React.memo(FinanceWithdrawalWayButtons)

import React, { useMemo, useEffect, useRef } from 'react'
import { Campaign, CampaignFactory } from '@golden/campaign'
import { makeStyles } from '@material-ui/core/styles'
import { usePageFlow } from '../../../utils/default/PageFlowHook'
import { ActivityLayoutInfo, ActivityType, createCampaignModule, Lang } from '@golden/gdk-campaign'
import { getServerUrl } from '../../../utils/default/StageHelper'
import '@golden/campaign/dist/esm/bundle.css'
import { parsePath } from '../../../utils/default/RouteHelper'
import { useLocation } from 'react-router'
import { Path } from '../../../components/admin/route/route'
import clsx from 'clsx'

const useStyles = makeStyles(() => ({
  '@global': {
    html: {
      fontSize: '62.5%'
    },
    body: {
      fontSize: '62.5%',
      lineHeight: 'var(--line-height-base)'
    },
    button: {
      lineHeight: 'var(--line-height-base)'
    }
  },
  root: {
    backgroundColor: '#FFFFFF',
    minHeight: 667,
    '& button, & a': {
      cursor: 'default !important',
      pointerEvents: 'none !important'
    },
    overflowX: 'hidden',
    overflowY: 'auto',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0
  }
}))

type ThenArg<T> = T extends PromiseLike<infer U> ? U : T

const ActivityManagementPreviewPage: React.FC = () => {
  const pageFlow = usePageFlow()
  const classes = useStyles({ show: pageFlow.status.showContent })
  const location = useLocation()

  const type = useMemo(() => {
    const { param } = parsePath(location.search, location.pathname, Path.ACTIVITY_MANAGEMENT_PREVIEW)
    return param.type as ActivityType
  }, [location.search, location.pathname])

  const previewData = useMemo(() => {
    const string = localStorage.getItem('activity-preview')
    if (!string) return undefined
    return JSON.parse(string) as ActivityLayoutInfo<Date | null> | { title: string, start_at: Date | null, end_at: Date | null }
  }, [])

  const domRef = useRef<HTMLDivElement | null>(null)
  useEffect(() => {
    if (domRef?.current) {
      domRef.current.setAttribute('style', '--line-height-base: 1.5; --line-height-large: 2')
    }
  }, [])

  useEffect(() => {
    const campaign = CampaignFactory.create(type)
    if (!campaign) return
    let component: InstanceType<ThenArg<ReturnType<Campaign['component']>>> | null = null
    void campaign.component().then((Comp) => {
      component = new Comp({
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        target: domRef.current!,
        props: {
          readonly: false,
          imageBaseUrl: getServerUrl('image'),
          campaignModule: createCampaignModule(getServerUrl('api'), 30000, Lang.ZH_CN),
          previewData
        }
      })
    })
    return () => {
      component?.$destroy()
    }
  }, [previewData, type])

  return (
    <div
      className={clsx(classes.root, 'activity-scroll')}
      ref={domRef}
    />
  )
}

export default React.memo(ActivityManagementPreviewPage)

import { ManualProcessStatusType } from '@golden/gdk-admin'
import TKeyType from '../../i18ns/admin/TKeyType'

const manualProcessStatusName: { [key in ManualProcessStatusType]: TKeyType } = {
  [ManualProcessStatusType.PENDING]: 'manualProcessStatus.pending',
  [ManualProcessStatusType.PROCESSING]: 'manualProcessStatus.processing',
  [ManualProcessStatusType.FAILED]: 'manualProcessStatus.failed'
}

export default manualProcessStatusName

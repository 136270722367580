import React, { MouseEvent, useCallback } from 'react'
import IconButton from '@material-ui/core/IconButton'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import useDrawer from '../../../providers/default/drawer/useDrawer'

export interface PropTypes {
  onMenuClick?: (event: MouseEvent<HTMLButtonElement>) => void
}

const AppBarMemuButton: React.FC<PropTypes> = (props) => {
  const { onMenuClick } = props
  const { setOpen } = useDrawer()

  const handleMenuClick = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    setOpen((open) => !open)
    if (onMenuClick !== undefined) onMenuClick(event)
  }, [setOpen, onMenuClick])

  return (
    <IconButton edge="start" color="inherit" onClick={handleMenuClick}>
      <MoreVertIcon />
    </IconButton>
  )
}

export default React.memo(AppBarMemuButton)

import React, { createContext, useMemo, useContext, useCallback, useState, Dispatch, SetStateAction, useEffect } from 'react'
import clsx from 'clsx'
import { BcMath } from '@golden/bcmath'
import { useDebouncedCallback } from 'use-debounce/lib'
import { GAME_ORDER } from '@golden/game-china'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import MuiButton from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import InputLabel from '@material-ui/core/InputLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import CircularProgress from '@material-ui/core/CircularProgress'
import MuiTextField, { TextFieldProps } from '@material-ui/core/TextField'
import { useCommonStyles, useDialogStyles } from '../../../utils/admin/StyleHook'
import useT from '../../../i18ns/admin/useT'
import { InitialFormFunc, useRedirectHandleBack, useGetDataByParams } from '../../../utils/default/ComplexFlowHook'
import { ValueGetter, createDefaultFormState, FormValidation } from '../../../utils/default/FormHook'
import { getValueFromValue, getValueFromChangeEvent, getCashInputProps, getValueFromCheckboxEvent } from '../../../utils/default/FormHelper'
import DropDown, { PropTypes as DropDownProps } from '../../../components/default/form/DropDown'
import FormStateProvider from '../../../components/default/form/FormStateProvider'
import RequiredText from '../../../components/default/form/RequiredText'
import FormField from '../../../components/default/form/FormField'
import NumberInput from '../../../components/default/form/NumberInput'
import allRoute, { Path } from '../../../components/admin/route/route'
import { createGlobalDialogConfig, OpenPayload, GloablDialogConfig } from '../../../utils/default/DialogHelper'
import { EffectiveCashForm, EffectiveCash, WithdrawPointTarget, GameType, BarriersPreviewReq, BarriersPreviewRes, GameCategoryType, ErrorCode, GameStatus } from '@golden/gdk-admin'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { useLocation, useParams } from 'react-router'
import { parsePath } from '../../../utils/default/RouteHelper'
import { usePageFlow } from '../../../utils/default/PageFlowHook'
import LoadingAndErrorFrame from '../../../components/default/frames/LoadingAndErrorFrame'
import { createValidateNotEmpty, createValidateCash, createErrorResult, createCorrectResult } from '../../../utils/default/Validator'
import { formatMoney } from '../../../utils/default/TableHelper'
import CoreDialog from '../../../components/default/dialogs/CoreDialog'
import { endOfDay, getTime, startOfDay } from 'date-fns'
import OnOffCheckbox, { PropTypes as OnOffCheckboxPropTypes } from '../../../components/default/form/OnOffCheckbox'
import EffectiveCashSummaryTable, { PropTypes as EffectiveCashSummaryTableProps, PointCell, NestedCell, sortBuckets } from '../../../components/admin/manual/EffectiveCashSummaryTable'
import DateTime from '../../../components/default/present/DateTime'
import useGDKStore from '../../../providers/admin/gdk/useGDKStore'
import { getGameName } from '../../../utils/default/PlatformHelper'
import withdrawPointTargetName from '../../../constants/admin/withdrawPointTargetName'
import GameIdInput, { PropTypes as GameIdProps } from '../../../components/admin/GameIdInput'
import ScrollablePaper from '../../../components/default/present/ScrollablePaper'
import { makeStyles } from '@material-ui/core'
import { NumberFormatValues } from 'react-number-format'

export interface FormType {
  sign: 1 | -1
  delta: string
  memo: string
  target: WithdrawPointTarget
  target_id: GameType
  is_reset: boolean
}

type MaxLimitType = Array<{ target: WithdrawPointTarget, max: number, target_id: GameType | undefined }>

export const initialForm: InitialFormFunc<FormType> = (defaultForm) => ({
  sign: 1,
  delta: '',
  memo: '',
  target: WithdrawPointTarget.GENERAL,
  target_id: GAME_ORDER[0],
  is_reset: false,
  ...defaultForm
})

const defaultForm = initialForm()

const getValueFromEvent: ValueGetter<FormType> = {
  sign: getValueFromChangeEvent,
  delta: getValueFromChangeEvent,
  memo: getValueFromChangeEvent,
  target: getValueFromChangeEvent,
  target_id: getValueFromValue,
  is_reset: getValueFromCheckboxEvent
}

const memoFormHelperTextProps = { error: true }

const cashInputProps = {
  ...getCashInputProps(),
  decimalScale: 4,
  maxLength: undefined
}

const FormContext = createContext(createDefaultFormState(initialForm()))

const TextField = React.memo(MuiTextField)

const Button = React.memo(MuiButton)

const useStyles = makeStyles((theme) => ({
  text: {
    color: theme.palette.error.main
  }
}))

export const formToRequest = (account: string, form: FormType): EffectiveCashForm => {
  const request: EffectiveCashForm = {
    account,
    value: (Number(form.delta) * Number(form.sign)).toFixed(4),
    memo: form.memo,
    target: form.target,
    is_reset: form.is_reset
  }
  if (form.target === WithdrawPointTarget.GAME) {
    request.target_id = form.target_id
  }
  return request
}

const GameInput: React.FC = React.memo(() => {
  const { t } = useT()
  const { value, handleChange, dispatch } = useContext(FormContext)
  const games = useGDKStore.platform.games()

  useEffect(() => {
    const gameId = games.find(game => game.status !== GameStatus.STOP_SALE)?.id as GameType
    if (value.target === WithdrawPointTarget.GAME && value.target_id !== gameId) {
      dispatch({ type: 'change', label: 'target_id', value: gameId })
    }
  }, [value.target, dispatch])

  if (value.target === WithdrawPointTarget.GAME) {
    return (
      <Grid item xs={12}>
        <FormField<FormType, GameIdProps>
          context={FormContext}
          component={GameIdInput}
          name="target_id"
          label={t('common.gamePlatform')}
          filterNoCategories={[GameCategoryType.LOTTERY]}
          fullWidth
          noAll
          active
          onChange={(e) => {
            handleChange('target_id')(e)
            dispatch({ type: 'change', label: 'delta', value: '' })
          }}
        />
      </Grid>
    )
  }
  return null
})

const CashInput: React.FC<{ limit: MaxLimitType }> = React.memo((props) => {
  const { limit } = props
  const { t } = useT()
  const { value, handleChange, handleOther, error, dispatch } = useContext(FormContext)
  const options = [
    { name: t('common.add'), value: 1 },
    { name: t('common.less'), value: -1 }
  ]
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onDopDownChange = useCallback(handleChange('sign'), [])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onChange = useCallback(handleChange('delta'), [])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onBlur = useCallback(handleOther('blur', 'delta'), [])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onFocus = useCallback(handleOther('focus', 'delta'), [])

  const isAllowed = useCallback(
    ({ floatValue }: NumberFormatValues) => {
      return value.sign === 1
        ? true
        : (floatValue ?? 0) <= (limit.find(item => {
            if (value.target === WithdrawPointTarget.GAME) {
              return item.target_id === value.target_id
            } else {
              return item.target === value.target
            }
          })?.max ?? 0)
    },
    [limit, value.sign, value.target, value.target_id]
  )

  return (
    <Box position="static">
      <Box position="absolute">
        <InputLabel shrink required>{t('common.moveNeededEffectiveCash')}</InputLabel>
      </Box>
      <Box display="flex" alignItems="flex-end">
        <Box display="flex">
          <DropDown
            label=""
            options={options}
            value={value.sign}
            onChange={(e) => {
              onDopDownChange(e)
              dispatch({ type: 'change', label: 'delta', value: '' })
            }}
            error={error.delta !== null}
          />
        </Box>
        <Box display="flex" flexGrow={1}>
          <NumberInput
            fullWidth
            required
            inputProps={{ ...cashInputProps, isAllowed }}
            value={value.delta}
            onChange={(e) => {
              onChange(e)
            }}
            onBlur={onBlur}
            onFocus={onFocus}
            error={error.delta !== null}
          />
        </Box>
      </Box>
      <FormHelperText error>{error.delta ?? ''}</FormHelperText>
    </Box>
  )
})

const SubmitButton: React.FC<{
  account: string
  onBack: () => void
}> = React.memo((props) => {
  const { account, onBack } = props
  const games = useGDKStore.platform.games()
  const gdk = useGDK()
  const commonClasses = useCommonStyles()
  const dialogClasses = useDialogStyles()
  const [loading, setLoading] = useState<boolean>(false)
  const [open, setOpen] = useState<OpenPayload>({ id: '', value: false, isOK: false })
  const [config, setConfig] = useState<GloablDialogConfig>(createGlobalDialogConfig())
  const { t } = useT()
  const { value, error, disableSubmit } = useContext(FormContext)
  const [handleDebouncedSubmit] = useDebouncedCallback(useCallback(() => {
    setConfig(createGlobalDialogConfig({
      showIcon: false,
      notUseTypo: true,
      message: (
        <React.Fragment>
          <Typography className={dialogClasses.text} align="center">{t('dialog.confirmUpdateEffectiveCash')}</Typography>
          <Typography className={clsx(commonClasses.purpleWord, dialogClasses.text)} align="center">
            [{account}] {value.target === WithdrawPointTarget.GAME ? `[${getGameName(value.target_id, games)}]` : `[${t(withdrawPointTargetName[value.target])}]`} [{value.sign > 0 ? t('common.add') : t('common.less')}] [{value.delta}]
          </Typography>
        </React.Fragment>
      )
    }))
    setOpen({ id: 'send', value: true, isOK: false })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, dialogClasses, value, games]), 200)
  const [handleDebouncedOK] = useDebouncedCallback(useCallback(() => {
    if (open.id === 'send') {
      setOpen({ id: 'send', value: false, isOK: true })
      setLoading(true)
      gdk.withdraw.updateEffectiveCash(formToRequest(account, value)).subscribe({
        next: () => {
          setConfig(createGlobalDialogConfig({
            showIcon: false,
            showOK: false,
            notUseTypo: true,
            message: (
              <Box whiteSpace="pre">
                <Typography className={dialogClasses.text} align="center">
                  {t('dialog.updateEffectiveCashSuccess')}
                </Typography>
              </Box>
            ),
            cancelText: t('common.confirm')
          }))
          setOpen({ id: 'success', value: true, isOK: false })
        },
        error: (error) => {
          setLoading(false)
          setConfig(createGlobalDialogConfig({
            showIcon: true,
            variant: 'error',
            message: error.message,
            showCancel: false
          }))
          setOpen({ id: 'error', value: true, isOK: false })
        }
      })
    }
    if (open.id === 'success') {
      setLoading(false)
    }
    if (open.id === 'error') {
      setLoading(false)
      setOpen({ id: 'error', value: false, isOK: true })
    }
  }, [dialogClasses, gdk, open.id, t, value, account]), 200)
  const handleOKWithLoading = () => {
    setLoading(true)
    handleDebouncedOK()
  }
  const [handleDebouncedCancel] = useDebouncedCallback(useCallback(() => {
    if (open.id === 'send') setOpen({ id: 'send', value: false, isOK: false })
    if (open.id === 'success') onBack()
  }, [open, onBack]), 200)
  return (
    <React.Fragment>
      <Button
        className={commonClasses.purpleGradualButton}
        disabled={disableSubmit || Object.values(error).some((item) => item !== null) || loading}
        onClick={handleDebouncedSubmit}
      >
        {loading
          ? (
          <Box display="flex" alignItems="center" justifyContent="center">
            <CircularProgress size={24} />
          </Box>
            )
          : t('common.confirmUpdate')}
      </Button>
      <CoreDialog
        notUseTypo={config.notUseTypo}
        showOK={config.showOK}
        okText={config.okText}
        showCancel={config.showCancel}
        cancelText={config.cancelText}
        showIcon={config.showIcon}
        open={open.value}
        variant={config.variant}
        message={config.message}
        loading={loading}
        onOK={handleOKWithLoading}
        onCancel={handleDebouncedCancel}
        okButtonClasses={{
          root: dialogClasses.okButton
        }}
        cancelButtonClasses={{
          root: open.id === 'success' ? dialogClasses.okButton : dialogClasses.cancelButton
        }}
      />
    </React.Fragment>
  )
})

const PreviewEffectiveCashTable: React.FC<{
  setLimit: Dispatch<SetStateAction<MaxLimitType>>
}> = React.memo((props) => {
  const { setLimit } = props
  const pageFlow = usePageFlow()
  const { t } = useT()
  const { value, dispatch } = useContext(FormContext)
  const gdk = useGDK()
  const params = useParams()
  const games = useGDKStore.platform.games()

  const [previewData, setPreviewData] = useState<BarriersPreviewRes>({
    last_reset_at: new Date(),
    buckets: []
  })

  useGetDataByParams({
    path: Path.MANUAL_EFFECTIVE_CASH_UPDATE,
    gdkFunc: (param: { account: string }) => {
      const request: BarriersPreviewReq = {
        account: param.account,
        value: (Number(value.delta) * Number(value.sign)).toFixed(4),
        target: value.target,
        is_reset: value.is_reset
      }
      if (value.target === WithdrawPointTarget.GAME) {
        request.target_id = value.target_id
      }
      return gdk.withdraw.getBarriersPreview(request)
    },
    gdkFuncDependencies: [gdk, value.delta, value.sign, value.target, value.target_id, value.is_reset],
    onBeforeFetch: pageFlow.setLoadingStart,
    onSuccess (res) {
      setPreviewData(res)
      setLimit(prev => prev.length === 0
        ? res.buckets.map(bucket => ({
          target: bucket.target,
          max: Number(BcMath.base(bucket.barrier_sum).sub(bucket.delayed_withdraw_point).get()),
          target_id: bucket.target_id
        }))
        : prev)
      pageFlow.setContentShow()
    },
    onError (error) {
      if (error.code === ErrorCode.EFFECTIVE_CASH_FAILED) {
        const { account } = params
        const request: BarriersPreviewReq = {
          account,
          value: (Number(value.delta) * Number(value.sign)).toFixed(4),
          target: value.target,
          is_reset: value.is_reset
        }
        if (value.target === WithdrawPointTarget.GAME) {
          request.target_id = value.target_id
        }
        gdk.withdraw.getBarriersPreview(request).subscribe({
          next (res) {
            dispatch({ type: 'change', label: 'delta', value: '' })
            setPreviewData(res)
            setLimit(() => res.buckets.map(bucket => ({
              target: bucket.target,
              max: Number(BcMath.base(bucket.barrier_sum).sub(bucket.delayed_withdraw_point).get()),
              target_id: bucket.target_id
            })))
          },
          error: pageFlow.setGDKError
        })
      } else {
        pageFlow.setGDKError(error)
      }
    }
  })

  const tablePayloads: EffectiveCashSummaryTableProps['tablePayloads'] = useMemo(() => {
    return [
      {
        name: t('common.lastResetTime'),
        width: 110,
        isNested: true,
        value: (<DateTime time={previewData.last_reset_at} />)
      },
      {
        name: t('common.type'),
        width: 80,
        isNested: true,
        value: (<NestedCell info={[t('common.delayEffectiveCash'), t('common.hasReach'), t('common.stillRequire'), t('common.difference'), t('common.adjustableAsNeeded')]} />)
      },
      ...(sortBuckets(previewData.buckets).map((bucket) => {
        return {
          name: bucket.target === WithdrawPointTarget.GAME && bucket.target_id ? getGameName(bucket.target_id, games) : t(withdrawPointTargetName[bucket.target]),
          isNested: true,
          value: (<NestedCell info={[
            (<PointCell point={bucket.delayed_withdraw_point} />),
            (<PointCell point={bucket.withdraw_point} />),
            (<PointCell point={bucket.barrier_sum} />),
            (<PointCell point={BcMath.base(bucket.delayed_withdraw_point).add(bucket.withdraw_point).sub(bucket.barrier_sum).get()} />),
            (<PointCell point={BcMath.base(bucket.barrier_sum).sub(bucket.delayed_withdraw_point).get()} showPlusOnPositive isColored />)
          ]} />)
        }
      }))
    ]
  }, [previewData, games, t])

  return (
    <Box minHeight='280px'>
      <LoadingAndErrorFrame {...pageFlow.status}>
        <EffectiveCashSummaryTable tablePayloads={tablePayloads} />
      </LoadingAndErrorFrame>
    </Box>
  )
})

const ResetActiveTimeCheckbox = React.memo(() => {
  const { t } = useT()
  const { dispatch, handleChange } = useContext(FormContext)

  return (
    <FormField<FormType, OnOffCheckboxPropTypes>
      context={FormContext}
      component={OnOffCheckbox}
      name="is_reset"
      label={t('common.resetActiveTime')}
      onChange={(e) => {
        handleChange('is_reset')(e)
        dispatch({ type: 'change', label: 'delta', value: '' })
      }}
    />
  )
})

const EffectiveCashOptions = React.memo(() => {
  const { t } = useT()
  const { handleChange, dispatch } = useContext(FormContext)

  const targetOptions = useMemo(() => {
    return Object.keys(withdrawPointTargetName).map((key) => ({ name: t(withdrawPointTargetName[key as WithdrawPointTarget]), value: key }))
  }, [t])

  return (
    <FormField<FormType, DropDownProps>
      fullWidth
      options={targetOptions}
      label={t('common.effectiveCashType2')}
      context={FormContext}
      component={DropDown}
      name='target'
        onChange={(e) => {
          handleChange('target')(e)
          dispatch({ type: 'change', label: 'delta', value: '' })
        }}
    />
  )
})

const ManualEffectiveCashUpdatePage: React.FC = () => {
  const [item, setItem] = useState<EffectiveCash>({
    user_id: 0,
    account: '',
    cash: '0.0000',
    buckets: [],
    is_suspended: false
  })
  const pageFlow = usePageFlow()
  const commonClasses = useCommonStyles()
  const classes = useStyles()
  const gdk = useGDK()
  const { t } = useT()

  const [limit, setLimit] = useState<MaxLimitType>([])

  const validation = useMemo(() => {
    return {
      sign: [],
      delta: [
        {
          func: createValidateNotEmpty('delta', t),
          when: ['change', 'beforeClickSubmit']
        },
        {
          func: createValidateCash('delta', t),
          when: ['change', 'beforeClickSubmit']
        },
        {
          func: (value, form) => {
            const bucket = item.buckets.find((b) => {
              if (form.target === WithdrawPointTarget.GAME) return b.target_id === form.target_id
              return b.target === form.target
            })
            if (Number(value) * Number(form.sign) + Number(bucket === undefined ? 0 : bucket.barrier_sum) < 0) {
              return createErrorResult('delta', t('error.mustLessBetCash'))
            }
            if (Number(value) === 0) return createErrorResult('delta', t('error.mustMoreThanZero'))
            return createCorrectResult('delta')
          },
          when: ['change', 'beforeClickSubmit', 'rerender']
        }
      ],
      memo: [
        {
          func: createValidateNotEmpty('memo', t),
          when: ['change', 'beforeClickSubmit']
        }
      ],
      target: [],
      target_id: [],
      is_reset: []
    } as FormValidation<FormType>
  }, [t, item.buckets])
  const location = useLocation()
  const account = useMemo(() => {
    const { param } = parsePath(location.search, location.pathname, Path.MANUAL_EFFECTIVE_CASH_UPDATE)
    return param.account
  }, [location.search, location.pathname])
  useGetDataByParams({
    path: Path.MANUAL_EFFECTIVE_CASH_UPDATE,
    gdkFunc: (param: { account: string }) => gdk.withdraw.getEffectiveCash({ account: param.account }),
    gdkFuncDependencies: [gdk],
    onBeforeFetch: pageFlow.setLoadingStart,
    onSuccess: (res) => {
      setItem(res)
      pageFlow.setContentShow()
    },
    onError: pageFlow.setGDKError
  })
  const [handleBack, handleDebouncedBack] = useRedirectHandleBack({
    path: allRoute.manualEffectiveCashHistory.encodePath({
      search: {
        account,
        start_at: getTime(startOfDay(new Date())),
        end_at: getTime(endOfDay(new Date())),
        page: 1
      },
      param: {}
    })
  })
  const memoInputProps = useMemo(() => ({
    classes: { input: commonClasses.memoField },
    inputProps: {
      placeholder: `${t('placeholder.inputMemo')}*`
    }
  }), [commonClasses.memoField, t])

  return (
    <FormStateProvider
      context={FormContext}
      defaultValue={defaultForm}
      onSubmit={(form) => form}
      getValueFromEvent={getValueFromEvent}
      validation={validation}
    >
      <ScrollablePaper marginX={5}>
        <Box padding={4}>
          <Box
            paddingY={1.25}
            paddingX={2}
            className={commonClasses.pinkTitleBar}
          >
            <Typography variant="h5">
              {t('page.updateEffectiveCash')}
            </Typography>
          </Box>
          <Box paddingY={2}>
            <LoadingAndErrorFrame { ...pageFlow.status } >
              <Grid container direction='row' spacing={5}>
                <Grid item xs={4}>
                  <Grid container direction="column" spacing={3}>
                    <Grid item>
                      <Grid container direction="row" spacing={3}>
                        <Grid item xs={12}>
                          <TextField
                            disabled
                            fullWidth
                            label={t('common.playerAccount')}
                            value={item.account}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <EffectiveCashOptions />
                        </Grid>
                        <GameInput />
                        <Grid item xs={12}>
                          <TextField
                            disabled
                            fullWidth
                            label={t('common.mainAccount')}
                            value={formatMoney(item.cash)}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <ResetActiveTimeCheckbox />
                    </Grid>
                    <Grid item>
                      <Grid container direction="row" spacing={2}>
                        <Grid item xs={12}>
                          <CashInput
                            limit={limit}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <FormField<FormType, TextFieldProps>
                        context={FormContext}
                        component={TextField}
                        name="memo"
                        multiline
                        fullWidth
                        type="text"
                        margin="normal"
                        variant="outlined"
                        InputProps={memoInputProps}
                        FormHelperTextProps={memoFormHelperTextProps}
                      />
                    </Grid>
                    <Grid item>
                      <RequiredText />
                      <Box className={classes.text}>{ t('common.updateEffectiveCashTip') }</Box>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={8}>
                  <PreviewEffectiveCashTable
                    setLimit={setLimit}
                  />
                </Grid>
              </Grid>
            </LoadingAndErrorFrame>
          </Box>
        </Box>
      </ScrollablePaper>
      <Box marginRight={5} marginY={2}>
        <Grid container direction="row" justifyContent="flex-end" spacing={2}>
          <Grid item>
            <Button
              className={commonClasses.greyButton}
              onClick={handleDebouncedBack}
            >
              {t('common.cancel')}
            </Button>
          </Grid>
          <Grid item>
            <SubmitButton
              account={item.account}
              onBack={handleBack}
            />
          </Grid>
        </Grid>
      </Box>
    </FormStateProvider>
  )
}

export default React.memo(ManualEffectiveCashUpdatePage)

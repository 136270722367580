import React, { useContext } from 'react'
import { ButtonProps } from '@material-ui/core/Button'
import { FormState } from '../../../utils/default/FormHook'

interface PropTypes {
  component: React.ElementType
  context: React.Context<FormState<any>>
}

const FormSubmitButton: React.FC<PropTypes & ButtonProps> = (props) => {
  const { component: Component, context, ...other } = props

  const { disableSubmit } = useContext(context)

  return (
    <Component
      {...other}
      disabled={disableSubmit || other.disabled}
    />
  )
}

export default FormSubmitButton

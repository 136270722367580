import { ActivityWalletCreateProcessStatusType } from '@golden/gdk-admin'
import TKeyType from '../../i18ns/admin/TKeyType'

const activityWalletQueuesStatusName: { [key in ActivityWalletCreateProcessStatusType]: TKeyType } = {
  [ActivityWalletCreateProcessStatusType.PENDING]: 'manualProcessStatus.pending',
  [ActivityWalletCreateProcessStatusType.PROCESSING]: 'manualProcessStatus.processing',
  [ActivityWalletCreateProcessStatusType.FAILED]: 'manualProcessStatus.failed'
}

export default activityWalletQueuesStatusName

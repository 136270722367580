import React, { useState, useMemo } from 'react'
import { PaginationRes, OperationLogsRes } from '@golden/gdk-admin'
import Box from '@material-ui/core/Box'
import CoreTable from '../../default/present/CoreTable'
import DateTime from '../../default/present/DateTime'
import LoadingAndErrorFrame from '../../default/frames/LoadingAndErrorFrame'
import ScrollablePaper from '../../default/present/ScrollablePaper'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import useT from '../../../i18ns/admin/useT'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { createDefaultPaginationData, createTableData } from '../../../utils/default/TableHelper'
import { searchToRequest } from './PlayerOperationLogsForm'
import { usePageFlow } from '../../../utils/default/PageFlowHook'
import { useRequestFromSearch, useGetDataByPayload, usePaginationClickAndChangeUrl } from '../../../utils/default/ComplexFlowHook'
import allRoutes from '../route/route'

interface RowType {
  id: number
  player_account: string
  category: string
  method: string
  operation: string
  remark: React.ReactElement | string
  operatedAt: React.ReactElement | string
  operatorAccount: string
}

const PlayerOperationLogsTable: React.FC = () => {
  const classes = useCommonStyles()
  const { t } = useT()
  const gdk = useGDK()
  const [list, setList] = useState<PaginationRes<OperationLogsRes[]>>(createDefaultPaginationData([]))
  const pageFlow = usePageFlow()
  const request = useRequestFromSearch({ searchToRequest })
  useGetDataByPayload({
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    payload: request!,
    gdkFunc: (payload) => gdk.player.getOperationLogs(payload),
    gdkFuncDependencies: [gdk],
    onBeforeFetch: pageFlow.setLoadingStart,
    onSuccess: (res: PaginationRes<OperationLogsRes[]>) => {
      setList(res)
      pageFlow.setContentShow()
    },
    onError: pageFlow.setGDKError,
    canLoadData: request !== undefined
  })
  const tableClasses = useMemo(() => ({
    head: classes.pinkTableHead,
    row: classes.tableRow,
    cellHead: classes.tableCellHead,
    cellBody: classes.pre
  }), [classes])
  const rows: RowType[] = useMemo(() => {
    return list.data.map((item, index) => {
      return {
        id: index,
        player_account: item.player_account,
        category: item.category,
        method: item.method,
        operation: item.operation,
        remark: item.remark,
        operatedAt: (<DateTime time={item.operated_at} />),
        operatorAccount: item.operator_account
      }
    })
  }, [list.data])
  const data = useMemo(() => {
    return createTableData<RowType>(
      {
        id: {
          label: '',
          value: 'id'
        },
        player_account: {
          label: t('common.playerAccount'),
          value: 'player_account'
        },
        category: {
          label: t('common.block'),
          value: 'category'
        },
        method: {
          label: t('common.function'),
          value: 'method'
        },
        operation: {
          label: t('common.operation'),
          value: 'operation'
        },
        remark: {
          label: t('common.memo'),
          value: 'remark'
        },
        operatedAt: {
          label: t('common.updateAt'),
          value: 'operatedAt'
        },
        operatorAccount: {
          label: t('common.updateBy'),
          value: 'operatorAccount'
        }
      },
      [
        'player_account',
        'category',
        'method',
        'operation',
        'remark',
        'operatedAt',
        'operatorAccount'
      ],
      rows,
      'id'
    )
  }, [rows, t])

  const handlePagination = usePaginationClickAndChangeUrl({
    request,
    encodePath: allRoutes.playerOperationLogs.encodePath
  })

  if (request === undefined) return null

  return (
    <ScrollablePaper marginX={5}>
      <Box padding={4}>
        <LoadingAndErrorFrame { ...pageFlow.status }>
          <CoreTable
            classes={tableClasses}
            data={data}
            total={list.total}
            showPagination
            page={request.page}
            onChangePage={handlePagination}
          />
        </LoadingAndErrorFrame>
      </Box>
    </ScrollablePaper>
  )
}

export default React.memo(PlayerOperationLogsTable)

import { useEffect, useCallback, useMemo, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Subscription, Observable, timer, finalize } from 'rxjs'
import { getTime } from 'date-fns'
import { GDKError, GDKFormError, Sort, AdminModule } from '@golden/gdk-admin'
import { useDebouncedCallback } from 'use-debounce'
import { useDialogFlow } from './DialogHook'
import { parsePath } from './RouteHelper'
import { toggleOrder } from './FormHelper'
import { GlobalDialogType, GloablDialogConfig } from './DialogHelper'
import PathOptionType from '../../components/default/route/PathOptionType'
import { PageFlowType } from './PageFlowHook'

export const useGDKFuncHandleClick = <Req, Res>(option: {
  payload: Req
  gdkFunc: (payload: Req) => Observable<Res>
  onSuccess?: (response: Res) => void
  onError?: (error: GDKFormError) => void
  gdkFuncDependencies?: any[]
  onSuccessDependencies?: any[]
  onErrorDependencies?: any[]
}): { handleClick: () => void, loading: boolean } => {
  const {
    payload,
    gdkFunc,
    onSuccess = () => {},
    onError = () => {},
    gdkFuncDependencies = [],
    onSuccessDependencies = [],
    onErrorDependencies = []
  } = option
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedGDKFunc = useCallback(gdkFunc, gdkFuncDependencies)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedOnSuccess = useCallback(onSuccess, onSuccessDependencies)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedOnError = useCallback(onError, onErrorDependencies)
  const ref = useRef<Subscription | null>(null)
  const [loading, setLoading] = useState(false)

  const handleFetchGDK = useCallback(() => {
    if (loading) return
    setLoading(true)

    ref.current = memoizedGDKFunc(payload)
      .pipe(
        finalize(() => { setLoading(false) })
      )
      .subscribe({
        next: (res) => memoizedOnSuccess(res),
        error: (error) => memoizedOnError(error)
      })
  }, [payload, memoizedGDKFunc, memoizedOnSuccess, memoizedOnError, setLoading, loading])

  useEffect(() => () => ref.current?.unsubscribe(), [])

  const handleClick = useCallback(() => {
    handleFetchGDK()
  }, [handleFetchGDK])

  return {
    handleClick,
    loading
  }
}

export const useGDKFuncHandleSubmit = <Form, Req, Res>(option: {
  formToRequest: (form: Form) => Req
  gdkFunc: (payload: Req) => Observable<Res>
  onSuccess?: (response: Res, form: Form) => void
  onError?: (error: GDKFormError, form: Form) => void
  gdkFuncDependencies?: any[]
  onSuccessDependencies?: any[]
  onErrorDependencies?: any[]
}): { handleSubmit: (form: Form) => Form, loading: boolean } => {
  const {
    formToRequest,
    gdkFunc,
    onSuccess = () => {},
    onError = () => {},
    gdkFuncDependencies = [],
    onSuccessDependencies = [],
    onErrorDependencies = []
  } = option
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedGDKFunc = useCallback(gdkFunc, gdkFuncDependencies)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedOnSuccess = useCallback(onSuccess, onSuccessDependencies)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedOnError = useCallback(onError, onErrorDependencies)
  const ref = useRef<Subscription | null>(null)
  const [loading, setLoading] = useState(false)

  const handleFetchGDK = useCallback((form: Form) => {
    if (loading) return
    setLoading(true)

    ref.current = memoizedGDKFunc(formToRequest(form))
      .pipe(
        finalize(() => { setLoading(false) })
      )
      .subscribe({
        next: (res) => memoizedOnSuccess(res, form),
        error: (error) => memoizedOnError(error, form)
      })
  }, [formToRequest, memoizedGDKFunc, memoizedOnSuccess, memoizedOnError, setLoading, loading])

  useEffect(() => () => ref.current?.unsubscribe(), [])

  const handleSubmit = useCallback((form: Form) => {
    handleFetchGDK(form)
    return form
  }, [handleFetchGDK])

  return {
    handleSubmit,
    loading
  }
}

export const useRedirectHandleBack = (option: {
  path: string
}) => {
  const navigate = useNavigate()
  const { path } = option
  const handleBack = useCallback(() => {
    navigate(path)
  }, [navigate, path])
  const [handleDebouncedBack] = useDebouncedCallback(handleBack, 200)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return [handleBack, useCallback(handleDebouncedBack, [handleBack])]
}

export const useDialogHandleClick = <Req, Res>(option: {
  dialogId: string
  globalDialog: GlobalDialogType
  changeDialogConfig: GloablDialogConfig
  successDialogConfig: GloablDialogConfig
  getFailDialogConfig: (error: string, errorObject: GDKFormError) => GloablDialogConfig
  payload: Req
  gdkFunc: (payload: Req) => Observable<Res>
  onSuccess?: (response: Res) => void
  onError?: (error: GDKFormError) => void
  afterSuccessDialog?: (response: Res | null) => void
  gdkFuncDependencies?: any[]
  onSuccessDependencies?: any[]
  onErrorDependencies?: any[]
  afterSuccessDialogDependencies?: any[]
}) => {
  const {
    dialogId,
    globalDialog,
    changeDialogConfig,
    successDialogConfig,
    getFailDialogConfig,
    payload,
    gdkFunc,
    onSuccess = () => {},
    onError = () => {},
    afterSuccessDialog = () => {},
    gdkFuncDependencies = [],
    onSuccessDependencies = [],
    onErrorDependencies = [],
    afterSuccessDialogDependencies = []
  } = option
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedGDKFunc = useCallback(gdkFunc, gdkFuncDependencies)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedOnSuccess = useCallback(onSuccess, onSuccessDependencies)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedOnError = useCallback(onError, onErrorDependencies)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedAfterSuccessDialog = useCallback(afterSuccessDialog, afterSuccessDialogDependencies)
  const [loading, setLoading] = useState(false)

  const [handleClick] = useDebouncedCallback(useCallback(() => {
    globalDialog.setConfig(changeDialogConfig)
    globalDialog.setOpen({ id: dialogId, value: true, isOK: false })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changeDialogConfig]), 200)

  const [res, setRes] = useState<Res | null>(null)

  useDialogFlow(globalDialog, dialogId, () => {
    if (loading) return
    setLoading(true)

    const subscription = memoizedGDKFunc(payload)
      .pipe(
        finalize(() => setLoading(false))
      )
      .subscribe({
        next: (res) => {
          globalDialog.clearState()
          globalDialog.setConfig(successDialogConfig)
          globalDialog.setOpen({ id: `${dialogId}Success`, value: true, isOK: false })
          setRes(res)
          memoizedOnSuccess(res)
        },
        error: (error) => {
          globalDialog.clearState()
          globalDialog.setConfig(getFailDialogConfig(error.message, error))
          globalDialog.setOpen({ id: `${dialogId}Fail`, value: true, isOK: false })
          memoizedOnError(error)
        }
      })
    return () => {
      if ([`${dialogId}Success`, `${dialogId}Fail`].includes(globalDialog.open.id)) {
        subscription.unsubscribe()
      }
    }
  }, [
    loading,
    dialogId,
    globalDialog.open.id,
    payload,
    memoizedGDKFunc,
    memoizedOnSuccess,
    memoizedOnError
  ])

  useDialogFlow(globalDialog, `${dialogId}Success`, () => {
    globalDialog.clearState()
    memoizedAfterSuccessDialog(res)
  }, [memoizedAfterSuccessDialog])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useCallback(handleClick, [])
}

export const useDialogHandleSubmit = <Form, Req, Res>(option: {
  dialogId: string
  globalDialog: GlobalDialogType
  getChangeDialogConfig: (form: Form) => GloablDialogConfig
  getSuccessDialogConfig: (form: Form, res: Res) => GloablDialogConfig
  getFailDialogConfig: (error: string, errorObject: GDKFormError) => GloablDialogConfig
  formToRequest: (form: Form) => Req
  gdkFunc: (payload: Req) => Observable<Res>
  onSuccess?: (response: Res) => void
  onError?: (error: GDKFormError) => void
  afterSuccessDialog?: (response: Res | null) => void
  gdkFuncDependencies?: any[]
  onSuccessDependencies?: any[]
  onErrorDependencies?: any[]
  afterSuccessDialogDependencies?: any[]
}): { handleSubmit: (form: Form) => Form, loading: boolean } => {
  const {
    dialogId,
    globalDialog,
    getChangeDialogConfig,
    getSuccessDialogConfig,
    getFailDialogConfig,
    formToRequest,
    gdkFunc,
    onSuccess = () => {},
    onError = () => {},
    afterSuccessDialog = () => {},
    gdkFuncDependencies = [],
    onSuccessDependencies = [],
    onErrorDependencies = [],
    afterSuccessDialogDependencies = []
  } = option
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedGDKFunc = useCallback(gdkFunc, gdkFuncDependencies)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedOnSuccess = useCallback(onSuccess, onSuccessDependencies)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedOnError = useCallback(onError, onErrorDependencies)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedAfterSuccessDialog = useCallback(afterSuccessDialog, afterSuccessDialogDependencies)

  const [form, setForm] = useState<Form | null>(null)
  const [res, setRes] = useState<Res | null>(null)
  const [loading, setLoading] = useState(false)

  const [handleDebouncedSubmit] = useDebouncedCallback(useCallback((form: Form) => {
    globalDialog.setConfig(getChangeDialogConfig(form))
    globalDialog.setOpen({ id: dialogId, value: true, isOK: false })
    setForm(form)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getChangeDialogConfig]), 200)

  useDialogFlow(globalDialog, dialogId, () => {
    if (form === null || loading) return () => {}
    setLoading(true)

    const subscription = memoizedGDKFunc(formToRequest(form))
      .pipe(
        finalize(() => setLoading(false))
      )
      .subscribe({
        next: (res) => {
          globalDialog.clearState()
          globalDialog.setConfig(getSuccessDialogConfig(form, res))
          globalDialog.setOpen({ id: `${dialogId}Success`, value: true, isOK: false })
          setRes(res)
          memoizedOnSuccess(res)
        },
        error: (error: GDKFormError) => {
          globalDialog.clearState()
          globalDialog.setConfig(getFailDialogConfig(error.message, error))
          globalDialog.setOpen({ id: `${dialogId}Fail`, value: true, isOK: false })
          memoizedOnError(error)
        }
      })

    globalDialog.clearState()
    return () => {
      if ([`${dialogId}Success`, `${dialogId}Fail`].includes(globalDialog.open.id)) {
        subscription.unsubscribe()
      }
    }
  }, [
    loading,
    dialogId,
    globalDialog.open,
    formToRequest,
    form,
    memoizedGDKFunc,
    memoizedOnSuccess,
    memoizedOnError
  ])

  useDialogFlow(globalDialog, `${dialogId}Success`, () => {
    globalDialog.clearState()
    memoizedAfterSuccessDialog(res)
  }, [memoizedAfterSuccessDialog])

  const handleSubmit = useCallback((form: Form) => {
    handleDebouncedSubmit(form)
    return form
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleDebouncedSubmit])
  return { handleSubmit, loading }
}

export const useGetData = <Res>(option: {
  canLoadData?: boolean
  gdkFunc: () => Observable<Res>
  onBeforeFetch?: () => void
  onSuccess: (response: Res) => void
  onError?: (error: GDKError | GDKFormError) => void
  gdkFuncDependencies?: any[]
  onBeforeFetchDependencies?: any[]
  onSuccessDependencies?: any[]
  onErrorDependencies?: any[]
}) => {
  const {
    canLoadData = true,
    gdkFunc,
    onBeforeFetch = () => {},
    onSuccess,
    onError = () => {},
    gdkFuncDependencies = [],
    onBeforeFetchDependencies = [],
    onSuccessDependencies = [],
    onErrorDependencies = []
  } = option
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedGDKFunc = useCallback(gdkFunc, gdkFuncDependencies)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedOnBeforeFetch = useCallback(onBeforeFetch, onBeforeFetchDependencies)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedOnSuccess = useCallback(onSuccess, onSuccessDependencies)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedOnError = useCallback(onError, onErrorDependencies)
  useEffect(() => {
    if (canLoadData) {
      memoizedOnBeforeFetch()
      const subscription = memoizedGDKFunc().subscribe({ next: memoizedOnSuccess, error: memoizedOnError })
      return () => subscription.unsubscribe()
    }
  }, [canLoadData, memoizedGDKFunc, memoizedOnBeforeFetch, memoizedOnSuccess, memoizedOnError])
}

export const useGetDataByParams = <Params, Res>(option: {
  path: string
  canLoadData?: boolean
  gdkFunc: (payload: Params) => Observable<Res>
  onBeforeFetch?: () => void
  onSuccess: (response: Res) => void
  onError?: (error: GDKError | GDKFormError) => void
  gdkFuncDependencies?: any[]
  onBeforeFetchDependencies?: any[]
  onSuccessDependencies?: any[]
  onErrorDependencies?: any[]
}) => {
  const {
    path,
    canLoadData = true,
    gdkFunc,
    onBeforeFetch = () => {},
    onSuccess,
    onError = () => {},
    gdkFuncDependencies = [],
    onBeforeFetchDependencies = [],
    onSuccessDependencies = [],
    onErrorDependencies = []
  } = option
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedGDKFunc = useCallback(gdkFunc, gdkFuncDependencies)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedOnBeforeFetch = useCallback(onBeforeFetch, onBeforeFetchDependencies)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedOnSuccess = useCallback(onSuccess, onSuccessDependencies)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedOnError = useCallback(onError, onErrorDependencies)
  const location = useLocation()
  useEffect(() => {
    if (canLoadData) {
      const param = parsePath(location.search, location.pathname, path).param as Params
      try {
        memoizedOnBeforeFetch()
        const subscription = memoizedGDKFunc(param).subscribe({ next: memoizedOnSuccess, error: memoizedOnError })
        return () => subscription.unsubscribe()
      } catch {}
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    location.pathname,
    location.search,
    path,
    canLoadData,
    memoizedGDKFunc,
    memoizedOnBeforeFetch,
    memoizedOnSuccess,
    memoizedOnError
  ])
}

export const useGetDataByPayload = <Req, Res>(option: {
  payload: Req
  canLoadData?: boolean
  gdkFunc: (payload: Req) => Observable<Res>
  onBeforeFetch?: () => void
  onSuccess: (response: Res) => void
  onError?: (error: GDKError | GDKFormError) => void
  gdkFuncDependencies?: any[]
  onBeforeFetchDependencies?: any[]
  onSuccessDependencies?: any[]
  onErrorDependencies?: any[]
}) => {
  const {
    payload,
    canLoadData = true,
    gdkFunc,
    onBeforeFetch = () => {},
    onSuccess,
    onError = () => {},
    gdkFuncDependencies = [],
    onBeforeFetchDependencies = [],
    onSuccessDependencies = [],
    onErrorDependencies = []
  } = option
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedGDKFunc = useCallback(gdkFunc, gdkFuncDependencies)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedOnBeforeFetch = useCallback(onBeforeFetch, onBeforeFetchDependencies)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedOnSuccess = useCallback(onSuccess, onSuccessDependencies)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedOnError = useCallback(onError, onErrorDependencies)
  useEffect(() => {
    if (canLoadData) {
      memoizedOnBeforeFetch()
      const subscription = memoizedGDKFunc(payload).subscribe({ next: memoizedOnSuccess, error: memoizedOnError })
      return () => subscription.unsubscribe()
    }
  }, [payload, canLoadData, memoizedGDKFunc, memoizedOnBeforeFetch, memoizedOnSuccess, memoizedOnError])
}

export type UrlSearchType<FormType> = Partial<{
  [key in keyof FormType]: string
}>

export type InitialFormFunc<FormType> = (defaultForm?: Partial<FormType>) => FormType

export type SearchToRequestFunc<Request> = (search: UrlSearchType<Request>) => Request | never

export const useRequestFromSearch = <Request>(option: {
  searchToRequest: SearchToRequestFunc<Request>
}): Request | undefined => {
  const { searchToRequest } = option
  const location = useLocation()
  const defaultForm = useMemo(() => {
    const search = parsePath(location.search, location.pathname).search as UrlSearchType<Request>
    try {
      return searchToRequest(search)
    } catch (e) { console.log(e) }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search, location.pathname, searchToRequest])
  return defaultForm
}

export const useChangeUrlSubmit = <Form, Req, Param = {}>(option: {
  formToRequest: (form: Form) => Req
  param?: Param
  encodePath: (option: PathOptionType<any, any>) => string
  toAddNowTimestamp?: boolean
}): (form: Form) => Form => {
  const { formToRequest, param, encodePath, toAddNowTimestamp } = option
  const navigate = useNavigate()
  const [handleDebouncedSubmit] = useDebouncedCallback(useCallback((form: Form) => {
    const search = toAddNowTimestamp ? Object.assign({}, formToRequest(form), { now_timestamp: getTime(new Date()) }) : formToRequest(form)
    navigate(encodePath({ search, param }))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate, encodePath, formToRequest, toAddNowTimestamp]), 200)
  const handleSubmit = useCallback((form: Form) => {
    handleDebouncedSubmit(form)
    return form
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleDebouncedSubmit])
  return handleSubmit
}

export const useSortClickAndChangeUrl = <Req extends Sort>(option: {
  request: Req | undefined
  encodePath: (option: PathOptionType<any, any>) => string
}) => {
  const navigate = useNavigate()
  const { request, encodePath } = option

  const [handleClick] = useDebouncedCallback(useCallback((_: any, column: string) => {
    if (request === undefined) {
      navigate(encodePath({ search: { sort_by: column, order: 'desc' }, param: {} }))
    } else {
      const order = toggleOrder({ sort_by: request.sort_by, order: request.order }, column)
      navigate(encodePath({ search: { ...request, sort_by: column, order }, param: {} }))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate, request, encodePath]), 200)

  return useCallback((event, column) => handleClick(event, column), [handleClick])
}

export const usePaginationClickAndChangeUrl = <Req, Param = {}>(option: {
  param?: Param
  request: Req | undefined
  encodePath: (option: PathOptionType<any, any>) => string
  pageFlow?: PageFlowType
  pageKey?: string
}) => {
  const navigate = useNavigate()
  const { request, encodePath, param, pageFlow, pageKey = 'page' } = option

  const [handleClick] = useDebouncedCallback(useCallback((_: any, page: number) => {
    if (pageFlow) pageFlow.setLoadingStart()
    navigate(encodePath({ search: { ...request, [pageKey]: page }, param }))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate, request, param, encodePath, pageKey]), 200)

  return useCallback((event, page) => handleClick(event, page), [handleClick])
}

export const usePerPageChange = <Req, Param = {}>(option: {
  gdk: AdminModule
  pageKey: string
  reload?: () => void
  encodePath?: (option: PathOptionType<any, any>) => string
  param?: Param
  request?: Req | undefined
  pageFlow?: PageFlowType
}) => {
  const { gdk, pageKey, reload, encodePath, request, param, pageFlow } = option
  const navigate = useNavigate()
  const handleAdminPerPageChange = useCallback((event) => {
    (gdk).admin.setPerPage({ page_key: pageKey, per_page: event.target.value })
      .subscribe({
        next: () => {
          if (reload) {
            reload()
          } else if (encodePath) {
            if (pageFlow) pageFlow.setLoadingStart()
            navigate(encodePath({ search: { ...request, page: 1, now_timestamp: getTime(new Date()) }, param }))
          }
        }
      })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gdk, reload, navigate, request, param, encodePath])
  const handleOtherPerPageChange = useCallback(() => {}, [])

  if ((gdk).admin) {
    return handleAdminPerPageChange
  } else {
    return handleOtherPerPageChange
  }
}

export const useRealTimeReload = (option: {
  isTurnedOn: boolean
  reload: () => void
}) => {
  const { isTurnedOn, reload } = option
  useEffect(() => {
    if (isTurnedOn) {
      const source = timer(0, 5000)
      const subscription = source.subscribe(() => reload())
      return () => subscription.unsubscribe()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTurnedOn])
}

export const useReload = () => {
  const [reloadFlag, setReloadFlag] = useState<boolean>(false)
  const reload = useCallback(() => setReloadFlag((reloadFlag) => !reloadFlag), [])
  return { reloadFlag, reload }
}

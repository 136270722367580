import React, { createContext, useContext, useMemo, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { getTime, startOfYear, startOfDay, endOfDay, subYears } from 'date-fns'
import MuiButton from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { ActivityType } from '@golden/gdk-admin'
import { SearchToRequestFunc, InitialFormFunc, useRequestFromSearch, useChangeUrlSubmit } from '../../../utils/default/ComplexFlowHook'
import { guaranteeNotUndefined, parseInt, guaranteeBetween, guaranteeBeOneOf, getTimeFromDateInputValue, getValueFromChangeEvent, pipe, convertEmptyToUndefined } from '../../../utils/default/FormHelper'
import DateInputBase, { DateInputValue } from '../../default/form/DateInput'
import { ValueGetter, ChangedFormGetter, createDefaultFormState, FormValidation } from '../../../utils/default/FormHook'
import useT from '../../../i18ns/admin/useT'
import DropDown from '../../default/form/DropDown'
import { setToday, setYesterday, setThisMonth, setThisWeek, setLastMonth, setLastWeek, createShouldDisableDate } from '../../../utils/default/TimeHelper'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import FormStateProvider from '../../default/form/FormStateProvider'
import useGDKStore from '../../../providers/admin/gdk/useGDKStore'
import allRoute, { Path } from '../route/route'
import RequiredText from '../../default/form/RequiredText'
import FormSubmitButton from '../../default/form/FormSubmitButton'
import FormField from '../../default/form/FormField'
import MuiTextField, { TextFieldProps } from '@material-ui/core/TextField'
import { parsePath } from '../../../utils/default/RouteHelper'
import { isUndefined, omitBy } from '@golden/utils'

export interface ActivityRecordAgentLayerRequest {
  start_at: number
  end_at: number
  account: string
  agent_account: string
  activity_type: string
  former_page_type?: ActivityType
  page: number
}

export interface ActivityRecordAgentLayerFormType {
  time: DateInputValue
  activity_type: ActivityType | 'all'
  account: string
  agent_account: string
}

export const initialForm: InitialFormFunc<ActivityRecordAgentLayerFormType> = (defaultForm) => ({
  time: {
    start: startOfDay(new Date()),
    end: endOfDay(new Date())
  },
  activity_type: 'all',
  account: '',
  agent_account: '',
  page: 1,
  ...defaultForm
})

const getValueFromEvent: ValueGetter<ActivityRecordAgentLayerFormType> = {
  time: getTimeFromDateInputValue,
  activity_type: getValueFromChangeEvent,
  account: getValueFromChangeEvent,
  agent_account: getValueFromChangeEvent
}

const getChangedForm: ChangedFormGetter<ActivityRecordAgentLayerFormType> = {
  activity_type: (value, form) => ({
    ...form,
    activity_type: value,
    account: '',
    agent_account: ''
  })
}

const formToRequest = (form: ActivityRecordAgentLayerFormType): ActivityRecordAgentLayerRequest => {
  const converted = {
    activity_type: form.activity_type === 'all' ? undefined : form.activity_type,
    start_at: form.time.start === null ? undefined : getTime(form.time.start),
    end_at: form.time.end === null ? undefined : getTime(form.time.end),
    account: convertEmptyToUndefined(form.account),
    agent_account: convertEmptyToUndefined(form.agent_account),
    page: 1
  } as ActivityRecordAgentLayerRequest
  const omited = omitBy(converted, isUndefined)

  return omited as ActivityRecordAgentLayerRequest
}

export const searchToRequest: SearchToRequestFunc<{
  start_at: number
  end_at: number
  activity_type: ActivityType | undefined
  account: string | undefined
  agent_account: string | undefined
  former_page_type?: ActivityType
  page: number
}> = (search) => {
  const fiveYearsAgo = getTime(startOfYear(subYears(new Date(), 5)))
  const endOfToday = getTime(endOfDay(new Date()))
  const converted = {
    ...search,
    activity_type: search.activity_type
      ? pipe(
        (value) => guaranteeNotUndefined(value as string),
        (value) => guaranteeBeOneOf(value, Object.values(ActivityType))
      )(search.activity_type)
      : undefined,
    start_at: pipe(
      (value) => guaranteeNotUndefined(value as string),
      parseInt,
      (value) => guaranteeBetween(value, fiveYearsAgo, endOfToday)
    )(search.start_at),
    end_at: pipe(
      (value) => guaranteeNotUndefined(value as string),
      parseInt,
      (value) => guaranteeBetween(value, fiveYearsAgo, endOfToday)
    )(search.end_at),
    former_page_type: search.former_page_type
      ? pipe(
        (value) => guaranteeNotUndefined(value as string),
        (value) => guaranteeBeOneOf(value, Object.values(ActivityType))
      )(search.former_page_type)
      : undefined,
    account: search.account,
    agent_account: search.agent_account,
    page: pipe(
      guaranteeNotUndefined,
      parseInt,
      (value) => guaranteeBetween(value, 1, Number.MAX_SAFE_INTEGER)
    )(search.page)
  }
  return omitBy(converted, isUndefined) as any
}

const FormContext = createContext(createDefaultFormState(initialForm()))

const Button = React.memo(MuiButton)

const DateInput: React.FC = React.memo(() => {
  const classes = useCommonStyles()
  const { t } = useT()
  const { value, handleChange } = useContext(FormContext)
  const {
    shouldDisableStartDate,
    shouldDisableEndDate
  } = useMemo(() => {
    const fiveYearsAgo = startOfYear(subYears(new Date(), 5))
    return createShouldDisableDate(value.time.start, value.time.end, fiveYearsAgo)
  }, [value.time])
  const tools = useMemo(() => {
    return [
      {
        label: t('common.today'),
        change: setToday
      },
      {
        label: t('common.yesterday'),
        change: setYesterday
      },
      {
        label: t('common.thisWeek'),
        change: setThisWeek
      },
      {
        label: t('common.lastWeek'),
        change: setLastWeek
      },
      {
        label: t('common.thisMonth'),
        change: setThisMonth
      },
      {
        label: t('common.lastMonth'),
        change: setLastMonth
      }
    ]
  }, [t])
  const startOption = useMemo(() => ({
    label: t('common.bonusSentStartTime'),
    shouldDisableDate: shouldDisableStartDate
  }), [shouldDisableStartDate, t])

  const endOption = useMemo(() => ({
    label: t('common.bonusSentEndTime'),
    shouldDisableDate: shouldDisableEndDate
  }), [shouldDisableEndDate, t])

  const dateClasses = useMemo(() => ({
    button: classes.pinkGradualButton
  }), [classes.pinkGradualButton])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onChange = useCallback(handleChange('time'), [])

  return (
    <DateInputBase
      value={value.time}
      onChange={onChange}
      start={startOption}
      end={endOption}
      tools={tools}
      classes={dateClasses}
    />
  )
})

const ActivityInput: React.FC = React.memo(() => {
  const { t } = useT()
  const activities = useGDKStore.activity.activities()
  const { value, handleChange } = useContext(FormContext)
  const options = useMemo(() => {
    const options = activities.map((item) => ({ name: item.name, value: item.type }))
    return [{ name: t('common.all'), value: 'all' }].concat(options)
  }, [activities, t])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onChange = useCallback(handleChange('activity_type'), [])
  return (
    <DropDown
      value={value.activity_type}
      onChange={onChange}
      label={t('common.activityType')}
      options={options}
      fullWidth
    />
  )
})
const TextField = React.memo(MuiTextField)

const OtherQuery: React.FC = React.memo(() => {
  const { t } = useT()
  return (
    <React.Fragment>
      <Grid item xs={12} md={6} lg={3}>
        <FormField<ActivityRecordAgentLayerFormType, TextFieldProps>
          context={FormContext}
          component={TextField}
          name="account"
          label={t('common.playerAccount')}
          placeholder={t('placeholder.inputPlayerAccount')}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} md={6} lg={3}>
        <FormField<ActivityRecordAgentLayerFormType, TextFieldProps>
          context={FormContext}
          component={TextField}
          name="agent_account"
          label={t('common.agentAccount')}
          placeholder={t('placeholder.inputAgentAccount')}
          fullWidth
        />
      </Grid>
    </React.Fragment>
  )
})

const ActivityRecordAgentLayerForm: React.FC = () => {
  const classes = useCommonStyles()
  const { t } = useT()
  const navigate = useNavigate()
  const request = useRequestFromSearch({ searchToRequest })
  const defaultForm = useMemo(() => {
    if (request === undefined) {
      return initialForm()
    }
    return initialForm({
      ...request,
      time: {
        start: new Date(request.start_at),
        end: new Date(request.end_at)
      }
    })
  }, [request])

  // eslint-disable-next-line @typescript-eslint/naming-convention
  const agent_id = useMemo(() => {
    const { param } = parsePath(window.location.search, window.location.pathname, Path.ACTIVITY_RECORD_AGENT_SUB)
    return param.id
  }, [])

  const handleSubmit = useChangeUrlSubmit({
    toAddNowTimestamp: true,
    param: { id: agent_id },
    formToRequest,
    encodePath: allRoute.activityRecordAgentSub.encodePath
  })

  const handleGoBack = useCallback(() => {
    const redirectTo = allRoute.activityRecord.encodePath(
      {
        param: {},
        search: omitBy({
          activity_type: request?.former_page_type,
          account: request?.account,
          agent_account: request?.agent_account,
          start_at: request?.start_at,
          end_at: request?.end_at
        }, isUndefined)
      }
    )
    navigate(redirectTo)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate, request])

  const validation = useMemo(() => ({
    time: [],
    activity_type: [],
    account: [],
    agent_account: []
  } as FormValidation<ActivityRecordAgentLayerFormType>), [])

  return (
    <FormStateProvider
      context={FormContext}
      defaultValue={defaultForm}
      onSubmit={handleSubmit}
      validation={validation}
      getValueFromEvent={getValueFromEvent}
      getChangedForm={getChangedForm}
    >
      <Paper>
        <Box padding={4}>
          <Grid container direction="column" spacing={2}>
            <Grid container justify="flex-start">
              <Grid item>
                <Button
                  className={classes.purpleGradualButton}
                  onClick={handleGoBack}
                >
                  {t('common.back')}
                </Button>
              </Grid>
            </Grid>
            <Grid item>
              <Box
                paddingY={1.25}
                paddingX={2}
                className={classes.pinkTitleBar}
              >
                <Typography variant="h5">
                  {t('common.statisticByAgent')}
                </Typography>
              </Box>
            </Grid>
            <Grid item>
              <DateInput />
            </Grid>
            <Grid item>
              <Grid container direction="row" wrap="wrap" spacing={2}>
                <Grid item xs={12} md={6} lg={3}>
                  <ActivityInput />
                </Grid>
                <OtherQuery />
              </Grid>
            </Grid>
            <Grid item>
              <RequiredText />
            </Grid>
            <Grid item>
              <Grid container justify="flex-end">
                <Grid item>
                  <FormSubmitButton
                    component={Button}
                    context={FormContext}
                    type="submit"
                    className={classes.purpleGradualButton}
                  >
                    {t('common.search')}
                  </FormSubmitButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </FormStateProvider>
  )
}

export default React.memo(ActivityRecordAgentLayerForm)

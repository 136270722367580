import { DepositAndWithdrawType } from '@golden/gdk-admin'
import TKeyType from '../../i18ns/admin/TKeyType'

const depositTypeName: { [key in DepositAndWithdrawType]: TKeyType } = {
  [DepositAndWithdrawType.SELF]: 'common.selfDeposit',
  [DepositAndWithdrawType.THIRD_PARTY]: 'common.thirdDeposit',
  [DepositAndWithdrawType.MANUAL]: 'page.manualDeposit',
  [DepositAndWithdrawType.AGENT_TRANSFER_DEPOSIT]: 'agentWalletTransferType.deposit',
  [DepositAndWithdrawType.AGENT_WALLET]: 'withdraw.agent_transfer_withdraw'
}

export default depositTypeName

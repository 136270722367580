import React, { useState, useCallback } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useDebouncedCallback } from 'use-debounce'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import MuiButton from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import { CryptoWallets } from '@golden/gdk-admin'
import { useCommonStyles, useDialogStyles } from '../../../utils/admin/StyleHook'
import useT from '../../../i18ns/admin/useT'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { createGlobalDialogConfig } from '../../../utils/default/DialogHelper'
import useGlobalDialog from '../../../providers/admin/dialog/useGlobalDialog'

const useStyles = makeStyles((theme) => ({
  actions: {
    paddingBottom: theme.spacing(4)
  },
  title: {
    fontSize: 16,
    marginBottom: theme.spacing(1),
    fontWeight: 600,
    color: theme.palette.common.black
  }
}))

const Button = React.memo(MuiButton)

interface PropTypes {
  id: number
  wallet: CryptoWallets
  reload: () => void
}

const CryptoWalletDeleteButton: React.FC<PropTypes> = (props) => {
  const { id, wallet, reload } = props
  const [open, setOpen] = useState<boolean>(false)
  const classes = useStyles()
  const commonClasses = useCommonStyles()
  const dialogClasses = useDialogStyles()
  const { t } = useT()
  const gdk = useGDK()
  const globalDialog = useGlobalDialog()
  const [handleDebouncedOK] = useDebouncedCallback(useCallback(() => {
    setOpen(false)
    gdk.player.deletePlayerCryptoWallet(id, wallet.id)
      .subscribe({
        next: () => {
          globalDialog.setConfig(createGlobalDialogConfig({
            showIcon: false,
            showCancel: false,
            message: t('dialog.deleteCryptoWalletSuccess')
          }))
          globalDialog.setOpen({
            id: `deleteCryptoWalletSuccess-${wallet.id}`,
            value: true,
            isOK: false
          })
          reload()
        },
        error: (error) => {
          globalDialog.setConfig(createGlobalDialogConfig({
            showIcon: true,
            variant: 'error',
            showCancel: false,
            message: error.message
          }))
          globalDialog.setOpen({
            id: `deleteCryptoWalletFail-${wallet.id}`,
            value: true,
            isOK: false
          })
        }
      })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gdk, id, wallet.id]), 200)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onClick = useCallback(handleDebouncedOK, [])
  return (
    <React.Fragment>
      <Button
        onClick={useCallback(() => setOpen(true), [])}
        className={commonClasses.pinkGradualOutlineButton}
      >
        {t('common.delete')}
      </Button>
      <Dialog open={open} fullWidth maxWidth="sm">
        <Box padding={2} className={commonClasses.dialogPinkHeader}>
          { t('common.deleteItem') }
        </Box>
        <DialogContent>
          <Box padding={3}>
            <Typography align="center" className={classes.title}>
              { t('common.confirmDelete') }
            </Typography>
            <Typography align="center" className={dialogClasses.text}>
              {`${wallet.protocol_name}：${wallet.address}`}
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions className={classes.actions}>
          <Grid container direction="row" spacing={2} justify="center">
            <Grid item>
              <Button
                className={commonClasses.greyButton}
                onClick={useCallback(() => setOpen(false), [])}
              >
                {t('common.cancel')}
              </Button>
            </Grid>
            <Grid item>
              <Button
                className={commonClasses.purpleGradualButton}
                onClick={onClick}
              >
                {t('common.confirm')}
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}

export default React.memo(CryptoWalletDeleteButton)

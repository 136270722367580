import React, { MouseEvent } from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import TableFooter from '@material-ui/core/TableFooter'
import TablePagination from '@material-ui/core/TablePagination'
import TablePaignationActions from './TablePaginationActions'
import { getPerPage } from '../../../utils/default/TableHelper'
import useT from '../../../i18ns/admin/useT'

export type Align = 'inherit' | 'left' | 'center' | 'right' | 'justify'

export type Order = 'asc' | 'desc'

export interface Column<T = string> {
  label: string | React.ReactElement
  value: T
  align?: Align
  columnAlign?: Align
  width?: number
  canSort?: boolean
  groupItems?: Array<Column<T>>
}

export interface Row<T = string> {
  key: string | number
  items: Map<T, number | string | React.ReactElement | undefined>
}

export interface PropTypes {
  data: {
    columns: Column[]
    rows: Row[]
  }
  total: number
  noScroll?: boolean
  sorted?: { column: string, order: Order }
  onSort?: (event: MouseEvent<HTMLAnchorElement>, column: string) => void
  showPagination?: boolean
  perPage?: number
  page?: number
  onChangePage?: (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => void
  classes?: {
    head?: string
    body?: string
    cellHead?: string
    cellBody?: string
    cellFooter?: string
    row?: string
    rowSelect?: string
  }
  dense?: boolean
  isSelected?: boolean
  selected?: any[]
  collapse?: React.ElementType
  tableAppend?: JSX.Element
  tableFooterMemo?: React.ElementType
  separateGroup?: boolean
  loading?: boolean
  onChangeRowsPerPage?: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>
  hasHeadWithNoData?: boolean
  showNoData?: boolean
}

const useStyles = makeStyles((theme) => ({
  fourPadding: {
    padding: theme.spacing(1)
  },
  upPadding: {
    paddingTop: theme.spacing(1),
    paddingBottom: 0
  },
  downPadding: {
    paddingTop: 0,
    paddingBottom: theme.spacing(1)
  },
  noPadding: {
    padding: 0
  },
  noBorder: {
    border: 'none'
  },
  separateGroup: {
    borderLeft: '1px solid rgba(233, 233, 233, 1)'
  },
  head: {
    borderTop: '1px solid rgba(233, 233, 233, 1)'
  },
  cellHead: {
    whiteSpace: 'nowrap',
    color: theme.palette.text.primary
  },
  cellBody: {
    color: theme.palette.text.secondary
  },
  cellFooter: {
    borderBottom: 'none'
  },
  paginationSelect: {
    display: 'none'
  },
  rowSelect: {
    backgroundColor: 'rgb(251, 150, 120, 0.6) !important'
  },
  shadow: {
    background: 'linear-gradient(to left, white 1%, rgba(255,255,255,0)), linear-gradient(to left, rgba(255,255,255,0), white 99%) 0 100%, radial-gradient(farthest-side at 100% 50%, rgba(0,0,0,.2), rgba(0,0,0,0)) 0 50%, radial-gradient(farthest-side at 0% 50%, rgba(0,0,0,.2), rgba(0,0,0,0)) 0 100%',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right, left, right, left',
    backgroundSize: '100px 100%, 100px 100%, 14px 100%, 14px 100%',
    backgroundAttachment: 'local, local, scroll, scroll'
  }
}))

const CoreTableHead: React.FC<PropTypes> = (props) => {
  const {
    data,
    sorted, onSort,
    separateGroup
  } = props
  const classes = useStyles()
  const propsClasses = props.classes ?? {}
  const { columns } = data
  const hasGroup = columns.some((item) => item.groupItems !== undefined)
  const firstsInGroup = columns.map((item) => item.groupItems !== undefined ? item.groupItems[0] || item : item)
  return (
    <TableHead className={clsx(classes.head, propsClasses.head)}>
      {hasGroup && (
        <TableRow>
          {
            columns
              .map((column, index) => {
                if (column.groupItems !== undefined) {
                  return (
                    <TableCell
                      classes={{ head: clsx(classes.cellHead, propsClasses.cellHead) }}
                      colSpan={column.groupItems?.length}
                      className={clsx(classes.noBorder, classes.upPadding, { [classes.separateGroup]: separateGroup && index !== 0 })}
                      key={column.value}
                      align={column.columnAlign ?? column.align}
                      style={{ minWidth: column.width ?? 'auto' }}
                    >
                      {column.label}
                    </TableCell>
                  )
                }
                return (
                  <TableCell
                    rowSpan={2}
                    key={column.value}
                    align={column.columnAlign ?? column.align}
                    classes={{ head: clsx(classes.cellHead, propsClasses.cellHead) }}
                    className={classes.fourPadding}
                    style={{ minWidth: column.width ?? 'auto' }}
                  >
                    {column.canSort
                      ? (
                      <TableSortLabel
                        active={sorted && sorted.column === column.value}
                        direction={sorted?.order}
                        classes={{
                          root: clsx(classes.cellHead, propsClasses.cellHead),
                          active: clsx(classes.cellHead, propsClasses.cellHead),
                          iconDirectionDesc: clsx(classes.cellHead, propsClasses.cellHead),
                          iconDirectionAsc: clsx(classes.cellHead, propsClasses.cellHead)
                        }}
                        onClick={(event: MouseEvent<HTMLAnchorElement>) => {
                          if (onSort !== undefined) onSort(event, column.value)
                        }}
                      >
                        {column.label}
                      </TableSortLabel>
                        )
                      : column.label}
                  </TableCell>
                )
              })
          }
        </TableRow>
      )}
      <TableRow>
        {
          columns
            .map((item) => {
              if (item.groupItems) return item.groupItems
              return [item]
            })
            .reduce((array, item) => array.concat(item), [])
            .map((column, index) => {
              const isMain = columns.findIndex((item) => item.value === column.value) >= 0
              const isFirst = firstsInGroup.findIndex((item) => item.value === column.value) >= 0
              if (hasGroup && isMain) return null
              return (
                <TableCell
                  key={column.value}
                  align={column.columnAlign ?? column.align}
                  classes={{ head: clsx(classes.cellHead, propsClasses.cellHead) }}
                  className={clsx(
                    { [classes.downPadding]: hasGroup, [classes.separateGroup]: separateGroup && isFirst && index !== 0 }
                  )}
                  style={{ minWidth: column.width ?? 'auto' }}
                >
                  {column.canSort
                    ? (
                    <TableSortLabel
                      active={sorted && sorted.column === column.value}
                      direction={sorted?.order}
                      classes={{
                        root: clsx(classes.cellHead, propsClasses.cellHead),
                        active: clsx(classes.cellHead, propsClasses.cellHead),
                        iconDirectionDesc: clsx(classes.cellHead, propsClasses.cellHead),
                        iconDirectionAsc: clsx(classes.cellHead, propsClasses.cellHead)
                      }}
                      onClick={(event: MouseEvent<HTMLAnchorElement>) => {
                        if (onSort !== undefined) onSort(event, column.value)
                      }}
                    >
                      {column.label}
                    </TableSortLabel>
                      )
                    : column.label}
                </TableCell>
              )
            })
        }
      </TableRow>
    </TableHead>
  )
}

const CoreTableBody: React.FC<PropTypes> = (props) => {
  const {
    data,
    isSelected,
    selected,
    collapse: Detail,
    tableAppend: TableAppend,
    separateGroup
  } = props
  const classes = useStyles()
  const propsClasses = props.classes ?? {}
  const { columns, rows } = data
  const firstsInGroup = columns.map((item) => item.groupItems !== undefined ? item.groupItems[0] || item : item)
  return (
    <TableBody classes={{ root: propsClasses.body }}>
      {rows.map((row) => {
        const rowIsSelected = isSelected && selected?.includes(row.key)
        const unfoldColumns = columns
          .map((item) => {
            if (item.groupItems) return item.groupItems
            return [item]
          })
          .reduce((array, item) => array.concat(item), [])
        return (
          <React.Fragment key={row.key}>
            <TableRow selected={rowIsSelected} classes={{ root: clsx(propsClasses.row), selected: clsx(classes.rowSelect, propsClasses.rowSelect) }}>
              {
                unfoldColumns
                  .map((column, index) => {
                    const isFirst = firstsInGroup.findIndex((item) => item.value === column.value) >= 0
                    return (
                      <TableCell
                        key={`${row.key}_${column.value}`}
                        align={column.align}
                        classes={{ body: clsx(classes.cellBody, propsClasses.cellBody) }}
                        className={clsx({ [classes.separateGroup]: separateGroup && isFirst && index !== 0 })}
                      >
                        {row.items.get(column.value)}
                      </TableCell>
                    )
                  })
              }
            </TableRow>
            {
              isSelected && Detail !== undefined && rowIsSelected && (
                <TableRow>
                  <TableCell colSpan={unfoldColumns.length} className={classes.noPadding}>
                    <Detail row={row.items} />
                  </TableCell>
                </TableRow>
              )
            }
          </React.Fragment>
        )
      })}
      {TableAppend}
    </TableBody>
  )
}

const CoreNoData: React.FC = () => {
  const { t } = useT()
  return (
    <Box display="flex" justifyContent="center" padding={1} overflow="auto">
      <Typography variant="h3">
        {t('common.noData')}
      </Typography>
    </Box>
  )
}

const CorePagination: React.FC<PropTypes> = (props) => {
  const {
    total,
    page: rowPageNum,
    perPage, onChangeRowsPerPage,
    onChangePage
  } = props
  const classes = useStyles()
  const { t } = useT()
  const propsClasses = props.classes ?? {}
  const page = rowPageNum === undefined ? 0 : rowPageNum - 1
  return (
    <Box overflow="auto">
      <Table>
        <TableFooter>
          <TableRow>
            <TablePagination
              classes={{
                root: clsx(classes.cellFooter, propsClasses.cellFooter),
                selectRoot: clsx({ [classes.paginationSelect]: onChangeRowsPerPage === undefined })
              }}
              labelRowsPerPage={onChangeRowsPerPage === undefined ? '' : t('common.perPage')}
              rowsPerPageOptions={[25, 50, 100]}
              ActionsComponent={TablePaignationActions}
              count={total}
              rowsPerPage={perPage ?? getPerPage()}
              page={page}
              onPageChange={(event, page) => {
                if (onChangePage !== undefined) onChangePage(event, page + 1)
              }}
              onRowsPerPageChange={onChangeRowsPerPage}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </Box>
  )
}

const CoreTable: React.FC<PropTypes> = (props) => {
  const {
    data,
    noScroll,
    dense,
    tableFooterMemo: TableFooterMemo,
    showPagination,
    page: rowPageNum,
    onChangePage,
    loading,
    hasHeadWithNoData,
    showNoData = true
  } = props
  const classes = useStyles()
  const { rows } = data

  if (rows.length === 0 && !loading && rowPageNum !== undefined && rowPageNum !== 1) {
    if (onChangePage) onChangePage(null, rowPageNum - 1)
    return null
  } else if (rows.length === 0) {
    if (!showNoData) return null
    if (hasHeadWithNoData) {
      return (
        <React.Fragment>
          <Box overflow={noScroll ? 'hidden' : 'auto'} className={classes.shadow}>
            <Table size={dense ? 'small' : 'medium'}>
              <CoreTableHead {...props} />
            </Table>
            <Box padding={0} paddingTop={4}>
              <CoreNoData />
            </Box>
          </Box>
          {TableFooterMemo && (<TableFooterMemo />)}
          {showPagination && (<CorePagination {...props} />)}
        </React.Fragment>
      )
    } else {
      return (
        <CoreNoData />
      )
    }
  }

  return (
    <React.Fragment>
      <Box overflow={noScroll ? 'hidden' : 'auto'} className={classes.shadow}>
        <Table size={dense ? 'small' : 'medium'}>
          <CoreTableHead {...props} />
          <CoreTableBody {...props} />
        </Table>
      </Box>
      {TableFooterMemo && (<TableFooterMemo />)}
      {showPagination && (<CorePagination {...props} />)}
    </React.Fragment>
  )
}

export default React.memo(CoreTable)

import React from 'react'
import { FinanceBlacklistQuery, PaginationReq } from '@golden/gdk-admin'
import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import FinanceBlacklistTable from '../../../components/admin/finance/FinanceBlacklistTable'
import FinanceBlacklistForm from '../../../components/admin/finance/FinanceBlacklistForm'
import { SearchToRequestFunc } from '../../../utils/default/ComplexFlowHook'
import {
  pipe,
  parseInt,
  acceptUndefined,
  guaranteeBeOneOf,
  guaranteeBetween
} from '../../../utils/default/FormHelper'
import { isUndefined, omitBy } from '@golden/utils'

export type Request = FinanceBlacklistQuery & PaginationReq

export const searchToRequest: (banks: number[]) => SearchToRequestFunc<Request> = (banks: number[]) => (search) => {
  const converted = {
    ...search,
    start_at: acceptUndefined(search.start_at, parseInt),
    end_at: acceptUndefined(search.end_at, parseInt),
    bank_code: acceptUndefined(
      search.bank_code,
      pipe(
        (value) => guaranteeBeOneOf(value, banks.map((item) => item.toString())),
        parseInt
      )
    ),
    page: acceptUndefined(
      search.page,
      pipe(
        parseInt,
        (value) => guaranteeBetween(value, 1, Number.MAX_SAFE_INTEGER)
      )
    ) || 1
  }
  if (converted.start_at && converted.end_at && converted.end_at < converted.start_at) {
    throw new Error('The end time can\'t exceed the start time')
  }
  return omitBy(converted, isUndefined) as Request
}

const FinanceBlacklistPage: React.FC = () => {
  return (
    <Box paddingY={6}>
      <Box paddingX={6}>
        <Paper>
          <FinanceBlacklistForm />
        </Paper>
      </Box>
      <Box paddingTop={2}>
        <FinanceBlacklistTable />
      </Box>
    </Box>
  )
}

export default React.memo(FinanceBlacklistPage)

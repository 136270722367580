import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import useT from '../../../i18ns/admin/useT'

const useStyles = makeStyles((theme) => ({
  title: {
    color: theme.palette.common.black
  },
  pin: {
    whiteSpace: 'nowrap',
    backgroundColor: 'rgba(97, 153, 227, 0.2)',
    color: '#6199e3',
    border: '1px solid #6199e3',
    borderRadius: theme.shape.borderRadius,
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    marginLeft: theme.spacing(0.5)
  }
}))

interface PropTypes {
  title: string
  isPinned: boolean
  content: string
}

const AnnouncementTableContent: React.FC<PropTypes> = (props) => {
  const { title, isPinned, content } = props
  const classes = useStyles()
  const { t } = useT()
  return (
    <Box>
      <Box display="flex" flexDirection="row" alignItems="center">
        <Typography className={classes.title}>{title}</Typography>
        {isPinned && (<Box className={classes.pin}>{t('common.pin')}</Box>)}
      </Box>
      <Box
        paddingY={2}
        dangerouslySetInnerHTML={{ __html: content }}
      >
      </Box>
    </Box>
  )
}

export default React.memo(AnnouncementTableContent)

import React, { useMemo, ChangeEvent } from 'react'
import DropDown from '../default/form/DropDown'
import useT from '../../i18ns/admin/useT'
import { CountryCallingCode } from '@golden/gdk-campaign'

export interface PropTypes {
  value: CountryCallingCode
  label?: string
  HideRegionNameInValue?: boolean
  disabled?: boolean
  onChange: (event: ChangeEvent<{ name?: string | undefined, value: unknown }>) => void
}

const IddCodeDropDown: React.FC<PropTypes> = (props) => {
  const { value, label, HideRegionNameInValue, disabled, onChange } = props
  const { t } = useT()
  const iddCodeOptions = useMemo(() => {
    return [
      { name: `+${CountryCallingCode.CN} ${t('regionName.china')}`, value: CountryCallingCode.CN, renderName: HideRegionNameInValue ? `+${CountryCallingCode.CN}` : undefined },
      { name: `+${CountryCallingCode.HK} ${t('regionName.chinaHongKong')}`, value: CountryCallingCode.HK, renderName: HideRegionNameInValue ? `+${CountryCallingCode.HK}` : undefined },
      { name: `+${CountryCallingCode.MO} ${t('regionName.chinaMacao')}`, value: CountryCallingCode.MO, renderName: HideRegionNameInValue ? `+${CountryCallingCode.MO}` : undefined },
      { name: `+${CountryCallingCode.MM} ${t('regionName.myanmar')}`, value: CountryCallingCode.MM, renderName: HideRegionNameInValue ? `+${CountryCallingCode.MM}` : undefined },
      { name: `+${CountryCallingCode.CAMBODIA} ${t('regionName.cambodia')}`, value: CountryCallingCode.CAMBODIA, renderName: HideRegionNameInValue ? `+${CountryCallingCode.CAMBODIA}` : undefined },
      { name: `+${CountryCallingCode.DUBAI} ${t('regionName.dubai')}`, value: CountryCallingCode.DUBAI, renderName: HideRegionNameInValue ? `+${CountryCallingCode.DUBAI}` : undefined }
    ]
  }, [t, HideRegionNameInValue])
  return (
    <DropDown
      label={label}
      value={value}
      onChange={onChange}
      options={iddCodeOptions}
      disabled={disabled}
      fullWidth
    />
  )
}

export default React.memo(IddCodeDropDown)

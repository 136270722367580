import React from 'react'
import { omitBy, isUndefined } from '@golden/utils'
import { PaginationReq, Sort, AdminRes } from '@golden/gdk-admin'
import Box from '@material-ui/core/Box'
import ManagerAccountTable, { initialSort } from '../../../components/admin/manager/ManagerAccountTable'
import ManagerAccountForm from '../../../components/admin/manager/ManagerAccountForm'
import { SearchToRequestFunc } from '../../../utils/default/ComplexFlowHook'
import { guaranteeBetween, parseInt, acceptUndefined, pipe, guaranteeBeOrder, guaranteeBeKey } from '../../../utils/default/FormHelper'
import ScrollablePaper from '../../../components/default/present/ScrollablePaper'

export type ManagerAccountRequest = { account?: string, role_id: number } & PaginationReq & Sort

export const searchToRequest: SearchToRequestFunc<ManagerAccountRequest> = (search) => {
  const initSort = initialSort()
  const converted = {
    account: search.account,
    role_id: acceptUndefined(search.role_id, pipe(parseInt)),
    page: acceptUndefined(
      search.page,
      pipe(
        parseInt,
        (value) => guaranteeBetween(value, 1, Number.MAX_SAFE_INTEGER)
      )
    ) || 1,
    sort_by: acceptUndefined(
      search.sort_by,
      (value) => guaranteeBeKey<AdminRes>(value as keyof AdminRes, ['account'])
    ) ?? initSort.sort_by,
    order: acceptUndefined(
      search.order,
      guaranteeBeOrder
    ) ?? initSort.order
  }
  return omitBy(converted, isUndefined) as ManagerAccountRequest
}

const ManagerAccountPage: React.FC = () => {
  return (
    <Box paddingY={5}>
      <ScrollablePaper marginX={5}>
        <Box padding={4}>
          <Box paddingBottom={3}>
            <ManagerAccountForm />
          </Box>
          <Box>
            <ManagerAccountTable />
          </Box>
        </Box>
      </ScrollablePaper>
    </Box>
  )
}

export default React.memo(ManagerAccountPage)

import React, { useMemo, useState, useContext } from 'react'
import { omitBy, isUndefined } from '@golden/utils'
import { PiggyAlipay, DepositMethodType, PaginationRes } from '@golden/gdk-admin'
import LoadingAndErrorFrame from '../../default/frames/LoadingAndErrorFrame'
import CoreTable from '../../default/present/CoreTable'
import { usePageFlow } from '../../../utils/default/PageFlowHook'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import useT from '../../../i18ns/admin/useT'
import { useGetDataByPayload, useReload } from '../../../utils/default/ComplexFlowHook'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { createTableData, formatMoney, createDefaultPaginationData } from '../../../utils/default/TableHelper'
import depositTypeName from '../../../constants/default/depositTypeName'
import { useChecker } from '../../../utils/admin/AdminRouteHook'
import DateTime from '../../default/present/DateTime'
import TableStatus from '../../default/present/TableStatus'
import { FormContext } from '../../../views/admin/finance/FinancePiggyAlipayPage'
import { convertEmptyToUndefined } from '../../../utils/default/FormHelper'
import FinancePiggyAlipayButtons from './FinancePiggyAlipayButtons'
import clientTypeName from '../../../constants/admin/clientTypeName'
import agentTypeName from '../../../constants/default/agentTypeName'

interface RowType {
  id: number
  layer: React.ReactElement
  name: string
  account: string
  way: string
  min: string
  max: string
  receiver: string
  memo: React.ReactElement
  client: React.ReactElement
  status: React.ReactElement
  updatedAt: React.ReactElement
  updatedBy: string
  functions: React.ReactElement
  agent_types: React.ReactElement
}

const FinancePiggyAlipayTable: React.FC = () => {
  const [form, setForm] = useContext(FormContext)
  const classes = useCommonStyles()
  const [list, setList] = useState<PaginationRes<PiggyAlipay[]>>(createDefaultPaginationData([]))
  const pageFlow = usePageFlow()
  const gdk = useGDK()
  const { t } = useT()
  const writable = useChecker()
  const { reload, reloadFlag } = useReload()
  const payload = useMemo(() => {
    const payload = omitBy({ account: convertEmptyToUndefined(form.account) }, isUndefined)
    if (form.status === '--') return { ...payload, page: form.page }
    return { ...payload, is_active: Number(form.status) as 0 | 1, page: form.page }
  }, [form])
  useGetDataByPayload({
    payload,
    gdkFunc: (payload) => gdk.finance.getPiggyAlipayList(payload),
    gdkFuncDependencies: [gdk, reloadFlag],
    onBeforeFetch: pageFlow.setLoadingStart,
    onSuccess: (res: PaginationRes<PiggyAlipay[]>) => {
      setList(res)
      pageFlow.setContentShow()
    },
    onError: pageFlow.setGDKError
  })

  const tableClasses = useMemo(() => {
    return {
      head: classes.pinkTableHead,
      row: classes.tableRow,
      cellHead: classes.tableCellHead
    }
  }, [classes])
  const rows = useMemo(() => {
    return list.data.map((item) => {
      return {
        id: item.id,
        layer: (
          <React.Fragment>
            { [...item.layers.map(layer => layer.name), item.is_for_proxy_deposit ? t('common.staffDeposit') : null].join('、') }
          </React.Fragment>
        ),
        name: item.name,
        account: item.account,
        way: t(depositTypeName[DepositMethodType.SCAN_ALIPAY]),
        min: formatMoney(item.cash_min),
        max: formatMoney(item.cash_max),
        receiver: item.real_name ?? '',
        memo: <div style={{ whiteSpace: 'nowrap' }}>{item.memo}</div>,
        client: (
          <React.Fragment>
            {item.clients.map((client) => (
              <p>{t(clientTypeName[client])}</p>
            ))}
          </React.Fragment>
        ),
        status: (<TableStatus status={item.is_active} />),
        updatedAt: (<DateTime time={item.updated_at} />),
        updatedBy: item.updated_by,
        functions: (
          <FinancePiggyAlipayButtons
            id={item.id}
            isActive={item.is_active}
            account={item.account}
            name={item.real_name}
            reload={reload}
          />
        ),
        agent_types: (
          <React.Fragment>
            { item.agent_types.map(agent => t(agentTypeName[agent])).join('、') }
          </React.Fragment>
        )
      } as RowType
    })
  }, [t, list, reload])

  const data = useMemo(() => {
    return createTableData<RowType>(
      {
        id: {
          label: '',
          value: 'id'
        },
        name: {
          label: t('common.alipayName'),
          value: 'name',
          align: 'center'
        },
        account: {
          label: t('common.alipayAccount'),
          value: 'account',
          align: 'center'
        },
        way: {
          label: t('common.financeDepositWay'),
          value: 'way',
          align: 'center'
        },
        min: {
          label: t('common.minDepositMoney'),
          value: 'min',
          align: 'right'
        },
        max: {
          label: t('common.maxDepositMoney'),
          value: 'max',
          align: 'right'
        },
        receiver: {
          label: t('common.playerSideReceiver'),
          value: 'receiver',
          align: 'center'
        },
        memo: {
          label: t('common.memoForPlayer'),
          value: 'memo',
          align: 'center'
        },
        layer: {
          label: t('common.openPlayerLayer'),
          value: 'layer',
          align: 'center',
          width: 120
        },
        agent_types: {
          label: t('common.openAgentLayer'),
          value: 'agent_types',
          align: 'center',
          width: 120
        },
        client: {
          label: t('common.platformVersion'),
          value: 'client',
          align: 'center'
        },
        status: {
          label: t('common.status'),
          value: 'status',
          align: 'center'
        },
        updatedAt: {
          label: t('common.updateAt'),
          value: 'updatedAt',
          align: 'center'
        },
        updatedBy: {
          label: t('common.updateBy'),
          value: 'updatedBy',
          align: 'center'
        },
        functions: {
          label: t('common.function'),
          value: 'functions',
          align: 'center'
        }
      },
      writable
        ? [
            'name',
            'account',
            'way',
            'min',
            'max',
            'receiver',
            'memo',
            'layer',
            // 'agent_types',
            'client',
            'status',
            'updatedAt',
            'updatedBy',
            'functions'
          ]
        : [
            'name',
            'account',
            'way',
            'min',
            'max',
            'receiver',
            'memo',
            'layer',
            // 'agent_types',
            'client',
            'status',
            'updatedAt',
            'updatedBy'
          ],
      rows,
      'id'
    )
  }, [t, rows, writable])

  return (
    <LoadingAndErrorFrame { ...pageFlow.status }>
      <CoreTable
        classes={tableClasses}
        data={data}
        showPagination
        total={list.total}
        page={form.page}
        onChangePage={(_, page) => setForm((form) => ({ ...form, page }))}
      />
    </LoadingAndErrorFrame>
  )
}

export default React.memo(FinancePiggyAlipayTable)

import React, { createContext, Dispatch, SetStateAction, useState, useMemo, useCallback } from 'react'
import { WithdrawLimitQuery, PaginationReq, PaginationRes, WithdrawLimit } from '@golden/gdk-admin'
import { omitBy, isUndefined } from '@golden/utils'
import { makeStyles } from '@material-ui/core/styles'
import { Link } from 'react-router-dom'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import ScrollablePaper from '../../../components/default/present/ScrollablePaper'
import StateProvider from '../../../providers/default/StateProvider'
import useT from '../../../i18ns/admin/useT'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import { useChecker } from '../../../utils/admin/AdminRouteHook'
import { Path } from '../../../components/admin/route/route'
import { usePageFlow } from '../../../utils/default/PageFlowHook'
import { createDefaultPaginationData, formatCount, formatMoney, createTableData } from '../../../utils/default/TableHelper'
import { useGetDataByPayload, useReload } from '../../../utils/default/ComplexFlowHook'
import useGDK from '../../../providers/admin/gdk/useGDK'
import DateTime from '../../../components/default/present/DateTime'
import CoreTable from '../../../components/default/present/CoreTable'
import LoadingAndErrorFrame from '../../../components/default/frames/LoadingAndErrorFrame'
import WithdrawalLimitButtons from '../../../components/admin/withdrawal/WithdrawalLimitButtons'
import { convertEmptyToUndefined } from '../../../utils/default/FormHelper'

const useStyles = makeStyles(() => ({
  noMargin: {
    margin: '0px auto'
  }
}))

const SearchContext = createContext<[string, Dispatch<SetStateAction<string>>]>(['', () => {}])

interface RowType {
  id: number
  account: string
  cashMax: string
  daily: string
  dailyCount: string
  time: React.ReactElement
  operator: string
  functions: React.ReactElement
}

const WithdrawalLimitPage: React.FC = () => {
  const gdk = useGDK()
  const writable = useChecker()
  const pageFlow = usePageFlow()
  const [payload, setPayload] = useState<WithdrawLimitQuery & PaginationReq>({
    page: 1
  })
  const [list, setList] = useState<PaginationRes<WithdrawLimit[]>>(createDefaultPaginationData([]))
  const { reload, reloadFlag } = useReload()
  const classes = useStyles()
  const commonClasses = useCommonStyles()
  const { t } = useT()
  const tableClasses = useMemo(() => ({
    head: commonClasses.pinkTableHead,
    row: commonClasses.tableRow,
    cellHead: commonClasses.tableCellHead
  }), [commonClasses.pinkTableHead, commonClasses.tableCellHead, commonClasses.tableRow])
  useGetDataByPayload({
    payload,
    gdkFunc: (form) => gdk.withdraw.getWithdrawLimitList(form),
    gdkFuncDependencies: [gdk, reloadFlag],
    onBeforeFetch: pageFlow.setLoadingStart,
    onSuccess: (res: PaginationRes<WithdrawLimit[]>) => {
      setList(res)
      pageFlow.setContentShow()
    },
    onError: pageFlow.setGDKError
  })
  const rows = useMemo(() => {
    return list.data.map((item) => ({
      id: item.id,
      account: item.account,
      cashMax: formatMoney(item.cash_max_withdraw),
      daily: formatMoney(item.cash_max_withdraw_daily),
      dailyCount: formatCount(item.withdraw_times_daily),
      time: (<DateTime time={item.updated_at} />),
      operator: item.updated_by,
      functions: (<WithdrawalLimitButtons id={item.id} account={item.account} reload={reload} />)
    } as RowType))
  }, [list.data, reload])
  const data = useMemo(() => {
    return createTableData<RowType>(
      {
        id: {
          label: '',
          value: 'id'
        },
        account: {
          label: t('common.playerAccount'),
          value: 'account',
          align: 'center'
        },
        cashMax: {
          label: t('common.cashMaxWithdraw'),
          value: 'cashMax',
          align: 'right'
        },
        daily: {
          label: t('common.cashMaxWithdrawDaily'),
          value: 'daily',
          align: 'right'
        },
        dailyCount: {
          label: t('common.withdrawTimesDaily'),
          value: 'dailyCount',
          align: 'right'
        },
        time: {
          label: t('common.updateAt'),
          value: 'time',
          align: 'center'
        },
        operator: {
          label: t('common.updateBy'),
          value: 'operator',
          align: 'center'
        },
        functions: {
          label: t('common.function'),
          value: 'functions',
          align: 'center'
        }
      },
      writable
        ? [
            'account',
            'cashMax',
            'daily',
            'dailyCount',
            'time',
            'operator',
            'functions'
          ]
        : [
            'account',
            'cashMax',
            'daily',
            'dailyCount',
            'time',
            'operator'
          ],
      rows,
      'id'
    )
  }, [rows, t, writable])
  return (
    <Box paddingY={5}>
      <ScrollablePaper marginX={5}>
        <Box padding={4}>
          <Grid container direction="row" justify="flex-end" alignItems="stretch" spacing={2}>
            <StateProvider context={SearchContext} defaultValue="">
              <Grid item>
                <SearchContext.Consumer>
                  {([account, setAccount]) => (
                    <TextField
                      className={classes.noMargin}
                      placeholder={t('placeholder.inputSearchPlayerAccount')}
                      variant="outlined"
                      margin="dense"
                      value={account}
                      onChange={(event) => setAccount(event.target.value)}
                    />
                  )}
                </SearchContext.Consumer>
              </Grid>
              <Grid item>
                <SearchContext.Consumer>
                  {([account]) => (
                    <Button
                      className={commonClasses.purpleGradualButton}
                      onClick={() => {
                        setPayload((payload) => {
                          const newPayload: WithdrawLimitQuery & PaginationReq = { ...payload, account: convertEmptyToUndefined(account) }
                          return omitBy(newPayload, isUndefined) as WithdrawLimitQuery & PaginationReq
                        })
                      }}
                    >
                      {t('common.search')}
                    </Button>
                  )}
                </SearchContext.Consumer>
              </Grid>
              {writable && (
                <Grid item>
                  <Button
                    className={commonClasses.purpleGradualButton}
                    component={Link}
                    to={Path.WITHDRAWAL_LIMIT_CREATE}
                  >
                    {t('common.createPlayerWithdrawLimit')}
                  </Button>
                </Grid>
              )}
            </StateProvider>
          </Grid>
          <Box marginTop={2}>
            <LoadingAndErrorFrame { ...pageFlow.status }>
              <CoreTable
                classes={tableClasses}
                data={data}
                total={list.total}
                showPagination
                page={payload.page}
                onChangePage={useCallback((_, page) => setPayload((payload) => ({ ...payload, page })), [])}
              />
            </LoadingAndErrorFrame>
          </Box>
        </Box>
      </ScrollablePaper>
    </Box>
  )
}

export default React.memo(WithdrawalLimitPage)

import { ManualWithdrawType } from '@golden/gdk-admin'
import TKeyType from '../../i18ns/admin/TKeyType'

const manualWithdrawName: { [key in ManualWithdrawType]: TKeyType } = {
  [ManualWithdrawType.EVENT]: 'common.activity',
  [ManualWithdrawType.WITHDRAW]: 'common.withdraw',
  [ManualWithdrawType.OTHER]: 'common.other',
  [ManualWithdrawType.RISK_ADJUSTMENT]: 'common.riskAdjustment'
}

export default manualWithdrawName

import React from 'react'
import { omitBy, isUndefined } from '@golden/utils'
import Box from '@material-ui/core/Box'
import AgentAnnouncementTable from '../../../components/admin/forestage/AgentAnnouncementTable'
import AgentAnnouncementForm from '../../../components/admin/forestage/AgentAnnouncementForm'
import ScrollablePaper from '../../../components/default/present/ScrollablePaper'
import { SearchToRequestFunc } from '../../../utils/default/ComplexFlowHook'
import { pipe, acceptUndefined, parseInt, guaranteeBetween } from '../../../utils/default/FormHelper'

export interface AgentAnnoucementRequestType {
  page: number
  account?: string
}

export const searchToRequest: SearchToRequestFunc<AgentAnnoucementRequestType> = (search) => {
  const converted = {
    page: acceptUndefined(
      search.page,
      pipe(
        parseInt,
        (value) => guaranteeBetween(value, 1, Number.MAX_SAFE_INTEGER)
      )
    ) || 1,
    account: search.account
  }
  return omitBy(converted, isUndefined) as AgentAnnoucementRequestType
}

const AgentAnnouncementPage: React.FC = () => {
  return (
    <Box paddingY={5}>
      <ScrollablePaper marginX={4}>
        <Box padding={4}>
          <AgentAnnouncementForm />
          <Box paddingY={5}>
            <AgentAnnouncementTable />
          </Box>
        </Box>
      </ScrollablePaper>
    </Box>
  )
}

export default React.memo(AgentAnnouncementPage)

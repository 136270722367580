import React from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import InputAdornment from '@material-ui/core/InputAdornment'
import { useCommonStyles } from '../../../utils/admin/StyleHook'

const useStyles = makeStyles((theme) => ({
  button: {
    marginBottom: theme.spacing(2)
  }
}))

interface PropTypes {
  title: string
  onClick: () => void
  disabled?: boolean
  isAdornment?: boolean
}

const PlayerInputButton: React.FC<PropTypes> = (props) => {
  const { title, onClick, disabled, isAdornment = true } = props
  const classes = useStyles()
  const commonClasses = useCommonStyles()

  const button = (
    <Button
      classes={{
        root: clsx(commonClasses.blueGradualOutlineButton, isAdornment ? classes.button : ''),
        disabled: commonClasses.blueGradualOutlineDisabledButton
      }}
      onClick={onClick}
      size="small"
      disabled={disabled}
    >
      {title}
    </Button>
  )
  return isAdornment
    ? (<InputAdornment position="end">
        {button}
      </InputAdornment>)
    : button
}

export default React.memo(PlayerInputButton)

import React, { useCallback, useMemo } from 'react'
import NumberInput from '../../default/form/NumberInput'
import useT from '../../../i18ns/admin/useT'

interface PropTypes {
  disabled?: boolean
  canEmpty?: boolean
  value?: string
  onChange?: (value: string) => void
}

const rateInputProps = {
  thousandSeparator: true,
  decimalScale: 2,
  allowNegative: false,
  fixedDecimalScale: true
}

const FeedbackInput: React.FC<PropTypes> = (props) => {
  const { t } = useT()
  const { disabled, canEmpty, value, onChange } = props
  const error: string | null = useMemo(() => {
    if ((!value || value === '') && !canEmpty) return t('error.mustNotEmpty')
    if (Number(value) > 3.5) return t('common.outOf3point5')
    return null
  }, [t, value, canEmpty])
  return (
    <NumberInput
      value={value}
      onChange={useCallback((event) => {
        const newValue = event.target.value
        if (onChange) onChange(newValue)
      }, [onChange])}
      variant="outlined"
      margin="dense"
      inputProps={rateInputProps}
      fullWidth
      disabled={disabled}
      error={error !== null}
      helperText={error ?? ''}
    />
  )
}

export default React.memo(FeedbackInput)

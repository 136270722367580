import React from 'react'
import Box from '@material-ui/core/Box'
import AnnouncementPersonalForm from '../../../components/admin/forestage/AnnouncementPersonalForm'
import AnnouncementPersonalTable from '../../../components/admin/forestage/AnnouncementPersonalTable'
import AnnouncementPersonalProcessTable from '../../../components/admin/forestage/AnnouncementPersonalProcessTable'
import { AnnouncementPersonalQuery, PaginationReq } from '@golden/gdk-admin'
import { SearchToRequestFunc } from '../../../utils/default/ComplexFlowHook'
import { omitBy, isUndefined } from '@golden/utils'
import { guaranteeBetween, guaranteeNotUndefined, pipe, parseInt } from '../../../utils/default/FormHelper'

export type Request = AnnouncementPersonalQuery & PaginationReq

export const searchToRequest: SearchToRequestFunc<Request> = (search) => {
  const converted = {
    ...search,
    start_at: pipe(
      guaranteeNotUndefined,
      parseInt,
      (value) => guaranteeBetween(value, 1, Number.MAX_SAFE_INTEGER)
    )(search.start_at),
    end_at: pipe(
      guaranteeNotUndefined,
      parseInt,
      (value) => guaranteeBetween(value, 1, Number.MAX_SAFE_INTEGER)
    )(search.end_at),
    page: pipe(
      guaranteeNotUndefined,
      parseInt,
      (value) => guaranteeBetween(value, 1, Number.MAX_SAFE_INTEGER)
    )(search.page)
  }
  if (converted.start_at && converted.end_at && converted.end_at < converted.start_at) {
    throw new Error('The end time can\'t exceed the start time')
  }
  return omitBy(converted, isUndefined) as Request
}

const AnnouncementPersonalPage: React.FC = () => {
  return (
    <Box paddingY={6}>
      <Box paddingX={6}>
        <AnnouncementPersonalProcessTable />
      </Box>
      <Box paddingTop={2} paddingX={6}>
        <AnnouncementPersonalForm />
      </Box>
      <Box paddingTop={2}>
        <AnnouncementPersonalTable />
      </Box>
    </Box>
  )
}

export default React.memo(AnnouncementPersonalPage)

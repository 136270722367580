import { AgentWalletTransactionType } from '@golden/gdk-admin'
import TKeyType from '../../i18ns/admin/TKeyType'

const agentWalletTransactionTypeName: { [key in AgentWalletTransactionType]: TKeyType } = {
  [AgentWalletTransactionType.RECHARGE]: 'common.recharge',
  [AgentWalletTransactionType.IN]: 'common.agentWalletTransfer',
  [AgentWalletTransactionType.OUT]: 'common.agentWalletTransfer',
  [AgentWalletTransactionType.REVOKE]: 'common.revoke',
  [AgentWalletTransactionType.DEPOSIT]: 'common.deposit2',
  [AgentWalletTransactionType.WITHDRAW]: 'common.playerWithdraw'
}

export default agentWalletTransactionTypeName

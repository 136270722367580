import React, { useMemo } from 'react'
import { getTime } from 'date-fns'
import { ActivityType, ActivityDailySignInStateType } from '@golden/gdk-admin'
import Grid from '@material-ui/core/Grid'
import MuiTextField, { TextFieldProps } from '@material-ui/core/TextField'
import FormField from '../../default/form/FormField'
import DropDown, { PropTypes as DropDownProps } from '../../default/form/DropDown'
import { FormState, ValueGetter } from '../../../utils/default/FormHook'
import { ActivityFormType, ActivityRequest } from './ActivityRecordDetailForm'
import useT from '../../../i18ns/admin/useT'
import { getValueFromChangeEvent, convertEmptyToUndefined, acceptUndefined, pipe, guaranteeBeKey } from '../../../utils/default/FormHelper'
import { SearchToRequestFunc } from '../../../utils/default/ComplexFlowHook'
import { dailySignInStateName } from '../../../constants/default/activityStatusName'
import { isUndefined, omitBy } from '@golden/utils'

export interface ActivityDailySignInFormType {
  account: string
  agent_account: string
  dailySignInState: ActivityDailySignInStateType | '--'
}

export const initialForm = (): ActivityDailySignInFormType => ({
  account: '',
  agent_account: '',
  dailySignInState: '--'
})

export const getValueFromEvent: ValueGetter<ActivityDailySignInFormType> = {
  account: getValueFromChangeEvent,
  agent_account: getValueFromChangeEvent,
  dailySignInState: getValueFromChangeEvent
}

export const formToRequest = (form: ActivityFormType): ActivityRequest => {
  const converted = {
    activity: ActivityType.DAILY_SIGN_IN_BONUS,
    account: convertEmptyToUndefined(form.account),
    agent_account: convertEmptyToUndefined(form.agent_account),
    state: form.dailySignInState === '--' ? undefined : form.dailySignInState,
    type: form.type,
    start_at: form.time.start === null ? undefined : getTime(form.time.start),
    end_at: form.time.end === null ? undefined : getTime(form.time.end),
    page: 1
  } as ActivityRequest
  return omitBy(converted, isUndefined) as ActivityRequest
}

export const searchToRequest: SearchToRequestFunc<{
  account: string | undefined
  agent_account: string | undefined
  state: number | undefined
}> = (search) => {
  const converted = {
    account: search.account,
    agent_account: search.agent_account,
    dailySignInState: acceptUndefined(search.state, pipe(
      (value) => guaranteeBeKey(value, Object.keys(dailySignInStateName))
    ))
  }
  return omitBy(converted, isUndefined) as any
}

interface PropTypes {
  context: React.Context<FormState<ActivityFormType>>
}

const TextField = React.memo(MuiTextField)

const NormalActivityForm: React.FC<PropTypes> = (props) => {
  const { context } = props
  const { t } = useT()

  const stateOptions = useMemo(() => {
    return [{ name: t('common.all'), value: '--' }]
      .concat(
        Object.keys(dailySignInStateName)
          .map((key) => ({ name: t(dailySignInStateName[key as ActivityDailySignInStateType]), value: key })) as any
      )
  }, [t])

  return (
    <React.Fragment>
      <Grid item xs={12} md={6} lg={3}>
        <FormField<ActivityFormType, TextFieldProps>
          context={context}
          component={TextField}
          name="account"
          label={t('common.playerAccount')}
          placeholder={t('placeholder.inputPlayerAccount')}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} md={6} lg={3}>
        <FormField<ActivityFormType, DropDownProps>
          context={context}
          component={DropDown}
          name="dailySignInState"
          label={t('common.status')}
          options={stateOptions}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} md={6} lg={3}>
        <FormField<ActivityFormType, TextFieldProps>
          context={context}
          component={TextField}
          name="agent_account"
          label={t('common.agentAccount')}
          placeholder={t('placeholder.inputAgentAccount')}
          fullWidth
        />
      </Grid>
    </React.Fragment>
  )
}

export default React.memo(NormalActivityForm)

import React, { useCallback } from 'react'
import { Box, Slide, Button } from '@material-ui/core'

const SliderEffectButton = React.memo((props: {
  debounceTime?: number
  defaultText?: string
  onClickText?: string
  onClickFunc?: Function
  className?: string
}) => {
  const {
    debounceTime = 1000,
    defaultText = 'click me',
    onClickText = 'thanks for clicking',
    onClickFunc = () => { console.log('no function passed') },
    className
  } = props
  const [checked, setChecked] = React.useState(false)

  const handleClick = useCallback(() => {
    setChecked((prev) => {
      if (prev) return true
      onClickFunc()
      setTimeout(() => { setChecked(false) }, debounceTime)
      return true
    })
  }, [onClickFunc, debounceTime])

  return (
    <Button
      variant="contained"
      onClick={handleClick}
      disabled={checked}
      className={className}
    >
      <Box
        width={60}
        height={30}
        display="flex"
        justifyContent="center"
        alignItems="center"
        overflow="hidden"
        position="relative"
      >
        <Slide
          direction="up"
          in={checked}
        >
          <Box position="absolute">{onClickText}</Box>
        </Slide>
        <Slide
          direction="down"
          in={!checked}
          appear={false}
        >
          <Box position="absolute">{defaultText}</Box>
        </Slide>
      </Box>
    </Button>
  )
})

export default SliderEffectButton

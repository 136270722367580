import React, { useMemo, createContext, useContext } from 'react'
import { PaginationReq, ManualWithdrawQuery, ManualWithdrawType, PermissionType } from '@golden/gdk-admin'
import { Link } from 'react-router-dom'
import { omitBy, isUndefined } from '@golden/utils'
import { isAfter, startOfDay, endOfDay, subYears, startOfYear, getTime } from 'date-fns'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import MuiButton from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import MuiTextField, { TextFieldProps } from '@material-ui/core/TextField'
import DropDown, { PropTypes as DropDownProps } from '../../default/form/DropDown'
import FormStateProvider from '../../default/form/FormStateProvider'
import FormField from '../../default/form/FormField'
import FormSubmitButton from '../../default/form/FormSubmitButton'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import useT from '../../../i18ns/admin/useT'
import { pipe, guaranteeNotUndefined, parseInt, guaranteeBetween, acceptUndefined, getValueFromChangeEvent, guaranteeBeKey, parseFloat, convertEmptyToUndefined, getValueFromValue } from '../../../utils/default/FormHelper'
import allRoutes, { Path } from '../route/route'
import { useRequestFromSearch, SearchToRequestFunc, InitialFormFunc, useChangeUrlSubmit } from '../../../utils/default/ComplexFlowHook'
import { FormValidation, createDefaultFormState, ValueGetter } from '../../../utils/default/FormHook'
import { createValidateDate, createValidateMin, createValidateMax } from '../../../utils/default/Validator'
import DateTimePicker from '../../default/form/DateTimePicker'
import RequiredText from '../../default/form/RequiredText'
import NumberInput from '../../default/form/NumberInput'
import { createShouldDisableDate } from '../../../utils/default/TimeHelper'
import { useChecker } from '../../../utils/admin/AdminRouteHook'
import manualWithdrawName from '../../../constants/default/manualWithdrawName'

export type ManualWithdrawRequest = PaginationReq & ManualWithdrawQuery

export interface ManualWithdrawFormType {
  account: string
  agent_account: string
  order_number: string
  method: ManualWithdrawType | '--'
  start_at: Date | null
  end_at: Date | null
  cash_start: string | number
  cash_end: string | number
  batch_number: string
}

export const searchToRequest: SearchToRequestFunc<ManualWithdrawRequest> = (search) => {
  const fiveYearsAgo = getTime(startOfYear(subYears(new Date(), 5)))
  const endOfToday = getTime(endOfDay(new Date()))
  const converted = {
    ...search,
    page: pipe(
      guaranteeNotUndefined,
      parseInt,
      (value) => guaranteeBetween(value, 1, Number.MAX_SAFE_INTEGER)
    )(search.page),
    method: acceptUndefined(search.method, pipe(
      (value) => guaranteeBeKey(value, Object.keys(manualWithdrawName))
    )),
    start_at: acceptUndefined(search.start_at, pipe(
      parseInt
    )),
    end_at: acceptUndefined(search.end_at, pipe(
      parseInt
    )),
    cash_start: acceptUndefined(search.cash_start, parseFloat),
    cash_end: acceptUndefined(search.cash_end, parseFloat)
  } as ManualWithdrawRequest
  if (converted.start_at && converted.end_at && converted.end_at < converted.start_at) throw new Error('The end time can\'t exceed the start time')
  if (converted.cash_start && converted.cash_end && converted.cash_end < converted.cash_start) throw new Error('The cash_end can\'t exceed the cash_start')
  return omitBy(converted, isUndefined) as ManualWithdrawRequest
}

export const initialForm: InitialFormFunc<ManualWithdrawFormType> = (defaultForm) => ({
  account: '',
  agent_account: '',
  order_number: '',
  method: '--',
  start_at: startOfDay(new Date()),
  end_at: endOfDay(new Date()),
  cash_start: '',
  cash_end: '',
  batch_number: '',
  ...defaultForm
})

const formToRequest = (form: ManualWithdrawFormType): ManualWithdrawRequest => {
  const cashStart = convertEmptyToUndefined(form.cash_start as string)
  const cashEnd = convertEmptyToUndefined(form.cash_end as string)
  const converted = {
    account: convertEmptyToUndefined(form.account),
    agent_account: convertEmptyToUndefined(form.agent_account),
    order_number: convertEmptyToUndefined(form.order_number),
    method: form.method === '--' ? undefined : form.method,
    start_at: form.start_at ? getTime(form.start_at) : undefined,
    end_at: form.end_at ? getTime(form.end_at) : undefined,
    cash_start: cashStart ? Number(cashStart) : undefined,
    cash_end: cashEnd ? Number(cashEnd) : undefined,
    batch_number: convertEmptyToUndefined(form.batch_number),
    page: 1
  } as ManualWithdrawRequest
  return omitBy(converted, isUndefined) as ManualWithdrawRequest
}

const getValueFromEvent: ValueGetter<ManualWithdrawFormType> = {
  account: getValueFromChangeEvent,
  agent_account: getValueFromChangeEvent,
  order_number: getValueFromChangeEvent,
  method: getValueFromChangeEvent,
  start_at: getValueFromValue,
  end_at: getValueFromValue,
  cash_start: getValueFromChangeEvent,
  cash_end: getValueFromChangeEvent,
  batch_number: getValueFromChangeEvent
}

const FormContext = createContext(createDefaultFormState(initialForm()))

const Button = React.memo(MuiButton)
const TextField = React.memo(MuiTextField)

const moneyInputProps = {
  thousandSeparator: true,
  decimalScale: 0,
  allowNegative: false,
  fixedDecimalScale: true
}

const DateRow: React.FC = React.memo(() => {
  const { value, handleChange } = useContext(FormContext)
  const { t } = useT()
  const {
    shouldDisableStartDate: shouldDisableCreateStartDate,
    shouldDisableEndDate: shouldDisableCreateEndDate
  } = useMemo(() => {
    const past = startOfYear(subYears(new Date(), 5))
    return createShouldDisableDate(value.start_at, value.end_at, past)
  }, [value.start_at, value.end_at])
  return (
    <React.Fragment>
      <Grid item xs={12} md={3}>
        <DateTimePicker
          value={value.start_at}
          onChange={handleChange('start_at')}
          label={`${t('common.updateAt')}${t('common.beginAt')}`}
          shouldDisableDate={shouldDisableCreateStartDate}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <DateTimePicker
          value={value.end_at}
          onChange={handleChange('end_at')}
          label={`${t('common.updateAt')}${t('common.endAt')}`}
          shouldDisableDate={shouldDisableCreateEndDate}
        />
      </Grid>
    </React.Fragment>
  )
})

const ManualWithdrawForm: React.FC = () => {
  const classes = useCommonStyles()
  const { t } = useT()
  const writableEdit = useChecker([PermissionType.MANUAL_WITHDRAW])

  const request = useRequestFromSearch({ searchToRequest })

  const defaultForm = useMemo(() => {
    return initialForm({
      ...request,
      method: request?.method ?? '--',
      start_at: request ? (request.start_at ? new Date(request.start_at) : null) : startOfDay(new Date()),
      end_at: request ? (request.end_at ? new Date(request.end_at) : null) : endOfDay(new Date())
    })
  }, [request])

  const handleSubmit = useChangeUrlSubmit({
    toAddNowTimestamp: true,
    formToRequest,
    encodePath: allRoutes.manualWithdraw.encodePath
  })

  const validation = useMemo(() => ({
    account: [],
    agent_account: [],
    order_number: [],
    method: [],
    start_at: [
      {
        func: (value, form, lastSubmitForm) => {
          if (value === null || value === '') {
            return { isPass: true, stop: false, newError: { start_at: null } }
          }
          return createValidateDate('start_at', t)(value, form, lastSubmitForm, null)
        },
        when: ['change']
      },
      {
        func: (value, form) => {
          if (form.end_at === null) {
            return { isPass: true, stop: false, newError: { start_at: null } }
          }
          if (isAfter(value as Date, form.end_at)) {
            return { isPass: false, stop: true, newError: { start_at: t('error.startMustBeforeEnd') } }
          }
          return { isPass: true, stop: false, newError: { start_at: null, end_at: null } }
        },
        when: ['change', 'beforeClickSubmit']
      }
    ],
    end_at: [
      {
        func: (value, form, lastSubmitForm) => {
          if (value === null || value === '') {
            return { isPass: true, stop: false, newError: { end_at: null } }
          }
          return createValidateDate('end_at', t)(value, form, lastSubmitForm, null)
        },
        when: ['change']
      },
      {
        func: (value, form) => {
          if (form.start_at === null) {
            return { isPass: true, stop: false, newError: { end_at: null } }
          }
          if (isAfter(form.start_at, value as Date)) {
            return { isPass: false, stop: true, newError: { end_at: t('error.endMustAfterStart') } }
          }
          return { isPass: true, stop: false, newError: { start_at: null, end_at: null } }
        },
        when: ['change', 'beforeClickSubmit']
      }
    ],
    cash_start: [
      {
        func: createValidateMin('cash_start', 'cash_end', t),
        when: ['change', 'beforeClickSubmit']
      }
    ],
    cash_end: [
      {
        func: createValidateMax('cash_start', 'cash_end', t),
        when: ['change', 'beforeClickSubmit']
      }
    ],
    batch_number: []
  } as FormValidation<ManualWithdrawFormType>), [t])

  const options = [{ name: t('common.all'), value: '--' }].concat(
    Object.keys(manualWithdrawName)
      .map((key) => key as ManualWithdrawType)
      .map((key) => ({
        name: t(manualWithdrawName[key]),
        value: key
      })) as any
  )

  return (
    <FormStateProvider
      context={FormContext}
      defaultValue={defaultForm}
      onSubmit={handleSubmit}
      validation={validation}
      getValueFromEvent={getValueFromEvent}
    >
      <Paper>
        <Box padding={4}>
          <Grid container direction="column" spacing={2}>
            {writableEdit && (
              <Grid item>
                <Grid container justify="flex-end">
                  <Grid item>
                    <MuiButton
                      component={Link}
                      to={Path.MANUAL_WITHDRAW_CREATE}
                      className={classes.purpleGradualButton}
                    >
                      {t('page.createManualWithdraw')}
                    </MuiButton>
                  </Grid>
                </Grid>
              </Grid>
            )}
            <Grid item>
              <Box
                paddingY={1.25}
                paddingX={2}
                className={classes.pinkTitleBar}
              >
                <Typography variant="h5">
                  {t('page.manualWithdraw')}
                </Typography>
              </Box>
            </Grid>
            <Grid item>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={12} md={3}>
                  <FormField<ManualWithdrawFormType, TextFieldProps>
                    context={FormContext}
                    component={TextField}
                    name="account"
                    label={t('common.playerAccount')}
                    placeholder={t('placeholder.inputPlayerAccount')}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <FormField<ManualWithdrawFormType, DropDownProps>
                    context={FormContext}
                    component={DropDown}
                    name="method"
                    options={options}
                    label={t('common.withdrawObject')}
                    fullWidth
                  />
                </Grid>
                <DateRow />
              </Grid>
            </Grid>
            <Grid item>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={12} md={3}>
                  <FormField<ManualWithdrawFormType, TextFieldProps>
                    context={FormContext}
                    component={NumberInput}
                    name="cash_start"
                    label={t('common.minWithdrawCash')}
                    placeholder={t('placeholder.inputMinWithdrawCash')}
                    inputProps={moneyInputProps}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <FormField<ManualWithdrawFormType, TextFieldProps>
                    context={FormContext}
                    component={NumberInput}
                    name="cash_end"
                    label={t('common.maxWithdrawCash')}
                    placeholder={t('placeholder.inputMaxWithdrawCash')}
                    inputProps={moneyInputProps}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <FormField<ManualWithdrawFormType, TextFieldProps>
                    context={FormContext}
                    component={TextField}
                    name="order_number"
                    label={t('common.serialNumber')}
                    placeholder={t('placeholder.inputSerialNumber')}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <FormField<ManualWithdrawFormType, TextFieldProps>
                    context={FormContext}
                    component={TextField}
                    name="agent_account"
                    label={t('common.agentAccount')}
                    placeholder={t('placeholder.inputAgentAccount')}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={12} md={3}>
                  <FormField<ManualWithdrawFormType, TextFieldProps>
                    context={FormContext}
                    component={TextField}
                    name="batch_number"
                    label={t('common.batchNumber')}
                    placeholder={t('placeholder.pleaseInput', { item: t('common.batchNumber') })}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <RequiredText />
            </Grid>
            <Grid item>
              <Grid container justify="flex-end">
                <Grid item>
                  <FormSubmitButton
                    component={Button}
                    context={FormContext}
                    type="submit"
                    className={classes.purpleGradualButton}
                  >
                    {t('common.search')}
                  </FormSubmitButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </FormStateProvider>
  )
}

export default React.memo(ManualWithdrawForm)

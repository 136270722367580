import { PMVersionType } from '@golden/gdk-campaign'
import TKeyType from '../../i18ns/admin/TKeyType'

const pmVersionName: { [key in PMVersionType]: TKeyType } = {
  [PMVersionType.OLD]: 'common.oldVersion',
  [PMVersionType.CUSTOM_NEW]: 'common.canSwitchOldVersion',
  [PMVersionType.CUSTOM]: 'common.canSwitchNewAndOldVersion',
  [PMVersionType.NEW]: 'common.newVersion'
}

export default pmVersionName

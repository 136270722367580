import React from 'react'
import Box from '@material-ui/core/Box'
import PlayerGameLoginHistoryForm from '../../../components/admin/player/PlayerGameLoginHistoryForm'
import PlayerGameLoginHistoryTable from '../../../components/admin/player/PlayerGameLoginHistoryTable'

const PlayerGameLoginHistoryPage: React.FC = () => {
  return (
    <Box paddingY={5}>
      <Box paddingX={5}>
        <PlayerGameLoginHistoryForm />
      </Box>
      <Box paddingTop={3}>
        <PlayerGameLoginHistoryTable />
      </Box>
    </Box>
  )
}

export default React.memo(PlayerGameLoginHistoryPage)

import React, { useCallback } from 'react'
import { GDKError } from '@golden/gdk-admin'
import useT from '../../../i18ns/admin/useT'
import { useDebouncedCallback } from 'use-debounce/lib'
import { isBefore } from 'date-fns'
import useGlobalDialog from '../../../providers/admin/dialog/useGlobalDialog'
import { createGlobalDialogConfig } from '../../../utils/default/DialogHelper'
import { useDialogFlow } from '../../../utils/default/DialogHook'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { useDialogHandleClick } from '../../../utils/default/ComplexFlowHook'
import Switch from '../Switch'
import { useChecker } from '../../../utils/admin/AdminRouteHook'

interface PropTypes {
  id: number
  isActive: boolean
  endAt: Date
  reload: () => void
}

const BannerNotificationTableStatus: React.FC<PropTypes> = (props) => {
  const { id, isActive, reload, endAt } = props
  const globalDialog = useGlobalDialog()
  const gdk = useGDK()
  const { t } = useT()
  const writable = useChecker()

  const [handleEnable] = useDebouncedCallback(useCallback(() => {
    globalDialog.setConfig(createGlobalDialogConfig({
      showIcon: false,
      message: t('dialog.confirmToggleOn')
    }))
    globalDialog.setOpen({ id: `enalbleBannerNotification-${id}`, value: true, isOK: false })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t]), 50)

  useDialogFlow(globalDialog, `enalbleBannerNotification-${id}`, () => {
    const subscription = gdk.notification.toggleBannerNotificationStatus(id, true)
      .subscribe({
        next: () => {
          globalDialog.setConfig(createGlobalDialogConfig({
            showIcon: false,
            message: t('dialog.toggleOnFinish'),
            showCancel: false
          }))
          globalDialog.setOpen({ id: `enalbleBannerNotificationSuccess-${id}`, value: true, isOK: false })
          reload()
        },
        error: (error: GDKError) => {
          globalDialog.setConfig(createGlobalDialogConfig({
            variant: 'error',
            showIcon: true,
            message: error.message,
            showCancel: false
          }))
          globalDialog.setOpen({ id: `enalbleBannerNotificationFail-${id}`, value: true, isOK: false })
        }
      })
    return () => {
      if ([`enalbleBannerNotificationSuccess-${id}`, `enalbleBannerNotificationFail-${id}`].includes(globalDialog.open.id)) {
        globalDialog.clearState()
        subscription.unsubscribe()
      }
    }
  }, [globalDialog.open, t])

  const handleDisable = useDialogHandleClick({
    dialogId: `disalbleBannerNotification-${id}`,
    globalDialog,
    changeDialogConfig: createGlobalDialogConfig({
      showIcon: false,
      message: t('dialog.confirmToggleOff')
    }),
    successDialogConfig: createGlobalDialogConfig({
      showIcon: false,
      message: t('dialog.toggleOffFinish'),
      showCancel: false
    }),
    getFailDialogConfig: (error: string) => createGlobalDialogConfig({
      variant: 'error',
      showIcon: true,
      message: error,
      showCancel: false
    }),
    payload: id,
    gdkFunc: (payload) => gdk.notification.toggleBannerNotificationStatus(payload, false),
    gdkFuncDependencies: [gdk],
    onSuccess: () => reload(),
    onSuccessDependencies: [reload]
  })
  return (<>
    <Switch
      value={isActive}
      onChange={(value) => {
        value ? handleEnable() : handleDisable()
        return false
      }}
      disabled={!writable}
    />
    { isBefore(endAt, new Date()) && (<div>{t('announcementStatus.outDate')}</div>) }
  </>)
}

export default React.memo(BannerNotificationTableStatus)

import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import picture from '../../assets/admin/images/3828545.svg'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import useT from '../../i18ns/admin/useT'

const useStyles = makeStyles((theme) => ({
  root: {
    height: `calc(100vh - ${Number(theme.mixins.toolbar.minHeight) + theme.spacing(1)}px)`,
    minHeight: 700
  },
  words: {
    fontSize: 32,
    fontWeight: 600,
    color: '#363636'
  }
}))

const ForbiddenPage: React.FC = () => {
  const classes = useStyles()
  const { t } = useT()
  return (
    <Grid
      className={classes.root}
      container
      direction="column"
      justify="center"
      alignItems="center"
      spacing={8}
    >
      <Grid item>
        <img src={picture} alt="maintaining" />
      </Grid>
      <Grid item>
        <Typography className={classes.words}>{t('common.yourPageWrong')}</Typography>
      </Grid>
    </Grid>
  )
}

export default React.memo(ForbiddenPage)

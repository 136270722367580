import React, { createContext, useMemo, useContext, useCallback, useEffect } from 'react'
import clsx from 'clsx'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import MuiButton from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import MuiTextField, { TextFieldProps } from '@material-ui/core/TextField'
import { useCommonStyles, useDialogStyles } from '../../../utils/admin/StyleHook'
import useT from '../../../i18ns/admin/useT'
import { InitialFormFunc, useRedirectHandleBack, useDialogHandleSubmit } from '../../../utils/default/ComplexFlowHook'
import { ValueGetter, createDefaultFormState, FormValidation } from '../../../utils/default/FormHook'
import { getValueFromChangeEvent, getCodeInputProps, getBankAccountInputProps, getCashInputProps } from '../../../utils/default/FormHelper'
import DropDown, { PropTypes as DropDownProps } from '../../../components/default/form/DropDown'
import FormStateProvider from '../../../components/default/form/FormStateProvider'
import FormSubmitButton from '../../../components/default/form/FormSubmitButton'
import RequiredText from '../../../components/default/form/RequiredText'
import FormField from '../../../components/default/form/FormField'
import NumberInput from '../../../components/default/form/NumberInput'
import { Path } from '../../../components/admin/route/route'
import useGlobalDialog from '../../../providers/admin/dialog/useGlobalDialog'
import { createGlobalDialogConfig } from '../../../utils/default/DialogHelper'
import { InternalWithdrawAccountForm } from '@golden/gdk-admin'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { createValidateNotEmpty, createValidateDropDownNotEmpty, createValidateMin, createValidateMax } from '../../../utils/default/Validator'
import useGDKStore from '../../../providers/admin/gdk/useGDKStore'

export interface FormType {
  name: string
  account: string
  bank: number | '--'
  code: string
  minCash: string
  maxCash: string
}

const initialForm: InitialFormFunc<FormType> = (defaultForm) => ({
  name: '',
  account: '',
  bank: '--',
  code: '',
  minCash: '',
  maxCash: '',
  ...defaultForm
})

const defaultValue = initialForm()

const getValueFromEvent: ValueGetter<FormType> = {
  name: getValueFromChangeEvent,
  account: getValueFromChangeEvent,
  bank: getValueFromChangeEvent,
  code: getValueFromChangeEvent,
  minCash: getValueFromChangeEvent,
  maxCash: getValueFromChangeEvent
}

const bankAccountInputProps = getBankAccountInputProps()

const cashInputProps = getCashInputProps()
const codeInputProps = getCodeInputProps()

const FormContext = createContext(createDefaultFormState(initialForm()))

const TextField = React.memo(MuiTextField)

const Button = React.memo(MuiButton)

const formToRequest = (form: FormType): InternalWithdrawAccountForm => ({
  real_name: form.name,
  bank_account: form.account,
  bank_code: Number(form.bank),
  code: form.code,
  cash_min: form.minCash.length === 0 ? undefined : Number(form.minCash),
  cash_max: form.maxCash.length === 0 ? undefined : Number(form.maxCash)
})

const AccountNumberInput: React.FC = React.memo(() => {
  const gdk = useGDK()
  const { value, error, handleChange, dispatch } = useContext(FormContext)
  const { t } = useT()

  useEffect(() => {
    if (value.account !== '') {
      const infoSubscription = gdk.finance.getDebitCardInfo({ bank_account: value.account })
        .subscribe({
          next: (res) => {
            if (res.bank === 0) {
              dispatch({ type: 'set', value: { ...value, bank: '--' } })
              return
            }
            dispatch({ type: 'set', value: { ...value, bank: res.bank } })
          },
          error: () => {
            dispatch({ type: 'set', value: { ...value, bank: '--' } })
          }
        })

      return () => {
        infoSubscription.unsubscribe()
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value.account, gdk])

  return (
    <NumberInput
      fullWidth
      label={t('common.bankAccount')}
      placeholder={t('placeholder.inputBankAccount')}
      inputProps={bankAccountInputProps}
      value={value.account}
      onChange={handleChange('account')}
      error={error.account !== null}
      helperText={error.account ?? ''}
      required
    />
  )
})

const FinanceInternalWithdrawCreatePage: React.FC = () => {
  const supportBanks = useGDKStore.finance.supportBanks()
  const commonClasses = useCommonStyles()
  const dialogClasses = useDialogStyles()
  const globalDialog = useGlobalDialog()
  const gdk = useGDK()
  const { t } = useT()
  const validation = useMemo(() => {
    return {
      name: [
        {
          func: createValidateNotEmpty('name', t),
          when: ['change', 'beforeClickSubmit']
        }
      ],
      account: [
        {
          func: createValidateNotEmpty('account', t),
          when: ['change', 'beforeClickSubmit']
        }
      ],
      bank: [
        {
          func: createValidateDropDownNotEmpty('bank', t),
          when: ['blur', 'beforeClickSubmit']
        }
      ],
      minCash: [
        {
          func: createValidateMin('minCash', 'maxCash', t),
          when: ['change', 'beforeClickSubmit']
        }
      ],
      maxCash: [
        {
          func: createValidateMax('minCash', 'maxCash', t),
          when: ['change', 'beforeClickSubmit']
        }
      ],
      code: [
        {
          func: createValidateNotEmpty('code', t),
          when: ['change', 'beforeClickSubmit']
        }
      ]
    } as FormValidation<FormType>
  }, [t])
  const [handleBack, handleDebouncedBack] = useRedirectHandleBack({ path: Path.FINANCE_INTERNAL_WITHDRAW_ACCOUNT })
  const { handleSubmit } = useDialogHandleSubmit({
    dialogId: 'createInternalWithdraw',
    globalDialog,
    getChangeDialogConfig: useCallback((form: FormType) => createGlobalDialogConfig({
      showIcon: false,
      notUseTypo: true,
      message: (
        <React.Fragment>
          <Typography className={dialogClasses.text} align="center">{t('dialog.confirmCreateInternalWithdraw')}</Typography>
          <Typography className={clsx(commonClasses.purpleWord, dialogClasses.text)} align="center">
            [{form.name}] [{supportBanks.find((item) => item.id === form.bank)?.name ?? ''}] [{form.account}]
          </Typography>
        </React.Fragment>
      )
    }), [t, supportBanks, commonClasses, dialogClasses]),
    getSuccessDialogConfig: useCallback(() => createGlobalDialogConfig({
      showIcon: false,
      showCancel: false,
      message: t('dialog.createInternalWithdraw')
    }), [t]),
    getFailDialogConfig: (error: string) => createGlobalDialogConfig({
      showIcon: true,
      variant: 'error',
      message: error
    }),
    formToRequest,
    gdkFunc: (payload) => gdk.finance.createInternalWithdrawAccount(payload),
    gdkFuncDependencies: [gdk],
    afterSuccessDialog: handleBack
  })

  const bankOptions = useMemo(
    () => [{ name: t('common.pleaseChooseBank'), value: '--' }].concat(supportBanks.map((bank) => ({ name: bank.name, value: bank.id })) as any),
    [supportBanks, t]
  )
  return (
    <FormStateProvider
      context={FormContext}
      defaultValue={defaultValue}
      onSubmit={handleSubmit}
      getValueFromEvent={getValueFromEvent}
      validation={validation}
    >
      <Box padding={5}>
        <Paper>
          <Box padding={4}>
            <Box
              paddingY={1.25}
              paddingX={2}
              className={commonClasses.pinkTitleBar}
            >
              <Typography variant="h5">
                {t('page.createInternalWithdraw')}
              </Typography>
            </Box>
            <Box paddingY={2}>
              <Grid container direction="column" spacing={3}>
                <Grid item>
                  <Grid container direction="row" spacing={2}>
                    <Grid item xs={12} md={6} lg={3}>
                      <FormField<FormType, TextFieldProps>
                        context={FormContext}
                        component={TextField}
                        name="name"
                        label={t('common.realName')}
                        placeholder={t('placeholder.inputRealName')}
                        fullWidth
                        required
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                      <AccountNumberInput />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container direction="row" spacing={2}>
                    <Grid item xs={12} md={6} lg={3}>
                      <FormField<FormType, DropDownProps>
                        component={DropDown}
                        context={FormContext}
                        name="bank"
                        label={t('common.bank')}
                        fullWidth
                        required
                        options={bankOptions}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container direction="row" spacing={2}>
                    <Grid item xs={12} md={6} lg={3}>
                      <FormField<FormType, TextFieldProps>
                        context={FormContext}
                        component={NumberInput}
                        name="minCash"
                        fullWidth
                        inputProps={cashInputProps}
                        label={t('common.minGetCash')}
                        placeholder={t('placeholder.inputMinGetCash')}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                      <FormField<FormType, TextFieldProps>
                        context={FormContext}
                        component={NumberInput}
                        name="maxCash"
                        fullWidth
                        inputProps={cashInputProps}
                        label={t('common.maxGetCash')}
                        placeholder={t('placeholder.inputMaxGetCash')}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container direction="row" spacing={2}>
                    <Grid item xs={12} md={6} lg={3}>
                      <FormField<FormType, TextFieldProps>
                        context={FormContext}
                        component={NumberInput}
                        name="code"
                        fullWidth
                        required
                        inputProps={codeInputProps}
                        label={t('common.googleCode')}
                        placeholder={t('placeholder.inputGoogleCode2')}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <RequiredText />
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Paper>
        <Box marginTop={2}>
          <Grid container direction="row" justify="flex-end" spacing={2}>
            <Grid item>
              <Button
                className={commonClasses.greyButton}
                onClick={handleDebouncedBack}
              >
                {t('common.cancel')}
              </Button>
            </Grid>
            <Grid item>
              <FormSubmitButton
                context={FormContext}
                component={Button}
                className={commonClasses.purpleGradualButton}
                type="submit"
              >
                {t('common.confirmCreate')}
              </FormSubmitButton>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </FormStateProvider>
  )
}

export default React.memo(FinanceInternalWithdrawCreatePage)

import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import TreeView from '@material-ui/lab/TreeView'
import TreeItem from '@material-ui/lab/TreeItem'
import AddBoxIcon from '@material-ui/icons/AddBox'
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox'

const useStyles = makeStyles((theme) => ({
  icon: (props: { color?: string }) => ({
    fontSize: 14,
    color: props.color ?? 'inherit'
  }),
  itemRoot: (props: { color?: string }) => ({
    fontSize: 14,
    color: props.color ?? 'inherit',
    '& > div': {
      backgroundColor: 'rgba(0, 0, 0, 0) !important'
    }
  }),
  itemGroup: {
    marginLeft: 11,
    paddingLeft: 14,
    borderLeft: `1px dashed ${theme.palette.grey[500]}`
  }
}))

export interface TreeNode {
  positions: string[]
  values: {
    [key: string]: {
      label: string
      leaves?: TreeNode
    }
  }
}

interface PropTypes {
  root: TreeNode
  defaultExpanded?: string[]
  color?: string
  onNodeToggle?: (event: React.ChangeEvent<{}>, nodeIds: string[]) => void
  onLabelClick?: (nodeId: string) => void
}

const Tree: React.FC<PropTypes> = (props) => {
  const { root, defaultExpanded, onNodeToggle, onLabelClick } = props
  const classes = useStyles(props)

  const renderNode = (key: string, node: TreeNode) => {
    return (
      <TreeItem
        classes={{
          root: classes.itemRoot,
          group: classes.itemGroup
        }}
        key={key}
        nodeId={key}
        label={
          <span onClick={() => {
            if (onLabelClick) onLabelClick(key)
          }}>
            {node.values[key].label}
          </span>
        }
      >
        {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          node.values[key].leaves?.positions.map((nextKey) => renderNode(nextKey, node.values[key].leaves!)) ?? null
        }
      </TreeItem>
    )
  }

  return (
    <TreeView
      defaultExpanded={defaultExpanded}
      defaultCollapseIcon={<IndeterminateCheckBoxIcon className={classes.icon} />}
      defaultExpandIcon={<AddBoxIcon className={classes.icon} />}
      onNodeToggle={onNodeToggle}
    >
      {root.positions.map((key) => renderNode(key, root))}
    </TreeView>
  )
}

export default React.memo(Tree)

import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Navigate, useLocation } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { getYear } from 'date-fns'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import useGDKStore from '../../../providers/admin/gdk/useGDKStore'
import allRoute, { routeOrder, Path } from './route'
import Permission from '../../../enums/default/Permission'
import { findAppRoute } from '../../../utils/default/RouteHelper'
import useT from '../../../i18ns/admin/useT'
import NotFoundPage from '../../default/frames/NotFoundPage'
import { getPass } from '../../../utils/admin/AdminRouteHelper'
import ForbiddenPage from '../../../views/admin/ForbiddenPage'
import { useUpdateVersion } from '../../../utils/default/VersionHook'
import useGlobalDialog from '../../../providers/admin/dialog/useGlobalDialog'
import { createGlobalDialogConfig } from '../../../utils/default/DialogHelper'
import { useDialogFlow } from '../../../utils/default/DialogHook'
import { useDialogStyles } from '../../../utils/admin/StyleHook'
import useGDK from '../../../providers/admin/gdk/useGDK'

const useStyles = makeStyles((theme) => ({
  text: {
    textAlign: 'right',
    bottom: theme.spacing(2),
    right: theme.spacing(4)
  },
  center: {
    textAlign: 'center'
  }
}))

const AppRoute: React.FC = () => {
  const classes = useStyles()
  const dialogClasses = useDialogStyles()
  const location = useLocation()
  const { t } = useT()

  const isLoggedIn = useGDKStore.auth.isLoggedIn()
  const isWrong = useGDKStore.auth.isWrong()
  const me = useGDKStore.admin.me()
  const gdk = useGDK()

  const route = findAppRoute(location.pathname, routeOrder, allRoute) ?? allRoute.notFound

  const { updated } = useUpdateVersion(gdk)
  const globalDialog = useGlobalDialog()
  useEffect(() => {
    if (updated && !route.hideUpdate) {
      globalDialog.setConfig(createGlobalDialogConfig({
        message: (
          <Grid container direction="column" alignItems="center">
            <span className={dialogClasses.title}>{t('dialog.newVersionDetected.title')}</span>
            <span className={dialogClasses.text}>{t('dialog.newVersionDetected.content')}</span>
          </Grid>
        ),
        showIcon: false,
        showCancel: false
      }))
      globalDialog.setOpen({ id: 'newVersionDetected', value: true, isOK: false })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updated, t])
  useDialogFlow(globalDialog, 'newVersionDetected', () => {
    if (updated) window.location.reload()
  }, [updated])

  if (isWrong) {
    return (<ForbiddenPage />)
  }

  if (
    !isLoggedIn &&
    !route.permissions.some((item) => item.type === 'accessible' && item.value === Permission.NOT_LOGIN)
  ) {
    return (<Navigate to={Path.LOGIN} />)
  }

  if (
    me !== null &&
    (me.is_need_update_password || me.is_need_authenticator) &&
    !route.permissions.some((item) => item.type === 'accessible' && item.value === Permission.NOT_GOOGLE_AUTHENTICATOR)
  ) {
    return (<Navigate to={Path.BASE_DATA} />)
  }

  if (me !== null && !getPass(route.permissions, me)) {
    return (<NotFoundPage />)
  }

  const { component: Component } = route

  const title = route.title === '' ? '' : ` | ${t(route.title)}`

  return (
    <React.Fragment>
      <Helmet>
        <title>{t('page.home')}{title}</title>
      </Helmet>
      <Component />
      { !!route.copyright &&
      (
        <Typography
          className={classes.text}
          style={{ position: route.copyright }}
        >
          {t('common.copyright', { date: getYear(new Date()) })}
        </Typography>
      ) }
    </React.Fragment>
  )
}

export default React.memo(AppRoute)

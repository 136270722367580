import React from 'react'
import Box from '@material-ui/core/Box'
import AdminReportProfitForm from '../../../components/admin/adminReport/AdminReportProfitForm'
import AdminReportProfitTable from '../../../components/admin/adminReport/AdminReportProfitTable'

const AdminReportProfitPage: React.FC = () => {
  return (
    <Box paddingY={5}>
      <Box paddingX={5}>
        <AdminReportProfitForm />
      </Box>
      <Box paddingTop={3}>
        <AdminReportProfitTable />
      </Box>
    </Box>
  )
}

export default React.memo(AdminReportProfitPage)

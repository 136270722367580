import React, { useState, useCallback, useMemo } from 'react'
import { useLocation } from 'react-router'
import { MuiThemeProvider, useTheme, createTheme } from '@material-ui/core/styles'
import { Role } from '@golden/gdk-admin'
import Box from '@material-ui/core/Box'
import EditRoleForm, { initialForm, RoleFormType } from '../../../components/admin/manager/EditRoleForm'
import { Path } from '../../../components/admin/route/route'
import useGlobalDialog from '../../../providers/admin/dialog/useGlobalDialog'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { useDialogHandleSubmit, useRedirectHandleBack, useGetDataByParams } from '../../../utils/default/ComplexFlowHook'
import { createGlobalDialogConfig } from '../../../utils/default/DialogHelper'
import useT from '../../../i18ns/admin/useT'
import { usePageFlow } from '../../../utils/default/PageFlowHook'
import LoadingAndErrorFrame from '../../../components/default/frames/LoadingAndErrorFrame'
import { FormError } from '../../../utils/default/FormHelper'
import { parsePath } from '../../../utils/default/RouteHelper'
import CustomThemeType from '../../../themes/admin/CustomThemeType'
import { useCommonStyles } from '../../../utils/admin/StyleHook'

const RoleManagementUpdatePage: React.FC = () => {
  const [defaultForm, setDefaultForm] = useState(initialForm())
  const [formError] = useState<Partial<FormError<RoleFormType>>>({})
  const { t } = useT()
  const gdk = useGDK()
  const pageFlow = usePageFlow()
  const location = useLocation()
  const commonClasses = useCommonStyles()
  const id = useMemo(() => {
    const { param } = parsePath(location.search, location.pathname, Path.ROLE_MANAGEMENT_UPDATE)
    return Number(param.id)
  }, [location.search, location.pathname])
  useGetDataByParams({
    path: Path.ROLE_MANAGEMENT_UPDATE,
    gdkFunc: (payload: { id: number }) => gdk.permissionRole.getRole(payload.id),
    gdkFuncDependencies: [gdk],
    onBeforeFetch: pageFlow.setLoadingStart,
    onSuccess: (res) => {
      pageFlow.setContentShow()
      setDefaultForm({
        ...res,
        parentId: res.parent_id
      })
    },
    onError: pageFlow.setGDKError
  })

  const globalDialog = useGlobalDialog()
  const [handleBack] = useRedirectHandleBack({ path: Path.ROLE_MANAGEMENT })
  const { handleSubmit } = useDialogHandleSubmit({
    dialogId: 'updateRole',
    globalDialog,
    getChangeDialogConfig: (form) => createGlobalDialogConfig({
      showIcon: false,
      message: (
        <span className={commonClasses.pre} style={{ textAlign: 'center' }}>
          {`${t('dialog.confirmUpdate')}\n`}
          <span className={commonClasses.purpleWord}>[{form.name}]</span>
        </span>
      )
    }),
    getSuccessDialogConfig: useCallback(() => createGlobalDialogConfig({
      showIcon: false,
      showCancel: false,
      message: t('dialog.updateSuccess')
    }), [t]),
    getFailDialogConfig: (error: string) => createGlobalDialogConfig({
      showIcon: true,
      variant: 'error',
      message: error
    }),
    formToRequest: (value: RoleFormType): Role => ({ ...value, parent_id: value.parentId }),
    gdkFunc: (payload: Role) => gdk.permissionRole.updateRole(id, payload),
    gdkFuncDependencies: [gdk, id],
    afterSuccessDialog: handleBack
  })
  return (
    <Box paddingY={5}>
      <LoadingAndErrorFrame { ...pageFlow.status }>
        <EditRoleForm
          roleId={id}
          titleText={t('page.updateRole')}
          okText={t('common.confirmUpdate')}
          defaultValue={defaultForm}
          onSubmit={handleSubmit}
          formError={formError}
          isUpdate
        />
      </LoadingAndErrorFrame>
    </Box>
  )
}

const RoleManagementUpdatePageContainer: React.FC = () => {
  const theme: CustomThemeType = useTheme()
  const permissionTheme = createTheme({
    ...theme,
    breakpoints: {
      values: {
        xl: 1920,
        lg: 1660,
        md: 1440,
        sm: 1100,
        xs: 0
      }
    }
  })
  return (
    <MuiThemeProvider theme={permissionTheme}>
      <RoleManagementUpdatePage />
    </MuiThemeProvider>
  )
}

export default React.memo(RoleManagementUpdatePageContainer)

import React, { useState, useRef } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Key } from 'ts-keycode-enum'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import useT from '../../i18ns/admin/useT'
import { useCommonStyles } from '../../utils/admin/StyleHook'

const useStyles = makeStyles((theme) => ({
  field: {
    textAlign: 'center',
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    minWidth: theme.spacing(2),
    maxWidth: theme.spacing(3),
    '&::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0
    },
    '&::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0
    }
  }
}))

export type FormType = [string, string, string, string, string, string]

interface PropTypes {
  onSubmit: (form: FormType) => FormType
  disabled?: boolean
}

const GoogleAuthenticatorForm: React.FC<PropTypes> = (props) => {
  const { onSubmit, disabled } = props
  const classes = useStyles()
  const commonClasses = useCommonStyles()
  const { t } = useT()
  const fieldRefs = [
    useRef<HTMLInputElement | null>(null),
    useRef<HTMLInputElement | null>(null),
    useRef<HTMLInputElement | null>(null),
    useRef<HTMLInputElement | null>(null),
    useRef<HTMLInputElement | null>(null),
    useRef<HTMLInputElement | null>(null)
  ]
  const [fields, setFields] = useState<FormType>(['', '', '', '', '', ''])
  return (
    <React.Fragment>
      <Grid item>
        <Grid container direction="row" spacing={1}>
          <Grid item xs={2}>
            <TextField
              inputRef={fieldRefs[0]}
              type="number"
              margin="dense"
              variant="outlined"
              disabled={disabled}
              value={fields[0]}
              onChange={(event) => {
                const value = event.target.value
                if (fieldRefs[1]?.current !== null && value.length === 1) {
                  fieldRefs[1].current.focus()
                }
                setFields((fields) => {
                  const newFields = fields.slice()
                  newFields[0] = value
                  return newFields as FormType
                })
              }}
              inputProps={{
                maxLength: 1,
                className: classes.field
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              inputRef={fieldRefs[1]}
              type="number"
              margin="dense"
              variant="outlined"
              disabled={disabled}
              value={fields[1]}
              onKeyDown={(event) => {
                if (
                  fieldRefs[0]?.current !== null &&
                  event.keyCode === Key.Backspace &&
                  fields[1].length === 0
                ) {
                  fieldRefs[0].current.focus()
                }
              }}
              onChange={(event) => {
                const value = event.target.value
                if (fieldRefs[2]?.current !== null && value.length === 1) {
                  fieldRefs[2].current.focus()
                }
                setFields((fields) => {
                  const newFields = fields.slice()
                  newFields[1] = value
                  return newFields as FormType
                })
              }}
              inputProps={{
                maxLength: 1,
                className: classes.field
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              inputRef={fieldRefs[2]}
              type="number"
              margin="dense"
              variant="outlined"
              disabled={disabled}
              value={fields[2]}
              onKeyDown={(event) => {
                if (
                  fieldRefs[1]?.current !== null &&
                  event.keyCode === Key.Backspace &&
                  fields[2].length === 0
                ) {
                  fieldRefs[1].current.focus()
                }
              }}
              onChange={(event) => {
                const value = event.target.value
                if (fieldRefs[3]?.current !== null && value.length === 1) {
                  fieldRefs[3].current.focus()
                }
                setFields((fields) => {
                  const newFields = fields.slice()
                  newFields[2] = value
                  return newFields as FormType
                })
              }}
              inputProps={{
                maxLength: 1,
                className: classes.field
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              inputRef={fieldRefs[3]}
              type="number"
              margin="dense"
              variant="outlined"
              disabled={disabled}
              value={fields[3]}
              onKeyDown={(event) => {
                if (
                  fieldRefs[2]?.current !== null &&
                  event.keyCode === Key.Backspace &&
                  fields[3].length === 0
                ) {
                  fieldRefs[2].current.focus()
                }
              }}
              onChange={(event) => {
                const value = event.target.value
                if (fieldRefs[4]?.current !== null && value.length === 1) {
                  fieldRefs[4].current.focus()
                }
                setFields((fields) => {
                  const newFields = fields.slice()
                  newFields[3] = value
                  return newFields as FormType
                })
              }}
              inputProps={{
                maxLength: 1,
                className: classes.field
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              inputRef={fieldRefs[4]}
              type="number"
              margin="dense"
              variant="outlined"
              disabled={disabled}
              value={fields[4]}
              onKeyDown={(event) => {
                if (
                  fieldRefs[3]?.current !== null &&
                  event.keyCode === Key.Backspace &&
                  fields[4].length === 0
                ) {
                  fieldRefs[3].current.focus()
                }
              }}
              onChange={(event) => {
                const value = event.target.value
                if (fieldRefs[5]?.current !== null && value.length === 1) {
                  fieldRefs[5].current.focus()
                }
                setFields((fields) => {
                  const newFields = fields.slice()
                  newFields[4] = value
                  return newFields as FormType
                })
              }}
              inputProps={{
                maxLength: 1,
                className: classes.field
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              inputRef={fieldRefs[5]}
              type="number"
              margin="dense"
              variant="outlined"
              disabled={disabled}
              value={fields[5]}
              onKeyDown={(event) => {
                if (
                  fieldRefs[4]?.current !== null &&
                  event.keyCode === Key.Backspace &&
                  fields[5].length === 0
                ) {
                  fieldRefs[4].current.focus()
                }
              }}
              onChange={(event) => {
                const value = event.target.value
                setFields((fields) => {
                  const newFields = fields.slice()
                  newFields[5] = value
                  return newFields as FormType
                })
              }}
              inputProps={{
                maxLength: 1,
                className: classes.field
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Box paddingTop={1}>
          <Grid container justifyContent="center">
            {!disabled && (<Button
              className={commonClasses.purpleGradualButton}
              disabled={!fields.every((field) => field.length === 1)}
              onClick={(event) => {
                event.preventDefault()
                onSubmit(fields)
                setFields(['', '', '', '', '', ''])
              }}
            >
              {t('common.confirmSave')}
            </Button>)}
          </Grid>
        </Box>
      </Grid>
    </React.Fragment>
  )
}

export default GoogleAuthenticatorForm

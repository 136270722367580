import React, { useState } from 'react'
import Box from '@material-ui/core/Box'
import FinanceBankCodeToolbar from '../../../components/admin/finance/FinanceBankCodeToolbar'
import FinanceBankCodeTable from '../../../components/admin/finance/FinanceBankCodeTable'
import ScrollablePaper from '../../../components/default/present/ScrollablePaper'
import { SearchToRequestFunc } from '../../../utils/default/ComplexFlowHook'
import { BankMappingForwarderInfo } from '@golden/gdk-admin'
import { usePageFlow } from '../../../utils/default/PageFlowHook'
import { isUndefined, omitBy } from '@golden/utils'

export interface RequestType {
  slug: string
}

export const searchToRequest: SearchToRequestFunc<RequestType> = (search) => {
  const converted = {
    ...search,
    slug: search.slug
  } as RequestType
  return omitBy(converted, isUndefined) as RequestType
}

const FinanceBankCodePage: React.FC = () => {
  const [list, setList] = useState<Array<BankMappingForwarderInfo & { edit_code: string }>>([])
  const pageFlow = usePageFlow(true)

  return (
    <Box paddingY={5}>
      <ScrollablePaper marginX={5}>
        <Box padding={4}>
          <FinanceBankCodeToolbar list={list} pageFlow={pageFlow}/>
        </Box>
        <Box paddingTop={1} paddingBottom={5} paddingX={4}>
          <FinanceBankCodeTable list={list} setList={setList} pageFlow={pageFlow} />
        </Box>
      </ScrollablePaper>
    </Box>
  )
}

export default React.memo(FinanceBankCodePage)

import { AdminDepositStatusType } from '@golden/gdk-admin'
import TKeyType from '../../i18ns/admin/TKeyType'

const adminDepositStatusName: { [key in AdminDepositStatusType]: TKeyType } = {
  [AdminDepositStatusType.IN_PROGRESS]: 'common.processing',
  [AdminDepositStatusType.SUCCESSFUL]: 'common.success',
  [AdminDepositStatusType.CANCEL]: 'common.cancel'
}

export default adminDepositStatusName

import React, { useRef, useState } from 'react'
import { useCommonStyles, useDialogStyles } from '../../../utils/admin/StyleHook'
import useT from '../../../i18ns/admin/useT'
import { makeStyles } from '@material-ui/core/styles'
import useGlobalDialog from '../../../providers/admin/dialog/useGlobalDialog'
import { createGlobalDialogConfig } from '../../../utils/default/DialogHelper'
import { openQQ } from '@golden/shared'
import { useDialogFlow } from '../../../utils/default/DialogHook'
import AppImage from '../AppImage'
import { getServerUrl } from '../../../utils/default/StageHelper'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import CustomThemeType from '../../../themes/admin/CustomThemeType'
import ImagePreview from '../ImagePreview'
import CopyButton from '../CopyButton'
import { getTargetURL } from '@golden/gdk-campaign'

const useStyles = makeStyles((theme: CustomThemeType) => ({
  hiddenArea: {
    opacity: 0,
    position: 'absolute',
    top: '-9999px',
    left: '-9999px'
  },
  title: {
    textAlign: 'center',
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(2),
    color: theme.custom.palette.text.dialog,
    fontSize: theme.custom.typography.dialog
  },
  content: {
    backgroundColor: theme.palette.grey[100],
    marginTop: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    border: 'solid 1px #e7e7e7',
    wordBreak: 'break-word',
    width: '500px',
    textAlign: 'left'
  },
  tip: {
    backgroundColor: theme.palette.grey[300],
    textAlign: 'center',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  },
  icon: {
    width: '24px',
    height: '24px',
    objectFit: 'contain',
    marginLeft: theme.spacing(1)
  },
  imageContainer: {
    width: 100,
    height: 100,
    marginBottom: 8,
    marginRight: 8,
    cursor: 'pointer'
  }
}))

interface PropType {
  detail: {
    title: string
    content: string
    qq: string
    image_urls: string[]
  }
  open: boolean
  handleClose: () => void
}

const AgentAnnouncementDialog: React.FC<PropType> = (props) => {
  const { detail, open, handleClose } = props
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { title, content, qq, image_urls } = detail
  const classes = useStyles()
  const commonClasses = useCommonStyles()
  const dialogClasses = useDialogStyles()
  const { t } = useT()
  const globalDialog = useGlobalDialog()
  const domTextArea = useRef<HTMLTextAreaElement | null>(null)

  const [qqLoading, setQQLoading] = useState(false)
  const clickQQ = () => {
    if (qqLoading) return
    globalDialog.setConfig(createGlobalDialogConfig({
      message: t('common.ifOpenQQ'),
      showIcon: false,
      showCancel: true
    }))
    globalDialog.setOpen({
      id: `openQQ-${qq}`,
      value: true,
      isOK: false
    })
  }
  useDialogFlow(globalDialog, `openQQ-${qq}`, () => {
    setQQLoading(true)
    openQQ(qq)
    setTimeout(() => {
      setQQLoading(false)
      globalDialog.setConfig(createGlobalDialogConfig({
        message: (
          <>
            <Box>{t('common.openQQFailed')}</Box>
            <CopyButton content={qq}>{t('common.copyQQNumber')}</CopyButton>
          </>
        ),
        showIcon: false,
        showCancel: true,
        showOK: false,
        cancelText: t('common.ignore')
      }))
      globalDialog.setOpen({
        id: `copyQQ-${qq}`,
        value: true,
        isOK: false
      })
    }, 3000)
  })

  const [previewSrc, setPreviewSrc] = useState('')

  return (
    <Dialog maxWidth="sm" open={open}>
      <DialogTitle className={classes.title}>{title}</DialogTitle>
      <DialogContent>
        <Box className={classes.content}>
          <Box className={classes.tip}>{ t('dialog.agentAnnouncementTip') }</Box>
          <Box dangerouslySetInnerHTML={{ __html: content }} />
          {!!qq && (
            <span
              className={commonClasses.anchor}
              onClick={() => clickQQ()}
            >
              {t('common.qqAddFriendLink')}
            </span>
          )}
          <textarea
            ref={domTextArea}
            readOnly
            className={classes.hiddenArea}
            value={qq}
          />
          {!!image_urls.length && (
            <Box display="flex" flexWrap="wrap" marginTop={2}>
            { image_urls.map((src) => (
              <Box
                className={classes.imageContainer}
                onClick={() => { setPreviewSrc(src) }}
                key={src}
              >
                <AppImage
                  src={src}
                  webp={getTargetURL(getServerUrl('image'), src.replace(/\.(jpg|png)$/i, '.webp'))}
                  lqip={getTargetURL(getServerUrl('image'), src.replace(/\.(jpg|png)$/i, '-blured.$1'))}
                />
              </Box>
            )) }
          </Box>
          )}
          { !!previewSrc && (
            <ImagePreview
              src={previewSrc}
              webp={getTargetURL(getServerUrl('image'), previewSrc.replace(/\.(jpg|png)$/i, '.webp'))}
              lqip={getTargetURL(getServerUrl('image'), previewSrc.replace(/\.(jpg|png)$/i, '-blured.$1'))}
              handleClose={() => setPreviewSrc('')}
            />
          ) }
        </Box>
      </DialogContent>
      <Box display="flex" justifyContent="center">
        <Button onClick={handleClose} classes={{ root: dialogClasses.okButton }}>
          {t('common.confirm')}
        </Button>
      </Box>
    </Dialog>
  )
}

export default React.memo(AgentAnnouncementDialog)

import { AssistantType } from '@golden/gdk-admin'
import TKeyType from '../../i18ns/admin/TKeyType'

const assistantTypeName: { [key in AssistantType]: TKeyType } = {
  [AssistantType.ACCOUNT_VIEWER]: 'assistant.accountViewer',
  [AssistantType.INFO_VIEWER]: 'assistant.infoViewer',
  [AssistantType.REPORT_VIEWER]: 'assistant.reportViewer',
  [AssistantType.CUSTOM_VIEWER]: 'assistant.customViewer'
}

export default assistantTypeName

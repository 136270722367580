import React from 'react'
import Box from '@material-ui/core/Box'
import PlayerIpMonitoringForm from '../../../components/admin/player/PlayerIpMonitoringForm'
import PlayerIpMonitoringTable from '../../../components/admin/player/PlayerIpMonitoringTable'

const PlayerIPMonitoringPage: React.FC = () => {
  return (
    <Box paddingY={5}>
      <Box paddingX={5}>
        <PlayerIpMonitoringForm />
      </Box>
      <Box paddingTop={3}>
        <PlayerIpMonitoringTable />
      </Box>
    </Box>
  )
}

export default React.memo(PlayerIPMonitoringPage)

import { ActivityApplicationStatusType } from '@golden/gdk-admin'
import TKeyType from '../../i18ns/admin/TKeyType'

const activityApplicationStatusName: { [key in ActivityApplicationStatusType]: TKeyType } = {
  [ActivityApplicationStatusType.CREATED]: 'common.unprocessed',
  [ActivityApplicationStatusType.LOCKED]: 'common.locked',
  [ActivityApplicationStatusType.PASSED]: 'common.passed',
  [ActivityApplicationStatusType.REJECTED]: 'common.unpassed',
  [ActivityApplicationStatusType.CANCELLED]: 'common.cancelled'
}

export default activityApplicationStatusName


import { ForwarderSecret, ForwarderSecretFormatType } from '@golden/gdk-admin'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import React, { useContext, useEffect } from 'react'
import FileTextField from '../../../components/default/form/FileTextField'
import useT from '../../../i18ns/admin/useT'
import { FormStateType } from './EditFinanceCourierForm'
import MuiTextField from '@material-ui/core/TextField'
import { Typography } from '@material-ui/core'

const TextField = React.memo(MuiTextField)

export const SecretBlock: React.FC<{
  requireSecret?: boolean
  secrets: ForwarderSecret[]
  context: React.Context<FormStateType>
}> = React.memo((props) => {
  const { t } = useT()
  const { secrets, requireSecret, context } = props
  const { form, setForm, error, setError } = useContext<FormStateType>(context)
  useEffect(() => {
    setForm({ ...form, secret: Object.fromEntries(secrets.map((s) => [s.key, ''])) })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [secrets])
  const validate = (secret: Record<string, string>) => {
    const skipValidation = Object.entries(secret).every(([_, value]) => !value) && !requireSecret
    if (skipValidation) return setError({ ...error, secret: {} })
    setError({
      ...error,
      secret: Object.entries(secret).reduce((pre, cur) => ({
        ...pre,
        [cur[0]]: !cur[1] ? t('error.mustNotEmpty') : null
      }), {})
    })
  }
  if (form.slug === '') return null
  return (<>
    <Grid item container direction="row" spacing={1} wrap="wrap">
      <Grid item container wrap="wrap" xs={12} md={12} lg={12}>
        <Box marginTop={2} width="100%">
          <Typography variant="h4">{t('common.forwarderSetting')}</Typography>
        </Box>
        { !requireSecret && (<Box marginTop={1}>
          <Typography>{t('common.forwarderSettingTip')}</Typography>
        </Box>) }
      </Grid>
      {
        secrets.map((secret) => (
          <Grid item xs={12} md={6} lg={3} key={secret.key}>
            {
              secret.format === ForwarderSecretFormatType.FILE
                ? (<FileTextField
                  onChange={({ content }) => {
                    const formSecret = { ...form.secret, [secret.key]: content }
                    setForm({ ...form, secret: formSecret })
                    validate(formSecret)
                  }}
                  error={!!error.secret[secret.key]}
                  label={secret.name}
                  helperText={error.secret[secret.key] ?? ''}
                  accept=".pem"
                  fullWidth
                  required={requireSecret}
                />)
                : (<TextField
                  defaultValue={form.secret[secret.key]}
                  onChange={(e) => {
                    const value = e.target.value
                    const formSecret = { ...form.secret, [secret.key]: value }
                    setForm({ ...form, secret: formSecret })
                    validate(formSecret)
                  }}
                  error={!!error.secret[secret.key]}
                  label={secret.name}
                  placeholder={`${t('placeholder.input')}${secret.name}`}
                  helperText={error.secret[secret.key] ?? ''}
                  fullWidth
                  type={secret.format === ForwarderSecretFormatType.MASK ? 'password' : 'text'}
                  inputProps={{ autoComplete: 'new-password' }}
                  required={requireSecret}
                />)
            }
          </Grid>
        ))
      }
    </Grid>
  </>)
})

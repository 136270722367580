import React, { useCallback, useMemo } from 'react'
import recordSearchTimeName from '../../../../constants/admin/recordSearchTimeName'
import useT from '../../../../i18ns/admin/useT'
import Grid from '@material-ui/core/Grid'
import DropDown from '../../../default/form/DropDown'
import { RecordSearchTimeType } from '@golden/gdk-admin'
import { useInternValue } from '../../../../utils/default/FormHook'

export interface PropTypes {
  value?: RecordSearchTimeType
  onChange?: (value: RecordSearchTimeType) => void
  onBlur?: () => void
  error?: boolean
  helperText?: string
}

const SearchDropDown: React.FC<PropTypes> = React.memo((props) => {
  const { value, onChange, onBlur, error, helperText } = props
  const { t } = useT()
  const [internValue, setInternValue] = useInternValue<string | number>(value ?? RecordSearchTimeType.SETTLED_AT)
  const searchTimeOptions = useMemo(() => {
    return Object.keys(recordSearchTimeName)
      .map((key) => ({ name: t(recordSearchTimeName[key as RecordSearchTimeType]), value: key }))
  }, [t])
  return (
    <Grid item xs={12} md={6} lg={3}>
      <DropDown
        value={internValue}
        options={searchTimeOptions}
        label={t('common.searchDate')}
        fullWidth
        required
        onChange={useCallback((event) => {
          const value = event.target.value
          setInternValue(value)
          if (onChange) onChange(value)
        }, [onChange, setInternValue])}
        onBlur={onBlur}
        error={error ?? false}
        helperText={helperText ?? ''}
      />
    </Grid>
  )
})

export default React.memo(SearchDropDown)

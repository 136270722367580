import React, { useState, useMemo, createContext, Dispatch, SetStateAction } from 'react'
import { omitBy, isUndefined } from '@golden/utils'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import useT from '../../../i18ns/admin/useT'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { formatCount, formatMoney, createTableData } from '../../../utils/default/TableHelper'
import { ActivityOverview, ActivityAgentOverview, ActivityOverviewQuery } from '@golden/gdk-admin'
import { usePageFlow } from '../../../utils/default/PageFlowHook'
import { searchToRequest } from './ActivityRecordForm'
import { useRequestFromSearch, useGetDataByPayload } from '../../../utils/default/ComplexFlowHook'
import ScrollablePaper from '../../default/present/ScrollablePaper'
import CoreTable from '../../default/present/CoreTable'
import allRoute from '../route/route'
import LoadingAndErrorFrame from '../../default/frames/LoadingAndErrorFrame'
import MemoPopoverWithContext from '../../default/memo/MemoPopoverWithContext'
import { MemoPopoverPayload, createDefaultMemoPopoverPayload } from '../../default/memo/MemoPopover'
import { Link } from 'react-router-dom'
import OnOffCheckbox from '../../default/form/OnOffCheckbox'

interface ActivityRowType {
  id: number
  type: string
  title: string
  cash_count: string
  user_count: string
  cash_sum: string
  title_name_string: JSX.Element | string
}

interface AgentRowType {
  id: number
  account: JSX.Element | string
  cash_count: string
  user_count: string
  cash_sum: string
}

interface OverviewResType {
  activity: ActivityOverview[]
  agent: ActivityAgentOverview[]
}

const MemoContext = createContext<[MemoPopoverPayload, Dispatch<SetStateAction<MemoPopoverPayload>>]>([
  createDefaultMemoPopoverPayload(),
  () => {}
])

const ActivityRecordOverviewTable: React.FC = () => {
  const classes = useCommonStyles()
  const { t } = useT()
  const gdk = useGDK()
  const [showAllActivityData, setShowAllActivityData] = useState<boolean>(false)
  const [showAllAgentData, setShowAllAgentData] = useState<boolean>(false)
  const [list, setList] = useState<OverviewResType>({
    activity: [],
    agent: []
  })
  const pageFlow = usePageFlow()
  const request = useRequestFromSearch({ searchToRequest })
  useGetDataByPayload({
    payload: request as ActivityOverviewQuery,
    gdkFunc: (payload) => gdk.activity.getActivityRecordOverview(payload),
    gdkFuncDependencies: [gdk],
    onBeforeFetch: pageFlow.setLoadingStart,
    onSuccess: (res: OverviewResType) => {
      setList(res)
      pageFlow.setContentShow()
    },
    onError: pageFlow.setGDKError,
    canLoadData: request !== undefined
  })
  const tableClasses = useMemo(() => ({
    head: classes.greyTableHead,
    row: classes.tableRow,
    cellHead: classes.tableCellHead,
    cellBody: classes.nowrap
  }), [classes])
  const activityRow = useMemo(() => {
    return list.activity.map((item, index) => {
      return {
        id: index,
        type: item.type,
        title: item.title,
        cash_count: formatCount(item.cash_count),
        user_count: formatCount(item.user_count),
        cash_sum: formatMoney(item.cash_sum),
        title_name_string: (
          <Link
            to={allRoute.activityRecordSub.encodePath(
              {
                param: {},
                search: omitBy({
                  activity: item.type,
                  account: request?.account,
                  agent_account: request?.agent_account,
                  start_at: request?.start_at,
                  end_at: request?.end_at,
                  type: 2,
                  page: 1,
                  former_page_type: request?.activity_type
                }, isUndefined)
              }
            )}
          >
            {item.title}
          </Link>
        )

      } as ActivityRowType
    }).concat({
      id: -1,
      type: '',
      title: '',
      cash_count: formatCount(list.activity.map((item) => item.cash_count).reduce((prev, current) => (prev + current), 0)),
      user_count: formatCount(list.activity.map((item) => item.user_count).reduce((prev, current) => (prev + current), 0)),
      cash_sum: formatMoney(list.activity.map((item) => parseFloat(item.cash_sum)).reduce((prev, current) => (prev + current), 0)),
      title_name_string: t('common.totalSum')
    }).filter((item) => {
      if (showAllActivityData) return true
      return (item.cash_count !== formatCount(0))
    })
  }, [list.activity, showAllActivityData, t, request])
  const activityData = useMemo(() => {
    return createTableData<ActivityRowType>(
      {
        id: {
          label: '',
          value: 'id'
        },
        type: {
          label: '',
          value: 'type',
          align: 'center'
        },
        title: {
          label: '',
          value: 'title',
          align: 'center'
        },
        cash_count: {
          label: t('common.totalBonusTimesCount'),
          value: 'cash_count',
          align: 'center'
        },
        user_count: {
          label: t('common.totalBonusPersonCount'),
          value: 'user_count',
          align: 'center'
        },
        cash_sum: {
          label: t('common.totalActivityBonus'),
          value: 'cash_sum',
          align: 'center'
        },
        title_name_string: {
          label: t('common.activityName'),
          value: 'title_name_string',
          align: 'center'
        }
      },
      [
        'title_name_string',
        'cash_count',
        'user_count',
        'cash_sum'
      ],
      activityRow,
      'id'
    )
  }, [t, activityRow])

  const agentRows = useMemo(() => {
    return list.agent.map((item) => {
      return {
        id: item.id,
        account: (
          <Link
            to={allRoute.activityRecordAgentSub.encodePath(
              {
                param: { id: item.account.toLocaleUpperCase() === 'RMB' ? item.account : item.id },
                search: omitBy({
                  activity_type: request?.activity_type,
                  account: request?.account,
                  agent_account: request?.agent_account,
                  start_at: request?.start_at,
                  end_at: request?.end_at,
                  page: 1,
                  former_page_type: request?.activity_type
                }, isUndefined)
              }
            )}
          >
            {item.account}
          </Link>
        ),
        cash_count: formatCount(item.cash_count),
        user_count: formatCount(item.user_count),
        cash_sum: formatMoney(item.cash_sum)
      } as AgentRowType
    }).concat({
      id: -1,
      account: t('common.totalSum'),
      cash_count: formatCount(list.agent.map((item) => item.cash_count).reduce((prev, current) => (prev + current), 0)),
      user_count: formatCount(list.agent.map((item) => item.user_count).reduce((prev, current) => (prev + current), 0)),
      cash_sum: formatMoney(list.agent.map((item) => parseFloat(item.cash_sum)).reduce((prev, current) => (prev + current), 0))
    }).filter((item) => {
      if (showAllAgentData) return true
      return (item.cash_count !== formatCount(0))
    })
  }, [list.agent, showAllAgentData, request, t])
  const agentData = useMemo(() => {
    return createTableData<AgentRowType>(
      {
        id: {
          label: '',
          value: 'id'
        },
        account: {
          label: t('common.emperor'),
          value: 'account',
          align: 'center'
        },
        cash_count: {
          label: t('common.totalBonusTimesCount'),
          value: 'cash_count',
          align: 'center'
        },
        user_count: {
          label: t('common.totalBonusPersonCount'),
          value: 'user_count',
          align: 'center'
        },
        cash_sum: {
          label: t('common.totalActivityBonus'),
          value: 'cash_sum',
          align: 'center'
        }
      },
      [
        'account',
        'cash_count',
        'user_count',
        'cash_sum'
      ],
      agentRows,
      'id'
    )
  }, [t, agentRows])

  return (
    <MemoPopoverWithContext memoPopoverContext={MemoContext}>
      <Box paddingBottom={3}>
        <ScrollablePaper marginX={5}>
          <Box padding={5}>
            <LoadingAndErrorFrame { ...pageFlow.status }>
              <Box
                paddingY={1.25}
                paddingX={2}
                className={classes.pinkTitleBar}
              >
                <Typography variant="h5">
                  {t('common.statisticByActivity')}
                </Typography>
              </Box>
              <Box display="flex" justifyContent="flex-end">
                <OnOffCheckbox
                  label={t('common.allData')}
                  value={showAllActivityData}
                  onChange={() => { setShowAllActivityData((!showAllActivityData)) }}
                />
              </Box>
              <CoreTable
                data={activityData}
                total={1}
                classes={tableClasses}
                hasHeadWithNoData
              />
            </LoadingAndErrorFrame>
          </Box>
        </ScrollablePaper>
      </Box>
      <Box paddingBottom={3}>
        <ScrollablePaper marginX={5}>
          <Box padding={5}>
            <LoadingAndErrorFrame { ...pageFlow.status }>
              <Box
                paddingY={1.25}
                paddingX={2}
                className={classes.pinkTitleBar}
              >
                <Typography variant="h5">
                  {t('common.statisticByAgent')}
                </Typography>
              </Box>
              <Box display="flex" justifyContent="flex-end">
                <OnOffCheckbox
                  label={t('common.allData')}
                  value={showAllAgentData}
                  onChange={() => { setShowAllAgentData((!showAllAgentData)) }}
                />
              </Box>
              <CoreTable
                data={agentData}
                total={1}
                classes={tableClasses}
                hasHeadWithNoData
              />
            </LoadingAndErrorFrame>
          </Box>
        </ScrollablePaper>
      </Box>
    </MemoPopoverWithContext>
  )
}

export default React.memo(ActivityRecordOverviewTable)

import React, { useState, useMemo } from 'react'
import { PaginationRes, SystemNotification, AnnouncementStatusType } from '@golden/gdk-admin'
import Box from '@material-ui/core/Box'
import ScrollablePaper from '../../../components/default/present/ScrollablePaper'
import LoadingAndErrorFrame from '../../../components/default/frames/LoadingAndErrorFrame'
import CoreTable from '../../../components/default/present/CoreTable'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import useT from '../../../i18ns/admin/useT'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { usePageFlow } from '../../../utils/default/PageFlowHook'
import { createDefaultPaginationData, createTableData } from '../../../utils/default/TableHelper'
import { useReload, useGetDataByPayload, SearchToRequestFunc, useRequestFromSearch, usePaginationClickAndChangeUrl } from '../../../utils/default/ComplexFlowHook'
import { useChecker } from '../../../utils/admin/AdminRouteHook'
import DateTime from '../../../components/default/present/DateTime'
import AnnouncementTableContent from '../../../components/admin/forestage/AnnouncementTableContent'
import NotificationTableStatus from '../../../components/admin/forestage/NotificationTableStatus'
import SystemNotificationListButtons from '../../../components/admin/forestage/SystemNotificationListButtons'
import channelName from '../../../constants/admin/channelName'
import notificationTypeName from '../../../constants/admin/notificationTypeName'
import { acceptUndefined, parseInt, pipe, guaranteeBetween } from '../../../utils/default/FormHelper'
import allRoute from '../../../components/admin/route/route'
import { renderContent } from '@golden/tiptap-react'
import { useAsyncEffect } from '../../../utils/default/useAsyncEffect'

interface RowType {
  id: number
  title: string
  situation: string
  content: React.ReactElement
  channels: React.ReactElement
  updatedAt: React.ReactElement
  operator: string
  status: React.ReactElement
  functions: React.ReactElement
}

export const searchToRequest: SearchToRequestFunc<{ page: number }> = (search) => ({
  page: acceptUndefined(search.page, pipe(
    parseInt,
    (value) => guaranteeBetween(value, 1, Number.MAX_SAFE_INTEGER)
  )) || 1
})

const AnnouncementNotificationPage: React.FC = () => {
  const request = useRequestFromSearch({ searchToRequest })
  const classes = useCommonStyles()
  const { t } = useT()
  const gdk = useGDK()
  const pageFlow = usePageFlow(true)
  const [list, setList] = useState<PaginationRes<SystemNotification[]>>(createDefaultPaginationData([]))
  const { reloadFlag, reload } = useReload()
  const writable = useChecker()
  useGetDataByPayload({
    payload: request?.page ?? 1,
    gdkFunc: (page) => gdk.notification.getSystemNotificationList({ page }),
    gdkFuncDependencies: [gdk, reloadFlag],
    onBeforeFetch: pageFlow.setLoadingStart,
    onSuccess: (res: PaginationRes<SystemNotification[]>) => {
      setList(res)
      pageFlow.setContentShow()
    },
    onError: (error) => {
      pageFlow.setError(error.message)
    }
  })
  const [rows, setRows] = useState<RowType[]>([])
  useAsyncEffect(async (mounted) => {
    if (!mounted) return
    setRows(await Promise.all(list.data.map(async (entry) => {
      const content = await renderContent(entry.content)
      return {
        id: entry.id,
        title: entry.title || '',
        situation: t(notificationTypeName[entry.trigger]),
        content: (<AnnouncementTableContent title="" content={content} isPinned={false} />),
        channels: (
          <React.Fragment>
            {entry.channels.map((channel) => (<p key={channel}>{t(channelName[channel])}</p>))}
          </React.Fragment>
        ),
        updatedAt: (<DateTime time={entry.updated_at} />),
        operator: entry.updated_by,
        status: (<NotificationTableStatus status={entry.is_active ? AnnouncementStatusType.START : AnnouncementStatusType.NOT_START} />),
        functions: (
          <SystemNotificationListButtons
            id={entry.id}
            isActive={entry.is_active}
            reload={reload}
          />
        )
      } as RowType
    })))
  }, [list.data, reload, t])
  const data = useMemo(() => {
    return createTableData<RowType>(
      {
        id: {
          label: '',
          value: 'id'
        },
        title: {
          label: t('common.sendTitle'),
          value: 'title',
          align: 'center'
        },
        situation: {
          label: t('common.sendSituation'),
          value: 'situation',
          align: 'left'
        },
        content: {
          label: t('common.sendContent'),
          value: 'content',
          align: 'left'
        },
        channels: {
          label: t('common.channel'),
          value: 'channels',
          align: 'center'
        },
        updatedAt: {
          label: t('common.updateAt'),
          value: 'updatedAt',
          align: 'center'
        },
        operator: {
          label: t('common.updateBy'),
          value: 'operator',
          align: 'center'
        },
        status: {
          label: t('common.status'),
          value: 'status',
          align: 'center'
        },
        functions: {
          label: t('common.function'),
          value: 'functions',
          align: 'center'
        }
      },
      writable
        ? [
            'situation',
            'title',
            'content',
            'channels',
            'updatedAt',
            'operator',
            'status',
            'functions'
          ]
        : [
            'situation',
            'title',
            'content',
            'channels',
            'updatedAt',
            'operator',
            'status'
          ],
      rows,
      'id'
    )
  }, [rows, t, writable])
  const tableClasses = useMemo(() => {
    return {
      head: classes.pinkTableHead,
      cellHead: classes.tableCellHead,
      row: classes.tableRow
    }
  }, [classes.pinkTableHead, classes.tableCellHead, classes.tableRow])
  const handlePagination = usePaginationClickAndChangeUrl({
    request,
    encodePath: allRoute.forestageNotification.encodePath,
    pageFlow
  })
  return (
    <Box paddingY={5}>
      <ScrollablePaper marginX={4}>
        <Box padding={4}>
          <Box paddingY={2}>
            <LoadingAndErrorFrame { ...pageFlow.status }>
              <CoreTable
                classes={tableClasses}
                data={data}
                total={list.total}
                showPagination
                page={request?.page ?? 1}
                onChangePage={handlePagination}
                loading={pageFlow.status.loading}
              />
            </LoadingAndErrorFrame>
          </Box>
        </Box>
      </ScrollablePaper>
    </Box>
  )
}

export default React.memo(AnnouncementNotificationPage)

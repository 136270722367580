import React, { Dispatch, SetStateAction, createContext, useCallback, useContext, useMemo, useState } from 'react'
import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { PlayerProfitExportRes, PaginationReq, getTargetURL, PlayerProfitExportQueryWithJson } from '@golden/gdk-admin'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import LoadingAndErrorFrame from '../../../components/default/frames/LoadingAndErrorFrame'
import useT from '../../../i18ns/admin/useT'
import FormStateProvider from '../../../components/default/form/FormStateProvider'
import { PageFlowType, usePageFlow } from '../../../utils/default/PageFlowHook'
import FileInputButton, { PropTypes as FileInputButtonPropTypes } from '../../../components/admin/FileInputButton'
import { InitialFormFunc, useGDKFuncHandleSubmit, useGetDataByPayload, useRedirectHandleBack } from '../../../utils/default/ComplexFlowHook'
import { startOfYear, subYears } from 'date-fns'
import { isUndefined, omitBy } from '@golden/utils'
import { ChangedFormGetter, createDefaultFormState, FormValidation, ValueGetter } from '../../../utils/default/FormHook'
import { getValueFromValue } from '../../../utils/default/FormHelper'
import FormField from '../../../components/default/form/FormField'
import DateInputBase from '../../../components/default/form/DateInput'
import SearchDropDown, { PropTypes as SearchDropDownProps } from '../../../components/admin/playerReport/profit/SearchDropDown'
import { createShouldDisableDate, setLastMonth, setThisMonth, setToday, setYesterday } from '../../../utils/default/TimeHelper'
import { createValidateStartAtWithEndAt } from '../../../utils/default/Validator'
import { makeStyles } from '@material-ui/core'
import { Path } from '../../../components/admin/route/route'
import FormSubmitButton from '../../../components/default/form/FormSubmitButton'
import { createGlobalDialogConfig } from '../../../utils/default/DialogHelper'
import useGlobalDialog from '../../../providers/admin/dialog/useGlobalDialog'
import { getServerUrl } from '../../../utils/default/StageHelper'
import {
  initialForm as playerProfitInitialForm,
  formToRequest as playerProfitF2R,
  getValueFromEvent as playerProfitGetValueFromEvent,
  PlayerReportProfitFormType
} from '../../../components/admin/playerReport/profit/PlayerReportProfitForm'
import GameInput, { PropTypes as GameInputProps } from '../../../components/admin/GameInput'
import OnOffCheckbox, { PropTypes as CheckboxProps } from '../../../components/default/form/OnOffCheckbox'
import DropDown, { PropTypes as DropDownProps } from '../../../components/default/form/DropDown'
import SportLeagueDialog from '../../../components/admin/playerReport/SportLeagueDialog'
import SpecifyLeagueDialogButton from '../../../components/admin/playerReport/SpecifyLeagueDialogButton'

const useStyles = makeStyles(() => ({
  divider: {
    backgroundColor: '#eee',
    margin: '32px 0 8px'
  },
  subtitle: {
    padding: '8px 0'
  }
}))

type FormType = Omit<PlayerReportProfitFormType, 'account'> & { file: File | null }

const initialForm: InitialFormFunc<FormType> = (defaultForm) => ({
  file: defaultForm?.file ?? null,
  ...playerProfitInitialForm(defaultForm)
})

const defaultForm = initialForm()

export const formToRequest = (form: FormType): { file: File } & PlayerProfitExportQueryWithJson => {
  const converted = {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    file: form.file!,
    ...playerProfitF2R({ ...form, account: '' })
  } as { file: File } & PlayerProfitExportQueryWithJson
  return omitBy(converted, isUndefined) as { file: File } & PlayerProfitExportQueryWithJson
}

const getValueFromEvent: ValueGetter<FormType> = {
  ...playerProfitGetValueFromEvent,
  file: getValueFromValue
}

const getChangedForm: ChangedFormGetter<FormType> = {
  game: (value, form) => {
    if (value.game_category !== form.game.game_category) {
      return initialForm({ ...form, game: value, specify_league: [] })
    }
    return { ...form, game: value }
  }
}

const FormContext = createContext(createDefaultFormState(initialForm()))

const Step1: React.FC<{ pageFlow: PageFlowType }> = React.memo((props) => {
  const { pageFlow } = props
  const { t } = useT()
  const classes = useStyles()
  const { value: form, dispatch } = useContext(FormContext)
  const gdk = useGDK()
  const globalDialog = useGlobalDialog()

  const [data, setData] = useState<{ total: number, duplicated: number, not_activity: number } | null>(null)

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const payload = useMemo(() => ({ file: form.file! }), [form.file])

  useGetDataByPayload({
    payload,
    gdkFunc: (payload) => gdk.history.getPlayerProfitHistoryCsvByCsv(payload),
    gdkFuncDependencies: [gdk],
    canLoadData: !!form.file,
    onBeforeFetch: () => {
      pageFlow.setLoadingStart()
    },
    onSuccess: (res: { total: number, duplicated: number, not_activity: number }) => {
      setData(res)
      pageFlow.setContentShow()
    },
    onError: (error) => {
      setData(null)
      globalDialog.setConfig(createGlobalDialogConfig({
        showIcon: true,
        variant: 'error',
        showCancel: false,
        message: error.message
      }))
      globalDialog.setOpen({
        id: 'validatePlayerProfitCsvFailed',
        value: true,
        isOK: false
      })
      dispatch({ type: 'change', label: 'file', value: null })
      pageFlow.setContentShow()
    }
  })

  return (
    <Box>
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <Typography variant="h5" className={classes.subtitle}>{`${t('common.stepWithColon', { number: t('common.one') })}${t('common.uploadCsvList')}`}</Typography>
        </Grid>
        <Grid item>
          <FormField<FormType, FileInputButtonPropTypes>
            context={FormContext}
            component={FileInputButton}
            name="file"
            loading={false}
            text={t('common.upload')}
          />
        </Grid>
        { !!data && <Grid item>
          <Typography color="error">{t('common.playerProfitCsvTip', data)}</Typography>
        </Grid> }
      </Grid>
    </Box>
  )
})

const TimeTypeDropDown: React.FC = React.memo(() => (
  <FormField<FormType, SearchDropDownProps>
    context={FormContext}
    component={SearchDropDown}
    name="search_type"
  />
))

const DateInput: React.FC = React.memo(() => {
  const classes = useCommonStyles()
  const { t } = useT()
  const { value, handleChange } = useContext(FormContext)
  const {
    shouldDisableStartDate,
    shouldDisableEndDate
  } = useMemo(() => {
    const fiveYearsAgo = startOfYear(subYears(new Date(), 5))
    return createShouldDisableDate(value.time.start, value.time.end, fiveYearsAgo)
  }, [value.time])
  const tools = useMemo(() => {
    return [
      {
        label: t('common.today'),
        change: setToday
      },
      {
        label: t('common.yesterday'),
        change: setYesterday
      },
      {
        label: t('common.thisMonth'),
        change: setThisMonth
      },
      {
        label: t('common.lastMonth'),
        change: setLastMonth
      }
    ]
  }, [t])
  const startOption = useMemo(() => ({
    label: t('common.beginAt'),
    shouldDisableDate: shouldDisableStartDate
  }), [shouldDisableStartDate, t])

  const endOption = useMemo(() => ({
    label: t('common.endAt'),
    shouldDisableDate: shouldDisableEndDate
  }), [shouldDisableEndDate, t])

  const dateClasses = useMemo(() => ({
    button: classes.pinkGradualButton
  }), [classes.pinkGradualButton])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onChange = useCallback(handleChange('time'), [])
  return (
    <DateInputBase
      value={value.time}
      onChange={onChange}
      start={startOption}
      end={endOption}
      tools={tools}
      classes={dateClasses}
      FrontComponent={TimeTypeDropDown}
    />
  )
})

const Step2: React.FC<{ setOpenDialog: Dispatch<SetStateAction<boolean>> }> = React.memo((props) => {
  const { value: form } = useContext(FormContext)
  const { setOpenDialog } = props
  const classes = useStyles()
  const { t } = useT()
  const pageFlow = usePageFlow()
  const activityExcludingTypeOptions = useMemo(() => ([
    {
      name: t('common.noLimit'),
      value: '--'
    },
    {
      name: t('common.notExclude'),
      value: false
    },
    {
      name: t('common.exclude'),
      value: true
    }
  ]), [t])

  if (form.file === null) return null
  return (<>
    <Divider className={classes.divider} />
    <Box>
      <LoadingAndErrorFrame { ...pageFlow.status }>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Typography variant="h5" className={classes.subtitle}>{`${t('common.stepWithColon', { number: t('common.two') })}${t('common.selectTimeAndGameForExport')}`}</Typography>
          </Grid>
          <Grid item container>
            <DateInput />
          </Grid>
          <Grid item>
            <FormField<FormType, GameInputProps>
              name="game"
              context={FormContext}
              component={GameInput}
              EndComponent={(<>
                <Grid item xs={12} md={6} lg={3}>
                  <FormField<FormType, DropDownProps>
                    context={FormContext}
                    component={DropDown}
                    name="is_activity_excluding"
                    options={activityExcludingTypeOptions}
                    label={t('common.searchingEffectiveCash')}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <SpecifyLeagueDialogButton<FormType>
                    setOpen={setOpenDialog}
                    context={FormContext}
                  />
                </Grid>
              </>)}
            />
          </Grid>
        </Grid>
      </LoadingAndErrorFrame>
    </Box>
  </>)
})

const Footer: React.FC<{ loading: boolean }> = React.memo((props) => {
  const { loading } = props
  const commonClasses = useCommonStyles()
  const classes = useStyles()
  const { t } = useT()
  const { value: form } = useContext(FormContext)
  const [, handleDebouncedBack] = useRedirectHandleBack({ path: Path.PLAYER_REPORT_PROFIT })
  return (<>
    <Divider className={classes.divider} />
    { !!form.file && (<Grid item container justifyContent="flex-end">
      <Grid item>
        <FormField<FormType, CheckboxProps>
          context={FormContext}
          component={OnOffCheckbox}
          name="filter_zero_cash"
          label={t('common.filterZeroCashData')}
        />
      </Grid>
    </Grid>) }
    <Grid container justifyContent="flex-end" spacing={2}>
      <Grid item>
        <Button
          className={commonClasses.greyButton}
          onClick={handleDebouncedBack}
        >
          {t('common.back')}
        </Button>
      </Grid>
      { !!form.file && <Grid item>
        <FormSubmitButton
          component={Button}
          context={FormContext}
          className={commonClasses.purpleGradualButton}
          type="submit"
          disabled={loading}
        >
          {loading
            ? (
            <Box display="flex" alignItems="center" justifyContent="center">
              <CircularProgress size={24} />
            </Box>
              )
            : t('common.export')}
        </FormSubmitButton>
      </Grid> }
    </Grid>
  </>)
})

const PlayerReportProfitExportPage: React.FC = () => {
  const gdk = useGDK()
  const commonClasses = useCommonStyles()
  const pageFlow = usePageFlow()
  const { t } = useT()

  const csvTemplate = useMemo(() => {
    const titles = [t('common.account')]
    return `data:text/csv;charset=utf-8,%EF%BB%BF${encodeURI(titles.join(','))}`
  }, [t])

  const validation: FormValidation<FormType> = useMemo(() => {
    return {
      file: [],
      search_type: [],
      time: [
        {
          func: createValidateStartAtWithEndAt('time', t),
          when: ['change', 'beforeClickSubmit']
        }
      ],
      game: [],
      filter_zero_cash: [],
      is_activity_excluding: [],
      specify_league: []
    }
  }, [t])

  const [downloadLoading, setDownloadLoading] = useState(false)
  const [openDialog, setOpenDialog] = useState<boolean>(false)

  const { handleSubmit, loading: submitLoading } = useGDKFuncHandleSubmit({
    formToRequest,
    onSuccess: ({ url: _url, payload }: { url: string, payload: PlayerProfitExportRes & PaginationReq }) => {
      setDownloadLoading(true)
      const url = getTargetURL(getServerUrl('api'), _url.replace(/\/api/i, ''))

      const formEl = document.createElement('form')
      formEl.action = url
      formEl.method = 'POST'
      formEl.style.position = 'absolute'
      formEl.style.zIndex = '-999'
      formEl.style.width = '0px'
      document.body.appendChild(formEl)

      const keys = Object.keys(payload) as Array<keyof typeof payload>
      for (const key of keys) {
        if (Array.isArray(payload[key])) {
          (payload[key] as any[]).forEach((value) => {
            const iptEl = document.createElement('input')
            iptEl.type = 'hidden'
            iptEl.name = `${key}[]`
            iptEl.value = typeof value === 'boolean' ? Number(value) : value
            formEl.append(iptEl)
          })
        } else {
          const iptEl = document.createElement('input')
          iptEl.type = 'hidden'
          iptEl.name = key
          const value = payload[key]
          iptEl.value = (typeof value === 'boolean' ? Number(value) : value) as string
          formEl.append(iptEl)
        }
      }

      formEl.submit()

      setTimeout(() => {
        setDownloadLoading(false)
        formEl.remove()
      }, 3000)
    },
    onError: pageFlow.setGDKError,
    gdkFunc: (payload) => gdk.history.downloadPlayerProfitHistoryCsv(payload),
    gdkFuncDependencies: [gdk]
  })
  const loading = useMemo(() => submitLoading || downloadLoading, [downloadLoading, submitLoading])

  return (
    <Box padding={5}>
      <Paper>
        <Box
          paddingY={1.25}
          paddingX={2}
          className={commonClasses.pinkTitleBar}
        >
          <Grid container justify="space-between" alignItems="center">
            <Grid>
              <Typography variant="h5">
                {t('page.playerReportProfitExport')}
              </Typography>
            </Grid>
            <Grid>
              <a
                href={csvTemplate}
                download={`${t('common.template')}.csv`}
                className={commonClasses.anchor}
              >
                {t('common.downloadCsvTemplate')}
              </a>
            </Grid>
          </Grid>
        </Box>
        <Box padding={4}>
          <LoadingAndErrorFrame { ...pageFlow.status }>
            <FormStateProvider
              context={FormContext}
              defaultValue={defaultForm}
              onSubmit={handleSubmit}
              validation={validation}
              getValueFromEvent={getValueFromEvent}
              getChangedForm={getChangedForm}
            >
              <Step1 pageFlow={pageFlow} />
              <Step2 setOpenDialog={setOpenDialog}/>
              <Footer loading={loading} />
              <SportLeagueDialog<FormType>
                open={openDialog}
                onClose={() => { setOpenDialog(false) }}
                context={FormContext}
              />
            </FormStateProvider>
          </LoadingAndErrorFrame>
        </Box>
      </Paper>
    </Box>
  )
}

export default React.memo(PlayerReportProfitExportPage)

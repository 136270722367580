import { PlayerJumpShipErrorType } from '@golden/gdk-admin'
import TKeyType from '../../i18ns/admin/TKeyType'

const playerJumpShipErrorTypeName: { [key in PlayerJumpShipErrorType]: TKeyType } = {
  [PlayerJumpShipErrorType.ACCOUNT_NOT_FOUND]: 'playerJumpShipError.accountNotFound',
  [PlayerJumpShipErrorType.PLAYER_HAS_BEEN_UNDER_STAFF]: 'playerJumpShipError.playerHasBeenUnderStaff',
  [PlayerJumpShipErrorType.ACCOUNT_REPEAT]: 'playerJumpShipError.accountRepeat',
  [PlayerJumpShipErrorType.PLAYER_HAS_OTHER_JUMP_SHIP]: 'playerJumpShipError.playerHasOtherJumpShip',
  [PlayerJumpShipErrorType.OVER_REGISTER_TIME_LIMIT]: 'playerJumpShipError.overRegisterTimeLimit',
  [PlayerJumpShipErrorType.NOT_OVER_LOGIN_TIME_LIMIT]: 'playerJumpShipError.notOverLoginTimeLimit',
  [PlayerJumpShipErrorType.ACCOUNT_BALANCE_OVER_MONEY_LIMIT]: 'playerJumpShipError.accountBalanceOverMoneyLimit',
  [PlayerJumpShipErrorType.CANT_CROSS_AGENT_CHAIN]: 'playerJumpShipError.cantCrossAgentChain'
}

export default playerJumpShipErrorTypeName

import React, { MouseEvent } from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import CustomThemeType from '../../../themes/default/CustomThemeType'

const useStyles = makeStyles((theme: CustomThemeType) => ({
  button: {
    wordBreak: 'keep-all'
  },
  text: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    width: theme.custom.shape.memoWidth,
    color: theme.palette.text.primary
  }
}))

interface PropTypes {
  classes?: Record<'text', string>
  memo: string
  onMouseEnter?: (event: MouseEvent<HTMLElement>) => void
  onMouseLeave?: (event: MouseEvent<HTMLElement>) => void
}

const MemoText: React.FC<PropTypes> = (props) => {
  const { memo, onMouseEnter, onMouseLeave, classes: propClasses } = props
  const classes = useStyles()
  if (!memo || (typeof memo === 'string' && memo.trim() === '')) {
    return (<></>)
  }
  return (
    <Box display="flex" justifyContent="center">
      <Typography
        className={clsx(classes.text, propClasses?.text)}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        {memo}
      </Typography>
    </Box>
  )
}

export default React.memo(MemoText)

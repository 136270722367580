import React, { useCallback } from 'react'
import useT from '../../../i18ns/admin/useT'
import { isAfter, isBefore } from 'date-fns'
import Switch from '../Switch'
import { useChecker } from '../../../utils/admin/AdminRouteHook'
import { useDebouncedCallback } from 'use-debounce/lib'
import useGlobalDialog from '../../../providers/admin/dialog/useGlobalDialog'
import { createGlobalDialogConfig } from '../../../utils/default/DialogHelper'
import { useDialogFlow } from '../../../utils/default/DialogHook'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { GDKError } from '@golden/gdk-admin'
import { useDialogHandleClick } from '../../../utils/default/ComplexFlowHook'

interface PropTypes {
  id: number
  isActive: boolean
  showEndAt: Date | null
  reload: () => void
}

const ActivityManagementTableStatus: React.FC<PropTypes> = (props) => {
  const { id, isActive, showEndAt, reload } = props
  const globalDialog = useGlobalDialog()
  const writable = useChecker()
  const gdk = useGDK()
  const { t } = useT()

  const [handleStart] = useDebouncedCallback(useCallback(() => {
    if (!showEndAt || isAfter(showEndAt, new Date())) {
      globalDialog.setConfig(createGlobalDialogConfig({
        showIcon: false,
        message: t('dialog.confirmToggleOn')
      }))
      globalDialog.setOpen({ id: `startActivity-${id}`, value: true, isOK: false })
    } else {
      globalDialog.setConfig(createGlobalDialogConfig({
        showIcon: false,
        message: t('dialog.outdateTip')
      }))
      globalDialog.setOpen({ id: `updateActivity-${id}`, value: true, isOK: false })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t]), 50)

  useDialogFlow(globalDialog, `startActivity-${id}`, () => {
    const subscription = gdk.activity.activateActivity(id, true)
      .subscribe({
        next: () => {
          globalDialog.setConfig(createGlobalDialogConfig({
            showIcon: false,
            message: t('dialog.toggleOnFinish'),
            showCancel: false
          }))
          globalDialog.setOpen({ id: `startActivitySuccess-${id}`, value: true, isOK: false })
          reload()
        },
        error: (error: GDKError) => {
          globalDialog.setConfig(createGlobalDialogConfig({
            variant: 'error',
            showIcon: true,
            message: error.message,
            showCancel: false
          }))
          globalDialog.setOpen({ id: `startActivityFail-${id}`, value: true, isOK: false })
        }
      })
    return () => {
      if ([`startActivitySuccess-${id}`, `startActivityFail-${id}`].includes(globalDialog.open.id)) {
        globalDialog.clearState()
        subscription.unsubscribe()
      }
    }
  }, [globalDialog.open, t])

  const handleStop = useDialogHandleClick({
    dialogId: `stopActivity-${id}`,
    globalDialog,
    changeDialogConfig: createGlobalDialogConfig({
      showIcon: false,
      message: t('dialog.confirmToggleOff')
    }),
    successDialogConfig: createGlobalDialogConfig({
      showIcon: false,
      message: t('dialog.toggleOffFinish'),
      showCancel: false
    }),
    getFailDialogConfig: (error: string) => createGlobalDialogConfig({
      variant: 'error',
      showIcon: true,
      message: error,
      showCancel: false
    }),
    payload: id,
    gdkFunc: (payload) => gdk.activity.activateActivity(payload, false),
    gdkFuncDependencies: [gdk],
    onSuccess: () => reload(),
    onSuccessDependencies: [reload]
  })

  return (
    <>
      <Switch
        value={isActive}
        onChange={(value) => {
          value ? handleStart() : handleStop()
          return false
        }}
        disabled={!writable}
      />
      { showEndAt && isBefore(showEndAt, new Date()) && (<div>{t('announcementStatus.outDate')}</div>) }
    </>
  )
}

export default React.memo(ActivityManagementTableStatus)

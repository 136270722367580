import { ActivityApplicationStatusType } from '@golden/gdk-admin'

const activityApplicationStatusColor: { [key in ActivityApplicationStatusType]: string } = {
  [ActivityApplicationStatusType.CREATED]: '#808080',
  [ActivityApplicationStatusType.PASSED]: '#72d476',
  [ActivityApplicationStatusType.REJECTED]: '#ff5f5f',
  [ActivityApplicationStatusType.LOCKED]: '#ff3e3e',
  [ActivityApplicationStatusType.CANCELLED]: '#ffbf00'
}

export default activityApplicationStatusColor

import { RCStatusType } from '@golden/gdk-admin'

const rcStatusColor: { [key in RCStatusType]: string } = {
  [RCStatusType.IN_PROGRESS]: '#808080',
  [RCStatusType.REVIEWING]: '#808080',
  [RCStatusType.SUCCESSFUL]: '#72d476',
  [RCStatusType.CANCELED]: '#ff5f5f',
  [RCStatusType.IRREGULAR]: '#FB9678'
}

export default rcStatusColor

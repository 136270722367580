import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import allRoute from '../route/route'
import RouteKey from '../route/RouteKey'
import allLink, { LinkKey } from './link'
import { TopNavType } from './topNav'
import PathTopNav from './PathTopNav'
import LinkTopNav from './LinkTopNav'
import CustomThemeType from '../../../themes/admin/CustomThemeType'
import useT from '../../../i18ns/admin/useT'
import GroupTopNav from './GroupTopNav'
import useGDKStore from '../../../providers/admin/gdk/useGDKStore'

const useStyles = makeStyles((theme: CustomThemeType) => ({
  icon: {
    fontSize: theme.custom.shape.logoSize
  }
}))

interface PropTypes {
  topNav: TopNavType
}

const TopNavComponent: React.FC<PropTypes> = (props) => {
  const { topNav } = props
  const classes = useStyles()
  const { t } = useT()
  const me = useGDKStore.admin.me()

  if (Array.isArray(topNav.item)) {
    return (
      <GroupTopNav
        classes={classes}
        topNavKey={topNav.key}
        icon={topNav.icon}
        title={t(topNav.title)}
        subItem={topNav.item }
      />
    )
  }

  if (Object.values(RouteKey).includes((topNav.item as RouteKey))) {
    const permissions = allRoute[topNav.item as RouteKey].permissions
    const isShow = permissions
      .filter((item) => item.type === 'accessible')
      .some((item) => me?.permissions.includes(item.value))
    if (!isShow) return null
    return (
      <PathTopNav
        classes={classes}
        topNavKey={topNav.key}
        icon={topNav.icon}
        title={t(topNav.title)}
        subItem={topNav.item as RouteKey}
      />
    )
  }

  if (Object.values(LinkKey).includes((topNav.item as LinkKey))) {
    const permissions = allLink[topNav.item as LinkKey].permissions
    const isShow = permissions
      .filter((item) => item.type === 'accessible')
      .some((item) => me?.permissions.includes(item.value))
    if (!isShow) return null
    return (
      <LinkTopNav
        classes={classes}
        topNavKey={topNav.key}
        icon={topNav.icon}
        title={t(topNav.title)}
        subItem={topNav.item as LinkKey}
      />
    )
  }
  return null
}

export default React.memo(TopNavComponent, (prev, next) => {
  return prev.topNav.key === next.topNav.key
})

import React, { useState, useMemo } from 'react'
import { PaginationRes, NewPeopleStatistic, Sort, ADMIN_API } from '@golden/gdk-admin'
import Box from '@material-ui/core/Box'
import { searchToRequest } from './AdminReportNewPeopleStatisticForm'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import useT from '../../../i18ns/admin/useT'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { createDefaultPaginationData, createTableData } from '../../../utils/default/TableHelper'
import { usePageFlow } from '../../../utils/default/PageFlowHook'
import { useRequestFromSearch, useGetDataByPayload, usePaginationClickAndChangeUrl, useSortClickAndChangeUrl, usePerPageChange } from '../../../utils/default/ComplexFlowHook'
import DateTime from '../../default/present/DateTime'
import allRoute from '../route/route'
import LoadingAndErrorFrame from '../../default/frames/LoadingAndErrorFrame'
import ScrollablePaper from '../../default/present/ScrollablePaper'
import CoreTable from '../../default/present/CoreTable'
import playerRankName from '../../../constants/default/playerRankName'
import clientPlatformName from '../../../constants/admin/clientPlatformName'
import registerWayName from '../../../constants/admin/registerWayName'
import VpnChip from '../VpnChip'

interface RowType {
  id: number
  staff: string
  register_staff: string
  register_way: string
  account: string
  nickname: string
  rank: string
  client_platform: string
  register_at: React.ReactElement
  ip: React.ReactElement
  register_url: string
  speed_test_url: string
}

export const initialSort = (): Sort => ({ sort_by: 'register_at', order: 'desc' })

const AdminReportNewPeopleStatisticTable: React.FC = () => {
  const classes = useCommonStyles()
  const { t } = useT()
  const gdk = useGDK()
  const [list, setList] = useState<PaginationRes<NewPeopleStatistic[]>>(createDefaultPaginationData([]))
  const pageFlow = usePageFlow()
  const request = useRequestFromSearch({ searchToRequest })
  useGetDataByPayload({
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    payload: request!,
    gdkFunc: (payload) => gdk.history.getNewPeopleStatistic(payload),
    gdkFuncDependencies: [gdk],
    onBeforeFetch: pageFlow.setLoadingStart,
    onSuccess: (res: PaginationRes<NewPeopleStatistic[]>) => {
      setList(res)
      pageFlow.setContentShow()
    },
    onError: pageFlow.setGDKError,
    canLoadData: request !== undefined
  })
  const tableClasses = useMemo(() => ({
    head: classes.pinkTableHead,
    row: classes.tableRow,
    cellHead: classes.tableCellHead,
    cellBody: classes.pre
  }), [classes])
  const rows: RowType[] = useMemo(() => {
    return list.data.map((item, index) => {
      return {
        id: index,
        staff: item.staff,
        register_staff: item.register_staff,
        register_way: t(registerWayName[item.register_way]),
        account: item.account,
        nickname: item.nickname,
        rank: t(playerRankName[item.rank]),
        client_platform: t(clientPlatformName[item.client_platform]),
        register_at: (<DateTime time={item.register_at} />),
        ip: (<Box display="flex" flexDirection="column" alignItems="center">
          {item.is_vpn && (<VpnChip />)}
          {item.ip}
        </Box>),
        register_url: item.register_url,
        speed_test_url: item.speed_test_url
      } as RowType
    })
  }, [list.data, t])
  const data = useMemo(() => {
    return createTableData<RowType>(
      {
        id: {
          label: '',
          value: 'id'
        },
        staff: {
          label: t('common.belongStaffAccount'),
          value: 'staff',
          align: 'center'
        },
        register_staff: {
          label: t('common.registerStaffAccount'),
          value: 'register_staff',
          align: 'center'
        },
        register_way: {
          label: t('common.registerWay'),
          value: 'register_way',
          align: 'center'
        },
        account: {
          label: t('common.playerAccount'),
          value: 'account',
          align: 'center',
          canSort: true
        },
        nickname: {
          label: t('common.playerNickname'),
          value: 'nickname',
          align: 'center'
        },
        rank: {
          label: t('common.playerRank'),
          value: 'rank',
          align: 'center'
        },
        client_platform: {
          label: t('common.registerDevice'),
          value: 'client_platform',
          align: 'center'
        },
        register_at: {
          label: t('common.registeredAt'),
          value: 'register_at',
          align: 'center',
          canSort: true
        },
        register_url: {
          label: t('common.registerUrl'),
          value: 'register_url',
          align: 'center'
        },
        speed_test_url: {
          label: t('common.speedTestUrl'),
          value: 'speed_test_url',
          align: 'center'
        },
        ip: {
          label: t('common.registerIP'),
          value: 'ip',
          align: 'center'
        }
      },
      [
        'staff',
        'register_staff',
        'register_way',
        'account',
        'nickname',
        'rank',
        'client_platform',
        'register_at',
        'register_url',
        'speed_test_url',
        'ip'
      ],
      rows,
      'id'
    )
  }, [rows, t])

  const sorted = useMemo(() => {
    const initSort = initialSort()
    return {
      column: request?.sort_by ?? initSort.sort_by,
      order: request?.order ?? initSort.order
    }
  }, [request])

  const handleSort = useSortClickAndChangeUrl({
    request,
    encodePath: allRoute.adminReportNewPeopleStatistic.encodePath
  })

  const handlePagination = usePaginationClickAndChangeUrl({
    request,
    encodePath: allRoute.adminReportNewPeopleStatistic.encodePath
  })
  const handlePerPageChange = usePerPageChange({
    request,
    encodePath: allRoute.adminReportNewPeopleStatistic.encodePath,
    gdk,
    pageKey: ADMIN_API.GET_NEW_PLAYER_STATISTIC.url
  })

  if (request === undefined) return null
  return (
    <ScrollablePaper marginX={5}>
      <Box padding={4}>
        <LoadingAndErrorFrame { ...pageFlow.status }>
          <CoreTable
            classes={tableClasses}
            data={data}
            total={list.total}
            showPagination
            page={request.page}
            onChangePage={handlePagination}
            sorted={sorted}
            onSort={handleSort}
            perPage={list.per_page}
            onChangeRowsPerPage={handlePerPageChange}
          />
        </LoadingAndErrorFrame>
      </Box>
    </ScrollablePaper>
  )
}

export default React.memo(AdminReportNewPeopleStatisticTable)

import React, { useContext, useCallback, useState, useMemo } from 'react'
import clsx from 'clsx'
import { useDebouncedCallback } from 'use-debounce'
import { DepositCrawler, DepositCrawlerDeposit } from '@golden/gdk-admin'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Typography from '@material-ui/core/Typography'
import { DialogContext } from '../../../views/admin/deposit/DepositCrawlerPage'
import { useDialogStyles, useCommonStyles } from '../../../utils/admin/StyleHook'
import useT from '../../../i18ns/admin/useT'
import useGDK from '../../../providers/admin/gdk/useGDK'
import useGlobalDialog from '../../../providers/admin/dialog/useGlobalDialog'
import { useGetDataByPayload } from '../../../utils/default/ComplexFlowHook'
import { createGlobalDialogConfig } from '../../../utils/default/DialogHelper'
import { usePageFlow } from '../../../utils/default/PageFlowHook'
import LoadingAndErrorFrame from '../../default/frames/LoadingAndErrorFrame'
import { formatMoney, createTableData } from '../../../utils/default/TableHelper'
import CoreTable from '../../default/present/CoreTable'
import { useDialogFlow } from '../../../utils/default/DialogHook'

interface RowType {
  id: number
  type: string
  serial: string
  name: string
  bank: string
  cash: string
  note: string
  memo: string
}

interface PropTypes {
  reload: () => void
}

const ConfirmDialog: React.FC<{
  item: DepositCrawler | null
  serial: string
  open: boolean
  onCancel: () => void
  onSubmit: () => void
}> = React.memo((props) => {
  const { item, serial, onCancel, onSubmit, open } = props
  const { t } = useT()
  const pageFlow = usePageFlow()
  const gdk = useGDK()
  const dialogClasses = useDialogStyles()
  const commonClasses = useCommonStyles()
  const [origin, setOrigin] = useState<DepositCrawlerDeposit | null>(null)
  const tableClasses = useMemo(() => ({
    head: commonClasses.blackTableHead,
    row: commonClasses.tableRow,
    cellHead: commonClasses.tableCellHead
  }), [commonClasses])
  useGetDataByPayload({
    payload: serial,
    gdkFunc: (payload) => gdk.deposit.getDepositCrawlerDeposit(payload),
    gdkFuncDependencies: [gdk],
    onBeforeFetch: pageFlow.setLoadingStart,
    onSuccess: (res: DepositCrawlerDeposit) => {
      setOrigin(res)
      pageFlow.setContentShow()
    },
    onError: pageFlow.setGDKError,
    canLoadData: serial !== ''
  })
  const rows = useMemo(() => {
    return [
      {
        id: 0,
        type: t('common.originDeposit'),
        serial: origin?.order_number,
        name: origin?.player_real_name,
        bank: origin?.bank_name,
        cash: origin?.cash ? formatMoney(origin.cash) : '',
        note: origin?.note,
        memo: ''
      },
      {
        id: 1,
        type: t('common.combineDeposit'),
        serial: '',
        name: item?.player_real_name,
        bank: item?.bank_name,
        cash: item?.cash ? formatMoney(item.cash) : '',
        note: item?.note,
        memo: item?.memo
      }
    ] as RowType[]
  }, [origin, item, t])
  const data = useMemo(() => {
    return createTableData<RowType>(
      {
        id: {
          label: '',
          value: 'id'
        },
        type: {
          label: '',
          value: 'type',
          align: 'center'
        },
        serial: {
          label: t('common.orderNumber'),
          value: 'serial',
          align: 'center'
        },
        name: {
          label: t('common.depositName'),
          value: 'name',
          align: 'center'
        },
        bank: {
          label: t('common.depositBank'),
          value: 'bank',
          align: 'center'
        },
        cash: {
          label: t('common.orderMoney'),
          value: 'cash',
          align: 'right'
        },
        note: {
          label: t('common.depositNote'),
          value: 'note',
          align: 'center'
        },
        memo: {
          label: t('common.memo'),
          value: 'memo',
          align: 'center'
        }
      },
      [
        'type',
        'serial',
        'name',
        'bank',
        'cash',
        'note',
        'memo'
      ],
      rows,
      'id'
    )
  }, [rows, t])
  return (
    <Dialog open={open} fullWidth maxWidth="md">
      <Box paddingY={2}>
        <DialogContent>
          <Box paddingY={8} display="flex" flexDirection="column" justifyContent="center" alignItems="center">
            <LoadingAndErrorFrame {...pageFlow.status}>
              <Grid container direction="column" justify="center" alignItems="center">
                <Grid item>
                  <Typography className={dialogClasses.text}>{t('dialog.confirmDepositCombin')}</Typography>
                </Grid>
                <Grid item>
                  <Typography className={clsx(dialogClasses.text, commonClasses.purpleWord)}>{t('common.orderNumber')}[{serial}]</Typography>
                </Grid>
                <Grid item>
                  <Typography className={dialogClasses.text}>{t('dialog.confirmDepositCombinAgain')}</Typography>
                </Grid>
                <Grid item>
                  <CoreTable
                    classes={tableClasses}
                    data={data}
                    total={2}
                    dense
                  />
                </Grid>
              </Grid>
            </LoadingAndErrorFrame>
          </Box>
        </DialogContent>
        <DialogActions>
          <Grid
            container
            direction="row"
            alignItems="center"
            justify="center"
          >
            <Button
              onClick={onCancel}
              classes={{ root: dialogClasses.cancelButton }}
            >
              {t('common.cancel')}
            </Button>
            {pageFlow.status.error === null && (
              <Button
                onClick={onSubmit}
                classes={{ root: dialogClasses.okButton }}
                disabled={serial === ''}
              >
                {t('common.confirm')}
              </Button>
            )}
          </Grid>
        </DialogActions>
      </Box>
    </Dialog>
  )
})

const DepositCrawlerDialog: React.FC<PropTypes> = (props) => {
  const { reload } = props
  const gdk = useGDK()
  const { t } = useT()
  const globalDialog = useGlobalDialog()
  const dialogClasses = useDialogStyles()
  const [open, setOpen] = useState<boolean>(false)
  const [serial, setSerial] = useState<string>('')
  const [payload, setPayload] = useContext(DialogContext)
  const { item } = payload

  const handleCancel = useCallback(() => {
    setPayload({ item: null })
    setSerial('')
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSubmit = useCallback(() => {
    setOpen(true)
  }, [])

  const handleCloseConfirm = useCallback(() => {
    setOpen(false)
  }, [])

  const [handleConfirmSubmitDebounced] = useDebouncedCallback(useCallback(() => {
    gdk.deposit.confirmDepositCrawler(item?.id ?? 0, serial).subscribe({
      next: () => {
        globalDialog.setConfig(createGlobalDialogConfig({
          showIcon: false,
          message: t('dialog.combinDepositSuccess'),
          showCancel: false
        }))
        globalDialog.setOpen({
          id: 'confirmCrawlerSuccess',
          value: true,
          isOK: false
        })
      },
      error: (error) => {
        globalDialog.setConfig(createGlobalDialogConfig({
          showIcon: false,
          message: error.message,
          showCancel: false
        }))
        globalDialog.setOpen({
          id: 'confirmCrawlerError',
          value: true,
          isOK: false
        })
      }
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gdk, item, serial, t]), 200)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleConfirmSubmit = useCallback(handleConfirmSubmitDebounced, [])

  useDialogFlow(globalDialog, 'confirmCrawlerSuccess', () => {
    handleCancel()
    reload()
    globalDialog.clearState()
  }, [])

  return (
    <React.Fragment>
      <Dialog open={item !== null} fullWidth maxWidth="sm">
        <Box paddingY={2}>
          <DialogContent>
            <Box paddingY={8} display="flex" flexDirection="column" justifyContent="center" alignItems="center">
              <Typography align="center" className={dialogClasses.text}>{t('dialog.inputCombinDeposit')}</Typography>
              <TextField
                label={t('common.orderNumber')}
                placeholder={t('placeholder.inputOrderNumber')}
                value={serial}
                onChange={useCallback((event) => {
                  setSerial(event.target.value)
                }, [])}
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Grid
              container
              direction="row"
              alignItems="center"
              justify="center"
            >
              <Button
                onClick={handleCancel}
                classes={{ root: dialogClasses.cancelButton }}
              >
                {t('common.cancel')}
              </Button>
              <Button
                onClick={handleSubmit}
                classes={{ root: dialogClasses.okButton }}
                disabled={serial === ''}
              >
                {t('common.confirm')}
              </Button>
            </Grid>
          </DialogActions>
        </Box>
      </Dialog>
      <ConfirmDialog item={item} serial={serial} open={open} onCancel={handleCloseConfirm} onSubmit={handleConfirmSubmit} />
    </React.Fragment>
  )
}

export default React.memo(DepositCrawlerDialog)

import React, { useMemo } from 'react'
import { getTime } from 'date-fns'
import { omitBy, isUndefined } from '@golden/utils'
import { ActivityType, RecommendFriendStateType } from '@golden/gdk-campaign'
import Grid from '@material-ui/core/Grid'
import MuiTextField, { TextFieldProps } from '@material-ui/core/TextField'
import FormField from '../../default/form/FormField'
import DropDown, { PropTypes as DropDownProps } from '../../default/form/DropDown'
import { FormState, ValueGetter } from '../../../utils/default/FormHook'
import { ActivityFormType, ActivityRequest } from './ActivityRecordDetailForm'
import useT from '../../../i18ns/admin/useT'
import { getValueFromChangeEvent, convertEmptyToUndefined, acceptUndefined, pipe, guaranteeBeKey } from '../../../utils/default/FormHelper'
import { SearchToRequestFunc } from '../../../utils/default/ComplexFlowHook'
import { recommendFriendStatusName } from '../../../constants/default/activityStatusName'

export interface ActivityRecordRecommendFriendFormType {
  recommend_account: string
  recommended_account: string
  agent_account: string
  recommendFriendState: RecommendFriendStateType | '--'
}

export const initialForm = (): ActivityRecordRecommendFriendFormType => ({
  recommend_account: '',
  recommended_account: '',
  agent_account: '',
  recommendFriendState: '--'
})

export const getValueFromEvent: ValueGetter<ActivityRecordRecommendFriendFormType> = {
  recommend_account: getValueFromChangeEvent,
  recommended_account: getValueFromChangeEvent,
  agent_account: getValueFromChangeEvent,
  recommendFriendState: getValueFromChangeEvent
}

export const formToRequest = (form: ActivityFormType): ActivityRequest => {
  const converted = {
    activity: ActivityType.RECOMMEND_FRIEND,
    account: convertEmptyToUndefined(form.recommend_account),
    agent_account: convertEmptyToUndefined(form.agent_account),
    recommend_account: convertEmptyToUndefined(form.recommend_account),
    recommended_account: convertEmptyToUndefined(form.recommended_account),
    state: form.recommendFriendState === '--' ? undefined : form.recommendFriendState,
    type: form.type,
    start_at: form.time.start === null ? undefined : getTime(form.time.start),
    end_at: form.time.end === null ? undefined : getTime(form.time.end),
    page: 1
  } as ActivityRequest
  return omitBy(converted, isUndefined) as ActivityRequest
}

export const searchToRequest: SearchToRequestFunc<{
  recommend_account: string | undefined
  recommended_account: string | undefined
  agent_account: string | undefined
  state: string | undefined
  account?: string
}> = (search) => {
  const converted = {
    recommend_account: search.recommend_account ? search.recommend_account : (search.account ? search.account : undefined),
    recommended_account: search.recommended_account,
    agent_account: search.agent_account,
    recommendFriendState: acceptUndefined(search.state, pipe(
      (value) => guaranteeBeKey(value, Object.keys(recommendFriendStatusName))
    ))
  }
  return omitBy(converted, isUndefined) as any
}

interface PropTypes {
  context: React.Context<FormState<ActivityFormType>>
}

const TextField = React.memo(MuiTextField)

const ActivityRecordRecommendFriendForm: React.FC<PropTypes> = (props) => {
  const { context } = props
  const { t } = useT()

  const statusOptions = useMemo(() => {
    return [{ name: t('common.all'), value: '--' }]
      .concat(
        Object.keys(recommendFriendStatusName)
          .map((key) => key as RecommendFriendStateType)
          .map((key) => ({ name: t(recommendFriendStatusName[key]), value: key })) as any
      )
  }, [t])

  return (
    <React.Fragment>
      <Grid item xs={12} md={6} lg={3}>
        <FormField<ActivityFormType, TextFieldProps>
          context={context}
          component={TextField}
          name="recommend_account"
          label={t('common.recommandPerson')}
          placeholder={t('placeholder.inputPlayerAccount')}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} md={6} lg={3}>
        <FormField<ActivityFormType, TextFieldProps>
          context={context}
          component={TextField}
          name="recommended_account"
          label={t('common.recommandedPerson')}
          placeholder={t('placeholder.inputPlayerAccount')}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} md={6} lg={3}>
        <FormField<ActivityFormType, DropDownProps>
          context={context}
          component={DropDown}
          name="recommendFriendState"
          label={t('common.status')}
          options={statusOptions}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} md={6} lg={3}>
        <FormField<ActivityFormType, TextFieldProps>
          context={context}
          component={TextField}
          name="agent_account"
          label={t('common.agentAccount')}
          placeholder={t('placeholder.inputAgentAccount')}
          fullWidth
        />
      </Grid>
    </React.Fragment>
  )
}

export default React.memo(ActivityRecordRecommendFriendForm)

import TKeyType from '../../i18ns/admin/TKeyType'

const blacklistMemoOptions: TKeyType[] = [
  'common.maliciousLoss',
  'common.maliciousReport',
  'common.thirdPartProvideMaliciousReport',
  'common.bankAccountWrong',
  'common.riskControl',
  'common.otherMemo'
]

export default blacklistMemoOptions

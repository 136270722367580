import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { NotificationChannelType, NotificationType } from '@golden/gdk-admin'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import OnOffCheckbox from '../../default/form/OnOffCheckbox'
import { useInternValue } from '../../../utils/default/FormHook'
import useT from '../../../i18ns/admin/useT'
import channelName from '../../../constants/admin/channelName'

const useStyles = makeStyles(() => ({
  none: {
    display: 'none'
  }
}))

export interface PropTypes {
  label: string
  notificationType: NotificationType
  required?: boolean
  defaultValue?: NotificationChannelType[]
  value?: NotificationChannelType[]
  onChange?: (value: NotificationChannelType[]) => void
}

const NotificationChannelCheckboxGroup: React.FC<PropTypes> = (props) => {
  const { label, notificationType, defaultValue, value, onChange, required } = props
  const classes = useStyles()
  const { t } = useT()
  const [internValue, setInternValue] = useInternValue(
    defaultValue ?? value ?? [] as NotificationChannelType[],
    value
  )
  return (
    <Grid container direction="column">
      <Typography>{label}{required && '*'}</Typography>
      <Grid item container direction="row">
        <Grid item xs={12} md={2} lg={2}>
          <OnOffCheckbox
            disabled={[NotificationType.SEND_EMAIL_VERIFY_LINK, NotificationType.SEND_PHONE_VERIFY_CODE].includes(notificationType)}
            label={t(channelName[NotificationChannelType.GOLDEN])}
            value={internValue.includes(NotificationChannelType.GOLDEN)}
            onChange={() => {
              setInternValue((value) => {
                const newValue = value.includes(NotificationChannelType.GOLDEN)
                  ? value.filter((item) => item !== NotificationChannelType.GOLDEN)
                  : value.concat(NotificationChannelType.GOLDEN)
                if (onChange) onChange(newValue)
                return newValue
              })
            }}
          />
        </Grid>
        <Grid className={classes.none} item xs={12} md={2} lg={2}>
          <OnOffCheckbox
            disabled={notificationType === NotificationType.SEND_EMAIL_VERIFY_LINK}
            label={t(channelName[NotificationChannelType.SMS])}
            value={internValue.includes(NotificationChannelType.SMS)}
            onChange={() => {
              setInternValue((value) => {
                const newValue = value.includes(NotificationChannelType.SMS)
                  ? value.filter((item) => item !== NotificationChannelType.SMS)
                  : value.concat(NotificationChannelType.SMS)
                if (onChange) onChange(newValue)
                return newValue
              })
            }}
          />
        </Grid>
        <Grid item xs={12} md={2} lg={2}>
          <OnOffCheckbox
            disabled={notificationType === NotificationType.SEND_PHONE_VERIFY_CODE}
            label={t(channelName[NotificationChannelType.EMAIL])}
            value={internValue.includes(NotificationChannelType.EMAIL)}
            onChange={() => {
              setInternValue((value) => {
                const newValue = value.includes(NotificationChannelType.EMAIL)
                  ? value.filter((item) => item !== NotificationChannelType.EMAIL)
                  : value.concat(NotificationChannelType.EMAIL)
                if (onChange) onChange(newValue)
                return newValue
              })
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default React.memo(NotificationChannelCheckboxGroup)

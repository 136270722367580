import React from 'react'
import Box from '@material-ui/core/Box'
import AnnouncementTable from '../../../components/admin/forestage/AnnouncementTable'
import AnnouncementListToolbar from '../../../components/admin/forestage/AnnouncementListToolbar'
import ScrollablePaper from '../../../components/default/present/ScrollablePaper'
import { SearchToRequestFunc } from '../../../utils/default/ComplexFlowHook'
import { pipe, acceptUndefined, parseInt, guaranteeBetween, guaranteeBeKey } from '../../../utils/default/FormHelper'
import announcementStatusName from '../../../constants/admin/announcementStatusName'

export interface RequestType {
  page: number
  status?: number
}

export const searchToRequest: SearchToRequestFunc<RequestType> = (search) => ({
  page: acceptUndefined(
    search.page,
    pipe(
      parseInt,
      (value) => guaranteeBetween(value, 1, Number.MAX_SAFE_INTEGER)
    )
  ) || 1,
  status: acceptUndefined(
    search.status,
    pipe(
      (value) => guaranteeBeKey(value, Object.keys(announcementStatusName).concat('0')),
      parseInt
    )
  ) || 0
})

const AnnouncementPage: React.FC = () => {
  return (
    <Box paddingY={5}>
      <ScrollablePaper marginX={4}>
        <Box padding={4}>
          <AnnouncementListToolbar />
          <Box paddingY={5}>
            <AnnouncementTable />
          </Box>
        </Box>
      </ScrollablePaper>
    </Box>
  )
}

export default React.memo(AnnouncementPage)

import { ManualDepositType } from '@golden/gdk-admin'
import TKeyType from '../../i18ns/admin/TKeyType'

const manualDepositName: { [key in ManualDepositType]: TKeyType } = {
  [ManualDepositType.EVENT]: 'common.activity',
  [ManualDepositType.DEPOSIT]: 'common.deposit',
  [ManualDepositType.OTHER]: 'common.other',
  [ManualDepositType.RISK_ADJUSTMENT]: 'common.riskAdjustment'
}

export default manualDepositName

import React, { PropsWithChildren, Dispatch, SetStateAction, useState } from 'react'

interface PropTypes<T> {
  context: React.Context<[T, Dispatch<SetStateAction<T>>]>
  defaultValue: T
}

const StateProvider = function<T> (props: PropsWithChildren<PropTypes<T>>) {
  const { context: Context, defaultValue, children } = props

  const [value, setValue] = useState(defaultValue)

  return (
    <Context.Provider value={[value, setValue]}>
      {children}
    </Context.Provider>
  )
}

export default StateProvider

import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import ErrorIcon from '@material-ui/icons/Error'
import useT from '../../../i18ns/admin/useT'

const useStyles = makeStyles((theme) => ({
  root: {
    height: `calc(100vh - ${Number(theme.mixins.toolbar.minHeight) + theme.spacing(1)}px)`,
    minHeight: 140
  },
  icon: {
    fontSize: 60,
    color: theme.palette.error.main
  }
}))

const NotFoundPage: React.FC = () => {
  const classes = useStyles()
  const { t } = useT()
  return (
    <Grid
      className={classes.root}
      container
      direction="column"
      justify="center"
      alignItems="center"
    >
      <ErrorIcon className={classes.icon} />
      <Box paddingTop={2} textAlign="center">
        <Typography color="error" variant="body1">{t('common.404')}</Typography>
        <Typography color="error" variant="body1">{t('common.noPageOrNoPermission')}</Typography>
      </Box>
    </Grid>
  )
}

export default React.memo(NotFoundPage)

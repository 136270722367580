import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import ButtonBase from '@material-ui/core/ButtonBase'
import Typography from '@material-ui/core/Typography'
import CustomThemeType from '../../../themes/admin/CustomThemeType'
import useT from '../../../i18ns/admin/useT'

const useStyles = makeStyles((theme: CustomThemeType) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    position: 'relative',
    backgroundColor: theme.custom.palette.button.grey,
    border: `${theme.custom.palette.primary} solid 1px`,
    '&:hover': {
      boxShadow: `0 0 2px ${theme.custom.palette.primary}`
    }
  },
  text: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    zIndex: 10
  },
  switch: (props: { checked: boolean }) => {
    const { checked } = props
    return {
      height: '100%',
      width: '50%',
      position: 'absolute',
      zIndex: 9,
      backgroundColor: theme.custom.palette.primary,
      transition: theme.transitions.create(['left', 'transform'], {
        duration: theme.transitions.duration.shortest
      }),
      ...(checked ? {} : { transform: 'translateX(100%)' })
    }
  }
}))

interface PropTypes {
  checked: boolean
  onClick: () => void
  disabled?: boolean
}

const Switch: React.FC<PropTypes> = (props) => {
  const { onClick, disabled } = props
  const classes = useStyles(props)
  const { t } = useT()
  return (
    <ButtonBase disableRipple disableTouchRipple onClick={onClick} disabled={disabled}>
      <div className={classes.root}>
        <Typography noWrap className={classes.text} variant="body1">{t('common.open')}</Typography>
        <Typography noWrap className={classes.text} variant="body1">{t('common.close')}</Typography>
        <div className={classes.switch} />
      </div>
    </ButtonBase>
  )
}

export default React.memo(Switch)

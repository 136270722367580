import MuiSwitch from '@material-ui/core/Switch'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { withStyles } from '@material-ui/core/styles'
import React, { useMemo } from 'react'
import useT from '../../i18ns/admin/useT'
import { useInternValue } from '../../utils/default/FormHook'
import { Typography } from '@material-ui/core'

export interface PropTypes {
  value?: boolean
  defaultValue?: boolean
  onChange?: (value: boolean) => boolean
  activeLabel?: string
  inactiveLabel?: string
  disabled?: boolean
  label?: string
}

const SwitchBase = React.memo(withStyles({
  switchBase: {
    color: '#797979',
    '&.Mui-checked': {
      color: '#1fc485'
    },
    '&.Mui-checked + .MuiSwitch-track': {
      backgroundColor: '#1fc485',
      opacity: 0.3
    }
  }
})(MuiSwitch))

const Switch: React.FC<PropTypes> = React.memo((props) => {
  const { value, defaultValue, onChange, disabled = false, activeLabel, inactiveLabel, label } = props
  const { t } = useT()

  const [internValue, setInternValue] = useInternValue<boolean>(
    defaultValue ?? value ?? false,
    value
  )

  const color = useMemo(() => internValue ? '#1fc485' : '#797979', [internValue])
  const status = useMemo(() => {
    return internValue
      ? activeLabel ?? t('common.toggleOn')
      : inactiveLabel ?? t('common.toggleOff')
  }, [internValue, activeLabel, inactiveLabel, t])

  if (disabled) return (<span style={{ color }}>{status}</span>)
  return (<>
    <Typography variant="body1">{label}</Typography>
    <FormControlLabel
      style={{ color }}
      label={status}
      control={
        <SwitchBase
          checked={internValue}
          onChange={() => {
            if (onChange) {
              if (onChange(!internValue)) setInternValue(!internValue)
              return
            }
            setInternValue(!internValue)
          }}
        />
      }
    />
  </>)
})

export default React.memo(Switch)

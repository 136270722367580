import React from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import useT from '../../../i18ns/admin/useT'

const useStyles = makeStyles((theme) => ({
  text: (props: { value: boolean }) => ({
    backgroundColor: props.value ? '#72d476' : theme.palette.error.main,
    padding: theme.spacing(1),
    color: theme.palette.common.white,
    borderRadius: theme.shape.borderRadius
  }),
  nowrap: {
    whiteSpace: 'nowrap'
  }
}))

interface PropTypes {
  value: boolean
  yesText?: string
  noText?: string
}

const OnOffText: React.FC<PropTypes> = (props) => {
  const { t } = useT()
  const {
    value,
    yesText = t('common.yes'),
    noText = t('common.no')
  } = props
  const classes = useStyles(props)
  const text = value ? yesText : noText
  return (
    <span className={clsx(classes.text, classes.nowrap)}>
      {text}
    </span>
  )
}

export default React.memo(OnOffText)

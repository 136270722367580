import React from 'react'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { useCommonStyles } from '../../utils/admin/StyleHook'

interface PropTypes {
  text: string
  color?: string
  titleBold?: boolean
}

const Tip: React.FC<PropTypes> = (props) => {
  const { text, color, titleBold } = props
  const classes = useCommonStyles()
  return (
    <Box paddingY={1}>
      <Typography className={`${classes.pre} ${titleBold ? classes.tipTitleBold : ''}`} color={color ? undefined : 'error'} style={color ? { color } : {}} >
        {text}
      </Typography>
    </Box>
  )
}

export default React.memo(Tip)

import React, { useMemo, useState } from 'react'
import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import ActivityManagementEditLayoutForm, {
  formToRequest,
  initialForm
} from '../../../components/admin/activity/ActivityManagementEditLayoutForm'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import { useDialogHandleSubmit, useGetDataByParams, useRedirectHandleBack, useRequestFromSearch } from '../../../utils/default/ComplexFlowHook'
import allRoute, { Path } from '../../../components/admin/route/route'
import useT from '../../../i18ns/admin/useT'
import useGlobalDialog from '../../../providers/admin/dialog/useGlobalDialog'
import { createGlobalDialogConfig } from '../../../utils/default/DialogHelper'
import { searchToRequest } from '../../../components/admin/activity/ActivityManagementForm'
import { ActivityBetConditionType, ActivityDepositConditionType, ActivityDurationType, ActivityGameDurationType, ActivityLayoutReq, ActivityModuleType } from '@golden/gdk-admin'
import { parsePath } from '../../../utils/default/RouteHelper'
import { usePageFlow } from '../../../utils/default/PageFlowHook'
import { defaultEditorState, parseContent, renderContent } from '@golden/tiptap-react'
import { useLocation } from 'react-router'

const ActivityManagementLayoutCreatePage: React.FC = () => {
  const location = useLocation()
  const id = useMemo(() => Number(parsePath(location.search, location.pathname, Path.ACTIVITY_MANAGEMENT_LAYOUT_CREATE).search?.id || 0), [location.pathname, location.search])
  const request = useRequestFromSearch({ searchToRequest })
  const commonClasses = useCommonStyles()
  const { t } = useT()
  const gdk = useGDK()
  const globalDialog = useGlobalDialog()
  const pageFlow = usePageFlow(!!id, !id)
  const [handleBack, handleDebouncedBack] = useRedirectHandleBack({ path: allRoute.activityManagement.encodePath({ search: { ...request }, param: {} }) })

  const [defaultForm, setDefaultForm] = useState(initialForm())
  useGetDataByParams({
    path: Path.ACTIVITY_MANAGEMENT_LAYOUT_CREATE,
    canLoadData: !!id,
    gdkFunc: () => gdk.activity.getActivityLayout(id),
    gdkFuncDependencies: [gdk],
    onBeforeFetch: pageFlow.setLoadingStart,
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    onSuccess: async (entry) => {
      pageFlow.setContentShow()
      const isSingle = entry.setting?.type === ActivityModuleType.SINGLE
      const isGame = entry.setting?.type === ActivityModuleType.GAME

      setDefaultForm({
        activity_id: entry.activity_id,
        type: entry.type,
        title: `${entry.title} (${t('common.duplication')})`.slice(0, 50),
        startAt: entry.start_at,
        endAt: entry.end_at,
        isNoEndAt: entry.end_at === null,
        showStartAt: entry.show_start_at,
        showEndAt: entry.show_end_at,
        isNoShowEndAt: entry.show_end_at === null,
        content: {
          ...defaultEditorState,
          content: await parseContent(entry.content)
        },
        pure_content: (await (renderContent(entry.content))).replace(/(<([^>]+)>)/ig, ''),
        desktopHomeImage: { url: entry.desktop_home_image_url, id: entry.desktop_home_image_id },
        mobileHomeImage: { url: entry.mobile_home_image_url, id: entry.mobile_home_image_id },
        couponImage: { url: entry.coupon_image_url, id: entry.coupon_image_id },
        agentLongImage: entry.agent_long_image_url ? { url: entry.agent_long_image_url, id: entry.agent_long_image_id } : null,
        isCarousel: entry.is_carousel,
        isGuest: entry.is_guest,
        isMy: entry.is_my,
        isGeneralRule: entry.is_general_rule,
        tagIds: entry.tags.map(item => item.id),

        hasSetting: !!entry.setting,
        moduleType: entry.setting?.type ?? ActivityModuleType.SINGLE,

        singleHasDepositCondition: !isSingle ? false : !!entry.setting?.deposit_condition,
        singleDepositDuration: !isSingle ? '-' : (entry.setting?.deposit_condition?.duration as ActivityDurationType ?? '-'),
        singleDepositCustomStartAt: entry.setting?.deposit_condition?.start_at ?? null,
        singleDepositCustomEndAt: entry.setting?.deposit_condition?.end_at ?? null,
        singleDepositType: !isSingle ? ActivityDepositConditionType.SINGLE : entry.setting?.deposit_condition?.type ?? ActivityDepositConditionType.SINGLE,
        singleDepositThreshold: !isSingle ? '' : (entry.setting?.deposit_condition?.threshold?.toString() ?? ''),
        singleHasBetCondition: !isSingle ? false : !!entry.setting?.bet_condition,
        singleBetDuration: !isSingle ? '-' : (entry.setting?.bet_condition?.duration as ActivityDurationType ?? '-'),
        singleBetCustomStartAt: entry.setting?.bet_condition?.start_at ?? null,
        singleBetCustomEndAt: entry.setting?.bet_condition?.end_at ?? null,
        singleBetType: !isSingle ? ActivityBetConditionType.SINGLE : entry.setting?.bet_condition?.type ?? ActivityBetConditionType.SINGLE,
        singleBetGameType: !isSingle ? null : entry.setting?.bet_condition?.game_id ? 'gameId' : entry.setting?.bet_condition?.sport_games ? 'sportGames' : null,
        singleBetGameIds: !isSingle ? [] : entry.setting?.bet_condition?.game_id ?? [],
        singleBetSportGames: !isSingle ? [{ game_id: null, tournament_id: null, match_id: null }] : entry.setting?.bet_condition?.sport_games ?? [{ game_id: null, tournament_id: null, match_id: null }],
        singleBetThreshold: !isSingle ? '' : entry.setting?.bet_condition?.threshold?.toString() ?? '',
        singleFrequency: !isSingle ? '-' : entry.setting?.frequency ?? '-',
        singleStartAt: !isSingle ? null : entry.setting?.start_at ?? null,
        singleEndAt: !isSingle ? null : entry.setting?.end_at ?? null,

        gameHasDepositCondition: !isGame ? false : !!entry.setting?.deposit_condition,
        gameDepositType: !isGame ? ActivityDepositConditionType.SINGLE : entry.setting?.deposit_condition?.type ?? ActivityDepositConditionType.SINGLE,
        gameDepositDuration: !isGame ? '-' : entry.setting?.deposit_condition?.duration as ActivityGameDurationType ?? '-',
        gameDepositThreshold: !isGame ? '' : entry.setting?.deposit_condition?.threshold?.toString() ?? '',
        gameHasBetCondition: !isGame ? false : !!entry.setting?.bet_condition,
        gameBetType: !isGame ? ActivityBetConditionType.SINGLE : entry.setting?.bet_condition?.type ?? ActivityBetConditionType.SINGLE,
        gameBetGameType: !isGame ? null : entry.setting?.bet_condition?.game_id ? 'gameId' : entry.setting?.bet_condition?.sport_games ? 'sportGames' : null,
        gameBetGameIds: !isGame ? [] : entry.setting?.bet_condition?.game_id ?? [],
        gameBetSportGames: !isGame ? [{ game_id: null, tournament_id: null, match_id: null }] : entry.setting?.bet_condition?.sport_games ?? [{ game_id: null, tournament_id: null, match_id: null }],
        gameBetThreshold: !isGame ? '' : entry.setting?.bet_condition?.threshold?.toString() ?? '',
        gameGames: !isGame ? [{ id: null, league: '', game: '', game_start_at: null, start_at: null, end_at: null, bet_start_at: null, bet_end_at: null }] : entry.setting?.games ?? [{ id: null, league: '', game: '', game_start_at: null, start_at: null, end_at: null, bet_start_at: null, bet_end_at: null }]
      })
    },
    onError: pageFlow.setGDKError
  })

  const { handleSubmit } = useDialogHandleSubmit({
    formToRequest,
    globalDialog,
    dialogId: 'createActivityLayout',
    gdkFunc: (payload) => gdk.activity.createActivityLayout(payload as ActivityLayoutReq<number>),
    gdkFuncDependencies: [gdk],
    getChangeDialogConfig: () => createGlobalDialogConfig({
      showIcon: false,
      message: t('dialog.confirmCreate')
    }),
    getSuccessDialogConfig: () => createGlobalDialogConfig({
      showIcon: false,
      showCancel: false,
      message: t('dialog.createSuccess')
    }),
    getFailDialogConfig: (_, error) => createGlobalDialogConfig({
      showIcon: true,
      variant: 'error',
      showCancel: false,
      message: error.message
    }),
    afterSuccessDialog: handleBack
  })

  return (
    <React.Fragment>
      <Box paddingY={5} paddingX={4}>
        <Paper>
          <Box padding={4}>
            <Typography className={commonClasses.goldenWord} variant="h5">{t('page.createActivity')}</Typography>
            <Box paddingY={4}>
              <ActivityManagementEditLayoutForm
                defaultValue={defaultForm}
                onSubmit={handleSubmit}
                okText={t('common.confirmCreate')}
                onBack={handleDebouncedBack}
              />
            </Box>
          </Box>
        </Paper>
      </Box>
    </React.Fragment>
  )
}

export default React.memo(ActivityManagementLayoutCreatePage)

import React, { useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { FinanceCourier, PaginationRes } from '@golden/gdk-admin'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import LoadingAndErrorFrame from '../../default/frames/LoadingAndErrorFrame'
import CoreTable from '../../default/present/CoreTable'
import { usePageFlow } from '../../../utils/default/PageFlowHook'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import useT from '../../../i18ns/admin/useT'
import { useDialogHandleClick, useGetDataByPayload, usePaginationClickAndChangeUrl, useReload, useRequestFromSearch } from '../../../utils/default/ComplexFlowHook'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { createDefaultPaginationData, createTableData } from '../../../utils/default/TableHelper'
import allRoute from '../route/route'
import { useChecker } from '../../../utils/admin/AdminRouteHook'
import DateTime from '../../default/present/DateTime'
import { searchToRequest } from './FinanceCourierToolbar'
import useGlobalDialog from '../../../providers/admin/dialog/useGlobalDialog'
import { createGlobalDialogConfig } from '../../../utils/default/DialogHelper'
import Switch from '../Switch'

interface RowType {
  id: number
  merchant: string
  name: string
  updatedAt: React.ReactElement
  updatedBy: string
  functions: React.ReactElement
  status: React.ReactElement
}

const ActiveSwitch: React.FC<{
  item: FinanceCourier
  reload: () => void
}> = React.memo((props) => {
  const { item, reload } = props
  const globalDialog = useGlobalDialog()
  const commonClasses = useCommonStyles()
  const writable = useChecker()
  const gdk = useGDK()
  const { t } = useT()

  const payload = useMemo(() => ({ is_active: !item.is_active }), [item])
  const handleClick = useDialogHandleClick({
    dialogId: `${item.is_active ? 'stop' : 'start'}Courier-${item.id}`,
    globalDialog,
    changeDialogConfig: createGlobalDialogConfig({
      showIcon: false,
      message: (
        <React.Fragment>
          {t(item.is_active ? 'dialog.confirmStopForwarder' : 'dialog.confirmStartForwarder')}
          <span className={commonClasses.purpleWord}>{item.name}</span>
        </React.Fragment>
      )
    }),
    successDialogConfig: createGlobalDialogConfig({
      showIcon: false,
      message: t('dialog.updateSuccess'),
      showCancel: false
    }),
    getFailDialogConfig: (error: string) => createGlobalDialogConfig({
      variant: 'error',
      showIcon: true,
      message: error,
      showCancel: false
    }),
    payload,
    gdkFunc: (payload) => gdk.finance.updateCourier(item.id, payload),
    gdkFuncDependencies: [gdk, item.id],
    onSuccess: () => reload(),
    onSuccessDependencies: [reload]
  })

  return (
    <Switch
      value={item.is_active}
      onChange={() => {
        handleClick()
        return false
      }}
      disabled={!writable}
    />
  )
})

const FinanceCourierTable: React.FC = () => {
  const commonClasses = useCommonStyles()
  const request = useRequestFromSearch({ searchToRequest })
  const [list, setList] = useState<PaginationRes<FinanceCourier[]>>(createDefaultPaginationData([]))
  const pageFlow = usePageFlow()
  const { reload, reloadFlag } = useReload()
  const gdk = useGDK()
  const { t } = useT()
  const writable = useChecker()

  useGetDataByPayload({
    payload: request as { is_active?: 0 | 1, name?: string },
    gdkFunc: (payload) => gdk.finance.getCourierList(payload),
    gdkFuncDependencies: [gdk, reloadFlag],
    onBeforeFetch: pageFlow.setLoadingStart,
    onSuccess: (res: PaginationRes<FinanceCourier[]>) => {
      setList(res)
      pageFlow.setContentShow()
    },
    onError: pageFlow.setGDKError
  })

  const tableClasses = useMemo(() => {
    return {
      head: commonClasses.pinkTableHead,
      row: commonClasses.tableRow,
      cellBody: commonClasses.nowrap,
      cellHead: commonClasses.tableCellHead
    }
  }, [commonClasses])
  const rows = useMemo(() => {
    return list.data.map((item, index) => {
      return {
        id: index,
        merchant: item.merchant,
        name: item.name,
        updatedAt: (<DateTime time={item.updated_at} />),
        updatedBy: item.updated_by,
        functions: (
          <Grid container direction="column" spacing={1}>
            <Grid item>
              <Button
                className={commonClasses.blueGradualOutlineButton}
                component={Link}
                to={allRoute.FinanceCourierUpdate.encodePath({ param: { id: item.id }, search: {} })}
              >
                {t('common.update')}
              </Button>
            </Grid>
          </Grid>
        ),
        status: (
          <Grid container direction="column" spacing={1}>
            <Grid item>
              <ActiveSwitch item={item} reload={reload}/>
            </Grid>
          </Grid>
        )
      } as RowType
    })
  }, [list, t, commonClasses.blueGradualOutlineButton, reload])

  const data = useMemo(() => {
    return createTableData<RowType>(
      {
        id: {
          label: '',
          value: 'id'
        },
        merchant: {
          label: t('secret.mer_id'),
          value: 'merchant',
          align: 'center'
        },
        name: {
          label: t('common.courierName'),
          value: 'name',
          align: 'center'
        },
        updatedAt: {
          label: t('common.updateAt'),
          value: 'updatedAt',
          align: 'center'
        },
        updatedBy: {
          label: t('common.updateBy'),
          value: 'updatedBy',
          align: 'center'
        },
        functions: {
          label: t('common.function'),
          value: 'functions',
          align: 'center'
        },
        status: {
          label: t('common.status'),
          value: 'status',
          align: 'center'
        }
      },
      writable
        ? [
            'merchant',
            'name',
            'updatedAt',
            'updatedBy',
            'functions',
            'status'
          ]
        : [
            'merchant',
            'name',
            'updatedAt',
            'updatedBy',
            'status'
          ],
      rows,
      'id'
    )
  }, [t, rows, writable])

  const handlePagination = usePaginationClickAndChangeUrl({
    request,
    encodePath: allRoute.FinanceCourier.encodePath
  })

  return (
    <LoadingAndErrorFrame { ...pageFlow.status }>
      <CoreTable
        classes={tableClasses}
        data={data}
        total={list.total}
        page={request?.page ?? 1}
        showPagination
        onChangePage={handlePagination}
      />
    </LoadingAndErrorFrame>
  )
}

export default React.memo(FinanceCourierTable)

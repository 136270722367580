import React, { useMemo, useState } from 'react'
import { ADMIN_API, BetHistory, GDKError, GameCategoryType, PaginationRes, PermissionType, AdminBetHistoryColumnType, BetHistoryPageAndTotalSummary, getTargetURL } from '@golden/gdk-admin'
import { useRequestFromSearch, useGetDataByPayload, usePaginationClickAndChangeUrl, usePerPageChange } from '../../../../utils/default/ComplexFlowHook'
import ScrollablePaper from '../../../default/present/ScrollablePaper'
import LoadingAndErrorFrame from '../../../default/frames/LoadingAndErrorFrame'
import CoreTable from '../../../default/present/CoreTable'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import { useCommonStyles } from '../../../../utils/admin/StyleHook'
import { useChecker } from '../../../../utils/admin/AdminRouteHook'
import useT from '../../../../i18ns/admin/useT'
import useGDK from '../../../../providers/admin/gdk/useGDK'
import { createDefaultPaginationData, createTableData } from '../../../../utils/default/TableHelper'
import { usePageFlow } from '../../../../utils/default/PageFlowHook'
import useGlobalDialog from '../../../../providers/admin/dialog/useGlobalDialog'
import DateTime from '../../../default/present/DateTime'
import PointsCell from '../../../default/present/PointsCell'
import OnOffText from '../../../default/present/OnOffText'
import orderStatusName from '../../../../constants/default/orderStatusName'
import { createGlobalDialogConfig } from '../../../../utils/default/DialogHelper'
import { useDebouncedCallback } from 'use-debounce/lib'
import allRoute from '../../route/route'
import { getServerUrl } from '../../../../utils/default/StageHelper'
import PayoutTip from '../../PayoutTip'
import BlueButton from '../../../default/BlueButton'
import LotteryBetDetail from './LotteryBetDetail'
import { PlayerReportBetRequestWithJson, searchToRequest } from './PlayerReportBetForm'
import { getGameName } from '../../../../utils/default/PlatformHelper'
import useGDKStore from '../../../../providers/admin/gdk/useGDKStore'
import { GameCategoryFactory } from '@golden/game-china'
import { isUndefined, omitBy } from '@golden/utils'

interface RowType extends Record<AdminBetHistoryColumnType, React.ReactElement | string> {
  id: number
}

const PlayerReportBetTable: React.FC = () => {
  const commonClasses = useCommonStyles()
  const writableExport = useChecker([PermissionType.BET_RECORD_EXPORT])
  const { t } = useT()
  const gdk = useGDK()
  const [list, setList] = useState<PaginationRes<BetHistory[]> & BetHistoryPageAndTotalSummary>({
    ...createDefaultPaginationData([]),
    paged_summary: {
      count: 0,
      cash: '',
      effective_cash: '',
      withdraw_point: '',
      cash_winning: '',
      cash_result: '',
      feedback: '',
      buyback_amount: ''
    },
    total_summary: {
      count: 0,
      cash: '',
      effective_cash: '',
      withdraw_point: '',
      cash_winning: '',
      cash_result: '',
      feedback: '',
      buyback_amount: ''
    }
  })
  const [detailIndex, setDetailIndex] = useState(-1)
  const pageFlow = usePageFlow()
  const globalDialog = useGlobalDialog()
  const request = useRequestFromSearch({ searchToRequest })
  const requestWithJson = useMemo(() => {
    const converted = { ...request, specify_league: JSON.stringify(request?.specify_league) }
    return omitBy(converted, isUndefined) as PlayerReportBetRequestWithJson
  }
  , [request])
  const games = useGDKStore.platform.games()
  useGetDataByPayload({
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    payload: request!,
    gdkFunc: (payload) => gdk.history.getBetHistory(payload),
    gdkFuncDependencies: [gdk],
    onBeforeFetch: pageFlow.setLoadingStart,
    onSuccess: (res: PaginationRes<BetHistory[]> & BetHistoryPageAndTotalSummary) => {
      setList({
        ...res,
        data: res.data.map((item) => item)
      })
      pageFlow.setContentShow()
    },
    onError: pageFlow.setGDKError,
    canLoadData: request !== undefined
  })
  const tableClasses = useMemo(() => ({
    head: commonClasses.pinkTableHead,
    row: commonClasses.tableRow,
    cellHead: commonClasses.tableCellHead,
    cellBody: commonClasses.nowrap
  }), [commonClasses])
  const sumRows = useMemo(() => {
    if (list.data.length === 0) return []
    if (!list.paged_summary && !list.total_summary) return []
    return (
      [list.paged_summary, list.total_summary].map((sumItem, index) => {
        return {
          id: (index + list.data.length),
          account: sumItem.count.toString(),
          game_name: '',
          serial_number: (<Box whiteSpace="pre" />),
          wanfa: (<Box whiteSpace="pre" />),
          draw_date: '',
          game_info: (<Box whiteSpace="pre" />),
          channel_name: (<Box whiteSpace="pre" />),
          bet_info: (<Box whiteSpace="pre" />),
          match_and_tournament_id: (<Box whiteSpace="pre" />),
          original_created_at: (<Box whiteSpace="pre-line">{index === 0 ? t('common.pageSum') : t('common.now')}</Box>),
          bet_cash: (<PointsCell points={sumItem.cash} />),
          odds_info: '',
          effective_bet: (<PointsCell points={sumItem.effective_cash} />),
          withdraw_point: (<PointsCell points={sumItem.withdraw_point} />),
          settled_at: (<Box whiteSpace="pre" />),
          revenue: (<PointsCell points={sumItem.cash_result} />),
          winning: (<PointsCell points={sumItem.cash_winning} />),
          buyback_amount: (request?.game_category === GameCategoryType.SPORT_LOTTERY) ? (<PointsCell points={sumItem.buyback_amount} />) : '',
          feedback: (<PointsCell points={sumItem.feedback} />),
          feedback_serial_number: '',
          status: (<Box whiteSpace="pre" />),
          operation: (<Box whiteSpace="pre" />),
          last_synced_at: ''
        }
      })
    )
  }, [list, t, request?.game_category])
  const rows: RowType[] = useMemo(() => {
    return list.data.map((item, index) => {
      return {
        id: index,
        account: item.account,
        game_name: getGameName(item.game_id, games),
        serial_number: (
          <Box whiteSpace="pre">
            {item.serial_number}
            {item.is_activity_excluding ? `\n${t('common.activityExcluding')}` : ''}
          </Box>
        ),
        wanfa: (
          <React.Fragment>
            <p>{item.channel_name}</p>
            <p>{item.bet_type_name}</p>
          </React.Fragment>
        ),
        draw_date: item.draw_date ?? '',
        game_info: (<Box width={200} whiteSpace="pre-line">{item.game_info}</Box>),
        channel_name: (<Box whiteSpace="pre-line">{item.channel_name}</Box>),
        bet_info: (<Box width={200} whiteSpace="pre-line">{item.bet_info}</Box>),
        original_created_at: (<DateTime time={item.original_created_at} />),
        bet_cash: (<PointsCell points={item.cash} />),
        odds_info: item.odds_info ?? '',
        match_and_tournament_id: (
          <Grid container direction="column" spacing={2}>
            <Grid item>
              {item.tournament_id ?? ''}
            </Grid>
            <Grid item>
              {item.match_id ?? ''}
            </Grid>
          </Grid>
        ),
        effective_bet: item.is_settle ? (<PointsCell points={item.effective_cash} />) : '--',
        withdraw_point: item.is_settle ? (<PointsCell points={item.withdraw_point} />) : '--',
        settled_at: item.settled_at ? (<DateTime time={item.settled_at} />) : '--',
        revenue: item.is_settle ? (<PointsCell points={item.cash_result} />) : '--',
        winning: item.is_settle ? (<PointsCell points={item.cash_winning} />) : '--',
        buyback_amount: item.is_settle ? (item.buyback_amount === undefined ? '' : (<PointsCell points={item.buyback_amount} />)) : '--',
        feedback: item.is_settle ? (<PointsCell points={item.feedback} />) : '--',
        feedback_serial_number: item.feedback_serial_number ? item.feedback_serial_number : '--',
        status: (
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <OnOffText
                value={item.is_settle}
                yesText={t('transactionStatus.settlement')}
                noText={t('transactionStatus.unsettlement')}
              />
            </Grid>
            {item.order_status !== undefined && (
              <Grid item>
                {t(orderStatusName[item.order_status])}
              </Grid>
            )}
          </Grid>
        ),
        operation: (
          <BlueButton
            variant="outlined"
            size="small"
            onClick={() => { setDetailIndex(index) }}
          >
            {t('common.detail')}
          </BlueButton>
        ),
        last_synced_at: item.last_synced_at ? (<DateTime time={item.last_synced_at} />) : '--'
      }
    }).concat(sumRows)
  }, [list.data, games, t, sumRows])

  const data = useMemo(() => {
    return createTableData(
      {
        id: {
          label: '',
          value: 'id'
        },
        account: {
          label: t('common.playerAccount'),
          value: 'account',
          align: 'center'
        },
        game_name: {
          label: t('common.gameName'),
          value: 'game_name',
          align: 'center'
        },
        serial_number: {
          label: t('common.serial'),
          value: 'serial_number',
          align: 'center'
        },
        game_info: {
          label: t('common.competition'),
          value: 'game_info',
          align: 'left'
        },
        wanfa: {
          label: `${t('common.publisher')}${t('common.wanfa')}`,
          value: 'wanfa',
          align: 'center'
        },
        draw_date: {
          label: t('common.drawDate'),
          value: 'draw_date',
          align: 'center'
        },
        channel_name: {
          label: t('common.betContent'),
          value: 'channel_name',
          align: 'center'
        },
        bet_info: {
          label: t('common.betDetail'),
          value: 'bet_info',
          align: 'left'
        },
        odds_info: {
          label: t('common.odds'),
          value: 'odds_info',
          align: 'center'
        },
        match_and_tournament_id: {
          label: (
            <React.Fragment>
              <div>
                {t('common.tournamentId')}
              </div>
              <div>
                {t('common.game2Id')}
              </div>
            </React.Fragment>
          ),
          value: 'match_and_tournament_id',
          align: 'center'
        },
        original_created_at: {
          label: t('common.betAt'),
          value: 'original_created_at',
          align: 'center'
        },
        bet_cash: {
          label: t('common.betCash'),
          value: 'bet_cash',
          align: 'right'
        },
        effective_bet: {
          label: t('common.successBetCash'),
          value: 'effective_bet',
          align: 'right'
        },
        withdraw_point: {
          label: t('common.withdrawPoint'),
          value: 'withdraw_point',
          align: 'right'
        },
        settled_at: {
          label: t('common.settledAt'),
          value: 'settled_at',
          align: 'center'
        },
        revenue: {
          label: t('common.totalWinAndLose'),
          value: 'revenue',
          align: 'right'
        },
        winning: {
          label: t('common.winningCash'),
          value: 'winning',
          align: 'right'
        },
        feedback: {
          label: t('common.feedback'),
          value: 'feedback',
          align: 'right'
        },
        feedback_serial_number: {
          label: t('common.feedbackSerial'),
          value: 'feedback_serial_number',
          align: 'center'
        },
        buyback_amount: {
          label: t('common.buyback'),
          value: 'buyback_amount',
          align: 'right'
        },
        status: {
          label: (
            <React.Fragment>
              <div>
                {t('common.transactionStatus')}
              </div>
              <div>
                {t('common.orderStatus')}
              </div>
            </React.Fragment>
          ),
          value: 'status',
          align: 'center'
        },
        operation: {
          label: t('common.operation'),
          value: 'operation',
          align: 'center'
        },
        last_synced_at: {
          label: t('common.lastSyncedAt'),
          value: 'last_synced_at',
          align: 'center'
        }
      },
      GameCategoryFactory.create(request?.game_category ?? GameCategoryType.SPORT_LOTTERY)?.adminColumns ?? [] as Array<keyof RowType>,
      rows,
      'id'
    )
  }, [request?.game_category, rows, t])

  const handlePagination = usePaginationClickAndChangeUrl({
    request: requestWithJson,
    encodePath: allRoute.playerReportBet.encodePath
  })
  const handlePerPageChange = usePerPageChange({
    request: requestWithJson,
    encodePath: allRoute.playerReportBet.encodePath,
    gdk,
    pageKey: ADMIN_API.GET_BET_HISTORY.url
  })

  const [handleDebouncedExport] = useDebouncedCallback(() => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    gdk.history.exportBetHistory(request!).subscribe({
      next: ({ url: _url, payload }) => {
        const url = getTargetURL(getServerUrl('api'), _url.replace(/\/api/i, ''))

        const formEl = document.createElement('form')
        formEl.action = url
        formEl.method = 'POST'
        formEl.style.position = 'absolute'
        formEl.style.zIndex = '-999'
        formEl.style.width = '0px'
        document.body.appendChild(formEl)

        const keys = Object.keys(payload) as Array<keyof typeof payload>
        for (const key of keys) {
          if (Array.isArray(payload[key])) {
            (payload[key] as any[]).forEach((value) => {
              const iptEl = document.createElement('input')
              iptEl.type = 'hidden'
              iptEl.name = `${key}[]`
              iptEl.value = typeof value === 'boolean' ? Number(value) : value
              formEl.append(iptEl)
            })
          } else {
            const iptEl = document.createElement('input')
            iptEl.type = 'hidden'
            iptEl.name = key
            const value = payload[key]
            iptEl.value = (typeof value === 'boolean' ? Number(value) : value) as string
            formEl.append(iptEl)
          }
        }

        formEl.submit()
        formEl.remove()
      },
      error: (err: GDKError) => {
        globalDialog.setConfig(createGlobalDialogConfig({
          variant: 'error',
          message: err.message,
          showCancel: false
        }))
        globalDialog.setOpen({ id: 'exportBetHistoryFailed', value: true, isOK: false })
      }
    })
  }, 200)

  if (request === undefined) return null
  return (
    <ScrollablePaper marginX={5}>
      <Box padding={4}>
        <LoadingAndErrorFrame { ...pageFlow.status }>
          {!!list.total && writableExport && (
            <Grid container justify="flex-end">
              <Box marginBottom={2}>
                <Button
                  onClick={handleDebouncedExport}
                  className={commonClasses.purpleGradualOutlineButton}
                >
                  {t('common.export')}
                </Button>
              </Box>
            </Grid>
          )}
          <CoreTable
            classes={tableClasses}
            data={data}
            total={list.total}
            showPagination
            page={request.page}
            perPage={list.per_page}
            onChangePage={handlePagination}
            tableFooterMemo={PayoutTip}
            onChangeRowsPerPage={handlePerPageChange}
          />
        </LoadingAndErrorFrame>
        {!!list.data?.[detailIndex] && <LotteryBetDetail
          item={list.data[detailIndex]}
          onClose={() => setDetailIndex(-1)}
        /> }
      </Box>
    </ScrollablePaper>
  )
}

export default React.memo(PlayerReportBetTable)

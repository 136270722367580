import { ErrorCode, GDKError, ManualWithdrawCsvPreviewRes } from '@golden/gdk-admin'
import { Typography } from '@material-ui/core'
import React, { createContext, Dispatch, SetStateAction, useContext, useMemo } from 'react'
import MemoTextWithTitleAndContext from '../../../components/default/memo/MemoTextWithTitleAndContext'
import MemoPopoverWithContext from '../../default/memo/MemoPopoverWithContext'
import { createDefaultMemoPopoverPayload, MemoPopoverPayload } from '../../../components/default/memo/MemoPopover'
import useT from '../../../i18ns/admin/useT'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import { formatMoney, createTableData } from '../../../utils/default/TableHelper'
import CoreTable from '../../default/present/CoreTable'
import { RequestContext } from '../../../views/admin/manual/ManualWithdrawCreatePage'
import { useGetDataByPayload } from '../../../utils/default/ComplexFlowHook'
import useGDK from '../../../providers/admin/gdk/useGDK'
import useGlobalDialog from '../../../providers/admin/dialog/useGlobalDialog'
import { createGlobalDialogConfig } from '../../../utils/default/DialogHelper'
import { Trans } from 'react-i18next'
import { PageFlowType } from '../../../utils/default/PageFlowHook'

interface RowType {
  id: number
  account: string
  withdrawAmount: string
  memo: React.ReactElement
  cash: string
  cashAfter: React.ReactElement
  check: React.ReactElement
}

const MemoContext = createContext<[MemoPopoverPayload, Dispatch<SetStateAction<MemoPopoverPayload>>]>([
  createDefaultMemoPopoverPayload(),
  () => {}
])

const PreviewTable: React.FC<{
  res: ManualWithdrawCsvPreviewRes
  setRes: (value: ManualWithdrawCsvPreviewRes) => void
  pageFlow: PageFlowType
}> = React.memo((props) => {
  const { res, setRes, pageFlow } = props
  const { t } = useT()
  const [context, setContext] = useContext(RequestContext)
  const gdk = useGDK()
  const globalDialog = useGlobalDialog()
  const commonClasses = useCommonStyles()
  const payload = useMemo(() => context.file, [context.file])
  useGetDataByPayload({
    payload,
    gdkFunc: (payload) => gdk.manual.getEffectiveBetByWithdrawCsv(payload),
    gdkFuncDependencies: [gdk],
    onBeforeFetch: pageFlow.setLoadingStart,
    onSuccess: (res: ManualWithdrawCsvPreviewRes) => {
      setRes(res)
      pageFlow.setContentShow()
      globalDialog.setConfig(createGlobalDialogConfig({
        showIcon: false,
        showCancel: false,
        maxWidth: 'xs',
        message: (
          <Trans
            i18nKey="dialog.manualWithdrawFileSummary"
            values={{
              duplicated: res.account_duplicated,
              minus: res.minus_cash
            }}
            components={{
              span: (<span style={{ color: '#f00' }} />)
            }}
          />
        )
      }))
      globalDialog.setOpen({
        id: `previewManualWithdrawCsv-${context.file.name}`,
        value: true,
        isOK: false
      })
    },
    onError: (error: GDKError) => {
      pageFlow.setContentShow()
      setContext({ ...context, file: new File([], '') })
      switch (error.code) {
        case ErrorCode.WRONG_ACCOUNT_FORMAT:
          error.message = error.message.replace('{account}', error.response?.extra?.account ?? '')
          break
        case ErrorCode.CASH_IS_NOT_NUMERIC:
        case ErrorCode.CASH_PRECISION_TOO_LONG:
        case ErrorCode.FILE_EXISTS_NEGATIVE_VALUE:
          error.message = error.message.replace('{cash}', error.response?.extra?.cash ?? '')
          break
        case ErrorCode.FILE_EXISTS_EMPTY_COLUMN:
          error.message = error.message.replace('{column}', error.response?.extra?.column ?? '')
          break
        case ErrorCode.SOME_ACCOUNTS_NOT_FOUND:
          error.message = error.message.replace('{accounts}', error.response?.extra?.accounts ?? '')
          break
      }
      globalDialog.setConfig(createGlobalDialogConfig({
        showIcon: true,
        variant: 'error',
        showCancel: false,
        message: error.message
      }))
      globalDialog.setOpen({
        id: `previewManualWithdrawCsvFailed-${context.file.name}`,
        value: true,
        isOK: false
      })
    },
    canLoadData: !!payload.size
  })

  const tableClasses = useMemo(() => ({
    head: commonClasses.pinkTableHead,
    row: commonClasses.tableRow,
    cellHead: commonClasses.tableCellHead,
    cellBody: commonClasses.pre
  }), [commonClasses])

  const rows: RowType[] = useMemo(() => res.data.map((el, index) => ({
    id: index,
    account: el.account,
    withdrawAmount: formatMoney(el.withdraw_amount),
    memo: (<MemoTextWithTitleAndContext context={MemoContext} title={el.memo} memo={el.memo} />),
    cash: formatMoney(el.cash),
    cashAfter: (<Typography color={Number(el.cash_after) < 0 ? 'error' : 'inherit'}>{formatMoney(el.cash_after)}</Typography>),
    check: (<>
      <Typography color="error">{el.is_account_duplicated && t('error.accountDuplicated')}</Typography>
      <Typography color="error">{el.is_minus_cash && t('error.minusCashAfterWithdrawal')}</Typography>
    </>)
  })), [res, t])

  const data = useMemo(() => {
    return createTableData<RowType>(
      {
        id: {
          label: '',
          value: 'id'
        },
        account: {
          value: 'account',
          label: t('common.playerAccount'),
          align: 'center'
        },
        withdrawAmount: {
          value: 'withdrawAmount',
          label: t('common.withdrawCash'),
          align: 'center'
        },
        memo: {
          value: 'memo',
          label: t('common.memo'),
          align: 'center'
        },
        cash: {
          value: 'cash',
          label: t('common.mainAccount'),
          align: 'center'
        },
        cashAfter: {
          value: 'cashAfter',
          label: t('common.withdrewAccountBalance'),
          align: 'center'
        },
        check: {
          value: 'check',
          label: t('common.check'),
          align: 'center'
        }
      },
      [
        'account',
        'withdrawAmount',
        'memo',
        'cash',
        'cashAfter',
        'check'
      ],
      rows,
      'id'
    )
  }, [rows, t])
  return (
    <MemoPopoverWithContext memoPopoverContext={MemoContext}>
      <CoreTable
        classes={tableClasses}
        data={data}
        total={rows.length}
      />
    </MemoPopoverWithContext>
  )
})

export default React.memo(PreviewTable)

import React, { createContext, Dispatch, SetStateAction } from 'react'
import Box from '@material-ui/core/Box'
import StateProvider from '../../../providers/default/StateProvider'
import ScrollablePaper from '../../../components/default/present/ScrollablePaper'
import InternalWithdrawToolbar from '../../../components/admin/finance/InternalWithdrawToolbar'
import InternalWithdrawTable from '../../../components/admin/finance/InternalWithdrawTable'

export interface RequestType {
  courier: string
}

const defaultRequest: RequestType = { courier: '' }

export const RequestContext = createContext<[RequestType, Dispatch<SetStateAction<RequestType>>]>([
  defaultRequest,
  () => {}
])

const FinanceInternalWithdrawPage: React.FC = () => {
  return (
    <Box paddingY={5}>
      <ScrollablePaper marginX={5}>
        <StateProvider
          context={RequestContext}
          defaultValue={defaultRequest}
        >
          <Box padding={4}>
            <InternalWithdrawToolbar />
          </Box>
          <Box paddingTop={1} paddingBottom={5} paddingX={4}>
            <InternalWithdrawTable />
          </Box>
        </StateProvider>
      </ScrollablePaper>
    </Box>
  )
}

export default React.memo(FinanceInternalWithdrawPage)

import React, { useState, useMemo } from 'react'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import allRoute from '../route/route'
import { TransferSearchTimeType, WalletType, WithdrawSlipTransfer } from '@golden/gdk-admin'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import useT from '../../../i18ns/admin/useT'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { useGetDataByPayload } from '../../../utils/default/ComplexFlowHook'
import { usePageFlow } from '../../../utils/default/PageFlowHook'
import PointsCell from '../../default/present/PointsCell'
import { createTableData } from '../../../utils/default/TableHelper'
import transferTransactionStatusTypeName from '../../../constants/admin/transferTransactionStatusTypeName'
import transferTransactionStatusTypeColor from '../../../constants/admin/transferTransactionStatusTypeColor'
import DateTime from '../../../components/default/present/DateTime'
import CoreTable from '../../../components/default/present/CoreTable'
import { getWalletNameWithStatus } from '../../../utils/default/PlatformHelper'
import useGDKStore from '../../../providers/admin/gdk/useGDKStore'

interface PropTypes {
  id: number
}

interface RowType {
  id: number
  created_at: React.ReactElement
  transfer_number: React.ReactElement
  from: string
  cash: React.ReactElement
  to: string
  after: React.ReactElement
  finished_at: React.ReactElement | '-'
  status: React.ReactElement
}

const WithdrawalTransferTable: React.FC<PropTypes> = React.memo((props) => {
  const { id } = props
  const commonClasses = useCommonStyles()
  const pageFlow = usePageFlow()
  const { t } = useT()
  const gdk = useGDK()
  const [list, setList] = useState<WithdrawSlipTransfer[]>([])
  const platforms = useGDKStore.platform.platforms()

  const payload = useMemo(() => ({ id }), [id])
  useGetDataByPayload({
    payload,
    gdkFunc: (payload) => gdk.withdraw.getWithdrawSlipTransfer(payload.id),
    gdkFuncDependencies: [gdk],
    onBeforeFetch: pageFlow.setLoadingStart,
    onSuccess: (res: WithdrawSlipTransfer[]) => {
      setList(res)
      pageFlow.setContentShow()
    },
    onError: pageFlow.setGDKError,
    canLoadData: id !== 0
  })

  const tableClasses = useMemo(() => ({
    head: commonClasses.greyTableHead,
    row: commonClasses.tableRow,
    cellHead: commonClasses.tableCellHead,
    cellBody: commonClasses.nowrap
  }), [commonClasses])

  const rows: RowType[] = useMemo(() => list.map((item, index) => ({
    id: index,
    transfer_number: (<Link
      className={commonClasses.anchor}
      to={allRoute.playerReportTransfer.encodePath({
        search: {
          transfer_number: item.transfer_number,
          search_type: TransferSearchTimeType.CREATED_AT,
          golden_wallet: WalletType.CENTER,
          page: 1
        },
        param: {}
      })}
      target="_blank"
    >
      {item.transfer_number}
    </Link>),
    created_at: (<DateTime time={item.created_at} />),
    finished_at: item.finished_at ? (<DateTime time={item.finished_at} />) : '-',
    from: getWalletNameWithStatus(item.from, platforms),
    cash: (<PointsCell points={item.cash} />),
    to: getWalletNameWithStatus(item.to, platforms),
    after: (<PointsCell points={item.after} />),
    status: (
      <span className={commonClasses.chipText} style={{ backgroundColor: transferTransactionStatusTypeColor[item.status] }}>
        {t(transferTransactionStatusTypeName[item.status])}
      </span>
    )
  // eslint-disable-next-line react-hooks/exhaustive-deps
  } as RowType)), [list, t])

  const data = useMemo(() => {
    return createTableData<RowType>(
      {
        id: {
          label: '',
          value: 'id'
        },
        created_at: {
          label: t('common.transactionTime'),
          value: 'created_at',
          align: 'center'
        },
        transfer_number: {
          label: t('common.serial'),
          value: 'transfer_number',
          align: 'center'
        },
        from: {
          label: t('common.outAccount'),
          value: 'from',
          align: 'center'
        },
        cash: {
          label: t('common.transferMoney'),
          value: 'cash',
          align: 'center'
        },
        to: {
          label: t('common.inAccount'),
          value: 'to',
          align: 'center'
        },
        after: {
          label: t('common.afterTransferMoney'),
          value: 'after',
          align: 'center'
        },
        finished_at: {
          label: t('common.finishTime'),
          value: 'finished_at',
          align: 'center'
        },
        status: {
          label: t('common.transactionStatus'),
          value: 'status',
          align: 'center'
        }
      },
      [
        'created_at',
        'transfer_number',
        'from',
        'cash',
        'to',
        'after',
        'finished_at',
        'status'
      ],
      rows,
      'id'
    )
  }, [rows, t])
  return (
    <Box overflow="auto" marginY={4}>
      <Box minWidth={400}>
        <Box
          paddingY={1}
          paddingX={1.5}
          className={clsx(commonClasses.blackTitleBar, commonClasses.pre)}
        >
          <Typography>
            {t('common.transfer')}
          </Typography>
        </Box>
        <CoreTable
          classes={tableClasses}
          total={list.length}
          data={data}
        />
      </Box>
    </Box>
  )
})

export default React.memo(WithdrawalTransferTable)

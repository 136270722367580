import React, { useState, useMemo, useContext, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import useT from '../../../i18ns/admin/useT'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import { ActivityDurationType, ActivityLayoutInfo, ActivityModuleType, ActivityType, LayoutStatusType } from '@golden/gdk-campaign'
import CustomThemeType from '../../../themes/admin/CustomThemeType'
import ActivityManagementTabs from './ActivityManagementTabs'
import { ActivityManagementFormContext as LayoutContext } from './ActivityManagementEditLayoutForm'
import { ActivityManagementFormContext as SystemContext } from './ActivityManagementEditSystemLayoutForm'
import { encodePath } from '../../../utils/default/RouteHelper'
import { Path } from '../route/route'
import '@golden/campaign/dist/esm/bundle.css'
import LoadingAndErrorFrame from '../../default/frames/LoadingAndErrorFrame'
import clsx from 'clsx'

const useStyles = makeStyles((theme: CustomThemeType) => ({
  actions: {
    paddingBottom: theme.spacing(4)
  },
  dialog: {
    minWidth: 1200
  },
  iframe: {
    width: '100%',
    height: 667
  },
  desktop: {
    width: '100% !important',
    minWidth: 1000
  },
  mobile: {
    width: 375,
    minWidth: 320,
    maxWidth: 999,
    resize: 'horizontal'
  }
}))

export interface PropTypes {
  onConfirm: () => void
  type: ActivityType
}

type TabType = 'desktop' | 'mobile'

const LayoutPreviewBlock: React.FC<{ tab: TabType }> = React.memo((props) => {
  const { tab } = props
  const { value: form } = useContext(LayoutContext)
  const classes = useStyles()

  const [loading, setLoading] = useState(true)

  const previewData: ActivityLayoutInfo<number | null> = useMemo(() => {
    const isSingle = form.moduleType === ActivityModuleType.SINGLE
    const hasDepositCondition = isSingle ? form.singleHasDepositCondition : form.gameHasDepositCondition
    const DepositType = isSingle ? form.singleDepositType : form.gameDepositType
    const depositDuration = isSingle ? form.singleDepositDuration : form.gameDepositDuration
    const depositThreshold = isSingle ? form.singleDepositThreshold : form.gameDepositThreshold
    const hasBetCondition = isSingle ? form.singleHasBetCondition : form.gameHasBetCondition
    const betType = isSingle ? form.singleBetType : form.gameBetType
    const betThreshold = isSingle ? form.singleBetThreshold : form.gameBetThreshold
    return {
      title: form.title,
      start_at: form.startAt?.getTime() ?? null,
      end_at: form.endAt?.getTime() ?? null,
      content: JSON.stringify(form.content.content),
      is_general_rule: form.isGeneralRule,
      setting: form.hasSetting
        ? {
            type: form.moduleType,
            deposit_condition: hasDepositCondition
              ? {
                  type: DepositType,
                  duration: depositDuration === '-' ? ActivityDurationType.TODAY : depositDuration,
                  threshold: !depositThreshold ? 0 : Number(depositThreshold)
                }
              : null,
            bet_condition: hasBetCondition
              ? {
                  type: betType,
                  duration: depositDuration === '-' || !isSingle ? ActivityDurationType.TODAY : depositDuration as ActivityDurationType,
                  threshold: !betThreshold ? 0 : Number(betThreshold)
                }
              : null,
            frequency: isSingle && form.singleFrequency !== '-' ? form.singleFrequency : null,
            reason: '',
            start_at: isSingle ? form.singleStartAt?.getTime() ?? null : null,
            end_at: isSingle ? form.singleEndAt?.getTime() ?? null : null,
            games: form.moduleType === ActivityModuleType.GAME
              ? form.gameGames.map((game) => ({
                ...game,
                start_at: game.start_at?.getTime() ?? null,
                end_at: game.end_at?.getTime() ?? null,
                bet_start_at: game.bet_start_at?.getTime() ?? null,
                bet_end_at: game.bet_end_at?.getTime() ?? null,
                game_start_at: game.game_start_at?.getTime() ?? null,
                user_status: LayoutStatusType.CAN_APPLY,
                reason: '',
                reasons: [],
                requirements: []
              }))
              : null,
            reasons: [],
            user_status: LayoutStatusType.CAN_APPLY,
            requirements: []
          }
        : null,
      desktop_home_image_url: form.desktopHomeImage?.url ?? '',
      mobile_home_image_url: form.mobileHomeImage?.url ?? '',
      coupon_image_url: form.couponImage?.url ?? '',
      reasons: []
    }
  }, [form])

  useEffect(() => {
    if (previewData) localStorage.setItem('activity-preview', JSON.stringify(previewData))
  }, [previewData])

  return (
    <LoadingAndErrorFrame
      error={null}
      loading={loading}
      showContent={!loading}
    >
      <Box display="flex" justifyContent="center">
        <iframe
          title="activity-preview"
          src={encodePath(Path.ACTIVITY_MANAGEMENT_PREVIEW, { search: {}, param: { type: ActivityType.LAYOUT } })}
          className={clsx(classes.iframe, classes[tab])}
          scrolling="auto"
          frameBorder="0"
          onLoad={() => setLoading(false)}
        />
      </Box>
    </LoadingAndErrorFrame>
  )
})

const SystemPreviewBlock: React.FC<{ type: ActivityType, tab: TabType }> = React.memo((props) => {
  const { tab, type } = props
  const { value: form } = useContext(SystemContext)
  const classes = useStyles()

  const [loading, setLoading] = useState(true)

  const previewData: { title: string, start_at: number | null, end_at: number | null } = useMemo(() => ({
    title: form.title,
    start_at: form.startAt?.getTime() ?? null,
    end_at: form.endAt?.getTime() ?? null
  }), [form])

  useEffect(() => {
    if (previewData) localStorage.setItem('activity-preview', JSON.stringify(previewData))
  }, [previewData])

  return (
    <LoadingAndErrorFrame
      error={null}
      loading={loading}
      showContent={!loading}
    >
      <Box display="flex" justifyContent="center">
        <iframe
          title="activity-preview"
          src={encodePath(Path.ACTIVITY_MANAGEMENT_PREVIEW, { search: {}, param: { type } })}
          className={clsx(classes.iframe, classes[tab])}
          scrolling="auto"
          frameBorder="0"
          onLoad={() => setLoading(false)}
        />
      </Box>
    </LoadingAndErrorFrame>
  )
})

const ActivityManagementPreviewDialog: React.FC<PropTypes> = (props) => {
  const { onConfirm, type } = props
  const { t } = useT()
  const classes = useStyles()
  const commonClasses = useCommonStyles()

  const [tab, setTab] = useState<TabType>('desktop')
  const tabs = useMemo(() => [
    { value: 'desktop', label: t('client.desktop') },
    { value: 'mobile', label: t('client.mobile') }
  ], [t])

  return (
    <Dialog open fullWidth maxWidth="lg" className={classes.dialog}>
      <DialogContent>
        <ActivityManagementTabs
          value={tab}
          tabs={tabs}
          onChange={(value) => setTab(value as TabType)}
        >
          {
            type === ActivityType.LAYOUT
              ? (<LayoutPreviewBlock tab={tab} />)
              : (<SystemPreviewBlock type={type} tab={tab} />)
          }
        </ActivityManagementTabs>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Grid container direction="row" spacing={2} justify="center">
          <Grid item>
            <Button
              className={commonClasses.purpleGradualButton}
              onClick={() => {
                onConfirm()
                localStorage.removeItem('activity-preview')
              }}
            >
              {t('common.confirm')}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  )
}

export default React.memo(ActivityManagementPreviewDialog)

import React, { useCallback, PropsWithChildren, useMemo } from 'react'
import { InitialFormFunc } from '../../../utils/default/ComplexFlowHook'
import { FormState, ValueGetter, useForm, FormValidation } from '../../../utils/default/FormHook'
import { getValueFromChangeEvent } from '../../../utils/default/FormHelper'
import useT from '../../../i18ns/admin/useT'
import { createValidateNotEmpty, createValidateIp } from '../../../utils/default/Validator'

export interface EditIpWhitelistFormType {
  ip: string
  memo: string
}

export const initialForm: InitialFormFunc<EditIpWhitelistFormType> = (defaultForm) => ({
  ip: '',
  memo: '',
  ...defaultForm
})

const getValueFromEvent: ValueGetter<EditIpWhitelistFormType> = {
  ip: getValueFromChangeEvent,
  memo: getValueFromChangeEvent
}

export interface PropTypes {
  context: React.Context<FormState<EditIpWhitelistFormType>>
  defaultValue: EditIpWhitelistFormType
}

const IpWhitelistFormStateProvider: React.FC<PropsWithChildren<PropTypes>> = (props) => {
  const { context: Context, defaultValue, children } = props
  const { t } = useT()

  const onSubmit = useCallback((form) => form, [])

  const formValidation: FormValidation<EditIpWhitelistFormType> = useMemo(() => {
    return {
      ip: [
        {
          func: createValidateNotEmpty<EditIpWhitelistFormType>('ip', t),
          when: ['change', 'beforeClickSubmit']
        },
        {
          func: createValidateIp('ip', t),
          when: ['change', 'beforeClickSubmit']
        }
      ],
      memo: [
        {
          func: createValidateNotEmpty<EditIpWhitelistFormType>('memo', t),
          when: ['change', 'beforeClickSubmit']
        }
      ]
    }
  }, [t])

  const formState = useForm<EditIpWhitelistFormType>({
    defaultValue,
    onSubmit,
    getValueFromEvent,
    formValidation
  })

  return (
    <Context.Provider value={formState}>
      {children}
    </Context.Provider>
  )
}

export default React.memo(IpWhitelistFormStateProvider)

import React, { createContext, Dispatch, SetStateAction, useMemo, useState, useCallback, useEffect } from 'react'
import { useTheme } from '@material-ui/core/styles'
import { AgentType, LayerStructure, ActivityOverviewQuery, ActivityAgentOverview, PaginationRes, PaginationReq } from '@golden/gdk-admin'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import CoreTable from '../../default/present/CoreTable'
import Tree, { TreeNode } from '../../default/present/Tree'
import MemoPopoverWithContext from '../../default/memo/MemoPopoverWithContext'
import LoadingAndErrorFrame from '../../default/frames/LoadingAndErrorFrame'
import { MemoPopoverPayload, createDefaultMemoPopoverPayload } from '../../default/memo/MemoPopover'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import useT from '../../../i18ns/admin/useT'
import agentTypeName from '../../../constants/default/agentTypeName'
import { getTitleOfNextLayer } from '../../../utils/default/AgentAccountHelper'
import { formatCount, formatMoney, createTableData, ColumnCollection, createDefaultPaginationData } from '../../../utils/default/TableHelper'
import ScrollablePaper from '../../default/present/ScrollablePaper'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { useRequestFromSearch, useGetDataByParams, useGetDataByPayload } from '../../../utils/default/ComplexFlowHook'
import { Path } from '../route/route'
import { usePageFlow } from '../../../utils/default/PageFlowHook'
import { searchToRequest } from './ActivityRecordAgentLayerForm'
import { parsePath } from '../../../utils/default/RouteHelper'

interface RowType {
  id: number
  account: string
  cash_count: string
  user_count: string
  cash_sum: string
}

interface ChildRowType {
  id: number
  account: JSX.Element | string
  cash_count: string
  user_count: string
  cash_sum: string
}

const MemoPopoverContext = createContext<[MemoPopoverPayload, Dispatch<SetStateAction<MemoPopoverPayload>>]>([
  createDefaultMemoPopoverPayload(),
  () => {}
])

const ActivityRecordAgentLayerTable: React.FC = () => {
  const classes = useCommonStyles()
  const theme = useTheme()
  const gdk = useGDK()
  const { t } = useT()

  const layerPageFlow = usePageFlow()
  const [showAllAgentData] = useState<boolean>(false)
  const [nodes, setNodes] = useState<LayerStructure[]>([])
  const [page, setPage] = useState<number>(1)
  useGetDataByParams({
    path: Path.ACTIVITY_RECORD_AGENT_SUB,
    gdkFunc: (param: { id: string }) => gdk.emperor.getLayer(Number(param.id)),
    gdkFuncDependencies: [gdk],
    onBeforeFetch: layerPageFlow.setLoadingStart,
    onSuccess: (res) => {
      setNodes(res)
      layerPageFlow.setContentShow()
    },
    onError: layerPageFlow.setGDKError
  })

  const request = useRequestFromSearch({ searchToRequest })
  const agentId = useMemo(() => {
    const { param } = parsePath(window.location.search, window.location.pathname, Path.ACTIVITY_RECORD_AGENT_SUB)
    return param.id
  }, [])

  const defaultExpanded: string[] = useMemo(() => {
    if (nodes.length === 0) return []
    if (request) {
      let target = nodes.find((node) => `${node.account}` === request.agent_account)
      const seeds = ['0']
      while (target?.superior_id) {
        const superiorId = target?.superior_id
        seeds.push(`${superiorId}`)
        target = nodes.find((node) => node.id === superiorId)
      }
      return seeds
    } else {
      const emperor = nodes.find((node) => node.superior_id === null)
      if (emperor) {
        const children = nodes.filter((node) => node.superior_id === emperor.id)
        return [`${emperor.id}`].concat(children.slice(0, 1).map((child) => `${child.id}`))
      }
    }
    return []
  }, [nodes, request])

  const [nodeId, setNodeId] = useState<string>('0')

  useEffect(() => {
    if (request && agentId) {
      setNodeId(agentId)
    } else if (defaultExpanded[1]) {
      setNodeId((nodeId) => {
        if (nodeId === '0') {
          return defaultExpanded[1]
        }
        return nodeId
      })
    }
  }, [request, defaultExpanded, agentId])

  const nodePageFlow = usePageFlow(true, false)
  const [info, setInfo] = useState<PaginationRes<ActivityAgentOverview[]> & ActivityAgentOverview>({
    ...createDefaultPaginationData([]),
    id: 0,
    cash_count: 0,
    user_count: 0,
    cash_sum: '0',
    account: '',
    type: 0
  })

  const payload = useMemo(() => {
    return {
      ...request,
      page,
      is_all_data: showAllAgentData
    }
  }, [request, page, showAllAgentData])

  useGetDataByPayload({
    payload: payload as ActivityOverviewQuery & PaginationReq,
    gdkFunc: (payload) => gdk.activity.getActivityRecordAgentOverview(Number(nodeId), payload),
    gdkFuncDependencies: [gdk, nodeId, showAllAgentData],
    onBeforeFetch: nodePageFlow.setLoadingStart,
    onSuccess: (res: PaginationRes<ActivityAgentOverview[]> & ActivityAgentOverview) => {
      setInfo(res)
      nodePageFlow.setContentShow()
    },
    onError: nodePageFlow.setGDKError,
    canLoadData: Number(nodeId) !== 0
  })

  const root: TreeNode = useMemo(() => {
    const getNode = (parentId: number | null): TreeNode => {
      const children = nodes.filter((node) => node.superior_id === parentId)
      return {
        positions: children.map((child) => `${child.id}`),
        values: children
          .map((child) => ({
            [`${child.id}`]: {
              label: child.account,
              leaves: getNode(child.id)
            }
          }))
          .reduce((collection, item) => Object.assign({}, collection, item), {})
      }
    }
    return getNode(null)
  }, [nodes])

  const NodeType: AgentType = useMemo(() => (info?.type || AgentType.EMPEROR), [info])

  const columnData: ColumnCollection<RowType> = useMemo(() => {
    return {
      id: {
        label: '',
        value: 'id'
      },
      account: {
        label: t(agentTypeName[NodeType]),
        value: 'account',
        align: 'center'
      },
      cash_count: {
        label: t('common.totalBonusTimesCount'),
        value: 'cash_count',
        align: 'center'
      },
      user_count: {
        label: t('common.totalBonusPersonCount'),
        value: 'user_count',
        align: 'center'
      },
      cash_sum: {
        label: t('common.totalActivityBonus'),
        value: 'cash_sum',
        align: 'center'
      }
    }
  }, [t, NodeType])

  const mainRows: RowType[] = useMemo(() => {
    return [{
      id: Number(nodeId),
      account: info?.account,
      cash_count: formatCount(info?.cash_count),
      user_count: formatCount(info?.user_count),
      cash_sum: formatMoney(info?.cash_sum)
    }]
  }, [nodeId, info])

  const mainData = useMemo(() => {
    return createTableData(
      columnData,
      [
        'account',
        'cash_count',
        'user_count',
        'cash_sum'
      ],
      mainRows,
      'id'
    )
  }, [mainRows, columnData])

  const childRows = useMemo(() => {
    return info.data.map((child) => ({
      id: child.id,
      account: info.type !== AgentType.STAFF
        ? (
        <span className={classes.anchor} onClick={() => setNodeId(child.id.toString())}>
          {child.account}
        </span>
          )
        : (
        <span>{child.account}</span>
          ),
      cash_count: formatCount(child.cash_count),
      user_count: formatCount(child.user_count),
      cash_sum: formatMoney(child.cash_sum)
    })).filter((item) => {
      if (showAllAgentData) return true
      return (item.cash_count !== formatCount(0))
    }) as ChildRowType[]
  }, [classes.anchor, info, showAllAgentData])

  const subData = useMemo(() => {
    return createTableData(
      {
        ...columnData,
        account: {
          label: t(getTitleOfNextLayer(NodeType)),
          value: 'account',
          align: 'center'
        }
      },
      [
        'account',
        'cash_count',
        'user_count',
        'cash_sum'
      ],
      childRows,
      'id'
    )
  }, [childRows, columnData, t, NodeType])

  const tableClasses = useMemo(() => {
    return {
      head: classes.greyTableHead,
      cellHead: classes.tableCellHead,
      row: classes.tableRow
    }
  }, [classes])

  const handlePagination = useCallback((_, page) => setPage(page), [])

  return (
    <ScrollablePaper marginX={6}>
      <Box padding={6}>
        <Grid container spacing={3} direction="row" alignItems="stretch">
          <Grid item xs={12} md={2}>
            <Box
              height="100%"
              bgcolor={theme.palette.common.black}
              padding={2}
              overflow="auto"
            >
              <LoadingAndErrorFrame { ...layerPageFlow.status }>
                {defaultExpanded.length > 0 && (
                  <Tree
                    color={theme.palette.common.white}
                    root={root}
                    defaultExpanded={defaultExpanded}
                    onLabelClick={(nodeId: string) => setNodeId(nodeId)}
                  />
                )}
              </LoadingAndErrorFrame>
            </Box>
          </Grid>
          <MemoPopoverWithContext memoPopoverContext={MemoPopoverContext}>
            <Grid item xs={12} md={10}>
              <Box width="100%">
                <LoadingAndErrorFrame { ...nodePageFlow.status }>
                  <Box paddingBottom={6} width="100%">
                    <CoreTable
                      classes={tableClasses}
                      data={mainData}
                      total={1}
                    />
                  </Box>
                  <CoreTable
                    classes={tableClasses}
                    data={subData}
                    total={info.total}
                    showPagination
                    page={page}
                    onChangePage={handlePagination}
                  />
                </LoadingAndErrorFrame>
              </Box>
            </Grid>
          </MemoPopoverWithContext>
        </Grid>
      </Box>
    </ScrollablePaper>
  )
}

export default React.memo(ActivityRecordAgentLayerTable)

import React, { useCallback, useContext } from 'react'
import { useDebouncedCallback } from 'use-debounce'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import { PermissionType, RCStatusType, WithdrawRCSlip } from '@golden/gdk-admin'
import { useChecker } from '../../../utils/admin/AdminRouteHook'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import useT from '../../../i18ns/admin/useT'
import useGDK from '../../../providers/admin/gdk/useGDK'
import useGlobalDialog from '../../../providers/admin/dialog/useGlobalDialog'
import { createGlobalDialogConfig } from '../../../utils/default/DialogHelper'
import { DialogPayloadContext } from './WithdrawalRCSlipDetailDialog'

interface PropTypes {
  item: WithdrawRCSlip
  reload: () => void
}

const LockButton: React.FC<{ id: number, reload: () => void }> = React.memo((props) => {
  const { id, reload } = props
  const { t } = useT()
  const commonClasses = useCommonStyles()
  const gdk = useGDK()
  const globalDialog = useGlobalDialog()
  const [, setPayload] = useContext(DialogPayloadContext)
  const [handleDebouncedClick] = useDebouncedCallback(useCallback(() => {
    gdk.withdraw.reviewWithdrawRCSlip(id).subscribe({
      next: () => {
        reload()
        setPayload({
          id,
          open: true,
          readonly: false
        })
      },
      error: (error) => {
        globalDialog.setConfig(createGlobalDialogConfig({
          variant: 'error',
          message: error.message,
          showCancel: false
        }))
        globalDialog.setOpen({
          id: 'lockError',
          value: true,
          isOK: false
        })
      }
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gdk, id]), 200)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleClick = useCallback(handleDebouncedClick, [])
  return (
    <Button
      className={commonClasses.blueGradualOutlineButton}
      onClick={handleClick}
      fullWidth
      size="small"
    >
      {t('common.review')}
    </Button>
  )
})

const UnlockButton: React.FC<{ id: number, reload: () => void }> = React.memo((props) => {
  const { id, reload } = props
  const commonClasses = useCommonStyles()
  const { t } = useT()
  const gdk = useGDK()
  const globalDialog = useGlobalDialog()
  const [handleDebouncedClick] = useDebouncedCallback(useCallback(() => {
    gdk.withdraw.unlockWithdrawRCSlip(id).subscribe({
      next: () => {
        reload()
      },
      error: (error) => {
        globalDialog.setConfig(createGlobalDialogConfig({
          variant: 'error',
          message: error.message,
          showCancel: false
        }))
        globalDialog.setOpen({
          id: 'unlockError',
          value: true,
          isOK: false
        })
      }
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gdk, id]), 200)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleClick = useCallback(handleDebouncedClick, [])
  return (
    <Button
      className={commonClasses.blueGradualOutlineButton}
      onClick={handleClick}
      fullWidth
      size="small"
    >
      {t('common.unlock')}
    </Button>
  )
})

const ReadButton: React.FC<{ id: number }> = React.memo((props) => {
  const { id } = props
  const commonClasses = useCommonStyles()
  const { t } = useT()
  const [, setPayload] = useContext(DialogPayloadContext)
  const [handleDebouncedClick] = useDebouncedCallback(useCallback(() => {
    setPayload({
      id,
      open: true,
      readonly: true
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]), 200)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleClick = useCallback(handleDebouncedClick, [])
  return (
    <Button
      onClick={handleClick}
      className={commonClasses.blueGradualOutlineButton}
      fullWidth
      size="small"
    >
      {t('common.lookDetail')}
    </Button>
  )
})

const WithdrawalRCSlipButtons: React.FC<PropTypes> = (props) => {
  const { item, reload } = props
  const writable = useChecker([PermissionType.WITHDRAW_RC_SLIP])
  return (
    <Box paddingY={1}>
      <Grid container direction="column" spacing={1}>
        {
          item.rc_status === RCStatusType.IN_PROGRESS && writable &&
          (<Grid item><LockButton reload={reload} id={item.id} /></Grid>)
        }
        {
          item.rc_status === RCStatusType.REVIEWING && writable &&
          (<Grid item><UnlockButton id={item.id} reload={reload} /></Grid>)
        }
        <Grid item>
          <ReadButton id={item.id} />
        </Grid>
      </Grid>
    </Box>
  )
}

export default React.memo(WithdrawalRCSlipButtons)

import { makeStyles } from '@material-ui/core/styles'
import CustomThemeType from '../../themes/admin/CustomThemeType'

export const useCommonStyles = makeStyles((theme: CustomThemeType) => ({
  disabledButton: {
    background: '#f8f9fa !important'
  },
  pinkGradualButton: {
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    background: theme.custom.palette.button.pink,
    color: theme.palette.common.white,
    '&:hover': {
      background: theme.custom.palette.button.pink,
      opacity: theme.custom.palette.opacity.hover,
      color: theme.palette.common.white
    },
    '&:disabled': {
      background: '#f8f9fa'
    }
  },
  pinkGradualOutlineButton: {
    background: theme.palette.common.white,
    color: theme.custom.palette.buttonText.pink,
    border: `1px solid ${theme.custom.palette.buttonText.pink}`,
    '&:hover': {
      border: '1px solid transparent',
      background: theme.custom.palette.button.pink,
      color: theme.palette.common.white
    },
    '&:disabled': {
      border: `1px solid ${theme.palette.grey[500]}`
    }
  },
  pinkGradualOutlineDisabledButton: {
    background: theme.palette.common.white,
    color: `${theme.custom.palette.buttonText.pink} !important`,
    border: `1px solid ${theme.custom.palette.buttonText.pink} !important`,
    opacity: 0.5
  },
  purpleGradualButton: {
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    background: theme.custom.palette.button.primary,
    color: theme.palette.common.white,
    '&:hover': {
      background: theme.custom.palette.button.primary,
      opacity: theme.custom.palette.opacity.hover,
      color: theme.palette.common.white
    },
    '&:disabled': {
      background: '#f8f9fa'
    }
  },
  purpleGradualOutlineButton: {
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    background: theme.palette.common.white,
    color: theme.custom.palette.buttonText.purple,
    border: `1px solid ${theme.custom.palette.buttonText.purple}`,
    '&:hover': {
      border: '1px solid transparent',
      background: theme.custom.palette.button.primary,
      color: theme.palette.common.white
    },
    '&:disabled': {
      border: `1px solid ${theme.palette.grey[500]}`
    }
  },
  greenGradualButton: {
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    background: theme.custom.palette.button.green,
    color: theme.palette.common.white,
    '&:hover': {
      background: theme.custom.palette.button.green,
      opacity: theme.custom.palette.opacity.hover,
      color: theme.palette.common.white
    },
    '&:disabled': {
      background: '#f8f9fa'
    }
  },
  greyButton: {
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    background: theme.custom.palette.button.grey,
    color: theme.palette.common.white,
    '&:hover': {
      background: theme.custom.palette.button.grey,
      opacity: theme.custom.palette.opacity.hover,
      color: theme.palette.common.white
    }
  },
  greyOutlineButton: {
    background: theme.palette.common.white,
    color: theme.custom.palette.buttonText.black,
    border: `1px solid ${theme.custom.palette.buttonText.black}`,
    '&:hover': {
      border: '1px solid transparent',
      background: theme.custom.palette.button.black,
      color: theme.palette.common.white
    }
  },
  blueGradualOutlineButton: {
    background: theme.palette.common.white,
    color: theme.custom.palette.buttonText.blue,
    border: `1px solid ${theme.custom.palette.buttonText.blue}`,
    '&:hover': {
      border: '1px solid transparent',
      background: theme.custom.palette.button.blue,
      color: theme.palette.common.white
    }
  },
  blueGradualOutlineDisabledButton: {
    background: theme.palette.common.white,
    color: `${theme.custom.palette.buttonText.blue} !important`,
    border: `1px solid ${theme.custom.palette.buttonText.blue}`,
    opacity: 0.5
  },
  greenGradualOutlineButton: {
    background: theme.palette.common.white,
    color: theme.custom.palette.buttonText.green,
    border: `1px solid ${theme.custom.palette.buttonText.green}`,
    '&:hover': {
      border: '1px solid transparent',
      background: theme.custom.palette.button.green,
      color: theme.palette.common.white
    },
    '&:disabled': {
      border: `1px solid ${theme.palette.grey[500]}`
    }
  },
  greenGradualOutlineDisabledButton: {
    background: theme.palette.common.white,
    color: `${theme.custom.palette.buttonText.green} !important`,
    border: `1px solid ${theme.custom.palette.buttonText.green}`,
    opacity: 0.5
  },
  yellowGradualOutlineButton: {
    background: theme.palette.common.white,
    color: theme.custom.palette.buttonText.yellow,
    border: `1px solid ${theme.custom.palette.buttonText.yellow}`,
    '&:hover': {
      border: '1px solid transparent',
      background: theme.custom.palette.button.yellow,
      color: theme.palette.common.white
    },
    '&:disabled': {
      border: `1px solid ${theme.palette.grey[500]}`
    }
  },
  yellowGradualOutlineDisabledButton: {
    background: theme.palette.common.white,
    color: `${theme.custom.palette.buttonText.yellow} !important`,
    border: `1px solid ${theme.custom.palette.buttonText.yellow}  !important`,
    opacity: 0.5
  },
  memoField: {
    minHeight: theme.spacing(10)
  },
  pinkTableHead: {
    borderTop: '1px solid rgba(233, 233, 233, 1)',
    backgroundColor: theme.custom.palette.secondary
  },
  blackTableHead: {
    borderTop: '1px solid rgba(233, 233, 233, 1)',
    backgroundColor: theme.palette.common.black
  },
  greyTableHead: {
    borderTop: '1px solid rgba(233, 233, 233, 1)',
    backgroundColor: theme.palette.primary.light
  },
  tableCellHead: {
    color: `${theme.palette.common.white} !important`,
    fontSize: theme.typography.body1.fontSize,
    '&:hover': {
      color: theme.palette.common.white
    }
  },
  tableRow: {
    borderBottom: '2px solid rgba(233, 233, 233, 1)'
  },
  borderTableRow: {
    borderBottom: '2px solid rgba(233, 233, 233, 1)',
    borderLeft: '2px solid rgba(233, 233, 233, 1)',
    borderRight: '2px solid rgba(233, 233, 233, 1)'
  },
  noBordertableRow: {
    border: 'none'
  },
  greyTableRow: {
    backgroundColor: theme.palette.grey[50],
    borderBottom: '2px solid rgba(233, 233, 233, 1)'
  },
  outlineDropDown: {
    ...theme.typography.button,
    lineHeight: '1.4 !important',
    padding: '15px !important'
  },
  anchor: {
    color: theme.custom.palette.blue.main,
    textDecoration: 'underline',
    cursor: 'pointer'
  },
  purpleAnchor: {
    color: theme.custom.palette.purple.main,
    textDecoration: 'underline',
    cursor: 'pointer'
  },
  disableStatus: {
    color: '#6199e3',
    opacity: 0.5
  },
  enableStatus: {
    color: '#1fc485'
  },
  statusIcon: {
    fontSize: theme.typography.h5.fontSize,
    marginRight: theme.spacing(1)
  },
  pinkTitleBar: {
    backgroundColor: theme.custom.palette.secondary,
    color: theme.palette.common.white
  },
  blackTitleBar: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white
  },
  purpleWord: {
    color: `${theme.custom.palette.buttonText.purple} !important`
  },
  goldenWord: {
    color: theme.custom.palette.text.golden
  },
  chipText: {
    padding: theme.spacing(1),
    color: theme.palette.common.white,
    borderRadius: theme.shape.borderRadius,
    whiteSpace: 'nowrap'
  },
  nowrap: {
    whiteSpace: 'nowrap'
  },
  pre: {
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-word'
  },
  ellipsis: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
  },
  bold: {
    fontWeight: 600
  },
  tipTitleBold: {
    '&:first-line': {
      fontWeight: 600
    }
  },
  dialogPinkHeader: {
    backgroundColor: theme.custom.palette.secondary,
    color: theme.palette.common.white,
    textAlign: 'center',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    fontSize: theme.typography.h6.fontSize
  },
  grayFrameContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: 180,
    backgroundColor: theme.palette.grey[100],
    border: `2px dashed ${theme.palette.grey[300]}`
  }
}))

export const useDialogStyles = makeStyles((theme: CustomThemeType) => ({
  okButton: {
    paddingRight: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    boxShadow: theme.shadows[0],
    background: theme.custom.palette.button.primary,
    color: theme.palette.common.white,
    '&:hover': {
      background: theme.custom.palette.button.primary,
      opacity: theme.custom.palette.opacity.hover,
      color: theme.palette.common.white
    },
    '&:disabled': {
      background: '#f8f9fa'
    }
  },
  cancelButton: {
    paddingRight: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    boxShadow: theme.shadows[0],
    background: theme.custom.palette.button.grey,
    color: theme.palette.common.white,
    '&:hover': {
      background: theme.custom.palette.button.grey,
      opacity: theme.custom.palette.opacity.hover,
      color: theme.palette.common.white
    }
  },
  title: {
    color: theme.custom.palette.text.dialog,
    fontSize: theme.typography.h5.fontSize,
    paddingBottom: theme.spacing(1),
    fontWeight: 600
  },
  text: {
    color: theme.custom.palette.text.dialog,
    fontSize: theme.custom.typography.dialog,
    paddingBottom: theme.spacing(1)
  }
}))

export const useDetailStyles = makeStyles((theme: CustomThemeType) => ({
  title: {
    color: theme.palette.common.white,
    backgroundColor: theme.custom.palette.secondary,
    textAlign: 'center',
    ...theme.typography.h5
  },
  content: {
    paddingTop: theme.spacing(3),
    paddingRight: theme.spacing(4),
    paddingLeft: theme.spacing(4)
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse'
  },
  head: {
    width: 88,
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    textAlign: 'center',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    border: `1px solid ${theme.palette.grey[300]}`
  },
  cell: {
    paddingLeft: theme.spacing(1),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    border: `1px solid ${theme.palette.grey[300]}`,
    backgroundColor: theme.palette.grey[100],
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-word'
  },
  greyHead: {
    width: 88,
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.common.white,
    textAlign: 'center',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    border: `1px solid ${theme.palette.grey[300]}`
  }
}))

export const useTablePanelStyles = makeStyles(() => ({
  border: {
    borderTop: '2px solid rgba(233, 233, 233, 1)',
    borderBottom: '2px solid rgba(233, 233, 233, 1)',
    borderRight: '1px solid rgba(233, 233, 233, 1)',
    borderLeft: '1px solid rgba(233, 233, 233, 1)'
  },
  greyBorder: {
    borderTop: '2px solid #e1e1e1',
    borderBottom: '2px solid #e1e1e1',
    borderRight: '1px solid #e1e1e1',
    borderLeft: '4px solid #4c4658'
  },
  grey: {
    backgroundColor: '#e1e1e1'
  },
  header: {
    backgroundColor: '#edf1f5'
  },
  body: {
    color: '#6c8dae !important',
    fontWeight: 600,
    paddingTop: 20,
    paddingBottom: 20
  }
}))

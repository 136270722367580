import React from 'react'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import useGDK from '../../../providers/admin/gdk/useGDK'
import useGlobalDialog from '../../../providers/admin/dialog/useGlobalDialog'
import { createGlobalDialogConfig } from '../../../utils/default/DialogHelper'
import { useDialogHandleClick } from '../../../utils/default/ComplexFlowHook'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import useT from '../../../i18ns/admin/useT'
import { GreetingFormType, formToRequest, ActionType } from './GreetingForm'

interface PropTypes {
  greeting: GreetingFormType
  reload: () => void
  onClick: (id: number, action: ActionType) => void
}

const GreetingTableButtons: React.FC<PropTypes> = React.memo((props) => {
  const { onClick, reload, greeting } = props
  const { id } = greeting
  const classes = useCommonStyles()
  const { t } = useT()
  const gdk = useGDK()
  const globalDialog = useGlobalDialog()
  const payload = formToRequest(greeting)

  const handleToggleStatus = useDialogHandleClick({
    dialogId: `toggleStatus-${id}`,
    globalDialog,
    changeDialogConfig: createGlobalDialogConfig({
      showIcon: false,
      message: !greeting.isActive ? t('dialog.confirmEnableGreetingQuestion') : t('dialog.confirmDisableGreetingQuestion')
    }),
    successDialogConfig: createGlobalDialogConfig({
      showIcon: false,
      message: !greeting.isActive ? t('dialog.enableGreetingSuccess') : t('dialog.disableGreetingSuccess'),
      showCancel: false
    }),
    getFailDialogConfig: (error: string) => createGlobalDialogConfig({
      variant: 'error',
      showIcon: true,
      message: error,
      showCancel: false
    }),
    payload,
    gdkFunc: (payload) => gdk.greeting.updateGreeting(id, {
      ...payload,
      is_active: !payload.is_active
    }),
    gdkFuncDependencies: [gdk],
    onSuccess: () => {
      reload()
    },
    onSuccessDependencies: [reload]
  })

  return (
    <Grid container spacing={1} justify="flex-end" wrap="nowrap">
      {greeting.isActive &&
        (
          <Grid item>
            <Button
              onClick={handleToggleStatus}
              size="small"
              className={classes.greyOutlineButton}
            >
              {t('common.toggleOff')}
            </Button>
          </Grid>
        )
      }
      {!greeting.isActive &&
        (
          <Grid item>
            <Button
              onClick={handleToggleStatus}
              size="small"
              className={classes.greenGradualOutlineButton}
            >
              {t('common.toggleOn')}
            </Button>
          </Grid>
        )
      }
      <Grid item>
        <Button
          onClick={() => onClick(id, 'UPDATE')}
          size="small"
          className={classes.blueGradualOutlineButton}
        >
          {t('common.update')}
        </Button>
      </Grid>
      <Grid item>
        <Button
          onClick={() => onClick(id, 'DELETE')}
          size="small"
          className={classes.pinkGradualOutlineButton}
        >
          {t('common.delete')}
        </Button>
      </Grid>
    </Grid>
  )
})

export default GreetingTableButtons

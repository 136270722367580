import React, { useEffect } from 'react'
import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import { Branch } from '@golden/gdk-admin'
import useGDK from '../../../providers/admin/gdk/useGDK'
import ExternalGameChannelForm from '../../../components/admin/externalGame/ExternalGameChannelForm'
import ExternalGameChannelCreateTable from '../../../components/admin/externalGame/ExternalGameChannelCreateTable'
import useT from '../../../i18ns/admin/useT'
import { useChangeUrlSubmit } from '../../../utils/default/ComplexFlowHook'
import allRoute from '../../../components/admin/route/route'

const ExternalGameChannelCreatePage: React.FC = () => {
  const gdk = useGDK()
  const { t } = useT()
  useEffect(() => {
    gdk.trunk.trigger([Branch.PLATFORMS])
  }, [gdk])
  const handleChange = useChangeUrlSubmit({
    formToRequest: (gameId) => ({ gameId, page: 1 }),
    encodePath: allRoute.externalGameChannelCreate.encodePath,
    toAddNowTimestamp: true
  })
  return (
    <Box paddingY={6}>
      <Box paddingX={6}>
        <Paper>
          <ExternalGameChannelForm warning title={t('page.createChannel')} onChange={handleChange} />
        </Paper>
      </Box>
      <Box paddingTop={2}>
        <ExternalGameChannelCreateTable />
      </Box>
    </Box>
  )
}

export default React.memo(ExternalGameChannelCreatePage)

import React from 'react'
import { SvgIconProps } from '@material-ui/core/SvgIcon'
import Box from '@material-ui/core/Box'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'
import VisibilityIcon from '@material-ui/icons/Visibility'
import CancelIcon from '@material-ui/icons/Cancel'
import ScheduleIcon from '@material-ui/icons/Schedule'
import TKeyType from '../../i18ns/admin/TKeyType'
import { useCommonStyles } from '../../utils/admin/StyleHook'
import useT from '../../i18ns/admin/useT'

enum StatusKey {
  START = 'start',
  NOT_START = 'not_start',
  STOP = 'stop',
  OUT_DATE = 'out_date'
}

type StatusType = keyof typeof StatusKey

export interface StatusInfo {
  type: StatusType
  value: number
}

interface PropTypes {
  define: StatusInfo[]
  status: number
}

const config: { [key in StatusKey]: { icon: React.ComponentType<SvgIconProps>, label: TKeyType } } = {
  [StatusKey.NOT_START]: {
    label: 'announcementStatus.notStart',
    icon: VisibilityOffIcon
  },
  [StatusKey.START]: {
    label: 'announcementStatus.start',
    icon: VisibilityIcon
  },
  [StatusKey.STOP]: {
    label: 'announcementStatus.stop',
    icon: CancelIcon
  },
  [StatusKey.OUT_DATE]: {
    label: 'announcementStatus.outDate',
    icon: ScheduleIcon
  }
}

const Empty: React.FC = () => (<div></div>)

const StatusIcon: React.FC<PropTypes> = (props: PropTypes) => {
  const { define, status } = props
  const classes = useCommonStyles()
  const { t } = useT()
  const statusStart = define.find((el) => el.type === 'START')
  const className = statusStart && status === statusStart.value ? classes.enableStatus : classes.disableStatus
  const currentStatus = define.find((el) => el.value === status)
  const Icon = currentStatus ? config[StatusKey[currentStatus.type]].icon : Empty

  return (
    currentStatus
      ? (<Box
        className={className}
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="center"
      >
        <Icon className={classes.statusIcon} />
        {currentStatus ? t(config[StatusKey[currentStatus.type]].label) : null}
      </Box>)
      : (<Empty />)
  )
}

export default React.memo(StatusIcon)

import { WithdrawPointReason } from '@golden/gdk-admin'
import TKeyType from '../../i18ns/admin/TKeyType'

const withdrawPointReasonName: { [key in WithdrawPointReason]: TKeyType } = {
  [WithdrawPointReason.DEPOSIT]: 'common.deposit',
  [WithdrawPointReason.WITHDRAW]: 'common.withdraw',
  [WithdrawPointReason.ACTIVITY]: 'common.activity',
  [WithdrawPointReason.ADMIN]: 'common.manualChange2',
  [WithdrawPointReason.FEEDBACK]: 'common.feedback',
  [WithdrawPointReason.POINT]: 'common.agentTransfer',
  [WithdrawPointReason.OTHERS]: 'common.other',
  [WithdrawPointReason.RC_ADJUSTMENT]: 'common.riskAdjustment',
  [WithdrawPointReason.RESET]: 'common.reset'
}

export default withdrawPointReasonName

import React, { useCallback } from 'react'
import { ImageDialogPayloadContext } from '../../../views/admin/externalGame/ExternalGameChannelPage'
import useGlobalDialog from '../../../providers/admin/dialog/useGlobalDialog'
import { createGlobalDialogConfig } from '../../../utils/default/DialogHelper'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { useDebouncedCallback } from 'use-debounce/lib'
import ExternalGameChannelImageButton from './ExternalGameChannelImageButton'

interface PropTypes {
  id: number
  name: string
  imageId: number
  imagePath: string
  reload: () => void
}

const ExternalGameChannelUpdateImageButton: React.FC<PropTypes> = (props) => {
  const { id, name, imageId, imagePath, reload } = props
  const gdk = useGDK()
  const globalDialog = useGlobalDialog()
  const [handleDebouncedSubmit] = useDebouncedCallback(useCallback((imageId: number) => {
    gdk.platform.updateChannel(id, { image_id: imageId }).subscribe({
      next: () => {
        reload()
      },
      error: (error) => {
        globalDialog.setConfig(createGlobalDialogConfig({
          showIcon: true,
          variant: 'error',
          showCancel: false,
          message: error.message
        }))
        globalDialog.setOpen({
          id: `updateImageFailure-${id}`,
          value: true,
          isOK: false
        })
      }
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gdk, id]), 200)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSubmit = useCallback(handleDebouncedSubmit, [])
  return (
    <ExternalGameChannelImageButton
      name={name}
      imageId={imageId}
      imagePath={imagePath}
      onChange={handleSubmit}
      context={ImageDialogPayloadContext}
      size={240}
    />
  )
}

export default React.memo(ExternalGameChannelUpdateImageButton)

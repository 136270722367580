import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import CircularProgress from '@material-ui/core/CircularProgress'

const useStyles = makeStyles((theme) => ({
  root: {
    height: `calc(100vh - ${Number(theme.mixins.toolbar.minHeight) + theme.spacing(1)}px)`
  }
}))

const LoadingPage: React.FC = () => {
  const classes = useStyles()
  return (
    <Grid
      className={classes.root}
      container
      direction="column"
      justify="center"
      alignItems="center"
    >
      <CircularProgress disableShrink />
    </Grid>
  )
}

export default React.memo(LoadingPage)

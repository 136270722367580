import React, { useState } from 'react'
import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import ActivityManagementEditLayoutForm, {
  ActivityManagementEditLayoutFormType,
  formToRequest,
  initialForm
} from '../../../components/admin/activity/ActivityManagementEditLayoutForm'
import LoadingAndErrorFrame from '../../../components/default/frames/LoadingAndErrorFrame'
import {
  useRedirectHandleBack,
  useGetDataByParams,
  useDialogHandleSubmit
} from '../../../utils/default/ComplexFlowHook'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { usePageFlow } from '../../../utils/default/PageFlowHook'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import allRoute, { Path } from '../../../components/admin/route/route'
import useT from '../../../i18ns/admin/useT'
import useGlobalDialog from '../../../providers/admin/dialog/useGlobalDialog'
import { createGlobalDialogConfig } from '../../../utils/default/DialogHelper'
import { ActivityBetConditionType, ActivityDepositConditionType, ActivityDurationType, ActivityGameDurationType, ActivityLayoutReq, ActivityModuleType } from '@golden/gdk-admin'
import { useChecker } from '../../../utils/admin/AdminRouteHook'
import { defaultEditorState, parseContent, renderContent } from '@golden/tiptap-react'

const ActivityManagementLayoutUpdatePage: React.FC = () => {
  const classes = useCommonStyles()
  const { t } = useT()
  const gdk = useGDK()
  const globalDialog = useGlobalDialog()
  const writable = useChecker()
  const pageFlow = usePageFlow(true, false)
  const [defaultForm, setDefaultForm] = useState<ActivityManagementEditLayoutFormType>(initialForm())

  useGetDataByParams({
    path: Path.ACTIVITY_MANAGEMENT_LAYOUT_UPDATE,
    gdkFunc: (param: { id: string }) => gdk.activity.getActivityLayout(Number(param.id)),
    gdkFuncDependencies: [gdk],
    onBeforeFetch: pageFlow.setLoadingStart,
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    onSuccess: async (entry) => {
      setDefaultForm({
        activity_id: entry.activity_id,
        type: entry.type,
        title: entry.title,
        startAt: entry.start_at,
        endAt: entry.end_at,
        isNoEndAt: entry.end_at === null,
        showStartAt: entry.show_start_at,
        showEndAt: entry.show_end_at,
        isNoShowEndAt: entry.show_end_at === null,
        content: {
          ...defaultEditorState,
          content: await parseContent(entry.content)
        },
        pure_content: (await (renderContent(entry.content))).replace(/(<([^>]+)>)/ig, ''),
        desktopHomeImage: { url: entry.desktop_home_image_url },
        mobileHomeImage: { url: entry.mobile_home_image_url },
        couponImage: { url: entry.coupon_image_url },
        agentLongImage: entry.agent_long_image_url ? { url: entry.agent_long_image_url, id: undefined } : null,
        isCarousel: entry.is_carousel,
        isGuest: entry.is_guest,
        isMy: entry.is_my,
        isGeneralRule: entry.is_general_rule,
        tagIds: entry.tags.map(item => item.id),

        hasSetting: !!entry.setting,
        moduleType: entry.setting?.type ?? ActivityModuleType.SINGLE,

        singleHasDepositCondition: !!entry.setting?.deposit_condition,
        singleDepositDuration: entry.setting?.deposit_condition?.duration as ActivityDurationType ?? '-',
        singleDepositCustomStartAt: entry.setting?.deposit_condition?.start_at ?? null,
        singleDepositCustomEndAt: entry.setting?.deposit_condition?.end_at ?? null,
        singleDepositType: entry.setting?.deposit_condition?.type ?? ActivityDepositConditionType.SINGLE,
        singleDepositThreshold: entry.setting?.deposit_condition?.threshold?.toString() ?? '',
        singleHasBetCondition: !!entry.setting?.bet_condition,
        singleBetDuration: entry.setting?.bet_condition?.duration as ActivityDurationType ?? '-',
        singleBetCustomStartAt: entry.setting?.bet_condition?.start_at ?? null,
        singleBetCustomEndAt: entry.setting?.bet_condition?.end_at ?? null,
        singleBetType: entry.setting?.bet_condition?.type ?? ActivityBetConditionType.SINGLE,
        singleBetGameType: entry.setting?.bet_condition?.game_id ? 'gameId' : entry.setting?.bet_condition?.sport_games ? 'sportGames' : null,
        singleBetGameIds: entry.setting?.bet_condition?.game_id ?? [],
        singleBetSportGames: entry.setting?.bet_condition?.sport_games ?? [{ game_id: null, tournament_id: null, match_id: null }],
        singleBetThreshold: entry.setting?.bet_condition?.threshold?.toString() ?? '',
        singleFrequency: entry.setting?.frequency ?? '-',
        singleStartAt: entry.setting?.start_at ?? null,
        singleEndAt: entry.setting?.end_at ?? null,

        gameHasDepositCondition: !!entry.setting?.deposit_condition,
        gameDepositDuration: entry.setting?.deposit_condition?.duration as ActivityGameDurationType ?? '-',
        gameDepositType: entry.setting?.deposit_condition?.type ?? ActivityDepositConditionType.SINGLE,
        gameDepositThreshold: entry.setting?.deposit_condition?.threshold?.toString() ?? '',
        gameHasBetCondition: !!entry.setting?.bet_condition,
        gameBetType: entry.setting?.bet_condition?.type ?? ActivityBetConditionType.SINGLE,
        gameBetGameType: entry.setting?.bet_condition?.game_id ? 'gameId' : entry.setting?.bet_condition?.sport_games ? 'sportGames' : null,
        gameBetGameIds: entry.setting?.bet_condition?.game_id ?? [],
        gameBetSportGames: entry.setting?.bet_condition?.sport_games ?? [{ game_id: null, tournament_id: null, match_id: null }],
        gameBetThreshold: entry.setting?.bet_condition?.threshold?.toString() ?? '',
        gameGames: entry.setting?.games ?? [],

        isUpdate: true
      })
      pageFlow.setContentShow()
    },
    onError: pageFlow.setGDKError
  })

  const [handleBack, handleDebouncedBack] = useRedirectHandleBack({ path: allRoute.activityManagement.encodePath({ search: {}, param: {} }) })

  const { handleSubmit } = useDialogHandleSubmit({
    formToRequest,
    globalDialog,
    dialogId: 'updateActivityLayout',
    gdkFunc: (payload) => gdk.activity.updateActivityLayout({ ...payload } as Partial<ActivityLayoutReq<number>> & { activity_id: number }),
    gdkFuncDependencies: [gdk],
    getChangeDialogConfig: () => createGlobalDialogConfig({
      showIcon: false,
      message: t('dialog.confirmUpdate')
    }),
    getSuccessDialogConfig: () => createGlobalDialogConfig({
      showIcon: false,
      showCancel: false,
      message: t('dialog.updateSuccess')
    }),
    getFailDialogConfig: (_, error) => createGlobalDialogConfig({
      showIcon: true,
      variant: 'error',
      showCancel: false,
      message: error.message
    }),
    afterSuccessDialog: handleBack
  })

  return (
    <React.Fragment>
      <Box paddingY={5} paddingX={4}>
        <LoadingAndErrorFrame {...pageFlow.status}>
          <Paper>
            <Box padding={4}>
              <Typography className={classes.goldenWord} variant="h5">{writable ? t('page.updateActivity') : t('page.lookActivity')}</Typography>
              <Box paddingY={4}>
                <ActivityManagementEditLayoutForm
                  isUpdate
                  defaultValue={defaultForm}
                  onSubmit={handleSubmit}
                  okText={t('common.confirmUpdate')}
                  onBack={handleDebouncedBack}
                />
              </Box>
            </Box>
          </Paper>
        </LoadingAndErrorFrame>
      </Box>
    </React.Fragment>
  )
}

export default React.memo(ActivityManagementLayoutUpdatePage)

import React, { useContext, useCallback, createContext, useMemo, Dispatch, SetStateAction } from 'react'
import { UpdatePasswordReq, Branch } from '@golden/gdk-admin'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import MuiTextField, { TextFieldProps } from '@material-ui/core/TextField'
import MuiButton from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import FormStateProvider from '../../default/form/FormStateProvider'
import FormField from '../../default/form/FormField'
import FormSubmitButton from '../../default/form/FormSubmitButton'
import useGDK from '../../../providers/admin/gdk/useGDK'
import useGDKStore from '../../../providers/admin/gdk/useGDKStore'
import useGlobalDialog from '../../../providers/admin/dialog/useGlobalDialog'
import useT from '../../../i18ns/admin/useT'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import { useDialogHandleSubmit } from '../../../utils/default/ComplexFlowHook'
import { createGlobalDialogConfig } from '../../../utils/default/DialogHelper'
import { createDefaultFormState, FormValidation, ValueGetter } from '../../../utils/default/FormHook'
import { createValidatePassword, createValidateNotEmpty } from '../../../utils/default/Validator'
import { getValueFromChangeEvent } from '../../../utils/default/FormHelper'

interface PasswordPayload {
  oldPassword: string
  newPassword: string
  newPasswordConfirmation: string
}

const defaultForm = {
  oldPassword: '',
  newPassword: '',
  newPasswordConfirmation: ''
}

const getValueFromEvent: ValueGetter<PasswordPayload> = {
  oldPassword: getValueFromChangeEvent,
  newPassword: getValueFromChangeEvent,
  newPasswordConfirmation: getValueFromChangeEvent
}

const formToRequest = (form: PasswordPayload): UpdatePasswordReq => ({
  old_password: form.oldPassword,
  new_password: form.newPassword,
  new_password_confirmation: form.newPasswordConfirmation
})

const passwordInputProps = { maxLength: 255 }

const FormContext = createContext(createDefaultFormState(defaultForm))

const TextField = React.memo(MuiTextField)

const Button = React.memo(MuiButton)

const PasswordInput: React.FC = () => {
  const { t } = useT()
  const { value, handleChange, error } = useContext(FormContext)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onChange = useCallback(handleChange('newPassword'), [])
  return (
    <TextField
      inputProps={passwordInputProps}
      type="password"
      fullWidth
      required
      label={t('common.newPassword')}
      placeholder={t('placeholder.inputNewPassword')}
      value={value.newPassword}
      onChange={onChange}
      helperText={error.newPassword ?? t('helperText.newPassword')}
      error={error.newPassword !== null}
    />
  )
}

interface PropTypes {
  setShow: Dispatch<SetStateAction<boolean>>
}

const BaseDataChangeDataSection: React.FC<PropTypes> = (props) => {
  const { setShow } = props
  const classes = useCommonStyles()
  const globalDialog = useGlobalDialog()
  const gdk = useGDK()
  const me = useGDKStore.admin.me()
  const { t } = useT()

  const { handleSubmit: handleSubmitWithDialog } = useDialogHandleSubmit({
    dialogId: 'changePassword',
    globalDialog,
    getChangeDialogConfig: useCallback(() => createGlobalDialogConfig({
      showIcon: false,
      message: t('dialog.confirmUpdatePassword')
    }), [t]),
    getSuccessDialogConfig: useCallback(() => createGlobalDialogConfig({
      showIcon: false,
      showCancel: false,
      message: t('dialog.updatePasswordSuccess')
    }), [t]),
    getFailDialogConfig: useCallback((_, error) => {
      return createGlobalDialogConfig({
        showIcon: true,
        variant: 'error',
        showCancel: false,
        message: error?.message
      })
    }, []),
    formToRequest,
    gdkFunc: (payload) => gdk.admin.changePassword(payload),
    gdkFuncDependencies: [gdk],
    afterSuccessDialog: () => {
      gdk.trunk.trigger([Branch.ME])
      setShow(true)
    }
  })

  const handleSubmit = useCallback((form: PasswordPayload): PasswordPayload => {
    handleSubmitWithDialog(form)
    return {
      oldPassword: '',
      newPassword: '',
      newPasswordConfirmation: ''
    }
  }, [handleSubmitWithDialog])

  const validation: FormValidation<PasswordPayload> = useMemo(() => {
    return {
      oldPassword: [
        {
          func: createValidateNotEmpty('oldPassword', t),
          when: ['change', 'beforeClickSubmit']
        },
        {
          func: createValidatePassword('oldPassword', t),
          when: ['change', 'beforeClickSubmit']
        }
      ],
      newPassword: [
        {
          func: createValidateNotEmpty('newPassword', t),
          when: ['change', 'beforeClickSubmit']
        },
        {
          func: createValidatePassword('newPassword', t),
          when: ['change', 'beforeClickSubmit']
        }, {
          func: (value: unknown, form: PasswordPayload) => {
            if (value as string === form.newPasswordConfirmation || form.newPasswordConfirmation === '') {
              return {
                isPass: true,
                stop: false,
                newError: { password: null, newPasswordConfirmation: null }
              }
            }
            return {
              isPass: false,
              stop: true,
              newError: { password: null, newPasswordConfirmation: t('error.mustEqualNewPassword') }
            }
          },
          when: ['change', 'beforeClickSubmit']
        }
      ],
      newPasswordConfirmation: [
        {
          func: createValidateNotEmpty('newPasswordConfirmation', t),
          when: ['change', 'beforeClickSubmit']
        },
        {
          func: createValidatePassword('newPasswordConfirmation', t),
          when: ['change', 'beforeClickSubmit']
        }, {
          func: (value: unknown, form: PasswordPayload) => {
            if (value as string === form.newPassword) {
              return {
                isPass: true,
                stop: false,
                newError: { password: null, newPasswordConfirmation: null }
              }
            }
            return {
              isPass: false,
              stop: true,
              newError: { password: null, newPasswordConfirmation: t('error.mustEqualNewPassword') }
            }
          },
          when: ['change', 'beforeClickSubmit']
        }
      ]
    }
  }, [t])

  if (me === null) return null

  return (
    <Paper>
      <Box padding={4}>
        <FormStateProvider
          context={FormContext}
          defaultValue={defaultForm}
          onSubmit={handleSubmit}
          getValueFromEvent={getValueFromEvent}
          validation={validation}
        >
          <Grid container direction="column" spacing={4}>
            <Grid item>
              <Box
                paddingY={1.25}
                paddingX={2}
                className={classes.pinkTitleBar}
              >
                <Typography variant="h5">
                  {t('common.baseData')}
                </Typography>
              </Box>
            </Grid>
            <Grid item>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={12} md={3}>
                  <TextField
                    label={t('common.account')}
                    value={me.account}
                    fullWidth
                    disabled
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <TextField
                    label={t('common.nickname')}
                    value={me.nickname}
                    fullWidth
                    disabled
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={12} md={3}>
                  <FormField<PasswordPayload, TextFieldProps>
                    context={FormContext}
                    component={TextField}
                    name="oldPassword"
                    inputProps={passwordInputProps}
                    type="password"
                    fullWidth
                    required
                    label={t('common.oldPassword')}
                    placeholder={t('placeholder.inputOldPassword')}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <PasswordInput />
                </Grid>
                <Grid item xs={12} md={3}>
                  <FormField<PasswordPayload, TextFieldProps>
                    context={FormContext}
                    component={TextField}
                    name="newPasswordConfirmation"
                    inputProps={passwordInputProps}
                    type="password"
                    fullWidth
                    required
                    label={t('common.confirmNewPassword')}
                    placeholder={t('placeholder.inputPasswordAgain')}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Box display="flex" justifyContent="flex-end">
                <FormSubmitButton
                  context={FormContext}
                  component={Button}
                  className={classes.purpleGradualButton}
                  type="submit"
                >
                  {t('common.confirmUpdate')}
                </FormSubmitButton>
              </Box>
            </Grid>
          </Grid>
        </FormStateProvider>
      </Box>
    </Paper>
  )
}

export default React.memo(BaseDataChangeDataSection)

import React, { useState, useCallback } from 'react'
import { useDebouncedCallback } from 'use-debounce'
import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import EditAnnouncementForm, {
  EditAnnouncementFormType,
  formToRequest,
  initialForm
} from '../../../components/admin/forestage/EditAnnouncementForm'
import LoadingAndErrorFrame from '../../../components/default/frames/LoadingAndErrorFrame'
import {
  useRedirectHandleBack,
  useGetDataByParams,
  useRequestFromSearch
} from '../../../utils/default/ComplexFlowHook'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { usePageFlow } from '../../../utils/default/PageFlowHook'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import allRoute, { Path } from '../../../components/admin/route/route'
import useT from '../../../i18ns/admin/useT'
import useGlobalDialog from '../../../providers/admin/dialog/useGlobalDialog'
import { createGlobalDialogConfig } from '../../../utils/default/DialogHelper'
import AnnouncementConfirmDialog from '../../../components/admin/forestage/AnnouncementConfirmDialog'
import { useDialogFlow } from '../../../utils/default/DialogHook'
import { GDKFormError } from '@golden/gdk-admin'
import { searchToRequest } from './AnnouncementPage'
import { defaultEditorState, parseContent } from '@golden/tiptap-react'
import { useChecker } from '../../../utils/admin/AdminRouteHook'

const AnnouncementUpdatePage: React.FC = () => {
  const request = useRequestFromSearch({ searchToRequest })
  const classes = useCommonStyles()
  const { t } = useT()
  const gdk = useGDK()
  const globalDialog = useGlobalDialog()
  const writable = useChecker()
  const pageFlow = usePageFlow(true, false)
  const [id, setId] = useState<number>(0)
  const [defaultForm, setDefaultForm] = useState<EditAnnouncementFormType>(initialForm())
  const [state, setState] = useState<{ open: boolean, form: EditAnnouncementFormType }>({ open: false, form: defaultForm })
  useGetDataByParams({
    path: Path.FORESTAGE_ANNOUNCEMENT_UPDATE,
    gdkFunc: (param: { id: string }) => gdk.announcement.getAnnouncement(Number(param.id)),
    gdkFuncDependencies: [gdk],
    onBeforeFetch: pageFlow.setLoadingStart,
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    onSuccess: async (entry) => {
      setId(entry.id)
      setDefaultForm({
        title: entry.title,
        beginAt: entry.begin_at,
        endAt: entry.end_at,
        content: {
          ...defaultEditorState,
          content: await parseContent(entry.content)
        },
        isPopup: entry.is_pop_up,
        isPinned: entry.is_pinned,
        isMarquee: entry.is_marquee,
        type: entry.type,
        viewers: entry.viewers.map((item) => ({ role: item.role_type, value: item.sub_type }))
      })
      pageFlow.setContentShow()
    },
    onError: pageFlow.setGDKError
  })

  const [handleBack, handleDebouncedBack] = useRedirectHandleBack({ path: allRoute.forestageAnnouncement.encodePath({ search: { ...request }, param: {} }) })

  const [handleDebouncedSubmit] = useDebouncedCallback(useCallback((form: EditAnnouncementFormType) => {
    setState({ open: true, form })
  }, []), 200)

  useDialogFlow(globalDialog, 'updateAnnouncementSuccess', () => {
    handleBack()
    globalDialog.clearState()
  }, [handleBack])

  const handleSubmit = useCallback((form: EditAnnouncementFormType) => {
    handleDebouncedSubmit(form)
    return form
  }, [handleDebouncedSubmit])

  const handleCancel = useCallback(() => setState((last) => ({ ...last, open: false })), [])

  const [handleOK] = useDebouncedCallback(useCallback(() => {
    setState((state) => {
      gdk.announcement.updateAnnouncement(formToRequest(state.form), id).subscribe({
        next: () => {
          globalDialog.setConfig(createGlobalDialogConfig({
            showIcon: false,
            showCancel: false,
            message: t('dialog.updateAnnouncementSuccess')
          }))
          globalDialog.setOpen({ id: 'updateAnnouncementSuccess', value: true, isOK: false })
        },
        error: (error: GDKFormError) => {
          globalDialog.setConfig(createGlobalDialogConfig({
            showIcon: true,
            variant: 'error',
            showCancel: false,
            message: error.message
          }))
          globalDialog.setOpen({ id: 'updateAnnouncementFail', value: true, isOK: false })
        }
      })
      return { ...state, open: false }
    })
  }, [gdk, globalDialog, id, t]), 200)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onOK = useCallback(handleOK, [])
  return (
    <React.Fragment>
      <Box paddingY={5} paddingX={4}>
        <LoadingAndErrorFrame {...pageFlow.status}>
          <Paper>
            <Box padding={4}>
              <Typography className={classes.goldenWord} variant="h5">{writable ? t('page.updateAnnouncement') : t('page.lookAnnouncement')}</Typography>
              <Box paddingY={4}>
                <EditAnnouncementForm
                  defaultValue={defaultForm}
                  onSubmit={handleSubmit}
                  okText={t('common.confirmUpdate')}
                  onBack={handleDebouncedBack}
                />
              </Box>
            </Box>
          </Paper>
        </LoadingAndErrorFrame>
      </Box>
      <AnnouncementConfirmDialog
        title={t('dialog.confirmUpdateAnnouncement')}
        open={state.open}
        form={state.form}
        onCancel={handleCancel}
        onOK={onOK}
      />
    </React.Fragment>
  )
}

export default React.memo(AnnouncementUpdatePage)

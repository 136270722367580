import { useLocation } from 'react-router'
import { PermissionType } from '@golden/gdk-admin'
import useGDKStore from '../../providers/admin/gdk/useGDKStore'
import { findAppRoute } from '../default/RouteHelper'
import allRoute, { routeOrder } from '../../components/admin/route/route'

export const useChecker = (permissions?: PermissionType[]): boolean => {
  const me = useGDKStore.admin.me()
  const { pathname } = useLocation()
  const route = findAppRoute(pathname, routeOrder, allRoute) ?? allRoute.notFound
  if (me === null) return false
  if (permissions) {
    return permissions.some((permission) => me.permissions.includes(permission))
  }
  return route.permissions
    .filter((item) => item.type === 'writable')
    .some((item) => me.permissions.includes(item.value))
}

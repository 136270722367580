import React, { useState, useMemo } from 'react'
import { PaginationRes, DepositCrawler } from '@golden/gdk-admin'
import Box from '@material-ui/core/Box'
import { searchToRequest } from './DepositCrawlerForm'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import useT from '../../../i18ns/admin/useT'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { createDefaultPaginationData, createTableData, formatMoney, formatCount } from '../../../utils/default/TableHelper'
import { usePageFlow } from '../../../utils/default/PageFlowHook'
import { useRequestFromSearch, useGetDataByPayload, usePaginationClickAndChangeUrl } from '../../../utils/default/ComplexFlowHook'
import DateTime from '../../default/present/DateTime'
import allRoute from '../route/route'
import LoadingAndErrorFrame from '../../default/frames/LoadingAndErrorFrame'
import ScrollablePaper from '../../default/present/ScrollablePaper'
import CoreTable from '../../default/present/CoreTable'
import { useChecker } from '../../../utils/admin/AdminRouteHook'
import PointsCell from '../../default/present/PointsCell'
import DepositCrawlerButtons from './DepositCrawlerButtons'

interface RowType {
  id: number
  time: React.ReactElement
  bank: string
  bankAccount: string
  money: React.ReactElement
  name: string
  account: string
  note: string
  memo: string
  operate: React.ReactElement
}

interface SumRowType {
  id: number
  count: string
  cash: string
}

interface PropTypes {
  reload: () => void
  reloadFlag: boolean
}

const DepositCrawlerTable: React.FC<PropTypes> = (props) => {
  const { reload, reloadFlag } = props
  const classes = useCommonStyles()
  const { t } = useT()
  const gdk = useGDK()
  const [list, setList] = useState<PaginationRes<DepositCrawler[]> & { cash_sum: string }>({
    ...createDefaultPaginationData([]),
    cash_sum: '0.0000'
  })
  const pageFlow = usePageFlow()
  const request = useRequestFromSearch({ searchToRequest })
  const writable = useChecker()
  useGetDataByPayload({
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    payload: request!,
    gdkFunc: (payload) => gdk.deposit.getDepositCrawlerList(payload),
    gdkFuncDependencies: [gdk, reloadFlag],
    onBeforeFetch: pageFlow.setLoadingStart,
    onSuccess: (res: PaginationRes<DepositCrawler[]> & { cash_sum: string }) => {
      setList(res)
      pageFlow.setContentShow()
    },
    onError: pageFlow.setGDKError,
    canLoadData: request !== undefined
  })
  const tableClasses = useMemo(() => ({
    head: classes.pinkTableHead,
    row: classes.tableRow,
    cellHead: classes.tableCellHead
  }), [classes])
  const rows: RowType[] = useMemo(() => {
    return list.data.map((item) => {
      return {
        id: item.id,
        time: (<DateTime time={item.created_at} />),
        bank: item.bank_name,
        bankAccount: item.golden_bank_account,
        money: (<PointsCell points={item.cash} />),
        name: item.player_real_name,
        account: item.player_bank_account,
        note: item.note,
        memo: item.memo,
        operate: (
          <DepositCrawlerButtons
            item={item}
            reload={reload}
          />
        )
      } as RowType
    })
  }, [list.data, reload])
  const data = useMemo(() => {
    return createTableData<RowType>(
      {
        id: {
          label: '',
          value: 'id'
        },
        time: {
          label: t('common.bankHandleDate'),
          value: 'time',
          align: 'center'
        },
        bank: {
          label: t('common.depositBank'),
          value: 'bank',
          align: 'center'
        },
        bankAccount: {
          label: t('common.depositAccount'),
          value: 'bankAccount',
          align: 'center'
        },
        money: {
          label: t('common.depositCash'),
          value: 'money',
          align: 'right'
        },
        name: {
          label: t('common.depositName'),
          value: 'name',
          align: 'center'
        },
        account: {
          label: t('common.senderAccount'),
          value: 'account',
          align: 'center'
        },
        note: {
          label: t('common.depositNote'),
          value: 'note',
          align: 'center'
        },
        memo: {
          label: t('common.memo'),
          value: 'memo',
          align: 'center'
        },
        operate: {
          label: t('common.operation'),
          value: 'operate',
          align: 'center'
        }
      },
      writable
        ? [
            'time',
            'bank',
            'bankAccount',
            'money',
            'name',
            'account',
            'note',
            'memo',
            'operate'
          ]
        : [
            'time',
            'bank',
            'bankAccount',
            'money',
            'name',
            'account',
            'note',
            'memo'
          ],
      rows,
      'id'
    )
  }, [rows, t, writable])

  const sumRows: SumRowType[] = useMemo(() => {
    return [{
      id: 1,
      count: formatCount(list.total),
      cash: formatMoney(list.cash_sum)
    }] as SumRowType[]
  }, [list.cash_sum, list.total])

  const sumData = useMemo(() => {
    return createTableData<SumRowType>(
      {
        id: {
          label: '',
          value: 'id'
        },
        count: {
          label: t('common.totalCount'),
          value: 'count',
          align: 'center'
        },
        cash: {
          label: t('common.totalCash'),
          value: 'cash',
          align: 'center'
        }
      },
      [
        'count',
        'cash'
      ],
      sumRows,
      'id'
    )
  }, [sumRows, t])

  const handlePagination = usePaginationClickAndChangeUrl({
    request,
    encodePath: allRoute.depositCrawler.encodePath
  })

  if (request === undefined) return null
  return (
    <React.Fragment>
      <Box paddingBottom={3}>
        <ScrollablePaper marginX={5}>
          <Box padding={4}>
            <LoadingAndErrorFrame { ...pageFlow.status }>
              <CoreTable
                classes={tableClasses}
                data={sumData}
                total={1}
              />
            </LoadingAndErrorFrame>
          </Box>
        </ScrollablePaper>
      </Box>
      <ScrollablePaper marginX={5}>
        <Box padding={4}>
          <LoadingAndErrorFrame { ...pageFlow.status }>
            <CoreTable
              classes={tableClasses}
              data={data}
              total={list.total}
              showPagination
              page={request.page}
              onChangePage={handlePagination}
            />
          </LoadingAndErrorFrame>
        </Box>
      </ScrollablePaper>
    </React.Fragment>
  )
}

export default React.memo(DepositCrawlerTable)

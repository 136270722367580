import React from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import CheckIcon from '@material-ui/icons/Check'
import CancelRoundedIcon from '@material-ui/icons/CancelRounded'
import CustomThemeType from '../../../themes/default/CustomThemeType'

interface styleProps {
  value: boolean
  onColor: string
  offColor: string
  clickable?: boolean
}

const useStyles = makeStyles((theme: CustomThemeType) => ({
  root: (props: styleProps) => {
    const { value, onColor, offColor, clickable } = props
    const color = value ? onColor : offColor
    return {
      display: 'flex',
      flexGrow: 1,
      flexDirection: 'row',
      alignItems: 'center',
      border: `${color} solid 1px`,
      borderRadius: theme.shape.borderRadius,
      color,
      cursor: clickable ? 'pointer' : 'auto',
      '&:hover': {
        boxShadow: `0 0 ${clickable ? 2 : 0}px ${color}`
      },
      minWidth: 150
    }
  },
  iconContainer: (props: styleProps) => {
    const { value, onColor, offColor } = props
    const color = value ? onColor : offColor
    return {
      display: 'flex',
      padding: theme.spacing(1),
      backgroundColor: color,
      color: theme.palette.common.white
    }
  },
  icon: {
    fontSize: 18
  },
  text: {
    display: 'flex',
    flexGrow: 1,
    textAlign: 'center',
    padding: theme.spacing(1),
    minHeight: 34
  }
}))

interface PropTypes {
  text: string
  value: boolean
  onColor: string
  offColor: string
  clickable?: boolean
  onClick?: () => void
  classes?: {
    root?: string
    iconContainer?: string
    icon?: string
    text?: string
  }
}

const StatusLabel: React.FC<PropTypes> = (props) => {
  const { classes: classesProp = {}, text, value, onClick } = props
  const classes = useStyles(props)
  return (
    <div className={clsx(classes.root, classesProp.root)} onClick={onClick}>
      <div className={clsx(classes.iconContainer, classesProp.iconContainer)}>
        {value
          ? (<CheckIcon className={clsx(classes.icon, classesProp.icon)} />)
          : (<CancelRoundedIcon className={clsx(classes.icon, classesProp.icon)} />)}
      </div>
      <div className={clsx(classes.text, classesProp.text)}>
        {text}
      </div>
    </div>
  )
}

export default React.memo(StatusLabel)

import React from 'react'
import Tip from './Tip'
import useT from '../../i18ns/admin/useT'

interface PropTypes {
  color?: string
}

const PayoutTip: React.FC<PropTypes> = (props) => {
  const { color } = props
  const { t } = useT()
  return (<Tip text={t('common.payoutTip')} color={color}/>)
}

export default React.memo(PayoutTip)

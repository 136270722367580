import React, { useMemo } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import allRoute from '../route/route'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { useDialogFlow } from '../../../utils/default/DialogHook'
import useGlobalDialog from '../../../providers/admin/dialog/useGlobalDialog'
import { createGlobalDialogConfig } from '../../../utils/default/DialogHelper'
import { useDialogHandleClick } from '../../../utils/default/ComplexFlowHook'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import useT from '../../../i18ns/admin/useT'
import { useChecker } from '../../../utils/admin/AdminRouteHook'
import { ActivityType } from '@golden/gdk-campaign'

interface PropTypes {
  id: number
  type: ActivityType
  isActive: boolean
  reload: () => void
}

const ActivityManagementTableButtonsBase: React.FC<{
  id: number
  type: ActivityType
  handleDelete: () => void
}> = React.memo((props) => {
  const {
    id,
    type,
    handleDelete
  } = props
  const classes = useCommonStyles()
  const writable = useChecker()
  const { t } = useT()
  const updateTo = useMemo(() => {
    if (type === ActivityType.BANNER) {
      return allRoute.activityManagementBannerUpdate.encodePath({ search: {}, param: { id } })
    } else if (type === ActivityType.LAYOUT) {
      return allRoute.activityManagementLayoutUpdate.encodePath({ search: {}, param: { id } })
    } else {
      return allRoute.activityManagementLayoutSystemUpdate.encodePath({ search: {}, param: { id: type } })
    }
  }, [id, type])
  const lookTo = useMemo(() => {
    if (type === ActivityType.BANNER) {
      return allRoute.activityManagementBannerLook.encodePath({ search: {}, param: { id } })
    } else if (type === ActivityType.LAYOUT) {
      return allRoute.activityManagementLayoutLook.encodePath({ search: {}, param: { id } })
    } else {
      return allRoute.activityManagementLayoutSystemLook.encodePath({ search: {}, param: { id: type } })
    }
  }, [id, type])

  const toCopy = useMemo(() => {
    if (type === ActivityType.LAYOUT) return allRoute.activityManagementLayoutCreate.encodePath({ search: { id }, param: {} })
    return null
  }, [id, type])

  return (
    <Grid container direction="column" spacing={1} justifyContent="center">
      <Grid item>
        <Button
          component={Link}
          to={lookTo}
          size="small"
          className={classes.blueGradualOutlineButton}
          classes={{ disabled: classes.blueGradualOutlineDisabledButton }}
        >
          {t('common.look')}
        </Button>
      </Grid>
      { writable && !!toCopy && (<Grid item>
        <Button
          component={Link}
          to={toCopy}
          size="small"
          className={classes.yellowGradualOutlineButton}
          classes={{ disabled: classes.yellowGradualOutlineDisabledButton }}
        >
          {t('common.copy')}
        </Button>
      </Grid>) }
      { writable && (<Grid item>
        <Button
          component={Link}
          to={updateTo}
          size="small"
          className={classes.blueGradualOutlineButton}
          classes={{ disabled: classes.blueGradualOutlineDisabledButton }}
        >
          {t('common.update')}
        </Button>
      </Grid>) }
      { writable && (<Grid item>
        <Button
          onClick={handleDelete}
          size="small"
          className={classes.pinkGradualOutlineButton}
        >
          {t('common.delete')}
        </Button>
      </Grid>) }
    </Grid>
  )
})

const ActivityManagementTableButtons: React.FC<PropTypes> = (props) => {
  const { t } = useT()
  const gdk = useGDK()
  const globalDialog = useGlobalDialog()
  const navigate = useNavigate()
  const { id, type, reload } = props

  const handleDelete = useDialogHandleClick({
    dialogId: `deleteActivity-${id}`,
    globalDialog,
    changeDialogConfig: createGlobalDialogConfig({
      showIcon: false,
      message: t('dialog.confirmDelete')
    }),
    successDialogConfig: createGlobalDialogConfig({
      showIcon: false,
      message: t('dialog.deleteFinish'),
      showCancel: false
    }),
    getFailDialogConfig: (error: string) => createGlobalDialogConfig({
      variant: 'error',
      showIcon: true,
      message: error,
      showCancel: false
    }),
    payload: id,
    gdkFunc: (payload) => gdk.activity.deteleActivity(payload),
    gdkFuncDependencies: [gdk],
    onSuccess: () => reload(),
    onSuccessDependencies: [reload]
  })

  useDialogFlow(globalDialog, `updateActivity-${id}`, () => {
    globalDialog.setOpen({ id: '', value: false, isOK: false })
    if (type === 'banner') {
      navigate(allRoute.activityManagementBannerUpdate.encodePath({ search: {}, param: { id } }))
    } else if (type === 'layout') {
      navigate(allRoute.activityManagementLayoutUpdate.encodePath({ search: {}, param: { id } }))
    } else {
      navigate(allRoute.activityManagementLayoutSystemUpdate.encodePath({ search: {}, param: { id: type } }))
    }
    return () => globalDialog.clearState()
  }, [globalDialog.open, navigate, id, type])

  return (
    <ActivityManagementTableButtonsBase
      id={id}
      type={type}
      handleDelete={handleDelete}
    />
  )
}

export default React.memo(ActivityManagementTableButtons)

import React, { useCallback, useMemo } from 'react'
import { omitBy, isUndefined } from '@golden/utils'
import Button from '@material-ui/core/Button'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import useT from '../../../i18ns/admin/useT'
import { EditChannelFormType } from './ChannelFormStateProvider'
import { ChannelForm, GameType } from '@golden/gdk-admin'
import { useDialogHandleClick, useRequestFromSearch } from '../../../utils/default/ComplexFlowHook'
import useGlobalDialog from '../../../providers/admin/dialog/useGlobalDialog'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { createGlobalDialogConfig } from '../../../utils/default/DialogHelper'
import { searchToRequest } from './ExternalGameChannelForm'
import { GameBase } from '@golden/game-china'
import useGDKStore from '../../../providers/admin/gdk/useGDKStore'
import { findGame } from '../../../utils/default/PlatformHelper'

interface PropTypes {
  onBack: () => void
  values: EditChannelFormType[]
  disabled: boolean
}

const formToRequest: (game: GameBase, form: EditChannelFormType) => ChannelForm = (game, form) => {
  const tableSetting = game.tableSetting
  const request: ChannelForm = {
    code: form.code,
    name: form.name,
    image_id: tableSetting.includes('image') && form.image.id ? form.image.id : undefined,
    is_show: tableSetting.includes('isShow') ? form.isShow : undefined,
    category: tableSetting.includes('category') ? form.category : undefined,
    ag_gci_type: tableSetting.includes('agGciType') ? form.agGciType : undefined
  }
  return omitBy(request, isUndefined) as ChannelForm
}

const CreateChannelFormSubmitButton: React.FC<PropTypes> = (props) => {
  const { onBack, disabled, values } = props
  const request = useRequestFromSearch({ searchToRequest })
  const commonClasses = useCommonStyles()
  const gdk = useGDK()
  const globalDialog = useGlobalDialog()
  const { t } = useT()
  const games = useGDKStore.platform.games()

  const payload = useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const game = findGame(request!.gameId as GameType, games)?.instance ?? null
    if (!game) return null
    return { game, channels: values.map((value) => formToRequest(game, value)) }
  }, [games, request, values])

  const handleClick = useDialogHandleClick({
    dialogId: 'createChannel',
    globalDialog,
    payload,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    gdkFunc: (payload) => gdk.platform.createChannels(payload!.game.id, { channels: payload!.channels }),
    gdkFuncDependencies: [gdk],
    changeDialogConfig: createGlobalDialogConfig({
      showIcon: false,
      message: t('dialog.confirmCreate')
    }),
    successDialogConfig: createGlobalDialogConfig({
      showIcon: false,
      showCancel: false,
      message: t('dialog.createSuccess')
    }),
    getFailDialogConfig: useCallback((_, error) => {
      let message = error.message
      if (error.originalColumns) {
        const firstIndex = Object.keys(error.originalColumns)[0] ?? ''
        if (firstIndex !== '') {
          const code = (error.originalColumns[firstIndex][0] as any).code ?? ''
          if (code === 'unique') {
            message = t('error.channelDontRepeat')
          }
        }
      }
      return createGlobalDialogConfig({
        showIcon: true,
        variant: 'error',
        showCancel: false,
        message
      })
    }, [t]),
    afterSuccessDialog: () => onBack(),
    afterSuccessDialogDependencies: [onBack]
  })

  if (!payload) return null

  return (
    <Button
      className={commonClasses.purpleGradualButton}
      disabled={disabled}
      onClick={handleClick}
    >
      {t('common.confirmCreate')}
    </Button>
  )
}

export default React.memo(CreateChannelFormSubmitButton)

import { WithdrawWayType } from '@golden/gdk-admin'
import TKeyType from '../../i18ns/admin/TKeyType'

const WithdrawWayTypeName: { [key in WithdrawWayType]: TKeyType } = {
  [WithdrawWayType.CURRENCY_DEBIT_CARD]: 'common.debitCard',
  [WithdrawWayType.CURRENCY_CRYPTO_ERC]: 'withdraw.cryptoErc',
  [WithdrawWayType.CURRENCY_CRYPTO_TRC]: 'withdraw.cryptoTrc',
  [WithdrawWayType.CURRENCY_TOPAY]: 'withdraw.topay',
  [WithdrawWayType.CURRENCY_EBPAY]: 'withdraw.ebpay',
  [WithdrawWayType.CURRENCY_GOUBAO]: 'withdraw.goubao',
  [WithdrawWayType.CURRENCY_CGPAY]: 'withdraw.cgpay',
  [WithdrawWayType.CURRENCY_CGPAY_USDT]: 'withdraw.cgpay_usdt',
  [WithdrawWayType.CURRENCY_KOIPAY]: 'withdraw.koipay',
  [WithdrawWayType.CURRENCY_PAY988]: 'withdraw.pay988',
  [WithdrawWayType.CURRENCY_PAY808]: 'withdraw.pay808',
  [WithdrawWayType.CURRENCY_KDPAY]: 'withdraw.kdpay',
  [WithdrawWayType.AGENT_TRANSFER_WITHDRAW]: 'withdraw.agent_transfer_withdraw'
}

export default WithdrawWayTypeName

import React, { useMemo, useEffect } from 'react'
import { FormPropType, getValueFromChangeEvent, getValueFromValue } from '../../../utils/default/FormHelper'
import { EditManagerAccountFormType } from './EditManagerAccountForm'
import { FormState, ValueGetter, FormValidation, DisableFieldGetter, useForm } from '../../../utils/default/FormHook'
import useT from '../../../i18ns/admin/useT'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { createValidateNotEmpty, createValidateAccount, createValidatePassword } from '../../../utils/default/Validator'

interface PropTypes extends FormPropType<EditManagerAccountFormType> {
  context: React.Context<FormState<EditManagerAccountFormType>>
  account?: string
  nickname?: string
  disableAccount?: boolean
  disableNickname?: boolean
  requirePassword?: boolean
  requirePasswordConfirmation?: boolean
}

const getValueFromEvent: ValueGetter<EditManagerAccountFormType> = {
  account: getValueFromChangeEvent,
  nickname: getValueFromChangeEvent,
  roleId: getValueFromChangeEvent,
  password: getValueFromChangeEvent,
  passwordConfirmation: getValueFromChangeEvent,
  isLoginable: getValueFromValue,
  isGoogleAuthActive: getValueFromValue,
  memo: getValueFromChangeEvent
}

const AdminFormStateProvider: React.FC<PropTypes> = (props) => {
  const {
    defaultValue,
    onSubmit,
    context: Context,
    account,
    nickname,
    disableAccount,
    disableNickname,
    requirePassword,
    requirePasswordConfirmation,
    children
  } = props
  const { t } = useT()
  const gdk = useGDK()

  const formValidation = useMemo(() => {
    return {
      account: [
        {
          func: createValidateNotEmpty<EditManagerAccountFormType>('account', t),
          when: ['change', 'beforeClickSubmit']
        },
        {
          func: createValidateAccount<EditManagerAccountFormType>('account', t),
          when: ['change', 'beforeClickSubmit']
        }
      ],
      nickname: [
        {
          func: createValidateNotEmpty<EditManagerAccountFormType>('nickname', t),
          when: ['change', 'beforeClickSubmit']
        }
      ],
      roleId: [
        {
          func: createValidateNotEmpty<EditManagerAccountFormType>('roleId', t),
          when: ['blur', 'beforeClickSubmit']
        }
      ],
      password: [
        {
          func: createValidateNotEmpty<EditManagerAccountFormType>('password', t),
          when: requirePassword ? ['change', 'beforeClickSubmit'] : []
        },
        {
          func: createValidatePassword<EditManagerAccountFormType>('password', t),
          when: ['change', 'beforeClickSubmit']
        },
        {
          func: (value, form) => {
            if (value !== '' && value !== form.passwordConfirmation) {
              return { isPass: false, stop: true, newError: { password: null, passwordConfirmation: t('error.mustEqualPassword') } }
            }
            return { isPass: true, stop: false, newError: { password: null, passwordConfirmation: null } }
          },
          when: requirePasswordConfirmation ? ['change', 'beforeClickSubmit'] : []
        }
      ],
      passwordConfirmation: [
        {
          func: createValidateNotEmpty<EditManagerAccountFormType>('passwordConfirmation', t),
          when: requirePasswordConfirmation ? (requirePassword ? ['change', 'beforeClickSubmit'] : []) : []
        },
        {
          func: createValidatePassword<EditManagerAccountFormType>('passwordConfirmation', t),
          when: requirePasswordConfirmation ? ['change', 'beforeClickSubmit'] : []
        },
        {
          func: (value, form) => {
            if (value !== '' && value !== form.password) {
              return { isPass: false, stop: true, newError: { passwordConfirmation: t('error.mustEqualPassword') } }
            }
            return { isPass: true, stop: false, newError: { passwordConfirmation: null } }
          },
          when: requirePasswordConfirmation ? ['change', 'beforeClickSubmit'] : []
        }
      ],
      isLoginable: [],
      isGoogleAuthActive: [],
      memo: []
    } as FormValidation<EditManagerAccountFormType>
  }, [t, requirePassword, requirePasswordConfirmation])

  const disableField = useMemo(() => {
    return {
      account: () => disableAccount,
      nickname: () => disableNickname
    } as DisableFieldGetter<EditManagerAccountFormType>
  }, [disableAccount, disableNickname])

  const formState = useForm<EditManagerAccountFormType>({
    defaultValue,
    onSubmit,
    getValueFromEvent,
    formValidation,
    disableField
  })

  useEffect(() => {
    if (
      formState.value.account !== '' &&
      formState.value.account !== account &&
      formValidation.account.every((validation) => {
        return validation.func(formState.value.account, formState.value, formState.lastSubmitForm, null).isPass
      })
    ) {
      const subscription = gdk.admin.validateAccount(formState.value.account)
        .subscribe({
          next: (res) => {
            if (!res.result) {
              formState.setError((err) => ({ ...err, account: t('error.accountMustNotRepeat') }))
            } else {
              formState.setError((err) => ({ ...err, account: null }))
            }
          },
          error: () => {
            formState.setError((err) => ({ ...err, account: t('error.accountError') }))
          }
        })
      return () => subscription.unsubscribe()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.value.account, formValidation.account, account, gdk, t])

  useEffect(() => {
    if (formState.value.nickname !== '' && formState.value.nickname !== nickname) {
      const subscription = gdk.admin.validateNickname(formState.value.nickname)
        .subscribe({
          next: (res) => {
            if (!res.result) {
              formState.setError((err) => ({ ...err, nickname: t('error.nicknameMustNotRepeat') }))
            } else {
              formState.setError((err) => ({ ...err, nickname: null }))
            }
          },
          error: () => {
            formState.setError((err) => ({ ...err, nickname: t('error.nicknameError') }))
          }
        })
      return () => subscription.unsubscribe()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.value.nickname, nickname, gdk, t])

  const { handleSubmit } = formState

  return (
    <Context.Provider value={formState}>
      <form onSubmit={handleSubmit}>
        {children}
      </form>
    </Context.Provider>
  )
}

export default AdminFormStateProvider

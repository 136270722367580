import React from 'react'
import { SvgIconProps } from '@material-ui/core/SvgIcon'
import ErrorIcon from '@material-ui/icons/Error'
import IconWithBadge from '../../default/present/IconWithBadge'
import useGDKStore from '../../../providers/admin/gdk/useGDKStore'

const EventIcon: React.FC<SvgIconProps> = (props) => {
  const me = useGDKStore.admin.me()
  const hasComplain = me?.has_alert ?? false
  const badgeContent = hasComplain ? 1 : 0
  return (
    <IconWithBadge
      icon={ErrorIcon}
      badgeContent={badgeContent}
      {...props}
    />
  )
}

export default React.memo(EventIcon)

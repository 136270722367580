import i18next from 'i18next'
import HttpApi from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import { languagePosition, defaultLanguage } from '../../enums/default/LanguageType'

import zhCN from './locales/zh-CN'

export const defaultNS = 'translation'
export const resources = {
  'zh-CN': {
    translation: zhCN
  }
} as const

const i18n = i18next
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    load: 'currentOnly',
    lng: defaultLanguage, // 如果希望讓使用者自由切換語系，請把這個拔掉
    ns: ['translation'],
    defaultNS,
    resources,
    fallbackLng: defaultLanguage,
    debug: false,
    supportedLngs: languagePosition,
    interpolation: {
      escapeValue: false,
      prefix: '{',
      suffix: '}'
    },
    react: {
      useSuspense: true
    }
  }, (err) => {
    if (err) console.error(err)
  })

export default i18n

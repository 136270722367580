import React, { useCallback, useMemo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { PlayerRankType } from '@golden/gdk-admin'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import FeedbackInput from './FeedbackInput'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import playerRankName from '../../../constants/default/playerRankName'
import { PageFlowType } from '../../../utils/default/PageFlowHook'
import DropDown from '../../default/form/DropDown'
import useT from '../../../i18ns/admin/useT'
import { createTableData } from '../../../utils/default/TableHelper'
import CoreTable from '../../default/present/CoreTable'
import LoadingAndErrorFrame from '../../default/frames/LoadingAndErrorFrame'

const useStyles = makeStyles((theme) => ({
  dropDown: {
    ...theme.typography.button,
    lineHeight: 1.4,
    padding: 10
  }
}))

type RowType = {
  [key: string]: React.ReactElement
} & {
  id: string
  channel: React.ReactElement
  delete: React.ReactElement
}

const DEFAULT_CHANNEL = -100

const RankInput: React.FC<{
  channel: number
  rank: PlayerRankType
  value: string
  onChange: (channel: number, rank: PlayerRankType, value: string) => void
  disabled?: boolean
}> = React.memo((props) => {
  const { channel, rank, value, onChange, disabled } = props
  const handleChange = useCallback((value: string) => onChange(channel, rank, value), [onChange, channel, rank])
  return (
    <FeedbackInput
      canEmpty
      value={value}
      onChange={handleChange}
      disabled={disabled ?? channel === DEFAULT_CHANNEL}
    />
  )
})

const ChannelInput: React.FC<{
  disabled?: boolean
  options: Array<{ name: string, value: number, disabled?: boolean }>
  value: number
  onChange: (channel: number, value: number) => void
}> = React.memo((props) => {
  const { disabled, options, value, onChange } = props
  const classes = useStyles()
  return (
    <DropDown
      className={classes.dropDown}
      label=""
      options={options}
      variant="outlined"
      value={value}
      disabled={disabled}
      fullWidth
      onChange={useCallback((event) => {
        const newValue = event.target.value
        onChange(value, newValue)
      }, [value, onChange])}
    />
  )
})

export interface PropTypes {
  disabled?: boolean
  channels: Array<{ id: number, name: string }>
  value?: Array<{ channel_id: number, ranks: Array<{ rank: PlayerRankType, feedback: string }> }>
  onChange?: (value: Array<{ channel_id: number, ranks: Array<{ rank: PlayerRankType, feedback: string }> }>) => void
  pageFlow: PageFlowType
}

const FeedbackChannelInput: React.FC<PropTypes> = (props) => {
  const { disabled, channels, value, onChange, pageFlow } = props
  const commonClasses = useCommonStyles()
  const { t } = useT()
  const tableClasses = useMemo(() => ({
    head: commonClasses.blackTableHead,
    row: commonClasses.tableRow,
    cellHead: commonClasses.tableCellHead
  }), [commonClasses])
  const options = useMemo(() => {
    const ids = value?.map((item) => item.channel_id) ?? []
    const channelOptions = channels.map((item) => {
      const disabled = ids.includes(item.id)
      return { name: item.name, value: item.id, disabled }
    })
    return [{ name: t('common.pleaseChoose'), value: DEFAULT_CHANNEL }, ...channelOptions]
  }, [channels, value, t])
  const handleRankChange = useCallback((channel: number, rank: PlayerRankType, newValue: string) => {
    if (value && onChange) {
      const index = value.findIndex((item) => item.channel_id === channel)
      const target = value.find((item) => item.channel_id === channel)
      if (target) {
        const newTarget = { ...target, ranks: target.ranks.filter((item) => item.rank !== rank).concat({ rank, feedback: newValue }) }
        onChange(
          value.slice(0, index).concat(newTarget).concat(value.slice(index + 1, value.length + 1))
        )
      } else {
        onChange(
          value.concat({ channel_id: channel, ranks: [{ rank, feedback: newValue }] })
        )
      }
    }
  }, [value, onChange])
  const handleChannelChange = useCallback((channel: number, newValue: number) => {
    if (value && onChange) {
      const index = value.findIndex((item) => item.channel_id === channel)
      const target = value.find((item) => item.channel_id === channel)
      if (target) {
        if (newValue === DEFAULT_CHANNEL) {
          onChange(
            value.slice(0, index).concat({ channel_id: newValue, ranks: [] }).concat(value.slice(index + 1, value.length + 1))
          )
        } else {
          onChange(
            value.slice(0, index).concat({ ...target, channel_id: newValue }).concat(value.slice(index + 1, value.length + 1))
          )
        }
      } else {
        onChange(value.concat({ channel_id: newValue, ranks: [] }))
      }
    }
  }, [value, onChange])
  const handleCreate = useCallback(() => {
    if (value && onChange) {
      onChange(value.concat({ channel_id: DEFAULT_CHANNEL, ranks: [] }))
    }
  }, [value, onChange])
  const handleDelete = useCallback((index: number) => {
    if (value && onChange) {
      onChange(value.slice(0, index).concat(value.slice(index + 1, value.length + 1)))
    }
  }, [value, onChange])
  const rows = useMemo(() => {
    return value?.map((item, index) => {
      const rankDataObject = Object.keys(playerRankName)
        .map((key) => key as PlayerRankType)
        .map((key) => {
          const target = item.ranks.find((item) => item.rank === key)
          return {
            [key.toString()]: (
              <RankInput
                channel={item.channel_id}
                rank={key}
                value={target?.feedback ?? ''}
                onChange={handleRankChange}
                disabled={disabled}
              />
            )
          }
        })
        .reduce((item, obj) => Object.assign({}, obj, item), {})
      return {
        id: `${item.channel_id} - ${index}`,
        channel: (
          <ChannelInput
            disabled={disabled}
            options={options}
            value={item.channel_id}
            onChange={handleChannelChange}
          />
        ),
        delete: (
          <Button
            className={commonClasses.pinkGradualOutlineButton}
            onClick={() => handleDelete(index)}
          >
            {t('common.delete')}
          </Button>
        ),
        ...rankDataObject
      } as RowType
    }) ?? []
  }, [value, options, handleChannelChange, handleDelete, disabled, handleRankChange, commonClasses, t])
  const data = useMemo(() => {
    const rankColumnObject = Object.keys(playerRankName)
      .map((key) => key as PlayerRankType)
      .map((key) => {
        return {
          [key.toString()]: {
            label: t(playerRankName[key]),
            value: key.toString(),
            align: 'center'
          }
        }
      })
      .reduce((item, obj) => Object.assign({}, item, obj), {})
    return createTableData<RowType>(
      {
        id: {
          label: '',
          value: 'id'
        },
        channel: {
          label: t('common.game'),
          value: 'channel',
          align: 'center'
        },
        delete: {
          label: '',
          value: 'delete',
          align: 'center'
        },
        ...rankColumnObject
      },
      [
        'channel',
        ...Object.keys(playerRankName),
        'delete'
      ],
      rows,
      'id'
    )
  }, [rows, t])
  return (
    <LoadingAndErrorFrame {...pageFlow.status}>
      <Box paddingBottom={2} display="flex" flexDirection="row" justifyContent="flex-end">
        <Button className={commonClasses.purpleGradualButton} onClick={handleCreate} disabled={disabled}>
          {t('common.batchCreate')}
        </Button>
      </Box>
      <CoreTable
        dense
        data={data}
        classes={tableClasses}
        total={value?.length ?? 0}
      />
    </LoadingAndErrorFrame>
  )
}

export default React.memo(FeedbackChannelInput)

import { AdminWithdrawStatusType } from '@golden/gdk-admin'
import TKeyType from '../../i18ns/admin/TKeyType'

const adminWithdrawStatusName: { [key in AdminWithdrawStatusType]: TKeyType } = {
  [AdminWithdrawStatusType.IN_PROGRESS]: 'adminWithdrawStatus.inProgress',
  [AdminWithdrawStatusType.REVIEWING]: 'adminWithdrawStatus.reviewing',
  [AdminWithdrawStatusType.SUCCESSFUL]: 'adminWithdrawStatus.successful',
  [AdminWithdrawStatusType.THIRD_PARTY_WITHDRAWING]: 'adminWithdrawStatus.thirdPartyWithdrawing',
  [AdminWithdrawStatusType.CANCEL]: 'adminWithdrawStatus.cancel',
  [AdminWithdrawStatusType.THIRD_PARTY_WITHDRAW_FAIL]: 'adminWithdrawStatus.thirdPartyWithdrawFail'
}

export default adminWithdrawStatusName

import { useContext } from 'react'
import {
  AuthIsLoggedInContext,
  AuthIsWrongContext,
  AdminMeContext,
  SupportBankContext,
  ForwarderContext,
  PlayerLayerContext,
  ActivityContext,
  WithdrawSlipAccountingContext
} from './GDKStoreProvider'
import { PlatformContext } from '../../../utils/default/PlatformHelper'
import { GameStatus } from '@golden/gdk-admin'

// eslint-disable-next-line react-hooks/rules-of-hooks
const isLoggedIn = () => useContext(AuthIsLoggedInContext)
// eslint-disable-next-line react-hooks/rules-of-hooks
const isWrong = () => useContext(AuthIsWrongContext)
// eslint-disable-next-line react-hooks/rules-of-hooks
const me = () => useContext(AdminMeContext)
// eslint-disable-next-line react-hooks/rules-of-hooks
const supportBanks = () => useContext(SupportBankContext)
// eslint-disable-next-line react-hooks/rules-of-hooks
const forwarders = () => useContext(ForwarderContext)
// eslint-disable-next-line react-hooks/rules-of-hooks
const platformGame = () => useContext(PlatformContext)
// eslint-disable-next-line react-hooks/rules-of-hooks
const layers = () => useContext(PlayerLayerContext)
// eslint-disable-next-line react-hooks/rules-of-hooks
const activities = () => useContext(ActivityContext)
// eslint-disable-next-line react-hooks/rules-of-hooks
const withdrawSlipAccounting = () => useContext(WithdrawSlipAccountingContext)

const useGDKStore = {
  auth: {
    isLoggedIn,
    isWrong
  },
  admin: {
    me
  },
  finance: {
    supportBanks,
    forwarders
  },
  platform: {
    categories: () => platformGame().categories,
    platforms: () => platformGame().platforms,
    alivePlatforms: () => platformGame().platforms.filter((platform) => (platform.games.some((game) => (game.status !== GameStatus.REMOVED)))),
    games: () => platformGame().games,
    aliveGames: () => platformGame().games.filter((game) => (game.status !== GameStatus.REMOVED))
  },
  player: {
    layers
  },
  activity: {
    activities
  },
  withdraw: {
    withdrawSlipAccounting
  }
}

export default useGDKStore

import React from 'react'
import Tip from './Tip'
import useT from '../../i18ns/admin/useT'

const FinishedOrderTip: React.FC = () => {
  const { t } = useT()
  return (<Tip text={t('common.finishOrderTip')} />)
}

export default React.memo(FinishedOrderTip)

import React, { useMemo, useState, useCallback } from 'react'
import { useNavigate, useLocation, Link } from 'react-router-dom'
import { useTheme } from '@material-ui/core/styles'
import { AgentType, LayerStructure, PaginationRes, ProfitLayerInfoItem } from '@golden/gdk-admin'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import CoreTable from '../../default/present/CoreTable'
import PointsCell from '../../default/present/PointsCell'
import Tree, { TreeNode } from '../../default/present/Tree'
import LoadingAndErrorFrame from '../../default/frames/LoadingAndErrorFrame'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import useT from '../../../i18ns/admin/useT'
import agentTypeName from '../../../constants/default/agentTypeName'
import { createTableData, ColumnCollection, formatMoney, createDefaultPaginationData, formatCount } from '../../../utils/default/TableHelper'
import ScrollablePaper from '../../default/present/ScrollablePaper'
import { parsePath } from '../../../utils/default/RouteHelper'
import allRoute, { Path } from '../route/route'
import { searchToRequest } from '../../../components/admin/adminReport/AdminReportProfitForm'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { usePageFlow } from '../../../utils/default/PageFlowHook'
import { useGetDataByParams, useGetDataByPayload, usePaginationClickAndChangeUrl, useRequestFromSearch } from '../../../utils/default/ComplexFlowHook'
import AdminReportProfitFeeDetailsDialog, { PropTypes as DetailsDialogProps, defaultProps as detailsDialogDefaultProps } from './AdminReportProfitFeeDetailsDialog'

interface RowType {
  id: string
  betPlayerCount: number | '--'
  account: React.ReactElement
  effectiveCash: string
  bet: string
  result: string
  feedback: string
  activity: string
  deposit: string
  withdrawal: string
  profit: React.ReactElement
  donate: string
  buyback: string
  platformFee: string
  financeFee: React.ReactElement
  net: React.ReactElement
  percentage: string
  commission: React.ReactElement
  other: string
  risk: string
  transferPoint?: string
}

const AdminReportProfitLayerTable: React.FC = () => {
  const classes = useCommonStyles()
  const theme = useTheme()
  const { t } = useT()
  const navigate = useNavigate()

  const location = useLocation()
  const id: string = useMemo(() => {
    return parsePath(location.search, location.pathname, Path.ADMIN_REPORT_PROFIT_LAYER).param.id
  }, [location.pathname, location.search])

  const gdk = useGDK()
  const request = useRequestFromSearch({ searchToRequest })
  const [dialogOpen, setDialogOpen] = useState<boolean>(false)
  const [dialogParams, setDialogParams] = useState<DetailsDialogProps>(detailsDialogDefaultProps)

  const layerPageFlow = usePageFlow()
  const [nodes, setNodes] = useState<LayerStructure[]>([])
  useGetDataByParams({
    path: Path.ADMIN_REPORT_PROFIT_LAYER,
    gdkFunc: (param: { id: string }) => gdk.emperor.getLayer(Number(param.id)),
    gdkFuncDependencies: [gdk],
    onBeforeFetch: layerPageFlow.setLoadingStart,
    onSuccess: (res) => {
      setNodes(res)
      layerPageFlow.setContentShow()
    },
    onError: layerPageFlow.setGDKError
  })

  const emperor = useMemo(() => nodes.find((node) => node.superior_id === null), [nodes])

  const defaultExpanded: string[] = useMemo(() => {
    if (emperor) {
      const children = nodes.filter((node) => node.superior_id === emperor.id)
      return [`${emperor.id}`].concat(children.slice(0, 1).map((child) => `${child.id}`))
    }
    return []
  }, [nodes, emperor])

  const nodeId = useMemo(() => {
    if (defaultExpanded[1]) {
      if (!request?.nodeId) {
        return Number(defaultExpanded[1])
      }
    }
    return request?.nodeId ?? (emperor?.id ?? 0)
  }, [request, defaultExpanded, emperor])

  const root: TreeNode = useMemo(() => {
    const getNode = (parentId: number | null): TreeNode => {
      const children = nodes.filter((node) => node.superior_id === parentId)
      return {
        positions: children.map((child) => `${child.id}`),
        values: children
          .map((child) => ({
            [`${child.id}`]: {
              label: child.account,
              leaves: getNode(child.id)
            }
          }))
          .reduce((collection, item) => Object.assign({}, collection, item), {})
      }
    }
    return getNode(null)
  }, [nodes])

  const mainPageFlow = usePageFlow()
  const [main, setMain] = useState<ProfitLayerInfoItem | null>(null)
  useGetDataByPayload({
    payload: nodeId,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    gdkFunc: (payload) => gdk.history.getProfitLayer(Number(payload), request!),
    gdkFuncDependencies: [gdk, request],
    onBeforeFetch: mainPageFlow.setLoadingStart,
    onSuccess: (res: ProfitLayerInfoItem) => {
      setMain(res)
      mainPageFlow.setContentShow()
    },
    onError: (error) => {
      mainPageFlow.setGDKError(error)
    },
    canLoadData: request !== undefined
  })

  const columnData: ColumnCollection<RowType> = useMemo(() => {
    return {
      id: {
        label: '',
        value: 'id'
      },
      betPlayerCount: {
        label: t('common.betPeople'),
        value: 'betPlayerCount',
        align: 'center'
      },
      account: {
        label: t(agentTypeName[main?.type ?? AgentType.BOSS]),
        value: 'account',
        align: 'center'
      },
      effectiveCash: {
        label: t('common.successBetCash'),
        value: 'effectiveCash',
        align: 'right'
      },
      bet: {
        label: t('common.allBetCash'),
        value: 'bet',
        align: 'right'
      },
      result: {
        label: t('common.payedCash'),
        value: 'result',
        align: 'right'
      },
      feedback: {
        label: t('common.allFeedback'),
        value: 'feedback',
        align: 'right'
      },
      activity: {
        label: t('common.allActivityCash'),
        value: 'activity',
        align: 'right'
      },
      deposit: {
        label: t('common.allDepositCash'),
        value: 'deposit',
        align: 'right'
      },
      withdrawal: {
        label: t('common.allWithdrawalCash'),
        value: 'withdrawal',
        align: 'right'
      },
      profit: {
        label: t('common.totalWinAndLose'),
        value: 'profit',
        align: 'right'
      },
      donate: {
        label: t('common.totalDonate'),
        value: 'donate',
        align: 'right'
      },
      buyback: {
        label: t('common.totalBuyback'),
        value: 'buyback',
        align: 'right'
      },
      platformFee: {
        label: t('common.platformFee'),
        value: 'platformFee',
        align: 'right'
      },
      financeFee: {
        label: t('common.financeFee'),
        value: 'financeFee',
        align: 'right'
      },
      net: {
        label: t('common.netResult'),
        value: 'net',
        align: 'right'
      },
      percentage: {
        label: t('common.splitPercentage'),
        value: 'percentage',
        align: 'right'
      },
      commission: {
        label: t('common.commission'),
        value: 'commission',
        align: 'right'
      },
      other: {
        label: t('common.other'),
        value: 'other',
        align: 'right'
      },
      risk: {
        label: t('common.riskAdjustment'),
        value: 'risk',
        align: 'right'
      }
    }
  }, [main, t])

  const handleAgentSearch = useCallback((nodeId: number) => {
    navigate(allRoute.adminReportProfitLayer.encodePath({ search: { ...request, nodeId, nextPage: 1 }, param: { id } }))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate, request])

  const mainRows: RowType[] = useMemo(() => {
    if (main === null) return []
    return [{
      id: `${main.id}` || '0',
      betPlayerCount: main.bet_player_count ?? '--',
      account: (
        <span className={classes.anchor} onClick={() => handleAgentSearch(main.id)}>
          {main.account}
        </span>
      ),
      effectiveCash: formatMoney(main.effective_cash),
      bet: formatMoney(main.bet_cash),
      result: formatMoney(main.cash_winning),
      feedback: formatMoney(main.feedback),
      activity: formatMoney(main.activity),
      deposit: formatMoney(main.deposit),
      withdrawal: formatMoney(main.withdraw),
      profit: (<PointsCell points={main.revenue} />),
      donate: formatMoney(main.donate),
      buyback: formatMoney(main.buyback_amount),
      platformFee: formatMoney(main.platform_fee),
      financeFee: (
        <span
          className={classes.anchor}
          onClick={() => {
            setDialogParams({
              target_id: (main.id ?? -1),
              type: 'agent',
              target_name: main.account ?? ''
            })
            setDialogOpen(true)
          }}
        >
          {formatMoney(main.finance_fee ?? '0.0000')}
        </span>
      ),
      net: (<PointsCell points={main.net_revenue} />),
      percentage: `${formatCount(Number(main.percentage))}%${main.is_changed ? '*' : ''}`,
      commission: (<PointsCell points={main.commission ?? '0.0000'} />),
      other: formatMoney(main.other),
      risk: formatMoney(main.risk_adjustment)
    }]
  }, [classes.anchor, main, handleAgentSearch, setDialogParams, setDialogOpen])

  const mainData = useMemo(() => {
    return createTableData(
      columnData,
      [
        'account',
        'betPlayerCount',
        'effectiveCash',
        'bet',
        'result',
        'buyback',
        'feedback',
        'activity',
        'platformFee',
        'risk',
        'profit',
        'financeFee',
        'net',
        'percentage',
        'commission',
        'deposit',
        'withdrawal',
        'donate',
        'other'
      ],
      mainRows,
      'id'
    )
  }, [mainRows, columnData])

  const subPageFlow = usePageFlow(true, false)
  const [sub, setSub] = useState<PaginationRes<ProfitLayerInfoItem[]>>(createDefaultPaginationData([]))
  useGetDataByPayload({
    payload: nodeId,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    gdkFunc: (payload: number) => gdk.history.getProfitLayerChildren(payload, { ...request!, page: request?.nextPage ?? 1 }),
    gdkFuncDependencies: [gdk, request],
    onBeforeFetch: subPageFlow.setLoadingStart,
    onSuccess: (res: PaginationRes<ProfitLayerInfoItem[]>) => {
      setSub(res)
      subPageFlow.setContentShow()
    },
    onError: (error) => {
      subPageFlow.setGDKError(error)
    },
    canLoadData: request !== undefined && nodeId !== 0
  })

  const subRows = useMemo(() => {
    return sub.data.map((child, index) => ({
      id: `${index}`,
      betPlayerCount: child.bet_player_count ?? '--',
      account: child.type
        ? (
        <span className={classes.anchor} onClick={() => handleAgentSearch(child.id)}>
          {child.account}
        </span>
          )
        : (<p>{child.account}</p>),
      effectiveCash: formatMoney(child.effective_cash),
      bet: formatMoney(child.bet_cash),
      result: formatMoney(child.cash_winning),
      feedback: formatMoney(child.feedback),
      activity: formatMoney(child.activity),
      deposit: formatMoney(child.deposit),
      withdrawal: formatMoney(child.withdraw),
      profit: (<PointsCell points={child.revenue} />),
      donate: formatMoney(child.donate),
      buyback: formatMoney(child.buyback_amount),
      platformFee: formatMoney(child.platform_fee),
      financeFee: (
        <span
          className={classes.anchor}
          onClick={() => {
            setDialogParams({
              target_id: (child.id ?? -1),
              type: main?.type === AgentType.STAFF ? 'player' : 'agent',
              target_name: child.account ?? ''
            })
            setDialogOpen(true)
          }}
        >
          {formatMoney(child.finance_fee ?? '0.0000')}
        </span>
      ),
      net: (<PointsCell points={child.net_revenue} />),
      percentage: `${formatCount(Number(child.percentage))}%${child.is_changed ? '*' : ''}`,
      commission: (<PointsCell points={child.commission ?? '0.0000'} />),
      other: formatMoney(child.other),
      risk: formatMoney(child.risk_adjustment),
      transferPoint: (child.transfer_point ? formatMoney(child.transfer_point) : '0.0000')
    })) as RowType[]
  }, [classes.anchor, sub.data, handleAgentSearch, main, setDialogParams, setDialogOpen])

  const subData = useMemo(() => {
    const nextNodeType: AgentType = (main?.type ?? AgentType.EMPEROR) + 1
    return createTableData(
      {
        ...columnData,
        account: {
          label: main?.type === AgentType.STAFF ? t('common.player') : t(agentTypeName[nextNodeType]),
          value: 'account',
          align: 'center'
        },
        transferPoint: {
          label: t('common.transferPoint'),
          value: 'transferPoint',
          align: 'right'
        }
      },
      main?.type === AgentType.STAFF
        ? [
            'account',
            'betPlayerCount',
            'effectiveCash',
            'bet',
            'result',
            'buyback',
            'feedback',
            'activity',
            'platformFee',
            'risk',
            'profit',
            'financeFee',
            'net',
            'deposit',
            'withdrawal',
            'donate',
            'transferPoint',
            'other'
          ]
        : [
            'account',
            'betPlayerCount',
            'effectiveCash',
            'bet',
            'result',
            'buyback',
            'feedback',
            'activity',
            'platformFee',
            'risk',
            'profit',
            'financeFee',
            'net',
            'percentage',
            'commission',
            'deposit',
            'withdrawal',
            'donate',
            'other'
          ],
      subRows,
      'id'
    )
  }, [main, columnData, t, subRows])

  const tableClasses = useMemo(() => {
    return {
      head: classes.greyTableHead,
      cellHead: classes.tableCellHead,
      row: classes.tableRow
    }
  }, [classes])

  const search = useMemo(() => {
    return parsePath(location.search, location.pathname, Path.ADMIN_REPORT_PROFIT_LAYER).search
  }, [location.pathname, location.search])

  const handleChangePage = usePaginationClickAndChangeUrl({
    request,
    encodePath: allRoute.adminReportProfitLayer.encodePath,
    param: { id },
    pageKey: 'nextPage'
  })

  return (
    <React.Fragment>
      <ScrollablePaper marginX={6}>
        <Box padding={4}>
          <Box
            paddingY={1.25}
            paddingX={2}
            marginBottom={3}
            className={classes.pinkTitleBar}
          >
            <Typography variant="h5">
              {t('page.adminReportProfitLayer')}
            </Typography>
          </Box>
          <Grid container spacing={3} direction="row" alignItems="stretch">
            <Grid item xs={12} md={2}>
              <Box
                height="100%"
                bgcolor={theme.palette.common.black}
                padding={2}
                overflow="auto"
              >
                <LoadingAndErrorFrame { ...layerPageFlow.status }>
                  {defaultExpanded.length > 0 && (
                    <Tree
                      color={theme.palette.common.white}
                      root={root}
                      defaultExpanded={defaultExpanded}
                      onLabelClick={(nodeId: string) => handleAgentSearch(Number(nodeId))}
                    />
                  )}
                </LoadingAndErrorFrame>
              </Box>
            </Grid>
            <Grid item xs={12} md={10}>
              <Box paddingBottom={6} width="100%">
                <LoadingAndErrorFrame { ...mainPageFlow.status }>
                  <CoreTable
                    classes={tableClasses}
                    data={mainData}
                    total={1}
                  />
                </LoadingAndErrorFrame>
              </Box>
              <LoadingAndErrorFrame { ...subPageFlow.status }>
                <CoreTable
                  classes={tableClasses}
                  data={subData}
                  total={sub.total}
                  showPagination
                  loading={subPageFlow.status.loading}
                  page={request?.nextPage ?? 1}
                  onChangePage={handleChangePage}
                  hasHeadWithNoData
                />
              </LoadingAndErrorFrame>
            </Grid>
          </Grid>
        </Box>
      </ScrollablePaper>
      <Box paddingX={6} paddingTop={2} display="flex" justifyContent="flex-end">
        <Button
          component={Link}
          to={allRoute.adminReportProfit.encodePath({ search, param: {} })}
          className={classes.greyButton}
        >
          {t('common.back')}
        </Button>
      </Box>
      { dialogOpen && (<AdminReportProfitFeeDetailsDialog
        handleClose={() => { setDialogOpen(false) }}
        target_id={dialogParams.target_id}
        target_name={dialogParams.target_name}
        type={dialogParams.type}
      />) }
    </React.Fragment>
  )
}

export default React.memo(AdminReportProfitLayerTable)

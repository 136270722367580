import React from 'react'
import Box from '@material-ui/core/Box'
import DepositErrorForm from '../../../components/admin/deposit/DepositErrorForm'
import DepositErrorTable from '../../../components/admin/deposit/DepositErrorTable'

const DepositErrorPage: React.FC = () => {
  return (
    <Box paddingY={5}>
      <Box paddingX={5}>
        <DepositErrorForm />
      </Box>
      <Box paddingTop={3}>
        <DepositErrorTable />
      </Box>
    </Box>
  )
}

export default React.memo(DepositErrorPage)

import React from 'react'
import Box from '@material-ui/core/Box'
import FinanceWithdrawalWayToolbar from '../../../components/admin/finance/FinanceWithdrawalWayToolbar'
import FinanceWithdrawalWayTable from '../../../components/admin/finance/FinanceWithdrawalWayTable'
import ScrollablePaper from '../../../components/default/present/ScrollablePaper'
import { SearchToRequestFunc } from '../../../utils/default/ComplexFlowHook'
import { acceptUndefined, pipe, guaranteeBetween } from '../../../utils/default/FormHelper'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { Branch, PaginationReq, WithdrawalWayQuery } from '@golden/gdk-admin'
import { isUndefined, omitBy } from '@golden/utils'

export type RequestType = PaginationReq & WithdrawalWayQuery

export const searchToRequest: SearchToRequestFunc<RequestType> = (search) => {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const is_active = acceptUndefined(
    search.is_active,
    pipe(
      parseInt,
      (value) => guaranteeBetween(value, 0, 2)
    )
  )

  const converted = {
    page: acceptUndefined(
      search.page,
      pipe(
        parseInt,
        (value) => guaranteeBetween(value, 1, Number.MAX_SAFE_INTEGER)
      )
    ) || 1,
    is_active: is_active === undefined ? 1 : is_active,
    method: search.method,
    forwarder_slug: search.forwarder_slug,
    layer_id: search.layer_id
  }
  return omitBy(converted, isUndefined) as RequestType
}

const FinanceWithdrawalWayPage: React.FC = () => {
  const gdk = useGDK()
  gdk.trunk.next([Branch.FORWARDERS])

  return (
    <Box paddingY={5}>
      <ScrollablePaper marginX={5}>
        <Box padding={4}>
          <FinanceWithdrawalWayToolbar />
        </Box>
        <Box paddingTop={1} paddingBottom={5} paddingX={4}>
          <FinanceWithdrawalWayTable />
        </Box>
      </ScrollablePaper>
    </Box>
  )
}

export default React.memo(FinanceWithdrawalWayPage)

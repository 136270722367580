import React from 'react'
import { makeStyles } from '@material-ui/core'
import useT from '../../../i18ns/admin/useT'

const useStyles = makeStyles((theme) => ({
  text: {
    color: theme.palette.error.main
  }
}))

const RequiredText: React.FC = () => {
  const classes = useStyles()
  const { t } = useT()
  return (<span className={classes.text}>{t('common.requiredText')}</span>)
}

export default React.memo(RequiredText)

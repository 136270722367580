import { RecordSearchTimeType } from '@golden/gdk-admin'
import TKeyType from '../../i18ns/admin/TKeyType'

const recordSearchTimeName: { [key in RecordSearchTimeType]: TKeyType } = {
  [RecordSearchTimeType.CREATED_AT]: 'common.betAt',
  [RecordSearchTimeType.SETTLED_AT]: 'common.settledAt',
  [RecordSearchTimeType.GAME_START_AT]: 'common.gameAt'
}

export default recordSearchTimeName

import React, { useCallback } from 'react'
import {
  KeyboardDatePicker as MuiKeyboardDatePicker,
  KeyboardDatePickerProps
} from '@golden/pickers'
import { MaterialUiPickersDate } from '@golden/pickers/typings/date'
import { useInternValue } from '../../../utils/default/FormHook'

export type PropTypes = {
  defaultValue?: Date | null
  value?: Date | null
  label: string
  placeholder?: string
  helperText?: string
  error?: boolean
  required?: boolean
  onBlur?: () => void
  onFocus?: () => void
  onChange?: (value: MaterialUiPickersDate) => void
  shouldDisableDate?: (day: Date | null) => boolean
} & Omit<KeyboardDatePickerProps, 'onChange' | 'value'>

const DatePicker: React.FC<PropTypes> = (props) => {
  const {
    defaultValue,
    value,
    initialFocusedDate,
    label,
    placeholder,
    helperText,
    error,
    required,
    onBlur,
    onFocus,
    onChange,
    FormHelperTextProps,
    inputProps,
    InputProps,
    InputLabelProps,
    InputAdornmentProps,
    disabled,
    readOnly,
    shouldDisableDate
  } = props
  const [internValue, setInternValue] = useInternValue<Date | null>(
    defaultValue ?? value ?? null,
    value
  )
  return (
    <MuiKeyboardDatePicker
      FormHelperTextProps={FormHelperTextProps}
      autoOk
      fullWidth
      variant="inline"
      format="yyyy-MM-dd"
      mask="____-__-__"
      label={label}
      required={required}
      disabled={disabled}
      readOnly={readOnly}
      inputProps={{ ...inputProps, readOnly, placeholder }}
      InputProps={InputProps}
      InputLabelProps={InputLabelProps}
      InputAdornmentProps={InputAdornmentProps}
      value={internValue}
      initialFocusedDate={initialFocusedDate}
      onBlur={onBlur}
      onFocus={onFocus}
      onChange={useCallback((event) => {
        setInternValue(event ?? null)
        if (onChange) onChange(event)
      }, [onChange, setInternValue])}
      helperText={helperText}
      error={error}
      shouldDisableDate={shouldDisableDate}
    />
  )
}

export default React.memo(DatePicker)

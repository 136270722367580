import { makeStyles } from '@material-ui/core'
import React from 'react'

const useStyles = makeStyles(() => ({
  chip: {
    color: '#6f6f6f',
    backgroundColor: '#edf1f5',
    borderRadius: '4px',
    padding: '4px 8px'
  }
}))

const VpnChip: React.FC = () => {
  const classes = useStyles()
  return (
    <span className={classes.chip}>VPN</span>
  )
}

export default React.memo(VpnChip)

import React, { useCallback, useMemo } from 'react'
import { Link } from 'react-router-dom'
import { SvgIconProps } from '@material-ui/core/SvgIcon'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import useDrawer from '../../../providers/default/drawer/useDrawer'
import allRoute from '../route/route'
import RouteKey from '../route/RouteKey'
import GroupKey from './GroupKey'

interface PropTypes {
  classes: Record<'text' | 'itemButton' | 'itemSelected', string>
  groupKey: GroupKey
  icon: React.ComponentType<SvgIconProps>
  title: string
  subItem: RouteKey
}

const PathGroup: React.FC<PropTypes> = (props) => {
  const { groupKey, icon: Icon, title, subItem, classes } = props
  const { itemSelected, itemClicked, setItemClicked, setOpen } = useDrawer()

  const itemShouldHint = useMemo(() => itemClicked === '' ? itemSelected : itemClicked, [itemClicked, itemSelected])

  const handlePathClick = useCallback(() => {
    setItemClicked('')
    setOpen(true)
  }, [setItemClicked, setOpen])

  return (
    <ListItem
      button
      selected={groupKey === itemShouldHint}
      onClick={handlePathClick}
      classes={{
        button: classes.itemButton,
        selected: classes.itemSelected
      }}
      component={Link}
      to={allRoute[subItem].path}
    >
      <ListItemIcon>
        <Icon />
      </ListItemIcon>
      <ListItemText primary={title} className={classes.text} />
    </ListItem>
  )
}

export default React.memo(PathGroup, (prev, next) => {
  return prev.groupKey === next.groupKey && prev.title === next.title
})

import React, { useState, useCallback, useMemo } from 'react'
import { startOfDay, lightFormat, isValid, isAfter, addSeconds, subSeconds } from 'date-fns'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import MuiButton from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import ScheduleIcon from '@material-ui/icons/Schedule'
import useT from '../../../i18ns/admin/useT'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { FeeType, WithdrawFee, WithdrawTip, WithdrawWayType } from '@golden/gdk-admin'
import { KeyboardTimePicker as MuiKeyboardTimePicker } from '@golden/pickers'
import DateTimePicker from '../../../components/default/form/DateTimePicker'
import { formatSimpleTime } from '../../../utils/default/TableHelper'
import OnOffCheckbox from '../../../components/default/form/OnOffCheckbox'
import useGlobalDialog from '../../../providers/admin/dialog/useGlobalDialog'
import { createGlobalDialogConfig } from '../../../utils/default/DialogHelper'
import { getCashInputProps } from '../../../utils/default/FormHelper'
import { useDialogHandleClick, useGetData, useReload } from '../../../utils/default/ComplexFlowHook'
import { usePageFlow } from '../../../utils/default/PageFlowHook'
import LoadingAndErrorFrame from '../../../components/default/frames/LoadingAndErrorFrame'
import { useChecker } from '../../../utils/admin/AdminRouteHook'
import { makeStyles } from '@material-ui/core'
import NumberInput from '../../../components/default/form/NumberInput'
import DropDown from '../../../components/default/form/DropDown'
import WithdrawWayTypeName from '../../../constants/admin/withdrawWayTypeName'
import { forkJoin } from 'rxjs'
import { uniq } from '@golden/utils'
import TKeyType from '../../../i18ns/admin/TKeyType'

const useStyles = makeStyles(() => ({
  subtitle: {
    fontWeight: 600,
    fontSize: 18,
    marginTop: 10
  }
}))

const DelayHintPicker: React.FC<{
  title: TKeyType
  form: FormType
  setForm: (value: React.SetStateAction<FormType>) => void
  writable: boolean
  setError: (value: React.SetStateAction<IFormError>) => void
  protocol: WithdrawWayType
  error: IFormError
}> = React.memo((props) => {
  const { title, form, setForm, writable, setError, protocol, error } = props
  const { t } = useT()

  return (
  <>
     <Grid item>
      <OnOffCheckbox
        label={t('common.toggleOn')}
        value={form.delay.find(item => item.type === protocol)?.enable}
        onChange={useCallback((event) => {
          const checked = event.target.checked
          setForm((form) => ({
            ...form,
            delay: form.delay.map(item => ({ ...item, enable: item.type === protocol ? checked : item.enable }))
          }))
        }, [protocol, setForm])}
        disabled={!writable}
      />
    </Grid>
    <Grid container direction="row" spacing={2}>
      <Grid item>
        <Typography>{t(title)}</Typography>
      </Grid>
      <Grid item xs={12} md={5}>
        <KeyboardTimePicker
          value={form.delay.find(item => item.type === protocol)?.start_time}
          onChange={useCallback((value) => {
            setForm((form) => ({
              ...form,
              delay: form.delay.map(item => ({
                ...item,
                start_time: item.type === protocol ? value as Date : item.start_time
              }))
            }))
            if (value !== null && isValid(value)) {
              setError((error) => {
                const delayDebitCard = form.delay.find(item => item.type === protocol)
                if (delayDebitCard?.end_time !== null && isValid(delayDebitCard?.end_time)) {
                  return {
                    ...error,
                    delay: error.delay.map(item => ({
                      ...item,
                      start_time: item.type === protocol ? null : item.start_time,
                      end_time: item.type === protocol ? null : item.end_time
                    }))
                  }
                }
                return {
                  ...error,
                  delay: error.delay.map(item => ({
                    ...item,
                    start_time: item.type === protocol ? null : item.start_time
                  }))
                }
              })
            } else {
              setError((error) => ({
                ...error,
                delay: error.delay.map(item => ({
                  ...item,
                  start_time: item.type === protocol ? t('error.startAtInvalid') : item.start_time
                }))
              }))
            }
          }, [t, setForm, setError, protocol, form.delay])}
          ampm={false}
          mask="__:__"
          views={['hours', 'minutes']}
          format="HH:mm"
          fullWidth
          keyboardIcon={<ScheduleIcon />}
          helperText={error.delay.find(item => item.type === protocol)?.start_time}
          FormHelperTextProps={{ error: true }}
          error={error.delay.find(item => item.type === protocol)?.start_time !== null}
          required
          disabled={!writable || !form.delay.find(item => item.type === protocol)?.enable}
        />
      </Grid>
      <Grid item xs={12} md={5}>
        <KeyboardTimePicker
          value={form.delay.find(item => item.type === protocol)?.end_time}
          onChange={useCallback((value) => {
            setForm((form) => ({
              ...form,
              delay: form.delay.map(item => ({
                ...item,
                end_time: item.type === protocol ? value as Date : item.end_time
              }))
            }))
            if (value !== null && isValid(value)) {
              setError((error) => {
                if (form.delay.find(item => item.type === protocol)?.start_time !== null && isValid(form.delay.find(item => item.type === protocol)?.start_time)) {
                  return {
                    ...error,
                    delay: error.delay.map(item => ({
                      ...item,
                      start_time: item.type === protocol ? null : item.start_time,
                      end_time: item.type === protocol ? null : item.start_time
                    }))
                  }
                }
                return {
                  ...error,
                  delay: error.delay.map(item => ({
                    ...item,
                    end_time: item.type === protocol ? null : item.end_time
                  }))
                }
              })
            } else {
              setError((error) => ({
                ...error,
                delay: error.delay.map(item => ({
                  ...item,
                  end_time: item.type === protocol ? t('error.endAtInvalid') : item.end_time
                }))
              }))
            }
          }, [t, setForm, setError, protocol, form.delay])}
          ampm={false}
          mask="__:__"
          views={['hours', 'minutes']}
          format="HH:mm"
          fullWidth
          keyboardIcon={<ScheduleIcon />}
          helperText={error.delay.find(item => item.type === protocol)?.end_time ?? ''}
          error={error.delay.find(item => item.type === protocol)?.end_time !== null}
          required
          disabled={!writable || !form.delay.find(item => item.type === protocol)?.enable}
        />
      </Grid>
    </Grid>
  </>)
})

const MaintainNextPoint: React.FC<{
  title: TKeyType
  form: FormType
  setForm: (value: React.SetStateAction<FormType>) => void
  protocol: WithdrawWayType
  writable: boolean
  error: IFormError
  setError: (value: React.SetStateAction<IFormError>) => void
}> = React.memo((props) => {
  const { title, form, setForm, protocol, writable, error, setError } = props
  const { t } = useT()

  return (
    <>
      <Grid item>
        <OnOffCheckbox
          label={t('common.toggleOn')}
          value={form.maintain.find(item => item.type === protocol)?.enable}
          onChange={useCallback((event) => {
            const checked = event.target.checked
            setForm((form) => ({
              ...form,
              maintain: form.maintain.map(item => ({
                ...item,
                enable: item.type === protocol ? checked : item.enable
              }))
            }))
          }, [setForm, protocol])}
          disabled={!writable}
        />
      </Grid>
      <Grid container direction="row" alignItems="center" spacing={2}>
        <Grid item>
          <Typography>{ t(title) }</Typography>
        </Grid>
        <Grid item xs={12} md={5}>
          <DateTimePicker
            value={form.maintain.find(item => item.type === protocol)?.start_time as Date}
            onChange={useCallback((value) => {
              setForm((form) => ({
                ...form,
                maintain: form.maintain.map(item => ({
                  ...item,
                  start_time: item.type === protocol ? value : item.start_time
                }))
              }))
              if (value !== null && isValid(value)) {
                const formMaintain = form.maintain.find(item => item.type === protocol)
                if (formMaintain?.end_time && isAfter(value, subSeconds(formMaintain.end_time, 1))) {
                  setError((error) => ({
                    ...error,
                    maintain: error.maintain.map(item => ({
                      ...item,
                      start_time: item.type === protocol ? t('error.startMustBeforeEnd') : item.start_time
                    }))
                  }))
                } else {
                  setError((error) => {
                    if (formMaintain?.end_time !== null && isValid(formMaintain?.end_time)) {
                      return {
                        ...error,
                        maintain: error.maintain.map(item => ({
                          ...item,
                          start_time: item.type === protocol ? null : item.start_time,
                          end_time: item.type === protocol ? null : item.end_time
                        }))
                      }
                    }
                    return {
                      ...error,
                      maintain: error.maintain.map(item => ({
                        ...item,
                        start_time: item.type === protocol ? null : item.start_time
                      }))
                    }
                  })
                }
              } else {
                setError((error) => ({
                  ...error,
                  maintain: error.maintain.map(item => ({
                    ...item,
                    start_time: item.type === protocol ? t('error.startAtInvalid') : item.start_time
                  }))
                }))
              }
            }, [t, form.maintain, protocol, setError, setForm])}
            fullWidth
            helperText={error.maintain.find(item => item.type === protocol)?.start_time ?? ''}
            FormHelperTextProps={{ error: true }}
            error={error.maintain.find(item => item.type === protocol)?.start_time !== null}
            required
            disabled={!writable || !form.maintain.find(item => item.type === protocol)?.enable}
          />
        </Grid>
        <Grid item xs={12} md={5}>
          <DateTimePicker
            value={form.maintain.find(item => item.type === protocol)?.end_time as Date}
            onChange={useCallback((value) => {
              setForm((form) => ({
                ...form,
                maintain: form.maintain.map(item => ({
                  ...item,
                  end_time: item.type === protocol ? value : item.end_time
                }))
              }))
              if (value !== null && isValid(value)) {
                const formMaintain = form.maintain.find(item => item.type === protocol)
                if (formMaintain?.start_time && isAfter(addSeconds(formMaintain.start_time, 1), value)) {
                  setError((error) => ({
                    ...error,
                    maintain: error.maintain.map(item => ({
                      ...item,
                      end_time: item.type === protocol ? t('error.endMustAfterStart') : item.end_time
                    }))
                  }))
                } else {
                  setError((error) => {
                    if (formMaintain?.start_time !== null && isValid(formMaintain?.start_time)) {
                      return {
                        ...error,
                        maintain: error.maintain.map(item => ({
                          ...item,
                          start_time: item.type === protocol ? null : item.start_time,
                          end_time: item.type === protocol ? null : item.end_time
                        }))
                      }
                    }
                    return {
                      ...error,
                      maintain: error.maintain.map(item => ({
                        ...item,
                        end_time: item.type === protocol ? null : item.end_time
                      }))
                    }
                  })
                }
              } else {
                setError((error) => ({
                  ...error,
                  maintain: error.maintain.map(item => ({
                    ...item,
                    end_time: item.type === protocol ? t('error.endAtInvalid') : item.end_time
                  }))
                }))
              }
            }, [t, form.maintain, protocol, setError, setForm])}
            fullWidth
            helperText={error.maintain.find(item => item.type === protocol)?.end_time ?? ''}
            error={error.maintain.find(item => item.type === protocol)?.end_time !== null}
            required
            disabled={!writable || !form.maintain.find(item => item.type === protocol)?.enable}
          />
        </Grid>
      </Grid>
    </>
  )
})

interface IFormError {
  delay: Array<{
    type: WithdrawWayType
    enable: null | string
    start_time: null | string
    end_time: null | string
  }>
  maintain: Array<{
    type: WithdrawWayType
    enable: null | string
    start_time: null | string
    end_time: null | string
  }>
  withdrawFees: null | string
}

type FormType = WithdrawTip<Date> & { withdrawFees: WithdrawFee[] }

const Button = React.memo(MuiButton)
const KeyboardTimePicker = React.memo(MuiKeyboardTimePicker)

const WithdrawFeeInput: React.FC<{
  disabled: boolean
  withdrawFee: WithdrawFee
  onFeeChange: (event: { target: { value: string } }) => void
  onTypeChange: (event: React.ChangeEvent<{ name?: string | undefined, value: unknown }>) => void
}> = React.memo((props) => {
  const { fee, method, type, currency } = props.withdrawFee

  const { t } = useT()

  const inputProps = {
    ...getCashInputProps(),
    decimalScale: 2,
    allowNegative: false
  }

  const typeOptions = useMemo(() => {
    return [
      { name: currency.toUpperCase(), value: FeeType.DOLLAR },
      { name: '%', value: FeeType.PERCENTAGE }
    ]
  }, [currency])

  return (
    <Grid container item direction="row" alignItems="flex-end" spacing={1}>
      <Grid item xs={2} lg={2}>
        <Typography>{t(WithdrawWayTypeName[method])}</Typography>
      </Grid>
      <Grid item xs={6} lg={3}>
        <NumberInput
          fullWidth
          required
          inputProps={inputProps}
          value={fee}
          onChange={props.onFeeChange}
          disabled={props.disabled}
        />
      </Grid>
      <Grid item xs={4} lg={2}>
        <DropDown
          options={typeOptions}
          value={type}
          onChange={props.onTypeChange}
          fullWidth
          disabled={props.disabled}
        />
      </Grid>
    </Grid>
  )
})

const WithdrawalTipPage: React.FC = () => {
  const gdk = useGDK()
  const pageFlow = usePageFlow()
  const globalDialog = useGlobalDialog()
  const commonClasses = useCommonStyles()
  const classes = useStyles()
  const writable = useChecker()
  const { reload, reloadFlag } = useReload()
  const { t } = useT()
  const zero = startOfDay(new Date())
  const [form, setForm] = useState<FormType>(() => ({
    delay: [],
    maintain: [],
    withdrawFees: [] as WithdrawFee[]
  }))
  const [error, setError] = useState<IFormError>({
    delay: [],
    maintain: [],
    withdrawFees: null
  })

  useGetData({
    gdkFunc: () => forkJoin([gdk.withdraw.getWithdrawTip(), gdk.withdraw.getWithdrawFees()]),
    gdkFuncDependencies: [gdk, reloadFlag],
    onBeforeFetch: pageFlow.setLoadingStart,
    onSuccess: ([tip, fees]) => {
      const day = lightFormat(zero, 'yyyy-MM-dd')
      setForm({
        ...tip,
        delay: tip.delay.map(item => ({
          type: item.type,
          start_time: new Date(`${day} ${item.start_time as string}`),
          end_time: new Date(`${day} ${item.end_time as string}`),
          enable: item.enable
        })),
        withdrawFees: fees
      })
      setError({
        delay: tip.delay.map((item) => ({
          type: item.type,
          enable: null,
          end_time: null,
          start_time: null
        })),
        maintain: tip.maintain.map((item) => ({
          type: item.type,
          enable: null,
          end_time: null,
          start_time: null
        })),
        withdrawFees: null
      })
      pageFlow.setContentShow()
    },
    onError: pageFlow.setGDKError
  })
  const handleSubmit = useDialogHandleClick({
    dialogId: 'modifyWithdrawTip',
    globalDialog,
    changeDialogConfig: createGlobalDialogConfig({
      showIcon: false,
      message: t('dialog.confirmUpdate')
    }),
    successDialogConfig: createGlobalDialogConfig({
      showIcon: false,
      message: t('dialog.updateSuccess'),
      showCancel: false
    }),
    getFailDialogConfig: (error: string) => createGlobalDialogConfig({
      variant: 'error',
      showIcon: true,
      message: error,
      showCancel: false
    }),
    payload: {
      delay: form.delay.map(item => ({
        type: item.type,
        start_time: item.start_time && isValid(item.start_time) ? formatSimpleTime(item.start_time as Date) : '00:00',
        end_time: item.end_time && isValid(item.end_time) ? formatSimpleTime(item.end_time as Date) : '00:00',
        enable: item.enable
      })),
      maintain: form.maintain.map(item => ({
        type: item.type,
        start_time: item.start_time ?? new Date(),
        end_time: item.end_time ?? new Date(),
        enable: item.enable
      })),
      withdrawFees: form.withdrawFees
    },
    gdkFunc: (res: FormType) => {
      const { withdrawFees, ...tip } = res
      return forkJoin([gdk.withdraw.updateWithdrawTip(tip), gdk.withdraw.updateWithdrawFees(withdrawFees)])
    },
    gdkFuncDependencies: [gdk],
    onSuccess: reload
  })

  const disabled = useMemo(() => {
    const _error = {
      delay: error.delay.map(item => ({
        enable: item.enable,
        end_time: item.end_time,
        start_time: item.start_time
      })),
      maintain: error.maintain.map(item => ({
        enable: item.enable,
        end_time: item.end_time,
        start_time: item.start_time
      })),
      withdrawFees: error.withdrawFees
    }

    return uniq([
      ..._error.delay.map(item => Object.values(item)).flat(1).filter(value => value !== null) as string[],
      ..._error.maintain.map(item => Object.values(item)).flat(1).filter(value => value !== null) as string[],
      _error.withdrawFees ? _error.withdrawFees : ''
    ]).length > 1
  }, [error])

  const delayTypes = useMemo(() => form.delay.map((item) => (item.type)), [form])
  const maintainTypes = useMemo(() => form.maintain.map((item) => (item.type)), [form])

  const typesNamingMap: { [key in WithdrawWayType]: TKeyType } = {
    ...WithdrawWayTypeName,
    [WithdrawWayType.CURRENCY_CRYPTO_ERC]: 'protocol.erc20',
    [WithdrawWayType.CURRENCY_CRYPTO_TRC]: 'protocol.trc20'
  }
  return (
    <Box padding={5}>
      <Paper>
        <Box padding={4}>
          <LoadingAndErrorFrame { ...pageFlow.status }>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <Box
                  paddingY={1.25}
                  paddingX={2}
                  className={commonClasses.pinkTitleBar}
                >
                  <Typography variant="h5">
                    {t('page.withdrawSetting')}
                  </Typography>
                </Box>
              </Grid>
              <Grid item>
                <Typography variant="h6" className={classes.subtitle}>{t('common.delayTip')}</Typography>
              </Grid>
              {delayTypes.map((type) => (
                <Grid
                  item
                  key={`${type}-delay`}
                  style={{ width: '100%' }}
                >
                  <DelayHintPicker
                    title={typesNamingMap[type]}
                    form={form}
                    setForm={setForm}
                    writable={writable}
                    setError={setError}
                    protocol={type}
                    error={error}
                  />
                </Grid>
              ))}
              <Grid item>
                <Typography variant="h6" className={classes.subtitle}>{t('common.withdrawMaintain')}</Typography>
                <Typography className={commonClasses.pre}>{t('common.withdrawMaintainTip')}</Typography>
              </Grid>
              {maintainTypes.map((type) => (
                <Grid
                  item
                  key={`${type}-maintain`}
                  style={{ width: '100%' }}
                >
                  <MaintainNextPoint
                    title={typesNamingMap[type]}
                    form={form}
                    setForm={setForm}
                    protocol={type}
                    writable={writable}
                    error={error}
                    setError={setError}
                  />
                </Grid>
              ))}
              <Grid item>
                <Typography variant="h6" className={classes.subtitle}>{t('common.systemFee')}</Typography>
                <Typography className={commonClasses.pre}>{t('common.playerWithdrawFeeTip')}</Typography>
              </Grid>
              {
                form.withdrawFees.map((el) => (
                  <WithdrawFeeInput
                    key={el.id}
                    withdrawFee={el}
                    disabled={!writable}
                    onFeeChange={(event) => {
                      setForm({
                        ...form,
                        withdrawFees: form.withdrawFees.map((item) => (item.id === el.id
                          ? {
                              ...item,
                              fee: event.target.value
                            }
                          : item))
                      })
                    }}
                    onTypeChange={(event) => {
                      setForm({
                        ...form,
                        withdrawFees: form.withdrawFees.map((item) => (item.id === el.id
                          ? {
                              ...item,
                              type: event.target.value as FeeType
                            }
                          : item))
                      })
                    }}
                  />
                ))
              }
              {writable && (
                <Grid item>
                  <Grid container direction="row" justify="flex-end">
                    <Grid item>
                      <Button
                        className={commonClasses.purpleGradualButton}
                        onClick={handleSubmit}
                        disabled={disabled}
                      >
                        {t('common.saveSetting')}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </LoadingAndErrorFrame>
        </Box>
      </Paper>
    </Box>
  )
}

export default React.memo(WithdrawalTipPage)

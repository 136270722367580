import React, { useState, useMemo } from 'react'
import { PaginationRes, DepositSlip, AdminDepositStatusType, GuardType } from '@golden/gdk-admin'
import Box from '@material-ui/core/Box'
import { searchToRequest } from './DepositSlipForm'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import useT from '../../../i18ns/admin/useT'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { createDefaultPaginationData, createTableData, formatMoney, formatCount } from '../../../utils/default/TableHelper'
import { usePageFlow } from '../../../utils/default/PageFlowHook'
import { useRequestFromSearch, useGetDataByPayload, usePaginationClickAndChangeUrl, useReload } from '../../../utils/default/ComplexFlowHook'
import DateTime from '../../default/present/DateTime'
import allRoute from '../route/route'
import LoadingAndErrorFrame from '../../default/frames/LoadingAndErrorFrame'
import ScrollablePaper from '../../default/present/ScrollablePaper'
import CoreTable from '../../default/present/CoreTable'
import { useChecker } from '../../../utils/admin/AdminRouteHook'
import PointsCell from '../../default/present/PointsCell'
import adminDepositStatusName from '../../../constants/admin/adminDepositStatusName'
import DepositSlipButtons from './DepositSlipButtons'
import RefreshCount from '../RefreshCount'

interface RowType {
  id: number
  serial: string
  account: string
  layer: string
  money: React.ReactElement
  fee: string
  name: string
  bank: string
  bankAccount: string
  note: string
  status: React.ReactElement
  sender: string
  operator: string
  apply: React.ReactElement
  confirm: React.ReactElement
  memo: string
  exchangedActivity: string
}

interface SumRowType {
  id: number
  count: string
  cash: string
  fee: string
}

const DepositSlipTable: React.FC = () => {
  const { reload, reloadFlag } = useReload()
  const commonClasses = useCommonStyles()
  const { t } = useT()
  const gdk = useGDK()
  const [list, setList] = useState<PaginationRes<DepositSlip[]> & { cash_sum: string, sum_fee: string }>({
    ...createDefaultPaginationData([]),
    cash_sum: '0.0000',
    sum_fee: '0.0000'
  })
  const pageFlow = usePageFlow()
  const request = useRequestFromSearch({ searchToRequest })
  const writable = useChecker()
  useGetDataByPayload({
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    payload: request!,
    gdkFunc: (payload) => gdk.deposit.getDepositSlipList(payload),
    gdkFuncDependencies: [gdk, reloadFlag],
    onBeforeFetch: pageFlow.setLoadingStart,
    onSuccess: (res: PaginationRes<DepositSlip[]> & { cash_sum: string, sum_fee: string }) => {
      setList(res)
      pageFlow.setContentShow()
    },
    onError: pageFlow.setGDKError,
    canLoadData: request !== undefined
  })
  const tableClasses = useMemo(() => ({
    head: commonClasses.pinkTableHead,
    row: commonClasses.tableRow,
    cellHead: commonClasses.tableCellHead
  }), [commonClasses])
  const rows: RowType[] = useMemo(() => {
    return list.data.map((item) => {
      return {
        id: item.id,
        serial: item.order_number,
        account: item.user_account,
        layer: item.layer_name ?? '',
        money: (<PointsCell points={item.cash} />),
        fee: formatMoney(item.fee),
        name: item.player_real_name,
        bank: item.piggy_name,
        bankAccount: item.piggy_account,
        note: item.note,
        status: writable && item.status === AdminDepositStatusType.IN_PROGRESS ? (<DepositSlipButtons id={item.id} serial={item.order_number} reload={reload} />) : (<p>{t(adminDepositStatusName[item.status])}</p>),
        sender: item.creator_account ? `${item.creator_account}${item.creator_type === GuardType.AGENT ? '*' : ''}` : '',
        operator: item.updated_by,
        apply: (<DateTime time={item.created_at} />),
        confirm: item.finished_at ? (<DateTime time={item.finished_at} />) : (<p></p>),
        memo: item.memo,
        exchangedActivity: item.activity_name ? item.activity_name : '-'
      } as RowType
    })
  }, [list.data, reload, t, writable])
  const data = useMemo(() => {
    return createTableData<RowType>(
      {
        id: {
          label: '',
          value: 'id'
        },
        serial: {
          label: t('common.orderNumber'),
          value: 'serial',
          align: 'center'
        },
        account: {
          label: t('common.playerAccount'),
          value: 'account',
          align: 'center'
        },
        layer: {
          label: t('common.playerLayer'),
          value: 'layer',
          align: 'center'
        },
        money: {
          label: t('common.orderMoney'),
          value: 'money',
          align: 'center'
        },
        fee: {
          label: t('common.playerFee'),
          value: 'fee',
          align: 'center'
        },
        name: {
          label: t('common.depositName'),
          value: 'name',
          align: 'center'
        },
        bank: {
          label: t('common.depositBank'),
          value: 'bank',
          align: 'center'
        },
        bankAccount: {
          label: t('common.depositAccount'),
          value: 'bankAccount',
          align: 'center'
        },
        note: {
          label: t('common.depositNote'),
          value: 'note',
          align: 'center'
        },
        status: {
          label: t('common.orderStatus2'),
          value: 'status',
          align: 'center'
        },
        sender: {
          label: t('common.createSlipPerson'),
          value: 'sender',
          align: 'center'
        },
        operator: {
          label: t('common.updateBy'),
          value: 'operator',
          align: 'center'
        },
        apply: {
          label: t('common.applyTime'),
          value: 'apply',
          align: 'center'
        },
        confirm: {
          label: t('common.confirmTime'),
          value: 'confirm',
          align: 'center'
        },
        memo: {
          label: t('common.memo'),
          value: 'memo',
          align: 'left'
        },
        exchangedActivity: {
          label: t('common.exchangedActivity'),
          value: 'exchangedActivity',
          align: 'center'
        }
      },
      [
        'layer',
        'serial',
        'account',
        'money',
        'fee',
        'name',
        'bank',
        'bankAccount',
        'note',
        'status',
        'sender',
        'operator',
        'apply',
        'confirm',
        'memo',
        'exchangedActivity'
      ],
      rows,
      'id'
    )
  }, [rows, t])

  const sumRows: SumRowType[] = useMemo(() => {
    return [{
      id: 1,
      count: formatCount(list.total),
      cash: formatMoney(list.cash_sum),
      fee: formatMoney(list.sum_fee)
    }] as SumRowType[]
  }, [list.cash_sum, list.sum_fee, list.total])

  const sumData = useMemo(() => {
    return createTableData<SumRowType>(
      {
        id: {
          label: '',
          value: 'id'
        },
        count: {
          label: t('common.totalCount'),
          value: 'count',
          align: 'center'
        },
        cash: {
          label: t('common.totalCash'),
          value: 'cash',
          align: 'center'
        },
        fee: {
          label: t('common.playerFee'),
          value: 'fee',
          align: 'center'
        }
      },
      [
        'count',
        'cash',
        'fee'
      ],
      sumRows,
      'id'
    )
  }, [sumRows, t])

  const handlePagination = usePaginationClickAndChangeUrl({
    request,
    encodePath: allRoute.depositSlip.encodePath
  })

  if (request === undefined) return null
  return (
    <React.Fragment>
      <Box paddingBottom={3}>
        <ScrollablePaper marginX={5}>
          <Box padding={4}>
            <LoadingAndErrorFrame { ...pageFlow.status }>
              <CoreTable
                classes={tableClasses}
                data={sumData}
                total={1}
              />
            </LoadingAndErrorFrame>
          </Box>
        </ScrollablePaper>
      </Box>
      <ScrollablePaper marginX={5}>
        <Box padding={4}>
          <RefreshCount reload={reload} />
          <Box paddingTop={2}>
            <LoadingAndErrorFrame { ...pageFlow.status }>
              <CoreTable
                classes={tableClasses}
                data={data}
                total={list.total}
                showPagination
                page={request.page}
                onChangePage={handlePagination}
              />
            </LoadingAndErrorFrame>
          </Box>
        </Box>
      </ScrollablePaper>
    </React.Fragment>
  )
}

export default React.memo(DepositSlipTable)

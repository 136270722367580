import React, { useCallback, useState, useEffect } from 'react'
import { PiggyAlipayForm, PiggyAlipay, Branch } from '@golden/gdk-admin'
import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import useT from '../../../i18ns/admin/useT'
import useGDK from '../../../providers/admin/gdk/useGDK'
import useGlobalDialog from '../../../providers/admin/dialog/useGlobalDialog'
import { useRedirectHandleBack, useDialogHandleSubmit, useGetDataByParams } from '../../../utils/default/ComplexFlowHook'
import { Path } from '../../../components/admin/route/route'
import { createGlobalDialogConfig } from '../../../utils/default/DialogHelper'
import EditFinancePiggyAlipayForm, { EditFinancePiggyAlipayFormType, initialForm } from '../../../components/admin/finance/EditFinancePiggyAlipayForm'
import LoadingAndErrorFrame from '../../../components/default/frames/LoadingAndErrorFrame'
import { usePageFlow } from '../../../utils/default/PageFlowHook'
import { formatMoney } from '../../../utils/default/TableHelper'

const formToRequest = (form: EditFinancePiggyAlipayFormType): Partial<PiggyAlipayForm> => ({
  cash_max: Number(form.max),
  cash_min: Number(form.min),
  real_name: form.receiver || null,
  memo: form.memo,
  is_active: form.isActive,
  clients: form.clients,
  agent_types: form.agentLayers,
  layer_ids: form.layer.layers,
  code: form.code,
  qr_content: form.qr || null,
  is_for_proxy_deposit: form.layer.isForProxyDeposit
})

const FinancePiggyAlipayUpdatePage: React.FC = () => {
  const classes = useCommonStyles()
  const { t } = useT()
  const gdk = useGDK()
  const [id, setId] = useState<number>(0)
  const [defaultForm, setDefaulForm] = useState<EditFinancePiggyAlipayFormType>(initialForm())
  const pageFlow = usePageFlow()
  const globalDialog = useGlobalDialog()
  useGetDataByParams({
    path: Path.FINANCE_PIGGY_ALIPAY_UPDATE,
    onBeforeFetch: pageFlow.setLoadingStart,
    gdkFunc: (param: { id: string }) => gdk.finance.getPiggyAlipay(Number(param.id)),
    gdkFuncDependencies: [gdk],
    onSuccess: (res: PiggyAlipay<number>) => {
      setId(res.id)
      setDefaulForm(initialForm({
        name: res.name,
        account: res.account,
        min: formatMoney(res.cash_min),
        max: formatMoney(res.cash_max),
        receiver: res.real_name,
        memo: res.memo,
        isActive: res.is_active,
        qr: res.qr_content ?? '',
        clients: res.clients,
        layer: {
          layers: res.layers.map((item) => item.id),
          isForProxyDeposit: res.is_for_proxy_deposit
        },
        agentLayers: res.agent_types
      }))
      pageFlow.setContentShow()
    },
    onError: pageFlow.setGDKError,
    canLoadData: id === 0
  })
  useEffect(() => {
    gdk.trunk.trigger([Branch.PLAYER_LAYER])
  }, [gdk])
  const [handleBack, handleDebouncedBack] = useRedirectHandleBack({ path: Path.FINANCE_PIGGY_ALIPAY })
  const { handleSubmit } = useDialogHandleSubmit({
    formToRequest,
    gdkFunc: (payload) => gdk.finance.updatePiggyAlipay(id, payload),
    gdkFuncDependencies: [gdk, id],
    dialogId: 'updatePiggyAlipay',
    globalDialog,
    getChangeDialogConfig: useCallback((form: EditFinancePiggyAlipayFormType) => createGlobalDialogConfig({
      showIcon: false,
      message: (
        <React.Fragment>
          {t('dialog.confirmUpdatePiggyAlipay')}
          <span className={classes.purpleWord}>{form.name} {form.receiver}</span>
        </React.Fragment>
      )
    }), [t, classes.purpleWord]),
    getSuccessDialogConfig: useCallback(() => createGlobalDialogConfig({
      showIcon: false,
      showCancel: false,
      message: t('dialog.updatePiggyAlipaySuccess')
    }), [t]),
    getFailDialogConfig: useCallback((error) => createGlobalDialogConfig({
      showIcon: true,
      variant: 'error',
      showCancel: false,
      message: error
    }), []),
    afterSuccessDialog: handleBack
  })
  return (
    <Box padding={5}>
      <Paper>
        <Box padding={4}>
          <Box
            paddingY={1.25}
            paddingX={2}
            className={classes.pinkTitleBar}
          >
            <Typography variant="h5">
              {t('page.updatePiggyAlipay')}
            </Typography>
          </Box>
          <Box paddingY={2}>
            <LoadingAndErrorFrame { ...pageFlow.status }>
              <EditFinancePiggyAlipayForm
                okText={t('common.confirmUpdate')}
                onBack={handleDebouncedBack}
                onSubmit={handleSubmit}
                defaultValue={defaultForm}
                disableName
                disableAccount
              />
            </LoadingAndErrorFrame>
          </Box>
        </Box>
      </Paper>
    </Box>
  )
}

export default React.memo(FinancePiggyAlipayUpdatePage)

import React, { createContext, useMemo } from 'react'
import { getBankImage } from '@golden/shared'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import MuiTextField, { TextFieldProps } from '@material-ui/core/TextField'
import OnOffCheckbox, { PropTypes as OnOffCheckboxProps } from '../../default/form/OnOffCheckbox'
import RequiredText from '../../default/form/RequiredText'
import FormStateProvider from '../../default/form/FormStateProvider'
import FormField from '../../default/form/FormField'
import FormSubmitButton from '../../default/form/FormSubmitButton'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import useT from '../../../i18ns/admin/useT'
import { FormPropType, getValueFromChangeEvent, getValueFromCheckboxEvent } from '../../../utils/default/FormHelper'
import { createValidateNotEmpty, createValidateUrl } from '../../../utils/default/Validator'
import { createDefaultFormState, ValueGetter, FormValidation } from '../../../utils/default/FormHook'
import { InitialFormFunc } from '../../../utils/default/ComplexFlowHook'
import ScrollablePaper from '../../default/present/ScrollablePaper'

export interface EditFinanceBankFormType {
  shortName: string
  url: string
  memo: string
  checked: boolean
}

export const initialForm: InitialFormFunc<EditFinanceBankFormType> = (defaultForm) => ({
  shortName: '',
  url: '',
  memo: '',
  checked: false,
  ...defaultForm
})

const getValueFromEvent: ValueGetter<EditFinanceBankFormType> = {
  shortName: getValueFromChangeEvent,
  url: getValueFromChangeEvent,
  memo: getValueFromChangeEvent,
  checked: getValueFromCheckboxEvent
}

interface PropTypes extends FormPropType<EditFinanceBankFormType> {
  title: string
  okText: string
  onBack: () => void
  name: string
  code: string
}

const memoFormHelperTextProps = { error: true }

const FormContext = createContext(createDefaultFormState(initialForm()))

const TextField = React.memo(MuiTextField)

const EditFinanceBankForm: React.FC<PropTypes> = (props) => {
  const { title, okText, onBack, onSubmit, defaultValue, name, code } = props
  const commonClasses = useCommonStyles()
  const { t } = useT()

  const memoInputProps = useMemo(() => ({
    classes: { input: commonClasses.memoField },
    inputProps: {
      placeholder: t('placeholder.inputMemo')
    }
  }), [commonClasses.memoField, t])

  const validation = useMemo(() => {
    return {
      shortName: [
        {
          func: createValidateNotEmpty('shortName', t),
          when: ['change', 'beforeClickSubmit']
        }
      ],
      url: [
        {
          func: createValidateUrl('url', t),
          when: ['change', 'beforeClickSubmit']
        }
      ],
      memo: [],
      checked: []
    } as FormValidation<EditFinanceBankFormType>
  }, [t])

  const imgSrc = useMemo(() => {
    let img = ''
    try {
      img = code !== '' ? getBankImage(code) : ''
    } catch {}
    return img
  }, [code])

  return (
    <FormStateProvider
      context={FormContext}
      onSubmit={onSubmit}
      defaultValue={defaultValue}
      validation={validation}
      getValueFromEvent={getValueFromEvent}
    >
      <ScrollablePaper marginX={5}>
        <Box padding={4}>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <Box
                paddingY={1.25}
                paddingX={2}
                className={commonClasses.pinkTitleBar}
              >
                <Typography variant="h5">
                  {title}
                </Typography>
              </Box>
            </Grid>
            <Grid item>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={12} md={6}>
                  <TextField
                    label={t('common.bankName')}
                    value={name}
                    fullWidth
                    disabled
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label={t('common.bankCode')}
                    value={code}
                    fullWidth
                    disabled
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={12} md={6}>
                  <FormField<EditFinanceBankFormType, TextFieldProps>
                    context={FormContext}
                    component={TextField}
                    name="shortName"
                    label={t('common.bankShortName')}
                    placeholder={t('placeholder.inputBankShortName')}
                    required
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormField<EditFinanceBankFormType, TextFieldProps>
                    context={FormContext}
                    component={TextField}
                    name="url"
                    label={t('common.url')}
                    placeholder={t('placeholder.inputBankUrl')}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <div className={commonClasses.grayFrameContainer}>
                <img src={imgSrc} alt={name} height={120} />
              </div>
            </Grid>
            <Grid item>
              <Grid container direction="row" spacing={2}>
                <Grid item>
                  <FormField<EditFinanceBankFormType, OnOffCheckboxProps>
                    context={FormContext}
                    component={OnOffCheckbox}
                    name="checked"
                    label={t('common.playerCanSee')}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <FormField<EditFinanceBankFormType, TextFieldProps>
                context={FormContext}
                component={TextField}
                name="memo"
                multiline
                fullWidth
                type="text"
                margin="normal"
                variant="outlined"
                InputProps={memoInputProps}
                FormHelperTextProps={memoFormHelperTextProps}
              />
            </Grid>
            <Grid item>
              <RequiredText />
            </Grid>
          </Grid>
          <Box paddingTop={3} display="flex" flexDirection="row" justifyContent="flex-end">
            <Box paddingX={2}>
              <Button
                className={commonClasses.greyButton}
                onClick={onBack}
              >
                {t('common.cancel')}
              </Button>
            </Box>
            <FormSubmitButton
              context={FormContext}
              component={Button}
              type="submit"
              className={commonClasses.purpleGradualButton}
            >
              {okText}
            </FormSubmitButton>
          </Box>
        </Box>
      </ScrollablePaper>
    </FormStateProvider>
  )
}

export default React.memo(EditFinanceBankForm)

import React, { useState, useMemo, createContext, Dispatch, SetStateAction } from 'react'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import useT from '../../../i18ns/admin/useT'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { createDefaultPaginationData, formatCount, formatMoney, createTableData } from '../../../utils/default/TableHelper'
import { PaginationRes, ActivityRankBonus, ActivityRankBonusSum, NormalActivityQuery, PaginationReq, ActivityType } from '@golden/gdk-admin'
import { usePageFlow } from '../../../utils/default/PageFlowHook'
import { searchToRequest } from './ActivityRecordDetailForm'
import { useRequestFromSearch, useGetDataByPayload, usePaginationClickAndChangeUrl } from '../../../utils/default/ComplexFlowHook'
import DateTime from '../../default/present/DateTime'
import ScrollablePaper from '../../default/present/ScrollablePaper'
import useGDKStore from '../../../providers/admin/gdk/useGDKStore'
import CoreTable from '../../default/present/CoreTable'
import allRoute, { Path } from '../route/route'
import LoadingAndErrorFrame from '../../default/frames/LoadingAndErrorFrame'
import MemoTextWithTitleAndContext from '../../default/memo/MemoTextWithTitleAndContext'
import MemoPopoverWithContext from '../../default/memo/MemoPopoverWithContext'
import { MemoPopoverPayload, createDefaultMemoPopoverPayload } from '../../default/memo/MemoPopover'
import { useLocation } from 'react-router-dom'

interface SumRowType {
  id: number
  subject: string
  total: string
  gold: string
  diamond: string
  crown: string
  glory: string
  king: string
  master: string
}

interface RowType {
  id: number
  order: string
  apply: React.ReactElement
  account: string
  staffAccount: string
  content: string
  status: React.ReactElement
  bonus: string
  updatedAt: React.ReactElement
  memo: React.ReactElement
}

const MemoContext = createContext<[MemoPopoverPayload, Dispatch<SetStateAction<MemoPopoverPayload>>]>([
  createDefaultMemoPopoverPayload(),
  () => {}
])

interface PropTypes {
  activity: ActivityType.WEEKLY_BONUS_GOLD | ActivityType.UPGRADE_BONUS_GOLD | ActivityType.BIRTHDAY_BONUS_GOLD
}

const ActivityRecordRankBonusTable: React.FC<PropTypes> = (props) => {
  const { activity } = props
  const classes = useCommonStyles()
  const { t } = useT()
  const gdk = useGDK()
  const activities = useGDKStore.activity.activities()
  const location = useLocation()
  const [list, setList] = useState<PaginationRes<ActivityRankBonus[]> & ActivityRankBonusSum>({
    ...createDefaultPaginationData([]),
    ranks: {
      gold: {
        count: 0,
        bonus: '0.0000'
      },
      diamond: {
        count: 0,
        bonus: '0.0000'
      },
      crown: {
        count: 0,
        bonus: '0.0000'
      },
      glory: {
        count: 0,
        bonus: '0.0000'
      },
      king: {
        count: 0,
        bonus: '0.0000'
      },
      master: {
        count: 0,
        bonus: '0.0000'
      }
    },
    total_count: 0,
    total_bonus: '0.0000'
  })
  const pageFlow = usePageFlow()
  const request = useRequestFromSearch({ searchToRequest })
  useGetDataByPayload({
    payload: request as Omit<NormalActivityQuery, 'status'> & PaginationReq,
    gdkFunc: (payload) => {
      if (activity === ActivityType.UPGRADE_BONUS_GOLD) return gdk.activity.getActivityRecordUpgradeBonus(payload)
      if (activity === ActivityType.WEEKLY_BONUS_GOLD) return gdk.activity.getActivityRecordWeeklyBonus(payload)
      if (activity === ActivityType.BIRTHDAY_BONUS_GOLD) return gdk.activity.getActivityRecordBirthdayBonus(payload)
      return gdk.activity.getActivityRecordUpgradeBonus(payload)
    },
    gdkFuncDependencies: [gdk, activity],
    onBeforeFetch: pageFlow.setLoadingStart,
    onSuccess: (res: PaginationRes<ActivityRankBonus[]> & ActivityRankBonusSum) => {
      setList(res)
      pageFlow.setContentShow()
    },
    onError: pageFlow.setGDKError,
    canLoadData: request !== undefined
  })
  const tableClasses = useMemo(() => ({
    head: classes.greyTableHead,
    row: classes.tableRow,
    cellHead: classes.tableCellHead,
    cellBody: classes.nowrap
  }), [classes])
  const sumRow = useMemo(() => {
    return [{
      id: 1,
      subject: t('common.count'),
      total: formatCount(list.total_count),
      gold: formatCount(list.ranks.gold.count || 0),
      diamond: formatCount(list.ranks.diamond.count || 0),
      crown: formatCount(list.ranks.crown.count || 0),
      glory: formatCount(list.ranks.glory.count || 0),
      king: formatCount(list.ranks.king.count || 0),
      master: formatCount(list.ranks.master.count || 0)
    }, {
      id: 2,
      subject: t('common.bonus'),
      total: formatMoney(list.total_bonus),
      gold: formatMoney(list.ranks.gold.bonus),
      diamond: formatMoney(list.ranks.diamond.bonus),
      crown: formatMoney(list.ranks.crown.bonus),
      glory: formatMoney(list.ranks.glory.bonus),
      king: formatMoney(list.ranks.king.bonus),
      master: formatMoney(list.ranks.master.bonus)
    }] as SumRowType[]
  }, [list, t])
  const sumData = useMemo(() => {
    return createTableData<SumRowType>(
      {
        id: {
          label: '',
          value: 'id'
        },
        subject: {
          label: '',
          value: 'subject',
          align: 'center'
        },
        total: {
          label: t('common.sum'),
          value: 'total',
          align: 'center'
        },
        gold: {
          label: t('player.gold'),
          value: 'gold',
          align: 'center'
        },
        diamond: {
          label: t('player.diamond'),
          value: 'diamond',
          align: 'center'
        },
        crown: {
          label: t('player.crown'),
          value: 'crown',
          align: 'center'
        },
        glory: {
          label: t('player.glory'),
          value: 'glory',
          align: 'center'
        },
        king: {
          label: t('player.king'),
          value: 'king',
          align: 'center'
        },
        master: {
          label: t('player.master'),
          value: 'master',
          align: 'center'
        }
      },
      [
        'subject',
        'total',
        'gold',
        'diamond',
        'crown',
        'glory',
        'king',
        'master'
      ],
      sumRow,
      'id'
    )
  }, [t, sumRow])
  const rows = useMemo(() => {
    return list.data.map((item, index) => {
      return {
        id: index,
        order: item.serial_number,
        apply: (<DateTime time={item.received_at} />),
        account: item.account,
        staffAccount: item.staff_account,
        content: item.activity_content,
        status: (<p className={classes.chipText} style={{ backgroundColor: '#72d476' }}>{t('activityStatus.received')}</p>),
        bonus: formatMoney(item.bonus),
        updatedAt: item.updated_at ? (<DateTime time={item.updated_at} />) : (<p></p>),
        memo: item.memo !== '' && (<MemoTextWithTitleAndContext context={MemoContext} title={item.memo} memo={item.memo} />)
      } as RowType
    })
  }, [list.data, t, classes])
  const data = useMemo(() => {
    return createTableData<RowType>(
      {
        id: {
          label: '',
          value: 'id'
        },
        order: {
          label: t('common.activitySerial'),
          value: 'order',
          align: 'center'
        },
        apply: {
          label: t('common.applyTime'),
          value: 'apply',
          align: 'center'
        },
        account: {
          label: t('common.playerAccount'),
          value: 'account',
          align: 'center'
        },
        staffAccount: {
          label: t('common.staffAccount'),
          value: 'staffAccount',
          align: 'center'
        },
        content: {
          label: t('common.activityContent'),
          value: 'content',
          align: 'left'
        },
        status: {
          label: t('common.status'),
          value: 'status',
          align: 'center'
        },
        bonus: {
          label: t('common.activityBonus'),
          value: 'bonus',
          align: 'right'
        },
        updatedAt: {
          label: t('common.updatedAt'),
          value: 'updatedAt',
          align: 'center'
        },
        memo: {
          label: t('common.memo'),
          value: 'memo',
          align: 'center'
        }
      },
      [
        'order',
        'apply',
        'account',
        'staffAccount',
        'content',
        'status',
        'bonus',
        'updatedAt',
        'memo'
      ],
      rows,
      'id'
    )
  }, [t, rows])
  const handlePagination = usePaginationClickAndChangeUrl({
    request,
    encodePath: (location.pathname === Path.ACTIVITY_RECORD_DETAIL) ? allRoute.activityRecordDetail.encodePath : allRoute.activityRecordSub.encodePath
  })
  return (
    <MemoPopoverWithContext memoPopoverContext={MemoContext}>
      <Box paddingBottom={3}>
        <ScrollablePaper marginX={5}>
          <Box padding={5}>
            <LoadingAndErrorFrame { ...pageFlow.status }>
              <Box
                paddingY={1.25}
                paddingX={2}
                className={classes.pinkTitleBar}
              >
                <Typography variant="h5">
                  {activities.find((item) => item.type === activity)?.name ?? ''}
                </Typography>
              </Box>
              <Box paddingY={2}>
                <Typography variant="h5">
                  {t('common.statisticResult')}
                </Typography>
              </Box>
              <CoreTable
                data={sumData}
                total={1}
                classes={tableClasses}
              />
            </LoadingAndErrorFrame>
          </Box>
        </ScrollablePaper>
      </Box>
      <ScrollablePaper marginX={5}>
        <Box padding={5}>
          <LoadingAndErrorFrame { ...pageFlow.status }>
            <CoreTable
              data={data}
              total={list.total}
              classes={tableClasses}
              showPagination
              page={request?.page ?? 1}
              onChangePage={handlePagination}
            />
          </LoadingAndErrorFrame>
        </Box>
      </ScrollablePaper>
    </MemoPopoverWithContext>
  )
}

export default React.memo(ActivityRecordRankBonusTable)

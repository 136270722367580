import React, { useCallback, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import DropDown from '../../default/form/DropDown'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import useT from '../../../i18ns/admin/useT'
import allRoute from '../route/route'
import { useChecker } from '../../../utils/admin/AdminRouteHook'
import { useRequestFromSearch, useChangeUrlSubmit, useGetData, useReload } from '../../../utils/default/ComplexFlowHook'
import { searchToRequest, RequestType } from '../../../views/admin/finance/FinanceWithdrawalWayPage'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { FinanceWithdrawFeeMethodQuery, FinanceWithdrawQueryList, ForwarderConfig, WithdrawalWayQuery } from '@golden/gdk-admin'
import { forkJoin } from 'rxjs'
import { isUndefined, omitBy } from '@golden/utils'
import FinanceWithdrawalSortDialog from './FinanceWithdrawalSortDialog'

const FinanceWithdrawalWayToolbar: React.FC = () => {
  const request = useRequestFromSearch({ searchToRequest })
  const classes = useCommonStyles()
  const { t } = useT()
  const gdk = useGDK()
  const { reload, reloadFlag } = useReload()

  const [withdrawalQueryData, setWithdrawalQueryData] = useState<FinanceWithdrawQueryList | null>(null)
  const [forwarderList, setForwarderList] = useState<Array<{ name: string, value: string }> | null>(null)
  const [methodList, setMethodList] = useState<FinanceWithdrawFeeMethodQuery[]>([])
  const [isShowWithdrawalSortDialog, setIsShowWithdrawalSortDialog] = useState(false)

  const forwarderOptions = useMemo(() => ([
    { name: t('common.allForwarder'), value: '--' },
    ...forwarderList?.map(forwarder => {
      const count = withdrawalQueryData?.forwarders.find(item => item.slug === forwarder.value)?.count
      return { name: `${forwarder.name}（${count ?? 0}）`, value: forwarder.value }
    }) ?? []
  ]), [forwarderList, withdrawalQueryData, t])
  const methodOptions = useMemo(() => [
    { name: t('common.allMethod'), value: '--' },
    ...withdrawalQueryData?.methods.filter(data => methodList.some(item => item.method === data.method)).map(({ name, count, method }) => ({ name: `${name}（${count}）`, value: method })) ?? []
  ], [methodList, withdrawalQueryData, t])
  const layerOptions = useMemo(() => [
    { name: t('common.allLayer'), value: '--' },
    ...withdrawalQueryData?.layers.map(({ name, count, id }) => ({ name: `${name}（${count ?? 0}）`, value: id })) ?? []
  ],
  [withdrawalQueryData, t])

  const statusOptions = useMemo(() => {
    return [
      { name: t('common.allStatus'), value: 2 },
      { name: t('common.toggleOn'), value: 1 },
      { name: t('common.toggleOff'), value: 0 }
    ]
  }, [t])

  useGetData({
    gdkFunc: () => forkJoin([gdk.finance.getWithdrawQueryList({
      is_active: request?.is_active === 2 ? undefined : request?.is_active,
      method: request?.method === '--' ? undefined : request?.method,
      forwarder_slug: request?.forwarder_slug === '--' ? undefined : request?.forwarder_slug,
      layer_id: request?.layer_id === '--' ? undefined : request?.layer_id
    }), gdk.finance.getForwarderConfig(), gdk.finance.getWithdrawFeeMethod()]),
    gdkFuncDependencies: [gdk, reloadFlag],
    onSuccess: ([query, forwarders, methods]: [FinanceWithdrawQueryList, ForwarderConfig[], FinanceWithdrawFeeMethodQuery[]]) => {
      setWithdrawalQueryData(query)
      setForwarderList(forwarders.map(forwarder => ({ name: forwarder.name, value: forwarder.slug })))
      setMethodList(methods)
    }
  })

  const handleStatusSubmit = useChangeUrlSubmit<{ is_active: number }, RequestType>({
    formToRequest: (form) => {
      const converted = {
        is_active: form.is_active,
        method: request?.method,
        forwarder_slug: request?.forwarder_slug,
        layer_id: request?.layer_id,
        page: 1
      } as RequestType
      return omitBy(converted, isUndefined) as RequestType
    },
    encodePath: allRoute.financeWithdrawalWay.encodePath,
    toAddNowTimestamp: true
  })

  const handleMethodSubmit = useChangeUrlSubmit<{ method: string }, RequestType>({
    formToRequest: (form) => {
      const converted = {
        is_active: request?.is_active,
        method: form.method === '--' ? undefined : form.method,
        forwarder_slug: request?.forwarder_slug,
        layer_id: request?.layer_id,
        page: 1
      } as RequestType
      return omitBy(converted, isUndefined) as RequestType
    },
    encodePath: allRoute.financeWithdrawalWay.encodePath,
    toAddNowTimestamp: true
  })

  const handleForwarderSubmit = useChangeUrlSubmit<{ forwarder_slug: string }, RequestType>({
    formToRequest: (form) => {
      const converted = {
        is_active: request?.is_active,
        method: request?.method,
        forwarder_slug: form.forwarder_slug === '--' ? undefined : form.forwarder_slug,
        layer_id: request?.layer_id,
        page: 1
      } as RequestType
      return omitBy(converted, isUndefined) as RequestType
    },
    encodePath: allRoute.financeWithdrawalWay.encodePath,
    toAddNowTimestamp: true
  })

  const handleLayerIdSubmit = useChangeUrlSubmit<{ layer_id: string }, RequestType>({
    formToRequest: (form) => {
      const converted = {
        is_active: request?.is_active,
        method: request?.method,
        forwarder_slug: request?.forwarder_slug,
        layer_id: form.layer_id === '--' ? undefined : form.layer_id,
        page: 1
      } as RequestType
      return omitBy(converted, isUndefined) as RequestType
    },
    encodePath: allRoute.financeWithdrawalWay.encodePath,
    toAddNowTimestamp: true
  })

  const updateQueryListCount = (payload: { is_active: number | undefined, method: string | undefined, forwarder_slug: string | undefined, layer_id: string | undefined }) => {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { is_active, method, forwarder_slug, layer_id } = payload
    const converted = {
      is_active: is_active === 2 ? undefined : is_active,
      method: method === '--' ? undefined : method,
      forwarder_slug: forwarder_slug === '--' ? undefined : forwarder_slug,
      layer_id: layer_id === '--' ? undefined : layer_id
    } as WithdrawalWayQuery
    gdk.finance.getWithdrawQueryList(omitBy(converted, isUndefined)).subscribe(res => setWithdrawalQueryData(res))
  }

  const writable = useChecker()

  return (
    <Grid container direction="row" justify="flex-end" spacing={2}>
      <Grid item xs={6} md={2}>
        <DropDown
          className={classes.outlineDropDown}
          fullWidth
          variant="outlined"
          label=""
          value={request?.is_active ?? 2}
          onChange={useCallback((event) => {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            const is_active = event.target.value as number
            handleStatusSubmit({ is_active })
            updateQueryListCount({
              is_active,
              method: request?.method,
              forwarder_slug: request?.forwarder_slug,
              layer_id: request?.layer_id
            })
          }, [handleStatusSubmit, updateQueryListCount])}
          options={statusOptions}
        />
      </Grid>
      <Grid item xs={6} md={3}>
        <DropDown
          className={classes.outlineDropDown}
          fullWidth
          variant="outlined"
          label=""
          value={request?.method ?? '--'}
          onChange={useCallback((event) => {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            const method = event.target.value as string
            handleMethodSubmit({ method })
            updateQueryListCount({
              is_active: request?.is_active,
              method,
              forwarder_slug: request?.forwarder_slug,
              layer_id: request?.layer_id
            })
          }, [handleMethodSubmit, updateQueryListCount])}
          options={methodOptions}
        />
      </Grid>
      <Grid item xs={6} md={2}>
        <DropDown
          className={classes.outlineDropDown}
          fullWidth
          variant="outlined"
          label=""
          value={request?.forwarder_slug ?? '--'}
          onChange={useCallback((event) => {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            const slug = event.target.value as string
            handleForwarderSubmit({ forwarder_slug: slug })
            updateQueryListCount({
              is_active: request?.is_active,
              method: request?.method,
              forwarder_slug: slug,
              layer_id: request?.layer_id
            })
          }, [handleForwarderSubmit, updateQueryListCount])}
          options={forwarderOptions}
        />
      </Grid>
      <Grid item xs={6} md={2}>
        <DropDown
          className={classes.outlineDropDown}
          fullWidth
          variant="outlined"
          label=""
          value={request?.layer_id === undefined ? '--' : request.layer_id}
          onChange={useCallback((event) => {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            const layerId = event.target.value as string
            handleLayerIdSubmit({ layer_id: layerId })
            updateQueryListCount({
              is_active: request?.is_active,
              method: request?.method,
              forwarder_slug: request?.forwarder_slug,
              layer_id: layerId
            })
          }, [handleLayerIdSubmit, updateQueryListCount])}
          options={layerOptions}
        />
      </Grid>
      {writable && (
        <>
          <Grid item>
            <Button
              onClick={() => setIsShowWithdrawalSortDialog(true)}
              className={classes.purpleGradualButton}
              classes={{ disabled: classes.disabledButton }}
            >
              <Box paddingY={0.5}>
                {t('common.withdrawalSort')}
              </Box>
            </Button>
            {isShowWithdrawalSortDialog && <FinanceWithdrawalSortDialog open={isShowWithdrawalSortDialog} close={() => setIsShowWithdrawalSortDialog(false)} reload={reload} />}
          </Grid>
          <Grid item>
            <Button
              component={Link}
              to={allRoute.financeWithdrawalWayCreate.encodePath({ search: { ...request }, param: {} })}
              className={classes.purpleGradualButton}
              classes={{ disabled: classes.disabledButton }}
            >
              <Box paddingY={0.5}>
                {t('common.createWithdrawal')}
              </Box>
            </Button>
          </Grid>
        </>
      )}
    </Grid>
  )
}

export default React.memo(FinanceWithdrawalWayToolbar)

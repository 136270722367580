import React, { useCallback } from 'react'
import Button from '@material-ui/core/Button'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import { useDialogHandleClick } from '../../../utils/default/ComplexFlowHook'
import useGlobalDialog from '../../../providers/admin/dialog/useGlobalDialog'
import { createGlobalDialogConfig } from '../../../utils/default/DialogHelper'
import useT from '../../../i18ns/admin/useT'
import useGDK from '../../../providers/admin/gdk/useGDK'

interface PropTypes {
  id: number
  name: string
  reload: () => void
}

const ExternalGameChannelDeleteButton: React.FC<PropTypes> = (props) => {
  const { id, name, reload } = props
  const classes = useCommonStyles()
  const { t } = useT()
  const gdk = useGDK()
  const globalDialog = useGlobalDialog()
  const handleSubmit = useDialogHandleClick({
    globalDialog,
    dialogId: `deleteChannel-${id}`,
    payload: id,
    gdkFunc: (payload) => gdk.platform.deleteChannel(payload),
    gdkFuncDependencies: [gdk],
    changeDialogConfig: createGlobalDialogConfig({
      showIcon: false,
      message: `${t('dialog.confirmDeleteChannel')} - [${name}]`
    }),
    successDialogConfig: createGlobalDialogConfig({
      showIcon: false,
      message: t('dialog.deleteFinish'),
      showCancel: false
    }),
    getFailDialogConfig: useCallback((err) => createGlobalDialogConfig({
      showIcon: true,
      message: err,
      showCancel: false
    }), []),
    onSuccess: reload
  })
  return (
    <Button
      className={classes.pinkGradualOutlineButton}
      onClick={handleSubmit}
    >
      {t('common.delete')}
    </Button>
  )
}

export default React.memo(ExternalGameChannelDeleteButton)

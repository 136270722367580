import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Popover from '@material-ui/core/Popover'
import Box from '@material-ui/core/Box'

const useStyles = makeStyles(() => ({
  popover: {
    pointerEvents: 'none'
  },
  wrap: {
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-word'
  }
}))

export interface MemoPopoverPayload {
  open: boolean
  anchorEl?: Element | ((element: Element) => Element) | null | undefined
  memo?: string
}

export const createDefaultMemoPopoverPayload = (): MemoPopoverPayload => ({
  open: false,
  anchorEl: null,
  memo: ''
})

export type PropTypes = MemoPopoverPayload & {
  onClose?: ((event: {}, reason: 'backdropClick' | 'escapeKeyDown') => void)
}

const MemoPopover: React.FC<PropTypes> = (props) => {
  const classes = useStyles()
  const { open, anchorEl, onClose, memo } = props
  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
      transitionDuration={{
        exit: 50
      }}
      disableRestoreFocus
      className={classes.popover}
    >
      <Box className={classes.wrap} padding={2}>
        {memo}
      </Box>
    </Popover>
  )
}

export default React.memo(MemoPopover)

import React from 'react'
import { getUnixTime } from 'date-fns'
import Box from '@material-ui/core/Box'
import { formatDate, formatTime } from '../../../utils/default/TableHelper'

interface PropTypes {
  time: Date
}

const DateTime: React.FC<PropTypes> = (props) => {
  const { time } = props
  const unixTime = getUnixTime(time)
  if (unixTime === -1) return (<Box>--</Box>)
  return (
    <Box>
      {formatDate(time)}
      <br />
      {formatTime(time)}
    </Box>
  )
}

export default React.memo(DateTime)

import React, { useState, useMemo } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import ScrollablePaper from '../../../components/default/present/ScrollablePaper'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import { useGetData } from '../../../utils/default/ComplexFlowHook'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { usePageFlow } from '../../../utils/default/PageFlowHook'
import { PlayerLeaderboardCategoryItem, PlayerLeaderboardItem } from '@golden/gdk-admin'
import useT from '../../../i18ns/admin/useT'
import { createTableData } from '../../../utils/default/TableHelper'
import CoreTable from '../../../components/default/present/CoreTable'
import LoadingAndErrorFrame from '../../../components/default/frames/LoadingAndErrorFrame'
import allRoute from '../../../components/admin/route/route'
import PointsCell from '../../../components/default/present/PointsCell'
import { forkJoin } from 'rxjs'
import { endOfDay, startOfDay, subDays } from 'date-fns'
import useGDKStore from '../../../providers/admin/gdk/useGDKStore'
import { getCategoryShortName } from '../../../utils/default/PlatformHelper'

interface RowType {
  id: number
  account: React.ReactElement
  revenue: React.ReactElement
}

const PlayerLeaderboardPage: React.FC = () => {
  const gdk = useGDK()
  const pageFlow = usePageFlow()
  const commonClasses = useCommonStyles()
  const storeCategories = useGDKStore.platform.categories()
  const navigate = useNavigate()
  const { t } = useT()
  const [all, setAll] = useState<PlayerLeaderboardItem[]>([])
  const [categories, setCategories] = useState<PlayerLeaderboardCategoryItem[]>([])
  const tableClasses = useMemo(() => {
    return {
      head: commonClasses.greyTableHead,
      row: commonClasses.tableRow,
      cellHead: commonClasses.tableCellHead
    }
  }, [commonClasses.greyTableHead, commonClasses.tableCellHead, commonClasses.tableRow])
  useGetData({
    gdkFunc: () => forkJoin(gdk.history.getPlayerLeaderboardAll(), gdk.history.getPlayerLeaderboardCategories()),
    gdkFuncDependencies: [gdk],
    onBeforeFetch: pageFlow.setLoadingStart,
    onSuccess: (res: [PlayerLeaderboardItem[], PlayerLeaderboardCategoryItem[]]) => {
      setAll(res[0])
      setCategories(res[1])
      pageFlow.setContentShow()
    },
    onError: pageFlow.setGDKError
  })
  const rowsList = useMemo(() => {
    return [
      {
        game_category: 0,
        result: all
      } as const,
      ...storeCategories.map((el) => ({ ...el, game_category: el.instance.id, result: categories.find((item) => item.game_category === el.instance.id)?.result ?? [] }))
    ].map((item) => ({
      ...item,
      result: Object.assign(new Array(10).fill({ account: (<></>), revenue: (<Box height={20} />) }), item.result.map((el) => {
        return {
          account: (
            <Link to={allRoute.playerDetail.encodePath({ search: {}, param: { id: el.id } })}>
              { el.account }
            </Link>
          ),
          revenue: (<Box height={20}><PointsCell points={el.revenue} /></Box>)
        }
      })) as Array<{ id: number, account: React.ReactElement, revenue: React.ReactElement }>
    }))
  }, [all, categories, storeCategories])
  const dataList = useMemo(() => {
    return rowsList.map((rows) => ({
      category: rows.game_category,
      data: createTableData<RowType>(
        {
          id: {
            label: '',
            value: 'id'
          },
          account: {
            label: t('common.account'),
            value: 'account',
            align: 'center'
          },
          revenue: {
            label: t('common.totalWinAndLose'),
            value: 'revenue',
            align: 'right'
          }
        },
        [
          'account',
          'revenue'
        ],
        rows.result,
        'id'
      )
    }))
  }, [rowsList, t])
  return (
    <Box paddingY={6}>
      <ScrollablePaper marginX={6}>
        <Box padding={5}>
          <LoadingAndErrorFrame { ...pageFlow.status }>
            <Grid container direction="column" spacing={3}>
              <Grid item>
                <Box
                  paddingY={1.25}
                  paddingX={2}
                  className={commonClasses.pinkTitleBar}
                >
                  <Typography variant="h5">
                    {t('common.weekLeaderboard')}
                  </Typography>
                </Box>
              </Grid>
              <Grid item container spacing={2}>
                { dataList.map((data) => (
                  <Grid item lg={4} md={6} xs={12}>
                    <Typography variant="h5" className={commonClasses.bold}>{data.category === 0 ? t('common.allPlateform') : getCategoryShortName(data.category, storeCategories)}</Typography>
                    <Box marginTop={1} marginBottom={2}>
                      <CoreTable
                        data={data.data}
                        total={10}
                        classes={tableClasses}
                      />
                    </Box>
                    <Box display="flex" justifyContent="flex-end" marginBottom={3}>
                      <Button
                        onClick={() => navigate(allRoute.adminReportPlayerLeaderboardDetail.encodePath({
                          search: { page: 1, start_at: subDays(startOfDay(new Date()), 6).getTime(), end_at: endOfDay(new Date()).getTime() },
                          param: { id: data.category }
                        }))}
                        className={commonClasses.blueGradualOutlineButton}
                      >
                        {t('common.more')}
                      </Button>
                    </Box>
                  </Grid>
                )) }
              </Grid>
            </Grid>
          </LoadingAndErrorFrame>
        </Box>
      </ScrollablePaper>
    </Box>
  )
}

export default React.memo(PlayerLeaderboardPage)

import React, { useState, useMemo } from 'react'
import { PaginationRes, DepositError } from '@golden/gdk-admin'
import Box from '@material-ui/core/Box'
import { searchToRequest } from './DepositErrorForm'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import useT from '../../../i18ns/admin/useT'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { createDefaultPaginationData, createTableData, formatMoney } from '../../../utils/default/TableHelper'
import { usePageFlow } from '../../../utils/default/PageFlowHook'
import { useRequestFromSearch, useGetDataByPayload, usePaginationClickAndChangeUrl } from '../../../utils/default/ComplexFlowHook'
import DateTime from '../../default/present/DateTime'
import allRoute from '../route/route'
import LoadingAndErrorFrame from '../../default/frames/LoadingAndErrorFrame'
import ScrollablePaper from '../../default/present/ScrollablePaper'
import CoreTable from '../../default/present/CoreTable'

interface RowType {
  id: number
  time: React.ReactElement
  account: string
  name: string
  cash: string
  message: string
  operator: string
}

const DepositErrorTable: React.FC = () => {
  const classes = useCommonStyles()
  const { t } = useT()
  const gdk = useGDK()
  const [list, setList] = useState<PaginationRes<DepositError[]>>(createDefaultPaginationData([]))
  const pageFlow = usePageFlow()
  const request = useRequestFromSearch({ searchToRequest })
  useGetDataByPayload({
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    payload: request!,
    gdkFunc: (payload) => gdk.deposit.getDepositError(payload),
    gdkFuncDependencies: [gdk],
    onBeforeFetch: pageFlow.setLoadingStart,
    onSuccess: (res: PaginationRes<DepositError[]>) => {
      setList(res)
      pageFlow.setContentShow()
    },
    onError: pageFlow.setGDKError,
    canLoadData: request !== undefined
  })
  const tableClasses = useMemo(() => ({
    head: classes.pinkTableHead,
    row: classes.tableRow,
    cellHead: classes.tableCellHead
  }), [classes])
  const rows: RowType[] = useMemo(() => {
    return list.data.map((item, index) => {
      return {
        id: index,
        time: (<DateTime time={item.created_at} />),
        account: item.account,
        name: item.forwarder_name,
        cash: formatMoney(item.cash),
        message: item.message,
        operator: item.operator
      } as RowType
    })
  }, [list.data])
  const data = useMemo(() => {
    return createTableData<RowType>(
      {
        id: {
          label: '',
          value: 'id'
        },
        time: {
          label: t('common.createdAt'),
          value: 'time',
          align: 'center'
        },
        name: {
          label: t('common.courierName'),
          value: 'name',
          align: 'center'
        },
        account: {
          label: t('common.account'),
          value: 'account',
          align: 'center'
        },
        cash: {
          label: t('common.cash'),
          value: 'cash',
          align: 'center'
        },
        message: {
          label: t('common.message'),
          value: 'message',
          align: 'center'
        },
        operator: {
          label: t('common.createSlipPerson'),
          value: 'operator',
          align: 'center'
        }
      },
      [
        'time',
        'account',
        'name',
        'cash',
        'message',
        'operator'
      ],
      rows,
      'id'
    )
  }, [rows, t])

  const handlePagination = usePaginationClickAndChangeUrl({
    request,
    encodePath: allRoute.depositError.encodePath
  })

  if (request === undefined) return null
  return (
    <ScrollablePaper marginX={5}>
      <Box padding={4}>
        <LoadingAndErrorFrame { ...pageFlow.status }>
          <CoreTable
            classes={tableClasses}
            data={data}
            total={list.total}
            showPagination
            page={request.page}
            onChangePage={handlePagination}
          />
        </LoadingAndErrorFrame>
      </Box>
    </ScrollablePaper>
  )
}

export default React.memo(DepositErrorTable)

import React, { useMemo, createContext, useContext, useCallback } from 'react'
import {
  PaginationReq,
  ActivityWalletQuery,
  ActivityWalletStatus
} from '@golden/gdk-admin'
import { omitBy, isUndefined } from '@golden/utils'
import { startOfDay, endOfDay, addMonths, isBefore, isAfter, subSeconds, getTime } from 'date-fns'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import MuiButton from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import MuiTextField, { TextFieldProps } from '@material-ui/core/TextField'
import DateInputBase, { DateInputValue } from '../../../default/form/DateInput'
import DropDown, { PropTypes as DropDownProps } from '../../../default/form/DropDown'
import FormField from '../../../default/form/FormField'
import FormSubmitButton from '../../../default/form/FormSubmitButton'
import { useCommonStyles } from '../../../../utils/admin/StyleHook'
import useT from '../../../../i18ns/admin/useT'
import {
  getValueFromChangeEvent,
  getTimeFromDateInputValue,
  convertEmptyToUndefined,
  pipe,
  parseInt,
  guaranteeBetween,
  guaranteeNotUndefined,
  acceptUndefined,
  guaranteeBeOneOf,
  convertUndefinedToDefault
} from '../../../../utils/default/FormHelper'
import { InitialFormFunc, useChangeUrlSubmit, useRequestFromSearch, SearchToRequestFunc } from '../../../../utils/default/ComplexFlowHook'
import FormStateProvider from '../../../default/form/FormStateProvider'
import { ValueGetter, FormValidation, createDefaultFormState, ChangedFormGetter } from '../../../../utils/default/FormHook'
import {
  setToday,
  setYesterday,
  setThisMonth,
  setLastMonth
} from '../../../../utils/default/TimeHelper'
import allRoute from '../../route/route'
import activityWalletStatusTypeName from '../../../../constants/admin/activityWalletStatusName'

export type ActivityWalletRequest = PaginationReq & ActivityWalletQuery

export interface ActivityWalletFormType {
  search_type: 'created_at' | 'updated_at'
  time: DateInputValue
  account: string
  title: string
  serial_number: string
  status: ActivityWalletStatus | '--'
  binding_deposit: string
  updated_by: string
}

export const initialForm: InitialFormFunc<ActivityWalletFormType> = (defaultForm) => ({
  search_type: 'created_at',
  time: {
    start: startOfDay(new Date()),
    end: endOfDay(new Date())
  },
  account: '',
  title: '',
  serial_number: '',
  status: '--',
  binding_deposit: '',
  updated_by: '',
  ...defaultForm
})

const getValueFromEvent: ValueGetter<ActivityWalletFormType> = {
  search_type: getValueFromChangeEvent,
  time: getTimeFromDateInputValue,
  account: getValueFromChangeEvent,
  title: getValueFromChangeEvent,
  serial_number: getValueFromChangeEvent,
  status: getValueFromChangeEvent,
  binding_deposit: getValueFromChangeEvent,
  updated_by: getValueFromChangeEvent
}

const getChangedForm: ChangedFormGetter<ActivityWalletFormType> = {
  time: (value, form) => {
    if (value.start && value.end && (isAfter(value.end, subSeconds(addMonths(value.start, 2), 1)) || isBefore(value.end, value.start))) {
      return { ...form, time: { start: value.start, end: subSeconds(addMonths(value.start, 2), 1) } }
    }
    return { ...form, time: value }
  }
}

const formToRequest = (form: ActivityWalletFormType): ActivityWalletRequest => {
  const converted = {
    search_type: form.search_type,
    start_at: form.time.start ? getTime(form.time.start) : undefined,
    end_at: form.time.end ? getTime(form.time.end) : undefined,
    account: convertEmptyToUndefined(form.account),
    title: convertEmptyToUndefined(form.title),
    serial_number: convertEmptyToUndefined(form.serial_number),
    status: form.status === '--' ? undefined : form.status,
    binding_deposit: convertEmptyToUndefined(form.binding_deposit),
    updated_by: convertEmptyToUndefined(form.updated_by),
    page: 1
  } as ActivityWalletRequest
  const omited = omitBy(converted, isUndefined)
  return omited as ActivityWalletRequest
}

export const searchToRequest: SearchToRequestFunc<ActivityWalletRequest> = (search) => {
  const converted = {
    ...search,
    page: pipe(
      guaranteeNotUndefined,
      parseInt,
      (value) => guaranteeBetween(value, 1, Number.MAX_SAFE_INTEGER)
    )(search.page),
    search_type: pipe(
      (value) => convertUndefinedToDefault(value, 'created_at'),
      (value) => guaranteeBeOneOf(value, ['created_at', 'updated_at'])
    )(search.search_type),
    start_at: acceptUndefined(search.start_at, pipe(
      parseInt
    )),
    end_at: acceptUndefined(search.end_at, pipe(
      parseInt
    )),
    status: acceptUndefined(search.status, pipe(
      (value) => guaranteeBeOneOf(value, Object.keys(activityWalletStatusTypeName))
    ))
  } as ActivityWalletRequest
  if (converted.end_at < converted.start_at) throw new Error('The end time can\'t exceed the start time')
  return omitBy(converted, isUndefined) as ActivityWalletRequest
}

const FormContext = createContext(createDefaultFormState(initialForm()))

const TextField = React.memo(MuiTextField)

const Button = React.memo(MuiButton)

const SearchDropDown: React.FC = React.memo(() => {
  const { t } = useT()
  const { value, handleChange, handleOther, error } = useContext(FormContext)
  const searchTimeOptions = useMemo(() => {
    return [
      { name: t('common.sendDate'), value: 'created_at' },
      { name: t('common.updatedDate'), value: 'updated_at' }
    ]
  }, [t])
  return (
    <Grid item xs={12} md={6} lg={3}>
      <DropDown
        value={value.search_type}
        onChange={handleChange('search_type')}
        onBlur={handleOther('blur', 'search_type')}
        options={searchTimeOptions}
        label={t('common.searchDate')}
        fullWidth
        required
        error={error.search_type !== null}
        helperText={error.search_type ?? ''}
      />
    </Grid>
  )
})

const DateInput: React.FC = React.memo(() => {
  const classes = useCommonStyles()
  const { t } = useT()
  const { value, handleChange } = useContext(FormContext)
  const {
    shouldDisableStartDate,
    shouldDisableEndDate
  } = useMemo(() => {
    const startTimeAddTwoMonths = value.time.start ? subSeconds(addMonths(value.time.start, 2), 1) : new Date()
    const shouldDisableStartDate = (day: Date | null): boolean => {
      if (day === null) return true
      if (isAfter(day, endOfDay(new Date()))) return true
      return false
    }
    const shouldDisableEndDate = (day: Date | null): boolean => {
      if (day === null) return true
      if (isAfter(day, endOfDay(startTimeAddTwoMonths))) return true
      if (value.time.start === null) return false
      return isBefore(day, startOfDay(value.time.start))
    }

    return {
      shouldDisableStartDate,
      shouldDisableEndDate
    }
  }, [value.time])
  const tools = useMemo(() => {
    return [
      {
        label: t('common.today'),
        change: setToday
      },
      {
        label: t('common.yesterday'),
        change: setYesterday
      },
      {
        label: t('common.thisMonth'),
        change: setThisMonth
      },
      {
        label: t('common.lastMonth'),
        change: setLastMonth
      }
    ]
  }, [t])
  const startOption = useMemo(() => ({
    label: t('common.beginAt'),
    shouldDisableDate: shouldDisableStartDate
  }), [shouldDisableStartDate, t])

  const endOption = useMemo(() => ({
    label: t('common.endAt'),
    shouldDisableDate: shouldDisableEndDate
  }), [shouldDisableEndDate, t])

  const dateClasses = useMemo(() => ({
    button: classes.pinkGradualButton
  }), [classes.pinkGradualButton])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onChange = useCallback(handleChange('time'), [])
  return (
    <DateInputBase
      value={value.time}
      onChange={onChange}
      start={startOption}
      end={endOption}
      tools={tools}
      classes={dateClasses}
      FrontComponent={SearchDropDown}
    />
  )
})

const ActivityWalletForm: React.FC = () => {
  const classes = useCommonStyles()
  const { t } = useT()

  const request = useRequestFromSearch({ searchToRequest })

  const defaultForm = useMemo(() => {
    if (request) {
      return initialForm({
        time: {
          start: request.start_at ? new Date(request.start_at) : null,
          end: request.end_at ? new Date(request.end_at) : null
        },
        ...request
      })
    }
    return initialForm()
  }, [request])

  const handleSubmit = useChangeUrlSubmit({
    toAddNowTimestamp: true,
    formToRequest,
    encodePath: allRoute.activityWallet.encodePath
  })

  const validation = useMemo(() => ({
    search_type: [],
    time: [],
    account: [],
    title: [],
    serial_number: [],
    status: [],
    binding_deposit: [],
    updated_by: []
  } as FormValidation<ActivityWalletFormType>), [])

  const options = [{ name: t('common.all'), value: '--' }].concat(
    Object.keys(activityWalletStatusTypeName)
      .map((key) => key as ActivityWalletStatus)
      .map((key) => ({
        name: t(activityWalletStatusTypeName[key]),
        value: key
      })) as any
  )

  return (
    <FormStateProvider
      context={FormContext}
      defaultValue={defaultForm}
      onSubmit={handleSubmit}
      validation={validation}
      getValueFromEvent={getValueFromEvent}
      getChangedForm={getChangedForm}
    >
      <Paper>
        <Box padding={4}>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <Box
                paddingY={1.25}
                paddingX={2}
                className={classes.pinkTitleBar}
              >
                <Typography variant="h5">
                  {t('page.activityWallet')}
                </Typography>
              </Box>
            </Grid>
            <Grid item>
              <DateInput />
            </Grid>
            <Grid item>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={12} md={3}>
                  <FormField<ActivityWalletFormType, TextFieldProps>
                    context={FormContext}
                    component={TextField}
                    name="account"
                    label={t('common.playerAccount')}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <FormField<ActivityWalletFormType, TextFieldProps>
                    context={FormContext}
                    component={TextField}
                    name="title"
                    label={t('common.activityName')}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <FormField<ActivityWalletFormType, TextFieldProps>
                    context={FormContext}
                    component={TextField}
                    name="serial_number"
                    label={t('common.orderNumber')}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <FormField<ActivityWalletFormType, DropDownProps>
                    context={FormContext}
                    component={DropDown}
                    name="status"
                    label={t('common.status')}
                    fullWidth
                    options={options}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={12} md={3}>
                  <FormField<ActivityWalletFormType, TextFieldProps>
                    context={FormContext}
                    component={TextField}
                    name="binding_deposit"
                    label={t('common.exchangedDepositOrderNo')}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <FormField<ActivityWalletFormType, TextFieldProps>
                    context={FormContext}
                    component={TextField}
                    name="updated_by"
                    label={t('common.updateBy')}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container justifyContent="flex-end">
                <Grid item>
                  <FormSubmitButton
                    component={Button}
                    context={FormContext}
                    type="submit"
                    className={classes.purpleGradualButton}
                  >
                    {t('common.search')}
                  </FormSubmitButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </FormStateProvider>
  )
}

export default React.memo(ActivityWalletForm)

import { PlayerStates } from '@golden/gdk-admin'
import TKeyType from '../../i18ns/admin/TKeyType'

const stateTypeName: { [key in PlayerStates]: TKeyType } = {
  [PlayerStates.IS_PASSWORD_BLOCKED]: 'stateType.isPasswordBlocked',
  [PlayerStates.VERIFY_FAIL_REACH_LIMIT]: 'stateType.verifyFailReachLimit',
  [PlayerStates.IS_FIRST_WITHDRAW_LIMIT]: 'stateType.firstWithdrawLimit',
  [PlayerStates.IS_SUSPENDED]: 'stateType.isSuspended',
  [PlayerStates.NOT_TRANSFERABLE]: 'stateType.notTransferable',
  [PlayerStates.NOT_ACTIVITY]: 'stateType.notActivity',
  [PlayerStates.NOT_BETABLE]: 'stateType.notBetable',
  [PlayerStates.NOT_DEPOSITABLE]: 'stateType.notDepositable',
  [PlayerStates.NOT_WITHDRAWABLE]: 'stateType.notWithdrawable'
}

export default stateTypeName

import React from 'react'
import { Link } from 'react-router-dom'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import useT from '../../../i18ns/admin/useT'
import allRoute from '../../../components/admin/route/route'
import { searchToRequest } from './ExternalGameChannelForm'
import { useRequestFromSearch } from '../../../utils/default/ComplexFlowHook'

const ExternalGameChannelCreateButton: React.FC = () => {
  const classes = useCommonStyles()
  const { t } = useT()
  const request = useRequestFromSearch({ searchToRequest })
  return (
    <Box display="flex" justifyContent="flex-end" paddingBottom={2}>
      <Button
        classes={{ disabled: classes.disabledButton }}
        className={classes.purpleGradualButton}
        component={Link}
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        to={allRoute.externalGameChannelCreate.encodePath({ search: { gameId: request!.gameId }, param: {} })}
      >
        {t('common.createGame')}
      </Button>
    </Box>
  )
}

export default React.memo(ExternalGameChannelCreateButton)

import React, { PropsWithChildren } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import MuiAppBar from '@material-ui/core/AppBar'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import AppBarMenuButton, { PropTypes as AppBarMenuButtonProps } from './AppBarMemuButton'
import CustomThemeType from '../../../themes/default/CustomThemeType'

const useStyles = makeStyles((theme: CustomThemeType) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    boxShadow: theme.shadows[1],
    backgroundColor: theme.custom.palette.background.appBar
  },
  root: {
    ...theme.mixins.toolbar
  },
  logo: {
    fontSize: theme.custom.shape.logoSize
  }
}))

const AppBar: React.FC<PropsWithChildren<AppBarMenuButtonProps & { text: string }>> = (props) => {
  const { onMenuClick, text, children } = props
  const classes = useStyles()

  return (
    <MuiAppBar
      position="fixed"
      className={classes.appBar}
    >
      <Box
        className={classes.root}
        display="flex"
        flexDirection="row"
        alignItems="center"
        width="100%"
      >
        <Box paddingLeft={3.5}>
          <AppBarMenuButton onMenuClick={onMenuClick} />
        </Box>
        <Box paddingLeft={1}>
          <Typography className={classes.logo}>
            {text}
          </Typography>
        </Box>
        <Box display="flex" flexGrow="1">
          {children}
        </Box>
      </Box>
    </MuiAppBar>
  )
}

export default React.memo(AppBar)

import React, { useState, useMemo } from 'react'
import { PaginationRes, Alert, AlertInfo, TransferSearchTimeType, RecordSearchTimeType, Branch, WalletType } from '@golden/gdk-admin'
import { Link } from 'react-router-dom'
import Box from '@material-ui/core/Box'
import { searchToRequest } from './JournalEventForm'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import useT from '../../../i18ns/admin/useT'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { createDefaultPaginationData, createTableData, formatMoney } from '../../../utils/default/TableHelper'
import { usePageFlow } from '../../../utils/default/PageFlowHook'
import { useRequestFromSearch, useGetDataByPayload, usePaginationClickAndChangeUrl, useReload } from '../../../utils/default/ComplexFlowHook'
import DateTime from '../../default/present/DateTime'
import allRoute from '../route/route'
import LoadingAndErrorFrame from '../../default/frames/LoadingAndErrorFrame'
import ScrollablePaper from '../../default/present/ScrollablePaper'
import CoreTable from '../../default/present/CoreTable'
import { useChecker } from '../../../utils/admin/AdminRouteHook'
import { getInfo } from '../../../utils/admin/AlertHelper'
import depositTypeName from '../../../constants/default/depositTypeName'
import alertInfoName from '../../../constants/admin/alertInfoName'
import { isBefore } from 'date-fns'
import useGDKStore from '../../../providers/admin/gdk/useGDKStore'

interface RowType {
  id: number
  createdAt: React.ReactElement
  type: string
  subject: React.ReactElement
  info: string
}

const InfoLink: React.FC<{
  info: AlertInfo
  subject: Alert['subject']
}> = (props) => {
  const { info, subject } = props
  const { t } = useT()
  const classes = useCommonStyles()
  const request = useRequestFromSearch({ searchToRequest })

  if (info === AlertInfo.DEPOSIT_WAY_FULL_CASH_REACHED) {
    const depositName = subject.deposit_method ? t(depositTypeName[subject.deposit_method]) : ''
    return (
      <Link
        className={classes.purpleAnchor}
        to={allRoute.financeDepositWay.encodePath({
          search: { is_active: 1 },
          param: {}
        })}
      >
        {subject.forwarder_name ?? ''} / {depositName} / {formatMoney(subject.cash_min ?? '0.0000')}~{formatMoney(subject.cash_max ?? '0.0000')}
      </Link>
    )
  }

  if ([AlertInfo.TRANSFER_STATUS_NOT_MATCH, AlertInfo.NO_TRANSFER_LOG_IN_THIRD_PARTY].includes(info)) {
    if (subject.wallet_id) {
      return (
        <Link
          className={classes.purpleAnchor}
          to={allRoute.playerReportTransfer.encodePath({
            search: {
              search_type: TransferSearchTimeType.CREATED_AT,
              golden_wallet: WalletType.CENTER,
              game_wallet: subject.wallet_id,
              transfer_number: subject.transfer_number,
              page: 1
            },
            param: {}
          })}
        >
          {subject.transfer_number ?? ''}
        </Link>
      )
    } else if (subject.game_wallet) {
      return (
        <Link
          className={classes.purpleAnchor}
          to={allRoute.playerReportTransfer.encodePath({
            search: {
              search_type: TransferSearchTimeType.CREATED_AT,
              golden_wallet: subject.golden_wallet ?? WalletType.CENTER,
              game_wallet: subject.game_wallet,
              transfer_number: subject.transfer_number,
              page: 1
            },
            param: {}
          })}
        >
          {subject.transfer_number ?? ''}
        </Link>
      )
    } else {
      return (
        <Link
          className={classes.purpleAnchor}
          to={allRoute.playerReportTransfer.encodePath({
            search: {
              search_type: TransferSearchTimeType.CREATED_AT,
              golden_wallet: WalletType.CENTER,
              transfer_number: subject.transfer_number,
              page: 1
            },
            param: {}
          })}
        >
          {subject.transfer_number ?? ''}
        </Link>
      )
    }
  }

  if (info === AlertInfo.BET_REVENUE_3W) {
    return (
      <Link
        className={classes.purpleAnchor}
        to={allRoute.playerReportBet.encodePath({
          search: {
            search_type: RecordSearchTimeType.CREATED_AT,
            game_category: subject.game_category ?? 1,
            serial_number: subject.serial_number,
            start_at: request?.start_at,
            end_at: request?.end_at,
            page: 1
          },
          param: {}
        })}
      >
        {subject.serial_number ?? ''}
      </Link>
    )
  }

  if (info === AlertInfo.WITHDRAW_REVENUE_3W) {
    return (
      <Link
        className={classes.purpleAnchor}
        to={allRoute.withdrawalRCSlip.encodePath({
          search: {
            order_number: subject.order_number,
            created_at_null: 0,
            created_at_start: request?.start_at,
            created_at_end: request?.end_at,
            page: 1
          },
          param: {}
        })}
      >
        {subject.order_number ?? ''}
      </Link>
    )
  }

  if ([AlertInfo.REAL_NAME_DUPLICATE, AlertInfo.WALLET_DUPLICATE, AlertInfo.TRC_BIND].includes(info)) {
    let hash = ''
    if (info === AlertInfo.REAL_NAME_DUPLICATE) hash = '#card'
    if (info === AlertInfo.TRC_BIND) hash = '#cryptoWallet'
    return (
      <Link
        className={classes.purpleAnchor}
        to={{
          pathname: allRoute.playerDetail.encodePath({ search: {}, param: { id: subject.user_id } }),
          hash
        }}
      >
        {subject.account ?? ''}
      </Link>
    )
  }

  if (info === AlertInfo.RC_WARNED_USER_GAME_LOGIN) {
    return (
      <Link
        className={classes.purpleAnchor}
        to={allRoute.playerGameLoginHistory.encodePath({
          search: {
            account: subject.account,
            start_at: request?.start_at,
            end_at: request?.end_at,
            page: 1
          },
          param: {}
        })}
      >
        {subject.account ?? ''}
      </Link>
    )
  }

  return null
}

const JournalEventTable: React.FC = () => {
  const classes = useCommonStyles()
  const { t } = useT()
  const gdk = useGDK()
  const games = useGDKStore.platform.games()
  const { reloadFlag } = useReload()
  const [list, setList] = useState<PaginationRes<Alert[]> & { last_read_id: number }>({
    ...createDefaultPaginationData([]),
    last_read_id: 0
  })
  const pageFlow = usePageFlow(true, false)
  const request = useRequestFromSearch({ searchToRequest })
  const writable = useChecker()
  useGetDataByPayload({
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    payload: request!,
    gdkFunc: (payload) => gdk.alert.getAlertList(payload),
    gdkFuncDependencies: [gdk, reloadFlag],
    onBeforeFetch: pageFlow.setLoadingStart,
    onSuccess: (res: PaginationRes<Alert[]> & { last_read_id: number }) => {
      setList(res)
      gdk.trunk.next([Branch.ME])
      pageFlow.setContentShow()
    },
    onError: pageFlow.setGDKError,
    canLoadData: request !== undefined
  })
  const tableClasses = useMemo(() => ({
    head: classes.pinkTableHead,
    row: classes.tableRow,
    cellHead: classes.tableCellHead,
    cellBody: classes.pre
  }), [classes])
  const rows: RowType[] = useMemo(() => {
    return list.data.map((item) => {
      return {
        id: item.id,
        createdAt: (
          <DateTime time={item.created_at} />
        ),
        type: t(alertInfoName[item.info]),
        subject: (<InfoLink subject={item.subject} info={item.info} />),
        info: getInfo(item.info, item.subject, t, games) || '-'
      } as RowType
    })
  }, [list.data, games, t])
  const data = useMemo(() => {
    return createTableData<RowType>(
      {
        id: {
          label: '',
          value: 'id'
        },
        createdAt: {
          label: t('common.notifiedAt'),
          value: 'createdAt',
          align: 'center',
          width: 120
        },
        type: {
          label: t('common.warningType'),
          value: 'type',
          align: 'center',
          width: 180
        },
        subject: {
          label: t('common.subject'),
          value: 'subject',
          align: 'center',
          width: 250
        },
        info: {
          label: t('common.caption'),
          value: 'info',
          align: 'center',
          width: 300
        }
      },
      writable
        ? [
            'createdAt',
            'type',
            'subject',
            'info'
          ]
        : [
            'createdAt',
            'type',
            'subject',
            'info'
          ],
      rows,
      'id'
    )
  }, [rows, t, writable])

  const handlePagination = usePaginationClickAndChangeUrl({
    request,
    encodePath: allRoute.journalEvent.encodePath
  })

  const selected = useMemo(() => {
    return list.data.filter((item) => {
      return isBefore(list.last_read_id, item.id)
    })
      .map((item) => (item.id))
  }, [list])

  if (request === undefined) return null
  return (
    <ScrollablePaper marginX={5}>
      <Box padding={4}>
        <LoadingAndErrorFrame { ...pageFlow.status }>
          <CoreTable
            classes={tableClasses}
            data={data}
            total={list.total}
            showPagination
            page={request.page}
            onChangePage={handlePagination}
            isSelected
            selected={selected}
            loading={pageFlow.status.loading}
          />
        </LoadingAndErrorFrame>
      </Box>
    </ScrollablePaper>
  )
}

export default React.memo(JournalEventTable)

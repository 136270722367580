import React, { createContext, useCallback, useMemo, useState, useContext } from 'react'
import { ActivityLayoutReq, ActivityType, GameType } from '@golden/gdk-admin'
import Grid from '@material-ui/core/Grid'
import MuiButton from '@material-ui/core/Button'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import MuiTextField, { TextFieldProps } from '@material-ui/core/TextField'
import { makeStyles } from '@material-ui/core/styles'
import OnOffCheckbox, { PropTypes as OnOffCheckboxProps } from '../../default/form/OnOffCheckbox'
import DateTimePicker, { PropTypes as AnnouncementTimePickerProps } from '../../default/form/DateTimePicker'
import MultipleSelector, { PropTypes as MultipleSelectorProps } from '../../default/form/MultipleSelector'
import FormStateProvider from '../../default/form/FormStateProvider'
import FormField from '../../default/form/FormField'
import FormSubmitButton from '../../default/form/FormSubmitButton'
import RequiredText from '../../default/form/RequiredText'
import { ValueGetter, FormValidation, createDefaultFormState } from '../../../utils/default/FormHook'
import {
  FormPropType,
  getValueFromChangeEvent,
  getValueFromCheckboxEvent,
  getValueFromValue
} from '../../../utils/default/FormHelper'
import { InitialFormFunc, useGetData } from '../../../utils/default/ComplexFlowHook'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import { createValidateNotEmpty, createValidateRange, createValidateDate } from '../../../utils/default/Validator'
import useT from '../../../i18ns/admin/useT'
import TextEditor, { PropTypes as TextEditorProps } from '../../default/form/textEditor/TextEditor'
import { defaultEditorState, EditorState, TextContentType } from '@golden/tiptap-react'
import CustomThemeType from '../../../themes/admin/CustomThemeType'
import FileImageField, { PropTypes as FileImageFieldProps } from '../../default/form/FileImageField'
import { isAfter, getUnixTime, isValid } from 'date-fns/esm'
import useGDK from '../../../providers/admin/gdk/useGDK'
import ActivityManagementPreviewDialog from './ActivityManagementPreviewDialog'
import useGDKStore from '../../../providers/admin/gdk/useGDKStore'
import { getGameName } from '../../../utils/default/PlatformHelper'

const useStyles = makeStyles((theme: CustomThemeType) => ({
  tabs: {
    maxWidth: '69vw'
  },
  tab: {
    fontSize: theme.typography.h6.fontSize,
    minWidth: 0,
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5)
  },
  tabSelected: {
    backgroundColor: '#f3f3f3',
    color: theme.custom.palette.secondary
  },
  tabContent: {
    backgroundColor: '#f3f3f3',
    padding: 24
  },
  fileField: {
    backgroundColor: '#fff',
    padding: '6px 12px 12px',
    marginBottom: 12
  },
  indicator: {
    height: 0
  }
}))

export interface ActivityManagementEditSystemLayoutFormType {
  activity_id?: number
  type: ActivityType | ''
  title: string
  startAt: Date | null
  endAt: Date | null
  isNoEndAt: boolean
  showStartAt: Date | null
  showEndAt: Date | null
  isNoShowEndAt: boolean
  content: EditorState
  desktopHomeImage: { id?: number, url: string } | null
  mobileHomeImage: { id?: number, url: string } | null
  couponImage: { id?: number, url: string } | null
  agentLongImage: { id?: number, url: string } | null
  isCarousel: boolean
  isGuest: boolean
  isMy: boolean
  tagIds: number[]
  tournaments: Record<GameType, string> | null
}

export const initialForm: InitialFormFunc<ActivityManagementEditSystemLayoutFormType> = (form) => ({
  title: '',
  type: '',
  startAt: null,
  endAt: null,
  isNoEndAt: false,
  showStartAt: null,
  showEndAt: null,
  isNoShowEndAt: false,
  content: defaultEditorState,
  desktopHomeImage: null,
  mobileHomeImage: null,
  couponImage: null,
  agentLongImage: null,
  isCarousel: false,
  isGuest: false,
  isMy: false,
  tagIds: [],
  tournaments: null,
  ...form
})

export const formToRequest = (data: ActivityManagementEditSystemLayoutFormType): Partial<ActivityLayoutReq<number>> & { activity_id?: number, type?: string } => ({
  activity_id: data.activity_id,
  type: data.type,
  title: data.title,
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  start_at: getUnixTime(data.startAt!),
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  end_at: data.isNoEndAt ? null : getUnixTime(data.endAt!),
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  show_start_at: getUnixTime(data.showStartAt!),
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  show_end_at: data.isNoShowEndAt ? null : getUnixTime(data.showEndAt!),
  content: JSON.stringify(data.content.content),
  desktop_home_image_id: data.desktopHomeImage?.id,
  mobile_home_image_id: data.mobileHomeImage?.id,
  coupon_image_id: data.couponImage?.id,
  agent_long_image_id: data.agentLongImage ? data.agentLongImage?.id : null,
  is_carousel: data.isCarousel,
  is_guest: data.isGuest,
  is_my: data.isMy,
  tag_ids: data.tagIds,
  tournaments: data.tournaments
})

interface PropTypes extends FormPropType<ActivityManagementEditSystemLayoutFormType> {
  okText: string
  onBack: () => void
  isLook?: boolean
}

const getValueFromEvent: ValueGetter<ActivityManagementEditSystemLayoutFormType> = {
  title: getValueFromChangeEvent,
  type: getValueFromValue,
  startAt: getValueFromValue,
  endAt: getValueFromValue,
  isNoEndAt: getValueFromCheckboxEvent,
  showStartAt: getValueFromValue,
  showEndAt: getValueFromValue,
  isNoShowEndAt: getValueFromCheckboxEvent,
  content: getValueFromValue,
  desktopHomeImage: getValueFromValue,
  mobileHomeImage: getValueFromValue,
  couponImage: getValueFromValue,
  agentLongImage: getValueFromValue,
  isCarousel: getValueFromCheckboxEvent,
  isGuest: getValueFromCheckboxEvent,
  isMy: getValueFromCheckboxEvent,
  tagIds: getValueFromValue,
  tournaments: getValueFromValue
}

export const ActivityManagementFormContext = createContext(createDefaultFormState(initialForm()))

const TextField = React.memo(MuiTextField)

const Button = React.memo(MuiButton)

const DateInput: React.FC<{ isShowDate: boolean, disabled: boolean }> = React.memo((props) => {
  const { isShowDate, disabled } = props
  const { t } = useT()
  const { value, dispatch } = useContext(ActivityManagementFormContext)
  return (
    <Grid item container direction="row" spacing={2}>
      <Grid item xs={12} md={6}>
        <FormField<ActivityManagementEditSystemLayoutFormType, AnnouncementTimePickerProps>
          component={DateTimePicker}
          context={ActivityManagementFormContext}
          name={isShowDate ? 'showStartAt' : 'startAt'}
          label={isShowDate ? t('common.startDisplayTime') : t('common.activityStartTime')}
          placeholder={isShowDate ? t('placeholder.activityStartAtTip') : t('placeholder.activityShowStartAtTip')}
          required
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <FormField<ActivityManagementEditSystemLayoutFormType, AnnouncementTimePickerProps>
          component={DateTimePicker}
          context={ActivityManagementFormContext}
          name={isShowDate ? 'showEndAt' : 'endAt'}
          label={isShowDate ? t('common.endDisplayTime') : t('common.activityEndTime')}
          placeholder={isShowDate ? t('placeholder.activityEndAtTip') : t('placeholder.activityShowEndAtTip')}
          required={isShowDate ? !value.isNoShowEndAt : !value.isNoEndAt}
          disabled={disabled || (isShowDate ? value.isNoShowEndAt : value.isNoEndAt)}
        />
        <OnOffCheckbox
          label={isShowDate ? t('common.longtermDisplay') : t('common.longtermActivity')}
          value={isShowDate ? value.isNoShowEndAt : value.isNoEndAt}
          onChange={useCallback((event) => {
            const checked = event.target.checked
            if (isShowDate) {
              dispatch({ type: 'change', label: 'isNoShowEndAt', value: checked })
              dispatch({ type: 'change', label: 'showEndAt', value: checked ? null : value.showEndAt })
            } else {
              dispatch({ type: 'change', label: 'isNoEndAt', value: checked })
              dispatch({ type: 'change', label: 'endAt', value: checked ? null : value.endAt })
            }
          }, [value, isShowDate, dispatch])}
          disabled={disabled}
        />
      </Grid>
    </Grid>
  )
})

const PreviewButton: React.FC = () => {
  const [openPreview, setOpenPreview] = useState(false)
  const commonClasses = useCommonStyles()
  const { t } = useT()
  const { value: form } = useContext(ActivityManagementFormContext)
  if (form.type === '') return null
  return (<>
    <Button
      className={commonClasses.purpleGradualButton}
      onClick={() => setOpenPreview(true)}
    >
      {t('common.preview')}
    </Button>
    { openPreview && (
      <ActivityManagementPreviewDialog
        type={form.type}
        onConfirm={() => setOpenPreview(false)}
      />
    )}
  </>)
}

const UefaTournaments: React.FC<{ isLook: boolean }> = (props) => {
  const { value: form } = useContext(ActivityManagementFormContext)
  const commonClasses = useCommonStyles()
  const { t } = useT()
  const { isLook } = props
  if (!form.tournaments) return null
  return (
    <Grid item container direction="column" spacing={2}>
      <Grid item>
        <Typography variant="h6" className={commonClasses.bold}>
          {t('common.specifyLeague')}
        </Typography>
      </Grid>
      {
        Object.entries(form.tournaments).map(([key]) => {
          return <Grid item>
            <UefaTournamentsInput key={key} inputKey={key} isLook={isLook} />
          </Grid>
        })
      }
    </Grid>
  )
}

const UefaTournamentsInput: React.FC<{ inputKey: string, isLook: boolean }> = (props) => {
  const { value: form, dispatch } = useContext(ActivityManagementFormContext)
  const { t } = useT()
  const { inputKey, isLook } = props
  const key = useMemo(() => Number(inputKey) as GameType, [inputKey])
  const games = useGDKStore.platform.games()

  if (form.tournaments && key in form.tournaments) {
    return (
      <TextField
        value={form.tournaments[key]}
        label={getGameName(key, games)}
        placeholder={t('placeholder.inputUefaTournaments')}
        onChange={(event) => dispatch({ type: 'change', label: 'tournaments', value: form.tournaments ? { ...form.tournaments, [key]: event.target.value } : null })}
        disabled={isLook}
        fullWidth
      />
    )
  }
  return null
}

const ActivityManagementEditSystemLayoutForm: React.FC<PropTypes> = (props) => {
  const { defaultValue, onSubmit, okText, onBack, isLook = false } = props
  const commonClasses = useCommonStyles()
  const classes = useStyles(props)
  const { t } = useT()
  const gdk = useGDK()

  const [tags, setTags] = useState<Array<{ value: number, name: string }>>([])

  useGetData({
    gdkFunc: () => gdk.activity.getActivityTagsPriority(),
    gdkFuncDependencies: [gdk],
    onSuccess: (res) => {
      setTags(res.map(item => ({ value: item.id, name: item.title })))
    }
  })

  const fileSizeBytesLimitation = 2 * (2 ** 20) // 2MB in bytes
  const fileSizeLimitedText = '2 MB'
  const imageFieldGrids = [
    {
      name: 'desktopHomeImage',
      text: `${t('client.desktop')}${t('common.image')}`,
      editable: false
    },
    {
      name: 'mobileHomeImage',
      text: `${t('client.mobile')}${t('common.image')}`,
      editable: false
    },
    {
      name: 'couponImage',
      text: t('common.couponImage'),
      editable: false
    },
    {
      name: 'agentLongImage',
      text: t('common.agentLongImage'),
      editable: !isLook
    }
  ]

  const textFieldInputProps = useMemo(() => {
    return {
      maxLength: 50,
      placeholder: t('placeholder.activityTitleTip')
    }
  }, [t])

  const validation: FormValidation<ActivityManagementEditSystemLayoutFormType> = useMemo(() => {
    return {
      title: [
        {
          func: createValidateNotEmpty<ActivityManagementEditSystemLayoutFormType>('title', t),
          when: ['change', 'beforeClickSubmit']
        },
        {
          func: createValidateRange<ActivityManagementEditSystemLayoutFormType>(
            'title', (value) => (value as string).length, 'lessEqual', 50, t('error.mustLessEqualFiftyWords')
          ),
          when: ['change', 'beforeClickSubmit']
        }
      ],
      type: [],
      startAt: [
        {
          func: createValidateNotEmpty<ActivityManagementEditSystemLayoutFormType>('startAt', t),
          when: ['change', 'beforeClickSubmit']
        },
        {
          func: createValidateDate<ActivityManagementEditSystemLayoutFormType>('startAt', t),
          when: ['change', 'beforeClickSubmit']
        },
        {
          func: (value, form) => {
            if (!form.endAt) {
              return { isPass: true, stop: false, newError: { startAt: null } }
            }
            if (!!form.endAt && isAfter(value as Date, form.endAt)) {
              return { isPass: false, stop: true, newError: { startAt: t('error.startMustBeforeEnd') } }
            }
            return { isPass: true, stop: false, newError: { startAt: null, endAt: null } }
          },
          when: ['change', 'beforeClickSubmit']
        }
      ],
      endAt: [
        {
          func: (value, form) => {
            if (!value && !form.isNoEndAt) {
              return { isPass: false, stop: true, newError: { endAt: t('error.mustNotEmpty') } }
            }
            if (!form.isNoEndAt && !isValid(value)) {
              return { isPass: false, stop: true, newError: { endAt: t('error.dateError') } }
            }
            if (!!form.startAt && !!value && isAfter(form.startAt, value as Date)) {
              return { isPass: false, stop: true, newError: { endAt: t('error.endMustAfterStart') } }
            }
            return { isPass: true, stop: false, newError: { startAt: null, endAt: null } }
          },
          when: ['change', 'beforeClickSubmit']
        }
      ],
      isNoEndAt: [],
      showStartAt: [
        {
          func: createValidateNotEmpty<ActivityManagementEditSystemLayoutFormType>('showStartAt', t),
          when: ['change', 'beforeClickSubmit']
        },
        {
          func: createValidateDate<ActivityManagementEditSystemLayoutFormType>('showStartAt', t),
          when: ['change', 'beforeClickSubmit']
        },
        {
          func: (value, form) => {
            if (!form.showEndAt) {
              return { isPass: true, stop: false, newError: { showStartAt: null } }
            }
            if (!!form.showEndAt && isAfter(value as Date, form.showEndAt)) {
              return { isPass: false, stop: true, newError: { showStartAt: t('error.startMustBeforeEnd') } }
            }
            return { isPass: true, stop: false, newError: { showStartAt: null, showEndAt: null } }
          },
          when: ['change', 'beforeClickSubmit']
        }
      ],
      showEndAt: [
        {
          func: (value, form) => {
            if (!form.showStartAt) {
              return { isPass: true, stop: false, newError: { showEndAt: null } }
            }
            if (!value && !form.isNoShowEndAt) {
              return { isPass: false, stop: true, newError: { showEndAt: t('error.mustNotEmpty') } }
            }
            if (!!value && !isValid(value)) {
              return { isPass: false, stop: true, newError: { showEndAt: t('error.dateError') } }
            }
            if (!!value && isAfter(form.showStartAt, value as Date)) {
              return { isPass: false, stop: true, newError: { showEndAt: t('error.endMustAfterStart') } }
            }
            return { isPass: true, stop: false, newError: { showStartAt: null, showEndAt: null } }
          },
          when: ['change', 'beforeClickSubmit']
        }
      ],
      isNoShowEndAt: [],
      content: [],
      desktopHomeImage: [],
      mobileHomeImage: [],
      couponImage: [],
      agentLongImage: [],
      isCarousel: [],
      isGuest: [],
      isMy: [],
      tagIds: [],
      tournaments: []
    }
  }, [t])

  return (
    <FormStateProvider<ActivityManagementEditSystemLayoutFormType>
      context={ActivityManagementFormContext}
      defaultValue={defaultValue}
      onSubmit={onSubmit}
      getValueFromEvent={getValueFromEvent}
      validation={validation}
    >
      <Grid container direction="column" spacing={3} wrap="nowrap">
        <Grid item container direction="row">
          <Grid item xs={12}>
            <FormField<ActivityManagementEditSystemLayoutFormType, TextFieldProps>
              component={TextField}
              context={ActivityManagementFormContext}
              name="title"
              label={t('common.title')}
              inputProps={textFieldInputProps}
              autoFocus
              fullWidth
              required
              disabled={isLook}
            />
          </Grid>
        </Grid>
        <Grid item container direction="row">
          <Grid item xs={12}>
            <FormField<ActivityManagementEditSystemLayoutFormType, MultipleSelectorProps>
              component={MultipleSelector}
              context={ActivityManagementFormContext}
              name="tagIds"
              label={t('common.tag')}
              options={tags}
              fullWidth
              disabled={isLook}
            />
          </Grid>
        </Grid>
        <DateInput isShowDate={true} disabled={isLook} />
        <DateInput isShowDate={false} disabled={isLook} />
        <Grid item>
          <Box className={commonClasses.pinkTitleBar}>
            <Box display="flex" flexDirection="row" alignItems="center">
              <Tabs
                classes={{ root: classes.tabs, indicator: classes.indicator }}
                value={'tab'}
              >
                <Tab
                  value={'tab'}
                  classes={{ root: classes.tab, selected: classes.tabSelected }}
                  label={t('common.content')}
                />
              </Tabs>
              <Box display="flex" flexGrow={1} />
            </Box>
          </Box>
          <Box className={classes.tabContent}>
            <Grid container direction='row' spacing={2} className={classes.fileField}>
              {imageFieldGrids.map(({ name, text, editable }) => (
                <Grid item xs={12} md={6}>
                  <span>{text}</span>
                  <FormField<ActivityManagementEditSystemLayoutFormType, FileImageFieldProps>
                    context={ActivityManagementFormContext}
                    component={FileImageField}
                    disabled={!editable}
                    name={name as keyof(ActivityManagementEditSystemLayoutFormType)}
                    label={t('common.image')}
                    helperText={t('helperText.bannerTip')}
                    buttonText={t('common.uploadImage')}
                    accept=".png, .jpg"
                    fullWidth
                    fileSizeBytesLimitation={fileSizeBytesLimitation}
                    fileSizeLimitedText={fileSizeLimitedText}
                  />
                </Grid>
              ))}
            </Grid>
            <FormField<ActivityManagementEditSystemLayoutFormType, TextEditorProps>
              component={TextEditor}
              context={ActivityManagementFormContext}
              name="content"
              controls={['textContentType', 'block', 'table', 'bold', 'ol', 'ul', 'textColor', 'clearFormat']}
              textContentType={[TextContentType.HEADING2, TextContentType.PARAGRAPH]}
              placeholder={`${t('placeholder.inputContent')}*`}
              colors={['#BA9F72']}
              disabled
              showDisabledTip
            />
          </Box>
        </Grid>
        {
          <UefaTournaments isLook={isLook}/>
        }
        <Grid item container direction="row">
          <FormField<ActivityManagementEditSystemLayoutFormType, OnOffCheckboxProps>
            component={OnOffCheckbox}
            context={ActivityManagementFormContext}
            name="isCarousel"
            label={t('common.homeCarousel')}
            disabled={isLook}
          />
          <FormField<ActivityManagementEditSystemLayoutFormType, OnOffCheckboxProps>
            component={OnOffCheckbox}
            context={ActivityManagementFormContext}
            name="isGuest"
            label={t('common.showBeforeLogin')}
            disabled={isLook}
          />
          <FormField<ActivityManagementEditSystemLayoutFormType, OnOffCheckboxProps>
            component={OnOffCheckbox}
            context={ActivityManagementFormContext}
            name="isMy"
            label={t('common.my')}
            disabled={isLook}
          />
        </Grid>
        <Grid item>
          <RequiredText />
        </Grid>
        <Grid item container direction="row" justifyContent="flex-end" spacing={2}>
          <Grid item>
            <Button
              className={commonClasses.greyButton}
              onClick={onBack}
            >
              {!isLook ? t('common.cancel') : t('common.back')}
            </Button>
          </Grid>
          <Grid item>
            <PreviewButton />
          </Grid>
          <Grid item>
            {!isLook && (<FormSubmitButton
              component={Button}
              context={ActivityManagementFormContext}
              className={commonClasses.purpleGradualButton}
              type="submit"
            >
              {okText}
            </FormSubmitButton>)}
          </Grid>
        </Grid>
      </Grid>
    </FormStateProvider>
  )
}

export default React.memo(ActivityManagementEditSystemLayoutForm)

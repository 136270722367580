import React, { useState, useMemo, useCallback, useEffect } from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import useGDK from '../../../providers/admin/gdk/useGDK'
import useT from '../../../i18ns/admin/useT'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import LoadingAndErrorFrame from '../../default/frames/LoadingAndErrorFrame'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import { useGDKFuncHandleSubmit } from '../../../utils/default/ComplexFlowHook'
import { usePageFlow } from '../../../utils/default/PageFlowHook'
import { useDialogStyles, useCommonStyles } from '../../../utils/admin/StyleHook'
import CustomThemeType from '../../../themes/admin/CustomThemeType'
import useGlobalDialog from '../../../providers/admin/dialog/useGlobalDialog'
import { createGlobalDialogConfig } from '../../../utils/default/DialogHelper'
import PointsCell from '../../default/present/PointsCell'
import { TextField } from '@material-ui/core'
import { useChecker } from '../../../utils/admin/AdminRouteHook'

interface FormType {
  binding_deposits: string
}

const useStyles = makeStyles((theme: CustomThemeType) => ({
  actions: {
    paddingBottom: theme.spacing(4)
  },
  title: {
    textAlign: 'center',
    paddingTop: theme.spacing(4),
    color: theme.custom.palette.text.dialog,
    fontSize: theme.custom.typography.dialog
  }
}))

const ManualDepositUpdateBindingDepositsDialog: React.FC<{ open: boolean, close: () => void, reload: () => void, id: number, bindingDeposits: string[], memo: string, account: string, cash: string }> = ({ id, open, close, reload, bindingDeposits, memo, account, cash }) => {
  const gdk = useGDK()
  const { t } = useT()
  const pageFlow = usePageFlow()
  const commonClasses = useCommonStyles()
  const dialogClasses = useDialogStyles()
  const classes = useStyles()
  const globalDialog = useGlobalDialog()
  const writable = useChecker()

  const [form, setForm] = useState<FormType>({ binding_deposits: bindingDeposits.join(',') })

  const formToRequest = (form: FormType) => {
    return {
      binding_deposits: form.binding_deposits
    }
  }

  useEffect(() => {
    if (open) setForm({ binding_deposits: bindingDeposits.join(',') })
  }, [open, bindingDeposits])

  const { handleSubmit } = useGDKFuncHandleSubmit({
    formToRequest,
    gdkFunc: (payload) => gdk.manual.updateDepositBindingDeposits(payload, id),
    gdkFuncDependencies: [gdk, id],
    onSuccess: () => {
      close()
      reload()
      globalDialog.setConfig(createGlobalDialogConfig({
        showIcon: false,
        showCancel: false,
        message: t('dialog.updateSuccess')
      }))
      globalDialog.setOpen({
        id: 'updateBindingDepositsSuccess',
        value: true,
        isOK: false
      })
    },
    onError: (error) => {
      globalDialog.setConfig(createGlobalDialogConfig({
        variant: 'error',
        showCancel: false,
        message: error.message
      }))
      globalDialog.setOpen({ id: 'updateBindingDepositsError', value: true, isOK: false })
    }
  })

  const onSubmit = useCallback(() => {
    if (writable) {
      handleSubmit(form)
    } else {
      close()
    }
  }, [form, handleSubmit, close, writable])

  const bindingDepositsInputProps = useMemo(() => ({
    classes: { input: commonClasses.memoField },
    inputProps: {
      placeholder: t('placeholder.inputBindingDeposits')
    }
  }), [commonClasses.memoField, t])

  return (
    <Dialog
      open={open}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle className={classes.title}>{t('common.exchangedDepositOrderNo')}</DialogTitle>
      <DialogContent>
        <Box padding={3} paddingTop={1}>
          <LoadingAndErrorFrame {...pageFlow.status}>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell className={clsx(commonClasses.pinkTableHead, commonClasses.tableCellHead)}>
                    {t('common.playerMemo')}
                  </TableCell>
                  <TableCell>
                    {memo}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={clsx(commonClasses.pinkTableHead, commonClasses.tableCellHead)}>
                    {t('common.playerAccount')}
                  </TableCell>
                  <TableCell>
                    {account}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={clsx(commonClasses.pinkTableHead, commonClasses.tableCellHead)}>
                    {t('common.depositCash')}
                  </TableCell>
                  <TableCell>
                    <PointsCell points={Number(cash)} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={clsx(commonClasses.pinkTableHead, commonClasses.tableCellHead)}>
                    {t('common.exchangedDepositOrderNo')}
                  </TableCell>
                  <TableCell className={clsx(commonClasses.pre)}>
                    {bindingDeposits.map((item) => `${item}\n`) }
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            {
              writable && (
                <Box paddingTop={1}>
                  <Typography>{`${t('common.exchangedDepositOrderNo')}`}</Typography>
                  <TextField
                    value={form.binding_deposits}
                    onChange={(event) => {
                      const value = event.target.value
                      setForm({ binding_deposits: value })
                    }}
                    multiline
                    fullWidth
                    type="text"
                    margin="normal"
                    variant="outlined"
                    InputProps={bindingDepositsInputProps}
                  />
                </Box>
              )
            }
          </LoadingAndErrorFrame>
        </Box>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Grid container direction="row" spacing={2} justify="center">
          <Grid item>
            {
              writable && (
                <Button
                  classes={{ root: dialogClasses.cancelButton }}
                  onClick={close}
                >
                  {t('common.cancel')}
                </Button>
              )
            }
          </Grid>
          <Grid item>
            <Button
              classes={{ root: dialogClasses.okButton }}
              onClick={onSubmit}
              disabled={ writable && bindingDeposits.join(',') === form.binding_deposits }
            >
              {t('common.confirm')}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  )
}

export default React.memo(ManualDepositUpdateBindingDepositsDialog)

import React, { PropsWithChildren, useRef, useState } from 'react'
import MuiButton from '@material-ui/core/Button'
import { useCommonStyles, useDialogStyles } from '../../utils/admin/StyleHook'
import useT from '../../i18ns/admin/useT'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(() => ({
  hiddenArea: {
    opacity: 0,
    position: 'absolute',
    top: '-9999px',
    left: '-9999px'
  }
}))

const Button = React.memo(MuiButton)

interface PropType {
  content: string
}

const CopyButton: React.FC<PropsWithChildren<PropType>> = (props) => {
  const { content, children } = props
  const dialogClasses = useDialogStyles()
  const commonClasses = useCommonStyles()
  const classes = useStyles()
  const { t } = useT()
  const [copied, setCopied] = useState(false)
  const domTextArea = useRef<HTMLTextAreaElement | null>(null)
  return (
    <Button
      onClick={() => {
        const node = domTextArea.current
        node?.select()
        document.execCommand('copy')
        setCopied(true)
        setTimeout(() => setCopied(false), 1000)
      }}
      classes={{
        root: dialogClasses.okButton,
        disabled: commonClasses.disabledButton
      }}
      style={{ minWidth: 120 }}
      disabled={copied}
    >
      {copied
        ? t('common.oneClickCopyDone')
        : children ?? t('common.oneClickCopy')
      }
      <textarea
        ref={domTextArea}
        readOnly
        className={classes.hiddenArea}
        value={content}
      />
    </Button>
  )
}

export default React.memo(CopyButton)

import React, { createContext, useMemo, useContext, useState, useCallback } from 'react'
import { ForwarderType, WithdrawWayType, ForwarderConfig } from '@golden/gdk-admin'
import { useTheme } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import DropDown, { PropTypes as DropDownProps } from '../../default/form/DropDown'
import OnOffCheckbox, { PropTypes as OnOffCheckboxProps } from '../../default/form/OnOffCheckbox'
import NumberInput from '../../default/form/NumberInput'
import FormStateProvider from '../../default/form/FormStateProvider'
import FormField from '../../default/form/FormField'
import FormSubmitButton from '../../default/form/FormSubmitButton'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import useT from '../../../i18ns/admin/useT'
import { FormPropType, getValueFromChangeEvent, getValueFromValue, getValueFromCheckboxEvent, getCashInputProps } from '../../../utils/default/FormHelper'
import { createValidateNotEmpty, createErrorResult, createCorrectResult } from '../../../utils/default/Validator'
import { createDefaultFormState, ValueGetter, FormValidation, ChangedFormGetter, DisableFieldGetter } from '../../../utils/default/FormHook'
import { InitialFormFunc, useGetData } from '../../../utils/default/ComplexFlowHook'
import useGDK from '../../../providers/admin/gdk/useGDK'
import RequiredText from '../../default/form/RequiredText'
import PlayerLayerInput, { PropTypes as PlayerLayerInputProps, PlayerLayerValue } from '../PlayerLayerInput'
import WithdrawWayTypeName from '../../../constants/admin/withdrawWayTypeName'
import useGDKStore from '../../../providers/admin/gdk/useGDKStore'
import { formatMoney } from '../../../utils/default/TableHelper'
export interface EditFinanceWithdrawalWayFormType {
  slug: ForwarderType | ''
  courierDropDown: string | ''
  courier: number | ''
  name: string
  min: string
  max: string
  autoMin: string
  autoMax: string
  layer: PlayerLayerValue
  isActive: boolean
  isAuto: boolean
  method: WithdrawWayType | ''
}

export const initialForm: InitialFormFunc<EditFinanceWithdrawalWayFormType> = (defaultForm) => ({
  slug: '',
  courierDropDown: '',
  courier: '',
  name: '',
  min: '',
  max: '',
  autoMin: '',
  autoMax: '',
  layer: {
    layers: []
  },
  isActive: false,
  isAuto: false,
  method: '',
  ...defaultForm
})

const getValueFromEvent: ValueGetter<EditFinanceWithdrawalWayFormType> = {
  slug: getValueFromChangeEvent,
  courierDropDown: getValueFromChangeEvent,
  courier: getValueFromChangeEvent,
  name: getValueFromChangeEvent,
  min: getValueFromChangeEvent,
  max: getValueFromChangeEvent,
  autoMin: getValueFromChangeEvent,
  autoMax: getValueFromChangeEvent,
  layer: getValueFromValue,
  isActive: getValueFromCheckboxEvent,
  isAuto: getValueFromCheckboxEvent,
  method: getValueFromChangeEvent
}

const getChangedForm: ChangedFormGetter<EditFinanceWithdrawalWayFormType> = {
  slug: (value, form) => {
    return {
      ...form,
      slug: value,
      courier: '',
      name: '',
      min: '',
      max: '',
      autoMin: '',
      autoMax: ''
    }
  },
  courierDropDown: (value, form) => {
    return {
      ...form,
      courierDropDown: value,
      courier: Number(value.split('-')[0]),
      name: value.split('-')[1] || '',
      min: '',
      max: '',
      autoMin: '',
      autoMax: ''
    }
  }
}

interface PropTypes extends FormPropType<EditFinanceWithdrawalWayFormType> {
  title: string
  okText: string
  onBack: () => void
  disableForwarder?: boolean
}

const FormContext = createContext(createDefaultFormState(initialForm()))

const cashInputProps = getCashInputProps()

const Fields: React.FC = React.memo(() => {
  const { value, handleChange, handleOther, error } = useContext(FormContext)
  const { t } = useT()
  const gdk = useGDK()
  const forwarders = useGDKStore.finance.forwarders()

  const [configs, setConfigs] = useState<ForwarderConfig[]>([])
  useGetData({
    gdkFunc: () => gdk.finance.getForwarderConfig(),
    gdkFuncDependencies: [gdk],
    onSuccess: (res: ForwarderConfig[]) => setConfigs(res)
  })

  const courierOptions = useMemo(() => {
    if (!(value.slug in forwarders)) return []
    return forwarders[value.slug].forwarders.map(({ id, name }) => ({ name, value: `${id}-${name}` }))
  }, [forwarders, value.slug])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onCourierNameChange = useCallback(handleChange('courierDropDown'), [])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onCourierNameBlur = useCallback(handleOther('blur', 'courierDropDown'), [])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onMinChange = useCallback(handleChange('min'), [])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onMaxChange = useCallback(handleChange('max'), [])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onAutoMinChange = useCallback(handleChange('autoMin'), [])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onAutoMaxChange = useCallback(handleChange('autoMax'), [])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onTypeChange = useCallback(handleChange('method'), [])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onTypeBlur = useCallback(handleOther('blur', 'method'), [])

  const wayOptions = useMemo(() => {
    const config = configs.find((config) => config.slug === value.slug)
    if (config) return config.withdraw_methods.map((method) => ({ name: t(WithdrawWayTypeName[method.method]), value: method.method }))
    return []
  }, [configs, t, value.slug])

  return (
    <React.Fragment>
      <Grid item xs={12} md={3}>
        <DropDown
          fullWidth
          required
          label={t('common.courierName')}
          options={courierOptions}
          value={value.courierDropDown}
          onChange={onCourierNameChange}
          onBlur={onCourierNameBlur}
          helperText={error.courierDropDown ?? ''}
          error={error.courierDropDown !== null}
          disabled={courierOptions.length === 0}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <NumberInput
          fullWidth
          required
          label={t('common.minWithdrawalMoney')}
          placeholder={t('placeholder.pleaseInput', { item: t('common.minWithdrawalMoney') })}
          inputProps={cashInputProps}
          value={value.min}
          onChange={onMinChange}
          helperText={error.min}
          error={error.min !== null}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <NumberInput
          fullWidth
          required
          label={t('common.maxWithdrawalMoney')}
          placeholder={t('placeholder.pleaseInput', { item: t('common.maxWithdrawalMoney') })}
          inputProps={cashInputProps}
          value={value.max}
          onChange={onMaxChange}
          helperText={error.max}
          error={error.max !== null}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <NumberInput
          fullWidth
          required
          label={t('common.minAutoWithdrawalMoney')}
          placeholder={t('placeholder.pleaseInput', { item: t('common.minAutoWithdrawalMoney') })}
          inputProps={cashInputProps}
          value={value.autoMin}
          onChange={onAutoMinChange}
          helperText={error.autoMin}
          error={error.autoMin !== null}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <NumberInput
          fullWidth
          required
          label={t('common.maxAutoWithdrawalMoney')}
          placeholder={t('placeholder.pleaseInput', { item: t('common.maxAutoWithdrawalMoney') })}
          inputProps={cashInputProps}
          value={value.autoMax}
          onChange={onAutoMaxChange}
          helperText={error.autoMax}
          error={error.autoMax !== null}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <DropDown
          fullWidth
          required
          label={t('common.playerWithdrawProcess')}
          options={wayOptions}
          value={value.method.toString()}
          onChange={onTypeChange}
          onBlur={onTypeBlur}
          disabled={!value.slug}
        />
      </Grid>
    </React.Fragment>
  )
})

const EditFinanceWithdrawalWayForm: React.FC<PropTypes> = (props) => {
  const { title, okText, onBack, disableForwarder, onSubmit, defaultValue } = props
  const theme = useTheme()
  const classes = useCommonStyles()
  const { t } = useT()
  const forwarders = useGDKStore.finance.forwarders()
  const forwarderOptions = useMemo(
    () => Object.entries(forwarders).map(([value, { name }]) => ({ value, name })),
    [forwarders]
  )

  const disableField = useMemo(() => {
    return {
      slug: () => disableForwarder ?? false,
      fee: () => true
    } as DisableFieldGetter<EditFinanceWithdrawalWayFormType>
  }, [disableForwarder])

  const validation = useMemo(() => {
    return {
      slug: [
        {
          func: createValidateNotEmpty<EditFinanceWithdrawalWayFormType>('slug', t),
          when: ['change', 'blur', 'beforeClickSubmit']
        }
      ],
      courierDropDown: [
        {
          func: createValidateNotEmpty<EditFinanceWithdrawalWayFormType>('courierDropDown', t),
          when: ['change', 'blur', 'beforeClickSubmit']
        }
      ],
      courier: [],
      name: [],
      min: [
        {
          func: createValidateNotEmpty<EditFinanceWithdrawalWayFormType>('min', t),
          when: ['change', 'beforeClickSubmit']
        },
        {
          func: (value, form) => {
            if (!value) return createCorrectResult('min')
            const min = Number(value as string)
            if (!!form.max && min > Number(form.max)) return createErrorResult('min', t('error.cashLimit', { action: t('common.lessThan'), cash: formatMoney(form.max), item: t('common.maxWithdrawalMoney') }))
            if (!!form.autoMin && min > Number(form.autoMin)) return createErrorResult('min', t('error.cashLimit', { action: t('common.lessThan'), cash: formatMoney(form.autoMin), item: t('common.minAutoWithdrawalMoney') }))
            return createCorrectResult('min')
          },
          when: ['change', 'beforeClickSubmit', 'change:autoMin', 'change:max']
        }
      ],
      max: [
        {
          func: createValidateNotEmpty<EditFinanceWithdrawalWayFormType>('max', t),
          when: ['change', 'beforeClickSubmit']
        },
        {
          func: (value, form) => {
            if (!value) return createCorrectResult('max')
            const max = Number(value as string)
            if (!!form.min && max < Number(form.min)) return createErrorResult('max', t('error.cashLimit', { action: t('common.moreThan'), cash: formatMoney(form.min), item: t('common.minWithdrawalMoney') }))
            if (!!form.autoMax && max < Number(form.autoMax)) return createErrorResult('max', t('error.cashLimit', { action: t('common.moreThan'), cash: formatMoney(form.autoMax), item: t('common.maxAutoWithdrawalMoney') }))
            return createCorrectResult('max')
          },
          when: ['change', 'beforeClickSubmit', 'change:autoMax', 'change:min']
        }
      ],
      autoMin: [
        {
          func: createValidateNotEmpty<EditFinanceWithdrawalWayFormType>('autoMin', t),
          when: ['change', 'beforeClickSubmit']
        },
        {
          func: (value, form) => {
            if (!value) return createCorrectResult('autoMin')
            const autoMin = Number(value as string)
            if (!!form.autoMax && autoMin > Number(form.autoMax)) return createErrorResult('autoMin', t('error.cashLimit', { action: t('common.lessThan'), cash: formatMoney(form.autoMax), item: t('common.maxAutoWithdrawalMoney') }))
            if (!!form.min && autoMin < Number(form.min)) return createErrorResult('autoMin', t('error.cashLimit', { action: t('common.moreThan'), cash: formatMoney(form.min), item: t('common.minWithdrawalMoney') }))
            return createCorrectResult('autoMin')
          },
          when: ['change', 'beforeClickSubmit', 'change:autoMax', 'change:min']
        }
      ],
      autoMax: [
        {
          func: createValidateNotEmpty<EditFinanceWithdrawalWayFormType>('autoMax', t),
          when: ['change', 'beforeClickSubmit']
        },
        {
          func: (value, form) => {
            if (!value) return createCorrectResult('autoMax')
            const autoMax = Number(value as string)
            if (!!form.autoMin && autoMax < Number(form.autoMin)) return createErrorResult('autoMax', t('error.cashLimit', { action: t('common.moreThan'), cash: formatMoney(form.autoMin), item: t('common.minAutoWithdrawalMoney') }))
            if (!!form.max && autoMax > Number(form.max)) return createErrorResult('autoMax', t('error.cashLimit', { action: t('common.lessThan'), cash: formatMoney(form.max), item: t('common.maxWithdrawalMoney') }))
            return createCorrectResult('autoMax')
          },
          when: ['change', 'beforeClickSubmit', 'change:autoMin', 'change:max']
        }
      ],
      layer: [
        {
          func: (value) => {
            const layer = (value as PlayerLayerValue)
            if (layer.layers.length === 0) return createErrorResult('layer', t('error.mustNotEmpty'))
            return createCorrectResult('layer')
          },
          when: ['beforeClickSubmit']
        }
      ],
      isActive: [],
      isAuto: [],
      method: [
        {
          func: createValidateNotEmpty<EditFinanceWithdrawalWayFormType>('method', t),
          when: ['change', 'beforeClickSubmit']
        }
      ]
    } as FormValidation<EditFinanceWithdrawalWayFormType>
  }, [t])

  return (
    <FormStateProvider
      context={FormContext}
      onSubmit={onSubmit}
      defaultValue={defaultValue}
      validation={validation}
      getValueFromEvent={getValueFromEvent}
      getChangedForm={getChangedForm}
      disableField={disableField}
    >
      <Paper>
        <Box padding={4}>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <Box
                paddingY={1.25}
                paddingX={2}
                className={classes.pinkTitleBar}
              >
                <Typography variant="h5">
                  {title}
                </Typography>
              </Box>
            </Grid>
            <Grid item>
              <Grid container direction="row" spacing={2} wrap="wrap">
                <Grid item xs={12} md={3}>
                  <FormField<EditFinanceWithdrawalWayFormType, DropDownProps>
                    context={FormContext}
                    component={DropDown}
                    name="slug"
                    label={t('common.forwarder')}
                    options={forwarderOptions}
                    fullWidth
                    required
                  />
                </Grid>
                <Fields />
              </Grid>
            </Grid>
            <Grid item>
              <Box
                marginTop={3}
                borderBottom={`4px solid ${theme.palette.grey[200]}`}
              />
            </Grid>
            <Grid item>
              <FormField<EditFinanceWithdrawalWayFormType, PlayerLayerInputProps>
                component={PlayerLayerInput}
                context={FormContext}
                name="layer"
                label={t('common.playerLayer')}
                isShowProxyDeposit={false}
              />
            </Grid>
            <Grid item>
              <Grid container direction="row" spacing={2} wrap="wrap">
                <Grid item>
                  <FormField<EditFinanceWithdrawalWayFormType, OnOffCheckboxProps>
                    component={OnOffCheckbox}
                    context={FormContext}
                    name="isActive"
                    label={t('common.isStartWithdrawalPlatform')}
                  />
                </Grid>
                <Grid item>
                  <FormField<EditFinanceWithdrawalWayFormType, OnOffCheckboxProps>
                    component={OnOffCheckbox}
                    context={FormContext}
                    name="isAuto"
                    label={t('common.isStartAutoWithdrawal')}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <RequiredText />
            </Grid>
          </Grid>
        </Box>
      </Paper>
      <Box paddingY={3} display="flex" flexDirection="row" justifyContent="flex-end">
        <Box paddingX={2}>
          <Button
            className={classes.greyButton}
            onClick={onBack}
          >
            {t('common.cancel')}
          </Button>
        </Box>
        <FormSubmitButton
          context={FormContext}
          component={Button}
          type="submit"
          className={classes.purpleGradualButton}
        >
          {okText}
        </FormSubmitButton>
      </Box>
    </FormStateProvider>
  )
}

export default React.memo(EditFinanceWithdrawalWayForm)

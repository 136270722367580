import React, { useCallback, useMemo, Dispatch, SetStateAction } from 'react'
import { BankMappingForwarderInfo } from '@golden/gdk-admin'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import LoadingAndErrorFrame from '../../default/frames/LoadingAndErrorFrame'
import CoreTable from '../../default/present/CoreTable'
import { PageFlowType } from '../../../utils/default/PageFlowHook'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import useT from '../../../i18ns/admin/useT'
import { useGetDataByPayload, useRequestFromSearch, useReload, useRedirectHandleBack } from '../../../utils/default/ComplexFlowHook'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { createTableData } from '../../../utils/default/TableHelper'
import { useChecker } from '../../../utils/admin/AdminRouteHook'
import { searchToRequest } from '../../../views/admin/finance/FinanceBankCodePage'
import useGlobalDialog from '../../../providers/admin/dialog/useGlobalDialog'
import { createGlobalDialogConfig } from '../../../utils/default/DialogHelper'
import { Path } from '../../../components/admin/route/route'

export interface RowType {
  id: number
  name: string
  goldenCode: string
  editCode: React.ReactElement
}

interface PropTypes {
  list: Array<BankMappingForwarderInfo & { edit_code: string }>
  setList: Dispatch<SetStateAction<Array<BankMappingForwarderInfo & {
    edit_code: string
  }>>>
  pageFlow: PageFlowType
}

const FinanceBankCodeTable: React.FC<PropTypes> = (props) => {
  const { list, setList, pageFlow } = props
  const request = useRequestFromSearch({ searchToRequest })
  const commonClasses = useCommonStyles()
  const { reload, reloadFlag } = useReload()
  const gdk = useGDK()
  const { t } = useT()
  const writable = useChecker()
  const globalDialog = useGlobalDialog()
  const [handleBack] = useRedirectHandleBack({
    path: Path.FINANCE_BANK
  })

  useGetDataByPayload({
    payload: request,
    gdkFunc: (payload) => gdk.finance.getBanksMappingForwarder(payload?.slug as string),
    gdkFuncDependencies: [gdk, reloadFlag],
    onBeforeFetch: pageFlow.setLoadingStart,
    onSuccess: (res: BankMappingForwarderInfo[]) => {
      setList(res.map(item => ({ ...item, forwarder_bank_code: item.forwarder_bank_code ?? '', edit_code: item.forwarder_bank_code ?? '' })))
      pageFlow.setContentShow()
    },
    onError: pageFlow.setGDKError,
    canLoadData: request?.slug !== undefined
  })

  const onChange = useCallback((index: number, code: string) => {
    setList(list => list.map((item, i) => index === i ? { ...item, edit_code: code } : item))
  }, [setList])

  const handleSubmit = useCallback(() => {
    if (request?.slug === undefined) return

    const data = list.reduce<Array<{ bank_id: number, bank_code: string }>>((accu, curr) => {
      if (curr.edit_code !== curr.forwarder_bank_code) {
        accu.push({ bank_id: curr.bank_id, bank_code: curr.edit_code })
      }
      return accu
    }, [])
    gdk.finance.updateBanksMappingForwarder(request?.slug, data).subscribe({
      next: () => {
        handleBack()
        globalDialog.setConfig(createGlobalDialogConfig({
          showIcon: false,
          showCancel: false,
          message: t('dialog.updateSuccess')
        }))
        globalDialog.setOpen({
          id: 'updateForwarderCodeSuccess',
          value: true,
          isOK: false
        })
      },
      error: (error) => {
        reload()
        globalDialog.setConfig(createGlobalDialogConfig({
          showIcon: true,
          variant: 'error',
          showCancel: false,
          message: error.message
        }))
        globalDialog.setOpen({
          id: 'updateForwarderCodeFailed',
          value: true,
          isOK: false
        })
      }
    })
  }, [request?.slug, list, gdk.finance, handleBack, globalDialog, t, reload])

  const tableClasses = useMemo(() => {
    return {
      head: commonClasses.pinkTableHead,
      row: commonClasses.tableRow,
      cellBody: commonClasses.nowrap,
      cellHead: commonClasses.tableCellHead
    }
  }, [commonClasses])

  const rows = useMemo(() => {
    return list.map((item, index) => (
      {
        id: item.bank_id,
        name: item.bank_name,
        goldenCode: item.golden_bank_code,
        editCode: (
          <TextField
            disabled={!writable}
            value={item.edit_code ?? ''}
            onChange={(event) => {
              onChange(index, event.target.value)
            }}
          />)
      }
    ))
  }, [list, writable, onChange])

  const data = useMemo(() => {
    return createTableData<RowType>(
      {
        id: {
          label: '',
          value: 'id'
        },
        name: {
          label: t('common.bankName'),
          value: 'name',
          align: 'center'
        },
        goldenCode: {
          label: t('common.bankCode'),
          value: 'goldenCode',
          align: 'center'
        },
        editCode: {
          label: t('common.thirdPartyCode'),
          value: 'editCode',
          align: 'center'
        }
      },
      [
        'name',
        'goldenCode',
        'editCode'
      ],
      rows,
      'id'
    )
  }, [rows, t])

  return (
    <LoadingAndErrorFrame { ...pageFlow.status }>
      <Box>
        <CoreTable
          classes={tableClasses}
          data={data}
          loading={pageFlow.status.loading}
          total={list.length}
          perPage={list.length}
        />
      </Box>
      <Box marginTop={2}>
        <Grid container justify='flex-end'>
          {(list.length !== 0) && (<Grid item>
            <Button
              className={commonClasses.purpleGradualButton}
              onClick={handleSubmit}
              fullWidth
            >
              {t('common.save2')}
            </Button>
          </Grid>)}
        </Grid>
      </Box>
    </LoadingAndErrorFrame>
  )
}

export default React.memo(FinanceBankCodeTable)

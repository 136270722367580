import { JumpShipStatusType } from '@golden/gdk-admin'
import TKeyType from '../../i18ns/admin/TKeyType'

const jumpShipStatusName: { [key in JumpShipStatusType]: TKeyType } = {
  [JumpShipStatusType.IN_PROCESS]: 'common.processing',
  [JumpShipStatusType.SUCCESSFUL]: 'common.success',
  [JumpShipStatusType.FAIL]: 'common.failure',
  [JumpShipStatusType.CANCEL]: 'common.cancel',
  [JumpShipStatusType.PENDING]: 'common.pendingJumpShip'
}

export default jumpShipStatusName

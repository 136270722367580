import React, { useState, useMemo, createContext, Dispatch, SetStateAction } from 'react'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import useT from '../../../i18ns/admin/useT'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { createDefaultPaginationData, formatCount, formatMoney, createTableData } from '../../../utils/default/TableHelper'
import { PaginationRes, ActivityRecommendFriend, ActivityRecommendFriendSum, ActivityRecommendFriendQuery, PaginationReq, ActivityType } from '@golden/gdk-admin'
import { usePageFlow } from '../../../utils/default/PageFlowHook'
import { searchToRequest } from './ActivityRecordDetailForm'
import { useRequestFromSearch, useGetDataByPayload, usePaginationClickAndChangeUrl } from '../../../utils/default/ComplexFlowHook'
import DateTime from '../../default/present/DateTime'
import ScrollablePaper from '../../default/present/ScrollablePaper'
import useGDKStore from '../../../providers/admin/gdk/useGDKStore'
import CoreTable from '../../default/present/CoreTable'
import allRoute, { Path } from '../route/route'
import LoadingAndErrorFrame from '../../default/frames/LoadingAndErrorFrame'
import MemoTextWithTitleAndContext from '../../default/memo/MemoTextWithTitleAndContext'
import MemoPopoverWithContext from '../../default/memo/MemoPopoverWithContext'
import { MemoPopoverPayload, createDefaultMemoPopoverPayload } from '../../default/memo/MemoPopover'
import PointsCell from '../../default/present/PointsCell'
import { recommendFriendStatusName } from '../../../constants/default/activityStatusName'
import { recommendFriendStatusColor } from '../../../constants/default/activityStatusColor'
import { useLocation } from 'react-router-dom'

interface SumRowType1 {
  id: number
  player: string
  success: string
  received: string
  expired: string
  canceled: string
}

interface SumRowType2 {
  id: number
  bonus: string
}

interface RowType {
  id: number
  order: string
  apply: React.ReactElement
  recommand: string
  staffAccount: string
  recommanded: string
  count: string
  effectiveCash: string
  result: React.ReactElement
  content: string
  status: React.ReactElement
  bonus: string
  updatedAt: React.ReactElement
  memo: React.ReactElement
}

const MemoContext = createContext<[MemoPopoverPayload, Dispatch<SetStateAction<MemoPopoverPayload>>]>([
  createDefaultMemoPopoverPayload(),
  () => {}
])

const AdminReportActivityRecommendFriendTable: React.FC = () => {
  const classes = useCommonStyles()
  const { t } = useT()
  const gdk = useGDK()
  const activities = useGDKStore.activity.activities()
  const location = useLocation()
  const [list, setList] = useState<PaginationRes<ActivityRecommendFriend[]> & ActivityRecommendFriendSum>({
    ...createDefaultPaginationData([]),
    applicant_player: 0,
    success_player: 0,
    expired_player: 0,
    received_player: 0,
    canceled_player: 0,
    bonus: '0.0000'
  })
  const pageFlow = usePageFlow()
  const request = useRequestFromSearch({ searchToRequest })
  const payload = useMemo(() => ({
    ...request,
    recommend_account: request?.account ? request?.account : undefined
  }), [request])
  useGetDataByPayload({
    payload: payload as ActivityRecommendFriendQuery & PaginationReq,
    gdkFunc: (payload) => gdk.activity.getActivityRecordRecommendFriend(payload),
    gdkFuncDependencies: [gdk],
    onBeforeFetch: pageFlow.setLoadingStart,
    onSuccess: (res: PaginationRes<ActivityRecommendFriend[]> & ActivityRecommendFriendSum) => {
      setList(res)
      pageFlow.setContentShow()
    },
    onError: pageFlow.setGDKError,
    canLoadData: request !== undefined
  })
  const tableClasses = useMemo(() => ({
    head: classes.greyTableHead,
    row: classes.tableRow,
    cellHead: classes.tableCellHead,
    cellBody: classes.nowrap
  }), [classes])
  const sumRow1 = useMemo(() => {
    return [{
      id: 1,
      player: formatCount(list.applicant_player),
      success: formatCount(list.success_player),
      received: formatCount(list.received_player),
      expired: formatCount(list.expired_player),
      canceled: formatCount(list.canceled_player)
    }] as SumRowType1[]
  }, [list])
  const sumData1 = useMemo(() => {
    return createTableData<SumRowType1>(
      {
        id: {
          label: '',
          value: 'id'
        },
        player: {
          label: t('common.activityTotalPlayer'),
          value: 'player',
          align: 'center'
        },
        success: {
          label: t('activityStatus.success'),
          value: 'success',
          align: 'center'
        },
        expired: {
          label: t('activityStatus.expired'),
          value: 'expired',
          align: 'center'
        },
        received: {
          label: t('activityStatus.sent'),
          value: 'received',
          align: 'center'
        },
        canceled: {
          label: t('activityStatus.canceled'),
          value: 'canceled',
          align: 'center'
        }
      },
      [
        'player',
        'success',
        'received',
        'expired',
        'canceled'
      ],
      sumRow1,
      'id'
    )
  }, [t, sumRow1])
  const sumRow2 = useMemo(() => {
    return [{
      id: 1,
      bonus: formatMoney(list.bonus)
    }] as SumRowType2[]
  }, [list])
  const sumData2 = useMemo(() => {
    return createTableData<SumRowType2>(
      {
        id: {
          label: '',
          value: 'id'
        },
        bonus: {
          label: t('common.totalActivityBonus'),
          value: 'bonus',
          align: 'center'
        }
      },
      [
        'bonus'
      ],
      sumRow2,
      'id'
    )
  }, [t, sumRow2])
  const rows = useMemo(() => {
    return list.data.map((item, index) => {
      return {
        id: index,
        order: item.serial_number,
        apply: (<DateTime time={item.applied_at} />),
        recommand: item.recommend_account,
        staffAccount: item.staff_account,
        recommanded: item.recommended_account,
        count: formatCount(item.bet_count),
        effectiveCash: formatMoney(item.effective_cash),
        result: (<PointsCell points={item.player_result} />),
        content: item.activity_content,
        status: (<p className={classes.chipText} style={{ backgroundColor: recommendFriendStatusColor[item.state] }}>{t(recommendFriendStatusName[item.state])}</p>),
        bonus: formatMoney(item.bonus),
        updatedAt: item.updated_at ? (<DateTime time={item.updated_at} />) : (<p></p>),
        memo: item.memo !== '' && (<MemoTextWithTitleAndContext context={MemoContext} title={item.memo} memo={item.memo} />)
      } as RowType
    })
  }, [list.data, t, classes])
  const data = useMemo(() => {
    return createTableData<RowType>(
      {
        id: {
          label: '',
          value: 'id'
        },
        order: {
          label: t('common.activitySerial'),
          value: 'order',
          align: 'center'
        },
        apply: {
          label: t('common.applyTime'),
          value: 'apply',
          align: 'center'
        },
        recommand: {
          label: t('common.recommandPerson'),
          value: 'recommand',
          align: 'center'
        },
        staffAccount: {
          label: t('common.staffAccount'),
          value: 'staffAccount',
          align: 'center'
        },
        recommanded: {
          label: t('common.recommandedPerson'),
          value: 'recommanded',
          align: 'center'
        },
        count: {
          label: t('common.betCount2'),
          value: 'count',
          align: 'center'
        },
        effectiveCash: {
          label: t('common.successBetCash'),
          value: 'effectiveCash',
          align: 'right'
        },
        result: {
          label: t('common.playerResult'),
          value: 'result',
          align: 'right'
        },
        content: {
          label: t('common.activityContent'),
          value: 'content',
          align: 'left'
        },
        status: {
          label: t('common.status'),
          value: 'status',
          align: 'center'
        },
        bonus: {
          label: t('common.activityBonus'),
          value: 'bonus',
          align: 'right'
        },
        updatedAt: {
          label: t('common.updatedAt'),
          value: 'updatedAt',
          align: 'center'
        },
        memo: {
          label: t('common.memo'),
          value: 'memo',
          align: 'center'
        }
      },
      [
        'order',
        'apply',
        'recommand',
        'staffAccount',
        'recommanded',
        'count',
        'effectiveCash',
        'result',
        'content',
        'status',
        'bonus',
        'updatedAt',
        'memo'
      ],
      rows,
      'id'
    )
  }, [t, rows])
  const handlePagination = usePaginationClickAndChangeUrl({
    request,
    encodePath: (location.pathname === Path.ACTIVITY_RECORD_DETAIL) ? allRoute.activityRecordDetail.encodePath : allRoute.activityRecordSub.encodePath
  })
  return (
    <MemoPopoverWithContext memoPopoverContext={MemoContext}>
      <Box paddingBottom={3}>
        <ScrollablePaper marginX={5}>
          <Box padding={5}>
            <LoadingAndErrorFrame { ...pageFlow.status }>
              <Box
                paddingY={1.25}
                paddingX={2}
                className={classes.pinkTitleBar}
              >
                <Typography variant="h5">
                  {activities.find((item) => item.type === ActivityType.RECOMMEND_FRIEND)?.name ?? ''}
                </Typography>
              </Box>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={12} md={6}>
                  <Box paddingY={2}>
                    <Typography variant="h5">
                      {t('common.peopleStatisticResult')}
                    </Typography>
                  </Box>
                  <CoreTable
                    data={sumData1}
                    total={1}
                    classes={tableClasses}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box paddingY={2}>
                    <Typography variant="h5">
                      {t('common.bonusStatisticResult')}
                    </Typography>
                  </Box>
                  <CoreTable
                    data={sumData2}
                    total={1}
                    classes={tableClasses}
                  />
                </Grid>
              </Grid>
            </LoadingAndErrorFrame>
          </Box>
        </ScrollablePaper>
      </Box>
      <ScrollablePaper marginX={5}>
        <Box padding={5}>
          <LoadingAndErrorFrame { ...pageFlow.status }>
            <CoreTable
              data={data}
              total={list.total}
              classes={tableClasses}
              showPagination
              page={request?.page ?? 1}
              onChangePage={handlePagination}
            />
          </LoadingAndErrorFrame>
        </Box>
      </ScrollablePaper>
    </MemoPopoverWithContext>
  )
}

export default React.memo(AdminReportActivityRecommendFriendTable)

import React, { createContext, Dispatch, SetStateAction } from 'react'
import Box from '@material-ui/core/Box'
import StateProvider from '../../../providers/default/StateProvider'
import ScrollablePaper from '../../../components/default/present/ScrollablePaper'
import FinancePiggyToolbar, { FinancePiggyToolbarFormType } from '../../../components/admin/finance/FinancePiggyToolbar'
import FinancePiggyWechatTable from '../../../components/admin/finance/FinancePiggyWechatTable'
import useT from '../../../i18ns/admin/useT'
import { Path } from '../../../components/admin/route/route'

export const FormContext = createContext<[FinancePiggyToolbarFormType, Dispatch<SetStateAction<FinancePiggyToolbarFormType>>]>([
  {
    status: '--',
    account: '',
    page: 1
  },
  () => {}
])

const FinancePiggyWechatPage: React.FC = () => {
  const { t } = useT()
  return (
    <Box paddingY={5}>
      <ScrollablePaper marginX={5}>
        <StateProvider
          context={FormContext}
          defaultValue={{ status: '--', account: '', page: 1 }}
        >
          <Box padding={4}>
            <FinancePiggyToolbar
              context={FormContext}
              namePlaceholder={t('placeholder.inputSearchWechatAccount')}
              createLink={Path.FINANCE_PIGGY_WECHAT_CREATE}
              createTitle={t('page.createPiggyWechat')}
            />
          </Box>
          <Box paddingTop={1} paddingBottom={5} paddingX={4}>
            <FinancePiggyWechatTable />
          </Box>
        </StateProvider>
      </ScrollablePaper>
    </Box>
  )
}

export default React.memo(FinancePiggyWechatPage)

import React, { useState, createContext, Dispatch, SetStateAction, useContext, useMemo, Fragment } from 'react'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import MuiButton from '@material-ui/core/Button'
import MuiTextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import useT from '../../../i18ns/admin/useT'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import useGDK from '../../../providers/admin/gdk/useGDK'
import useGlobalDialog from '../../../providers/admin/dialog/useGlobalDialog'
import { createGlobalDialogConfig } from '../../../utils/default/DialogHelper'
import { useDialogHandleClick, useGetData, useReload } from '../../../utils/default/ComplexFlowHook'
import { usePageFlow } from '../../../utils/default/PageFlowHook'
import LoadingAndErrorFrame from '../../../components/default/frames/LoadingAndErrorFrame'
import { makeStyles } from '@material-ui/core'
import { DownloadLinkSetting } from '@golden/gdk-admin'
import MuiSwitch from '@material-ui/core/Switch'

const useStyles = makeStyles(() => ({
  subtitle: {
    fontWeight: 600,
    fontSize: 18,
    marginTop: 10
  },
  text: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  }
}))

const initialForm = (): DownloadLinkSetting[] => ([])

interface ContextType {
  form: DownloadLinkSetting[]
  setForm: Dispatch<SetStateAction<DownloadLinkSetting[]>>
  reload: () => void
  reloadFlag: boolean
}

export const FormContext = createContext<ContextType>({
  form: initialForm(),
  setForm: () => {},
  reload: () => {},
  reloadFlag: false
})

const Button = React.memo(MuiButton)
const TextField = React.memo(MuiTextField)

const StatusInput: React.FC<{
  slug: string
}> = React.memo((props) => {
  const { slug } = props
  const { t } = useT()
  const { form, setForm } = useContext(FormContext)
  const status = useMemo(() => form.find(item => item.slug === slug)?.status, [form, slug])

  return (
    <Grid container item direction="row" alignItems="center" spacing={2}>
      <Grid item>
        <Typography>{t('common.currentStateWithColon')}</Typography>
      </Grid>
      <Grid item>
        <span style={{ color: status ? '#72d476' : '#f44336' }}>
          {status ? t('common.show') : t('common.hide')}
        </span>
      </Grid>
        <Grid item>
          <MuiSwitch
            checked={status}
            value={slug}
          onChange={(_, isChecked) => {
            setForm((form) => form.map(item => ({
              ...item,
              status: item.slug === slug ? isChecked : item.status
            })))
          }}
          />
        </Grid>
    </Grid>
  )
})

const DownloadLinkInput: React.FC<{
  type: 'ios' | 'android'
  slug: string
}> = React.memo((props) => {
  const { type, slug } = props
  const { t } = useT()
  const { form, setForm } = useContext(FormContext)

  return (
    <Grid container item direction="row" alignItems="center" spacing={1}>
      <Grid item>
        <Box width="110px">
          <Typography>{t('common.downloadLinkWithColon', { prefix: type === 'ios' ? t('common.ios') : t('common.android') })}</Typography>
        </Box>
      </Grid>
      <Grid item xs={4}>
        <TextField
            fullWidth
            value={form.find(item => item.slug === slug)?.[`${type}_link`]}
            onChange={(e) => {
              const value = e.target.value
              setForm((form) => form.map(item => ({
                ...item,
                [`${type}_link`]: item.slug === slug ? value : item[`${type}_link`]
              })))
            }}
          />
      </Grid>
    </Grid>
  )
})

const SubmitButton: React.FC = () => {
  const commonClasses = useCommonStyles()
  const { t } = useT()
  const { form, reload, reloadFlag } = useContext(FormContext)
  const globalDialog = useGlobalDialog()
  const gdk = useGDK()

  const handleSubmit = useDialogHandleClick({
    dialogId: 'updateDownloadLink',
    globalDialog,
    changeDialogConfig: createGlobalDialogConfig({
      showIcon: false,
      message: t('dialog.confirmUpdateDownloadLink')
    }),
    successDialogConfig: createGlobalDialogConfig({
      showIcon: false,
      message: t('dialog.updateSuccess'),
      showCancel: false
    }),
    getFailDialogConfig: (error: string) => createGlobalDialogConfig({
      variant: 'error',
      showIcon: true,
      message: error,
      showCancel: false
    }),
    payload: form,
    gdkFunc: (payload) => gdk.downloadLink.updateDownloadLinkSetting(payload.map(item => ({
      ...item,
      status: (!item.android_link?.trim() && !item.ios_link?.trim()) ? false : item.status
    }))),
    gdkFuncDependencies: [gdk, reloadFlag],
    onSuccess: () => {
      reload()
    }
  })

  return (
    <Grid container justifyContent='flex-end' spacing={2} style={{ marginTop: '10px' }}>
    <Button
      className={commonClasses.purpleGradualButton}
      onClick={handleSubmit}
    >
      { t('common.saveSetting') }
    </Button>
  </Grid>
  )
}

const DownloadLinkSettingPage: React.FC = () => {
  const gdk = useGDK()
  const pageFlow = usePageFlow()
  const commonClasses = useCommonStyles()
  const classes = useStyles()
  const { reload, reloadFlag } = useReload()
  const { t } = useT()
  const [form, setForm] = useState<DownloadLinkSetting[]>(initialForm())

  useGetData({
    gdkFunc: () => gdk.downloadLink.getDownloadLinkSetting(),
    gdkFuncDependencies: [gdk, reloadFlag],
    onBeforeFetch: pageFlow.setLoadingStart,
    onSuccess: (res) => {
      setForm(res)
      pageFlow.setContentShow()
    },
    onError: pageFlow.setGDKError
  })

  return (
  <FormContext.Provider value={{ form, setForm, reload, reloadFlag }}>
    <Box padding={5}>
      <Paper>
        <Box padding={4}>
          <LoadingAndErrorFrame { ...pageFlow.status }>
            <Box>
                <Grid container direction="column" spacing={2}>
                  <Grid item>
                    <Box
                      paddingY={1.25}
                      paddingX={2}
                      className={commonClasses.pinkTitleBar}
                    >
                      <Typography variant="h5">
                        {t('page.downloadLink')}
                      </Typography>
                    </Box>
                  </Grid>
                  {
                    form.map((item) => (
                      <Fragment key={item.slug}>
                        <Grid item>
                          <Typography variant="h6" className={classes.subtitle}>{item.name}</Typography>
                        </Grid>
                        <Grid item container spacing={4}>
                          <StatusInput slug={item.slug}/>
                          <DownloadLinkInput type="ios" slug={item.slug} />
                          <DownloadLinkInput type="android" slug={item.slug} />
                        </Grid>
                      </Fragment>
                    ))
                  }
                </Grid>
              </Box>
            </LoadingAndErrorFrame>
            <SubmitButton />
          </Box>
        </Paper>
      </Box>
    </FormContext.Provider>
  )
}

export default React.memo(DownloadLinkSettingPage)

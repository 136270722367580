import React from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import useT from '../../../i18ns/admin/useT'

export type Align = 'inherit' | 'left' | 'center' | 'right' | 'justify'
export type Order = 'asc' | 'desc'

interface IpMonitoringHistoryDataType {
  last_login_at: JSX.Element
  player_account: string | JSX.Element
  staff_account: string
  user_id: number
  is_selected: boolean
  player_real_name: string
  game_category_names: string[]
}

interface IpHistoryMonitoringData {
  ip: string | JSX.Element
  histories: IpMonitoringHistoryDataType[]
  is_register_ip: boolean
}

export interface PropTypes {
  data: IpHistoryMonitoringData[] | undefined
  columnNames: {
    groupTag: string
    rowTags: string[]
  }
  loading?: boolean
  classes?: {
    head?: string
    body?: string
    cellHead?: string
    cellBody?: string
    row?: string
    rowSelect?: string
  }
  reqAccount?: string
}

const useStyles = makeStyles((theme) => ({
  fourBorder: {
    border: '1px solid rgba(233, 233, 233, 1)'
  },
  boldBottomBorder: {
    borderBottom: '3px solid rgba(200, 200, 200, 1)'
  },
  fourPadding: {
    padding: theme.spacing(1)
  },
  noPadding: {
    padding: 0
  },
  fixedTable: {
    tableLayout: 'fixed',
    minWidth: '400px'
  },
  separateGroup: {
    borderLeft: '1px solid rgba(233, 233, 233, 1)'
  },
  head: {
    borderTop: '1px solid rgba(233, 233, 233, 1)'
  },
  cellHead: {
    whiteSpace: 'nowrap',
    color: theme.palette.text.primary
  },
  cellBody: {
    color: theme.palette.text.secondary
  },
  rowSelect: {
    backgroundColor: '#f2f2f2'
  },
  shadow: {
    background: 'linear-gradient(to left, white 1%, rgba(255,255,255,0)), linear-gradient(to left, rgba(255,255,255,0), white 99%) 0 100%, radial-gradient(farthest-side at 100% 50%, rgba(0,0,0,.2), rgba(0,0,0,0)) 0 50%, radial-gradient(farthest-side at 0% 50%, rgba(0,0,0,.2), rgba(0,0,0,0)) 0 100%',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right, left, right, left',
    backgroundSize: '100px 100%, 100px 100%, 14px 100%, 14px 100%',
    backgroundAttachment: 'local, local, scroll, scroll'
  },
  singleLineTextDiv: {
    margin: '2px'
  }
}))

const PlayerIpMonitoringNestedTable: React.FC<PropTypes> = (props) => {
  const {
    data,
    columnNames
  } = props
  const classes = useStyles()
  const { t } = useT()
  const propsClasses = props.classes ?? {}

  if (!data || data.length === 0) {
    return (
      <Box display="flex" justifyContent="center" padding={1} overflow="auto">
        <Typography variant="h3">
          {t('common.noData')}
        </Typography>
      </Box>
    )
  }

  return (
    <React.Fragment>
      <Box className={classes.shadow}>
        <Table size='medium' className={classes.fourBorder}>
          <TableHead className={clsx(classes.head, propsClasses.head)}>
            <TableRow>
              <TableCell
                classes={{ head: clsx(classes.cellHead, propsClasses.cellHead) }}
                className={classes.fourPadding}
                align='center'
              >
                {columnNames.groupTag}
              </TableCell>
              <TableCell className={classes.noPadding}>
                <Table className={classes.fixedTable}>
                  <TableHead className={clsx(classes.head, propsClasses.head)}>
                    <TableRow>
                      {columnNames.rowTags.map((columnName, indexOfColumnName) => {
                        return (
                          <TableCell
                            classes={{ head: clsx(classes.cellHead, propsClasses.cellHead) }}
                            className={clsx(classes.fourPadding, classes.fourBorder)}
                            align='center'
                            key={`the${indexOfColumnName}-rdColumnName${columnName}`}
                          >
                            {columnName}
                          </TableCell>
                        )
                      })}
                    </TableRow>
                  </TableHead>
                </Table>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody classes={{ root: propsClasses.body }}>
            {data.map((groupedRowItem: IpHistoryMonitoringData, indexOfIp) => {
              return (
                <TableRow
                  classes={{ root: clsx(propsClasses.row), selected: clsx(propsClasses.rowSelect) }}
                  className={clsx((indexOfIp !== data.length - 1) ? classes.boldBottomBorder : '')}
                  key={`rowOfThe${indexOfIp}-rdIp`}
                >
                  <TableCell
                    classes={{ head: clsx(classes.cellBody, propsClasses.cellBody) }}
                    className={clsx(classes.fourPadding, classes.fourBorder)}
                    align='center'
                  >
                    <div className={clsx(classes.singleLineTextDiv)}>
                      {groupedRowItem.ip}
                    </div>
                    <div className={clsx(classes.singleLineTextDiv)}>
                      {groupedRowItem.is_register_ip && `(${t('common.registerIP')})`}
                    </div>
                  </TableCell>
                  <TableCell className={clsx(classes.noPadding, classes.separateGroup)}>
                    <Table className={classes.fixedTable}>
                      <TableBody>
                        {groupedRowItem.histories.map((ipHistory) => {
                          return (
                            <TableRow
                              classes={{ root: clsx(propsClasses.row), selected: clsx(propsClasses.rowSelect) }}
                              className={(ipHistory.is_selected ? classes.rowSelect : '')}
                              key={`rowOfUser${ipHistory.user_id}`}
                            >
                              {[ipHistory.player_account, ipHistory.game_category_names, ipHistory.staff_account, ipHistory.player_real_name, ipHistory.last_login_at].map((infoItem, index) => {
                                return (
                                  <TableCell
                                    classes={{ head: clsx(classes.cellBody, propsClasses.cellBody) }}
                                    className={clsx(classes.fourPadding, classes.fourBorder)}
                                    align='center'
                                    key={`${index}ColumnOfUser${ipHistory.user_id}`}
                                  >
                                    {Array.isArray(infoItem) ? (infoItem.length ? infoItem.map((e) => <div>{e}</div>) : <div>{'-'}</div>) : infoItem}
                                  </TableCell>
                                )
                              })}
                            </TableRow>
                          )
                        })}
                      </TableBody>
                    </Table>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </Box>
    </React.Fragment>
  )
}

export default React.memo(PlayerIpMonitoringNestedTable)

import React, { useState } from 'react'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import { AdminPlayerGoubao, OperationLogsCategoryType, OperationLogsCryptoWalletMethod } from '@golden/gdk-admin'
import { useCommonStyles, useDialogStyles } from '../../../utils/admin/StyleHook'
import useT from '../../../i18ns/admin/useT'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { DialogTitle } from '@material-ui/core'
import { usePageFlow } from '../../../utils/default/PageFlowHook'
import LoadingAndErrorFrame from '../../default/frames/LoadingAndErrorFrame'
import { useGetDataByPayload } from '../../../utils/default/ComplexFlowHook'
import { formatDateTime } from '../../../utils/default/TableHelper'
import { Link } from 'react-router-dom'
import allRoute from '../route/route'
import { endOfDay, getTime, startOfDay, subMonths } from 'date-fns'

interface GoubaoDetailPropTypes {
  open: boolean
  id: number
  onOk: () => void
}

const GoubaoDetailDialog: React.FC<GoubaoDetailPropTypes> = (props) => {
  const { open, id, onOk } = props
  const dialogClasses = useDialogStyles()
  const commonClasses = useCommonStyles()
  const { t } = useT()
  const gdk = useGDK()
  const pageFlow = usePageFlow()

  const [detail, setDetail] = useState<AdminPlayerGoubao>({
    account: '',
    goubao_nickname: '',
    goubao_real_name: '',
    goubao_registered_at: new Date(),
    has_bought: false,
    last_bought_at: null
  })

  useGetDataByPayload({
    canLoadData: open,
    payload: id,
    gdkFunc: (payload) => gdk.player.getPlayerGoubao(payload),
    gdkFuncDependencies: [gdk],
    onBeforeFetch: pageFlow.setLoadingStart,
    onSuccess: (res: AdminPlayerGoubao) => {
      setDetail(res)
      pageFlow.setContentShow()
    },
    onError: pageFlow.setGDKError
  })

  return (
    <Dialog open={open}>
    <DialogTitle className={commonClasses.dialogPinkHeader}>
      {t('common.goubaoDetail')}
    </DialogTitle>
    <LoadingAndErrorFrame { ...pageFlow.status }>
      <DialogContent dividers className={commonClasses.pre} style={{ lineHeight: 2.5 }}>
        { `${t('common.accountWithColon')}${detail.account}\n` }
        { `${t('common.goubaoNicknameWithColon')}${detail.goubao_nickname}\n` }
        { `${t('common.goubaoBankNameWithColon')}${detail.goubao_real_name || t('userStatus.noBindCard')}\n` }
        { `${t('common.goubaoRegisterAtWithColon')}${formatDateTime(detail.goubao_registered_at)}\n` }
        { `${t('common.hasBoughtCoinWithColon')}${detail.has_bought ? t('common.yes') : t('common.no')}\n` }
        { `${t('common.goubaoLastDepositAtWithColon')}${detail.last_bought_at ? formatDateTime(detail.last_bought_at) : t('common.none')}\n` }
      </DialogContent>
    </LoadingAndErrorFrame>
    <DialogActions>
      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent="center"
      >
        <Button
          classes={{ root: dialogClasses.cancelButton }}
          component={Link}
          to={allRoute.playerOperationLogs.encodePath({ param: {}, search: { start_at: getTime(startOfDay(subMonths(new Date(), 3))), end_at: getTime(endOfDay(new Date())), account: detail.account, page: 1, category: OperationLogsCategoryType.CRYPTO_WALLET, method: OperationLogsCryptoWalletMethod.CRYPTO_WALLET } })}
        >
          {t('common.checkPlayerLog')}
        </Button>
        <Button onClick={onOk} classes={{ root: dialogClasses.okButton }}>
          {t('common.confirm')}
        </Button>
      </Grid>
    </DialogActions>
  </Dialog>
  )
}

interface PropTypes {
  id: number
}

const GoubaoDetailButton: React.FC<PropTypes> = (props) => {
  const { id } = props
  const [open, setOpen] = useState<boolean>(false)
  const commonClasses = useCommonStyles()
  const { t } = useT()

  return (
    <>
      <Button
        onClick={() => setOpen(true)}
        className={commonClasses.greyOutlineButton}
      >
        {t('common.betInfo')}
      </Button>
      <GoubaoDetailDialog open={open} id={id} onOk={() => setOpen(false)} />
    </>
  )
}

export default React.memo(GoubaoDetailButton)

import React, { useState, useMemo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { ThirdPartyDepositSlipSum } from '@golden/gdk-admin'
import Box from '@material-ui/core/Box'
import { searchToRequest } from './DepositThirdPartySlipForm'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import useT from '../../../i18ns/admin/useT'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { createTableData, formatCount, formatMoney } from '../../../utils/default/TableHelper'
import { usePageFlow } from '../../../utils/default/PageFlowHook'
import { useRequestFromSearch, useGetDataByPayload, useReload } from '../../../utils/default/ComplexFlowHook'
import LoadingAndErrorFrame from '../../default/frames/LoadingAndErrorFrame'
import ScrollablePaper from '../../default/present/ScrollablePaper'
import CoreTable from '../../default/present/CoreTable'
import RefreshCount from '../RefreshCount'
import PlayerTable from './DepositThirdPartySlipPlayerTable'
import AgentTable from './DepositThirdPartySlipAgentTable'
import Tabs from '../../../components/admin/Tabs'

const useStyles = makeStyles(() => ({
  noMargin: {
    margin: '0px auto'
  },
  usdtText: {
    color: '#aaaaaa',
    margin: '0px auto'
  }
}))

interface SumRowType {
  id: number
  type: string
  cash: string | React.ReactElement
  fee: string | React.ReactElement
  count: string
}

const DepositThirdPartySlipTable: React.FC = () => {
  const classes = useStyles()
  const commonClasses = useCommonStyles()
  const { t } = useT()
  const gdk = useGDK()
  const { reloadFlag, reload } = useReload()
  const [res, setRes] = useState<ThirdPartyDepositSlipSum>({
    rmb: {
      cash_sum: '0.0000',
      sum_fee: '0.0000',
      count: 0
    },
    usdt: {
      cash_sum: '0.0000',
      sum_fee: '0.0000',
      count: 0,
      cash_crypto_sum: '0.0000',
      sum_crypto_fee: '0.0000'
    },
    player_count: 0,
    agent_count: 0
  })
  const pageFlow = usePageFlow()
  const request = useRequestFromSearch({ searchToRequest })
  useGetDataByPayload({
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    payload: request!,
    gdkFunc: (payload) => gdk.deposit.getThirdPartyDepositSlipSummary(payload),
    gdkFuncDependencies: [gdk, reloadFlag],
    onBeforeFetch: pageFlow.setLoadingStart,
    onSuccess: (res: ThirdPartyDepositSlipSum) => {
      setRes(res)
      pageFlow.setContentShow()
    },
    onError: pageFlow.setGDKError,
    canLoadData: request !== undefined
  })
  const tableClasses = useMemo(() => ({
    head: commonClasses.pinkTableHead,
    row: commonClasses.tableRow,
    cellHead: commonClasses.tableCellHead,
    cellBody: commonClasses.pre
  }), [commonClasses])

  const sumRows: SumRowType[] = useMemo(() => {
    return [
      {
        id: 1,
        type: 'RMB',
        count: formatCount(res.rmb.count),
        cash: formatMoney(res.rmb.cash_sum),
        fee: formatMoney(res.rmb.sum_fee)
      },
      {
        id: 2,
        type: 'USDT',
        count: formatCount(res.usdt.count),
        cash: (
          <React.Fragment>
            <p className={classes.noMargin}>{formatMoney(res.usdt.cash_sum)}</p>
            <p className={classes.usdtText}>{formatMoney(res.usdt.cash_crypto_sum)} USDT</p>
          </React.Fragment>
        ),
        fee: (
          <React.Fragment>
            <p className={classes.noMargin}>{formatMoney(res.usdt.sum_fee)}</p>
          </React.Fragment>
        )
      }
    ] as SumRowType[]
  }, [res.rmb, res.usdt, classes.noMargin, classes.usdtText])

  const sumData = useMemo(() => {
    return createTableData<SumRowType>(
      {
        id: {
          label: '',
          value: 'id'
        },
        type: {
          label: t('common.orderType'),
          value: 'type',
          align: 'center'
        },
        cash: {
          label: t('common.cash'),
          value: 'cash',
          align: 'center'
        },
        fee: {
          label: t('common.fee'),
          value: 'fee',
          align: 'center'
        },
        count: {
          label: t('common.amount'),
          value: 'count',
          align: 'center'
        }
      },
      [
        'type',
        'cash',
        'fee',
        'count'
      ],
      sumRows,
      'id'
    )
  }, [sumRows, t])

  const tabs = useMemo(() => ([
    { value: 'player', label: t('common.player') },
    { value: 'agent', label: `${t('common.agent')} (${res.agent_count})` }
  ]), [res.agent_count, t])
  const [tab, setTab] = useState('player')

  if (request === undefined) return null
  return (
    <React.Fragment>
      <Box paddingBottom={3}>
        <ScrollablePaper marginX={5}>
          <Box padding={4}>
            <LoadingAndErrorFrame { ...pageFlow.status }>
              <CoreTable
                classes={tableClasses}
                data={sumData}
                total={1}
              />
            </LoadingAndErrorFrame>
          </Box>
        </ScrollablePaper>
      </Box>
      <ScrollablePaper marginX={5}>
        <Box padding={4}>
          <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
            <Tabs
              value={tab}
              defaultValue={tab}
              onChange={(value) => {
                setTab(value.toString())
                reload()
              }}
              tabs={tabs}
            />
            <RefreshCount reload={reload} reloadFlag={reloadFlag}/>
          </Box>
          { tab === 'player' && <PlayerTable summaryReload={reload} summaryReloadFlag={reloadFlag} /> }
          { tab === 'agent' && <AgentTable summaryReload={reload} summaryReloadFlag={reloadFlag} /> }
        </Box>
      </ScrollablePaper>
    </React.Fragment>
  )
}

export default React.memo(DepositThirdPartySlipTable)

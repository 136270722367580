import React, { useCallback, useEffect, useState } from 'react'
import { useDebouncedCallback } from 'use-debounce'
import Box from '@material-ui/core/Box'
import MuiCheckbox from '@material-ui/core/Checkbox'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import useT from '../../../i18ns/admin/useT'
import useGDK from '../../../providers/admin/gdk/useGDK'

const icon = (<CheckBoxOutlineBlankIcon />)
const checkedIcon = (<CheckBoxIcon />)

const Checkbox: React.FC<{ selected: boolean, name: string }> = React.memo((props) => {
  const { selected, name } = props
  return (
    <Box paddingRight={2}>
      <MuiCheckbox
        color="primary"
        icon={icon}
        checkedIcon={checkedIcon}
        checked={selected}
      />
      {name}
    </Box>
  )
})

interface PropType {
  value: Array<{ name: string, value: number }>
  onChange: (value: Array<{ name: string, value: number }>) => void
}

const PlayerLayerAccountInput: React.FC<PropType> = (props) => {
  const { value, onChange } = props
  const [search, setSearch] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)
  const [playerOptions, setPlayerOptions] = useState<Array<{ name: string, value: number }>>([])
  const { t } = useT()
  const gdk = useGDK()
  const [handleInputChange] = useDebouncedCallback(useCallback((_: React.ChangeEvent<{}>, value: string) => {
    setSearch(value)
  }, []), 200)
  useEffect(() => {
    if (search !== '') {
      setLoading(true)
      const subscription = gdk.player.searchPlayerLayer(search).subscribe({
        next: (res) => {
          setLoading(false)
          setPlayerOptions(
            res.map((item) => {
              const lockText = item.is_locked ? `[${t('common.manual')}]` : ''
              return { name: `${item.account}[${item.layer}]${lockText}`, value: item.id }
            })
          )
        },
        error: () => {
          setLoading(false)
        }
      })
      return () => {
        subscription.unsubscribe()
        setLoading(false)
      }
    }
  }, [gdk, search, t])
  return (
    <Autocomplete<Array<{ name: string, value: number }>, boolean, boolean, boolean>
      multiple
      freeSolo
      disableCloseOnSelect
      loading={loading}
      options={playerOptions as any}
      onChange={useCallback((_, value) => {
        onChange(value)
      }, [onChange])}
      value={value}
      onInputChange={
        // eslint-disable-next-line react-hooks/exhaustive-deps
        useCallback(handleInputChange, [])
      }
      getOptionLabel={useCallback((item) => item.name, [])}
      getOptionSelected={useCallback((option, value) => option.name === value.name, [])}
      renderInput={(params) => (
        <TextField
          {...params}
          label={`${t('common.playerAccount')}*`}
          placeholder={t('common.inputWordAndSearch')}
        />
      )}
      renderOption={useCallback((option, { selected }) => (
        <Checkbox selected={selected} name={option.name} />
      ), [])}
    />
  )
}

export default React.memo(PlayerLayerAccountInput)

import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import Box from '@material-ui/core/Box'
import VisibilityIcon from '@material-ui/icons/Visibility'
import CancelIcon from '@material-ui/icons/Cancel'
import useT from '../../../i18ns/admin/useT'

const useStyles = makeStyles((theme) => ({
  statusIcon: {
    fontSize: theme.typography.h5.fontSize,
    marginRight: theme.spacing(1)
  },
  disableStatus: {
    color: '#6199e3',
    opacity: 0.5
  },
  enableStatus: {
    color: '#1fc485'
  }
}))

interface PropTypes {
  status: boolean
  onText?: string
  offText?: string
}

const TableStatus: React.FC<PropTypes> = (props) => {
  const { status, onText, offText } = props
  const classes = useStyles()
  const { t } = useT()
  return (
    <Box
      className={clsx({
        [classes.enableStatus]: status,
        [classes.disableStatus]: !status
      })}
      display="flex"
      flexDirection="row"
      alignItems="center"
      justifyContent="center"
    >
      {status ? (<VisibilityIcon className={classes.statusIcon} />) : (<CancelIcon className={classes.statusIcon} />)}
      {status ? onText ?? (t('common.toggleOn')) : offText ?? (t('common.toggleOff'))}
    </Box>
  )
}

export default React.memo(TableStatus)

import React, { useCallback } from 'react'
import { NewEmperorReq } from '@golden/gdk-admin'
import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import EditAccountEmperorForm, {
  EditAccountEmperorFormType,
  initialForm
} from '../../../components/admin/account/EditAccountEmperorForm'
import { useRedirectHandleBack, useDialogHandleSubmit } from '../../../utils/default/ComplexFlowHook'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import { createGlobalDialogConfig } from '../../../utils/default/DialogHelper'
import { Path } from '../../../components/admin/route/route'
import useT from '../../../i18ns/admin/useT'
import useGlobalDialog from '../../../providers/admin/dialog/useGlobalDialog'

export const formToRequest = (form: EditAccountEmperorFormType): NewEmperorReq => ({
  account: form.account,
  nickname: form.nickname,
  password: form.password,
  password_confirmation: form.passwordConfirmation,
  is_loginable: form.isLoginable === 1,
  percentage: Number(form.percentage),
  memo: form.memo
})

const defaultForm = initialForm()

const AccountEmperorCreatePage: React.FC = () => {
  const classes = useCommonStyles()
  const { t } = useT()
  const gdk = useGDK()
  const globalDialog = useGlobalDialog()
  const [handleBack, handleDebouncedBack] = useRedirectHandleBack({ path: Path.ACCOUNT_EMPEROR })
  const { handleSubmit } = useDialogHandleSubmit({
    formToRequest,
    gdkFunc: (payload) => gdk.emperor.createEmperor(payload),
    gdkFuncDependencies: [gdk],
    dialogId: 'createEmperor',
    globalDialog,
    getChangeDialogConfig: useCallback((form: EditAccountEmperorFormType) => createGlobalDialogConfig({
      showIcon: false,
      message: (
        <React.Fragment>
          {t('dialog.confirmCreateEmperorAccount')}
          <span className={classes.purpleWord}>{form.account}</span>
        </React.Fragment>
      )
    }), [t, classes.purpleWord]),
    getSuccessDialogConfig: useCallback(() => createGlobalDialogConfig({
      showIcon: false,
      showCancel: false,
      message: t('dialog.createEmperorAccountSuccess')
    }), [t]),
    getFailDialogConfig: useCallback((error) => createGlobalDialogConfig({
      showIcon: true,
      showCancel: false,
      variant: 'error',
      message: error
    }), []),
    afterSuccessDialog: handleBack
  })
  return (
    <Box padding={5}>
      <Paper>
        <Box padding={4}>
          <Box
            paddingY={1.25}
            paddingX={2}
            className={classes.pinkTitleBar}
          >
            <Typography variant="h5">
              {t('common.createEmperorAccount')}
            </Typography>
          </Box>
          <Box paddingY={2}>
            <EditAccountEmperorForm
              requirePassword
              okText={t('common.confirmCreate')}
              passwordText={t('common.loginPassword')}
              onBack={handleDebouncedBack}
              onSubmit={handleSubmit}
              defaultValue={defaultForm}
              editSecurityPassword={false}
            />
          </Box>
        </Box>
      </Paper>
    </Box>
  )
}

export default React.memo(AccountEmperorCreatePage)

import { createTheme } from '@material-ui/core/styles'
import { goldmanSans } from '../default/CustomFont'
import { CustomTheme } from './CustomThemeType'

const theme = createTheme({
  palette: {
    primary: {
      light: '#4c4658',
      main: '#fbab93',
      dark: '#252525',
      contrastText: '#fff'
    },
    secondary: {
      light: '#fbab93',
      main: '#fb9678',
      dark: '#af6954',
      contrastText: '#fff'
    },
    background: {
      default: '#edf1f5'
    },
    text: {
      primary: '#808080'
    },
    divider: '#fff'
  },
  typography: {
    fontFamily: '"Goldman Sans", monospace',
    fontSize: 14,
    h1: {
      fontSize: '52px'
    },
    h2: {
      fontSize: '42px'
    },
    h3: {
      fontSize: '38px'
    },
    h4: {
      fontSize: '24px'
    },
    h5: {
      fontSize: '18px'
    },
    h6: {
      fontSize: '16px'
    },
    body1: {
      fontSize: '14px',
      lineHeight: '22px'
    },
    body2: {
      fontSize: '14px',
      letterSpacing: '0.5px'
    }
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': goldmanSans
      }
    }
  }
})

const customTheme: CustomTheme = Object.assign(theme, {
  custom: {
    palette: {
      primary: '#01c0c8',
      secondary: '#fb9678',
      button: {
        primary: 'linear-gradient(to right, #da8cff, #9a55ff)',
        pink: 'linear-gradient(to right, #ffbf96, #fe7096)',
        blue: 'linear-gradient(to right, #90caf9, #047edf)',
        green: 'linear-gradient(to right, #84d9d2, #07cdae)',
        yellow: 'linear-gradient(to right, #FFD9B6, #FF8E25)',
        black: 'linear-gradient(to right, #5e7188, #3e4b5b)',
        blueGreen: 'linear-gradient(to right, #7edde1, #01c0c8)',
        grey: '#b4b4b4',
        disabled: '#f8f9fa'
      },
      buttonText: {
        pink: '#fe7c96',
        blue: '#1d8ce3',
        green: '#19cfb3',
        yellow: '#FF8E25',
        black: '#979797',
        purple: '#8f3cff'
      },
      background: {
        navigation: '#01c0c8',
        appBar: '#b4b4b4'
      },
      text: {
        navigation: '#fff',
        title: '#363636',
        golden: '#b79657',
        dialog: '#363636'
      },
      blue: {
        main: '#1976d2'
      },
      purple: {
        main: '#a15bff'
      },
      opacity: {
        hover: 0.6
      }
    },
    typography: {
      dialog: 16
    },
    shape: {
      drawerWidth: 282,
      closeDrawerWidth: 88,
      logoSize: 20,
      memoWidth: 76
    }
  }
})

export default customTheme

import React, { useCallback, useMemo } from 'react'
import { Link } from 'react-router-dom'
import { AnnouncementStatusType } from '@golden/gdk-admin'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import DropDown from '../../default/form/DropDown'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import allRoute from '../route/route'
import announcementStatusName from '../../../constants/admin/announcementStatusName'
import useT from '../../../i18ns/admin/useT'
import { useChecker } from '../../../utils/admin/AdminRouteHook'
import { useRequestFromSearch, useChangeUrlSubmit } from '../../../utils/default/ComplexFlowHook'
import { searchToRequest, RequestType } from '../../../views/admin/forestage/AnnouncementPage'

const AnnouncementListToolbar: React.FC = () => {
  const request = useRequestFromSearch({ searchToRequest })
  const classes = useCommonStyles()
  const { t } = useT()
  const options = useMemo(() => {
    return [
      { name: t('common.allStatus'), value: 0 },
      ...([AnnouncementStatusType.OUT_DATE, AnnouncementStatusType.START, AnnouncementStatusType.STOP] as const)
        .map((key) => ({ name: t(announcementStatusName[key]), value: key }))
    ]
  }, [t])

  const handleSubmit = useChangeUrlSubmit<{ status: 0 | AnnouncementStatusType }, RequestType>({
    formToRequest: (form) => ({ status: form.status, page: 1 }),
    encodePath: allRoute.forestageAnnouncement.encodePath,
    toAddNowTimestamp: true
  })

  const writable = useChecker()

  return (
    <Grid container direction="row" justify="flex-end" spacing={2}>
      <Grid item xs={6} md={2}>
        <DropDown
          className={classes.outlineDropDown}
          fullWidth
          variant="outlined"
          label=""
          value={request?.status ?? 0}
          onChange={useCallback((event) => {
            const status = event.target.value as number
            handleSubmit({ status })
          }, [handleSubmit])}
          options={options}
        />
      </Grid>
      {writable && (
        <Grid item>
          <Button
            component={Link}
            to={allRoute.forestageAnnouncementCreate.encodePath({ search: { ...request }, param: {} })}
            className={classes.purpleGradualButton}
            classes={{ disabled: classes.disabledButton }}
          >
            <Box paddingX={2.5} paddingY={0.5}>
              {t('common.createAnnouncement')}
            </Box>
          </Button>
        </Grid>
      )}
    </Grid>
  )
}

export default React.memo(AnnouncementListToolbar)

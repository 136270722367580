import { Locale } from 'date-fns'
import zhCNLocale from 'date-fns/locale/zh-CN'

enum Language {
  ZH_CN = 'zh-CN'
}

export const defaultLanguage = Language.ZH_CN

export const languagePosition: Language[] = [
  Language.ZH_CN
]

export const languageMap: { [key in Language]: string } = {
  [Language.ZH_CN]: '简体中文'
}

export const languageToDateLocale: { [key in Language]: Locale } = {
  [Language.ZH_CN]: zhCNLocale
}

export default Language

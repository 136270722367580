import React from 'react'
import { searchToRequest } from './ActivityRecordForm'
import { useRequestFromSearch } from '../../../utils/default/ComplexFlowHook'
import ActivityRecordOverviewTable from './ActivityRecordOverviewTable'

const ActivityRecordTable: React.FC = () => {
  const request = useRequestFromSearch({ searchToRequest })
  if (request === undefined) return null
  return (
    <React.Fragment>
      <ActivityRecordOverviewTable />
    </React.Fragment>
  )
}

export default React.memo(ActivityRecordTable)

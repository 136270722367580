import React, { createContext, useMemo } from 'react'
import { GDKError, GreetingReq } from '@golden/gdk-admin'
import MuiTextField, { TextFieldProps } from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import MuiButton from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import FormSubmitButton from '../../default/form/FormSubmitButton'
import FormField from '../../default/form/FormField'
import useT from '../../../i18ns/admin/useT'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import { createDefaultFormState, ValueGetter, FormValidation } from '../../../utils/default/FormHook'
import { createValidateNotEmpty, createValidateRange } from '../../../utils/default/Validator'
import { InitialFormFunc, useGDKFuncHandleSubmit } from '../../../utils/default/ComplexFlowHook'
import FormStateProvider from '../../default/form/FormStateProvider'
import CustomThemeType from '../../../themes/admin/CustomThemeType'
import { getValueFromChangeEvent, getValueFromValue } from '../../../utils/default/FormHelper'
import { createGlobalDialogConfig } from '../../../utils/default/DialogHelper'
import useGlobalDialog from '../../../providers/admin/dialog/useGlobalDialog'
import RequiredText from '../../default/form/RequiredText'
import { useDialogFlow } from '../../../utils/default/DialogHook'

export type ActionType = 'CREATE' | 'UPDATE' | 'DELETE'

export interface GreetingFormType {
  id: number
  title: string
  content: string
  isActive: boolean
}

interface PropTypes {
  action: ActionType
  defaultValue: GreetingFormType
  onClose: () => void
  reload: () => void
}

export const initialForm: InitialFormFunc<GreetingFormType> = (form) => ({
  id: 0,
  title: '',
  content: '',
  isActive: true,
  ...form
})

export const formToRequest = (data: GreetingFormType): GreetingReq => ({
  title: data.title,
  content: data.content,
  is_active: data.isActive
})

const getValueFromEvent: ValueGetter<GreetingFormType> = {
  id: getValueFromValue,
  title: getValueFromChangeEvent,
  content: getValueFromChangeEvent,
  isActive: getValueFromValue
}

const TextField = React.memo(MuiTextField)
const Button = React.memo(MuiButton)

const useStyles = makeStyles((theme: CustomThemeType) => ({
  actions: {
    paddingTop: theme.spacing(8)
  },
  margin: {
    paddingLeft: theme.spacing(2)
  },
  title: {
    color: theme.palette.common.black
  }
}))

const formContext = createContext(createDefaultFormState(initialForm()))

const GreetingForm: React.FC<PropTypes> = (props) => {
  const { action, defaultValue, onClose, reload } = props
  const gdk = useGDK()
  const globalDialog = useGlobalDialog()
  const { t } = useT()
  const classes = useStyles()
  const commonClasses = useCommonStyles()
  const getDialogSuccessMsg = () => {
    switch (action) {
      case 'CREATE':
        return t('dialog.createGreetingSuccess')
      case 'UPDATE':
        return t('dialog.updateGreetingSuccess')
      case 'DELETE':
        return t('dialog.deleteGreetingSuccess')
    }
  }
  const getGDKFunc = (payload: GreetingReq) => {
    switch (action) {
      case 'CREATE':
        return gdk.greeting.createGreeting(payload)
      case 'UPDATE':
        return gdk.greeting.updateGreeting(defaultValue.id, payload)
      case 'DELETE':
        return gdk.greeting.deleteGreeting(defaultValue.id)
    }
  }

  const validation: FormValidation<GreetingFormType> = useMemo(() => {
    return {
      id: [],
      title: [
        {
          func: createValidateNotEmpty<GreetingFormType>('title', t),
          when: ['change', 'beforeClickSubmit']
        },
        {
          func: createValidateRange<GreetingFormType>(
            'title', (value) => (value as string).length, 'lessEqual', 20, t('error.mustLessEqualTwentyWords')
          ),
          when: ['change', 'beforeClickSubmit']
        }
      ],
      content: [
        {
          func: createValidateNotEmpty<GreetingFormType>('content', t),
          when: ['change', 'beforeClickSubmit']
        },
        {
          func: createValidateRange<GreetingFormType>(
            'content', (value) => (value as string).length, 'lessEqual', 50, t('error.mustLessEqualFiftyWords')
          ),
          when: ['change', 'beforeClickSubmit']
        }
      ],
      isActive: []
    }
  }, [t])

  const { handleSubmit } = useGDKFuncHandleSubmit({
    formToRequest,
    gdkFunc: (payload) => getGDKFunc(payload),
    gdkFuncDependencies: [gdk, defaultValue.id],
    onSuccess: () => {
      globalDialog.setConfig(createGlobalDialogConfig({
        variant: 'success',
        message: getDialogSuccessMsg(),
        showCancel: false,
        showIcon: false
      }))
      globalDialog.setOpen({
        id: `${action}GreetingSuccess`,
        value: true,
        isOK: false
      })
      reload()
    },
    onError: (err: GDKError) => {
      globalDialog.setConfig(createGlobalDialogConfig({
        variant: 'error',
        message: err.message,
        showCancel: false
      }))
      globalDialog.setOpen({
        id: `${action}GreetingFailure`,
        value: true,
        isOK: false
      })
    }
  })

  useDialogFlow(globalDialog, `${action}GreetingSuccess`, () => {
    globalDialog.clearState()
    onClose()
  }, [onClose])

  return (
    <FormStateProvider<GreetingFormType>
      context={formContext}
      defaultValue={defaultValue}
      onSubmit={handleSubmit}
      getValueFromEvent={getValueFromEvent}
      validation={validation}
    >
      <FormField<GreetingFormType, TextFieldProps>
        component={TextField}
        context={formContext}
        name="title"
        label={t('common.title')}
        inputProps={{
          maxLength: 20,
          placeholder: t('placeholder.inputTitle')
        }}
        fullWidth
        required
      />
      <FormField<GreetingFormType, TextFieldProps>
        context={formContext}
        component={TextField}
        name="content"
        multiline
        fullWidth
        type="text"
        margin="normal"
        variant="outlined"
        helperText={t('helperText.greeting')}
        inputProps={{
          maxLength: 50,
          placeholder: `${t('placeholder.inputContent')}*`
        }}
        FormHelperTextProps={{ error: true }}
        rows={6}
      />
      <Box paddingTop={2}>
        <RequiredText />
      </Box>
      <Grid
        container
        alignItems="center"
        justify="flex-end"
        className={classes.actions}
      >
        <Button className={commonClasses.greyButton} onClick={onClose}>
          {t('common.cancel')}
        </Button>
        <Box className={classes.margin}>
          <FormSubmitButton
            component={Button}
            context={formContext}
            className={commonClasses.purpleGradualButton}
            type="submit"
          >
            { action === 'CREATE' && t('common.confirmCreate') }
            { action === 'UPDATE' && t('common.confirmUpdate') }
            { action === 'DELETE' && t('common.confirmDelete') }
          </FormSubmitButton>
        </Box>
      </Grid>
    </FormStateProvider>
  )
}

export default GreetingForm

import React, { useState, useMemo } from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import { InAndOutDepositAndWithdraw } from '@golden/gdk-admin'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { searchToRequest } from './AdminReportInAndOutForm'
import { AdminReportInAndOutReport } from '../../../views/admin/adminReport/AdminReportInAndOutPage'
import { useRequestFromSearch, useGetDataByPayload } from '../../../utils/default/ComplexFlowHook'
import useT from '../../../i18ns/admin/useT'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import ScrollablePaper from '../../default/present/ScrollablePaper'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { usePageFlow } from '../../../utils/default/PageFlowHook'
import LoadingAndErrorFrame from '../../default/frames/LoadingAndErrorFrame'
import CoreTable from '../../default/present/CoreTable'
import { formatCount, createTableData, formatMoney } from '../../../utils/default/TableHelper'
import depositMethodTypeName from '../../../constants/default/depositTypeName'
import depositTypeName from '../../../constants/admin/depositTypeName'
import withdrawTypeName from '../../../constants/admin/withdrawTypeName'
import FinishedOrderTip from '../FinishedOrderTip'

const useStyles = makeStyles(() => ({
  special: {
    color: '#01c0c8'
  }
}))

interface RowType {
  id: number
  name: string
  count: string
  cash: string
}

const AdminReportInAndOutDepositAndWithdraw: React.FC = () => {
  const [res, setRes] = useState<InAndOutDepositAndWithdraw>({
    deposits: [],
    withdraws: [],
    methods: []
  })
  const gdk = useGDK()
  const pageFlow = usePageFlow()
  const request = useRequestFromSearch({ searchToRequest })
  const hide = request?.reports.length !== 0 && !request?.reports.includes(AdminReportInAndOutReport.DEPOSIT_AND_WITHDRAW)
  const { t } = useT()
  const classes = useStyles()
  const commonClasses = useCommonStyles()
  const tableClasses = useMemo(() => {
    return {
      head: commonClasses.greyTableHead,
      cellHead: commonClasses.tableCellHead,
      row: commonClasses.tableRow
    }
  }, [commonClasses])
  useGetDataByPayload({
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    payload: request!,
    gdkFunc: (payload) => gdk.history.getInAndOutDepositAndWithdraw(payload),
    gdkFuncDependencies: [gdk],
    onBeforeFetch: pageFlow.setLoadingStart,
    onSuccess: (res: InAndOutDepositAndWithdraw) => {
      setRes(res)
      pageFlow.setContentShow()
    },
    onError: pageFlow.setGDKError,
    canLoadData: request !== undefined && !hide
  })
  const depositRows = useMemo(() => {
    return res.deposits.map((item, index) => ({
      id: index,
      name: t(depositTypeName[item.deposit_type]),
      count: formatCount(item.deposit_times),
      cash: formatMoney(item.cash_sum_deposit)
    } as RowType))
  }, [res.deposits, t])
  const depositData = useMemo(() => {
    return createTableData<RowType>(
      {
        id: {
          label: '',
          value: 'id'
        },
        name: {
          label: t('common.depositType'),
          value: 'name',
          align: 'center'
        },
        count: {
          label: t('common.totalDepositCount'),
          value: 'count',
          align: 'right'
        },
        cash: {
          label: t('common.totalDepositCash'),
          value: 'cash',
          align: 'right'
        }
      },
      [
        'name',
        'count',
        'cash'
      ],
      depositRows,
      'id'
    )
  }, [t, depositRows])
  const wayRows = useMemo(() => {
    return res.methods.map((item) => ({
      name: t(depositMethodTypeName[item.method]),
      count: formatCount(item.deposit_times),
      cash: formatMoney(item.cash_sum_deposit)
    } as RowType))
  }, [res.methods, t])
  const wayData = useMemo(() => {
    return createTableData<RowType>(
      {
        id: {
          label: '',
          value: 'id'
        },
        name: {
          label: t('common.depositWay2'),
          value: 'name',
          align: 'center'
        },
        count: {
          label: t('common.totalDepositCount'),
          value: 'count',
          align: 'right'
        },
        cash: {
          label: t('common.totalDepositCash'),
          value: 'cash',
          align: 'right'
        }
      },
      [
        'name',
        'count',
        'cash'
      ],
      wayRows,
      'id'
    )
  }, [t, wayRows])
  const withdrawRows = useMemo(() => {
    return res.withdraws.map((item) => ({
      name: t(withdrawTypeName[item.withdraw_type]),
      count: formatCount(item.withdraw_times),
      cash: formatMoney(item.cash_sum_withdraw)
    } as RowType))
  }, [res.withdraws, t])
  const withdrawData = useMemo(() => {
    return createTableData<RowType>(
      {
        id: {
          label: '',
          value: 'id'
        },
        name: {
          label: t('common.withdrawType'),
          value: 'name',
          align: 'center'
        },
        count: {
          label: t('common.totalWithdrawCount'),
          value: 'count',
          align: 'right'
        },
        cash: {
          label: t('common.totalWithdrawCash'),
          value: 'cash',
          align: 'right'
        }
      },
      [
        'name',
        'count',
        'cash'
      ],
      withdrawRows,
      'id'
    )
  }, [t, withdrawRows])
  if (hide) return null
  return (
    <ScrollablePaper marginX={5}>
      <Box padding={4}>
        <Box
          paddingY={1.25}
          paddingX={2}
          marginBottom={3}
          className={commonClasses.pinkTitleBar}
        >
          <Typography variant="h5">
            {t('common.depositWithdrawStatistic')}
          </Typography>
        </Box>
        <LoadingAndErrorFrame { ...pageFlow.status }>
          <Box paddingBottom={2}>
            <Typography className={clsx(classes.special, commonClasses.bold)} variant="h6">
              {t('common.totalDepositStatistic')}
            </Typography>
          </Box>
          <CoreTable
            classes={tableClasses}
            data={depositData}
            total={depositData.rows.length}
          />
          <Box paddingY={2}>
            <Typography className={commonClasses.bold} variant="h6">
              {t('common.depositWay2')}
            </Typography>
          </Box>
          <CoreTable
            classes={tableClasses}
            data={wayData}
            total={wayData.rows.length}
          />
          <Box paddingY={2}>
            <Typography className={clsx(classes.special, commonClasses.bold)} variant="h6">
              {t('common.totalWithdrawStatistic')}
            </Typography>
          </Box>
          <CoreTable
            classes={tableClasses}
            data={withdrawData}
            total={withdrawData.rows.length}
            tableFooterMemo={FinishedOrderTip}
          />
        </LoadingAndErrorFrame>
      </Box>
    </ScrollablePaper>
  )
}

export default React.memo(AdminReportInAndOutDepositAndWithdraw)

import React, { useContext, useCallback, useState } from 'react'
import { Link } from 'react-router-dom'
import SearchIcon from '@material-ui/icons/Search'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import useT from '../../../i18ns/admin/useT'
import { Path } from '../route/route'
import { useChecker } from '../../../utils/admin/AdminRouteHook'
import { RequestContext } from '../../../views/admin/finance/FinanceInternalWithdrawAccountPage'

const InternalWithdrawAccountToolbar: React.FC = () => {
  const [, setRequest] = useContext(RequestContext)
  const [bank, setBank] = useState<string>('')
  const classes = useCommonStyles()
  const { t } = useT()
  const writable = useChecker()
  return (
    <Grid container direction="row" alignItems="flex-end" spacing={2}>
      <Grid item>
        <Button
          className={classes.greyButton}
          component={Link}
          to={Path.FINANCE_INTERNAL_WITHDRAW}
        >
          <Box paddingY={0.5}>
            {t('common.back')}
          </Box>
        </Button>
      </Grid>
      <Box display="flex" flexGrow={1}></Box>
      <Grid item xs={6} md={3}>
        <Box display="flex" flexDirection="row" alignItems="flex-end">
          <Box display="flex" marginRight={1}>
            <SearchIcon />
          </Box>
          <Box display="flex" flexGrow={1}>
            <TextField
              value={bank}
              onChange={useCallback((event) => {
                setBank(event.target.value)
              }, [])}
              placeholder={t('placeholder.inputSearchBank')}
              fullWidth
            />
          </Box>
        </Box>
      </Grid>
      <Grid item>
        <Button
          onClick={useCallback(() => {
            setRequest((request) => ({ ...request, bank }))
          // eslint-disable-next-line react-hooks/exhaustive-deps
          }, [bank])}
          className={classes.pinkGradualButton}
        >
          <Box paddingY={0.5}>
            {t('common.search')}
          </Box>
        </Button>
      </Grid>
      {writable && (
        <Grid item>
          <Button
            component={Link}
            to={Path.FINANCE_INTERNAL_WITHDRAW_CREATE}
            className={classes.purpleGradualButton}
          >
            <Box paddingY={0.5}>
              {t('page.createInternalWithdraw')}
            </Box>
          </Button>
        </Grid>
      )}
    </Grid>
  )
}

export default React.memo(InternalWithdrawAccountToolbar)

import React, { useEffect, createContext, useMemo, useState, useCallback } from 'react'
import Box from '@material-ui/core/Box'
import Dialog from '@material-ui/core/Dialog'
import { Grid } from '@material-ui/core'
import ButtonBase from '../../../components/default/Button'
import SliderEffectButton from '../../../components/default/SliderEffectButton'
import FormField from '../../default/form/FormField'
import FormSubmitButton from '../../default/form/FormSubmitButton'
import MuiTextField, { TextFieldProps } from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import FormStateProvider from '../../default/form/FormStateProvider'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import useT from '../../../i18ns/admin/useT'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { ValueGetter, FormValidation, createDefaultFormState } from '../../../utils/default/FormHook'
import { getValueFromChangeEvent } from '../../../utils/default/FormHelper'
import { useGDKFuncHandleSubmit } from '../../../utils/default/ComplexFlowHook'
import { GameLoginHistoryDetailReq, GameLoginHistoryDetail } from '@golden/gdk-admin'

interface PropTypes {
  detailId: string
  isOpen: boolean
  handleClose: () => void
}

interface OtpPropTypes {
  detailId: string
  onOtpSuccess: (detailInfo: GameLoginHistoryDetail) => void
}

interface OtpFormType {
  otpCode: string
  detailId: string
}

const getValueFromEvent: ValueGetter<OtpFormType> = {
  otpCode: getValueFromChangeEvent,
  detailId: getValueFromChangeEvent
}

const defaultValue = {
  otpCode: '',
  detailId: ''
}

export const formToRequest = (data: OtpFormType): GameLoginHistoryDetailReq => ({
  code: data.otpCode,
  id: data.detailId
})

const OtpFormContext = createContext(createDefaultFormState(defaultValue))

const TextField = React.memo(MuiTextField)

const Button = React.memo(ButtonBase)

const OtpFormBase: React.FC<OtpPropTypes> = React.memo((props) => {
  const [authAttemptFailded, setAuthAttemptFailded] = useState<boolean>(false)
  const { detailId, onOtpSuccess } = props
  const { t } = useT()
  const gdk = useGDK()

  const { handleSubmit } = useGDKFuncHandleSubmit({
    formToRequest,
    gdkFunc: (payload) => gdk.history.getGameLoginHistoryDetail(detailId, payload),
    gdkFuncDependencies: [gdk, detailId],
    onSuccess: (res: GameLoginHistoryDetail) => {
      onOtpSuccess(res)
    },
    onError: () => {
      setAuthAttemptFailded(true)
    }
  })

  const validation: FormValidation<OtpFormType> = useMemo(() => {
    return {
      otpCode: [],
      detailId: []
    }
  }, [])

  return (
    <Grid
      container
      direction="column"
      justify="center"
      alignItems="center"
    >
      <Box padding={2}>
        <Typography variant="h6">
          {t('dialog.googleAuthRequiredWarning')}
        </Typography>
      </Box>
      <FormStateProvider<OtpFormType>
        context={OtpFormContext}
        defaultValue={defaultValue}
        onSubmit={handleSubmit}
        getValueFromEvent={getValueFromEvent}
        validation={validation}
      >
        <Box width="lg" paddingX={2}>
          <Box paddingBottom={2}>
            <FormField<OtpFormType, TextFieldProps>
              component={TextField}
              context={OtpFormContext}
              name="otpCode"
              autoFocus
              fullWidth
              variant="outlined"
              placeholder={t('placeholder.inputGoogleCode')}
            />
          </Box>
          <Box paddingBottom={2} color='red'>
            {authAttemptFailded && t('dialog.googleAuthFailWarning')}
          </Box>
          <Box textAlign="center">
            <FormSubmitButton
              component={Button}
              context={OtpFormContext}
              type="submit"
              variant="contained"
              color="primary"
            >
              {t('common.send')}
            </FormSubmitButton>
          </Box>
        </Box>
      </FormStateProvider>
    </Grid>
  )
})

const PlayerGameLoginHistoryDialog: React.FC<PropTypes> = (props) => {
  const { isOpen, detailId, handleClose } = props
  const { t } = useT()
  const commonClasses = useCommonStyles()
  const [optValidated, setOtpValidate] = useState<boolean>(false)
  const [detailInfo, setDetailInfo] = useState<GameLoginHistoryDetail>(({ id: '', login_url: '', response: '' }))

  useEffect(() => {
    if (isOpen) {
      setOtpValidate(false)
    }
  }, [isOpen])

  const onOtpSuccess = useCallback((detailInfo: GameLoginHistoryDetail) => {
    setOtpValidate(true)
    setDetailInfo(detailInfo)
  }, [])

  const handleCopyLoginUrl = async () => {
    try {
      await navigator.clipboard.writeText(detailInfo.login_url)
    } catch (err) {
      console.error('Failed to copy: ', err)
    }
  }

  return (
    <Dialog maxWidth={optValidated ? 'md' : 'xs'} open={isOpen} fullWidth>
      <Box padding={2} className={commonClasses.dialogPinkHeader}>
        {t('common.detailInformation')}
      </Box>
      <Box paddingBottom={2} overflow="auto">
        {optValidated
          ? (
          <Box
            padding={4}
            textAlign='left'
            justifyContent='center'
          >
            <Box maxWidth={1} paddingBottom={2}>
              <Typography variant="h6">
                {t('common.loginUrl')}
              </Typography>
              <Box padding={2}>
                <Box paddingBottom={2} marginBottom={2} overflow="auto">
                  {detailInfo.login_url.length ? detailInfo.login_url : '-'}
                </Box>
                {(detailInfo.login_url.length !== 0) && <Box>
                  <SliderEffectButton defaultText={t('common.oneClickCopy')} onClickText={t('common.oneClickCopyDone')} onClickFunc={handleCopyLoginUrl}/>
                </Box>}
              </Box>
            </Box>
            <Box maxWidth={1}>
              <Typography variant="h6">
                {t('common.thirdPartyResponse')}
              </Typography>
              <Box padding={2} overflow="auto">
                {detailInfo.response}
              </Box>
            </Box>
          </Box>
            )
          : (
          <OtpFormBase detailId={detailId} onOtpSuccess={onOtpSuccess}/>
            )}
      </Box>
      <Box textAlign="center" paddingX={2} paddingBottom={2}>
        <Button
          onClick={handleClose}
          variant="contained"
          color="default"
        >
          {t('common.close')}
        </Button>
      </Box>
    </Dialog>
  )
}

export default PlayerGameLoginHistoryDialog

import React, { useMemo, useState, useCallback } from 'react'
import { isEqual, lightFormat } from 'date-fns'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import { FrequencyType, CompanyStatisticQuery, CompanyStatistic } from '@golden/gdk-admin'
import useT from '../../../i18ns/admin/useT'
import ScrollablePaper from '../../default/present/ScrollablePaper'
import LineChart, { PropTypes as LineChartProps } from '../../default/present/LineChart'
import { getDayArray, getWeekArray, getMonthArray } from '../../../utils/default/TimeHelper'
import NumberInput from '../../default/form/NumberInput'
import OnOffCheckbox from '../../default/form/OnOffCheckbox'
import { useDebouncedCallback } from 'use-debounce/lib'

interface PropTypes {
  request: CompanyStatisticQuery
  result: CompanyStatistic
}

interface ChartType {
  date: string
  register?: number
  bet?: number
  deposit?: number
  online?: number
}

interface FormType {
  interval: string
  showRegister: boolean
  showBet: boolean
  showDeposit: boolean
  showOnline: boolean
}

const AdminReportCompanyStatisticCount: React.FC<PropTypes> = (props) => {
  const { request, result } = props
  const { t } = useT()
  const classes = useCommonStyles()
  const [form, setForm] = useState<FormType>({
    interval: '500',
    showRegister: true,
    showBet: true,
    showDeposit: true,
    showOnline: true
  })
  const data: ChartType[] = useMemo(() => {
    if (!request) return []
    const mappingData = (date: Date, formatDateString: string) => {
      const target = result.people.find((item) => isEqual(item.date, date))
      const displayTime = lightFormat(date, formatDateString)
      if (target) {
        return {
          date: displayTime,
          register: target.registered_user || 0,
          bet: target.bet_people_count || 0,
          deposit: target.deposit_people_count || 0,
          online: target.online_people_count || 0
        }
      }
      return { date: displayTime, register: 0, bet: 0, deposit: 0, online: 0 }
    }
    if (request.period === FrequencyType.DAY) {
      return getDayArray(new Date(request.start_at), new Date(request.end_at))
        .map((item) => mappingData(item, 'MM/dd'))
    }
    if (request.period === FrequencyType.WEEK) {
      return getWeekArray(new Date(request.start_at), new Date(request.end_at))
        .map((item) => mappingData(item, 'MM/dd'))
    }
    if (request.period === FrequencyType.MONTH) {
      return getMonthArray(new Date(request.start_at), new Date(request.end_at))
        .map((item) => mappingData(item, 'yyyy/MM'))
    }
    return []
  }, [request, result.people])
  const lines = useMemo(() => {
    const lines = []
    if (form.showRegister) lines.push({ name: t('common.registerCount'), dataKey: 'register', stroke: '#6236ff' } as LineChartProps<ChartType>['lines'][0])
    if (form.showBet) lines.push({ name: t('common.betPeople'), dataKey: 'bet', stroke: '#fb9678' } as LineChartProps<ChartType>['lines'][0])
    if (form.showDeposit) lines.push({ name: t('common.depositPeople'), dataKey: 'deposit', stroke: '#43c4f3' } as LineChartProps<ChartType>['lines'][0])
    if (form.showOnline) lines.push({ name: t('common.onlinePeople'), dataKey: 'online', stroke: '#6dd400' } as LineChartProps<ChartType>['lines'][0])
    return lines
  }, [form, t])
  return (
    <ScrollablePaper marginX={5}>
      <Box padding={4}>
        <Box
          paddingY={1.25}
          paddingX={2}
          className={classes.pinkTitleBar}
        >
          <Typography variant="h5">
            {t('common.companyCount')}
          </Typography>
        </Box>
        <Box paddingTop={2} display="flex" alignItems="center" flexDirection="row">
          <Box display="flex" marginRight={2}>
            <NumberInput
              label={t('common.peopleCountInterval')}
              value={form.interval}
              onChange={useDebouncedCallback(useCallback((event) => {
                const value = event.target.value
                setForm((form) => ({ ...form, interval: value }))
              }, []), 200)[0]}
              helperText={t('helperText.interval')}
              FormHelperTextProps={{ error: true }}
              inputProps={{
                decimalScale: 0,
                fixedDecimalScale: true,
                allowNegative: false,
                maxLength: 6
              }}
            />
          </Box>
          <OnOffCheckbox
            label={t('common.registerCount')}
            value={form.showRegister}
            onChange={useCallback((event) => {
              const value = event.target.checked
              setForm((form) => ({ ...form, showRegister: value }))
            }, [])}
          />
          <OnOffCheckbox
            label={t('common.betPeople')}
            value={form.showBet}
            onChange={useCallback((event) => {
              const value = event.target.checked
              setForm((form) => ({ ...form, showBet: value }))
            }, [])}
          />
          <OnOffCheckbox
            label={t('common.depositPeople')}
            value={form.showDeposit}
            onChange={useCallback((event) => {
              const value = event.target.checked
              setForm((form) => ({ ...form, showDeposit: value }))
            }, [])}
          />
          <OnOffCheckbox
            label={t('common.onlinePeople')}
            value={form.showOnline}
            onChange={useCallback((event) => {
              const value = event.target.checked
              setForm((form) => ({ ...form, showOnline: value }))
            }, [])}
          />
        </Box>
        <Box paddingY={2}>
          <LineChart<ChartType>
            height={600}
            chartMarginRight={48}
            interval={Number(form.interval)}
            data={data}
            dataKey="date"
            xLabel={t('common.date')}
            yLabel={t('common.peopleCount')}
            lines={lines}
          />
        </Box>
      </Box>
    </ScrollablePaper>
  )
}

export default React.memo(AdminReportCompanyStatisticCount)

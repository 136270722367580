import React, { useState, useCallback } from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import InputLabel from '@material-ui/core/InputLabel'
import Switch from '@material-ui/core/Switch'
import Typography from '@material-ui/core/Typography'
import useT from '../../../i18ns/admin/useT'
import PlayerInputButton from './PlayerInputButton'
import { useInternValue } from '../../../utils/default/FormHook'
import useGDK from '../../../providers/admin/gdk/useGDK'
import CustomThemeType from '../../../themes/admin/CustomThemeType'
import { FeatureFlag } from '@golden/gdk-admin'
import featureFlagName from '../../../constants/default/featureFlagName'
interface PropTypes {
  id: number
  flag: {
    slug: FeatureFlag
    is_enabled: boolean
  }
  disabled?: boolean
  reload: () => void
}

const useStyles = makeStyles((theme: CustomThemeType) => ({
  label: {
    color: theme.palette.primary.main,
    transform: 'scale(0.75)',
    '&.disabled': {
      color: theme.palette.text.disabled
    }
  },
  disabled: {
    color: theme.palette.text.disabled
  }
}))

const PlayerFeatureFlagSwitch: React.FC<PropTypes> = (props) => {
  const { id, flag, reload, disabled: buttonDisabled } = props
  const gdk = useGDK()
  const [value, setValue] = useInternValue<boolean>(false, flag.is_enabled)
  const [disabled, setDisabled] = useState<boolean>(true)
  const classes = useStyles()

  const { t } = useT()
  const handleUpdate = useCallback(() => setDisabled(false), [])
  const handleSave = useCallback(() => {
    gdk.featureFlag.updatePlayerFeauteFlag(id, [{ ...flag, is_enabled: value }]).subscribe(() => {
      setDisabled(true)
      reload()
    }
    )
  },
  [gdk, id, reload, flag, value]
  )

  return (
    <>
      <Box marginBottom={1}>
        <InputLabel className={clsx(classes.label, disabled && classes.disabled)} >{ t(featureFlagName[flag.slug]) }</InputLabel>
      </Box>
      <Box display='flex' alignItems='center' justifyContent='space-between'>
        <Box display='flex' alignItems='center'>
          <Switch
            disabled={disabled}
            checked={value}
            size='small'
            onChange={useCallback((event) => setValue(event.target.checked), [setValue])}
          />
          <Typography className={clsx(disabled && classes.disabled)}>{ value ? t('common.open') : t('common.close') }</Typography>
        </Box>
        <Box marginBottom={-2}>
          <PlayerInputButton
            title={disabled ? t('common.update') : t('common.save')}
            onClick={disabled ? handleUpdate : handleSave}
            disabled={buttonDisabled}
          />
        </Box>
      </Box>
    </>
  )
}

export default React.memo(PlayerFeatureFlagSwitch)

import React, { ChangeEvent } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Tabs, { TabsProps } from '@material-ui/core/Tabs'
import Tab, { TabProps } from '@material-ui/core/Tab'

const useStyles = makeStyles((theme) => ({
  tabline: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white
  },
  tabTextColorInherit: {
    opacity: 1
  },
  tabSelected: {
    color: theme.palette.secondary.main
  }
}))

interface PropTypes {
  value: number | string
  onChange: (event: ChangeEvent<{}>, newValue: number | string) => void
  tabs: Array<{ label: string, value: number | string }>
  TabsProps?: TabsProps
  TabProps?: TabProps
}

const Tabline: React.FC<PropTypes> = (props) => {
  const { value, onChange, tabs, TabsProps, TabProps } = props
  const classes = useStyles()
  return (
    <Tabs
      variant="scrollable"
      className={classes.tabline}
      value={value}
      {...TabsProps}
      onChange={onChange}
      {...TabsProps}
    >
      {tabs.map((tab) => (
        <Tab
          classes={{
            textColorInherit: classes.tabTextColorInherit,
            selected: classes.tabSelected
          }}
          label={tab.label}
          value={tab.value}
          key={tab.value}
          {...TabProps}
        />
      ))}
    </Tabs>
  )
}

export default React.memo(Tabline)

import { ActivityType } from '@golden/gdk-campaign'
import TKeyType from '../../i18ns/admin/TKeyType'

const activityTypeName: { [key in ActivityType]: TKeyType } = {
  [ActivityType.NEW_MEMBER_BONUS]: 'activity.activity',
  [ActivityType.RECOMMEND_FRIEND]: 'activity.activity',
  [ActivityType.RANK_BONUS]: 'activity.activity',
  [ActivityType.DAILY_SIGN_IN_BONUS]: 'activity.activity',
  [ActivityType.HIGH_CASH_BACK]: 'activity.activity',
  [ActivityType.LAYOUT]: 'activity.activity',
  [ActivityType.BANNER]: 'activity.banner',
  [ActivityType.UPGRADE_BONUS_GOLD]: '',
  [ActivityType.NEW_YEAR_2023]: 'activity.activity',
  [ActivityType.DRAGON_BOAT_FESTIVAL_2023]: 'activity.activity',
  [ActivityType.NEW_YEAR_2024]: 'activity.activity',
  [ActivityType.DEPOSIT_BONUS]: 'activity.activity',
  [ActivityType.UEFA_BUILDING_UP]: 'activity.activity',
  [ActivityType.UEFA_STARS]: 'activity.activity',
  [ActivityType.WEEKLY_BONUS_DRAW]: 'activity.activity',
  [ActivityType.DAILY_BONUS_DRAW]: 'activity.activity',
  [ActivityType.WEEKLY_BONUS_GOLD]: '',
  [ActivityType.BIRTHDAY_BONUS_GOLD]: ''
}

export default activityTypeName

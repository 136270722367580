import React, { useState, useEffect, ChangeEvent, FormEvent } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { GDKError, AdminModule } from '@golden/gdk-admin'
import { useDebouncedCallback } from 'use-debounce'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '../Button'
import { createGlobalDialogConfig, GlobalDialogType } from '../../../utils/default/DialogHelper'
import useT from '../../../i18ns/admin/useT'

const useStyles = makeStyles((theme) => ({
  textField: {
    minHeight: theme.spacing(10)
  }
}))

export interface MemoDialogPayload {
  showIconInSuccess: boolean
  open: boolean
  account: string
  nickname: string
  id: number
  type: 'user' | 'agent' | 'admin'
  memo: string
}

export const createDefaultMemoDialogPayload = (): MemoDialogPayload => ({
  showIconInSuccess: true,
  open: false,
  account: '',
  nickname: '',
  id: 0,
  type: 'agent',
  memo: ''
})

export type PropTypes = MemoDialogPayload & {
  gdk: AdminModule
  globalDialog: GlobalDialogType
  onOK: (memo: string) => void
  onCancel: () => void
}

const MemoDialog: React.FC<PropTypes> = (props) => {
  const { gdk, globalDialog, showIconInSuccess, open, account, nickname, id, type, memo, onOK, onCancel } = props
  const classes = useStyles()
  const { t } = useT()
  const [content, setContent] = useState<string>(memo)
  useEffect(() => {
    setContent(memo)
  }, [memo])
  const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => setContent(event.target.value)

  const [handleDebouncedSubmit] = useDebouncedCallback(() => {
    gdk.memo.postMemo({ [`${type}_id`]: id, content })
      .subscribe({
        next: () => {
          globalDialog.setConfig(createGlobalDialogConfig({
            showIcon: showIconInSuccess,
            variant: 'success',
            message: t('dialog.updateFinish'),
            showCancel: false
          }))
          globalDialog.setOpen({ id: 'memoSuccess', value: true, isOK: false })
          setContent(memo)
          onOK(content)
        },
        error: (err: GDKError) => {
          globalDialog.setConfig(createGlobalDialogConfig({
            variant: 'error',
            message: err.message,
            showCancel: false
          }))
          globalDialog.setOpen({ id: 'memoError', value: true, isOK: false })
        }
      })
  }, 200)

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    handleDebouncedSubmit()
  }

  return (
    <Dialog open={open} fullWidth maxWidth="sm">
      <Box padding={3}>
        <form onSubmit={handleSubmit}>
          <DialogContent>
            <Typography variant="h5">{t('common.memo')}</Typography>
            <Typography variant="body1" color="textSecondary">{`${account} [${nickname}]`}</Typography>
            <TextField
              autoFocus
              multiline
              fullWidth
              type="text"
              margin="normal"
              variant="outlined"
              InputProps={{ classes: { input: classes.textField } }}
              FormHelperTextProps={{ error: true }}
              defaultValue={content}
              onChange={handleChange}
            />
          </DialogContent>
          <Box paddingX={2}>
            <DialogActions>
              <Button
                color="secondary"
                variant="outlined"
                onClick={onCancel}
              >
                {t('common.cancel')}
              </Button>
              <Button
                color="secondary"
                variant="contained"
                type="submit"
              >
                {t('common.confirmUpdate')}
              </Button>
            </DialogActions>
          </Box>
        </form>
      </Box>
    </Dialog>
  )
}

export default React.memo(MemoDialog)

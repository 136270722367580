import React from 'react'
import { AgentType } from '@golden/gdk-admin'
import Grid from '@material-ui/core/Grid'
import OnOffCheckbox from '../../default/form/OnOffCheckbox'
import { useInternValue } from '../../../utils/default/FormHook'
import agentTypeName from '../../../constants/default/agentTypeName'
import useT from '../../../i18ns/admin/useT'

export interface PropTypes {
  required?: boolean
  defaultValue?: AgentType[]
  value?: AgentType[]
  onChange?: (value: AgentType[]) => void
  disabled?: boolean
}

const getAgents = (): AgentType[] => {
  return Object.keys(agentTypeName)
    .map((key) => Number(key))
    .filter((key) => key >= AgentType.EMPEROR && key <= AgentType.STAFF)
}

const sortAgentTypeFunc = (a: AgentType, b: AgentType) => (a - b)

const isChecked = (
  permissions: AgentType[],
  value: AgentType
): boolean => {
  return permissions.includes(value)
}

const checkOne = (
  permissions: AgentType[],
  value: AgentType
): AgentType[] => {
  return (isChecked(permissions, value)
    ? permissions.filter((item) => !(item === value))
    : permissions.concat([value])).sort(sortAgentTypeFunc)
}

const isAllChecked = (permissions: AgentType[]): boolean => {
  return permissions.length === getAgents().length
}

const checkAll = (permissions: AgentType[]):
AgentType[] => {
  const agents = getAgents()
  return isAllChecked(permissions) ? [] : agents
}

const BannerNotificationAgentCheckboxGroup: React.FC<PropTypes> = (props) => {
  const { defaultValue, value, onChange, disabled } = props
  const { t } = useT()
  const [internValue, setInternValue] = useInternValue(
    defaultValue ?? value ?? [] as AgentType[],
    value
  )
  return (
    <Grid container direction="column">
      <Grid item container direction="row">
        <Grid item xs={12} md={6} lg={2}>
          <OnOffCheckbox
            label={t('common.allAgent')}
            value={isAllChecked(internValue)}
            onChange={() => {
              setInternValue((value) => {
                const newValue = checkAll(value)
                if (onChange) onChange(newValue)
                return newValue
              })
            }}
            disabled={disabled}
          />
        </Grid>
      </Grid>
      <Grid item container direction="row">
        {
          getAgents()
            .map((key) => (
              <Grid key={key} item xs={12} md={2} lg={2}>
                <OnOffCheckbox
                  label={t(agentTypeName[key])}
                  value={isChecked(internValue, key)}
                  onChange={() => {
                    setInternValue((value) => {
                      const newValue = checkOne(value, key)
                      if (onChange) onChange(newValue)
                      return newValue
                    })
                  }}
                  disabled={disabled}
                />
              </Grid>
            ))
        }
      </Grid>
    </Grid>
  )
}

export default React.memo(BannerNotificationAgentCheckboxGroup)

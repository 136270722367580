import { SmsOperatorType } from '@golden/gdk-admin'
import TKeyType from '../../i18ns/admin/TKeyType'

const smsOperatorNname: { [key in SmsOperatorType]: TKeyType } = {
  [SmsOperatorType.MEMBER]: 'smsOperator.member',
  [SmsOperatorType.SYSTEM]: 'smsOperator.system',
  [SmsOperatorType.ADMIN]: 'smsOperator.admin'
}

export default smsOperatorNname

import React, { useCallback } from 'react'
import MuiAutocomplete, { AutocompleteProps } from '@material-ui/lab/Autocomplete'
import TextField, { TextFieldProps } from '@material-ui/core/TextField'

export type PropTypes = TextFieldProps & {
  autocompleteProps?: Omit<AutocompleteProps<any, boolean, boolean, boolean>, 'renderInput'>
  onChange?: (value: any) => void
}

const Autocomplete: React.FC<PropTypes> = (props) => {
  const { autocompleteProps, onChange, value, disabled, ...others } = props
  const handleInputChange = useCallback((_, value) => {
    if (onChange) onChange(value)
  }, [onChange])
  return (
    <MuiAutocomplete
      {...autocompleteProps}
      options={autocompleteProps?.options ?? []}
      disabled={disabled}
      onChange={handleInputChange}
      onInputChange={autocompleteProps?.freeSolo ? handleInputChange : undefined}
      value={value}
      renderInput={(params) => (
        <TextField
          {...others}
          {...params}
          inputProps={{
            ...params.inputProps,
            ...others.inputProps
          }}
        />
      )}
    />
  )
}

export default React.memo(Autocomplete)

import React from 'react'
import { Link } from 'react-router-dom'
import { SvgIconProps } from '@material-ui/core/SvgIcon'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import { TopNavKey } from './topNav'
import allRoute from '../route/route'
import RouteKey from '../route/RouteKey'

interface PropTypes {
  classes: Record<'icon', string>
  topNavKey: TopNavKey
  icon: React.ComponentType<SvgIconProps>
  title: string
  subItem: RouteKey
}

const PathTopNav: React.FC<PropTypes> = (props) => {
  const { classes, icon: Icon, title, subItem } = props
  return (
    <Tooltip title={title}>
      <IconButton
        component={Link}
        to={allRoute[subItem].path}
      >
        <Icon className={classes.icon} />
      </IconButton>
    </Tooltip>

  )
}

export default React.memo(PathTopNav, (prev, next) => {
  return prev.topNavKey === next.topNavKey
})

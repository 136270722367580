enum GroupKey {
  MY_DATA = 'myData',
  FORESTAGE = 'forestage',
  EXTERNAL_GAME = 'externalGame',
  ACCOUNT = 'account',
  ADMIN_REPORT = 'adminReport',
  PLAYER_REPORT = 'playerReport',
  FINANCE = 'finance',
  DEPOSIT = 'deposit',
  WITHDRAWAL = 'withdrawal',
  MANUAL = 'manual',
  CUSTOMER_SERVICE = 'customerService',
  SYSTEM = 'system',
  PLAYER = 'player',
  MANAGER = 'manager',
  JOURNAL = 'journal',
  ACTIVITY = 'activity'
}

export default GroupKey

import { DeviceType } from '@golden/gdk-admin'
import TKeyType from '../../i18ns/admin/TKeyType'

const deviceName: { [key in DeviceType]: TKeyType } = {
  [DeviceType.COMPUTER]: 'device.computer',
  [DeviceType.H5]: 'device.h5',
  [DeviceType.TV]: 'device.tv',
  [DeviceType.WATCH]: 'device.watch',
  [DeviceType.OTHER]: 'device.other',
  [DeviceType.APP]: 'device.app',
  [DeviceType.PWA]: 'device.pwa'
}

export default deviceName

import React, { createContext } from 'react'
import { ActiveForwarder, ActivityType, AdminMe, Bank, WithdrawSlipAccounting } from '@golden/gdk-admin'
import useGDK from './useGDK'
import GDKStateProvider from '../../default/GDKStateProvider'
import { map } from 'rxjs'
import useT from '../../../i18ns/admin/useT'
import { PlatformContext, getPlatformContextValue, platformContextDefaultValue } from '../../../utils/default/PlatformHelper'

export const AuthIsLoggedInContext = createContext<boolean>(true)
export const AuthIsWrongContext = createContext<boolean>(false)
export const AdminMeContext = createContext<AdminMe | null>(null)
export const SupportBankContext = createContext<Bank[]>([])
export const ForwarderContext = createContext<Record<string, ActiveForwarder>>({})
export const PlayerLayerContext = createContext<Array<{ id: number, name: string }>>([])
export const ActivityContext = createContext<Array<{ type: ActivityType, name: string }>>([])

const withdrawSlipAccountingDefaultValue = {
  is_first_withdraw: false,
  cash_last_balance: null,
  cash_accounting: null,
  cash_self_balance: null,
  difference: null,
  start_at: new Date(),
  end_at: new Date(),
  last_balances: [],
  self_balances: []
}

export const WithdrawSlipAccountingContext = createContext<WithdrawSlipAccounting>(withdrawSlipAccountingDefaultValue)

const GDKStoreProvider: React.FC = (props) => {
  const { children } = props
  const gdk = useGDK()
  const { t } = useT()

  return (
    <GDKStateProvider
      key="auth.isLoggedIn"
      context={AuthIsLoggedInContext}
      defaultValue={true}
      inputFactory={() => gdk.auth.isLoggedIn}
    >
      <GDKStateProvider
        key="auth.isWrong"
        context={AuthIsWrongContext}
        defaultValue={false}
        inputFactory={() => gdk.auth.isWrong}
      >
        <GDKStateProvider
          key="admin.me"
          context={AdminMeContext}
          defaultValue={null}
          inputFactory={() => gdk.admin.me}
        >
          <GDKStateProvider
            key="finance.supportBanks"
            context={SupportBankContext}
            defaultValue={[]}
            inputFactory={() => gdk.finance.supportBanks}
          >
            <GDKStateProvider
              key="finance.forwarders"
              context={ForwarderContext}
              defaultValue={{}}
              inputFactory={() => gdk.finance.forwarders}
            >
              <GDKStateProvider
                key="platform.platforms"
                context={PlatformContext}
                defaultValue={platformContextDefaultValue}
                inputFactory={() => gdk.platform.platforms.pipe(
                  map((res) => getPlatformContextValue(res, t))
                )}
              >
                <GDKStateProvider
                  key="player.layers"
                  context={PlayerLayerContext}
                  defaultValue={[]}
                  inputFactory={() => gdk.player.layers}
                >
                  <GDKStateProvider
                    key="activity.activities"
                    context={ActivityContext}
                    defaultValue={[]}
                    inputFactory={() => gdk.activity.activities}
                  >
                    <GDKStateProvider
                      key="admin.withdraw.balance"
                      context={WithdrawSlipAccountingContext}
                      defaultValue={withdrawSlipAccountingDefaultValue}
                      inputFactory={() => gdk.withdraw.withdrawSlipAccounting}
                    >
                      {children}
                    </GDKStateProvider>
                  </GDKStateProvider>
                </GDKStateProvider>
              </GDKStateProvider>
            </GDKStateProvider>
          </GDKStateProvider>
        </GDKStateProvider>
      </GDKStateProvider>
    </GDKStateProvider>
  )
}

export default GDKStoreProvider

import React from 'react'
import useT from '../../../../i18ns/admin/useT'
import DateTime from '../../../default/present/DateTime'
import { FeedbackHistory, PlayerFeedbackStatusType } from '@golden/gdk-admin'
import { addDays, lightFormat } from 'date-fns'
import { Grid } from '@material-ui/core'

export interface PropTypes {
  data: FeedbackHistory
}

const FeedbackUpdateAtGrid: React.FC<PropTypes> = React.memo((props) => {
  const { t } = useT()
  const { data } = props

  if ((data.search_status === PlayerFeedbackStatusType.PENDING)) {
    const expiredDate = addDays(data.date, 30)
    return (
      <Grid container direction="column" spacing={2}>
        <Grid item>
          { t('common.expiredAt', { time: lightFormat(expiredDate, 'MM-dd hh:mm') }) }
        </Grid>
      </Grid>
    )
  } else {
    return (
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <DateTime time={data.updated_at} />
        </Grid>
      </Grid>
    )
  }
})

export default React.memo(FeedbackUpdateAtGrid)

import React from 'react'
import Box from '@material-ui/core/Box'
import ActivityManagementForm from '../../../components/admin/activity/ActivityManagementForm'
import ActivityManagementTable from '../../../components/admin/activity/ActivityManagementTable'
import ActivityManagementListToolbar from '../../../components/admin/activity/ActivityManagementListToolbar'
import ScrollablePaper from '../../../components/default/present/ScrollablePaper'
import { useReload } from '../../../utils/default/ComplexFlowHook'

const ActivityManagementPage: React.FC = () => {
  const { reload, reloadFlag } = useReload()
  return (
    <Box paddingY={5}>
      <Box padding={5}>
        <ActivityManagementForm />
      </Box>
      <ScrollablePaper marginX={4}>
        <Box padding={4}>
          <ActivityManagementListToolbar reload={reload} />
          <Box paddingY={5}>
            <ActivityManagementTable reload={reload} reloadFlag={reloadFlag} />
          </Box>
        </Box>
      </ScrollablePaper>
    </Box>
  )
}

export default React.memo(ActivityManagementPage)

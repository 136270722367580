import React from 'react'
import Box from '@material-ui/core/Box'
import AdminReportNewPeopleStatisticForm from '../../../components/admin/adminReport/AdminReportNewPeopleStatisticForm'
import AdminReportNewPeopleStatisticTable from '../../../components/admin/adminReport/AdminReportNewPeopleStatisticTable'

const AdminReportNewPeopleStatisticPage: React.FC = () => {
  return (
    <Box paddingY={5}>
      <Box paddingX={5}>
        <AdminReportNewPeopleStatisticForm />
      </Box>
      <Box paddingTop={3}>
        <AdminReportNewPeopleStatisticTable />
      </Box>
    </Box>
  )
}

export default React.memo(AdminReportNewPeopleStatisticPage)

import React, { useState, useMemo, createContext, Dispatch, SetStateAction } from 'react'
import { PlayerReportWinlossAnalyze, GameCategoryType, PlayerReportWinlossAnalyzeReq, OrderStatusType, RecordSearchTimeType } from '@golden/gdk-admin'
import Box from '@material-ui/core/Box'
import Table from '@material-ui/core/Table'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableBody from '@material-ui/core/TableBody'
import Typography from '@material-ui/core/Typography'
import { searchToRequest } from './PlayerReportWinlossAnalyzeForm'
import CoreTable from '../../../default/present/CoreTable'
import PointsCell from '../../../default/present/PointsCell'
import LoadingAndErrorFrame from '../../../default/frames/LoadingAndErrorFrame'
import ScrollablePaper from '../../../default/present/ScrollablePaper'
import { useRequestFromSearch, useGetDataByPayload } from '../../../../utils/default/ComplexFlowHook'
import { usePageFlow } from '../../../../utils/default/PageFlowHook'
import useGDK from '../../../../providers/admin/gdk/useGDK'
import { createTableData, formatMoney } from '../../../../utils/default/TableHelper'
import useT from '../../../../i18ns/admin/useT'
import { useCommonStyles } from '../../../../utils/admin/StyleHook'
import StateProvider from '../../../../providers/default/StateProvider'
import { mergeArray, range } from '@golden/utils'
import { lightFormat, subMonths, startOfMonth, endOfMonth, endOfDay, getTime } from 'date-fns'
import { BcMath, RoundingMode } from '@golden/bcmath'
import useGDKStore from '../../../../providers/admin/gdk/useGDKStore'

interface PlayerReportWinlossAnalyzeDetailRow {
  id: GameCategoryType
  winlost: React.ReactNode
  effective_bet: string
  cash: string
  kill_rate: React.ReactNode
  category: React.ReactNode | string
}

interface PlayerReportWinlossAnalyzeRow {
  id: number
  cash: string
  effective_bet: string
  kill_rate: React.ReactNode | string
  month: React.ReactNode
  winlost: React.ReactNode
  categories: PlayerReportWinlossAnalyzeDetailRow[]
}

const StateContext = createContext<[
  number[],
  Dispatch<SetStateAction<number[]>>
]>([
  [],
  () => {}
])

function handleTextColor (value: string | number) {
  return +value >= 0 ? '#55B559' : '#F44336'
}

const PlayerReportWinlossAnalyzePanel: React.FC<{ row: Map<string, any> }> = React.memo((props) => {
  const { row } = props
  const commonClasses = useCommonStyles()
  return (
    <Table>
      <TableBody>
        {(row.get('categories') as PlayerReportWinlossAnalyzeDetailRow[])?.map((item) => (
          <TableRow key={item.id} className={commonClasses.tableRow}>
            <TableCell align="left" width={150}>
              <Box paddingLeft={2.5}>{item.category}</Box>
            </TableCell>
            <TableCell align="right" width={150}>{item.winlost}</TableCell>
            <TableCell align="right" width={150}>{item.effective_bet}</TableCell>
            <TableCell align="right" width={150}>{item.cash}</TableCell>
            <TableCell align="right" width={150}>{item.kill_rate}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
})

const BetHistoryStatisticTable: React.FC = React.memo(() => {
  const commonClasses = useCommonStyles()
  const { t } = useT()
  const gdk = useGDK()
  const categories = useGDKStore.platform.categories()

  const tableClasses = useMemo(() => ({
    head: commonClasses.pinkTableHead,
    row: commonClasses.tableRow,
    cellHead: commonClasses.tableCellHead
  }), [commonClasses])
  const [list, setList] = useState<PlayerReportWinlossAnalyze>({
    months: [],
    winlost: '',
    effective_bet: '',
    cash: '',
    kill_rate: ''
  })
  const pageFlow = usePageFlow()
  const payload = useRequestFromSearch({ searchToRequest })

  useGetDataByPayload({
    payload: payload as PlayerReportWinlossAnalyzeReq,
    gdkFunc: (payload) => gdk.history.getWinlossAnalyze(payload),
    gdkFuncDependencies: [gdk],
    onBeforeFetch: pageFlow.setLoadingStart,
    onSuccess: (res: PlayerReportWinlossAnalyze) => {
      setList(res)
      pageFlow.setContentShow()
    },
    onError: pageFlow.setGDKError,
    canLoadData: payload?.account !== undefined
  })

  const rows = useMemo(() => {
    const res: PlayerReportWinlossAnalyzeRow[] = range(0, 12)
      .reduce((prev: string[], curr) =>
        [...prev, lightFormat(subMonths(new Date(), curr), 'yyyy/MM')]
      , [])
      .map((month, index, months) => {
        const target = list?.months.find(item => item.month === month)
        if (target) {
          return {
            id: index,
            ...target,
            winlost: <PointsCell showSign={Number(target.winlost) < 0} points={target.winlost} />,
            effective_bet: formatMoney(target.effective_bet),
            cash: formatMoney(target.cash),
            kill_rate: target.kill_rate === null && target.cash !== null
              ? t('common.cantCalculate')
              : <span style={{
                color: handleTextColor(target.kill_rate)
              }}>
                {BcMath.base(Number(target.kill_rate)).round(2, RoundingMode.RoundDown).get() + '%'}
              </span>,
            month: (
            <StateContext.Consumer>
              {
                (([, setSelects]) => (
                  <span
                  className={commonClasses.anchor}
                    onClick={() => {
                      setSelects((selects) => {
                        if (selects.includes(index)) {
                          return selects.filter((id) => id !== index)
                        }
                        return selects.concat(index)
                      })
                    }}>
                    {target.month}
                  </span>
                ))
              }
            </StateContext.Consumer>
            ),
            categories: mergeArray(categories, target.categories, (category, item) => category.instance.id === item.category)
              .map(([category, item]) => ({
                id: item.category,
                category: item.effective_bet !== null && item.winlost !== null
                  ? <span
                    className={commonClasses.anchor}
                    onClick={() => {
                      const query = {
                        search_type: RecordSearchTimeType.SETTLED_AT,
                        start_at: getTime(startOfMonth(new Date(month))),
                        end_at: getTime(months[0] === month ? endOfDay(new Date()) : endOfMonth(new Date(month))),
                        order_status: `${OrderStatusType.WIN}, ${OrderStatusType.LOSE}`,
                        account: payload?.account,
                        game_category: item.category,
                        page: 1
                      }
                      const queryString = Object.entries(query).reduce((prev, [key, value]) => `${prev}${key}=${value ?? ''}&`, '?')
                      const aEl = document.createElement('a')
                      aEl.rel = 'noopener'
                      aEl.href = `/player-report/bet${queryString}`
                      aEl.target = '_blank'
                      aEl.click()
                      aEl.remove()
                    }}>
                    {category.instance.name}
                  </span>
                  : category.instance.name,
                winlost: item.winlost ? <PointsCell showSign={Number(item.winlost) < 0} points={item.winlost} /> : '-',
                effective_bet: item.effective_bet ? formatMoney(item.effective_bet) : '-',
                cash: item.cash ? formatMoney(item.cash) : '-',
                kill_rate: item.kill_rate !== null
                  ? <span style={{
                    color: handleTextColor(item.kill_rate)
                  }}>
                  {BcMath.base(Number(item.kill_rate)).round(2, RoundingMode.RoundDown).get() + '%'}
                </span>
                  : item.cash !== null ? t('common.cantCalculate') : '-'
              }), 'category')
          }
        } else {
          return {
            id: index,
            month,
            winlost: '-',
            effective_bet: '-',
            cash: '-',
            kill_rate: '-',
            categories: []
          }
        }
      })
    return [...res, {
      id: res.length,
      cash: formatMoney(list.cash),
      effective_bet: formatMoney(list.effective_bet),
      kill_rate: <span style={{
        color: handleTextColor(list.kill_rate)
      }}>
        {BcMath.base(Number(list.kill_rate)).round(2, RoundingMode.RoundDown).get() + '%'}
      </span>,
      month: t('common.winlossCount'),
      winlost: <PointsCell showSign points={list.winlost} />,
      categories: []
    }]
  }, [categories, commonClasses.anchor, list.cash, list.effective_bet, list.kill_rate, list?.months, list.winlost, payload?.account, t])

  const data = useMemo(() => {
    return createTableData<PlayerReportWinlossAnalyzeRow>(
      {
        id: {
          label: '',
          value: 'id'
        },
        month: {
          label: t('common.time'),
          value: 'month',
          align: 'left',
          width: 150
        },
        winlost: {
          label: t('common.totalWinAndLose'),
          value: 'winlost',
          align: 'right',
          width: 150
        },
        effective_bet: {
          label: t('common.effectiveBet'),
          value: 'effective_bet',
          align: 'right',
          width: 150
        },
        cash: {
          label: t('common.allBetCash'),
          value: 'cash',
          align: 'right',
          width: 150
        },
        kill_rate: {
          label: t('common.killRate'),
          value: 'kill_rate',
          align: 'right',
          width: 150
        },
        categories: {
          label: '',
          value: 'categories'
        }
      },
      [
        'month',
        'winlost',
        'effective_bet',
        'cash',
        'kill_rate'
      ],
      rows,
      'id'
    )
  }, [rows, t])

  if (payload?.account === undefined) return null
  return (
    <ScrollablePaper marginX={5}>
      <Box padding={4}>
        <LoadingAndErrorFrame {...pageFlow.status}>
          <Box paddingBottom={1} fontSize={22} fontWeight={700}>{t('common.winlossPastYear')}</Box>
          <Box paddingBottom={1}>
            <Typography color="error" className={commonClasses.pre}>{t('common.killRateTip')}</Typography>
          </Box>
          <StateProvider
            context={StateContext}
            defaultValue={[]}
          >
            <StateContext.Consumer>
              {([selects]) => (
                <CoreTable
                  classes={tableClasses}
                  data={data}
                  total={rows.length}
                  isSelected
                  selected={selects}
                  collapse={PlayerReportWinlossAnalyzePanel}
                />
              )}
            </StateContext.Consumer>
          </StateProvider>
        </LoadingAndErrorFrame>
      </Box>
    </ScrollablePaper>
  )
})

export default React.memo(BetHistoryStatisticTable)

import React, { createContext, useState, useMemo, useContext, Dispatch, SetStateAction } from 'react'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import MuiTextField, { TextFieldProps } from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import useT from '../../../i18ns/admin/useT'
import { AgentAccountType, AgentWalletsSummary } from '@golden/gdk-admin'
import { InitialFormFunc, useGetDataByPayload } from '../../../utils/default/ComplexFlowHook'
import { ValueGetter, createDefaultFormState, FormValidation } from '../../../utils/default/FormHook'
import { getValueFromChangeEvent, getCashInputProps, getCodeInputProps } from '../../../utils/default/FormHelper'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { usePageFlow } from '../../../utils/default/PageFlowHook'
import LoadingAndErrorFrame from '../../default/frames/LoadingAndErrorFrame'
import FormStateProvider from '../../default/form/FormStateProvider'
import { createValidateCash, createValidateNotEmpty } from '../../../utils/default/Validator'
import FormField from '../../default/form/FormField'
import NumberInput from '../../default/form/NumberInput'
import RequiredText from '../../default/form/RequiredText'
import FormSubmitButton from '../../default/form/FormSubmitButton'

export interface FormType {
  account: string
  amount: string
  memo: string
  code: string
}

const initialForm: InitialFormFunc<FormType> = (defaultForm) => ({
  account: '',
  amount: '',
  memo: '',
  code: '',
  ...defaultForm
})

export const formToRequest = (form: FormType) => ({
  account: form.account,
  amount: form.amount,
  code: form.code,
  memo: form.memo
})

const memoFormHelperTextProps = { error: true }

const getValueFromEvent: ValueGetter<FormType> = {
  account: getValueFromChangeEvent,
  amount: getValueFromChangeEvent,
  code: getValueFromChangeEvent,
  memo: getValueFromChangeEvent
}

const FormContext = createContext(createDefaultFormState(initialForm()))

const TextField = React.memo(MuiTextField)

const amountInputProps = {
  ...getCashInputProps(),
  decimalScale: 2,
  maxLength: undefined
}

const codeInputProps = getCodeInputProps()

interface PropTypes {
  handleSubmit: (form: FormType) => FormType
  handleCancel: () => void
  loading: boolean
  requireCode?: boolean
  amountText: string
  requestContext: React.Context<[string, Dispatch<SetStateAction<string>>]>
}

const AccountAgentWalletCreateForm: React.FC<PropTypes> = (props) => {
  const { handleSubmit, handleCancel, loading, requireCode, requestContext, amountText } = props

  const classes = useCommonStyles()
  const { t } = useT()
  const [account] = useContext(requestContext)
  const [report, setReport] = useState<AgentWalletsSummary | null>(null)

  const defaultForm = useMemo(() => initialForm({ account, ...report }), [account, report])
  const pageFlow = usePageFlow()
  const gdk = useGDK()
  const payload = useMemo(() => ({ account, account_type: AgentAccountType.EMPEROR }), [account])

  useGetDataByPayload({
    payload,
    gdkFunc: (payload) => gdk.agentWallet.getSummary(payload),
    gdkFuncDependencies: [gdk],
    onBeforeFetch: pageFlow.setLoadingStart,
    onSuccess: (res: AgentWalletsSummary) => {
      setReport(res)
      pageFlow.setContentShow()
    },
    onError: pageFlow.setGDKError,
    canLoadData: account !== ''
  })

  const validation = useMemo(() => {
    return {
      account: [],
      amount: [
        {
          func: createValidateNotEmpty('amount', t),
          when: ['change', 'beforeClickSubmit']
        },
        {
          func: createValidateCash('amount', t),
          when: ['change', 'beforeClickSubmit']
        }
      ],
      code: [
        {
          func: requireCode ? createValidateNotEmpty('code', t) : () => ({ isPass: true, stop: false, newError: { code: null } }),
          when: ['change', 'beforeClickSubmit']
        }
      ],
      memo: []
    } as FormValidation<FormType>
  }, [t, requireCode])
  const memoInputProps = useMemo(() => ({
    classes: { input: classes.memoField },
    inputProps: {
      placeholder: t('placeholder.inputMemo'),
      maxLength: 100
    }
  }), [classes.memoField, t])
  if (account === '') return null
  return (
    <FormStateProvider
      context={FormContext}
      defaultValue={defaultForm}
      onSubmit={handleSubmit}
      validation={validation}
      getValueFromEvent={getValueFromEvent}
    >
      <Paper>
        <Box padding={5}>
          <LoadingAndErrorFrame {...pageFlow.status}>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <Box
                  paddingY={1.25}
                  paddingX={2}
                  className={classes.pinkTitleBar}
                >
                  <Typography variant="h5">
                    {t('common.emperorInformation')}
                  </Typography>
                </Box>
              </Grid>
              {report
                ? (
                <React.Fragment>
                  <Grid item>
                    <Grid container direction="row" spacing={2}>
                      <Grid item xs={12} md={6} lg={3}>
                        <TextField
                          label={t('common.account')}
                          value={account}
                          disabled
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} md={6} lg={3}>
                        <FormField<FormType, TextFieldProps>
                          context={FormContext}
                          component={NumberInput}
                          name="amount"
                          label={amountText}
                          inputProps={amountInputProps}
                          fullWidth
                          required
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid container direction="row" spacing={2}>
                      <Grid item xs={12} lg={3} container alignItems="center">
                        <NumberInput
                          label={t('common.rechargeTotalAmount')}
                          value={report.recharge_amount}
                          inputProps={amountInputProps}
                          disabled
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} lg={3} container alignItems="center">
                        <Grid item xs={1}>
                          -
                        </Grid>
                        <Grid item xs={11}>
                          <NumberInput
                            label={t('common.revokeTotalAmount')}
                            value={report.revoke_amount}
                            inputProps={amountInputProps}
                            disabled
                            fullWidth
                          />
                        </Grid>
                      </Grid>
                      <Grid item xs={12} lg={3} container alignItems="center">
                        <Grid item xs={1}>
                          +
                        </Grid>
                        <Grid item xs={11}>
                          <NumberInput
                            label={t('common.depositPoint')}
                            value={report.deposit_amount}
                            inputProps={amountInputProps}
                            disabled
                            fullWidth
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid container direction="row" spacing={2}>
                      <Grid item xs={12} lg={3} container alignItems="center">
                        <Grid item xs={1}>
                          =
                        </Grid>
                        <Grid item xs={11}>
                          <NumberInput
                            label={t('common.accountBalance')}
                            value={report.balance}
                            inputProps={amountInputProps}
                            disabled
                            fullWidth
                          />
                        </Grid>
                      </Grid>
                      <Grid item xs={12} lg={3} container alignItems="center">
                        <Grid item xs={1}>
                          +
                        </Grid>
                        <Grid item xs={11}>
                          <NumberInput
                            label={t('common.distribeTotalAmount')}
                            value={report.distribute_amount}
                            inputProps={amountInputProps}
                            disabled
                            fullWidth
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Typography>{`${t('common.memo')}`}</Typography>
                    <FormField<FormType, TextFieldProps>
                      context={FormContext}
                      component={TextField}
                      name="memo"
                      multiline
                      fullWidth
                      type="text"
                      margin="normal"
                      variant="outlined"
                      InputProps={memoInputProps}
                      FormHelperTextProps={memoFormHelperTextProps}
                    />
                  </Grid>
                  {
                    requireCode
                      ? (
                      <Grid item>
                        <Grid container direction="row" spacing={2}>
                          <Grid item xs={12} md={6} lg={3}>
                            <FormField<FormType, TextFieldProps>
                              context={FormContext}
                              component={NumberInput}
                              name="code"
                              fullWidth
                              inputProps={codeInputProps}
                              label={t('common.googleCode')}
                              placeholder={t('placeholder.inputGoogleCode2')}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                        )
                      : null
                  }
                  <Grid item>
                    <RequiredText />
                  </Grid>
                </React.Fragment>
                  )
                : (
                <Box display="flex" justifyContent="center" padding={1} overflow="auto">
                  <Typography variant="h3">
                    {t('common.noData')}
                  </Typography>
                </Box>
                  )}
            </Grid>
          </LoadingAndErrorFrame>
        </Box>
      </Paper>
      <Box paddingTop={2}>
        <Grid container direction="row" justify="flex-end" spacing={2}>
          <Grid item>
            <Button
              onClick={handleCancel}
              className={classes.greyButton}
            >
              {t('common.cancel')}
            </Button>
          </Grid>
          <Grid item>
            <FormSubmitButton
              context={FormContext}
              component={Button}
              disabled={loading}
              type="submit"
              className={classes.purpleGradualButton}
            >
              {loading
                ? (
                <Box display="flex" alignItems="center" justifyContent="center">
                  <CircularProgress size={24} />
                </Box>
                  )
                : t('common.confirmCreate')}
            </FormSubmitButton>
          </Grid>
        </Grid>
      </Box>
    </FormStateProvider>
  )
}

export default React.memo(AccountAgentWalletCreateForm)

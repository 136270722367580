import React, { createContext, useCallback, useContext, useMemo } from 'react'
import { ActivityBannerReq } from '@golden/gdk-admin'
import Grid from '@material-ui/core/Grid'
import MuiButton from '@material-ui/core/Button'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Box from '@material-ui/core/Box'
import MuiTextField, { TextFieldProps } from '@material-ui/core/TextField'
import { makeStyles } from '@material-ui/core/styles'
import OnOffCheckbox, { PropTypes as OnOffCheckboxProps } from '../../default/form/OnOffCheckbox'
import DateTimePicker, { PropTypes as AnnouncementTimePickerProps } from '../../default/form/DateTimePicker'
import FormStateProvider from '../../default/form/FormStateProvider'
import FormField from '../../default/form/FormField'
import FormSubmitButton from '../../default/form/FormSubmitButton'
import RequiredText from '../../default/form/RequiredText'
import { ValueGetter, FormValidation, createDefaultFormState } from '../../../utils/default/FormHook'
import {
  FormPropType,
  getValueFromChangeEvent,
  getValueFromCheckboxEvent,
  getValueFromValue
} from '../../../utils/default/FormHelper'
import { InitialFormFunc } from '../../../utils/default/ComplexFlowHook'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import { createValidateNotEmpty, createValidateRange, createValidateDate } from '../../../utils/default/Validator'
import useT from '../../../i18ns/admin/useT'
import CustomThemeType from '../../../themes/admin/CustomThemeType'
import FileImageField, { PropTypes as FileImageFieldProps } from '../../default/form/FileImageField'
import { isAfter, getYear, getUnixTime, isValid } from 'date-fns'

const useStyles = makeStyles((theme: CustomThemeType) => ({
  tabs: {
    maxWidth: '69vw'
  },
  tab: {
    fontSize: theme.typography.h6.fontSize,
    minWidth: 0,
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5)
  },
  tabSelected: {
    backgroundColor: '#f3f3f3',
    color: theme.custom.palette.secondary
  },
  tabContent: {
    backgroundColor: '#f3f3f3',
    padding: 24
  },
  fileField: {
    backgroundColor: '#fff',
    padding: '6px 12px 12px',
    marginBottom: 12
  },
  indicator: {
    height: 0
  }
}))

export interface ActivityManagementEditBannerFormType {
  activity_id?: number
  title: string
  showStartAt: Date | null
  showEndAt: Date | null
  isLongtermDisplay: boolean
  agentLongImage: { id?: number, url: string } | null
  couponImage: { id?: number, url: string } | null
  desktopHomeImage: { id?: number, url: string } | null
  mobileHomeImage: { id?: number, url: string } | null
  isCarousel: boolean
  isGuest: boolean
  isMy: boolean
}

export const initialForm: InitialFormFunc<ActivityManagementEditBannerFormType> = (form) => ({
  title: '',
  showStartAt: null,
  showEndAt: null,
  isLongtermDisplay: false,
  content: { type: 'doc' },
  agentLongImage: null,
  couponImage: null,
  desktopHomeImage: null,
  mobileHomeImage: null,
  isCarousel: false,
  isGuest: false,
  isMy: false,
  ...form
})

const NO_LIMIT_YEAR = 2038

export const formToRequest = (data: ActivityManagementEditBannerFormType): Partial<ActivityBannerReq<number>> & { activity_id?: number } => ({
  activity_id: data.activity_id,
  title: data.title,
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  show_start_at: getUnixTime(data.showStartAt!),
  show_end_at: data.showEndAt && getYear(data.showEndAt) < NO_LIMIT_YEAR ? getUnixTime(data.showEndAt) : null,
  agent_long_image_id: data.agentLongImage ? data.agentLongImage?.id : null,
  coupon_image_id: data.couponImage?.id,
  desktop_home_image_id: data.desktopHomeImage?.id,
  mobile_home_image_id: data.mobileHomeImage?.id,
  is_carousel: data.isCarousel,
  is_guest: data.isGuest,
  is_my: data.isMy
})

interface PropTypes extends FormPropType<ActivityManagementEditBannerFormType> {
  okText: string
  onBack: () => void
  isLook?: boolean
}

const getValueFromEvent: ValueGetter<ActivityManagementEditBannerFormType> = {
  title: getValueFromChangeEvent,
  showStartAt: getValueFromValue,
  showEndAt: getValueFromValue,
  isLongtermDisplay: getValueFromCheckboxEvent,
  desktopHomeImage: getValueFromValue,
  mobileHomeImage: getValueFromValue,
  couponImage: getValueFromValue,
  agentLongImage: getValueFromValue,
  isCarousel: getValueFromCheckboxEvent,
  isGuest: getValueFromCheckboxEvent,
  isMy: getValueFromCheckboxEvent
}

const ActivityManagementFormContext = createContext(createDefaultFormState(initialForm()))

const TextField = React.memo(MuiTextField)

const Button = React.memo(MuiButton)

const DateInput: React.FC<{ disabled: boolean }> = React.memo((props) => {
  const { disabled } = props
  const { t } = useT()
  const { value, dispatch } = useContext(ActivityManagementFormContext)
  return (
    <Grid item container direction="row" spacing={2}>
      <Grid item xs={12} md={6}>
        <FormField<ActivityManagementEditBannerFormType, AnnouncementTimePickerProps>
          component={DateTimePicker}
          context={ActivityManagementFormContext}
          name="showStartAt"
          label={t('common.startDisplayTime')}
          placeholder={t('placeholder.activityStartAtTip')}
          required
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <FormField<ActivityManagementEditBannerFormType, AnnouncementTimePickerProps>
          component={DateTimePicker}
          context={ActivityManagementFormContext}
          name="showEndAt"
          label={t('common.endDisplayTime')}
          placeholder={t('placeholder.activityEndAtTip')}
          required={!value.isLongtermDisplay}
          disabled={disabled || value.isLongtermDisplay}
        />
        <OnOffCheckbox
          label={t('common.longtermDisplay')}
          value={value.isLongtermDisplay}
          onChange={useCallback((event) => {
            const checked = event.target.checked
            dispatch({ type: 'change', label: 'isLongtermDisplay', value: checked })
            dispatch({ type: 'change', label: 'showEndAt', value: checked ? null : value.showEndAt })
          }, [value, dispatch])}
          disabled={disabled}
        />
      </Grid>
    </Grid>
  )
})

const ActivityManagementEditBannerForm: React.FC<PropTypes> = (props) => {
  const { defaultValue, onSubmit, okText, onBack, isLook = false } = props
  const commonClasses = useCommonStyles()
  const classes = useStyles(props)
  const { t } = useT()

  const fileSizeBytesLimitation = 2 * (2 ** 20) // 2MB in bytes
  const fileSizeLimitedText = '2 MB'
  const imageFieldGrids = [
    {
      name: 'desktopHomeImage',
      text: `${t('client.desktop')}${t('common.image')}`,
      isRequired: true
    },
    {
      name: 'mobileHomeImage',
      text: `${t('client.mobile')}${t('common.image')}`,
      isRequired: true
    },
    {
      name: 'couponImage',
      text: t('common.couponImage'),
      isRequired: true
    },
    {
      name: 'agentLongImage',
      text: t('common.agentLongImage'),
      isRequired: false
    }
  ]

  const textFieldInputProps = useMemo(() => {
    return {
      maxLength: 50,
      placeholder: t('placeholder.activityTitleTip')
    }
  }, [t])

  const validation: FormValidation<ActivityManagementEditBannerFormType> = useMemo(() => {
    return {
      title: [
        {
          func: createValidateNotEmpty<ActivityManagementEditBannerFormType>('title', t),
          when: ['change', 'beforeClickSubmit']
        },
        {
          func: createValidateRange<ActivityManagementEditBannerFormType>(
            'title', (value) => (value as string).length, 'moreEqual', 4, t('error.mustMoreEqualFourWords')
          ),
          when: ['change', 'beforeClickSubmit']
        },
        {
          func: createValidateRange<ActivityManagementEditBannerFormType>(
            'title', (value) => (value as string).length, 'lessEqual', 50, t('error.mustLessEqualFiftyWords')
          ),
          when: ['change', 'beforeClickSubmit']
        }
      ],
      showStartAt: [
        {
          func: createValidateNotEmpty<ActivityManagementEditBannerFormType>('showStartAt', t),
          when: ['change', 'beforeClickSubmit']
        },
        {
          func: createValidateDate<ActivityManagementEditBannerFormType>('showStartAt', t),
          when: ['change', 'beforeClickSubmit']
        },
        {
          func: (value, form) => {
            if (!form.showEndAt) {
              return { isPass: true, stop: false, newError: { showStartAt: null } }
            }
            if (!!form.showEndAt && isAfter(value as Date, form.showEndAt)) {
              return { isPass: false, stop: true, newError: { showStartAt: t('error.startMustBeforeEnd') } }
            }
            return { isPass: true, stop: false, newError: { showStartAt: null, showEndAt: null } }
          },
          when: ['change', 'beforeClickSubmit']
        }
      ],
      showEndAt: [
        {
          func: (value, form) => {
            if (!form.showStartAt) {
              return { isPass: true, stop: false, newError: { showEndAt: null } }
            }
            if (!value && !form.isLongtermDisplay) {
              return { isPass: false, stop: true, newError: { showEndAt: t('error.mustNotEmpty') } }
            }
            if (!!value && !isValid(value)) {
              return { isPass: false, stop: true, newError: { showEndAt: t('error.dateError') } }
            }
            if (!!value && isAfter(form.showStartAt, value as Date)) {
              return { isPass: false, stop: true, newError: { showEndAt: t('error.endMustAfterStart') } }
            }
            return { isPass: true, stop: false, newError: { showStartAt: null, showEndAt: null } }
          },
          when: ['change', 'beforeClickSubmit']
        }
      ],
      isLongtermDisplay: [],
      agentLongImage: [],
      couponImage: [
        {
          func: createValidateNotEmpty<ActivityManagementEditBannerFormType>('couponImage', t),
          when: ['change', 'beforeClickSubmit']
        }
      ],
      desktopHomeImage: [
        {
          func: createValidateNotEmpty<ActivityManagementEditBannerFormType>('desktopHomeImage', t),
          when: ['change', 'beforeClickSubmit']
        }
      ],
      mobileHomeImage: [
        {
          func: createValidateNotEmpty<ActivityManagementEditBannerFormType>('mobileHomeImage', t),
          when: ['change', 'beforeClickSubmit']
        }
      ],
      isCarousel: [],
      isGuest: [],
      isMy: []
    }
  }, [t])

  return (
    <FormStateProvider<ActivityManagementEditBannerFormType>
      context={ActivityManagementFormContext}
      defaultValue={defaultValue}
      onSubmit={onSubmit}
      getValueFromEvent={getValueFromEvent}
      validation={validation}
    >
      <Grid container direction="column" spacing={3} wrap="nowrap">
        <Grid item container direction="row">
          <Grid item xs={12}>
            <FormField<ActivityManagementEditBannerFormType, TextFieldProps>
              component={TextField}
              context={ActivityManagementFormContext}
              name="title"
              label={t('common.title')}
              inputProps={textFieldInputProps}
              autoFocus
              fullWidth
              required
              disabled={isLook}
            />
          </Grid>
        </Grid>
        <DateInput disabled={isLook} />
        <Grid item>
          <Box className={commonClasses.pinkTitleBar}>
            <Box display="flex" flexDirection="row" alignItems="center">
              <Tabs
                classes={{ root: classes.tabs, indicator: classes.indicator }}
                value={'tab'}
              >
                <Tab
                  value={'tab'}
                  classes={{ root: classes.tab, selected: classes.tabSelected }}
                  label={t('common.content')}
                />
              </Tabs>
              <Box display="flex" flexGrow={1} />
            </Box>
          </Box>
          <Box className={classes.tabContent}>
            <Grid container direction='row' spacing={2} className={classes.fileField}>
                {imageFieldGrids.map(({ name, text, isRequired }) => (
                  <Grid item xs={12} md={6}>
                    <span>{text}</span>
                    <FormField<ActivityManagementEditBannerFormType, FileImageFieldProps>
                      context={ActivityManagementFormContext}
                      component={FileImageField}
                      name={name as keyof(ActivityManagementEditBannerFormType)}
                      label={t('common.image')}
                      helperText={t('helperText.bannerTip')}
                      buttonText={t('common.uploadImage')}
                      accept=".png, .jpg"
                      fullWidth
                      fileSizeBytesLimitation={fileSizeBytesLimitation}
                      fileSizeLimitedText={fileSizeLimitedText}
                      required={isRequired}
                      disabled={isLook}
                    />
                  </Grid>
                ))}
              </Grid>
          </Box>
        </Grid>
        <Grid item container direction="row">
          <FormField<ActivityManagementEditBannerFormType, OnOffCheckboxProps>
            component={OnOffCheckbox}
            context={ActivityManagementFormContext}
            name="isCarousel"
            label={t('common.homeCarousel')}
            disabled={isLook}
          />
          <FormField<ActivityManagementEditBannerFormType, OnOffCheckboxProps>
            component={OnOffCheckbox}
            context={ActivityManagementFormContext}
            name="isGuest"
            label={t('common.showBeforeLogin')}
            disabled={isLook}
          />
          <FormField<ActivityManagementEditBannerFormType, OnOffCheckboxProps>
            component={OnOffCheckbox}
            context={ActivityManagementFormContext}
            name="isMy"
            label={t('common.my')}
            disabled={isLook}
          />
        </Grid>
        <Grid item>
          <RequiredText />
        </Grid>
        <Grid item container direction="row" justifyContent="flex-end" spacing={2}>
          <Grid item>
            <Button
              className={commonClasses.greyButton}
              onClick={onBack}
            >
              {!isLook ? t('common.cancel') : t('common.back')}
            </Button>
          </Grid>
          <Grid item>
            {!isLook && (<FormSubmitButton
              component={Button}
              context={ActivityManagementFormContext}
              className={commonClasses.purpleGradualButton}
              type="submit"
            >
              {okText}
            </FormSubmitButton>)}
          </Grid>
        </Grid>
      </Grid>
    </FormStateProvider>
  )
}

export default React.memo(ActivityManagementEditBannerForm)

import React, { ChangeEvent } from 'react'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { useInternValue } from '../../../utils/default/FormHook'

export interface PropTypes {
  label: string
  defaultValue?: boolean
  value?: boolean
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void
  disabled?: boolean
  onBlur?: () => void
  onFocus?: () => void
  classes?: {
    root?: string
    checked?: string
    disabled?: string
    indeterminate?: string
    colorPrimary?: string
    colorSecondary?: string
  }
  className?: string
  color?: 'primary' | 'secondary' | 'default'
}

const OnOffCheckbox: React.FC<PropTypes> = (props) => {
  const {
    label,
    defaultValue,
    value,
    onChange,
    disabled,
    onBlur,
    onFocus,
    classes,
    className,
    color = 'secondary'
  } = props

  const [internValue, setInternValue] = useInternValue<boolean>(
    defaultValue ?? value ?? false,
    value
  )

  return (
    <FormControlLabel
      className={className}
      disabled={disabled}
      onBlur={onBlur}
      onFocus={onFocus}
      control={
        <Checkbox
          checked={internValue}
          onChange={(event) => {
            setInternValue(event.target.checked)
            if (onChange) onChange(event)
          }}
          classes={classes}
          color={color}
        />
      }
      label={label}
    />
  )
}

export default React.memo(OnOffCheckbox)

import React, { useState } from 'react'
import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import ActivityManagementEditBannerForm, {
  ActivityManagementEditBannerFormType,
  initialForm
} from '../../../components/admin/activity/ActivityManagementEditBannerForm'
import LoadingAndErrorFrame from '../../../components/default/frames/LoadingAndErrorFrame'
import {
  useRedirectHandleBack,
  useGetDataByParams
} from '../../../utils/default/ComplexFlowHook'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { usePageFlow } from '../../../utils/default/PageFlowHook'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import allRoute, { Path } from '../../../components/admin/route/route'
import useT from '../../../i18ns/admin/useT'

const ActivityManagementBannerLookPage: React.FC = () => {
  const classes = useCommonStyles()
  const { t } = useT()
  const gdk = useGDK()
  const pageFlow = usePageFlow(true, false)
  const [defaultForm, setDefaultForm] = useState<ActivityManagementEditBannerFormType>(initialForm())

  useGetDataByParams({
    path: Path.ACTIVITY_MANAGEMENT_BANNER_LOOK,
    gdkFunc: (param: { id: string }) => gdk.activity.getActivityBanner(Number(param.id)),
    gdkFuncDependencies: [gdk],
    onBeforeFetch: pageFlow.setLoadingStart,
    onSuccess: (entry) => {
      pageFlow.setContentShow()
      setDefaultForm({
        activity_id: entry.activity_id,
        title: entry.title,
        showStartAt: entry.show_start_at,
        showEndAt: entry.show_end_at,
        isLongtermDisplay: entry.show_end_at === null,
        couponImage: { url: entry.coupon_image_url },
        desktopHomeImage: { url: entry.desktop_home_image_url },
        mobileHomeImage: { url: entry.mobile_home_image_url },
        agentLongImage: entry.agent_long_image_url ? { url: entry.agent_long_image_url } : null,
        isCarousel: entry.is_carousel,
        isGuest: entry.is_guest,
        isMy: entry.is_my
      })
    },
    onError: pageFlow.setGDKError
  })

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, handleDebouncedBack] = useRedirectHandleBack({ path: allRoute.activityManagement.encodePath({ search: {}, param: {} }) })

  return (
    <React.Fragment>
      <Box paddingY={5} paddingX={4}>
        <LoadingAndErrorFrame {...pageFlow.status}>
          <Paper>
            <Box padding={4}>
              <Typography className={classes.goldenWord} variant="h5">{t('page.lookActivityBanner')}</Typography>
              <Box paddingY={4}>
                <ActivityManagementEditBannerForm
                  defaultValue={defaultForm}
                  onSubmit={(form) => form}
                  okText={''}
                  onBack={handleDebouncedBack}
                  isLook
                />
              </Box>
            </Box>
          </Paper>
        </LoadingAndErrorFrame>
      </Box>
    </React.Fragment>
  )
}

export default React.memo(ActivityManagementBannerLookPage)

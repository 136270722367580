import React, { useState, useMemo, createContext, Dispatch, SetStateAction, useCallback } from 'react'
import { PaginationRes, AdminRes, Sort } from '@golden/gdk-admin'
import MemoPopoverAndDialogWithContext from '../../default/memo/MemoPopoverAndDialogWithContext'
import LoadingAndErrorFrame from '../../default/frames/LoadingAndErrorFrame'
import ManagerAccountButtons from './ManagerAccountButtons'
import CoreTable from '../../default/present/CoreTable'
import { searchToRequest } from '../../../views/admin/manager/ManagerAccountPage'
import { createDefaultMemoPopoverPayload, MemoPopoverPayload } from '../../default/memo/MemoPopover'
import { MemoDialogPayload, createDefaultMemoDialogPayload } from '../../default/memo/MemoDialog'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import useT from '../../../i18ns/admin/useT'
import useGDK from '../../../providers/admin/gdk/useGDK'
import useGlobalDialog from '../../../providers/admin/dialog/useGlobalDialog'
import { usePageFlow } from '../../../utils/default/PageFlowHook'
import { createDefaultPaginationData, createTableData } from '../../../utils/default/TableHelper'
import { useRequestFromSearch, useGetDataByPayload, usePaginationClickAndChangeUrl, useSortClickAndChangeUrl } from '../../../utils/default/ComplexFlowHook'
import allRoute from '../route/route'
import OnOffText from '../../default/present/OnOffText'
import { useChecker } from '../../../utils/admin/AdminRouteHook'
import DateTime from '../../default/present/DateTime'
import MemoTextWithTitleAndContext from '../../default/memo/MemoTextWithTitleAndContext'

interface RowType {
  id: number
  account: string
  nickname: string
  roleName: string
  isLoginable: React.ReactElement
  isGoogleAuthActive: React.ReactElement
  createdAt: React.ReactElement
  lastLoginAt: React.ReactElement
  ipAddr: string
  memo: React.ReactElement
  functions: React.ReactElement
}

const MemoPopoverContext = createContext<[MemoPopoverPayload, Dispatch<SetStateAction<MemoPopoverPayload>>]>([
  createDefaultMemoPopoverPayload(),
  () => {}
])

const MemoDialogContext = createContext<[MemoDialogPayload, Dispatch<SetStateAction<MemoDialogPayload>>]>([
  createDefaultMemoDialogPayload(),
  () => {}
])

export const initialSort = () => ({ sort_by: 'account', order: 'asc' } as Sort)

const ManagerAccountTable: React.FC = () => {
  const classes = useCommonStyles()
  const { t } = useT()
  const gdk = useGDK()
  const globalDialog = useGlobalDialog()
  const pageFlow = usePageFlow(true)
  const [list, setList] = useState<PaginationRes<Array<AdminRes<Date>>>>(
    createDefaultPaginationData([])
  )
  const [reloadFlag, setReloadFlag] = useState<boolean>(true)
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const request = useRequestFromSearch({ searchToRequest })!
  useGetDataByPayload({
    payload: request,
    gdkFunc: (payload) => gdk.admin.getAccountList(payload),
    gdkFuncDependencies: [gdk, reloadFlag],
    onBeforeFetch: pageFlow.setLoadingStart,
    onSuccess: (res) => {
      setList(res as PaginationRes<Array<AdminRes<Date>>>)
      pageFlow.setContentShow()
    },
    onError: (error) => {
      pageFlow.setGDKError(error)
    },
    canLoadData: request !== undefined
  })

  const writable = useChecker()
  const handleReload = useCallback(() => setReloadFlag((reloadFlag) => !reloadFlag), [])
  const tableClasses = useMemo(() => {
    return {
      head: classes.pinkTableHead,
      cellHead: classes.tableCellHead,
      row: classes.tableRow
    }
  }, [classes])
  const rows: RowType[] = useMemo(() => {
    return list.data.map((item) => {
      return {
        id: item.id,
        account: item.account,
        nickname: item.nickname,
        roleName: item.role_name,
        isLoginable: (<OnOffText value={item.is_loginable} yesText={t('common.toggleOn')} noText={t('common.disable')} />),
        isGoogleAuthActive: (<OnOffText value={item.is_google_auth_active} yesText={t('common.bound')} noText={t('common.notBind')} />),
        createdAt: (<DateTime time={item.created_at} />),
        lastLoginAt: (<DateTime time={item.last_login_at} />),
        ipAddr: item.ip_addr,
        memo: (<MemoTextWithTitleAndContext context={MemoPopoverContext} title={item.memo} memo={item.memo} />),
        functions: (
          <ManagerAccountButtons
            id={item.id}
            account={item.account}
            nickname={item.nickname}
            reload={handleReload}
            isAuthActive={item.is_google_auth_active}
          />
        )
      }
    })
  }, [list.data, t, handleReload])
  const data = useMemo(() => {
    return createTableData(
      {
        id: {
          label: '',
          value: 'id'
        },
        account: {
          label: t('common.account'),
          value: 'account',
          align: 'center',
          canSort: true
        },
        nickname: {
          label: t('common.nickname'),
          value: 'nickname',
          align: 'center'
        },
        roleName: {
          label: t('common.role'),
          value: 'roleName',
          align: 'center'
        },
        isLoginable: {
          label: t('common.status'),
          value: 'isLoginable',
          align: 'center'
        },
        isGoogleAuthActive: {
          label: t('common.googleAuthenticator'),
          value: 'isGoogleAuthActive',
          align: 'center'
        },
        createdAt: {
          label: t('common.createdAt'),
          value: 'createdAt',
          align: 'center'
        },
        lastLoginAt: {
          label: t('common.lastLoginAt'),
          value: 'lastLoginAt',
          align: 'center'
        },
        ipAddr: {
          label: t('common.ipAddress'),
          value: 'ipAddr',
          align: 'center'
        },
        memo: {
          label: t('common.memo'),
          value: 'memo',
          align: 'center'
        },
        functions: {
          label: t('common.function'),
          value: 'functions',
          align: 'center'
        }
      },
      [
        'account',
        'nickname',
        'roleName',
        'isLoginable',
        'isGoogleAuthActive',
        'createdAt',
        'lastLoginAt',
        'ipAddr',
        'memo',
        ...(writable ? ['functions'] as const : [])
      ],
      rows,
      'id'
    )
  }, [rows, t, writable])

  const handlePagination = usePaginationClickAndChangeUrl({
    request,
    encodePath: allRoute.managerAccount.encodePath
  })

  const sorted = useMemo(() => {
    const initSort = initialSort()
    return {
      column: request?.sort_by ?? initSort.sort_by,
      order: request?.order ?? initSort.order
    }
  }, [request])

  const handleSort = useSortClickAndChangeUrl({
    request,
    encodePath: allRoute.managerAccount.encodePath
  })

  if (request === undefined) return null

  return (
    <LoadingAndErrorFrame { ...pageFlow.status }>
      <MemoPopoverAndDialogWithContext
        gdk={gdk}
        globalDialog={globalDialog}
        memoDialogContext={MemoDialogContext}
        memoPopoverContext={MemoPopoverContext}
        onOK={handleReload}
      >
        <CoreTable
          classes={tableClasses}
          data={data}
          total={list.total}
          showPagination
          page={request.page}
          onChangePage={handlePagination}
          sorted={sorted}
          onSort={handleSort}
          loading={pageFlow.status.loading}
        />
      </MemoPopoverAndDialogWithContext>
    </LoadingAndErrorFrame>
  )
}

export default React.memo(ManagerAccountTable)

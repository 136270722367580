import React, { useEffect } from 'react'
import useGDK from '../../providers/admin/gdk/useGDK'
import { useGetData } from '../../utils/default/ComplexFlowHook'
import { createGlobalDialogConfig } from '../../utils/default/DialogHelper'
import useGlobalDialog from '../../providers/admin/dialog/useGlobalDialog'
import useGreetingDisplayStatus from '../../providers/admin/greeting/useGreetingDisplayStatus'
import menuPermissionMap from '../../constants/admin/menuPermissionMap'
import allRoutes from '../../components/admin/route/route'
import useGDKStore from '../../providers/admin/gdk/useGDKStore'
import { useNavigate } from 'react-router'

const HomePage: React.FC = () => {
  const gdk = useGDK()
  const globalDialog = useGlobalDialog()
  const { isDisplayed, setDisplayed } = useGreetingDisplayStatus()
  const me = useGDKStore.admin.me()
  const navigate = useNavigate()

  useGetData({
    canLoadData: !isDisplayed,
    gdkFunc: () => gdk.greeting.getGreeting(),
    onSuccess: (greeting) => {
      if (greeting.content !== '') {
        setDisplayed(true)
        globalDialog.setConfig(createGlobalDialogConfig({
          variant: 'success',
          message: greeting.content,
          showCancel: false,
          showIcon: false
        }))
        globalDialog.setOpen({
          id: 'globalGreeting',
          value: true,
          isOK: false
        })
      }
    }
  })
  useEffect(() => {
    if (!me?.role_id) return
    const route = menuPermissionMap.find((el) => {
      return allRoutes[el.route].permissions.some((permission) => permission.type === 'accessible' && me.permissions.includes(permission.value))
    })
    if (!route) return
    navigate(allRoutes[route.route].path)
  }, [me, navigate])

  return null
}

export default HomePage

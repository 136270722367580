import { TOptions } from 'i18next'
import { isValid, isAfter, differenceInYears } from 'date-fns'
import { FormError } from './FormHelper'
import TKeyType from '../../i18ns/admin/TKeyType'
import { isEmpty, EditorState } from '@golden/tiptap-react'

export interface Result<FormType> {
  isPass: boolean
  stop: boolean
  newError: Partial<FormError<FormType>>
}

export type ValidateFunc<FormType> = (value: unknown, form: FormType, lastSubmitForm: FormType, error: FormError<FormType> | null) => Result<FormType>

export type ValidationTimePoint<FormType> = 'blur'
| 'focus'
| 'change'
| `change:${keyof FormType & string}`
| 'beforeClickSubmit'
| 'beforeSubmit'
| 'rerender'

export function createCorrectResult<FormType> (label: keyof FormType): Result<FormType> {
  return {
    isPass: true,
    stop: false,
    newError: { [label]: null } as Partial<FormError<FormType>>
  }
}

export function createErrorResult<FormType> (label: keyof FormType, text: string): Result<FormType> {
  return {
    isPass: false,
    stop: true,
    newError: { [label]: text } as Partial<FormError<FormType>>
  }
}

export function createValidateEditorNotEmpty<FormType> (
  label: keyof FormType,
  t: (key: TKeyType, options?: TOptions) => string
): ValidateFunc<FormType> {
  return (value: unknown) => {
    if (isEmpty((value as EditorState).content)) {
      return createErrorResult(label, t('error.mustNotEmpty'))
    }
    return createCorrectResult(label)
  }
}

export function createValidateNotEmpty<FormType> (
  label: keyof FormType,
  t: (key: TKeyType, options?: TOptions) => string
): ValidateFunc<FormType> {
  return (value: unknown) => {
    if (value === undefined || value === null) return createErrorResult(label, t('error.mustNotEmpty'))
    if (typeof value === 'string' && (value).trim() === '') {
      return createErrorResult(label, t('error.mustNotEmpty'))
    }
    return createCorrectResult(label)
  }
}

export function createValidateDropDownNotEmpty<FormType> (
  label: keyof FormType,
  t: (key: TKeyType, options?: TOptions) => string
): ValidateFunc<FormType> {
  return (value: unknown) => {
    if (value as string !== '--') {
      return createCorrectResult(label)
    }
    return createErrorResult(label, t('error.mustNotEmpty'))
  }
}

export function createValidateDate<FormType> (
  label: keyof FormType,
  t: (key: TKeyType, options?: TOptions) => string
): ValidateFunc<FormType> {
  return (value: unknown) => {
    if (isValid(value as Date)) {
      return createCorrectResult(label)
    }
    return createErrorResult(label, t('error.dateError'))
  }
}

export function createValidateStartAtWithEndAt<FormType> (
  label: keyof FormType,
  t: (key: TKeyType, options?: TOptions) => string
): ValidateFunc<FormType> {
  return (value: unknown) => {
    const time: { start: Date | null, end: Date | null } = value as { start: Date | null, end: Date | null }
    if (
      (time.start !== null && time.end === null) ||
      (time.start === null && time.end !== null)
    ) {
      return createErrorResult(label, t('error.startMustComeWithEnd'))
    }
    if (
      (time.start !== null && !isValid(time.start)) ||
      (time.end !== null && !isValid(time.end))
    ) {
      return createErrorResult(label, t('error.dateError'))
    }
    if (time.start !== null && time.end !== null && !isAfter(time.end, time.start)) {
      return createErrorResult(label, t('error.startMustBeforeEnd'))
    }
    return createCorrectResult(label)
  }
}

export function createValidateBirthday<FormType> (
  label: keyof FormType,
  t: (key: TKeyType, options?: TOptions) => string
): ValidateFunc<FormType> {
  return (value: unknown) => {
    const date: Date | null = value as Date | null
    if (date !== null && differenceInYears(new Date(), date) < 18) {
      return createErrorResult(label, t('error.birthdayError'))
    }
    return createCorrectResult(label)
  }
}

export function createValidateRange<FormType> (
  label: keyof FormType,
  getNumber: (value: unknown) => number,
  mustCondition: 'lessThan' | 'moreThan' | 'lessEqual' | 'moreEqual' | 'equal' | 'notEqual',
  boundary: number,
  errorText: string
): ValidateFunc<FormType> {
  return (value: unknown) => {
    const num = getNumber(value)
    switch (mustCondition) {
      case 'lessThan':
        if (num < boundary) {
          return createCorrectResult(label)
        }
        break
      case 'moreThan':
        if (num > boundary) {
          return createCorrectResult(label)
        }
        break
      case 'lessEqual':
        if (num <= boundary) {
          return createCorrectResult(label)
        }
        break
      case 'moreEqual':
        if (num >= boundary) {
          return createCorrectResult(label)
        }
        break
      case 'equal':
        if (num === boundary) {
          return createCorrectResult(label)
        }
        break
      case 'notEqual':
        if (num !== boundary) {
          return createCorrectResult(label)
        }
        break
    }
    return createErrorResult(label, errorText)
  }
}

export function createValidatePassword<FormType> (
  label: keyof FormType,
  t: (key: TKeyType, options?: TOptions) => string,
  key?: TKeyType
): ValidateFunc<FormType> {
  return (value: unknown) => {
    const regex = new RegExp('^(?=.*[0-9])(?=.*[a-z])[a-z0-9#?!@$%^&*-._]{6,255}$' +
      '|^(?=.*[0-9])(?=.*[#?!@$%^&*-._])[a-z0-9#?!@$%^&*-._]{6,255}$' +
      '|^(?=.*[a-z])(?=.*[#?!@$%^&*-._])[a-z0-9#?!@$%^&*-._]{6,255}$', 'i')
    if (regex.test(value as string) || value === '') {
      return createCorrectResult(label)
    }
    return createErrorResult(label, t(key ?? 'error.passwordError'))
  }
}

export function createValidateAccount<FormType> (
  label: keyof FormType,
  t: (key: TKeyType, options?: TOptions) => string
): ValidateFunc<FormType> {
  return (value: unknown) => {
    const regex = /^[a-zA-Z0-9]{4,10}$/i
    if (regex.test(value as string) || value === '') {
      return createCorrectResult(label)
    }
    return createErrorResult(label, t('error.accountError'))
  }
}

export function createValidateIp<FormType> (
  label: keyof FormType,
  t: (key: TKeyType, options?: TOptions) => string
): ValidateFunc<FormType> {
  return (value: unknown) => {
    const ipv4 = /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/i
    const ipv6 = /^(?:(?:(?:(?:(?:(?:(?:[0-9a-fA-F]{1,4})):){6})(?:(?:(?:(?:(?:[0-9a-fA-F]{1,4})):(?:(?:[0-9a-fA-F]{1,4})))|(?:(?:(?:(?:(?:25[0-5]|(?:[1-9]|1[0-9]|2[0-4])?[0-9]))\.){3}(?:(?:25[0-5]|(?:[1-9]|1[0-9]|2[0-4])?[0-9])))))))|(?:(?:::(?:(?:(?:[0-9a-fA-F]{1,4})):){5})(?:(?:(?:(?:(?:[0-9a-fA-F]{1,4})):(?:(?:[0-9a-fA-F]{1,4})))|(?:(?:(?:(?:(?:25[0-5]|(?:[1-9]|1[0-9]|2[0-4])?[0-9]))\.){3}(?:(?:25[0-5]|(?:[1-9]|1[0-9]|2[0-4])?[0-9])))))))|(?:(?:(?:(?:(?:[0-9a-fA-F]{1,4})))?::(?:(?:(?:[0-9a-fA-F]{1,4})):){4})(?:(?:(?:(?:(?:[0-9a-fA-F]{1,4})):(?:(?:[0-9a-fA-F]{1,4})))|(?:(?:(?:(?:(?:25[0-5]|(?:[1-9]|1[0-9]|2[0-4])?[0-9]))\.){3}(?:(?:25[0-5]|(?:[1-9]|1[0-9]|2[0-4])?[0-9])))))))|(?:(?:(?:(?:(?:(?:[0-9a-fA-F]{1,4})):){0,1}(?:(?:[0-9a-fA-F]{1,4})))?::(?:(?:(?:[0-9a-fA-F]{1,4})):){3})(?:(?:(?:(?:(?:[0-9a-fA-F]{1,4})):(?:(?:[0-9a-fA-F]{1,4})))|(?:(?:(?:(?:(?:25[0-5]|(?:[1-9]|1[0-9]|2[0-4])?[0-9]))\.){3}(?:(?:25[0-5]|(?:[1-9]|1[0-9]|2[0-4])?[0-9])))))))|(?:(?:(?:(?:(?:(?:[0-9a-fA-F]{1,4})):){0,2}(?:(?:[0-9a-fA-F]{1,4})))?::(?:(?:(?:[0-9a-fA-F]{1,4})):){2})(?:(?:(?:(?:(?:[0-9a-fA-F]{1,4})):(?:(?:[0-9a-fA-F]{1,4})))|(?:(?:(?:(?:(?:25[0-5]|(?:[1-9]|1[0-9]|2[0-4])?[0-9]))\.){3}(?:(?:25[0-5]|(?:[1-9]|1[0-9]|2[0-4])?[0-9])))))))|(?:(?:(?:(?:(?:(?:[0-9a-fA-F]{1,4})):){0,3}(?:(?:[0-9a-fA-F]{1,4})))?::(?:(?:[0-9a-fA-F]{1,4})):)(?:(?:(?:(?:(?:[0-9a-fA-F]{1,4})):(?:(?:[0-9a-fA-F]{1,4})))|(?:(?:(?:(?:(?:25[0-5]|(?:[1-9]|1[0-9]|2[0-4])?[0-9]))\.){3}(?:(?:25[0-5]|(?:[1-9]|1[0-9]|2[0-4])?[0-9])))))))|(?:(?:(?:(?:(?:(?:[0-9a-fA-F]{1,4})):){0,4}(?:(?:[0-9a-fA-F]{1,4})))?::)(?:(?:(?:(?:(?:[0-9a-fA-F]{1,4})):(?:(?:[0-9a-fA-F]{1,4})))|(?:(?:(?:(?:(?:25[0-5]|(?:[1-9]|1[0-9]|2[0-4])?[0-9]))\.){3}(?:(?:25[0-5]|(?:[1-9]|1[0-9]|2[0-4])?[0-9])))))))|(?:(?:(?:(?:(?:(?:[0-9a-fA-F]{1,4})):){0,5}(?:(?:[0-9a-fA-F]{1,4})))?::)(?:(?:[0-9a-fA-F]{1,4})))|(?:(?:(?:(?:(?:(?:[0-9a-fA-F]{1,4})):){0,6}(?:(?:[0-9a-fA-F]{1,4})))?::))))$/i
    if (ipv6.test(value as string) || ipv4.test(value as string) || value === '') {
      return createCorrectResult(label)
    }
    return createErrorResult(label, t('error.invalidIp'))
  }
}

export function createValidateUrl<FormType> (
  label: keyof FormType,
  t: (key: TKeyType, options?: TOptions) => string
): ValidateFunc<FormType> {
  return (value: unknown) => {
    const url = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&/=]*)/gi
    if (url.test(value as string) || value === '') {
      return createCorrectResult(label)
    }
    return createErrorResult(label, t('error.invalidUrl'))
  }
}

export function createValidateDomain<FormType> (
  label: keyof FormType,
  t: (key: TKeyType, options?: TOptions) => string
): ValidateFunc<FormType> {
  return (value: unknown) => {
    const domain = /^(?!:\/\/)([a-zA-Z0-9-_]+\.)*[a-zA-Z0-9][a-zA-Z0-9-_]+\.[a-zA-Z]{2,11}?$/gi
    if (domain.test(value as string) || value === '') {
      return createCorrectResult(label)
    }
    return createErrorResult(label, t('error.invalidDomain'))
  }
}

export function createValidateCash<FormType> (
  label: keyof FormType,
  t: (key: TKeyType, options?: TOptions) => string
): ValidateFunc<FormType> {
  return (value: unknown) => {
    if (Number(value) >= 1000000000) {
      return createErrorResult(label, t('error.mustLessThan9Digits'))
    }
    return createCorrectResult(label)
  }
}

export function createValidateMin<FormType> (
  minLabel: keyof FormType,
  maxLabel: keyof FormType,
  t: (key: TKeyType, options?: TOptions) => string
): ValidateFunc<FormType> {
  return (value: unknown, form: FormType, _: FormType, error: FormError<FormType> | null) => {
    if (value !== '' && (form[maxLabel] as unknown as string) !== '' && Number(value) > Number(form[maxLabel])) {
      return createErrorResult(minLabel, t('error.mustLessThanMax'))
    }
    return {
      isPass: true,
      stop: false,
      newError: error?.[maxLabel] === t('error.mustMoreThanMin')
        ? { [minLabel]: null, [maxLabel]: null } as Partial<FormError<FormType>>
        : { [minLabel]: null } as Partial<FormError<FormType>>
    }
  }
}

export function createValidateMax<FormType> (
  minLabel: keyof FormType,
  maxLabel: keyof FormType,
  t: (key: TKeyType, options?: TOptions) => string
): ValidateFunc<FormType> {
  return (value: unknown, form: FormType, _: FormType, error: FormError<FormType> | null) => {
    if (value !== '' && (form[minLabel] as unknown as string) !== '' && Number(form[minLabel]) > Number(value)) {
      return createErrorResult(maxLabel, t('error.mustMoreThanMin'))
    }
    return {
      isPass: true,
      stop: false,
      newError: error?.[minLabel] === t('error.mustLessThanMax')
        ? { [minLabel]: null, [maxLabel]: null } as Partial<FormError<FormType>>
        : { [maxLabel]: null } as Partial<FormError<FormType>>
    }
  }
}

import React from 'react'
import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import ActivityManagementEditBannerForm, {
  formToRequest,
  initialForm
} from '../../../components/admin/activity/ActivityManagementEditBannerForm'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import { useDialogHandleSubmit, useRedirectHandleBack, useRequestFromSearch } from '../../../utils/default/ComplexFlowHook'
import allRoute from '../../../components/admin/route/route'
import useT from '../../../i18ns/admin/useT'
import useGlobalDialog from '../../../providers/admin/dialog/useGlobalDialog'
import { createGlobalDialogConfig } from '../../../utils/default/DialogHelper'
import { searchToRequest } from '../../../components/admin/activity/ActivityManagementForm'
import { ActivityBannerReq } from '@golden/gdk-admin'

const defaultForm = initialForm()

const ActivityManagementBannerCreatePage: React.FC = () => {
  const request = useRequestFromSearch({ searchToRequest })
  const commonClasses = useCommonStyles()
  const { t } = useT()
  const gdk = useGDK()
  const globalDialog = useGlobalDialog()
  const [handleBack, handleDebouncedBack] = useRedirectHandleBack({ path: allRoute.activityManagement.encodePath({ search: { ...request }, param: {} }) })

  const { handleSubmit } = useDialogHandleSubmit({
    formToRequest,
    globalDialog,
    dialogId: 'createActivityBanner',
    gdkFunc: (payload) => gdk.activity.createActivityBanner(payload as ActivityBannerReq<number>),
    gdkFuncDependencies: [gdk],
    getChangeDialogConfig: () => createGlobalDialogConfig({
      showIcon: false,
      message: t('dialog.confirmCreate')
    }),
    getSuccessDialogConfig: () => createGlobalDialogConfig({
      showIcon: false,
      showCancel: false,
      message: t('dialog.createSuccess')
    }),
    getFailDialogConfig: (_, error) => createGlobalDialogConfig({
      showIcon: true,
      variant: 'error',
      showCancel: false,
      message: error.message
    }),
    afterSuccessDialog: handleBack
  })

  return (
    <React.Fragment>
      <Box paddingY={5} paddingX={4}>
        <Paper>
          <Box padding={4}>
            <Typography className={commonClasses.goldenWord} variant="h5">{t('page.createActivityBanner')}</Typography>
            <Box paddingY={4}>
              <ActivityManagementEditBannerForm
                defaultValue={defaultForm}
                onSubmit={handleSubmit}
                okText={t('common.confirmCreate')}
                onBack={handleDebouncedBack}
              />
            </Box>
          </Box>
        </Paper>
      </Box>
    </React.Fragment>
  )
}

export default React.memo(ActivityManagementBannerCreatePage)

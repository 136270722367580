import React, { useMemo, useState } from 'react'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core'
import clsx from 'clsx'

const useStyles = makeStyles(() => ({
  base: {
    height: '100%',
    position: 'relative',
    zIndex: 0
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    objectPosition: 'center',
    '&:-moz-loading': {
      opacity: 0
    }
  },
  loading: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    zIndex: 1
  },
  placeholder: {
    width: '100%',
    height: '100%',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center'
  }
}))

interface PropTypes {
  src: string
  webp?: string
  lqip?: string
  imageClass?: string
}

const AppImage: React.FC<PropTypes> = (props) => {
  const { src, webp = '', lqip = '', imageClass } = props
  const classes = useStyles()

  const [loading, setLoading] = useState(true)
  const [noWebp, setNoWebp] = useState(false)

  const handleLoad = () => { setLoading(false) }
  const webpImage = useMemo(() => {
    if (!webp) return ''
    const image = new Image()
    image.onload = handleLoad
    image.onerror = () => { setNoWebp(true) }
    image.src = webp
    return image.src
  }, [webp])
  return (
    <Box className={classes.base}>
      <picture>
        { !!webp && !noWebp && (
          <source
            srcSet={webpImage}
            type="image/webp"
          />
        )}
        { !loading && (
          <img
            src={src}
            className={clsx(classes.image, imageClass)}
            onLoad={handleLoad}
            alt=""
          />
        )}
      </picture>
      { loading && (
        <Box
          className={classes.loading}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          { !!lqip && (
            <Box
              style={{
                backgroundImage: `url('${lqip}')`
              }}
              className={lqip}
            />
          )}
        </Box>
      )}
    </Box>
  )
}

export default React.memo(AppImage)

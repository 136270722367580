import React, { useMemo, useContext, useCallback, createContext } from 'react'
import { PaginationReq, GameCategoryType, PlayerCashAccountingQuery, GameType } from '@golden/gdk-admin'
import { omitBy, isUndefined, enumValues } from '@golden/utils'
import { startOfDay, endOfDay, subMonths, getTime } from 'date-fns'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import MuiTextField, { TextFieldProps } from '@material-ui/core/TextField'
import MuiButton from '@material-ui/core/Button'
import DateInputBase, { DateInputValue } from '../../../default/form/DateInput'
import FormStateProvider from '../../../default/form/FormStateProvider'
import FormField from '../../../default/form/FormField'
import FormSubmitButton from '../../../default/form/FormSubmitButton'
import { useCommonStyles } from '../../../../utils/admin/StyleHook'
import useT from '../../../../i18ns/admin/useT'
import LoadingAndErrorFrame from '../../../default/frames/LoadingAndErrorFrame'
import { InitialFormFunc, useRequestFromSearch, useChangeUrlSubmit, SearchToRequestFunc } from '../../../../utils/default/ComplexFlowHook'
import { ValueGetter, createDefaultFormState, FormValidation } from '../../../../utils/default/FormHook'
import { getTimeFromDateInputValue, getValueFromChangeEvent, convertEmptyToUndefined, pipe, guaranteeNotUndefined, guaranteeBetween, acceptUndefined, guaranteeBeOneOf, parseArray, getValueFromValue } from '../../../../utils/default/FormHelper'
import { createShouldDisableDate, setToday, setYesterday, setThisMonth } from '../../../../utils/default/TimeHelper'
import allRoute from '../../route/route'
import { usePageFlow } from '../../../../utils/default/PageFlowHook'
import { createValidateNotEmpty } from '../../../../utils/default/Validator'
import GameInput, { PropTypes as GameInputProps } from '../../GameInput'

export type PlayerReportCashAccountingRequest = PaginationReq & PlayerCashAccountingQuery

export interface PlayerReportCashAccountingFormType {
  time: DateInputValue
  account: string
  game: {
    game_category: GameCategoryType | 'all'
    game_id: GameType | 'noGame' | 'all'
    channel_id: number[]
    lottery_channel_id: number[]
  }
}

export const initialForm: InitialFormFunc<PlayerReportCashAccountingFormType> = (defaultForm) => ({
  time: {
    start: startOfDay(new Date()),
    end: endOfDay(new Date())
  },
  account: '',
  game: {
    game_category: 'all',
    game_id: 'all',
    channel_id: [],
    lottery_channel_id: [-1]
  },
  ...defaultForm
})

const getValueFromEvent: ValueGetter<PlayerReportCashAccountingFormType> = {
  time: getTimeFromDateInputValue,
  account: getValueFromChangeEvent,
  game: getValueFromValue
}

export const searchToRequest: SearchToRequestFunc<PlayerReportCashAccountingRequest> = (search) => {
  const converted = {
    ...search,
    page: pipe(
      guaranteeNotUndefined,
      parseInt,
      (value) => guaranteeBetween(value, 1, Number.MAX_SAFE_INTEGER)
    )(search.page),
    start_at: pipe(
      guaranteeNotUndefined,
      parseInt,
      (value) => guaranteeBetween(value, 1, Number.MAX_SAFE_INTEGER)
    )(search.start_at),
    end_at: pipe(
      guaranteeNotUndefined,
      parseInt,
      (value) => guaranteeBetween(value, 1, Number.MAX_SAFE_INTEGER)
    )(search.end_at),
    game_category: acceptUndefined(search.game_category, pipe(
      (value) => guaranteeBeOneOf(Number(value), enumValues(GameCategoryType)),
      parseInt
    )),
    game_id: acceptUndefined(search.game_id, pipe(
      (value) => guaranteeBeOneOf(Number(value), enumValues(GameType)),
      parseInt
    )),
    channel_id: acceptUndefined(search.channel_id, pipe(
      parseArray,
      (values) => values.map((item: string) => Number(item))
    )),
    lottery_channel_id: acceptUndefined(search.channel_id, pipe(
      parseArray,
      (values) => values.map((item: string) => Number(item))
    ))
  } as PlayerReportCashAccountingRequest
  if (converted.end_at < converted.start_at) throw new Error('The end time can\'t exceed the start time')
  return omitBy(converted, isUndefined) as PlayerReportCashAccountingRequest
}

const formToRequest = (form: PlayerReportCashAccountingFormType): PlayerReportCashAccountingRequest => {
  const converted = {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    start_at: getTime(form.time.start!),
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    end_at: getTime(form.time.end!),
    account: convertEmptyToUndefined(form.account),
    game_category: form.game.game_category === 'all' ? undefined : form.game.game_category,
    game_id: form.game.game_id === 'noGame' || form.game.game_id === 'all' ? undefined : form.game.game_id,
    channel_id: form.game.game_category === GameCategoryType.LOTTERY
      ? form.game.lottery_channel_id[0] === -1 ? undefined : form.game.lottery_channel_id
      : form.game.channel_id.length === 0 ? undefined : form.game.channel_id,
    page: 1
  } as PlayerReportCashAccountingRequest
  return omitBy(converted, isUndefined) as PlayerReportCashAccountingRequest
}

const FormContext = createContext(createDefaultFormState(initialForm()))

const TextField = React.memo(MuiTextField)

const Button = React.memo(MuiButton)

const DateInput: React.FC = React.memo(() => {
  const classes = useCommonStyles()
  const { t } = useT()
  const { value, handleChange } = useContext(FormContext)
  const {
    shouldDisableStartDate,
    shouldDisableEndDate
  } = useMemo(() => {
    const twoMonthsAgo = subMonths(new Date(), 2)
    return createShouldDisableDate(value.time.start, value.time.end, twoMonthsAgo)
  }, [value.time])
  const tools = useMemo(() => {
    return [
      {
        label: t('common.today'),
        change: setToday
      },
      {
        label: t('common.yesterday'),
        change: setYesterday
      },
      {
        label: t('common.thisMonth'),
        change: setThisMonth
      }
    ]
  }, [t])
  const startOption = useMemo(() => ({
    label: t('common.beginAt'),
    shouldDisableDate: shouldDisableStartDate
  }), [shouldDisableStartDate, t])

  const endOption = useMemo(() => ({
    label: t('common.endAt'),
    shouldDisableDate: shouldDisableEndDate
  }), [shouldDisableEndDate, t])

  const dateClasses = useMemo(() => ({
    button: classes.pinkGradualButton
  }), [classes.pinkGradualButton])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onChange = useCallback(handleChange('time'), [])
  return (
    <DateInputBase
      value={value.time}
      onChange={onChange}
      start={startOption}
      end={endOption}
      tools={tools}
      classes={dateClasses}
    />
  )
})

const PlayerReportCashAccountingForm: React.FC = () => {
  const classes = useCommonStyles()
  const { t } = useT()
  const pageFlow = usePageFlow()

  const request = useRequestFromSearch({ searchToRequest })

  const defaultForm = useMemo(() => {
    if (request) {
      return initialForm({
        time: {
          start: new Date(request.start_at),
          end: new Date(request.end_at)
        },
        game: {
          game_category: request.game_category ?? 'all',
          game_id: request.game_id ?? 'all',
          channel_id: request.channel_id ?? [],
          lottery_channel_id: request.channel_id ?? [-1]
        },
        ...request
      })
    }
    return initialForm()
  }, [request])

  const handleSubmit = useChangeUrlSubmit({
    toAddNowTimestamp: true,
    formToRequest,
    encodePath: allRoute.playerCashAccounting.encodePath
  })

  const validation = useMemo(() => ({
    time: [],
    account: [
      {
        func: createValidateNotEmpty('account', t),
        when: ['change', 'beforeClickSubmit']
      }
    ],
    game: []
  } as FormValidation<PlayerReportCashAccountingFormType>), [t])

  return (
    <FormStateProvider
      context={FormContext}
      defaultValue={defaultForm}
      onSubmit={handleSubmit}
      validation={validation}
      getValueFromEvent={getValueFromEvent}
    >
      <Paper>
        <Box padding={4}>
          <LoadingAndErrorFrame { ...pageFlow.status }>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <Box
                  paddingY={1.25}
                  paddingX={2}
                  className={classes.pinkTitleBar}
                >
                  <Typography variant="h5">
                    {t('page.cashAccounting')}
                  </Typography>
                </Box>
              </Grid>
              <Grid item>
                <DateInput />
              </Grid>
              <Grid item>
                <FormField<PlayerReportCashAccountingFormType, GameInputProps>
                  context={FormContext}
                  component={GameInput}
                  name="game"
                  FrontComponent={
                    <Grid item xs={12} md={6} lg={3}>
                      <FormField<PlayerReportCashAccountingFormType, TextFieldProps>
                        context={FormContext}
                        component={TextField}
                        name="account"
                        label={t('common.playerAccount')}
                        placeholder={t('placeholder.inputPlayerAccount')}
                        fullWidth
                        required
                      />
                    </Grid>
                  }
                />
              </Grid>
              <Grid item>
                <Grid container justifyContent="flex-end">
                  <Grid item>
                    <FormSubmitButton
                      component={Button}
                      context={FormContext}
                      type="submit"
                      className={classes.purpleGradualButton}
                    >
                      {t('common.search')}
                    </FormSubmitButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </LoadingAndErrorFrame>
        </Box>
      </Paper>
    </FormStateProvider>
  )
}

export default React.memo(PlayerReportCashAccountingForm)

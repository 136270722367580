import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Tabline from '../../components/default/present/Tabline'
import { useInternValue } from '../../utils/default/FormHook'

const useStyles = makeStyles((theme) => ({
  tabline: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.secondary.main,
    borderColor: theme.palette.secondary.main,
    borderWidth: 1,
    borderStyle: 'solid',
    borderRadius: '4px'
  },
  tabSelected: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white
  },
  indicator: {
    display: 'none'
  }
}))

export interface PropTypes {
  value: number | string
  tabs: Array<{ value: number | string, label: string }>
  onChange?: (value: number | string) => void
}

const TableTabs: React.FC<PropTypes> = (props) => {
  const { value, tabs, onChange } = props
  const classes = useStyles()
  const [internValue, setInternValue] = useInternValue<string | number>(value)

  return (
    <Tabline
      value={internValue}
      onChange={(_, newValue) => {
        setInternValue(newValue)
        if (onChange) onChange(newValue)
      }}
      tabs={tabs}
      TabsProps={{ className: classes.tabline, classes: { indicator: classes.indicator } }}
      TabProps={{
        classes: { selected: classes.tabSelected }
      }}
    />
  )
}

export default React.memo(TableTabs)

import React, { useState, useCallback, useMemo } from 'react'
import { FinanceBlacklist, PaginationRes } from '@golden/gdk-admin'
import Box from '@material-ui/core/Box'
import CoreTable from '../../default/present/CoreTable'
import LoadingAndErrorFrame from '../../default/frames/LoadingAndErrorFrame'
import { searchToRequest } from '../../../views/admin/finance/FinanceBlacklistPage'
import { usePageFlow } from '../../../utils/default/PageFlowHook'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { useRequestFromSearch, useGetDataByPayload, usePaginationClickAndChangeUrl, useReload } from '../../../utils/default/ComplexFlowHook'
import useGDKStore from '../../../providers/admin/gdk/useGDKStore'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import useT from '../../../i18ns/admin/useT'
import { createDefaultPaginationData, createTableData } from '../../../utils/default/TableHelper'
import allRoute from '../route/route'
import FinanceBlacklistButtons from './FinanceBlacklistButtons'
import { useChecker } from '../../../utils/admin/AdminRouteHook'
import DateTime from '../../default/present/DateTime'
import ScrollablePaper from '../../default/present/ScrollablePaper'

interface RowType {
  id: number
  createdAt: React.ReactElement
  realName: string
  bankAccount: string
  bank: string
  account: string
  memo: string
  createdBy: string
  functions: React.ReactElement
}

const FinanceBlacklistTable: React.FC = () => {
  const classes = useCommonStyles()
  const pageFlow = usePageFlow()
  const gdk = useGDK()
  const { t } = useT()
  const [list, setList] = useState<PaginationRes<FinanceBlacklist[]>>(
    createDefaultPaginationData([])
  )
  const { reloadFlag, reload } = useReload()
  const supportBanks = useGDKStore.finance.supportBanks()
  const banks = useMemo(() => supportBanks.map((bank) => bank.id), [supportBanks])
  const writable = useChecker()

  const memoSearchToRequest = useCallback((search) => {
    return searchToRequest(banks)(search)
  }, [banks])
  const request = useRequestFromSearch({
    searchToRequest: memoSearchToRequest
  })
  useGetDataByPayload({
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    payload: request!,
    gdkFunc: (payload) => gdk.finance.getBlacklist(payload),
    gdkFuncDependencies: [gdk, reloadFlag],
    onBeforeFetch: pageFlow.setLoadingStart,
    onSuccess: (res: PaginationRes<FinanceBlacklist[]>) => {
      setList(res)
      pageFlow.setContentShow()
    },
    onError: pageFlow.setGDKError,
    canLoadData: request !== undefined
  })

  const tableClasses = useMemo(() => ({
    head: classes.pinkTableHead,
    row: classes.tableRow,
    cellHead: classes.tableCellHead
  }), [classes])
  const rows: RowType[] = useMemo(() => {
    return list.data.map((item) => ({
      id: item.id,
      createdAt: (<DateTime time={item.created_at} />),
      realName: item.real_name,
      bankAccount: item.bank_account,
      bank: supportBanks.find((bank) => bank.id === item.bank_code)?.name ?? '--',
      account: item.player_account === '' ? '--' : item.player_account,
      memo: item.memo,
      createdBy: item.created_by,
      functions: (
        <FinanceBlacklistButtons
          id={item.id}
          realName={item.real_name}
          bankAccount={item.bank_account}
          reload={reload}
        />
      )
    }))
  }, [list.data, reload, supportBanks])
  const data = useMemo(() => {
    return createTableData<RowType>(
      {
        id: {
          label: '',
          value: 'id'
        },
        createdAt: {
          label: t('common.createdAt'),
          value: 'createdAt',
          align: 'center'
        },
        realName: {
          label: t('common.realName'),
          value: 'realName',
          align: 'center'
        },
        bankAccount: {
          label: t('common.bankAccount'),
          value: 'bankAccount',
          align: 'center'
        },
        bank: {
          label: t('common.bank'),
          value: 'bank',
          align: 'center'
        },
        account: {
          label: t('common.playerAccount'),
          value: 'account',
          align: 'center'
        },
        memo: {
          label: t('common.memo'),
          value: 'memo',
          align: 'left'
        },
        createdBy: {
          label: t('common.createdBy'),
          value: 'createdBy',
          align: 'center'
        },
        functions: {
          label: t('common.function'),
          value: 'functions',
          align: 'center'
        }
      },
      writable
        ? [
            'createdAt',
            'realName',
            'bankAccount',
            'bank',
            'account',
            'createdBy',
            'memo',
            'functions'
          ]
        : [
            'createdAt',
            'realName',
            'bankAccount',
            'bank',
            'account',
            'createdBy',
            'memo'
          ],
      rows,
      'id'
    )
  }, [rows, t, writable])

  const handlePagination = usePaginationClickAndChangeUrl({
    request,
    encodePath: allRoute.financeBlacklist.encodePath
  })

  if (request === undefined) return null

  return (
    <ScrollablePaper marginX={6}>
      <Box padding={4}>
        <LoadingAndErrorFrame { ...pageFlow.status }>
          <CoreTable
            classes={tableClasses}
            data={data}
            total={list.total}
            showPagination
            page={request.page}
            onChangePage={handlePagination}
          />
        </LoadingAndErrorFrame>
      </Box>
    </ScrollablePaper>
  )
}

export default React.memo(FinanceBlacklistTable)

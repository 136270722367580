import PathOptionType from '../../components/default/route/PathOptionType'
import RouteType from '../../components/default/route/RouteType'
import { matchPath } from 'react-router'

export function encodePath (path: string, option: PathOptionType<any, any>): string {
  const { search, param } = option
  let url = path
  const params = url.split('/:')
  if (params.length > 1) {
    params
      .map((str) => str.replace(/\/.*/, ''))
      .sort((a, b) => a.length - b.length)
      .forEach((key) => {
        if (key === '') return
        const value = encodeURI(param[key])
        url = url.replace(`/:${key}`, `/${value}`)
      })
  }
  const query = Object.keys(search)
    .map((key) => {
      const value = encodeURIComponent(search[key])
      return `${key}=${value}`
    })
    .join('&')
  return query === '' ? url : `${url}?${query}`
}

export function parsePath (locationSearch: string, pathname: string, path?: string): PathOptionType<any, any> {
  const collection = new URLSearchParams(locationSearch)
  const search = Array.from(collection.keys())
    .map((key) => ({ [key]: collection.get(key) }))
    .reduce((object, entry) => Object.assign({}, object, entry), {})
  const match = matchPath(path ?? '', pathname)
  return {
    search,
    param: match?.params ?? {}
  }
}

export function findAppRoute<RouteKey extends string, Path extends string, Permission, TKeyType, Other = {}> (
  pathname: string,
  routeOrder: RouteKey[],
  allRoute: { [key in RouteKey]: RouteType<RouteKey, Path, Permission, TKeyType> & Other }
): RouteType<RouteKey, Path, Permission, TKeyType> & Other | undefined {
  const routeIndex = routeOrder.find((item) => {
    return matchPath(allRoute[item].path, pathname) !== null
  })

  if (routeIndex === undefined) {
    return undefined
  }

  return allRoute[routeIndex]
}

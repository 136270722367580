import React, { Dispatch, SetStateAction, useContext, useMemo } from 'react'
import { GameCategoryType, SpecifyLeague } from '@golden/gdk-admin'
import Button from '@material-ui/core/Button'
import useT from '../../../i18ns/admin/useT'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import { FormState } from '../../../utils/default/FormHook'

interface CommonFormType {
  game: { game_category: 'all' | GameCategoryType }
  specify_league: SpecifyLeague[]
}

interface PropTypes<FormType extends CommonFormType> {
  context: React.Context<FormState<FormType>>
  setOpen: Dispatch<SetStateAction<boolean>>
}

const SpecifyLeagueDialogButton = function<FormType extends CommonFormType> (props: PropTypes<FormType>) {
  const { setOpen, context } = props
  const classes = useCommonStyles()
  const { t } = useT()
  const { value } = useContext(context)
  const numberString = useMemo(() => {
    return value.specify_league.length ? `(${value.specify_league.length})` : ''
  }, [value])
  if (value.game.game_category !== GameCategoryType.SPORT_LOTTERY) return null
  return (
    <Button
      onClick={() => { setOpen(true) }}
      className={classes.purpleGradualButton}
    >
      { `${t('dialog.specifyLeagueAndGame')}${numberString}` }
    </Button>
  )
}

export default SpecifyLeagueDialogButton

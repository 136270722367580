import { FeedbackFrequency } from '@golden/gdk-admin'
import TKeyType from '../../i18ns/admin/TKeyType'

const feedbackFrequencyName: { [key in FeedbackFrequency]: TKeyType } = {
  [FeedbackFrequency.RIGHT_NOW]: 'feedbackFrequency,rightNow',
  [FeedbackFrequency.DAY]: 'feedbackFrequency.day',
  [FeedbackFrequency.NO_FEEDBACK]: 'feedbackFrequency.noFeedback'
}

export default feedbackFrequencyName

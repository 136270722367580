import React, { useState, useMemo } from 'react'
import { PaginationRes, ManagerLoginHistory, AgentType, AssistantType } from '@golden/gdk-admin'
import Box from '@material-ui/core/Box'
import CoreTable from '../../default/present/CoreTable'
import DateTime from '../../default/present/DateTime'
import LoadingAndErrorFrame from '../../default/frames/LoadingAndErrorFrame'
import ScrollablePaper from '../../default/present/ScrollablePaper'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import useT from '../../../i18ns/admin/useT'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { createDefaultPaginationData, createTableData } from '../../../utils/default/TableHelper'
import { searchToRequest } from './ManagerLoginHistoryForm'
import { usePageFlow } from '../../../utils/default/PageFlowHook'
import { useRequestFromSearch, useGetDataByPayload, usePaginationClickAndChangeUrl } from '../../../utils/default/ComplexFlowHook'
import deviceName from '../../../constants/admin/deviceName'
import allRoutes from '../route/route'
import agentTypeName from '../../../constants/default/agentTypeName'
import assistantTypeName from '../../../constants/default/assistantTypeName'

interface RowType {
  id: number
  loginAt: React.ReactElement
  account: string
  ip: string
  browser: string
  os: string
  device: string
  url: string
  location: string
  role?: string
  agentType?: string
}

type IdentityType = string | undefined

const ManagerLoginHistoryTable: React.FC = () => {
  const classes = useCommonStyles()
  const { t } = useT()
  const gdk = useGDK()
  const pageFlow = usePageFlow()
  const request = useRequestFromSearch({ searchToRequest })
  const [list, setList] = useState<PaginationRes<ManagerLoginHistory[]>>(createDefaultPaginationData([]))
  const [identity, setIdentity] = useState<IdentityType>()
  useGetDataByPayload({
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    payload: request!,
    gdkFunc: (payload) => gdk.history.getManagerLoginHistory(payload),
    gdkFuncDependencies: [gdk],
    onBeforeFetch: pageFlow.setLoadingStart,
    onSuccess: (res: PaginationRes<ManagerLoginHistory[]> & { identity?: 'admin' | 'agent' }) => {
      setList(res)
      setIdentity(res.identity)
      pageFlow.setContentShow()
    },
    onError: pageFlow.setGDKError,
    canLoadData: request !== undefined
  })
  const tableClasses = useMemo(() => ({
    head: classes.pinkTableHead,
    row: classes.tableRow,
    cellHead: classes.tableCellHead
  }), [classes])
  const rows: RowType[] = useMemo(() => {
    return list.data.map((item, index) => {
      return {
        id: index,
        loginAt: (<DateTime time={item.login_at} />),
        account: item.account,
        ip: item.ip,
        browser: item.browser,
        os: item.os,
        device: t(deviceName[item.device]),
        url: item.login_url,
        location: `${item.country}${item.state ? '/' + item.state : ''}${item.city ? '/' + item.city : ''}`,
        agentType: t(agentTypeName[Number(item.agent_type) as AgentType]) + (item.sub_type ? `(${t(assistantTypeName[Number(item.sub_type) as AssistantType])})` : ''),
        role: item.role
      }
    })
  }, [list.data, t])
  const data = useMemo(() => {
    return createTableData<RowType>(
      {
        id: {
          label: '',
          value: 'id'
        },
        loginAt: {
          label: t('common.loginAt'),
          value: 'loginAt',
          align: 'center'
        },
        account: {
          label: t('common.account'),
          value: 'account',
          align: 'center'
        },
        ip: {
          label: t('common.loginIpAddress'),
          value: 'ip',
          align: 'center'
        },
        browser: {
          label: t('common.browser'),
          value: 'browser',
          align: 'center'
        },
        os: {
          label: t('common.os'),
          value: 'os',
          align: 'center'
        },
        device: {
          label: t('common.device'),
          value: 'device',
          align: 'center'
        },
        url: {
          label: t('common.loginUrl'),
          value: 'url',
          align: 'center'
        },
        location: {
          label: `${t('common.country')}/${t('common.province')}/${t('common.city')}`,
          value: 'location',
          align: 'center'
        },
        role: {
          label: `${t('common.role')}`,
          value: 'role',
          align: 'center'
        },
        agentType: {
          label: t('common.accountType'),
          value: 'agentType',
          align: 'center'
        }
      },
      [
        'loginAt',
        'account',
        (identity === 'admin' ? 'role' : 'agentType'),
        'ip',
        'browser',
        'os',
        'device',
        'url',
        'location'
      ],
      rows,
      'id'
    )
  }, [rows, t, identity])

  const handlePagination = usePaginationClickAndChangeUrl({
    request,
    encodePath: allRoutes.managerLoginHistory.encodePath
  })

  if (request === undefined) return null

  return (
    <ScrollablePaper marginX={5}>
      <Box padding={4}>
        <LoadingAndErrorFrame { ...pageFlow.status }>
          <CoreTable
            classes={tableClasses}
            data={data}
            total={list.total}
            showPagination
            page={request.page}
            onChangePage={handlePagination}
          />
        </LoadingAndErrorFrame>
      </Box>
    </ScrollablePaper>
  )
}

export default React.memo(ManagerLoginHistoryTable)

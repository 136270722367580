import { AgentWalletTransferType } from '@golden/gdk-admin'
import TKeyType from '../../i18ns/admin/TKeyType'

const agentWalletTransferTypeName: { [key in AgentWalletTransferType]: TKeyType } = {
  [AgentWalletTransferType.DEPOSIT]: 'agentWalletTransferType.deposit',
  [AgentWalletTransferType.TRANSFER]: 'agentWalletTransferType.transfer',
  [AgentWalletTransferType.WITHDRAW]: 'agentWalletTransferType.withdraw'
}

export default agentWalletTransferTypeName

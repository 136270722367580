import { AgentType } from '@golden/gdk-admin'
import agentTypeName from '../../constants/default/agentTypeName'
import TKeyType from '../../i18ns/admin/TKeyType'

export function getTitleOfNextLayer (type: AgentType): TKeyType {
  if (type !== AgentType.STAFF) {
    return agentTypeName[(type + 1) as AgentType]
  } else {
    return 'common.player'
  }
}

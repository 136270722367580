import React, { createContext, useEffect } from 'react'
import { useEditor, EditorContent, Editor, getEditorExtensions, defaultEditorState, EditorState, TextContentType, getCore } from '@golden/tiptap-react'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core'
import { useInternValue } from '../../../../utils/default/FormHook'
import TextEditorToolbar, { ControlType, BuiltInControlType } from './TextEditorToolbar'
import { isEqual } from '@golden/utils'
import useT from '../../../../i18ns/admin/useT'

interface StyleTypes {
  height?: string | number
  error?: boolean
}

const useStyles = makeStyles((theme) => ({
  root: (props: StyleTypes) => ({
    border: props.error ? `1px solid ${theme.palette.error.main}` : `1px solid ${theme.palette.grey[300]}`,
    overflow: 'hidden',
    position: 'relative'
  }),
  helperText: (props: StyleTypes) => ({
    ...theme.typography.caption,
    lineHeight: '1rem',
    marginTop: theme.spacing(1),
    color: props.error ? theme.palette.error.main : theme.palette.text.primary
  }),
  placeholder: {
    '&::before': {
      content: 'attr(data-placeholder)',
      float: 'left',
      color: '#ced4da',
      pointerEvents: 'none',
      height: 0
    }
  },
  hidden: {
    display: 'none'
  },
  editorWrapper: {
    position: 'relative'
  },
  editorDisabled: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    background: 'rgba(255, 255, 255, 0.25)',
    color: '#808080',
    fontSize: '24px'
  },
  content: {
    padding: '30px 24px',
    overflowY: 'auto',
    fontSize: '1rem',
    '& > div:focus': {
      outline: 'none'
    },
    '& > .ProseMirror': {
      height: 450
    },
    '& table': {
      borderCollapse: 'collapse',
      width: '100%',
      '& th, & td': {
        minWidth: '30px',
        textAlign: 'left',
        border: '2px solid #e0e0e0',
        padding: '3px 5px'
      },
      '& th': {
        backgroundColor: '#f4f4f4'
      },
      '& .selectedCell': {
        backgroundColor: '#aaaaaa'
      }
    },
    '& section': {
      padding: 8,
      border: '1px solid #aaaaaa',
      '& + section': {
        marginTop: 8
      }
    }
  }
}))

export const EditorContext = createContext({ editor: null as Editor | null })

export interface PropTypes {
  value?: EditorState
  controls: BuiltInControlType[]
  textContentType?: TextContentType[]
  colors?: string[]
  error?: boolean
  helperText?: string
  placeholder?: string
  onChange?: (value: EditorState) => void
  extendControls?: ControlType[]
  showDisabledTip?: boolean
  disabled?: boolean
}

export const TextEditor: React.FC<PropTypes> = (props) => {
  const { t } = useT()
  const classes = useStyles(props)
  const { helperText, placeholder = '', value, onChange, disabled = false, showDisabledTip = false } = props
  const [internValue, setInternValue] = useInternValue(value ?? defaultEditorState, value)

  const getExtensions = async () => {
    const core = await getCore()
    return await getEditorExtensions(core, { placeholder: () => placeholder, emptyEditorClass: classes.placeholder })
  }

  const editor = useEditor({
    extensions: getExtensions,
    content: internValue.content,
    editable: !disabled,
    onTransaction: ({ editor }) => {
      const newValue = {
        position: { from: editor.state.selection.from, to: editor.state.selection.to },
        content: editor.getJSON()
      }
      if (isEqual(newValue, internValue)) return
      setInternValue(newValue)
      if (onChange) onChange(newValue)
    },
    onUpdate: ({ editor }) => {
      const newValue = {
        position: { from: editor.state.selection.from, to: editor.state.selection.to },
        content: editor.getJSON()
      }
      if (isEqual(newValue, internValue)) return
      setInternValue(newValue)
      if (onChange) onChange(newValue)
    }
  })

  useEffect(
    () => {
      if (!editor) return
      const editorValue = {
        content: editor.getJSON(),
        position: { from: editor.state.selection.from, to: editor.state.selection.to }
      }
      if (!isEqual(editorValue.content, internValue.content)) {
        editor.commands.setContent(internValue.content)
        editor.commands.setTextSelection(editorValue.position)
      } else if (!isEqual(editorValue.position, internValue.position)) {
        editor.commands.setTextSelection(internValue.position)
      }
    },
    [internValue, editor]
  )

  if (!editor) return null

  return (
    <React.Fragment>
      <Paper
        variant="outlined"
        className={classes.root}
      >
        <EditorContext.Provider value={{
          editor
        }}>
          <TextEditorToolbar {...props}/>
          <div className={classes.editorWrapper} >
            <EditorContent
              className={classes.content}
              editor={editor}
            />
            {disabled && showDisabledTip && (<div className={classes.editorDisabled}>{t('common.disabledEditTip')}</div>)}
          </div>
        </EditorContext.Provider>
      </Paper>
      {helperText && (<div className={classes.helperText}>{helperText}</div>)}
    </React.Fragment>
  )
}

export default React.memo(TextEditor)

import React from 'react'
import Box from '@material-ui/core/Box'
import UserStatisticTable from './UserStatisticTable'
import BetHistoryStatisticTable from './BetHistoryStatisticTable'

const PlayerReportCashAccountingTable: React.FC = () => {
  return (
    <React.Fragment>
      <Box marginBottom={3}>
        <UserStatisticTable />
      </Box>
      <BetHistoryStatisticTable />
    </React.Fragment>
  )
}

export default React.memo(PlayerReportCashAccountingTable)

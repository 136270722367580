import React from 'react'
import { SvgIconProps } from '@material-ui/core/SvgIcon'
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount'
import IconWithBadge from '../../default/present/IconWithBadge'
import useGDKStore from '../../../providers/admin/gdk/useGDKStore'

const PeopleIcon: React.FC<SvgIconProps> = (props) => {
  const me = useGDKStore.admin.me()
  const has = me?.has_newcomer ?? false
  const badgeContent = has ? 1 : 0
  return (
    <IconWithBadge
      icon={SupervisorAccountIcon}
      badgeContent={badgeContent}
      {...props}
    />
  )
}

export default React.memo(PeopleIcon)

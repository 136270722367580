import React, { useMemo } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import Box from '@material-ui/core/Box'
import { useRequestFromSearch } from '../../../utils/default/ComplexFlowHook'
import { Path } from '../../../components/admin/route/route'
import AdminReportProfitLayerTable from '../../../components/admin/adminReport/AdminReportProfitLayerTable'
import AdminReportProfitGoldenTable from '../../../components/admin/adminReport/AdminReportProfitGoldenTable'
import { parsePath } from '../../../utils/default/RouteHelper'
import { searchToRequest } from '../../../components/admin/adminReport/AdminReportProfitForm'

const AdminReportProfitLayerPage: React.FC = () => {
  const request = useRequestFromSearch({ searchToRequest })
  const location = useLocation()
  const id = useMemo(() => {
    return parsePath(location.search, location.pathname, Path.ADMIN_REPORT_PROFIT_LAYER).param.id
  }, [location.pathname, location.search])
  if (!request) return (<Navigate to={Path.ADMIN_REPORT_PROFIT} />)
  return (
    <Box paddingY={6}>
      {id !== 'RMB' && (
        <AdminReportProfitLayerTable />
      )}
      {id === 'RMB' && (
        <AdminReportProfitGoldenTable />
      )}
    </Box>
  )
}

export default React.memo(AdminReportProfitLayerPage)

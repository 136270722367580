import { AgentAccountType } from '@golden/gdk-admin'
import TKeyType from '../../i18ns/admin/TKeyType'

const agentAccountTypeName: { [key in AgentAccountType]: TKeyType } = {
  [AgentAccountType.EMPEROR]: 'agent.emperor',
  [AgentAccountType.BOSS]: 'agent.boss',
  [AgentAccountType.MANAGER]: 'agent.manager',
  [AgentAccountType.LEADER]: 'agent.leader',
  [AgentAccountType.STAFF]: 'agent.staff',
  [AgentAccountType.USER]: 'common.player'
}

export default agentAccountTypeName

import { ManualNotificationQueuesStatusType } from '@golden/gdk-admin'
import TKeyType from '../../i18ns/admin/TKeyType'

const manualNotificationQueuesStatusName: { [key in ManualNotificationQueuesStatusType]: TKeyType } = {
  [ManualNotificationQueuesStatusType.WAITING]: 'manualProcessStatus.pending',
  [ManualNotificationQueuesStatusType.PROCESSING]: 'manualProcessStatus.processing',
  [ManualNotificationQueuesStatusType.FAILED]: 'manualProcessStatus.failed'
}

export default manualNotificationQueuesStatusName

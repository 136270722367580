import React, { useCallback, useState } from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import ClearIcon from '@material-ui/icons/Clear'
import useT from '../../../i18ns/admin/useT'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { usePageFlow } from '../../../utils/default/PageFlowHook'
import { getServerUrl } from '../../../utils/default/StageHelper'
import { useInternValue } from '../../../utils/default/FormHook'
import { getTargetURL } from '@golden/gdk-admin'

const useStyles = makeStyles(() => ({
  fileInput: {
    display: 'none'
  },
  button: {
    whiteSpace: 'nowrap',
    marginLeft: '15px'
  },
  imageContainer: {
    position: 'relative'
  },
  image: {
    width: '100%'
  },
  clear: {
    position: 'absolute',
    top: '5px',
    right: '5px',
    cursor: 'pointer'
  }
}))

export interface FileImageFieldValue {
  id?: number
  url: string
}

export interface PropTypes {
  label: string
  accept: string
  value?: FileImageFieldValue
  defaultValue?: FileImageFieldValue
  onChange?: (value: FileImageFieldValue | null) => void
  helperText?: string
  buttonText?: string
  error?: boolean
  required?: boolean
  fullWidth?: boolean
  disabled?: boolean
  fileSizeBytesLimitation?: number
  fileSizeLimitedText?: string
}

const FileImageField: React.FC<PropTypes> = (props) => {
  const classes = useStyles()
  const commonClasses = useCommonStyles()
  const gdk = useGDK()
  const pageFlow = usePageFlow()
  const { defaultValue, value, label, accept, required, error, helperText = '', fullWidth, buttonText, onChange, disabled = false, fileSizeBytesLimitation, fileSizeLimitedText } = props
  const { t } = useT()
  const [internValue, setInternValue] = useInternValue<FileImageFieldValue | null>(defaultValue ?? value ?? null, value)
  const [hitSizeLimit, setHitSizeLimit] = useState<string>('')
  const fileRef = React.createRef<HTMLInputElement>()
  const handleClick = useCallback(() => {
    if (fileRef.current) fileRef.current.click()
  }, [fileRef])
  const handleUploadImage = useCallback((event) => {
    pageFlow.setLoadingStart()
    if (fileSizeBytesLimitation) {
      if (event.target.files[0].size > fileSizeBytesLimitation) {
        setHitSizeLimit(t('dialog.imageSizeLimitation', { file_size: fileSizeLimitedText }))
        return
      }
    }
    gdk.activity.uploadImage(event.target.files[0]).subscribe({
      next: (res: { id: number, url: string }) => {
        setInternValue(res)
        if (onChange) onChange(res)
        pageFlow.setContentShow()
      },
      error: (error) => {
        pageFlow.setGDKError(error)
      },
      complete: () => {
        setHitSizeLimit('')
      }
    })
  }, [pageFlow, gdk, onChange, setInternValue, fileSizeBytesLimitation, fileSizeLimitedText, t])
  return (
    !internValue
      ? (
      <Box display="flex" flexDirection="row" alignItems="baseline">
        <TextField
          disabled
          label={label}
          required={required}
          error={hitSizeLimit !== '' || error}
          helperText={(hitSizeLimit === '') ? helperText : hitSizeLimit}
          fullWidth={fullWidth}
        />
        <Button
          onClick={handleClick}
          className={clsx(commonClasses.purpleGradualButton, classes.button)}
          disabled={disabled}
        >
          { buttonText ?? t('common.importFile') }
        </Button>
        <input
          onChange={handleUploadImage}
          className={classes.fileInput}
          type="file"
          accept={accept}
          ref={fileRef}
        />
      </Box>
        )
      : (
        <div className={classes.imageContainer}>
          { !disabled && (<ClearIcon className={classes.clear} onClick={() => {
            setInternValue(null)
            if (onChange) onChange(null)
          }}/>) }
          <img
            className={classes.image}
            src={getTargetURL(getServerUrl('image'), internValue.url)}
            alt={getTargetURL(getServerUrl('image'), internValue.url)}
          />
        </div>
        )
  )
}

export default React.memo(FileImageField)

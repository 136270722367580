import React, { createContext, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import MuiTextField, { TextFieldProps } from '@material-ui/core/TextField'
import MuiButton from '@material-ui/core/Button'
import SearchIcon from '@material-ui/icons/Search'
import FormStateProvider from '../../default/form/FormStateProvider'
import FormField from '../../default/form/FormField'
import FormSubmitButton from '../../default/form/FormSubmitButton'
import { AccountEmperorRequest, searchToRequest, initialSort } from '../../../views/admin/account/AccountEmperorPage'
import { useRequestFromSearch, useChangeUrlSubmit } from '../../../utils/default/ComplexFlowHook'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import { createDefaultFormState, FormValidation, ValueGetter } from '../../../utils/default/FormHook'
import { getValueFromChangeEvent, convertEmptyToUndefined } from '../../../utils/default/FormHelper'
import allRoute, { Path } from '../route/route'
import useT from '../../../i18ns/admin/useT'
import { useChecker } from '../../../utils/admin/AdminRouteHook'
import AccountEmperorFeeDialog from './AccountEmperorFeeDialog'
import { isUndefined, omitBy } from '@golden/utils'

interface AccountEmperorFormType {
  account: string
}

const formToRequest = (form: AccountEmperorFormType): AccountEmperorRequest => {
  const data = {
    page: 1,
    ...initialSort(),
    account: convertEmptyToUndefined(form.account)
  } as AccountEmperorRequest
  return omitBy(data, isUndefined) as AccountEmperorRequest
}

const validation: FormValidation<AccountEmperorFormType> = {
  account: []
}

const getValueFromEvent: ValueGetter<AccountEmperorFormType> = {
  account: getValueFromChangeEvent
}

const FormContext = createContext(createDefaultFormState({
  account: ''
}))

const TextField = React.memo(MuiTextField)

const Button = React.memo(MuiButton)

const AccountEmperorForm: React.FC = () => {
  const classes = useCommonStyles()
  const { t } = useT()
  const request = useRequestFromSearch({ searchToRequest })
  const defaultForm = useMemo(() => ({ account: request?.account ?? '' }), [request])
  const handleSubmit = useChangeUrlSubmit({
    toAddNowTimestamp: true,
    formToRequest,
    encodePath: allRoute.accountEmperor.encodePath
  })
  const writable = useChecker()

  const [dialogOpen, setDialogOpen] = useState(false)
  return (
    <Grid container justifyContent="flex-end">
      <FormStateProvider
        context={FormContext}
        defaultValue={defaultForm}
        onSubmit={handleSubmit}
        getValueFromEvent={getValueFromEvent}
        validation={validation}
      >
        <Grid container spacing={2} alignItems="flex-end">
          <Grid item>
            <Grid container spacing={1} alignItems="flex-end">
              <Grid item>
                <SearchIcon />
              </Grid>
              <Grid item>
                <FormField<AccountEmperorFormType, TextFieldProps>
                  context={FormContext}
                  component={TextField}
                  name="account"
                  placeholder={t('placeholder.inputSearchAccount')}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <FormSubmitButton
              context={FormContext}
              component={Button}
              className={classes.pinkGradualButton}
              type="submit"
            >
              {t('common.search')}
            </FormSubmitButton>
          </Grid>
          <Grid item>
            <MuiButton
              classes={{ disabled: classes.disabledButton }}
              className={classes.purpleGradualButton}
              onClick={() => setDialogOpen(true)}
            >
              {t('common.financeFee')}
            </MuiButton>
          </Grid>
          {writable && (
            <Grid item>
              <MuiButton
                classes={{ disabled: classes.disabledButton }}
                className={classes.purpleGradualButton}
                component={Link}
                to={Path.ACCOUNT_EMPEROR_CREATE}
              >
                {t('common.createAccount')}
              </MuiButton>
            </Grid>
          )}
        </Grid>
      </FormStateProvider>
      { dialogOpen && (<AccountEmperorFeeDialog handleClose={() => setDialogOpen(false)} />) }
    </Grid>
  )
}

export default React.memo(AccountEmperorForm)

import React, { createContext, Dispatch, SetStateAction } from 'react'
import Box from '@material-ui/core/Box'
import DepositCrawlerForm from '../../../components/admin/deposit/DepositCrawlerForm'
import DepositCrawlerTable from '../../../components/admin/deposit/DepositCrawlerTable'
import { DepositCrawler } from '@golden/gdk-admin'
import StateProvider from '../../../providers/default/StateProvider'
import DepositCrawlerDialog from '../../../components/admin/deposit/DepositCrawlerDialog'
import { useReload } from '../../../utils/default/ComplexFlowHook'

export const DialogContext = createContext<[
  { item: DepositCrawler | null },
  Dispatch<SetStateAction<{ item: DepositCrawler | null }>>
]>([
  { item: null },
  () => {}
])

const DepositCrawlerPage: React.FC = () => {
  const { reload, reloadFlag } = useReload()
  return (
    <StateProvider<{ item: DepositCrawler | null }>
      context={DialogContext}
      defaultValue={{ item: null }}
    >
      <Box paddingY={5}>
        <Box paddingX={5}>
          <DepositCrawlerForm />
        </Box>
        <Box paddingTop={3}>
          <DepositCrawlerTable reloadFlag={reloadFlag} reload={reload} />
        </Box>
      </Box>
      <DepositCrawlerDialog reload={reload} />
    </StateProvider>
  )
}

export default React.memo(DepositCrawlerPage)

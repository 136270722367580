import { JumpShipStatusType } from '@golden/gdk-admin'

const jumpShipStatusColor: { [key in JumpShipStatusType]: string } = {
  [JumpShipStatusType.IN_PROCESS]: '#b86fff',
  [JumpShipStatusType.SUCCESSFUL]: '#72d476',
  [JumpShipStatusType.FAIL]: '#f44336',
  [JumpShipStatusType.CANCEL]: '#f44336',
  [JumpShipStatusType.PENDING]: '#b86fff'
}

export default jumpShipStatusColor

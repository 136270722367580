import React from 'react'
import Box from '@material-ui/core/Box'
import AccountAgentOperationLogForm from '../../../components/admin/account/AccountAgentOperationLogForm'
import AccountAgentOperationLogTable from '../../../components/admin/account/AccountAgentOperationLogTable'

const AccountAgentOperationLogPage: React.FC = () => {
  return (
    <Box paddingY={5}>
      <Box paddingX={5}>
        <AccountAgentOperationLogForm />
      </Box>
      <Box paddingTop={3}>
        <AccountAgentOperationLogTable />
      </Box>
    </Box>
  )
}

export default React.memo(AccountAgentOperationLogPage)

import React from 'react'
import { Link } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import useT from '../../../i18ns/admin/useT'
import { useDialogHandleClick } from '../../../utils/default/ComplexFlowHook'
import useGlobalDialog from '../../../providers/admin/dialog/useGlobalDialog'
import { createGlobalDialogConfig } from '../../../utils/default/DialogHelper'
import useGDK from '../../../providers/admin/gdk/useGDK'
import allRoute from '../route/route'
import { PiggyBank } from '@golden/gdk-admin'

interface PropTypes {
  item: PiggyBank
  reload: () => void
}

const FinancePiggyBankButtons: React.FC<PropTypes> = (props) => {
  const { item, reload } = props
  const classes = useCommonStyles()
  const globalDialog = useGlobalDialog()
  const gdk = useGDK()
  const { t } = useT()
  const handleStart = useDialogHandleClick({
    dialogId: `startPiggyBank-${item.id}`,
    globalDialog,
    changeDialogConfig: createGlobalDialogConfig({
      showIcon: false,
      message: (
        <React.Fragment>
          {t('dialog.confirmStartPiggyBank')}
          <span className={classes.purpleWord}>{item.name} {item.real_name}</span>
        </React.Fragment>
      )
    }),
    successDialogConfig: createGlobalDialogConfig({
      showIcon: false,
      showCancel: false,
      message: t('dialog.startPiggyBankSuccess')
    }),
    getFailDialogConfig: (error) => createGlobalDialogConfig({
      showIcon: true,
      variant: 'error',
      showCancel: false,
      message: error
    }),
    payload: item.id,
    gdkFunc: (payload) => gdk.finance.togglePiggyBank(payload, { is_active: true }),
    gdkFuncDependencies: [gdk],
    onSuccess: () => reload()
  })
  const handleStop = useDialogHandleClick({
    dialogId: `stopPiggyBank-${item.id}`,
    globalDialog,
    changeDialogConfig: createGlobalDialogConfig({
      showIcon: false,
      message: (
        <React.Fragment>
          {t('dialog.confirmStopPiggyBank')}
          <span className={classes.purpleWord}>{item.name} {item.real_name}</span>
        </React.Fragment>
      )
    }),
    successDialogConfig: createGlobalDialogConfig({
      showIcon: false,
      showCancel: false,
      message: t('dialog.stopPiggyBankSuccess')
    }),
    getFailDialogConfig: (error) => createGlobalDialogConfig({
      showIcon: true,
      variant: 'error',
      showCancel: false,
      message: error
    }),
    payload: item.id,
    gdkFunc: (payload) => gdk.finance.togglePiggyBank(payload, { is_active: false }),
    gdkFuncDependencies: [gdk],
    onSuccess: () => reload()
  })
  /* const handleDelete = useDialogHandleClick({
    dialogId: `deletePiggyBank-${item.id}`,
    globalDialog,
    changeDialogConfig: createGlobalDialogConfig({
      showIcon: false,
      message: (
        <React.Fragment>
          {t('dialog.confirmDeleteBank')}
          <span className={classes.purpleWord}>{item.name} {item.real_name}</span>
        </React.Fragment>
      )
    }),
    successDialogConfig: createGlobalDialogConfig({
      showIcon: false,
      showCancel: false,
      message: t('dialog.deleteBankSuccess')
    }),
    getFailDialogConfig: (error) => createGlobalDialogConfig({
      showIcon: true,
      variant: 'error',
      showCancel: false,
      message: error
    }),
    payload: item.id,
    gdkFunc: (payload) => gdk.finance.deletePiggyBank(payload),
    gdkFuncDependencies: [gdk],
    onSuccess: () => reload()
  }) */
  return (
    <Grid container direction="column" justify="center" spacing={2}>
      <Grid item>
        <Button
          component={Link}
          to={allRoute.financePiggyBankUpdate.encodePath({ param: { id: item.id }, search: {} })}
          className={classes.blueGradualOutlineButton}
        >
          {t('common.update')}
        </Button>
      </Grid>
      {item.is_active
        ? (
        <Grid item>
          <Button
            className={classes.greyOutlineButton}
            onClick={handleStop}
          >
            {t('common.toggleOff')}
          </Button>
        </Grid>
          )
        : (
        <Grid item>
          <Button
            className={classes.greenGradualOutlineButton}
            onClick={handleStart}
          >
            {t('common.toggleOn')}
          </Button>
        </Grid>
          )}
      {/*
      <Grid item>
        <Button
          className={classes.pinkGradualOutlineButton}
          onClick={handleDelete}
        >
          {t('common.delete')}
        </Button>
      </Grid>
      */}
    </Grid>
  )
}

export default React.memo(FinancePiggyBankButtons)

import React, { createContext, useState, useMemo, useContext, useCallback, useEffect, ChangeEvent } from 'react'
import clsx from 'clsx'
import { useLocation, useParams } from 'react-router'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import TextField, { TextFieldProps } from '@material-ui/core/TextField'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import MuiButton from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import LoadingAndErrorFrame from '../../../components/default/frames/LoadingAndErrorFrame'
import { useCommonStyles, useDetailStyles, useDialogStyles } from '../../../utils/admin/StyleHook'
import useT from '../../../i18ns/admin/useT'
import useGlobalDialog from '../../../providers/admin/dialog/useGlobalDialog'
import { parsePath } from '../../../utils/default/RouteHelper'
import allRoute, { Path } from '../../../components/admin/route/route'
import { useRedirectHandleBack, useDialogHandleSubmit, useGetDataByParams, InitialFormFunc, useGetDataByPayload } from '../../../utils/default/ComplexFlowHook'
import { createGlobalDialogConfig } from '../../../utils/default/DialogHelper'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { usePageFlow } from '../../../utils/default/PageFlowHook'
import { BannerNotificationContentType, BannerNotificationListType, UserRmbLineType, BannerNotificationForm, BannerNotificationTargetType, BannerNotificationCsvPreviewRes, GDKError, ErrorCode, AgentType } from '@golden/gdk-admin'
import FormStateProvider from '../../../components/default/form/FormStateProvider'
import FormField from '../../../components/default/form/FormField'
import FormSubmitButton from '../../../components/default/form/FormSubmitButton'
import RequiredText from '../../../components/default/form/RequiredText'
import { getValueFromValue, getValueFromChangeEvent, convertEmptyToUndefined, getTimeFromDateInputValue } from '../../../utils/default/FormHelper'
import { ValueGetter, createDefaultFormState, FormValidation } from '../../../utils/default/FormHook'
import { createValidateNotEmpty, createCorrectResult, createValidateStartAtWithEndAt, createErrorResult, createValidateUrl } from '../../../utils/default/Validator'
import CustomThemeType from '../../../themes/admin/CustomThemeType'
import { isUndefined, omitBy, times } from '@golden/utils'
import DateInput, { PropTypes as DateInputProps, DateInputValue } from '../../../components/default/form/DateInput'
import DropDown, { PropTypes as DropDownProps } from '../../../components/default/form/DropDown'
import { getTime } from 'date-fns'
import { formatDateTime } from '../../../utils/default/TableHelper'
import FileInputButton from '../../../components/admin/FileInputButton'
import BannerNotificationAgentCheckboxGroup, {
  PropTypes as BannerNotificationAgentCheckboxGroupProps
} from '../../../components/admin/forestage/BannerNotificationAgentCheckboxGroup'
import RadioGroup from '../../../components/default/form/RadioGroup'
import agentTypeName from '../../../constants/default/agentTypeName'

const useStyles = makeStyles((theme: CustomThemeType) => ({
  table: {
    minWidth: 400
  },
  button: {
    background: theme.palette.common.white,
    color: theme.custom.palette.buttonText.blue,
    border: `1px solid ${theme.custom.palette.buttonText.blue}`,
    '&:hover': {
      background: theme.custom.palette.button.blue,
      color: theme.palette.common.white
    },
    '&.active': {
      background: theme.custom.palette.button.blue,
      color: theme.palette.common.white
    }
  },
  list: {
    listStyle: 'none',
    lineHeight: '1.1876em',
    padding: '18.5px 15px',
    margin: 0,
    color: '#e7e7e7'
  },
  textarea: {
    paddingLeft: '30px',
    resize: 'none'
  },
  head: {
    width: 100
  },
  textareaBox: {
    minHeight: 200,
    maxHeight: 400,
    overflow: 'auto'
  }
}))

interface FormType {
  target: BannerNotificationTargetType
  title: string
  subTitle: string
  linkType: BannerNotificationContentType
  content: string
  time: DateInputValue
  listType: BannerNotificationListType
  accounts: string[]
  agentAccount: string
  userType: UserRmbLineType
  agentTypes: AgentType[]
  sendCounts: number
}

const getAgents = (): AgentType[] => {
  return Object.keys(agentTypeName)
    .map((key) => Number(key))
    .filter((key) => key >= AgentType.EMPEROR && key <= AgentType.STAFF)
}

const formToRequest = (form: FormType): BannerNotificationForm => {
  const converted = {
    target: form.target,
    title: form.title,
    sub_title: convertEmptyToUndefined(form.subTitle),
    type: form.linkType,
    content: form.content,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    start_at: getTime(form.time.start!),
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    end_at: getTime(form.time.end!),
    list_type: form.listType,
    accounts: form.accounts?.length ? form.accounts : undefined,
    agent_account: form.listType === BannerNotificationListType.BY_AGENT ? form.agentAccount : undefined,
    user_type: form.listType === BannerNotificationListType.BY_USER_TYPE ? form.userType : undefined,
    agent_types: form.listType === BannerNotificationListType.BY_AGENT_TYPE ? form.agentTypes : undefined
  } as BannerNotificationForm
  return omitBy(converted, isUndefined) as BannerNotificationForm
}

const getValueFromEvent: ValueGetter<FormType> = {
  target: getValueFromChangeEvent,
  title: getValueFromChangeEvent,
  subTitle: getValueFromChangeEvent,
  linkType: getValueFromChangeEvent,
  content: getValueFromChangeEvent,
  time: getTimeFromDateInputValue,
  listType: getValueFromValue,
  accounts: getValueFromChangeEvent,
  agentAccount: getValueFromChangeEvent,
  userType: getValueFromChangeEvent,
  agentTypes: getValueFromValue,
  sendCounts: getValueFromChangeEvent

}

const initialForm: InitialFormFunc<FormType> = (defaultForm) => ({
  target: BannerNotificationTargetType.USER,
  title: '',
  subTitle: '',
  linkType: BannerNotificationContentType.SURVEY_ID,
  content: '',
  time: {
    start: null,
    end: null
  },
  listType: BannerNotificationListType.BY_AGENT,
  accounts: [],
  agentAccount: '',
  userType: UserRmbLineType.ALL,
  agentTypes: [],
  sendCounts: 0,
  ...defaultForm
})

export const FormContext = createContext(createDefaultFormState(initialForm()))

const Button = React.memo(MuiButton)

const AccountsInput: React.FC = React.memo(() => {
  const classes = useStyles()
  const { value } = useContext(FormContext)

  const accountsAsMultilineValue = useMemo(() => (value.accounts ? value.accounts.join('\n') : ''), [value.accounts])

  return (
    <Box
      position='relative'
      maxHeight={400}
      overflow='auto'
      className={classes.textareaBox}
    >
      <TextField
        name="accounts"
        value={accountsAsMultilineValue}
        variant="outlined"
        placeholder={''}
        multiline
        fullWidth
        required
        minRows={value.accounts.length}
        maxRows={value.accounts.length}
        inputProps={{
          wrap: 'hard',
          className: classes.textarea
        }}
        disabled
      />
      <Box position='absolute' top={0} left={0}>
        <ul className={classes.list}>
          {
            times(value.accounts.length, (i) => (<li key={i}>{`${i + 1}.`}</li>))
          }
        </ul>
      </Box>
    </Box>
  )
})

const UserTypeInput: React.FC = React.memo(() => {
  const { t } = useT()
  const { value, handleChange } = useContext(FormContext)
  const options = useMemo(() => ([
    { name: t('common.allPlayer2'), value: UserRmbLineType.ALL },
    { name: t('common.agentsPlayer', { agent: 'RMB' }), value: UserRmbLineType.RMB_LINE },
    { name: t('common.notAgentsPlayer', { agent: 'RMB' }), value: UserRmbLineType.NOT_RMB_LINE }
  ]), [t])

  return (
    <RadioGroup
      label=''
      value={value.userType}
      options={options}
      onChange={(event) => { handleChange('userType')(event) }}
    />
  )
})

const CsvInput: React.FC = React.memo(() => {
  const { t } = useT()
  const commonClasses = useCommonStyles()
  const gdk = useGDK()
  const pageFlow = usePageFlow()
  const globalDialog = useGlobalDialog()

  const { value, dispatch } = useContext(FormContext)
  const [csvFile, setCsvFile] = useState<File>(new File([], ''))
  const [csvCountInfo, setCsvCountInfo] = useState<BannerNotificationCsvPreviewRes>({
    total_count: 0,
    duplicated_count: 0,
    suspended_count: 0,
    not_loginable_count: 0
  })

  const csvTemplate = useMemo(() => {
    const titles = [t('common.account')]
    return `data:text/csv;charset=utf-8,%EF%BB%BF${encodeURI(titles.join(','))}`
  }, [t])
  const payload = useMemo(() => ({ target: value.target, file: csvFile }), [csvFile, value.target])

  const handleInputCsv = useCallback((file: File) => {
    setCsvFile(file)
    const reader = new FileReader()
    reader.onload = (e) => {
      if (!e.target || !e.target.result) return
      // eslint-disable-next-line @typescript-eslint/no-base-to-string
      const csvToArray = e.target.result.toString().split('\n').filter((_, index) => (index !== 0)).map((el) => (el.split(',')[0]))
      dispatch({
        type: 'change',
        label: 'accounts',
        value: csvToArray
      })
    }
    reader.readAsText(file)
  }, [dispatch])

  useGetDataByPayload({
    payload,
    gdkFunc: (payload) => gdk.notification.validateBannerNotificationCsv(payload),
    gdkFuncDependencies: [gdk],
    onBeforeFetch: pageFlow.setLoadingStart,
    onSuccess: (res: BannerNotificationCsvPreviewRes) => {
      setCsvCountInfo(res)
      pageFlow.setContentShow()
    },
    onError: (error: GDKError) => {
      pageFlow.setContentShow()
      dispatch({
        type: 'change',
        label: 'accounts',
        value: []
      })
      setCsvCountInfo({
        total_count: 0,
        duplicated_count: 0,
        suspended_count: 0,
        not_loginable_count: 0
      })
      setCsvFile(new File([], ''))
      switch (error.code) {
        case ErrorCode.WRONG_ACCOUNT_FORMAT:
          error.message = error.message.replace('{account}', error.response?.extra?.account ?? '')
          break
        case ErrorCode.FILE_EXISTS_EMPTY_COLUMN:
          error.message = error.message.replace('{column}', error.response?.extra?.column ?? '')
          break
        case ErrorCode.SOME_ACCOUNTS_NOT_FOUND:
          error.message = error.message.replace('{accounts}', error.response?.extra?.accounts ?? '')
          break
      }
      globalDialog.setConfig(createGlobalDialogConfig({
        showIcon: true,
        variant: 'error',
        showCancel: false,
        message: error.message
      }))
      globalDialog.setOpen({
        id: `previewBannerNotificationCsvFailed-${csvFile.name}`,
        value: true,
        isOK: false
      })
    },
    canLoadData: !!payload.file.size
  })

  useEffect(() => {
    if (value.accounts.length && !csvFile.size) {
      const csvStr = ['帐号'].concat(value.accounts).join('\n')
      const myBlob = new Blob([csvStr], { type: 'text/csv' })
      const myFile = new File([myBlob], 'copyCsv')
      handleInputCsv(myFile)
    }
  }, [handleInputCsv, value.accounts, csvFile.size])
  return (
    <Box paddingX={4} paddingTop={2}>
      <Box>
        <a
          href={csvTemplate}
          download={`${t('common.template')}.csv`}
          className={commonClasses.anchor}
        >
          {t('common.downloadCsvTemplate')}
        </a>
      </Box>
      <Box marginTop={2}>
        <FileInputButton
          loading={false}
          onChange={useCallback((file) => {
            handleInputCsv(file)
          }, [handleInputCsv])}
        />
      </Box>
      {!!(csvCountInfo.total_count) && (<Box marginTop={2} style={{ color: 'red' }}>
        { t(`bannerNotification.${value.target}CsvTip`, {
          total: csvCountInfo.total_count,
          duplicated: csvCountInfo.duplicated_count,
          banned: (value.target === BannerNotificationTargetType.AGENT ? csvCountInfo.not_loginable_count : csvCountInfo.suspended_count)
        })}
        <AccountsInput />
      </Box>)}
    </Box>
  )
})

const AgentInput: React.FC = React.memo(() => {
  const { t } = useT()
  const gdk = useGDK()
  const [helperText, setHelperText] = useState('')
  const { value, handleChange, error, setError, dispatch } = useContext(FormContext)
  const [dirty, setDirty] = useState<boolean>(false)

  const onChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setHelperText('')
    setDirty(true)
    handleChange('agentAccount')(event)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useEffect(() => {
    if (value.agentAccount === '' && dirty) {
      setError((error) => ({ ...error, agentAccount: t('error.mustNotEmpty') }))
    } else if (value.agentAccount !== '') {
      setError((error) => ({ ...error, agentAccount: null }))
      const subsciption = gdk.notification.validateBannerNotificationAgentAccount({ target: value.target, account: value.agentAccount }).subscribe({
        next: (res) => {
          setHelperText(t('bannerNotification.willSendTo', { count: res.count, target: t(value.target === BannerNotificationTargetType.AGENT ? 'common.agent' : 'common.player') }))
          dispatch({
            type: 'change',
            label: 'sendCounts',
            value: res.count
          })
        },
        error: () => {
          setError((error) => ({ ...error, agentAccount: t('error.accountNotFound') }))
        }
      })
      return () => subsciption.unsubscribe()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value.agentAccount, dirty, t, gdk])

  return (
    <TextField
      name="agentAccount"
      label={t('common.agentAccount2')}
      placeholder={t('common.agentAccount2')}
      value={value.agentAccount}
      onChange={onChange}
      error={error.agentAccount !== null}
      helperText={error.agentAccount ?? helperText}
      required
      fullWidth
    />
  )
})

const Inputs: React.FC = React.memo(() => {
  const { value } = useContext(FormContext)
  return (
    <React.Fragment>
      {
        value.listType === BannerNotificationListType.BY_AGENT && (<AgentInput />)
      }
      {
        value.listType === BannerNotificationListType.BY_AGENT_TYPE && (
          <FormField<FormType, BannerNotificationAgentCheckboxGroupProps>
            component={BannerNotificationAgentCheckboxGroup}
            context={FormContext}
            name="agentTypes"
            required
          />
        )
      }
      {
        value.listType === BannerNotificationListType.BY_LIST && (<CsvInput />)
      }
      {
        value.listType === BannerNotificationListType.BY_USER_TYPE && (<UserTypeInput/>)
      }
    </React.Fragment>
  )
})

const ListTypeButtons: React.FC = React.memo(() => {
  const { t } = useT()
  const classes = useStyles()

  const { value, handleChange, setError, dispatch } = useContext(FormContext)
  const resetLastValue = () => {
    if (value.listType === BannerNotificationListType.BY_AGENT) {
      dispatch({
        type: 'change',
        label: 'agentAccount',
        value: ''
      })
      setError((error) => ({ ...error, agentAccount: null }))
    }
    if (value.listType === BannerNotificationListType.BY_AGENT_TYPE) {
      dispatch({
        type: 'change',
        label: 'agentTypes',
        value: []
      })
      setError((error) => ({ ...error, agentTypes: null }))
    }
    if (value.listType === BannerNotificationListType.BY_LIST) {
      dispatch({
        type: 'change',
        label: 'accounts',
        value: ''
      })
      setError((error) => ({ ...error, accounts: null }))
    }
    if (value.listType === BannerNotificationListType.BY_USER_TYPE) {
      dispatch({
        type: 'change',
        label: 'userType',
        value: UserRmbLineType.ALL
      })
      setError((error) => ({ ...error, userType: null }))
    }
  }
  const onChange = useCallback((listTypeInput: BannerNotificationListType) => {
    if (value.listType === listTypeInput) return
    resetLastValue()
    handleChange('listType')(listTypeInput)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  return (
    <ButtonGroup size="small" aria-label="small button group">
      <Button className={ clsx(value.listType === BannerNotificationListType.BY_AGENT && 'active', classes.button) } onClick={() => onChange(BannerNotificationListType.BY_AGENT)}>{t('common.agentChain')}</Button>
      <Button className={ clsx(value.listType === BannerNotificationListType.BY_LIST && 'active', classes.button) } onClick={() => onChange(BannerNotificationListType.BY_LIST)}>{t('bannerNotification.uploadList')}</Button>
      <Button className={ clsx(value.listType === BannerNotificationListType.BY_AGENT_TYPE && 'active', value.listType === BannerNotificationListType.BY_USER_TYPE && 'active', classes.button) } onClick={() => onChange(value.target === BannerNotificationTargetType.AGENT ? BannerNotificationListType.BY_AGENT_TYPE : BannerNotificationListType.BY_USER_TYPE)}>{t('bannerNotification.specificTarget', { target: (t(value.target === BannerNotificationTargetType.AGENT ? 'common.agent' : 'common.player')) })}</Button>
    </ButtonGroup>
  )
})

const BannerNotificationCreatePage: React.FC = () => {
  const classes = useStyles()
  const commonClasses = useCommonStyles()
  const dialogClasses = useDialogStyles()
  const detailClasses = useDetailStyles()
  const { t } = useT()
  const globalDialog = useGlobalDialog()
  const gdk = useGDK()
  const params = useParams()
  const target = useMemo(() => ((params.target ?? BannerNotificationTargetType.USER) as BannerNotificationTargetType), [params])

  const location = useLocation()
  const id = useMemo(() => {
    return parsePath(location.search, location.pathname, Path.FORESTAGE_BANNER_NOTIFICATION_COPY).param.id
  }, [location.pathname, location.search])
  const [defaultForm, setDefaultForm] = useState(initialForm({ target }))
  const pageFlow = usePageFlow(!!id, !id)

  useGetDataByParams({
    path: Path.FORESTAGE_BANNER_NOTIFICATION_COPY,
    canLoadData: !!id,
    gdkFunc: () => gdk.notification.getBannerNotificationDetail(id),
    gdkFuncDependencies: [gdk],
    onBeforeFetch: pageFlow.setLoadingStart,
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    onSuccess: async (entry) => {
      pageFlow.setContentShow()

      setDefaultForm({
        target: entry.target,
        title: entry.title,
        subTitle: entry.sub_title ?? '',
        linkType: isNaN(Number(entry.content)) ? BannerNotificationContentType.LINK : BannerNotificationContentType.SURVEY_ID,
        content: entry.content,
        time: {
          start: entry.start_at,
          end: entry.end_at
        },
        listType: entry.list_type,
        accounts: entry.accounts,
        agentAccount: entry.agent_account ?? '',
        userType: entry.user_types ?? UserRmbLineType.ALL,
        agentTypes: entry.agent_types ?? [],
        sendCounts: 0
      })
    },
    onError: pageFlow.setGDKError
  })

  const [handleDebouncedBack] = useRedirectHandleBack({ path: allRoute.forestageBannerNotification.encodePath({ search: { }, param: {} }) })
  const [handleBack] = useRedirectHandleBack({ path: allRoute.forestageBannerNotification.encodePath({ search: { }, param: {} }) })
  const { handleSubmit } = useDialogHandleSubmit({
    dialogId: 'createBannerNotification',
    globalDialog,
    getChangeDialogConfig: useCallback((form: FormType) => {
      const targetPreview = (() => {
        switch (form.listType) {
          case BannerNotificationListType.BY_AGENT:
            if (form.target === BannerNotificationTargetType.AGENT) {
              return (form.sendCounts > 1) ? t('common.agentLine', { agent: form.agentAccount }) : form.agentAccount
            } else {
              return t('common.agentsPlayer', { agent: form.agentAccount })
            }
          case BannerNotificationListType.BY_LIST:
            if (form.accounts.length === 1) return form.accounts[0]
            return `${form.accounts[0]} ~ ${form.accounts[form.accounts.length - 1]}`
          case BannerNotificationListType.BY_USER_TYPE:
            switch (form.userType) {
              case UserRmbLineType.ALL:
                return t('common.allPlayer2')
              case UserRmbLineType.RMB_LINE:
                return t('common.agentsPlayer', { agent: 'RMB' })
              case UserRmbLineType.NOT_RMB_LINE:
                return t('common.notAgentsPlayer', { agent: 'RMB' })
              default:
                return ''
            }
          case BannerNotificationListType.BY_AGENT_TYPE:
            if (form.agentTypes.length === getAgents().length) return t('common.allAgent')
            return form.agentTypes.map((item) => (t(agentTypeName[item]))).join('、')
          default:
            return ''
        }
      })()

      return createGlobalDialogConfig({
        showIcon: false,
        notUseTypo: true,
        message: (
          <Box width={500} display="flex" flexDirection="column" alignItems="center">
            <Typography className={dialogClasses.text}>{t('dialog.confirmAgain')}</Typography>
            <table className={clsx(detailClasses.table, classes.table)}>
              <tbody>
                <tr>
                  <td className={clsx(detailClasses.greyHead, classes.head)}>
                    {t('common.title')}
                  </td>
                  <td className={detailClasses.cell}>
                    {form.title}
                  </td>
                </tr>
                <tr>
                  <td className={clsx(detailClasses.greyHead, classes.head)}>
                    {t('common.subTitle')}
                  </td>
                  <td className={detailClasses.cell}>
                    {form.subTitle}
                  </td>
                </tr>
                <tr>
                  <td className={clsx(detailClasses.greyHead, classes.head)}>
                    {form.linkType === BannerNotificationContentType.SURVEY_ID ? t('common.surveyId') : t('common.link')}
                  </td>
                  <td className={detailClasses.cell}>
                    {form.content}
                  </td>
                </tr>
                <tr>
                  <td className={clsx(detailClasses.greyHead, classes.head)}>
                    {t('common.displayTime')}
                  </td>
                  <td className={detailClasses.cell}>
                    {`${formatDateTime(form.time.start ?? new Date())} ${t('common.to')} ${formatDateTime(form.time.end ?? new Date())}`}
                  </td>
                </tr>
                <tr>
                  <td className={clsx(detailClasses.greyHead, classes.head)}>
                    {`${form.target === BannerNotificationTargetType.AGENT ? t('common.agent') : t('common.player')}${t('common.nameList')}`}
                  </td>
                  <td className={detailClasses.cell}>
                    { targetPreview }
                  </td>
                </tr>
              </tbody>
            </table>
          </Box>
        )
      })
    }, [dialogClasses.text, t, detailClasses.table, detailClasses.greyHead, detailClasses.cell, classes.table, classes.head]),
    getSuccessDialogConfig: useCallback((form: FormType, res: { successful_count: number }) => {
      const message: React.ReactElement = (
        <Box display="flex" flexDirection="column" alignItems="center">
          <Typography className={dialogClasses.text}>{t('dialog.sendSuccess2')}</Typography>
          <Box>
            { t('bannerNotification.expectSendToCount', { count: res.successful_count }) }
          </Box>
        </Box>
      )
      return createGlobalDialogConfig({
        showIcon: false,
        showCancel: false,
        message
      })
    }, [t, dialogClasses]),
    getFailDialogConfig: useCallback((error) => createGlobalDialogConfig({
      showIcon: true,
      variant: 'error',
      showCancel: false,
      message: error
    }), []),
    formToRequest,
    gdkFunc: (payload) => gdk.notification.createBannerNotification(payload),
    gdkFuncDependencies: [gdk],
    afterSuccessDialog: handleBack
  })
  const validation = useMemo(() => {
    return {
      target: [],
      title: [
        {
          func: createValidateNotEmpty('title', t),
          when: ['change', 'beforeClickSubmit']
        }
      ],
      subTitle: [],
      linkType: [],
      content: [
        {
          func: createValidateNotEmpty('content', t),
          when: ['change', 'beforeClickSubmit']
        },
        {
          func: (value, form, lastSubmitForm) => {
            if (form.linkType === BannerNotificationContentType.LINK) {
              return createValidateUrl('content', t)(value, form, lastSubmitForm, null)
            } else {
              return createCorrectResult('content')
            }
          },
          when: ['change', 'change:linkType', 'beforeClickSubmit']
        }
      ],
      time: [
        {
          func: (_value, form) => {
            if (!form.time.start && !form.time.end) {
              return createErrorResult('time', t('error.mustNotEmpty'))
            } else {
              return createCorrectResult('time')
            }
          },
          when: ['change', 'beforeClickSubmit']
        },
        {
          func: createValidateStartAtWithEndAt('time', t),
          when: ['change', 'beforeClickSubmit']
        }
      ],
      listType: [],
      agentAccount: [
        {
          func: (value, form, lastSubmitForm) => {
            if (form.listType === BannerNotificationListType.BY_AGENT) return createValidateNotEmpty('agentAccount', t)(value, form, lastSubmitForm, null)
            return createCorrectResult('agentAccount')
          },
          when: ['change', 'beforeClickSubmit']
        }
      ],
      accounts: [
        {
          func: (value, form) => {
            if (form.listType !== BannerNotificationListType.BY_LIST) return createCorrectResult('accounts')
            if (Array.isArray(value) && value.length === 0) return createErrorResult('accounts', t('error.mustNotEmpty'))
            return createCorrectResult('accounts')
          },
          when: ['change', 'beforeClickSubmit', 'change:listType']
        }
      ],
      agentTypes: [
        {
          func: (value, form) => {
            if (form.listType !== BannerNotificationListType.BY_AGENT_TYPE) return createCorrectResult('agentTypes')
            if (Array.isArray(value) && value.length === 0) return createErrorResult('agentTypes', t('error.mustNotEmpty'))
            return createCorrectResult('agentTypes')
          },
          when: ['change', 'beforeClickSubmit', 'change:listType']
        }
      ],
      userType: [],
      sendCounts: []
    } as FormValidation<FormType>
  }, [t])

  const startOption = useMemo(() => ({
    label: t('common.startDisplayTime')
  }), [t])

  const endOption = useMemo(() => ({
    label: t('common.endDisplayTime')
  }), [t])

  const linkTypeOptions = useMemo(() => (
    [
      {
        name: t('common.surveyId'),
        value: BannerNotificationContentType.SURVEY_ID
      },
      {
        name: t('common.link'),
        value: BannerNotificationContentType.LINK
      }
    ]
  ), [t])

  return (
    <Box paddingY={5} paddingX={4}>
      <LoadingAndErrorFrame {...pageFlow.status}>
        <Paper>
          <Box padding={4}>
            <Box
              paddingY={1.25}
              paddingX={2}
              marginBottom={2}
              className={commonClasses.pinkTitleBar}
            >
              <Typography variant="h5">
                {`${t('common.create')}${t('page.bannerNotification')}`}
              </Typography>
            </Box>
            <FormStateProvider<FormType>
              context={FormContext}
              defaultValue={defaultForm}
              onSubmit={handleSubmit}
              getValueFromEvent={getValueFromEvent}
              validation={validation}
            >
              <Box paddingX={4}>
                <Grid container direction="column" spacing={3}>
                  <Grid item container direction="row">
                    <Grid item xs={12}>
                      <FormField<FormType, TextFieldProps>
                        component={TextField}
                        context={FormContext}
                        name="title"
                        label={t('common.title')}
                        placeholder={t('placeholder.suggestDigitsRange', { min: 4, max: 8 })}
                        required
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                  <Grid item container direction="row">
                    <Grid item xs={12}>
                      <FormField<FormType, TextFieldProps>
                        component={TextField}
                        context={FormContext}
                        name="subTitle"
                        label={t('common.subTitle')}
                        placeholder={t('placeholder.suggestMaxDigits', { max: 13 })}
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                  <Grid item container direction="row" spacing={2}>
                    <Grid item xs={4} md={2}>
                      <FormField<FormType, DropDownProps>
                        context={FormContext}
                        component={DropDown}
                        name="linkType"
                        options={linkTypeOptions}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={8} md={10}>
                      <FormField<FormType, TextFieldProps>
                        component={TextField}
                        context={FormContext}
                        name="content"
                        required
                        fullWidth
                        margin='normal'
                      />
                    </Grid>
                  </Grid>
                  <Grid item container direction="row">
                    <Grid item xs={12}>
                      <FormField<FormType, DateInputProps>
                        context={FormContext}
                        component={DateInput}
                        name="time"
                        start={startOption}
                        end={endOption}
                        required
                      />
                    </Grid>
                  </Grid>
                  <Grid item container direction="row">
                    <Grid item xs={12}>
                      <ListTypeButtons />
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Inputs />
                  </Grid>
                  <Grid item>
                    <RequiredText />
                  </Grid>
                  <Grid item container direction="row" justifyContent="flex-end" spacing={2}>
                    <Grid item>
                      <Button
                        className={commonClasses.greyButton}
                        onClick={handleDebouncedBack}
                      >
                        {t('common.cancel')}
                      </Button>
                    </Grid>
                    <Grid item>
                      <FormSubmitButton
                        component={Button}
                        context={FormContext}
                        className={commonClasses.purpleGradualButton}
                        type="submit"
                      >
                        {t('common.confirmCreate')}
                      </FormSubmitButton>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </FormStateProvider>
          </Box>
        </Paper>
      </LoadingAndErrorFrame>
    </Box>
  )
}

export default React.memo(BannerNotificationCreatePage)

import { AdminCashLogType } from '@golden/gdk-admin'
import TKeyType from '../../i18ns/admin/TKeyType'

const adminCashLogName: { [key in AdminCashLogType]: TKeyType } = {
  [AdminCashLogType.DEPOSIT]: 'common.deposit',
  [AdminCashLogType.WITHDRAW]: 'common.withdraw',
  [AdminCashLogType.FEEDBACK]: 'common.feedback',
  [AdminCashLogType.ACTIVITY]: 'common.activity',
  [AdminCashLogType.TRANSFER_IMPORT]: 'common.transferIn',
  [AdminCashLogType.TRANSFER_EXPORT]: 'common.transferOut',
  [AdminCashLogType.OTHER]: 'common.other',
  [AdminCashLogType.AGENT_TRANSFER]: 'common.agentTransfer'
}

export default adminCashLogName

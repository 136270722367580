import React from 'react'
import { BetHistory } from '@golden/gdk-admin'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import useT from '../../../../i18ns/admin/useT'
import { useCommonStyles, useDetailStyles } from '../../../../utils/admin/StyleHook'
import { formatMoney, formatCount } from '../../../../utils/default/TableHelper'
import orderStatusName from '../../../../constants/default/orderStatusName'
import DateTime from '../../../default/present/DateTime'

export interface LotteryBetDetailPayload {
  item: BetHistory
}

interface PropTypes extends LotteryBetDetailPayload {
  onClose: () => void
}

const LotteryBetDetail: React.FC<PropTypes> = (props) => {
  const { item, onClose } = props
  const detailClasses = useDetailStyles()
  const commonClasses = useCommonStyles()
  const { t } = useT()

  const getUnitName = (num: number): string => {
    switch (num) {
      case 2:
        return `2${t('unit.dollar')}`
      case 1:
        return `1${t('unit.dollar')}`
      case 0.2:
        return `2${t('unit.dime')}`
      case 0.1:
        return `1${t('unit.dime')}`
      case 0.02:
        return `2${t('unit.cent')}`
      case 0.01:
        return `1${t('unit.cent')}`
      case 0.002:
        return `2${t('unit.milli')}`
      case 0.001:
        return `1${t('unit.milli')}`
    }
    return `${num}`
  }

  return (
    <Dialog
      open={true}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle className={detailClasses.title} disableTypography>{t('common.betDetail')}</DialogTitle>
      <DialogContent className={detailClasses.content}>
        <table className={detailClasses.table}>
          <tbody>
            <tr>
              <td className={detailClasses.head}>{t('common.serial')}</td>
              <td className={detailClasses.cell}>{item.serial_number}</td>
              <td className={detailClasses.head}>{t('common.betAt')}</td>
              <td className={detailClasses.cell}><DateTime time={item.original_created_at} /></td>
            </tr>
            <tr>
              <td className={detailClasses.head}>{t('common.publisher')}</td>
              <td className={detailClasses.cell}>{item.channel_name}</td>
              <td className={detailClasses.head}>{t('common.wanfa')}</td>
              <td className={detailClasses.cell}>
                {item.bet_type_name}
                {item.is_subscribed && (t('common.isSubscribed'))}
              </td>
            </tr>
            <tr>
              <td className={detailClasses.head}>{t('common.drawDateWanfa')}</td>
              <td className={detailClasses.cell}>{item.draw_date}</td>
              <td className={detailClasses.head}>{t('common.betCash')}</td>
              <td className={detailClasses.cell}>{formatMoney(item.cash)}</td>
            </tr>
            <tr>
              <td className={detailClasses.head}>{t('common.multiple')}</td>
              <td className={detailClasses.cell}>{item.multiple === undefined ? '' : formatCount(Number(item.multiple))}</td>
              <td className={detailClasses.head}>{t('common.betUnit')}</td>
              <td className={detailClasses.cell}>{getUnitName(Number(item.unit))}</td>
            </tr>
            <tr>
              <td className={detailClasses.head}>{t('common.betPair')}</td>
              <td className={detailClasses.cell}>{item.count_pairs === undefined ? '' : formatCount(Number(item.count_pairs))}</td>
              <td className={detailClasses.head}>{t('common.winningCash')}</td>
              <td className={detailClasses.cell}>{formatMoney(item.cash_winning)}</td>
            </tr>
            <tr>
              <td className={detailClasses.head}>{t('common.betStatus')}</td>
              <td className={detailClasses.cell}>{item.order_status ? t(orderStatusName[item.order_status]) : ''}</td>
              <td className={detailClasses.head}>{t('common.winningNumbers')}</td>
              <td className={detailClasses.cell}>{item.winning_number}</td>
            </tr>
            <tr>
              <td className={detailClasses.head}>{t('common.canWinCash')}</td>
              <td className={detailClasses.cell}>--</td>
            </tr>
          </tbody>
        </table>
        <Box paddingTop={2}>
          <table className={detailClasses.table}>
            <tbody>
              <tr>
                <td className={detailClasses.head}>{t('common.betNumbers')}</td>
                <td className={detailClasses.cell}>{item.numbers}</td>
              </tr>
            </tbody>
          </table>
        </Box>
      </DialogContent>
      <DialogActions>
        <Box display="flex" justifyContent="center" width="100%" paddingY={2}>
          <Button onClick={onClose} className={commonClasses.purpleGradualButton}>{t('common.confirm')}</Button>
        </Box>
      </DialogActions>
    </Dialog>
  )
}

export default React.memo(LotteryBetDetail)

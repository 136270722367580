import React, { useState, useMemo, useRef } from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import { InAndOutRevenue } from '@golden/gdk-admin'
import { BcMath } from '@golden/bcmath'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import { searchToRequest } from './AdminReportInAndOutForm'
import { AdminReportInAndOutReport } from '../../../views/admin/adminReport/AdminReportInAndOutPage'
import { useRequestFromSearch, useGetDataByPayload } from '../../../utils/default/ComplexFlowHook'
import useT from '../../../i18ns/admin/useT'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import ScrollablePaper from '../../default/present/ScrollablePaper'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { usePageFlow } from '../../../utils/default/PageFlowHook'
import LoadingAndErrorFrame from '../../default/frames/LoadingAndErrorFrame'
import CoreTable from '../../default/present/CoreTable'
import { formatCount, createTableData, formatMoney } from '../../../utils/default/TableHelper'
import PayoutTip from '../PayoutTip'
import useGDKStore from '../../../providers/admin/gdk/useGDKStore'
import { useSnackbar } from 'notistack'
import { mergeArray } from '@golden/utils'

const useStyles = makeStyles(() => ({
  special: {
    color: '#01c0c8'
  }
}))

interface TotalRowType {
  id: number
  others: string
  risk: string
}

interface RowType {
  id: number
  name: string
  people: string
  bet: string
  betCash: string
  winning: string
  buyback: string
  feedback: string
  revenue: string
  donate: string
}

const Tip: React.FC = React.memo(() => {
  const { t } = useT()
  return (
    <Box paddingTop={1}>
      <Typography color="error">{t('common.riskAdjustmentTip')}</Typography>
    </Box>
  )
})

const AdminReportInAndOutRevenue: React.FC = () => {
  const [res, setRes] = useState<InAndOutRevenue>({
    third_party_bet_people_count: 0,
    risk_adjustment: '0.0000',
    game_categories: [],
    games: []
  })
  const gdk = useGDK()
  const pageFlow = usePageFlow()
  const { enqueueSnackbar } = useSnackbar()
  const categories = useGDKStore.platform.categories()
  const games = useGDKStore.platform.games()
  const request = useRequestFromSearch({ searchToRequest })
  const hide = request?.reports.length !== 0 && !request?.reports.includes(AdminReportInAndOutReport.REVENUE)
  const { t } = useT()
  const classes = useStyles()
  const commonClasses = useCommonStyles()
  const tableClasses = useMemo(() => {
    return {
      head: commonClasses.greyTableHead,
      cellHead: commonClasses.tableCellHead,
      row: commonClasses.tableRow
    }
  }, [commonClasses])
  useGetDataByPayload({
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    payload: request!,
    gdkFunc: (payload) => gdk.history.getInAndOutRevenue(payload),
    gdkFuncDependencies: [gdk],
    onBeforeFetch: pageFlow.setLoadingStart,
    onSuccess: (res: InAndOutRevenue) => {
      setRes(res)
      pageFlow.setContentShow()
    },
    onError: pageFlow.setGDKError,
    canLoadData: request !== undefined && !hide
  })
  const totalRows = useMemo(() => {
    return [{
      id: 0,
      others: formatCount(res.third_party_bet_people_count),
      risk: formatMoney(res.risk_adjustment)
    }] as TotalRowType[]
  }, [res])
  const totalData = useMemo(() => {
    return createTableData<TotalRowType>(
      {
        id: {
          label: '',
          value: 'id'
        },
        others: {
          label: t('common.externalGameBetPlayer'),
          value: 'others',
          align: 'center'
        },
        risk: {
          label: t('common.riskAdjustment'),
          value: 'risk',
          align: 'center'
        }
      },
      [
        'others',
        'risk'
      ],
      totalRows,
      'id'
    )
  }, [t, totalRows])
  const categoryRows = useMemo(() => mergeArray(categories, res.game_categories, (category, item) => category.instance.id === item.category)
    .map(([category, item]) => ({
      id: category.instance.id,
      name: category.instance.name,
      people: formatCount(item.bet_people_count),
      bet: formatCount(item.bet_count),
      betCash: formatMoney(item.bet_cash),
      winning: formatMoney(item.cash_result),
      buyback: formatMoney(item.buyback_amount),
      feedback: formatMoney(item.feedback),
      revenue: formatMoney(item.revenue),
      donate: formatMoney(item.donate)
    })), [categories, res.game_categories])
  const categoryData = useMemo(() => {
    return createTableData<RowType>(
      {
        id: {
          label: '',
          value: 'id'
        },
        name: {
          label: t('common.gameType'),
          value: 'name',
          align: 'center'
        },
        people: {
          label: t('common.totalBetPlayer'),
          value: 'people',
          align: 'right'
        },
        bet: {
          label: t('common.totalBetCount'),
          value: 'bet',
          align: 'right'
        },
        betCash: {
          label: t('common.allBetCash'),
          value: 'betCash',
          align: 'right'
        },
        winning: {
          label: t('common.totalPrize'),
          value: 'winning',
          align: 'right'
        },
        buyback: {
          label: t('common.totalBuyback'),
          value: 'buyback',
          align: 'right'
        },
        feedback: {
          label: t('common.allFeedback'),
          value: 'feedback',
          align: 'right'
        },
        revenue: {
          label: t('common.betRevenue'),
          value: 'revenue',
          align: 'right'
        },
        donate: {
          label: t('common.totalDonate'),
          value: 'donate',
          align: 'right'
        }
      },
      [
        'name',
        'people',
        'bet',
        'betCash',
        'winning',
        'buyback',
        'feedback',
        'revenue',
        'donate'
      ],
      categoryRows,
      'id'
    )
  }, [t, categoryRows])
  const platformRows = useMemo(() => mergeArray(games, res.games, (game, item) => game.id === item.game_id)
    .map(([game, item]) => ({
      id: game.id,
      ...item,
      name: game.nameWithStatus,
      people: formatCount(item.bet_people_count),
      bet: formatCount(item.bet_count),
      betCash: item.bet_cash, // 後面要計算，先不 format
      winning: formatMoney(item.cash_result),
      buyback: formatMoney(item.buyback_amount),
      feedback: formatMoney(item.feedback),
      revenue: item.revenue, // 後面要計算，先不 format
      donate: formatMoney(item.donate)
    })), [res.games, games])
  const platformData = useMemo(() => {
    return createTableData<RowType>(
      {
        id: {
          label: '',
          value: 'id'
        },
        name: {
          label: t('common.gamePlatform'),
          value: 'name',
          align: 'center'
        },
        people: {
          label: t('common.totalBetPlayer'),
          value: 'people',
          align: 'right'
        },
        bet: {
          label: t('common.totalBetCount'),
          value: 'bet',
          align: 'right'
        },
        betCash: {
          label: t('common.allBetCash'),
          value: 'betCash',
          align: 'right'
        },
        winning: {
          label: t('common.totalPrize'),
          value: 'winning',
          align: 'right'
        },
        buyback: {
          label: t('common.totalBuyback'),
          value: 'buyback',
          align: 'right'
        },
        feedback: {
          label: t('common.allFeedback'),
          value: 'feedback',
          align: 'right'
        },
        revenue: {
          label: t('common.betRevenue'),
          value: 'revenue',
          align: 'right'
        },
        donate: {
          label: t('common.totalDonate'),
          value: 'donate',
          align: 'right'
        }
      },
      [
        'name',
        'people',
        'bet',
        'betCash',
        'winning',
        'buyback',
        'feedback',
        'revenue',
        'donate'
      ],
      platformRows,
      'id'
    )
  }, [t, platformRows])

  const divRef = useRef<HTMLDivElement | null>(null)
  const handleCopy = () => {
    if (!divRef.current) return
    const totalBetCash = formatMoney(platformRows.reduce((pre, cur) => BcMath.base(pre).add(cur.bet_cash).get(), '0'))
    const totalRevenue = formatMoney(platformRows.reduce((pre, cur) => BcMath.base(pre).add(cur.revenue).get(), '0'))
    divRef.current.innerHTML = '<table>' +
      `<tr>${platformRows.map((game) => `<td colspan="2">${game.name}</td>`).join('')}<td colspan="2">${t('common.sum')}</td></tr>` +
      `<tr>${Array.from(Array(platformRows.length + 1).keys()).map(() => `<td>${t('common.allBetCash')}</td><td>${t('common.betRevenue')}</td>`).join('')}</tr>` +
      `<tr>${platformRows.map((game) => `<td>${formatMoney(game.bet_cash)}</td><td>${formatMoney(game.revenue)}</td>`).join('')}<td>${totalBetCash}</td><td>${totalRevenue}</td></tr>` +
      '</table>'
    divRef.current.setAttribute('style', 'display: block; color: #000; text-align: center;')
    const range = document.createRange()
    const selection = window.getSelection()
    selection?.removeAllRanges()
    range.selectNodeContents(divRef.current)
    selection?.addRange(range)
    document.execCommand('copy')
    divRef.current.setAttribute('style', 'display: none;')
    enqueueSnackbar(t('common.oneClickCopyDone'), {
      autoHideDuration: 2 * 1000
    })
  }

  if (hide) return null
  return (
    <ScrollablePaper marginX={5}>
      <Box padding={4}>
        <Box
          paddingY={1.25}
          paddingX={2}
          marginBottom={3}
          className={commonClasses.pinkTitleBar}
        >
          <Typography variant="h5">
            {t('common.betRevenueStatistic')}
          </Typography>
        </Box>
        <LoadingAndErrorFrame { ...pageFlow.status }>
          <Box paddingBottom={2}>
            <Typography className={clsx(classes.special, commonClasses.bold)} variant="h6">
              {t('common.totalStatistic')}
            </Typography>
          </Box>
          <CoreTable
            classes={tableClasses}
            data={totalData}
            total={1}
            tableFooterMemo={Tip}
          />
          <Box paddingY={2}>
            <Typography className={commonClasses.bold} variant="h6">
              {t('common.gameType')}
            </Typography>
          </Box>
          <CoreTable
            classes={tableClasses}
            data={categoryData}
            total={categoryData.rows.length}
          />
          <Box paddingY={2}>
            <Grid container justify="space-between">
              <Typography className={commonClasses.bold} variant="h6">
                {t('common.gamePlatform')}
              </Typography>
              <Button
                onClick={handleCopy}
                className={commonClasses.purpleGradualButton}
              >
                {t('common.accountantCopy')}
              </Button>
              <div
                ref={divRef}
                style={{ display: 'none' }}
              />
            </Grid>
          </Box>
          <CoreTable
            classes={tableClasses}
            data={platformData}
            total={platformData.rows.length}
            tableFooterMemo={PayoutTip}
          />
        </LoadingAndErrorFrame>
      </Box>
    </ScrollablePaper>
  )
}

export default React.memo(AdminReportInAndOutRevenue)

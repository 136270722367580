import React, { useState, useCallback, useMemo } from 'react'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import DebitCardValidationDialog, { initialValidationForm } from './DebitCardValidationDialog'
import DebitCardInfoDialog, { initialCardForm, CardFormType } from './DebitCardInfoDialog'
import useT from '../../../i18ns/admin/useT'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { AdminPlayerDebitCardForm, GDKError } from '@golden/gdk-admin'
import useGlobalDialog from '../../../providers/admin/dialog/useGlobalDialog'
import { createGlobalDialogConfig } from '../../../utils/default/DialogHelper'

interface PropTypes {
  id: number
  reload: () => void
}

const defaultInfoForm = initialCardForm()

const formToRequest = (form: CardFormType, token: string): AdminPlayerDebitCardForm => ({
  real_name: form.name,
  bank_account: form.account,
  bank_code: Number(form.bank),
  token
})

const DebitCardCreateButton: React.FC<PropTypes> = (props) => {
  const { id, reload } = props
  const { t } = useT()
  const gdk = useGDK()
  const commonClasses = useCommonStyles()
  const globalDialog = useGlobalDialog()
  const [form, setForm] = useState<CardFormType | null>(null)
  const [open, setOpen] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [validationOpen, setValidationOpen] = useState<boolean>(false)
  const handleSubmitInfo = useCallback((form: CardFormType) => {
    setForm(form)
    setValidationOpen(true)
    return form
  }, [])
  const handleSubmit = useCallback((token: string) => {
    if (form !== null) {
      setLoading(true)
      gdk.player.createPlayerDebitCards(id, formToRequest(form, token)).subscribe({
        next: () => {
          setOpen(false)
          setValidationOpen(false)
          setLoading(false)
          reload()
          globalDialog.setConfig(createGlobalDialogConfig({
            showIcon: false,
            showCancel: false,
            message: t('dialog.bankCardBindSuccess')
          }))
          globalDialog.setOpen({ id: 'createDebitCardSuccess', value: true, isOK: false })
        },
        error: (error: GDKError) => {
          setLoading(false)
          globalDialog.clearState()
          globalDialog.setConfig(createGlobalDialogConfig({
            showIcon: true,
            variant: 'error',
            showCancel: false,
            message: error.message
          }))
          globalDialog.setOpen({ id: 'createDebitCardFail', value: true, isOK: false })
        }
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, form, gdk])
  return (
    <Box display="flex" justifyContent="flex-end">
      <Button
        className={commonClasses.purpleGradualButton}
        onClick={useCallback(() => setOpen(true), [])}
      >
        {t('common.bindDebitCard')}
      </Button>
      <DebitCardInfoDialog
        open={open}
        title={t('common.bindDebitCard')}
        onClose={useCallback(() => setOpen(false), [])}
        onSubmit={handleSubmitInfo}
        defaultValue={defaultInfoForm}
      />
      <DebitCardValidationDialog
        title={t('common.bindDebitCard')}
        confirmText={t('common.bind')}
        id={id}
        open={validationOpen}
        loading={loading}
        onClose={useCallback(() => setValidationOpen(false), [])}
        onSubmit={handleSubmit}
        defaultValue={useMemo(() => initialValidationForm({
          account: form?.account ?? '',
          realName: form?.name ?? ''
        }), [form])}
      />
    </Box>
  )
}

export default React.memo(DebitCardCreateButton)

import { SmsTriggerType } from '@golden/gdk-admin'
import TKeyType from '../../i18ns/admin/TKeyType'

const smsTriggerNname: { [key in SmsTriggerType]: TKeyType } = {
  [SmsTriggerType.BIND]: 'smsTrigger.bind',
  [SmsTriggerType.UNBIND]: 'smsTrigger.unbind',
  [SmsTriggerType.REGISTER]: 'smsTrigger.register',
  [SmsTriggerType.FORGET_PASSWORD]: 'smsTrigger.forgetPassword',
  [SmsTriggerType.RESET_PHONE_BY_ADMIN]: 'smsTrigger.resetPhoneByAdmin'
}

export default smsTriggerNname

import React, { PropsWithChildren } from 'react'
import useT from '../../../i18ns/admin/useT'

interface PropTyps {
  gameId?: undefined | number
  updatedGames: number[]
}

const PlatformFeeCell: React.FC<PropsWithChildren<PropTyps>> = (props) => {
  const { gameId, updatedGames, children } = props
  const { t } = useT()
  if ((gameId && updatedGames.includes(gameId)) ?? (!gameId && updatedGames.length !== 0)) {
    return (
      <React.Fragment>
        {t('common.updateWithEllipsis')}
      </React.Fragment>
    )
  }
  return (
    <React.Fragment>
      {children}
    </React.Fragment>
  )
}

export default React.memo(PlatformFeeCell)

import React from 'react'
import Box from '@material-ui/core/Box'
import ActivityApplicationForm from '../../../components/admin/activity/application/ActivityApplicationForm'
import ActivityApplicationTable from '../../../components/admin/activity/application/ActivityApplicationTable'

const ActivityApplicationPage: React.FC = () => {
  return (
    <Box paddingY={5}>
      <Box paddingX={5}>
        <ActivityApplicationForm />
      </Box>
      <Box paddingTop={3}>
        <ActivityApplicationTable />
      </Box>
    </Box>
  )
}

export default React.memo(ActivityApplicationPage)

import { AnnouncementType } from '@golden/gdk-admin'
import TKeyType from '../../i18ns/admin/TKeyType'

const announcementTypeName: { [key in AnnouncementType]: TKeyType } = {
  [AnnouncementType.SYSTEM]: 'announcement.system',
  [AnnouncementType.GAME]: 'announcement.game',
  [AnnouncementType.FINANCE]: 'announcement.finance',
  [AnnouncementType.ACTIVITY]: 'announcement.activity',
  [AnnouncementType.STAFF]: 'announcement.staff'
}

export default announcementTypeName

import React from 'react'
import Box from '@material-ui/core/Box'
import ManagerLoginHistoryForm from '../../../components/admin/manager/ManagerLoginHistoryForm'
import ManagerLoginHistoryTable from '../../../components/admin/manager/ManagerLoginHistoryTable'

const ManagerLoginHistoryPage: React.FC = () => {
  return (
    <Box paddingY={5}>
      <Box paddingX={5}>
        <ManagerLoginHistoryForm />
      </Box>
      <Box paddingTop={3}>
        <ManagerLoginHistoryTable />
      </Box>
    </Box>
  )
}

export default React.memo(ManagerLoginHistoryPage)

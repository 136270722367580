import { useEffect, useCallback } from 'react'
import { GlobalDialogType } from './DialogHelper'

export const useDialogFlow = (
  globalDialog: GlobalDialogType,
  id: string,
  next: () => void,
  dependancies: any[] = []
) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedCallback = useCallback(next, dependancies)
  useEffect(() => {
    const isIdCorrect = globalDialog.open.id === id
    const isDialogClose = !globalDialog.open.value
    const isOK = globalDialog.open.isOK
    if (isIdCorrect && isDialogClose && isOK) {
      return memoizedCallback()
    }
  }, [memoizedCallback, globalDialog.open.id, globalDialog.open.value, globalDialog.open.isOK, id])
}

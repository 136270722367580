import React from 'react'
import { SvgIconProps } from '@material-ui/core/SvgIcon'
import UnarchiveIcon from '@material-ui/icons/Unarchive'
import IconWithBadge from '../../default/present/IconWithBadge'
import useGDKStore from '../../../providers/admin/gdk/useGDKStore'

const WithdrawIcon: React.FC<SvgIconProps> = (props) => {
  const me = useGDKStore.admin.me()
  const has = me?.has_withdraw ?? false
  const badgeContent = has ? 1 : 0
  return (
    <IconWithBadge
      icon={UnarchiveIcon}
      badgeContent={badgeContent}
      {...props}
    />
  )
}

export default React.memo(WithdrawIcon)

import React, { useCallback } from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import Input from '@material-ui/core/Input'
import { useInternValue } from '../../../utils/default/FormHook'

export interface PropTypes {
  label?: string
  hideLabel?: boolean
  value?: string | number | string[] | number[] | boolean | undefined
  defaultValue?: string | number | string[] | number[] | undefined
  onChange?: (event: React.ChangeEvent<{
    name?: string | undefined
    value: unknown
  // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
  }>) => boolean | void
  options: Array<{
    name: string
    value: string | number | string[] | number[] | boolean | undefined
    disabled?: boolean
    renderName?: string
  }>
  disabled?: boolean
  fullWidth?: boolean
  placeholder?: string
  onBlur?: () => void
  onFocus?: () => void
  helperText?: string
  error?: boolean
  autoFocus?: boolean
  required?: boolean
  variant?: 'standard' | 'outlined' | 'filled'
  margin?: 'none' | 'dense'
  className?: string
  classes?: Partial<Record<'label' | 'underline' | 'icon' | 'outlined', string>>
  itemClasses?: string
}

const useStyles = makeStyles((theme) => ({
  outlined: {
    lineHeight: 0.8
  },
  helperText: {
    fontSize: '0.75rem'
  },
  error: {
    color: theme.palette.error.main
  }
}))

const DropDown: React.FC<PropTypes> = (props) => {
  const {
    label,
    hideLabel,
    value,
    defaultValue,
    onChange,
    disabled,
    fullWidth,
    placeholder,
    options,
    onBlur,
    onFocus,
    helperText,
    error,
    autoFocus,
    required,
    variant,
    margin,
    className,
    itemClasses,
    classes: propsClasses
  } = props

  const classes = useStyles()

  const [internValue, setInternValue] = useInternValue<string | number | string[] | number[] | boolean | undefined>(
    defaultValue ?? value,
    value
  )

  const selectValue = options.find((option) => (option?.value ?? '').toString() === (internValue ?? '').toString())?.value ?? ''

  const renderCustomName = useCallback((value) => {
    const renderItem = options.find((el) => (el.value === value))
    return renderItem?.renderName ?? renderItem?.name
  }, [options])
  return (
    <>
      <FormControl
        fullWidth={fullWidth}
        disabled={disabled}
        onBlur={onBlur}
        onFocus={onFocus}
        error={error}
        required={required}
      >
        { hideLabel ? null : <InputLabel classes={{ root: propsClasses?.label }}>{ label }</InputLabel>}
        {(propsClasses?.underline === undefined)
          ? (
          <Select
            classes={{
              root: className,
              icon: propsClasses?.icon,
              outlined: clsx(classes.outlined, propsClasses?.outlined)
            }}
            variant={variant ?? 'standard'}
            value={selectValue}
            onChange={(event) => {
              if (onChange) {
                const result = onChange(event)
                if (result) {
                  setInternValue(event.target.value as string | number | string[] | number[] | undefined)
                }
              }
            }}
            autoFocus={autoFocus}
            margin={margin}
            displayEmpty={placeholder !== undefined}
            renderValue={renderCustomName}
          >
            {placeholder !== undefined && (<MenuItem value="" disabled>{placeholder}</MenuItem>)}
            {options.map((option, index) => (
              <MenuItem disabled={option.disabled} key={index} value={option.value as number} className={itemClasses}>{option.name}</MenuItem>
            ))}
          </Select>
            )
          : (
          <Select
            input={
              <Input
                classes={{
                  underline: propsClasses?.underline
                }}
              />
            }
            classes={{
              root: className,
              icon: propsClasses?.icon
            }}
            variant={variant ?? 'standard'}
            value={internValue}
            onChange={(event) => {
              setInternValue(event.target.value as string | number | string[] | undefined)
              if (onChange) onChange(event)
            }}
            autoFocus={autoFocus}
            displayEmpty={placeholder !== undefined}
            renderValue={renderCustomName}
          >
            {placeholder !== undefined && (<MenuItem value="" disabled>{placeholder}</MenuItem>)}
            {options.map((option, index) => (
              <MenuItem disabled={option.disabled} key={index} value={option.value as number} className={itemClasses}>{option.name}</MenuItem>
            ))}
          </Select>
            )}
      </FormControl>
      {helperText && (<span
        className={clsx(classes.helperText, error ? classes.error : undefined)}
      >
        {helperText}
      </span>)}
    </>
  )
}

export default React.memo(DropDown)

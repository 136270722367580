import React, { createContext, useCallback, useState } from 'react'
import Box from '@material-ui/core/Box'
import EditWithdrawalLimitForm, {
  EditWithdrawalLimitFormType,
  initialWithdrawalLimitForm,
  formToRequest
} from '../../../components/admin/withdrawal/EditWithdrawalLimitForm'
import { createDefaultFormState } from '../../../utils/default/FormHook'
import useT from '../../../i18ns/admin/useT'
import { useRedirectHandleBack, useDialogHandleSubmit, useGetDataByParams } from '../../../utils/default/ComplexFlowHook'
import { Path } from '../../../components/admin/route/route'
import useGDK from '../../../providers/admin/gdk/useGDK'
import useGlobalDialog from '../../../providers/admin/dialog/useGlobalDialog'
import { createGlobalDialogConfig } from '../../../utils/default/DialogHelper'
import LoadingAndErrorFrame from '../../../components/default/frames/LoadingAndErrorFrame'
import { usePageFlow } from '../../../utils/default/PageFlowHook'
import { formatMoney } from '../../../utils/default/TableHelper'

const defaultForm = initialWithdrawalLimitForm()
const FormContext = createContext(createDefaultFormState(defaultForm))

const WithdrawalLimitUpdatePage: React.FC = () => {
  const { t } = useT()
  const pageFlow = usePageFlow()
  const gdk = useGDK()
  const [id, setId] = useState<number>(0)
  const [form, setForm] = useState<EditWithdrawalLimitFormType>(defaultForm)
  const globalDialog = useGlobalDialog()
  useGetDataByParams({
    path: Path.WITHDRAWAL_LIMIT_UPDATE,
    gdkFunc: (param: { id: string }) => gdk.withdraw.getWithdrawLimit(Number(param.id)),
    gdkFuncDependencies: [gdk],
    onBeforeFetch: pageFlow.setLoadingStart,
    onSuccess: (res) => {
      setId(res.id)
      setForm(initialWithdrawalLimitForm({
        account: res.account,
        cashMax: formatMoney(res.cash_max_withdraw),
        daily: formatMoney(res.cash_max_withdraw_daily),
        dailyCount: res.withdraw_times_daily.toString()
      }))
      pageFlow.setContentShow()
    },
    onError: pageFlow.setGDKError,
    canLoadData: id === 0
  })
  const [handleBack, handleDebouncedBack] = useRedirectHandleBack({
    path: Path.WITHDRAWAL_LIMIT
  })
  const { handleSubmit } = useDialogHandleSubmit({
    globalDialog,
    dialogId: 'udpateWithdrawLimit',
    formToRequest,
    gdkFunc: (form) => gdk.withdraw.updateWithdrawLimit(id, form),
    gdkFuncDependencies: [gdk, id],
    getChangeDialogConfig: useCallback(() => createGlobalDialogConfig({
      showIcon: false,
      message: t('dialog.confirmUpdateWithdrawLimit')
    }), [t]),
    getSuccessDialogConfig: useCallback(() => createGlobalDialogConfig({
      showIcon: false,
      message: t('dialog.updateWithdrawLimitUpdate'),
      showCancel: false
    }), [t]),
    getFailDialogConfig: useCallback((_, error) => createGlobalDialogConfig({
      showIcon: true,
      variant: 'error',
      message: error.message,
      showCancel: false
    }), []),
    afterSuccessDialog: handleBack
  })
  return (
    <Box padding={5}>
      <LoadingAndErrorFrame { ...pageFlow.status }>
        <EditWithdrawalLimitForm
          context={FormContext}
          defaultValue={form}
          title={t('page.updateWithdrawLimit')}
          okText={t('common.confirmUpdate')}
          onCancel={handleDebouncedBack}
          onSubmit={handleSubmit}
          disableAccount
        />
      </LoadingAndErrorFrame>
    </Box>
  )
}

export default React.memo(WithdrawalLimitUpdatePage)

import React, { useState, useMemo, createContext, Dispatch, SetStateAction } from 'react'
import MuiButton from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import useT from '../../../i18ns/admin/useT'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { createDefaultPaginationData, formatCount, formatMoney, createTableData } from '../../../utils/default/TableHelper'
import { PaginationRes, ActivityUefaStars, ActivityUefaStarsSum, ActivityUefaStarsQuery, PaginationReq, ActivityType } from '@golden/gdk-admin'
import { usePageFlow } from '../../../utils/default/PageFlowHook'
import { searchToRequest } from './ActivityRecordDetailForm'
import { useRequestFromSearch, useGetDataByPayload, usePaginationClickAndChangeUrl, useDialogHandleSubmit } from '../../../utils/default/ComplexFlowHook'
import DateTime from '../../default/present/DateTime'
import ScrollablePaper from '../../default/present/ScrollablePaper'
import useGDKStore from '../../../providers/admin/gdk/useGDKStore'
import CoreTable from '../../default/present/CoreTable'
import allRoute, { Path } from '../route/route'
import LoadingAndErrorFrame from '../../default/frames/LoadingAndErrorFrame'
import MemoPopoverWithContext from '../../default/memo/MemoPopoverWithContext'
import { MemoPopoverPayload, createDefaultMemoPopoverPayload } from '../../default/memo/MemoPopover'
import { uefaStarsStatusName } from '../../../constants/default/activityStatusName'
import { uefaStarsStatusColor } from '../../../constants/default/activityStatusColor'
import { useLocation } from 'react-router-dom'
import useGlobalDialog from '../../../providers/admin/dialog/useGlobalDialog'
import { createGlobalDialogConfig } from '../../../utils/default/DialogHelper'
import ActivitySentDialog from './ActivitySentDialog'
import type { FormType } from './ActivitySentDialog'

interface SumRowType1 {
  id: number
  totalCount: string
  noUseCount: string
  winCount: string
  loseCount: string
  expiredCount: string
}

interface SumRowType2 {
  id: number
  bonus0: string
  bonus8: string
  bonus18: string
  bonus58: string
  bonus88: string
  bonus888: string
  totalBonus: string
}

interface SumRowType3 {
  id: number
  effectiveBet: string
  count: string
  bonus: string
}

interface RowType {
  id: number
  order: string
  apply: React.ReactElement
  account: string
  staffAccount: string
  content: string
  status: React.ReactElement
  bonus: string
  updatedAt: React.ReactElement
  updatedBy: string
  memo: string
}

const MemoContext = createContext<[MemoPopoverPayload, Dispatch<SetStateAction<MemoPopoverPayload>>]>([
  createDefaultMemoPopoverPayload(),
  () => {}
])

const Button = React.memo(MuiButton)

const SentButton: React.FC = () => {
  const commonClasses = useCommonStyles()
  const { t } = useT()
  const gdk = useGDK()
  const globalDialog = useGlobalDialog()
  const [open, setOpen] = useState(false)

  const { handleSubmit } = useDialogHandleSubmit({
    formToRequest: (form: FormType) => form,
    globalDialog,
    dialogId: 'sentByHandDialog',
    gdkFunc: (payload) => gdk.activity.postActivityRecordUefaStarsTicket(payload),
    gdkFuncDependencies: [gdk],
    getChangeDialogConfig: (form) => createGlobalDialogConfig({
      showIcon: false,
      message: (<div className={commonClasses.pre}>
        {
          `${t('dialog.confirmSentUefaStars', { account: form.account, ticket_count: form.ticket_count })}
          
${t('common.memo')}: ${form.memo}
          `
        }
      </div>)
    }),
    getSuccessDialogConfig: () => createGlobalDialogConfig({
      showIcon: false,
      showCancel: false,
      message: t('common.hasSent')
    }),
    getFailDialogConfig: (_, error) => createGlobalDialogConfig({
      showIcon: true,
      variant: 'error',
      showCancel: false,
      message: error.message
    }),
    onSuccess: () => {
      setOpen(false)
    }
  })
  return (
    <React.Fragment>
      <Button className={commonClasses.purpleGradualButton} onClick={() => setOpen(true)}>{t('common.sentByHand')}</Button>
      <ActivitySentDialog open={open} onClose={() => setOpen(false)} onSubmit={handleSubmit} getCount={gdk.activity.getActivityRecordUefaStarsTicket} />
    </React.Fragment>
  )
}

const AdminReportActivityUefaStarsTable: React.FC = () => {
  const classes = useCommonStyles()
  const { t } = useT()
  const gdk = useGDK()
  const activities = useGDKStore.activity.activities()
  const location = useLocation()
  const [list, setList] = useState<PaginationRes<ActivityUefaStars[]> & { summary: ActivityUefaStarsSum }>({
    ...createDefaultPaginationData([]),
    summary: {
      times: {
        total: 0,
        no_use: 0,
        win: 0,
        lose: 0,
        expired: 0
      },
      bonus: {
        0: 0,
        8: 0,
        18: 0,
        58: 0,
        88: 0,
        888: 0,
        total: '0.0000'
      },
      pool: {
        effective_bet: '0.0000',
        user_count: 0,
        receive_count: 0,
        bonus: '0.0000'
      }
    }
  })
  const pageFlow = usePageFlow()
  const request = useRequestFromSearch({ searchToRequest })
  useGetDataByPayload({
    payload: request as ActivityUefaStarsQuery & PaginationReq,
    gdkFunc: (payload) => gdk.activity.getActivityRecordUefaStars(payload),
    gdkFuncDependencies: [gdk],
    onBeforeFetch: pageFlow.setLoadingStart,
    onSuccess: (res: PaginationRes<ActivityUefaStars[]> & { summary: ActivityUefaStarsSum }) => {
      setList(res)
      pageFlow.setContentShow()
    },
    onError: pageFlow.setGDKError,
    canLoadData: request !== undefined
  })
  const tableClasses = useMemo(() => ({
    head: classes.greyTableHead,
    row: classes.tableRow,
    cellHead: classes.tableCellHead,
    cellBody: classes.nowrap
  }), [classes])
  const sumRow1 = useMemo(() => {
    return [{
      id: 1,
      totalCount: formatCount(list.summary.times.total),
      noUseCount: formatCount(list.summary.times.no_use),
      winCount: formatCount(list.summary.times.win),
      loseCount: formatCount(list.summary.times.lose),
      expiredCount: formatCount(list.summary.times.expired)
    }] as SumRowType1[]
  }, [list])
  const sumData1 = useMemo(() => {
    return createTableData<SumRowType1>(
      {
        id: {
          label: '',
          value: 'id'
        },
        totalCount: {
          label: t('common.totalSentCount'),
          value: 'totalCount',
          align: 'center'
        },
        noUseCount: {
          label: t('activityStatus.noUse'),
          value: 'noUseCount',
          align: 'center'
        },
        winCount: {
          label: t('activityStatus.win'),
          value: 'winCount',
          align: 'center'
        },
        loseCount: {
          label: t('activityStatus.lost'),
          value: 'loseCount',
          align: 'center'
        },
        expiredCount: {
          label: t('activityStatus.expired'),
          value: 'expiredCount',
          align: 'center'
        }
      },
      [
        'totalCount',
        'noUseCount',
        'winCount',
        'loseCount',
        'expiredCount'
      ],
      sumRow1,
      'id'
    )
  }, [t, sumRow1])
  const sumRow2 = useMemo(() => {
    return [{
      id: 1,
      bonus0: formatCount(list.summary.bonus[0]),
      bonus8: formatCount(list.summary.bonus[8]),
      bonus18: formatCount(list.summary.bonus[18]),
      bonus58: formatCount(list.summary.bonus[58]),
      bonus88: formatCount(list.summary.bonus[88]),
      bonus888: formatCount(list.summary.bonus[888]),
      totalBonus: formatMoney(list.summary.bonus.total)
    }] as SumRowType2[]
  }, [list])
  const sumData2 = useMemo(() => {
    return createTableData<SumRowType2>(
      {
        id: {
          label: '',
          value: 'id'
        },
        bonus0: {
          label: '0',
          value: 'bonus0',
          align: 'center'
        },
        bonus8: {
          label: '8',
          value: 'bonus8',
          align: 'center'
        },
        bonus18: {
          label: '18',
          value: 'bonus18',
          align: 'center'
        },
        bonus58: {
          label: '58',
          value: 'bonus58',
          align: 'center'
        },
        bonus88: {
          label: '88',
          value: 'bonus88',
          align: 'center'
        },
        bonus888: {
          label: '888',
          value: 'bonus888',
          align: 'center'
        },
        totalBonus: {
          label: t('common.totalActivityBonus'),
          value: 'totalBonus',
          align: 'center'
        }
      },
      [
        'totalBonus',
        'bonus888',
        'bonus88',
        'bonus58',
        'bonus18',
        'bonus8',
        'bonus0'
      ],
      sumRow2,
      'id'
    )
  }, [t, sumRow2])
  const sumRow3 = useMemo(() => {
    return [{
      id: 1,
      effectiveBet: formatMoney(list.summary.pool.effective_bet),
      count: `${formatCount(list.summary.pool.user_count)}/${formatCount(list.summary.pool.receive_count)}`,
      bonus: formatMoney(list.summary.pool.bonus)
    }] as SumRowType3[]
  }, [list])
  const sumData3 = useMemo(() => {
    return createTableData<SumRowType3>(
      {
        id: {
          label: '',
          value: 'id'
        },
        effectiveBet: {
          label: t('common.uefaEffectiveCash'),
          value: 'effectiveBet',
          align: 'center'
        },
        count: {
          label: t('common.prizePoolCount'),
          value: 'count',
          align: 'center'
        },
        bonus: {
          label: t('common.prizePoolBonus'),
          value: 'bonus',
          align: 'center'
        }
      },
      [
        'effectiveBet',
        'count',
        'bonus'
      ],
      sumRow3,
      'id'
    )
  }, [t, sumRow3])
  const rows = useMemo(() => {
    return list.data.map((item, index) => {
      return {
        id: index,
        order: item.serial_number,
        apply: (<DateTime time={item.created_at} />),
        account: item.account,
        staffAccount: item.staff_account,
        content: item.activity_content,
        status: (<p className={classes.chipText} style={{ backgroundColor: uefaStarsStatusColor[item.status] }}>{t(uefaStarsStatusName[item.status])}</p>),
        bonus: formatMoney(item.bonus),
        updatedBy: item.updated_by,
        updatedAt: item.updated_at ? (<DateTime time={item.updated_at} />) : (<p></p>),
        memo: item.memo
      } as RowType
    })
  }, [list.data, t, classes])
  const data = useMemo(() => {
    return createTableData<RowType>(
      {
        id: {
          label: '',
          value: 'id'
        },
        order: {
          label: t('common.activitySerial'),
          value: 'order',
          align: 'center'
        },
        apply: {
          label: t('common.applyTime'),
          value: 'apply',
          align: 'center'
        },
        account: {
          label: t('common.playerAccount'),
          value: 'account',
          align: 'center'
        },
        staffAccount: {
          label: t('common.staffAccount'),
          value: 'staffAccount',
          align: 'center'
        },
        content: {
          label: t('common.activityContent'),
          value: 'content',
          align: 'left'
        },
        status: {
          label: t('common.status'),
          value: 'status',
          align: 'center'
        },
        bonus: {
          label: t('common.activityBonus'),
          value: 'bonus',
          align: 'right'
        },
        updatedAt: {
          label: t('common.updatedAt'),
          value: 'updatedAt',
          align: 'center'
        },
        updatedBy: {
          label: t('common.updateBy'),
          value: 'updatedBy',
          align: 'center'
        },
        memo: {
          label: t('common.memo'),
          value: 'memo',
          align: 'center'
        }
      },
      [
        'order',
        'apply',
        'account',
        'staffAccount',
        'content',
        'status',
        'bonus',
        'updatedAt',
        'updatedBy',
        'memo'
      ],
      rows,
      'id'
    )
  }, [t, rows])
  const handlePagination = usePaginationClickAndChangeUrl({
    request,
    encodePath: (location.pathname === Path.ACTIVITY_RECORD_DETAIL) ? allRoute.activityRecordDetail.encodePath : allRoute.activityRecordSub.encodePath
  })
  return (
    <MemoPopoverWithContext memoPopoverContext={MemoContext}>
      <Box paddingBottom={3}>
        <ScrollablePaper marginX={5}>
          <Box padding={5}>
            <LoadingAndErrorFrame { ...pageFlow.status }>
              <Box
                paddingY={1.25}
                paddingX={2}
                className={classes.pinkTitleBar}
              >
                <Grid container justify='space-between'>
                  <Grid item>
                    <Typography variant="h5">
                      {activities.find((item) => item.type === ActivityType.UEFA_STARS)?.name ?? ''}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <SentButton />
                  </Grid>
                </Grid>
              </Box>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={12} md={4}>
                  <Box paddingY={2}>
                    <Typography variant="h5">
                      {t('common.countStatisticResult')}
                    </Typography>
                  </Box>
                  <CoreTable
                    data={sumData1}
                    total={1}
                    classes={tableClasses}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <Box paddingY={2}>
                    <Typography variant="h5">
                      {t('common.prizeStatisticResult')}
                    </Typography>
                  </Box>
                  <CoreTable
                    data={sumData2}
                    total={1}
                    classes={tableClasses}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <Box paddingY={2}>
                    <Typography variant="h5">
                      {t('common.prizePoolStatisticResult')}
                    </Typography>
                  </Box>
                  <CoreTable
                    data={sumData3}
                    total={1}
                    classes={tableClasses}
                  />
                </Grid>
              </Grid>
            </LoadingAndErrorFrame>
          </Box>
        </ScrollablePaper>
      </Box>
      <ScrollablePaper marginX={5}>
        <Box padding={5}>
          <LoadingAndErrorFrame { ...pageFlow.status }>
            <CoreTable
              data={data}
              total={list.total}
              classes={tableClasses}
              showPagination
              page={request?.page ?? 1}
              onChangePage={handlePagination}
            />
          </LoadingAndErrorFrame>
        </Box>
      </ScrollablePaper>
    </MemoPopoverWithContext>
  )
}

export default React.memo(AdminReportActivityUefaStarsTable)

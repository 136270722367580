import React from 'react'
import { SnackbarProvider as SnackbarProviderBase } from 'notistack'
import EventHandler from './EventHandler'
import { findAppRoute } from '../../../utils/default/RouteHelper'
import allRoute, { routeOrder } from '../../../components/admin/route/route'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface PropTypes {}

const SnackbarProvider: React.FC<PropTypes> = (props) => {
  const { children } = props
  const route = findAppRoute(window.location.pathname, routeOrder, allRoute) ?? allRoute.notFound
  return (
    <SnackbarProviderBase disableWindowBlurListener maxSnack={1} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
      { !route.hideEventHandler && <EventHandler /> }
      {children}
    </SnackbarProviderBase>
  )
}

export default React.memo(SnackbarProvider)

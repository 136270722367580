import React, { useState, useMemo } from 'react'
import { Link } from 'react-router-dom'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import ScrollablePaper from '../../../components/default/present/ScrollablePaper'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import { useGetData } from '../../../utils/default/ComplexFlowHook'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { usePageFlow } from '../../../utils/default/PageFlowHook'
import { PlayerRankActivity, PlayerRankItem } from '@golden/gdk-admin'
import useT from '../../../i18ns/admin/useT'
import DateTime from '../../../components/default/present/DateTime'
import { createTableData, formatCount, formatMoney } from '../../../utils/default/TableHelper'
import { useChecker } from '../../../utils/admin/AdminRouteHook'
import CoreTable from '../../../components/default/present/CoreTable'
import LoadingAndErrorFrame from '../../../components/default/frames/LoadingAndErrorFrame'
import allRoute from '../../../components/admin/route/route'
import playerRankName from '../../../constants/default/playerRankName'

interface RankRowType {
  id: number
  name: string
  multiple: string
  feedbackMultiple?: string
  max: string
  daily: string
  times: string
  count: React.ReactElement
  updatedAt: React.ReactElement
  updatedBy: string
  function: React.ReactElement
}

interface ActivityRowType {
  id: number
  name: string
  upgradeThreshold: string
  keepThreshold: string
  upgradeBonus: string
  weeklyBonus: string
}

const PlayerRankPage: React.FC = () => {
  const gdk = useGDK()
  const rankPageFlow = usePageFlow()
  const activityPageFlow = usePageFlow()
  const commonClasses = useCommonStyles()
  const { t } = useT()
  const writable = useChecker()
  const [res, setRes] = useState<PlayerRankItem[]>([])
  const [activityContents, setActivityContents] = useState<PlayerRankActivity[]>([])
  const tableClasses = useMemo(() => {
    return {
      head: commonClasses.pinkTableHead,
      row: commonClasses.tableRow,
      cellHead: commonClasses.tableCellHead
    }
  }, [commonClasses.pinkTableHead, commonClasses.tableCellHead, commonClasses.tableRow])
  useGetData({
    gdkFunc: () => gdk.player.getPlayerRankList(),
    gdkFuncDependencies: [gdk],
    onBeforeFetch: rankPageFlow.setLoadingStart,
    onSuccess: (res: PlayerRankItem[]) => {
      setRes(res)
      rankPageFlow.setContentShow()
    },
    onError: rankPageFlow.setGDKError
  })
  useGetData({
    gdkFunc: () => gdk.player.getPlayerRankActivity(),
    gdkFuncDependencies: [gdk],
    onBeforeFetch: activityPageFlow.setLoadingStart,
    onSuccess: (res: PlayerRankActivity[]) => {
      setActivityContents(res)
      activityPageFlow.setContentShow()
    },
    onError: activityPageFlow.setGDKError
  })
  const rankRows = useMemo(() => {
    return res.map((item) => ({
      id: item.id,
      name: t(playerRankName[item.rank]),
      multiple: Number(item.effective_cash_deposit_multiple).toFixed(0),
      feedbackMultiple: Number(item.feedback_barrier_multiple).toFixed(0),
      max: formatMoney(item.cash_max_withdraw),
      daily: formatMoney(item.cash_max_withdraw_daily),
      times: formatCount(item.withdraw_times_daily),
      count: (
        <Link
          to={allRoute.player.encodePath({
            param: {},
            search: { rank: item.rank, page: 1 }
          })}
          className={commonClasses.purpleAnchor}
        >
          {formatCount(item.player_count)}
        </Link>
      ),
      updatedAt: (<DateTime time={item.updated_at} />),
      updatedBy: item.updated_by,
      function: (
        <Button
          component={Link}
          to={allRoute.playerRankUpdate.encodePath({ param: { id: item.id }, search: {} })}
          className={commonClasses.blueGradualOutlineButton}
        >
          {t('common.update')}
        </Button>
      )
    }))
  }, [commonClasses, res, t])
  const rankData = useMemo(() => {
    return createTableData<RankRowType>(
      {
        id: {
          label: '',
          value: 'id'
        },
        name: {
          label: t('common.rankName'),
          value: 'name',
          align: 'center'
        },
        multiple: {
          label: t('common.depositEffectiveCashMultiple'),
          value: 'multiple',
          align: 'center'
        },
        feedbackMultiple: {
          label: t('common.feedbackBarrierMultiple'),
          value: 'feedbackMultiple',
          align: 'center'
        },
        max: {
          label: t('common.MaxWithdrawCashEachTime'),
          value: 'max',
          align: 'center'
        },
        daily: {
          label: t('common.MaxWithdrawCashEachDay'),
          value: 'daily',
          align: 'center'
        },
        times: {
          label: t('common.withdrawTimesDaily'),
          value: 'times',
          align: 'center'
        },
        count: {
          label: t('common.playerCount'),
          value: 'count',
          align: 'center'
        },
        updatedAt: {
          label: t('common.updateAt'),
          value: 'updatedAt',
          align: 'center'
        },
        updatedBy: {
          label: t('common.updateBy'),
          value: 'updatedBy',
          align: 'center'
        },
        function: {
          label: t('common.function'),
          value: 'function',
          align: 'center'
        }
      },
      writable
        ? [
            'name',
            'multiple',
            'feedbackMultiple',
            'max',
            'daily',
            'times',
            'count',
            'updatedAt',
            'updatedBy',
            'function'
          ]
        : [
            'name',
            'multiple',
            'feedbackMultiple',
            'max',
            'daily',
            'times',
            'count',
            'updatedAt',
            'updatedBy'
          ],
      rankRows,
      'id'
    )
  }, [rankRows, t, writable])
  const activityRows = useMemo(() => {
    return activityContents.map((item, index) => ({
      id: index,
      name: t(playerRankName[item.rank]),
      upgradeThreshold: formatMoney(item.upgrade_threshold),
      keepThreshold: formatMoney(item.keep_threshold),
      upgradeBonus: formatMoney(item.upgrade_bonus),
      weeklyBonus: formatMoney(item.weekly_bonus)
    } as ActivityRowType))
  }, [activityContents, t])
  const activityData = useMemo(() => {
    return createTableData<ActivityRowType>(
      {
        id: {
          label: '',
          value: 'id'
        },
        name: {
          label: t('common.playerRank'),
          value: 'name',
          align: 'center'
        },
        upgradeThreshold: {
          label: t('common.upgradeThresold'),
          value: 'upgradeThreshold',
          align: 'center'
        },
        keepThreshold: {
          label: t('common.keepThresold'),
          value: 'keepThreshold',
          align: 'center'
        },
        upgradeBonus: {
          label: t('common.upRankMoney'),
          value: 'upgradeBonus',
          align: 'center'
        },
        weeklyBonus: {
          label: t('common.weekGift2'),
          value: 'weeklyBonus',
          align: 'center'
        }
      },
      [
        'name',
        'upgradeThreshold',
        'keepThreshold',
        'upgradeBonus',
        'weeklyBonus'
      ],
      activityRows,
      'id'
    )
  }, [t, activityRows])
  return (
    <Box paddingY={6}>
      <ScrollablePaper marginX={6}>
        <Box padding={5}>
          <LoadingAndErrorFrame { ...rankPageFlow.status }>
            <CoreTable
              data={rankData}
              total={rankData.rows.length}
              classes={tableClasses}
            />
          </LoadingAndErrorFrame>
        </Box>
      </ScrollablePaper>
      <Box paddingTop={2}>
        <ScrollablePaper marginX={6}>
          <Box padding={5}>
            <Typography variant="h5" className={commonClasses.bold}>{t('common.playerRankDescription')}</Typography>
            <Box paddingTop={2}>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={6}>
                  <LoadingAndErrorFrame { ...activityPageFlow.status }>
                    <CoreTable
                      data={activityData}
                      total={activityData.rows.length}
                      classes={tableClasses}
                    />
                  </LoadingAndErrorFrame>
                </Grid>
                <Grid item xs={6}>
                  <Grid container direction="column" spacing={2}>
                    <Grid item>
                      <Typography variant="h6" className={commonClasses.bold}>{t('common.upRank')}</Typography>
                      <Typography className={commonClasses.pre}>{t('common.upRankTip')}</Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="h6" className={commonClasses.bold}>{t('common.downRank')}</Typography>
                      <Typography className={commonClasses.pre}>{t('common.downRankTip')}</Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="h6" className={commonClasses.bold}>{t('common.upRankAfterDownRank')}</Typography>
                      <Typography className={commonClasses.pre}>{t('common.upRankAfterDownRankTip')}</Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="h6" className={commonClasses.bold}>{t('common.upRankMoney')}</Typography>
                      <Typography className={commonClasses.pre}>{t('common.upRankMoneyTip')}</Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="h6" className={commonClasses.bold}>{t('common.weekGift2')}</Typography>
                      <Typography className={commonClasses.pre}>{t('common.weekGiftTip')}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </ScrollablePaper>
      </Box>
    </Box>
  )
}

export default React.memo(PlayerRankPage)

import { PublisherStatusType } from '@golden/gdk-admin'
import TKeyType from '../../i18ns/admin/TKeyType'

const publisherStatusName: { [key in PublisherStatusType]: TKeyType } = {
  [PublisherStatusType.POPULAR]: 'operationState.popular',
  [PublisherStatusType.ONLINE]: 'operationState.online',
  [PublisherStatusType.IN_MAINTENANCE]: 'operationState.inMaintenance',
  [PublisherStatusType.NOT_SALE]: 'operationState.notSale',
  [PublisherStatusType.REMOVED]: 'operationState.removed'
}

export default publisherStatusName

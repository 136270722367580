import React, { useEffect } from 'react'
import Box from '@material-ui/core/Box'
import PlayerReportBetForm from '../../../components/admin/playerReport/bet/PlayerReportBetForm'
import PlayerReportBetTable from '../../../components/admin/playerReport/bet/PlayerReportBetTable'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { Branch } from '@golden/gdk-admin'

const PlayerReportBetPage: React.FC = () => {
  const gdk = useGDK()
  useEffect(() => {
    gdk.trunk.trigger([Branch.PLATFORMS, Branch.PUBLISHERS])
  }, [gdk])
  return (
    <Box paddingY={5}>
      <Box paddingX={5}>
        <PlayerReportBetForm />
      </Box>
      <Box paddingTop={3}>
        <PlayerReportBetTable />
      </Box>
    </Box>
  )
}

export default React.memo(PlayerReportBetPage)

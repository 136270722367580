import { PermissionType, PlayerWallet, WalletType } from '@golden/gdk-admin'
import React, { useMemo, useState } from 'react'
import { map } from 'rxjs'
import { usePageFlow } from '../../../../utils/default/PageFlowHook'
import useGDK from '../../../../providers/admin/gdk/useGDK'
import { useGetDataByPayload, useGDKFuncHandleClick, useReload } from '../../../../utils/default/ComplexFlowHook'
import LoadingAndErrorFrame from '../../../../components/default/frames/LoadingAndErrorFrame'
import Box from '@material-ui/core/Box'
import { createTableData } from '../../../../utils/default/TableHelper'
import PointsCell from '../../../../components/default/present/PointsCell'
import Button from '@material-ui/core/Button'
import useT from '../../../../i18ns/admin/useT'
import { useCommonStyles } from '../../../../utils/admin/StyleHook'
import Grid from '@material-ui/core/Grid'
import { Link } from 'react-router-dom'
import allRoute from '../../route/route'
import CoreTable from '../../../../components/default/present/CoreTable'
import ScrollablePaper from '../../../../components/default/present/ScrollablePaper'
import { PlayerReportTransferRequest } from '../../../../components/admin/playerReport/transfer/PlayerReportTransferForm'
import { useChecker } from '../../../../utils/admin/AdminRouteHook'
import { mainWalletName } from '../../../../utils/default/PlatformHelper'
import Tip from '../../Tip'

interface RowType {
  account: string
  cash: React.ReactElement
  action: React.ReactElement
}

const PlayerReportTransferSingleAction: React.FC<{ request: PlayerReportTransferRequest }> = (props) => {
  const { request } = props
  const gdk = useGDK()
  const pageFlow = usePageFlow()
  const commonClasses = useCommonStyles()
  const { reload, reloadFlag } = useReload()
  const { t } = useT()
  const writableTransfer = useChecker([PermissionType.TRANSFER_RECORD_TRANSFER])

  const tableClasses = useMemo(() => ({
    head: commonClasses.pinkTableHead,
    row: commonClasses.tableRow,
    cellHead: commonClasses.tableCellHead,
    cellBody: commonClasses.pre
  }), [commonClasses])

  const [wallet, setWallet] = useState<PlayerWallet>({ id: WalletType.CENTER, state: 'rejected', cash: '0', is_locked: false })

  const { handleClick: handleTransferToMain, loading } = useGDKFuncHandleClick({
    payload: request.account,
    gdkFunc: (payload) => gdk.withdraw.dumpWallet(payload),
    gdkFuncDependencies: [gdk],
    onSuccess: reload,
    onError: reload
  })

  useGetDataByPayload({
    payload: request.account,
    canLoadData: request.account !== undefined,
    gdkFunc: (payload) => gdk.withdraw.getWallets(payload, [WalletType.CENTER]).pipe(
      map((data) => data[0])
    ),
    gdkFuncDependencies: [gdk, request, reloadFlag],
    onBeforeFetch: pageFlow.setLoadingStart,
    onSuccess: (res: PlayerWallet) => {
      setWallet(res)
      pageFlow.setContentShow()
    },
    onError: pageFlow.setGDKError
  })

  const rows = useMemo(() => [{
    account: request.account,
    cash: (loading) ? <Box component="span">--</Box> : <PointsCell points={wallet.cash ?? '0.0000'} />,
    action: (
      <Grid container direction="row" spacing={1} justify="center">
        <Grid item>
          <Button
            size="small"
            className={commonClasses.blueGradualOutlineButton}
            classes={{
              disabled: commonClasses.blueGradualOutlineDisabledButton
            }}
            disabled={loading}
            onClick={handleTransferToMain}
          >
            {t('common.oneClickDump')}
          </Button>
        </Grid>
        <Grid item>
          <Button
            size="small"
            className={commonClasses.blueGradualOutlineButton}
            classes={{
              disabled: commonClasses.blueGradualOutlineDisabledButton
            }}
            component={Link}
            to={allRoute.playerReportTransferUpdate.encodePath({ search: {}, param: { account: request.account } })}
          >
            {t('common.transfer')}
          </Button>
        </Grid>
      </Grid>
    )
  }], [request.account, loading, wallet.cash, commonClasses.blueGradualOutlineButton, commonClasses.blueGradualOutlineDisabledButton, handleTransferToMain, t])

  const data = useMemo(() => {
    return createTableData<RowType>(
      {
        account: {
          label: t('common.playerAccount'),
          value: 'account',
          align: 'center'
        },
        cash: {
          label: mainWalletName,
          value: 'cash',
          align: 'center'
        },
        action: {
          label: t('common.function'),
          value: 'action',
          align: 'center'
        }
      },
      [
        'account',
        'cash',
        'action'
      ],
      rows,
      'account'
    )
  }, [t, rows])

  if (!request.account || !writableTransfer) return null
  return (
    <Box paddingY={3}>
      <LoadingAndErrorFrame {...pageFlow.status}>
        <ScrollablePaper marginX={5}>
          <CoreTable
            classes={tableClasses}
            data={data}
            total={1}
            showNoData={false}
          />
        </ScrollablePaper>
        <Box marginX={6}>
          <Tip text={t('common.transferLockTip')} />
        </Box>
      </LoadingAndErrorFrame>
    </Box>
  )
}

export default React.memo(PlayerReportTransferSingleAction)

import React, { useMemo } from 'react'
import Typography from '@material-ui/core/Typography'
import { useInternValue } from '../../utils/default/FormHook'
import { useCommonStyles } from '../../utils/admin/StyleHook'
import OnOffCheckbox from '../default/form/OnOffCheckbox'
import useT from '../../i18ns/admin/useT'
import { AgentType } from '@golden/gdk-admin'
import agentTypeName from '../../constants/default/agentTypeName'

export type OpenAgentLayerValue = number[]

export interface PropTypes {
  label: string
  value?: OpenAgentLayerValue
  defaultValue?: OpenAgentLayerValue
  onChange?: (value: OpenAgentLayerValue) => void
  disabled?: boolean
  required?: boolean
}

const OpenAgentLayerInput: React.FC<PropTypes> = (props) => {
  const {
    label,
    value,
    defaultValue,
    onChange,
    disabled,
    required
  } = props

  const { t } = useT()
  const commonClasses = useCommonStyles()

  const layers = useMemo(() => Object.keys(agentTypeName).map((key) => Number(key) as AgentType).map((id) => ({ id, name: t(agentTypeName[id]) })), [t])

  const [internValue, setInternValue] = useInternValue<OpenAgentLayerValue>(defaultValue ?? value ?? [], value)

  const allChecked = layers.every((layer) => internValue.includes(layer.id))

  return (
    <React.Fragment>
      <Typography className={commonClasses.bold}>{label}{required && '*'}</Typography>
      <OnOffCheckbox
        disabled={disabled}
        label={t('common.all')}
        value={allChecked}
        onChange={() => {
          const newValue = allChecked ? [] : layers.map((layer) => layer.id)
          setInternValue(newValue)
          if (onChange) onChange(newValue)
        }}
      />
      {
        layers
          .map((item) => (
            <OnOffCheckbox
              key={item.id}
              disabled={disabled}
              label={item.name}
              value={internValue.includes(item.id)}
              onChange={() => {
                const newValue = internValue.includes(item.id)
                  ? internValue.filter((layer) => layer !== item.id)
                  : internValue.concat(item.id)
                setInternValue(newValue)
                if (onChange) onChange(newValue)
              }}
            />
          ))
      }
    </React.Fragment>
  )
}

export default React.memo(OpenAgentLayerInput)

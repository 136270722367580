import { AdminTransferTransactionStatusType } from '@golden/gdk-admin'

const transferTransactionStatusTypeColor: { [key in AdminTransferTransactionStatusType]: string } = {
  [AdminTransferTransactionStatusType.IN_PROGRESS]: '#b86fff',
  [AdminTransferTransactionStatusType.SUCCESSFUL]: '#72d476',
  [AdminTransferTransactionStatusType.FAIL]: '#f44336',
  [AdminTransferTransactionStatusType.ROLLBACK]: '#f44336',
  [AdminTransferTransactionStatusType.ERROR]: '#f44336'
}

export default transferTransactionStatusTypeColor

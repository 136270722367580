import { OperationType } from '@golden/gdk-admin'
import TKeyType from '../../i18ns/admin/TKeyType'

const operationTypeName: { [key in OperationType]: TKeyType } = {
  [OperationType.CREATE]: 'operation.create',
  [OperationType.MODIFY]: 'operation.modify',
  [OperationType.DELETE]: 'operation.delete'
}

export default operationTypeName

import React, { useMemo, createContext, useState, useContext } from 'react'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { TextFieldProps } from '@material-ui/core/TextField'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import useT from '../../../i18ns/admin/useT'
import { InitialFormFunc, useDialogHandleSubmit, useGetData } from '../../../utils/default/ComplexFlowHook'
import { ValueGetter, FormValidation, createDefaultFormState } from '../../../utils/default/FormHook'
import { getValueFromChangeEvent, getValueFromCheckboxEvent, getValueFromValue } from '../../../utils/default/FormHelper'
import FormStateProvider from '../../../components/default/form/FormStateProvider'
import FormField from '../../../components/default/form/FormField'
import NumberInput from '../../../components/default/form/NumberInput'
import OnOffCheckbox, { PropTypes as OnOffCheckboxProps } from '../../../components/default/form/OnOffCheckbox'
import { useChecker } from '../../../utils/admin/AdminRouteHook'
import BanksInput, { PropTypes as BanksInputProps } from '../../../components/admin/BanksInput'
import FormSubmitButton from '../../../components/default/form/FormSubmitButton'
import useGlobalDialog from '../../../providers/admin/dialog/useGlobalDialog'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { AutoWithdraw } from '@golden/gdk-admin'
import { createGlobalDialogConfig } from '../../../utils/default/DialogHelper'
import { usePageFlow } from '../../../utils/default/PageFlowHook'
import LoadingAndErrorFrame from '../../../components/default/frames/LoadingAndErrorFrame'
import { createValidateRange } from '../../../utils/default/Validator'

interface FormType {
  days: string
  isFirst: boolean
  fmWarned: boolean
  rcWarned: boolean
  isDebitCardInBlackList: boolean
  banks: number[]
}

const initialForm: InitialFormFunc<FormType> = (defaultForm) => ({
  days: '',
  isFirst: false,
  fmWarned: false,
  rcWarned: false,
  isDebitCardInBlackList: false,
  banks: [],
  ...defaultForm
})

const getValueFromEvent: ValueGetter<FormType> = {
  days: getValueFromChangeEvent,
  isFirst: getValueFromCheckboxEvent,
  fmWarned: getValueFromCheckboxEvent,
  rcWarned: getValueFromCheckboxEvent,
  isDebitCardInBlackList: getValueFromCheckboxEvent,
  banks: getValueFromValue
}

const formToRequest = (form: FormType): AutoWithdraw => {
  const data = {
    register_unreached_days: form.days === '' ? 0 : Number(form.days),
    is_first_withdraw: form.isFirst,
    is_fm_warning_account: form.fmWarned,
    is_rc_warning_account: form.rcWarned,
    is_debit_card_in_black_list: form.isDebitCardInBlackList,
    banks: form.banks.filter((item) => item !== -1)
  } as AutoWithdraw
  return data
}

const defaultForm = initialForm()

const FormContext = createContext(createDefaultFormState(defaultForm))

const Fields: React.FC = React.memo(() => {
  const { error } = useContext(FormContext)
  const writable = useChecker()
  const { t } = useT()
  const moneyInputProps = useMemo(() => ({
    decimalScale: 0,
    fixedDecimalScale: true,
    allowNegative: false,
    thousandSeparator: true,
    readOnly: !writable
  }), [writable])
  return (
    <Grid container direction="row" alignItems={error.days ? 'center' : 'flex-end'} spacing={2}>
      <Grid item xs={12} md={12} lg={3}>
        <FormField<FormType, TextFieldProps>
          context={FormContext}
          component={NumberInput}
          name="days"
          inputProps={moneyInputProps}
          fullWidth
          label={t('common.registerUnreachDay')}
          placeholder={t('placeholder.inputRegisterUnreachDay')}
        />
      </Grid>
      <Grid item xs={12} md={12} lg={9}>
        <Grid container spacing={2}>
          <Grid item>
            <FormField<FormType, OnOffCheckboxProps>
              context={FormContext}
              component={OnOffCheckbox}
              name="isFirst"
              label={t('common.isFirstWithdraw')}
              disabled={!writable}
            />
          </Grid>
          <Grid item>
            <FormField<FormType, OnOffCheckboxProps>
              context={FormContext}
              component={OnOffCheckbox}
              name="fmWarned"
              label={`${t('common.finance')} ${t('common.warning')}`}
              disabled={!writable}
            />
          </Grid>
          <Grid item>
            <FormField<FormType, OnOffCheckboxProps>
              context={FormContext}
              component={OnOffCheckbox}
              name="rcWarned"
              label={`${t('common.riskControl')} ${t('common.warning')}`}
              disabled={!writable}
            />
          </Grid>
          <Grid item>
            <FormField<FormType, OnOffCheckboxProps>
              context={FormContext}
              component={OnOffCheckbox}
              name="isDebitCardInBlackList"
              label={t('common.isDebitCardInBlackList')}
              disabled={!writable}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
})

const WithdrawalAutoPage: React.FC = () => {
  const commonClasses = useCommonStyles()
  const globalDialog = useGlobalDialog()
  const pageFlow = usePageFlow()
  const gdk = useGDK()
  const writable = useChecker()
  const { t } = useT()
  const [form, setForm] = useState<FormType>(defaultForm)
  const validation = useMemo(() => {
    return {
      days: [
        {
          func: createValidateRange('days', (value) => Number(value), 'lessEqual', 100, t('common.dayMustLessThan100')),
          when: ['change', 'beforeClickSubmit']
        }
      ],
      isFirst: [],
      fmWarned: [],
      rcWarned: [],
      isDebitCardInBlackList: [],
      banks: []
    } as FormValidation<FormType>
  }, [t])
  useGetData({
    gdkFunc: () => gdk.withdraw.getAutoWithdraw(),
    gdkFuncDependencies: [gdk],
    onBeforeFetch: pageFlow.setLoadingStart,
    onSuccess: (res: AutoWithdraw) => {
      setForm(initialForm({
        days: res.register_unreached_days || res.register_unreached_days === 0 ? res.register_unreached_days.toString() : '',
        isFirst: res.is_first_withdraw || false,
        fmWarned: res.is_fm_warning_account || false,
        rcWarned: res.is_rc_warning_account || false,
        isDebitCardInBlackList: res.is_debit_card_in_black_list || false,
        banks: res.banks || []
      }))
      pageFlow.setContentShow()
    },
    onError: pageFlow.setGDKError
  })
  const { handleSubmit } = useDialogHandleSubmit({
    dialogId: 'autoWithdraw',
    globalDialog,
    formToRequest,
    gdkFunc: (form) => gdk.withdraw.putAutoWithdraw(form),
    gdkFuncDependencies: [gdk],
    getChangeDialogConfig: () => createGlobalDialogConfig({
      showIcon: false,
      message: t('dialog.confirmAutoWithdrawSetting')
    }),
    getSuccessDialogConfig: () => createGlobalDialogConfig({
      showIcon: false,
      message: t('dialog.autoWithdrawSettingSuccess'),
      showCancel: false
    }),
    getFailDialogConfig: (error) => createGlobalDialogConfig({
      showIcon: true,
      variant: 'error',
      showCancel: false,
      message: error
    })
  })
  return (
    <Box padding={5}>
      <Paper>
        <Box padding={4}>
          <Box
            paddingY={1.25}
            paddingX={2}
            marginBottom={2}
            className={commonClasses.pinkTitleBar}
          >
            <Typography variant="h5">
              {t('page.withdrawAuto')}
            </Typography>
          </Box>
          <Box marginBottom={2}>
            <Typography color="error">
              {t('common.autoWithdrawTip')}
            </Typography>
          </Box>
          <LoadingAndErrorFrame { ...pageFlow.status }>
            <FormStateProvider
              context={FormContext}
              defaultValue={form}
              onSubmit={handleSubmit}
              getValueFromEvent={getValueFromEvent}
              validation={validation}
            >
              <Box marginBottom={2}>
                <Fields />
              </Box>
              <FormField<FormType, BanksInputProps>
                context={FormContext}
                component={BanksInput}
                name="banks"
                label={t('common.bankBlacklist')}
                placeholder={t('placeholder.inputBankBlacklist')}
                fullWidth
                disabled={!writable}
              />
              {writable && (
                <Box marginTop={2} display="flex" justifyContent="flex-end">
                  <FormSubmitButton
                    context={FormContext}
                    component={Button}
                    className={commonClasses.purpleGradualButton}
                    type="submit"
                  >
                    {t('common.confirmSetting')}
                  </FormSubmitButton>
                </Box>
              )}
            </FormStateProvider>
          </LoadingAndErrorFrame>
        </Box>
      </Paper>
    </Box>
  )
}

export default React.memo(WithdrawalAutoPage)

import React, { useState, useCallback, useContext, Dispatch, SetStateAction } from 'react'
import { useCommonStyles } from '../../utils/admin/StyleHook'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import useT from '../../i18ns/admin/useT'
import RequiredText from '../default/form/RequiredText'

interface PropTypes {
  context: React.Context<[string, Dispatch<SetStateAction<string>>]>
}

const EmperorSearchForm: React.FC<PropTypes> = (props) => {
  const { context } = props
  const [, setState] = useContext(context)
  const [account, setAccount] = useState<string>('')
  const classes = useCommonStyles()
  const { t } = useT()
  return (
    <Paper>
      <Box padding={4}>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Box
              paddingY={1.25}
              paddingX={2}
              className={classes.pinkTitleBar}
            >
              <Typography variant="h5">
                {t('common.accountSearch')}
              </Typography>
            </Box>
          </Grid>
          <Grid item>
            <Grid container direction="row" spacing={2}>
              <Grid item xs={12} md={6} lg={3}>
                <TextField
                  label={t('common.emperorAccount')}
                  placeholder={t('placeholder.inputAgentEmperorAccount')}
                  value={account}
                  onChange={useCallback((event) => setAccount(event.target.value), [])}
                  fullWidth
                  required
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <RequiredText />
          </Grid>
          <Grid item>
            <Box display="flex" justifyContent="flex-end">
              <Button
                className={classes.purpleGradualButton}
                onClick={useCallback(() => {
                  setState(account)
                // eslint-disable-next-line react-hooks/exhaustive-deps
                }, [account])}
                disabled={account === ''}
              >
                {t('common.search')}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  )
}

export default React.memo(EmperorSearchForm)

import { PermissionType } from '@golden/gdk-admin'
import LinkType from '../../default/navigation/LinkType'
import TKeyType from '../../../i18ns/admin/TKeyType'
import RoutePermission from '../route/RoutePermission'
import { goldenURL } from '@golden/url'

export enum LinkKey {
  BATTLE_FIELD = 'battleField'
}

const stage = import.meta.env.VITE_APP_STAGE
const url = import.meta.env.VITE_APP_URL
const csUrl = goldenURL(stage === 'local' ? url : undefined, 'https:').get('bfAdmin')
const link: {
  [key in LinkKey]: LinkType<LinkKey, RoutePermission, TKeyType>
} = {
  [LinkKey.BATTLE_FIELD]: {
    key: LinkKey.BATTLE_FIELD,
    title: 'page.csChat',
    href: `${csUrl}/case`,
    blank: true,
    permissions: [
      { type: 'accessible', value: PermissionType.CUSTOMER_SERVICE_READ }
    ]
  }
}

export default link

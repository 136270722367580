import React, { useMemo, createContext, useContext } from 'react'
import { InternalWithdrawInfo, InternalWithdrawSlip } from '@golden/gdk-admin'
import { useInternValue } from '../../../utils/default/FormHook'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import useT from '../../../i18ns/admin/useT'
import { createTableData, formatMoney } from '../../../utils/default/TableHelper'
import CoreTable from '../../default/present/CoreTable'
import NumberInputBase from '../../default/form/NumberInput'
import { getCashInputProps } from '../../../utils/default/FormHelper'

export interface PropTypes {
  minCash: string
  accounts: InternalWithdrawInfo['accounts']
  defaultValue?: InternalWithdrawSlip['accounts']
  value?: InternalWithdrawSlip['accounts']
  onChange?: (value: InternalWithdrawSlip['accounts']) => void
}

interface RowType {
  id: number
  bank: string
  name: string
  account: string
  cash: React.ReactElement
}

const StateContext = createContext<[InternalWithdrawSlip['accounts'], (value: InternalWithdrawSlip['accounts']) => void]>([
  [],
  () => {}
])

const inputProps = getCashInputProps()

const NumberInput: React.FC<{ item: InternalWithdrawInfo['accounts'][0], minCash: string }> = React.memo((props) => {
  const { item, minCash } = props
  const [internValue, setInternValue] = useContext(StateContext)
  const { t } = useT()
  const target = internValue.find((iv) => iv.id === item.id)
  let error: null | string = null
  let helperText = `${item.cash_min ? formatMoney(item.cash_min) : ''} ~ ${item.cash_max ? formatMoney(item.cash_max) : ''}`
  helperText = helperText === ' ~ ' ? '' : helperText
  if (target && item.cash_max && target.cash > Number(item.cash_max)) {
    helperText = `${t('error.bankMaxCash')} ${formatMoney(item.cash_max)}`
  }
  if (target?.cash && target.cash < Number(minCash)) {
    error = `${t('error.forwarderMinCash')} ${formatMoney(minCash)}`
  }
  return (
    <NumberInputBase
      fullWidth
      margin="dense"
      variant="outlined"
      inputProps={inputProps}
      value={target?.cash ?? ''}
      onChange={(event) => {
        const cash = Number(event.target.value)
        const newValue = internValue.filter((iv) => iv.id !== item.id).concat({ id: item.id, cash })
        setInternValue(newValue)
      }}
      error={error !== null}
      helperText={error ?? helperText}
    />
  )
})

const TableBase: React.FC<{ accounts: InternalWithdrawInfo['accounts'], minCash: string }> = React.memo((props) => {
  const { accounts, minCash } = props
  const classes = useCommonStyles()
  const { t } = useT()
  const tableClasses = useMemo(() => {
    return {
      head: classes.blackTableHead,
      row: classes.greyTableRow,
      cellHead: classes.tableCellHead
    }
  }, [classes])
  const rows: RowType[] = useMemo(() => {
    return accounts.map((item) => ({
      id: item.id,
      bank: item.bank_name,
      name: item.real_name,
      account: item.bank_account,
      cash: (<NumberInput item={item} minCash={minCash} />)
    } as RowType))
  }, [accounts, minCash])
  const data = useMemo(() => {
    return createTableData<RowType>(
      {
        id: {
          label: '',
          value: 'id'
        },
        bank: {
          label: t('common.bankName'),
          value: 'bank',
          align: 'center'
        },
        name: {
          label: t('common.realName'),
          value: 'name',
          align: 'center'
        },
        account: {
          label: t('common.bankAccount'),
          value: 'account',
          align: 'center'
        },
        cash: {
          label: t('common.internalWithdrawCashAssign'),
          value: 'cash',
          align: 'center'
        }
      },
      [
        'bank',
        'name',
        'account',
        'cash'
      ],
      rows,
      'id'
    )
  }, [t, rows])
  return (
    <CoreTable
      dense
      classes={tableClasses}
      data={data}
      total={rows.length}
    />
  )
})

const FinanceInternalWithdrawExecuteTable: React.FC<PropTypes> = (props) => {
  const { minCash, accounts, defaultValue, value, onChange } = props
  const [internValue, setInternValue] = useInternValue<InternalWithdrawSlip['accounts']>(
    defaultValue ?? value ?? [],
    value
  )
  const handleChange = (value: InternalWithdrawSlip['accounts']) => {
    setInternValue(value)
    if (onChange) onChange(value)
  }
  return (
    <StateContext.Provider value={[internValue, handleChange]}>
      <TableBase accounts={accounts} minCash={minCash} />
    </StateContext.Provider>
  )
}

export default React.memo(FinanceInternalWithdrawExecuteTable)

import React, { useCallback, useEffect } from 'react'
import { WithdrawalWayForm, Branch, WithdrawWayType } from '@golden/gdk-admin'
import Box from '@material-ui/core/Box'
import useT from '../../../i18ns/admin/useT'
import { useDialogHandleSubmit, useRedirectHandleBack, useRequestFromSearch } from '../../../utils/default/ComplexFlowHook'
import useGlobalDialog from '../../../providers/admin/dialog/useGlobalDialog'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import { createGlobalDialogConfig } from '../../../utils/default/DialogHelper'
import allRoute from '../../../components/admin/route/route'
import EditFinanceWithdrawalWayForm, { EditFinanceWithdrawalWayFormType, initialForm } from '../../../components/admin/finance/EditFinanceWithdrawalWayForm'
import { searchToRequest } from './FinanceWithdrawalWayPage'
import useGDKStore from '../../../providers/admin/gdk/useGDKStore'

const formToRequest: (form: EditFinanceWithdrawalWayFormType) => WithdrawalWayForm = (form) => {
  return {
    slug: form.slug,
    forwarder_id: Number(form.courier),
    cash_min: form.min,
    cash_max: form.max,
    cash_auto_min: form.autoMin,
    cash_auto_max: form.autoMax,
    layer_ids: form.layer.layers,
    is_active: form.isActive,
    is_auto_withdraw: form.isAuto,
    method: form.method as WithdrawWayType
  }
}

const defaultForm = initialForm()

const FinanceWithdrawalWayCreatePage: React.FC = () => {
  const request = useRequestFromSearch({ searchToRequest })
  const classes = useCommonStyles()
  const globalDialog = useGlobalDialog()
  const gdk = useGDK()
  const { t } = useT()
  const forwarders = useGDKStore.finance.forwarders()

  const [handleBack, handleDebouncedBack] = useRedirectHandleBack({
    path: allRoute.financeWithdrawalWay.encodePath({ search: { ...request }, param: {} })
  })

  const { handleSubmit } = useDialogHandleSubmit<EditFinanceWithdrawalWayFormType, WithdrawalWayForm, null>({
    dialogId: 'createWay',
    globalDialog,
    getChangeDialogConfig: useCallback((form) => createGlobalDialogConfig({
      showIcon: false,
      message: (
        <React.Fragment>
          {t('dialog.confirmCreateWithdrawal')}
          <span className={classes.purpleWord}>
            {`${forwarders[form.slug]?.name ?? ''} ${form.name}`}
          </span>
        </React.Fragment>
      )
    }), [t, forwarders, classes.purpleWord]),
    getSuccessDialogConfig: useCallback(() => createGlobalDialogConfig({
      showIcon: false,
      showCancel: false,
      message: t('dialog.createWithdrawalSuccess')
    }), [t]),
    getFailDialogConfig: useCallback((error) => createGlobalDialogConfig({
      showIcon: true,
      variant: 'error',
      showCancel: false,
      message: error
    }), []),
    formToRequest,
    gdkFunc: (payload) => gdk.finance.createWithdrawalWay(payload),
    gdkFuncDependencies: [gdk],
    afterSuccessDialog: handleBack
  })
  useEffect(() => {
    gdk.trunk.trigger([Branch.PLAYER_LAYER, Branch.FORWARDERS])
  }, [gdk])

  return (
    <Box padding={5}>
      <EditFinanceWithdrawalWayForm
        title={t('page.createWithdrawal')}
        okText={t('common.confirmCreate')}
        onBack={handleDebouncedBack}
        onSubmit={handleSubmit}
        defaultValue={defaultForm}
      />
    </Box>
  )
}

export default React.memo(FinanceWithdrawalWayCreatePage)

import React from 'react'
import Box from '@material-ui/core/Box'
import Divider from '@material-ui/core/Divider'
import GroupComponent from './GroupComponent'
import { RegionKey } from './region'
import allGroup from './group'
import GroupKey from './GroupKey'
import RegionType from '../../default/navigation/RegionType'

interface PropTypes {
  region: RegionType<RegionKey, GroupKey>
}

const RegionComponent: React.FC<PropTypes> = (props) => {
  const { region } = props
  const groups = region.groups.map((key) => allGroup[key])
  return (
    <React.Fragment>
      {groups.map((group) => (<GroupComponent key={group.key} group={group} />))}
      {!region.noDivider && (
        <Box paddingTop={2.5} paddingBottom={2.5}>
          <Divider />
        </Box>
      )}
    </React.Fragment>
  )
}

export default React.memo(RegionComponent, (prev, next) => {
  return prev.region.key === next.region.key
})

import React, { useState, useMemo } from 'react'
import { Link } from 'react-router-dom'
import {
  PaginationRes,
  CashLog,
  AdminCashLogType,
  ADMIN_API,
  BalanceRetrieveRecord,
  BalanceRetrieveRecordStatus,
  WalletType
} from '@golden/gdk-admin'
import Box from '@material-ui/core/Box'
import { searchToRequest } from './PlayerReportCashForm'
import CoreTable from '../../default/present/CoreTable'
import PointsCell from '../../default/present/PointsCell'
import LoadingAndErrorFrame from '../../default/frames/LoadingAndErrorFrame'
import ScrollablePaper from '../../default/present/ScrollablePaper'
import { useRequestFromSearch, useGetDataByPayload, usePaginationClickAndChangeUrl, usePerPageChange } from '../../../utils/default/ComplexFlowHook'
import { usePageFlow } from '../../../utils/default/PageFlowHook'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { createDefaultPaginationData, createTableData, formatMoney } from '../../../utils/default/TableHelper'
import useT from '../../../i18ns/admin/useT'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import allRoute from '../route/route'
import adminCashLogName from '../../../constants/admin/adminCashLogName'
import DateTime from '../../default/present/DateTime'
import BalanceRetrieveRecordDialog from '../BalanceRetrieveRecordDialog'
import { endOfDay, getTime, startOfDay, subDays } from 'date-fns'

interface RowType {
  id: number
  account: string
  serial: React.ReactElement
  type: string
  before: string
  delta: React.ReactElement
  after: string
  balance: React.ReactElement
  details: BalanceRetrieveRecord[]
  updatedAt: React.ReactElement
  updatedBy: string
  memo: React.ReactElement
}

const PlayerReportCashTable: React.FC = () => {
  const classes = useCommonStyles()
  const { t } = useT()
  const gdk = useGDK()
  const [list, setList] = useState<PaginationRes<CashLog[]>>(createDefaultPaginationData([]))

  const pageFlow = usePageFlow()
  const request = useRequestFromSearch({ searchToRequest })
  useGetDataByPayload({
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    payload: request!,
    gdkFunc: (payload) => gdk.history.getCashLog(payload),
    gdkFuncDependencies: [gdk],
    onBeforeFetch: pageFlow.setLoadingStart,
    onSuccess: (res: PaginationRes<CashLog[]>) => {
      setList(res)
      pageFlow.setContentShow()
    },
    onError: pageFlow.setGDKError,
    canLoadData: request !== undefined
  })
  const tableClasses = useMemo(() => ({
    head: classes.pinkTableHead,
    row: classes.tableRow,
    cellHead: classes.tableCellHead
  }), [classes])
  const rows: RowType[] = useMemo(() => {
    return list.data.map((item, index) => {
      let href = ''
      if (item.category === AdminCashLogType.DEPOSIT) {
        if (item.extra?.is_manual) {
          href = allRoute.manualDeposit.encodePath({ search: { serial_number: item.order_number, page: 1 }, param: {} })
        } else {
          if (item.extra?.is_third_party) {
            href = allRoute.depositThirdPartySlip.encodePath({ search: { order_number: item.order_number, page: 1 }, param: {} })
          } else {
            href = allRoute.depositSlip.encodePath({ search: { order_number: item.order_number, page: 1 }, param: {} })
          }
        }
      }
      if (item.category === AdminCashLogType.WITHDRAW) {
        if (item.extra?.is_manual) {
          href = allRoute.manualWithdraw.encodePath({ search: { serial_number: item.order_number, page: 1 }, param: {} })
        } else {
          href = allRoute.withdrawalRCSlip.encodePath({ search: { order_number: item.order_number, page: 1 }, param: {} })
        }
      }

      if (item.category === AdminCashLogType.TRANSFER_EXPORT || item.category === AdminCashLogType.TRANSFER_IMPORT) {
        if (item.extra?.wallet) {
          href = allRoute.playerReportTransfer.encodePath({ search: { transfer_number: item.order_number, golden_wallet: WalletType.CENTER, game_wallet: item.extra.wallet, page: 1 }, param: {} })
        } else if (item.extra?.game_wallet) {
          href = allRoute.playerReportTransfer.encodePath({ search: { transfer_number: item.order_number, golden_wallet: item.extra?.golden_wallet ?? WalletType.CENTER, game_wallet: item.extra.game_wallet, page: 1 }, param: {} })
        } else {
          href = allRoute.playerReportTransfer.encodePath({ search: { transfer_number: item.order_number, golden_wallet: WalletType.CENTER, page: 1 }, param: {} })
        }
      }

      if (item.category === AdminCashLogType.AGENT_TRANSFER || item.type === 'agent_transfer_deposit') {
        href = allRoute.agentWalletHistory.encodePath({ search: { transfer_number: item.order_number, page: 1 }, param: {} })
      }

      if (item.category === AdminCashLogType.FEEDBACK) {
        href = allRoute.playerReportFeedbackHistory.encodePath({ search: { serial_number: item.order_number, page: 1, start_at: getTime(subDays(startOfDay(item.updated_at), 60)), end_at: getTime(endOfDay(item.updated_at)) }, param: {} })
      }

      return {
        id: index,
        account: item.account,
        serial: href === '' ? (<p>{item.order_number}</p>) : (<Link className={classes.anchor} to={href}>{item.order_number}</Link>),
        type: t(adminCashLogName[item.category]),
        before: formatMoney(item.before),
        delta: (<PointsCell showSign points={item.cash} />),
        after: formatMoney(item.after),
        balance: item.cash_balance
          ? (<span
            className={classes.anchor}
            onClick={() => {
              setIsDialogOpen(true)
              setDialogIndex(index)
            }}
          >
            {`${formatMoney(item.cash_balance)}${item.balances.filter((el) => el.status !== BalanceRetrieveRecordStatus.SUCCESS).length > 0 ? '*' : ''}`}
          </span>)
          : '-',
        details: item.balances,
        updatedAt: (<DateTime time={item.updated_at} />),
        updatedBy: item.updated_by,
        memo: (
          <p className={classes.pre}>
            {item.memo}
          </p>
        )
      } as RowType
    })
  }, [list.data, t, classes])
  const data = useMemo(() => {
    return createTableData<RowType>(
      {
        id: {
          label: '',
          value: 'id'
        },
        account: {
          label: t('common.playerAccount'),
          value: 'account',
          align: 'center'
        },
        serial: {
          label: t('common.serialNumber'),
          value: 'serial',
          align: 'center'
        },
        type: {
          label: t('common.cashLogType'),
          value: 'type',
          align: 'center'
        },
        before: {
          label: t('common.beforeBalance'),
          value: 'before',
          align: 'center'
        },
        delta: {
          label: t('common.deltaBalance'),
          value: 'delta',
          align: 'center'
        },
        after: {
          label: t('common.afterBalance'),
          value: 'after',
          align: 'center'
        },
        balance: {
          label: t('common.totalAmount'),
          value: 'balance',
          align: 'center'
        },
        updatedAt: {
          label: t('common.updateAt'),
          value: 'updatedAt',
          align: 'center'
        },
        updatedBy: {
          label: t('common.updateBy'),
          value: 'updatedBy',
          align: 'center'
        },
        memo: {
          label: t('common.memo'),
          value: 'memo',
          align: 'center'
        },
        details: {
          label: '',
          value: 'details'
        }
      },
      [
        'account',
        'serial',
        'type',
        'before',
        'delta',
        'after',
        'balance',
        'updatedAt',
        'updatedBy',
        'memo'
      ],
      rows,
      'id'
    )
  }, [rows, t])
  const handlePagination = usePaginationClickAndChangeUrl({
    request,
    encodePath: allRoute.playerReportCash.encodePath
  })
  const handlePerPageChange = usePerPageChange({
    request,
    encodePath: allRoute.playerReportCash.encodePath,
    gdk,
    pageKey: ADMIN_API.GET_CASH_LOG.url
  })

  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false)
  const [dialogIndex, setDialogIndex] = useState(-1)
  const details = useMemo(() => rows.length > 0 && dialogIndex !== -1 ? rows[dialogIndex].details : [], [rows, dialogIndex])

  if (request === undefined) return null
  return (
    <ScrollablePaper marginX={5}>
      <Box padding={4}>
        <LoadingAndErrorFrame { ...pageFlow.status }>
          <CoreTable
            classes={tableClasses}
            data={data}
            total={list.total}
            showPagination
            page={request.page}
            perPage={list.per_page}
            onChangePage={handlePagination}
            onChangeRowsPerPage={handlePerPageChange}
          />
        </LoadingAndErrorFrame>
      </Box>
      <BalanceRetrieveRecordDialog
        list={details}
        open={isDialogOpen}
        handleOk={() => { setIsDialogOpen(false) }}
      />
    </ScrollablePaper>
  )
}

export default React.memo(PlayerReportCashTable)

import React, { createContext, useMemo } from 'react'
import { AdminAnnouncementReq, AgentType, AnnouncementType, PlayerRankType, GuardType } from '@golden/gdk-admin'
import { isAfter, getTime } from 'date-fns'
import Grid from '@material-ui/core/Grid'
import MuiButton from '@material-ui/core/Button'
import MuiTextField, { TextFieldProps } from '@material-ui/core/TextField'
import DropDown, { PropTypes as DropDownProps } from '../../default/form/DropDown'
import OnOffCheckbox, { PropTypes as OnOffCheckboxProps } from '../../default/form/OnOffCheckbox'
import AnnouncementPermissionCheckboxGroup, {
  PropTypes as AnnouncementPermissionCheckboxGroupProps
} from './AnnouncementPermissionCheckboxGroup'
import DateTimePicker, { PropTypes as AnnouncementTimePickerProps } from '../../default/form/DateTimePicker'
import announcementTypeName from '../../../constants/default/announcementTypeName'
import FormStateProvider from '../../default/form/FormStateProvider'
import FormField from '../../default/form/FormField'
import FormSubmitButton from '../../default/form/FormSubmitButton'
import RequiredText from '../../default/form/RequiredText'
import { ValueGetter, FormValidation, createDefaultFormState } from '../../../utils/default/FormHook'
import {
  FormPropType,
  getValueFromChangeEvent,
  getValueFromCheckboxEvent,
  getValueFromValue
} from '../../../utils/default/FormHelper'
import { InitialFormFunc } from '../../../utils/default/ComplexFlowHook'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import { createValidateNotEmpty, createValidateRange, createValidateDate, createValidateEditorNotEmpty } from '../../../utils/default/Validator'
import useT from '../../../i18ns/admin/useT'
import TextEditor, { PropTypes as TextEditorProps } from '../../default/form/textEditor/TextEditor'
import { defaultEditorState, EditorState } from '@golden/tiptap-react'

export interface EditAnnouncementFormType {
  title: string
  beginAt: Date | null
  endAt: Date | null
  content: EditorState
  isPopup: boolean
  isPinned: boolean
  isMarquee: boolean
  type: AnnouncementType
  viewers: Array<{ role: GuardType, value: AgentType | PlayerRankType }>
}

export const initialForm: InitialFormFunc<EditAnnouncementFormType> = (form) => ({
  title: '',
  beginAt: null,
  endAt: null,
  content: defaultEditorState,
  isPopup: false,
  isPinned: false,
  isMarquee: false,
  type: AnnouncementType.SYSTEM,
  viewers: [],
  ...form
})

export const formToRequest = (data: EditAnnouncementFormType): AdminAnnouncementReq<number> => ({
  title: data.title,
  content: JSON.stringify(data.content.content),
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  begin_at: getTime(data.beginAt!),
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  end_at: getTime(data.endAt!),
  type: data.type,
  is_marquee: data.isMarquee,
  is_pinned: data.isPinned,
  is_pop_up: data.isPopup,
  viewers: data.viewers.map((item) => ({ role_type: item.role, sub_type: item.value }))
})

interface PropTypes extends FormPropType<EditAnnouncementFormType> {
  okText: string
  onBack: () => void
  isLook?: boolean
}

const getValueFromEvent: ValueGetter<EditAnnouncementFormType> = {
  title: getValueFromChangeEvent,
  beginAt: getValueFromValue,
  endAt: getValueFromValue,
  content: getValueFromValue,
  isPopup: getValueFromCheckboxEvent,
  isPinned: getValueFromCheckboxEvent,
  isMarquee: getValueFromCheckboxEvent,
  type: getValueFromChangeEvent,
  viewers: getValueFromValue
}

const AnnouncementFormContext = createContext(createDefaultFormState(initialForm()))

const TextField = React.memo(MuiTextField)

const Button = React.memo(MuiButton)

const EditAnnouncementForm: React.FC<PropTypes> = (props) => {
  const { defaultValue, onSubmit, okText, onBack, isLook = false } = props
  const classes = useCommonStyles()
  const { t } = useT()

  const textFieldInputProps = useMemo(() => {
    return {
      maxLength: 50,
      placeholder: t('placeholder.inputTitle')
    }
  }, [t])

  const validation: FormValidation<EditAnnouncementFormType> = useMemo(() => {
    return {
      title: [
        {
          func: createValidateNotEmpty<EditAnnouncementFormType>('title', t),
          when: ['change', 'beforeClickSubmit']
        },
        {
          func: createValidateRange<EditAnnouncementFormType>(
            'title', (value) => (value as string).length, 'moreEqual', 4, t('error.mustMoreEqualFourWords')
          ),
          when: ['change', 'beforeClickSubmit']
        },
        {
          func: createValidateRange<EditAnnouncementFormType>(
            'title', (value) => (value as string).length, 'lessEqual', 50, t('error.mustLessEqualFiftyWords')
          ),
          when: ['change', 'beforeClickSubmit']
        }
      ],
      beginAt: [
        {
          func: createValidateNotEmpty<EditAnnouncementFormType>('beginAt', t),
          when: ['change', 'beforeClickSubmit']
        },
        {
          func: createValidateDate<EditAnnouncementFormType>('beginAt', t),
          when: ['change', 'beforeClickSubmit']
        },
        {
          func: (value, form) => {
            if (form.endAt === null) {
              return { isPass: true, stop: false, newError: { beginAt: null } }
            }
            if (isAfter(value as Date, form.endAt)) {
              return { isPass: false, stop: true, newError: { beginAt: t('error.startMustBeforeEnd') } }
            }
            return { isPass: true, stop: false, newError: { beginAt: null, endAt: null } }
          },
          when: ['change', 'beforeClickSubmit']
        }
      ],
      endAt: [
        {
          func: createValidateNotEmpty<EditAnnouncementFormType>('endAt', t),
          when: ['change', 'beforeClickSubmit']
        },
        {
          func: createValidateDate<EditAnnouncementFormType>('endAt', t),
          when: ['change', 'beforeClickSubmit']
        },
        {
          func: (value, form) => {
            if (form.beginAt === null) {
              return { isPass: true, stop: false, newError: { endAt: null } }
            }
            if (isAfter(form.beginAt, value as Date)) {
              return { isPass: false, stop: true, newError: { endAt: t('error.endMustAfterStart') } }
            }
            return { isPass: true, stop: false, newError: { beginAt: null, endAt: null } }
          },
          when: ['change', 'beforeClickSubmit']
        }
      ],
      content: [
        {
          func: createValidateEditorNotEmpty('content', t),
          when: ['change', 'beforeClickSubmit']
        }
      ],
      isPopup: [],
      isPinned: [],
      isMarquee: [],
      type: [],
      viewers: [
        {
          func: createValidateRange(
            'viewers',
            (value) => (value as Array<{ role: GuardType, value: AgentType | PlayerRankType }>).length,
            'moreThan',
            0,
            t('error.mustNotEmpty')
          ),
          when: ['beforeClickSubmit']
        }
      ]
    }
  }, [t])

  const typeOptions = useMemo(() => {
    return Object.keys(announcementTypeName)
      .map((key) => ({ name: t(announcementTypeName[key as AnnouncementType]), value: key }))
      .filter((option) => (option.value !== AnnouncementType.STAFF))
  }, [t])

  return (
    <FormStateProvider<EditAnnouncementFormType>
      context={AnnouncementFormContext}
      defaultValue={defaultValue}
      onSubmit={onSubmit}
      getValueFromEvent={getValueFromEvent}
      validation={validation}
    >
      <Grid container direction="column" spacing={3}>
        <Grid item container direction="row">
          <Grid item xs={12}>
            <FormField<EditAnnouncementFormType, TextFieldProps>
              component={TextField}
              context={AnnouncementFormContext}
              name="title"
              label={t('common.title')}
              inputProps={textFieldInputProps}
              autoFocus
              fullWidth
              required
              disabled={isLook}
            />
          </Grid>
        </Grid>
        <Grid item container direction="row" spacing={2}>
          <Grid item xs={12} md={6}>
            <FormField<EditAnnouncementFormType, AnnouncementTimePickerProps>
              component={DateTimePicker}
              context={AnnouncementFormContext}
              name="beginAt"
              label={t('common.startDisplayTime')}
              placeholder={t('placeholder.inputBeginAt')}
              required
              disabled={isLook}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormField<EditAnnouncementFormType, AnnouncementTimePickerProps>
              component={DateTimePicker}
              context={AnnouncementFormContext}
              name="endAt"
              label={t('common.endDisplayTime')}
              placeholder={t('placeholder.inputEndAt')}
              required
              disabled={isLook}
            />
          </Grid>
        </Grid>
        <Grid item container direction="row">
          <Grid item xs={12}>
            <FormField<EditAnnouncementFormType, TextEditorProps>
              component={TextEditor}
              context={AnnouncementFormContext}
              name="content"
              controls={['textColor', 'clearFormat']}
              placeholder={`${t('placeholder.inputContent')}*`}
              disabled={isLook}
            />
          </Grid>
        </Grid>
        <Grid item container direction="row">
          <Grid item xs={12} md={2}>
            <FormField<EditAnnouncementFormType, DropDownProps>
              component={DropDown}
              context={AnnouncementFormContext}
              name="type"
              label={t('common.announcementType')}
              options={typeOptions}
              fullWidth
              required
              disabled={isLook}
            />
          </Grid>
          <Grid item xs={12} md={1} />
          <Grid item xs={12} md={3}>
            <FormField<EditAnnouncementFormType, OnOffCheckboxProps>
              component={OnOffCheckbox}
              context={AnnouncementFormContext}
              name="isPopup"
              label={t('common.announcementIsPopup')}
              disabled={isLook}
            />
            <Grid style={{ color: 'grey', opacity: 0.6 }}>
              {t('common.announcementPopupPrompt')}
            </Grid>
          </Grid>
          <Grid item xs={12} md={3}>
            <FormField<EditAnnouncementFormType, OnOffCheckboxProps>
              component={OnOffCheckbox}
              context={AnnouncementFormContext}
              name="isPinned"
              label={t('common.announcementIsPinned')}
              disabled={isLook}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <FormField<EditAnnouncementFormType, OnOffCheckboxProps>
              component={OnOffCheckbox}
              context={AnnouncementFormContext}
              name="isMarquee"
              label={t('common.announcementIsMarquee')}
              disabled={isLook}
            />
          </Grid>
        </Grid>
        <Grid item container direction="row">
          <FormField<EditAnnouncementFormType, AnnouncementPermissionCheckboxGroupProps>
            component={AnnouncementPermissionCheckboxGroup}
            context={AnnouncementFormContext}
            name="viewers"
            label={t('common.openLayer')}
            required
            disabled={isLook}
          />
        </Grid>
        <Grid item>
          <RequiredText />
        </Grid>
        <Grid item container direction="row" justifyContent="flex-end" spacing={2}>
          <Grid item>
            <Button
              className={classes.greyButton}
              onClick={onBack}
            >
              {!isLook ? t('common.cancel') : t('common.back')}
            </Button>
          </Grid>
          { !isLook && (<Grid item>
            <FormSubmitButton
              component={Button}
              context={AnnouncementFormContext}
              className={classes.purpleGradualButton}
              type="submit"
            >
              {okText}
            </FormSubmitButton>
          </Grid>) }
        </Grid>
      </Grid>
    </FormStateProvider>
  )
}

export default React.memo(EditAnnouncementForm)

import React, { useCallback, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import DropDown from '../../default/form/DropDown'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import useT from '../../../i18ns/admin/useT'
import allRoute from '../route/route'
import { useChecker } from '../../../utils/admin/AdminRouteHook'
import { useRequestFromSearch, useChangeUrlSubmit, useGetData, useReload } from '../../../utils/default/ComplexFlowHook'
import { searchToRequest, RequestType, IS_PROXY_FOR_DEPOSIT } from '../../../views/admin/finance/FinanceDepositWayPage'
import { isUndefined, omitBy } from '@golden/utils'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { DepositWayQuery, FinanceDepositFeeMethodQuery, FinanceDepositQueryList, ForwarderConfig } from '@golden/gdk-admin'
import { forkJoin } from 'rxjs'
import FinanceDepositSortDialog from './FinanceDepositSortDialog'

const FinanceDepositWayToolbar: React.FC = () => {
  const request = useRequestFromSearch({ searchToRequest })
  const classes = useCommonStyles()
  const { t } = useT()
  const gdk = useGDK()
  const { reload, reloadFlag } = useReload()

  const [depositQueryData, setDepositQueryData] = useState<FinanceDepositQueryList | null>(null)
  const [forwarderList, setForwarderList] = useState<Array<{ name: string, value: string }> | null>(null)
  const [methodList, setMethodList] = useState<FinanceDepositFeeMethodQuery[]>([])
  const [isShowDepositSortDialog, setIsShowDepositSortDialog] = useState(false)

  const forwarderOptions = useMemo(() => ([
    { name: t('common.allForwarder'), value: '--' },
    ...forwarderList?.map(forwarder => {
      const count = depositQueryData?.forwarders.find(item => item.slug === forwarder.value)?.count
      return { name: `${forwarder.name}（${count ?? 0}）`, value: forwarder.value }
    }) ?? []
  ]), [forwarderList, depositQueryData, t])
  const methodOptions = useMemo(() => [
    { name: t('common.allMethod'), value: '--' },
    ...depositQueryData?.methods.filter(data => methodList.some(item => item.method === data.method)).map(({ name, count, method, first_withdraw_by_debit_card: isFirstWithdrawByDebitCard }) => ({ name: `${name}${isFirstWithdrawByDebitCard ? ` | ${t('common.firstWithdrawLimit')}` : ''}（${count}）`, value: method })
    ) ?? []
  ], [methodList, depositQueryData, t])
  const layerOptions = useMemo(() => [
    { name: t('common.allLayer'), value: '--' },
    ...depositQueryData?.layers.map(({ name, count, id }) => ({ name: `${name}（${count}）`, value: id })) ?? [],
    { name: `${t('common.staffDeposit')}（${depositQueryData?.is_for_proxy_deposit ?? 0}）`, value: IS_PROXY_FOR_DEPOSIT }
  ],
  [depositQueryData, t])
  const statusOptions = useMemo(() => ([
    { name: t('common.allStatus'), value: 2 },
    { name: t('common.toggleOn'), value: 1 },
    { name: t('common.toggleOff'), value: 0 }
  ]), [t])

  useGetData({
    gdkFunc: () => forkJoin([gdk.finance.getDepositQueryList({
      is_active: request?.is_active === 2 ? undefined : request?.is_active,
      method: request?.method === '--' ? undefined : request?.method,
      forwarder_slug: request?.forwarder_slug === '--' ? undefined : request?.forwarder_slug,
      layer_id: (request?.layer_id === '--' || request?.layer_id === IS_PROXY_FOR_DEPOSIT) ? undefined : request?.layer_id,
      is_for_proxy_deposit: request?.layer_id === IS_PROXY_FOR_DEPOSIT ? 1 : undefined
    } as DepositWayQuery), gdk.finance.getForwarderConfig(), gdk.finance.getDepositFeeMethod()]),
    gdkFuncDependencies: [gdk, reloadFlag],
    onSuccess: ([query, forwarders, methods]: [FinanceDepositQueryList, ForwarderConfig[], FinanceDepositFeeMethodQuery[]]) => {
      setDepositQueryData(query)
      setForwarderList(forwarders.map(forwarder => ({ name: forwarder.name, value: forwarder.slug })))
      setMethodList(methods)
    }
  })

  const handleStatusSubmit = useChangeUrlSubmit<{ is_active: number }, RequestType>({
    formToRequest: (form) => {
      const converted = {
        is_active: form.is_active,
        method: request?.method,
        forwarder_slug: request?.forwarder_slug,
        layer_id: request?.layer_id,
        page: 1
      } as RequestType
      return omitBy(converted, isUndefined) as RequestType
    },
    encodePath: allRoute.financeDepositWay.encodePath,
    toAddNowTimestamp: true
  })

  const handleMethodSubmit = useChangeUrlSubmit<{ method: string }, RequestType>({
    formToRequest: (form) => {
      const converted = {
        is_active: request?.is_active,
        method: form.method === '--' ? undefined : form.method,
        forwarder_slug: request?.forwarder_slug,
        layer_id: request?.layer_id,
        page: 1
      } as RequestType
      return omitBy(converted, isUndefined) as RequestType
    },
    encodePath: allRoute.financeDepositWay.encodePath,
    toAddNowTimestamp: true
  })

  const handleForwarderSubmit = useChangeUrlSubmit<{ forwarder_slug: string }, RequestType>({
    formToRequest: (form) => {
      const converted = {
        is_active: request?.is_active,
        method: request?.method,
        forwarder_slug: form.forwarder_slug === '--' ? undefined : form.forwarder_slug,
        layer_id: request?.layer_id,
        page: 1
      } as RequestType
      return omitBy(converted, isUndefined) as RequestType
    },
    encodePath: allRoute.financeDepositWay.encodePath,
    toAddNowTimestamp: true
  })

  const handleLayerIdSubmit = useChangeUrlSubmit<{ layer_id: string }, RequestType>({
    formToRequest: (form) => {
      const converted = {
        is_active: request?.is_active,
        method: request?.method,
        forwarder_slug: request?.forwarder_slug,
        layer_id: form.layer_id === '--' ? undefined : form.layer_id,
        is_for_proxy_deposit: form.layer_id === IS_PROXY_FOR_DEPOSIT ? 1 : undefined,
        page: 1
      } as RequestType
      return omitBy(converted, isUndefined) as RequestType
    },
    encodePath: allRoute.financeDepositWay.encodePath,
    toAddNowTimestamp: true
  })

  const updateQueryListCount = (payload: { is_active: number | undefined, method: string | undefined, forwarder_slug: string | undefined, layer_id: string | undefined }) => {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { is_active, method, forwarder_slug, layer_id } = payload
    const converted = {
      is_active: is_active === 2 ? undefined : is_active,
      method: method === '--' ? undefined : method,
      forwarder_slug: forwarder_slug === '--' ? undefined : forwarder_slug,
      layer_id: (layer_id === '--' || layer_id === IS_PROXY_FOR_DEPOSIT) ? undefined : layer_id,
      is_for_proxy_deposit: layer_id === IS_PROXY_FOR_DEPOSIT ? 1 : undefined
    } as DepositWayQuery
    gdk.finance.getDepositQueryList(omitBy(converted, isUndefined)).subscribe(res => setDepositQueryData(res))
  }

  const writable = useChecker()

  return (
    <Grid container direction="row" justify="flex-end" spacing={2}>
      <Grid item xs={6} md={2}>
        <DropDown
          className={classes.outlineDropDown}
          fullWidth
          variant="outlined"
          label=""
          value={request?.is_active ?? 2}
          onChange={useCallback((event) => {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            const is_active = event.target.value as number
            handleStatusSubmit({ is_active })
            updateQueryListCount({
              is_active,
              method: request?.method,
              forwarder_slug: request?.forwarder_slug,
              layer_id: request?.layer_id
            })
          }, [handleStatusSubmit, updateQueryListCount])}
          options={statusOptions}
        />
      </Grid>
      <Grid item xs={6} md={3}>
        <DropDown
          className={classes.outlineDropDown}
          fullWidth
          variant="outlined"
          label=""
          value={request?.method ?? '--'}
          onChange={useCallback((event) => {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            const method = event.target.value as string
            handleMethodSubmit({ method })
            updateQueryListCount({
              is_active: request?.is_active,
              method,
              forwarder_slug: request?.forwarder_slug,
              layer_id: request?.layer_id
            })
          }, [handleMethodSubmit, updateQueryListCount])}
          options={methodOptions}
        />
      </Grid>
      <Grid item xs={6} md={2}>
        <DropDown
          className={classes.outlineDropDown}
          fullWidth
          variant="outlined"
          label=""
          value={request?.forwarder_slug ?? '--'}
          onChange={useCallback((event) => {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            const slug = event.target.value as string
            handleForwarderSubmit({ forwarder_slug: slug })
            updateQueryListCount({
              is_active: request?.is_active,
              method: request?.method,
              forwarder_slug: slug,
              layer_id: request?.layer_id
            })
          }, [handleForwarderSubmit, updateQueryListCount])}
          options={forwarderOptions}
        />
      </Grid>
      <Grid item xs={6} md={2}>
        <DropDown
          className={classes.outlineDropDown}
          fullWidth
          variant="outlined"
          label=""
          value={request?.layer_id === undefined ? '--' : request.layer_id === IS_PROXY_FOR_DEPOSIT ? IS_PROXY_FOR_DEPOSIT : request.layer_id}
          onChange={useCallback((event) => {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            const layerId = event.target.value as string
            handleLayerIdSubmit({ layer_id: layerId })
            updateQueryListCount({
              is_active: request?.is_active,
              method: request?.method,
              forwarder_slug: request?.forwarder_slug,
              layer_id: layerId
            })
          }, [handleLayerIdSubmit, updateQueryListCount])}
          options={layerOptions}
        />
      </Grid>
      {writable && (
        <>
          <Grid item>
            <Button
              onClick={() => setIsShowDepositSortDialog(true)}
              className={classes.purpleGradualButton}
              classes={{ disabled: classes.disabledButton }}
            >
              <Box paddingY={0.5}>
                {t('common.depositSort')}
              </Box>
            </Button>
            {isShowDepositSortDialog && <FinanceDepositSortDialog open={isShowDepositSortDialog} close={() => setIsShowDepositSortDialog(false)} reload={reload} />}
          </Grid>
          <Grid item>
            <Button
              component={Link}
              to={allRoute.financeDepositWayCreate.encodePath({ search: { ...request }, param: {} })}
              className={classes.purpleGradualButton}
              classes={{ disabled: classes.disabledButton }}
            >
              <Box paddingY={0.5}>
                {t('common.createDeposit')}
              </Box>
            </Button>
          </Grid>
        </>
      )}
    </Grid>
  )
}

export default React.memo(FinanceDepositWayToolbar)

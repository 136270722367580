import React from 'react'
import { useTheme } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import ErrorIcon from '@material-ui/icons/Error'

interface PropTypes {
  error: string
}

const ErrorText: React.FC<PropTypes> = (props) => {
  const { error } = props
  const theme = useTheme()
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      paddingY={2}
      fontSize={theme.typography.h4.fontSize}
      color={theme.palette.error.main}
    >
      <ErrorIcon />
      <Box paddingTop={1}>
        {error}
      </Box>
    </Box>
  )
}

export default React.memo(ErrorText)

import { WithdrawPointTarget } from '@golden/gdk-admin'
import TKeyType from '../../i18ns/admin/TKeyType'

const withdrawPointTargetName: { [key in WithdrawPointTarget]: TKeyType } = {
  [WithdrawPointTarget.GENERAL]: 'common.general',
  [WithdrawPointTarget.ACTIVITY]: 'common.activity',
  [WithdrawPointTarget.GAME]: 'common.gamePlatform'
}

export default withdrawPointTargetName

import React, { useMemo } from 'react'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import { PaginationRes, Greeting } from '@golden/gdk-admin'
import useT from '../../../i18ns/admin/useT'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import CoreTable from '../../default/present/CoreTable'
import { createTableData } from '../../../utils/default/TableHelper'
import StatusIcon, { StatusInfo } from '../StatusIcon'
import GreetingTableButtons from '../greeting/GreetingTableButtons'
import { ActionType } from '../greeting/GreetingForm'
import DateTime from '../../default/present/DateTime'

interface RowType {
  id: number
  createdAt: React.ReactElement
  content: React.ReactElement
  isActive: React.ReactElement
  functions: React.ReactElement
}

interface PropTypes {
  page: number
  greetingList: PaginationRes<Array<Greeting<Date>>>
  onChangePage: (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => void
  onClick: (id: number, action: ActionType) => void
  reload: () => void
}

const useStyles = makeStyles((theme) => ({
  title: {
    color: theme.palette.common.black,
    fontWeight: 500
  }
}))

const defineIconValue: StatusInfo[] = [
  {
    type: 'STOP',
    value: 0
  },
  {
    type: 'START',
    value: 1
  }
]

const GreetingTable: React.FC<PropTypes> = (props) => {
  const { onChangePage, onClick, reload, greetingList, page } = props
  const classes = useCommonStyles()
  const customClasses = useStyles()
  const { t } = useT()

  const rows: RowType[] = useMemo(() => {
    return greetingList.data.map((greeting, index) => ({
      id: index,
      createdAt: (<DateTime time={greeting.created_at} />),
      content: (
        <React.Fragment>
          <Typography className={customClasses.title}>{greeting.title}</Typography>
          <Typography>{greeting.content}</Typography>
        </React.Fragment>
      ),
      isActive: (<StatusIcon define={defineIconValue} status={greeting.is_active ? 1 : 0} />),
      functions: (
        <GreetingTableButtons
          onClick={onClick}
          greeting={{
            id: greeting.id,
            title: greeting.title,
            content: greeting.content,
            isActive: greeting.is_active
          }}
          reload={reload}
        />)
    }))
  }, [greetingList.data, customClasses.title, onClick, reload])

  const data = useMemo(() => {
    return createTableData<RowType>(
      {
        id: {
          label: '',
          value: 'id'
        },
        createdAt: {
          label: t('common.createdAt'),
          value: 'createdAt',
          align: 'center'
        },
        content: {
          label: t('common.greetingContent'),
          value: 'content',
          align: 'left'
        },
        isActive: {
          label: t('common.status'),
          value: 'isActive',
          align: 'center',
          width: 90
        },
        functions: {
          label: '',
          value: 'functions',
          align: 'center',
          width: 240
        }
      },
      [
        'createdAt',
        'content',
        'isActive',
        'functions'
      ],
      rows,
      'id'
    )
  }, [rows, t])

  return (
    <CoreTable
      classes={{
        head: classes.pinkTableHead,
        cellHead: classes.tableCellHead,
        row: classes.tableRow,
        cellBody: classes.nowrap
      }}
      data={data}
      total={greetingList.total}
      page={page}
      onChangePage={onChangePage}
      showPagination
    />
  )
}

export default React.memo(GreetingTable)

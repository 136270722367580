import React from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import MuiButton, { ButtonProps } from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'

const useStyles = makeStyles((theme) => ({
  button: {
    boxShadow: theme.shadows[0]
  }
}))

const Button: React.FC<ButtonProps> = (props) => {
  const classes = useStyles()
  const { children, className, ...rest } = props
  return (
    <MuiButton {...rest} className={clsx(className, classes.button)}>
      <Box paddingX={2.5}>
        {children}
      </Box>
    </MuiButton>
  )
}

export default Button

import { ActivityWalletStatus } from '@golden/gdk-admin'

const activityWalletStatusColor: { [key in ActivityWalletStatus]: string } = {
  [ActivityWalletStatus.CAN_RECEIVE]: '#ff5f5f',
  [ActivityWalletStatus.ERROR]: '#ff5f5f',
  [ActivityWalletStatus.EXPIRED]: '#808080',
  [ActivityWalletStatus.RECEIVED]: '#72d476',
  [ActivityWalletStatus.TRANSFERRING]: '#808080'
}

export default activityWalletStatusColor

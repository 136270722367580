import React, { useContext, useCallback, useMemo, Dispatch, SetStateAction } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Link } from 'react-router-dom'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import MuiTextField from '@material-ui/core/TextField'
import DropDown from '../../default/form/DropDown'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import useT from '../../../i18ns/admin/useT'
import { useChecker } from '../../../utils/admin/AdminRouteHook'
import { useInternValue } from '../../../utils/default/FormHook'

const useStyles = makeStyles(() => ({
  textField: {
    paddingTop: 14,
    paddingBottom: 14
  }
}))

const TextField = React.memo(MuiTextField)

export interface FinancePiggyToolbarFormType {
  status: 0 | 1 | '--'
  account: string
  page: number
}

interface PropTypes {
  context: React.Context<[FinancePiggyToolbarFormType, Dispatch<SetStateAction<FinancePiggyToolbarFormType>>]>
  namePlaceholder: string
  createLink: string
  createTitle: string
}

const FinancePiggyToolbar: React.FC<PropTypes> = (props) => {
  const { context, namePlaceholder, createLink, createTitle } = props
  const [form, setForm] = useContext(context)
  const [text, setText] = useInternValue<string>('', form.account)
  const classes = useStyles()
  const commonClasses = useCommonStyles()
  const { t } = useT()
  const options = useMemo(() => {
    return [
      { name: t('common.allStatus'), value: '--' },
      { name: t('common.toggleOn'), value: 1 },
      { name: t('common.toggleOff'), value: 0 }
    ]
  }, [t])
  const writable = useChecker()
  return (
    <Grid container direction="row" justify="flex-end" spacing={2}>
      <Grid item>
        <TextField
          variant="outlined"
          InputProps={{ classes: { input: classes.textField } }}
          placeholder={namePlaceholder}
          value={text}
          onChange={useCallback((event) => {
            const value = event.target.value
            setText(value)
          // eslint-disable-next-line react-hooks/exhaustive-deps
          }, [])}
        />
      </Grid>
      <Grid item>
        <Button
          className={commonClasses.purpleGradualButton}
          onClick={useCallback(() => {
            setForm((form) => ({ ...form, account: text, page: 1 }))
          // eslint-disable-next-line react-hooks/exhaustive-deps
          }, [text])}
        >
          <Box paddingY={0.5}>
            {t('common.search')}
          </Box>
        </Button>
      </Grid>
      <Grid item xs={6} md={2}>
        <DropDown
          className={commonClasses.outlineDropDown}
          fullWidth
          variant="outlined"
          label=""
          value={form.status}
          onChange={useCallback((event) => {
            const status = event.target.value as (0 | 1)
            setForm((form) => ({ ...form, status, page: 1 }))
          // eslint-disable-next-line react-hooks/exhaustive-deps
          }, [])}
          options={options}
        />
      </Grid>
      {writable && (
        <Grid item>
          <Button
            component={Link}
            to={createLink}
            className={commonClasses.purpleGradualButton}
            classes={{ disabled: commonClasses.disabledButton }}
          >
            <Box paddingY={0.5}>
              {createTitle}
            </Box>
          </Button>
        </Grid>
      )}
    </Grid>
  )
}

export default React.memo(FinancePiggyToolbar)

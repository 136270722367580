import { InternalWithdrawStatusType } from '@golden/gdk-admin'
import TKeyType from '../../i18ns/admin/TKeyType'

const internalWithdrawStatusName: { [key in InternalWithdrawStatusType]: TKeyType } = {
  [InternalWithdrawStatusType.SUCCESSFUL]: 'common.success',
  [InternalWithdrawStatusType.IN_PROGRESS]: 'common.processing',
  [InternalWithdrawStatusType.FAIL]: 'common.failure',
  [InternalWithdrawStatusType.REQUEST_FAIL]: 'common.thirdPartyFail'
}

export default internalWithdrawStatusName

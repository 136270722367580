import React, { PropsWithChildren, useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import AppFrameBase from '../../default/frames/AppFrameBase'
import { findAppRoute } from '../../../utils/default/RouteHelper'
import allRoute, { routeOrder } from '../route/route'
import useGDKStore from '../../../providers/admin/gdk/useGDKStore'
import useT from '../../../i18ns/admin/useT'

interface PropTypes {
  drawerContentComponent?: any
  appBarContentComponent?: any
}

const AppFrame: React.FC<PropsWithChildren<PropTypes>> = (props) => {
  const location = useLocation()
  const { t } = useT()
  const {
    drawerContentComponent,
    appBarContentComponent
  } = props
  const appRoute = useMemo(
    () => findAppRoute(location.pathname, routeOrder, allRoute) ?? allRoute.notFound,
    [location.pathname]
  )
  const isWrong = useGDKStore.auth.isWrong()
  if (appRoute.noFrame || isWrong) {
    return (
      <React.Fragment>
        {props.children}
      </React.Fragment>
    )
  }
  return (
    <AppFrameBase
      text={t('goldenPlatform.admin')}
      drawerContentComponent={drawerContentComponent}
      appBarContentComponent={appBarContentComponent}
    >
      {props.children}
    </AppFrameBase>
  )
}

export default React.memo(AppFrame)

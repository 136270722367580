import React, { useState, useCallback, useMemo } from 'react'
import { useTheme, createTheme, MuiThemeProvider } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import EditRoleForm, { initialForm, RoleFormType } from '../../../components/admin/manager/EditRoleForm'
import { Path } from '../../../components/admin/route/route'
import useGlobalDialog from '../../../providers/admin/dialog/useGlobalDialog'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { useDialogHandleSubmit, useRedirectHandleBack } from '../../../utils/default/ComplexFlowHook'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import { createGlobalDialogConfig } from '../../../utils/default/DialogHelper'
import useT from '../../../i18ns/admin/useT'
import { FormError } from '../../../utils/default/FormHelper'
import CustomThemeType from '../../../themes/admin/CustomThemeType'
import { Role } from '@golden/gdk-admin'
import useGDKStore from '../../../providers/admin/gdk/useGDKStore'

const RoleManagementCreatePage: React.FC = () => {
  const classes = useCommonStyles()
  const { t } = useT()
  const gdk = useGDK()
  const globalDialog = useGlobalDialog()
  const me = useGDKStore.admin.me()
  const defaultForm = useMemo(() => ({ ...initialForm(), parentId: me?.role_id ?? 0 }), [me?.role_id])
  const [formError] = useState<Partial<FormError<RoleFormType>>>({})
  const [handleBack] = useRedirectHandleBack({ path: Path.ROLE_MANAGEMENT })
  const { handleSubmit } = useDialogHandleSubmit({
    dialogId: 'createRole',
    globalDialog,
    getChangeDialogConfig: useCallback((form: RoleFormType) => createGlobalDialogConfig({
      showIcon: false,
      message: (
        <span className={classes.pre} style={{ textAlign: 'center' }}>
          {`${t('dialog.confirmCreate')}\n`}
          <span className={classes.purpleWord}>[{form.name}]</span>
        </span>
      )
    }), [classes.pre, classes.purpleWord, t]),
    getSuccessDialogConfig: useCallback(() => createGlobalDialogConfig({
      showIcon: false,
      showCancel: false,
      message: t('dialog.createSuccess')
    }), [t]),
    getFailDialogConfig: (error: string) => createGlobalDialogConfig({
      showIcon: true,
      variant: 'error',
      message: error
    }),
    formToRequest: (form: RoleFormType): Role => ({ ...form, parent_id: form.parentId }),
    gdkFunc: (payload: Role) => gdk.permissionRole.createRole(payload),
    gdkFuncDependencies: [gdk],
    afterSuccessDialog: handleBack
  })
  return (
    <Box paddingY={5}>
      <EditRoleForm
        titleText={t('page.createRole')}
        okText={t('common.save')}
        defaultValue={defaultForm}
        onSubmit={handleSubmit}
        formError={formError}
      />
    </Box>
  )
}

const RoleManagementCreatePageContainer: React.FC = () => {
  const theme: CustomThemeType = useTheme()
  const permissionTheme = createTheme({
    ...theme,
    breakpoints: {
      values: {
        xl: 1920,
        lg: 1660,
        md: 1440,
        sm: 1100,
        xs: 0
      }
    }
  })
  return (
    <MuiThemeProvider theme={permissionTheme}>
      <RoleManagementCreatePage />
    </MuiThemeProvider>
  )
}

export default React.memo(RoleManagementCreatePageContainer)

import React, { useEffect } from 'react'
import Box from '@material-ui/core/Box'
import DepositThirdPartySlipForm from '../../../components/admin/deposit/DepositThirdPartySlipForm'
import DepositThirdPartySlipTable from '../../../components/admin/deposit/DepositThirdPartySlipTable'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { Branch } from '@golden/gdk-admin'

const DepositThirdPartySlipPage: React.FC = () => {
  const gdk = useGDK()
  useEffect(() => {
    gdk.trunk.trigger([Branch.PLAYER_LAYER])
  }, [gdk])
  return (
    <Box paddingY={5}>
      <Box paddingX={5}>
        <DepositThirdPartySlipForm />
      </Box>
      <Box paddingTop={3}>
        <DepositThirdPartySlipTable />
      </Box>
    </Box>
  )
}

export default React.memo(DepositThirdPartySlipPage)

import React from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'
import MuiRadioGroup from '@material-ui/core/RadioGroup'
import { useInternValue } from '../../../utils/default/FormHook'

const useStyles = makeStyles(() => ({
  label: {
    fontWeight: 600
  }
}))

export interface PropTypes {
  label: string
  value?: string | undefined
  defaultValue?: string | undefined
  onChange?: (event: React.ChangeEvent<{
    name?: string | undefined
    value: unknown
  }>) => void
  options: Array<{
    name: string
    value: string | undefined
    disabled?: boolean
  }>
  disabled?: boolean
  fullWidth?: boolean
  placeholder?: string
  onBlur?: () => void
  onFocus?: () => void
  helperText?: string
  error?: boolean
  autoFocus?: boolean
  required?: boolean
  classes?: Partial<Record<'label', string>>
}

const RadioGroup: React.FC<PropTypes> = (props) => {
  const classes = useStyles()
  const { label, options, value, defaultValue, onChange, disabled, required, classes: classesProps } = props
  const [internValue, setInternValue] = useInternValue<string | undefined>(
    defaultValue ?? value,
    value
  )
  return (
    <FormControl component="fieldset" disabled={disabled}>
      <Typography className={clsx(classes.label, classesProps?.label)}>{label}{required && '*'}</Typography>
      <MuiRadioGroup
        row
        value={internValue}
        onChange={(event) => {
          setInternValue(event.target.value)
          if (onChange) onChange(event)
        }}
      >
        {options.map((option) => (
          <FormControlLabel key={option.value} disabled={option.disabled} value={option.value} control={<Radio />} label={option.name} />
        ))}
      </MuiRadioGroup>
    </FormControl>
  )
}

export default React.memo(RadioGroup)

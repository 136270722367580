import React, { Dispatch, SetStateAction, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { GameCategoryType, GameType, SpecifyLeague } from '@golden/gdk-admin'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import useT from '../../../i18ns/admin/useT'
import { useCommonStyles, useDetailStyles } from '../../../utils/admin/StyleHook'
import { createTableData } from '../../../utils/default/TableHelper'
import { Grid, IconButton, TextField, makeStyles } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import AddIcon from '@material-ui/icons/Add'
import { FormState } from '../../../utils/default/FormHook'
import { isEqual } from '@golden/utils'
import CoreTable from '../../default/present/CoreTable'
import GameIdInput from '../GameIdInput'
import CustomThemeType from '../../../themes/admin/CustomThemeType'

const useStyles = makeStyles((theme: CustomThemeType) => ({
  button: {
    color: theme.custom.palette.blue.main
  }
}))

interface CommonFormType { specify_league: SpecifyLeague[] }

interface ChildProps {
  specifyLeagues?: SpecifyLeague[]
  setSpecifyLeagues: Dispatch<SetStateAction<SpecifyLeague[]>>
  error: ErrorType[]
  setError: Dispatch<SetStateAction<ErrorType[]>>
}

interface PropTypes<FormType extends CommonFormType> {
  context: React.Context<FormState<FormType>>
  open: boolean
  onClose: () => void
}

interface ErrorType {
  game_id: string
  tournament_id: string
  match_id: string
}

interface LeagueRowType {
  index: number
  id: number | null
  delete: React.ReactElement
  gameId: React.ReactElement
  tournamentId: React.ReactElement
  matchId: React.ReactElement
}

const LeagueDeleteButton: React.FC<{
  index: number
  error: ErrorType[]
  setError: (value: ErrorType[]) => void
} & ChildProps> = React.memo((props) => {
  const { index, error, setError, setSpecifyLeagues } = props
  return (
    <IconButton
      size="small"
      onClick={() => {
        setSpecifyLeagues((value) => (value.filter((_, i) => i !== index)))
        setError(error.filter((_, i) => i !== index))
      }}
    >
      <CloseIcon fontSize="small" />
    </IconButton>
  )
})

const LeagueAddButton: React.FC<{
  error: ErrorType[]
  setError: (value: ErrorType[]) => void
} & ChildProps> = React.memo((props) => {
  const { t } = useT()
  const { setError, setSpecifyLeagues } = props
  return (
    <IconButton
      onClick={() => {
        const defaultGame = { game_id: null, tournament_id: '', match_id: '' }
        const defaultError = { game_id: t('error.mustNotEmpty'), tournament_id: t('error.mustNotEmpty'), match_id: '' }
        setSpecifyLeagues((value) => ([...value, defaultGame]))
        setError((value) => ([...value, defaultError]))
      }}
    >
      <AddIcon fontSize="small" />
    </IconButton>
  )
})

const LeagueDropdownField: React.FC<{
  value?: GameType | null
  name: keyof SpecifyLeague
  index: number
  error: ErrorType[]
  setError: (value: ErrorType[]) => void
} & ChildProps> = React.memo((props) => {
  const { value, name, index, error, setError, setSpecifyLeagues } = props
  const { t } = useT()
  return (
    <GameIdInput
      value={value ?? 'noGame'}
      fullWidth
      noAll
      hasTournamentId
      hideLabel
      category={GameCategoryType.SPORT_LOTTERY}
      onChange={(event) => {
        const newValue = event
        setSpecifyLeagues((value) => (value.map((game, i) => index === i ? ({ ...game, [name]: newValue }) : game)))
        setError(error.map((e, i) => i === index
          ? { ...e, [name]: !newValue ? t('error.mustNotEmpty') : '' }
          : e
        ))
      }}
      error={!!error[index][name as keyof ErrorType]}
      helperText={error[index][name as keyof ErrorType]}
    />
  )
})

const LeagueTextField: React.FC<{
  value?: string
  name: keyof SpecifyLeague
  index: number
  placeholder: string
  error: ErrorType[]
  setError: (value: ErrorType[]) => void
  require?: boolean
} & ChildProps> = React.memo((props) => {
  const { value, name, index, placeholder, error, require, setError, setSpecifyLeagues } = props
  const { t } = useT()
  return (
    <TextField
      value={value}
      fullWidth
      placeholder={placeholder}
      onChange={(event) => {
        const newValue = event.target.value
        setSpecifyLeagues((value) => (value.map((game, i) => index === i ? ({ ...game, [name]: newValue.replace(/\s/g, '') }) : game)))
        setError(error.map((e, i) => i === index
          ? { ...e, [name]: (!(newValue.replace(/\s/g, '')) && require) ? t('error.mustNotEmpty') : '' }
          : e
        ))
      }}
      error={!!error[index][name as keyof ErrorType]}
      helperText={error[index][name as keyof ErrorType]}
      inputProps={{ maxLength: 50 }}
    />
  )
})

const LeagueTable: React.FC<ChildProps> = React.memo((props) => {
  const { specifyLeagues, setSpecifyLeagues, error, setError } = props
  const { t } = useT()
  const commonClasses = useCommonStyles()
  const tableClasses = useMemo(() => ({
    head: commonClasses.greyTableHead,
    row: commonClasses.tableRow,
    cellHead: commonClasses.tableCellHead,
    cellBody: commonClasses.pre
  }), [commonClasses])

  const rows: LeagueRowType[] = useMemo(() => {
    if (!specifyLeagues) return []
    return specifyLeagues.map((item, index) => {
      return {
        id: index,
        delete: (
          <LeagueDeleteButton
            index={index}
            error={error}
            setError={setError}
            setSpecifyLeagues={setSpecifyLeagues}
          />
        ),
        gameId: (
          <LeagueDropdownField
            name="game_id"
            index={index}
            value={item.game_id}
            error={error}
            setError={setError}
            setSpecifyLeagues={setSpecifyLeagues}
          />
        ),
        tournamentId: (
          <LeagueTextField
            name="tournament_id"
            index={index}
            value={item.tournament_id}
            placeholder={t('placeholder.pleaseInput', { item: t('common.gameLeague') })}
            error={error}
            setError={setError}
            setSpecifyLeagues={setSpecifyLeagues}
            require
          />
        ),
        matchId: (
          <LeagueTextField
            name="match_id"
            index={index}
            value={item.match_id}
            placeholder={t('placeholder.pleaseInput', { item: t('common.designatedGame') })}
            error={error}
            setError={setError}
            setSpecifyLeagues={setSpecifyLeagues}
          />
        )
      } as LeagueRowType
    })
  }, [error, specifyLeagues, t, setError, setSpecifyLeagues])
  const data = useMemo(() => {
    const order: Array<keyof LeagueRowType> = [
      'gameId',
      'tournamentId',
      'matchId'
    ]
    return createTableData<LeagueRowType>(
      {
        index: {
          label: '',
          value: 'index'
        },
        id: {
          label: '',
          value: 'id'
        },
        delete: {
          label: '',
          value: 'delete',
          align: 'center',
          width: 10
        },
        gameId: {
          label: `${t('common.gamePlatform')}*`,
          value: 'gameId',
          align: 'center',
          width: 100
        },
        tournamentId: {
          label: `${t('common.gameLeague')}*`,
          value: 'tournamentId',
          align: 'center',
          width: 100
        },
        matchId: {
          label: `${t('common.designatedGame')}`,
          value: 'matchId',
          align: 'center',
          width: 100
        }
      },
      specifyLeagues && specifyLeagues.length > 1 ? ['delete', ...order] : order,
      rows,
      'index'
    )
  }, [t, specifyLeagues, rows])

  return (<>
    <CoreTable
      classes={tableClasses}
      data={data}
      total={rows.length}
      showNoData={false}
    />
    <LeagueAddButton
      error={error}
      setError={setError}
      setSpecifyLeagues={setSpecifyLeagues}
    />
  </>)
})

const SportLeagueDialog = function<FormType extends CommonFormType> (props: PropTypes<FormType>) {
  const { open, onClose, context } = props
  const classes = useStyles()
  const detailClasses = useDetailStyles()
  const commonClasses = useCommonStyles()
  const { t } = useT()
  const { value: form, dispatch } = useContext(context)
  const [specifyLeagues, setSpecifyLeagues] = useState<SpecifyLeague[]>([])

  const [error, setError] = useState<ErrorType[]>([])
  const [canSave, setCanSave] = useState<boolean>(false)
  const defaultGame = { game_id: null, tournament_id: '', match_id: '' }
  const defaultError = { game_id: t('error.mustNotEmpty'), tournament_id: t('error.mustNotEmpty'), match_id: '' }
  const emptyError = useMemo(() => ({ game_id: '', tournament_id: '', match_id: '' }), [])

  useEffect(() => {
    if (open) {
      if (form.specify_league.length) {
        setSpecifyLeagues(form.specify_league)
        setError(form.specify_league.map((_) => (emptyError)))
      } else {
        setSpecifyLeagues([defaultGame])
        setError([defaultError])
      }
    }
  }, [open, form.specify_league, emptyError, t, setSpecifyLeagues, setError])

  useEffect(() => {
    setCanSave(error.some((err) => (!isEqual(err, emptyError))))
  }, [error, emptyError])

  const handleConfirm = useCallback(() => {
    dispatch({
      type: 'change',
      label: 'specify_league',
      value: [...specifyLeagues]
    })
    onClose()
  }, [specifyLeagues, dispatch, onClose])

  const handleCancel = useCallback(() => {
    onClose()
  }, [onClose])

  const handleReset = useCallback(() => {
    dispatch({
      type: 'change',
      label: 'specify_league',
      value: []
    })
    setSpecifyLeagues([defaultGame])
    setError([defaultError])
  }, [dispatch, onClose])

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle className={detailClasses.title} disableTypography>{t('dialog.specifyLeagueAndGame')}</DialogTitle>
      <DialogContent className={detailClasses.content}>
        <Box display="flex" justifyContent="flex-end" width="100%" paddingBottom={2}>
          <Grid item>
            <Button
              className={classes.button}
              onClick={handleReset}
            >
              {t('common.reset')}
            </Button>
          </Grid>
        </Box>
        <LeagueTable
          specifyLeagues={specifyLeagues}
          setSpecifyLeagues={setSpecifyLeagues}
          error={error}
          setError={setError}
        />
      </DialogContent>
      <DialogActions>
        <Box display="flex" justifyContent="center" width="100%" paddingY={2}>
          <Grid container direction="row" justify="center" spacing={2}>
            <Grid item>
              <Button onClick={handleCancel} className={commonClasses.greyButton}>{t('common.cancel')}</Button>
            </Grid>
            <Grid item>
              <Button
                onClick={handleConfirm}
                className={commonClasses.purpleGradualButton}
                disabled={canSave}
              >
                {t('common.confirm')}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </DialogActions>
    </Dialog>
  )
}

export default SportLeagueDialog

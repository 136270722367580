import React, { useCallback } from 'react'
import { SvgIconProps } from '@material-ui/core/SvgIcon'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import GroupKey from './GroupKey'
import allLink, { LinkKey } from './link'
import useT from '../../../i18ns/admin/useT'
import { defaultLanguage } from '../../../enums/default/LanguageType'
import { useDebouncedCallback } from 'use-debounce'

interface PropTypes {
  classes: Record<'text' | 'itemButton', string>
  groupKey: GroupKey
  icon: React.ComponentType<SvgIconProps>
  title: string
  subItem: LinkKey
}

const emptyCallback = () => {}

const LinkGroup: React.FC<PropTypes> = (props) => {
  const { icon: Icon, title, subItem, classes } = props
  const { i18n } = useT()

  const lng = i18n.language || defaultLanguage

  const [handleDebouncedClick] = useDebouncedCallback(useCallback(() => {
    window.open(`${allLink.battleField.href}?lng=${lng}`)
  }, [lng]), 200)

  const handleClick = useCallback((event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault()
    handleDebouncedClick()
  }, [handleDebouncedClick])

  const href = allLink[subItem].href

  return (
    <React.Fragment>
      <ListItem
        button
        classes={{ button: classes.itemButton }}
        component="a"
        href={subItem === LinkKey.BATTLE_FIELD ? '#' : href}
        onClick={subItem === LinkKey.BATTLE_FIELD ? handleClick : emptyCallback}
        target={allLink[subItem].blank ? '_blank' : '_self'}
      >
        <ListItemIcon>
          <Icon />
        </ListItemIcon>
        <ListItemText primary={title} className={classes.text} />
      </ListItem>
    </React.Fragment>
  )
}

export default React.memo(LinkGroup)

import React, { useMemo } from 'react'
import Box from '@material-ui/core/Box'
import AdminReportPlayerLeaderboardDetailForm from '../../../components/admin/adminReport/AdminReportPlayerLeaderboardDetailForm'
import AdminReportPlayerLeaderboardDetailTable from '../../../components/admin/adminReport/AdminReportPlayerLeaderboardDetailTable'
import { useLocation } from 'react-router'
import { parsePath } from '../../../utils/default/RouteHelper'
import { Path } from '../../../components/admin/route/route'
import { GameCategoryType } from '@golden/gdk-campaign'

const AdminReportDepositStatisticPage: React.FC = () => {
  const location = useLocation()
  const category = useMemo(() => {
    const param = parsePath(location.search, location.pathname, Path.ADMIN_REPORT_PLAYER_LEADERBOARD_DETAIL).param as { id: string }
    return Number(param.id) as 0 | GameCategoryType
  }, [location])
  return (
    <Box paddingY={5}>
      <Box paddingX={5}>
        <AdminReportPlayerLeaderboardDetailForm category={category} />
      </Box>
      <Box paddingTop={3}>
        <AdminReportPlayerLeaderboardDetailTable category={category} />
      </Box>
    </Box>
  )
}

export default React.memo(AdminReportDepositStatisticPage)

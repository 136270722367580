import React, { useMemo } from 'react'
import { NewAdminReq } from '@golden/gdk-admin'
import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import EditManagerAccountForm, { EditManagerAccountFormType, initialForm } from '../../../components/admin/manager/EditManagerAccountForm'
import { getRandomPassword } from '../../../utils/default/FormHelper'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import useT from '../../../i18ns/admin/useT'
import useGDK from '../../../providers/admin/gdk/useGDK'
import useGlobalDialog from '../../../providers/admin/dialog/useGlobalDialog'
import { useRedirectHandleBack, useGDKFuncHandleSubmit } from '../../../utils/default/ComplexFlowHook'
import allRoute, { Path } from '../../../components/admin/route/route'
import { createGlobalDialogConfig } from '../../../utils/default/DialogHelper'
import CopyButton from '../../../components/admin/CopyButton'
import { useDialogFlow } from '../../../utils/default/DialogHook'
import { parsePath } from '../../../utils/default/RouteHelper'
import { useLocation } from 'react-router'

export const formToRequest = (form: EditManagerAccountFormType): NewAdminReq => ({
  account: form.account,
  nickname: form.nickname,
  role_id: form.roleId === '' ? 0 : Number(form.roleId),
  password: form.password,
  is_loginable: form.isLoginable,
  memo: form.memo
})

const ManagerAccountCreatePage: React.FC = () => {
  const classes = useCommonStyles()
  const { t } = useT()
  const gdk = useGDK()
  const globalDialog = useGlobalDialog()
  const location = useLocation()
  const search = parsePath(location.search, location.pathname, Path.MANAGER_ACCOUNT).search as { page: number, sort_by: string, order: string }
  const [handleBack, handleDebouncedBack] = useRedirectHandleBack({ path: allRoute.managerAccount.encodePath({ search: { page: search.page, order: search.order, sort_by: search.sort_by }, param: {} }) })
  const { handleSubmit } = useGDKFuncHandleSubmit({
    formToRequest,
    gdkFunc: (payload) => gdk.admin.createAccount(payload),
    gdkFuncDependencies: [gdk],
    onSuccess: (_, form) => {
      const content = `${t('common.account')}: ${form.account}\n${t('common.password')}: ${form.password}`
      globalDialog.setConfig(createGlobalDialogConfig({
        showIcon: false,
        showCancel: false,
        okText: t('common.close'),
        message: (
          <Box display="flex" flexDirection="column" alignItems="center">
            <span className={classes.bold}>{t('dialog.createSuccess')}</span>
            <Box display="flex" justifyContent="space-between" alignItems="center" marginTop={2} paddingX={4} bgcolor="#eee">
              <Typography className={classes.pre}>{content}</Typography>
              <CopyButton content={content} />
            </Box>
          </Box>
        )
      }))
      globalDialog.setOpen({
        id: 'accountCreateSuccess',
        value: true,
        isOK: true
      })
    },
    onSuccessDependencies: [t, classes.pre, classes.bold],
    onError: (error) => {
      globalDialog.setConfig(createGlobalDialogConfig({
        showIcon: true,
        variant: 'error',
        showCancel: false,
        message: error.message
      }))
      globalDialog.setOpen({
        id: 'accountCreateFailed',
        value: true,
        isOK: false
      })
    }
  })
  useDialogFlow(globalDialog, 'accountCreateSuccess', () => {
    handleBack()
    globalDialog.clearState()
  })
  const defaultForm = useMemo(() => initialForm({
    password: getRandomPassword()
  }), [])

  return (
    <Box padding={5}>
      <Paper>
        <Box padding={4}>
          <Box
            paddingY={1.25}
            paddingX={2}
            className={classes.pinkTitleBar}
          >
            <Typography variant="h5">
              {t('common.createAccount')}
            </Typography>
          </Box>
          <Box paddingY={2}>
            <EditManagerAccountForm
              requirePassword
              readonlyPassword
              showPassword
              okText={t('common.confirmCreate')}
              onBack={handleDebouncedBack}
              onSubmit={handleSubmit}
              defaultValue={defaultForm}
            />
          </Box>
        </Box>
      </Paper>
    </Box>
  )
}

export default React.memo(ManagerAccountCreatePage)

import { BaseCSSProperties } from '@material-ui/core/styles/withStyles'
import GoldmanSansWoff2 from '../../assets/font/GoldmanSans_W_Rg.woff2'

export const goldmanSans = [{
  fontFamily: 'Goldman Sans',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 'regular',
  src: `
    local('Goldman Sans'),
    local('Goldman Sans Regular'),
    url(${GoldmanSansWoff2}) format('woff2')
  `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF'
}] as BaseCSSProperties['@font-face']

import React, { useEffect, useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import Box from '@material-ui/core/Box'
import ActivityRecordAgentLayerForm from '../../../components/admin/activity/ActivityRecordAgentLayerForm'
import ActivityRecordAgentLayerTable from '../../../components/admin/activity/ActivityRecordAgentLayerTable'
import ActivityRecordGoldenLayerTable from '../../../components/admin/activity/ActivityRecordAgentGoldenTable'
import { parsePath } from '../../../utils/default/RouteHelper'
import { Path } from '../../../components/admin/route/route'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { Branch } from '@golden/gdk-admin'

const ActivityRecordSubPage: React.FC = () => {
  const gdk = useGDK()
  const location = useLocation()
  const id = useMemo(() => {
    return parsePath(location.search, location.pathname, Path.ACTIVITY_RECORD_AGENT_SUB).param.id
  }, [location.pathname, location.search])
  useEffect(() => {
    gdk.trunk.trigger([Branch.ACTIVITY])
  }, [gdk])
  return (
    <Box paddingY={5}>
      <Box paddingX={5}>
        <ActivityRecordAgentLayerForm />
      </Box>
      <Box paddingTop={3}>
        {id !== 'RMB' && (
          <ActivityRecordAgentLayerTable />
        )}
        {id === 'RMB' && (
          <ActivityRecordGoldenLayerTable />
        )}
      </Box>
    </Box>
  )
}

export default React.memo(ActivityRecordSubPage)

import React, { useState, useCallback } from 'react'
import { Link } from 'react-router-dom'
import { SvgIconProps } from '@material-ui/core/SvgIcon'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { TopNavKey } from './topNav'
import allRoute from '../route/route'
import RouteKey from '../route/RouteKey'
import useT from '../../../i18ns/admin/useT'
import useGDKStore from '../../../providers/admin/gdk/useGDKStore'

interface PropTypes {
  classes: Record<'icon', string>
  topNavKey: TopNavKey
  icon: React.ComponentType<SvgIconProps>
  title: string
  subItem: RouteKey[]
}

const GroupTopNav: React.FC<PropTypes> = (props) => {
  const { classes, icon: Icon, title, subItem } = props
  const { t } = useT()
  const me = useGDKStore.admin.me()
  const isShow = subItem.some((item) => {
    const permissions = allRoute[item].permissions
    return permissions
      .filter((permission) => permission.type === 'accessible')
      .some((permission) => me?.permissions.includes(permission.value))
  })
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const handleClick = useCallback((event) => setAnchorEl(event.currentTarget), [])
  const handleClose = useCallback(() => setAnchorEl(null), [])
  if (!isShow) return null
  return (
    <React.Fragment>
      <Tooltip title={title}>
        <IconButton onClick={handleClick}>
          <Icon className={classes.icon} />
        </IconButton>
      </Tooltip>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        {subItem.map((item) => {
          const permissions = allRoute[item].permissions
          const isShow = permissions
            .filter((permission) => permission.type === 'accessible')
            .some((permission) => me?.permissions.includes(permission.value))
          if (!isShow) return null
          return (<MenuItem key={item} component={Link} onClick={handleClose} to={allRoute[item].path}>{t(allRoute[item].title)}</MenuItem>)
        })}
      </Menu>
    </React.Fragment>

  )
}

export default React.memo(GroupTopNav, (prev, next) => {
  return prev.topNavKey === next.topNavKey
})

import React, { useState, useMemo } from 'react'
import CheckIcon from '@material-ui/icons/Check'
import { PaginationRes, PaginationReq, ActivityManagementData, ActivityManagementListQuery, ActivityModuleType } from '@golden/gdk-admin'
import CoreTable from '../../default/present/CoreTable'
import LoadingAndErrorFrame from '../../default/frames/LoadingAndErrorFrame'
import ActivityManagementTableStatus from './ActivityManagementTableStatus'
import ActivityManagementTableButtons from './ActivityManagementTableButtons'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { usePageFlow } from '../../../utils/default/PageFlowHook'
import { useGetDataByPayload, useRequestFromSearch, usePaginationClickAndChangeUrl } from '../../../utils/default/ComplexFlowHook'
import activityTypeName from '../../../constants/default/activityTypeName'
import {
  Cell,
  createTableData,
  createDefaultPaginationData,
  formatDateTime
} from '../../../utils/default/TableHelper'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import useT from '../../../i18ns/admin/useT'
import DateTime from '../../default/present/DateTime'
import { searchToRequest } from './ActivityManagementForm'
import allRoute from '../../../components/admin/route/route'
import Box from '@material-ui/core/Box'

interface RowType extends Cell {
  title: string
  type: string
  tags: React.ReactElement | '-'
  showTime: React.ReactElement
  activityTime: React.ReactElement | string
  layout_setting_type: string
  is_carousel: React.ReactElement | ''
  is_guest: React.ReactElement | ''
  status: React.ReactElement
  updatedAt: React.ReactElement
  updatedBy: string
  buttons: React.ReactElement
}

interface PropTypes {
  reload: () => void
  reloadFlag: boolean
}

const ActivityManagementTable: React.FC<PropTypes> = (props) => {
  const { reload, reloadFlag } = props
  const request = useRequestFromSearch({ searchToRequest })
  const classes = useCommonStyles()
  const { t } = useT()
  const gdk = useGDK()
  const pageFlow = usePageFlow(true, false)
  const [activityList, setActivityList] = useState<PaginationRes<ActivityManagementData[]>>(
    createDefaultPaginationData([] as ActivityManagementData[])
  )

  useGetDataByPayload({
    payload: request as ActivityManagementListQuery & PaginationReq,
    gdkFunc: (payload) => gdk.activity.getActivityManagementList(payload),
    gdkFuncDependencies: [gdk, reloadFlag],
    onBeforeFetch: pageFlow.setLoadingStart,
    onSuccess: (res: PaginationRes<ActivityManagementData[]>) => {
      setActivityList(res)
      pageFlow.setContentShow()
    },
    onError: (error) => {
      pageFlow.setError(error.message)
    }
  })

  const rows: RowType[] = useMemo(() => {
    return activityList.data.map((entry) => {
      return {
        id: entry.id,
        title: entry.title,
        type: t(activityTypeName[entry.type]),
        tags: entry.tags.length > 0
          ? (<span className={classes.pre}>{entry.tags.join('\n')}</span>)
          : '-',
        showTime: (
          <Box>
            {formatDateTime(entry.show_start_at)}
            <br />
            {t('common.to')}
            <br />
            {entry.show_end_at ? formatDateTime(entry.show_end_at) : t('common.longtermDisplay')}
          </Box>
        ),
        activityTime: entry.start_at
          ? (<Box>
              {formatDateTime(entry.start_at)}
              <br />
              {t('common.to')}
              <br />
              {entry.end_at ? formatDateTime(entry.end_at) : t('common.longtermActivity')}
            </Box>
            )
          : '-',
        layout_setting_type: (entry.layout_setting_type ? (entry.layout_setting_type === ActivityModuleType.SINGLE ? t('common.singleActivity') : t('common.game2')) : '-'),
        is_carousel: (entry.is_carousel ? <CheckIcon /> : ''),
        is_guest: (entry.is_guest ? <CheckIcon /> : ''),
        is_my: (entry.is_my ? <CheckIcon /> : ''),
        status: (
          <ActivityManagementTableStatus
            id={entry.id}
            isActive={entry.is_active}
            showEndAt={entry.show_end_at}
            reload={reload}
          />),
        updatedAt: (<DateTime time={entry.updated_at} />),
        updatedBy: entry.updated_by,
        buttons: (
          <ActivityManagementTableButtons
            id={entry.id}
            type={entry.type}
            isActive={entry.is_active}
            reload={reload}
          />
        )
      }
    })
  }, [activityList, reload, t, classes.pre])

  const data = useMemo(() => {
    return createTableData<RowType>(
      {
        id: {
          label: '',
          value: 'id'
        },
        title: {
          label: t('common.title'),
          value: 'title',
          align: 'center',
          width: 200
        },
        type: {
          label: t('common.activityType'),
          value: 'type',
          align: 'center',
          width: 50
        },
        tags: {
          label: t('common.tag'),
          value: 'tags',
          align: 'center',
          width: 50
        },
        layout_setting_type: {
          label: t('common.applyModule'),
          value: 'layout_setting_type',
          align: 'center',
          width: 50
        },
        showTime: {
          label: t('common.displayTime'),
          value: 'showTime',
          align: 'center',
          width: 150
        },
        activityTime: {
          label: t('common.activityTime'),
          value: 'activityTime',
          align: 'center',
          width: 150
        },
        is_carousel: {
          label: t('common.homeCarousel'),
          value: 'is_carousel',
          align: 'center',
          width: 50
        },
        is_guest: {
          label: t('common.showBeforeLogin'),
          value: 'is_guest',
          align: 'center',
          width: 50
        },
        is_my: {
          label: t('common.my'),
          value: 'is_my',
          align: 'center',
          width: 50
        },
        status: {
          label: t('common.status'),
          value: 'status',
          align: 'center',
          width: 125
        },
        updatedAt: {
          label: t('common.updateAt'),
          value: 'updatedAt',
          align: 'center',
          width: 150
        },
        updatedBy: {
          label: t('common.updateBy'),
          value: 'updatedBy',
          align: 'center',
          width: 50
        },
        buttons: {
          label: '',
          value: 'buttons',
          align: 'center',
          width: 50
        }
      },
      [
        'title',
        'type',
        'tags',
        'layout_setting_type',
        'showTime',
        'activityTime',
        'is_carousel',
        'is_guest',
        'is_my',
        'status',
        'updatedAt',
        'updatedBy',
        'buttons'
      ],
      rows,
      'id'
    )
  }, [rows, t])

  const handlePagination = usePaginationClickAndChangeUrl({
    request,
    encodePath: allRoute.activityManagement.encodePath,
    pageFlow
  })

  return (
    <LoadingAndErrorFrame {...pageFlow.status}>
      <CoreTable
        classes={{
          head: classes.pinkTableHead,
          cellHead: classes.tableCellHead,
          row: classes.tableRow
        }}
        data={data}
        total={activityList.total}
        showPagination
        page={request?.page ?? 1}
        perPage={activityList.per_page}
        onChangePage={handlePagination}
        loading={pageFlow.status.loading}
      />
    </LoadingAndErrorFrame>
  )
}

export default React.memo(ActivityManagementTable)

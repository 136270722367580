import React, { useMemo } from 'react'
import clsx from 'clsx'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import useT from '../../../i18ns/admin/useT'
import { Align } from '../../default/present/CoreTable'
import playerRankName from '../../../constants/default/playerRankName'
import { PlayerRankType, FeedbackChannel } from '@golden/gdk-admin'
import { formatMoney } from '../../../utils/default/TableHelper'
import DateTime from '../../default/present/DateTime'
import { useTablePanelStyles } from '../../../utils/admin/StyleHook'

interface PropTypes {
  row: Map<string, any>
}

const FeedbackDetailPanel: React.FC<PropTypes> = (props) => {
  const { row } = props
  const classes = useTablePanelStyles()
  const { t } = useT()
  const columns = useMemo(() => {
    return [
      { name: '', align: 'center' },
      { name: '↳', align: 'center' },
      { name: t('common.game'), align: 'center' },
      ...Object.keys(playerRankName).map((key) => ({ name: t(playerRankName[key as PlayerRankType]), align: 'center' })),
      { name: t('common.updateAt'), align: 'center' },
      { name: t('common.updateBy'), align: 'center' }
    ] as Array<{ name: string, align: Align }>
  }, [t])
  const channels = useMemo(() => {
    return row.get('detail') as FeedbackChannel[]
  }, [row])
  return (
    <Table>
      <TableHead>
        <TableRow className={clsx(classes.greyBorder, classes.header)}>
          {columns.map((column, index) => (<TableCell key={index} className={classes.body} align={column.align}>{column.name}</TableCell>))}
        </TableRow>
      </TableHead>
      <TableBody>
        {channels.map((item) => (
          <TableRow key={item.channel_id} className={clsx(classes.greyBorder, classes.grey)}>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell align="center">
              {item.channel_name}
            </TableCell>
            {Object.keys(playerRankName).map((key) => {
              const target = item.ranks.find((rank) => rank.rank === key as PlayerRankType)
              return (
                <TableCell key={key} align="center">
                  {target?.feedback !== undefined ? formatMoney(target.feedback) : t('common.notAdapt')}
                </TableCell>
              )
            })}
            <TableCell align="center">
              <DateTime time={item.updated_at} />
            </TableCell>
            <TableCell align="center">
              {item.updated_by}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}

export default React.memo(FeedbackDetailPanel)

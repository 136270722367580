import React, { useState, createContext, Dispatch, SetStateAction, useContext, useMemo } from 'react'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import MuiButton from '@material-ui/core/Button'
import MuiTextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import useT from '../../../i18ns/admin/useT'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import useGDK from '../../../providers/admin/gdk/useGDK'
import useGlobalDialog from '../../../providers/admin/dialog/useGlobalDialog'
import { createGlobalDialogConfig } from '../../../utils/default/DialogHelper'
import { useDialogHandleClick, useGetData, useReload } from '../../../utils/default/ComplexFlowHook'
import { usePageFlow } from '../../../utils/default/PageFlowHook'
import LoadingAndErrorFrame from '../../../components/default/frames/LoadingAndErrorFrame'
import { makeStyles } from '@material-ui/core'
import { JointVentureContactSetting, JointVentureContactTypes } from '@golden/gdk-admin'
import MuiSwitch from '@material-ui/core/Switch'

const useStyles = makeStyles(() => ({
  subtitle: {
    fontWeight: 600,
    fontSize: 18,
    marginTop: 10
  },
  text: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  }
}))

const initialForm = (): JointVentureContactSetting[] => ([
  {
    type: JointVentureContactTypes.SKYPE,
    is_shown: false,
    contact: '',
    link: ''
  },
  {
    type: JointVentureContactTypes.QQ,
    is_shown: false,
    contact: '',
    link: ''
  },
  {
    type: JointVentureContactTypes.TELEGRAM,
    is_shown: false,
    contact: '',
    link: ''
  },
  {
    type: JointVentureContactTypes.BUBBLE,
    is_shown: false,
    contact: '',
    link: ''
  }
])

interface ContextType {
  form: JointVentureContactSetting[]
  setForm: Dispatch<SetStateAction<JointVentureContactSetting[]>>
  reload: () => void
  reloadFlag: boolean
}

export const FormContext = createContext<ContextType>({
  form: initialForm(),
  setForm: () => {},
  reload: () => {},
  reloadFlag: false
})

const Button = React.memo(MuiButton)
const TextField = React.memo(MuiTextField)

const IsShownStatusInput: React.FC<{
  type: JointVentureContactTypes
}> = React.memo((props) => {
  const { type } = props
  const { t } = useT()
  const { form, setForm } = useContext(FormContext)
  const status = useMemo(() => form.find(item => item.type === type)?.is_shown, [form, type])

  return (
    <Grid container item direction="row" alignItems="center" spacing={2}>
      <Grid item>
        <Typography>{t('common.contactBlock')}</Typography>
      </Grid>
      <Grid item>
        <span style={{ color: status ? '#72d476' : '#f44336' }}>
          {status ? t('common.show') : t('common.hide')}
        </span>
      </Grid>
        <Grid item>
          <MuiSwitch
            checked={status}
            value={type}
            onChange={(_, isChecked) => {
              setForm((form) => form.map((item) => ({
                ...item,
                is_shown: item.type === type ? isChecked : item.is_shown
              })))
            }}
          />
        </Grid>
    </Grid>
  )
})

const ContactInput: React.FC<{
  inputType: 'contact' | 'link'
  contactType: JointVentureContactTypes
}> = React.memo((props) => {
  const { inputType, contactType } = props
  const { t } = useT()
  const { form, setForm } = useContext(FormContext)

  return (
    <Grid container item direction="row" alignItems="center" spacing={1}>
      <Grid item>
        <Typography>{inputType === 'contact' ? t('common.contactWay') : t('common.contactButtonLink')}</Typography>
      </Grid>
      <Grid item xs={4}>
        <TextField
          fullWidth
          value={form.find((item) => (item.type === contactType))?.[inputType]}
          onChange={(e) => {
            const value = e.target.value
            setForm((form) => (form.map(item => ({
              ...item,
              [inputType]: item.type === contactType ? value : item[inputType]
            }))))
          }}
        />
      </Grid>
    </Grid>
  )
})

const JointVentureSettingPage: React.FC = () => {
  const gdk = useGDK()
  const pageFlow = usePageFlow()
  const commonClasses = useCommonStyles()
  const classes = useStyles()
  const { reload, reloadFlag } = useReload()
  const { t } = useT()
  const [form, setForm] = useState<JointVentureContactSetting[]>(initialForm())
  const globalDialog = useGlobalDialog()

  useGetData({
    gdkFunc: () => gdk.jointVentureSetting.getJointVentureSetting(),
    gdkFuncDependencies: [gdk, reloadFlag],
    onBeforeFetch: pageFlow.setLoadingStart,
    onSuccess: (res) => {
      setForm(res.map((item) => ({
        ...item,
        link: item.link ?? '',
        contact: item.contact ?? ''
      })))
      pageFlow.setContentShow()
    },
    onError: pageFlow.setGDKError
  })

  const handleSubmit = useDialogHandleClick({
    dialogId: 'updateJointVentureSettings',
    globalDialog,
    changeDialogConfig: createGlobalDialogConfig({
      showIcon: false,
      message: t('dialog.confirmUpdateJointVentureLink')
    }),
    successDialogConfig: createGlobalDialogConfig({
      showIcon: false,
      message: t('dialog.updateSuccess'),
      showCancel: false
    }),
    getFailDialogConfig: (error: string) => createGlobalDialogConfig({
      variant: 'error',
      showIcon: true,
      message: error,
      showCancel: false
    }),
    payload: form,
    gdkFunc: (payload) => gdk.jointVentureSetting.updateJointVentureSetting(payload.map(item => ({
      ...item,
      is_shown: (!item.contact && !item.link) ? false : item.is_shown
    }))),
    gdkFuncDependencies: [gdk, reloadFlag],
    onSuccess: () => {
      reload()
    }
  })

  return (
  <FormContext.Provider value={{ form, setForm, reload, reloadFlag }}>
    <Box padding={5}>
      <Paper>
        <Box padding={4}>
          <LoadingAndErrorFrame { ...pageFlow.status }>
            <Box>
                <Grid container direction="column" spacing={2}>
                  <Grid item>
                    <Box
                      paddingY={1.25}
                      paddingX={2}
                      className={commonClasses.pinkTitleBar}
                    >
                      <Typography variant="h5">
                        {t('page.jointVenture')}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item>
                    <Typography variant="h6" className={classes.subtitle}>{t('common.skype')}</Typography>
                  </Grid>
                  <Grid item container spacing={4}>
                    <IsShownStatusInput type={JointVentureContactTypes.SKYPE} />
                    <ContactInput inputType="contact" contactType={JointVentureContactTypes.SKYPE} />
                    <ContactInput inputType="link" contactType={JointVentureContactTypes.SKYPE} />
                  </Grid>
                  <Grid item>
                    <Typography variant="h6" className={classes.subtitle}>{t('common.qq')}</Typography>
                  </Grid>
                  <Grid item container spacing={4}>
                    <IsShownStatusInput type={JointVentureContactTypes.QQ}/>
                    <ContactInput inputType="contact" contactType={JointVentureContactTypes.QQ} />
                  </Grid>
                  <Grid item>
                    <Typography variant="h6" className={classes.subtitle}>{t('common.telegram')}</Typography>
                  </Grid>
                  <Grid item container spacing={4}>
                    <IsShownStatusInput type={JointVentureContactTypes.TELEGRAM}/>
                    <ContactInput inputType="contact" contactType={JointVentureContactTypes.TELEGRAM} />
                  </Grid>
                  <Grid item>
                    <Typography variant="h6" className={classes.subtitle}>{t('common.sigua')}</Typography>
                  </Grid>
                  <Grid item container spacing={4}>
                    <IsShownStatusInput type={JointVentureContactTypes.BUBBLE}/>
                    <ContactInput inputType="contact" contactType={JointVentureContactTypes.BUBBLE} />
                  </Grid>
                </Grid>
              </Box>
            </LoadingAndErrorFrame>
            <Grid container justifyContent='flex-end' spacing={2} style={{ marginTop: '10px' }}>
              <Button
                className={commonClasses.purpleGradualButton}
                onClick={handleSubmit}
              >
                { t('common.save') }
              </Button>
            </Grid>
          </Box>
        </Paper>
      </Box>
    </FormContext.Provider>
  )
}

export default React.memo(JointVentureSettingPage)

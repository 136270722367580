import React, { useCallback, PropsWithChildren } from 'react'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import CustomThemeType from '../../../themes/admin/CustomThemeType'

const useStyles = makeStyles((theme: CustomThemeType) => ({
  tabs: {
    maxWidth: '69vw'
  },
  tab: {
    fontSize: theme.typography.h6.fontSize,
    minWidth: 0,
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5)
  },
  tabSelected: {
    backgroundColor: '#f3f3f3',
    color: theme.custom.palette.secondary
  },
  tabContent: {
    backgroundColor: '#f3f3f3',
    padding: 24
  },
  indicator: {
    height: 0
  }
}))

interface PropTypes {
  value: string
  tabs: Array<{ value: string, label: string }>
  onChange: (value: string) => void
}

const ActivityManagementTabs: React.FC<PropsWithChildren<PropTypes>> = (props) => {
  const { value, tabs, onChange, children } = props
  const commonClasses = useCommonStyles()
  const classes = useStyles(props)

  return (<>
    <Box className={commonClasses.pinkTitleBar}>
      <Box display="flex" flexDirection="row" alignItems="center">
        <Tabs
          classes={{ root: classes.tabs, indicator: classes.indicator }}
          value={value}
          onChange={useCallback((_, value) => {
            onChange(value)
          }, [onChange])}
        >
          { tabs.map((tab) => (
            <Tab
              key={tab.value}
              value={tab.value}
              classes={{ root: classes.tab, selected: classes.tabSelected }}
              label={tab.label}
            />
          )) }
        </Tabs>
        <Box display="flex" flexGrow={1} />
      </Box>
    </Box>
    <Box className={classes.tabContent}>
      { children }
    </Box>
  </>)
}

export default React.memo(ActivityManagementTabs)

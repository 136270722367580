import { PlayerFeedbackStatusType } from '@golden/gdk-campaign'
import TKeyType from '../../i18ns/admin/TKeyType'
export const feedbackStatusName: { [key in PlayerFeedbackStatusType]: TKeyType } = {
  [PlayerFeedbackStatusType.PENDING]: 'activityStatus.notReceived',
  [PlayerFeedbackStatusType.RECEIVED]: 'activityStatus.received',
  [PlayerFeedbackStatusType.EXPIRED]: 'activityStatus.expiredSimple',
  [PlayerFeedbackStatusType.PROCESSING]: 'activityStatus.progressing'
}

export default feedbackStatusName

import React, { useState, useMemo, useCallback } from 'react'
import clsx from 'clsx'
import {
  PaginationRes,
  AgentAnnouncement,
  AgentAnnouncementListReq,
  PaginationReq,
  AnnouncementStatusType,
  AgentAnnouncementStatusType,
  NowTime
} from '@golden/gdk-admin'
import CoreTable from '../../default/present/CoreTable'
import LoadingAndErrorFrame from '../../default/frames/LoadingAndErrorFrame'
import NotificationTableStatus from './NotificationTableStatus'
import AgentAnnouncementTableButtons from './AgentAnnouncementTableButtons'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { usePageFlow } from '../../../utils/default/PageFlowHook'
import { useGetDataByPayload, useRequestFromSearch, usePaginationClickAndChangeUrl } from '../../../utils/default/ComplexFlowHook'
import {
  Cell,
  createTableData,
  createDefaultPaginationData,
  formatDate
} from '../../../utils/default/TableHelper'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import useT from '../../../i18ns/admin/useT'
import { useChecker } from '../../../utils/admin/AdminRouteHook'
import DateTime from '../../default/present/DateTime'
import { searchToRequest } from '../../../views/admin/forestage/AgentAnnouncementPage'
import allRoute from '../route/route'
import { renderContent } from '@golden/tiptap-react'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import { useAsyncEffect } from '../../../utils/default/useAsyncEffect'
import { isAfter } from 'date-fns'
import QQIcon from '../../../assets/admin/images/qq.png'
import ImageIcon from '../../../assets/admin/images/image.png'
import AgentAnnouncementDialog from './AgentAnnouncementDialog'

const useStyles = makeStyles((theme) => ({
  ellipsis: {
    width: 360,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
  },
  icon: {
    width: '24px',
    height: '24px',
    objectFit: 'contain',
    marginLeft: theme.spacing(1)
  }
}))

interface RowType extends Cell {
  id: number
  account: string
  title: string
  content: React.ReactElement
  time: React.ReactElement
  status: React.ReactElement
  updatedAt: React.ReactElement
  updatedBy: string
  buttons: React.ReactElement
  detail: {
    title: string
    content: string
    qq: string
    image_urls: string[]
  }
}

const AgentAnnouncementTable: React.FC = () => {
  const request = useRequestFromSearch({ searchToRequest })
  const classes = useStyles()
  const commonClasses = useCommonStyles()
  const { t } = useT()
  const gdk = useGDK()
  const pageFlow = usePageFlow(true, false)
  const [result, setResult] = useState<PaginationRes<Array<AgentAnnouncement<Date>>>>(
    createDefaultPaginationData([] as Array<AgentAnnouncement<Date>>)
  )
  const [reloadFlag, setReloadFlag] = useState<boolean>(true)
  const writable = useChecker()

  const payload = useMemo(() => {
    const payload: AgentAnnouncementListReq & PaginationReq = {
      page: request?.page ?? 1
    }
    if (request?.account) {
      return { ...payload, account: request.account }
    }
    return payload
  }, [request])
  useGetDataByPayload({
    payload,
    gdkFunc: (payload) => gdk.announcement.getAgentAnnouncementList(payload),
    gdkFuncDependencies: [gdk, reloadFlag],
    onBeforeFetch: pageFlow.setLoadingStart,
    onSuccess: (res: PaginationRes<Array<AgentAnnouncement<Date>>>) => {
      setResult(res)
      pageFlow.setContentShow()
    },
    onError: (error) => {
      pageFlow.setError(error.message)
    }
  })

  const handleReload = useCallback(() => setReloadFlag((reloadFlag) => !reloadFlag), [])

  const [rows, setRows] = useState<RowType[]>([])
  const [dialogId, setDialogId] = useState<number | null>(null)
  const dialogDetail = useMemo(() => {
    return rows.find((el) => el.id === dialogId)?.detail ?? null
  }, [dialogId, rows])

  useAsyncEffect(async (mounted) => {
    if (!mounted) return
    setRows(await Promise.all(result.data.map(async (entry) => {
      const status = (entry.status === AgentAnnouncementStatusType.ENABLE ? AnnouncementStatusType.START : AnnouncementStatusType.STOP)
      const content = await renderContent(entry.content)
      return {
        id: entry.id,
        account: entry.account,
        title: entry.title,
        content: (
          <Box display="flex" justifyContent="space-between">
            <Box
              className={clsx(commonClasses.anchor, classes.ellipsis)}
              onClick={() => { setDialogId(entry.id) }}
            >
              { content.replace(/<[^>]*>/g, '') }
            </Box>
            <Box display="flex">
              { !!entry.qq && (<img src={QQIcon} alt="qq-icon" className={classes.icon} />)}
              { !!entry.image_urls.length && (<img src={ImageIcon} alt="images-icon" className={classes.icon} />)}
            </Box>
          </Box>
        ),
        time: (<Box>
          {`${formatDate(entry.begin_at)} ${t('common.to')} ${formatDate(entry.end_at)}`}
          {isAfter(NowTime.get(), entry.end_at) && (<Typography color="error">{t('announcementStatus.outDate')}</Typography>)}
        </Box>),
        status: (<NotificationTableStatus status={status} />),
        updatedAt: (<DateTime time={entry.updated_at} />),
        updatedBy: entry.updated_by,
        buttons: (
          <AgentAnnouncementTableButtons
            id={entry.id}
            status={status}
            endAt={entry.end_at}
            reload={handleReload}
          />
        ),
        detail: {
          title: entry.title,
          content,
          qq: entry.qq,
          image_urls: entry.image_urls
        }
      }
    })))
  }, [result.data, commonClasses.anchor, classes.ellipsis, t, handleReload])

  const data = useMemo(() => {
    return createTableData<RowType>(
      {
        id: {
          label: '',
          value: 'id'
        },
        account: {
          label: t('agent.staff'),
          value: 'account',
          align: 'center'
        },
        title: {
          label: t('common.title'),
          value: 'title',
          align: 'left',
          columnAlign: 'center'
        },
        content: {
          label: t('common.announcementContent'),
          value: 'content',
          align: 'left',
          columnAlign: 'center'
        },
        time: {
          label: t('common.displayTime'),
          value: 'time',
          align: 'center',
          width: 120
        },
        status: {
          label: t('common.status'),
          value: 'status',
          align: 'center',
          width: 120
        },
        updatedAt: {
          label: t('common.updateAt'),
          value: 'updatedAt',
          align: 'center',
          width: 120
        },
        updatedBy: {
          label: t('common.updateBy'),
          value: 'updatedBy',
          align: 'center',
          width: 120
        },
        buttons: {
          label: t('common.function'),
          value: 'buttons',
          align: 'center',
          width: 120
        },
        detail: {
          label: '',
          value: 'detail'
        }
      },
      writable
        ? [
            'account',
            'title',
            'content',
            'time',
            'status',
            'updatedAt',
            'updatedBy',
            'buttons'
          ]
        : [
            'account',
            'title',
            'content',
            'time',
            'status',
            'updatedAt',
            'updatedBy'
          ],
      rows,
      'id'
    )
  }, [rows, t, writable])

  const handlePagination = usePaginationClickAndChangeUrl({
    request,
    encodePath: allRoute.forestageAgentAnnouncement.encodePath,
    pageFlow
  })

  return (
    <LoadingAndErrorFrame {...pageFlow.status}>
      <CoreTable
        classes={{
          head: commonClasses.pinkTableHead,
          cellHead: commonClasses.tableCellHead,
          row: commonClasses.tableRow
        }}
        data={data}
        total={result.total}
        showPagination
        page={request?.page ?? 1}
        onChangePage={handlePagination}
        loading={pageFlow.status.loading}
      />
      { !!dialogDetail && (<AgentAnnouncementDialog
        detail={dialogDetail}
        open={!!dialogId}
        handleClose={() => { setDialogId(-1) }}
      />)}
    </LoadingAndErrorFrame>
  )
}

export default React.memo(AgentAnnouncementTable)

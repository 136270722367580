import React, { useEffect } from 'react'
import Typography from '@material-ui/core/Typography'
import { useInternValue } from '../../utils/default/FormHook'
import useGDKStore from '../../providers/admin/gdk/useGDKStore'
import { useCommonStyles } from '../../utils/admin/StyleHook'
import OnOffCheckbox from '../default/form/OnOffCheckbox'
import useT from '../../i18ns/admin/useT'

export interface OpenPlayerLayerValue {
  layers: number[]
  isForProxyDeposit: boolean
}

export interface PropTypes {
  label: string
  value?: OpenPlayerLayerValue
  defaultValue?: OpenPlayerLayerValue
  onChange?: (value: OpenPlayerLayerValue) => void
  disabled?: boolean
  proxyDepositDisabled?: boolean
  required?: boolean
}

const OpenPlayerLayerInput: React.FC<PropTypes> = (props) => {
  const {
    label,
    value,
    defaultValue,
    onChange,
    disabled,
    required,
    proxyDepositDisabled
  } = props

  const { t } = useT()
  const commonClasses = useCommonStyles()

  const layers = useGDKStore.player.layers()

  const [internValue, setInternValue] = useInternValue<OpenPlayerLayerValue>(
    defaultValue ?? value ?? { layers: [], isForProxyDeposit: false },
    value
  )

  const allChecked = layers.every((item) => internValue.layers.includes(item.id)) && (internValue.isForProxyDeposit || proxyDepositDisabled)

  useEffect(() => {
    if (proxyDepositDisabled && internValue.isForProxyDeposit) {
      setInternValue({ ...internValue, isForProxyDeposit: false })
    }
  }, [internValue, proxyDepositDisabled, setInternValue])

  return (
    <React.Fragment>
      <Typography className={commonClasses.bold}>{label}{required && '*'}</Typography>
      <OnOffCheckbox
        disabled={disabled}
        label={t('common.all')}
        value={allChecked}
        onChange={() => {
          let newValue = internValue
          if (allChecked) {
            newValue = {
              layers: [],
              isForProxyDeposit: false
            }
          } else {
            newValue = {
              layers: layers.map((item) => item.id),
              isForProxyDeposit: !proxyDepositDisabled
            }
          }
          setInternValue(newValue)
          if (onChange) onChange(newValue)
        }}
      />
      {
        layers
          .map((item) => (
            <OnOffCheckbox
              key={item.id}
              disabled={disabled}
              label={item.name}
              value={internValue.layers.includes(item.id)}
              onChange={() => {
                let newValue = internValue
                if (internValue.layers.includes(item.id)) {
                  newValue = {
                    layers: internValue.layers.filter((layer) => layer !== item.id),
                    isForProxyDeposit: internValue.isForProxyDeposit
                  }
                } else {
                  newValue = {
                    layers: internValue.layers.concat(item.id),
                    isForProxyDeposit: internValue.isForProxyDeposit
                  }
                }
                setInternValue(newValue)
                if (onChange) onChange(newValue)
              }}
            />
          ))
      }
      <OnOffCheckbox
        // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
        disabled={disabled || proxyDepositDisabled}
        label={t('common.staffDeposit')}
        value={internValue.isForProxyDeposit}
        onChange={() => {
          const newValue = internValue
          newValue.isForProxyDeposit = !newValue.isForProxyDeposit
          setInternValue(newValue)
          if (onChange) onChange(newValue)
        }}
      />
    </React.Fragment>
  )
}

export default React.memo(OpenPlayerLayerInput)

import React, { PropsWithChildren } from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import CircularProgress from '@material-ui/core/CircularProgress'
import ErrorText from '../present/ErrorText'

const useStyles = makeStyles(() => ({
  hidden: {
    display: 'none'
  }
}))

export interface LoadingAndErrorPayload {
  error: string | null
  loading: boolean
  showContent: boolean
}

const LoadingAndErrorFrame: React.FC<PropsWithChildren<LoadingAndErrorPayload>> = (props) => {
  const { error, loading, showContent, children } = props
  const classes = useStyles()

  return (
    <React.Fragment>
      <Box
        display={loading ? 'flex' : 'none'}
        justifyContent="center"
        width="100%"
      >
        <CircularProgress disableShrink />
      </Box>
      <div className={clsx({ [classes.hidden]: error === null || loading })}>
        <ErrorText error={error ?? ''} />
      </div>
      <div className={clsx({ [classes.hidden]: loading || error !== null || !showContent })}>
        {children}
      </div>
    </React.Fragment>
  )
}

export default React.memo(LoadingAndErrorFrame)

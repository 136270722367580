import React, { createContext, useMemo, useContext } from 'react'
import Grid from '@material-ui/core/Grid'
import MuiButton from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import MuiTextField, { TextFieldProps } from '@material-ui/core/TextField'
import DropDown, { PropTypes as DropDownProps } from '../../default/form/DropDown'
import FormField from '../../default/form/FormField'
import FormSubmitButton from '../../default/form/FormSubmitButton'
import { FormPropType, getValueFromChangeEvent, getValueFromCheckboxEvent, getCashInputProps, getBankAccountInputProps, getValueFromValue, getCodeInputProps } from '../../../utils/default/FormHelper'
import { InitialFormFunc } from '../../../utils/default/ComplexFlowHook'
import { createDefaultFormState, ValueGetter, FormValidation } from '../../../utils/default/FormHook'
import useT from '../../../i18ns/admin/useT'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import { DepositMethodType, ClientType } from '@golden/gdk-admin'
import RequiredText from '../../default/form/RequiredText'
import FormStateProvider from '../../default/form/FormStateProvider'
import { createValidateNotEmpty, createValidateRange, createValidateCash, createValidateMin, createValidateMax, createErrorResult, createCorrectResult } from '../../../utils/default/Validator'
import depositTypeName from '../../../constants/default/depositTypeName'
import NumberInput from '../../default/form/NumberInput'
import OnOffCheckbox from '../../default/form/OnOffCheckbox'
import clientTypeName from '../../../constants/admin/clientTypeName'
import OpenPlayerLayerInput, { PropTypes as OpenPlayerLayerInputProps, OpenPlayerLayerValue } from '../OpenPlayerLayerInput'
import CheckboxGroup, { PropTypes as CheckboxGroupProps } from '../../default/form/CheckboxGroup'

export interface EditFinancePiggyBankFormType {
  bank: number | ''
  account: string
  min: string
  max: string
  receiver: string
  memo: string
  clients: ClientType[]
  layer: OpenPlayerLayerValue
  isActive: boolean
  isRecommended: boolean
  code: string
}

export const initialForm: InitialFormFunc<EditFinancePiggyBankFormType> = (defaultForm) => ({
  bank: '',
  account: '',
  min: '',
  max: '',
  receiver: '',
  memo: '',
  clients: [],
  layer: { layers: [], isForProxyDeposit: false },
  isActive: false,
  isRecommended: false,
  code: '',
  ...defaultForm
})

const getValueFromEvent: ValueGetter<EditFinancePiggyBankFormType> = {
  bank: getValueFromChangeEvent,
  account: getValueFromChangeEvent,
  min: getValueFromChangeEvent,
  max: getValueFromChangeEvent,
  receiver: getValueFromChangeEvent,
  memo: getValueFromChangeEvent,
  clients: getValueFromValue,
  layer: getValueFromValue,
  isActive: getValueFromCheckboxEvent,
  isRecommended: getValueFromCheckboxEvent,
  code: getValueFromChangeEvent
}

interface PropTypes extends FormPropType<EditFinancePiggyBankFormType> {
  banks: Array<{ id: number, name: string }>
  bankName?: string
  disableBank?: boolean
  disableAccount?: boolean
  okText: string
  onBack: () => void
}

const memoFormHelperTextProps = { error: true }

const cashInputProps = getCashInputProps()
const codeInputProps = getCodeInputProps()

const bankAccountInputProps = getBankAccountInputProps()

const FormContext = createContext(createDefaultFormState(initialForm()))

const TextField = React.memo(MuiTextField)

const Button = React.memo(MuiButton)

const ShowAccount: React.FC = () => {
  const { t } = useT()
  const { value } = useContext(FormContext)
  return (
    <TextField
      label={t('common.playerSideShowAccount')}
      value={value.account}
      fullWidth
      disabled
    />
  )
}

const EditFinancePiggyBankForm: React.FC<PropTypes> = (props) => {
  const {
    banks,
    bankName,
    defaultValue,
    onSubmit,
    onBack,
    okText,
    disableAccount,
    disableBank
  } = props
  const commonClasses = useCommonStyles()
  const { t } = useT()

  const bankOptions = useMemo(() => {
    return banks.map((bank) => ({ name: bank.name, value: bank.id }))
  }, [banks])

  const validation = useMemo(() => {
    return {
      bank: disableBank
        ? []
        : [
            {
              func: createValidateNotEmpty('bank', t),
              when: ['blur', 'beforeClickSubmit']
            }
          ],
      account: [
        {
          func: createValidateNotEmpty('account', t),
          when: ['change', 'beforeClickSubmit']
        },
        {
          func: createValidateRange<EditFinancePiggyBankFormType>(
            'account',
            (value) => (value as string).length,
            'moreEqual',
            16,
            t('error.invalidBankAccount')
          ),
          when: ['change', 'beforeClickSubmit']
        }
      ],
      min: [
        {
          func: createValidateNotEmpty('min', t),
          when: ['change', 'beforeClickSubmit']
        },
        {
          func: createValidateCash('min', t),
          when: ['change', 'beforeClickSubmit']
        },
        {
          func: createValidateMin('min', 'max', t),
          when: ['change', 'beforeClickSubmit']
        }
      ],
      max: [
        {
          func: createValidateNotEmpty('max', t),
          when: ['change', 'beforeClickSubmit']
        },
        {
          func: createValidateCash('max', t),
          when: ['change', 'beforeClickSubmit']
        },
        {
          func: createValidateMax('min', 'max', t),
          when: ['change', 'beforeClickSubmit']
        }
      ],
      receiver: [
        {
          func: createValidateNotEmpty('receiver', t),
          when: ['change', 'beforeClickSubmit']
        }
      ],
      memo: [],
      isActive: [],
      isRecommended: [],
      clients: [
        {
          func: createValidateRange('clients', (value) => (value as any[]).length, 'moreThan', 0, t('error.mustNotEmpty')),
          when: ['beforeClickSubmit']
        }
      ],
      layer: [
        {
          func: (value) => {
            const layer = (value as OpenPlayerLayerValue)
            if (layer.layers.length === 0 && !layer.isForProxyDeposit) return createErrorResult('layer', t('error.mustNotEmpty'))
            return createCorrectResult('layer')
          },
          when: ['beforeClickSubmit']
        }
      ],
      code: [
        {
          func: createValidateNotEmpty('code', t),
          when: ['change', 'beforeClickSubmit']
        }
      ]
    } as FormValidation<EditFinancePiggyBankFormType>
  }, [t, disableBank])

  const clientOptions = useMemo(() => {
    return Object.keys(clientTypeName)
      .map((key) => Number(key) as ClientType)
      .map((key) => ({ name: t(clientTypeName[key]), value: key }))
  }, [t])

  const memoInputProps = useMemo(() => ({
    classes: { input: commonClasses.memoField },
    inputProps: {
      placeholder: t('placeholder.inputMemo')
    }
  }), [commonClasses.memoField, t])

  return (
    <FormStateProvider
      context={FormContext}
      defaultValue={defaultValue}
      onSubmit={onSubmit}
      getValueFromEvent={getValueFromEvent}
      validation={validation}
    >
      <Grid container direction="column" spacing={3}>
        <Grid item>
          <Grid container direction="row" spacing={2}>
            <Grid item xs={12} md={3}>
              {disableBank
                ? (
                <TextField
                  label={t('common.bankName')}
                  value={bankName ?? ''}
                  disabled
                  fullWidth
                />
                  )
                : (
                <FormField<EditFinancePiggyBankFormType, DropDownProps>
                  context={FormContext}
                  component={DropDown}
                  name="bank"
                  fullWidth
                  required
                  label={t('common.bankName')}
                  options={bankOptions}
                />
                  )}
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                label={t('common.depositWay')}
                value={t(depositTypeName[DepositMethodType.ONLINE])}
                disabled
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <FormField<EditFinancePiggyBankFormType, TextFieldProps>
                context={FormContext}
                component={NumberInput}
                name="account"
                fullWidth
                required
                label={t('common.bankAccount')}
                placeholder={t('placeholder.inputBankAccount')}
                inputProps={bankAccountInputProps}
                disabled={disableAccount}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container direction="row" spacing={2}>
            <Grid item xs={12} md={3}>
              <FormField<EditFinancePiggyBankFormType, TextFieldProps>
                context={FormContext}
                component={NumberInput}
                name="min"
                fullWidth
                required
                label={t('common.minDepositMoney')}
                inputProps={cashInputProps}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <FormField<EditFinancePiggyBankFormType, TextFieldProps>
                context={FormContext}
                component={NumberInput}
                name="max"
                fullWidth
                required
                label={t('common.maxDepositMoney')}
                inputProps={cashInputProps}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <FormField<EditFinancePiggyBankFormType, TextFieldProps>
                context={FormContext}
                component={TextField}
                name="receiver"
                fullWidth
                required
                label={t('common.playerSideReceiver')}
                placeholder={t('placeholder.inputPlayerSideReceiver')}
                inputProps={{ maxLength: 20 }}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <ShowAccount />
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <FormField<EditFinancePiggyBankFormType, TextFieldProps>
            context={FormContext}
            component={TextField}
            name="memo"
            multiline
            fullWidth
            type="text"
            margin="normal"
            variant="outlined"
            InputProps={memoInputProps}
            FormHelperTextProps={memoFormHelperTextProps}
          />
        </Grid>
        <Grid item>
          <FormField<EditFinancePiggyBankFormType, CheckboxGroupProps>
            context={FormContext}
            component={CheckboxGroup}
            name="clients"
            label={t('common.platformVersion')}
            options={clientOptions}
            required
          />
        </Grid>
        <Grid item>
          <FormField<EditFinancePiggyBankFormType, OpenPlayerLayerInputProps>
            context={FormContext}
            component={OpenPlayerLayerInput}
            name="layer"
            label={t('common.openLayer')}
            required
          />
        </Grid>
        <Grid item>
          <FormField
            component={OnOffCheckbox}
            context={FormContext}
            name="isRecommended"
            label={t('common.suggestion')}
          />
        </Grid>
        <Grid item>
          <Typography className={commonClasses.bold}>{t('common.status')}</Typography>
          <FormField
            component={OnOffCheckbox}
            context={FormContext}
            name="isActive"
            label={t('common.isStartPiggyBank')}
          />
        </Grid>
        <Grid item>
          <Grid container direction="row" spacing={2}>
            <Grid item xs={12} md={3}>
              <FormField<EditFinancePiggyBankFormType, TextFieldProps>
                context={FormContext}
                component={NumberInput}
                name="code"
                fullWidth
                required
                inputProps={codeInputProps}
                label={t('common.googleCode')}
                placeholder={t('placeholder.inputGoogleCode2')}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <RequiredText />
        </Grid>
        <Grid item>
          <Grid container direction="row" justify="flex-end" spacing={2}>
            <Grid item>
              <Button
                className={commonClasses.greyButton}
                onClick={onBack}
              >
                {t('common.cancel')}
              </Button>
            </Grid>
            <Grid item>
              <FormSubmitButton
                component={Button}
                context={FormContext}
                className={commonClasses.purpleGradualButton}
                type="submit"
              >
                {okText}
              </FormSubmitButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </FormStateProvider>
  )
}

export default React.memo(EditFinancePiggyBankForm)

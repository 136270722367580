import React, { useCallback, useState, useEffect, useMemo } from 'react'
import { PiggyBankForm, Branch, ClientType } from '@golden/gdk-admin'
import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import useT from '../../../i18ns/admin/useT'
import useGDK from '../../../providers/admin/gdk/useGDK'
import useGlobalDialog from '../../../providers/admin/dialog/useGlobalDialog'
import { useRedirectHandleBack, useDialogHandleSubmit, useGetData } from '../../../utils/default/ComplexFlowHook'
import { Path } from '../../../components/admin/route/route'
import { createGlobalDialogConfig } from '../../../utils/default/DialogHelper'
import EditFinancePiggyBankForm, { EditFinancePiggyBankFormType, initialForm } from '../../../components/admin/finance/EditFinancePiggyBankForm'
import LoadingAndErrorFrame from '../../../components/default/frames/LoadingAndErrorFrame'
import { usePageFlow } from '../../../utils/default/PageFlowHook'

const formToRequest = (form: EditFinancePiggyBankFormType): PiggyBankForm => ({
  bank_code: Number(form.bank),
  account: form.account,
  cash_max: Number(form.max),
  cash_min: Number(form.min),
  real_name: form.receiver,
  memo: form.memo,
  clients: form.clients,
  is_active: form.isActive,
  layer_ids: form.layer.layers,
  code: form.code,
  is_for_proxy_deposit: form.layer.isForProxyDeposit,
  is_recommended: form.isRecommended
})

const FinancePiggyBankCreatePage: React.FC = () => {
  const classes = useCommonStyles()
  const { t } = useT()
  const gdk = useGDK()
  const [banks, setBanks] = useState<Array<{ id: number, name: string }>>([])
  const pageFlow = usePageFlow()
  const globalDialog = useGlobalDialog()
  const defaultForm = useMemo(() => {
    return initialForm({
      clients: [ClientType.DESKTOP, ClientType.MOBILE],
      memo: t('common.depositMemo')
    })
  }, [t])
  useGetData({
    gdkFunc: () => gdk.finance.getPiggyBankOptions(),
    gdkFuncDependencies: [gdk],
    onBeforeFetch: pageFlow.setLoadingStart,
    onSuccess: (res: Array<{ id: number, name: string }>) => {
      setBanks(res)
      pageFlow.setContentShow()
    },
    onError: pageFlow.setGDKError
  })
  useEffect(() => {
    gdk.trunk.trigger([Branch.PLAYER_LAYER])
  }, [gdk])
  const [handleBack, handleDebouncedBack] = useRedirectHandleBack({ path: Path.FINANCE_PIGGY_BANK })
  const { handleSubmit } = useDialogHandleSubmit({
    formToRequest,
    gdkFunc: (payload) => gdk.finance.createPiggyBank(payload),
    gdkFuncDependencies: [gdk],
    dialogId: 'createPiggyBank',
    globalDialog,
    getChangeDialogConfig: useCallback((form: EditFinancePiggyBankFormType) => createGlobalDialogConfig({
      showIcon: false,
      message: (
        <React.Fragment>
          {t('dialog.confirmCreatePiggyBank')}
          <span className={classes.purpleWord}>{banks.find((item) => item.id === form.bank)?.name ?? ''} {form.receiver}</span>
        </React.Fragment>
      )
    }), [t, classes.purpleWord, banks]),
    getSuccessDialogConfig: useCallback(() => createGlobalDialogConfig({
      showIcon: false,
      showCancel: false,
      message: t('dialog.createPiggyBankSuccess')
    }), [t]),
    getFailDialogConfig: useCallback((_, error) => createGlobalDialogConfig({
      showIcon: true,
      variant: 'error',
      showCancel: false,
      message: error.message
    }), []),
    afterSuccessDialog: handleBack
  })
  return (
    <Box padding={5}>
      <Paper>
        <Box padding={4}>
          <Box
            paddingY={1.25}
            paddingX={2}
            className={classes.pinkTitleBar}
          >
            <Typography variant="h5">
              {t('page.createPiggyBank')}
            </Typography>
          </Box>
          <Box paddingY={2}>
            <LoadingAndErrorFrame { ...pageFlow.status }>
              <EditFinancePiggyBankForm
                okText={t('common.confirmCreate')}
                onBack={handleDebouncedBack}
                onSubmit={handleSubmit}
                defaultValue={defaultForm}
                banks={banks}
              />
            </LoadingAndErrorFrame>
          </Box>
        </Box>
      </Paper>
    </Box>
  )
}

export default React.memo(FinancePiggyBankCreatePage)

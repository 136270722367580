import React, { useCallback, useState, useMemo } from 'react'
import { AdminChangeBank, AdminBank } from '@golden/gdk-admin'
import Box from '@material-ui/core/Box'
import LoadingAndErrorFrame from '../../../components/default/frames/LoadingAndErrorFrame'
import EditFinanceBankForm, {
  EditFinanceBankFormType,
  initialForm
} from '../../../components/admin/finance/EditFinanceBankForm'
import useT from '../../../i18ns/admin/useT'
import { useDialogHandleSubmit, useRedirectHandleBack, useGetDataByParams, useRequestFromSearch } from '../../../utils/default/ComplexFlowHook'
import useGlobalDialog from '../../../providers/admin/dialog/useGlobalDialog'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import { createGlobalDialogConfig } from '../../../utils/default/DialogHelper'
import allRoute, { Path } from '../../../components/admin/route/route'
import { usePageFlow } from '../../../utils/default/PageFlowHook'
import { searchToRequest } from './FinanceBankPage'

const formToRequest: (form: EditFinanceBankFormType) => AdminChangeBank = (form) => {
  return {
    simple_name: form.shortName,
    url: form.url,
    is_showed: form.checked,
    memo: form.memo
  }
}

const FinanceBankUpdatePage: React.FC = () => {
  const classes = useCommonStyles()
  const globalDialog = useGlobalDialog()
  const request = useRequestFromSearch({ searchToRequest })
  const pageFlow = usePageFlow()
  const [bank, setBank] = useState<AdminBank>()
  const [defaultForm, setDefaulForm] = useState<EditFinanceBankFormType>(initialForm())
  const gdk = useGDK()
  const { t } = useT()

  useGetDataByParams({
    path: Path.FINANCE_BANK_UPDATE,
    gdkFunc: (param: { id: string }) => gdk.finance.getBank(Number(param.id)),
    gdkFuncDependencies: [gdk],
    onBeforeFetch: pageFlow.setLoadingStart,
    onSuccess: (res) => {
      setBank(res)
      setDefaulForm(initialForm({
        shortName: res.simple_name,
        url: res.url,
        checked: res.is_showed,
        memo: res.memo
      }))
      pageFlow.setContentShow()
    },
    onError: pageFlow.setGDKError,
    canLoadData: bank === undefined
  })

  const [handleBack, handleDebouncedBack] = useRedirectHandleBack({
    path: allRoute.financeBank.encodePath({ search: { ...request }, param: {} })
  })

  const bankName = useMemo(() => bank?.name ?? '', [bank])

  const { handleSubmit } = useDialogHandleSubmit<EditFinanceBankFormType, AdminChangeBank, null>({
    dialogId: 'updateCourier',
    globalDialog,
    getChangeDialogConfig: useCallback(() => createGlobalDialogConfig({
      showIcon: false,
      message: (
        <React.Fragment>
          {t('dialog.confirmChangeBankSetting')}
          <span className={classes.purpleWord}>{bankName}</span>
        </React.Fragment>
      )
    }), [t, classes.purpleWord, bankName]),
    getSuccessDialogConfig: useCallback(() => createGlobalDialogConfig({
      showIcon: false,
      showCancel: false,
      message: t('dialog.changeBankSettingSuccess')
    }), [t]),
    getFailDialogConfig: useCallback((error) => createGlobalDialogConfig({
      showIcon: true,
      variant: 'error',
      showCancel: false,
      message: error
    }), []),
    formToRequest,
    gdkFunc: (payload) => gdk.finance.updateBank(bank?.id ?? 0, payload),
    gdkFuncDependencies: [gdk, bank],
    afterSuccessDialog: handleBack
  })

  return (
    <Box paddingY={5}>
      <LoadingAndErrorFrame { ...pageFlow.status } >
        <EditFinanceBankForm
          title={t('page.updateBank')}
          okText={t('common.confirmUpdate')}
          onBack={handleDebouncedBack}
          onSubmit={handleSubmit}
          defaultValue={defaultForm}
          name={bank?.name ?? ''}
          code={bank?.simple_code ?? ''}
        />
      </LoadingAndErrorFrame>
    </Box>
  )
}

export default React.memo(FinanceBankUpdatePage)

import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import CustomThemeType from '../../themes/admin/CustomThemeType'

const BlueButton = withStyles((theme) => ({
  root: {
    boxShadow: 'none',
    color: (theme as CustomThemeType).custom.palette.blue.main,
    borderColor: (theme as CustomThemeType).custom.palette.blue.main,
    '&:hover': {
      backgroundColor: (theme as CustomThemeType).custom.palette.blue.main,
      color: theme.palette.getContrastText((theme as CustomThemeType).custom.palette.blue.main)
    }
  }
}))(Button)

export default React.memo(BlueButton)

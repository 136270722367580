import React, { useState, useMemo } from 'react'
import { PaginationRes, AgentOperationLog, AgentAccountType, AgentOperationLogsCategoryType, GuardType } from '@golden/gdk-admin'
import Box from '@material-ui/core/Box'
import CoreTable from '../../default/present/CoreTable'
import DateTime from '../../default/present/DateTime'
import LoadingAndErrorFrame from '../../default/frames/LoadingAndErrorFrame'
import ScrollablePaper from '../../default/present/ScrollablePaper'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import useT from '../../../i18ns/admin/useT'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { createDefaultPaginationData, createTableData } from '../../../utils/default/TableHelper'
import { searchToRequest } from './AccountAgentOperationLogForm'
import { usePageFlow } from '../../../utils/default/PageFlowHook'
import { useRequestFromSearch, useGetDataByPayload, usePaginationClickAndChangeUrl } from '../../../utils/default/ComplexFlowHook'
import allRoutes from '../route/route'

interface RowType {
  id: number
  agentAccount: string
  agentType: AgentAccountType
  category: AgentOperationLogsCategoryType
  method: string
  operation: string
  memo: React.ReactElement | string
  operatedAt: React.ReactElement | string
  operatorAccount: string
}

const AccountAgentOperationLogTable: React.FC = () => {
  const classes = useCommonStyles()
  const { t } = useT()
  const gdk = useGDK()
  const [list, setList] = useState<PaginationRes<AgentOperationLog[]>>(createDefaultPaginationData([]))
  const pageFlow = usePageFlow()
  const request = useRequestFromSearch({ searchToRequest })
  useGetDataByPayload({
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    payload: request!,
    gdkFunc: (payload) => gdk.history.getAgentOperationLog(payload),
    gdkFuncDependencies: [gdk],
    onBeforeFetch: pageFlow.setLoadingStart,
    onSuccess: (res: PaginationRes<AgentOperationLog[]>) => {
      setList(res)
      pageFlow.setContentShow()
    },
    onError: pageFlow.setGDKError,
    canLoadData: request !== undefined
  })
  const tableClasses = useMemo(() => ({
    head: classes.pinkTableHead,
    row: classes.tableRow,
    cellHead: classes.tableCellHead,
    cellBody: classes.pre
  }), [classes])
  const rows: RowType[] = useMemo(() => {
    return list.data.map((item, index) => {
      return {
        id: index,
        agentAccount: item.agent_account,
        agentType: item.agent_type,
        category: item.category,
        method: item.method,
        operation: item.operation,
        memo: item.memo,
        operatedAt: item.operated_at ? (<DateTime time={item.operated_at} />) : (<p></p>),
        operatorAccount: `${item.operator_account}${item.operator_type === GuardType.AGENT ? '*' : ''}`
      }
    })
  }, [list.data])
  const data = useMemo(() => {
    return createTableData<RowType>(
      {
        id: {
          label: '',
          value: 'id',
          align: 'center'
        },
        agentAccount: {
          label: t('common.agentAccount'),
          value: 'agentAccount',
          width: 90,
          align: 'center'
        },
        agentType: {
          label: t('common.accountType'),
          value: 'agentType',
          align: 'center'
        },
        category: {
          label: t('common.block'),
          value: 'category',
          width: 120,
          align: 'center'
        },
        method: {
          label: t('common.function'),
          value: 'method',
          align: 'center'
        },
        operation: {
          label: t('common.operation'),
          value: 'operation',
          align: 'center'
        },
        memo: {
          label: t('common.memo'),
          value: 'memo',
          width: 150,
          align: 'center'
        },
        operatedAt: {
          label: t('common.updateAt'),
          value: 'operatedAt',
          align: 'center'
        },
        operatorAccount: {
          label: t('common.updateBy'),
          value: 'operatorAccount',
          align: 'center'
        }
      },
      [
        'agentAccount',
        'agentType',
        'category',
        'method',
        'operation',
        'memo',
        'operatedAt',
        'operatorAccount'
      ],
      rows,
      'id'
    )
  }, [rows, t])

  const handlePagination = usePaginationClickAndChangeUrl({
    request,
    encodePath: allRoutes.accountAgentOperationLog.encodePath
  })

  if (request === undefined) return null

  return (
    <ScrollablePaper marginX={5}>
      <Box padding={4}>
        <LoadingAndErrorFrame { ...pageFlow.status }>
          <CoreTable
            classes={tableClasses}
            data={data}
            total={list.total}
            showPagination
            page={request.page}
            onChangePage={handlePagination}
          />
        </LoadingAndErrorFrame>
      </Box>
    </ScrollablePaper>
  )
}

export default React.memo(AccountAgentOperationLogTable)

import React, { useCallback, useMemo } from 'react'
import { Link } from 'react-router-dom'
import { omitBy, isUndefined } from '@golden/utils'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import MuiTextField from '@material-ui/core/TextField'
import DropDown from '../../default/form/DropDown'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import useT from '../../../i18ns/admin/useT'
import allRoute from '../route/route'
import { useInternValue } from '../../../utils/default/FormHook'
import { searchToRequest, RequestType } from '../../../views/admin/finance/FinanceBankPage'
import { useRequestFromSearch, useChangeUrlSubmit } from '../../../utils/default/ComplexFlowHook'
import { convertEmptyToUndefined } from '../../../utils/default/FormHelper'
import { useChecker } from '../../../utils/admin/AdminRouteHook'
import { PermissionType } from '@golden/gdk-campaign'

const useStyles = makeStyles(() => ({
  textField: {
    paddingTop: 14,
    paddingBottom: 14
  }
}))

const TextField = React.memo(MuiTextField)

const FinanceBankToolbar: React.FC = () => {
  const request = useRequestFromSearch({ searchToRequest })
  const [text, setText] = useInternValue<string>('', request?.name ?? '')
  const classes = useStyles()
  const commonClasses = useCommonStyles()
  const writableCode = useChecker([PermissionType.BANK_SETTING_CODE])
  const { t } = useT()
  const showOptions = useMemo(() => {
    return [
      { name: t('common.all'), value: '--' },
      { name: t('common.showInPlayer'), value: 1 },
      { name: t('common.notShowInPlayer'), value: 0 }
    ]
  }, [t])
  const maintainOption = useMemo(() => {
    return [
      { name: t('common.all'), value: '--' },
      { name: t('common.maintaining'), value: 1 },
      { name: t('common.notMaintaining'), value: 0 }
    ]
  }, [t])
  const handleTextSubmit = useChangeUrlSubmit<{}, RequestType>({
    formToRequest: () => omitBy({
      maintained: request?.maintained,
      is_showed: request?.is_showed,
      name: convertEmptyToUndefined(text),
      page: 1
    }, isUndefined) as unknown as RequestType,
    encodePath: allRoute.financeBank.encodePath,
    toAddNowTimestamp: true
  })
  const handleStatusChange = useChangeUrlSubmit<{ is_showed: '--' | 0 | 1 }, RequestType>({
    formToRequest: (form) => omitBy({
      maintained: request?.maintained,
      is_showed: form.is_showed === '--' ? undefined : form.is_showed,
      name: convertEmptyToUndefined(text),
      page: 1
    }, isUndefined) as unknown as RequestType,
    encodePath: allRoute.financeBank.encodePath,
    toAddNowTimestamp: true
  })
  const handleMaintainChange = useChangeUrlSubmit<{ maintained: '--' | 0 | 1 }, RequestType>({
    formToRequest: (form) => omitBy({
      maintained: form.maintained === '--' ? undefined : form.maintained,
      is_showed: request?.is_showed,
      name: convertEmptyToUndefined(text),
      page: 1
    }, isUndefined) as unknown as RequestType,
    encodePath: allRoute.financeBank.encodePath,
    toAddNowTimestamp: true
  })
  return (
    <Grid container direction="row" justify="space-between">
      <Grid container item xs={10} spacing={2}>
        <Grid item>
          <TextField
            variant="outlined"
            InputProps={{ classes: { input: classes.textField } }}
            placeholder={t('placeholder.inputSearchBankName')}
            value={text}
            onChange={useCallback((event) => {
              setText(event.target.value)
              // eslint-disable-next-line react-hooks/exhaustive-deps
            }, [])}
          />
        </Grid>
        <Grid item>
          <Button
            className={commonClasses.purpleGradualButton}
            onClick={handleTextSubmit}
          >
            <Box paddingY={0.5}>
              {t('common.search')}
            </Box>
          </Button>
        </Grid>
        <Grid item xs={2}>
          <DropDown
            className={commonClasses.outlineDropDown}
            fullWidth
            variant="outlined"
            label=""
            value={request?.is_showed !== undefined ? request.is_showed : '--'}
            onChange={useCallback((event) => {
              // eslint-disable-next-line @typescript-eslint/naming-convention
              const is_showed = event.target.value
              handleStatusChange({ is_showed })
            }, [handleStatusChange])}
            options={showOptions}
          />
        </Grid>
        <Grid item xs={2}>
          <DropDown
            className={commonClasses.outlineDropDown}
            fullWidth
            variant="outlined"
            label=""
            value={request?.maintained !== undefined ? request.maintained : '--'}
            onChange={useCallback((event) => {
              const maintained = event.target.value
              handleMaintainChange({ maintained })
            }, [handleMaintainChange])}
            options={maintainOption}
          />
        </Grid>
      </Grid>
      { writableCode && (<Grid item>
        <Button
          component={Link}
          to={allRoute.financeBankCode.encodePath({ search: {}, param: {} })}
          className={commonClasses.purpleGradualOutlineButton}
        >
          <Box paddingY={0.5}>
            {t('common.thirdPartyCode')}
          </Box>
        </Button>
      </Grid>) }
    </Grid>
  )
}

export default React.memo(FinanceBankToolbar)

import React, { useMemo, PropsWithChildren } from 'react'
import { useTheme } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import { useWindowSize } from 'react-use'
import CustomThemeType from '../../../themes/default/CustomThemeType'
import useDrawer from '../../../providers/default/drawer/useDrawer'

interface PropTypes {
  marginX?: number
}

const ScrollablePaper: React.FC<PropsWithChildren<PropTypes>> = (props) => {
  const { marginX = 0, children } = props
  const { width: windowWidth } = useWindowSize()
  const theme: CustomThemeType = useTheme()
  const drawer = useDrawer()
  const style = useMemo(() => {
    const drawerWidth = drawer.open ? theme.custom.shape.drawerWidth : theme.custom.shape.closeDrawerWidth
    const paperWidth = windowWidth - Number(drawerWidth) - (theme.spacing(marginX) * 2)
    return { width: paperWidth, overflow: 'auto', marginLeft: theme.spacing(marginX), marginRight: theme.spacing(marginX) }
  }, [drawer, theme, marginX, windowWidth])

  return (
    <Paper style={style}>
      {children}
    </Paper>
  )
}

export default React.memo(ScrollablePaper)

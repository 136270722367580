import React, { createContext, Dispatch, SetStateAction } from 'react'
import Box from '@material-ui/core/Box'
import StateProvider from '../../../providers/default/StateProvider'
import ScrollablePaper from '../../../components/default/present/ScrollablePaper'
import InternalWithdrawAccountToolbar from '../../../components/admin/finance/InternalWithdrawAccountToolbar'
import InternalWithdrawAccountTable from '../../../components/admin/finance/InternalWithdrawAccountTable'

export interface RequestType {
  bank: string
}

const defaultRequest: RequestType = { bank: '' }

export const RequestContext = createContext<[RequestType, Dispatch<SetStateAction<RequestType>>]>([
  defaultRequest,
  () => {}
])

const FinanceInternalWithdrawAccountPage: React.FC = () => {
  return (
    <Box paddingY={5}>
      <ScrollablePaper marginX={5}>
        <StateProvider
          context={RequestContext}
          defaultValue={defaultRequest}
        >
          <Box padding={4}>
            <InternalWithdrawAccountToolbar />
          </Box>
          <Box paddingTop={1} paddingBottom={5} paddingX={4}>
            <InternalWithdrawAccountTable />
          </Box>
        </StateProvider>
      </ScrollablePaper>
    </Box>
  )
}

export default React.memo(FinanceInternalWithdrawAccountPage)

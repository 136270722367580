import { AnnouncementStatusType } from '@golden/gdk-admin'
import TKeyType from '../../i18ns/admin/TKeyType'

const announcementStatusName: { [key in AnnouncementStatusType]: TKeyType } = {
  [AnnouncementStatusType.NOT_START]: 'announcementStatus.notStart',
  [AnnouncementStatusType.START]: 'announcementStatus.start',
  [AnnouncementStatusType.STOP]: 'announcementStatus.stop',
  [AnnouncementStatusType.OUT_DATE]: 'announcementStatus.outDate'
}

export default announcementStatusName

import React, { createContext, Dispatch, SetStateAction, useCallback, useState } from 'react'
import clsx from 'clsx'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import StateProvider from '../../../providers/default/StateProvider'
import useT from '../../../i18ns/admin/useT'
import { useRedirectHandleBack, useDialogHandleSubmit } from '../../../utils/default/ComplexFlowHook'
import { Path } from '../../../components/admin/route/route'
import { useDialogStyles } from '../../../utils/admin/StyleHook'
import useGDK from '../../../providers/admin/gdk/useGDK'
import useGlobalDialog from '../../../providers/admin/dialog/useGlobalDialog'
import { createGlobalDialogConfig } from '../../../utils/default/DialogHelper'
import PlayerSearchForm from '../../../components/admin/manual/PlayerSearchForm'
import ManualDepositCreateForm, { formToRequest, FormType } from '../../../components/admin/manual/ManualDepositCreateForm'
import ManualDepositFileCreateForm from '../../../components/admin/manual/ManualDepositFileCreateForm'
import manualDepositName from '../../../constants/admin/manualDepositName'
import { formatMoney } from '../../../utils/default/TableHelper'
import { ManualDepositType } from '@golden/gdk-admin'

export interface ContextType { account: string, file: File, isBatch: boolean }

const defaultContext = { account: '', file: new File([], ''), isBatch: false }

export const RequestContext = createContext<[
  ContextType,
  Dispatch<SetStateAction<ContextType>>
]>([defaultContext, () => {}])

const ManualDepositCreatePage: React.FC = () => {
  const dialogClasses = useDialogStyles()
  const [handleBack, handleDebouncedBack] = useRedirectHandleBack({
    path: Path.MANUAL_DEPSOT
  })
  const { t } = useT()
  const gdk = useGDK()
  const [loading, setLoading] = useState<boolean>(false)
  const globalDialog = useGlobalDialog()
  const { handleSubmit } = useDialogHandleSubmit({
    dialogId: 'createManualDeposit',
    globalDialog,
    getChangeDialogConfig: useCallback((form: FormType) => {
      return createGlobalDialogConfig({
        showIcon: false,
        notUseTypo: true,
        message: (
          <Box display="flex" flexDirection="column">
            <Box marginBottom={2}>
              <Typography className={dialogClasses.title} align="center">{t('dialog.confirmCreate')}</Typography>
            </Box>
            <Typography className={clsx(dialogClasses.text)} align="left">
              {t('common.cash')}: {formatMoney(form.cash)}
            </Typography>
            <Typography className={clsx(dialogClasses.text)} align="left">
              {t('common.depositObject')}: {t(manualDepositName[form.type])}
            </Typography>
            <Typography className={clsx(dialogClasses.text)} align="left">
              {t('common.memo')}: {form.memo}
            </Typography>
            {
              form.type === ManualDepositType.EVENT && (
                <Typography className={clsx(dialogClasses.text)} align="left">
                  {t('common.exchangedDepositOrderNo')}: {form.bindingDeposits || '-'}
                </Typography>
              )
            }
          </Box>
        )
      })
    }, [t, dialogClasses]),
    getSuccessDialogConfig: useCallback(() => createGlobalDialogConfig({
      showIcon: false,
      showCancel: false,
      message: t('dialog.createManualDepositSuccess')
    }), [t]),
    getFailDialogConfig: useCallback((error) => createGlobalDialogConfig({
      showIcon: true,
      variant: 'error',
      showCancel: false,
      message: error
    }), []),
    formToRequest,
    gdkFunc: (payload) => {
      setLoading(true)
      return gdk.manual.createDeposit(payload)
    },
    onSuccess: () => {
      setLoading(false)
    },
    onError: () => {
      setLoading(false)
    },
    gdkFuncDependencies: [gdk],
    afterSuccessDialog: handleBack
  })
  return (
    <StateProvider context={RequestContext} defaultValue={defaultContext}>
      <Box padding={5}>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <PlayerSearchForm context={RequestContext} loading={loading}/>
          </Grid>
          <Grid item>
            <ManualDepositCreateForm
              handleSubmit={handleSubmit}
              handleCancel={handleDebouncedBack}
              loading={loading}
            />
            <ManualDepositFileCreateForm
              loading={loading}
              setLoading={setLoading}
              handleBack={handleDebouncedBack}
            />
          </Grid>
        </Grid>
      </Box>
    </StateProvider>
  )
}

export default React.memo(ManualDepositCreatePage)

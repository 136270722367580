import React, { createContext, useMemo, useContext, useCallback, useState } from 'react'
import { useCommonStyles, useDialogStyles } from '../../../utils/admin/StyleHook'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import { TextFieldProps } from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import useT from '../../../i18ns/admin/useT'
import { ManualDepositCsvPreviewRes, ManualDepositType } from '@golden/gdk-admin'
import { BcMath } from '@golden/bcmath'
import { InitialFormFunc, useDialogHandleSubmit } from '../../../utils/default/ComplexFlowHook'
import { ValueGetter, createDefaultFormState, FormValidation, ChangedFormGetter } from '../../../utils/default/FormHook'
import { getValueFromChangeEvent, getValueFromCheckboxEvent, getCodeInputProps } from '../../../utils/default/FormHelper'
import { RequestContext } from '../../../views/admin/manual/ManualDepositCreatePage'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { usePageFlow } from '../../../utils/default/PageFlowHook'
import LoadingAndErrorFrame from '../../default/frames/LoadingAndErrorFrame'
import FormStateProvider from '../../default/form/FormStateProvider'
import { createCorrectResult, createErrorResult, createValidateNotEmpty } from '../../../utils/default/Validator'
import FormField from '../../default/form/FormField'
import NumberInput from '../../default/form/NumberInput'
import DropDown, { PropTypes as DropDownProps } from '../../default/form/DropDown'
import manualDepositName from '../../../constants/admin/manualDepositName'
import RequiredText from '../../default/form/RequiredText'
import FormSubmitButton from '../../default/form/FormSubmitButton'
import useGlobalDialog from '../../../providers/admin/dialog/useGlobalDialog'
import { createGlobalDialogConfig } from '../../../utils/default/DialogHelper'
import ManualDepositFilePreviewTable from './ManualDepositFilePreviewTable'
import { formatMoney } from '../../../utils/default/TableHelper'

interface RequestType { file: File, depositType: ManualDepositType, code: string, multiple: string, isExcludingSpecifiedGames: boolean }

export interface FormType {
  file: File
  depositType: ManualDepositType
  code: string
  isExcludingSpecifiedGames: boolean
  multiple: string
}

const initialForm: InitialFormFunc<FormType> = (defaultForm) => ({
  file: new File([], ''),
  depositType: ManualDepositType.EVENT,
  code: '',
  isExcludingSpecifiedGames: true,
  multiple: '3',
  ...defaultForm
})

export const formToRequest = (form: FormType): RequestType => {
  return form
}

const getValueFromEvent: ValueGetter<FormType> = {
  file: getValueFromChangeEvent,
  depositType: getValueFromChangeEvent,
  code: getValueFromChangeEvent,
  isExcludingSpecifiedGames: getValueFromCheckboxEvent,
  multiple: getValueFromChangeEvent
}

const getChangedForm: ChangedFormGetter<FormType> = {
  file: (value, form) => ({
    ...initialForm(),
    depositType: form.depositType,
    isExcludingSpecifiedGames: form.isExcludingSpecifiedGames,
    multiple: form.multiple,
    file: value
  }),
  depositType: (value, form) => ({
    ...form,
    depositType: value,
    multiple: (value === ManualDepositType.EVENT) ? '3' : '1',
    isExcludingSpecifiedGames: (value === ManualDepositType.EVENT)
  })
}

export const FormContext = createContext(createDefaultFormState(initialForm()))

const codeInputProps = getCodeInputProps()

interface PropTypes {
  loading: boolean
  setLoading: (value: boolean) => void
  handleBack: () => void
}

const ManualDepositFileCreateForm: React.FC<PropTypes> = (props) => {
  const { loading, setLoading, handleBack } = props
  const globalDialog = useGlobalDialog()
  const classes = useCommonStyles()
  const dialogClasses = useDialogStyles()
  const { t } = useT()
  const [context] = useContext(RequestContext)
  const defaultForm = useMemo(() => initialForm({ file: context.file }), [context.file])
  const pageFlow = usePageFlow()
  const gdk = useGDK()
  const validation = useMemo(() => {
    return {
      file: [
        {
          func: createValidateNotEmpty('file', t),
          when: ['change', 'beforeClickSubmit']
        }
      ],
      depositType: [
        {
          func: createValidateNotEmpty('depositType', t),
          when: ['change', 'beforeClickSubmit']
        }
      ],
      code: [
        {
          func: createValidateNotEmpty('code', t),
          when: ['change', 'beforeClickSubmit']
        }
      ],
      isExcludingSpecifiedGames: [],
      multiple: [
        {
          func: createValidateNotEmpty('multiple', t),
          when: ['change', 'beforeClickSubmit']
        },
        {
          func: (value) => {
            if (Number(value) === 0) return createErrorResult('multiple', t('error.mustNotZero'))
            if (!Number.isInteger(Number(value))) return createErrorResult('multiple', t('error.mustBeInt'))
            return createCorrectResult('multiple')
          },
          when: ['change', 'beforeClickSubmit']
        }
      ]
    } as FormValidation<FormType>
  }, [t])
  const options = useMemo(() => {
    return Object.keys(manualDepositName)
      .map((key) => ({ name: t(manualDepositName[key as ManualDepositType]), value: key }))
  }, [t])

  const [res, setRes] = useState<ManualDepositCsvPreviewRes>({
    account_duplicated: 0,
    debit_card_unbound: 0,
    warning_amount: 0,
    not_activity: 0,
    data: []
  })

  const { handleSubmit } = useDialogHandleSubmit({
    dialogId: 'createManualFileDeposit',
    globalDialog,
    getChangeDialogConfig: useCallback(() => {
      return createGlobalDialogConfig({
        showIcon: false,
        notUseTypo: true,
        message: (
          <React.Fragment>
            <Typography
              className={dialogClasses.text}
              align="center"
            >
              {t('dialog.confirmUploadManualDeposit', {
                total: res.data.length,
                cash: formatMoney(res.data.reduce((pre, cur) => BcMath.base(pre).add(cur.deposit_amount).get(), '0'))
              })}
            </Typography>
          </React.Fragment>
        )
      })
    }, [t, dialogClasses, res]),
    getSuccessDialogConfig: useCallback(() => createGlobalDialogConfig({
      showIcon: false,
      showCancel: false,
      message: t('dialog.createManualDepositSuccess')
    }), [t]),
    onSuccess: () => {
      setLoading(false)
    },
    getFailDialogConfig: useCallback((_, error) => createGlobalDialogConfig({
      showIcon: true,
      variant: 'error',
      showCancel: false,
      message: error.message
    }), []),
    onError: () => {
      setLoading(false)
    },
    formToRequest,
    gdkFunc: (payload) => {
      setLoading(true)
      return gdk.manual.createDepositByCsv(payload)
    },
    gdkFuncDependencies: [gdk],
    afterSuccessDialog: handleBack
  })
  if (!context.file.size || !context.isBatch) return null
  return (
    <FormStateProvider
      context={FormContext}
      defaultValue={defaultForm}
      onSubmit={handleSubmit}
      validation={validation}
      getValueFromEvent={getValueFromEvent}
      getChangedForm={getChangedForm}
    >
      <Paper>
        <Box padding={5}>
          <LoadingAndErrorFrame {...pageFlow.status}>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <ManualDepositFilePreviewTable
                  res={res}
                  setRes={setRes}
                  pageFlow={pageFlow}
                />
              </Grid>
              <Grid item>
                <Grid container direction="row" spacing={2}>
                  <Grid item xs={12} md={6} lg={2}>
                    <FormField<FormType, DropDownProps>
                      context={FormContext}
                      component={DropDown}
                      name="depositType"
                      label={t('common.depositObject')}
                      options={options}
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={1}>
                    <FormField<FormType, TextFieldProps>
                      context={FormContext}
                      component={NumberInput}
                      name="multiple"
                      label={`${t('common.deltaEffectiveCash')}${t('common.multiple')}`}
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={3}>
                    <FormField<FormType, TextFieldProps>
                      context={FormContext}
                      component={NumberInput}
                      name="code"
                      fullWidth
                      required
                      inputProps={codeInputProps}
                      label={t('common.googleCode')}
                      placeholder={t('placeholder.inputGoogleCode2')}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <RequiredText />
                <Typography color="error">{ t('common.memoDisplayAtForestage') }</Typography>
              </Grid>
            </Grid>
          </LoadingAndErrorFrame>
        </Box>
      </Paper>
      <Box paddingTop={2}>
        <Grid container direction="row" justify="flex-end" spacing={2}>
          <Grid item>
            <Button
              onClick={handleBack}
              className={classes.greyButton}
            >
              {t('common.cancel')}
            </Button>
          </Grid>
          <Grid item>
            <FormSubmitButton
              context={FormContext}
              component={Button}
              disabled={loading}
              type="submit"
              className={classes.purpleGradualButton}
            >
              {loading
                ? (
                <Box display="flex" alignItems="center" justifyContent="center">
                  <CircularProgress size={24} />
                </Box>
                  )
                : t('common.confirmCreate')}
            </FormSubmitButton>
          </Grid>
        </Grid>
      </Box>
    </FormStateProvider>
  )
}

export default React.memo(ManualDepositFileCreateForm)

import React, { useEffect } from 'react'
import Box from '@material-ui/core/Box'
import AdminReportPlatformFeeForm from '../../../components/admin/adminReport/AdminReportPlatformFeeForm'
import AdminReportPlatformFeeTable from '../../../components/admin/adminReport/AdminReportPlatformFeeTable'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { Branch } from '@golden/gdk-admin'
import { useReload } from '../../../utils/default/ComplexFlowHook'

const AdminReportPlatformFeePage: React.FC = () => {
  const gdk = useGDK()
  const { reload, reloadFlag } = useReload()
  useEffect(() => {
    gdk.trunk.trigger([Branch.PLATFORMS])
  }, [gdk])
  return (
    <Box paddingY={5}>
      <Box paddingX={5}>
        <AdminReportPlatformFeeForm reload={reload} />
      </Box>
      <Box paddingTop={3}>
        <AdminReportPlatformFeeTable reloadFlag={reloadFlag} />
      </Box>
    </Box>
  )
}

export default React.memo(AdminReportPlatformFeePage)

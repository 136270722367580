import React, { useState, useContext, useMemo, Dispatch, SetStateAction } from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import { Link } from 'react-router-dom'
import CachedIcon from '@material-ui/icons/Cached'
import LockIcon from '@material-ui/icons/Lock'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import { useCommonStyles, useDetailStyles } from '../../../utils/admin/StyleHook'
import useT from '../../../i18ns/admin/useT'
import { useGetDataByPayload, useReload } from '../../../utils/default/ComplexFlowHook'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { usePageFlow } from '../../../utils/default/PageFlowHook'
import { WalletType } from '@golden/gdk-admin'
import { BcMath } from '@golden/bcmath'
import allRoute from '../route/route'
import PointsCell from '../../default/present/PointsCell'
import { InfoContext } from '../../../views/admin/player/PlayerDetailPage'
import ScrollablePaper from '../../default/present/ScrollablePaper'
import { getWalletNameWithStatusByWalletId } from '../../../utils/default/PlatformHelper'
import useGDKStore from '../../../providers/admin/gdk/useGDKStore'
import { map } from 'rxjs'
import LoadingAndErrorFrame from '../../../components/default/frames/LoadingAndErrorFrame'
import { chunk } from '@golden/utils'

const useStyles = makeStyles((theme) => ({
  cell: {
    backgroundColor: theme.palette.common.white,
    textAlign: 'right',
    padding: theme.spacing(2),
    width: '25%'
  },
  lock: {
    width: 12,
    height: 12
  }
}))

interface PropTypes {
  id: number
  section: string
  loaded: Dispatch<SetStateAction<string>>
}

interface WalletData { id: number, status: 'fulfilled' | 'rejected', balance: string, isLocked: boolean }

const MoneyText: React.FC<{
  res: WalletData
}> = React.memo((props) => {
  const { res } = props
  const { t } = useT()

  if (!res || res.status === 'rejected') return (<Typography color="error">{t('common.cantGetWalletBalance')}</Typography>)
  return (
    <PointsCell points={res.balance} />
  )
})

const PlayerDetailWalletSection: React.FC<PropTypes> = (props) => {
  const { section, loaded } = props
  const gdk = useGDK()
  const pageFlow = usePageFlow()
  const [info] = useContext(InfoContext)
  const { reload, reloadFlag } = useReload()
  const classes = useStyles()
  const commonClasses = useCommonStyles()
  const detailClasses = useDetailStyles()
  const { t } = useT()
  const platforms = useGDKStore.platform.platforms()
  const length = 4
  const page = 1
  const account = info.account

  const [wallets, setWallets] = useState<WalletData[]>([])

  const filterWallets = useMemo(() =>
    wallets
      .filter((r) => BcMath.base(r.balance || '0').comp('0') !== 0 || r.id === WalletType.CENTER)
      .sort((r1, r2) => {
        if (r1.id === WalletType.CENTER) return -1
        if (r2.id === WalletType.CENTER) return 1
        return r1.id - r2.id
      }), [wallets])

  const sumText = useMemo(() =>
    wallets.reduce((bignumber, item) => {
      return bignumber.add(item.balance)
    }, BcMath.base(0)).toFixed(4).get(), [wallets])

  const payload = useMemo(() => ({ account: info.account, platforms }), [info, platforms])

  useGetDataByPayload({
    payload,
    gdkFunc: (payload) => gdk.withdraw.getWallets(payload.account, payload.platforms.map((p) => p.wallet_id)).pipe(
      map((data) => data.map((el) => ({ ...el, status: el.state, balance: el.cash ?? '0', isLocked: el.is_locked } as WalletData)))
    ),
    gdkFuncDependencies: [gdk, reloadFlag],
    onBeforeFetch: pageFlow.setLoadingStart,
    onSuccess: (res: WalletData[]) => {
      setWallets(res)
      pageFlow.setContentShow()
      loaded(section)
    },
    onError: pageFlow.setGDKError,
    canLoadData: account !== ''
  })

  return (
    <div id={section}>
      <ScrollablePaper marginX={5}>
        <Box padding={4}>
          <Box
            paddingY={1.25}
            paddingX={2}
            className={commonClasses.pinkTitleBar}
            display="flex"
            alignItems="center"
          >
            <Typography variant="h5">
              {t('common.platformWalletStatistic')}
            </Typography>
            <Box marginLeft={1}>
              <IconButton size="small" color="inherit" onClick={reload}>
                <CachedIcon />
              </IconButton>
            </Box>
          </Box>
          <LoadingAndErrorFrame {...pageFlow.status}>
            <Box paddingY={2}>
              <Box paddingY={1}>
                <Grid container direction="row" justifyContent="space-between">
                  <Grid item>
                    <Typography component="span">{t('common.totalAmountWithColon')}</Typography>
                    <PointsCell points={sumText} />
                  </Grid>
                </Grid>
              </Box>
              <Box overflow="auto">
                <table className={detailClasses.table}>
                  <tbody>
                    {
                      chunk(filterWallets, length)
                        .map((items, index) => {
                          return (
                            <tr key={index}>
                              {
                                items
                                  .concat((new Array(length)).fill(-1))
                                  .slice(0, length)
                                  .map((item, index2) => (
                                    <Tooltip disableHoverListener={!item.isLocked} title={t('common.walletLockedTip')}>
                                      <td key={`${index}_${index2}`} className={clsx(detailClasses.cell, classes.cell)}>
                                        {item as unknown as number === -1
                                          ? null
                                          : (
                                              <Grid container direction="row" justifyContent="space-between" wrap="nowrap">
                                                <Grid item>
                                                  <Grid container direction="row" alignItems="center">
                                                    {getWalletNameWithStatusByWalletId(item.id, platforms)}{item.id === WalletType.CENTER ? '：' : ''}
                                                    {item.isLocked ? (<LockIcon className={classes.lock} />) : null}
                                                  </Grid>
                                                </Grid>
                                                <Grid item>
                                                  <MoneyText res={item} />
                                                </Grid>
                                              </Grid>
                                            )}
                                      </td>
                                    </Tooltip>
                                  ))
                              }
                            </tr>
                          )
                        })
                    }
                  </tbody>
                </table>
              </Box>
            </Box>
          </LoadingAndErrorFrame>
          <Box display="flex" justifyContent="flex-end">
            <Button
              component={Link}
              className={commonClasses.purpleGradualButton}
              to={allRoute.playerReportTransfer.encodePath({ param: {}, search: { account, page, golden_wallet: WalletType.CENTER } })}
            >
              {t('adminPermission.transferRecord')}
            </Button>
          </Box>
        </Box>
      </ScrollablePaper>
    </div>
  )
}

export default React.memo(PlayerDetailWalletSection)

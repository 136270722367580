import React, {
  createContext,
  useState,
  Dispatch,
  SetStateAction
} from 'react'

export interface DrawerType {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  itemSelected: string
  setItemSelected: Dispatch<SetStateAction<string>>
  itemClicked: string
  setItemClicked: Dispatch<SetStateAction<string>>
  subItemSelected: string
  setSubItemSelected: Dispatch<SetStateAction<string>>
}

export const DrawerContext = createContext<DrawerType>({
  open: true,
  setOpen: () => {},
  itemSelected: '',
  setItemSelected: () => {},
  itemClicked: '',
  setItemClicked: () => {},
  subItemSelected: '',
  setSubItemSelected: () => {}
})

const DrawerProvider: React.FC = (props) => {
  const { children } = props
  const [open, setOpen] = useState<boolean>(true)
  const [itemSelected, setItemSelected] = useState<string>('')
  const [itemClicked, setItemClicked] = useState<string>('')
  const [subItemSelected, setSubItemSelected] = useState<string>('')

  return (
    <DrawerContext.Provider
      value={{
        open,
        setOpen,
        itemSelected,
        setItemSelected,
        itemClicked,
        setItemClicked,
        subItemSelected,
        setSubItemSelected
      }}
    >
      {children}
    </DrawerContext.Provider>
  )
}

export default DrawerProvider

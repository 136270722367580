import React, { useState, useCallback, useRef, useEffect, useMemo } from 'react'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import NumberInput from '../../default/form/NumberInput'
import useT from '../../../i18ns/admin/useT'
import { useInternValue } from '../../../utils/default/FormHook'
import { useDialogStyles } from '../../../utils/admin/StyleHook'
import CoreDialog from '../../default/dialogs/CoreDialog'
import useGlobalDialog from '../../../providers/admin/dialog/useGlobalDialog'
import { createGlobalDialogConfig } from '../../../utils/default/DialogHelper'
import PlayerInputButton from './PlayerInputButton'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { getCodeInputProps } from '../../../utils/default/FormHelper'
import { useChecker } from '../../../utils/admin/AdminRouteHook'
import { CountryCallingCode, PermissionType } from '@golden/gdk-admin'
import { Grid } from '@material-ui/core'
import IddCodeDropDown from '../IddCodeDropDown'

const codeInputProps = getCodeInputProps()

interface PropTypes {
  id: number
  iddCode: CountryCallingCode
  phone: string
  reload: () => void
}

const PlayerPhoneInput: React.FC<PropTypes> = (props) => {
  const { id, iddCode, phone, reload } = props
  const gdk = useGDK()
  const globalDialog = useGlobalDialog()
  const writable = useChecker([PermissionType.PLAYER_MANAGEMENT_INFO_PHONE])
  const [value, setValue] = useInternValue<string>(phone, phone)
  const [iddCodeInput, setIddCodeInput] = useInternValue<CountryCallingCode>(iddCode, iddCode)
  const [disabled, setDisabled] = useState<boolean>(true)
  const [open, setOpen] = useState<boolean>(false)
  const [code, setCode] = useState<string>('')
  const [isValid, setIsValid] = useState<boolean>(true)
  const dialogClasses = useDialogStyles()
  const { t } = useT()
  const ref = useRef<HTMLInputElement | null>(null)
  const handleUpdate = useCallback(() => setDisabled(false), [])
  const handleValidate = useCallback(() => {
    gdk.player.updatePlayerPhone(id, iddCodeInput, value).subscribe({
      next: () => {
        setOpen(true)
      },
      error: (error) => {
        globalDialog.setConfig(createGlobalDialogConfig({
          showIcon: true,
          showCancel: false,
          variant: 'error',
          message: error.message
        }))
        globalDialog.setOpen({
          id: 'setPhoneFail',
          value: true,
          isOK: false
        })
      }
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gdk, iddCodeInput, id, value])
  const handleValidateChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => setCode(event.target.value), [])
  const handleCancel = useCallback(() => {
    setCode('')
    setOpen(false)
  }, [])
  const handleSendValidate = useCallback(() => {
    setCode('')
    gdk.player.validatePlayerPhone(id, iddCodeInput, value, code).subscribe({
      next: () => {
        globalDialog.setConfig(createGlobalDialogConfig({
          showIcon: false,
          message: t('dialog.validatePhoneSuccess'),
          showCancel: false
        }))
        globalDialog.setOpen({
          id: 'validatePhoneSuccess',
          value: true,
          isOK: false
        })
        setDisabled(true)
        setOpen(false)
        reload()
      },
      error: (error) => {
        globalDialog.setConfig(createGlobalDialogConfig({
          showIcon: true,
          variant: 'error',
          showCancel: false,
          message: error.message
        }))
        globalDialog.setOpen({
          id: 'validatePhoneFail',
          value: true,
          isOK: false
        })
      }
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gdk, iddCodeInput, id, value, code])
  const isEmpty = useMemo(() => !value && disabled, [value, disabled])
  useEffect(() => {
    if (!disabled) ref.current?.focus()
  }, [disabled])
  useEffect(() => {
    switch (iddCodeInput) {
      case CountryCallingCode.HK:
      case CountryCallingCode.MO:
        setIsValid(/^[0-9]{8}$/.test(value))
        break
      case CountryCallingCode.MM:
        setIsValid(/^[0-9]{8,10}$/.test(value))
        break
      case CountryCallingCode.CAMBODIA:
        setIsValid(/^(\d{8,9})$/.test(value))
        break
      case CountryCallingCode.DUBAI:
        setIsValid(/^\d{9}$/.test(value))
        break
      case CountryCallingCode.CN:
      default:
        setIsValid(/^[1]([3-9])[0-9]{9}$/.test(value))
    }
  }, [iddCodeInput, value])
  return (
    <React.Fragment>
      <Grid item xs={3}>
        <IddCodeDropDown
          label={t('common.countryCallingCode')}
          value={iddCodeInput}
          onChange={useCallback((event) => {
            setIddCodeInput(event.target.value as CountryCallingCode)
          }, [setIddCodeInput])}
          HideRegionNameInValue
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={9}>
        <NumberInput
          label={t('common.cellphone')}
          InputProps={{
            inputProps: {
              type: isEmpty ? 'text' : 'tel',
              allowLeadingZeros: true,
              decimalScale: 0,
              allowNegative: isEmpty,
              fixedDecimalScale: !isEmpty
            },
            inputRef: ref,
            endAdornment: writable && (
              <PlayerInputButton
                title={disabled ? t('common.update') : t('common.validate')}
                onClick={disabled ? handleUpdate : handleValidate}
              />
            )
          }}
          FormHelperTextProps={{ error: !disabled }}
          helperText={disabled ? '' : (isValid ? t('common.playerValidatePhoneTip') : t('common.playerPhoneInvalidTip'))}
          value={isEmpty ? '-' : value}
          onChange={useCallback((event) => {
            setValue(event.target.value)
          // eslint-disable-next-line react-hooks/exhaustive-deps
          }, [])}
          disabled={disabled}
          fullWidth
        />
      </Grid>
      <CoreDialog
        open={open}
        notUseTypo
        message={(
          <React.Fragment>
            <Typography className={dialogClasses.text}>{t('dialog.validatePhone')}</Typography>
            <Box marginTop={3} width={400}>
              <NumberInput
                label={t('common.validateCode')}
                placeholder={t('placeholder.inputValidateCode')}
                helperText={t('helperText.inputValidateCode')}
                inputProps={codeInputProps}
                value={code}
                onChange={handleValidateChange}
                fullWidth
              />
            </Box>
          </React.Fragment>
        )}
        showOK
        showCancel
        disabledOK={code.length !== 6}
        onOK={handleSendValidate}
        onCancel={handleCancel}
        okButtonClasses={{ root: dialogClasses.okButton }}
        cancelButtonClasses={{ root: dialogClasses.cancelButton }}
      />
    </React.Fragment>
  )
}

export default React.memo(PlayerPhoneInput)

import React, { createContext, useMemo, useCallback, useEffect, useState } from 'react'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import TextField, { TextFieldProps } from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import Tooltip from '@material-ui/core/Tooltip'
import LockIcon from '@material-ui/icons/Lock'
import FormStateProvider from '../../../components/default/form/FormStateProvider'
import { WalletTransferForm, Branch, PlayerWallet, WalletType } from '@golden/gdk-admin'
import { BcMath } from '@golden/bcmath'
import { InitialFormFunc, useDialogHandleSubmit, useRedirectHandleBack, useGetDataByPayload } from '../../../utils/default/ComplexFlowHook'
import { ValueGetter, createDefaultFormState, FormValidation } from '../../../utils/default/FormHook'
import { getValueFromChangeEvent, getCashInputProps } from '../../../utils/default/FormHelper'
import { parsePath } from '../../../utils/default/RouteHelper'
import { useLocation } from 'react-router'
import allRoute, { Path } from '../../../components/admin/route/route'
import useGlobalDialog from '../../../providers/admin/dialog/useGlobalDialog'
import useGDK from '../../../providers/admin/gdk/useGDK'
import useT from '../../../i18ns/admin/useT'
import { createGlobalDialogConfig } from '../../../utils/default/DialogHelper'
import FormField from '../../../components/default/form/FormField'
import { useCommonStyles, useDetailStyles } from '../../../utils/admin/StyleHook'
import RequiredText from '../../../components/default/form/RequiredText'
import FormSubmitButton from '../../../components/default/form/FormSubmitButton'
import NumberInput from '../../../components/default/form/NumberInput'
import DropDown, { PropTypes as DropDownProps } from '../../../components/default/form/DropDown'
import { createValidateNotEmpty, createValidateCash } from '../../../utils/default/Validator'
import useGDKStore from '../../../providers/admin/gdk/useGDKStore'
import { StorePlatform, getWalletNameWithStatusByWalletId } from '../../../utils/default/PlatformHelper'
import { PageFlowType, usePageFlow } from '../../../utils/default/PageFlowHook'
import LoadingAndErrorFrame from '../../../components/default/frames/LoadingAndErrorFrame'
import clsx from 'clsx'
import PointsCell from '../../../components/default/present/PointsCell'
import { makeStyles } from '@material-ui/core'
import { ArrowRight } from '@material-ui/icons'
import { mergeArray, chunk } from '@golden/utils'

const cashInputProps = getCashInputProps()

export interface FormType {
  from: WalletType
  to: WalletType
  cash: string
}

export const initialForm: InitialFormFunc<FormType> = (defaultForm) => ({
  from: WalletType.PM,
  to: WalletType.CENTER,
  cash: '',
  ...defaultForm
})

const getValueFromEvent: ValueGetter<FormType> = {
  from: getValueFromChangeEvent,
  to: getValueFromChangeEvent,
  cash: getValueFromChangeEvent
}

export const formToRequest = (account: string) => (form: FormType): WalletTransferForm => ({
  account,
  from: form.from,
  to: form.to,
  cash: form.cash
})

const FormContext = createContext(createDefaultFormState(initialForm()))

const useStyles = makeStyles((theme) => ({
  cell: {
    backgroundColor: theme.palette.common.white,
    textAlign: 'right',
    padding: theme.spacing(2),
    width: '25%'
  },
  lock: {
    width: 12,
    height: 12
  }
}))

const MoneyText: React.FC<{
  res: Omit<PlayerWallet, 'id'>
}> = React.memo((props) => {
  const { res } = props
  const { t } = useT()

  if (!res || res.state === 'rejected') return (<Typography color="error">{t('common.cantGetWalletBalance')}</Typography>)
  return (
    <PointsCell points={res.cash ?? '0.0000'} />
  )
})

const PlayerReportTransferInfo: React.FC<{
  account: string
  pageFlow: PageFlowType
  filterWallets: Array<StorePlatform & Omit<PlayerWallet, 'id'>>
  setRes: (res: PlayerWallet[]) => void
}> = (props) => {
  const { account, pageFlow, filterWallets, setRes } = props
  const { t } = useT()
  const gdk = useGDK()
  const platforms = useGDKStore.platform.platforms()
  const classes = useStyles()
  const detailClasses = useDetailStyles()
  const length = 4

  const sumText = useMemo(() =>
    filterWallets
      .filter((w) => w.state === 'fulfilled')
      .reduce((sum, w) => sum.add(w.cash ?? '0.0000'), BcMath.base(0))
      .goldenFormat(), [filterWallets])
  const payload = useMemo(() => ({ account, platforms }), [account, platforms])
  useGetDataByPayload({
    payload,
    gdkFunc: (payload) => gdk.withdraw.getWallets(payload.account, payload.platforms.map((p) => p.wallet_id)),
    gdkFuncDependencies: [gdk],
    onBeforeFetch: pageFlow.setLoadingStart,
    onSuccess: (res: PlayerWallet[]) => {
      setRes(res)
      pageFlow.setContentShow()
    },
    onError: pageFlow.setGDKError
  })

  return (
    <Box paddingX={16} borderBottom={1}>
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <TextField
            label={t('common.playerAccount')}
            value={account}
            disabled
          />
        </Grid>
        <Grid item>
          <TextField
            label={t('common.totalAmount')}
            value={sumText}
            disabled
          />
        </Grid>
        <Grid item>
          <Box overflow="auto">
            <table className={detailClasses.table}>
              <tbody>
                {
                  chunk(filterWallets, length)
                    .map((items, index) => {
                      return (
                        <tr key={index}>
                          {
                            items
                              .concat((new Array(length)).fill(-1))
                              .slice(0, length)
                              .map((item, index2) => (
                                <Tooltip disableHoverListener={!item.is_locked} title={t('common.walletLockedTip')}>
                                  <td key={`${index}_${index2}`} className={clsx(detailClasses.cell, classes.cell)}>
                                    {item as unknown as number === -1
                                      ? null
                                      : (
                                          <Grid container direction="row" justifyContent="space-between" wrap="nowrap">
                                            <Grid item>
                                              <Grid container direction="row" alignItems="center">
                                                {item.nameWithStatus}{item.wallet_id === WalletType.CENTER ? '：' : ''}
                                                {item.is_locked ? (<LockIcon className={classes.lock} />) : null}
                                              </Grid>
                                            </Grid>
                                            <Grid item>
                                              <MoneyText res={item} />
                                            </Grid>
                                          </Grid>
                                        )}
                                  </td>
                                </Tooltip>
                              ))
                          }
                        </tr>
                      )
                    })
                }
              </tbody>
            </table>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

const PlayerReportTransferUpdatePage: React.FC = () => {
  const classes = useCommonStyles()
  const { t } = useT()
  const pageFlow = usePageFlow()
  const globalDialog = useGlobalDialog()
  const gdk = useGDK()
  const platforms = useGDKStore.platform.platforms()
  const location = useLocation()
  const param = useMemo(() => {
    return parsePath(location.search, location.pathname, Path.PLAYER_REPORT_TRANSFER_UPDATE).param as { account: string }
  }, [location])
  useEffect(() => {
    gdk.trunk.trigger([Branch.PLATFORMS])
  }, [gdk])

  const [res, setRes] = useState<PlayerWallet[]>([])
  const wallets = useMemo(() => {
    return mergeArray(platforms, res, (platform, item) => platform.wallet_id === item.id)
      .map(([platform, item]) => (({ ...platform, ...item, id: platform.id })))
  }, [platforms, res])

  const filterWallets = useMemo(() => wallets
    .filter((r) => BcMath.base(r.cash ?? '0').comp('0') !== 0 || r.wallet_id === WalletType.CENTER)
  , [wallets])

  const defaultForm = useMemo(() => initialForm({ from: filterWallets?.[1]?.wallet_id ?? WalletType.PM }), [filterWallets])

  const transferOutOptions = useMemo(() => {
    return wallets
      .filter((item) => (item.state === 'fulfilled' && BcMath.base(item.cash ?? '0').comp('0') !== 0) || item.wallet_id === WalletType.CENTER)
      .map((item) => ({ name: item.nameWithStatus, value: item.id }))
  }, [wallets])

  const transferInOptions = useMemo(() => {
    return wallets
      .filter((item) => item.state === 'fulfilled' || item.wallet_id === WalletType.CENTER)
      .map((item) => ({ name: item.nameWithStatus, value: item.id }))
  }, [wallets])

  const validation = useMemo(() => {
    return {
      from: [],
      to: [],
      cash: [
        {
          func: createValidateNotEmpty('cash', t),
          when: ['change', 'beforeClickSubmit']
        },
        {
          func: createValidateCash('cash', t),
          when: ['change', 'beforeClickSubmit']
        }
      ]
    } as FormValidation<FormType>
  }, [t])
  const [handleBack, handleDebouncedBack] = useRedirectHandleBack({
    path: allRoute.playerReportTransfer.encodePath({ search: { account: param.account, page: 1, golden_wallet: WalletType.CENTER }, param: {} })
  })
  const { handleSubmit } = useDialogHandleSubmit({
    globalDialog,
    dialogId: 'updateCashTransfer',
    formToRequest: formToRequest(param.account),
    gdkFunc: (form) => gdk.withdraw.transferWallet(form),
    gdkFuncDependencies: [gdk],
    getChangeDialogConfig: useCallback((form: FormType) => createGlobalDialogConfig({
      showIcon: false,
      message: `[${param.account}][${getWalletNameWithStatusByWalletId(form.from, platforms)}][${t('common.transferOut')}][${getWalletNameWithStatusByWalletId(form.to, platforms)}][${form.cash}]`
    }), [param.account, platforms, t]),
    getSuccessDialogConfig: useCallback(() => createGlobalDialogConfig({
      showIcon: false,
      message: t('dialog.transferUserCashSuccess'),
      showCancel: false
    }), [t]),
    getFailDialogConfig: useCallback((_, error) => createGlobalDialogConfig({
      showIcon: true,
      variant: 'error',
      message: error.message,
      showCancel: false
    }), []),
    afterSuccessDialog: handleBack
  })
  return (
    <Box padding={5}>
      <FormStateProvider
        context={FormContext}
        defaultValue={defaultForm}
        getValueFromEvent={getValueFromEvent}
        validation={validation}
        onSubmit={handleSubmit}
      >
        <Paper>
          <Box padding={4}>
            <Box
              marginBottom={2}
              paddingY={1.25}
              paddingX={2}
              className={classes.pinkTitleBar}
            >
              <Typography variant="h5">
                {t('common.transfer')}
              </Typography>
            </Box>
            <LoadingAndErrorFrame {...pageFlow.status}>
              <PlayerReportTransferInfo
                account={param.account}
                pageFlow={pageFlow}
                filterWallets={filterWallets}
                setRes={setRes}
              />
              <Box paddingX={16} paddingY={4}>
                <Grid container direction="column" spacing={2}>
                  <Grid item container direction="row" spacing={2}>
                    <Grid item xs={12} md={6} lg={3}>
                      <FormField<FormType, DropDownProps>
                        context={FormContext}
                        component={DropDown}
                        name="from"
                        label={t('common.outAccount')}
                        options={transferOutOptions}
                        fullWidth
                        required
                      />
                    </Grid>
                    <Grid item>
                      <ArrowRight />
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                      <FormField<FormType, DropDownProps>
                        context={FormContext}
                        component={DropDown}
                        name="to"
                        label={t('common.inAccount')}
                        options={transferInOptions}
                        fullWidth
                        required
                      />
                    </Grid>
                  </Grid>
                  <Grid item>
                    <FormField<FormType, TextFieldProps>
                      context={FormContext}
                      component={NumberInput}
                      name="cash"
                      label={t('common.transferPointAmount')}
                      placeholder={t('placeholder.inputTransferCash')}
                      inputProps={cashInputProps}
                      required
                    />
                  </Grid>
                </Grid>
              </Box>
            </LoadingAndErrorFrame>
            <Box paddingTop={2}>
              <RequiredText />
            </Box>
          </Box>
        </Paper>
        <Box marginTop={2}>
          <Grid container justifyContent="flex-end" direction="row" spacing={2}>
            <Grid item>
              <Button className={classes.greyButton} onClick={handleDebouncedBack}>{t('common.cancel')}</Button>
            </Grid>
            <Grid item>
              <FormSubmitButton
                context={FormContext}
                component={Button}
                type="submit"
                className={classes.purpleGradualButton}
              >
                {t('common.confirm')}
              </FormSubmitButton>
            </Grid>
          </Grid>
        </Box>
      </FormStateProvider>
    </Box>
  )
}

export default React.memo(PlayerReportTransferUpdatePage)

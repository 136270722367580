import React from 'react'
import Box from '@material-ui/core/Box'
import JournalOperationForm from '../../../components/admin/journal/JournalOperationForm'
import JournalOperationTable from '../../../components/admin/journal/JournalOperationTable'

const JournalOperationPage: React.FC = () => {
  return (
    <Box paddingY={5}>
      <Box paddingX={5}>
        <JournalOperationForm />
      </Box>
      <Box paddingTop={3}>
        <JournalOperationTable />
      </Box>
    </Box>
  )
}

export default React.memo(JournalOperationPage)

import React, { useMemo, useContext, useState, useEffect, useCallback } from 'react'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import TextField, { TextFieldProps } from '@material-ui/core/TextField'
import DropDown, { PropTypes as DropDownProps } from '../../default/form/DropDown'
import NumberInput from '../../default/form/NumberInput'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import useT from '../../../i18ns/admin/useT'
import FormField from '../../default/form/FormField'
import { InitialFormFunc, useGetDataByPayload } from '../../../utils/default/ComplexFlowHook'
import { Context, FormContext, WithdrawExchangeRateType } from './WithdrawalFMSlipDetailDialog'
import useGDK from '../../../providers/admin/gdk/useGDK'
import LoadingAndErrorFrame from '../../default/frames/LoadingAndErrorFrame'
import { usePageFlow } from '../../../utils/default/PageFlowHook'
import useGlobalDialog from '../../../providers/admin/dialog/useGlobalDialog'
import { createGlobalDialogConfig } from '../../../utils/default/DialogHelper'
import { getCashInputProps, getCodeInputProps } from '../../../utils/default/FormHelper'
import { WithdrawWayType } from '@golden/gdk-admin'

export enum WithdrawSlipType {
  PENDING = 0,
  GOLDEN_WITHDRAW = 1,
  THIRD_PARTY_WITHDRAW = 2,
  CANCEL = 3
}

export interface WithdrawSlipFormType {
  type: WithdrawSlipType
  cashCrypto: string
  memo: string
  courier: number | '--'
  code: string
}

export const initialForm: InitialFormFunc<WithdrawSlipFormType> = (defaultForm) => ({
  type: WithdrawSlipType.PENDING,
  cashCrypto: '',
  memo: '',
  courier: '--',
  code: '',
  ...defaultForm
})

const moneyInputProps = {
  ...getCashInputProps(),
  decimalScale: 2,
  maxLength: 15
}

const memoFormHelperTextProps = { error: true }

const UsdtInput: React.FC<{ exchangeRate: WithdrawExchangeRateType, hasDetailSuccess: boolean }> = React.memo(({ exchangeRate, hasDetailSuccess }) => {
  const { t } = useT()
  const { value } = useContext(FormContext)
  return (
    <Grid item xs={12} md={6} lg={3}>
      <FormField<WithdrawSlipFormType, TextFieldProps>
        context={FormContext}
        component={NumberInput}
        name="cashCrypto"
        label={`${value.type === WithdrawSlipType.THIRD_PARTY_WITHDRAW ? t('common.estimate') : t('common.withdrawal')} USDT`}
        fullWidth
        disabled={value.type === WithdrawSlipType.THIRD_PARTY_WITHDRAW || hasDetailSuccess}
        inputProps={moneyInputProps}
        helperText={`${t('common.exchangeRate')}: 1 USDT ≈ ${exchangeRate.sell} RMB (${exchangeRate.min}:${exchangeRate.sec})`}
      />
    </Grid>
  )
})

const CourierInput: React.FC<{ id: number, isCrypto: boolean, exchangeRate: WithdrawExchangeRateType, hasDetailSuccess: boolean }> = React.memo((props) => {
  const { id } = props
  const gdk = useGDK()
  const pageFlow = usePageFlow()
  const globalDialog = useGlobalDialog()
  const { value } = useContext(FormContext)
  const { t } = useT()
  const [list, setList] = useState<Array<{ id: number, name: string, balance: string | null, is_active: boolean, method: WithdrawWayType, reason: string }>>([])
  useGetDataByPayload({
    payload: id,
    gdkFunc: (payload) => gdk.withdraw.getWithdrawSlipCouriers(payload),
    gdkFuncDependencies: [gdk],
    onBeforeFetch: pageFlow.setLoadingStart,
    onSuccess: (res: Array<{ id: number, name: string, balance: string | null, is_active: boolean, method: WithdrawWayType, reason: string }>) => {
      setList(res)
      pageFlow.setContentShow()
    },
    onError: pageFlow.setGDKError,
    canLoadData: value.type === WithdrawSlipType.THIRD_PARTY_WITHDRAW && id !== 0
  })
  useEffect(() => {
    if (pageFlow.status.error !== null) {
      globalDialog.setConfig(createGlobalDialogConfig({
        showIcon: true,
        variant: 'error',
        showCancel: false,
        message: pageFlow.status.error
      }))
      globalDialog.setOpen({
        id: 'courierGetError',
        value: true,
        isOK: false
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageFlow.status.error])
  const options = useMemo(() => {
    return list.map((item) => {
      const currency = [WithdrawWayType.CURRENCY_CRYPTO_ERC, WithdrawWayType.CURRENCY_CRYPTO_TRC, WithdrawWayType.CURRENCY_CGPAY_USDT].includes(item.method) ? ' USDT' : ''
      const name = `${item.name}(${t('common.balanceWithColon')} ${item.balance === null ? '-' : item.balance}${currency})`
      return {
        name: item.is_active ? name : item.reason,
        value: item.id,
        disabled: !item.is_active
      }
    })
  }, [list, t])
  if (value.type !== WithdrawSlipType.THIRD_PARTY_WITHDRAW) return null
  return (
    <React.Fragment>
      <Grid item xs={12} md={6} lg={3}>
        <LoadingAndErrorFrame loading={pageFlow.status.loading} error={null} showContent={!pageFlow.status.loading}>
          <FormField<WithdrawSlipFormType, DropDownProps>
            context={FormContext}
            component={DropDown}
            name="courier"
            label={t('common.withdrawSource')}
            options={options.length === 0 ? [{ name: t('error.noWayCanUse'), value: '--' }] : options}
            fullWidth
            disabled={options.length === 0}
          />
        </LoadingAndErrorFrame>
      </Grid>
      {props.isCrypto && <UsdtInput exchangeRate={props.exchangeRate} hasDetailSuccess={props.hasDetailSuccess} />}
    </React.Fragment>
  )
})

const MemoInput: React.FC = React.memo(() => {
  const { value, handleChange, handleOther, error } = useContext(FormContext)
  const { t } = useT()
  const commonClasses = useCommonStyles()
  const memoInputProps = useMemo(() => ({
    classes: { input: commonClasses.memoField },
    inputProps: {
      placeholder: t('placeholder.inputPlayerMemo')
    }
  }), [commonClasses.memoField, t])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onChange = useCallback(handleChange('memo'), [])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onFocus = useCallback(handleOther('focus', 'memo'), [])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onBlur = useCallback(handleOther('blur', 'memo'), [])
  return (
    <Box paddingTop={2}>
      <Typography>{`${t('common.playerMemo')}`}</Typography>
      <TextField
        multiline
        fullWidth
        type="text"
        margin="normal"
        variant="outlined"
        required={value.type === WithdrawSlipType.CANCEL}
        value={value.memo}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        error={error.memo !== null}
        InputProps={memoInputProps}
        // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
        helperText={error.memo || t('helperText.greeting')}
        FormHelperTextProps={memoFormHelperTextProps}
      />
    </Box>
  )
})

const GoogleCodeInput: React.FC<{ isFirst: boolean }> = React.memo((props) => {
  const { isFirst } = props
  const { value } = useContext(FormContext)
  const { t } = useT()
  const codeInputProps = getCodeInputProps()
  if (value.type !== WithdrawSlipType.THIRD_PARTY_WITHDRAW || !isFirst) return null
  return (
    <Grid item xs={12} md={6} lg={3}>
      <FormField<WithdrawSlipFormType, TextFieldProps>
        context={FormContext}
        component={NumberInput}
        name="code"
        fullWidth
        required
        inputProps={codeInputProps}
        label={t('common.googleCode')}
        placeholder={t('placeholder.inputGoogleCode2')}
      />
    </Grid>
  )
})

const EditWithdrawalFMSlipForm: React.FC<{ id: number, isFirst: boolean, isCrypto: boolean, hasDetailSuccess: boolean }> = (props) => {
  const { id, isFirst, isCrypto, hasDetailSuccess } = props
  const [exchangeRate] = useContext(Context)
  const { t } = useT()
  const options = useMemo(() => {
    return [
      { name: t('withdrawSlipType.pending'), value: WithdrawSlipType.PENDING },
      { name: t('withdrawSlipType.goldenWithdraw'), value: WithdrawSlipType.GOLDEN_WITHDRAW },
      { name: t('withdrawSlipType.thirdPartyWithdraw'), value: WithdrawSlipType.THIRD_PARTY_WITHDRAW },
      { name: t('withdrawSlipType.cancel'), value: WithdrawSlipType.CANCEL }
    ]
  }, [t])
  return (
    <React.Fragment>
      <Grid container direction="row" spacing={2}>
        <Grid item xs={12} md={6} lg={3}>
          <FormField<WithdrawSlipFormType, DropDownProps>
            context={FormContext}
            component={DropDown}
            name="type"
            label={t('common.chooseWithdrawWay')}
            options={options}
            fullWidth
          />
        </Grid>
        <CourierInput id={id} isCrypto={isCrypto} exchangeRate={exchangeRate} hasDetailSuccess={hasDetailSuccess} />
        <GoogleCodeInput isFirst={isFirst} />
      </Grid>
      <MemoInput />
    </React.Fragment>
  )
}

export default EditWithdrawalFMSlipForm

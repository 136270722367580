import React from 'react'
import { AnnouncementStatusType } from '@golden/gdk-admin'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import useGDK from '../../../providers/admin/gdk/useGDK'
import useGlobalDialog from '../../../providers/admin/dialog/useGlobalDialog'
import { createGlobalDialogConfig } from '../../../utils/default/DialogHelper'
import { useDialogHandleClick } from '../../../utils/default/ComplexFlowHook'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import useT from '../../../i18ns/admin/useT'

interface PropTypes {
  id: number
  status: AnnouncementStatusType
  endAt: Date
  reload: () => void
}

const AgentAnnouncementTableButtonsBase: React.FC<{
  id: number
  status: AnnouncementStatusType
  handleToggle: () => void
}> = React.memo((props) => {
  const {
    status,
    handleToggle
  } = props
  const classes = useCommonStyles()
  const { t } = useT()

  return (
    <Grid container direction="column" spacing={1} justify="center">
      {
        status === AnnouncementStatusType.START &&
          (
            <Grid item>
              <Button
                onClick={handleToggle}
                size="small"
                className={classes.greyOutlineButton}
              >
                {t('common.toggleOff')}
              </Button>
            </Grid>
          )
      }
      {
        status === AnnouncementStatusType.STOP &&
          (
            <Grid item>
              <Button
                onClick={handleToggle}
                size="small"
                className={classes.greenGradualOutlineButton}
              >
                {t('common.toggleOn')}
              </Button>
            </Grid>
          )
      }
    </Grid>
  )
})

const AnnouncementTableButtons: React.FC<PropTypes> = (props) => {
  const { t } = useT()
  const gdk = useGDK()
  const globalDialog = useGlobalDialog()
  const { id, status, reload } = props

  const handleToggle = useDialogHandleClick({
    dialogId: `stopAnnouncement-${id}`,
    globalDialog,
    changeDialogConfig: createGlobalDialogConfig({
      showIcon: false,
      message: status === AnnouncementStatusType.START ? t('dialog.toggleOffAnnouncementQuestion') : t('dialog.toggleOnAnnouncementQuestion')
    }),
    successDialogConfig: createGlobalDialogConfig({
      showIcon: false,
      message: status === AnnouncementStatusType.START ? t('dialog.toggleOffAnnouncementFinish') : t('dialog.toggleOnAnnouncementFinish'),
      showCancel: false
    }),
    getFailDialogConfig: (error: string) => createGlobalDialogConfig({
      variant: 'error',
      showIcon: true,
      message: error,
      showCancel: false
    }),
    payload: {
      status: status === AnnouncementStatusType.START ? 'disable' : 'enable'
    },
    gdkFunc: (payload) => gdk.announcement.toggleAgentAnnouncementStatus(payload, id),
    gdkFuncDependencies: [gdk],
    onSuccess: () => reload(),
    onSuccessDependencies: [reload]
  })

  return (
    <AgentAnnouncementTableButtonsBase
      id={id}
      status={status}
      handleToggle={handleToggle}
    />
  )
}

export default React.memo(AnnouncementTableButtons)

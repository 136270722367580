import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import allRoute from '../route/route'
import useT from '../../../i18ns/admin/useT'
import { useChecker } from '../../../utils/admin/AdminRouteHook'
import { Menu, MenuItem } from '@material-ui/core'
import { BannerNotificationTargetType } from '@golden/gdk-admin'

const BannerNotificationCreateToolbar: React.FC = () => {
  const classes = useCommonStyles()
  const { t } = useT()

  const writable = useChecker()
  const [menuEl, setMenuEl] = useState<null | HTMLElement>(null)

  return (
    <Grid container direction="row" justify="flex-end" spacing={2}>
      {writable && (
        <Grid item>
          <Button
            onClick={(event) => setMenuEl(event.currentTarget)}
            className={classes.purpleGradualButton}
            classes={{ disabled: classes.disabledButton }}
          >
            <Box paddingX={2.5} paddingY={0.5}>
              {t('common.create')}
            </Box>
          </Button>
          <Menu
            anchorEl={menuEl}
            keepMounted
            open={Boolean(menuEl)}
            onClose={() => { setMenuEl(null) }}
          >
            <MenuItem
              component={Link}
              to={allRoute.forestageBannerNotificationCreate.encodePath({ search: {}, param: { target: BannerNotificationTargetType.USER } })}
            >
              <Box paddingX={2.5} paddingY={0.5}>
                { t('common.player') }
              </Box>
            </MenuItem>
            <MenuItem
              component={Link}
              to={allRoute.forestageBannerNotificationCreate.encodePath({ search: {}, param: { target: BannerNotificationTargetType.AGENT } })}
            >
              <Box paddingX={2.5} paddingY={0.5}>
                { t('common.agent') }
              </Box>
            </MenuItem>
          </Menu>
        </Grid>
      )}
    </Grid>
  )
}

export default React.memo(BannerNotificationCreateToolbar)

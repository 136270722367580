import { SmsSendStatusType } from '@golden/gdk-admin'
import TKeyType from '../../i18ns/admin/TKeyType'

const smsSendStatusName: { [key in SmsSendStatusType]: TKeyType } = {
  [SmsSendStatusType.SUCCESS]: 'smsSendStatus.success',
  [SmsSendStatusType.FAILURE]: 'smsSendStatus.failure',
  [SmsSendStatusType.WAITING]: 'smsSendStatus.waiting'
}

export default smsSendStatusName

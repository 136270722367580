import React, { useContext, useCallback, useState } from 'react'
import clsx from 'clsx'
import CircularProgress from '@material-ui/core/CircularProgress'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Typography from '@material-ui/core/Typography'
import { PlayerJumpShipItem } from '@golden/gdk-admin'
import { useCommonStyles, useDetailStyles, useDialogStyles } from '../../../utils/admin/StyleHook'
import { DialogPayloadContext } from '../../../views/admin/player/PlayerJumpShipPage'
import useT from '../../../i18ns/admin/useT'
import playerJumpShipReasonName from '../../../constants/admin/playerJumpShipReasonName'
import useGDK from '../../../providers/admin/gdk/useGDK'
import useGlobalDialog from '../../../providers/admin/dialog/useGlobalDialog'
import { createGlobalDialogConfig } from '../../../utils/default/DialogHelper'
import { useDialogFlow } from '../../../utils/default/DialogHook'

const useStyles = makeStyles(() => ({
  head: {
    width: 120
  }
}))

export interface Payload {
  item: PlayerJumpShipItem | null
  open: boolean
}

interface PropTypes {
  reload: () => void
}

const JumpShipCancelDialog: React.FC<PropTypes> = (props) => {
  const { reload } = props
  const classes = useStyles()
  const commonClasses = useCommonStyles()
  const detailClasses = useDetailStyles()
  const dialogClasses = useDialogStyles()
  const globalDialog = useGlobalDialog()
  const { t } = useT()
  const gdk = useGDK()
  const [payload, setPayload] = useContext(DialogPayloadContext)
  const [loading, setLoading] = useState<boolean>(false)
  const { open, item } = payload
  const handleCancel = useCallback(() => {
    setPayload({
      open: false,
      item: null
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const handleConfirm = useCallback(() => {
    if (item === null) return
    setLoading(true)
    gdk.jumpShip.cancelJumpShip(item.id).subscribe({
      next: () => {
        setLoading(false)
        globalDialog.setConfig(createGlobalDialogConfig({
          showCancel: false,
          showIcon: false,
          message: t('dialog.cancelJumpShipSuccess')
        }))
        globalDialog.setOpen({
          id: `jumpShipCancelSuccess-${item.id}`,
          value: true,
          isOK: false
        })
      },
      error: (error) => {
        setLoading(false)
        globalDialog.setConfig(createGlobalDialogConfig({
          showCancel: false,
          variant: 'error',
          message: error.message
        }))
        globalDialog.setOpen({
          id: `jumpShipCancelFail-${item.id}`,
          value: true,
          isOK: false
        })
      }
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item, t])
  useDialogFlow(globalDialog, `jumpShipCancelSuccess-${item?.id ?? 0}`, () => {
    setLoading(false)
    handleCancel()
    reload()
  }, [])
  return (
    <Dialog open={open} fullWidth maxWidth="sm">
      <Box padding={2} className={commonClasses.dialogPinkHeader}>
        {t('common.cancelPlayerJumpShip')}
      </Box>
      <DialogContent>
        <Box padding={3}>
          <Box paddingBottom={2}>
            <Typography align="center" className={dialogClasses.text}>
              {t('dialog.confirmCancelJumpShip')}
            </Typography>
          </Box>
          <table className={detailClasses.table}>
            <tbody>
              <tr>
                <td className={clsx(detailClasses.head, classes.head)}>{t('common.playerAccount')}</td>
                <td className={detailClasses.cell}>{item?.player_account ?? ''}</td>
              </tr>
              <tr>
                <td className={clsx(detailClasses.head, classes.head)}>{t('common.originalStaff')}</td>
                <td className={detailClasses.cell}>{item?.original_staff_account ?? ''}</td>
              </tr>
              <tr>
                <td className={clsx(detailClasses.head, classes.head)}>{t('common.newStaff')}</td>
                <td className={detailClasses.cell}>{item?.new_staff_account ?? ''}</td>
              </tr>
              <tr>
                <td className={clsx(detailClasses.head, classes.head)}>{t('common.jumpShipReason')}</td>
                <td className={detailClasses.cell}>{item?.reason ? t(playerJumpShipReasonName[item.reason]) : ''}</td>
              </tr>
            </tbody>
          </table>
        </Box>
      </DialogContent>
      <DialogActions>
        <Box paddingBottom={3} width="100%">
          <Grid container direction="row" justify="center" spacing={2}>
            <Grid item>
              <Button
                className={commonClasses.greyButton}
                onClick={handleCancel}
              >
                {t('common.cancel')}
              </Button>
            </Grid>
            <Grid item>
              <Button
                disabled={loading}
                className={commonClasses.purpleGradualButton}
                onClick={handleConfirm}
              >
                {loading
                  ? (
                  <Box display="flex" alignItems="center" justifyContent="center">
                    <CircularProgress size={24} />
                  </Box>
                    )
                  : t('common.confirm')}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </DialogActions>
    </Dialog>
  )
}

export default React.memo(JumpShipCancelDialog)

import { i18n, TOptions } from 'i18next'
import { useTranslation } from 'react-i18next'
import TKeyType from './TKeyType'
import { useCallback } from 'react'

const useT = (): {
  t: (key: TKeyType, options?: TOptions) => string
  i18n: i18n
  ready: boolean
} => {
  const { t: tFunc, i18n, ready } = useTranslation()
  const t = useCallback((key: TKeyType, options?: TOptions) => {
    return tFunc(key, { ...options })
  }, [tFunc])
  return {
    t,
    i18n,
    ready
  }
}

export default useT

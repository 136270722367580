import React, { useMemo, useState, useContext } from 'react'
import { InternalWithdraw } from '@golden/gdk-admin'
import LoadingAndErrorFrame from '../../default/frames/LoadingAndErrorFrame'
import CoreTable from '../../default/present/CoreTable'
import { usePageFlow } from '../../../utils/default/PageFlowHook'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import useT from '../../../i18ns/admin/useT'
import { useGetDataByPayload } from '../../../utils/default/ComplexFlowHook'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { createTableData, formatMoney } from '../../../utils/default/TableHelper'
import DateTime from '../../default/present/DateTime'
import { RequestContext } from '../../../views/admin/finance/FinanceInternalWithdrawPage'
import { convertEmptyToUndefined } from '../../../utils/default/FormHelper'
import InternalWithdrawButtons from './InternalWithdrawButtons'

interface RowTyep {
  id: number
  forwarder: string
  courier: string
  balance: string
  updatedAt: React.ReactElement
  updatedBy: string
  functions: React.ReactElement
}

const InternalWithdrawTable: React.FC = () => {
  const commonClasses = useCommonStyles()
  const [request] = useContext(RequestContext)
  const [list, setList] = useState<InternalWithdraw[]>([])
  const pageFlow = usePageFlow()
  const gdk = useGDK()
  const { t } = useT()
  const payload = useMemo(() => {
    let payload: { forwarder_name?: string } = {}
    const courierName = convertEmptyToUndefined(request.courier)
    if (courierName) payload = { ...payload, forwarder_name: request.courier }
    return payload
  }, [request])
  useGetDataByPayload({
    payload,
    gdkFunc: (payload) => gdk.finance.getInternalWithdrawList(payload),
    gdkFuncDependencies: [gdk],
    onBeforeFetch: pageFlow.setLoadingStart,
    onSuccess: (res: InternalWithdraw[]) => {
      setList(res)
      pageFlow.setContentShow()
    },
    onError: pageFlow.setGDKError
  })

  const tableClasses = useMemo(() => {
    return {
      head: commonClasses.pinkTableHead,
      row: commonClasses.tableRow,
      cellHead: commonClasses.tableCellHead
    }
  }, [commonClasses])

  const rows = useMemo(() => {
    return list.map((item) => {
      return {
        id: item.forwarder_id,
        forwarder: item.slug_name,
        courier: item.forwarder_name,
        balance: item.is_active ? formatMoney(item.balance) : t('common.cantUse'),
        updatedAt: item.enable_internal_withdraw
          ? item.updated_at ? (<DateTime time={item.updated_at} />) : (<p></p>)
          : '-',
        updatedBy: item.enable_internal_withdraw
          ? item.updated_by
          : '-',
        functions: (
          <InternalWithdrawButtons item={item} />
        )
      } as RowTyep
    })
  }, [list, t])
  const data = useMemo(() => {
    return createTableData(
      {
        id: {
          label: '',
          value: 'id'
        },
        forwarder: {
          label: t('common.forwarder'),
          value: 'forwarder',
          align: 'center'
        },
        courier: {
          label: t('common.courierName'),
          value: 'courier',
          align: 'center'
        },
        balance: {
          label: t('common.balance'),
          value: 'balance',
          align: 'right'
        },
        updatedAt: {
          label: t('common.updateAt'),
          value: 'updatedAt',
          align: 'center'
        },
        updatedBy: {
          label: t('common.updateBy'),
          value: 'updatedBy',
          align: 'center'
        },
        functions: {
          label: t('common.function'),
          value: 'functions',
          align: 'center'
        }
      },
      [
        'forwarder',
        'courier',
        'balance',
        'updatedAt',
        'updatedBy',
        'functions'
      ],
      rows,
      'id'
    )
  }, [t, rows])

  return (
    <LoadingAndErrorFrame { ...pageFlow.status }>
      <CoreTable
        classes={tableClasses}
        data={data}
        total={list.length}
      />

    </LoadingAndErrorFrame>
  )
}

export default React.memo(InternalWithdrawTable)

import React, { useMemo } from 'react'
import { BalanceRetrieveRecordStatus, WalletType } from '@golden/gdk-admin'
import { BcMath } from '@golden/bcmath'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { useCommonStyles, useDialogStyles } from '../../utils/admin/StyleHook'
import useT from '../../i18ns/admin/useT'
import { createTableData, formatDate, formatMoney, formatTime } from '../../utils/default/TableHelper'
import CoreTable from '../../components/default/present/CoreTable'
import { makeStyles } from '@material-ui/core'
import CustomThemeType from '../../themes/default/CustomThemeType'
import useGDKStore from '../../providers/admin/gdk/useGDKStore'
import { getWalletNameWithStatusByWalletId } from '../../utils/default/PlatformHelper'

const useStyles = makeStyles((theme: CustomThemeType) => ({
  title: {
    textAlign: 'center',
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(2),
    color: theme.custom.palette.text.dialog,
    fontSize: theme.custom.typography.dialog
  }
}))

interface PropTypes {
  open: boolean
  list: Array<{
    id: number
    wallet_id: WalletType
    cash: string
    status: BalanceRetrieveRecordStatus
    fetched_at: Date | null
  }>
  handleOk: () => void
}

const BalanceRetrieveRecordDialog: React.FC<PropTypes> = (props) => {
  const { open, list, handleOk } = props
  const classes = useStyles()
  const dialogClasses = useDialogStyles()
  const commonClasses = useCommonStyles()
  const { t } = useT()
  const platforms = useGDKStore.platform.platforms()

  const tableClasses = useMemo(() => ({
    head: commonClasses.greyTableHead,
    row: commonClasses.tableRow,
    cellHead: commonClasses.tableCellHead,
    cellBody: commonClasses.nowrap
  }), [commonClasses])

  const rows = useMemo(() => {
    let currentDate = 0
    const tempList: Array<{
      id: number
      wallet_id: WalletType
      cash: string
      status: BalanceRetrieveRecordStatus
      fetched_at: Date | null
      hideDate: boolean
    }> = []
    for (const item of list.filter((el) => el.status === BalanceRetrieveRecordStatus.SUCCESS && Number(el.cash) !== 0)) {
      if (item.fetched_at?.getDate() === currentDate) {
        tempList.push({ ...item, hideDate: true })
      } else {
        tempList.push({ ...item, hideDate: false })
        currentDate = item.fetched_at?.getDate() ?? 0
      }
    }
    return tempList.map((item, index) => ({
      id: index,
      date: item.hideDate
        ? ''
        : item.wallet_id === WalletType.CENTER
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          ? (<span className={commonClasses.bold}>{formatDate(item.fetched_at!)}</span>)
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          : formatDate(item.fetched_at!),
      time: item.wallet_id === WalletType.CENTER
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        ? (<span className={commonClasses.bold}>{formatTime(item.fetched_at!)}</span>)
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        : formatTime(item.fetched_at!),
      platform: item.wallet_id === WalletType.CENTER
        ? (<span className={commonClasses.bold}>{getWalletNameWithStatusByWalletId(item.wallet_id, platforms)}</span>)
        : getWalletNameWithStatusByWalletId(item.wallet_id, platforms),
      balance: item.wallet_id === WalletType.CENTER
        ? (<span className={commonClasses.bold}>{formatMoney(item.cash)}</span>)
        : formatMoney(item.cash)
    }))
  }, [list, platforms, commonClasses.bold])

  const data = useMemo(() => {
    return createTableData(
      {
        id: {
          label: '',
          value: 'id'
        },
        date: {
          label: t('common.date'),
          value: 'date',
          align: 'center',
          width: 100
        },
        time: {
          label: t('common.time'),
          value: 'time',
          align: 'center',
          width: 100
        },
        platform: {
          label: t('common.gameStoreName'),
          value: 'platform',
          align: 'center'
        },
        balance: {
          label: t('common.balance'),
          value: 'balance',
          align: 'center'
        }
      },
      [
        'date',
        'time',
        'platform',
        'balance'
      ],
      rows,
      'id'
    )
  }, [rows, t])

  const failureTip = useMemo(() => {
    const failures = list.filter((item) => item.status === BalanceRetrieveRecordStatus.FAILURE)
    return failures.length > 0
      ? (`*${t('common.cantRetrieveBalance')} : ` +
      failures.map((item) => getWalletNameWithStatusByWalletId(item.wallet_id, platforms)).join('、'))
      : ''
  }, [t, list, platforms])

  const zeroTip = useMemo(() => {
    const zeros = list.filter((item) => item.status === BalanceRetrieveRecordStatus.SUCCESS && Number(item.cash) === 0)
    return zeros.length > 0
      ? (`*${t('common.balanceIsZero')} : ` +
      zeros.map((item) => getWalletNameWithStatusByWalletId(item.wallet_id, platforms)).join('、'))
      : ''
  }, [t, list, platforms])

  const summary = useMemo(() => (
    <TableRow>
      <TableCell width={100} />
      <TableCell width={100} />
      <TableCell align="center">
        <span className={commonClasses.bold}>{t('common.totalAmount')}</span>
      </TableCell>
      <TableCell align="center">
        <span className={commonClasses.bold}>
          {list.reduce((pre, cur) => BcMath.base(pre).add(cur.cash).get(), '0')}
        </span>
      </TableCell>
    </TableRow>
  ), [list, t, commonClasses.bold])

  return (
    <Dialog maxWidth="sm" open={open}>
      <DialogTitle className={classes.title}>{t('common.balanceRetrieveDetail')}</DialogTitle>
      <DialogContent dividers>
        <CoreTable
          data={data}
          total={rows.length}
          classes={tableClasses}
          tableAppend={summary}
        />
        <Box paddingTop={1}>
          <Typography color="error">{failureTip}</Typography>
        </Box>
        <Box>
          <Typography>{zeroTip}</Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Grid container justify="center">
          <Button onClick={handleOk} classes={{ root: dialogClasses.okButton }}>
            {t('common.confirm')}
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  )
}

export default React.memo(BalanceRetrieveRecordDialog)

import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import Box from '@material-ui/core/Box'
import List from '@material-ui/core/List'
import RegionComponent from '../navigation/RegionComponent'
import useDrawer from '../../../providers/default/drawer/useDrawer'
import allRegion, { regionOrder } from '../navigation/region'
import group from '../navigation/group'
import GroupKey from '../navigation/GroupKey'
import allRoute, { routeOrder } from '../route/route'
import RouteKey from '../route/RouteKey'
import { findAppRoute } from '../../../utils/default/RouteHelper'

const regions = regionOrder
  .map((key) => allRegion[key])
  .map((region) => (<RegionComponent key={region.key} region={region} />))

const AppDrawerContentBase: React.FC = React.memo(() => (
  <List>
    <Box paddingX={2}>
      {regions}
    </Box>
  </List>
))

const AppDrawerContent: React.FC = () => {
  const drawer = useDrawer()
  const location = useLocation()
  useEffect(() => {
    if (drawer !== null) {
      const { itemSelected, setItemSelected, subItemSelected, setSubItemSelected } = drawer
      const route = findAppRoute(location.pathname, routeOrder, allRoute) ?? allRoute.notFound
      const shouldItemSelected = Object.keys(group).find((key) => {
        if (Array.isArray(group[key as GroupKey].subItem)) {
          return (group[key as GroupKey].subItem as RouteKey[])
            .some((item) => route.path.indexOf(allRoute[item]?.path) === 0)
        }
        return route.path.indexOf(allRoute[group[key as GroupKey].subItem as RouteKey]?.path) === 0
      }) ?? ''
      if (itemSelected !== shouldItemSelected) {
        setItemSelected(shouldItemSelected)
        setSubItemSelected('')
      }
      if (
        Object.keys(group).includes(shouldItemSelected) &&
        Array.isArray(group[shouldItemSelected as GroupKey].subItem) &&
        subItemSelected !== route.key
      ) {
        setSubItemSelected(route.key)
      }
    }
  }, [drawer, location.pathname])

  return (<AppDrawerContentBase />)
}

export default React.memo(AppDrawerContent)

import React, { useCallback, PropsWithChildren, useMemo } from 'react'
import { InitialFormFunc } from '../../../utils/default/ComplexFlowHook'
import { FormState, ValueGetter, useForm, FormValidation } from '../../../utils/default/FormHook'
import { getValueFromChangeEvent, getValueFromCheckboxEvent, getValueFromValue } from '../../../utils/default/FormHelper'
import useT from '../../../i18ns/admin/useT'
import { createValidateNotEmpty } from '../../../utils/default/Validator'
import { AgCategoryType } from '@golden/gdk-admin'

export interface EditChannelFormType {
  code: string
  name: string
  image: { id: number, path: string }
  category: AgCategoryType
  agGciType: string
  isShow: boolean
}

export const initialForm: InitialFormFunc<EditChannelFormType> = (defaultForm) => ({
  code: '',
  name: '',
  image: { id: 0, path: '' },
  category: AgCategoryType.SLOT,
  agGciType: '',
  isShow: true,
  ...defaultForm
})

const getValueFromEvent: ValueGetter<EditChannelFormType> = {
  code: getValueFromChangeEvent,
  name: getValueFromChangeEvent,
  image: getValueFromValue,
  category: getValueFromChangeEvent,
  agGciType: getValueFromChangeEvent,
  isShow: getValueFromCheckboxEvent
}

export interface PropTypes {
  context: React.Context<FormState<EditChannelFormType>>
  defaultValue: EditChannelFormType
}

const ChannelFormStateProvider: React.FC<PropsWithChildren<PropTypes>> = (props) => {
  const { context: Context, defaultValue, children } = props
  const { t } = useT()

  const onSubmit = useCallback((form) => form, [])

  const formValidation: FormValidation<EditChannelFormType> = useMemo(() => {
    return {
      code: [
        {
          func: createValidateNotEmpty<EditChannelFormType>('code', t),
          when: ['change', 'beforeClickSubmit']
        }
      ],
      name: [
        {
          func: createValidateNotEmpty<EditChannelFormType>('name', t),
          when: ['change', 'beforeClickSubmit']
        }
      ],
      image: [],
      category: [],
      agGciType: [],
      isShow: []
    }
  }, [t])

  const formState = useForm<EditChannelFormType>({
    defaultValue,
    onSubmit,
    getValueFromEvent,
    formValidation
  })

  return (
    <Context.Provider value={formState}>
      {children}
    </Context.Provider>
  )
}

export default React.memo(ChannelFormStateProvider)

import React, { createContext, useMemo, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import MuiButton from '@material-ui/core/Button'
import MuiTextField, { TextFieldProps } from '@material-ui/core/TextField'
import DropDown, { PropTypes as DropDownProps } from '../../default/form/DropDown'
import FormField from '../../default/form/FormField'
import FormSubmitButton from '../../default/form/FormSubmitButton'
import { FormPropType } from '../../../utils/default/FormHelper'
import { InitialFormFunc, useGetDataByPayload } from '../../../utils/default/ComplexFlowHook'
import { createDefaultFormState } from '../../../utils/default/FormHook'
import useT from '../../../i18ns/admin/useT'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import AdminFormStateProvider from './AdminFormStateProvider'
import { RoleTreeItem } from '@golden/gdk-admin'
import useGDK from '../../../providers/admin/gdk/useGDK'
import RequiredText from '../../default/form/RequiredText'
import useGDKStore from '../../../providers/admin/gdk/useGDKStore'
import Switch, { PropTypes as SwitchProps } from '../Switch'

export interface EditManagerAccountFormType {
  account: string
  nickname: string
  roleId: number | string
  password: string
  passwordConfirmation: string
  isLoginable: boolean
  isGoogleAuthActive: boolean
  memo: string
}

export const initialForm: InitialFormFunc<EditManagerAccountFormType> = (defaultForm) => ({
  account: '',
  nickname: '',
  roleId: '',
  password: '',
  passwordConfirmation: '',
  isLoginable: true,
  isGoogleAuthActive: false,
  memo: '',
  ...defaultForm
})

interface PropTypes extends FormPropType<EditManagerAccountFormType> {
  adminId?: number
  account?: string
  nickname?: string
  showPassword?: boolean
  disableAccount?: boolean
  disableNickname?: boolean
  readonlyPassword?: boolean
  requirePassword?: boolean
  requirePasswordConfirmation?: boolean
  okText: string
  onBack: () => void
}

const accountInputProps = { maxLength: 10 }

const nicknameInputProps = { maxLength: 10 }

const memoFormHelperTextProps = { error: true }

const FormContext = createContext(createDefaultFormState(initialForm()))

const TextField = React.memo(MuiTextField)

const Button = React.memo(MuiButton)

const EditManagerAccountForm: React.FC<PropTypes> = (props) => {
  const {
    defaultValue,
    onSubmit,
    onBack,
    okText,
    account,
    nickname,
    showPassword,
    disableAccount,
    disableNickname,
    readonlyPassword,
    requirePassword,
    requirePasswordConfirmation
  } = props
  const classes = useCommonStyles()
  const gdk = useGDK()
  const { t } = useT()
  const [nodes, setTree] = useState<RoleTreeItem[]>([])
  const me = useGDKStore.admin.me()

  useGetDataByPayload({
    payload: me?.role_id ?? 0,
    canLoadData: !!me?.role_id,
    gdkFunc: (payload) => gdk.permissionRole.getRoleTree(payload),
    gdkFuncDependencies: [gdk],
    onSuccess: (res: RoleTreeItem[]) => setTree(res)
  })

  const memoInputProps = useMemo(() => ({
    classes: { input: classes.memoField },
    inputProps: {
      maxLength: 100,
      placeholder: t('placeholder.inputMemo')
    }
  }), [classes.memoField, t])

  const roleOptions = useMemo(() => {
    return nodes
      .filter((role) => (me?.role_id ?? 0) !== role.id || role.parent_id === 0)
      .map((role) => ({ name: role.name, value: role.id }))
  }, [me?.role_id, nodes])

  const passwordType = useMemo(() => {
    return showPassword ? 'text' : 'password'
  }, [showPassword])

  const passwordInputProps = useMemo(() => ({
    maxLength: 255,
    readOnly: readonlyPassword
  }), [readonlyPassword])

  if (!me) return null
  return (
    <AdminFormStateProvider
      context={FormContext}
      defaultValue={defaultValue}
      onSubmit={onSubmit}
      account={account}
      nickname={nickname}
      disableAccount={disableAccount}
      disableNickname={disableNickname}
      requirePassword={requirePassword}
      requirePasswordConfirmation={requirePasswordConfirmation}
    >
      <Grid container direction="column" spacing={3}>
        <Grid item>
          <Grid container direction="row" spacing={2}>
            <Grid item xs={12} md={3}>
              <FormField<EditManagerAccountFormType, TextFieldProps>
                context={FormContext}
                component={TextField}
                name="account"
                fullWidth
                required
                label={t('common.account')}
                placeholder={t('placeholder.inputAccount')}
                inputProps={accountInputProps}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <FormField<EditManagerAccountFormType, TextFieldProps>
                context={FormContext}
                component={TextField}
                name="nickname"
                fullWidth
                required
                label={t('common.nickname')}
                placeholder={t('placeholder.inputNickname')}
                inputProps={nicknameInputProps}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <FormField<EditManagerAccountFormType, TextFieldProps>
                context={FormContext}
                component={TextField}
                name="password"
                type={passwordType}
                fullWidth
                required={requirePassword}
                label={t('common.loginPassword')}
                inputProps={passwordInputProps}
              />
            </Grid>
            {requirePasswordConfirmation && (
              <Grid item xs={12} md={3}>
                <FormField<EditManagerAccountFormType, TextFieldProps>
                  context={FormContext}
                  component={TextField}
                  name="passwordConfirmation"
                  type="password"
                  fullWidth
                  required={requirePassword}
                  label={t('common.confirmLoginPassword')}
                  inputProps={passwordInputProps}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item>
          <Grid container direction="row" spacing={2}>
            <Grid item xs={12} md={3}>
              <FormField<EditManagerAccountFormType, DropDownProps>
                context={FormContext}
                component={DropDown}
                name="roleId"
                fullWidth
                required
                label={t('common.role')}
                options={roleOptions}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <FormField<EditManagerAccountFormType, SwitchProps>
                context={FormContext}
                component={Switch}
                label={t('common.status')}
                name="isLoginable"
                inactiveLabel={t('common.disable')}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <FormField<EditManagerAccountFormType, TextFieldProps>
            context={FormContext}
            component={TextField}
            name="memo"
            multiline
            fullWidth
            type="text"
            margin="normal"
            variant="outlined"
            InputProps={memoInputProps}
            FormHelperTextProps={memoFormHelperTextProps}
          />
        </Grid>
        <Grid item>
          <RequiredText />
        </Grid>
        <Grid item>
          <Grid container direction="row" justifyContent="flex-end" spacing={2}>
            <Grid item>
              <Button
                className={classes.greyButton}
                onClick={onBack}
              >
                {t('common.cancel')}
              </Button>
            </Grid>
            <Grid item>
              <FormSubmitButton
                component={Button}
                context={FormContext}
                className={classes.purpleGradualButton}
                type="submit"
              >
                {okText}
              </FormSubmitButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </AdminFormStateProvider>
  )
}

export default React.memo(EditManagerAccountForm)

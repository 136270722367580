import React, { useState, createContext, useCallback, useMemo } from 'react'
import { useDebouncedCallback } from 'use-debounce'
import { FinanceExchangeRateDefault, FinanceExchangeRateDiff, FinanceExchangeRateProviders } from '@golden/gdk-admin'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { TextFieldProps } from '@material-ui/core/TextField'
import NumberInput from '../../../components/default/form/NumberInput'
import LoadingAndErrorFrame from '../../../components/default/frames/LoadingAndErrorFrame'
import { usePageFlow } from '../../../utils/default/PageFlowHook'
import { useGetData, InitialFormFunc, useDialogHandleSubmit, useReload } from '../../../utils/default/ComplexFlowHook'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import useT from '../../../i18ns/admin/useT'
import FormStateProvider from '../../../components/default/form/FormStateProvider'
import { createDefaultFormState, ValueGetter, FormValidation } from '../../../utils/default/FormHook'
import useGlobalDialog from '../../../providers/admin/dialog/useGlobalDialog'
import { createGlobalDialogConfig } from '../../../utils/default/DialogHelper'
import { getValueFromChangeEvent, getCashInputProps, getValueFromValue } from '../../../utils/default/FormHelper'
import { createValidateNotEmpty } from '../../../utils/default/Validator'
import FormField from '../../../components/default/form/FormField'
import { formatMoney } from '../../../utils/default/TableHelper'
import FormSubmitButton from '../../../components/default/form/FormSubmitButton'
import { useChecker } from '../../../utils/admin/AdminRouteHook'
import RequiredText from '../../../components/default/form/RequiredText'
import FinanceParameterExchangeRateList from '../../../components/admin/finance/FinanceParameterExchangeRateList'
import { forkJoin } from 'rxjs'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  formHelper: {
    color: theme.palette.error.main
  }
}))

export type FinanceExchangeRate = FinanceExchangeRateDefault & FinanceExchangeRateDiff & { providers: FinanceExchangeRateProviders[] }

const initialForm: InitialFormFunc<FinanceExchangeRate> = (defaultValue) => ({
  currency: 'usdt',
  buy: '0.0000',
  buy_diff: '0.0000',
  buy_diff_for_sell: '0.0000',
  providers: [],
  ...defaultValue
})

const formToRequest = (form: FinanceExchangeRate): FinanceExchangeRate => form

const getValueFromEvent: ValueGetter<FinanceExchangeRate> = {
  currency: getValueFromValue,
  buy: getValueFromChangeEvent,
  buy_diff: getValueFromChangeEvent,
  buy_diff_for_sell: getValueFromChangeEvent,
  providers: getValueFromValue
}

const FormContext = createContext(createDefaultFormState(initialForm()))

const FinanceParameterPage: React.FC = () => {
  const commonClasses = useCommonStyles()
  const classes = useStyles()
  const [defaultForm, setDefaultForm] = useState<FinanceExchangeRate>(initialForm())
  const [rate, setRate] = useState({ buy: '0.0000', sell: '0.0000' })
  const pageFlow = usePageFlow()
  const gdk = useGDK()
  const globalDialog = useGlobalDialog()
  const { t } = useT()
  const writable = useChecker()
  const { reload, reloadFlag } = useReload()

  useGetData({
    gdkFunc: () => forkJoin([gdk.finance.getExchaneRateDefault({ currency: 'usdt' }), gdk.finance.getExchaneRateDiff(), gdk.finance.getExchaneRateProviders({ currency: 'usdt' }), gdk.exchangeRate.getExchangeRate()]),
    gdkFuncDependencies: [gdk, reloadFlag],
    onBeforeFetch: pageFlow.setLoadingStart,
    onSuccess: ([rateDefault, rateDiff, rateProviders, rate]) => {
      pageFlow.setContentShow()

      setRate({
        buy: rate.buy,
        sell: rate.sell
      })

      setDefaultForm(initialForm({
        buy: formatMoney(rateDefault.buy),
        buy_diff: formatMoney(rateDiff.buy_diff),
        buy_diff_for_sell: formatMoney(rateDiff.buy_diff_for_sell),
        providers: rateProviders.sort((a, b) => a.order - b.order)
      }))
    },
    onError: pageFlow.setGDKError
  })

  const { handleSubmit } = useDialogHandleSubmit({
    dialogId: 'updateParameter',
    globalDialog,
    getChangeDialogConfig: useCallback(() => createGlobalDialogConfig({
      showIcon: false,
      message: t('dialog.confirmUpdateFinanceParameter')
    }), [t]),
    getSuccessDialogConfig: useCallback(() => createGlobalDialogConfig({
      showIcon: false,
      showCancel: false,
      message: t('dialog.updateFinanceParameterSuccess')
    }), [t]),
    getFailDialogConfig: useCallback((error) => createGlobalDialogConfig({
      showIcon: true,
      showCancel: false,
      variant: 'error',
      message: error
    }), []),
    formToRequest,
    gdkFunc: (payload) => {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const { currency, buy, buy_diff, buy_diff_for_sell, providers } = payload

      return forkJoin([
        gdk.finance.updateExchaneRateDefault({ currency, buy }),
        gdk.finance.updateExchaneRateDiff({ buy_diff, buy_diff_for_sell }),
        // eslint-disable-next-line @typescript-eslint/naming-convention
        gdk.finance.updateExchaneRateProviders({ providers: providers.map(({ id, is_active, order }) => ({ id, is_active, order })) })
      ])
    },
    afterSuccessDialog: () => { reload() },
    gdkFuncDependencies: [gdk]
  })

  const [debouncedHandleExport] = useDebouncedCallback(useCallback(() => {
    gdk.parameter.exportFinance().subscribe({
      next: () => {
        globalDialog.setConfig(createGlobalDialogConfig({
          showIcon: false,
          showCancel: false,
          message: t('dialog.sendDataSuccess')
        }))
        globalDialog.setOpen({ id: 'exportDataSuccess', value: true, isOK: false })
      },
      error: (error) => {
        globalDialog.setConfig(createGlobalDialogConfig({
          showIcon: true,
          showCancel: false,
          variant: 'error',
          message: error.message
        }))
        globalDialog.setOpen({ id: 'exportDataFailure', value: true, isOK: false })
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gdk, t]), 200)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleExport = useCallback(debouncedHandleExport, [])

  const formValidation = useMemo(() => {
    return {
      currency: [],
      buy: [
        {
          func: createValidateNotEmpty<FinanceExchangeRate>('buy', t),
          when: ['change', 'beforeClickSubmit']
        }
      ],
      buy_diff: [
        {
          func: createValidateNotEmpty<FinanceExchangeRate>('buy_diff', t),
          when: ['change', 'beforeClickSubmit']
        }
      ],
      buy_diff_for_sell: [
        {
          func: createValidateNotEmpty<FinanceExchangeRate>('buy_diff_for_sell', t),
          when: ['change', 'beforeClickSubmit']
        }
      ],
      providers: []
    } as FormValidation<FinanceExchangeRate>
  }, [t])

  const rateInputProps = useMemo(() => ({
    decimalScale: 2,
    readOnly: !writable
  }), [writable])

  const diffInputProps = useMemo(() => ({
    ...getCashInputProps(),
    decimalScale: 2,
    allowNegative: true,
    readOnly: !writable
  }), [writable])

  return (
    <Box padding={5}>
      <Paper>
        <Box padding={4}>
          <LoadingAndErrorFrame {...pageFlow.status}>
            <FormStateProvider
              context={FormContext}
              defaultValue={defaultForm}
              onSubmit={handleSubmit}
              getValueFromEvent={getValueFromEvent}
              validation={formValidation}
            >
              <Grid container direction="column" spacing={3}>
                <Grid item>
                  <Box
                    paddingY={1.25}
                    paddingX={2}
                    className={commonClasses.pinkTitleBar}
                  >
                    <Typography variant="h5">
                      {t('common.financeSetting')}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item>
                  <Box>
                    <Typography variant="h5" className={commonClasses.bold}>
                      {t('common.financeDepositWithdraw')}
                    </Typography>
                  </Box>
                  <Box paddingTop={2} display="flex" flexDirection="row" alignItems="center">
                    <Box display="flex" flexDirection="column" paddingRight={2}>
                      <Typography>{t('common.manualFinanceDataExport')}</Typography>
                      <Typography className={classes.formHelper}>{t('common.manualFinanceDataExportTip')}</Typography>
                    </Box>
                    <Box display="flex">
                      <Button
                        onClick={handleExport}
                        className={commonClasses.purpleGradualButton}
                        disabled={!writable}
                      >
                        {t('common.send')}
                      </Button>
                    </Box>
                  </Box>
                </Grid>
                <Grid item>
                  <Box>
                    <Typography variant="h5" className={commonClasses.bold}>
                      {t('common.usdtSetting')}
                    </Typography>
                  </Box>
                  <Box paddingTop={2}>
                    <Grid container direction="row" spacing={2}>
                      <Grid item xs={12} md={6}>
                        <Grid container direction="column" spacing={3}>
                          <Grid item>
                            <Box paddingTop={1} paddingBottom={2}>
                              <Typography variant="h6" className={commonClasses.bold}>
                                {t('common.exchangeUpdate')}
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid item>
                            <FinanceParameterExchangeRateList context={FormContext} disabled={!writable} />
                          </Grid>
                          <Grid item>
                            <Box width={1 / 2}>
                              <FormField<FinanceExchangeRate, TextFieldProps>
                                context={FormContext}
                                component={NumberInput}
                                name="buy"
                                fullWidth
                                required
                                label={t('common.buyDefault')}
                                placeholder={t('placeholder.inputBuyDefault')}
                                inputProps={rateInputProps}
                              />
                            </Box>
                          </Grid>
                          <Grid item>
                            <Typography className={commonClasses.pre}>{t('common.exchangeUpdateTip')}</Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <Grid container direction="column" spacing={3}>
                          <Grid item>
                            <Box paddingTop={1} paddingBottom={2}>
                              <Typography variant="h6" className={commonClasses.bold}>
                                {t('common.exchangeDiff')}
                              </Typography>
                            </Box>
                            <FormField<FinanceExchangeRate, TextFieldProps>
                              context={FormContext}
                              component={NumberInput}
                              name="buy_diff"
                              fullWidth
                              required
                              label={t('common.buyDiff')}
                              placeholder={t('placeholder.inputBuyDiff')}
                              inputProps={diffInputProps}
                            />
                          </Grid>
                          <Grid item>
                            <FormField<FinanceExchangeRate, TextFieldProps>
                              context={FormContext}
                              component={NumberInput}
                              name="buy_diff_for_sell"
                              fullWidth
                              required
                              label={t('common.buyDiffForSell')}
                              placeholder={t('placeholder.inputSellDiff')}
                              inputProps={diffInputProps}
                            />
                          </Grid>
                          <Grid item>
                            <Typography className={commonClasses.pre}>{t('common.exchangeDiffNote')}</Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <Grid container direction="column" spacing={3}>
                          <Grid item>
                            <Box paddingTop={1} paddingBottom={2}>
                              <Typography variant="h6" className={commonClasses.bold}>
                                {t('common.currentExchangeRate')}
                              </Typography>
                            </Box>
                            <NumberInput
                              value={rate.buy}
                              fullWidth
                              disabled
                              label={t('common.deposit')}
                              inputProps={diffInputProps}
                            />
                          </Grid>
                          <Grid item>
                            <NumberInput
                              value={rate.sell}
                              name="sell_diff"
                              fullWidth
                              disabled
                              label={t('common.withdraw')}
                              inputProps={diffInputProps}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
                <Grid item>
                  <RequiredText />
                </Grid>
                {writable && (
                  <Grid item>
                    <Grid container justify="flex-end">
                      <FormSubmitButton
                        context={FormContext}
                        component={Button}
                        className={commonClasses.purpleGradualButton}
                        type="submit"
                      >
                        {t('common.saveSetting')}
                      </FormSubmitButton>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </FormStateProvider>
          </LoadingAndErrorFrame>
        </Box>
      </Paper>
    </Box>
  )
}

export default React.memo(FinanceParameterPage)

import React, { useState, useMemo, useCallback } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { PaginationRes, AdminDepositStatusType, ThirdPartyDepositSlipAgent } from '@golden/gdk-admin'
import { searchToRequest } from './DepositThirdPartySlipForm'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import useT from '../../../i18ns/admin/useT'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { createDefaultPaginationData, createTableData, formatMoney } from '../../../utils/default/TableHelper'
import { usePageFlow } from '../../../utils/default/PageFlowHook'
import { useRequestFromSearch, useGetDataByPayload, usePaginationClickAndChangeUrl, useReload } from '../../../utils/default/ComplexFlowHook'
import DateTime from '../../default/present/DateTime'
import allRoute from '../route/route'
import LoadingAndErrorFrame from '../../default/frames/LoadingAndErrorFrame'
import CoreTable from '../../default/present/CoreTable'
import { useChecker } from '../../../utils/admin/AdminRouteHook'
import PointsCell from '../../default/present/PointsCell'
import adminDepositStatusName from '../../../constants/admin/adminDepositStatusName'
import depositTypeName from '../../../constants/default/depositTypeName'
import DepositThirdPartySlipButtons from './DepositThirdPartySlipButtons'
import clsx from 'clsx'
import agentTypeName from '../../../constants/default/agentTypeName'

const useStyles = makeStyles(() => ({
  noMargin: {
    margin: '0px auto'
  },
  usdtText: {
    color: '#aaaaaa',
    margin: '0px auto'
  },
  noBorder: {
    border: 'none'
  }
}))

interface RowType {
  id: number
  layer: string
  serial: string
  account: string
  money: React.ReactElement
  fee: React.ReactElement
  courier: string
  method: string
  status: React.ReactElement
  sender: string
  operator: string
  apply: React.ReactElement
  confirm: React.ReactElement
  exchangedActivity: string
  real_name: string
}

const DepositThirdPartySlipAgentTable: React.FC<{ summaryReloadFlag: boolean, summaryReload: () => void }> = (props) => {
  const { summaryReloadFlag, summaryReload } = props
  const classes = useStyles()
  const commonClasses = useCommonStyles()
  const { t } = useT()
  const gdk = useGDK()

  const { reloadFlag: listReloadFlag, reload: listReload } = useReload()
  const reloadFlag = useMemo(() => summaryReloadFlag || listReloadFlag, [summaryReloadFlag, listReloadFlag])
  const reload = useCallback(() => {
    summaryReload()
    listReload()
  }, [listReload, summaryReload])

  const [list, setList] = useState<PaginationRes<ThirdPartyDepositSlipAgent[]>>(createDefaultPaginationData([]))
  const pageFlow = usePageFlow()
  const request = useRequestFromSearch({ searchToRequest })
  const writable = useChecker()
  useGetDataByPayload({
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    payload: request!,
    gdkFunc: (payload) => gdk.deposit.getThirdPartyDepositSlipAgentList(payload),
    gdkFuncDependencies: [gdk, reloadFlag],
    onBeforeFetch: pageFlow.setLoadingStart,
    onSuccess: (res: PaginationRes<ThirdPartyDepositSlipAgent[]>) => {
      setList(res)
      pageFlow.setContentShow()
    },
    onError: pageFlow.setGDKError,
    canLoadData: request !== undefined
  })
  const tableClasses = useMemo(() => ({
    head: clsx(commonClasses.pinkTableHead, classes.noBorder),
    row: commonClasses.tableRow,
    cellHead: commonClasses.tableCellHead,
    cellBody: commonClasses.pre
  }), [commonClasses, classes])
  const rows: RowType[] = useMemo(() => {
    return list.data.map((item) => {
      return {
        id: item.id,
        layer: item.agent_type ? t(agentTypeName[item.agent_type]) : '',
        serial: item.order_number,
        account: item.account,
        money: (<React.Fragment>
          <PointsCell points={item.cash} />
          { item.cash_crypto && (<p className={classes.usdtText}>{ formatMoney(item.cash_crypto) } USDT</p>) }
        </React.Fragment>),
        fee: (<React.Fragment>
          <PointsCell points={item.fee} />
          { item.crypto_fee && (<p className={classes.usdtText}>{ formatMoney(item.crypto_fee) } USDT</p>) }
        </React.Fragment>),
        courier: item.forwarder_name,
        method: t(depositTypeName[item.method]),
        status: writable && item.status === AdminDepositStatusType.IN_PROGRESS ? (<DepositThirdPartySlipButtons type="agent" id={item.id} serial={item.order_number} reload={reload} />) : (<p>{t(adminDepositStatusName[item.status])}</p>),
        real_name: item.real_name ? item.real_name : '-',
        sender: item.creator_account,
        operator: item.operator,
        apply: (<DateTime time={item.created_at} />),
        confirm: item.finished_at ? (<DateTime time={item.finished_at} />) : (<p></p>),
        exchangedActivity: item.activity_name ? item.activity_name : '-'
      } as RowType
    })
  }, [list.data, reload, t, writable, classes.usdtText])
  const data = useMemo(() => {
    return createTableData<RowType>(
      {
        id: {
          label: '',
          value: 'id'
        },
        layer: {
          label: t('common.agentLayer'),
          value: 'layer',
          align: 'center'
        },
        serial: {
          label: t('common.orderNumber'),
          value: 'serial',
          align: 'center'
        },
        account: {
          label: t('common.account'),
          value: 'account',
          align: 'center'
        },
        money: {
          label: t('common.orderMoney'),
          value: 'money',
          align: 'center',
          width: 100
        },
        fee: {
          label: t('common.fee'),
          value: 'fee',
          align: 'center',
          width: 100
        },
        courier: {
          label: t('common.courierName'),
          value: 'courier',
          align: 'center'
        },
        method: {
          label: t('common.depositWay'),
          value: 'method',
          align: 'center'
        },
        status: {
          label: t('common.orderStatus2'),
          value: 'status',
          align: 'center'
        },
        real_name: {
          label: t('common.depositName'),
          value: 'real_name',
          align: 'center'
        },
        sender: {
          label: t('common.createSlipPerson'),
          value: 'sender',
          align: 'center'
        },
        operator: {
          label: t('common.updateBy'),
          value: 'operator',
          align: 'center'
        },
        apply: {
          label: t('common.applyTime'),
          value: 'apply',
          align: 'center'
        },
        confirm: {
          label: t('common.confirmAt'),
          value: 'confirm',
          align: 'center'
        },
        exchangedActivity: {
          label: t('common.exchangedActivity'),
          value: 'exchangedActivity',
          align: 'center'
        }
      },
      [
        'layer',
        'serial',
        'account',
        'money',
        'fee',
        'courier',
        'method',
        'status',
        'real_name',
        'sender',
        'operator',
        'apply',
        'confirm',
        'exchangedActivity'
      ],
      rows,
      'id'
    )
  }, [rows, t])
  const handlePagination = usePaginationClickAndChangeUrl({
    request,
    encodePath: allRoute.depositThirdPartySlip.encodePath
  })

  if (request === undefined) return null
  return (
    <LoadingAndErrorFrame { ...pageFlow.status }>
      <CoreTable
        classes={tableClasses}
        data={data}
        total={list.total}
        showPagination
        page={request.page}
        onChangePage={handlePagination}
      />
    </LoadingAndErrorFrame>
  )
}

export default React.memo(DepositThirdPartySlipAgentTable)

import React, { useEffect } from 'react'
import Box from '@material-ui/core/Box'
import AdminReportInAndOutForm from '../../../components/admin/adminReport/AdminReportInAndOutForm'
import AdminReportInAndOutPlatform from '../../../components/admin/adminReport/AdminReportInAndOutPlatform'
import AdminReportInAndOutRevenue from '../../../components/admin/adminReport/AdminReportInAndOutRevenue'
import AdminReportInAndOutDepositAndWithdraw from '../../../components/admin/adminReport/AdminReportInAndOutDepositAndWithdraw'
import AdminReportInAndOutTransfer from '../../../components/admin/adminReport/AdminReportInAndOutTransfer'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { Branch } from '@golden/gdk-admin'

export enum AdminReportInAndOutReport {
  PLATFORM = 'PLATFORM',
  REVENUE = 'REVENUE',
  DEPOSIT_AND_WITHDRAW = 'DEPOSIT_AND_WITHDRAW',
  TRANSFER = 'TRANSFER'
}

const AdminReportInAndOutPage: React.FC = () => {
  const gdk = useGDK()
  useEffect(() => {
    gdk.trunk.trigger([Branch.PLATFORMS])
  }, [gdk])
  return (
    <Box paddingY={5}>
      <Box paddingX={5}>
        <AdminReportInAndOutForm />
      </Box>
      <Box marginTop={2}>
        <AdminReportInAndOutPlatform />
      </Box>
      <Box marginTop={2}>
        <AdminReportInAndOutRevenue />
      </Box>
      <Box marginTop={2}>
        <AdminReportInAndOutDepositAndWithdraw />
      </Box>
      <Box marginTop={2}>
        <AdminReportInAndOutTransfer />
      </Box>
    </Box>
  )
}

export default React.memo(AdminReportInAndOutPage)

import React, { createContext, useMemo, useState, useEffect, useCallback, Dispatch, SetStateAction, FormEvent } from 'react'
import { ForwarderConfig, ForwarderType } from '@golden/gdk-admin'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import MuiTextField from '@material-ui/core/TextField'
import DropDown from '../../default/form/DropDown'
import RequiredText from '../../default/form/RequiredText'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import useT from '../../../i18ns/admin/useT'
import { InitialFormFunc, useGetData } from '../../../utils/default/ComplexFlowHook'
import ScrollablePaper from '../../default/present/ScrollablePaper'
import { FormPropType } from '../../../utils/default/FormHelper'
import { useDebouncedCallback } from 'use-debounce'
import { SecretBlock } from './CourierSecretBlock'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { usePageFlow } from '../../../utils/default/PageFlowHook'
import LoadingAndErrorFrame from '../../../components/default/frames/LoadingAndErrorFrame'
import OnOffCheckbox from '../../../components/default/form/OnOffCheckbox'

export interface EditFinanceCourierFormType {
  slug: ForwarderType | string
  name: string
  secret: Record<string, string>
  is_active: boolean
}

export const initialForm: InitialFormFunc<EditFinanceCourierFormType> = (defaultForm) => ({
  slug: '',
  name: '',
  secret: {},
  is_active: false,
  ...defaultForm
})

export interface EditFinanceCourierErrorType {
  slug: string | null
  name: string | null
  secret: Record<string, string | null>
  is_active: string | null
}

export const initialError = (): EditFinanceCourierErrorType => ({
  slug: null,
  name: null,
  secret: {},
  is_active: null
})

interface PropTypes extends FormPropType<EditFinanceCourierFormType> {
  title: string
  okText: string
  onBack: () => void
  disableForwarder?: boolean
  requireSecret?: boolean
  formError?: EditFinanceCourierErrorType
}

export interface FormStateType {
  form: EditFinanceCourierFormType
  setForm: Dispatch<SetStateAction<EditFinanceCourierFormType>>
  error: EditFinanceCourierErrorType
  setError: Dispatch<SetStateAction<EditFinanceCourierErrorType>>
}
export const FormContext = createContext<FormStateType>({
  form: initialForm(),
  setForm: () => {},
  error: initialError(),
  setError: () => {}
})

const nameInputProps = {
  maxLength: 20
}
const TextField = React.memo(MuiTextField)

const EditFinanceCourierForm: React.FC<PropTypes> = (props) => {
  const { title, okText, onBack, onSubmit, disableForwarder, defaultValue, formError, requireSecret } = props
  const classes = useCommonStyles()
  const { t } = useT()
  const gdk = useGDK()
  const pageFlow = usePageFlow()
  const [form, setForm] = useState(initialForm())
  const [error, setError] = useState<EditFinanceCourierErrorType>(initialError())
  const [lastSubmitForm, setLastSubmitForm] = useState(defaultValue)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { setForm(defaultValue) }, [defaultValue])

  const [configs, setConfigs] = useState<ForwarderConfig[]>([])
  useGetData({
    gdkFunc: () => gdk.finance.getForwarderConfig(),
    gdkFuncDependencies: [gdk],
    onBeforeFetch: pageFlow.setLoadingStart,
    onSuccess: (res: ForwarderConfig[]) => {
      setConfigs(res)
      pageFlow.setContentShow()
    },
    onError: pageFlow.setGDKError
  })

  const forwarderOptions = useMemo(
    () => configs.map((config) => ({ value: config.slug, name: config.name })),
    [configs]
  )

  const secrets = useMemo(
    () => configs.find((config) => config.slug === form.slug)?.secret ?? [],
    [configs, form.slug]
  )
  const formErrorCourier = useMemo(() => formError?.name, [formError])

  const disabledSubmit = useMemo(
    () =>
      !form.slug || !form.name ||
      !form.name || form.name === lastSubmitForm.name ||
      // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
      (requireSecret && secrets.some((secret: { key: string }) => !form.secret[secret.key])) ||
      Object.keys(error).some((key) => key !== 'secret' && !!error[key as keyof EditFinanceCourierErrorType]) ||
      Object.keys(error.secret).some((key) => !!error.secret[key as keyof EditFinanceCourierErrorType]),
    [error, form.name, form.secret, form.slug, lastSubmitForm.name, requireSecret, secrets])

  const [handleDebouncedSubmit] = useDebouncedCallback(() => {
    setLastSubmitForm(form)
    onSubmit(form)
  }, 200)
  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    if (disabledSubmit) return
    handleDebouncedSubmit()
  }

  return (
    <FormContext.Provider value={{ form, setForm, error, setError }}>
      <LoadingAndErrorFrame {...pageFlow.status}>
        <ScrollablePaper marginX={5}>
          <form onSubmit={handleSubmit}>
            <Box padding={4}>
              <Grid container direction="column" spacing={2}>
                <Grid item>
                  <Box
                    paddingY={1.25}
                    paddingX={2}
                    className={classes.pinkTitleBar}
                  >
                    <Typography variant="h5">
                      {title}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item>
                  <Grid container direction="row" alignItems="flex-end" spacing={2}>
                    <Grid item xs={12} md={6} lg={3}>
                      <DropDown
                        value={form.slug}
                        onChange={useCallback((e) => {
                          const slug = e.target.value as string
                          setForm({ ...form, slug, secret: {} })
                          setError({ ...error, secret: {} })
                          if (slug === '') {
                            setError({ ...error, slug: t('error.mustNotEmpty') })
                            return
                          }
                          setError({ ...error, slug: null })
                        }, [form, error, setError, t])}
                        error={error.slug !== null}
                        helperText={error.slug ?? ''}
                        label={t('common.forwarder')}
                        options={forwarderOptions}
                        fullWidth
                        required
                        // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
                        disabled={disableForwarder || false}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                      <TextField
                        name="name"
                        onChange={useCallback((e) => {
                          const name = e.target.value as string
                          setForm({ ...form, name })
                          if (name === '') {
                            setError({ ...error, name: t('error.mustNotEmpty') })
                            return
                          }
                          if (formErrorCourier && name === lastSubmitForm.name) {
                            setError({ ...error, name: formErrorCourier.replace('name ', t('common.courierName')) })
                            return
                          }
                          setError({ ...error, name: null })
                        }, [form, formErrorCourier, lastSubmitForm.name, error, t])}
                        error={error.name !== null}
                        helperText={error.name ?? ''}
                        value={form.name}
                        label={t('common.courierName')}
                        placeholder={t('placeholder.inputCourierName')}
                        inputProps={nameInputProps}
                        fullWidth
                        required
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                      <OnOffCheckbox
                        label={t('common.isOpenCourier')}
                        value={form.is_active}
                        onChange={useCallback((event) => {
                          // eslint-disable-next-line @typescript-eslint/naming-convention
                          const is_active = event.target.checked
                          setForm((form) => ({ ...form, is_active }))
                        }, [])}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <SecretBlock context={FormContext} secrets={secrets} requireSecret={requireSecret}/>
                <Grid item>
                  <RequiredText />
                </Grid>
              </Grid>
            </Box>
            <Box paddingY={3} paddingX={5} display="flex" flexDirection="row" justifyContent="flex-end">
              <Box paddingX={2}>
                <Button
                  className={classes.greyButton}
                  onClick={onBack}
                >
                  {t('common.cancel')}
                </Button>
              </Box>
              <Button
                type="submit"
                className={classes.purpleGradualButton}
                disabled={disabledSubmit}
              >
                {okText}
              </Button>
            </Box>
          </form>
        </ScrollablePaper>
      </LoadingAndErrorFrame>
    </FormContext.Provider>
  )
}

export default React.memo(EditFinanceCourierForm)

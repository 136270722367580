import React, { createContext, Dispatch, SetStateAction } from 'react'
import Box from '@material-ui/core/Box'
import StateProvider from '../../../providers/default/StateProvider'
import ScrollablePaper from '../../../components/default/present/ScrollablePaper'
import FinancePiggyBankToolbar from '../../../components/admin/finance/FinancePiggyBankToolbar'
import FinancePiggyBankTable from '../../../components/admin/finance/FinancePiggyBankTable'

export interface FinancePiggyBankToolbarFormType {
  status: 0 | 1 | '--'
  account: string
  receiver: string
  page: number
}

export const FinancePiggyBankFormContext = createContext<[FinancePiggyBankToolbarFormType, Dispatch<SetStateAction<FinancePiggyBankToolbarFormType>>]>([
  {
    status: '--',
    account: '',
    receiver: '',
    page: 1
  },
  () => {}
])

const FinancePiggyBankPage: React.FC = () => {
  return (
    <Box paddingY={5}>
      <ScrollablePaper marginX={5}>
        <StateProvider
          context={FinancePiggyBankFormContext}
          defaultValue={{ status: '--', account: '', receiver: '', page: 1 }}
        >
          <Box padding={4}>
            <FinancePiggyBankToolbar />
          </Box>
          <Box paddingTop={1} paddingBottom={5} paddingX={4}>
            <FinancePiggyBankTable />
          </Box>
        </StateProvider>
      </ScrollablePaper>
    </Box>
  )
}

export default React.memo(FinancePiggyBankPage)

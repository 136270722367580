import { DepositCategoryType, DepositMethodType } from '@golden/gdk-admin'
import TKeyType from '../../i18ns/admin/TKeyType'

const depositTypeName: { [key in DepositMethodType | DepositCategoryType]: TKeyType } = {
  [DepositMethodType.ONLINE]: 'deposit.online',
  [DepositMethodType.ONLINE_MANUAL]: 'deposit.onlineManual',
  [DepositMethodType.ONLINE_BANK]: 'deposit.onlineBank',
  [DepositMethodType.SCAN_WECHAT]: 'deposit.scanWechat',
  [DepositMethodType.SCAN_ALIPAY]: 'deposit.scanAlipay',
  [DepositMethodType.GOUBAO]: 'deposit.goubao',
  [DepositMethodType.TOPAY]: 'deposit.topay',
  [DepositMethodType.EBPAY]: 'deposit.ebpay',
  [DepositMethodType.CRYPTO]: 'deposit.crypto',
  [DepositMethodType.CRYPTO_TRC]: 'deposit.cryptoTrc',
  [DepositMethodType.CRYPTO_ERC]: 'deposit.cryptoErc',
  [DepositMethodType.MANUAL]: 'deposit.manual',
  [DepositMethodType.MANUAL_DEPOSIT]: 'deposit.manualDeposit',
  [DepositMethodType.MANUAL_EVENT]: 'deposit.manualActivity',
  [DepositMethodType.MANUAL_OTHER]: 'deposit.manualOthers',
  [DepositMethodType.MANUAL_RISK_ADJUSTMENT]: 'deposit.manualRisk',
  [DepositMethodType.UNION_PAY_QUICK]: 'deposit.unionPayQuick',
  [DepositMethodType.UNION_PAY_CLOUD]: 'deposit.unionPayCloud',
  [DepositMethodType.CGPAY]: 'deposit.cgpay',
  [DepositMethodType.CGPAY_USDT]: 'deposit.cgpay_usdt',
  [DepositMethodType.KOIPAY]: 'deposit.koipay',
  [DepositMethodType.E_CNY]: 'deposit.e_cny',
  [DepositMethodType.PAY988]: 'deposit.pay988',
  [DepositMethodType.PAY808]: 'deposit.pay808',
  [DepositMethodType.KDPAY]: 'deposit.kdpay'
}

export const manualDepositTypeName = {
  [DepositMethodType.MANUAL]: 'deposit.manual',
  [DepositMethodType.MANUAL_DEPOSIT]: 'deposit.manualDeposit',
  [DepositMethodType.MANUAL_EVENT]: 'deposit.manualActivity',
  [DepositMethodType.MANUAL_OTHER]: 'deposit.manualOthers',
  [DepositMethodType.MANUAL_RISK_ADJUSTMENT]: 'deposit.manualRisk'
}

export default depositTypeName

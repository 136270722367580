import React, { Context, useMemo, useContext } from 'react'
import { PlatformType, FetchTaskOrder } from '@golden/gdk-admin'
import { addHours, startOfDay, getUnixTime } from 'date-fns'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Typography from '@material-ui/core/Typography'
import DateTimePicker, { PropTypes as DateTimePickerProps } from '../../default/form/DateTimePicker'
import FormStateProvider from '../../default/form/FormStateProvider'
import FormField from '../../default/form/FormField'
import FormSubmitButton from '../../default/form/FormSubmitButton'
import { DialogPayloadContext } from '../../../views/admin/playerReport/PlayerReportFetchTaskPage'
import { FormState, ValueGetter, FormValidation } from '../../../utils/default/FormHook'
import { InitialFormFunc } from '../../../utils/default/ComplexFlowHook'
import { getValueFromValue } from '../../../utils/default/FormHelper'
import useT from '../../../i18ns/admin/useT'
import { createValidateNotEmpty, createValidateDate } from '../../../utils/default/Validator'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import { getWalletName } from '../../../utils/default/PlatformHelper'
import useGDKStore from '../../../providers/admin/gdk/useGDKStore'

export interface FormType {
  startAt: Date
}

export const initialForm: InitialFormFunc<FormType> = (defaultForm) => ({
  startAt: startOfDay(new Date()),
  ...defaultForm
})

export const formToRequest = (platform: PlatformType) => (form: FormType): FetchTaskOrder => ({
  platform_id: platform,
  start_at: getUnixTime(form.startAt)
})

const getValueFromEvent: ValueGetter<FormType> = {
  startAt: getValueFromValue
}

export interface Payload {
  context: Context<FormState<FormType>>
  onSubmit: (form: FormType) => FormType
  onCancel: () => void
  platform: PlatformType
  defaultValue: FormType
  open: boolean
}

const EndAt: React.FC<{ context: Context<FormState<FormType>> }> = React.memo((props) => {
  const { context } = props
  const { t } = useT()
  const { value } = useContext(context)

  return (
    <DateTimePicker
      label={t('common.endAt')}
      fullWidth
      required
      disabled
      value={addHours(value.startAt, 2)}
    />
  )
})

const FetchTaskDialog: React.FC = () => {
  const classes = useCommonStyles()
  const platforms = useGDKStore.platform.platforms()
  const [payload] = useContext(DialogPayloadContext)
  const {
    open,
    context,
    defaultValue,
    onSubmit,
    onCancel,
    platform
  } = payload
  const { t } = useT()
  const validation = useMemo(() => {
    return {
      startAt: [
        {
          func: createValidateNotEmpty<FormType>('startAt', t),
          when: ['change', 'beforeClickSubmit']
        },
        {
          func: createValidateDate<FormType>('startAt', t),
          when: ['change', 'beforeClickSubmit']
        }
      ]
    } as FormValidation<FormType>
  }, [t])
  return (
    <Dialog open={open} fullWidth maxWidth="sm">
      <Box padding={2} className={classes.dialogPinkHeader}>
        {t('common.gameStore')}：{getWalletName(platform, platforms)}
      </Box>
      <FormStateProvider
        context={context}
        defaultValue={defaultValue}
        onSubmit={onSubmit}
        getValueFromEvent={getValueFromEvent}
        validation={validation}
      >
        <DialogContent>
          <Box padding={3}>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <Grid container direction="row" spacing={2}>
                  <Grid item xs={6}>
                    <FormField<FormType, DateTimePickerProps>
                      context={context}
                      component={DateTimePicker}
                      name="startAt"
                      label={t('common.beginAt')}
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <EndAt context={context} />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Typography className={classes.pre}>
                  {t('common.sabaTimeTipInDialog')}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Box padding={3} width="100%">
            <Grid container direction="row" justify="center" spacing={2}>
              <Grid item>
                <Button
                  className={classes.greyButton}
                  onClick={onCancel}
                >
                  {t('common.cancel')}
                </Button>
              </Grid>
              <Grid item>
                <FormSubmitButton
                  context={context}
                  component={Button}
                  className={classes.purpleGradualButton}
                  type="submit"
                >
                  {t('common.confirm')}
                </FormSubmitButton>
              </Grid>
            </Grid>
          </Box>
        </DialogActions>
      </FormStateProvider>
    </Dialog>
  )
}

export default React.memo(FetchTaskDialog)

import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import useT from '../../../i18ns/admin/useT'
import { useDialogStyles } from '../../../utils/admin/StyleHook'
import CustomThemeType from '../../../themes/admin/CustomThemeType'
import { EditAnnouncementFormType } from './EditAnnouncementForm'
import { formatDateTime } from '../../../utils/default/TableHelper'
import { renderContent } from '@golden/tiptap-react'
import { useAsyncEffect } from '../../../utils/default/useAsyncEffect'

const useStyles = makeStyles((theme: CustomThemeType) => ({
  title: {
    textAlign: 'center',
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(2),
    color: theme.custom.palette.text.dialog,
    fontSize: theme.custom.typography.dialog
  },
  content: {
    marginRight: theme.spacing(6),
    marginLeft: theme.spacing(6),
    border: 'solid 1px #e7e7e7'
  },
  text: {
    ...theme.typography.body1
  },
  formTitle: {
    fontSize: theme.typography.h6.fontSize,
    fontWeight: 600,
    color: theme.palette.common.black,
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5)
  }
}))

interface PropTypes {
  title: string
  open: boolean
  onCancel: () => void
  onOK: () => void
  form: EditAnnouncementFormType
}

const AnnouncementConfirmDialog: React.FC<PropTypes> = (props) => {
  const { title, open, onCancel, onOK, form } = props
  const classes = useStyles()
  const dialogClasses = useDialogStyles()
  const { t } = useT()
  const [content, setContent] = useState('')
  useAsyncEffect(async (mounted) => {
    if (!mounted) return
    setContent(await renderContent(JSON.stringify(form.content.content)))
  }, [form.content])
  return (
    <Dialog maxWidth="sm" open={open}>
      <DialogTitle className={classes.title}>{title}</DialogTitle>
      <DialogContent dividers className={classes.content}>
        <Typography className={classes.formTitle}>{t('common.titleWithColon', { content: form.title })}</Typography>
        <Typography>
          {`${t('common.beginAtWithColon', { content: form.beginAt ? formatDateTime(form.beginAt) : '' })} ${t('common.endAtWithColon', { content: form.endAt ? formatDateTime(form.endAt) : form.endAt })}`}
        </Typography>
        <Box component='span' className={classes.text} dangerouslySetInnerHTML={{ __html: content }} />
      </DialogContent>
      <DialogActions>
        <Grid
          container
          direction="row"
          alignItems="center"
          justify="center"
        >
          <Button onClick={onCancel} classes={{ root: dialogClasses.cancelButton }}>
            {t('common.cancel')}
          </Button>
          <Button onClick={onOK} classes={{ root: dialogClasses.okButton }}>
            {t('common.confirm')}
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  )
}

export default React.memo(AnnouncementConfirmDialog)

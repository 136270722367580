import React, { ChangeEvent } from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormGroup from '@material-ui/core/FormGroup'
import Checkbox from '@material-ui/core/Checkbox'
import { useInternValue } from '../../../utils/default/FormHook'

const useStyles = makeStyles(() => ({
  label: {
    fontWeight: 600
  }
}))

export interface PropTypes {
  label: string
  value?: string[] | number[]
  defaultValue?: string[] | number[]
  onChange?: (value: string[] | number[]) => void
  options: Array<{
    name: string
    value: string | number | undefined
    disabled?: boolean
  }>
  disabled?: boolean
  fullWidth?: boolean
  placeholder?: string
  onBlur?: () => void
  onFocus?: () => void
  helperText?: string
  error?: boolean
  autoFocus?: boolean
  required?: boolean
  classes?: Partial<Record<'label', string>>
}

const CheckboxGroup: React.FC<PropTypes> = (props) => {
  const classes = useStyles()
  const { label, options, value, defaultValue, onChange, disabled, required, classes: classesProps } = props
  const [internValue, setInternValue] = useInternValue<string[] | number[]>(
    defaultValue ?? value ?? [],
    value
  )
  const handleChange = (value: string | number | undefined) => (event: ChangeEvent<{ checked: boolean }>) => {
    const checked = event.target.checked
    let newState = []
    if (value) {
      if (checked) {
        newState = (internValue as any[]).concat(value)
      } else {
        newState = (internValue as any[]).filter((item) => item !== value)
      }
      setInternValue(newState)
      if (onChange) onChange(newState)
    }
  }
  return (
    <FormControl component="fieldset" disabled={disabled}>
      <Typography className={clsx(classes.label, classesProps?.label)}>{label}{required && '*'}</Typography>
      <FormGroup
        row
      >
        {options.map((option) => (
          <FormControlLabel
            key={option.value}
            label={option.name}
            disabled={option.disabled}
            control={
              <Checkbox
                checked={(internValue as any[]).includes(option.value)}
                onChange={handleChange(option.value)}
              />
            }
          />
        ))}
      </FormGroup>
    </FormControl>
  )
}

export default React.memo(CheckboxGroup)

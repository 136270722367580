import React, { useEffect } from 'react'
import Box from '@material-ui/core/Box'
import PlayerReportProfitForm from '../../../components/admin/playerReport/profit/PlayerReportProfitForm'
import PlayerReportProfitTable from '../../../components/admin/playerReport/profit/PlayerReportProfitTable'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { Branch } from '@golden/gdk-admin'

const PlayerReportProfitPage: React.FC = () => {
  const gdk = useGDK()
  useEffect(() => {
    gdk.trunk.trigger([Branch.PLATFORMS])
  }, [gdk])
  return (
    <Box paddingY={5}>
      <Box paddingX={5}>
        <PlayerReportProfitForm />
      </Box>
      <Box paddingTop={3}>
        <PlayerReportProfitTable />
      </Box>
    </Box>
  )
}

export default React.memo(PlayerReportProfitPage)

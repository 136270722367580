import React, { Dispatch, SetStateAction, useContext, useState } from 'react'
import { Link } from 'react-router-dom'
import { getTime, startOfDay, endOfDay, subMonths, differenceInDays } from 'date-fns'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import useT from '../../../i18ns/admin/useT'
import { useGetDataByPayload, useReload } from '../../../utils/default/ComplexFlowHook'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { usePageFlow } from '../../../utils/default/PageFlowHook'
import { AdminPlayerInfo, CountryCallingCode, FeatureFlag, PlayerStatusType } from '@golden/gdk-admin'
import LoadingAndErrorFrame from '../../default/frames/LoadingAndErrorFrame'
import playerStatusName from '../../../constants/default/playerStatusName'
import playerRankName from '../../../constants/default/playerRankName'
import { formatDateTime } from '../../../utils/default/TableHelper'
import allRoute from '../route/route'
import PlayerPhoneInput from './PlayerPhoneInput'
import PlayerEmailInput from './PlayerEmailInput'
import { InfoContext } from '../../../views/admin/player/PlayerDetailPage'
import PlayerNicknameInput from './PlayerNicknameInput'
import PlayerFeatureFlagSwitch from './PlayerFeatureFlagSwitch'
import { forkJoin } from 'rxjs'
import PlayerMemoInput from './PlayerMemoInput'
import { uniq } from '@golden/utils'
import pmVersionName from '../../../constants/default/pmVersionName'

interface PropTypes {
  id: number
  section: string
  reloadFlag: boolean
  loaded: Dispatch<SetStateAction<string>>
}

const chipBackground: { [key in PlayerStatusType]: string } = {
  [PlayerStatusType.NORMAL]: '#72d476',
  [PlayerStatusType.NO_CARD]: '#fb9678',
  [PlayerStatusType.SUSPENDED]: '#ff5f5f'
}

const PlayerDetailInfoSection: React.FC<PropTypes> = (props) => {
  const { id, section, reloadFlag: reloadFlagProps, loaded } = props
  const gdk = useGDK()
  const classes = useCommonStyles()
  const { t } = useT()
  const pageFlow = usePageFlow()
  const { reload, reloadFlag } = useReload()
  const startAt = getTime(startOfDay(new Date()))
  const endAt = getTime(endOfDay(new Date()))
  const page = 1
  const [res, setRes] = useContext(InfoContext)
  const [featureFlag, setFeatureFlag] = useState<Array<{ slug: FeatureFlag, is_enabled: boolean }>>([])

  useGetDataByPayload({
    payload: id,
    gdkFunc: (payload) => forkJoin([gdk.player.getPlayerInfo(payload), gdk.featureFlag.getPlayerFeatureFlag(payload)]),
    gdkFuncDependencies: [gdk, reloadFlag, reloadFlagProps],
    onBeforeFetch: pageFlow.setLoadingStart,
    onSuccess: (res: [AdminPlayerInfo, Array<{ slug: FeatureFlag, is_enabled: boolean }>]) => {
      setRes(res[0])
      setFeatureFlag(res[1])
      pageFlow.setContentShow()
      loaded(section)
    },
    onError: pageFlow.setGDKError
  })

  return (
    <div id={section}>
      <Paper>
        <Box padding={4}>
          <Box
            paddingY={1.25}
            paddingX={2}
            className={classes.pinkTitleBar}
          >
            <Typography variant="h5">
              {`${t('common.playerInformation')} ( ${uniq((res?.agent_chain ?? [])).concat(res?.account ?? '').join(' > ')} )`}
            </Typography>
          </Box>
          <Box paddingY={2}>
            <LoadingAndErrorFrame { ...pageFlow.status }>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={12} md={6} lg={3}>
                  <TextField
                    label={(
                      <React.Fragment>
                        {t('common.playerAccount')}
                        <Box component="span" marginLeft={1} className={classes.chipText} style={{ backgroundColor: chipBackground[res.status] }}>
                          {t(playerStatusName[res.status])}
                        </Box>
                      </React.Fragment>
                    )}
                    value={res.account}
                    disabled
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <PlayerNicknameInput id={id} nickname={res.nickname} reload={reload} />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <TextField
                    label={t('common.playerRank')}
                    value={t(playerRankName[res.rank])}
                    disabled
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <TextField
                    label={t('common.playerLayer')}
                    value={res.layer_name ?? t('common.none')}
                    disabled
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <TextField
                    label={t('common.belongStaffAccount')}
                    value={res.staff}
                    disabled
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <TextField
                    label={t('common.registeredAt')}
                    value={`${formatDateTime(res.created_at)}${t('common.nthDay', { day: differenceInDays(new Date(), res.created_at) + 1 })}`}
                    disabled
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <TextField
                    label={t('common.lastLoginAt')}
                    value={res.last_login_at ? formatDateTime(res.last_login_at) : '-'}
                    disabled
                    fullWidth
                  />
                </Grid>
                <Grid container item xs={12} md={6} lg={3}>
                  <PlayerPhoneInput id={id} iddCode={res.idd_code ?? CountryCallingCode.CN} phone={res.phone} reload={reload} />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <PlayerEmailInput id={id} email={res.email} reload={reload} disabled={!res.is_allow_modify} />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <TextField
                    label={t('common.registerIP')}
                    value={res.registered_ip || '-'}
                    disabled
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <TextField
                    label={t('common.loginIP')}
                    // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
                    value={res.login_ip || '-'}
                    disabled
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <TextField
                    label={t('common.siguaId')}
                    value={res.bubble_id ? res.bubble_id : t('common.noSetting')}
                    disabled
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <TextField
                    label={t('common.pmVersion')}
                    value={res.pm_version_switch ? t(pmVersionName[res.pm_version_switch]) : ''}
                    disabled
                    fullWidth
                  />
                </Grid>
                { (['rc', 'cs', 'fm'] as const).map((type) => (
                  <Grid item xs={12} md={6} lg={3} key={type}>
                    <PlayerMemoInput
                      id={id}
                      res={res}
                      type={type}
                      reload={reload}
                    />
                  </Grid>
                )) }
                {
                  featureFlag.map(flag => (
                    <Grid item xs={12} md={6} lg={3}>
                      <PlayerFeatureFlagSwitch id={id} flag={flag} reload={reload}/>
                    </Grid>))
                }
              </Grid>
            </LoadingAndErrorFrame>
          </Box>
          <Grid container justifyContent="flex-end" spacing={2}>
            <Grid item>
              <Button
                component={Link}
                className={classes.purpleGradualButton}
                to={allRoute.playerOperationLogs.encodePath({ param: {}, search: { start_at: getTime(startOfDay(subMonths(new Date(), 3))), end_at: getTime(endOfDay(new Date())), account: res.account, page } })}
              >
                {t('page.playerOperationLogs')}
              </Button>
            </Grid>
            <Grid item>
              <Button
                component={Link}
                className={classes.purpleGradualButton}
                to={allRoute.playerIp.encodePath({ param: {}, search: { start_at: startAt, end_at: endAt, account: res.account, page } })}
              >
                {t('adminPermission.loginIpSearch')}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </div>
  )
}

export default React.memo(PlayerDetailInfoSection)

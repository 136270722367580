import React from 'react'
import useT from '../../../../i18ns/admin/useT'
import { FeedbackHistory, PlayerFeedbackStatusType } from '@golden/gdk-admin'
import CustomThemeType from '../../../../themes/admin/CustomThemeType'
import clsx from 'clsx'
import feedbackStatusName from '../../../../constants/default/feedbackStatusName'
import { Grid, makeStyles } from '@material-ui/core'

export interface PropTypes {
  data: FeedbackHistory
}

const useStyles = makeStyles((theme: CustomThemeType) => ({
  text: {
    padding: theme.spacing(1),
    color: theme.palette.common.white,
    borderRadius: theme.shape.borderRadius
  },
  grey: {
    backgroundColor: '#9D9D9D'
  },
  red: {
    backgroundColor: '#FF5151'
  },
  green: {
    backgroundColor: '#72d476'
  },
  nowrap: {
    whiteSpace: 'nowrap'
  }
}))

const FeedbackStatusGrid: React.FC<PropTypes> = React.memo((props) => {
  const { t } = useT()
  const { data } = props
  const classes = useStyles()
  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <span className={clsx(classes.text, { [classes.green]: (data.search_status === PlayerFeedbackStatusType.RECEIVED) }, { [classes.grey]: (data.search_status !== PlayerFeedbackStatusType.RECEIVED) }, { [classes.red]: (data.search_status === PlayerFeedbackStatusType.PENDING) })}>
          {t(feedbackStatusName[data.search_status])}
        </span>
      </Grid>
    </Grid>
  )
})

export default React.memo(FeedbackStatusGrid)

import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.common.white,
    background: 'linear-gradient(to bottom, #5cf683, #28b24b)',
    '&:hover': {
      background: 'linear-gradient(to bottom, #fffd78 1%, #c4be14)'
    }
  }
}))

interface PropTypes {
  onClick?: () => void
  icon: string
  text: string
}

const BattleFieldButton: React.FC<PropTypes> = (props) => {
  const { icon, onClick, text } = props
  const classes = useStyles()
  return (
    <Button
      classes={classes}
      onClick={onClick}
    >
      <img src={icon} alt="chat" />
      {text}
    </Button>
  )
}

export default React.memo(BattleFieldButton)

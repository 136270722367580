import React, { useCallback, useMemo, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import Typography from '@material-ui/core/Typography'
import MuiButton from '@material-ui/core/Button'
import { SearchToRequestFunc, useGetDataByPayload, useRequestFromSearch } from '../../../utils/default/ComplexFlowHook'
import { useCommonStyles, useDialogStyles } from '../../../utils/admin/StyleHook'
import useT from '../../../i18ns/admin/useT'
import CoreTable from '../../default/present/CoreTable'
import { createTableData, formatMoney } from '../../../utils/default/TableHelper'
import { BcMath, RoundingMode } from '@golden/bcmath'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { FinanceFeeDetailQuery, FinanceFeeGeneralDetails } from '@golden/gdk-admin'
import { usePageFlow } from '../../../utils/default/PageFlowHook'
import LoadingAndErrorFrame from '../../default/frames/LoadingAndErrorFrame'
import { guaranteeNotUndefined, parseInt, pipe } from '../../../utils/default/FormHelper'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'

interface RowType {
  id: number
  name: string
  cash: string
  fee_rate: string
  fee: string
}

export const searchToRequest: SearchToRequestFunc<FinanceFeeDetailQuery> = (search) => {
  const converted = {
    ...search,
    start_at: pipe(
      (value) => guaranteeNotUndefined(value as string),
      parseInt
    )(search.start_at),
    end_at: pipe(
      (value) => guaranteeNotUndefined(value as string),
      parseInt
    )(search.end_at)
  }
  return converted as any
}

const Button = React.memo(MuiButton)

export interface PropTypes {
  handleClose?: () => void
  type: ('agent' | 'golden' | 'player')
  target_id: number
  target_name: string
}

export const defaultProps: PropTypes = {
  type: 'agent',
  target_id: -1,
  target_name: ''
}

const AdminReportProfitFeeDetailsDialog: React.FC<PropTypes> = (props) => {
  const commonClasses = useCommonStyles()
  const dialogClasses = useDialogStyles()
  const { t } = useT()
  const gdk = useGDK()
  const pageFlow = usePageFlow()
  const request = useRequestFromSearch({ searchToRequest })
  const [res, setRes] = useState<FinanceFeeGeneralDetails[]>([])
  useGetDataByPayload({
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    payload: request!,
    gdkFunc: (payload) => {
      switch (props.type) {
        case 'agent':
          return gdk.history.getProfitHistoryFinanceFeeAgentsDetails(props.target_id, payload)
        case 'golden':
          return gdk.history.getProfitHistoryFinanceFeeGoldenDetails(payload)
        case 'player':
          return gdk.history.getProfitHistoryFinanceFeePlayersDetails(props.target_id, payload)
        default:
          throw new Error('wrong type')
      }
    },
    gdkFuncDependencies: [gdk, props],
    onBeforeFetch: pageFlow.setLoadingStart,
    onSuccess: (data: FinanceFeeGeneralDetails[]) => {
      setRes(data)
      pageFlow.setContentShow()
    },
    onError: pageFlow.setGDKError,
    canLoadData: !!props.type && props.target_id > 0
  })

  const [layerTab, setLayerTab] = useState<'self' | 'children'>('children')
  const [yearMonthIndex, setYearMonthIndex] = useState(0)

  const layerTabs: Array<{ value: number | string, label: string }> = useMemo(() => {
    if (props.type === 'agent') {
      return [
        { value: 'children', label: t('common.subordinate') },
        { value: 'self', label: props.target_name }
      ]
    } else {
      return []
    }
  }, [props])

  const yearMonthTabs: Array<{ value: number | string, label: string }> = useMemo(() => {
    if (res?.length && res.length > 1) {
      return res.map((item, index) => ({ value: index, label: item.year_month }))
    } else {
      return []
    }
  }, [props.type, res])

  const currentData = useMemo(() => {
    switch (props.type) {
      case 'agent':
        return res[yearMonthIndex]?.[layerTab] ?? { deposit: [], withdraw: [] }
      case 'golden':
      case 'player':
        return res[yearMonthIndex]?.details ?? { deposit: [], withdraw: [] }
      default:
        return { deposit: [], withdraw: [] }
    }
  }, [props.type, res, yearMonthIndex, layerTab])

  const createFeeTableData = useCallback((rows: RowType[]) => createTableData(
    {
      id: {
        label: '',
        value: 'id'
      },
      name: {
        label: t('common.financeWay'),
        value: 'name',
        align: 'center',
        width: 120
      },
      cash: {
        label: t('common.cash'),
        value: 'cash',
        align: 'right',
        width: 120
      },
      fee_rate: {
        label: t('common.feePercent'),
        value: 'fee_rate',
        align: 'right',
        width: 120
      },
      fee: {
        label: t('common.fee'),
        value: 'fee',
        align: 'right',
        width: 120
      }
    },
    ['name', 'cash', 'fee_rate', 'fee'],
    rows,
    'id'
  ), [t])
  const tableColumns: Array<'deposit' | 'withdraw'> = useMemo(() => {
    if ('withdraw' in currentData) {
      return ['deposit', 'withdraw']
    } else {
      return ['deposit']
    }
  }, [currentData])
  const tables = useMemo(() => tableColumns.map((type) => ({
    title: t(`common.${type}`),
    data: createFeeTableData(currentData[type].map((el, index) => ({
      id: index,
      name: el.name,
      cash: formatMoney(el.cash),
      fee: formatMoney(el.fee),
      fee_rate: BcMath.base(el.fee_rate).round(2, RoundingMode.RoundDown).get() + '%'
    })).concat([{
      id: currentData[type].length,
      name: t('common.winlossCount'),
      cash: formatMoney(currentData[type].map((el) => (el.cash)).reduce((prev, curr) => (Number(prev) + Number(curr)).toString(), '0')),
      fee: formatMoney(currentData[type].map((el) => (el.fee)).reduce((prev, curr) => (Number(prev) + Number(curr)).toString(), '0')),
      fee_rate: '-'
    }]))
  })), [createFeeTableData, currentData, t])
  const tableClasses = useMemo(() => ({
    head: commonClasses.greyTableHead,
    row: commonClasses.tableRow,
    cellHead: commonClasses.tableCellHead
  }), [commonClasses])

  return (
    <Dialog maxWidth="lg" open={true}>
      <Box padding={2} className={commonClasses.dialogPinkHeader}>
        {t('common.financeFee')}
      </Box>
      <DialogContent>
        <Box minWidth="1050px">
          <LoadingAndErrorFrame { ...pageFlow.status }>
            {!!layerTabs.length && (<Tabs
              value={layerTab}
              onChange={(_, value) => {
                setLayerTab(value)
              }}
            >
              { layerTabs.map((tab) => (
                <Tab
                  key={`layerTab-${tab.value}`}
                  value={tab.value}
                  label={tab.label}
                />
              )) }
            </Tabs>)}
            {!!yearMonthTabs.length && (<Tabs
              value={yearMonthIndex}
              onChange={(_, value) => {
                setYearMonthIndex(value)
              }}
            >
              { yearMonthTabs.map((tab) => (
                <Tab
                  key={`yearMonthTab-${tab.value}`}
                  value={tab.value}
                  label={tab.label}
                />
              )) }
            </Tabs>)}
            <Grid container spacing={3}>
              { tables.map((el) => (<Grid item md={ tableColumns.length > 1 ? 6 : 12} key={`dialog-table-${el.title}`}>
                <Box paddingY={1}>
                  <Typography variant="h5" className={dialogClasses.title}>{el.title}</Typography>
                </Box>
                <CoreTable
                  data={el.data}
                  total={el.data.rows.length}
                  hasHeadWithNoData
                  classes={tableClasses}
                />
              </Grid>)) }
            </Grid>
          </LoadingAndErrorFrame>
        </Box>
      </DialogContent>
      <Box display="flex" justifyContent="center">
        <Button onClick={props.handleClose} classes={{ root: dialogClasses.cancelButton }}>
          {t('common.close')}
        </Button>
      </Box>
    </Dialog>
  )
}

export default React.memo(AdminReportProfitFeeDetailsDialog)

import React, { useState, useMemo, useCallback, useEffect, Dispatch, SetStateAction, ChangeEvent } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useDebouncedCallback } from 'use-debounce'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Dialog from '@material-ui/core/Dialog'
import Typography from '@material-ui/core/Typography'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import useT from '../../../i18ns/admin/useT'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { CountryCallingCode, GDKError, SmsProvider, SmsProviderForm, SmsProviderRes, SmsResendWayType } from '@golden/gdk-admin'
import CoreTable from '../../default/present/CoreTable'
import { createTableData } from '../../../utils/default/TableHelper'
import CustomThemeType from '../../../themes/admin/CustomThemeType'
import useGlobalDialog from '../../../providers/admin/dialog/useGlobalDialog'
import { createGlobalDialogConfig } from '../../../utils/default/DialogHelper'
import DropDown from '../../default/form/DropDown'
import { useDialogFlow } from '../../../utils/default/DialogHook'
import { isEqual } from '@golden/utils'
import IddCodeDropDown from '../IddCodeDropDown'

const useStyles = makeStyles((theme: CustomThemeType) => ({
  buttons: {
    '&>button': {
      color: theme.custom.palette.blue.main
    }
  },
  button: {
    color: theme.custom.palette.blue.main
  },
  actions: {
    paddingBottom: theme.spacing(4)
  }
}))

interface PropTypes {
  open: boolean
  onClose: () => void
  reload: () => void
  providerStatus: SmsProviderRes | null
}

interface RowType {
  id: number
  name: string
  order: string
  operate: React.ReactElement
}

const Buttons: React.FC<{
  id: number
  index: number
  isActive: boolean
  setForm: Dispatch<SetStateAction<SmsProviderForm>>
  threshold: number
}> = React.memo((props) => {
  const { id, index, isActive, setForm, threshold } = props
  const { t } = useT()
  const classes = useStyles()
  const handleUp = useCallback(() => {
    setForm((form) => {
      const arr = form.order?.slice() ?? []
      const prev = arr[index - 1]
      arr[index - 1] = arr[index]
      arr[index] = prev
      return {
        ...form,
        order: arr
      }
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, index])
  const handleDown = useCallback(() => {
    setForm((form) => {
      const arr = form.order?.slice() ?? []
      const back = arr[index + 1]
      arr[index + 1] = arr[index]
      arr[index] = back
      return {
        ...form,
        order: arr
      }
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, index])
  const handleDisable = useCallback(() => {
    setForm((form) => ({
      ...form,
      order: form.order?.filter((item) => item !== id).concat(id),
      is_active: form.is_active?.filter((item) => item !== id),
      is_not_active: (form.is_not_active?.filter((item) => item !== id) ?? []).concat(id)
    }))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])
  const handleIndisable = useCallback(() => {
    setForm((form) => {
      const newOrder = form.order?.filter((item) => item !== id).slice()
      newOrder?.splice(threshold, 0, id)
      return {
        ...form,
        order: newOrder,
        is_active: (form.is_active?.filter((item) => item !== id) ?? []).concat(id),
        is_not_active: form.is_not_active?.filter((item) => item !== id)
      }
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, threshold])
  if (!isActive) {
    return (
      <Button className={classes.button} onClick={handleIndisable}>
        {t('common.indisable')}
      </Button>
    )
  }
  return (
    <ButtonGroup variant="text" className={classes.buttons}>
      <Button disabled={index === 0} onClick={handleUp}>
        {t('common.upMove')}
      </Button>
      <Button disabled={index === threshold - 1} onClick={handleDown}>
        {t('common.downMove')}
      </Button>
      <Button onClick={handleDisable}>
        {t('common.disable')}
      </Button>
    </ButtonGroup>
  )
})

const getIsActive = (form: SmsProviderForm, id: number, target: SmsProvider | undefined): boolean => {
  return form.is_active?.includes(id) ? true : (form.is_not_active?.includes(id) ? false : (target?.is_active ?? false))
}

const Warning: React.FC = React.memo(() => {
  const { t } = useT()
  return (
    <Typography color="error">
      {t('common.warningDisabledAllProviders')}
    </Typography>
  )
})

const AnnouncementSmsDialog: React.FC<PropTypes> = (props) => {
  const { open, onClose, reload, providerStatus } = props
  const { t } = useT()
  const globalDialog = useGlobalDialog()
  const gdk = useGDK()
  const classes = useStyles()
  const commonClasses = useCommonStyles()
  const [form, setForm] = useState<SmsProviderForm>({
    idd_code: CountryCallingCode.CN
  })
  const [toIddCode, setToIddCode] = useState<CountryCallingCode>(CountryCallingCode.CN)
  useEffect(() => {
    setForm({
      idd_code: form.idd_code,
      order: providerStatus?.[form.idd_code].providers.map((item) => item.id),
      sms_auto_resend_way: providerStatus?.[form.idd_code].sms_auto_resend_way,
      sms_auto_resend_provider: providerStatus?.[form.idd_code].sms_auto_resend_provider
    })
  }, [providerStatus, form.idd_code])
  const disabledAllProviders = useMemo(() => {
    if (form.is_not_active !== undefined) {
      return providerStatus?.[CountryCallingCode.CN].providers.every((item) => {
        if (form.is_not_active?.includes(item.id)) {
          return true
        }
        if (!item.is_active) {
          if (form.is_active?.includes(item.id)) {
            return false
          }
          return true
        }
        return false
      })
    }
    return false
  }, [form, providerStatus])
  const rows = useMemo(() => {
    if (!providerStatus) return []
    const order = form.order ?? providerStatus[form.idd_code].providers.map((item) => item.id)
    const threshold = order.findIndex((id) => {
      return !getIsActive(form, id, providerStatus[form.idd_code].providers.find((item) => item.id === id))
    })
    return order.map((id, index) => {
      const target = providerStatus?.[form.idd_code].providers.find((item) => item.id === id)
      const isActive = getIsActive(form, id, target)
      return {
        id,
        name: target?.name ?? '',
        order: isActive ? `${index + 1}` : t('common.disable'),
        operate: (<Buttons id={id} index={index} isActive={isActive} setForm={setForm} threshold={threshold === -1 ? providerStatus[form.idd_code].providers.length : threshold} />)
      } as RowType
    })
  }, [form, providerStatus, t])
  const data = createTableData<RowType>(
    {
      id: {
        label: '',
        value: 'id'
      },
      name: {
        label: t('common.smsProvider'),
        value: 'name',
        align: 'center'
      },
      order: {
        label: t('common.useOrder'),
        value: 'order',
        align: 'center'
      },
      operate: {
        label: t('common.operation'),
        value: 'operate',
        align: 'center'
      }
    },
    [
      'name',
      'order',
      'operate'
    ],
    rows,
    'id'
  )
  const [handleSubmitDebounced] = useDebouncedCallback(useCallback(() => {
    onClose()
    if (!providerStatus) return
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const is_active = form.is_active?.filter((item) => {
      return providerStatus[form.idd_code].providers
        .filter((provider) => !provider.is_active)
        .map((provider) => provider.id)
        .includes(item)
    })
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const is_not_active = form.is_not_active?.filter((item) => {
      return providerStatus[form.idd_code].providers
        .filter((provider) => provider.is_active)
        .map((provider) => provider.id)
        .includes(item)
    })
    const order = form.order?.every((id, index) => id === providerStatus[form.idd_code].providers[index]?.id) ? undefined : form.order
    const payload: SmsProviderForm = { idd_code: form.idd_code, is_active, is_not_active, order }
    if (providerStatus[form.idd_code].sms_auto_resend_way !== form.sms_auto_resend_way) {
      payload.sms_auto_resend_way = form.sms_auto_resend_way
    }
    if (providerStatus[form.idd_code].sms_auto_resend_provider !== form.sms_auto_resend_provider) {
      payload.sms_auto_resend_provider = form.sms_auto_resend_provider
    }
    gdk.sms.updateSmsProvider(payload).subscribe({
      next: () => {
        setForm({ idd_code: CountryCallingCode.CN })
        reload()
      },
      error: (error: GDKError) => {
        setForm({ idd_code: payload.idd_code })
        globalDialog.setConfig(createGlobalDialogConfig({
          variant: 'error',
          showIcon: true,
          showCancel: false,
          message: error.message
        }))
        globalDialog.setOpen({
          id: 'setProviderError',
          value: false,
          isOK: false
        })
      }
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gdk, form, providerStatus]), 200)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSubmit = useCallback(handleSubmitDebounced, [])
  useDialogFlow(
    globalDialog,
    'willChangeIddCode',
    () => { setForm({ ...form, idd_code: toIddCode }) },
    [setForm, toIddCode]
  )
  const handleIddCodeChange = useCallback((event: ChangeEvent<{ name?: string | undefined, value: unknown }>) => {
    const value = event.target.value as CountryCallingCode
    const hasStatusChanged = () => {
      if (form.is_active?.length) return true
      if (form.is_not_active?.length) return true
      if (providerStatus?.[form.idd_code].sms_auto_resend_provider !== form.sms_auto_resend_provider) return true
      if (providerStatus?.[form.idd_code].sms_auto_resend_way !== form.sms_auto_resend_way) return true
      if (!isEqual(providerStatus?.[form.idd_code].providers.map((item) => item.id), form.order)) return true
      return false
    }
    if (hasStatusChanged()) {
      setToIddCode(value)
      globalDialog.setConfig(createGlobalDialogConfig({
        showIcon: false,
        maxWidth: 'xs',
        message: t('dialog.confirmChangeBeforeSave')
      }))
      globalDialog.setOpen({
        id: 'willChangeIddCode',
        value: true,
        isOK: false
      })
    } else {
      setForm({ ...form, idd_code: value })
    }
  }, [form, t, globalDialog, setToIddCode, providerStatus])
  const handleWayChange = useCallback((event: ChangeEvent<{ name?: string | undefined, value: unknown }>) => {
    const value = event.target.value as string
    if (value === SmsResendWayType.POLL) {
      setForm((form) => ({ ...form, sms_auto_resend_way: SmsResendWayType.POLL }))
    } else {
      setForm((form) => ({ ...form, sms_auto_resend_provider: value, sms_auto_resend_way: SmsResendWayType.SPECIFY }))
    }
  }, [])
  const options = useMemo(() => {
    const providersToOptions = providerStatus ? providerStatus[form.idd_code].providers.map((item) => ({ name: item.name, value: item.code })) : []
    return [{ name: t('common.autoResendPoll'), value: SmsResendWayType.POLL }].concat(providersToOptions as any)
  }, [providerStatus, t, form.idd_code])
  const [handleDebouncedCancel] = useDebouncedCallback(useCallback(() => {
    setForm({
      idd_code: form.idd_code,
      order: providerStatus?.[form.idd_code].providers.map((item) => item.id),
      sms_auto_resend_way: providerStatus?.[form.idd_code].sms_auto_resend_way,
      sms_auto_resend_provider: providerStatus?.[form.idd_code].sms_auto_resend_provider
    })
    onClose()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [providerStatus]), 200)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleCancel = useCallback(handleDebouncedCancel, [])
  const tableClasses = useMemo(() => ({
    head: commonClasses.greyTableHead,
    row: commonClasses.tableRow,
    cellHead: commonClasses.tableCellHead
  }), [commonClasses])
  return (
    <Dialog open={open} fullWidth maxWidth="sm">
      <Box padding={2} className={commonClasses.dialogPinkHeader}>
        {t('common.smsProviderManagement')}
      </Box>
      <DialogContent>
        <Box display="flex" justifyContent="center">
          <Box width={130}>
            <IddCodeDropDown
              value={form.idd_code}
              onChange={handleIddCodeChange}
            />
          </Box>
        </Box>
        <Box padding={3}>
          <CoreTable
            classes={tableClasses}
            data={data}
            total={providerStatus?.[form.idd_code].providers.length ?? 0}
            tableFooterMemo={disabledAllProviders ? Warning : undefined}
          />
          <Box paddingTop={2} display="flex" flexDirection="row" alignItems="baseline">
            <Box display="flex">
              <FormControl>
                <FormControlLabel
                  labelPlacement="start"
                  label={t('common.autoResend')}
                  control={<Switch />}
                  checked={form.sms_auto_resend_way !== SmsResendWayType.NONE}
                  onChange={useCallback((event) => {
                    const value = event.target.checked
                    if (value) {
                      setForm((form) => ({ ...form, sms_auto_resend_way: SmsResendWayType.POLL }))
                    } else {
                      setForm((form) => ({ ...form, sms_auto_resend_way: SmsResendWayType.NONE }))
                    }
                  }, [])}
                />
              </FormControl>
            </Box>
            {form.sms_auto_resend_way !== SmsResendWayType.NONE && (
              <Box display="flex" paddingLeft={2} width={180}>
                <DropDown
                  fullWidth
                  label=""
                  value={form.sms_auto_resend_way !== SmsResendWayType.POLL ? form.sms_auto_resend_provider : SmsResendWayType.POLL}
                  onChange={handleWayChange}
                  options={options}
                />
              </Box>
            )}
          </Box>
        </Box>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Grid
          container
          direction="row"
          alignItems="center"
          justify="center"
          spacing={2}
        >
          <Grid item>
            <Button onClick={handleCancel} className={commonClasses.greyButton}>
              {t('common.cancel')}
            </Button>
          </Grid>
          <Grid item>
            <Button
              disabled={disabledAllProviders}
              className={commonClasses.purpleGradualButton}
              onClick={handleSubmit}
            >
              {t('common.saveSetting')}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  )
}

export default React.memo(AnnouncementSmsDialog)

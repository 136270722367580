import React from 'react'
import Box from '@material-ui/core/Box'
import FinanceCourierToolbar from '../../../components/admin/finance/FinanceCourierToolbar'
import FinanceCourierTable from '../../../components/admin/finance/FinanceCourierTable'
import ScrollablePaper from '../../../components/default/present/ScrollablePaper'

const FinanceCourierPage: React.FC = () => {
  return (
    <Box paddingY={5}>
      <ScrollablePaper marginX={5}>
        <Box padding={4}>
          <FinanceCourierToolbar />
        </Box>
        <Box paddingTop={1} paddingBottom={5} paddingX={4}>
          <FinanceCourierTable />
        </Box>
      </ScrollablePaper>
    </Box>
  )
}

export default React.memo(FinanceCourierPage)

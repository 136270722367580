import React, { useCallback, useContext, useMemo, useState } from 'react'
import { isBefore, subHours } from 'date-fns'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import ScrollablePaper from '../../default/present/ScrollablePaper'
import LoadingAndErrorFrame from '../../default/frames/LoadingAndErrorFrame'
import CoreTable from '../../default/present/CoreTable'
import { SmsResendDialogContext, searchToRequest } from '../../../views/admin/forestage/AnnouncementSmsPage'
import { initialPayload } from './AnnouncementSmsResendDialog'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import { useGetDataByPayload, usePaginationClickAndChangeUrl, useReload, useRequestFromSearch } from '../../../utils/default/ComplexFlowHook'
import { usePageFlow } from '../../../utils/default/PageFlowHook'
import allRoute from '../route/route'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { PaginationRes, PermissionType, SmsHistory } from '@golden/gdk-admin'
import { createDefaultPaginationData, createTableData } from '../../../utils/default/TableHelper'
import useT from '../../../i18ns/admin/useT'
import DateTime from '../../default/present/DateTime'
import smsTriggerNname from '../../../constants/admin/smsTriggerName'
import smsSendStatusName from '../../../constants/admin/smsSendStatusName'
import smsReceiveStatusName from '../../../constants/admin/smsReceiveStatusName'
import { useChecker } from '../../../utils/admin/AdminRouteHook'

interface RowType {
  id: number
  sentAt: React.ReactElement
  user: string
  phone: string
  trigger: string
  provider: string
  send: string
  serial: string
  memo: string
  receive: string
  verifiedAt: React.ReactElement
  operator: string
  functions: React.ReactElement
}

const ResendButton: React.FC<{ item: SmsHistory, reload: () => void }> = React.memo((props) => {
  const { item, reload } = props
  const commonClasses = useCommonStyles()
  const { t } = useT()
  const [, setPayload] = useContext(SmsResendDialogContext)
  const handleClose = useCallback(() => {
    setPayload(initialPayload())
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const handleClick = useCallback(() => {
    setPayload({
      id: item.id,
      open: true,
      onClose: handleClose,
      reload,
      account: item.account,
      iddCode: item.idd_code,
      phone: item.phone,
      trigger: item.trigger,
      originProvider: item.provider
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item, handleClose])
  return (
    <Button onClick={handleClick} className={commonClasses.blueGradualOutlineButton}>
      {t('common.resendShort')}
    </Button>
  )
})

const AnnouncementSmslTable: React.FC = () => {
  const request = useRequestFromSearch({ searchToRequest })
  const commonClasses = useCommonStyles()
  const pageFlow = usePageFlow()
  const gdk = useGDK()
  const writableResend = useChecker([PermissionType.SMS_RECORD_RESEND])
  const { t } = useT()
  const { reload, reloadFlag } = useReload()
  const [list, setList] = useState<PaginationRes<SmsHistory[]>>(createDefaultPaginationData([]))
  useGetDataByPayload({
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    payload: request!,
    gdkFunc: (payload) => gdk.sms.getSmsHistory(payload),
    gdkFuncDependencies: [gdk, reloadFlag],
    onBeforeFetch: pageFlow.setLoadingStart,
    onSuccess: (res: PaginationRes<SmsHistory[]>) => {
      setList(res)
      pageFlow.setContentShow()
    },
    onError: (error) => {
      pageFlow.setError(error.message)
    },
    canLoadData: request !== undefined
  })
  const rows: RowType[] = useMemo(() => {
    return list.data.map((entry) => ({
      id: entry.id,
      sentAt: (<DateTime time={entry.created_at} />),
      user: entry.account,
      phone: `+${entry.idd_code} ${entry.phone}`,
      trigger: t(smsTriggerNname[entry.trigger]),
      provider: entry.provider,
      send: t(smsSendStatusName[entry.send_status]),
      serial: entry.serial_number,
      memo: entry.memo,
      receive: t(smsReceiveStatusName[entry.receive_status]),
      verifiedAt: entry.verified_at ? (<DateTime time={entry.verified_at} />) : (<p></p>),
      operator: entry.created_by,
      functions: (entry.verified_at ?? isBefore(entry.created_at, subHours(new Date(), 3)))
        ? (<p></p>)
        : (<ResendButton item={entry} reload={reload} />)
    } as RowType))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list.data, t])
  const data = useMemo(() => {
    return createTableData<RowType>(
      {
        id: {
          label: '',
          value: 'id'
        },
        sentAt: {
          label: t('common.sentAt'),
          value: 'sentAt',
          align: 'center'
        },
        user: {
          label: t('common.sendObject'),
          value: 'user',
          align: 'center'
        },
        phone: {
          label: t('common.cellphone'),
          value: 'phone',
          align: 'center',
          width: 180
        },
        trigger: {
          label: t('common.triggerSence'),
          value: 'trigger',
          align: 'center'
        },
        provider: {
          label: t('common.smsProvider'),
          value: 'provider',
          align: 'center'
        },
        send: {
          label: t('common.sendStatus'),
          value: 'send',
          align: 'center'
        },
        serial: {
          label: t('common.smsId'),
          value: 'serial',
          align: 'center'
        },
        memo: {
          label: t('common.memo'),
          value: 'memo',
          align: 'center'
        },
        receive: {
          label: t('common.receiveStatus'),
          value: 'receive',
          align: 'center'
        },
        verifiedAt: {
          label: t('common.verifySuccessAt'),
          value: 'verifiedAt',
          align: 'center'
        },
        operator: {
          label: t('common.updateBy'),
          value: 'operator',
          align: 'center'
        },
        functions: {
          label: t('common.operation'),
          value: 'functions',
          align: 'center'
        }
      },
      [
        'sentAt',
        'user',
        'phone',
        'trigger',
        'provider',
        'send',
        'serial',
        'memo',
        'receive',
        'verifiedAt',
        'operator',
        ...(writableResend ? ['functions'] as const : [])
      ],
      rows,
      'id'
    )
  }, [rows, writableResend, t])
  const tableClasses = useMemo(() => {
    return {
      head: commonClasses.pinkTableHead,
      cellHead: commonClasses.tableCellHead,
      row: commonClasses.tableRow
    }
  }, [commonClasses.pinkTableHead, commonClasses.tableCellHead, commonClasses.tableRow])
  const handlePagination = usePaginationClickAndChangeUrl({
    request,
    encodePath: allRoute.forestageSms.encodePath
  })
  if (request === undefined) return null
  return (
    <ScrollablePaper marginX={6}>
      <Box padding={4}>
        <Box paddingY={2}>
          <LoadingAndErrorFrame { ...pageFlow.status }>
            <CoreTable
              classes={tableClasses}
              data={data}
              total={list.total}
              showPagination
              page={request.page}
              onChangePage={handlePagination}
              loading={pageFlow.status.loading}
            />
          </LoadingAndErrorFrame>
        </Box>
      </Box>
    </ScrollablePaper>
  )
}

export default React.memo(AnnouncementSmslTable)

import React from 'react'
import { Link } from 'react-router-dom'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import AccountEmperorLayerSummaryCard from '../../../components/admin/account/AccountEmperorLayerSummaryCard'
import AccountEmperorLayerTable from '../../../components/admin/account/AccountEmperorLayerTable'
import { Path } from '../../../components/admin/route/route'
import useT from '../../../i18ns/admin/useT'
import { useCommonStyles } from '../../../utils/admin/StyleHook'

const AccountEmperorLayerPage: React.FC = () => {
  const { t } = useT()
  const classes = useCommonStyles()
  return (
    <Box paddingY={6}>
      <Box display="flex" justifyContent="flex-end" paddingBottom={2} paddingX={6}>
        <Button component={Link} to={Path.ACCOUNT_EMPEROR} className={classes.greyButton}>
          {t('common.back')}
        </Button>
      </Box>
      <AccountEmperorLayerSummaryCard />
      <Box paddingTop={2}>
        <AccountEmperorLayerTable />
      </Box>
    </Box>
  )
}

export default React.memo(AccountEmperorLayerPage)

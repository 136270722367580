import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import CustomThemeType from '../../../themes/admin/CustomThemeType'
import { useCommonStyles } from '../../../utils/admin/StyleHook'

const useStyles = makeStyles((theme: CustomThemeType) => ({
  content: {
    padding: theme.spacing(6)
  }
}))

interface PropTypes {
  title: string
  isOpen: boolean
  content: React.ReactElement
}

const GreetingDialog: React.FC<PropTypes> = (props) => {
  const { title, isOpen, content } = props
  const classes = useStyles()
  const commonClasses = useCommonStyles()

  return (
    <Dialog open={isOpen} fullWidth maxWidth="sm">
      <Box padding={2} className={commonClasses.dialogPinkHeader}>
        {title}
      </Box>
      <DialogContent className={classes.content}>
        {content}
      </DialogContent>
    </Dialog>
  )
}

export default GreetingDialog

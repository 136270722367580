import { DebitCardValidationResultType } from '@golden/gdk-admin'
import TKeyType from '../../i18ns/admin/TKeyType'

const debitcardValidationResultName: { [key in DebitCardValidationResultType]: TKeyType } = {
  [DebitCardValidationResultType.SUCCESS]: 'debitCardValidationResult.success',
  [DebitCardValidationResultType.FAIL]: 'debitCardValidationResult.fail',
  [DebitCardValidationResultType.THIRD_PARTY_EXCEPTION]: 'debitCardValidationResult.thirdPartyException',
  [DebitCardValidationResultType.SYSTEM_EXCEPTION]: 'debitCardValidationResult.systemException'
}

export default debitcardValidationResultName

import { makeStyles } from '@material-ui/core'
import React, { useCallback } from 'react'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import useT from '../../i18ns/admin/useT'
import { useCommonStyles } from '../../utils/admin/StyleHook'
import clsx from 'clsx'
import { useDebouncedCallback } from 'use-debounce/lib'

const useStyles = makeStyles(() => ({
  fileInput: {
    display: 'none'
  },
  button: {
    whiteSpace: 'nowrap'
  }
}))

export interface PropTypes {
  text?: string
  loading: boolean
  onChange?: (value: File) => void
}

const FileInputButton: React.FC<PropTypes> = React.memo((props) => {
  const { t } = useT()
  const { text, loading, onChange } = props
  const classes = useStyles()
  const commonClasses = useCommonStyles()

  const fileRef = React.createRef<HTMLInputElement>()
  const [handleClick] = useDebouncedCallback(() => {
    if (fileRef.current && !fileRef.current.value) fileRef.current.click()
  }, 500)
  return (
    <Box display="flex" flexDirection="row" alignItems="baseline">
      <Button
        onClick={handleClick}
        className={clsx(commonClasses.purpleGradualButton, classes.button)}
        disabled={loading}
      >
        {text ?? t('common.uploadCsvFile')}
      </Button>
      <input
        onChange={useCallback((event) => {
          const file = event.target.files[0]
          if (onChange) onChange(file)
          event.target.value = null
        }, [onChange])}
        className={classes.fileInput}
        type="file"
        accept=".csv"
        ref={fileRef}
      />
    </Box>
  )
})

export default React.memo(FileInputButton)

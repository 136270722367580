import React, { useMemo, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { DepositWay, PaginationRes } from '@golden/gdk-admin'
import Box from '@material-ui/core/Box'
import ThumbUpIcon from '@material-ui/icons/ThumbUp'
import LoadingAndErrorFrame from '../../default/frames/LoadingAndErrorFrame'
import CoreTable from '../../default/present/CoreTable'
import { usePageFlow } from '../../../utils/default/PageFlowHook'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import useT from '../../../i18ns/admin/useT'
import { useGetDataByPayload, useReload, useRequestFromSearch, usePaginationClickAndChangeUrl } from '../../../utils/default/ComplexFlowHook'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { createTableData, createDefaultPaginationData, formatMoney } from '../../../utils/default/TableHelper'
import depositTypeName from '../../../constants/default/depositTypeName'
import TableStatus from '../../default/present/TableStatus'
import FinanceDepositWayButtons from './FinanceDepositWayButtons'
import CustomThemeType from '../../../themes/admin/CustomThemeType'
import { useChecker } from '../../../utils/admin/AdminRouteHook'
import clientTypeName from '../../../constants/admin/clientTypeName'
import { IS_PROXY_FOR_DEPOSIT, searchToRequest, RequestType } from '../../../views/admin/finance/FinanceDepositWayPage'
import allRoute from '../../../components/admin/route/route'
import agentTypeName from '../../../constants/default/agentTypeName'
import { isUndefined, omitBy } from '@golden/utils'

const useStyles = makeStyles((theme: CustomThemeType) => ({
  icon: {
    marginLeft: theme.spacing(1),
    color: theme.custom.palette.blue.main
  },
  error: {
    color: theme.palette.error.main
  }
}))

interface RowTyep {
  id: number
  forwarder: string
  courier: string
  way: React.ReactElement
  min: string
  max: string
  full: string
  userFee: string
  playerLayer: React.ReactElement
  agentLayers: React.ReactElement
  client: React.ReactElement
  status: React.ReactElement
  functions: React.ReactElement
}

const FinanceDepositWayTable: React.FC = () => {
  const request = useRequestFromSearch({ searchToRequest })
  const classes = useStyles()
  const commonClasses = useCommonStyles()
  const { reloadFlag, reload } = useReload()
  const pageFlow = usePageFlow(true)
  const gdk = useGDK()
  const writable = useChecker()
  const { t } = useT()

  const [list, setList] = useState<PaginationRes<DepositWay[]>>(createDefaultPaginationData([]))

  const payload = useMemo(() => {
    const converted = { ...request, page: request?.page ?? 1, is_active: request?.is_active === 2 ? undefined : request?.is_active, layer_id: request?.layer_id === IS_PROXY_FOR_DEPOSIT ? undefined : request?.layer_id } as RequestType
    return omitBy(converted, isUndefined) as RequestType
  }, [request])
  useGetDataByPayload({
    payload,
    gdkFunc: (payload) => gdk.finance.getDepositWayList(payload),
    gdkFuncDependencies: [gdk, reloadFlag],
    onBeforeFetch: pageFlow.setLoadingStart,
    onSuccess: (res: PaginationRes<DepositWay[]>) => {
      setList(res)
      pageFlow.setContentShow()
    },
    onError: pageFlow.setGDKError
  })

  const tableClasses = useMemo(() => {
    return {
      head: commonClasses.pinkTableHead,
      row: commonClasses.tableRow,
      cellHead: commonClasses.tableCellHead
    }
  }, [commonClasses])

  const rows = useMemo(() => {
    return list.data.map((item) => {
      return {
        id: item.id,
        forwarder: item.slug_name,
        courier: item.forwarder_name,
        way: (
          <Box display="flex" flexDirection="row" alignItems="flex-end" justifyContent="center">
            {t(depositTypeName[item.method])}
            {item.is_recommended && (<ThumbUpIcon className={classes.icon} />)}
          </Box>
        ),
        min: formatMoney(item.cash_min),
        max: formatMoney(item.cash_max),
        full: formatMoney(item.cash_full),
        userFee: `${formatMoney(item.fee_rate)}%`,
        playerLayer: (
          <React.Fragment>
            { [...(item.layers.map(layer => layer.name)), item.is_for_proxy_deposit ? t('common.staffDeposit') : null].join('、') }
          </React.Fragment>
        ),
        agentLayers: (
          <React.Fragment>
            {item.agent_types.map(layer => t(agentTypeName[layer])).join('、')}
          </React.Fragment>
        ),
        client: (
          <React.Fragment>
            {item.clients.map((client) => (
              <p key={client}>{t(clientTypeName[client])}</p>
            ))}
          </React.Fragment>
        ),
        status: (
          <React.Fragment>
            <TableStatus status={item.is_active} />
            {item.is_full && (<p className={classes.error}>{t('common.isFull')}</p>)}
          </React.Fragment>
        ),
        functions: (
          <FinanceDepositWayButtons
            item={item}
            reload={reload}
          />
        )
      } as RowTyep
    })
  }, [t, list, classes, reload])
  const data = useMemo(() => {
    return createTableData(
      {
        id: {
          label: '',
          value: 'id'
        },
        forwarder: {
          label: t('common.forwarder'),
          value: 'forwarder',
          align: 'center'
        },
        courier: {
          label: t('common.courierName'),
          value: 'courier',
          align: 'center'
        },
        way: {
          label: t('common.depositWay'),
          value: 'way',
          align: 'center'
        },
        min: {
          label: t('common.minDepositMoney'),
          value: 'min',
          align: 'center'
        },
        max: {
          label: t('common.maxDepositMoney'),
          value: 'max',
          align: 'center'
        },
        full: {
          label: t('common.stopMoney'),
          value: 'full',
          align: 'center'
        },
        userFee: {
          label: t('common.playerDepositFee'),
          value: 'userFee',
          align: 'center'
        },
        playerLayer: {
          label: t('common.openPlayerLayer'),
          value: 'playerLayer',
          align: 'center'
        },
        agentLayers: {
          label: t('common.openAgentLayer'),
          value: 'agentLayers',
          align: 'center'
        },
        client: {
          label: t('common.platformVersion'),
          value: 'client',
          align: 'center'
        },
        status: {
          label: t('common.status'),
          value: 'status',
          align: 'center'
        },
        functions: {
          label: t('common.function'),
          value: 'functions',
          align: 'center'
        }
      },
      writable
        ? [
            'forwarder',
            'courier',
            'way',
            'min',
            'max',
            'full',
            'userFee',
            'client',
            'playerLayer',
            'agentLayers',
            'status',
            'functions'
          ]
        : [
            'forwarder',
            'courier',
            'way',
            'min',
            'max',
            'full',
            'userFee',
            'client',
            'playerLayer',
            'agentLayers',
            'status'
          ],
      rows,
      'id'
    )
  }, [t, rows, writable])

  const handlePagination = usePaginationClickAndChangeUrl({
    request,
    encodePath: allRoute.financeDepositWay.encodePath,
    pageFlow
  })

  return (
    <LoadingAndErrorFrame { ...pageFlow.status }>
      <CoreTable
        classes={tableClasses}
        data={data}
        total={list.total}
        showPagination
        page={request?.page ?? 1}
        onChangePage={handlePagination}
        loading={pageFlow.status.loading}
      />
    </LoadingAndErrorFrame>
  )
}

export default FinanceDepositWayTable

import React, { useMemo } from 'react'
import { MuiPickersUtilsProvider } from '@golden/pickers'
import DateFnsUtils from '@date-io/date-fns'
import Language, { defaultLanguage, languageToDateLocale } from '../../enums/default/LanguageType'
import useT from '../../i18ns/admin/useT'

const PickersUtilsProvider: React.FC = (props) => {
  const { children } = props
  const { i18n } = useT()
  const calendarLocale = useMemo(
    () => languageToDateLocale[(i18n.language || defaultLanguage) as Language],
    [i18n.language]
  )
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={calendarLocale}>
      {children}
    </MuiPickersUtilsProvider>
  )
}

export default PickersUtilsProvider

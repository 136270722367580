import React, { useEffect } from 'react'
import Box from '@material-ui/core/Box'
import ActivityRecordForm from '../../../components/admin/activity/ActivityRecordForm'
import ActivityRecordTable from '../../../components/admin/activity/ActivityRecordTable'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { Branch } from '@golden/gdk-admin'

const ActivityRecordPage: React.FC = () => {
  const gdk = useGDK()
  useEffect(() => {
    gdk.trunk.trigger([Branch.ACTIVITY])
  }, [gdk])
  return (
    <Box paddingY={5}>
      <Box paddingX={5}>
        <ActivityRecordForm />
      </Box>
      <Box paddingTop={3}>
        <ActivityRecordTable />
      </Box>
    </Box>
  )
}

export default React.memo(ActivityRecordPage)

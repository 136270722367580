import React from 'react'
import { Link } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import useT from '../../../i18ns/admin/useT'
import useGDK from '../../../providers/admin/gdk/useGDK'
import useGlobalDialog from '../../../providers/admin/dialog/useGlobalDialog'
import { useDialogHandleClick, useRequestFromSearch } from '../../../utils/default/ComplexFlowHook'
import { createGlobalDialogConfig } from '../../../utils/default/DialogHelper'
import allRoute from '../route/route'
import { searchToRequest } from '../../../views/admin/forestage/AnnouncementNotificationPage'

interface PropTypes {
  id: number
  isActive: boolean
  reload: () => void
}

const ButtonsBase: React.FC<{
  id: number
  isActive: boolean
  handleStart: () => void
  handleStop: () => void
}> = React.memo((props) => {
  const request = useRequestFromSearch({ searchToRequest })
  const {
    id,
    isActive,
    handleStart,
    handleStop
  } = props
  const classes = useCommonStyles()
  const { t } = useT()
  return (
    <Grid container direction="column" spacing={1} justify="center">
      <Grid item>
        <Button
          component={Link}
          to={allRoute.forestageNotificationUpdate.encodePath({ search: { ...request }, param: { id } })}
          size="small"
          className={classes.blueGradualOutlineButton}
        >
          {t('common.update')}
        </Button>
      </Grid>
      {
        !isActive &&
          (
            <Grid item>
              <Button
                onClick={handleStart}
                size="small"
                className={classes.greenGradualOutlineButton}
              >
                {t('common.toggleOn')}
              </Button>
            </Grid>
          )
      }
      {
        isActive &&
          (
            <Grid item>
              <Button
                onClick={handleStop}
                size="small"
                className={classes.greyOutlineButton}
              >
                {t('common.toggleOff')}
              </Button>
            </Grid>
          )
      }
    </Grid>
  )
})

const SystemNotificationListButtons: React.FC<PropTypes> = (props) => {
  const { id, isActive, reload } = props
  const { t } = useT()
  const gdk = useGDK()
  const globalDialog = useGlobalDialog()

  const handleStart = useDialogHandleClick({
    dialogId: `startNotification-${id}`,
    globalDialog,
    changeDialogConfig: createGlobalDialogConfig({
      showIcon: false,
      message: t('dialog.confirmStartNotification')
    }),
    successDialogConfig: createGlobalDialogConfig({
      showIcon: false,
      message: t('dialog.startNotificationSuccess'),
      showCancel: false
    }),
    getFailDialogConfig: (error: string) => createGlobalDialogConfig({
      variant: 'error',
      showIcon: true,
      message: error,
      showCancel: false
    }),
    payload: id,
    gdkFunc: (payload) => gdk.notification.updateSystemNotification(payload, ({ is_active: true })),
    gdkFuncDependencies: [gdk],
    onSuccess: reload
  })

  const handleStop = useDialogHandleClick({
    dialogId: `stopNotification-${id}`,
    globalDialog,
    changeDialogConfig: createGlobalDialogConfig({
      showIcon: false,
      message: t('dialog.confirmStopNotification')
    }),
    successDialogConfig: createGlobalDialogConfig({
      showIcon: false,
      message: t('dialog.stopNotificationSuccess'),
      showCancel: false
    }),
    getFailDialogConfig: (error: string) => createGlobalDialogConfig({
      variant: 'error',
      showIcon: true,
      message: error,
      showCancel: false
    }),
    payload: id,
    gdkFunc: (payload) => gdk.notification.updateSystemNotification(payload, ({ is_active: false })),
    gdkFuncDependencies: [gdk],
    onSuccess: reload
  })

  return (
    <ButtonsBase
      id={id}
      isActive={isActive}
      handleStart={handleStart}
      handleStop={handleStop}
    />
  )
}

export default React.memo(SystemNotificationListButtons)

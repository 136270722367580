import React, { useState, useMemo, useCallback, createContext, Dispatch, SetStateAction } from 'react'
import {
  PaginationRes,
  ActivityWalletItem,
  ActivityWalletLimitType,
  GDKError,
  PermissionType,
  getTargetURL
} from '@golden/gdk-admin'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import MuiButton from '@material-ui/core/Button'
import { searchToRequest } from '../../activity/wallet/ActivityWalletForm'
import CoreTable from '../../../default/present/CoreTable'
import LoadingAndErrorFrame from '../../../default/frames/LoadingAndErrorFrame'
import MemoTextWithTitleAndContext from '../../../default/memo/MemoTextWithTitleAndContext'
import MemoPopoverWithContext from '../../../default/memo/MemoPopoverWithContext'
import { useRequestFromSearch, useGetDataByPayload, usePaginationClickAndChangeUrl, useReload } from '../../../../utils/default/ComplexFlowHook'
import { usePageFlow } from '../../../../utils/default/PageFlowHook'
import useGDK from '../../../../providers/admin/gdk/useGDK'
import { createDefaultPaginationData, createTableData, formatMoney, formatCount } from '../../../../utils/default/TableHelper'
import useT from '../../../../i18ns/admin/useT'
import { useCommonStyles } from '../../../../utils/admin/StyleHook'
import allRoute from '../../route/route'
import DateTime from '../../../default/present/DateTime'
import ScrollablePaper from '../../../default/present/ScrollablePaper'
import useGlobalDialog from '../../../../providers/admin/dialog/useGlobalDialog'
import { createGlobalDialogConfig } from '../../../../utils/default/DialogHelper'
import { getCategoryShortName, getGameName, getWalletNameByWalletId } from '../../../../utils/default/PlatformHelper'
import { createDefaultMemoPopoverPayload, MemoPopoverPayload } from '../../../default/memo/MemoPopover'
import useGDKStore from '../../../../providers/admin/gdk/useGDKStore'
import activityWalletStatusColor from '../../../../constants/admin/activityWalletStatusColor'
import activityWalletStatusTypeName from '../../../../constants/admin/activityWalletStatusName'
import { useDebouncedCallback } from 'use-debounce/lib'
import { getServerUrl } from '../../../../utils/default/StageHelper'
import { useChecker } from '../../../../utils/admin/AdminRouteHook'
import ActivityWalletBindingDepositsDialog from './ActivityWalletBindingDepositsDialog'

interface SumRowType {
  id: number
  totalCount: string
  totalCash: string
}
interface RowType {
  id: string
  account: string
  createdAt: React.ReactElement
  expiredAt: React.ReactElement
  activitySerial: string
  title: string
  content: React.ReactElement
  platform: React.ReactElement
  multiple: string
  cash: string
  status: React.ReactElement
  transferTo: string
  transferSerial: React.ReactElement
  updatedAt: React.ReactElement
  updatedBy: string
  bindingDeposits: string | React.ReactElement
}

const MemoContext = createContext<[MemoPopoverPayload, Dispatch<SetStateAction<MemoPopoverPayload>>]>([
  createDefaultMemoPopoverPayload(),
  () => {}
])

const Button = React.memo(MuiButton)

const BindingDepositButton: React.FC<{ list: string[], reload: () => void, id: string, title: string, account: string, cash: string }> = ({ id, list, reload, title, account, cash }) => {
  const { t } = useT()
  const commonClasses = useCommonStyles()
  const [open, setOpen] = useState(false)
  const close = useCallback(() => {
    setOpen(false)
  }, [setOpen])

  const text = useMemo(() => {
    if (list.length === 0) return '--'
    if (list.length > 1) return `${list.length} ${t('common.amountShort')}`
    return list[0]
  }, [t, list])

  return (
    <React.Fragment>
      <span
        className={commonClasses.anchor}
        onClick={useCallback(() => setOpen(true), [])}
      >
        {text}
      </span>
      <ActivityWalletBindingDepositsDialog open={open} close={close} reload={reload} id={id} bindingDeposits={list} title={title} account={account} cash={cash} />
    </React.Fragment>
  )
}

const ActivityWalletTable: React.FC = () => {
  const classes = useCommonStyles()
  const { t } = useT()
  const gdk = useGDK()
  const platforms = useGDKStore.platform.platforms()
  const games = useGDKStore.platform.games()
  const storeCategories = useGDKStore.platform.categories()
  const globalDialog = useGlobalDialog()
  const [list, setList] = useState<PaginationRes<ActivityWalletItem[]> & { total_count: number, total_cash: string }>({ ...createDefaultPaginationData([]), total_count: 0, total_cash: '0.0000' })
  const writableExport = useChecker([PermissionType.ACTIVITY_WALLET_EXPORT])
  const { reload, reloadFlag } = useReload()

  const pageFlow = usePageFlow()
  const request = useRequestFromSearch({ searchToRequest })
  useGetDataByPayload({
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    payload: request!,
    gdkFunc: (payload) => gdk.activity.getActivityWalletList(payload),
    gdkFuncDependencies: [gdk, reloadFlag],
    onBeforeFetch: pageFlow.setLoadingStart,
    onSuccess: (res: PaginationRes<ActivityWalletItem[]> & { total_count: number, total_cash: string }) => {
      setList(res)
      pageFlow.setContentShow()
    },
    onError: pageFlow.setGDKError,
    canLoadData: request !== undefined
  })
  const tableClasses = useMemo(() => ({
    head: classes.pinkTableHead,
    row: classes.tableRow,
    cellHead: classes.tableCellHead,
    cellBody: classes.nowrap
  }), [classes])

  const sumRow = useMemo(() => {
    return [{
      id: 1,
      totalCount: formatCount(list.total_count),
      totalCash: formatMoney(list.total_cash)
    }] as SumRowType[]
  }, [list])
  const sumData = useMemo(() => {
    return createTableData<SumRowType>(
      {
        id: {
          label: '',
          value: 'id'
        },
        totalCount: {
          label: t('common.totalCount'),
          value: 'totalCount',
          align: 'center'
        },
        totalCash: {
          label: t('common.totalCash'),
          value: 'totalCash',
          align: 'center'
        }
      },
      [
        'totalCount',
        'totalCash'
      ],
      sumRow,
      'id'
    )
  }, [t, sumRow])

  const rows: RowType[] = useMemo(() => {
    return list.data.map((item) => {
      return {
        id: item.id,
        account: item.account,
        expiredAt: (<DateTime time={item.expired_at}/>),
        createdAt: (<DateTime time={item.created_at} />),
        activitySerial: item.serial_number,
        title: item.title,
        content: (<MemoTextWithTitleAndContext context={MemoContext} title={item.content ?? '-'} memo={item.content ?? '-'} />),
        platform: (<span className={classes.pre}>{item.limit_type === ActivityWalletLimitType.GAME_CATEGORY ? item.limit_ids.map((id) => getCategoryShortName(id, storeCategories)).join(', ') : item.limit_ids.map((id) => getGameName(id, games)).join(', ')}</span>),
        multiple: `${item.multiple} ${t('common.multi')}`,
        cash: formatMoney(item.cash),
        status: (<span style={{ color: activityWalletStatusColor[item.status] }}>
          {t(activityWalletStatusTypeName[item.status])}
        </span>),
        transferTo: item.to === null ? '--' : getWalletNameByWalletId(item.to, platforms),
        transferSerial: item.transfer_logs.length === 0
          ? (<span>--</span>)
          : (
              item.transfer_logs.length === 1
                ? (<span>{ item.transfer_logs[0] }</span>)
                : (
                  <span
                    className={classes.anchor}
                    onClick={() => {
                      globalDialog.setConfig(createGlobalDialogConfig({
                        showIcon: false,
                        showCancel: false,
                        message: item.transfer_logs.join('\n')
                      }))
                      globalDialog.setOpen({
                        id: `transferSerial-${item.id}`,
                        value: true,
                        isOK: false
                      })
                    }}
                  >
                    {item.transfer_logs.length} {t('common.amountShort')}
                  </span>
                  )
            ),
        updatedAt: (<DateTime time={item.updated_at} />),
        updatedBy: item.updated_by,
        bindingDeposits: ((<BindingDepositButton id={item.id} list={item.binding_deposits} reload={reload} title={item.title} account={item.account} cash={item.cash} />))
      } as RowType
    })
  }, [list.data, classes.anchor, classes.pre, games, storeCategories, platforms, t, globalDialog, reload])
  const data = useMemo(() => {
    return createTableData<RowType>(
      {
        id: {
          label: '',
          value: 'id'
        },
        account: {
          label: t('common.playerAccount'),
          value: 'account',
          align: 'center'
        },
        createdAt: {
          label: t('common.sendDate'),
          value: 'createdAt',
          align: 'center',
          width: 100
        },
        expiredAt: {
          label: t('common.receivedExpiredTime'),
          value: 'expiredAt',
          align: 'center',
          width: 100
        },
        activitySerial: {
          label: t('common.activitySerial'),
          value: 'activitySerial',
          align: 'center'
        },
        title: {
          label: t('common.activityName'),
          value: 'title',
          align: 'center'
        },
        content: {
          label: t('common.activityContent'),
          value: 'content',
          align: 'center'
        },
        platform: {
          label: t('common.activityGamePlatform'),
          value: 'platform',
          align: 'center',
          width: 300
        },
        multiple: {
          label: t('common.searchingEffectiveCash'),
          value: 'multiple',
          align: 'center'
        },
        cash: {
          label: t('common.cash'),
          value: 'cash',
          align: 'right'
        },
        status: {
          label: t('common.status'),
          value: 'status',
          align: 'center'
        },
        transferTo: {
          label: t('common.transferIn'),
          value: 'transferTo',
          align: 'center'
        },
        transferSerial: {
          label: t('common.serialNumber'),
          value: 'transferSerial',
          align: 'center'
        },
        updatedAt: {
          label: t('common.updatedAt'),
          value: 'updatedAt',
          align: 'center'
        },
        updatedBy: {
          label: t('common.updateBy'),
          value: 'updatedBy',
          align: 'center'
        },
        bindingDeposits: {
          label: t('common.exchangedDepositOrderNo'),
          value: 'bindingDeposits',
          align: 'center'
        }
      },
      [
        'account',
        'createdAt',
        'expiredAt',
        'activitySerial',
        'title',
        'content',
        'platform',
        'multiple',
        'cash',
        'status',
        'transferTo',
        'transferSerial',
        'updatedAt',
        'updatedBy',
        'bindingDeposits'
      ],
      rows,
      'id'
    )
  }, [t, rows])

  const handlePagination = usePaginationClickAndChangeUrl({
    request,
    encodePath: allRoute.activityWallet.encodePath
  })

  const [handleDebouncedExport] = useDebouncedCallback(() => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    gdk.activity.exportActivityWalletValidate(request!)
      .subscribe({
        next: (res) => {
          const link = document.createElement('a')
          link.href = getTargetURL(getServerUrl('api'), res.url.replace(/\/api/i, ''))
          link.click()
          link.remove()
        },
        error: (err: GDKError) => {
          globalDialog.setConfig(createGlobalDialogConfig({
            variant: 'error',
            message: err.message,
            showCancel: false
          }))
          globalDialog.setOpen({ id: 'exportActivityWalletFailed', value: true, isOK: false })
        }
      })
  }, 200)

  if (request === undefined) return null
  return (
    <React.Fragment>
      <Grid container direction="column" spacing={3}>
        <Grid item>
          <ScrollablePaper marginX={5}>
            <Box padding={4}>
              <LoadingAndErrorFrame {...pageFlow.status}>

                <Box paddingTop={3}>
                  <CoreTable
                    data={sumData}
                    total={1}
                    classes={tableClasses}
                  />
                </Box>
              </LoadingAndErrorFrame>
            </Box>
          </ScrollablePaper>
        </Grid>
        <Grid item>
          <ScrollablePaper marginX={5}>
            <Box padding={4}>
              <LoadingAndErrorFrame {...pageFlow.status}>
              {!!list.total && writableExport && (
                <Box>
                  <Grid container justifyContent="flex-end">
                    <Grid item>
                      <Button
                        className={classes.purpleGradualButton}
                        onClick={handleDebouncedExport}
                      >
                        {t('common.export')}
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              )}
                <Box paddingTop={3}>
                  <MemoPopoverWithContext memoPopoverContext={MemoContext}>
                    <CoreTable
                        classes={tableClasses}
                        data={data}
                        total={list.total}
                        showPagination
                        page={request.page}
                        perPage={list.per_page}
                        onChangePage={handlePagination}
                      />
                  </MemoPopoverWithContext>
                </Box>
              </LoadingAndErrorFrame>
            </Box>
          </ScrollablePaper>
        </Grid>
      </Grid>
    </React.Fragment>
  )
}

export default React.memo(ActivityWalletTable)

import React, { useState, useMemo } from 'react'
import { Link } from 'react-router-dom'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import ScrollablePaper from '../../../components/default/present/ScrollablePaper'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import { useGetData, useReload } from '../../../utils/default/ComplexFlowHook'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { usePageFlow } from '../../../utils/default/PageFlowHook'
import { PermissionType, PlayerLayer } from '@golden/gdk-admin'
import useT from '../../../i18ns/admin/useT'
import DateTime from '../../../components/default/present/DateTime'
import { createTableData, formatMoney, formatCount, formatDateTime } from '../../../utils/default/TableHelper'
import { useChecker } from '../../../utils/admin/AdminRouteHook'
import CoreTable from '../../../components/default/present/CoreTable'
import LoadingAndErrorFrame from '../../../components/default/frames/LoadingAndErrorFrame'
import allRoute from '../../../components/admin/route/route'
import PlayerLayerSetButton from '../../../components/admin/player/PlayerLayerSetButton'
import Tip from '../../../components/admin/Tip'
import PlayerLayerSystemButton from '../../../components/admin/player/PlayerLayerSystemButton'

interface RowType {
  id: number
  name: string
  condition: string
  register: string
  depositCount: string
  depositCash: string
  onlyBindDebitCard: string
  isManual: string
  count: React.ReactElement
  updatedAt: React.ReactElement
  updatedBy: string
  function: React.ReactElement
}

const PlayerLayerPage: React.FC = () => {
  const gdk = useGDK()
  const pageFlow = usePageFlow()
  const commonClasses = useCommonStyles()
  const { t } = useT()
  const writableEdit = useChecker()
  const writableSetPlayer = useChecker([PermissionType.PLAYER_LAYER_SET_PLAYER])
  const [res, setRes] = useState<PlayerLayer | null>(null)
  const tableClasses = useMemo(() => {
    return {
      head: commonClasses.pinkTableHead,
      row: commonClasses.tableRow,
      cellHead: commonClasses.tableCellHead
    }
  }, [commonClasses.pinkTableHead, commonClasses.tableCellHead, commonClasses.tableRow])
  const { reload, reloadFlag } = useReload()
  useGetData({
    gdkFunc: () => gdk.player.getPlayerLayerList(),
    gdkFuncDependencies: [gdk, reloadFlag],
    onBeforeFetch: pageFlow.setLoadingStart,
    onSuccess: (res: PlayerLayer) => {
      setRes(res)
      pageFlow.setContentShow()
    },
    onError: pageFlow.setGDKError
  })
  const layers = useMemo(() => res?.layers.map((item) => ({ id: item.id, name: item.name })) ?? [], [res])
  const rows = useMemo(() => {
    return res?.layers.map((item) => ({
      id: item.id,
      name: item.name,
      condition: '',
      register: formatCount(item.register_days),
      depositCount: formatCount(item.deposit_times),
      depositCash: formatMoney(item.cash_sum_deposit),
      onlyBindDebitCard: item.only_bind_debit_card ? 'V' : '',
      isManual: item.is_manual ? 'V' : '',
      count: (
        <Link
          to={allRoute.player.encodePath({
            param: {},
            search: { layer_id: item.id, page: 1 }
          })}
          className={commonClasses.purpleAnchor}
        >
          {formatCount(item.player_count)}
        </Link>
      ),
      updatedAt: (<DateTime time={item.updated_at} />),
      updatedBy: item.updated_by,
      function: (
        <Button
          component={Link}
          to={allRoute.playerLayerUpdate.encodePath({ param: { id: item.id }, search: {} })}
          className={commonClasses.blueGradualOutlineButton}
          classes={{ disabled: commonClasses.blueGradualOutlineDisabledButton }}
          disabled={!item.can_modify}
        >
          {t('common.update')}
        </Button>
      )
    })) ?? []
  }, [commonClasses, res, t])
  const data = useMemo(() => {
    return createTableData<RowType>(
      {
        id: {
          label: '',
          value: 'id'
        },
        name: {
          label: t('common.layerName'),
          value: 'name',
          align: 'center'
        },
        condition: {
          label: t('common.layerCondition'),
          value: 'condition',
          align: 'center',
          groupItems: [
            'register',
            'depositCount',
            'depositCash'
          ]
        },
        register: {
          label: t('common.totalRegisterDay'),
          value: 'register',
          align: 'center'
        },
        depositCount: {
          label: t('common.totalDepositCount'),
          value: 'depositCount',
          align: 'center'
        },
        depositCash: {
          label: t('common.totalDepositCash'),
          value: 'depositCash',
          align: 'center'
        },
        onlyBindDebitCard: {
          label: t('userStatus.hasBindCard'),
          value: 'onlyBindDebitCard',
          align: 'center'
        },
        isManual: {
          label: t('common.onlyManualAssign'),
          value: 'isManual',
          align: 'center'
        },
        count: {
          label: t('common.playerCount'),
          value: 'count',
          align: 'center'
        },
        updatedAt: {
          label: t('common.updateAt'),
          value: 'updatedAt',
          align: 'center'
        },
        updatedBy: {
          label: t('common.updateBy'),
          value: 'updatedBy',
          align: 'center'
        },
        function: {
          label: t('common.function'),
          value: 'function',
          align: 'center'
        }
      },
      writableEdit
        ? [
            'name',
            'condition',
            'onlyBindDebitCard',
            'isManual',
            'count',
            'updatedAt',
            'updatedBy',
            'function'
          ]
        : [
            'name',
            'condition',
            'onlyBindDebitCard',
            'isManual',
            'count',
            'updatedAt',
            'updatedBy'
          ],
      rows,
      'id'
    )
  }, [rows, t, writableEdit])
  return (
    <Box paddingY={6}>
      <ScrollablePaper marginX={6}>
        <Box padding={5}>
          <Box paddingBottom={2}>
            <Grid container spacing={2} justify="space-between">
              <Grid item>
                <PlayerLayerSystemButton reload={reload} />
              </Grid>
              { writableSetPlayer && (<Grid item>
                <PlayerLayerSetButton
                  reload={reload}
                  layers={layers}
                />
              </Grid>) }
            </Grid>
          </Box>
          <LoadingAndErrorFrame { ...pageFlow.status }>
            <CoreTable
              data={data}
              total={data.rows.length}
              classes={tableClasses}
            />
          </LoadingAndErrorFrame>
          <Tip
            text={t('common.playerLayerTip', { time: res ? formatDateTime(res.layer_start_at) : '' })}
          />
        </Box>
      </ScrollablePaper>
    </Box>
  )
}

export default React.memo(PlayerLayerPage)

import React, { useMemo, useState, useEffect, useCallback } from 'react'
import { useTheme } from '@material-ui/core/styles'
import { RoleListItem, PaginationRes, RoleTreeItem } from '@golden/gdk-admin'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Tree, { TreeNode } from '../../default/present/Tree'
import LoadingAndErrorFrame from '../../default/frames/LoadingAndErrorFrame'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import useT from '../../../i18ns/admin/useT'
import { ColumnCollection, createDefaultPaginationData, createTableData, formatDateTime } from '../../../utils/default/TableHelper'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { useRequestFromSearch, SearchToRequestFunc, useGetDataByParams, useGetDataByPayload, useReload, usePaginationClickAndChangeUrl } from '../../../utils/default/ComplexFlowHook'
import allRoute, { Path } from '../route/route'
import { usePageFlow } from '../../../utils/default/PageFlowHook'
import { acceptUndefined, parseInt } from '../../../utils/default/FormHelper'
import CoreTable from '../../default/present/CoreTable'
import { useChecker } from '../../../utils/admin/AdminRouteHook'
import { Link } from 'react-router-dom'
import RoleManagementButtons from './RoleManagementButtons'
import useGDKStore from '../../../providers/admin/gdk/useGDKStore'

interface RowType {
  id: number
  name: string
  count: React.ReactElement | string | number
  childCount: number
  updatedAt: string
  updatedBy: string
  operation: React.ReactElement
}

const searchToRequest: SearchToRequestFunc<{ nodeId?: string, page: number }> = (search) => ({
  ...search,
  page: acceptUndefined(search.page, parseInt) ?? 1
})

const RoleManagementTable: React.FC = () => {
  const classes = useCommonStyles()
  const theme = useTheme()
  const writable = useChecker()
  const gdk = useGDK()
  const { t } = useT()
  const me = useGDKStore.admin.me()

  const treePageFlow = usePageFlow()
  const [nodes, setNodes] = useState<RoleTreeItem[]>([])

  const { reload: treeReload, reloadFlag: treeReloadFlag } = useReload()
  useGetDataByParams({
    canLoadData: !!me?.role_id,
    path: Path.ROLE_MANAGEMENT,
    gdkFunc: (param: { id: string }) => gdk.permissionRole.getRoleTree(Number(param.id ?? me?.role_id)),
    gdkFuncDependencies: [gdk, treeReloadFlag, me?.role_id],
    onBeforeFetch: treePageFlow.setLoadingStart,
    onSuccess: (res) => {
      setNodes(res)
      if (res.length > 0) setNodeId(res[0].id.toString())
      treePageFlow.setContentShow()
    },
    onError: treePageFlow.setGDKError
  })

  const request = useRequestFromSearch({ searchToRequest })

  const defaultExpanded: string[] = useMemo(() => {
    if (!me?.role_id) return []
    return [me.role_id.toString()]
  }, [me?.role_id])

  const [nodeId, setNodeId] = useState<string>('0')
  useEffect(() => {
    if (request?.nodeId) {
      setNodeId(request.nodeId)
    } else if (defaultExpanded[1]) {
      setNodeId((nodeId) => {
        if (nodeId === '0') {
          return defaultExpanded[1]
        }
        return nodeId
      })
    }
  }, [request, defaultExpanded])

  const root: TreeNode = useMemo(() => {
    const getNode = (parentId: number): TreeNode => {
      const children = nodes.filter((node) => node.parent_id === parentId)
      return {
        positions: children.map((child) => child.id.toString()),
        values: children
          .map((child) => ({
            [child.id]: {
              label: child.name,
              leaves: getNode(child.id)
            }
          }))
          .reduce((collection, item) => Object.assign({}, collection, item), {})
      }
    }
    return getNode(nodes.find((node) => node.id === me?.role_id)?.parent_id ?? 0)
  }, [nodes, me])

  const payload = useMemo(() => {
    return {
      nodeId,
      page: request?.page ?? 1
    }
  }, [request, nodeId])

  const listPageFlow = usePageFlow(true, false)
  const { reload: listReload, reloadFlag: listReloadFlag } = useReload()
  const [list, setList] = useState<PaginationRes<RoleListItem[]> & { self_data: RoleListItem }>({
    ...createDefaultPaginationData([]),
    self_data: {
      id: 0,
      name: '',
      count: 0,
      child_count: 0,
      updated_at: new Date(),
      updated_by: ''
    }
  })
  useGetDataByPayload({
    payload,
    gdkFunc: (payload) => gdk.permissionRole.getRoleList(Number(payload.nodeId), payload.page),
    gdkFuncDependencies: [gdk, listReloadFlag],
    onBeforeFetch: listPageFlow.setLoadingStart,
    onSuccess: (res: PaginationRes<RoleListItem[]> & { self_data: RoleListItem }) => {
      setList(res)
      listPageFlow.setContentShow()
    },
    onError: listPageFlow.setGDKError,
    canLoadData: Number(payload.nodeId) !== 0
  })

  const column: ColumnCollection<RowType> = useMemo(() => ({
    id: {
      label: '',
      value: 'id'
    },
    name: {
      label: t('common.role'),
      value: 'name',
      align: 'center',
      width: 100
    },
    count: {
      label: t('common.peopleCount'),
      value: 'count',
      align: 'center',
      width: 100
    },
    childCount: {
      label: t('common.subordinate'),
      value: 'childCount',
      align: 'center',
      width: 100
    },
    updatedAt: {
      label: t('common.updatedAt'),
      value: 'updatedAt',
      align: 'center',
      width: 100
    },
    updatedBy: {
      label: t('common.updateBy'),
      value: 'updatedBy',
      align: 'center',
      width: 100
    },
    operation: {
      label: t('common.operation'),
      value: 'operation',
      align: 'center',
      width: 100
    }
  }), [t])
  const columnOrder = useMemo(() => [
    'name',
    'count',
    'childCount',
    'updatedAt',
    'updatedBy',
    ...(writable ? ['operation'] : [])
  ] as Array<keyof RowType>, [writable])

  const reload = useCallback(() => {
    setNodeId((me?.role_id ?? 0).toString())
    treeReload()
    listReload()
  }, [listReload, me?.role_id, treeReload])

  const mainRows: RowType[] = useMemo(() => {
    const el = list.self_data
    return [{
      id: el.id,
      name: el.name,
      count: el.id !== (me?.role_id ?? 0) || nodes.find((node) => node.id === me?.role_id)?.parent_id === 0
        ? el.count
          ? (<Link
            className={classes.anchor}
            to={allRoute.managerAccount.encodePath({ search: { role_id: el.id, page: 1 }, param: {} })}
          >
            {el.count}
          </Link>)
          : el.count
        : '-',
      childCount: el.child_count,
      updatedAt: formatDateTime(el.updated_at),
      updatedBy: el.updated_by,
      operation: (
        <RoleManagementButtons
          id={el.id}
          name={el.name}
          reload={reload}
          disabled={nodeId === '0' || nodeId === me?.role_id.toString()}
        />
      )
    }]
  }, [list.self_data, me?.role_id, nodes, classes.anchor, reload, nodeId])
  const mainData = useMemo(() => createTableData(column, columnOrder, mainRows, 'id'), [column, columnOrder, mainRows])

  const subRows: RowType[] = useMemo(() => list.data.map((el) => ({
    id: el.id,
    name: el.name,
    count: el.count
      ? (<Link
        className={classes.anchor}
        to={allRoute.managerAccount.encodePath({ search: { role_id: el.id, page: 1 }, param: {} })}
      >
        {el.count}
      </Link>)
      : el.count,
    childCount: el.child_count,
    updatedAt: formatDateTime(el.updated_at),
    updatedBy: el.updated_by,
    operation: (
      <RoleManagementButtons
        id={el.id}
        name={el.name}
        reload={reload}
      />
    )
  })), [classes.anchor, list.data, reload])
  const subData = useMemo(() => createTableData(column, columnOrder, subRows, 'id'), [column, columnOrder, subRows])

  const tableClasses = useMemo(() => {
    return {
      head: classes.greyTableHead,
      cellHead: classes.tableCellHead,
      row: classes.tableRow
    }
  }, [classes])

  const handlePagination = usePaginationClickAndChangeUrl({
    request,
    encodePath: allRoute.accountEmperorLayer.encodePath
  })

  return (
    <Grid container spacing={3} direction="row" alignItems="stretch">
      <Grid item xs={12} md={3}>
        <Box
          height="100%"
          bgcolor={theme.palette.common.black}
          padding={2}
          overflow="auto"
        >
          <LoadingAndErrorFrame { ...treePageFlow.status }>
            {defaultExpanded.length > 0 && (
              <Tree
                color={theme.palette.common.white}
                root={root}
                defaultExpanded={defaultExpanded}
                onLabelClick={(nodeId: string) => setNodeId(nodeId)}
              />
            )}
          </LoadingAndErrorFrame>
        </Box>
      </Grid>
      <Grid item xs={12} md={9}>
        <Box paddingBottom={6} width="100%">
          <LoadingAndErrorFrame { ...listPageFlow.status }>
            <CoreTable
              classes={tableClasses}
              data={mainData}
              total={1}
            />
          </LoadingAndErrorFrame>
        </Box>
        <LoadingAndErrorFrame
          loading={listPageFlow.status.loading}
          error={listPageFlow.status.error}
          showContent={listPageFlow.status.showContent && subRows.length !== 0}
        >
          <CoreTable
            classes={tableClasses}
            data={subData}
            total={list.total}
            showPagination
            page={request?.page ?? 1}
            onChangePage={handlePagination}
          />
        </LoadingAndErrorFrame>
      </Grid>
    </Grid>
  )
}

export default React.memo(RoleManagementTable)

import React from 'react'
import Box from '@material-ui/core/Box'
import EffectiveCashHistoryForm from '../../../components/admin/manual/EffectiveCashHistoryForm'
import EffectiveCashHistoryTable from '../../../components/admin/manual/EffectiveCashHistoryTable'

const ManualEffectiveCashPage: React.FC = () => {
  return (
    <Box paddingY={5}>
      <Box paddingX={5}>
        <EffectiveCashHistoryForm />
      </Box>
      <EffectiveCashHistoryTable />
    </Box>
  )
}

export default React.memo(ManualEffectiveCashPage)

import React, { createContext, useState, Dispatch, SetStateAction } from 'react'

export interface GreetingIsDisplayedType {
  isDisplayed: boolean
  setDisplayed: Dispatch<SetStateAction<boolean>>
}

export const GreetingIsDisplayedContext = createContext<GreetingIsDisplayedType>({
  isDisplayed: true,
  setDisplayed: () => {}
})

const GreetingDisplayStatusProvider: React.FC = (props) => {
  const [isDisplayed, setDisplayed] = useState(true)
  const { children } = props

  return (
    <GreetingIsDisplayedContext.Provider
      value={{ isDisplayed, setDisplayed }}
    >
      {children}
    </GreetingIsDisplayedContext.Provider>
  )
}

export default GreetingDisplayStatusProvider

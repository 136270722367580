import React, { useState, useMemo, useCallback } from 'react'
import { PaginationRes, GameLoginHistory } from '@golden/gdk-admin'
import Box from '@material-ui/core/Box'
import CoreTable from '../../default/present/CoreTable'
import DateTime from '../../default/present/DateTime'
import LoadingAndErrorFrame from '../../default/frames/LoadingAndErrorFrame'
import ScrollablePaper from '../../default/present/ScrollablePaper'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import useT from '../../../i18ns/admin/useT'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { createDefaultPaginationData, createTableData } from '../../../utils/default/TableHelper'
import { searchToRequest } from './PlayerGameLoginHistoryForm'
import { usePageFlow } from '../../../utils/default/PageFlowHook'
import { useRequestFromSearch, useGetDataByPayload, usePaginationClickAndChangeUrl } from '../../../utils/default/ComplexFlowHook'
import allRoutes from '../route/route'
import PlayerGameLoginHistoryDialog from './PlayerGameLoginHistoryDialog'
import VpnChip from '../VpnChip'

interface RowType {
  id: number
  detailId: string
  loginAt: React.ReactElement
  account: string
  ip: React.ReactElement
  ipLocation: string
  isSuccess: React.ReactElement | string
  gameName: string
}

const LoginHistoryDetailDialog = React.memo(PlayerGameLoginHistoryDialog)

const PlayerGameLoginHistoryTable: React.FC = () => {
  const classes = useCommonStyles()
  const { t } = useT()
  const gdk = useGDK()
  const [list, setList] = useState<PaginationRes<GameLoginHistory[]>>(createDefaultPaginationData([]))
  const [detailIdForDialog, setDetailIdForDialog] = useState<string>('')
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false)
  const pageFlow = usePageFlow()
  const request = useRequestFromSearch({ searchToRequest })
  useGetDataByPayload({
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    payload: request!,
    gdkFunc: (payload) => gdk.history.getGameLoginHistory(payload),
    gdkFuncDependencies: [gdk],
    onBeforeFetch: pageFlow.setLoadingStart,
    onSuccess: (res: PaginationRes<GameLoginHistory[]>) => {
      setList(res)
      pageFlow.setContentShow()
    },
    onError: pageFlow.setGDKError,
    canLoadData: request !== undefined
  })
  const tableClasses = useMemo(() => ({
    head: classes.pinkTableHead,
    row: classes.tableRow,
    cellHead: classes.tableCellHead
  }), [classes])
  const rows: RowType[] = useMemo(() => {
    return list.data.map((item, index) => {
      return {
        id: index,
        detailId: item.id,
        loginAt: (<DateTime time={item.login_at} />),
        account: item.account,
        ip: (<Box display="flex" flexDirection="column" alignItems="center">
          {item.is_vpn && (<VpnChip />)}
          {item.ip}
        </Box>),
        ipLocation: item.ip_location,
        isSuccess: (
          <span
            className={classes.anchor}
            onClick = {() => {
              setDetailIdForDialog(item.id)
              setIsDialogOpen(true)
            }}
          >
            {(item.is_success) ? t('common.success') : t('common.fail')}
          </span>
        ),
        gameName: item.game_name
      }
    })
  }, [list.data, t, classes.anchor])
  const data = useMemo(() => {
    return createTableData<RowType>(
      {
        id: {
          label: '',
          value: 'id'
        },
        detailId: {
          label: '',
          value: 'detailId'
        },
        loginAt: {
          label: t('common.loginAt'),
          value: 'loginAt',
          align: 'center'
        },
        account: {
          label: t('common.playerAccount'),
          value: 'account',
          align: 'center'
        },
        ip: {
          label: t('common.loginIpAddress'),
          value: 'ip',
          align: 'center'
        },
        ipLocation: {
          label: t('common.location'),
          value: 'ipLocation',
          align: 'center'
        },
        isSuccess: {
          label: t('common.status'),
          value: 'isSuccess',
          align: 'center'
        },
        gameName: {
          label: t('common.gamePlatform'),
          value: 'gameName',
          align: 'center'
        }
      },
      [
        'loginAt',
        'account',
        'gameName',
        'ip',
        'ipLocation',
        'isSuccess'
      ],
      rows,
      'id'
    )
  }, [rows, t])

  const handlePagination = usePaginationClickAndChangeUrl({
    request,
    encodePath: allRoutes.playerGameLoginHistory.encodePath
  })

  const handleClose = useCallback(() => { setIsDialogOpen(false) }, [])

  if (request === undefined) return null

  return (
    <>
      <ScrollablePaper marginX={5}>
        <Box padding={4}>
          <LoadingAndErrorFrame { ...pageFlow.status }>
            <CoreTable
              classes={tableClasses}
              data={data}
              total={list.total}
              showPagination
              page={request.page}
              onChangePage={handlePagination}
            />
          </LoadingAndErrorFrame>
        </Box>
      </ScrollablePaper>
      <LoginHistoryDetailDialog detailId={detailIdForDialog} isOpen={isDialogOpen} handleClose={handleClose}/>
    </>
  )
}

export default React.memo(PlayerGameLoginHistoryTable)

import React, { useState, useMemo } from 'react'
import {
  PaginationRes,
  PlayerProfitHistory
} from '@golden/gdk-admin'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { PlayerReportProfitRequestWithJson, searchToRequest } from './PlayerReportProfitForm'
import CoreTable from '../../../default/present/CoreTable'
import PointsCell from '../../../default/present/PointsCell'
import LoadingAndErrorFrame from '../../../default/frames/LoadingAndErrorFrame'
import ScrollablePaper from '../../../default/present/ScrollablePaper'
import { useRequestFromSearch, useGetDataByPayload, usePaginationClickAndChangeUrl } from '../../../../utils/default/ComplexFlowHook'
import { usePageFlow } from '../../../../utils/default/PageFlowHook'
import useGDK from '../../../../providers/admin/gdk/useGDK'
import { createDefaultPaginationData, createTableData, formatMoney } from '../../../../utils/default/TableHelper'
import useT from '../../../../i18ns/admin/useT'
import { useCommonStyles } from '../../../../utils/admin/StyleHook'
import allRoute from '../../route/route'
import PayoutTip from '../../PayoutTip'
import { isUndefined, omitBy } from '@golden/utils'

interface RowType {
  id: number
  account: string
  rank: string
  effective: string
  bet: string
  result: string
  feedback: string
  activity: string
  deposit: string
  withdrawal: string
  profit: React.ReactElement
  buyback: string
  risk: string
  other: string
  agentTransfer: string
  donate: string
}

const Tip: React.FC = React.memo(() => {
  const { t } = useT()
  return (
    <React.Fragment>
      <Box paddingTop={1}>
        <Typography color="error">{t('common.profitPlayerReportTip')}</Typography>
        <Typography color="error">{t('common.riskAdjustmentTip')}</Typography>
      </Box>
      <PayoutTip />
    </React.Fragment>
  )
})

const PlayerReportProfitTable: React.FC = () => {
  const classes = useCommonStyles()
  const { t } = useT()
  const gdk = useGDK()
  const [list, setList] = useState<PaginationRes<PlayerProfitHistory[]>>(createDefaultPaginationData([]))
  const pageFlow = usePageFlow()
  const request = useRequestFromSearch({ searchToRequest })
  const requestWithJson = useMemo(() => {
    const converted = { ...request, specify_league: JSON.stringify(request?.specify_league) }
    return omitBy(converted, isUndefined) as PlayerReportProfitRequestWithJson
  }
  , [request])

  useGetDataByPayload({
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    payload: request!,
    gdkFunc: (payload) => gdk.history.getPlayerProfitHistory(payload),
    gdkFuncDependencies: [gdk],
    onBeforeFetch: pageFlow.setLoadingStart,
    onSuccess: (res: PaginationRes<PlayerProfitHistory[]>) => {
      setList(res)
      pageFlow.setContentShow()
    },
    onError: pageFlow.setGDKError,
    canLoadData: request !== undefined
  })
  const tableClasses = useMemo(() => ({
    head: classes.pinkTableHead,
    row: classes.tableRow,
    cellHead: classes.tableCellHead
  }), [classes])
  const rows: RowType[] = useMemo(() => {
    return list.data.map((item, index) => {
      return {
        id: index,
        account: item.account,
        rank: item.rank,
        effective: formatMoney(item.effective_bet),
        bet: formatMoney(item.bet_cash),
        result: formatMoney(item.cash_result),
        feedback: formatMoney(item.feedback),
        activity: formatMoney(item.activity_cash),
        deposit: formatMoney(item.deposit),
        withdrawal: formatMoney(item.withdraw),
        profit: (<PointsCell points={item.user_revenue} />),
        buyback: formatMoney(item.buyback_amount),
        risk: formatMoney(item.risk_adjustment),
        other: formatMoney(item.other),
        agentTransfer: formatMoney(item.agent_transfer),
        donate: formatMoney(item.donate)
      } as RowType
    })
  }, [list.data])
  const data = useMemo(() => {
    return createTableData<RowType>(
      {
        id: {
          label: '',
          value: 'id'
        },
        account: {
          label: t('common.playerAccount'),
          value: 'account',
          align: 'center'
        },
        rank: {
          label: t('common.playerRank'),
          value: 'rank',
          align: 'center'
        },
        effective: {
          label: t('common.successBetCash'),
          value: 'effective',
          align: 'center'
        },
        bet: {
          label: t('common.allBetCash'),
          value: 'bet',
          align: 'right'
        },
        result: {
          label: t('common.payedCash'),
          value: 'result',
          align: 'right'
        },
        feedback: {
          label: t('common.allFeedback'),
          value: 'feedback',
          align: 'right'
        },
        activity: {
          label: t('common.allActivityCash'),
          value: 'activity',
          align: 'right'
        },
        deposit: {
          label: t('common.allDepositCash'),
          value: 'deposit',
          align: 'right'
        },
        withdrawal: {
          label: t('common.allWithdrawalCash'),
          value: 'withdrawal',
          align: 'right'
        },
        profit: {
          label: t('common.playerRevenue'),
          value: 'profit',
          align: 'right'
        },
        buyback: {
          label: t('common.totalBuyback'),
          value: 'buyback',
          align: 'right'
        },
        risk: {
          label: t('common.riskAdjustment'),
          value: 'risk',
          align: 'right'
        },
        other: {
          label: t('common.other'),
          value: 'other',
          align: 'right'
        },
        agentTransfer: {
          label: t('common.transferPoint'),
          value: 'agentTransfer',
          align: 'right'
        },
        donate: {
          label: t('common.donate'),
          value: 'donate',
          align: 'right'
        }
      },
      [
        'account',
        'rank',
        'effective',
        'bet',
        'result',
        'buyback',
        'feedback',
        'activity',
        'risk',
        'profit',
        'deposit',
        'withdrawal',
        'other',
        'agentTransfer',
        'donate'
      ],
      rows,
      'id'
    )
  }, [rows, t])
  const handlePagination = usePaginationClickAndChangeUrl({
    request: requestWithJson,
    encodePath: allRoute.playerReportProfit.encodePath
  })

  if (request === undefined) return null
  return (
    <ScrollablePaper marginX={5}>
      <Box padding={4}>
        <LoadingAndErrorFrame { ...pageFlow.status }>
          <CoreTable
            classes={tableClasses}
            data={data}
            total={list.total}
            showPagination
            page={request.page}
            onChangePage={handlePagination}
            tableFooterMemo={Tip}
          />
        </LoadingAndErrorFrame>
      </Box>
    </ScrollablePaper>
  )
}

export default React.memo(PlayerReportProfitTable)

import { BaseTimeType } from '@golden/gdk-admin'
import TKeyType from '../../i18ns/admin/TKeyType'

const baseTimeTypeName: { [key in BaseTimeType]: TKeyType } = {
  [BaseTimeType.ORIGINAL_CREATED_AT]: 'baseTimeType.originalCreatedAt',
  [BaseTimeType.SETTLED_AT]: 'baseTimeType.settledAt',
  [BaseTimeType.GAME_START_AT]: 'baseTimeType.gameStartAt',
  [BaseTimeType.ACCOUNTED_AT]: 'baseTimeType.accountedAt'
}

export default baseTimeTypeName

import React, { useCallback, useMemo } from 'react'
import { Link } from 'react-router-dom'
import { SvgIconProps } from '@material-ui/core/SvgIcon'
import Box from '@material-ui/core/Box'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Typography from '@material-ui/core/Typography'
import Collapse from '@material-ui/core/Collapse'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import useDrawer from '../../../providers/default/drawer/useDrawer'
import GroupKey from './GroupKey'
import allRoute from '../route/route'
import RouteKey from '../route/RouteKey'
import allLink, { LinkKey } from './link'
import useT from '../../../i18ns/admin/useT'

interface PropTypes {
  classes: Record<'text' | 'item' | 'itemButton' | 'itemSelected' | 'subItem' | 'subItemButton' | 'subItemSelected', string>
  groupKey: GroupKey
  icon: React.ComponentType<SvgIconProps>
  title: string
  subItems: Array<RouteKey | LinkKey>
}

const ListGroup: React.FC<PropTypes> = (props) => {
  const { groupKey, icon: Icon, title, subItems, classes } = props
  const { t } = useT()
  const { open, itemSelected, itemClicked, subItemSelected, setOpen, setItemClicked } = useDrawer()
  const itemShouldHint = useMemo(() => itemClicked === '' ? itemSelected : itemClicked, [itemClicked, itemSelected])
  const thisOpen = useMemo(() => groupKey === itemShouldHint && open, [open, itemShouldHint, groupKey])

  const handleOpen = useCallback(() => {
    setOpen(true)
    setItemClicked(groupKey)
  }, [setOpen, setItemClicked, groupKey])

  const isSelected = useCallback((key: RouteKey): boolean => {
    if (subItemSelected === '') return false
    if (allRoute[subItemSelected as RouteKey].path === allRoute[key].path) return true
    return allRoute[subItemSelected as RouteKey].path.indexOf(`${allRoute[key].path}/`) === 0
  }, [subItemSelected])

  return (
    <React.Fragment>
      <ListItem
        button
        selected={groupKey === itemShouldHint}
        onClick={handleOpen}
        classes={{
          root: classes.item,
          button: classes.itemButton,
          selected: classes.itemSelected
        }}
      >
        <ListItemIcon>
          <Icon />
        </ListItemIcon>
        <ListItemText
          primary={<Typography variant="h6">{title}</Typography>}
          className={classes.text}
        />
        {thisOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </ListItem>
      <Collapse in={thisOpen} timeout="auto" unmountOnExit>
        <Box paddingY={1}>
          <List component="div" disablePadding>
            {subItems.map((key) => {
              if (Object.values(RouteKey).includes((key as RouteKey))) {
                return (
                  <ListItem
                    key={key}
                    button
                    selected={isSelected(key as RouteKey)}
                    className={classes.subItem}
                    classes={{
                      button: classes.subItemButton,
                      selected: classes.subItemSelected
                    }}
                    component={Link}
                    to={allRoute[key as RouteKey].path}
                  >
                    <ListItemText primary={t(allRoute[key as RouteKey].title)} className={classes.text} />
                  </ListItem>
                )
              }
              if (Object.values(LinkKey).includes((key as LinkKey))) {
                return (
                  <ListItem
                    key={key}
                    button
                    selected={key === subItemSelected}
                    className={classes.subItem}
                    classes={{
                      button: classes.subItemButton,
                      selected: classes.subItemSelected
                    }}
                    component="a"
                    href={allLink[key as LinkKey].href}
                    target={allLink[key as LinkKey].blank ? '_blank' : '_self'}
                  >
                    <ListItemText primary={t(allLink[key as LinkKey].title)} className={classes.text} />
                  </ListItem>
                )
              }
              return null
            })}
          </List>
        </Box>
      </Collapse>
    </React.Fragment>
  )
}

export default React.memo(ListGroup)

import React, { useState, useMemo } from 'react'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import useT from '../../../i18ns/admin/useT'
import useGDK from '../../../providers/admin/gdk/useGDK'
import ScrollablePaper from '../../../components/default/present/ScrollablePaper'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import { PlatformFeeDailyRecord } from '@golden/gdk-admin'
import { useGetDataByPayload, useRequestFromSearch } from '../../../utils/default/ComplexFlowHook'
import { searchToRequest } from './AdminReportPlatformFeeForm'
import { usePageFlow } from '../../../utils/default/PageFlowHook'
import LoadingAndErrorFrame from '../../../components/default/frames/LoadingAndErrorFrame'
import CoreTable from '../../../components/default/present/CoreTable'
import useGDKStore from '../../../providers/admin/gdk/useGDKStore'
import PointsCell from '../../../components/default/present/PointsCell'
import { createTableData, formatCount, formatDateTime } from '../../../utils/default/TableHelper'
import baseTimeTypeName from '../../../constants/admin/baseTimeTypeName'
import { mergeArray } from '@golden/utils'

interface RowType {
  id: number
  bet: string
  activity: string
  game: string
  base: string
  time: string
  count: string
  revenue: React.ReactElement
  rate: string
  fee: React.ReactElement
  donate: React.ReactElement
  activityRevenue: React.ReactElement
}

interface PropTypes {
  reloadFlag: boolean
}

const AdminReportPlatformFeeTable: React.FC<PropTypes> = (props) => {
  const { reloadFlag } = props
  const classes = useCommonStyles()
  const { t } = useT()
  const gdk = useGDK()
  const games = useGDKStore.platform.games()
  const pageFlow = usePageFlow()
  const [res, setRes] = useState<PlatformFeeDailyRecord[]>([])
  const request = useRequestFromSearch({ searchToRequest })
  useGetDataByPayload({
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    payload: request!,
    gdkFunc: (payload) => gdk.history.getPlatformFeeDailyRecords(payload),
    gdkFuncDependencies: [gdk, reloadFlag],
    onBeforeFetch: pageFlow.setLoadingStart,
    onSuccess: (res: PlatformFeeDailyRecord[]) => {
      setRes(res)
      pageFlow.setContentShow()
    },
    onError: pageFlow.setGDKError,
    canLoadData: request !== undefined
  })
  const tableClasses = useMemo(() => ({
    head: classes.greyTableHead,
    row: classes.tableRow,
    cellHead: classes.tableCellHead
  }), [classes])
  const rows = useMemo(() => mergeArray(games, res, (game, item) => game.id === item.game_id).map(([game, item]) => ({
    id: game.id,
    game: game.nameWithStatus,
    bet: '',
    base: t(baseTimeTypeName[item.base_time]),
    time: `${formatDateTime(item.start_at)} ~ ${formatDateTime(item.end_at)}`,
    count: formatCount(item.bet_count),
    revenue: (<PointsCell points={item.revenue} />),
    rate: item.fee_rates.map((item) => `${item}%`).join(', '),
    fee: (<PointsCell points={item.fee} />),
    donate: (<PointsCell points={item.donate} />),
    activity: '',
    activityRevenue: (<PointsCell points={item.activity_revenue} />)
  }), 'game_id'), [games, res, t])

  const data = useMemo(() => {
    return createTableData<RowType>(
      {
        id: {
          label: '',
          value: 'id'
        },
        game: {
          label: t('common.gameStore'),
          value: 'game',
          align: 'center'
        },
        bet: {
          label: t('common.allBets'),
          value: 'bet',
          align: 'center',
          groupItems: [
            'count',
            'revenue'
          ]
        },
        base: {
          label: t('common.settleBaseTime'),
          value: 'base',
          align: 'center'
        },
        time: {
          label: t('common.timeBlock'),
          value: 'time',
          align: 'center'
        },
        count: {
          label: t('common.betCount2'),
          value: 'count',
          align: 'center'
        },
        revenue: {
          label: t('common.totalWinAndLose'),
          value: 'revenue',
          align: 'center'
        },
        rate: {
          label: t('common.feePercent'),
          value: 'rate',
          align: 'center'
        },
        fee: {
          label: t('common.platformFee'),
          value: 'fee',
          align: 'center'
        },
        donate: {
          label: t('common.donate'),
          value: 'donate',
          align: 'center'
        },
        activity: {
          label: t('common.activityBetOrder'),
          value: 'activity',
          groupItems: ['activityRevenue'],
          align: 'center'
        },
        activityRevenue: {
          label: t('common.totalWinAndLose'),
          value: 'activityRevenue',
          align: 'center'
        }
      },
      [
        'game',
        'base',
        'time',
        'bet',
        'rate',
        'fee',
        'donate',
        'activity'
      ],
      rows,
      'id'
    )
  }, [t, rows])
  if (request === undefined) return null
  return (
    <ScrollablePaper marginX={5}>
      <Box padding={4}>
        <Box
          paddingY={1.25}
          paddingX={2}
          className={classes.pinkTitleBar}
        >
          <Typography variant="h5">
            {t('common.platformFeeStatistic')}
          </Typography>
        </Box>
        <Box paddingY={2}>
          <LoadingAndErrorFrame {...pageFlow.status}>
            <CoreTable
              classes={tableClasses}
              data={data}
              total={res.length}
            />
          </LoadingAndErrorFrame>
        </Box>
      </Box>
    </ScrollablePaper>
  )
}

export default React.memo(AdminReportPlatformFeeTable)

import { NotificationChannelType } from '@golden/gdk-admin'
import TKeyType from '../../i18ns/admin/TKeyType'

const channelName: { [key in NotificationChannelType]: TKeyType } = {
  [NotificationChannelType.GOLDEN]: 'common.systemMail',
  [NotificationChannelType.EMAIL]: 'common.email',
  [NotificationChannelType.SMS]: 'common.sms'
}

export default channelName

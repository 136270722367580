import { AgentType } from '@golden/gdk-admin'
import TKeyType from '../../i18ns/admin/TKeyType'

const agentTypeName: { [key in AgentType]: TKeyType } = {
  [AgentType.EMPEROR]: 'agent.emperor',
  [AgentType.BOSS]: 'agent.boss',
  [AgentType.MANAGER]: 'agent.manager',
  [AgentType.LEADER]: 'agent.leader',
  [AgentType.STAFF]: 'agent.staff'
}

export default agentTypeName

import React from 'react'
import ReactDOM from 'react-dom'
import { registerGA } from '@golden/ga-react'
import App from './App'
import * as serviceWorker from '../../serviceWorker'
import QrScanner from 'qr-scanner'
import QrScannerWorkerPath from 'qr-scanner/qr-scanner-worker.min.js?url'
import { registerSentry } from '@golden/sentry-react'
import { checkMock } from '../../utils/admin/checkMock'
import { installClarity } from '@golden/clarity'

void (async () => {
  QrScanner.WORKER_PATH = QrScannerWorkerPath

  if (import.meta.env.VITE_APP_STAGE !== 'local') {
    if (!import.meta.env.VITE_APP_SENTRY_DSN) throw new Error('Please set VITE_APP_SENTRY_DSN env for sentry.')
    registerSentry({
      dsn: import.meta.env.VITE_APP_SENTRY_DSN,
      release: import.meta.env.VITE_APP_SENTRY_RELEASE,
      stage: import.meta.env.VITE_APP_STAGE
    })
  }

  if (import.meta.env.VITE_APP_GA_ID) {
    registerGA(import.meta.env.VITE_APP_GA_ID)
  }
  if (import.meta.env.VITE_APP_CLARITY_ID) {
    installClarity(import.meta.env.VITE_APP_CLARITY_ID)
  }

  await checkMock()

  ReactDOM.render(<App />, document.getElementById('root'))
})()

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()

document.title = '总后台系统'

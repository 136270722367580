import React from 'react'
import Box from '@material-ui/core/Box'
import FinanceBankToolbar from '../../../components/admin/finance/FinanceBankToolbar'
import FinanceBankTable from '../../../components/admin/finance/FinanceBankTable'
import ScrollablePaper from '../../../components/default/present/ScrollablePaper'
import { SearchToRequestFunc } from '../../../utils/default/ComplexFlowHook'
import { acceptUndefined, pipe, guaranteeBetween } from '../../../utils/default/FormHelper'
import { isUndefined, omitBy } from '@golden/utils'

export interface RequestType {
  maintained?: number
  is_showed?: number
  name?: string
  page: number
}

export const searchToRequest: SearchToRequestFunc<RequestType> = (search) => {
  const converted = {
    ...search,
    page: acceptUndefined(
      search.page,
      pipe(
        parseInt,
        (value) => guaranteeBetween(value, 1, Number.MAX_SAFE_INTEGER)
      )
    ) || 1,
    is_showed: acceptUndefined(
      search.is_showed,
      pipe(
        parseInt,
        (value) => guaranteeBetween(value, 0, 1)
      )
    ),
    maintained: acceptUndefined(
      search.maintained,
      pipe(
        parseInt,
        (value) => guaranteeBetween(value, 0, 1)
      )
    )
  } as RequestType
  return omitBy(converted, isUndefined) as RequestType
}

const FinanceBankPage: React.FC = () => {
  return (
    <Box paddingY={5}>
      <ScrollablePaper marginX={5}>
        <Box padding={4}>
          <FinanceBankToolbar />
        </Box>
        <Box paddingTop={1} paddingBottom={5} paddingX={4}>
          <FinanceBankTable />
        </Box>
      </ScrollablePaper>
    </Box>
  )
}

export default React.memo(FinanceBankPage)

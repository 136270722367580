import React, { useMemo, createContext, useState } from 'react'
import { omitBy, isUndefined } from '@golden/utils'
import { Link } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import SearchIcon from '@material-ui/icons/Search'
import Box from '@material-ui/core/Box'
import MuiTextField, { TextFieldProps } from '@material-ui/core/TextField'
import MuiButton from '@material-ui/core/Button'
import FormStateProvider from '../../default/form/FormStateProvider'
import FormField from '../../default/form/FormField'
import FormSubmitButton from '../../default/form/FormSubmitButton'
import { ManagerAccountRequest, searchToRequest } from '../../../views/admin/manager/ManagerAccountPage'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import useT from '../../../i18ns/admin/useT'
import { useRequestFromSearch, useChangeUrlSubmit, useGetDataByPayload } from '../../../utils/default/ComplexFlowHook'
import { ChangedFormGetter, createDefaultFormState, FormValidation, ValueGetter } from '../../../utils/default/FormHook'
import { getValueFromChangeEvent, convertEmptyToUndefined } from '../../../utils/default/FormHelper'
import allRoute from '../route/route'
import { useChecker } from '../../../utils/admin/AdminRouteHook'
import DropDown, { PropTypes as DropDownProps } from '../../default/form/DropDown'
import { RoleTreeItem } from '@golden/gdk-admin'
import useGDKStore from '../../../providers/admin/gdk/useGDKStore'
import useGDK from '../../../providers/admin/gdk/useGDK'

interface ManagerAccountFormType {
  account: string
  roleId: number
}

const formToRequest = (form: ManagerAccountFormType): ManagerAccountRequest => {
  const data = {
    page: 1,
    role_id: form.roleId === 0 ? undefined : form.roleId,
    account: convertEmptyToUndefined(form.account)
  } as ManagerAccountRequest
  return omitBy(data, isUndefined) as ManagerAccountRequest
}
const validation: FormValidation<ManagerAccountFormType> = {
  account: [],
  roleId: []
}

const getValueFromEvent: ValueGetter<ManagerAccountFormType> = {
  account: getValueFromChangeEvent,
  roleId: getValueFromChangeEvent
}

const FormContext = createContext(createDefaultFormState({
  account: '',
  roleId: 0
}))

const TextField = React.memo(MuiTextField)

const Button = React.memo(MuiButton)

const ManagerAccountForm: React.FC = () => {
  const classes = useCommonStyles()
  const { t } = useT()
  const request = useRequestFromSearch({ searchToRequest })
  const me = useGDKStore.admin.me()
  const writable = useChecker()
  const gdk = useGDK()

  const defaultForm = useMemo(() => ({
    ...request,
    account: request?.account ?? '',
    roleId: request?.role_id ?? 0
  }), [request])
  const handleSubmit = useChangeUrlSubmit({
    toAddNowTimestamp: true,
    formToRequest,
    encodePath: allRoute.managerAccount.encodePath
  })

  const getChangedForm: ChangedFormGetter<ManagerAccountFormType> = {
    roleId: (value, form) => {
      const newForm = { ...form, roleId: value, account: request?.account ?? '' }
      return handleSubmit(newForm)
    }
  }

  const [nodes, setTree] = useState<RoleTreeItem[]>([])
  useGetDataByPayload({
    payload: me?.role_id ?? 0,
    canLoadData: !!me?.role_id,
    gdkFunc: (payload) => gdk.permissionRole.getRoleTree(payload),
    gdkFuncDependencies: [gdk],
    onSuccess: (res: RoleTreeItem[]) => setTree(res)
  })
  const roleOptions = useMemo(() => {
    return [
      { name: t('common.all'), value: 0 },
      ...nodes
        .filter((role) => (me?.role_id ?? 0) !== role.id || role.parent_id === 0)
        .map((role) => ({ name: role.name, value: role.id }))
    ]
  }, [me?.role_id, nodes, t])

  return (
    <FormStateProvider
      context={FormContext}
      defaultValue={defaultForm}
      onSubmit={handleSubmit}
      getValueFromEvent={getValueFromEvent}
      getChangedForm={getChangedForm}
      validation={validation}
    >
      <Grid container alignItems="flex-end" justifyContent="space-between" spacing={2}>
        <Grid item container spacing={2} alignItems="flex-end" md={12} lg={8}>
          <Grid item xs={12} md={7}>
            <Box display="flex" alignItems="flex-end">
              <Box display="flex" alignItems="center">
                <SearchIcon />
              </Box>
              <Box display="flex" flexGrow={1} marginRight={1}>
                <FormField<ManagerAccountFormType, TextFieldProps>
                  context={FormContext}
                  component={TextField}
                  name="account"
                  placeholder={t('common.accountSearch')}
                  fullWidth
                />
              </Box>
              <FormSubmitButton
                context={FormContext}
                component={Button}
                className={classes.pinkGradualButton}
                type="submit"
                style={{ wordBreak: 'keep-all' }}
              >
                {t('common.search')}
              </FormSubmitButton>
            </Box>
          </Grid>
          <Grid item xs={12} md={5}>
            <FormField<ManagerAccountFormType, DropDownProps>
              context={FormContext}
              component={DropDown}
              name="roleId"
              fullWidth
              options={roleOptions}
            />
          </Grid>
        </Grid>
        {writable && (
          <Grid item container justifyContent="flex-end" md={12} lg={4}>
            <MuiButton
              classes={{ disabled: classes.disabledButton }}
              className={classes.purpleGradualButton}
              style={{ wordBreak: 'keep-all' }}
              component={Link}
              to={allRoute.managerAccountCreate.encodePath({ search: { page: request?.page ?? 1, order: request?.order ?? 'asc', sort_by: request?.sort_by ?? 'account' }, param: {} })}
            >
              {t('common.createAccount')}
            </MuiButton>
          </Grid>
        )}
      </Grid>
    </FormStateProvider>
  )
}

export default React.memo(ManagerAccountForm)

import React from 'react'
import Box from '@material-ui/core/Box'
import BannerNotificationForm from '../../../components/admin/forestage/BannerNotificationForm'
import BannerNotificationTable from '../../../components/admin/forestage/BannerNotificationTable'
import BannerNotificationCreateToolbar from '../../../components/admin/forestage/BannerNotificationCreateToolbar'
import { BannerNotificationQuery, PaginationReq } from '@golden/gdk-admin'
import { SearchToRequestFunc } from '../../../utils/default/ComplexFlowHook'
import { omitBy, isUndefined } from '@golden/utils'
import { guaranteeBetween, guaranteeNotUndefined, pipe, parseInt, acceptUndefined, guaranteeBeKey } from '../../../utils/default/FormHelper'
import bannerNotificationTargetTypeName from '../../../constants/admin/bannerNotificationTargetTypeName'
import bannerNotificationStatusName from '../../../constants/admin/bannerNotificationStatusName'

export type Request = BannerNotificationQuery & PaginationReq

export const searchToRequest: SearchToRequestFunc<Request> = (search) => {
  const converted = {
    ...search,
    target: acceptUndefined(search.target, pipe(
      (value) => guaranteeBeKey(value, Object.keys(bannerNotificationTargetTypeName))
    )),
    status: acceptUndefined(search.status, pipe(
      (value) => guaranteeBeKey(value, Object.keys(bannerNotificationStatusName))
    )),
    page: pipe(
      guaranteeNotUndefined,
      parseInt,
      (value) => guaranteeBetween(value, 1, Number.MAX_SAFE_INTEGER)
    )(search.page)
  }
  return omitBy(converted, isUndefined) as Request
}

const BannerNotificationPage: React.FC = () => {
  return (
    <Box paddingY={6}>
      <Box paddingX={6}>
        <BannerNotificationCreateToolbar />
      </Box>
      <Box paddingTop={2} paddingX={6}>
        <BannerNotificationForm />
      </Box>
      <Box paddingTop={2} paddingX={6}>
        <BannerNotificationTable />
      </Box>
    </Box>
  )
}

export default React.memo(BannerNotificationPage)

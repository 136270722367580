
import React, { useState } from 'react'
import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import AdminReportDeviceStatisticForm, { searchToRequest } from '../../../components/admin/adminReport/AdminReportDeviceStatisticForm'
import AdminReportDeviceStatisticLoginCount from '../../../components/admin/adminReport/AdminReportDeviceStatisticLoginCount'
import { useRequestFromSearch, useGetDataByPayload } from '../../../utils/default/ComplexFlowHook'
import { DeviceStatistic } from '@golden/gdk-admin'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { usePageFlow } from '../../../utils/default/PageFlowHook'
import LoadingAndErrorFrame from '../../../components/default/frames/LoadingAndErrorFrame'

const AdminReportDeviceStatisticPage: React.FC = () => {
  const gdk = useGDK()
  const request = useRequestFromSearch({ searchToRequest })
  const [result, setResult] = useState<DeviceStatistic>({
    login_count: []
  })
  const pageFlow = usePageFlow()
  useGetDataByPayload({
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    payload: request!,
    gdkFunc: (payload) => gdk.history.getDeviceStatistic(payload),
    gdkFuncDependencies: [gdk],
    onBeforeFetch: pageFlow.setLoadingStart,
    onSuccess: (res: DeviceStatistic) => {
      setResult(res)
      pageFlow.setContentShow()
    },
    onError: pageFlow.setGDKError,
    canLoadData: request !== undefined
  })
  return (
    <Box paddingY={5}>
      <Box paddingX={5}>
        <AdminReportDeviceStatisticForm request={request} />
      </Box>
      <LoadingAndErrorFrame error={null} loading={false} showContent={!pageFlow.status.showContent}>
        <Box paddingX={5} paddingY={3}>
          <Paper>
            <Box padding={5}>
              <LoadingAndErrorFrame { ...pageFlow.status }></LoadingAndErrorFrame>
            </Box>
          </Paper>
        </Box>
      </LoadingAndErrorFrame>
      {request && (
        <LoadingAndErrorFrame { ...pageFlow.status } error={null} loading={false}>
          <Box marginTop={3}>
            <AdminReportDeviceStatisticLoginCount request={request} result={result} />
          </Box>
        </LoadingAndErrorFrame>
      )}
    </Box>
  )
}

export default React.memo(AdminReportDeviceStatisticPage)

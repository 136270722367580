import React, { useEffect } from 'react'
import Box from '@material-ui/core/Box'
import PlayerReportTransferForm from '../../../components/admin/playerReport/transfer/PlayerReportTransferForm'
import PlayerReportTransferTable from '../../../components/admin/playerReport/transfer/PlayerReportTransferTable'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { Branch } from '@golden/gdk-admin'

const PlayerReportBetPage: React.FC = () => {
  const gdk = useGDK()
  useEffect(() => {
    gdk.trunk.trigger([Branch.PLATFORMS])
  }, [gdk])
  return (
    <Box paddingY={5}>
      <Box paddingX={5}>
        <PlayerReportTransferForm />
      </Box>
      <Box paddingTop={3}>
        <PlayerReportTransferTable />
      </Box>
    </Box>
  )
}

export default React.memo(PlayerReportBetPage)

import React, { useCallback, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { useCommonStyles, useDialogStyles } from '../../../utils/admin/StyleHook'
import useT from '../../../i18ns/admin/useT'
import useGDK from '../../../providers/admin/gdk/useGDK'
import useGlobalDialog from '../../../providers/admin/dialog/useGlobalDialog'
import { useDialogHandleClick } from '../../../utils/default/ComplexFlowHook'
import { createGlobalDialogConfig } from '../../../utils/default/DialogHelper'
import { DepositCrawler } from '@golden/gdk-admin'
import { formatDateTime } from '../../../utils/default/TableHelper'
import { DialogContext } from '../../../views/admin/deposit/DepositCrawlerPage'

interface PropTypes {
  item: DepositCrawler
  reload: () => void
}

const useStyles = makeStyles(() => ({
  container: {
    width: 400
  }
}))

const ButtonsBase: React.FC<{
  handleConfirm: () => void
  handleDelete: () => void
}> = React.memo((props) => {
  const { handleConfirm, handleDelete } = props
  const commonClasses = useCommonStyles()
  const { t } = useT()
  return (
    <Grid container direction="column" spacing={1} justify="center">
      <Grid item>
        <Button
          onClick={handleConfirm}
          size="small"
          className={commonClasses.blueGradualOutlineButton}
        >
          {t('common.confirm')}
        </Button>
      </Grid>
      <Grid item>
        <Button
          onClick={handleDelete}
          size="small"
          className={commonClasses.pinkGradualOutlineButton}
        >
          {t('common.delete')}
        </Button>
      </Grid>
    </Grid>
  )
})

const DepositCrawlerButtons: React.FC<PropTypes> = (props) => {
  const { item, reload } = props
  const { t } = useT()
  const classes = useStyles()
  const commonClasses = useCommonStyles()
  const dialogClasses = useDialogStyles()
  const gdk = useGDK()
  const globalDialog = useGlobalDialog()
  const [, setPayload] = useContext(DialogContext)
  const handleDelete = useDialogHandleClick({
    dialogId: `delete-${item.id}`,
    globalDialog,
    changeDialogConfig: createGlobalDialogConfig({
      showIcon: false,
      notUseTypo: true,
      message: (
        <Grid container direction="column" justify="center" alignContent="center" spacing={2}>
          <Grid item>
            <Typography align="center" className={dialogClasses.text}>{t('dialog.confirmDeleteDepositCrawler')}</Typography>
          </Grid>
          <Grid item className={classes.container}>
            <Grid container direction="column">
              <Grid item>
                <Typography className={clsx(dialogClasses.text, commonClasses.purpleWord)}>{`${t('common.bankHandleDate')}[${formatDateTime(item.created_at)}]`}</Typography>
              </Grid>
              <Grid item>
                <Typography className={clsx(dialogClasses.text, commonClasses.purpleWord)}>{`${t('common.depositAccount')}[${item.golden_bank_account}]`}</Typography>
              </Grid>
              <Grid item>
                <Typography className={clsx(dialogClasses.text, commonClasses.purpleWord)}>{`${t('common.depositCash')}[${item.cash}]`}</Typography>
              </Grid>
              <Grid item>
                <Typography className={clsx(dialogClasses.text, commonClasses.purpleWord)}>{`${t('common.depositName')}[${item.player_real_name}]`}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )
    }),
    successDialogConfig: createGlobalDialogConfig({
      showIcon: false,
      showCancel: false,
      message: t('dialog.deleteDepositCrawlerSuccess')
    }),
    getFailDialogConfig: useCallback((error) => createGlobalDialogConfig({
      showIcon: true,
      variant: 'error',
      showCancel: false,
      message: error
    }), []),
    payload: item.id,
    gdkFunc: (payload) => gdk.deposit.deleteDepositCrawler(payload),
    gdkFuncDependencies: [gdk],
    onSuccess: reload
  })
  const handleConfirm = useCallback(() => {
    setPayload({ item })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item])
  return (<ButtonsBase handleConfirm={handleConfirm} handleDelete={handleDelete} />)
}

export default React.memo(DepositCrawlerButtons)

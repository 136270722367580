import Permission from '../../enums/default/Permission'
import { AdminMe } from '@golden/gdk-admin'
import RoutePermission from '../../components/admin/route/RoutePermission'

export function getPass (
  routePermissions: RoutePermission[],
  me: AdminMe
): boolean {
  return routePermissions
    .filter((item) => item.type === 'accessible')
    .some((item) => item.value === Permission.EVERYONE || me?.permissions?.includes(item.value))
}

import React, { PropsWithChildren } from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import Divider from '@material-ui/core/Divider'
import useDrawer from '../../../providers/default/drawer/useDrawer'
import CustomThemeType from '../../../themes/default/CustomThemeType'

const useStyles = makeStyles((theme: CustomThemeType) => ({
  toolbar: {
    display: 'flex',
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    ...theme.mixins.toolbar
  },
  drawer: {
    backgroundColor: theme.custom.palette.background.navigation,
    border: 'none',
    color: theme.custom.palette.text.navigation
  },
  drawerOpen: {
    width: theme.custom.shape.drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: 'hidden',
    width: theme.custom.shape.closeDrawerWidth
  },
  divider: {
    backgroundColor: theme.custom.palette.text.navigation
  }
}))

const AppDrawer: React.FC<PropsWithChildren<{}>> = (props) => {
  const { children } = props
  const classes = useStyles()
  const { open } = useDrawer()
  return (
    <Drawer
      variant="permanent"
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open
      })}
      classes={{
        paper: clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open
        })
      }}
      open={open}
    >
      <div className={classes.toolbar} />
      <Divider className={classes.divider} />
      { children }
    </Drawer>
  )
}

export default React.memo(AppDrawer)

import React, { useState, useMemo } from 'react'
import { PaginationRes, IpHistory } from '@golden/gdk-admin'
import Box from '@material-ui/core/Box'
import CoreTable from '../../default/present/CoreTable'
import DateTime from '../../default/present/DateTime'
import LoadingAndErrorFrame from '../../default/frames/LoadingAndErrorFrame'
import ScrollablePaper from '../../default/present/ScrollablePaper'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import useT from '../../../i18ns/admin/useT'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { createDefaultPaginationData, createTableData } from '../../../utils/default/TableHelper'
import { searchToRequest } from './PlayerIpForm'
import { usePageFlow } from '../../../utils/default/PageFlowHook'
import { useRequestFromSearch, useGetDataByPayload, usePaginationClickAndChangeUrl } from '../../../utils/default/ComplexFlowHook'
import deviceName from '../../../constants/admin/deviceName'
import allRoutes from '../route/route'
import VpnChip from '../VpnChip'

interface RowType {
  id: number
  loginAt: React.ReactElement
  account: string
  ip: React.ReactElement
  register: React.ReactElement
  browser: string
  os: string
  device: string
  loginUrl: string
  apiUrl: string
  country: string
  province: string
  city: string
  isp: string
}

const PlayerIpTable: React.FC = () => {
  const classes = useCommonStyles()
  const { t } = useT()
  const gdk = useGDK()
  const [list, setList] = useState<PaginationRes<IpHistory[]>>(createDefaultPaginationData([]))
  const pageFlow = usePageFlow()
  const request = useRequestFromSearch({ searchToRequest })
  useGetDataByPayload({
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    payload: request!,
    gdkFunc: (payload) => gdk.history.getIpHistory(payload),
    gdkFuncDependencies: [gdk],
    onBeforeFetch: pageFlow.setLoadingStart,
    onSuccess: (res: PaginationRes<IpHistory[]>) => {
      setList(res)
      pageFlow.setContentShow()
    },
    onError: pageFlow.setGDKError,
    canLoadData: request !== undefined
  })
  const tableClasses = useMemo(() => ({
    head: classes.pinkTableHead,
    row: classes.tableRow,
    cellHead: classes.tableCellHead
  }), [classes])
  const rows: RowType[] = useMemo(() => {
    return list.data.map((item, index) => {
      return {
        id: index,
        loginAt: (<DateTime time={item.login_at} />),
        account: item.account,
        ip: (<Box display="flex" flexDirection="column" alignItems="center">
          {item.is_vpn && (<VpnChip />)}
          {item.ip || '-'}
        </Box>),
        register: (<Box display="flex" flexDirection="column" alignItems="center">
          {item.register_ip_is_vpn && (<VpnChip />)}
          {item.register_ip}
        </Box>),
        browser: item.browser,
        os: item.os,
        device: t(deviceName[item.device]),
        loginUrl: item.login_url,
        apiUrl: item.api_url,
        country: item.country,
        province: item.state,
        city: item.city,
        isp: item.isp
      }
    })
  }, [list.data, t])
  const data = useMemo(() => {
    return createTableData<RowType>(
      {
        id: {
          label: '',
          value: 'id'
        },
        loginAt: {
          label: t('common.loginAt'),
          value: 'loginAt',
          align: 'center'
        },
        account: {
          label: t('common.playerAccount'),
          value: 'account',
          align: 'center'
        },
        ip: {
          label: t('common.loginIpAddress'),
          value: 'ip',
          align: 'center'
        },
        register: {
          label: t('common.registerIPAddress'),
          value: 'register',
          align: 'center'
        },
        browser: {
          label: t('common.browser'),
          value: 'browser',
          align: 'center'
        },
        os: {
          label: t('common.os'),
          value: 'os',
          align: 'center'
        },
        device: {
          label: t('common.device'),
          value: 'device',
          align: 'center'
        },
        isp: {
          label: t('common.telecomProvider'),
          value: 'isp',
          align: 'center'
        },
        loginUrl: {
          label: t('common.loginUrl'),
          value: 'loginUrl',
          align: 'center'
        },
        apiUrl: {
          label: t('common.apiUrl'),
          value: 'apiUrl',
          align: 'center'
        },
        country: {
          label: t('common.country'),
          value: 'country',
          align: 'center'
        },
        province: {
          label: t('common.province'),
          value: 'province',
          align: 'center'
        },
        city: {
          label: t('common.city'),
          value: 'city',
          align: 'center'
        }
      },
      [
        'loginAt',
        'account',
        'ip',
        'register',
        'browser',
        'os',
        'device',
        'isp',
        'loginUrl',
        'apiUrl',
        'country',
        'province',
        'city'
      ],
      rows,
      'id'
    )
  }, [rows, t])

  const handlePagination = usePaginationClickAndChangeUrl({
    request,
    encodePath: allRoutes.playerIp.encodePath
  })

  if (request === undefined) return null

  return (
    <ScrollablePaper marginX={5}>
      <Box padding={4}>
        <LoadingAndErrorFrame { ...pageFlow.status }>
          <CoreTable
            classes={tableClasses}
            data={data}
            total={list.total}
            showPagination
            page={request.page}
            onChangePage={handlePagination}
          />
        </LoadingAndErrorFrame>
      </Box>
    </ScrollablePaper>
  )
}

export default React.memo(PlayerIpTable)
